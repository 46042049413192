import { POMessagePayload, VitalMessagePayload } from '../../../../../services/CHSServices/types/data';
import { MessageVitalSingleComponent, MessageVitalSingleComponentProps } from '../MessageVitalSingleComponent/MessageVitalSingleComponent';
import { MessageVitalSingleDisplayValueComponent } from '../MessageVitalSingleDisplayComponent/MessageVitalSingleDisplayValueComponent';
import { MeasurementResultTypeEnum, VitalEnumType } from '../../../../../uc-api-sdk';
import { MessageVitalSingleDisplaySeverityComponent } from '../MessageVitalSingleDisplayComponent/MessageVitalSingleDisplaySeverityComponent';

export interface MessagePOComponentProps extends Omit<MessageVitalSingleComponentProps, 'vitalName' | 'children'> {
  oxygenSaturation: POMessagePayload['oxygen_saturation'];
  unit: VitalMessagePayload['unit'];
  severity: VitalMessagePayload['severity'];
}

export const MessagePOComponent = ({
  oxygenSaturation,
  measuredAt,
  text,
  unit,
  severity,
}: MessagePOComponentProps) => (
  <MessageVitalSingleComponent
    vitalName={MeasurementResultTypeEnum.PO}
    measuredAt={measuredAt}
    text={text}
  >
    <MessageVitalSingleDisplayValueComponent
      value={oxygenSaturation}
      unit={unit}
    />
    <MessageVitalSingleDisplaySeverityComponent
      vitalType={VitalEnumType.PO}
      severity={severity}
    />
  </MessageVitalSingleComponent>
);
