import dayjs, { Dayjs } from 'dayjs';
import { SnoozeIntervalEnum } from '../../../uc-api-sdk';

export const getSnoozeIntervalDate = (e: SnoozeIntervalEnum | string, startDate?: string) => {
  let fromDate: Dayjs = dayjs();
  if (startDate) {
    fromDate = dayjs(startDate);
  }
  switch (e) {
    case SnoozeIntervalEnum.ONE_DAY:
      return fromDate.add(24, 'hour');
    case SnoozeIntervalEnum.THREE_DAYS:
      return fromDate.add(3 * 24, 'hour');
    case SnoozeIntervalEnum.ONE_WEEK:
      return fromDate.add(1, 'week');
    case SnoozeIntervalEnum.TWO_WEEKS:
      return fromDate.add(2, 'week');
    case SnoozeIntervalEnum.ONE_MONTH:
      return fromDate.add(1, 'month');
    case SnoozeIntervalEnum.THREE_MONTHS:
      return fromDate.add(3, 'month');
    default:
      break;
  }
  return fromDate;
};
