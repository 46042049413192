import { DeviceModelEnumComponent } from '../../../../enumComponent/DeviceModelEnumComponent/DeviceModelEnumComponent';
import { DeviceModelEnum } from '../../../../uc-api-sdk';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';

export interface OrderDeviceWithIdComponentProps {
  deviceModel: DeviceModelEnum;
  deviceId?: string;
  onUnassign?: () => void;
}

export const OrderDeviceWithIdComponent = ({
  deviceModel,
  deviceId,
  onUnassign,
}: OrderDeviceWithIdComponentProps) => (
  <div className="flex ai-c">
    <DeviceModelEnumComponent value={deviceModel} />
    {
      deviceId
      && (<span>{`(${deviceId})`}</span>)
    }
    {
      deviceId
      && onUnassign
      && (
        <LinkButton
          onClick={onUnassign}
          useBlueColor
        >
          Unassign
        </LinkButton>
      )
    }
  </div>
);
