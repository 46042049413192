import { map, orderBy } from 'lodash';
import EmployeeInfo from '../../../hooks/useUserInfo/employeeInfo';
import { EmployeeInfoWithRole } from '../../patientCare/container/SelectCoverRangeContainer/SelectCoverRangeContainer';

export const sortHelper = (members: EmployeeInfo[]) => {
  const membersListWithRole: EmployeeInfoWithRole[] = [];
  map(members, (member: EmployeeInfo) => (
    map(member.allRoleTypes, (role) => (
      membersListWithRole.push({ id: member.id, name: member.fullName as string, role })
    ))
  ));

  const sort = orderBy(membersListWithRole, [
    (v) => v.name,
  ], ['asc']);
  return sort;
};
