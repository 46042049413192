import { message } from 'antd';
import { FC, useCallback } from 'react';
import { useCareNoteContext } from '../../../../contexts/CareNoteContext/CareNoteContext';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { CareNote, useCareNoteInsert } from '../../../../uc-api-sdk';
import { CareNoteFormComponent } from '../../component/CareNoteFormComponent/CareNoteFormComponent/CareNoteFormComponent';

import { CareNoteTexts } from './constant';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';

export interface CreateCareNoteFormContainerProps {
  onSubmit?: () => void;
  onCancel?: () => void;
}

export const CreateCareNoteFormContainer: FC<CreateCareNoteFormContainerProps> = ({
  onSubmit,
  onCancel,
}) => {
  const { value: isLoading, setTrue, setFalse } = useBoolean(false);
  const { info } = usePatientContext();
  const { id = '' } = info?.patientInfo || {};
  const useInsert = useCareNoteInsert({});
  const { refresh } = useCareNoteContext();
  const { send } = useBillableTimeInterventionComp();
  const { send: sendMixpanel } = useMixpanelContext();

  const insertCareNote = useCallback(async (values: CareNote) => {
    setTrue();
    sendMixpanel({ event: MixpanelEvents.AddCareNote, patientId: id });
    send({
      patientId: id as string,
      component: BillableTimeInterventionComponentEnum.AddCareNote,
      detail: BillableTimeInterventionDetailEnum.AddCareNoteSubmit,
    });
    const response = await useInsert.send({
      params: {
        document: { ...values, memberId: id },
      },
    });

    if (response?.code === 200) {
      message.success(CareNoteTexts.createSuccess);
    } else if (response?.code && response.code >= 400) {
      message.warning(CareNoteTexts.error);
    }
    setFalse();
    onSubmit?.();
    refresh?.();
  }, []);

  return (
    <CareNoteFormComponent
      isLoading={isLoading}
      onSubmit={insertCareNote}
      onCancel={onCancel}
    />
  );
};
