import { Nullable, PatientDevice } from '../uc-api-sdk';

export class PatientDeviceService {
  private patientDevices?: Nullable<PatientDevice>;

  constructor(patientDevices?: Nullable<PatientDevice>) {
    this.patientDevices = patientDevices;
  }

  getPatientDevices = () => this.patientDevices;

  public getDeviceList() {
    return this.patientDevices?.deviceList;
  }
}
