import React from 'react';
import { FreqEnumComponent } from '../../enumComponent/FreqEnumComponent/FreqEnumComponent';

export interface FreqComponentProps {
  value?: number;
  unit?: string;
}

export const FreqComponent = ({
  value,
  unit,
}: FreqComponentProps) => (
  <div>
    {value}
    {' times per '}
    {
      unit
        ? <FreqEnumComponent value={unit} type="short" />
        : ''
    }
  </div>
);
