import { ColumnType } from 'antd/es/table';
import { measurementTableDateColumnMaker } from '../MeasurementTable/MeasurementTableColumn';
import { HSChartData } from '../HSChartComponent/HSChartComponent';
import { DATE_TIME } from '../../../../constants/timeFormat';
import { HSHelper } from '../../helper/HSHelper';

/* eslint-disable import/prefer-default-export */
export const HSTablePrintColumns = {
  date: measurementTableDateColumnMaker(undefined, DATE_TIME),
  measurement: (): ColumnType<HSChartData> => ({
    title: 'Measurement',
    key: 'measurement',
    dataIndex: 'weightInLbs',
    render: (value) => `${value} ${HSHelper.getLbsUnit()}`,
  }),
};
