import { useEffect, useMemo } from 'react';
import { filter, map } from 'lodash';
import { usePatientSearchPatientViewer, usePatientTrackPatientViewer } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { PatientViewerComponent } from '../../component/PatientViewerComponent/PatientViewerComponent';
import { LimitedUser } from '../../../../generates';

export interface PatientViewerContainerProps {
  patientId: string;
}

export const PatientViewerContainer = ({
  patientId,
}: PatientViewerContainerProps) => {
  const searchPatientViewers = usePatientSearchPatientViewer({});
  const trackPatientViewers = usePatientTrackPatientViewer({});

  // Searches patient viewers for a patient every 30 seconds
  useEffect(() => {
    const searchViewers = () => searchPatientViewers.send({
      params: {
        memberId: patientId,
      }
    });
    searchViewers();
    const searchInterval = setInterval(searchViewers, 30000);
    return () => clearInterval(searchInterval);
  }, []);

  // Tracks patient viewers for a patient every 10 seconds
  useEffect(() => {
    const sendTracking = () => trackPatientViewers.send({
      params: {
        memberId: patientId,
      }
    });
    sendTracking();
    const trackInterval = setInterval(sendTracking, 10000);
    return () => clearInterval(trackInterval);
  }, []);

  const limitedUsers = useMemo<LimitedUser[]>(() => (
    filter(map(searchPatientViewers.data?.data, (v) => v.limitedUser), (v) => !!v) as LimitedUser[]
  ), [searchPatientViewers.data]);

  return (
    <div>
      <FetchComponent
        info={searchPatientViewers}
        showLoadingOverlay={limitedUsers.length > 0}
        showOnRefetch
      >
        <PatientViewerComponent
          viewers={limitedUsers}
        />
      </FetchComponent>
    </div>
  );
};
