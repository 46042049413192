import { MessageSystemAvatarComponent } from './MessageSystemAvatarComponent';
import { MessageUserAvatarComponent } from './MessageUserAvatarComponent';

import './MessageAvatarComponent.scss';

export interface MessageAvatarComponentProps {
  url?: string | null;
  isSystemMessage?: boolean;
}

export const MessageAvatarComponent = ({
  url,
  isSystemMessage,
}: MessageAvatarComponentProps) => (
  <div className="message-item-avatar">
    {
      isSystemMessage
        ? <MessageSystemAvatarComponent />
        : <MessageUserAvatarComponent avatarUrl={url} />
    }
  </div>
);
