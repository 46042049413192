import { map } from 'lodash';
import { Button, Space } from 'antd';
import { TranscribeOutstandingDetail, TranscribeOutstandingTypeEnum } from '../../../../uc-api-sdk';
import { OutstandingItemsCardComponent } from '../OutstandingItemsCardComponent/OutstandingItemsCardComponent';
import { TranscribeStatusButtonComponent } from '../../../transcribing/component/TranscribeStatusButtonComponent/TranscribeStatusButtonComponent';
import { TranscribeOutstandingTypeEnumComponent } from '../../../../enumComponent/TranscribeOutstandingTypeEnumComponent/TranscribeOutstandingTypeEnumComponent';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';

export interface OutstandingTranscribingComponentProps {
  data: TranscribeOutstandingDetail[] | null | undefined;
  onClick?: (v: TranscribeOutstandingTypeEnum) => void;
}

export const OutstandingTranscribingComponent = ({
  data = [],
  onClick,
}: OutstandingTranscribingComponentProps) => {
  const { send: sendMixpanel } = useMixpanelContext();

  const renderTranscribeButton = (v: TranscribeOutstandingDetail) => {
    const getMixpanelEvent = (type: TranscribeOutstandingTypeEnum) => {
      switch (type) {
        case TranscribeOutstandingTypeEnum.A1C:
          return MixpanelEvents.OutstandingA1CTranscribing;
        case TranscribeOutstandingTypeEnum.FOLLOW_UP:
          return MixpanelEvents.OutstandingFollowUpTranscribing;
        case TranscribeOutstandingTypeEnum.INIT:
          return MixpanelEvents.OutstandingInitialTranscribing;
        default:
          return MixpanelEvents.Unknown;
      }
    };

    const handleClick = () => {
      const { type } = v;
      if (type) {
        sendMixpanel({ event: getMixpanelEvent(type) });
        onClick?.(type);
      }
    };

    return (
      <Button
        type="link"
        onClick={handleClick}
      >
        Start now
      </Button>
    );
  };

  const renderSingle = (v: TranscribeOutstandingDetail) => (
    <OutstandingItemsCardComponent
      key={v.id}
      title={<TranscribeOutstandingTypeEnumComponent value={v.type} />}
      button={(
        <TranscribeStatusButtonComponent
          transcribeId={v.id || ''}
          button={renderTranscribeButton(v)}
        />
      )}
      dueDate={v.dueDate}
    />
  );

  return (
    <Space direction="vertical" className="w100">
      {map(data, renderSingle)}
    </Space>
  );
};
