import { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  Button,
  ButtonProps,
  Divider,
} from 'antd';
import { useBoolean } from 'usehooks-ts';
import { PlusOutlined, ShrinkOutlined } from '@ant-design/icons';
import { useOpen } from '../../hooks/useBoolean/useOpen';
import { ClickableDiv, ClickableDivProps } from '../ClickableDiv/ClickableDiv';
import { StickyNoteValue } from './types';
import { StickyNoteInputComponent, StickyNoteInputComponentProps } from './StickyNoteInputComponent';
import { StickyNoteSingleMemoComponent, StickyNoteSingleMemoComponentProps } from './StickyNoteSingleMemoComponent';
import './StickyNoteComponent.scss';

const NEW_ID = 'NEW_ID';

export interface StickyNoteComponentProps {
  value?: StickyNoteValue[];
  onEdit?: (value: StickyNoteValue) => void;
  onDelete?: (id: string) => void;
  onAdd?: (value: StickyNoteValue) => void;
  onCollapse?: () => void;
}

export const StickyNoteComponent = ({
  value,
  onEdit,
  onDelete,
  onAdd,
  onCollapse,
}: StickyNoteComponentProps) => {
  const { isOpen, open, close } = useOpen(!value?.length);
  const { value: showDotDotDot, setValue: setOverflow } = useBoolean(true);
  const [newNote, setNewNote] = useState<StickyNoteValue | undefined>(() => (
    !value?.length ? { id: NEW_ID, note: '' } : undefined
  ));
  const [selectedNoteId, setSelectedNoteId] = useState<string | undefined>(() => (
    !value?.length ? NEW_ID : undefined
  ));

  useEffect(() => {
    if (!isOpen) {
      // 250 because the css transition is 200
      const timeout = setTimeout(() => setOverflow(true), 250);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [isOpen]);

  const handleOpen: ClickableDivProps['onClick'] = () => {
    setOverflow(false);
    open();
  };

  const handleClose: ButtonProps['onClick'] = (evt) => {
    evt.stopPropagation();
    setSelectedNoteId(undefined);
    setNewNote(undefined);
    onCollapse?.();
    close();
  };

  const handleAdd: ButtonProps['onClick'] = () => {
    if (!selectedNoteId) {
      setNewNote({ id: NEW_ID, note: '' });
      setSelectedNoteId(NEW_ID);
    }
  };

  const handleAddSubmit: StickyNoteInputComponentProps['onSubmit'] = (id, newNote) => {
    onAdd?.({ note: newNote });
    setSelectedNoteId(undefined);
    setNewNote(undefined);
  };

  const handleEdit: StickyNoteInputComponentProps['onSubmit'] = (id, newNote) => {
    onEdit?.({ id, note: newNote });
    setSelectedNoteId(undefined);
  };

  const handleDelete: StickyNoteSingleMemoComponentProps['onDelete'] = (id) => {
    onDelete?.(id);
  };

  const handleCancel: StickyNoteInputComponentProps['onCancel'] = () => {
    setSelectedNoteId(undefined);
  };

  const renderClosedMemo = () => (
    <div className="sticky-note-component__closed-memo">
      <div className="sticky-note-component__closed-memo-text">
        {value?.at(0)?.note}
      </div>
      {!!value?.length && (
        <div className="text-gray-scale-2">
          {`(${value.length})`}
        </div>
      )}
    </div>
  );

  const renderSingleSticky = (v: StickyNoteValue, i: number, mainObj: StickyNoteValue[]) => (
    <div key={i}>
      {v.id === selectedNoteId ? (
        <StickyNoteInputComponent
          key={v.id}
          value={v}
          onSubmit={handleEdit}
          onCancel={handleCancel}
        />
      ) : (
        <StickyNoteSingleMemoComponent
          key={v.id}
          id={v.id ?? ''}
          value={v.note ?? ''}
          updatedAt={v.updatedAt}
          updatedByUser={v.updatedByUser}
          createdAt={v.createdAt}
          createdByUser={v.createdByUser}
          onEdit={setSelectedNoteId}
          onDelete={handleDelete}
          showButtons={!selectedNoteId}
        />
      )}
      {i !== mainObj.length - 1 && <Divider />}
    </div>
  );

  return (
    <ClickableDiv
      className={classNames({
        'sticky-note-component': true,
        'not-expanded': !isOpen,
        expanded: isOpen,
      })}
      onClick={handleOpen}
    >
      <div
        className={classNames({
          'sticky-note-component__content': true,
          'no-overflow': showDotDotDot,
          expanded: isOpen,
        })}
      >
        {isOpen ? (
          <div className="flex jc-sb ai-c mb10">
            <Button
              className="sticky-note-component__add-btn"
              icon={<PlusOutlined />}
              onClick={handleAdd}
              disabled={!!selectedNoteId}
            />
            <Button
              type="text"
              icon={<ShrinkOutlined />}
              onClick={handleClose}
            />
          </div>
        ) : null}
        <div className="ant-typography">
          {selectedNoteId === NEW_ID && newNote && (
            <>
              <StickyNoteInputComponent
                key={NEW_ID}
                value={newNote}
                onSubmit={handleAddSubmit}
                onCancel={handleCancel}
              />
              {!!value?.length && <Divider />}
            </>
          )}
          {isOpen ? value?.map(renderSingleSticky) : renderClosedMemo()}
        </div>
      </div>
    </ClickableDiv>
  );
};
