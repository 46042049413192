import { Dayjs } from 'dayjs';
import { USA_DATE } from '../../../../constants/timeFormat';
import { ExerciseGoalInfo } from './ExerciseChartComponent';
import { FromDateToDateDayjs, Point } from '../../type';
import TimezoneService from '../../../../helpers/timezone/timezoneService';

export const createExerciseChartSeries = <T extends Point>(data: T[]) => ([
  {
    data,
    states: {
      hover: {
        halo: null,
      },
    },
    findNearestPointBy: 'xy',
  },
]);

const getDayRange = ({
  fromDate, toDate,
}: FromDateToDateDayjs) => {
  const diffInDays = toDate.clone().diff(fromDate, 'days');
  if (diffInDays < 33) {
    return 1;
  }
  if (diffInDays >= 190) {
    return 14;
  }
  return 7;
};

export const createExerciseChartOptions = (
  series: ReturnType<typeof createExerciseChartSeries>,
  extraInfo: ExerciseGoalInfo,
  fromDate: Dayjs,
  toDate: Dayjs,
) => ({
  chart: {
    type: 'line',
  },
  title: {
    align: 'left',
    text: `Goal:${extraInfo.goal} ${extraInfo.goalUnit}`,
    x: -10,
    style: {
      fontSize: '12px',
    },
  },
  subtitle: {
    text: `
      <div>
          <span>Total: ${extraInfo.total}</span>
          <br/>
          <span>Above Target: ${extraInfo.aboveTarget}(${extraInfo.abovePercentage}%)</span>
          <br/>
          <span>Below Target: ${extraInfo.belowTarget}(${extraInfo.bellowPercentage}%)</span>
      </div>
    `,
    useHTML: true,
    align: 'right',
    x: -10,
  },
  noData: 'No Data',
  tooltip: {
    headerFormat: '',
    backgroundColor: '#FFFFFF',
    borderColor: '#D9D9D9',
    // @ts-ignore
    pointFormatter() {
      // @ts-ignore
      const { y, category } = this;
      const date: string = TimezoneService.calcDateTimeDayjs(category).format(USA_DATE);
      return `
        <br>
          Date: <b>${date}</b><br/>
          Exercise: <b>${y} steps</b><br/>
          Target:<b>${extraInfo.goal}</b>
        </br>
      `;
    },
    padding: 12,
    style: {
      fontSize: 12,
      width: '200px',
    },
    dateTimeLabelFormats: {
      day: '%m/%e/%Y',
    },
  },
  plotBackgroundColor: '#0099FF',
  legend: {
    align: 'left',
    verticalAlign: 'top',
  },
  xAxis: {
    type: 'datetime',
    tickWidth: 2,
    tickInterval: 24 * 3600 * 1000 * getDayRange({ fromDate, toDate }),
    min: fromDate.valueOf(),
    max: toDate.valueOf(),
    labels: {
      formatter: (xAxis: { value: Date; }) => (
        TimezoneService.calcDateTimeDayjs(xAxis.value).format(USA_DATE)
      ),
    },
    crosshair: {
      width: '1',
      color: '#00A3FF',
    },
  },
  yAxis: {
    title: {
      text: 'Steps / Day',
    },
    plotLines: [{
      value: extraInfo.goal,
      width: 2,
      label: {
        text: extraInfo.goal,
      },
    }],
    min: 0,
    gridLineDashStyle: 'Dash',
  },
  credits: {
    enabled: false,
  },
  time: {
    useUTC: false,
    timezone: TimezoneService.guessTimezone(),
  },
  series,
});
