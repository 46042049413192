import { VisitMethodEnum } from '../../uc-api-sdk';
import EmptyComponent from '../../uiComponent/EmptyComponent/EmptyComponent';

export interface VisitMethodEnumComponentProps {
  value: VisitMethodEnum | null | undefined;
}

export const VisitMethodEnumComponent = ({
  value,
}: VisitMethodEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case VisitMethodEnum.INCLINIC: return 'In-clinic';
      case VisitMethodEnum.IN_CLINIC: return 'In-clinic';
      case VisitMethodEnum.PHONE: return 'Phone Call';
      case VisitMethodEnum.VIRTUAL: return 'Video Call';
      default: return <EmptyComponent />;
    }
  };

  return (
    <span>{getText()}</span>
  );
};
