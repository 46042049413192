import { Input } from 'antd';
import { useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';

export interface PriorAuthCommentFormItemComponentProps {}

export const PriorAuthCommentFormItemComponent = () => {
  const insuranceForm = useInsuranceForm();
  return (
    <FormItem
      info={insuranceForm.getInfo('priorAuthComments')}
    >
      <Input.TextArea
        autoSize={{ minRows: 3, maxRows: 3 }}
      />
    </FormItem>
  );
};
