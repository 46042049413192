import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  Button, Input, InputProps, Spin
} from 'antd';
import { useEffect, useState } from 'react';
import useBoolean from '../../hooks/useBoolean/useBoolean';
import './StandaloneInputComponent.scss';

export interface StandaloneInputComponentProps extends Omit<InputProps, 'onSubmit'> {
  onSubmit?: (value: string) => void;
  loading?: boolean;
}

export const StandaloneInputComponent = ({
  value,
  onChange,
  onSubmit,
  placeholder = 'Add note here',
  loading,
  ...props
}: StandaloneInputComponentProps) => {
  const [initialValue, setInitialValue] = useState(value || '');
  const [inputValue, setInputValue] = useState(value || '');
  const { value: isChanged, setTrue: setIsChanged, setFalse: setUnChanged } = useBoolean(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    onChange?.(e);
  };

  const handleSubmit = () => {
    if (inputValue !== initialValue) {
      onSubmit?.(inputValue as string);
    }
    setUnChanged();
  };

  useEffect(() => {
    if (initialValue !== inputValue) {
      setIsChanged();
    } else {
      setUnChanged();
    }
  }, [inputValue, initialValue]);

  useEffect(() => {
    if (value !== initialValue) {
      setInitialValue(value || '');
    }
  }, [value]);

  const onCancel = () => {
    setInputValue(value || '');
  };

  return (
    <div className="standalone-input-component">
      <Input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        onChange={handleChange}
        value={inputValue}
        placeholder={placeholder}
      />
      {isChanged && (
        <div className="standalone-input-buttons-group">
          <Button onClick={handleSubmit}>
            <CheckOutlined />
          </Button>
          <Button onClick={onCancel}>
            <CloseOutlined />
          </Button>
        </div>
      )}
      {loading && (
        <div className="standalone-input-loading">
          <Spin />
        </div>
      )}
    </div>
  );
};
