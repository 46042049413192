import { isEmpty } from 'lodash';
import React, { FC } from 'react';
import { SummaryEmptyComponent } from '../../SummaryColumnComponents/SummaryEmptyComponent';
import { SummaryTwoIndexComponent } from '../../SummaryColumnComponents/SummaryTwoIndexComponent';
import { BPSummaryKey } from '../../../type';

export interface BPSummaryAverageColumnComponentProps {
  value: number[];
  type: BPSummaryKey;
}

export const BPSummaryAverageColumnComponent: FC<BPSummaryAverageColumnComponentProps> = ({
  value,
  type,
}) => {
  if (type === 'pulsePressure') {
    if (isEmpty(value)) {
      return (
        <SummaryEmptyComponent />
      );
    }
    return (
      <div>{value}</div>
    );
  }
  return (
    <SummaryTwoIndexComponent value={value} />
  );
};
