import { compact } from 'lodash';
import { LimitedUser, PatientAssignees } from '../../../../uc-api-sdk';
import { useDeepCompareMemo } from '../../../../hooks/useDeepCompareEffect';

export interface PatientAssigneeComponentProps {
  assignees?: PatientAssignees | null;
}

export const PatientAssigneeComponent = ({
  assignees,
}: PatientAssigneeComponentProps) => {
  const renderUser = (user?: LimitedUser | null) => (
    `${user?.firstName} ${user?.lastName} (${user?.title || 'N/A'})`.trim()
  );

  const assigneeUserDisplay = useDeepCompareMemo(() => {
    const assigneeUsers = compact([
      assignees?.assignedToRDUser,
      assignees?.assignedToCAUser
    ]);

    return assigneeUsers.map(renderUser);
  }, [assignees]);

  if (assigneeUserDisplay.length === 0) {
    return (<span>N/A</span>);
  }
  return (<span>{assigneeUserDisplay.join(', ')}</span>);
};
