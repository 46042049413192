import { ReactNode } from 'react';
import classNames from 'classnames';
import { Typography } from 'antd';

import './CardTextComponent.scss';

export type CardTextSize = 'small' | 'medium' | 'default' | 'large' | 'x-large' | 'xx-large';
export type CardTextColor = 'gray1' | 'gray2' | 'gray3' | 'white' | 'default';

export interface CardTextComponentProps {
  children: ReactNode;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  size?: CardTextSize;
  color?: CardTextColor;
  className?: string;
  centerText?: boolean;
  display?: 'inline-block' | 'block';
}

export const CardTextComponent = ({
  children,
  bold,
  italic,
  underline,
  size = 'default',
  color = 'default',
  className = '',
  display = 'block',
  centerText,
}: CardTextComponentProps) => (
  <Typography.Text
    className={classNames({
      'card-text-component': true,
      bold,
      [`card-text-${size}`]: !!size,
      [color]: !!color,
      [className]: !!className,
      [display]: display,
      'ta-c': !!centerText,
    })}
    italic={italic}
    underline={underline}
  >
    {children}
  </Typography.Text>
);
