// @ts-nocheck
import React from 'react';
import { ColumnType } from 'antd/lib/table';
import { DayOfWeekEnum, VitalsMonitoringBGTableData } from './VitalsMonitoringBGTableComponent';
import { VitalsMonitoringBGTableCellComponent } from './VitalsMonitoringBGTableCellComponent/VitalsMonitoringBGTableCell';
import { BgMealTypeEnum } from '../../../../../../uc-api-sdk';

export type CellClick = (day: DayOfWeekEnum, mealType: BgMealTypeEnum, beforeMeal: boolean) => void;

const width = '11%';

const createBGColumns = (title: string, key: Exclude<BgMealTypeEnum, 'BEDTIME' | 'OVERNIGHT'>, onClick: CellClick) => (
  (): ColumnType<VitalsMonitoringBGTableData> => ({
    title,
    // This is because antd doesn't have the correct type
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    children: [
      {
        title: 'Pre',
        key: `${key}Before`,
        render: (_value, rec) => (
          <VitalsMonitoringBGTableCellComponent
            isSelected={rec.data[key].beforeMeal}
          />
        ),
        width,
        onCell: (data) => ({
          onClick: () => onClick(data.day, key, true),
        }),
      },
      {
        title: 'Post',
        key: `${key}After`,
        render: (_value, rec) => (
          <VitalsMonitoringBGTableCellComponent
            isSelected={rec.data[key].afterMeal}
          />
        ),
        width,
        onCell: (data) => ({
          onClick: () => onClick(data.day, key, false),
        }),
      },
    ] as ColumnType<VitalsMonitoringBGTableData>[],
  })
);

export const vitalsMonitoringBGColumns = (onClick: CellClick) => ({
  day: (): ColumnType<VitalsMonitoringBGTableData> => ({
    title: 'Date',
    key: 'day',
    dataIndex: 'dayName',
  }),
  breakfast: createBGColumns('Breakfast', BgMealTypeEnum.BREAKFAST, onClick),
  lunch: createBGColumns('Lunch', BgMealTypeEnum.LUNCH, onClick),
  dinner: createBGColumns('Dinner', BgMealTypeEnum.DINNER, onClick),
  bedtime: (): ColumnType<VitalsMonitoringBGTableData> => ({
    title: 'Bed-time',
    key: 'bedtime',
    render: (_value, rec) => (
      <VitalsMonitoringBGTableCellComponent
        isSelected={rec.data.BEDTIME}
      />
    ),
    width,
    onCell: (data) => ({
      onClick: () => onClick(data.day, BgMealTypeEnum.BEDTIME, false),
    }),
  }),
  overnight: (): ColumnType<VitalsMonitoringBGTableData> => ({
    title: 'Over-night',
    key: 'overnight',
    render: (_value, rec) => (
      <VitalsMonitoringBGTableCellComponent
        isSelected={rec.data.OVERNIGHT}
      />
    ),
    width,
    onCell: (data) => ({
      onClick: () => onClick(data.day, BgMealTypeEnum.OVERNIGHT, false),
    }),
  }),
});
