import React, { FC } from 'react';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import './BOTextComponent.scss';

export interface BOTextComponentProps{
  bloodOxygen: number;
  perfusionIndex: number | undefined | null;
}

export const BOTextComponent: FC<BOTextComponentProps> = ({
  bloodOxygen,
  perfusionIndex,
}) => (
  <div>
    <div className="bo-text-component">
      {bloodOxygen}
      %
    </div>
    <div>
      PI:
      {' '}
      <DisplayOrEmptyComponent value={perfusionIndex} emptyType="mini" />
      %
    </div>
  </div>
);
