import { NonNullUndefined } from '../../../types/common';
import { Measurement } from '../../../uc-api-sdk';
import { VitalHelper } from './VitalHelper';
import { HSHelper } from './HSHelper';
import { TimezoneService } from '../../../helpers/timezone/timezoneService';
import { HSChartData } from '../component/HSChartComponent/HSChartComponent';

export class HSResultHelper {
  public static toDataTable(value: NonNullUndefined<Measurement>): HSChartData {
    return {
      id: value.id,
      date: TimezoneService.calcDateTimeDayjs(value.date, value.timezone),
      resultSource: VitalHelper.getResultSource(value.resultSource),
      weightInKg: value.body_weight?.value || 0,
      weightInLbs: value.body_weight?.value ? HSHelper.kgToLbs(value.body_weight.value) : 0,
      weightVariation: value.body_weight_change?.value
        ? HSHelper.kgToLbs(value.body_weight_change.value) : 0,
      patientNote: value.user_notes || '--',
    };
  }
}
