import { useMemo } from 'react';
import { Icons } from '../../../../../icons/Icons';
import { MessageVitalDurationEnum, VitalDurationMessagePayload } from '../../../../../services/CHSServices/types/data';
import { MeasurementResultTypeEnum, VitalEnumType } from '../../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { DisplayDateComponent } from '../../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { VitalsEnumTypeComponent } from '../../../../../enumComponent/VitalsEnumTypeComponent/VitalsEnumTypeComponent';

import './MessageVitalDurationComponent.scss';

export interface MessageVitalDurationComponentProps {
  vitalName: MeasurementResultTypeEnum;
  text?: string;
  dateFrom?: VitalDurationMessagePayload['dateFrom'];
  dateTo?: VitalDurationMessagePayload['dateTo'];
  duration?: VitalDurationMessagePayload['duration'];
}

export const MessageVitalDurationComponent = ({
  vitalName,
  text,
  dateFrom,
  dateTo,
  duration,
}: MessageVitalDurationComponentProps) => {
  const VitalIcon = useMemo(() => {
    switch (vitalName) {
      case MeasurementResultTypeEnum.BG:
        return <Icons.BloodDrop2Tone size={30} asSVG />;
      case MeasurementResultTypeEnum.BP:
        return <Icons.Heart2Tone size={30} asSVG />;
      case MeasurementResultTypeEnum.HS:
        return ''; // App doesn't support yet
      case MeasurementResultTypeEnum.TM:
        return ''; // App doesn't support yet
      case MeasurementResultTypeEnum.PO:
        return ''; // App doesn't support yet
      default:
        return null;
    }
  }, [vitalName]);

  const {
    durationText,
    DurationCalendarIcon,
  } = useMemo(() => {
    switch (duration) {
      case MessageVitalDurationEnum.TwoWeek:
        return {
          durationText: '2-Week',
          DurationCalendarIcon: <Icons.TwoWeekCalendar hexColor="#FCA642" size={30} />,
        };
      default:
        return {};
    }
  }, [duration]);

  return (
    <div>
      <div className="message-vital-duration message-item__message-content message-item__message-highlight">
        <div className="flex justify-start">
          <div className="message-vital-duration__left-pane">
            <div>
              {VitalIcon}
            </div>
            <div>
              {DurationCalendarIcon}
            </div>
          </div>
          <div className="message-vital-duration__right-pane">
            <div className="message-vital-duration__title">
              <div className="message-vital-duration__title__label">
                {durationText}
                {' '}
                <DisplayOrEmptyComponent
                  value={(
                    <VitalsEnumTypeComponent value={vitalName as unknown as VitalEnumType} />
                  )}
                />
                {' '}
                Data
              </div>
              <div className="message-vital-duration__title__date-range">
                <DisplayDateComponent value={dateFrom} format="USA_DATE" />
                {' - '}
                <DisplayDateComponent value={dateTo} format="USA_DATE" />
              </div>
            </div>
            <div className="message-vital-duration__text">
              <DisplayOrEmptyComponent value={text} />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};
