import { FormOptions, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';

export const useActualBillableTimeForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    correctedBillableTime: {
      name: 'correctedBillableTime',
      label: 'Actual Billable time',
      required: true,
    },
    comment: {
      name: 'comment',
      label: 'Comment',
    }
  }, options);

  return {
    ...factory,
  };
};
