// @ts-nocheck
import { InputNumber } from 'antd';
import React, { FC } from 'react';
import { InputType } from '../../../../Input/types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface VitalsMonitoringOccurrenceInputComponentProps extends
  InputType<number> {

}

export const VitalsMonitoringOccurrenceInputComponent: FC<
  VitalsMonitoringOccurrenceInputComponentProps
> = ({
  value,
  onChange,
}) => (
  <InputNumber
    min={1}
    value={value}
    onChange={onChange}
    className="mw120"
  />
);
