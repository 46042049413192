import React, { ReactNode } from 'react';
import { CloseOutlined, ExclamationCircleFilled, WarningFilled } from '@ant-design/icons';
import { Space } from 'antd';
import classNames from 'classnames';
import './WarningComponent.scss';

export interface WarningComponentProps {
  text: ReactNode;
  textColor?: 'danger' | 'alert' | 'none';
  showIcon?: boolean;
  size?: 'small' | 'medium' | 'large';
  className?: string;
  iconShape?: 'triangle' | 'circle';
  showCloseIcon?: boolean;
  onClose?: () => void;
}

export const WarningComponent = ({
  text,
  textColor = 'danger',
  showIcon = true,
  size = 'small',
  className = '',
  iconShape = 'triangle',
  showCloseIcon = false,
  onClose,
}: WarningComponentProps) => {
  const handleClose = () => {
    onClose?.();
  };

  return (
    <Space
      className={classNames({
        'warning-component': true,
        [size]: !!size,
        [className]: !!className,
      })}
      align={iconShape === 'triangle' ? 'center' : 'start'}
    >
      {showIcon && (iconShape === 'triangle' ? <WarningFilled /> : <ExclamationCircleFilled />)}
      <div
        className={classNames({
          'text-alert': textColor === 'alert',
          'text-danger': textColor === 'danger'
        })}
      >
        {text}
      </div>
      {showCloseIcon && (
        <CloseOutlined
          className="close-icon"
          onClick={handleClose}
        />
      )}
    </Space>
  );
};
