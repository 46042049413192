import { Dayjs } from 'dayjs';
import { Moment } from 'moment';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import './MeasurementDateComponent.scss';

export interface MeasurementDateComponentProps {
  value?: number | string | Dayjs | Moment | null;
}
export const MeasurementDateComponent = ({
  value,
}:MeasurementDateComponentProps) => (
  <>
    <DisplayDateComponent value={value} format="MONTH_DAY_DATE" />
    <div className="display-date-year">
      <DisplayDateComponent value={value} format="YEAR_DATE" />
    </div>
  </>
);
