import { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { Nullable } from '../../../uc-api-sdk';
import { MessageHistory } from './data';

export interface CHSRequestArguments<T> {
  params?: T;
  headers: AxiosRequestHeaders;
}

export type CHSRequestFunction<Params, ResponseData, Config = unknown> = (
  args: CHSRequestArguments<Params>,
) => Promise<AxiosResponse<ResponseData, Config>>

export interface NotificationGroupParams {
  authKey: string;
}

export interface NotificationGroupResponseData {
  data: Nullable<{
    group: string;
    channels: string[];
  }>;
  success: boolean;
  error?: string;
}

export interface PaginatedChannelsParams {
  pageNumber: string; // (current time in ms) * 1e4
  fromTimestamp: string; // (current time in ms) * 1e4
  unread: boolean;
  pageSize?: string;
  teamIds?: string[];
}

export interface PaginatedChannelsResponseData {
  Channels: string[]; // string in <teamObjectId>-<patientOnjectId> format
  NextFromTimestamp: string;
  NextPageNumber: string;
}

export interface GetChannelHistoryParams {
  count: number;
  origin?: 'new';
  ignoreACK?: boolean;
  patientIds: string[];
  fromTimestamp?: string;
}

export type GetChannelHistoryResponseData = MessageHistory[];

export interface TagMessageParams {
  messageId: string;
  taggedBy: string;
  taggedTo: string;
}

export interface TagMessageResponseData {
  success: boolean;
  error: Nullable<string>;
}

export interface MarkReadTagMessageParams {
  messageId: string;
}

export interface MarkReadTagMessageResponseData {
  success: boolean;
  error: Nullable<string>;
}

export interface MarkUnreadTagMessageParams {
  messageId: string;
}

export interface MarkUnreadTagMessageResponseData {
  success: boolean;
  error: Nullable<string>;
}

export interface PublishMessageParams {
  channel: string; // patientId
  payload: string;
}

export interface PublishMessageResponseData {
  success: boolean;
  error: Nullable<string>;
}

export interface InitChannelParams {
  patientId: string;
  userId: string;
}

export interface InitChannelResponseData {
  freshSubscription: boolean;
  initChannel: boolean;
}

export enum MessageEventType {
  TEXT_NOTIFICATION = 'text',
  ACK_NOTIFICATION = 'ACK',
  VIDEO_NOTIFICATION = 'PATIENT_ENTER_MEETING',
  CONSENT_NOTIFICATION = 'consent',
  VISIT_REMINDER = 'VISIT_REMINDER',
}

export interface SubscriptionMessagePayload {
  type: MessageEventType;
  publisher: string;
  patient: string;
}

export interface SubscriptionMessageVisitPayload extends SubscriptionMessagePayload {
  clinicEventId?: string;
}
