import { ColumnType } from 'antd/lib/table';
import _, { map } from 'lodash';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { LabResultTableColumnActionComponent } from './LabResultTableColumnActionComponent';
import {
  LabResultClass, LabResultItem, ReferenceLevel, ReferenceLevelEnum
} from '../../../../uc-api-sdk';
import { A1cTemplateFlagComponent } from '../LabResultDetailTableComponent/A1cTemplateFlagComponent';
import { ReferenceLevelEnumComponent } from '../ReferenceLevelEnumComponent';
import { LabResultCollectedDateComponent } from '../LabResultCollectedDateComponent/LabResultCollectedDateComponent';

export const labResultColumns = () => ({
  dateCollected: (): ColumnType<LabResultClass> => ({
    title: 'Test Date',
    render: (v: string, l: LabResultClass) => (
      <LabResultCollectedDateComponent labResult={l} />
    ),
    width: '20%',
    sorter: true,
    defaultSortOrder: 'descend',
  }),
  result: (): ColumnType<LabResultClass> => ({
    title: 'Result',
    dataIndex: 'result',
    key: 'result',
    width: '20%',
    render: (v: string, l: LabResultClass) => {
      const labResultItem = _.first(l.results) as LabResultItem;
      const { value, unit } = labResultItem;
      return (
        <>
          <DisplayOrEmptyComponent value={value} />
          {' '}
          <DisplayOrEmptyComponent value={unit} />
        </>
      );
    },
  }),
  flag: (ref?: ReferenceLevel[]): ColumnType<LabResultClass> => ({
    title: 'Flag',
    dataIndex: 'flag',
    key: 'flag',
    width: '20%',
    render: (v: string, l: LabResultClass) => {
      const labResultItem = _.first(l.results) as LabResultItem;
      const { value } = labResultItem;
      return (
        value
          ? (
            <A1cTemplateFlagComponent
              value={Number(value)}
              referenceLevels={ref}
            />
          )
          : <DisplayOrEmptyComponent />
      );
    },
    filters: map(ReferenceLevelEnum, (value) => ({
      text: (<ReferenceLevelEnumComponent value={value} />),
      value,
    })),
  }),
  inHouseTest: (): ColumnType<LabResultClass> => ({
    title: 'In-house test',
    dataIndex: 'inHouseTest',
    key: 'inHouseTest',
    render: (v: boolean, l: LabResultClass) => {
      const labResultItem = _.first(l.results) as LabResultItem;
      const { isInHouse } = labResultItem;
      return (<DisplayOrEmptyComponent value={isInHouse ? 'Yes' : 'No'} />);
    },
  }),
  action: () => ({
    title: '',
    dataIndex: 'id',
    key: 'id',
    width: '5%',
    render: (id: string, l: LabResultClass) => (
      <LabResultTableColumnActionComponent labResult={l} />
    ),
  }),
});
