import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import React from 'react';

import './PrevNextButtonsComponent.scss';

export interface PrevNextButtonsComponentProps {
  onGoPrev?: () => void;
  onGoNext?: () => void;
}

export const PrevNextButtonsComponent = ({
  onGoPrev,
  onGoNext,
}: PrevNextButtonsComponentProps) => (
  <Space className="prev-next-buttons-component">
    <Button className="no-style-btn prev-btn" onClick={onGoPrev}>
      <LeftOutlined />
    </Button>
    <Button className="no-style-btn next-btn" onClick={onGoNext}>
      <RightOutlined />
    </Button>
  </Space>
);
