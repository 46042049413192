/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Drawer } from 'antd';
import { useState } from 'react';
import './MessagePopupComponent.scss';
import { useUpdateEffect } from 'usehooks-ts';
import { MessageHistoryContainer } from '../../containers/MessageHistoryContainer/MessageHistoryContainer';
import { useMessagePatientContext } from '../../../../contexts/MessageContext/MessagePatientContext';
import { MessageInputContainer } from '../../containers/MessageInputContainer/MessageInputContainer';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import { FixedChildComponent } from '../../../../uiComponent/FixedComponent/FixedChildComponent';
import { MessageUnreadButtonComponent } from '../MessageUnreadButtonComponent/MessageUnreadButtonComponent';
import { useMessagePopupContext } from '../../../../contexts/MessageContext/MessagePopupContext';
import { OldAppVersionNoteInMsgComponent } from '../../../patient/component/OldAppVersionNoteInMsgComponent/OldAppVersionNoteInMsgComponent';

export interface MessagePopupComponentProps {
  onUnread?: () => void;
  alwaysFetchHistory?: boolean; // to always fetch history when chat is open
}

export const MessagePopupComponent = ({
  onUnread,
  alwaysFetchHistory,
}: MessagePopupComponentProps) => {
  const {
    isChatOpen,
    handleCloseChat,
  } = useMessagePopupContext() || {};

  const {
    patientChannel,
    getPatientChannelHistoryFirstTime,
    isLoadingPatientChannel,
  } = useMessagePatientContext();

  const [drawerHeight, setDrawerHeight] = useState(465);
  const isChannelUnread = !!patientChannel?.isUnread;
  const historyLength = patientChannel?.messages?.length || 0;

  const handleOnUnread = () => {
    onUnread?.();
    handleCloseChat?.();
  };

  useUpdateEffect(() => {
    if (isChatOpen && (alwaysFetchHistory || !patientChannel)) {
      getPatientChannelHistoryFirstTime();
    }
  }, [patientChannel, isChatOpen, alwaysFetchHistory]);

  const onMouseMove = (e: MouseEvent) => {
    const offsetTop = window.innerHeight - e.clientY;
    const minHeight = 465;
    if (offsetTop > minHeight && offsetTop < window.innerHeight) {
      setDrawerHeight(offsetTop);
    }
  };

  const onMouseUp = () => {
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);
  };

  const onMouseDown = () => {
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  };

  return (
    <div>
      <Drawer
        className="message-popup-component"
        title={(
          <div onMouseDown={onMouseDown}>
            <div className="flex ai-c jc-sb render-custom-title">
              <span>Chat History</span>
              <span>
                {
                  (!isChannelUnread && historyLength > 0)
                    ? (
                      <MessageUnreadButtonComponent
                        className="message-popup-component__mark-btn"
                        onUnread={handleOnUnread}
                        disabled={isLoadingPatientChannel}
                      />
                    ) : null
                }
              </span>
            </div>
          </div>
        )}
        placement="bottom"
        onClose={handleCloseChat}
        open={isChatOpen}
        height={drawerHeight}
        forceRender={false}
        destroyOnClose
      >
        <FixedComponent>
          <FixedChildComponent isFixed>
            <OldAppVersionNoteInMsgComponent patientId={patientChannel?.patientId || ''} />
          </FixedChildComponent>
          <FixedChildComponent>
            <MessageHistoryContainer />
          </FixedChildComponent>
          <FixedChildComponent isFixed>
            <MessageInputContainer />
          </FixedChildComponent>
        </FixedComponent>
      </Drawer>
    </div>
  );
};
