import { Col, Row, Space } from 'antd';
import { find } from 'lodash';
import { useCallback } from 'react';
import { AccountHelper } from '../../../../helpers/account';
import { CustomTagProps } from '../../../../types/tag';
import { CalendarSourceEnum, RoleTypeEnum } from '../../../../uc-api-sdk';
import { CoWorkerDropdownComponent, CoWorkerDropdownComponentProps } from '../../../../uiComponent/CoWorkerDropdownComponent/CoWorkerDropdownComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { EmployeeSelectLabelComponent } from '../../../calendar/component/EmployeeCalendarSelectComponent/EmployeeSelectLabelComponent';
import { MiniUserComponent } from '../../../user/component/MiniUserComponent/MiniUserComponent';
import { useCreateVisitForm } from '../../hook/useCreateVisitForm';
import { ParticipantsEnum } from '../../type/participants';
import './ParticipantSelectFormItemComponent.scss';

export interface ParticipantSelectFormItemComponentProps {
  formItemInfo: Parameters<ReturnType<typeof useCreateVisitForm>['getInfo']>[0];
  text: string;
  participantRole: ParticipantsEnum;
  dropdownRoles: RoleTypeEnum[];
  assignees?: string[];
  patientClinicId?: string;
  needToFilter?: boolean;
  internalEmployeeForPatientId?: string;
  externalEmployeeForPatientId?: string;
  addCurrentUserToOption?: boolean;
  source?: CalendarSourceEnum;
}

export const ParticipantSelectFormItemComponent = ({
  formItemInfo,
  text,
  participantRole,
  dropdownRoles,
  patientClinicId = '',
  assignees = [],
  needToFilter,
  internalEmployeeForPatientId,
  externalEmployeeForPatientId,
  addCurrentUserToOption,
  source,
}: ParticipantSelectFormItemComponentProps) => {
  const formHook = useCreateVisitForm();

  const tagRender = useCallback<Exclude<CoWorkerDropdownComponentProps['tagRender'], undefined>>((props: CustomTagProps) => (
    <EmployeeSelectLabelComponent
      label={props.label}
      value={props.value}
      closable={false}
      onClose={props.onClose}
      source={source}
    />
  ), [assignees, source]);

  const fullNameRenderer = useCallback<Exclude<CoWorkerDropdownComponentProps['fullNameRenderer'], undefined>>((v) => (
    <EmployeeSelectLabelComponent
      label={AccountHelper.getFullNameWithTitle(v.profile)}
      value={v.id}
      closable={false}
      source={source}
      onClose={() => { /** do nothing */ }}
    />
  ), [source]);

  const optionRenderer = useCallback<Exclude<CoWorkerDropdownComponentProps['optionLabelRenderer'], undefined>>((v) => {
    const isAssigned = !!find(assignees, (a) => a === v.id);
    return (
      <Space>
        <MiniUserComponent
          avatar={v.profile?.avatar?.url || undefined}
          name={AccountHelper.getFullName(v.profile)}
          role={v.profile?.title || undefined}
          email={v.email || undefined}
        />
        {isAssigned && (
          <div className="text-gray-scale-3">
            (Assigned)
          </div>
        )}
      </Space>
    );
  }, [assignees, source]);

  return (
    <FormItem
      noStyle
      shouldUpdate={formHook.shouldUpdate(['patient', 'participants'])}
    >
      {({ getFieldValue }) => {
        const initialAssignee = formHook.getValue(formItemInfo, getFieldValue); // for edit visit
        return (
          formHook.shouldShowParticipantSelect(participantRole, getFieldValue) ? (
            <Row gutter={5} className="my5">
              <Col span="6">
                {text}
              </Col>
              <Col span="18">
                <FormItem
                  info={formHook.getInfo(formItemInfo)}
                  initialValue={initialAssignee || assignees.at(0)}
                  required
                  preserve={false}
                >
                  <CoWorkerDropdownComponent
                    className="coworker-dropdown no-switcher"
                    optionLabelRenderer={optionRenderer}
                    tagRender={tagRender}
                    treeNodeLabelProp="fullNameTag"
                    treeNodeFilterProp="fullName"
                    filterRoles={dropdownRoles}
                    showSearch
                    allowClear
                    fullNameRenderer={fullNameRenderer}
                    clinicIdList={patientClinicId ? [patientClinicId] : []}
                    needToFilter={needToFilter}
                    internalEmployeeForPatientId={internalEmployeeForPatientId}
                    externalEmployeeForPatientId={externalEmployeeForPatientId}
                    addCurrentUserToOption={addCurrentUserToOption}
                  />
                </FormItem>
              </Col>
            </Row>
          ) : null
        );
      }}
    </FormItem>
  );
};
