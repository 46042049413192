import { BillableComponentEnum, useBillableEventTrackerComp } from '../../hook/useBillableEventTracker/useBillableEventTrackerComp';

export type {
  BillableComponentEnum
};

export interface BillableTimeContainerProps {
  patientId: string | undefined;
  comp: BillableComponentEnum | undefined;
}

export const BillableTimeContainer = ({
  patientId,
  comp,
}: BillableTimeContainerProps) => {
  useBillableEventTrackerComp({
    patientId,
    comp,
  });

  return null;
};
