import { PatientCommonCardTitleComponent } from '../../../patient/component/PatientCommonCardTitleComponent/PatientCommonCardTitleComponent';
import { BillingHistoryContainer } from '../../container/BillingContainer/BillingHistoryContainer';
import { BillingTimeContainer } from '../../container/BillingContainer/BillingTimeContainer';

export interface BillingComponentProps{
  patientId?: string;
}

export const BillingComponent = ({
  patientId,
}: BillingComponentProps) => (
  <>
    <PatientCommonCardTitleComponent className="mb20">
      Billing Eligibility History
    </PatientCommonCardTitleComponent>
    <BillingHistoryContainer patientId={patientId} />
    <div className="mt40">
      <BillingTimeContainer patientId={patientId} />
    </div>
  </>
);
