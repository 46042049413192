import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { DeviceDeliveryMethodRadioComponent } from '../../../enrollment/component/DeviceDeliveryMethodRadioComponent/DeviceDeliveryMethodRadioComponent';
import { useOrderFormHook } from '../../hook/useOrderFormHook';

export const OrderDeliveryMethodFormItemComponent = () => {
  const orderForm = useOrderFormHook();
  return (
    <FormItem
      noStyle
      shouldUpdate
    >
      {
        ({ getFieldsValue }) => {
          const isDisabled = !orderForm.isAnyOrderItemChecked(getFieldsValue);
          return (
            <>
              <TextComponent
                size="large"
                bold
              >
                How will patient get device?
              </TextComponent>
              <FormItem
                info={orderForm.getInfo('deliveryMethod')}
                required
              >
                <DeviceDeliveryMethodRadioComponent
                  direction="horizontal"
                  disabled={isDisabled}
                />
              </FormItem>
            </>
          );
        }
      }
    </FormItem>
  );
};
