import { useMemo } from 'react';
import { isEmpty, map } from 'lodash';
import { DeviceAssignment, Nullable } from '../../../../uc-api-sdk';
import { useGetDeviceInfo } from '../../../device/hook/useGetDeviceInfo';
import { DeviceModelEnumComponent } from '../../../../enumComponent/DeviceModelEnumComponent/DeviceModelEnumComponent';

export interface PatientDevicesComponentProps {
  devices?: Nullable<DeviceAssignment[]>;
}
export const PatientDevicesComponent = ({
  devices,
}: PatientDevicesComponentProps) => {
  const {
    getAllMostRecentDevices,
  } = useGetDeviceInfo({ deviceList: devices });
  const mostRecentDevices = useMemo(() => (
    getAllMostRecentDevices()
  ), [devices, getAllMostRecentDevices]);

  if (isEmpty(mostRecentDevices)) {
    return <span>N/A</span>;
  }
  const totalLength = mostRecentDevices.length;
  return (
    <span>
      { map(mostRecentDevices, (item, index) => (
        <span key={index}>
          <DeviceModelEnumComponent
            value={item?.deviceModel}
            type="short"
          />
          {index !== totalLength - 1 ? ', ' : ''}
        </span>
      ))}
    </span>
  );
};
