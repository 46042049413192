export enum CPTCodes {
  G0506 = 'G0506',
  CPT99490 = '99490',
  CPT99439 = '99439',
  CPT99487 = '99487',
  CPT99489 = '99489',
  CPT99453 = '99453',
  CPT99454 = '99454',
  CPT99457 = '99457',
  CPT99458 = '99458',
}
