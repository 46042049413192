import { Col, Row } from 'antd';
import { DiseaseHistoryItem } from '../../../../uc-api-sdk';
import YesNoComponent from '../../../../uiComponent/YesNoComponent/YesNoComponent';
import { DiseaseHistorySpecialistDisplayComponent } from './DiseaseHistorySpecialistDisplayComponent';
import YearOfDiagnosisComponent from './YearOfDiagnosisComponent';

export interface DiseaseHistoryTemplateGeneralDisplayComponentProps {
  diseaseHistory: DiseaseHistoryItem;
}

export const DiseaseHistoryTemplateGeneralDisplayComponent = ({
  diseaseHistory
}: DiseaseHistoryTemplateGeneralDisplayComponentProps) => (
  <Row
    gutter={70}
    className="disease-history-template-row disease-history-display"
  >
    <Col>
      <YearOfDiagnosisComponent diseaseHistory={diseaseHistory} />
    </Col>
    <Col>
      <div className="disease-history-item">
        <span className="label">Taking Medication?</span>
        <YesNoComponent
          yes={diseaseHistory.isTakingMedication}
          showIcon={false}
        />
      </div>
      <DiseaseHistorySpecialistDisplayComponent
        diseaseHistory={diseaseHistory}
      />
    </Col>
  </Row>
);
