import dayjs, { Dayjs } from 'dayjs';
import { InfoCircleTwoTone } from '@ant-design/icons';
import classnames from 'classnames';

export interface LastMeasurementComponentProps {
  value?: Dayjs;
  showNoMeasurement?: boolean;
  showWarning?: boolean;
  fontColor?: string;
}

// value has to be TimezoneService.calcDateTimeDayjs(latestMeasurementDate);
// gonna compare the latestMeasurementDate with the current date(local timezone)
export const LastMeasurementDiffDays = (value?: Dayjs) => {
  if (value) {
    const diff = dayjs()
      .startOf('day')
      .diff(value?.startOf('day'), 'days');
    return diff;
  }
  return undefined;
};

export const LastMeasurementComponent = ({
  value,
  showNoMeasurement = false,
  showWarning = true,
  fontColor = 'text-gray-scale-2',
}: LastMeasurementComponentProps) => {
  if (!showNoMeasurement && !value) return null;
  const diff = LastMeasurementDiffDays(value);

  const renderDaysAgo = () => {
    if (diff === 0) {
      return 'today';
    }
    if (diff === 1) {
      return `${diff} day ago`;
    }
    return `${diff} days ago`;
  };

  return (
    <div className="flex ai-c">
      {showWarning && diff !== undefined && diff >= 14 && (
        <InfoCircleTwoTone twoToneColor="#da6453" />
      )}
      <div
        className={classnames({
          [fontColor]: true,
        })}
      >
        {showNoMeasurement && !value ? 'No measurement' : `Last measurement: ${renderDaysAgo()}`}
      </div>
    </div>
  );
};
