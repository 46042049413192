import dayjs, { Dayjs } from 'dayjs';

export const getDaysToExpiration = (
  expirationDate?: Dayjs,
  exact?: boolean, // to use exact time in diff comparison
) => {
  if (!expirationDate) {
    return undefined;
  }
  let expirationDateCopy = dayjs(expirationDate);
  let currentDateTime = dayjs();
  if (!exact) {
    expirationDateCopy = expirationDate.endOf('day');
    currentDateTime = currentDateTime.endOf('day');
  }
  const daysToExpiration = expirationDateCopy.diff(currentDateTime, 'day');

  // < 0: expiration date is in the past => expired
  // 0: today is the expiration date
  // > 0: expiration date is in the future
  return daysToExpiration;
};
