/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  ReactNode,
  useContext
} from 'react';
import { useLoggedInUserFromContext } from '../loggedInUserContext/LoggedInUserContext';
import { defaultVisibilityProcessor } from './VisibilityProcessor';
import { useDeepCompareMemo } from '../../hooks/useDeepCompareEffect';

export type VisibilityProcessorReturnType = ReturnType<typeof defaultVisibilityProcessor>;

export interface VisibilityProcessorArgs {
  loggedInUserContext: ReturnType<typeof useLoggedInUserFromContext>;
}

export interface VisibilityContextProps<
  T extends Record<string, any>
> {
  children: ReactNode;
  visibilityProcessor?: (arg: VisibilityProcessorArgs) => T;
}

export const VisibilityContext = createContext<
  Record<string, any> | undefined
>(undefined);

// eslint-disable-next-line max-len
export const useVisibility = <T extends Record<string, any> = VisibilityProcessorReturnType>() => {
  const contextValue = useContext(VisibilityContext);
  return contextValue as T;
};

/**
 * This context provides info for whether a component should be visible or not based on the
 * logged in user information, and  is dependent on the Context
 */
export const VisibilityContextProvider = <T extends { [key: string]: boolean }>({
  children,
  visibilityProcessor: valueProcessor,
}: VisibilityContextProps<T>) => {
  const loggedInUserContext = useLoggedInUserFromContext();

  const defaultVisibilityProcessorValue = defaultVisibilityProcessor({
    loggedInUserContext
  });

  const value = useDeepCompareMemo(() => {
    const arg = { loggedInUserContext };
    return {
      ...defaultVisibilityProcessorValue,
      ...(valueProcessor?.(arg) || {}),
    };
  }, [
    loggedInUserContext,
    valueProcessor,
    defaultVisibilityProcessorValue,
  ]);

  return (
    <VisibilityContext.Provider value={value}>
      {children}
    </VisibilityContext.Provider>
  );
};
