import React, { FC } from 'react';
import { Measurement } from '../../../../uc-api-sdk';
import { useMeasurementResultToTablePrint } from '../../hook/useMeasurementResultToTablePrint/useMeasurementResultToTablePrint';
import { NonNullUndefined } from '../../../../types/common';
import { TimeOfDayTablePrintComponent } from '../TimeOfDayTablePrintComponent/TimeOfDayTablePrintComponent';
import { BOTableCellComponent } from '../BOTableComponent/BOTableCellComponent';
import { BOMeasurementService } from '../../helper/BOMeasurementService';

export interface BOTableDataProcessorComponentProps {
  data: Measurement[];
}

export const BOTablePrintDataProcessorComponent: FC<BOTableDataProcessorComponentProps> = ({
  data,
}) => {
  const processedData = useMeasurementResultToTablePrint(
    data as NonNullUndefined<Measurement>[],
    (curr) => new BOMeasurementService(curr),
  );

  return (
    <TimeOfDayTablePrintComponent
      data={processedData}
      render={(v) => <BOTableCellComponent data={[v]} />}
    />
  );
};
