export const scrollToFirstError = (
  selector?: string,
  scrollOptions = { behavior: 'smooth' } as ScrollIntoViewOptions,
) => {
  if (!selector) return;
  const element = document.querySelectorAll(selector);
  const firstErrorEl = element?.[0];
  if (firstErrorEl) {
    firstErrorEl.setAttribute('style', 'scroll-margin-top: 45px');
    firstErrorEl.scrollIntoView(scrollOptions);
  }
};
