import { useState } from 'react';
import { SiderComponent } from '../../../../uiComponent/SiderComponent/SiderComponent';
import { PATIENT_TABLE_TABS } from '../../constants';
import { EnrolledPatientsTableContainer } from '../../../patients/container/EnrolledPatientsTableContainer/EnrolledPatientsTableContainer';
import { UnenrolledPatientsTableContainer } from '../../../patients/container/UnenrolledPatientsTableContainer/UnenrolledPatientsTableContainer';
import { AllPatientsTableContainer } from '../../../patients/container/AllPatientsTableContainer/AllPatientsTableContainer';
import { PatientTablesSiderContainer, PatientTablesSiderContainerProps } from '../../container/PatientTablesSiderContainer/PatientTablesSiderContainer';
import { PatientTablesSiderHeaderComponent } from '../PatientTablesSiderHeaderComponent/PatientTablesSiderHeaderComponent';
import { WatchedPatientsContainer } from '../../../patients/container/WatchedPatientsContainer/WatchedPatientsContainer';
import { MyAssignedPatientsTableContainer } from '../../../patients/container/MyAssignedPatientsTableContainer/MyAssignedPatientsTableContainer';
import { StorageKeyEnum, useSessionStorage } from '../../../../hooks';
import './PatientTablesComponent.scss';
import { MixpanelProvider } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEventsParents } from '../../../../contexts/MixpanelContext/MixpanelEvents';

export const PatientTablesComponent = () => {
  const [
    lastSelectedTable,
    setLastSelectedTable,
  ] = useSessionStorage<PATIENT_TABLE_TABS>(StorageKeyEnum.PATIENTS_TABLE_LAST_SELECTED_TABLE);
  const [table, setTable] = useState(lastSelectedTable || PATIENT_TABLE_TABS.ASSIGNED);

  const renderComponent = () => {
    switch (table) {
      case (PATIENT_TABLE_TABS.ENROLLED_PATIENTS):
        return <EnrolledPatientsTableContainer />;
      case (PATIENT_TABLE_TABS.UNENROLLED_PATIENTS):
        return <UnenrolledPatientsTableContainer />;
      case (PATIENT_TABLE_TABS.ALL_PATIENTS):
        return <AllPatientsTableContainer />;
      case (PATIENT_TABLE_TABS.WATCHLIST):
        return <WatchedPatientsContainer />;
      case (PATIENT_TABLE_TABS.ASSIGNED):
        return <MyAssignedPatientsTableContainer />;
      default:
        return <EnrolledPatientsTableContainer />;
    }
  };

  const handleSelectTable: PatientTablesSiderContainerProps['onClick'] = (table) => {
    setLastSelectedTable(table);
    setTable(table);
  };

  return (
    <MixpanelProvider context={MixpanelEventsParents.PatientList}>
      <div className="patientTables flex h100">
        <div className="tableSider">
          <SiderComponent
            type="primary"
            header={<PatientTablesSiderHeaderComponent />}
            content={(
              <PatientTablesSiderContainer
                currentTab={table}
                onClick={handleSelectTable}
              />
            )}
          />
        </div>
        <div className="patientTableWrapper">
          {renderComponent()}
        </div>
      </div>
    </MixpanelProvider>
  );
};

export default PatientTablesComponent;
