import { Tabs, TabsProps } from 'antd';
import { map, orderBy } from 'lodash';
import { FC, useMemo, useState } from 'react';
import { FoodLogTabContentContainer } from '../../container/FoodLogTabContentContainer/FoodLogTabContentContainer';
import { FoodLogTabLabelContainer } from '../../container/FoodLogTabLabelContainer/FoodLogTabLabelContainer';
import { FoodLog } from '../../type';

export interface FoodLogTabComponentProps {
  data?: FoodLog[];
  onCancel?: () => void;
}

export const FoodLogTabComponent: FC<FoodLogTabComponentProps> = ({
  data,
  onCancel,
}) => {
  const [activeKey, setActiveKey] = useState('0');
  const [prevKey, setPrevKey] = useState('');

  // go to the next tab or cancel
  const handleGoNextTab = () => {
    setPrevKey(activeKey);
    setActiveKey((v) => {
      const i = +v;
      if (i < (data?.length || 0) - 1) {
        return (i + 1).toString();
      }
      onCancel?.();
      return v;
    });
  };

  const tabs = useMemo(() => {
    const sortedData = orderBy(data, (v) => v.uploadedAt, 'desc');
    const result: TabsProps['items'] = map(sortedData, (v, i) => ({
      label: (
        <FoodLogTabLabelContainer
          prevKey={prevKey}
          data={v}
          index={i}
        />
      ),
      key: i.toString(),
      children: (
        <FoodLogTabContentContainer
          data={v}
          onCancel={onCancel}
          onSubmit={handleGoNextTab}
          index={i.toString()}
          activeKey={activeKey}
        />
      ),
    }
    ));
    return result;
  }, [activeKey]);

  return (
    <Tabs
      className="FoodLogTab"
      activeKey={activeKey}
      onChange={setActiveKey}
      items={tabs}
    />
  );
};
