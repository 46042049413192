import { find, isEqual, uniq } from 'lodash';
import dayjs from 'dayjs';
import { Modal } from 'antd';
import { CalendarComponent, CalendarComponentProps } from '../../component/CalendarComponent/CalendarComponent';
import { FromDateToDateDayjs } from '../../../../types/date';
import {
  CalendarSourceEnum,
  ClinicEvent,
  VisitTypeEnum,
  useClinicEventSearch,
  useClinicEventUpdate,
} from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { useCalendarEventProcessor } from '../../hook/useCalendarEventProcessor';
import { useCalendarFilter } from '../../hook/useCalendarFilter';
import { useCalendarContext } from '../../context/CalendarContext/CalendarContext';
import { useUpdate, useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { CalendarClinicViewFilterValue } from '../../hook/useCalendarHelper';
import { useDeepCompareEffect } from '../../../../hooks/useDeepCompareEffect';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { MMM_DD_YYYY_HH_MM_A } from '../../../../constants/timeFormat';

export interface CalendarContainerProps extends
  FromDateToDateDayjs,
  CalendarComponentProps {
  employeeIds?: string[];
  clinicIds?: string[];
  visitTypes?: VisitTypeEnum[];
  showMyGoogleEvents: boolean;
  showMyOtherEvents: boolean;
  clinicViewFilters?: CalendarClinicViewFilterValue;
  defaultStartingHourView?: number | null;
}

export const CalendarContainer = ({
  date,
  fromDate,
  toDate,
  onNavigate,
  view,
  onView,
  onSelect,
  onSelectNew,
  onEventDrop,
  selectedEvent,
  employeeIds,
  onPopoverClose,
  extra,
  clinicIds,
  visitTypes,
  showMyGoogleEvents,
  showMyOtherEvents,
  clinicViewFilters,
  defaultStartingHourView,
}: CalendarContainerProps) => {
  const { eventId, checkOwnPatients } = useCalendarContext();
  const editVisit = useClinicEventUpdate({});
  const updateVisitsAndEventsTable = useUpdate('UPDATE_VISITS_AND_EVENTS_TABLE');
  const filters = useCalendarFilter({
    myVisitTypes: visitTypes,
    showMyGoogleEvents,
    showMyOtherEvents,
    fromDate,
    toDate,
  });

  const parsedClinicViewFilters = useCalendarFilter({
    myVisitTypes: clinicViewFilters?.visitType,
    showMyOtherEvents: clinicViewFilters?.otherEvents,
    fromDate,
    toDate,
  });

  const getCalendarFilters = () => {
    if (!clinicViewFilters?.isActive) {
      return {
        participantsIn: clinicIds ? undefined : {
          in: uniq(employeeIds),
        },
        clinicIdIn: clinicIds ? {
          in: clinicIds,
        } : undefined,
        myVisitTypeIn: clinicIds ? undefined : filters.myVisitTypeIn,
        myCalendarSourceIn: clinicIds ? undefined : filters.myCalendarSourceIn,
        calendarSourceIn: clinicIds
          ? { in: [CalendarSourceEnum.UC_VISIT] }
          : undefined,
        startTimeRange: filters.startTimeRange,
      };
    }

    return {
      clinicIdIn: clinicViewFilters.clinicId
        ? { in: [clinicViewFilters.clinicId] }
        : undefined,
      visitTypeIn: clinicViewFilters.visitType
        ? { in: clinicViewFilters.visitType }
        : undefined,
      calendarSourceIn: parsedClinicViewFilters.myCalendarSourceIn?.in
        ? { in: parsedClinicViewFilters.myCalendarSourceIn.in }
        : undefined,
      startTimeRange: parsedClinicViewFilters.startTimeRange,
      checkOwnPatients,
    };
  };

  const calendarInfo = useClinicEventSearch({
    options: { sendOnMount: true },
    params: {
      filter: getCalendarFilters(),
    },
    // TODO remove in kartana
    // @ts-ignore
    pageInfo: {
      pagination: false,
    }
  });

  const { processedEvents } = useCalendarEventProcessor(
    calendarInfo.data?.data?.content,
    {
      isClinicView: clinicViewFilters?.isActive,
    },
  );
  useUpdateListener('VIDEO_VISIT_STATUS_UPDATED', calendarInfo.refetch);
  useUpdateListener('VIDEO_VISIT_ENDED', calendarInfo.refetch);

  // This is to show select the event when the events are fetched and the
  // page has a selected event in the query string
  useDeepCompareEffect(() => {
    const event = find(processedEvents, (v) => (v.id === eventId));
    if (event && !isEqual(event?.info, selectedEvent?.info)) {
      onSelect?.(event);
    }
  }, [eventId, processedEvents, selectedEvent]);

  const handleEventDrop: CalendarComponentProps['onEventDrop'] = async (data) => {
    const visit = data.event?.info as ClinicEvent;
    const id = visit?.id;

    if (selectedEvent) {
      onEventDrop?.(data);
      return;
    }

    const move = () => {
      if (!id) return;
      const res = editVisit.send({
        params: {
          id,
          document: {
            ...visit,
            startTime: dayjs(data.start).toISOString().replace('Z', ''),
            endTime: dayjs(data.end).toISOString().replace('Z', ''),
          },
        },
      });
      ApiRequestHelper.tryCatch(res, {
        success: 'Event moved successfully!',
        error: 'Failed to move event!',
        onSuccess: () => {
          updateVisitsAndEventsTable.updateValue();
          calendarInfo.refetch();
        }
      });
    };

    const handleVisitMove = () => {
      Modal.confirm({
        content: (
          <p>
            Are you sure you want to move this event
            from
            {' '}
            <b><DisplayDateComponent format="MMM_DD_YYYY_HH_MM_A" value={visit.startTime} /></b>
            {' '}
            to
            {' '}
            <b>{dayjs(data.start).format(MMM_DD_YYYY_HH_MM_A)}</b>
            ?
          </p>
        ),
        onOk: move,
      });
    };

    const handleGCaltMove = () => {
      Modal.warning({
        content: 'You cannot move Google calendar events!',
      });
    };

    if (
      visit.calendarSource === CalendarSourceEnum.UC_VISIT
      || visit.calendarSource === CalendarSourceEnum.OTHER
    ) {
      handleVisitMove();
    } else if (visit.calendarSource === CalendarSourceEnum.GOOGLE_CALENDAR) {
      handleGCaltMove();
    }
  };

  return (
    <FetchComponent
      info={calendarInfo}
      showEmptyOverlay={false}
      showErrorOverlay={false}
      showLoadingOverlay={false}
      alwaysShowChildren
    >
      <CalendarComponent
        selectedEvent={selectedEvent}
        date={date}
        events={processedEvents}
        onPopoverClose={onPopoverClose}
        onNavigate={onNavigate}
        view={view}
        onView={onView}
        isLoading={calendarInfo.isLoading}
        onSelect={onSelect}
        onSelectNew={onSelectNew}
        onEventDrop={handleEventDrop}
        defaultStartingHourView={defaultStartingHourView}
        extra={extra}
      />
    </FetchComponent>
  );
};
