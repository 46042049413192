import { FC } from 'react';
import { useMessagePopupContext } from '../../../../contexts/MessageContext/MessagePopupContext';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents, MixpanelEventsParents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { ActionsComponentProps } from '../ActionsComponent/ActionsComponent';
import { EnrolledPatientInfoCardComponent } from '../EnrolledPatientInfoCardComponent/EnrolledPatientInfoCardComponent';
import { PatientStatusTagEnum } from '../TagsListComponent/PatientStatusTagListComponent/PatientStatusTagEnum';
import {
  UnenrolledPatientInfoCardComponent
} from '../UnenrolledPatientInfoCardComponent/UnenrolledPatientInfoCardComponent';
import './PatientInfoCardComponent.scss';

export interface PatientInfoCardComponentProps extends ActionsComponentProps {
  patientInfo: PatientInfo | undefined;
  patientStatus?: PatientStatusTagEnum[];
  hasNewMessage?: boolean;
  onClickChat?: () => void;
  onClickStartEnrollment?: () => void;
  onClickReferPatient?: () => void;
  onClickCreateMTPR?: () => void;
  onBillableTagClick?: () => void;
  onMeasurementTagClick?: () => void;
  hyperlinkToPatientProfile?: boolean;
}

export const PatientInfoCardComponent: FC<PatientInfoCardComponentProps> = ({
  patientInfo,
  patientStatus,
  hasNewMessage = false,
  onClickChat,
  onClickStartEnrollment,
  onClickCreateTask,
  onClickPrintVitalsData,
  onClickCreateMTPR,
  onClickReferPatient,
  onClickCreateVisit,
  onClickPrintCarePlan,
  onBillableTagClick,
  onMeasurementTagClick,
  hyperlinkToPatientProfile
}) => {
  const { send: sendMixpanel } = useMixpanelContext();
  const { handleOpenChat } = useMessagePopupContext() || {};
  const { send } = useBillableTimeInterventionComp();

  const sendMixpanelEvent = (event: MixpanelEvents) => {
    sendMixpanel({
      event,
      parent: MixpanelEventsParents.PatientHeader,
      patientId: patientInfo?.id,
    });
  };

  const handleOnClickChat = () => {
    if (handleOpenChat) handleOpenChat();
    else onClickChat?.();
    sendMixpanelEvent(MixpanelEvents.PatientHeaderChat);
  };

  const handleOpenPrintVitalModal = () => {
    onClickPrintVitalsData?.();
    sendMixpanelEvent(MixpanelEvents.PatientHeaderPrintVital);
  };

  const handleOpenCreateTicketDrawer = () => {
    onClickCreateTask?.();
    sendMixpanelEvent(MixpanelEvents.TasksCreateTaskButton);
  };

  const handleOpenManualMTPR = () => {
    onClickCreateMTPR?.();
    sendMixpanelEvent(MixpanelEvents.PatientHeaderCreateManualMTPR);
  };

  const handleOpenVisit = () => {
    onClickCreateVisit?.();
    sendMixpanelEvent(MixpanelEvents.PatientHeaderCreateVisit);
  };

  const handleStartEnrollment = () => {
    onClickStartEnrollment?.();
    sendMixpanelEvent(MixpanelEvents.PatientEnrollmentDrawerOpen);
  };

  const handleBillableTagClick = () => {
    onBillableTagClick?.();
    sendMixpanelEvent(MixpanelEvents.PatientHeaderBillableTag);
  };

  const handleMeasurementTagClick = () => {
    onMeasurementTagClick?.();
    sendMixpanelEvent(MixpanelEvents.PatientHeaderMeasurementTag);
  };

  const handlePrintCarePlan = () => {
    send({
      patientId: patientInfo?.id,
      component: BillableTimeInterventionComponentEnum.UpdateCarePlan,
      detail: BillableTimeInterventionDetailEnum.PrintCarePlan,
    });
    onClickPrintCarePlan?.();
  };

  return (
    <div className="patient-info-card-component-wrapper">
      {
        patientInfo?.enrolledProgramService?.isEnrolled()
          ? (
            <EnrolledPatientInfoCardComponent
              patientInfo={patientInfo}
              patientStatus={patientStatus}
              hasNewMessage={hasNewMessage}
              onClickChat={handleOnClickChat}
              onClickCreateTask={handleOpenCreateTicketDrawer}
              onClickPrintVitalsData={handleOpenPrintVitalModal}
              onClickCreateMTPR={handleOpenManualMTPR}
              onClickStartEnrollment={handleStartEnrollment}
              onClickCreateVisit={handleOpenVisit}
              onClickPrintCarePlan={handlePrintCarePlan}
              onBillableTagClick={handleBillableTagClick}
              onMeasurementTagClick={handleMeasurementTagClick}
              hyperlinkToPatientProfile={hyperlinkToPatientProfile}
            />
          )
          : (
            <UnenrolledPatientInfoCardComponent
              patientInfo={patientInfo}
              onClickStartEnrollment={handleStartEnrollment}
              onClickReferPatient={onClickReferPatient}
              onClickCreateTask={handleOpenCreateTicketDrawer}
              onClickCreateVisit={handleOpenVisit}
            />
          )
      }
    </div>
  );
};
