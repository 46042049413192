import { FC } from 'react';
import { Height, MeasurementResultTypeEnum } from '../../../../uc-api-sdk';
import { VitalFetchProps } from '../../type';
import { MeasurementType } from '../../type/MeasurementTypes';
import { HSChartDataProcessorComponent } from '../HSChartComponent/HSChartDataProcessorComponent';
import { HSSummaryTableDataProcessorComponent } from '../HSSummaryTableComponent/HSSummaryTableDataProcessorComponent';
import { HSTableDataProcessorComponent } from '../HSTableComponent/HSTableDataProcessorComponent';
import { VitalChartTableSummaryViewComponent, VitalChartTableSummaryViewComponentProps } from '../VitalChartTableSummaryViewComponent/VitalChartTableSummaryViewComponent';

export interface HSVitalComponentProps extends VitalFetchProps {
  height?: Height;
}

export const HSVitalComponent: FC<HSVitalComponentProps> = ({
  memberId,
  fromDate,
  toDate,
  height
}) => {
  const chartView: VitalChartTableSummaryViewComponentProps['chartView'] = ({ data, fromDate, toDate }) => (
    <HSChartDataProcessorComponent
      data={data?.results?.content || []}
      fromDate={fromDate}
      toDate={toDate}
    />
  );

  const tableView: VitalChartTableSummaryViewComponentProps['tableView'] = ({ data, fromDate, toDate }) => (
    <HSTableDataProcessorComponent
      data={(data?.results?.content || []) as MeasurementType[]}
      fromDate={fromDate}
      toDate={toDate}
      height={height}
    />
  );

  const summaryView: VitalChartTableSummaryViewComponentProps['summaryTableView'] = ({ data }) => (
    <HSSummaryTableDataProcessorComponent
      data={data?.stats?.hs || {}}
    />
  );

  return (
    <VitalChartTableSummaryViewComponent
      type={MeasurementResultTypeEnum.HS}
      memberId={memberId}
      title="Weight"
      unit="lbs"
      chartView={chartView}
      tableView={tableView}
      summaryTableView={summaryView}
      fromDate={fromDate}
      toDate={toDate}
    />
  );
};
