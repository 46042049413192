import { Form } from 'antd';
import { ReactNode } from 'react';
import { ChronicDiagnosesNoteComponent } from '../../../../uiComponent/ChronicDiagnosesNoteComponent/ChronicDiagnosesNoteComponent';
import { ICDCodeFormItemComponent } from '../../../ICDCode/component/ICDCodeFormComponent/ICDCodeFormComponent';
import { FormType } from '../../../Input/types';
import { InterventionSubmitCancelButtons } from '../../../intervention/component/InterventionSubmitCancelButtons/InterventionSubmitCancelButtons';
import { PatientHealthConditionComplexityFormItemComponent } from '../../component/PatientComplexityFormItemComponent/PatientHealthConditionComplexityFormItemComponent';
import { PatientComplexitySubmitValue, usePatientComplexityForm } from '../../hook/usePatientComplexityForm';
import { usePatientUpdateHealthCondition, usePatientUpdatePatientComplexity } from '../../../../uc-api-sdk';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';

export interface PatientHealthConditionComplexityFormContainerProps
  extends FormType<PatientComplexitySubmitValue> {
  patientId: string;
  visitId?: string;
  note?: ReactNode;
}

export const PatientHealthConditionComplexityFormContainer = ({
  patientId,
  visitId,
  note = <ChronicDiagnosesNoteComponent />,
  initialValues,
  isLoading,
  onSubmit,
  onError,
  onValuesChange,
  onCancel,
  formButtons = <InterventionSubmitCancelButtons onCancel={onCancel} />
}: PatientHealthConditionComplexityFormContainerProps) => {
  const complexityForm = usePatientComplexityForm();
  const healthConditionUpdateInfo = usePatientUpdateHealthCondition({});
  const patientComplexityUpdateInfo = usePatientUpdatePatientComplexity({});
  const update = useUpdate('ICD_UPDATED');

  const handleSubmit = async (values: PatientComplexitySubmitValue) => {
    const { icdTable, patientComplexity } = values;
    const hcReq = healthConditionUpdateInfo.send({
      params: {
        memberId: patientId,
        request: {
          healthConditions: icdTable,
          visitId,
        },
      },
    });
    const hcRes = await ApiRequestHelper.tryCatch(hcReq, 'Failed to update icd code');
    if (!hcRes) {
      onError?.();
      return;
    }
    // successfully update icd code
    // refetch click goals/behavior goals/disease history
    update.updateValue();

    const pcReq = patientComplexityUpdateInfo.send({
      params: {
        memberId: patientId,
        request: {
          level: patientComplexity.level,
          source: patientComplexity.source,
        }
      },
    });
    const pcRes = await ApiRequestHelper.tryCatch(pcReq, 'Failed to update complexity');
    if (!pcRes) {
      onError?.();
      return;
    }
    onSubmit?.(values);
  };

  return (
    <Form
      form={complexityForm.form}
      onFinish={complexityForm.handleSubmit(handleSubmit)}
      initialValues={initialValues}
      disabled={complexityForm.formDisabled || isLoading}
      onValuesChange={onValuesChange}
    >
      <div className="flex fd-c">
        {note}
        <ICDCodeFormItemComponent
          disabled={complexityForm.formDisabled || isLoading}
        />
        <PatientHealthConditionComplexityFormItemComponent />
        {formButtons}
      </div>
    </Form>
  );
};
