import { Form, Input } from 'antd';
import Title from 'antd/lib/typography/Title';
import CancelSubmitButtonsComponent from '../../../../uiComponent/CancelSubmitButtonsComponent/CancelSubmitButtonsComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useDeletePatientForm } from '../../hook/useDeletePatientForm';

export interface DeletePatientFormValue {
  reason: string;
  delete: string;
}

export interface DeletePatientFormComponentProps {
  onSubmit: (v: DeletePatientFormValue) => void;
  onCancel: () => void;
  fullName: string;
  isLoading: boolean;
  shouldBeDisabled: (values: DeletePatientFormValue) => boolean;
}

export const DeletePatientFormComponent = ({
  onSubmit,
  fullName,
  onCancel,
  shouldBeDisabled,
  isLoading
}: DeletePatientFormComponentProps) => {
  const hook = useDeletePatientForm();

  return (
    <div>
      <Title level={5}>
        {
          `Delete [${fullName}]`
        }
      </Title>
      <p>
        Are you sure to delete this patient? This is an irreversible process.
        After deletion, no care portal user will be able to view or access this patient.
        Please ensure this is not the account patient updates measurement regularly
        or contain important data. To proceed, type "
        <span className="danger">DELETE</span>
        " below:
      </p>
      <Form
        form={hook.form}
        onFinish={hook.handleSubmitAndReset(onSubmit)}
        layout="vertical"
        disabled={isLoading}
      >
        <FormItem
          name={hook.getName('delete')}
          label={undefined}
          required
        >
          <Input className="ta-c" />
        </FormItem>
        <FormItem
          info={hook.getInfo('reason')}
          rules={[
            {
              required: true,
              message: 'Please input the reason for deletion!',
            },
          ]}
        >
          <Input.TextArea
            placeholder="Specify delete reason here"
          />
        </FormItem>
        <CancelSubmitButtonsComponent
          onCancel={onCancel}
          submitText="Delete"
          shouldBeDisabled={shouldBeDisabled}
          isLoading={isLoading}
        />
      </Form>
    </div>
  );
};
