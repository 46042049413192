import { ReactNode } from 'react';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { Icon } from '../../../../icons/Icon';
import { TWINKLE_STAR } from '../../../../assets/commonIconInfo';
import { ExtendableDrawerComponent } from '../../../../uiComponent/ExtendableDrawerComponent/ExtendableDrawerComponent';
import { DefaultExtendableDrawers } from '../../../../uiComponent/ExtendableDrawerComponent/controller/DefaultExtendableDrawers';
import { DrawerWidth570 } from '../../../../constants/drawer';
import { useLLMBlockPrompt } from '../../hook/useLLMBlockPrompt';
import { BlockUnmountKey } from '../../../../contexts/BlockUnmountContext/BlockUnmountContext';
import { AITranscribeOnboardingModalComponent } from '../../../../uiComponent/AITranscribeOnboardingModalComponent/AITranscribeOnboardingModalComponent';
import { useDoNotShowAgain } from '../../../patient/hook/useDoNotShowAlertAgain';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { ExtendableDrawerButtonComponent } from '../../../../uiComponent/ExtendableDrawerComponent/component/ExtendableDrawerButtonComponent';

import './LLMDrawerButtonComponent.scss';

export interface LLMDrawerButtonComponentProps {
  children: (close: () => void) => ReactNode;
  onClose?: () => void;
  title?: ReactNode
  extendableId?: DefaultExtendableDrawers;
  buttonText?: ReactNode;
}

export const LLMDrawerButtonComponent = ({
  children,
  onClose,
  title,
  extendableId,
  buttonText = (
    <div className="flex ai-c">
      <Icon info={TWINKLE_STAR} />
      <div>AI Transcribe (Beta)</div>
    </div>
  ),
}: LLMDrawerButtonComponentProps) => {
  const {
    userId,
  } = useLoggedInUserFromContext();
  const {
    isOpen,
    open,
    close,
  } = useOpen();
  const {
    isOpen: IsAITranscribeOnboardOpen,
    open: openAITranscribeOnboard,
    close: closeAITranscribeOnboard,
  } = useOpen();
  const [
    IsAITranscribeOnboard,
  ] = useDoNotShowAgain('IsAITranscribeOnboard', userId);
  const {
    checkIfBlocked,
    blockPrompt,
    unblockUnmountLLM,
  } = useLLMBlockPrompt();

  const handleOpen = () => {
    if (!IsAITranscribeOnboard) {
      openAITranscribeOnboard();
    }
    open();
  };

  const handleOnClose = async () => {
    const isBlocked = checkIfBlocked?.([BlockUnmountKey.LLM]);
    if (isBlocked) {
      const unblocked = await blockPrompt({ keys: [BlockUnmountKey.LLM] });
      if (!unblocked) {
        throw new Error('LLM is blocked due to processing state');
      }
    }
    unblockUnmountLLM?.();
    close();
    onClose?.();
  };

  return (
    <>
      <ExtendableDrawerButtonComponent
        buttonText={buttonText}
        buttonProps={{
          type: 'primary',
          ghost: true,
        }}
        onClick={handleOpen}
      />
      <ExtendableDrawerComponent
        id={extendableId || '' as DefaultExtendableDrawers}
        className="llm-drawer"
        title={title}
        open={isOpen}
        width={DrawerWidth570}
        onClose={handleOnClose}
        destroyOnClose
      >
        {children(handleOnClose)}
      </ExtendableDrawerComponent>
      <AITranscribeOnboardingModalComponent
        open={IsAITranscribeOnboardOpen}
        onClose={closeAITranscribeOnboard}
      />
    </>
  );
};
