import { message } from 'antd';
import { map } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents, MixpanelEventsParents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { useUpdate, useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { useMpTrackingHelper } from '../../../../hooks/useMpTrackingHelper/useMpTrackingHelper';
import {
  SmartAlertTaskStatus,
  useMedicalAlertGetAlertsByMemberId,
  useMedicalAlertResolveSmartAlerts,
  useMedicalAlertSearch
} from '../../../../uc-api-sdk';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { AlertFormSubmitValue } from '../../component/AlertComponent/AlertFormComponent';
import { AlertTabComponent } from '../../component/AlertComponent/AlertTabComponent';
import { MedicalAlertFormSubmitValue } from '../../component/MedicalAlertFormComponent/MedicalAlertFormComponent';
import { AlertText } from '../../constant/text';
import {
  closeAlerts, commentAlerts, getCompliance, getMutedAlerts,
} from '../../hook/useAlertSearch';

export interface AlertTabContainerProps {
  patientId: string;
}

export const AlertTabContainer = ({
  patientId,
}: AlertTabContainerProps) => {
  const complianceRequest = getCompliance(patientId);
  const snoozedRequest = getMutedAlerts(patientId);
  const resolveRequest = closeAlerts();
  const commentRequest = commentAlerts();
  const resolveMedicalAlert = useMedicalAlertResolveSmartAlerts({});
  const { send } = useBillableTimeInterventionComp();
  const { send: sendMixpanel } = useMixpanelContext();
  const location = useLocation();
  const { startEvent, endEvent } = useMpTrackingHelper({
    eventStart: MixpanelEvents.ComplianceAlertsStart,
    eventEnd: MixpanelEvents.ComplianceAlertsEnd,
    parent: location.pathname.includes('care-portal/utilities/medical-alert-dashboard')
      ? MixpanelEventsParents.MedicalAlertDashboard : MixpanelEventsParents.Outstanding,
    patientId,
  });

  const {
    startEvent: medicalAlertEventStart,
    endEvent: medicalAlertEventEnd
  } = useMpTrackingHelper({
    eventStart: MixpanelEvents.MedicalAlertsStart,
    eventEnd: MixpanelEvents.MedicalAlertsEnd,
    parent: location.pathname.includes('care-portal/utilities/medical-alert-dashboard')
      ? MixpanelEventsParents.MedicalAlertDashboard : MixpanelEventsParents.Outstanding,
    patientId,
  });

  const medicalAlertResultSearchByPatient = useMedicalAlertGetAlertsByMemberId({
    options: {
      sendOnMount: true,
    },
    params: {
      memberId: patientId,
    },
  });

  const medicalAlertSearchByPatient = useMedicalAlertSearch({
    options: {
      sendOnMount: true,
    },
    params: {
      filter: {
        memberId: patientId,
        status: SmartAlertTaskStatus.TODO,
      }
    },
  });

  const updateHookResolve = useUpdate('ALERT_RESOLVED');
  const updateHookMedicalAlertUpdate = useUpdate('MEDICAL_ALERT_UPDATED');
  const updateHookComplianceAlertUpdate = useUpdate('COMPLIANCE_ALERT_UPDATED');
  const updateHookComment = useUpdate('ALERT_COMMENTED');

  const handleResolve = async (
    value: AlertFormSubmitValue,
  ) => {
    if (value.isCommentOnly) {
      sendMixpanel({ event: MixpanelEvents.ComplianceAlertComment });
      send({
        patientId,
        component: BillableTimeInterventionComponentEnum.ReviewAlerts,
        detail: BillableTimeInterventionDetailEnum.OutstandingAlertsComplianceAlertsComment,
      });
      // here comment on the alert(s)
      const result = await commentRequest.send(value);
      let count = 0;
      map(result, (r) => {
        if (r && r.code === 200) {
          count += 1;
        }
      });
      if (count > 0) {
        message.success(AlertText.commentSuccess);
        endEvent({
          alertIds: value.alerts.map((alert) => alert.id),
        });
        // refresh care notes;
        updateHookComment.updateValue();
        updateHookComplianceAlertUpdate.updateValue();
      } else {
        message.warning(AlertText.error);
      }
    } else {
      sendMixpanel({ event: MixpanelEvents.ComplianceAlertResolve });
      send({
        patientId,
        component: BillableTimeInterventionComponentEnum.ReviewAlerts,
        detail: BillableTimeInterventionDetailEnum.OutstandingAlertsComplianceAlertsResolve,
      });
      // here resolve the alert(s)
      const result = await resolveRequest.send(value.alerts, value.comment);
      let count = 0;
      map(result, (r) => {
        if (r && r.code === 200) {
          count += 1;
        }
      });
      if (count > 0) {
        message.success(AlertText.resolveSuccess);
        endEvent({
          alertIds: value.alerts.map((alert) => alert.id),
        });
        // refresh compliance/medical
        complianceRequest.refetch();

        // refresh care notes; outstanding items counts;
        updateHookResolve.updateValue();
        updateHookComplianceAlertUpdate.updateValue();
      } else {
        message.warning(AlertText.error);
      }
    }
  };

  const handleResolveMedicalAlert = async (value: MedicalAlertFormSubmitValue) => {
    const alertList: string[] = [];
    value.alerts.forEach(
      (alertResult) => alertResult.alerts?.forEach((alert) => (alertList.push(alert.id as string)))
    );

    if (!value.noteOnly) {
      send({
        patientId,
        component: BillableTimeInterventionComponentEnum.ReviewAlerts,
        detail: BillableTimeInterventionDetailEnum.OutstandingAlertsMedicalAlertsResolve,
      });
    }

    sendMixpanel({ event: MixpanelEvents.MedicalAlertResolve });
    const result = await resolveMedicalAlert.send({
      params: {
        resolveAlertRequest: {
          note: value.note,
          alertIds: alertList,
          noteOnly: value.noteOnly ?? false,
        }
      }
    });
    if (result?.code === 200) {
      message.success(AlertText.noteSuccess);
      if (value.noteOnly !== true) {
        message.success(AlertText.resolveSuccess);
        medicalAlertSearchByPatient.refetch();
        medicalAlertResultSearchByPatient.refetch();
      }
      updateHookComment.updateValue();
      updateHookResolve.updateValue();
      updateHookMedicalAlertUpdate.updateValue();
      medicalAlertEventEnd({
        alertIds: alertList
      });
    } else {
      message.warning(AlertText.error);
    }
  };

  useUpdateListener('ALERT_SNOOZE_OR_UN_SNOOZE', () => {
    snoozedRequest.refetch();
    complianceRequest.refetch();
  });

  // scenario 1: GIVEN visit due alert exit, WHEN create/update visit, THEN remove alert
  useUpdateListener('VISIT_CREATED', complianceRequest.refetch);
  useUpdateListener('VISIT_UPDATED', complianceRequest.refetch);
  useUpdateListener('VISIT_DELETED', complianceRequest.refetch);

  // scenario 2: GIVEN A1C due alert exit, WHEN create/update lab, THEN remove alert
  useUpdateListener('LAB_DELETE', complianceRequest.refetch);
  useUpdateListener('LAB_EDITED', complianceRequest.refetch);
  useUpdateListener('LAB_CREATED', complianceRequest.refetch);

  return (
    <AlertTabComponent
      compliance={complianceRequest.data?.data ?? undefined}
      complianceLoading={complianceRequest.isLoading}
      medical={medicalAlertResultSearchByPatient.data?.data ?? undefined}
      snoozed={snoozedRequest.data?.data?.content ?? undefined}
      handleResolve={handleResolve}
      handleResolveMedicalAlert={handleResolveMedicalAlert}
      medicalAlertCount={medicalAlertSearchByPatient.data?.data?.totalSize ?? 0}
      onComplianceAlertStart={startEvent}
      onComplianceAlertEnd={endEvent}
      onMedicalAlertStart={medicalAlertEventStart}
    />
  );
};
