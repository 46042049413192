/* eslint-disable react/jsx-props-no-spreading */
import { Modal, ModalProps } from 'antd';
import classNames from 'classnames';
import { useEffect } from 'react';
import { ModalWidth600 } from '../../constants/modal';
import { FeedbackComponent, FeedbackComponentProps } from '../FeedbackComponent/FeedbackComponent';
import { useOpen } from '../../hooks/useBoolean/useOpen';

export interface FeedbackModalComponentProps extends ModalProps {
  feedbackProps: Omit<FeedbackComponentProps, 'onSubmit' | 'onSkip'>;
  onSubmit: FeedbackComponentProps['onSubmit'];
  onCancel?: () => void;
}

export const FeedbackModalComponent = ({
  open,
  feedbackProps,
  onSubmit,
  onCancel,
  className = '',
  ...props
}: FeedbackModalComponentProps) => {
  const {
    isOpen,
    setValue
  } = useOpen(open);

  useEffect(() => {
    setValue(!!open);
  }, [open]);

  const handleClose = () => {
    setValue(false);
    onCancel?.();
  };

  const handleSubmit: FeedbackComponentProps['onSubmit'] = (
    rating,
    comment
  ) => {
    onSubmit?.(rating, comment);
    handleClose();
  };

  return (
    <Modal
      open={isOpen}
      width={ModalWidth600}
      centered
      footer={null}
      onCancel={handleClose}
      destroyOnClose
      maskClosable={false}
      className={classNames({
        'feedback-modal': true,
        [className]: !!className,
      })}
      {...props}
    >
      <div className="p24 feedback-modal-body">
        <FeedbackComponent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...feedbackProps}
          onSubmit={handleSubmit}
          onSkip={handleClose}
        />
      </div>
    </Modal>
  );
};
