import { DefaultOptionType } from 'antd/lib/select';
import { RenderDOMFunc } from 'rc-select/lib/interface';
import { useEffect, useMemo } from 'react';
import { useLoggedInUserFromContext } from '../../contexts/loggedInUserContext';
import { SelectType } from '../../features/Input/types';
import { LimitedClinic } from '../../uc-api-sdk';
import { AllClinicSearchContainer } from '../../uiContainer/AllClinicSearchContainer/AllClinicSearchContainer';
import { ClinicSelectContainer, ClinicSelectContainerProps } from '../../uiContainer/ClinicSelectContainer/ClinicSelectContainer';
import { ClinicSelectContainerHelper } from '../../uiContainer/ClinicSelectContainer/helper';
import { SelectWithPrefixIconComponent, SelectWithPrefixIconComponentProps } from '../SelectWithPrefixIconComponent/SelectWithPrefixIconComponent';

export type ClinicOption = DefaultOptionType & {
  clinicData?: LimitedClinic;
  options?: ClinicOption[];
} & {
  [Key in (
    keyof typeof ClinicSelectContainerHelper.searchableFieldName | string
  )]?: unknown; // searchable field
}

export const ALL_CLINIC_SELECT_OPTION = {
  label: 'All clinics',
  value: 'ALL',
  clinicData: {},
  searchable: 'All clinics',
};

export interface ClinicSelectComponentProps extends SelectType<string>, Omit<ClinicSelectContainerProps, 'children'> {
  defaultSearchValue?: string;
  prefixIcon?: SelectWithPrefixIconComponentProps['prefixIcon'],
  showPrefixIcon?: boolean;
  getClinicData?: (clinic: LimitedClinic) => void;
  includesAllOption?: boolean;
  getAllClinics?: boolean;
  onChangeClinicList?: (clinicList: string[]) => void;
  paginatedSearch?: boolean;
  selectFirstClinicByDefault?: boolean;
  containerId?: string;
}

export const ClinicSelectComponent = ({
  defaultSearchValue,
  defaultSearchSize,
  filters,
  onSearch,
  // select props
  value,
  onChange,
  disabled,
  size,
  className = '',
  prefixIcon,
  showPrefixIcon = false,
  placeholder = 'Select a clinic',
  optionFilterProp = ClinicSelectContainerHelper.searchableFieldName,
  filterOption = true,
  autoClearSearchValue = true,
  dropdownMatchSelectWidth = true,
  getClinicData,
  includesAllOption,
  mode,
  getAllClinics,
  onChangeClinicList,
  paginatedSearch = true,
  selectFirstClinicByDefault = false,
  containerId,
}: ClinicSelectComponentProps) => {
  const { isProvider } = useLoggedInUserFromContext();

  const Container = useMemo(() => {
    if (!getAllClinics) {
      return ClinicSelectContainer;
    }
    return AllClinicSearchContainer;
  }, [getAllClinics]);

  return (
    <Container
      defaultSearchValue={defaultSearchValue}
      defaultSearchSize={defaultSearchSize}
      filters={{
        ...filters,
      }}
      onSearch={onSearch}
      paginatedSearch={paginatedSearch}
    >
      {
        ({
          isLoading,
          allClinics,
          handleOnSearch,
          handleOnScroll,
        }) => {
          const processedOptions = useMemo(() => {
            const allOptions = ClinicSelectContainerHelper.makeAndSortClinics(allClinics, {
              isProvider
            }) as ClinicOption[];

            if (includesAllOption) {
              allOptions.unshift(ALL_CLINIC_SELECT_OPTION as ClinicOption);
            }

            return allOptions;
          }, [allClinics, includesAllOption]);

          const handleOnChange = (clinicId: string, option: unknown) => {
            onChange?.(clinicId);
            onChangeClinicList?.([clinicId]);
            getClinicData?.((option as ClinicOption).clinicData || {});
          };

          useEffect(() => {
            if (selectFirstClinicByDefault && !value) {
              handleOnChange(processedOptions.at(0)?.clinicData?.id as string, processedOptions);
            }
          }, [processedOptions]);

          return (
            <SelectWithPrefixIconComponent
              showSearch
              size={size}
              disabled={disabled}
              className={className}
              options={processedOptions as DefaultOptionType[]}
              loading={isLoading}
              prefixIcon={prefixIcon}
              showPrefixIcon={showPrefixIcon}
              placeholder={placeholder}
              onSearch={handleOnSearch}
              value={isLoading ? undefined : value}
              onChange={handleOnChange}
              onPopupScroll={handleOnScroll}
              optionFilterProp={optionFilterProp}
              filterOption={filterOption}
              autoClearSearchValue={autoClearSearchValue}
              dropdownMatchSelectWidth={dropdownMatchSelectWidth}
              data-id="clinicSelectComponent"
              mode={mode}
              getPopupContainer={
                containerId
                  ? (() => document.getElementById(containerId || '')) as RenderDOMFunc : undefined
              }
            />
          );
        }
      }
    </Container>
  );
};
