export enum OutstandingEnum {
  VISITS = 'VISITS',
  REFERRAL = 'REFERRAL',
  TASKS = 'TASKS',
  TRANSCRIBING = 'TRANSCRIBING',
  ENROLLMENT_PREPARATION = 'ENROLLMENT_PREPARATION',
  ONBOARDING_TODOS = 'ONBOARDING_TODOS',
  ALERTS = 'ALERTS',
  MESSAGES = 'MESSAGES',
  MONTHLY_REVIEW = 'MONTHLY_REVIEW',
  FOOD_DAIRY = 'FOOD_DIARY',
  MANUAL_MONTHLY_REVIEW = 'MANUAL_MONTHLY_REVIEW',
}

export const UNENROLLED_ORDER = [
  OutstandingEnum.VISITS,
  OutstandingEnum.REFERRAL,
  OutstandingEnum.TASKS,
  OutstandingEnum.TRANSCRIBING,
  OutstandingEnum.ENROLLMENT_PREPARATION
];

export const ENROLLED_CA_ORDER = [
  OutstandingEnum.VISITS,
  OutstandingEnum.ONBOARDING_TODOS,
  OutstandingEnum.ALERTS,
  OutstandingEnum.TASKS,
  OutstandingEnum.TRANSCRIBING,
  OutstandingEnum.MESSAGES,
  OutstandingEnum.MONTHLY_REVIEW,
  OutstandingEnum.FOOD_DAIRY
];

export const ENROLLED_RD_ORDER = [
  OutstandingEnum.MANUAL_MONTHLY_REVIEW,
  OutstandingEnum.VISITS,
  OutstandingEnum.MESSAGES,
  OutstandingEnum.ALERTS,
  OutstandingEnum.MONTHLY_REVIEW,
  OutstandingEnum.FOOD_DAIRY,
  OutstandingEnum.TASKS,
  OutstandingEnum.ONBOARDING_TODOS,
  OutstandingEnum.TRANSCRIBING
];
