import { TagComponent } from '../../../../../uiComponent/TagComponent/TagComponent';

export const TranscribeStatusTagComponent = {
  IncompleteTag: () => (
    <TagComponent type="warning" iconType="halfSolid" background="none" className="p0i incomplete-tag">
      <u>Incomplete</u>
    </TagComponent>
  ),
  CompleteTag: () => (
    <TagComponent type="default" iconType="default" background="none" iconColor="blue" className="p0i complete-tag">
      Completed
    </TagComponent>
  ),
};
