import React, { FC } from 'react';
import { SummaryFourIndexComponent } from '../../SummaryColumnComponents/SummaryFourIndexComponent';
import { SummaryTwoIndexComponent } from '../../SummaryColumnComponents/SummaryTwoIndexComponent';
import { BPSummaryKey } from '../../../type';

export interface BPSummaryRangeColumnComponentProps {
  value: number[];
  type: BPSummaryKey;
}

export const BPSummaryRangeColumnComponent: FC<BPSummaryRangeColumnComponentProps> = ({
  value,
  type,
}) => {
  if (type === 'pulsePressure') {
    return (
      <SummaryTwoIndexComponent value={value} divider="-" />
    );
  }
  return (
    <SummaryFourIndexComponent value={value} />
  );
};
