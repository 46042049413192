import { map } from 'lodash';
import { PatientDiagnosesEnumComponent } from '../../enumComponent/PatientDiagnosesEnumComponent/PatientDiagnosesEnumComponent';
import { PatientDiagnosesEnum } from '../../uc-api-sdk';

export interface PatientDiagnosesComponentProps {
  values: PatientDiagnosesEnum[];
}

export const PatientDiagnosesComponent = ({
  values,
}: PatientDiagnosesComponentProps) => (
  <div className="flex f-w">
    {
      map(values, (diagnosis, idx) => (
        <div key={idx}>
          <PatientDiagnosesEnumComponent value={diagnosis} />
          { idx < values.length - 1 && ', ' }
        </div>
      ))
    }
  </div>
);
