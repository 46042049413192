import { MeetingStatusSelectComponent } from '../../../../patient/component/MeetingStatusSelectComponent/MeetingStatusSelectComponent';
import { ClinicalGoalVitalsLabelComponent } from '../ClinicalGoalVitalsLabelComponent/ClinicalGoalVitalsLabelComponent';
import { ClinicalGoalEnumComponent } from '../../../../../enumComponent/ClinicalGoalEnumComponent/ClinicalGoalEnumComponent';
import { GoalFormTimeComponent } from '../GoalFormTimeComponent/GoalFormTimeComponent';
import {
  ClinicGoalEnum, ClinicalGoalItem, ConditionEnum, GoalStatusEnum,
} from '../../../../../uc-api-sdk';
import '../ClinicalGoalCardListComponent/GoalCardsComponent.scss';
import { ClinicalGoalFreeTextVitalsLabelComponent } from '../ClinicalGoalFreeTextVitalsLabelComponent/ClinicalGoalFreeTextVitalsLabelComponent';

export type Status = string;

export interface ClinicalGoalProps {
  goal: ClinicalGoalItem;
}

export const ClinicalGoalItemComponent = ({
  goal,
}: ClinicalGoalProps) => (
  <div className="clinical-goal">
    {
      !goal.customCategory
        ? (
          <ClinicalGoalVitalsLabelComponent value={goal.condition as ConditionEnum} />
        ) : (
          <ClinicalGoalFreeTextVitalsLabelComponent customCategory={goal.customCategory} />
        )
    }
    <div className="intervention-goal-content">
      <div className="goal-description">
        {
          !goal.customCategory
            ? (
              <ClinicalGoalEnumComponent value={goal.clinicalGoalName as ClinicGoalEnum} />
            ) : (
              <span>{goal.customContent}</span>
            )
        }
      </div>
      <div className="meeting-status">
        <p>Status:</p>
        <MeetingStatusSelectComponent
          bordered={false}
          disabled
          value={goal.goalStatus as GoalStatusEnum}
        />
        <GoalFormTimeComponent value={goal.updatedDate as string} />
      </div>
    </div>
  </div>
);
