import { useFormHookFactory } from '../../../../../hooks/useFormHookFactory/useFormHookFactory';

export const useCareNoteHook = () => {
  const factory = useFormHookFactory({
    tags: {
      name: 'tags',
      label: 'Add Tag',
    },
    content: {
      name: 'content',
      label: 'Content',
    },
  });
  return factory;
};
