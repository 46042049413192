import React from 'react';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { SnoozeIntervalEnum } from '../../uc-api-sdk';

export interface SnoozeIntervalEnumComponentProps {
  value?: SnoozeIntervalEnum;
}

export const SnoozeIntervalEnumComponent = ({ value }: SnoozeIntervalEnumComponentProps) => {
  const getFullName = () => {
    switch (value) {
      case SnoozeIntervalEnum.ONE_DAY: return '1 day';
      case SnoozeIntervalEnum.THREE_DAYS: return '3 days';
      case SnoozeIntervalEnum.ONE_WEEK: return '1 week';
      case SnoozeIntervalEnum.TWO_WEEKS: return '2 weeks';
      case SnoozeIntervalEnum.ONE_MONTH: return '1 month';
      case SnoozeIntervalEnum.THREE_MONTHS: return '3 months';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  return (<>{getFullName()}</>);
};
