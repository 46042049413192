import { Form } from 'antd';
import { useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { PriorAuthCardFormItemComponent } from './PriorAuthCardFormItemComponent';
import { PriorAuthFormItemLayoutComponent } from './PriorAuthFormItemLayoutComponent';
import { PriorAuthCheckboxFormItemContainer } from './PriorAuthCheckboxFormItemContainer';
import { PriorAuthHistoryComponent } from '../PriorAuthHistoryComponent/PriorAuthHistoryComponent';
import { PriorAuthActionButtonComponent } from '../PriorAuthActionButtonComponent/PriorAuthActionButtonComponent';

export interface PriorAuthFormItemComponentProps {
  patientId: string;
  primaryProvider?: string;
  primaryProviderId?: string;
  primaryPolicy?: string;
}

export const PriorAuthFormItemComponent = ({
  patientId,
  primaryProvider,
  primaryProviderId,
  primaryPolicy,
}: PriorAuthFormItemComponentProps) => {
  const form = Form.useFormInstance();
  const insuranceForm = useInsuranceForm({ form });

  if (!patientId) {
    console.error('Patient ID is required');
    return null;
  }

  return (
    <Form
      form={insuranceForm.form}
      layout="vertical"
      component={false}
    >
      <PriorAuthFormItemLayoutComponent>
        <PriorAuthCheckboxFormItemContainer
          form={insuranceForm.form}
        />
        <FormItem
          noStyle
          shouldUpdate={
            insuranceForm.shouldUpdate([
              'priorAuth',
              'priorAuthAuthPeriod',
            ])
          }
        >
          {
            ({ getFieldValue }) => {
              const priorAuth = insuranceForm.getValue('priorAuth', getFieldValue);
              const priorAuthActiveCase = insuranceForm.getValue('priorAuthActiveCase', getFieldValue);
              if (priorAuth) {
                return (
                  <div className="prior-auth-card-wrapper">
                    <div className="flex jc-e">
                      <PriorAuthActionButtonComponent
                        patientId={patientId}
                        priorAuth={priorAuthActiveCase}
                        provider={primaryProvider}
                        providerId={primaryProviderId}
                        policyNumber={primaryPolicy}
                      />
                    </div>
                    <PriorAuthCardFormItemComponent />
                  </div>
                );
              }
              return <div />;
            }
          }
        </FormItem>
        <FormItem
          name={insuranceForm.getName('priorAuthActiveCase')}
          hidden
        />
        <FormItem
          noStyle
          shouldUpdate={insuranceForm.shouldUpdate(['priorAuthHistoryCases'])}
        >
          {
            ({ getFieldValue }) => {
              const priorAuthHistoryCases = insuranceForm.getValue('priorAuthHistoryCases', getFieldValue);
              return (
                <div className="prior-auth-card-wrapper">
                  <PriorAuthHistoryComponent
                    priorAuthCases={priorAuthHistoryCases}
                  />
                </div>
              );
            }
          }
        </FormItem>
      </PriorAuthFormItemLayoutComponent>
    </Form>
  );
};
