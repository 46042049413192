import { MonthEnum } from '../../../types/date';

export enum BillableTimeInterventionComponentEnum {
  Charting = 'Charting',
  ReviewAlerts = 'Review alerts',
  TaskAssignment = 'Task assignment',
  Transcribing = 'Transcribing',
  Messaging = 'Chat with patient through messaging',
  UpdateCarePlan = 'Update care plan',
  UpdateLabResults = 'Update lab result',
  UpdateMedication = 'Update medication',
  ReviewFoodLog = 'Review food diary',
  AddCareNote = 'Add care notes',
  ConductMTPR = 'Conduct MTPR',
}

export enum BillableTimeInterventionDetailEnum {
  WorkVisitsCheckIn = 'workvisit/btn_checkin',
  WorkVisitsCheckOut = 'workvisit/btn_checkout',
  WorkVisitVideoCallCheckIn = 'workvisit_videocall/btn_openzoom_checkin',
  WorkVisitVideoCallCheckOut = 'workvisit_videocall/btn_endzoom_checkout',
  OutstandingTasksResolve = 'btn_resolvetask',
  OutstandingTranscribingInitialTranscribeStartNow = 'outstanding_transcribing/initial_transcribing/textlink_startnow',
  OutstandingTranscribingFollowUpTranscribeStartNow = 'outstanding_transcribing/follow_up_transcribing/textlink_startnow',
  OutstandingTranscribingA1CTranscribeStartNow = 'outstanding_transcribing/a1c_transcribing/textlink_startnow',
  InterventionClinicalGoalEditIcon = 'intervention/clinical_goals/icon_edit',
  InterventionVitalsMonitoringGoalEditIcon = 'intervention/vitals_monitoring_goal/icon_edit',
  AddCareNoteSubmit = 'add_care_note/btn_submit',
  TaskCreateTaskEditVisitResolve = 'btn_resolve',
  OutstandingAlertsMedicalAlertsResolve = 'outstanding_alerts/medical_alerts/btn_resolve',
  OutstandingAlertsComplianceAlertsResolve = 'outstanding_alerts/compliance_alerts/btn_resolve',
  OutstandingAlertsComplianceAlertsComment = 'outstanding_alerts/compliance_alerts/btn_comment',
  OutstandingAlertsComplianceAlertsSnooze = 'outstanding_alerts/compliance_alerts/btn_snooze',
  OutstandingMessengerSend = 'outstanding_messenger/btn_send',
  InterventionBehavioralGoalEdit = 'intervention/behavioral_goal/icon_edit',
  MedicalHistoryMedicationListAddMedication = 'medical_history/medication_list/btn_add_medication',
  MedicalHistoryLabResultAddLabResult = 'medical_history/medication_list/btn_add_lab_result',
  TranscribingA1CTranscribeAdd = 'a1c_transcribe/btn_add',
  TranscribingVisitTranscribeMedicationListAddMedication = 'transcribing/visit_transcribe/medication_list/add_medication/btn_add',
  TranscribingVisitTranscribeMedicationListEditMedication = 'transcribing/visit_transcribe/medication_list/edit_medication/btn_save',
  TranscribingVisitTranscribeLabResultAddLabResultComplete = 'transcribing/visit_transcribe/lab_result/add_labresult/btn_submit',
  TranscribingVisitTranscribeLabResultEditLabResultSave = 'transcribing/visit_transcribe/lab_result/edit_labresult/btn_save',
  TranscribingVisitTranscribeLabResultReviewLabResultReview = 'transcribing/visit_transcribing/lab_result/review_labresult/btn_view_result',
  InterventionFoodLogReview = 'intervention/food_log/review',
  OutstandingMtprAddAGoal = 'outstanding_mtpr/add_a_goal',
  OutstandingMtprSubmit = 'outstanding_mtpr/submit',
  OutstandingMtprSeeNoteHistory = 'outstanding_mtpr/see_note_history',
  CreateMtprSubmit = 'create_mtpr/submit',
  MessageOngoingAllMessages = 'messages/ongoing&all_messages/read_messages',
  Messages = 'btn_messages',
  MessageThread = 'messages/message_thread',
  PrintCarePlan = 'print_care_plan',
  OutstandingFoodlogReview = 'outstanding_foodlog',
}

export const BILLABLE_TIME_MONTH_MAP = [
  {
    label: 'Jan',
    value: MonthEnum.January,
  },
  {
    label: 'Feb',
    value: MonthEnum.February,
  },
  {
    label: 'Mar',
    value: MonthEnum.March,
  },
  {
    label: 'Apr',
    value: MonthEnum.April,
  },
  {
    label: 'May',
    value: MonthEnum.May,
  },
  {
    label: 'Jun',
    value: MonthEnum.June,
  },
  {
    label: 'Jul',
    value: MonthEnum.July,
  },
  {
    label: 'Aug',
    value: MonthEnum.August,
  },
  {
    label: 'Sep',
    value: MonthEnum.September,
  },
  {
    label: 'Oct',
    value: MonthEnum.October,
  },
  {
    label: 'Nov',
    value: MonthEnum.November,
  },
  {
    label: 'Dec',
    value: MonthEnum.December,
  },
];
