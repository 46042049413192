import { Table, TableProps } from 'antd';
import { Moment } from 'moment';
import React, { FC } from 'react';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { createTaskColumns } from '../../../../table/FoodLogTable/columns';
import { NonNullUndefined } from '../../../../types/common';
import { FoodLog } from '../../type';
import './FoodLogTableComponent.scss';

export interface FoodLogTableData {
  date: Moment;
  data: NonNullUndefined<FoodLog>[];
}

export interface FoodLogTableComponentProps extends
  Omit<TableProps<FoodLogTableData>, 'dataSource' | 'columns'> {
  data: FoodLogTableData[],
  onReview?: () => void;
}

export const FoodLogTableComponent: FC<FoodLogTableComponentProps> = ({
  data,
  onReview,
  ...props
}) => {
  const columns = useTableColumns(
    createTaskColumns(onReview as () => void),
    ['Date', 'Breakfast', 'Lunch', 'Dinner', 'Snack'],
  );

  return (
    <div data-testid="FoodLogTableComponent" className="food-log-table">
      <Table
        size="middle"
        dataSource={data}
        columns={columns}
        rowKey="date"
        pagination={false}
        bordered
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </div>
  );
};

export default FoodLogTableComponent;
