import {
  Button,
  ButtonProps,
  Popconfirm,
  PopconfirmProps,
} from 'antd';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { useOpen } from '../../hooks/useBoolean/useOpen';

const commonButtonStyles = { height: 32, minWidth: 100 };

export interface CancelSubmitWithPopconfirmComponentProps {
  disabledCancelWithSubmit?: boolean;
  onSubmit?: () => void;
  submitText?: ReactNode;
  submitDisabled?: boolean;
  submitButtonProps?: ButtonProps;
  onCancel?: () => void;
  cancelText?: ReactNode;
  cancelDisabled?: boolean;
  cancelButtonProps?: ButtonProps;
  showConfirm?: boolean;
  // popconfirm props
  title?: ReactNode;
  popConfirmProps?: Omit<PopconfirmProps, 'title'>;
  beforeConfirm?: () => Promise<void>;
}

export const CancelSubmitWithPopconfirmComponent = ({
  disabledCancelWithSubmit = true,
  onSubmit,
  submitText = 'Submit',
  submitDisabled,
  submitButtonProps,
  onCancel,
  cancelText = 'Cancel',
  cancelDisabled = disabledCancelWithSubmit ? submitDisabled : undefined,
  cancelButtonProps,
  showConfirm,
  title,
  popConfirmProps,
  beforeConfirm,
}: CancelSubmitWithPopconfirmComponentProps) => {
  const {
    isOpen,
    open,
    close,
  } = useOpen();

  const handleOnSubmit = async () => {
    if (!showConfirm) {
      onSubmit?.();
      return;
    }
    try {
      await beforeConfirm?.();
      open();
    } catch (error) {
      // ignore
    }
  };

  const handleOnConfirm = () => {
    close();
    onSubmit?.();
  };

  return (
    <>
      {
        onCancel
        && (
          <Button
            onClick={onCancel}
            className="cancel-submit-with-confirm__cancel"
            disabled={cancelDisabled}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...cancelButtonProps}
          >
            {cancelText}
          </Button>
        )
      }
      <Popconfirm
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...popConfirmProps}
        okButtonProps={{
          type: 'primary',
          style: commonButtonStyles,
          ...popConfirmProps?.okButtonProps,
        }}
        cancelButtonProps={{
          type: 'default',
          style: commonButtonStyles,
          ...popConfirmProps?.cancelButtonProps,
        }}
        title={title}
        open={isOpen}
        overlayClassName={classNames({
          'cancel-submit-with-confirm__popconfirm': true,
          [popConfirmProps?.overlayClassName || '']: !!popConfirmProps?.overlayClassName,
        })}
        onConfirm={handleOnConfirm}
        onCancel={close}
        trigger="click"
      >
        <Button
          type="primary"
          className="cancel-submit-with-confirm__submit"
          disabled={submitDisabled}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...submitButtonProps}
          onClick={handleOnSubmit}
        >
          {submitText}
        </Button>
      </Popconfirm>
    </>
  );
};
