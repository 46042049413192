import { capitalize } from 'lodash';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useEffectOnce } from 'usehooks-ts';
import EnvConfig from '../../configs/envConfig/envConfig';
import { usePreviewWarningContext } from '../../contexts/PreviewWarningContext/PreviewWarningContext';

import './PreviewEnvWarningBannerComponent.scss';

export const PreviewEnvWarningBannerComponent = () => {
  const {
    openPreviewWarningModal,
  } = usePreviewWarningContext();

  useEffectOnce(() => {
    if (EnvConfig.showPreviewWarning) {
      document.body.classList.add('with-preview-env-warning-banner-style');
    }
  });

  if (!EnvConfig.showPreviewWarning) return null;

  return (
    <div className="preview-env-warning-banner">
      You are using the
      {' '}
      {capitalize(EnvConfig.envName)}
      {' '}
      Environment of Care Portal
      <div className="ml5">
        <QuestionCircleOutlined
          onClick={openPreviewWarningModal}
        />
      </div>
    </div>
  );
};
