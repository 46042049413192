import { Select, SelectProps } from 'antd';
import { TechLevelText } from '../../../features/enrollment/component/TechLevelRadioComponent/TechLevelRadioComponent';

export interface PatientTechLevelInputComponentProps extends SelectProps { }

export const PatientTechLevelInputComponent = (props: PatientTechLevelInputComponentProps) => (
  <Select
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    options={TechLevelText}
  />
);
