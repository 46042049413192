import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { SubmitValue } from '../../../../uiComponent/OfflineTimeComponent/AddOfflineTimeDrawerComponent/AddOfflineTimeDrawerComponent';
import { AFKBillableTimeFormComponent } from '../../component/AFKBillableTimeFormComponent/AFKBillableTimeFormComponent';
import { useBillableTimeSegmentInsertReq } from '../../hook/useBillableTimeSegmentInsert';

export interface AFKBillableTimeFormContainerProps {
  totalTime: number;
  totalAfkTime: number;
  patientId: string;
  onSubmit?: () => void;
  onIgnore?: () => void;
}

export const AFKBillableTimeFormContainer = ({
  totalTime,
  totalAfkTime,
  onSubmit,
  onIgnore,
  patientId,
}: AFKBillableTimeFormContainerProps) => {
  const { send } = useBillableTimeSegmentInsertReq(patientId);
  const {
    value: loading,
    setTrue,
    setFalse,
  } = useBoolean(false);

  const handleOnSubmit = async (formValue: SubmitValue) => {
    const req = send(formValue);
    setTrue();
    ApiRequestHelper.tryCatch(
      req,
      {
        success: 'Offline time added!',
        error: 'Failed to add offline time!',
        onSuccess: () => {
          onSubmit?.();
          setFalse();
        },
      },
    );
  };

  return (
    <AFKBillableTimeFormComponent
      totalAfkTime={totalAfkTime}
      totalTime={totalTime}
      onSubmit={handleOnSubmit}
      onCancel={onIgnore}
      isLoading={loading}
    />
  );
};
