import { Form } from 'antd';
import classNames from 'classnames';
import { ICDSearchDropdownContainer } from '../../container/ICDSearchDropdownContainer';
import { ICDTableFormItemComponent, ICDTableFormItemComponentProps } from '../ICDTableFormItemComponent/ICDTableFormItemComponent';

import './ICDCodeFormComponent.scss';
import { FormOptions } from '../../../../hooks/useFormHookFactory/useFormHookFactory';
import { useICDCodeForm } from '../../hook/useICDCodeForm';

export interface ICDCodeFormComponentProps
  extends Omit<ICDTableFormItemComponentProps, 'name'>,
  FormOptions { }

export const ICDCodeFormItemComponent = ({
  form,
  isLoading,
  disabled,
  required,
  requiredMessage,
}: ICDCodeFormComponentProps) => {
  const icdForm = useICDCodeForm({ form });

  return (
    <div
      className={classNames({
        'icd-form-component': true,
        'flex fd-c gap3': true,
      })}
    >
      <Form.Item
        name={icdForm.getName('icdSelect')}
      >
        <ICDSearchDropdownContainer
          disabled={disabled}
        />
      </Form.Item>

      <ICDTableFormItemComponent
        name={icdForm.getName('icdTable')}
        isLoading={isLoading}
        required={required}
        requiredMessage={requiredMessage}
        disabled={disabled}
      />
    </div>
  );
};
