import { OnboardingChecklistItemEnum } from '../../../uc-api-sdk';
import { CheckboxGroupComponent, CheckboxGroupComponentProps } from '../../../uiComponent/CheckboxGroupComponent/CheckboxGroupComponentWithType';
import { InputTypeArray } from '../../Input/types';
import { TechOnboardingChecklistEnumComponent } from '../component/TechOnboardingChecklistEnumComponent/TechOnboardingChecklistEnumComponent';

const options: CheckboxGroupComponentProps<OnboardingChecklistItemEnum>['options'] = [
  {
    value: OnboardingChecklistItemEnum.AUTO_UPDATE_ENABLED,
    label: (
      <TechOnboardingChecklistEnumComponent
        value={OnboardingChecklistItemEnum.AUTO_UPDATE_ENABLED}
      />
    ),
  },
  {
    value: OnboardingChecklistItemEnum.CT_NUMBER_ADDED,
    label: (
      <TechOnboardingChecklistEnumComponent
        value={OnboardingChecklistItemEnum.CT_NUMBER_ADDED}
      />
    ),
  },
  {
    value: OnboardingChecklistItemEnum.PATIENT_GUIDED,
    label: (
      <TechOnboardingChecklistEnumComponent
        value={OnboardingChecklistItemEnum.PATIENT_GUIDED}
      />
    ),
  },
  {
    value: OnboardingChecklistItemEnum.KEYFUNCTIONS_INTRODUCED,
    label: (
      <TechOnboardingChecklistEnumComponent
        value={OnboardingChecklistItemEnum.KEYFUNCTIONS_INTRODUCED}
      />
    ),
  },
];

export interface TechOnboardingChecklistComponentProps extends
  InputTypeArray<OnboardingChecklistItemEnum> {
}

export const TechOnboardingChecklistComponent = ({
  value,
  onChange,
  disabled,
}: TechOnboardingChecklistComponentProps) => (
  <CheckboxGroupComponent
    value={value || []}
    onChange={onChange}
    options={options}
    direction="vertical"
    disabled={disabled}
  />
);
