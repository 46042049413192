import { ReactNode } from 'react';
import { VitalEnumType } from '../../../../uc-api-sdk';
import { DeviceDrawerProps } from '../../type';
import { DeviceMonitorDrawerComponent } from '../DeviceMonitorDrawerComponent/DeviceMonitorDrawerComponent';
import { DeviceMonitorFormComponentProps } from '../DeviceMonitorFormComponent/DeviceMonitorFormComponent';
import { BloodPressureDeviceAssignmentFormComponent } from './BloodPressureDeviceAssignmentFormComponent';

export interface BloodPressureDeviceMonitorDrawerComponentProps
  extends DeviceDrawerProps {
  title?: ReactNode;
  onSubmit?: DeviceMonitorFormComponentProps['onSubmit'];
  useDefaultTemplate?: boolean;
}

export const BloodPressureDeviceMonitorDrawerComponent = ({
  patientId,
  onSubmit,
  isOpen,
  onClose,
  title,
  useDefaultTemplate,
}: BloodPressureDeviceMonitorDrawerComponentProps) => (
  <DeviceMonitorDrawerComponent
    open={isOpen}
    onClose={onClose}
    patientId={patientId}
    vitalType={VitalEnumType.BP}
    onSubmit={onSubmit}
    title={title}
    useDefaultTemplate={useDefaultTemplate}
  >
    {
      (props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <BloodPressureDeviceAssignmentFormComponent {...props} />
      )
    }
  </DeviceMonitorDrawerComponent>
);
