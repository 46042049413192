import { remove } from 'lodash';
import { COLORS, COLOR_NAMES, ColorName } from './Colors';

export interface Color {
  backgroundColor: string;
  borderColor: string;
  leftBorderColor: string;
  textColor?: string;
  subTextColor?: string;
}

const defaultTextColor = '#2D3238';
const defaultSubTextColor = '#6B7178';

export class CalendarColorService {
  private static unusedColors = [...COLOR_NAMES];

  public static getColor(name?: ColorName): Required<Color> {
    const colorName: ColorName = (
      name
      || (
        this.unusedColors.length > 0
          ? this.unusedColors[0]
          : 'gray'
      )
    );
    if (name === undefined) {
      remove(this.unusedColors, (c) => c === colorName);
    }
    return {
      textColor: defaultTextColor,
      subTextColor: defaultSubTextColor,
      ...COLORS[colorName],
    };
  }
}
