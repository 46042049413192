import { FC } from 'react';
import { Button, Rate, Space } from 'antd';
import { Moment } from 'moment';
import { TIME } from '../../../../constants/timeFormat';
import './FoodLogTableItemComponent.scss';

export interface FoodLogTableItemComponentProps {
  date: Moment;
  image: string | undefined;
  rate: number | undefined;
  count: number | undefined;
  needsReview: boolean;
  onClick: () => void;
}

export const FoodLogTableItemComponent: FC<FoodLogTableItemComponentProps> = ({
  date,
  image,
  rate,
  count,
  needsReview,
  onClick,
}) => {
  const ratedView = () => (
    <>
      <Space>
        <div>Rating:</div>
        <Rate
          value={rate}
          disabled
        />
      </Space>
      <Button onClick={onClick} type="text">
        <span className="viewBtn">View &gt;</span>
      </Button>
    </>
  );

  return (
    <div>
      <div className="flex fd-c jc-sb">
        <div className="flex">
          Uploaded:
          {' '}
          {date.format(TIME)}
        </div>
        <div>
          {count !== undefined && (
            <div>
              {count}
              {' '}
              {count > 1 ? 'items' : 'item'}
            </div>
          )}
        </div>
      </div>
      <div className="food-log-column-image-container">
        <img src={image} alt={date.toISOString()} className="image" />
      </div>
      <div className="flex jc-c ai-c fd-c">
        {needsReview
          ? (
            <Button onClick={onClick} type="primary">
              Review
            </Button>
          )
          : ratedView()}
      </div>
    </div>
  );
};
