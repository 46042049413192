import Title from 'antd/lib/typography/Title';
import { Button, Drawer } from 'antd';
import { useState } from 'react';
import { EditFilled } from '@ant-design/icons';
import { EnterActualBillableTimeContainer } from '../../container/EnterActualBillableTimeContainer/EnterActualBillableTimeContainer';

export interface EnterActualBillableTimeDrawerComponentProps {
  startDate: number;
  totalTime: number;
  totalBillableTime: number;
  id: string;
}

export const EnterActualBillableTimeDrawerComponent = ({
  startDate,
  totalTime,
  totalBillableTime,
  id,
}: EnterActualBillableTimeDrawerComponentProps) => {
  const [open, setOpen] = useState(false);
  const renderOpenButton = () => (
    <Button
      type="default"
      className="enter-button primary-link"
      onClick={() => setOpen(true)}
    >
      <div className="flex gap1 b7">
        <EditFilled />
        <div>Enter actual billable time</div>
      </div>
    </Button>
  );

  return (
    <>
      {renderOpenButton()}
      <Drawer
        size="default"
        width="643px"
        title={(
          <Title level={5} className="p0">
            Billable Time Requiring Review
          </Title>
        )}
        destroyOnClose
        open={open}
        onClose={() => setOpen(false)}
      >
        <EnterActualBillableTimeContainer
          id={id}
          startDate={startDate}
          totalTime={totalTime}
          totalBillableTime={totalBillableTime}
          close={() => setOpen(false)}
        />
      </Drawer>
    </>
  );
};
