import {
  DeviceMonitorFormComponent,
  DeviceMonitorFormComponentProps
} from '../DeviceMonitorFormComponent/DeviceMonitorFormComponent';

import './DeviceMonitorFormWithDefaultComponent.scss';

export interface DeviceMonitorFormWithDefaultComponentProps
  extends Omit<DeviceMonitorFormComponentProps, 'onSubmit'> {
  onSubmit?: DeviceMonitorFormComponentProps['onSubmit'];
}

export const DeviceMonitorFormWithDefaultComponent = ({
  vitalType,
  onSubmit,
  onCancel,
  children,
}: DeviceMonitorFormWithDefaultComponentProps) => {
  const handleSubmit: DeviceMonitorFormComponentProps['onSubmit'] = (
    values,
  ) => {
    onSubmit?.(values);
  };

  return (
    <div className="device-monitor-form-with-default">
      <DeviceMonitorFormComponent
        vitalType={vitalType}
        onSubmit={handleSubmit}
        onCancel={onCancel}
      >
        {(props) => children?.(props) || null}
      </DeviceMonitorFormComponent>
    </div>
  );
};
