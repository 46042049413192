import { useMemo } from 'react';
import { useIterableFormHook } from '../../../hooks/useIterableFormListHook/useIterableFormHook';
import { FormOptions } from '../../../hooks/useFormHookFactory/useFormHookFactory';

export const useClinicalGoalForm = (options?: FormOptions) => {
  const factory = useIterableFormHook('clinicalGoals', {
    clinicalGoalName: {
      name: 'clinicalGoalName',
      label: '',
    },
    goalStatus: {
      name: 'goalStatus',
      label: 'Status',
    },
    updatedDate: {
      name: 'updatedDate',
      label: '',
    },
    condition: {
      name: 'condition',
      label: '',
    },
    isManualInput: {
      name: 'isManualInput',
      label: '',
    },
    customCategory: {
      name: 'customCategory',
      label: '',
    },
    customContent: {
      name: 'customContent',
      label: '',
    }
  }, options);

  const response = useMemo(() => ({
    ...factory,
  }), [factory]);

  return response;
};
