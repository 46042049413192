import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

export interface CardWHorizontalSectionItemComponentProps {
  children: ReactNode;
  isHeader?: boolean;
  noDivider?: boolean;
  isExtra?: boolean;
}

export const CardWHorizontalSectionItemComponent: FC<CardWHorizontalSectionItemComponentProps> = ({
  children,
  isHeader = false,
  noDivider = false,
  isExtra = false,
}) => (
  <div
    className={
      classNames({
        'card-w-horizontal-section-item': true,
        'no-divider': noDivider,
        'header-item': isHeader,
        'extra-item': isExtra,
      })
    }
  >
    {children}
  </div>
);

export default CardWHorizontalSectionItemComponent;
