import { map } from 'lodash';
import { ClinicEventsService } from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { FutureFollowUpClinicEventCardComponent } from '../FutureFollowUpClinicEventCardComponent/FutureFollowUpClinicEventCardComponent';

import './FutureFollowUpClinicEventListComponent.scss';

export interface FutureFollowUpClinicEventListComponentProps {
  clinicEvents: ClinicEventsService[]
}

export const FutureFollowUpClinicEventListComponent = ({
  clinicEvents,
}: FutureFollowUpClinicEventListComponentProps) => (
  <div className="future-follow-up-list">
    {
      map(clinicEvents, (clinicEvent) => (
        <div
          key={clinicEvent.id}
          className="future-follow-up-card-wrapper"
        >
          <FutureFollowUpClinicEventCardComponent
            clinicEvent={clinicEvent}
          />
        </div>
      ))
    }
  </div>
);
