import { CALENDAR_ICON } from '../../../../../assets/commonIconInfo';
import { Icon } from '../../../../../icons/Icon';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';

import './PrintReturnReminderComponent.scss';

export const PrintReturnReminderComponent = () => (
  <>
    <div>
      <Icon info={CALENDAR_ICON} />
    </div>
    <div className="print-return-reminder">
      <TextComponent className="pop-text">
        Return every 3 months
      </TextComponent>
      <div className="flex f-w">
        Come back every 3 months for your next follow-up checkup.
        You can stay in touch with your care team through secure
        {' '}
        <TextComponent bold size="small">Unified Care APP messaging</TextComponent>
        {' '}
        or phone calls at
        {' '}
        <TextComponent bold size="small">866-899-3998.</TextComponent>
      </div>
    </div>
  </>
);
