import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { useTranscribingJobFeedback } from '../../../../uc-api-sdk';
import { FeedbackModalComponent, FeedbackModalComponentProps } from '../../../../uiComponent/FeedbackModalComponent/FeedbackModalComponent';

export interface LLMFeedbackModalContainerProps {
  open?: boolean;
  jobId?: string;
  feedbackProps?: Omit<FeedbackModalComponentProps['feedbackProps'], 'onSubmit' | 'onSkip'>;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export const LLMFeedbackModalContainer = ({
  open,
  jobId,
  feedbackProps,
  onSubmit,
  onCancel,
}: LLMFeedbackModalContainerProps) => {
  const submitFeedbackInfo = useTranscribingJobFeedback({});

  const handleSubmit: FeedbackModalComponentProps['onSubmit'] = (
    score,
    comment,
  ) => {
    ApiRequestHelper.tryCatch(
      submitFeedbackInfo.send({
        params: {
          request: {
            jobId,
            score,
            comment,
          }
        }
      }),
      {
        success: 'Survey submitted.',
        error: 'Failed to submit survey.',
        onSuccess: onSubmit,
      }
    );
  };

  const debouncedHandleSubmit = useDebounce(handleSubmit, 500, [handleSubmit, jobId]);

  return (
    <FeedbackModalComponent
      open={open}
      feedbackProps={(
        feedbackProps || {
          ratingQuestion: 'How is your experience using the "AI Transcribe" so far?',
          commentQuestion: 'Was there any issue or anything can be improved?',
          commentPlaceholder: 'Were there issues such as missing, incorrect, or made-up data, long loading times, or unrecognized files, etc? Please specify the EHR you used. (Optional) '
        }
      )}
      onSubmit={debouncedHandleSubmit}
      onCancel={onCancel}
    />
  );
};
