import { ChronicDiseaseEnum } from '../../uc-api-sdk';
import { BasicEnumComponent } from '../BasicEnumComponent/BasicEnumComponent';

export interface DiseaseComponentProps {
  disease?: ChronicDiseaseEnum;
}

export const DiseaseComponent = ({
  disease,
}: DiseaseComponentProps) => (
  <BasicEnumComponent value={disease as string} />
);
