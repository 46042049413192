import { toUpper } from 'lodash';
import { StorageKeyEnum, useSessionStorage } from '../../../../hooks';
import { EhrJobRecord } from '../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface EhrNameComponentProps {
  name?: string;
}

export const EhrNameComponent = ({
  name,
}: EhrNameComponentProps) => {
  const [ehrQuery] = useSessionStorage<EhrJobRecord>(StorageKeyEnum.EHR_QUERY);

  const ehrName = toUpper(name || ehrQuery?.ehr || '');

  return <DisplayOrEmptyComponent value={ehrName} />;
};
