import { map } from 'lodash';
import { HealthCondition } from '../../../../uc-api-sdk';

export const PatientHealthConditionsComponent = ({
  healthConditions,
}: HealthCondition) => {
  if (!healthConditions) {
    return (
      <span>
        N/A
      </span>
    );
  }
  const totalLength = healthConditions.length;
  return (
    <span>
      { map(healthConditions, (item, index) => (
        <span key={index}>
          {`${item.condition}${index !== totalLength - 1 ? ', ' : ''}`}
        </span>
      ))}
    </span>
  );
};
