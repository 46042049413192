/* eslint-disable react/jsx-props-no-spreading */
import {
  Skeleton,
  SkeletonProps,
  Spin,
  SpinProps,
} from 'antd';
import classNames from 'classnames';
import {
  Children,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';

export type LoadingOverlaySkeletonType = 'avatar'
  | 'title'
  | 'paragraph'
  | 'button'
  | 'input'
  | 'avatar-paragraph'
  | 'avatar-title-paragraph';

export interface LoadingOverlayComponentProps {
  size?: SpinProps['size'];
  isLoading?: boolean;
  children?: ReactNode;
  className?: string;
  skeletonType?: LoadingOverlaySkeletonType;
  skeletonParagraphRows?: number;
  showSpin?: boolean;
  showSkeleton?: boolean;
}

export const LoadingOverlayComponent = ({
  size,
  isLoading,
  children = null,
  className = '',
  skeletonType = 'title',
  skeletonParagraphRows = 3,
  showSpin,
  showSkeleton = true,
}: LoadingOverlayComponentProps) => {
  const SkeletonComponent = useMemo(() => {
    switch (skeletonType) {
      case 'avatar': return Skeleton.Avatar;
      case 'avatar-paragraph':
        return (props: SkeletonProps) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Skeleton avatar title={false} paragraph={{ rows: skeletonParagraphRows }} {...props} />
        );
      case 'avatar-title-paragraph':
        return (props: SkeletonProps) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Skeleton avatar paragraph={{ rows: skeletonParagraphRows }} {...props} />
        );
      case 'title': return Skeleton;
      case 'paragraph':
        return (props: SkeletonProps) => (
          <Skeleton paragraph={{ rows: skeletonParagraphRows }} {...props} />
        );
      case 'button': return Skeleton.Button;
      case 'input': return Skeleton.Input;
      default: return Skeleton;
    }
  }, [skeletonType, skeletonParagraphRows]);

  const renderSkeleton = useCallback(() => {
    if (!showSkeleton) return null;

    return Children.map((children), () => (
      <SkeletonComponent
        active={!showSpin}
        className={classNames({
          'loading-overlay-component__skeleton': true,
          [className]: !!className,
        })}
      />
    )) as ReactNode[];
  }, [children, showSkeleton, showSpin]);

  const renderChildren = useCallback(() => (
    isLoading
      ? renderSkeleton()
      : children
  ), [isLoading, renderSkeleton, children]);

  return showSpin
    ? (
      <Spin
        size={size}
        spinning={!!isLoading}
        wrapperClassName={classNames({
          'loading-overlay-component': true,
          [className]: !!className,
        })}
      >
        {renderChildren()}
      </Spin>
    ) : (
      <>
        {renderChildren()}
      </>
    );
};
