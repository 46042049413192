import { useState, useMemo } from 'react';
import {
  find,
  findIndex,
  includes,
  map,
} from 'lodash';
import { Select } from 'antd';
import {
  ArticleCategories,
  PatientArticleMap,
  WebflowLanguageEnum,
} from '../../types';
import { Article, PatientArticles } from '../../../../uc-api-sdk';
import { NO_ARTICLE_FOUND, SEARCH_ARTICLES_PAGE_SIZE } from '../../constants';
import { UseSearchArticlesResult } from '../../hooks/useSearchArticles';
import { PaginatedSelectComponent } from '../../../../uiComponent/PaginatedSelectComponent/PaginatedSelectComponent';
import { ArticleSearchOptionHeaderComponent } from '../ArticleSearchOptionComponent/ArticleSearchOptionHeaderComponent';
import { ArticleSearchOptionComponent } from '../ArticleSearchOptionComponent/ArticleSearchOptionComponent';
import { ArticleSendButtonComponent } from '../ArticleSendButtonComponent/ArticleSendButtonComponent';

import './ArticlesSearchComponent.scss';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';

export interface ArticleSearchComponentProps
  extends Omit<UseSearchArticlesResult, 'resetToInitial' | 'isLoadingArticlesDataSource'> {
  containerClassName?: string;
  isLoading: boolean;
  onSubmit?: (selectedArticles: PatientArticles[]) => void;
  submitText?: string;
  disabled?: boolean;
  patientArticles: PatientArticleMap;
  articleCategories: ArticleCategories;
}
export const ArticleSearchComponent = (
  {
    containerClassName = '',
    searchValue,
    category,
    page,
    onChange,
    articlesDataSource,
    isLoading,
    onSubmit,
    submitText = 'Send',
    disabled,
    patientArticles,
    articleCategories,
  }: ArticleSearchComponentProps,
) => {
  const [selectedArticles, setSelectedArticles] = useState<Article[]>([]);
  const { send: sendMixpanel } = useMixpanelContext();

  const {
    data,
    totalSize,
  } = articlesDataSource || {};
  const selectedArticleIds = useMemo(() => map(selectedArticles, 'id') as string[], [selectedArticles]);

  const handleSearchInputChange = (searchValue: string) => {
    onChange({ changedSearchValue: searchValue, changedPage: 1 });
    sendMixpanel({ event: MixpanelEvents.InterventionEducationSearch });
  };

  const handleCategoryChange = (categoryId: string) => {
    onChange({ changedCategory: categoryId, changedPage: 1 });
  };

  const handleChangePage = (page: number) => {
    onChange({ changedPage: page });
  };

  const handleSetSelectedArticles = (articleId: string) => {
    const article = find(data, { id: articleId });
    const newSelectedArticles = [...selectedArticles, article];
    setSelectedArticles(newSelectedArticles as Article[]);
  };

  const handleUnsetSelectedArticles = (articleId: string) => {
    const articleIndex = findIndex(selectedArticles, { id: articleId });
    if (articleIndex === -1) return;
    const newSelectedArticles = [...selectedArticles];
    newSelectedArticles.splice(articleIndex, 1);
    setSelectedArticles(newSelectedArticles);
  };

  const handleSubmit = () => {
    onSubmit?.(selectedArticles);
    sendMixpanel({ event: MixpanelEvents.InterventionEducationAdd });
    setSelectedArticles([]);
  };

  return (
    <div className="articles-search-component">
      <div className="articles-search-component__label">
        Search articles
      </div>
      <PaginatedSelectComponent
        containerClassName={containerClassName}
        selectedValues={selectedArticleIds}
        onSelectChange={handleSetSelectedArticles}
        onDeselectChange={handleUnsetSelectedArticles}
        searchValue={searchValue}
        onSearchChange={handleSearchInputChange}
        pagination={{
          onChange: handleChangePage,
          current: page,
          total: totalSize,
          pageSize: SEARCH_ARTICLES_PAGE_SIZE,
          size: 'small',
        }}
        clearIcon={(
          <ArticleSendButtonComponent
            onSubmit={handleSubmit}
            disabled={isLoading}
          >
            {submitText}
          </ArticleSendButtonComponent>
        )}
        disabled={disabled}
        isLoading={isLoading}
        notFoundContent={NO_ARTICLE_FOUND}
        placeholder="Search"
        keepDropdownOpenOnSelect
      >
        <>
          <Select.Option
            key="categories"
            disabled
            className="dropdown-search-categories"
          >
            <ArticleSearchOptionHeaderComponent
              categories={articleCategories}
              selectedCategory={category}
              onCategoryChange={handleCategoryChange}
            />
          </Select.Option>
          {
            data && data.length > 0
              ? (
                data.map((d: Article) => {
                  const patientArticle = patientArticles[d.id as string];
                  const isSelected = includes(selectedArticleIds, d.id);
                  return (
                    <Select.Option
                      key={d.id}
                      value={d.id}
                      label={d.name}
                    >
                      <ArticleSearchOptionComponent
                        key={d.id}
                        article={d}
                        sentOn={patientArticle?.updatedAt as string}
                        isSelected={isSelected}
                        excludeLanguageTags={[WebflowLanguageEnum.en]}
                      />
                    </Select.Option>
                  );
                })
              ) : (
                <Select.Option
                  key="notice"
                  disabled
                >
                  {
                    isLoading
                      ? '...'
                      : NO_ARTICLE_FOUND
                  }
                </Select.Option>
              )
          }
        </>
      </PaginatedSelectComponent>
    </div>
  );
};
