import { OrderItemEnum, OrderStaging } from '../../uc-api-sdk';
import { OrderItemEnumComponent } from '../OrderItemEnumComponent/OrderItemEnumComponent';

export interface OrderItemEnumListComponentProps {
  value: OrderStaging;
}

export const OrderItemEnumListComponent = ({
  value,
}: OrderItemEnumListComponentProps) => {
  if (value?.orderItems?.deviceId) {
    return (
      <div className="flex f-w">
        <OrderItemEnumComponent value={value?.orderItems?.orderName as OrderItemEnum} />
        <div>
          (
          {value?.orderItems?.deviceId}
          )
        </div>
      </div>
    );
  }

  return (
    <OrderItemEnumComponent value={value?.orderItems?.orderName as OrderItemEnum} />
  );
};
