import React from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { Space } from 'antd';
import './QualifiedComponent.scss';

export const QualifiedComponent = () => (
  <div className="qualified-component">
    <Space>
      <CheckCircleFilled className="qualified-component__icon" />
      <span className="qualified-component__text">Eligible for billing</span>
    </Space>
  </div>
);
