export enum PatientStatusTagEnum {
  'EligibleToEnrollTag',
  'FullyCoveredTag',
  'IneligibleTag',
  'UnenrolledPotentialTag',
  'InsCheckPendingTag',
  'ManualInsCheckPendingTag',
  'InactiveTag',
  'MissingA1CTag',
  'A1CDueTag',
  'VisitDueTag',
  'VisitOverdueTag',
  'MissingInfoTag',
  'CopayTag',
  'NoCoverageTag',
  'InsCheckWaivedTag',
  'NoAttentionNeededTag',
}
