import { Row, Col, Space } from 'antd';
import USStateSelectComponent from '../../../../selectComponents/USStatesSelectComponent/USStatesSelectComponent';
import { AddressAptInputComponent } from '../../../../uiComponent/Address/AddressAptInputComponent/AddressAptInputComponent';
import { AddressCityInputComponent } from '../../../../uiComponent/Address/AddressCityInputComponent/AddressCityInputComponent';
import { AddressInputComponent } from '../../../../uiComponent/Address/AddressInputComponent/AddressInputComponent';
import ZipCodeInputComponent from '../../../../uiComponent/ZipCodeInputComponent/ZipCodeInputComponent';
import { useAddressForm } from '../../../../hooks/formHook/useAddressForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { FormOptions } from '../../../../hooks/useFormHookFactory/useFormHookFactory';

export interface TranscribeAddressFormItemComponentProps extends FormOptions {}

export const TranscribeAddressFormItemComponent = ({
  form,
}: TranscribeAddressFormItemComponentProps) => {
  const addressForm = useAddressForm({ form });
  return (
    <Row gutter={16} align="bottom">
      <Col span={12}>
        <Space.Compact
          block
          className="compact"
        >
          <FormItem
            info={addressForm.getInfo('address')}
            className="w80"
          >
            <AddressInputComponent />
          </FormItem>
          <FormItem
            className="w20"
            info={addressForm.getInfo('address2')}
          >
            <AddressAptInputComponent />
          </FormItem>
        </Space.Compact>
      </Col>
      <Col span={6}>
        <FormItem
          info={addressForm.getInfo('city')}
        >
          <AddressCityInputComponent />
        </FormItem>
      </Col>
      <Col span={6}>
        <Space.Compact
          block
          className="compact"
        >
          <FormItem
            info={addressForm.getInfo('state')}
            className="w100"
          >
            <USStateSelectComponent />
          </FormItem>
          <FormItem
            info={addressForm.getInfo('zipcode')}
          >
            <ZipCodeInputComponent />
          </FormItem>
        </Space.Compact>
      </Col>
    </Row>
  );
};
