import { StarFilled } from '@ant-design/icons';
import { isNumber } from 'lodash';
import {
  BG_NO_CIRCLE,
  BP_NO_CIRCLE,
  CANCER_ICON,
  CHF_ICON,
  CKD_ICON,
  COPD_ICON,
  COVID_ICON,
  LIPID_MANAGEMENT_ICON,
  WEIGHT_NO_CIRCLE,
} from '../../assets/commonIconInfo';
import { Icon, IconAllProps } from '../../icons/Icon';
import { ConditionEnum } from '../../uc-api-sdk';

export interface ConditionIconComponentProps {
  condition?: ConditionEnum;
  size?: IconAllProps['size'];
}

export const ConditionIconComponent = ({
  condition,
  size = 11,
}: ConditionIconComponentProps) => {
  const { width, height } = (
    !isNumber(size)
      ? size
      : { width: size, height: size }
  );
  const getIconInfo = () => {
    switch (condition) {
      case ConditionEnum.BLOOD_PRESSURE_MANAGEMENT:
        return BP_NO_CIRCLE;
      case ConditionEnum.GLUCOSE_MANAGEMENT:
        return BG_NO_CIRCLE;
      case ConditionEnum.LIPID_MANAGEMENT:
        return LIPID_MANAGEMENT_ICON;
      case ConditionEnum.CANCER:
        return CANCER_ICON;
      case ConditionEnum.WEIGHT_REDUCTION:
      case ConditionEnum.UNDERWEIGHT:
        return WEIGHT_NO_CIRCLE;
      case ConditionEnum.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE:
        return COPD_ICON;
      case ConditionEnum.CONGESTIVE_HEART_FAILURE:
        return CHF_ICON;
      case ConditionEnum.CHRONIC_KIDNEY_DISEASE:
        return CKD_ICON;
      case ConditionEnum.COVID:
        return COVID_ICON;
      default:
        return null;
    }
  };

  const iconInfo = getIconInfo();
  if (iconInfo === null) {
    return (
      <StarFilled
        style={{
          // eslint-disable-next-line no-inline-styles/no-inline-styles
          color: '#F2C81A',
          width,
          height,
        }}
      />
    );
  }

  return <Icon info={iconInfo} size={size} />;
};
