import {
  ReactNode,
  createContext, useContext,
  useState
} from 'react';
import { message } from 'antd';
import { isEmpty } from 'lodash';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import { useClinicEventGet, useZoomEndMeeting } from '../../uc-api-sdk';
import { ZoomNotificationComponent } from '../../features/zoom/component/ZoomNotificationComponent/ZoomNotificationComponent';
import useChangePage from '../../hooks/useChangePage/useChangePage';
import { OutstandingEnum } from '../../features/outstanding/constant/outstandingOrder';
import { ZoomEntry, ZoomVideoComponent } from '../../features/zoom/component/ZoomVideoComponent/ZoomVideoComponent';
import { ApiRequestHelper } from '../../helpers/ApiRequest';
import { useUpdate } from '../UpdateContext/UpdateContext';
import { useCalendarNotificationContext } from '../CalendarNotificationContext/CalendarNotificationContext';

export interface ZoomStatus {
  [clinicEventId: string]: boolean; // true means notification for visit received
}

export interface ZoomContextValue {
  openNotification: (clinicEventId: string) => void;
  closeNotification: (clinicEventId?: string | null) => void;
  goCheckIn: (patientId: string, clinicEventId?: string | null) => void;
  openZoom: (zoomEntry: ZoomEntry) => void;
  endZoom: (onEnded?: () => void) => void;
}

const ZoomContext = createContext<
  ZoomContextValue | undefined
>(undefined);

export const useZoomContext = () => {
  const context = useContext(ZoomContext);
  if (!context) throw new Error('ZoomContext not found');
  return context as ZoomContextValue;
};

export interface ZoomContextProviderProps {
  children: ReactNode;
}
export const ZoomContextProvider = ({
  children,
}: ZoomContextProviderProps) => {
  const {
    makeCalendarNotification,
    closeNotification,
  } = useCalendarNotificationContext();
  const [
    activeZoomEntry,
    setActiveZoomEntry,
  ] = useState<ZoomEntry>();
  const { goToCarePortalPatient } = useChangePage();
  const clinicEventGetInfo = useClinicEventGet({ options: { sendOnMount: false } });
  const endMeetingInfo = useZoomEndMeeting({});
  const visitVideoStatusHook = useUpdate('VIDEO_VISIT_STATUS_UPDATED');
  const visitVideoEndedHook = useUpdate('VIDEO_VISIT_ENDED');

  const updateZoomStatus = (
    status: 'VIDEO_VISIT_STATUS_UPDATED' | 'VIDEO_VISIT_ENDED',
  ) => {
    switch (status) {
      case 'VIDEO_VISIT_STATUS_UPDATED':
        visitVideoStatusHook.updateValue();
        break;
      case 'VIDEO_VISIT_ENDED':
        visitVideoEndedHook.updateValue();
        break;
      default:
        break;
    }
  };

  const handleCloseNotification = (clinicEventId?: string | null) => {
    if (!clinicEventId) return;
    closeNotification(clinicEventId);
  };

  const goCheckIn: ZoomContextValue['goCheckIn'] = (
    patientId,
    clinicEventId,
  ) => {
    handleCloseNotification(clinicEventId);
    goToCarePortalPatient({
      patientId,
      outstanding: OutstandingEnum.VISITS,
    }, {
      showOutstandingDrawer: true,
      focusVisit: clinicEventId,
    });
  };

  const openNotification: ZoomContextValue['openNotification'] = async (
    clinicEventId,
  ) => {
    ApiRequestHelper.tryCatch(
      clinicEventGetInfo.send({
        params: { id: clinicEventId },
      }),
      {
        success: '',
        error: '',
        onSuccess: (res) => {
          updateZoomStatus('VIDEO_VISIT_STATUS_UPDATED');
          const {
            patientInfo,
            visitType,
            startTime,
            endTime,
            checkInTime,
            checkOutTime,
          } = res?.data || {};
          if (checkInTime || checkOutTime) return;
          makeCalendarNotification({
            id: clinicEventId,
            visitType,
            startTime,
            endTime,
            patientInfo,
          }, {
            description: (
              <ZoomNotificationComponent
                goCheckIn={goCheckIn}
                id={clinicEventId}
                visitType={visitType}
                startTime={startTime}
                endTime={endTime}
                patientInfo={patientInfo}
              />
            ),
          });
        }
      }
    );
  };

  const openZoom: ZoomContextValue['openZoom'] = async ({
    clinicEventId,
    meetingId,
    password,
  }: ZoomEntry) => {
    if (!meetingId || !password) {
      message.error('Invalid zoom entry');
      return;
    }
    if (activeZoomEntry && activeZoomEntry.meetingId === meetingId) {
      message.info('Zoom started!');
      return;
    }
    setActiveZoomEntry({ clinicEventId, meetingId, password });
  };

  const endZoom: ZoomContextValue['endZoom'] = (onEnded) => {
    if (isEmpty(activeZoomEntry)) return;
    ApiRequestHelper.tryCatch(
      endMeetingInfo.send({
        params: { meetingId: activeZoomEntry.meetingId }
      }),
      {
        success: '',
        error: 'Failed to end meeting.',
        onSuccess: () => {
          setActiveZoomEntry(undefined);
          onEnded?.();
        }
      }
    );
  };

  const handleOnEnded = () => {
    setActiveZoomEntry(undefined);
    updateZoomStatus('VIDEO_VISIT_ENDED');
  };

  const contextValue = useGetContextValue<ZoomContextValue>({
    goCheckIn,
    openNotification,
    closeNotification: handleCloseNotification,
    openZoom,
    endZoom,
  });

  return (
    <ZoomContext.Provider value={contextValue}>
      {children}
      {
        activeZoomEntry && (
          <ZoomVideoComponent
            entry={activeZoomEntry}
            onEnded={handleOnEnded}
          />
        )
      }
    </ZoomContext.Provider>
  );
};
