import { EditOutlined } from '@ant-design/icons';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { DeviceDrawerComponent } from '../DeviceDrawerComponent/DeviceDrawerComponent';
import { VitalEnumType } from '../../../../uc-api-sdk';

export interface DeviceEditButtonComponentProps {
  patientId: string;
  vitalType: VitalEnumType;
}

export const DeviceEditButtonComponent = ({
  patientId,
  vitalType,
}: DeviceEditButtonComponentProps) => {
  const {
    isOpen,
    open,
    close,
  } = useOpen();

  return (
    <div className="flex relative">
      <EditOutlined onClick={open} />
      <DeviceDrawerComponent
        patientId={patientId}
        vitalType={vitalType}
        open={isOpen}
        onClose={close}
      />
    </div>
  );
};
