/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import { FC } from 'react';
import { Icon as NewIconComponent, IconProps as NewIconProps } from '../../icons/Icon';
import './Icon.scss';

export interface ImgInfo {
  src: string;
  alt: string;
}

export interface IconProps {
  img: ImgInfo;
  className?: string;
  onClick?: NewIconProps['onClick'];
  originalSize?: boolean;
}

export const Icon: FC<IconProps> = ({
  img,
  className,
  onClick,
  originalSize = false,
}) => (
  <NewIconComponent
    src={img.src}
    alt={img.alt}
    className={
      classNames({
        icon: true,
        [className || '']: true,
        pointer: !!onClick,
        'non-original-size': !originalSize,
      })
    }
    onClick={onClick}
  />
);

export default Icon;
