import { ReactNode } from 'react';
import { Drawer } from 'antd';
import { LargeDrawerWidth } from '../../../../constants/drawer';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { ConsentFormContainer } from '../../container/ConsentFormContainer/ConsentFormContainer';
import { PreviewConsentDrawerComponent } from '../PreviewConsentComponent/PreviewConsentDrawerComponent';
import { ConsentFormFetchContainerProps } from '../../container/ConsentFormFetchContainer/ConsentFormFetchContainer';

export interface PreviewAndResignConsentComponentProps {
  patientInfo: PatientInfo;
  children: ReactNode;
  onResign?: () => void;
  fetchByContainer?: ConsentFormFetchContainerProps['fetchByContainer'];
}

export const PreviewAndResignConsentComponent = ({
  patientInfo,
  children,
  onResign,
  fetchByContainer,
}: PreviewAndResignConsentComponentProps) => {
  const {
    isOpen,
    open,
    close,
  } = useOpen();

  const handleOnSubmit = () => {
    close();
    onResign?.();
  };

  return (
    <>
      <PreviewConsentDrawerComponent
        patientInfo={patientInfo}
        onResignOpen={open}
        fetchByContainer={fetchByContainer}
      >
        {children}
      </PreviewConsentDrawerComponent>
      <Drawer
        open={isOpen}
        onClose={close}
        width={LargeDrawerWidth}
        destroyOnClose
      >
        <ConsentFormContainer
          patientInfo={patientInfo}
          showSentModal
          onSubmit={handleOnSubmit}
        />
      </Drawer>
    </>
  );
};
