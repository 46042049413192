/* eslint-disable import/no-extraneous-dependencies */
import './ZoomVideoComponent.scss';
import ZoomMtgEmbedded, { ExecutedFailure, SuspensionViewType } from '@zoomus/websdk/embedded';
import { useState, useCallback, useRef } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { isEmpty } from 'lodash';
import { Spin, message } from 'antd';
import classNames from 'classnames';
import { ZoomCredential, useZoomGetRealTimeSignature } from '../../../../uc-api-sdk';
import { ConnectionChangePayload } from '../../type';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { useRetry } from '../../../../hooks/useRetry/useRetry';
import { DraggableComponent, DraggableComponentProps } from '../../../../uiComponent/DraggableComponent/DraggableComponent';
import { useOriginTrial } from '../../../../hooks/useOriginTrial/useOriginTrial';
import EnvConfig from '../../../../configs/envConfig/envConfig';

export const meetingSDKElementId = 'meetingSDKElement';
// recommended min and max sizes,
// https://developers.zoom.us/docs/meeting-sdk/web/component-view/resizing/
export const zoomVideoWidth = 720;
export const zoomVideoHeight = 540;

export interface ZoomEntry {
  clinicEventId: string;
  meetingId: string;
  password: string;
}

export interface ZoomVideoComponentProps {
  entry: ZoomEntry;
  onJoined?: () => void;
  onEnded?: () => void;
}

export const ZoomVideoComponent = ({
  entry: {
    meetingId,
    password,
  },
  onJoined,
  onEnded,
}: ZoomVideoComponentProps) => {
  const { userInfo } = useLoggedInUserFromContext();
  const client = useRef(ZoomMtgEmbedded.createClient()).current;
  const [isJoined, setIsJoined] = useState(false);
  const getSignatureInfo = useZoomGetRealTimeSignature({
    params: { meetingId },
    options: { sendOnMount: !!meetingId },
  });
  const { retry, resetRetry } = useRetry({ retryAttempts: 15, delay: 1000 });
  const [currentY, setCurrentY] = useState(0);
  const {
    mount: mountTrial,
    unmount: unmountTrial,
  } = useOriginTrial(EnvConfig.sharedArrayBufferTrial);

  const cleanUp = () => {
    const scriptBlocks = document.querySelectorAll('script[src^="https://source.zoom.us"]');
    scriptBlocks?.forEach((scriptBlock) => {
      scriptBlock.remove();
    });
  };

  const endZoom = () => {
    unmountTrial();
    onEnded?.();
  };

  const onConnectionChange = useCallback((payload: ConnectionChangePayload) => {
    switch (payload.state) {
      case 'Connected': {
        setIsJoined(true);
        onJoined?.();
        break;
      }
      case 'Closed': {
        endZoom();
        break;
      }
      default:
    }
  }, []);

  const handleOnLeave = () => {
    client.leaveMeeting();
    client.off('connection-change', onConnectionChange);
    ZoomMtgEmbedded.destroyClient();
  };

  const handleOnEnded = () => {
    handleOnLeave();
    endZoom();
  };

  const startMeeting = async (zoomCredential: ZoomCredential) => {
    if (isEmpty(zoomCredential)) return;
    try {
      await client.join({
        signature: zoomCredential?.signature || '',
        sdkKey: zoomCredential?.clientId || '',
        meetingNumber: meetingId,
        password,
        userName: userInfo?.fullNameWithTitle || '',
      });

      // simulate automatically join with computer audio
      const autoJoinBtn = document.querySelector('button[title*="Audio"]') as HTMLDivElement;
      autoJoinBtn?.click();
      resetRetry();
    } catch (error) {
      let errorMessage = (error as Error).message;
      if ((error as ExecutedFailure).reason) {
        errorMessage = (error as ExecutedFailure).reason;

        if (errorMessage.startsWith('Signature is invalid.')) {
          const retryResult = retry(() => {
            cleanUp();
            startMeeting(zoomCredential);
          });
          if (retryResult) return;
          errorMessage = `${errorMessage} Please try again.`;
        }
      }
      message.error(`Failed to join zoom. ${errorMessage}`);
      handleOnEnded();
    }
  };

  const handleOnDragStop: DraggableComponentProps['onStop'] = (e, data) => {
    const { y } = data;
    setCurrentY(y);
  };

  useUpdateEffect(() => {
    if (getSignatureInfo.data?.data?.signature) {
      mountTrial();
      const meetingScreen = document.getElementById(meetingSDKElementId) as HTMLDivElement;
      client.init({
        zoomAppRoot: meetingScreen,
        language: 'en-US',
        customize: {
          video: {
            defaultViewType: 'gallery' as SuspensionViewType,
            popper: {
              disableDraggable: true,
            },
            viewSizes: {
              default: {
                width: zoomVideoWidth,
                height: zoomVideoHeight,
              }
            }
          }
        },
      });
      client.off('connection-change', onConnectionChange);
      client.on('connection-change', onConnectionChange);
      const zoomCredential = getSignatureInfo.data?.data;
      startMeeting(zoomCredential);
    }
  }, [getSignatureInfo.data?.data]);

  useUpdateEffect(() => () => {
    // on unmount
    handleOnEnded();
    cleanUp();
  }, [isJoined]);

  return (
    <div
      className={classNames({
        'zoom-video': true,
        loading: !isJoined,
      })}
    >
      {
        !isJoined && (
          <div className="zoom-loader">
            <div className="zoom-loader-content">
              <div>Loading zoom video, please wait</div>
              <Spin />
            </div>
          </div>
        )
      }
      <DraggableComponent
        handle="[class*='zmwebsdk-makeStyles-leftButtonsContainer']"
        cancel="[class*='zmwebsdk-makeStyles-leftButtonsContainer'] > button"
        className="zoom-video-draggable-component"
        defaultPosition={{
          x: (
            (window.innerWidth > zoomVideoWidth)
              ? (window.innerWidth / 2) - (zoomVideoWidth / 2)
              : 0
          ),
          y: currentY,
        }}
        onStop={handleOnDragStop}
      >
        <div
          id={meetingSDKElementId}
          style={{
            // @ts-ignore
            '--zoom-max-height': `calc(90vh - ${currentY}px)`,
          }}
        />
      </DraggableComponent>
    </div>
  );
};
