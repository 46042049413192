import { Dayjs } from 'dayjs';
import { USA_DATE } from '../../../../constants/timeFormat';
import { HSChartData } from './HSChartComponent';
import { Point } from '../../type';
import TimezoneService from '../../../../helpers/timezone/timezoneService';

export interface HSChartSeriesDataInfo<T extends Point> {
  data: T[];
}

export const createMarker = (data: HSChartData) => ({
  symbol: data.resultSource === 'vital' ? 'circle' : 'triangle',
  halo: null,
  radius: 6,
  fillColor: data.resultSource === 'vital' ? '#7cb5ec' : '#34c935',
  states: {
    hover: {
      lineColor: 'white',
      lineWidth: 0,
      halo: null,
      animation: {
        duration: 20,
      },
    },
  },
});

export const createHSChartSeries = <T extends Point>(info: HSChartSeriesDataInfo<T>) => ([
  {
    data: info.data,
    name: 'Vital',
    states: {
      hover: {
        halo: null,
      },
    },
    findNearestPointBy: 'xy',
    linkedTo: 'visit',
  },
  {
    data: [],
    id: 'visit',
    name: 'Clinic Visit',
    marker: {
      symbol: 'triangle',
      fillColor: '#34c935',
    },
    events: {
      legendItemClick() {
        return false;
      },
    },
  },
]);

export const createHSChartOptions = (
  series: ReturnType<typeof createHSChartSeries>,
  fromDate: Dayjs,
  toDate: Dayjs,
) => ({
  chart: {
    type: 'line',
  },
  title: null,
  noData: 'No Data',
  tooltip: {
    headerFormat: '',
    backgroundColor: '#FFFFFF',
    borderColor: '#D9D9D9',
    // @ts-ignore
    pointFormatter() {
      const {
        // @ts-ignore
        y, category, weightVariation, notes,
      } = this;
      const date: string = TimezoneService.calcDateTimeDayjs(category).format('MM/DD/YYYY');
      return `
        Date: <b>${date}</b><br />
        Weight: <b>${y} lb</b><br />
        Weight Variation: <b>${weightVariation} lb</b><br />
        Notes: <b>${notes}</b>
      `;
    },
    padding: 12,
    style: {
      fontSize: 12,
      width: '200px',
    },
    dateTimeLabelFormats: {
      day: '%m/%e/%Y',
    },
  },
  plotBackgroundColor: '#0099FF',
  legend: {
    align: 'left',
    verticalAlign: 'top',
  },
  xAxis: {
    type: 'datetime',
    tickWidth: 2,
    tickInterval: 24 * 3600 * 1000,
    min: fromDate.valueOf(),
    max: toDate.valueOf(),
    labels: {
      formatter: (xAxis: { value: Date; }) => (
        TimezoneService.calcDateTimeDayjs(xAxis.value).format(USA_DATE)
      ),
    },
    crosshair: {
      width: '1',
      color: '#00A3FF',
    },
  },
  yAxis: {
    title: {
      text: 'Pound',
    },
    min: 100,
    gridLineDashStyle: 'Dash',
  },
  credits: {
    enabled: false,
  },
  time: {
    useUTC: false,
    timezone: TimezoneService.guessTimezone(),
  },
  series,
});
