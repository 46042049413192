/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import { debounce, DebounceSettings } from 'lodash';
import { DEFAULT_DEBOUNCE_TIME } from '../../constants/timers';

export const useDebounce = <T extends (...args: any) => any>(
  func: T,
  debounceTime: number = DEFAULT_DEBOUNCE_TIME,
  deps: any[] = [],
  debounceSettings: DebounceSettings = {},
) => useCallback(debounce(func, debounceTime, debounceSettings) as unknown as T, [...deps]);

export default useDebounce;
