import { ColumnType } from 'antd/es/table';
import { BaseMeasurement } from '../../helper/MeasurementHelper';
import { MeasurementDateComponent } from './MeasurementDateComponent';

export const measurementTableDateColumnMaker = <T extends BaseMeasurement>(
  width?: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dateFormat?: string,
) => (
    (): ColumnType<T> => ({
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (v) => <MeasurementDateComponent value={v} />,
      defaultSortOrder: 'descend',
      // sorter: (a, b) => moment(a.date).valueOf() - moment(b.date).valueOf(),
      showSorterTooltip: false,
      width,
      className: 'ta-c',
    })
  );
