import { useCallback, useMemo } from 'react';
import { filter, values } from 'lodash';
import { FormProps } from 'antd';
import { FormOptions, useFormHookFactory } from '../../../../hooks/useFormHookFactory/useFormHookFactory';
import { DeviceModelEnum } from '../../../../uc-api-sdk';
import { GetFieldValue } from '../../../../types/form';
import { DeviceHelper } from '../../../device/helper';

export const useAssignDeviceInfoForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    deviceId: {
      name: 'deviceId',
      label: 'Device ID',
    },
    deviceModel: {
      name: 'deviceModel',
      label: '',
    },
    deviceMethod: {
      name: 'deviceMethod',
      label: '',
    },
    deviceLanguage: {
      name: 'deviceLanguage',
      label: 'Device Language',
    },
    bpReadOut: {
      name: 'bpReadOut',
      label: 'Read out BP Reading (Available in EN/FR)',
    },
    cuffSize: {
      name: 'cuffSize',
      label: 'Choose a cuff size for patient',
    },
  }, options);

  const BPMDeviceModels = [
    DeviceModelEnum.BPM1,
    DeviceModelEnum.BPM1_HOTSPOT,
  ];

  const shouldShowLanguageAndBPReadOut = useCallback((
    deviceModel: DeviceModelEnum,
  ) => (
    BPMDeviceModels.includes(deviceModel)
  ), []);

  const shouldSupportBpReadOutByLanguage = useCallback((
    selectedLanguage: string,
  ) => {
    const option = filter(values(DeviceHelper.BPM1DeviceLanguage), (v) => (
      selectedLanguage === v.value
    ))[0];
    return option?.supportVolume;
  }, [factory]);

  const shouldDisableBpReadOut = useCallback((
    getFieldValue: GetFieldValue,
  ) => {
    const selectedLanguage = factory.getValue('deviceLanguage', getFieldValue);
    return !shouldSupportBpReadOutByLanguage(selectedLanguage);
  }, [factory]);

  const onBPM1ValuesChange = useCallback<Exclude<FormProps['onValuesChange'], undefined>>((
    changedValues,
  ) => {
    const deviceLanguage = changedValues[factory.getName('deviceLanguage')];
    if (deviceLanguage && !shouldSupportBpReadOutByLanguage(deviceLanguage)) {
      factory.form.setFieldsValue({
        [factory.getName('bpReadOut')]: false
      });
    }
  }, []);

  const response = useMemo(() => ({
    ...factory,
    shouldShowLanguageAndBPReadOut,
    shouldSupportBpReadOutByLanguage,
    shouldDisableBpReadOut,
    onBPM1ValuesChange
  }), [
    factory,
  ]);

  return response;
};
