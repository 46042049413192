import { useFormHookFactory } from '../../../../hooks/useFormHookFactory/useFormHookFactory';

export const useLifestyleForm = () => {
  const factory = useFormHookFactory({
    activityLevel: {
      name: 'activityLevel',
      label: 'Physical Activity Level',
    },
    howOften: {
      name: 'howOften',
      label: 'How often',
      required: false,
    },
    activityDuration: {
      name: 'activityDuration',
      label: '  ',
      required: false,
    },
    sleepingRoutine: {
      name: 'sleepingRoutine',
      label: 'How often do you get 7-8 hours of sleep?',
      required: false,
    },
    eatingRoutine: {
      name: 'eatingRoutine',
      label: 'Eating Routine',
    },
    foodAccessibility: {
      name: 'foodAccessibility',
      label: 'Food Accessibility',
    },
    smoke: {
      name: 'smoke',
      label: 'Does Patient Smoke?',
      required: true,
    },
    howOftenSmoke: {
      name: 'howOftenSmoke',
      label: 'How often',
      required: true,
    },
    smokeQuitYear: {
      name: 'smokeQuitYear',
      label: 'Quit Year',
      required: true,
    },
    drink: {
      name: 'drink',
      label: 'Does Patient Drink?',
      required: true,
    },
    howOftenDrink: {
      name: 'howOftenDrink',
      label: 'How often',
      required: true,
    },
    drinkQuantity: {
      name: 'drinkQuantity',
      label: 'Quantity',
      required: false,
    },
    drinkChoice: {
      name: 'drinkChoice',
      label: 'Choice',
      required: false,
    },
    drinkQuitYear: {
      name: 'drinkQuitYear',
      label: 'Quit Year',
      required: true,
    },
    lifestylePattern: {
      name: 'lifestylePattern',
      label: 'Typical Lifestyle Pattern',
      required: true,
    },
  });

  return factory;
};
