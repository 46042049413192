import { map } from 'lodash';
import { PageInfo, Sort$Direction, SortInfo } from '../../uc-api-sdk';
import { BasePageInfo, BaseSortDirection, BaseSortInfo } from '../../uc-api-sdk/staticFiles/useReqHook';
import { DEFAULT_PAGINATION } from '../../constants/pagination';

/**
   * used for API like MakeRequestHookConfig<BaseSearchParams<...
   *
   * @remarks  no need for API like this MakeRequestHookConfig<TranscribeSearchVisitParams...
   *           But API like this MakeRequestHookConfig<BaseSearchParams<...
   * @param sorter SortInfo
   * @param pageInfo PageInfo
   * @return BasePageInfo {
      page: number;
      size: number;
      sort?: BaseSortInfo[];
      pagination?: boolean;}
 */
export const baseAPIHelper = (sorter?: SortInfo[], pageInfo?: PageInfo) => {
  const convertedSort:
  BaseSortInfo[] | undefined = map(sorter, (v) => ({
    direction: v.direction === Sort$Direction.DESC ? BaseSortDirection.DESC : BaseSortDirection.ASC,
    property: v.property,
  } as BaseSortInfo));

  const convertedPage: BasePageInfo = {
    page: pageInfo?.page ?? 1,
    size: pageInfo?.size ?? DEFAULT_PAGINATION.pageSize,
    sort: convertedSort,
  };
  return convertedPage;
};
