import { upperFirst } from 'lodash';
import { FC, useMemo } from 'react';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { BPTooltips } from '../../tooltip/BPTooltip';
import { BPSummaryKey } from '../../type';

export interface BPSummaryTypeComponentProps {
  type: BPSummaryKey;
}

export const BPSummaryTypeComponent: FC<BPSummaryTypeComponentProps> = ({
  type,
}) => {
  const value = useMemo(() => {
    switch (type) {
      case 'criticalHigh': return 'Critical High';
      case 'hypertension1': return 'Stage 1 Hypertension';
      case 'hypertension2': return 'Stage 2 Hypertension';
      case 'arrhythmia': return 'IHB';
      case 'pulsePressure': return (
        <TooltipComponent type="dashed" overlay={BPTooltips.pulsePressure}>
          Pulse Pressure
        </TooltipComponent>
      );
      default: return upperFirst(type);
    }
  }, [type]);

  return (
    <div>
      {value}
    </div>
  );
};
