import { map } from 'lodash';
import { Checkbox } from 'antd';
import { useEffect } from 'react';
import { CheckboxGroupProps, CheckboxValueType } from 'antd/lib/checkbox/Group';
import { InputTypeArray } from '../../../Input/types';
import { MedicalAlertResult } from '../../../../uc-api-sdk';
import { MedicalAlertComponent } from '../MedicalAlertComponent/MedicalAlertComponent';

export interface MedicalAlertCheckboxGroupComponentProps
extends InputTypeArray<MedicalAlertResult> {
  medicalData?: MedicalAlertResult[];
}

export const MedicalAlertCheckboxGroupComponent = ({
  value,
  medicalData,
  onChange,
}: MedicalAlertCheckboxGroupComponentProps) => {
  const handleOnChange: CheckboxGroupProps['onChange'] = (checkedValue) => {
    onChange?.(checkedValue as unknown as MedicalAlertResult[]);
  };

  useEffect(() => {
    onChange?.(medicalData as MedicalAlertResult[]);
  }, []);

  return (
    <Checkbox.Group
      value={value as unknown as CheckboxValueType[]}
      onChange={handleOnChange}
      className="display-block"
    >
      {
        map(medicalData, (k, index) => (
          <MedicalAlertComponent
            medicalAlertResult={k}
            key={index}
          />
        ))
      }
    </Checkbox.Group>
  );
};
