// @ts-ignore
import React, { FC } from 'react';
import classNames from 'classnames';
import { Avatar as AntdAvatar, AvatarProps as BaseAvatarProps } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { StringHelper } from '../../helpers/string';
import './Avatar.scss';

export interface AvatarProps extends Omit<BaseAvatarProps, 'size'> {
  name?: string;
  size?: BaseAvatarProps['size'] | 'x-small' | 'select';
}

export const Avatar: FC<AvatarProps> = ({
  size,
  name,
  className = '',
  ...props
}) => {
  const getSize = () => {
    switch (size) {
      case 'select': return 18;
      case 'x-small': return 24;
      default: return size;
    }
  };

  return (
    <AntdAvatar
      icon={name ? null : <UserOutlined />}
      alt={name}
      size={getSize()}
      className={classNames({
        'avatar-component': true,
        'show-color': !!name,
        [className]: !!className,
      })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {name && StringHelper.getInitials(name)}
    </AntdAvatar>
  );
};

export default Avatar;
