import dayjs, { Dayjs } from 'dayjs';
import { map } from 'lodash';
import { ChartingStatusEnum } from '../../../../../enumComponent/ChartingStatusEnumComponent/ChartingStatusEnum';
import { VisitsStatusEnum } from '../../../../../enumComponent/VisitsStatusEnumComponent/VisitsStatusEnum';
import TimezoneService from '../../../../../helpers/timezone/timezoneService';
import {
  CalendarSourceEnum,
  ClinicEvent,
  VisitChartingStatusEnum,
  VisitMethodEnum,
  VisitParticipantClass,
  VisitStatusEnum
} from '../../../../../uc-api-sdk';

export class ClinicEventsService {
  protected readonly value: ClinicEvent;

  constructor(value: ClinicEvent) {
    this.value = value;
  }

  public static calcTime(value: string | null | undefined, nullable = true) {
    if (
      value !== undefined
      && value !== null
    ) {
      return TimezoneService.calcDateTimeDayjs(
        value as string,
      );
    }
    if (nullable) {
      return undefined;
    }
    console.error('DateTime is missing!');
    return dayjs().startOf('year');
  }

  static ifUserParticipant = (
    visitParticipantsId: string[],
    userId?: string,
  ) => (
    userId && !!visitParticipantsId?.includes(userId)
  );

  get self() {
    return this.value;
  }

  get id() {
    return this.value.id ?? undefined;
  }

  get calendarEventId() {
    return this.value.calendarEventId ?? undefined;
  }

  get visitType() {
    return this.value.visitType ?? undefined;
  }

  get transcribeId() {
    return this.value.transcribeId ?? undefined;
  }

  get startTimeUtc() {
    return this.value.startTime;
  }

  get startTime() {
    return ClinicEventsService.calcTime(this.value.startTime, false) as Dayjs;
  }

  get endTime() {
    return ClinicEventsService.calcTime(this.value.endTime, false) as Dayjs;
  }

  get isCheckedIn() {
    return this.value.checkInTime !== null;
  }

  get checkInTime() {
    return ClinicEventsService.calcTime(this.value.checkInTime, true);
  }

  get checkOutTime() {
    return ClinicEventsService.calcTime(this.value.checkOutTime, true);
  }

  get visitMethod() {
    return this.value.visitMethod ?? undefined;
  }

  get visitParticipants() {
    if (
      this.value.visitParticipants !== undefined
      && this.value.visitParticipants !== null
    ) {
      return map(this.value.visitParticipants, (v) => new VisitParticipantClass(v));
    }
    return [];
  }

  get visitParticipantsId() {
    return map(this.visitParticipants, (v) => v.participantId as string)
      .filter((v) => v);
  }

  get description() {
    return this.value.description || '';
  }

  get transcribeStatus() {
    return this.value.transcribeStatus ?? undefined;
  }

  get visitsStatusLegacy() {
    const currentTime = dayjs();
    const visitStartTime = this.startTime;
    const visitCheckInTime = this.checkInTime;
    const visitCheckOutTime = this.checkOutTime;

    if (
      visitStartTime
      && visitCheckInTime
      && !visitCheckOutTime
    ) {
      return VisitsStatusEnum.ON_GOING;
    }

    if (
      visitStartTime
      && TimezoneService.calcDateTimeDayjs(visitStartTime).isAfter(currentTime)
      && !visitCheckInTime
      && !visitCheckOutTime
    ) {
      return VisitsStatusEnum.UPCOMING;
    }

    if (
      visitStartTime
      && TimezoneService.calcDateTimeDayjs(visitStartTime).isBefore(currentTime)
      && !visitCheckInTime
    ) {
      return VisitsStatusEnum.NO_SHOW;
    }

    if (
      visitStartTime
      && visitCheckOutTime
    ) {
      return VisitsStatusEnum.CHECKED_OUT;
    }

    return null;
  }

  get visitsStatus() {
    return this.value.visitStatus;
  }

  get chartingStatus() {
    if (this.value.visitChartingStatus === VisitChartingStatusEnum.DONE) {
      return ChartingStatusEnum.CHARTING;
    }

    // if (this.visitsStatus === VisitsStatusEnum.ON_GOING) {
    //   return ChartingStatusEnum.NO_CHARTING;
    // }
    return ChartingStatusEnum.NO_CHARTING;
  }

  get clinicInfo() {
    return this.value.clinicInfo;
  }

  get patientInfo() {
    return this.value.patientInfo ?? undefined;
  }

  get patientId() {
    return this.value.patientId ?? undefined;
  }

  get calendarSource() {
    return this.value.calendarSource ?? undefined;
  }

  get summary() {
    return this.value.summary ?? undefined;
  }

  get visitSummary() {
    return this.value.visitSummary ?? undefined;
  }

  get isCompleted() {
    return this.chartingStatus === ChartingStatusEnum.CHARTING;
  }

  get isCheckedOut() {
    return this.value.visitStatus === VisitStatusEnum.CHECKED_OUT;
  }

  get isOnGoing() {
    return this.value.visitStatus === VisitStatusEnum.ONGOING;
  }

  get followupVisitSkipped() {
    return this.value.followupVisitSkipped ?? false;
  }

  get onboardingChecklist() {
    return this.value.onboardingChecklist || [];
  }

  get isVideoVisit() {
    return this.visitMethod === VisitMethodEnum.VIRTUAL;
  }

  get zoomEntry() {
    const clinicEventId = this.value.id;
    const { meetingNumber, password } = this.value.zoomCredential || {};
    return {
      clinicEventId: clinicEventId || '',
      meetingId: meetingNumber || '',
      password: password || '',
    };
  }

  get zoomStatus() {
    return this.value.zoomStatus;
  }

  get otherEventTitle() {
    return this.value.eventTitle;
  }

  get isOtherEvent() {
    return this.value.calendarSource === CalendarSourceEnum.OTHER;
  }

  get isEditable() {
    return !this.isCheckedIn && this.startTime.isAfter(dayjs().startOf('D'));
  }
}
