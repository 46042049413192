import { Dropdown, DropDownProps } from 'antd';
import { FC, useEffect, useState } from 'react';
import { OPTION_DOTS_ICON } from '../../assets/commonIconInfo';
import Icon from '../Icon/Icon';
import './DropdownComponent.scss';

export interface DropdownComponentProps extends DropDownProps {
  stopPropagationOnClick?: boolean;
  openState?: boolean;
  setOpenState?: (val: boolean) => void;
  loading?: boolean;
}

export const DropdownComponent: FC<DropdownComponentProps> = ({
  trigger = ['click'],
  children,
  stopPropagationOnClick = true,
  className,
  openState,
  setOpenState,
  loading,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(!!openState);
  }, [openState]);

  return (
    <Dropdown.Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={`dropdown-component ${className || ''}`}
      trigger={trigger}
      open={open}
      onOpenChange={(isOpen) => {
        setOpen(isOpen);
        setOpenState?.(isOpen);
      }}
      loading={loading}
      icon={children || (
        <Icon
          img={OPTION_DOTS_ICON}
          className="tree-action-icon"
          originalSize
          onClick={(e) => {
            e.preventDefault();
            if (stopPropagationOnClick) {
              e.stopPropagation();
            }
            setOpen(true);
            setOpenState?.(true);
          }}
        />
      )}
    />
  );
};

export default DropdownComponent;
