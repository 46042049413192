import { HsStat } from '../../../../uc-api-sdk';
import { useHSResultToSummaryTable } from '../../hook/useHSResultToSummaryTable/useHSResultToSummaryTable';
import { HSSummaryTableComponent } from './HSSummaryTableComponent';

export interface HSSummaryTableDataProcessorComponentProps {
  data: HsStat;
}

export const HSSummaryTableDataProcessorComponent = ({
  data
}: HSSummaryTableDataProcessorComponentProps) => {
  const processedData = useHSResultToSummaryTable(data);
  return <HSSummaryTableComponent data={processedData} />;
};
