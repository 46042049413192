import React, { FC, useContext } from 'react';
import { useCreateVisitForm } from '../../../visit/hook/useCreateVisitForm';
import { CalendarEvent } from '../../component/BigCalendarComponent/BigCalendarComponent';
import { ClinicEvent } from '../../../../uc-api-sdk';

export interface CreateVisitValue {
  selectedEvent?: CalendarEvent<ClinicEvent>;
  tempEvent?: CalendarEvent<ClinicEvent>;
  setTempEventAsSelectedEvent: (value: boolean) => void;
  form: ReturnType<typeof useCreateVisitForm>;
  removeNewEvent: () => void;
  isFieldsTouched: () => boolean;
}

export const CreateVisitContext = React.createContext<CreateVisitValue | undefined>(undefined);

export interface CreateVisitProviderProps {
  info?: CreateVisitValue;
  children: React.ReactNode;
}

export const CreateVisitProvider: FC<CreateVisitProviderProps> = ({
  info,
  children,
}) => (
  <CreateVisitContext.Provider value={info}>
    {children}
  </CreateVisitContext.Provider>
);

export const useCreateVisitContext = () => {
  const VisitContextValue = useContext(CreateVisitContext);
  return VisitContextValue;
};
