import { useEffect, useMemo, useState } from 'react';
import { Dayjs } from 'dayjs';
import { Moment } from 'moment';
import {
  Button,
  Divider,
  Form, FormProps, Spin,
} from 'antd';
import { Store } from 'antd/lib/form/interface';
import _, { get } from 'lodash';
import {
  EditLabResultMode, LabResultEditableItem, getRange,
} from '../../constants';
import { useCreateEditLabResultForm } from '../../hook/useCreateEditLabResultForm';
import { TranscribeLabResultFormItemComponent } from '../TranscribeLabResultFormItemComponent/TranscribeLabResultFormItemComponent';
import { LabResultDetailTableComponent } from '../LabResultDetailTableComponent/LabResultDetailTableComponent';
import FormSubmitButton from '../../../../uiComponent/FormSubmitButton/FormSubmitButton';
import { LabResult, LabResultItem, LabResultTemplateItem } from '../../../../uc-api-sdk';
import { LabResultDeleteButtonContainer } from '../../container/LabResultTableContainer/LabResultDeleteButtonContainer';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { USA_DATE } from '../../../../constants/timeFormat';

export interface SubmitValue {
  testDate: Dayjs;
  templateName?: string;
  item?: LabResultItem[];
  lab?: LabResult;
}

export interface FormButtonsProps {
  onCancel?: () => void;
}

export interface CreateEditLabResultFormComponentProps {
  id?: string;
  labResultItems?: LabResultItem[];
  initialValues?: FormProps['initialValues'];
  onFinish?: FormProps['onFinish'],
  mode?: EditLabResultMode;
  isLoading: boolean;
  template?: string;
  onCloseDrawer?: () => void;
  testDate?: Dayjs | Moment | string;
  templateName?: string;
}

export const CreateEditLabResultFormComponent = ({
  id,
  labResultItems,
  initialValues,
  onFinish,
  mode = 'Create',
  isLoading,
  template,
  onCloseDrawer,
  testDate,
  templateName,
}: CreateEditLabResultFormComponentProps) => {
  const [theMode, setMode] = useState<EditLabResultMode | undefined>(mode);

  const [labResultItemValues, setLabResultItemValues] = useState<LabResultItem[]
    | undefined>(labResultItems ?? undefined);
  const [labResultValues, setLabResultValues] = useState<LabResult
    | undefined>(undefined);
  const [labResultTemplateId, setLabResultTemplateId] = useState<string
    | undefined>(template);
  const hook = useCreateEditLabResultForm();
  const { form } = hook;

  const initialTestDateAndTemplate = useMemo(() => ({
    testDate,
    templateName,
  }), [testDate, templateName]);

  useEffect(() => {
    setLabResultItemValues(labResultItems);
  }, [labResultItems]);

  const isValuesChanged = (initValues: Store | undefined, newValues: SubmitValue) => {
    // compare with parent's stored data in results;
    if (!_.isEqual(labResultItemValues, labResultItems)) {
      return true;
    }

    // date has changed
    if (_.isEmpty(newValues)) {
      return false;
    }

    if (newValues.testDate) {
      const newDate = newValues.testDate.format(USA_DATE);
      const currentDate = initValues?.testDate?.format(USA_DATE);
      return newDate !== currentDate;
    }

    return false;
  };

  const onFinishPreCheck = (value: SubmitValue) => {
    // fields are testDate & templateName
    // not including fields in the table(LabResultDetailTableComponent)
    const fields = form.getFieldsValue(true);
    if (isValuesChanged(initialValues, fields)) {
      const newValue = value;

      // write back the flag to item (ReferenceLevelEnum)
      // omit referenceLevels dbKey to submit to server
      newValue.item = labResultItemValues?.map((v) => {
        const r = getRange(Number(v.value), get(v, 'referenceLevels'));
        const newItem = { ...v };
        newItem.flag = r?.description;
        if ((v as LabResultEditableItem).isManual) {
          newItem.flag = v.flag;
        }
        return (
          _.omit(
            newItem,
            ['referenceLevels', 'dbKey']
          )
        );
      });

      // labResultValues includes templateId && templateName when create
      // labResultValues is undefine when edit
      newValue.lab = labResultValues;
      onFinish?.(newValue);
    } else {
      onFinish?.(null);
    }
  };

  const handleSave = (row: LabResultItem) => {
    setLabResultItemValues((prev) => {
      const newData: LabResultItem[] = [...prev || []];
      const theIndex = newData.findIndex((item) => row.dbKey === item.dbKey);
      if (theIndex > -1) {
        const item = newData[theIndex];
        // Replaces 1 element at index 'theIndex'
        newData.splice(theIndex, 1, {
          ...item,
          ...row,
        });
      } else {
        newData.push(row);
      }
      return newData;
    });
  };

  const handleRemove = (index: number) => {
    setLabResultItemValues((prev) => {
      const newData = [...(prev || [])];
      newData.splice(index, 1);
      return newData;
    });
  };

  if (mode !== 'Create' && !initialValues) {
    return (
      <Spin size="large" />
    );
  }

  return (
    <Form
      className="h100"
      initialValues={initialTestDateAndTemplate}
      onFinish={hook.handleSubmitAndReset(onFinishPreCheck)}
      scrollToFirstError
      form={form}
      preserve={false}
      disabled={isLoading}
      layout="vertical"
    >
      <FixedComponent>
        <FixedComponent.Child>
          <TranscribeLabResultFormItemComponent
            onChange={(info?: LabResultTemplateItem[], v?) => {
              // use LabResultTemplateItem'referenceLevels to edit lab
              // dbKey is unique, used for local editing;
              let i = 1;
              const editableItems = info?.map((v) => {
                i += 1;
                const item: LabResultEditableItem = {
                  ...v,
                  dbKey: i.toString(),
                };
                return item;
              });
              setLabResultItemValues(editableItems);
              setLabResultValues(v);
              setLabResultTemplateId(v?.templateId);
            }}
            mode={theMode}
          />
          {labResultTemplateId && (
            <LabResultDetailTableComponent
              labResultItem={labResultItemValues}
              handleSave={handleSave}
              handleRemove={handleRemove}
              template={labResultTemplateId}
              isValueEditable={theMode !== 'View'}
            />
          )}
        </FixedComponent.Child>
        <FixedComponent.Child isFixed>
          <Divider />
          <div className="flex jc-e">
            {(theMode === 'Edit')
              && (
                <div className="flex">
                  {id
                    && (
                      <LabResultDeleteButtonContainer
                        btnType="default"
                        id={id}
                        size="middle"
                      />
                    )}
                  <FormItem noStyle shouldUpdate>
                    {
                      ({ getFieldsValue }) => {
                        const fields = getFieldsValue(true);
                        const shouldBeDisabled = !isValuesChanged(initialValues, fields);
                        return (
                          <FormSubmitButton
                            rightAlign={false}
                            text="Save"
                            isLoading={isLoading}
                            shouldBeDisabled={() => shouldBeDisabled}
                          />
                        );
                      }
                    }
                  </FormItem>
                </div>
              )}
            {(theMode === 'Create' && labResultTemplateId)
              && (
                <FormSubmitButton
                  rightAlign={false}
                  text="Add"
                  shouldBeDisabled={() => isLoading}
                />
              )}
            {(theMode === 'View' && labResultTemplateId)
              && (
                <div className="flex jc-e gap3">
                  <Button
                    type="default"
                    onClick={() => {
                      setMode('Edit');
                    }}
                  >
                    Edit
                  </Button>
                  <Button type="primary" onClick={onCloseDrawer}>Close</Button>
                </div>
              )}
          </div>
        </FixedComponent.Child>
      </FixedComponent>
    </Form>
  );
};
