import { Popconfirm } from 'antd';
import { ReactNode } from 'react';

import './BloodPressureDeviceAssignmentPopconfirmWarningComponent.scss';

export interface BloodPressureDeviceAssignmentPopconfirmWarningComponentProps {
  children: ReactNode
  isDeviceAssignmentDisabled?: boolean;
}

export const BloodPressureDeviceAssignmentPopconfirmWarningComponent = ({
  children,
  isDeviceAssignmentDisabled,
}: BloodPressureDeviceAssignmentPopconfirmWarningComponentProps) => (
  isDeviceAssignmentDisabled
    ? (

      <Popconfirm
        placement="top"
        align={{ offset: [0, 200] }}
        trigger="click"
        title={(
          <div>
            Before assigning a new device, please ensure
            that the current one is unassigned.
          </div>
        )}
        destroyTooltipOnHide
        overlayClassName="blood-pressure-device-assignment-warning"
        showArrow={false}
        okText="Got it"
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div
          className="blood-pressure-device-assignment-warning-wrapper disabled"
        >
          {children}
        </div>
      </Popconfirm>
    ) : (
      <div
        className="blood-pressure-device-assignment-warning-wrapper"
      >
        {children}
      </div>
    )
);
