import { AccountHelper } from '../../../../helpers/account';
import EmployeeProfilePhotoService from '../../../../services/EmployeeProfilePhotoService';
import { TicketCommentClass } from '../../../../uc-api-sdk';
import { TicketCommentHandleProps } from '../TicketViewComponent/TicketViewComponent';
import { TicketCommentComponent } from './TicketCommentComponent';

export interface TicketCommentListComponentProps extends TicketCommentHandleProps {
  comments: TicketCommentClass[];
}
export const TicketCommentListComponent = ({
  comments,
  handleDeleteComment,
  handleEditComment,
}: TicketCommentListComponentProps) => {
  const item = (v: TicketCommentClass) => (
    <TicketCommentComponent
      key={v?.id}
      id={v?.id || ''}
      date={v?.rawValue.createdAt ?? undefined}
      commentedById={v?.employeeId || ''}
      comment={v?.comment || ''}
      commentedBy={AccountHelper.getFullName(v.employeeUser)}
      avatarUrl={EmployeeProfilePhotoService.getEmployeeProfilePhotoPublicUrl(
        v.employeeUser?.avatar?.fileKey ?? undefined,
      )}
      onDelete={handleDeleteComment}
      onEdit={handleEditComment}
    />
  );
  return (
    <>
      {comments.map(item)}
    </>
  );
};
