import { ColumnType } from 'antd/es/table';
import { isNil } from 'lodash';
import React from 'react';
import { BOSummaryTableData } from './BOSummaryTableComponent';
import { SummaryTwoIndexComponent } from '../SummaryColumnComponents/SummaryTwoIndexComponent';
import { SummaryEmptyComponent } from '../SummaryColumnComponents/SummaryEmptyComponent';
import { BOSummaryTypeComponent } from './BOSummaryTypeComponent';

/* eslint-disable import/prefer-default-export */
export const BOSummaryTableColumns = {
  type: (): ColumnType<BOSummaryTableData> => ({
    title: 'Type',
    key: 'type',
    dataIndex: 'type',
    render: (value) => <BOSummaryTypeComponent type={value} />,
    width: '25%',
  }),
  count: (): ColumnType<BOSummaryTableData> => ({
    title: '#',
    key: 'count',
    dataIndex: 'count',
    render: (value) => value || 0,
    width: '15%',
  }),
  average: (): ColumnType<BOSummaryTableData> => ({
    title: 'Avg (%)',
    key: 'average',
    dataIndex: 'average',
    render: (value: BOSummaryTableData['average']) => (isNil(value) ? <SummaryEmptyComponent /> : value.toFixed(2)),
    width: '15%',
  }),
  range: (): ColumnType<BOSummaryTableData> => ({
    title: 'Range (%)',
    key: 'range',
    dataIndex: 'range',
    render: (value) => <SummaryTwoIndexComponent value={value} divider="-" />,
    width: '15%',
  }),
  perfusionIndex: (): ColumnType<BOSummaryTableData> => ({
    title: 'Perfusion Index (%)',
    key: 'perfusionIndex',
    dataIndex: 'perfusionIndexAverage',
    render: (value: BOSummaryTableData['perfusionIndexAverage']) => (isNil(value) ? <SummaryEmptyComponent /> : `${value.toFixed(2)}%`),
    width: '15%',
  }),
  averagePulseRate: (): ColumnType<BOSummaryTableData> => ({
    title: 'Average Pulse Rate (bpm)',
    key: 'averagePulseRate',
    dataIndex: 'pulseRateAverage',
    render: (value): BOSummaryTableData['pulseRateAverage'] => (isNil(value) ? <SummaryEmptyComponent /> : value.toFixed(2)),
    width: '15%',
  }),
};
