import { ReactNode } from 'react';
import TooltipComponent from '../TooltipComponent/TooltipComponent';

export interface VitalsToMonitorTooltipComponentProps {
   children: ReactNode;
 }

export const VitalsToMonitorTooltipComponent = ({
  children
}: VitalsToMonitorTooltipComponentProps) => (
  <TooltipComponent
    title={(
      <table width={200}>
        <tr>
          <th>Diagnosis</th>
          <th>Vitals</th>
        </tr>
        <tr>
          <td>HTN, CKD, CHF</td>
          <td>BP</td>
        </tr>
        <tr>
          <td>DM, pre-DM</td>
          <td>BG</td>
        </tr>
        <tr>
          <td>Obesity</td>
          <td>Weight</td>
        </tr>
        <tr>
          <td>COPD</td>
          <td>SPO2</td>
        </tr>
        <tr>
          <td>Others</td>
          <td>No vital</td>
        </tr>
      </table>
    )}
    type="question-icon"
  >
    {children}
  </TooltipComponent>
);
