import { compact } from 'lodash';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { CalendarSourceEnum, RoleTypeEnum } from '../../../../uc-api-sdk';
import { ParticipantsEnum } from '../../type/participants';
import { ParticipantSelectFormItemComponent } from './ParticipantSelectFormItemComponent';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';

export interface ParticipantsSelectFormItemComponentProps {
  addCurrentUserToOption?: boolean;
  source?: CalendarSourceEnum;
}

export const ParticipantsSelectFormItemComponent = ({
  addCurrentUserToOption,
  source,
}: ParticipantsSelectFormItemComponentProps) => {
  const patientContext = usePatientContext();
  const patientClinicId = patientContext.info?.patientInfo?.clinicId;
  const patientId = patientContext.info?.id || '';
  const isLoading = (
    !!patientId
    && (
      patientContext.info?.assigneesService === undefined
      || patientContext.info.isLoadingObj.assigneesLoading
    )
  );

  return (
    <LoadingOverlayComponent
      isLoading={isLoading}
      skeletonType="input"
    >
      <ParticipantSelectFormItemComponent
        source={source}
        key={`${patientId}-rdhc`}
        formItemInfo="rdhc"
        text="RD/HC"
        participantRole={ParticipantsEnum.RdHc}
        dropdownRoles={[RoleTypeEnum.RD, RoleTypeEnum.HC]}
        assignees={patientContext.info?.assigneesService?.getRdAssigneesId()}
        internalEmployeeForPatientId={patientId}
        needToFilter={false}
        patientClinicId={patientClinicId as string}
        addCurrentUserToOption={addCurrentUserToOption}
      />
      <ParticipantSelectFormItemComponent
        source={source}
        key={`${patientId}-ca`}
        formItemInfo="ca"
        text="CA"
        participantRole={ParticipantsEnum.CA}
        dropdownRoles={[RoleTypeEnum.CA]}
        assignees={patientContext.info?.assigneesService?.getCaAssigneesId()}
        internalEmployeeForPatientId={patientId}
        needToFilter={false}
        patientClinicId={patientClinicId as string}
        addCurrentUserToOption={addCurrentUserToOption}
      />
      <ParticipantSelectFormItemComponent
        source={source}
        key={`${patientId}-md`}
        formItemInfo="md"
        text="MD"
        participantRole={ParticipantsEnum.MD}
        assignees={compact([patientContext.info?.patientInfoService?.getProviderId()])}
        dropdownRoles={[RoleTypeEnum.PROVIDER]}
        patientClinicId={patientClinicId as string}
        externalEmployeeForPatientId={patientId}
        addCurrentUserToOption={addCurrentUserToOption}
      />
      <ParticipantSelectFormItemComponent
        source={source}
        key={`${patientId}-ma`}
        formItemInfo="ma"
        text="MA"
        participantRole={ParticipantsEnum.MA}
        dropdownRoles={[RoleTypeEnum.MA]}
        patientClinicId={patientClinicId as string}
        externalEmployeeForPatientId={patientId}
        addCurrentUserToOption={addCurrentUserToOption}
      />
    </LoadingOverlayComponent>
  );
};
