import { FC } from 'react';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { useTableColumnWithFiltersAndSorters } from '../../../../hooks/useTableColumnWithFiltersAndSorters/useTableColumnWithFiltersAndSorters';
import { PatientTableProps } from '../../../patients/type';
import { createPatientColumns } from '../PatientsTableComponent/columns';
import { PatientsTableComponent } from '../PatientsTableComponent/PatientsTableComponent';

export interface EnrolledPatientsTableComponentProps extends PatientTableProps {

}

export const EnrolledPatientsTableComponent: FC<EnrolledPatientsTableComponentProps> = ({
  totalSize,
  filters,
  sorters,
  exportData,
  ...props
}) => {
  const columns = useTableColumns(
    createPatientColumns,
    ['name', 'age', 'gender', 'program', 'vitals', 'riskLevel', 'RPMDays', 'billableTime', 'billable', 'clinic', 'provider', 'assignedRdHC', 'assignedCA', 'enrollmentDate'],
  );

  const columnsWithFilters = useTableColumnWithFiltersAndSorters({
    columns,
    filters,
    sorters: sorters || { columnKey: 'enrollmentDate', order: 'descend' },
  });

  return (
    <PatientsTableComponent
      totalSize={totalSize}
      columns={columnsWithFilters}
      exportData={exportData}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};
