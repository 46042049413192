import { Form } from 'antd';
import { useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { CPTCodeUnitsComponent } from '../CPTCodeComponent/CPTCodeUnitsComponent/CPTCodeUnitsComponent';
import { CPTCodeCategorySelectComponent, CPTCodeCategorySelectComponentProps } from '../CPTCodeComponent/CPTCodeCategorySelectComponent/CPTCodeCategorySelectComponent';
import { defaultCPTCodesByCategory } from '../CPTCodeComponent/constant';

import './CPTCodeFormItemComponent.scss';

export interface CPTCodeFormItemComponentProps { }

export const CPTCodeFormItemComponent = () => {
  const form = Form.useFormInstance();
  const insuranceForm = useInsuranceForm({ form });

  const handleOnChangeCategory: CPTCodeCategorySelectComponentProps['onChange'] = (
    value,
  ) => {
    if (!value) return;
    const cptCodeUnitsByCategory = defaultCPTCodesByCategory[value];
    insuranceForm.form.setFieldsValue({
      [insuranceForm.getName('priorAuthCPTCodeUnits')]: cptCodeUnitsByCategory,
    });
  };

  return (
    <div className="ant-form-item-combined-rows">
      <FormItem
        info={insuranceForm.getInfo('priorAuthCPTCodeCategory')}
      >
        <CPTCodeCategorySelectComponent
          onChange={handleOnChangeCategory}
        />
      </FormItem>
      <FormItem
        name={insuranceForm.getName('priorAuthCPTCodeUnits')}
        colon={false}
        label=" "
        className="prior-auth-cpt-code-units"
      >
        <CPTCodeUnitsComponent />
      </FormItem>
    </div>
  );
};
