import { isEmpty } from 'lodash';
import { Card } from 'antd';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { MakeSchedulesComponent } from '../../../patient/component/PatientVisitsListComponent/MakeSchedulesComponent/MakeSchedulesComponent';
import { FutureFollowUpClinicEventListComponent } from '../FutureFollowUpClinicEventListComponent/FutureFollowUpClinicEventListComponent';
import { useFutureClinicEventContext } from '../../../../contexts/FutureClinicEventContext/FutureClinicEventContext';

import './FutureFollowUpClinicEventComponent.scss';

export interface FutureFollowUpClinicEventComponentProps { }

export const FutureFollowUpClinicEventComponent = () => {
  const { futureClinicEventServices } = useFutureClinicEventContext();
  const hasVisits = !isEmpty(futureClinicEventServices);

  const getTitle = () => (
    hasVisits ? 'Following Visit' : 'Schedule following Visit'
  );

  const getMakeScheduleText = () => (
    hasVisits ? 'Create Another Visit' : 'Create a Visit'
  );

  return (
    <Card className="upcoming-follow-up flex fd-c gap2">
      <TextComponent>
        {getTitle()}
      </TextComponent>
      {
        hasVisits
        && (
          <div className="pt20">
            <FutureFollowUpClinicEventListComponent
              clinicEvents={futureClinicEventServices}
            />
          </div>
        )
      }
      <div className="pt12">
        <MakeSchedulesComponent
          btnText={getMakeScheduleText()}
          icon={null}
        />
      </div>
    </Card>
  );
};
