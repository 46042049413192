import {
  Button, Col, Form, Input, Row
} from 'antd';
import { useState } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useActualBillableTimeForm } from '../../hook/useActualBillableTimeForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { SubmitValue } from '../BillableTimeReviewActionComponent/BillableTimeReviewActionComponent';
import { MillisecondToMinuteComponent } from '../../../../uiComponent/MillisecondToMinuteComponent/MillisecondToMinuteComponent';
import './EnterActualBillableTimeFormComponent.scss';

export interface EnterActualBillableTimeFormComponentProps {
  startDate: number;
  totalTime: number;
  totalBillableTime: number;
  onCorrectBillableTime?: (v: SubmitValue) => void;
}

export const EnterActualBillableTimeFormComponent = ({
  startDate,
  totalTime,
  totalBillableTime,
  onCorrectBillableTime,
}:EnterActualBillableTimeFormComponentProps) => {
  const hook = useActualBillableTimeForm();
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

  const handleOnSubmit = (v: SubmitValue) => {
    onCorrectBillableTime?.(v);
  };

  const renderWarning = () => (
    <FormItem
      shouldUpdate={hook.shouldUpdate(['correctedBillableTime'])}
      noStyle
    >
      {({ getFieldValue }) => {
        const actualTime = getFieldValue('correctedBillableTime');
        const isLarger = actualTime && actualTime > Math.ceil(totalBillableTime / 60000);
        const isInvalid = actualTime && !/^[0-9]+$/.test(actualTime);
        setIsSubmitButtonDisabled(isLarger || isInvalid || !actualTime);
        if (isInvalid) {
          return (
            <div className="danger">
              <ExclamationCircleFilled className="danger" />
              {' Incorrect format. Please enter a number only.'}
            </div>
          );
        }
        if (isLarger) {
          return (
            <div className="danger">
              <ExclamationCircleFilled className="danger" />
              {' The revised time cannot exceed the originally recorded billable time.'}
            </div>
          );
        }
        return null;
      }}
    </FormItem>
  );

  return (
    <Form
      className="h100 w100 enter-actual-billable-time-form"
      form={hook.form}
      onFinish={hook.handleSubmit(handleOnSubmit)}
    >
      <FixedComponent>
        <FixedComponent.Child>
          <div className="mt15 mb30">Please enter the actual minutes that reflects the time you spent on the patient.</div>
          <Row className="mb30">
            <Col span={8}>
              <div className="b5">Start at</div>
            </Col>
            <Col span={8}>
              <div><DisplayDateComponent value={startDate} format="DATE_COMMA_TIME_AM_PM" /></div>
            </Col>
          </Row>
          <Row className="mb30">
            <Col span={8}>
              <div className="b5">Total time</div>
            </Col>
            <Col span={12}>
              <div className="flex gap1">
                <div className="b7">
                  <MillisecondToMinuteComponent value={totalTime} />
                </div>
                <div className="text-secondary-gray">min</div>
              </div>
            </Col>
          </Row>
          <Row className="mb30">
            <Col span={8}>
              <div className="b5">Billable time to review</div>
            </Col>
            <Col span={12}>
              <div className="flex gap1">
                <div className="b7">
                  <MillisecondToMinuteComponent value={totalBillableTime} />
                </div>
                <div className="text-secondary-gray">min</div>
              </div>
            </Col>
          </Row>
          <Row className="mb30">
            <Col span={8} className="flex gap1">
              <div className="b5">Actual billable time</div>
              <div className="danger">*</div>
            </Col>
            <Col span={15}>
              <div className="flex gap1 ai-c">
                <FormItem
                  info={hook.getInfo('correctedBillableTime')}
                  required
                  className="w20"
                >
                  <Input />
                </FormItem>
                <div className="text-secondary-gray mb1">min</div>
              </div>
              {renderWarning()}
            </Col>
          </Row>
          <Row className="mb30">
            <Col span={8}>
              <div className="b5">Comment</div>
            </Col>
            <Col span={15}>
              <FormItem
                info={hook.getInfo('comment')}
                noStyle
              >
                <Input.TextArea
                  autoSize={{ minRows: 3 }}
                  placeholder="Enter your comment here (Optional)"
                />
              </FormItem>
            </Col>
          </Row>
        </FixedComponent.Child>
        <FixedComponent.Child isFixed className="border-t">
          <Button
            type="primary"
            className="w100 b5 fs16"
            htmlType="submit"
            disabled={isSubmitButtonDisabled}
          >
            Confirm
          </Button>
        </FixedComponent.Child>
      </FixedComponent>
    </Form>
  );
};
