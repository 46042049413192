import Input, { InputProps } from 'antd/lib/input/Input';
import { FC } from 'react';
import { InputType } from '../../../../features/Input/types';

export interface MedicalIdInputComponentProps extends InputType<string> { }

export const MedicalIdInputComponent: FC<MedicalIdInputComponentProps> = ({
  value,
  onChange,
}) => {
  const handleChange: InputProps['onChange'] = (e) => {
    onChange?.(e.target.value);
  };
  return (
    <Input
      value={value}
      onChange={handleChange}
    />
  );
};
