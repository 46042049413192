import { Table } from 'antd';
import { TableProps } from 'antd/es/table';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { LabResultClass, ReferenceLevel } from '../../../../uc-api-sdk';
import { labResultColumns } from './LabResultTableColumn';
import { useLabResultTimer } from '../../hook/useLabResultTimer';

export interface LabResultA1CTableComponentProps extends Omit<TableProps<LabResultClass>, 'column'> {
  referenceLevels?: ReferenceLevel[],
}

export const LabResultA1CTableComponent = ({
  referenceLevels,
  ...props
}: LabResultA1CTableComponentProps) => {
  const columns = useTableColumns(
    labResultColumns(),
    ['dateCollected', 'result', { name: 'flag', args: [referenceLevels] }, 'inHouseTest', 'action'],
    [referenceLevels],
  );
  const justAddedLabResultId = useLabResultTimer();

  return (
    <Table
      size="middle"
      onRow={(row) => ({
        style: (justAddedLabResultId && row.id === justAddedLabResultId) ? { background: '#e6f4ff' } : {}
      })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      columns={columns}
      rowKey={(r) => r.id || ''}
    />
  );
};
