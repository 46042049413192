import { Space } from 'antd';
import React from 'react';
import { PatientStatusTagComponent } from '../../TagsComponent/PatientStatusTagComponent/PatientStatusTagComponent';
import { PatientStatusTagEnum } from './PatientStatusTagEnum';

interface PatientStatusTagListComponentProps {
  value?: PatientStatusTagEnum[];
}

export const PatientStatusTagListComponent = ({
  value = [],
}: PatientStatusTagListComponentProps) => (
  <Space wrap>
    {value.map((status) => {
      switch (status) {
        case PatientStatusTagEnum.IneligibleTag:
          return <PatientStatusTagComponent.IneligibleTag />;
        case PatientStatusTagEnum.UnenrolledPotentialTag:
          return <PatientStatusTagComponent.UnenrolledPotentialTag />;
        case PatientStatusTagEnum.InsCheckPendingTag:
          return <PatientStatusTagComponent.InsCheckPendingTag />;
        case PatientStatusTagEnum.ManualInsCheckPendingTag:
          return <PatientStatusTagComponent.ManualInsCheckPendingTag />;
        case PatientStatusTagEnum.InactiveTag:
          return <PatientStatusTagComponent.InactiveTag />;
        case PatientStatusTagEnum.MissingInfoTag:
          return <PatientStatusTagComponent.MissingInfoTag />;
        case PatientStatusTagEnum.NoAttentionNeededTag:
          return <PatientStatusTagComponent.NoAttentionNeededTag />;
        default:
          return null;
      }
    })}
  </Space>
);
