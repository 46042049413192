import { SignatureCollectionMethodEnumComponent } from '../../../../enumComponent/SignatureCollectionMethodEnumComponent/SignatureCollectionMethodEnumComponent';
import { SignatureCollectionMethodEnum } from '../../../../uc-api-sdk';
import { RadioGroupComponent } from '../../../../uiComponent/RadioGroupComponent/RadioGroupComponent';
import { InputType } from '../../../Input/types';

export interface ConsentFormSignatureRadioComponentProps extends
  Partial<InputType<SignatureCollectionMethodEnum>> {

}

const options = [
  {
    value: SignatureCollectionMethodEnum.APP,
    label: (
      <SignatureCollectionMethodEnumComponent
        value={SignatureCollectionMethodEnum.APP}
      />
    ),
  },
  {
    value: SignatureCollectionMethodEnum.TEAM_MEMBER_DEVICE,
    label: (
      <SignatureCollectionMethodEnumComponent
        value={SignatureCollectionMethodEnum.TEAM_MEMBER_DEVICE}
      />
    ),
  },
  {
    value: SignatureCollectionMethodEnum.VERBALLY,
    label: (
      <SignatureCollectionMethodEnumComponent
        value={SignatureCollectionMethodEnum.VERBALLY}
      />
    ),
  },
  {
    value: SignatureCollectionMethodEnum.CLINIC_PRINTED,
    label: (
      <SignatureCollectionMethodEnumComponent
        value={SignatureCollectionMethodEnum.CLINIC_PRINTED}
      />
    ),
  }
];

export const ConsentFormSignatureRadioComponent = ({
  value,
  onChange,
}: ConsentFormSignatureRadioComponentProps) => (
  <RadioGroupComponent<SignatureCollectionMethodEnum>
    direction="vertical"
    options={options}
    value={value}
    onChange={onChange}
  />
);
