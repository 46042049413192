import { FC } from 'react';
import {
  Button, Dropdown, Space,
} from 'antd';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import { Icons } from '../../../../icons/Icons';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import './DotDropdownComponent.scss';

export interface DotDropdownComponentProps {
  items: MenuItemType[];
}
export const DotDropdownComponent: FC<DotDropdownComponentProps> = ({
  items,
}) => {
  const { send: sendMixpanel } = useMixpanelContext();

  const onClick = (v: boolean) => {
    if (v) {
      sendMixpanel({ event: MixpanelEvents.PatientHeaderPlusBtn });
    }
  };

  return (
    <Dropdown
      className="dot-dropdown-component"
      onOpenChange={onClick}
      menu={{ items }}
      trigger={['click']}
      placement="bottomRight"
      overlayClassName="dot-dropdown-component"
    >
      <Button
        className="flex b5 menu-dot-btn"
      >
        <Space className="flex ai-c">
          <Icons.PlusIcon />
        </Space>
      </Button>
    </Dropdown>
  );
};
