import { ChartingTagComponent } from '../../features/patient/component/TagsComponent/ChartingTagComponent/ChartingTagComponent';
import { ChartingStatusEnum } from './ChartingStatusEnum';

export interface ChartingStatusEnumComponentProps {
  value: ChartingStatusEnum | undefined | null;
}

export const ChartingStatusEnumComponent = ({
  value,
}: ChartingStatusEnumComponentProps) => {
  const getTag = (): React.ReactNode => {
    switch (value) {
      case ChartingStatusEnum.CHARTING:
        return <ChartingTagComponent.ChartingTag />;
      case ChartingStatusEnum.NO_CHARTING:
        return <ChartingTagComponent.NoChartingTag />;
      default:
        return null;
    }
  };

  return (
    <span>{getTag()}</span>
  );
};
