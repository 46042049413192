import { UcMedicationManagement } from '../uc-api-sdk';

export interface MedicationmangementInfo {
  medicationManagement?: UcMedicationManagement;
}

export class MedicationManagementService {
  public reconciliationCompliance: string | undefined;

  public reconciliationComplianceOther: string | undefined;

  public otherInfo: string | undefined;

  public extraNote: string | undefined;

  constructor(medicationManagement?: UcMedicationManagement) {
    const {
      reconciliationCompliance,
      reconciliationComplianceOther,
      otherInfo,
      extraNote,
    } = medicationManagement || {};

    this.reconciliationCompliance = reconciliationCompliance ?? undefined;
    this.reconciliationComplianceOther = reconciliationComplianceOther ?? undefined;
    this.otherInfo = otherInfo ?? undefined;
    this.extraNote = extraNote ?? undefined;
  }
}
