import React, { useCallback } from 'react';
import { Button, Modal, message } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ButtonType } from 'antd/lib/button';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useLabResultDelete } from '../../../../uc-api-sdk';
import { TranscribeTexts } from '../../constants';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';

export interface LabResultDeleteButtonContainerProp {
  id?: string;
  btnType?: ButtonType;
  size?: SizeType;
}
export const LabResultDeleteButtonContainer = ({
  id,
  btnType = 'text',
  size = 'small',
}: LabResultDeleteButtonContainerProp) => {
  const updateHook = useUpdate('LAB_DELETE');
  const [modal, contextHolder] = Modal.useModal();
  const deleteLab = useLabResultDelete({});

  const onDelete = useCallback(async (id: string) => {
    const res = await deleteLab.send({ params: { id } });
    if (res?.code === 200) {
      message.success(TranscribeTexts.deleteSuccess);
      updateHook.updateValue();
    }
  }, []);

  const handleDelete = () => {
    if (id) onDelete(id);
  };

  const confirm = () => {
    modal.confirm({
      title: 'Are you sure delete this Lab result?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: handleDelete,
    });
  };

  return (
    <>
      <Button
        type={btnType}
        size={size}
        onClick={confirm}
      >
        Delete
      </Button>
      {contextHolder}
    </>
  );
};
