import React from 'react';

export const RiskPriorityTooltipTitleComponent = () => (
  <div className="fs1">
    <div>
      Risk priority is determined based on the patient's most recent A1C measurement
      within the last 90 days:
    </div>
    <ul className="pl20">
      <li>{'High: A1C >= 9.0%'}</li>
      <li>Medium: A1C is between 7.5%-8.9%</li>
      <li>{'Low: A1C <7.5%'}</li>
    </ul>
  </div>
);
