import React, { FC } from 'react';
import { RoleType } from '../../types/roles';
import { RoleTypeEnum } from '../../uc-api-sdk';

export interface RoleTypeComponentProps {
  value?: RoleType | RoleTypeEnum;
}

export const RoleTypeComponent: FC<RoleTypeComponentProps> = ({
  value,
}) => {
  const getRoleTypeName = () => {
    switch (value) {
      case RoleType.Admin: return 'Admin';
      case RoleType.AreaManager: return 'Area Manager';
      case RoleType.Biller: return 'Biller';
      case RoleType.Ca: return 'CA';
      case RoleType.Hc: return 'HC';
      case RoleType.Ma: return 'MA';
      case RoleType.Md: return 'MD';
      case RoleType.Member: return 'Member';
      case RoleType.PartnerBiller: return 'Partner Biller';
      case RoleType.Rd: return 'RD';
      case RoleType.SuperAdmin: return 'Admin';
      default: return null;
    }
  };

  return (
    <span data-testid="role-type-component">{getRoleTypeName()}</span>
  );
};

export default RoleTypeComponent;
