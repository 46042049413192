/* eslint-disable react/jsx-props-no-spreading */
import { Divider } from 'antd';
import { useMemo } from 'react';
import { useBoolean } from 'usehooks-ts';
import { Icons } from '../../../../icons/Icons';
import { CalendarSourceEnum, ClinicEvent } from '../../../../uc-api-sdk';
import { DiscardUnsavedChangesModalComponent } from '../../../../uiComponent/DiscardUnsavedChangesModalComponent/DiscardUnsavedChangesModalComponent';
import { CreateVisitContainer } from '../../../visit/container/CreateVisitContainer/CreateVisitContainer';
import { useCreateVisitForm } from '../../../visit/hook/useCreateVisitForm';
import { useCreateVisitInitialValues } from '../../../visit/hook/useCreateVisitInitialValues';
import { EventViewContainer } from '../../container/EventViewContainer/EventViewContainer';
import { VisitViewContainer } from '../../container/VisitViewContainer/VisitViewContainer';
import { CreateVisitFormProvider } from '../../context/CreateVisitFormContext/CreateVisitFormContext';
import { CalendarEvent } from '../BigCalendarComponent/BigCalendarComponent';
import { CalendarGoogleFullEventComponent } from '../CalendarGoogleFullEventComponent/CalendarGoogleFullEventComponent';

import './DayCalendarEvenDrawerContentComponent.scss';

export interface DayCalendarEvenDrawerContentComponentProps {
  event?: CalendarEvent<ClinicEvent>;
  onClose?: (closeOuter?: boolean) => void;
  refetch?: () => void;
}

export const DayCalendarEvenDrawerContentComponent = ({
  event,
  onClose,
  refetch,
}: DayCalendarEvenDrawerContentComponentProps) => {
  const createVisitForm = useCreateVisitForm({ editVisit: event?.info });
  const { getInitialValues } = useCreateVisitInitialValues();
  const { value: isEdit, setTrue: setEditTrue, setFalse: setEditFalse } = useBoolean();

  const initialValues = useMemo(() => (event?.info ? getInitialValues(event.info) : undefined), []);

  const handleSubmit = () => {
    onClose?.();
    refetch?.();
  };

  const handleClose = (closeOuter?: boolean) => {
    setEditFalse();
    onClose?.(closeOuter);
  };

  const handleCloseEdit = () => {
    handleClose();
    handleSubmit();
  };

  const renderCloseButton = (title: string) => (
    <div>
      <div className="flex jc-sb ai-c p20">
        <h3 className="m0">{title}</h3>
        <DiscardUnsavedChangesModalComponent
          onConfirm={handleClose}
        >
          <Icons.CloseIcon />
        </DiscardUnsavedChangesModalComponent>
      </div>
      <Divider className="mt0" />
    </div>
  );

  const renderContent = () => {
    if (!!event && isEdit) {
      return (
        <>
          {renderCloseButton('Edit Visit')}
          <div className="f1 overflow-h">
            <CreateVisitFormProvider value={createVisitForm}>
              <CreateVisitContainer
                visit={event.info}
                onClose={handleCloseEdit}
                initialValues={initialValues}
                isEdit
              />
            </CreateVisitFormProvider>
          </div>
        </>
      );
    }
    if (event?.info?.visitType) {
      return (
        <VisitViewContainer
          visit={event}
          onDelete={handleSubmit}
          onEdit={setEditTrue}
          onClose={handleClose}
        />
      );
    }
    if (event?.info?.calendarSource === CalendarSourceEnum.OTHER) {
      return (
        <EventViewContainer
          onDelete={handleSubmit}
          onEdit={setEditTrue}
          onClose={handleClose}
          visit={event}
        />
      );
    }
    return (
      <CalendarGoogleFullEventComponent
        onClose={handleClose}
        {...event}
      />
    );
  };

  return (
    <div className="day-calendar-event-drawer-content flex fd-c h100">
      {renderContent()}
    </div>
  );
};
