import { TreatmentTypeEnum } from '../../uc-api-sdk';
import { BasicEnumComponent } from '../BasicEnumComponent/BasicEnumComponent';

export interface TreatmentComponentProps {
  treatment: TreatmentTypeEnum;
}

export const TreatmentComponent = ({
  treatment,
}: TreatmentComponentProps) => (
  <BasicEnumComponent value={treatment} />
);
