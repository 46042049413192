import { useCallback, useMemo } from 'react';
import { FormOptions, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { ShouldUpdate } from '../../../types/form';
import { useICDCodeForm } from '../../ICDCode/hook/useICDCodeForm';
import { HealthConditionItem, PatientComplexity } from '../../../uc-api-sdk';

export interface PatientComplexitySubmitValue {
  patientComplexity: PatientComplexity;
  icdTable: HealthConditionItem[];
}

export const usePatientComplexityForm = (options?: FormOptions) => {
  const icdForm = useICDCodeForm(options);

  const factory = useFormHookFactory({
    patientComplexity: {
      name: 'patientComplexity',
    },
    ...icdForm.formInput,
  }, options);

  const shouldUpdateComplexity = useCallback((
    icdTableName?: string,
  ): ShouldUpdate => {
    if (icdTableName) return (prev, next) => prev[icdTableName] !== next[icdTableName];
    return factory.shouldUpdate(['icdTable']);
  }, []);

  const factoryForm = useMemo(() => ({
    ...factory,
    shouldUpdateComplexity,
  }), [factory]);

  return factoryForm;
};
