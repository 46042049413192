import { useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import EnvConfig from '../../../configs/envConfig/envConfig';
import { APIResponse } from '../../../uc-api-sdk';

export interface UCVersion {
  id?: string;
  feVersion: string;
  beVersion: string;
  mobileVersion: string;
  releaseNote: string;
  createdAt: string;
  updatedAt: string;
}

const ucVersionPath = `${EnvConfig.restfulServerUrl}/server-info`;

export const getUCVersion = () => (
  axios.get(ucVersionPath) as Promise<APIResponse<{ data: UCVersion }>>
);

export const useUCVersion = () => {
  const [ucVersion, setUcVersion] = useState<UCVersion>();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const res = await getUCVersion();
      setIsLoading(false);
      if (res.data?.data) {
        setUcVersion(res.data?.data);
        return;
      }
      console.error('Failed to get uc version: ', res.msg);
    })();
  }, []);

  const getVersionDate = (dateString?: string | null) => (
    dateString ? dayjs(dateString).utc() : null
  );

  return {
    ...ucVersion,
    createdAt: getVersionDate(ucVersion?.createdAt),
    updatedAt: getVersionDate(ucVersion?.beVersion),
    isLoading,
  };
};
