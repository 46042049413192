import React, {
  createContext,
  ReactNode, FC,
  useContext,
} from 'react';
import { Info } from '../../uiComponent/FetchComponent/FetchComponent';

type FetchComponentValue = object;

const FetchComponentContext = createContext<FetchComponentValue | undefined>(undefined);

export interface FetchComponentProviderProps {
  children: ReactNode;
  value?: FetchComponentValue;
}

export const FetchComponentProvider: FC<FetchComponentProviderProps> = ({
  children,
  value,
}) => (
  <FetchComponentContext.Provider value={value}>
    {children}
  </FetchComponentContext.Provider>
);

export const useFetchComponentContext = <T, D>() => {
  const contextValue = useContext(FetchComponentContext);
  return contextValue as Info<T, D> | undefined;
};
