import { VisitTypeEnum } from '../../../../uc-api-sdk';

export interface TranscribeVisitTitleComponentProps {
  visitType?: VisitTypeEnum;
}

export const TranscribeVisitTitleComponent = ({
  visitType,
}: TranscribeVisitTitleComponentProps) => (
  <span>
    {
      visitType === VisitTypeEnum.FOLLOW_UP
        ? 'Follow-up visit'
        : 'Initial'
    }
    {' '}
    Transcribe
  </span>
);
