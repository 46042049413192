import { ReactNode } from 'react';
import { HeightService } from '../../helpers/height';
import { Height } from '../../uc-api-sdk';
import EmptyComponent from '../EmptyComponent/EmptyComponent';

export interface HeightComponentProps {
  label?: string;
  height?: Height;
  showLabelWhenEmpty?: boolean;
  showUnit?: boolean;
  feetText?: ReactNode;
  inchText?: ReactNode;
  cmText?: ReactNode;
  showAsCm?: boolean;
  showAsInch?: boolean;
}

export const HeightComponent = ({
  label = 'Height:',
  height,
  showLabelWhenEmpty = true,
  showUnit = true,
  feetText = '\'',
  inchText = '"',
  cmText = 'cm',
  showAsCm,
  showAsInch,
}: HeightComponentProps) => {
  const { value: heightInCm } = height || {};

  if (!heightInCm) {
    return (
      <>
        {showLabelWhenEmpty && <span>{label}</span>}
        <EmptyComponent />
      </>
    );
  }

  const renderCm = () => (
    <span>
      {heightInCm}
      {showUnit ? cmText : null}
    </span>
  );

  const renderFeetInch = () => {
    const [feet, inch] = HeightService.cmToFeet(heightInCm);
    return (
      <>
        <span>
          {feet}
          {feetText}
        </span>
        <span>
          {inch}
          {inchText}
        </span>
      </>
    );
  };

  const renderInch = () => {
    const [feet, inch] = HeightService.cmToFeet(heightInCm);
    const totalInches = feet * 12 + inch;
    return (
      <span>
        {totalInches}
        {showUnit ? inchText : null}
      </span>
    );
  };

  const getText = () => {
    if (showAsCm) return renderCm();
    if (showAsInch) return renderInch();
    return renderFeetInch();
  };

  return (
    <div className="flex">
      <span>
        {/* ie Height: */}
        {label}
      </span>
      <div className="flex">
        {getText()}
      </div>
    </div>
  );
};
