import { useState } from 'react';
import {
  find,
  forEach,
  groupBy,
  intersection,
  isNumber,
  map
} from 'lodash';
import {
  ClinicGoalEnum,
  ClinicalGoalItem,
  ConditionEnum,
  GoalStatusEnum,
  VitalEnumType,
} from '../../../uc-api-sdk';
import { getText as getConditionText } from '../../../enumComponent/ConditionEumComponent/ConditionEumComponent';
import { getText as getGoalValueText } from '../../../enumComponent/ClinicalGoalEnumComponent/ClinicalGoalEnumComponent';
import { MonthlyMeasurementValue } from '../../../contexts/MonthlyMeasurementContext/MonthlyMeasurementContext';

export interface ChangedClinicalGoalTemplate {
  condition: string;
  oldGoalValue?: string | null;
  newGoalValue?: string | null;
  goalStatus?: GoalStatusEnum | null;
}

export interface UnchangedClincalGoalTemplate {
  condition: string;
  goalStatus?: GoalStatusEnum | null;
}

export interface AddedClincalGoalTemplate {
  condition: string;
  goalStatus?: GoalStatusEnum | null;
}

export interface RemovedClincalGoalTemplate {
  condition: string;
}

export interface MTPRNoteTemplates {
  changed: ChangedClinicalGoalTemplate[];
  unchanged: UnchangedClincalGoalTemplate[];
  added: AddedClincalGoalTemplate[];
  removed: RemovedClincalGoalTemplate[];
}

export const useGenerateMTPRNote = (
  clinicalGoals: ClinicalGoalItem[]
) => {
  const [initialClinicalGoals] = useState(clinicalGoals);

  const findGoal = (
    goalList: ClinicalGoalItem[],
    goalCondition?: string | null,
  ) => (
    goalCondition
      ? find(goalList, (goal) => (
        goal.condition === goalCondition
        || goal.customCategory === goalCondition
      )) : undefined
  );

  const processMTPRNoteTemplates = (
    templates: MTPRNoteTemplates,
    monthlyMeasurements: MonthlyMeasurementValue,
    patientEnrolledVitals: VitalEnumType[],
  ) => {
    let templateString = '';
    const getConditionName = (condition: string) => (
      (getConditionText(condition as ConditionEnum) || condition)
      ?? 'undefined'
    );
    const getGoalValue = (goalValue?: ClinicGoalEnum | string | null) => {
      const goalValueText = (getGoalValueText(goalValue as ClinicGoalEnum) || goalValue);
      if (!goalValueText || goalValueText === ClinicGoalEnum.CHOOSE_THE_OPTIONS) {
        return 'undefined';
      }
      return goalValueText;
    };
    const getGoalStatus = (goalStatus?: GoalStatusEnum | null) => {
      switch (goalStatus) {
        case GoalStatusEnum.MEETING:
          return 'is met';
        case GoalStatusEnum.NOT_MEETING:
          return 'is not met';
        case GoalStatusEnum.UNABLE_TO_CONCLUDE:
        default:
          return 'cannot be assessed due to insufficient data';
      }
    };
    const joinNames = (names: string[]) => {
      let joinedNames = names.join(', ');
      const lastCommaIndex = joinedNames.lastIndexOf(',');
      if (lastCommaIndex > -1) {
        const [firstJoined, secondJoined] = [
          joinedNames.slice(0, lastCommaIndex),
          joinedNames.slice(lastCommaIndex + 1)
        ];
        joinedNames = `${firstJoined.trim()} and ${secondJoined.trim()}`;
      }
      return joinedNames;
    };
    forEach(templates.changed, (changed) => {
      const {
        condition,
        oldGoalValue,
        newGoalValue,
        goalStatus,
      } = changed;
      templateString = templateString.concat(`The ${getConditionName(condition)} goal has been updated from ${getGoalValue(oldGoalValue)} to ${getGoalValue(newGoalValue)}, and the goal status now ${getGoalStatus(goalStatus)}.`);
      templateString = templateString.concat('\n');
    });

    const unchangedTemplateByStatus = groupBy(templates.unchanged, (unchanged) => (
      (!unchanged.goalStatus || unchanged.goalStatus === GoalStatusEnum.NONE)
        ? GoalStatusEnum.UNABLE_TO_CONCLUDE
        : unchanged.goalStatus
    ));
    forEach(unchangedTemplateByStatus, (unchanged, status) => {
      const goalStatus = getGoalStatus(status as GoalStatusEnum);
      const isMultipleUnchanged = unchanged.length > 1;
      const unchangedConditions = map(unchanged, 'condition');
      const textByGrammar = isMultipleUnchanged ? 'goals all remain unchanged' : 'goal remains unchanged';
      templateString = templateString.concat(`The ${joinNames(unchangedConditions.map(getConditionName))} ${textByGrammar}, and the goal status ${goalStatus}.`);
      templateString = templateString.concat('\n');
    });

    forEach(templates.added, (added) => {
      const { condition, goalStatus } = added;
      templateString = templateString.concat(`A ${getConditionName(condition)} goal has been added, and the goal status now ${getGoalStatus(goalStatus)}.`);
      templateString = templateString.concat('\n');
    });

    const removedConditions = map(templates.removed, 'condition');
    if (removedConditions.length > 0) {
      const removedConditionNames = joinNames(map(removedConditions, getConditionName));
      const textByGrammar = removedConditions.length > 1 ? 'goals have been removed' : 'goal has been removed';
      templateString = templateString.concat(`The ${removedConditionNames} ${textByGrammar}.`);
      templateString = templateString.concat('\n');
    }

    let hasLessThan12MonthlyMeasurementDays = false;
    forEach(monthlyMeasurements, (monthlyMeasurement) => {
      const { measurementDays, vitals } = monthlyMeasurement || {};
      if (isNumber(measurementDays) && intersection(patientEnrolledVitals, vitals).length) {
        hasLessThan12MonthlyMeasurementDays = (
          hasLessThan12MonthlyMeasurementDays
          || measurementDays < 12
        );
      }
    });
    if (hasLessThan12MonthlyMeasurementDays) {
      templateString = templateString.concat('Reviewed monitoring schedule with the patient and also encouraged more frequent measurements.');
      templateString = templateString.concat('\n');
    }

    const lastNewLineBreakIndex = templateString.lastIndexOf('\n');
    templateString = templateString.slice(0, lastNewLineBreakIndex);

    return templateString;
  };

  const handleGenerate = (
    updatedClinicGoals: ClinicalGoalItem[],
    monthlyMeasurements: MonthlyMeasurementValue,
    patientEnrolledVitals: VitalEnumType[],
  ) => {
    const templates: MTPRNoteTemplates = {
      changed: [],
      unchanged: [],
      added: [],
      removed: [],
    };
    const getGoalCondition = (goal: ClinicalGoalItem) => (
      goal.condition || goal.customCategory
    );
    // goals that are changed / unchanged
    forEach(initialClinicalGoals, (goal) => {
      const goalCondition = getGoalCondition(goal);
      const updatedGoal = findGoal(updatedClinicGoals, goalCondition);
      if (!goalCondition) return;
      if (updatedGoal) {
        const oldGoalValue = goal.clinicalGoalName || goal.customContent;
        const newGoalValue = updatedGoal.clinicalGoalName || updatedGoal.customContent;
        const newGoalStatus = updatedGoal.goalStatus;
        const isValueChanged = oldGoalValue !== newGoalValue;
        if (isValueChanged) {
          templates.changed.push({
            condition: goalCondition,
            oldGoalValue,
            newGoalValue,
            goalStatus: newGoalStatus,
          });
        } else {
          templates.unchanged.push({
            condition: goalCondition,
            goalStatus: newGoalStatus,
          });
        }
        return;
      }
      // goals that are removed
      templates.removed.push({
        condition: goalCondition,
      });
    });

    forEach(updatedClinicGoals, (goal) => {
      const goalCondition = getGoalCondition(goal);
      const initialGoal = findGoal(initialClinicalGoals, goalCondition);
      if (goalCondition && !initialGoal) {
        // goals that are added
        templates.added.push({
          condition: goalCondition,
          goalStatus: goal.goalStatus,
        });
      }
    });

    return processMTPRNoteTemplates(
      templates,
      monthlyMeasurements,
      patientEnrolledVitals,
    );
  };

  return handleGenerate;
};
