import classNames from 'classnames';
import React from 'react';
import { SmartAlertTaskStatusEnumComponent } from '../../../../enumComponent/SmartAlertTaskStatusComponent/SmartAlertTaskStatusComponent';
import { SmartAlertTaskStatus } from '../../../../uc-api-sdk';
import './MedicalAlertTaskStatusCellComponent.scss';

export interface SmartAlertTaskStatusCellComponentProps {
  value: SmartAlertTaskStatus;
}

export const MedicalAlertTaskStatusCellComponent = ({
  value,
}: SmartAlertTaskStatusCellComponentProps) => (
  <div
    className={classNames({
      'medical-alert-status-component': true,
      [value]: true,
    })}
  >
    <SmartAlertTaskStatusEnumComponent value={value} />
  </div>
);
