import React, {
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { DeviceModelEnum, useTemplatesGetDeviceModels, DeviceModelInfo } from '../../uc-api-sdk';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';

type SetupGuideType = Record<DeviceModelEnum, DeviceModelInfo>;
interface Value {
  data?: SetupGuideType;
  sendRequest: () => Promise<SetupGuideType | undefined>;
  loading: boolean;
}

export const SetupGuideContext = React.createContext<Value | undefined>(undefined);

export interface SetupGuideContextProviderProps {
  children: ReactNode;
}

let hasFetched = false;

export const SetupGuideContextProvider = ({
  children,
}: SetupGuideContextProviderProps) => {
  const [data, setData] = useState<SetupGuideType | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const req = useTemplatesGetDeviceModels({ options: { sendOnMount: false } });

  const sendRequest = async () => {
    if (!hasFetched) {
      hasFetched = true;
      setLoading(true);
      const response = await req.send();
      setData(response?.data as SetupGuideType);
      setLoading(false);
      return response?.data as SetupGuideType;
    }
    return undefined;
  };

  const contextValue: Value = useGetContextValue<Value>({
    data,
    sendRequest,
    loading,
  }, []);

  return (
    <SetupGuideContext.Provider value={contextValue}>
      {children}
    </SetupGuideContext.Provider>
  );
};

export const useSetupGuideContext = () => {
  const context = useContext(SetupGuideContext);

  useEffect(() => {
    context?.sendRequest();
  }, []);

  return (context || {}) as Value;
};
