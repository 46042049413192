import { useEffect } from 'react';
import { usePatientPageControllerContext } from '../../../patient/context/PatientPageControllerContext/PatientPageControllerContext';
import { OutstandingEnum } from '../../constant/outstandingOrder';
import { useOutstandingFoodLog } from '../../hook/useOutstandingFoodLog';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';

export interface OutstandingFoodDairyComponentProps {
  patientId: string;
}

export const OutstandingFoodDairyComponent = ({
  patientId,
}: OutstandingFoodDairyComponentProps) => {
  const {
    selectedOutstanding,
  } = usePatientPageControllerContext();
  const {
    handleFocusFoodLog
  } = useOutstandingFoodLog();

  const { send } = useBillableTimeInterventionComp();

  useEffect(() => {
    if (selectedOutstanding === OutstandingEnum.FOOD_DAIRY) {
      handleFocusFoodLog();
      send({
        patientId,
        component: BillableTimeInterventionComponentEnum.ReviewFoodLog,
        detail: BillableTimeInterventionDetailEnum.OutstandingFoodlogReview,
      });
    }
  }, [selectedOutstanding]);

  return (
    <div>
      The patient has uploaded new food diary entries. Please review it.
    </div>
  );
};
