import { Button, ButtonProps, Space } from 'antd';
import classNames from 'classnames';
import { ReactNode } from 'react';

export type Justify = 'start' | 'end';

export interface CancelManualSubmitButtonComponentProps {
  onSubmit?: () => void;
  onCancel?: () => void;
  disabled?: boolean;
  loading?: boolean;
  submitText?: ReactNode;
  cancelText?: ReactNode;
  cancelBtnType?: ButtonProps['type'];
  submitBtnType?: ButtonProps['type'];
  className?: string;
  justify?: Justify;
  reverseOrder?: boolean;
  noMargin?: boolean;
  ghost?: boolean;
}

export const CancelManualSubmitButtonComponent = ({
  onSubmit,
  onCancel,
  disabled,
  loading,
  submitText = 'Submit',
  cancelText = 'Cancel',
  cancelBtnType,
  submitBtnType = 'primary',
  className = '',
  justify = 'start',
  reverseOrder,
  noMargin,
  ghost,
}: CancelManualSubmitButtonComponentProps) => (
  <Space
    className={classNames({
      [className]: !!className,
      flex: true,
      my20: !noMargin,
      'jc-e w100': justify === 'end',
      'fr-reverse': !!reverseOrder,
    })}
  >
    {
      onCancel
      && (
        <Button
          type={cancelBtnType}
          onClick={onCancel}
          disabled={loading}
        >
          {cancelText}
        </Button>
      )
    }
    <Button
      type={submitBtnType}
      onClick={onSubmit}
      disabled={loading || disabled}
      loading={loading}
      ghost={ghost}
    >
      {submitText}
    </Button>
  </Space>
);
