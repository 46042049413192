import { Input, InputProps } from 'antd';
import { FC } from 'react';

export type OtherMedicationInformationComponentProps = InputProps;

export const OtherMedicationInformationComponent:
  FC<OtherMedicationInformationComponentProps> = ({
    value,
    onChange,
  }) => (
    <Input
      value={value}
      onChange={onChange}
      allowClear
      placeholder="Enter details about skipped medications and/or nutritional supplements"
    />
  );
