import { useMemo } from 'react';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { MemoComponent, MemoComponentProps } from '../../component/MemoComponent/MemoComponent';
import {
  useMemoDelete,
  useMemoInsert,
  useMemoSearch,
  useMemoUpdate,
} from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';

export interface MemoContainerProps {

}

export const MemoContainer = () => {
  const { info } = usePatientContext();
  const id = info?.id || '';

  const getMemo = useMemoSearch({
    options: { sendOnMount: true },
    params: {
      filter: {
        memberId: id,
      }
    },
  });

  const updateMemo = useMemoUpdate({});
  const deleteMemo = useMemoDelete({});
  const addMemo = useMemoInsert({});

  // update the state value when the note has been fetched
  const value = useMemo(() => {
    const notes = getMemo.data?.data?.content ?? [];
    return notes?.map((n) => ({
      id: n.id ?? undefined,
      updatedAt: n.updatedAt,
      updatedByUser: n.updatedByUser,
      createdAt: n.createdAt,
      createdByUser: n.createdByUser,
      note: n.note ?? undefined,
    })) || [];
  }, [getMemo.data]);

  const handleAdd: MemoComponentProps['onAdd'] = (v) => {
    const req = addMemo.send({
      params: {
        request: {
          memberId: id,
          note: v.note,
        }
      },
    });
    ApiRequestHelper.tryCatch(req, {
      success: 'Added Memo!',
      error: 'Failed to Add Memo!',
      onSuccess: () => getMemo.refetch(),
    });
  };

  const handleEdit: MemoComponentProps['onEdit'] = (v) => {
    const req = updateMemo.send({
      params: {
        id: v.id || '',
        request: {
          note: v.note,
        },
      },
    });
    ApiRequestHelper.tryCatch(req, {
      success: 'Updated Memo!',
      error: 'Failed to update Memo!',
      onSuccess: () => getMemo.refetch(),
    });
  };

  const handleDelete: MemoComponentProps['onDelete'] = (id) => {
    const req = deleteMemo.send({
      params: {
        id,
      },
    });
    ApiRequestHelper.tryCatch(req, {
      success: 'Deleted Memo!',
      error: 'Failed to Delete Memo!',
      onSuccess: () => getMemo.refetch(),
    });
  };

  return (
    <FetchComponent info={getMemo} showOnRefetch showLoadingOverlay={false}>
      <MemoComponent
        value={value}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
    </FetchComponent>
  );
};
