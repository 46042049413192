import { FC } from 'react';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { Icons } from '../../../../icons/Icons';
import { CareNoteHistory } from '../../../../uc-api-sdk';
import { DefaultExtendableDrawers } from '../../../../uiComponent/ExtendableDrawerComponent/controller/DefaultExtendableDrawers';
import { ExtendableDrawerComponent } from '../../../../uiComponent/ExtendableDrawerComponent/ExtendableDrawerComponent';
import { HistoryListComponent } from '../../../../uiComponent/HistoryComponent/HistoryListComponent';
import { ExtendableDrawerButtonComponent } from '../../../../uiComponent/ExtendableDrawerComponent/component/ExtendableDrawerButtonComponent';

export interface CareNoteHistoryDrawerProps {
  histories: CareNoteHistory[];
}
export const CareNoteHistoryDrawer: FC<CareNoteHistoryDrawerProps> = ({
  histories,
}) => {
  const {
    value: open,
    setTrue: setOpen,
    setFalse: setClose,
  } = useBoolean(false);
  return (
    <div>
      <ExtendableDrawerButtonComponent
        buttonText={<Icons.History />}
        buttonProps={{
          type: 'text',
          className: 'p0',
        }}
        onClick={setOpen}
      />
      <ExtendableDrawerComponent
        open={open}
        onClose={setClose}
        title="History"
        className="header-reversed"
        id={DefaultExtendableDrawers.addCareNoteHistory}
      >
        <HistoryListComponent notes={histories} />
      </ExtendableDrawerComponent>
    </div>
  );
};
