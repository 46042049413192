import { useMemo } from 'react';
import { message } from 'antd';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import {
  useClinicEventUpdate
} from '../../../../uc-api-sdk';
import { PatientProfileContainer } from '../../../patient/container/PatientProfileContainer/PatientProfileContainer';
import { useCreateVisitForm } from '../../hook/useCreateVisitForm';
import { useCreateVisitInitialValues } from '../../hook/useCreateVisitInitialValues';
import { ClinicEventsService } from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { VisitPariticipantReassignFormComponent, VisitPariticipantReassignFormComponentProps } from '../../component/VisitParticipantReassignFormComponent/VisitParticipantReassignFormComponent';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { useGetParticipantWithCurrentUser } from '../../hook/useGetParticipantWithCurrentUser';

export interface VisitParticipantReassignFormContainerProps {
  visit: ClinicEventsService;
  onSubmit: VisitPariticipantReassignFormComponentProps['onSubmit'];
  onCancel: VisitPariticipantReassignFormComponentProps['onCancel'];
}

export const VisitParticipantReassignFormContainer = ({
  visit,
  onSubmit,
  onCancel,
}: VisitParticipantReassignFormContainerProps) => {
  const clinicEventInfo = useClinicEventUpdate({});
  const {
    userInfo
  } = useLoggedInUserFromContext();
  const {
    visitParticipants,
    patientInfo,
  } = visit;
  const {
    parseParticipants,
  } = useCreateVisitForm();
  const {
    getParticipantsInitialValue,
  } = useCreateVisitInitialValues();
  const initialVisitValues = getParticipantsInitialValue(visitParticipants || []);
  const editVisitEvent = useUpdate('VISIT_UPDATED');
  const { send: sendMixpanel } = useMixpanelContext();

  const {
    participants,
    ...participantValues
  } = useGetParticipantWithCurrentUser(visit.self);

  const initialValues = useMemo(() => ({
    ...initialVisitValues,
    participants,
    ...participantValues
  }), [
    initialVisitValues,
    participants,
    participantValues,
  ]);

  const handleSubmit: VisitPariticipantReassignFormComponentProps['onSubmit'] = (
    values
  ) => {
    sendMixpanel({ event: MixpanelEvents.EditVisitParticipants });
    if (!visit.id) {
      message.error('Id is not found.');
      return;
    }

    ApiRequestHelper.tryCatch(
      clinicEventInfo.send({
        params: {
          id: visit.id || '',
          document: {
            visitParticipants: parseParticipants(values),
          }
        }
      }),
      {
        success: 'Updated successfully.',
        error: 'Failed to update.',
        onSuccess: () => {
          editVisitEvent.updateValue();
          onSubmit?.(values);
        }
      }
    );
  };

  return (
    <PatientProfileContainer
      patientId={patientInfo?.id || ''}
      getAssignees
    >
      <VisitPariticipantReassignFormComponent
        userId={userInfo?.id}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        addCurrentUserToOption
      />
    </PatientProfileContainer>
  );
};
