import { FC, useMemo } from 'react';
import { compact, find, map } from 'lodash';
import {
  VitalMonitoringBGScheduleOption,
  VitalMonitoringBGScheduleSelectComponent,
  VitalMonitoringBGScheduleSelectComponentProps,
  VitalMonitoringBGScheduleValue
} from '../../component/VitalsMonitoringScheduleComponent/VitalsMonitoringBGComponent/VitalsMonitoringBGTableComponent/VitalMonitoringBGScheduleSelectComponent/VitalMonitoringBGScheduleSelectComponent';
import { useWeeklyBGScheduleTemplate } from '../../../patient/hook/useWeeklyBGScheduleTemplate';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { useDeepCompareEffect } from '../../../../hooks/useDeepCompareEffect';

export interface VitalMonitoringBGScheduleSelectContainerProps extends
  Omit<VitalMonitoringBGScheduleSelectComponentProps, 'options'> {
  isCustomized?: boolean;
  autoSetDefault?: boolean;
}

export const VitalMonitoringBGScheduleSelectContainer: FC<
  VitalMonitoringBGScheduleSelectContainerProps
> = ({
  value,
  onChange,
  isCustomized,
  autoSetDefault,
}) => {
  const templateInfo = useWeeklyBGScheduleTemplate({
    params: { filter: {} },
    options: { sendOnMount: true },
  });

  const [
    options,
    defaultOptValue,
  ] = useMemo(() => {
    const opts = map(templateInfo.data?.data.content, (v) => {
      if (v.name && v.id && v.schedule) {
        const opt = {
          label: v.name,
          value: v.id,
          schedule: v.schedule,
          isCustomized: isCustomized && (
            value?.value !== undefined ? v.id === value?.value : v.name === value?.name
          ),
          isDefault: v.isDefault,
        };
        return opt;
      }
      return null;
    });
    const defaultOpt = find(opts, { isDefault: true });
    let defaultOptValue;
    if (defaultOpt) {
      defaultOptValue = {
        name: defaultOpt.label,
        schedule: defaultOpt.schedule,
      } as VitalMonitoringBGScheduleValue;
    }
    return [
      compact(opts),
      defaultOptValue
    ];
  }, [templateInfo, value, isCustomized]);

  useDeepCompareEffect(() => {
    if (autoSetDefault && !value?.name && defaultOptValue) {
      onChange?.(defaultOptValue);
    }
  }, [autoSetDefault, defaultOptValue, value]);

  return (
    <FetchComponent info={templateInfo} alwaysShowChildren>
      <VitalMonitoringBGScheduleSelectComponent
        options={options as VitalMonitoringBGScheduleOption[]}
        value={value || defaultOptValue}
        onChange={onChange}
      />
    </FetchComponent>
  );
};
