import { FC } from 'react';
import { FoodLogTabContentComponent, FoodLogTabContentComponentProps } from '../../component/FoodLogTabComponent/FoodLogTabContentComponent';
import { useGetFoodLog } from '../../hook/useGetFoodLog';
import { FoodLog } from '../../type';

export interface FoodLogTabContentContainerProps extends Omit<FoodLogTabContentComponentProps, 'refetch'> {
  activeKey: string;
  index: string;
}

export const FoodLogTabContentContainer: FC<FoodLogTabContentContainerProps> = ({
  data,
  onCancel,
  onSubmit,
  activeKey,
  index,
}) => {
  const getFoodLog = useGetFoodLog({
    params: { id: data.id },
    options: { sendOnMount: false },
  });

  return (
    <FoodLogTabContentComponent
      data={(getFoodLog.data?.data || data) as FoodLog}
      onCancel={onCancel}
      onSubmit={onSubmit}
      refetch={getFoodLog.refetch}
      activeKey={activeKey}
      index={index}
    />
  );
};
