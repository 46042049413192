import { Row, Col } from 'antd';
import { DiseaseHistoryItem } from '../../../../uc-api-sdk';
import YesNoComponent from '../../../../uiComponent/YesNoComponent/YesNoComponent';
import { DiseaseHistoryCurrentTreatmentComponent } from './DiseaseHistoryCurrentTreatmentComponent';
import { DiseaseTestedBeforeLabel } from './DiseaseTestedBeforeLabel';
import { DiseaseHistorySpecialistDisplayComponent } from './DiseaseHistorySpecialistDisplayComponent';
import { DiseaseHistoryMeasureFrequencyDisplayComponent } from './DiseaseHistoryMeasureFrequencyDisplayComponent';
import YearOfDiagnosisComponent from './YearOfDiagnosisComponent';

export interface DiseaseHistoryTemplateByVitalDisplayComponentProps {
  diseaseHistory: DiseaseHistoryItem;
}

export const DiseaseHistoryTemplateByVitalDisplayComponent = ({
  diseaseHistory,
}: DiseaseHistoryTemplateByVitalDisplayComponentProps) => (
  <Row
    gutter={70}
    className="disease-history-template-row disease-history-display"
  >
    <Col>
      <YearOfDiagnosisComponent diseaseHistory={diseaseHistory} />
      <div className="disease-history-item">
        <span className="label">Seen doctor in past 6 months?</span>
        <YesNoComponent
          yes={diseaseHistory.hasSeenDoctor}
          showIcon={false}
        />
      </div>
      <DiseaseHistorySpecialistDisplayComponent
        diseaseHistory={diseaseHistory}
      />
    </Col>
    <Col>
      <div className="disease-history-item">
        <DiseaseTestedBeforeLabel
          disease={diseaseHistory.disease}
        />
        <YesNoComponent
          yes={diseaseHistory.hasTestedBefore}
          showIcon={false}
        />
      </div>
      <DiseaseHistoryMeasureFrequencyDisplayComponent
        diseaseHistory={diseaseHistory}
      />
      <div className="disease-history-item">
        <DiseaseHistoryCurrentTreatmentComponent
          diseaseHistory={diseaseHistory}
        />
      </div>
    </Col>
  </Row>
);
