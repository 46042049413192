import classNames from 'classnames';
import { PlusOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { LinkButton } from '../LinkButton/LinkButton';

export interface AddNewLinkButtonComponentProps {
  onClick: () => void;
  buttonText?: string;
  disabled?: boolean;
  marginTop?: boolean;
  testId?: string;
}

export const AddNewLinkButtonComponent = ({
  onClick,
  buttonText = 'Add new',
  disabled,
  marginTop = true,
  testId = 'addNewLinkButton',
}: AddNewLinkButtonComponentProps) => (
  <LinkButton
    onClick={() => onClick()}
    disabled={disabled}
    className={classNames({
      b5: true,
      mt20: !!marginTop,
    })}
    color="var(--uc-blue)"
    data-testid={testId}
  >
    <Space>
      <PlusOutlined />
      {buttonText}
    </Space>
  </LinkButton>
);
