import { ReactNode } from 'react';
import { EmployeeStatus, UserTypeEnum } from '../../../../uc-api-sdk';

export enum UserFormItemName {
  FirstName = 'firstName',
  LastName = 'lastName',
  LoginEmail = 'email',
  LoginId = 'loginId',
  Language = 'language',
  Credentials = 'credentials',
  Degree = 'degree',
  Specialties = 'specialties',
  WorkState = 'workState',
  WorkTimezone = 'workTimezone',
  Phone = 'phone',
  DirectAddress = 'directAddress',
  NPI = 'npi',
  Roles = 'roles',
  RoleType = 'roleType',
  RoleCapacity = 'capacity',
  RoleEntities = 'roleEntities',
  RoleClinicalOrgs = 'roleClinicalOrgs',
  DataAnalytics = 'dataAnalytics',
  InternalObserver = 'isObserverUser',
  IgnoreCredentials = 'ignoreCredentials',
  PhoneCountryCode = 'countryCode',
}

export type UserFormItemsType = {
  [key in UserFormItemName]: {
    name: string;
    label: string;
    optionalLabel?: string;
  };
};

export type UserDisplayItemsType = {
  title: string;
  Component: ReactNode | string;
  valuePath?: string
}[];

export const USER_BASIC_INFORMATION_FORM_INPUT_MAP = {
  [UserFormItemName.FirstName]: {
    name: UserFormItemName.FirstName,
    label: 'First name',
  },
  [UserFormItemName.LastName]: {
    name: UserFormItemName.LastName,
    label: 'Last name',
  },
  [UserFormItemName.LoginEmail]: {
    name: UserFormItemName.LoginEmail,
    label: 'Account email',
  },
  [UserFormItemName.LoginId]: {
    name: UserFormItemName.LoginId,
    label: 'Login ID',
  },
  [UserFormItemName.Language]: {
    name: UserFormItemName.Language,
    label: 'Language',
  },
  [UserFormItemName.Credentials]: {
    name: UserFormItemName.Credentials,
    label: 'Credentials',
  },
  [UserFormItemName.IgnoreCredentials]: {
    name: UserFormItemName.IgnoreCredentials,
    label: '',
  },
  [UserFormItemName.Degree]: {
    name: UserFormItemName.Degree,
    label: 'Degree',
    optionalLabel: 'Degree (Optional)',
  },
  [UserFormItemName.Specialties]: {
    name: UserFormItemName.Specialties,
    label: 'Specialties',
    optionalLabel: 'Specialties (Optional)',
  },
  [UserFormItemName.WorkState]: {
    name: UserFormItemName.WorkState,
    label: 'Work state',
    optionalLabel: 'Work state (Optional)',
  },
  [UserFormItemName.WorkTimezone]: {
    name: UserFormItemName.WorkTimezone,
    label: 'Work time zone',
    optionalLabel: 'Work time zone (Optional)',
  },
  [UserFormItemName.Phone]: {
    name: UserFormItemName.Phone,
    label: 'Phone number',
    optionalLabel: 'Phone number (Optional)',
  },
  [UserFormItemName.DirectAddress]: {
    name: UserFormItemName.DirectAddress,
    label: 'Direct address',
    optionalLabel: 'Direct address (Optional)',
  },
  [UserFormItemName.NPI]: {
    name: UserFormItemName.NPI,
    label: 'NPI',
    optionalLabel: 'NPI (Optional)',
  },
} as UserFormItemsType;

export const USER_TYPE_TEXT = {
  [UserTypeEnum.CLINICAL_STAFF]: 'Clinical Staff',
  [UserTypeEnum.IHEALTH_EMPLOYEE]: 'iHealth Employee',
};

export const MEMBERS_STATUS_TEXT = {
  [EmployeeStatus.PENDING]: 'Activation Pending',
  [EmployeeStatus.ACTIVATED]: 'Active',
  [EmployeeStatus.DEACTIVATED]: 'Deactivated',
  [EmployeeStatus.DELETED]: 'Deleted',
  [EmployeeStatus.priority]: 'Priority',
};
