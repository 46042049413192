import { ReactNode } from 'react';
import './TitleWithContent.scss';
import classNames from 'classnames';

export interface TitleWithContentProps {
  title: ReactNode;
  content?: ReactNode;
  className?: string;
}

export const TitleWithContent = ({
  title,
  content,
  className = ''
}: TitleWithContentProps) => (
  <div
    className={classNames({
      'title-with-content': true,
      [className]: !!className
    })}
  >
    <div className="text-title">
      {title}
    </div>
    <div className="text-content">
      {content}
    </div>
  </div>
);
