import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import './CardComponent.scss';

export interface CardComponentProps {
  type?: 'white' | 'gray1' | 'gray2' | 'gray3' | 'gray4' | 'gray5';
  hovered?: boolean;
  children: ReactNode;
  className?: string;
}

export const CardComponent: FC<CardComponentProps> = ({
  type = 'white',
  hovered,
  children,
  className = '',
}) => (
  <div
    data-testid="card-component"
    className={classNames({
      'card-component': true,
      [className]: !!className,
      hovered,
      [type]: true,
    })}
  >
    {children}
  </div>
);

export default CardComponent;
