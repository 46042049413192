import { Button, Form } from 'antd';
import Title from 'antd/lib/typography/Title';
import { USA_DATE } from '../../../../constants/timeFormat';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { TextareaComponent } from '../../../../uiComponent/TextareaComponent/TextareaComponent';
import { useUnableToEngageForm } from '../../hook/useUnableToEngageForm';
import './UnableToEngageModalContentComponent.scss';

export interface UnableToEngageFormValues {
  unableToEngageReason: string;
  unableToEngageUpdateTime?: string;
}

export interface UnableToEngageModalContentComponentProps {
  onSubmit?: (values: UnableToEngageFormValues) => void;
  onClose?: () => void;
  initialValues?: UnableToEngageFormValues;
}

export const UnableToEngageModalContentComponent = ({
  onSubmit,
  onClose,
  initialValues
}: UnableToEngageModalContentComponentProps) => {
  const hook = useUnableToEngageForm();
  const { timezone } = usePatientContext()?.info?.patientInfo?.clinic || {};

  const handleSubmit = (values: UnableToEngageFormValues) => {
    onSubmit?.(values);
    onClose?.();
  };

  const renderTitle = () => {
    if (initialValues) {
      return (
        <span>
          Patient was in
          <span className="primary-link"> Unable to Engage </span>
          status since
          <span className="engage-date">
            {' '}
            {TimezoneService.calcDateTimeDayjs(
              initialValues.unableToEngageUpdateTime || '',
              timezone || undefined
            ).format(USA_DATE)}
          </span>
        </span>
      );
    }
    return (
      <span>
        {' '}
        Are you sure to put patient into
        {' '}
        <span className="primary-link">Unable to Engage</span>
        {' '}
        status?
      </span>
    );
  };

  return (
    <div className="unable-to-engage-modal-content-component">
      <Title level={5} className="mb20">
        {renderTitle()}
      </Title>
      <p className="text-gray-scale-2">
        {initialValues ? 'The system is currently halt the triggering of compliance '
          + 'alerts until the patient resumes taking measurements.'
          : 'By putting the patient in an "Unable to Engage” status, the system will halt'
          + ' the triggering of compliance alerts until the patient resumes taking measurements.'}
      </p>
      <Form
        form={hook.form}
        onFinish={hook.handleSubmit(handleSubmit)}
        initialValues={initialValues}
      >
        <FormItem
          required
          label={undefined}
          name="unableToEngageReason"
          className="mb20"
        >
          <TextareaComponent placeholder="Enter reason" />
        </FormItem>
        <FormItem dependencies={['unableToEngageReason']}>
          {({ getFieldValue }) => {
            const reason = getFieldValue('unableToEngageReason');
            return (
              <Button htmlType="submit" type="primary" className="w100 mb10" disabled={!reason}>
                {initialValues ? 'Remove Patient from This Status' : 'Put Patient to Unable to Engage Status'}
              </Button>
            );
          }}
        </FormItem>
        <Button type="text" className="w100 text-gray-scale-2" onClick={onClose}>
          Cancel
        </Button>
      </Form>
    </div>
  );
};
