import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { OutstandingDrawerButtonComponent } from '../../../outstanding/component/OutstandingDrawerButtonComponent/OutstandingDrawerButtonComponent';
import { MemoContainer } from '../../container/MemoContainer/MemoContainer';
import './PatientProfileTabsExtraComponent.scss';

export const PatientProfileTabsExtraComponent = () => {
  const { info } = usePatientContext();
  const id = info?.id || '';

  return (
    <div className="flex jc-e patient-profile-tabs-extra">
      <OutstandingDrawerButtonComponent patientId={id} />
      <div>
        <MemoContainer />
      </div>
    </div>
  );
};
