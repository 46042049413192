import { Button, Modal } from 'antd';

export interface VitalMonitoringRemoveButtonComponentProps {
  canRemove: () => boolean;
  onClick: () => void;
}

export const VitalMonitoringRemoveButtonComponent = ({
  canRemove,
  onClick,
}: VitalMonitoringRemoveButtonComponentProps) => {
  const handleClick = () => {
    Modal.confirm({
      title: 'Warning',
      content: 'Are you sure you want to unenroll this patient from monitoring Blood Oxygen? This will also remove the patient\'s ability to monitor this vital at home.',
      onOk: onClick,
      okText: 'Confirm',
    });
  };

  return canRemove()
    ? (
      <Button type="link" onClick={handleClick} className="p0 bold">
        Remove
      </Button>
    ) : null;
};
