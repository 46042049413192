import { FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons';
import { InputType } from '../../features/Input/types';

import './FaceRateComponent.scss';

export interface FaceRateComponentProps extends InputType<number> {
  allowClear?: boolean;
}

export const FaceRateComponent = ({
  value,
  onChange,
  allowClear = true,
}: FaceRateComponentProps) => {
  const activeClassName = (rate: number) => (value === rate ? 'face-rate--active' : '');

  const handleOnClick = (rate: number) => {
    if (value === rate && allowClear) {
      onChange?.(undefined);
      return;
    }
    onChange?.(rate);
  };

  return (
    <div className="flex jc-sb w100 face-rate">
      <FrownOutlined
        className={activeClassName(0)}
        onClick={() => handleOnClick(0)}
      />
      <MehOutlined
        className={activeClassName(1)}
        onClick={() => handleOnClick(1)}
      />
      <SmileOutlined
        className={activeClassName(2)}
        onClick={() => handleOnClick(2)}
      />
    </div>
  );
};
