import './ShippingNoticeComponent.scss';

export interface ShippingNoticeComponentProps {
  hide?: boolean;
}

export const ShippingNoticeComponent = ({
  hide,
}: ShippingNoticeComponentProps) => {
  if (hide) return null;
  return (
    <div className="shipping-notice">
      *Please note that the order is currently not linked to the warehouse.
      Kindly follow the existing protocol for device shipping.
    </div>
  );
};
