import { SignatureCollectionMethodEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface SignatureCollectionMethodEnumComponentProps {
  value: SignatureCollectionMethodEnum;
}

export const SignatureCollectionMethodEnumComponent = ({
  value,
}: SignatureCollectionMethodEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case SignatureCollectionMethodEnum.VERBALLY:
        return 'Patient verbally consents.';
      case SignatureCollectionMethodEnum.APP:
        return 'Sign consent form through the App. (App user only)';
      case SignatureCollectionMethodEnum.TEAM_MEMBER_DEVICE:
        return 'Sign consent via care team member\'s device. (In-clinic enrollment only)';
      case SignatureCollectionMethodEnum.CLINIC_PRINTED:
        return 'Patient signed printed consent form.';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  return (
    <>{getText()}</>
  );
};
