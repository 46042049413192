import { includes } from 'lodash';
import { ReactNode } from 'react';
import { useOrderFormHook } from '../../hook/useOrderFormHook';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { SupplyTypeEnum, OrderType } from '../../type';
import { CuffSuppliesComponent } from '../CuffSuppliesComponent/CuffSuppliesComponent';
import { DeviceReplacementCheckboxComponent } from '../DeviceReplacementCheckboxComponent/DeviceReplacementCheckboxComponent';
import { OrderInfoComponent } from '../OrderInfoComponent/OrderInfoComponent';
import { useOrderSuppliesContext } from '../../../../contexts/OrderSuppliesContext/OrderSuppliesContext';
import { DeviceModelEnum, VitalEnumType } from '../../../../uc-api-sdk';
import { LoanerPhoneReplacementComponent } from '../LoanerPhoneReplacementComponent/LoanerPhoneReplacementComponent';
import { TestStripLancetFormItemComponent } from '../TestStripLancetFormItemComponent/TestStripLancetFormItemComponent';

interface OrderSuppliesFormItemComponentProps {
  title?: ReactNode;
  onUncheck?: (deviceId: string) => void;
}

export const OrderSuppliesFormItemComponent = ({
  title = null,
  onUncheck,
}: OrderSuppliesFormItemComponentProps) => {
  const { orderSuppliesForm } = useOrderFormHook();
  const {
    patientVitals,
    isBPM1WithRouter,
    isBPM1WithHotspot,
    isEaseDevice,
    loanerPhone,
  } = useOrderSuppliesContext();

  const patientHasBG = includes(patientVitals, VitalEnumType.BG);
  const patientHasLoanerPhone = loanerPhone?.deviceId !== undefined;
  const showTitle = (
    patientHasBG
    || (isBPM1WithRouter || isBPM1WithHotspot)
    || isEaseDevice
    || isBPM1WithRouter
    || isBPM1WithHotspot
    || patientHasLoanerPhone
  );

  return (
    <>
      {showTitle && title}

      {
        patientHasBG
        && (
          <FormItem
            name={orderSuppliesForm.makeSuppliesFieldName(SupplyTypeEnum.BG_STRIP)}
          >
            <TestStripLancetFormItemComponent />
          </FormItem>
        )
      }

      {
        (isBPM1WithRouter || isBPM1WithHotspot)
        && (
          <FormItem name={orderSuppliesForm.makeSuppliesFieldName(SupplyTypeEnum.BPM1_CUFF)}>
            <CuffSuppliesComponent supplyType={SupplyTypeEnum.BPM1_CUFF} />
          </FormItem>
        )
      }

      {
        isEaseDevice
        && (
          <FormItem name={orderSuppliesForm.makeSuppliesFieldName(SupplyTypeEnum.EASE_CUFF)}>
            <CuffSuppliesComponent supplyType={SupplyTypeEnum.EASE_CUFF} />
          </FormItem>
        )
      }

      {
        isBPM1WithRouter
        && (
          <FormItem name={orderSuppliesForm.makeSuppliesFieldName(SupplyTypeEnum.WIFI_ROUTER)}>
            <DeviceReplacementCheckboxComponent>
              <OrderInfoComponent
                type={OrderType.SUPPLIES}
                supplyType={SupplyTypeEnum.WIFI_ROUTER}
              />
            </DeviceReplacementCheckboxComponent>
          </FormItem>
        )
      }

      {
        isBPM1WithHotspot
        && (
          <FormItem name={orderSuppliesForm.makeSuppliesFieldName(SupplyTypeEnum.HOTSPOT)}>
            <DeviceReplacementCheckboxComponent>
              <OrderInfoComponent
                type={OrderType.SUPPLIES}
                supplyType={SupplyTypeEnum.HOTSPOT}
              />
            </DeviceReplacementCheckboxComponent>
          </FormItem>
        )
      }

      {
        patientHasLoanerPhone
        && (
          <FormItem name={orderSuppliesForm.makeSuppliesFieldName(DeviceModelEnum.LOAN_DEVICE)}>
            <LoanerPhoneReplacementComponent
              onUncheck={onUncheck}
            />
          </FormItem>
        )
      }
    </>
  );
};
