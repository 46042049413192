import { ExclamationCircleFilled } from '@ant-design/icons';
import './OutstandingItemsTagComponent.scss';
import { Tag } from 'antd';

export const OutstandingItemsTagComponent = () => (
  <Tag className="rectangle-4219">
    <span className="group-3521">
      <ExclamationCircleFilled className="icon" />
      <span className="text">
        Patient has open outstanding items
      </span>
    </span>
  </Tag>
);
