import React from 'react';
import { InputType } from '../../../Input/types';
import { TextareaComponent } from '../../../../uiComponent/TextareaComponent/TextareaComponent';

export interface RejectReasonInputComponentProps extends
  InputType<string> {

}

export const RejectReasonInputComponent = ({
  value,
  onChange,
}: RejectReasonInputComponentProps) => (
  <TextareaComponent
    value={value}
    onChange={onChange}
    placeholder="Enter reject reason."
  />
);
