import { Icons } from '../../../../icons/Icons';
import './UnifiedCareAppIconComponent.scss';

export interface UnifiedCareAppIconComponentProps {

}

export const UnifiedCareAppIconComponent = () => (
  <div className="unifiedcare-app-icon-component">
    <Icons.UcAppIcon size={{ width: 40 }} />
  </div>
);
