import { Empty } from 'antd';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { DeviceListComponent } from '../../component/DeviceListComponent/DeviceListComponent';
import { usePatientGetPatientDevice } from '../../../../uc-api-sdk';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';

export interface DeviceTableContainerProps {
  patientId?: string;
}

export const DeviceTableContainer = ({
  patientId,
}: DeviceTableContainerProps) => {
  const info = usePatientGetPatientDevice({
    params: {
      memberId: patientId ?? '',
    },
  });
  useUpdateListener('DEVICE_MONITOR_UPDATED', info.refetch);

  return (
    <FetchComponent
      info={info}
      alwaysShowChildren
      showErrorOverlay
      showLoadingOverlay
    >
      {info.data?.data?.deviceList ? (
        <DeviceListComponent
          patientId={patientId || ''}
          patientDevice={info.data?.data}
        />
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </FetchComponent>
  );
};
