import React from 'react';
import { TagComponent } from '../../../../../uiComponent/TagComponent/TagComponent';

export const BillableTagComponent = {
  YesTag: () => (
    <TagComponent type="success" iconType="solidDot" background="none">
      Yes
    </TagComponent>
  ),
  NoTag: () => (
    <TagComponent type="error" iconType="solidDot" background="none">
      No
    </TagComponent>
  ),
};
