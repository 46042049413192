/* eslint-disable react/jsx-props-no-spreading */
import { map } from 'lodash';
import { Form, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { InterventionSubmitCancelButtons } from '../../InterventionSubmitCancelButtons/InterventionSubmitCancelButtons';
import { InterventionFormComponentProps } from '../../../type';
import FormItem from '../../../../../uiComponent/FormItem/FormItem';
import { BehaviorGoalCategoryEnum, BehaviorGoalItem } from '../../../../../uc-api-sdk';
import { useBehaviorGoalForm } from '../../../hook/useBehaviorGoalForm';

import '../../ClinicalGoalComponent/ClinicalGoalFormComponent/GoalFormComponent.scss';

import '../BehaviorGoalCardListComponent/BehaviorGoalCardListComponent.scss';
import { BehaviorGoalSearchSelectContainer } from '../../../container/BehaviorGoalSearchSelectContainer/BehaviorGoalSearchSelectContainer';

import { BehaviorGoalAddNewComponent } from '../BehaviorGoalAddNewComponent/BehaviorGoalAddNewComponent';
import { useDeepCompareMemo } from '../../../../../hooks/useDeepCompareEffect';
import { BehaviorGoalCategoryLabelComponent } from '../BehaviorGoalCategoryLabelComponent/BehaviorGoalCategoryLabelComponent';
import { useBehaviorGoalHelpers } from '../../../hook/useBehaviorGoalHelpers';

export interface SubmitValue {
  behaviorGoals: BehaviorGoalItem[]
}

export type Status = string;
export type Description = string;

export interface BehaviorGoalCardFormComponentProps extends
  InterventionFormComponentProps<SubmitValue> { }

export const BehaviorGoalCardFormComponent = ({
  onSubmit,
  initialValues,
  isLoading,
  onCancel,
  onValuesChange,
  formButtons = <InterventionSubmitCancelButtons onCancel={onCancel} />,
}: BehaviorGoalCardFormComponentProps) => {
  const behaviorGoalForm = useBehaviorGoalForm();
  const {
    form,
    formDisabled,
    getMainFormItemName,
    makeListNamePath,
    makeObjectNamePathFromMain,
    handleAddCategory,
  } = behaviorGoalForm;
  const {
    groupBehaviorGoalsByCategory
  } = useBehaviorGoalHelpers();

  const processedInitialValues = useDeepCompareMemo(() => ({
    behaviorGoals: groupBehaviorGoalsByCategory(initialValues?.behaviorGoals),
  }), []);

  return (
    <Form
      form={form}
      onFinish={behaviorGoalForm.handleSubmit(onSubmit)}
      initialValues={processedInitialValues}
      disabled={formDisabled || isLoading}
      onValuesChange={behaviorGoalForm.onValuesChange(onValuesChange)}
    >
      <div className="behavior-goal-items">
        <FormItem
          name={getMainFormItemName()}
        >
          <BehaviorGoalSearchSelectContainer />
        </FormItem>
        <FormItem
          noStyle
          shouldUpdate
        >
          {
            ({ getFieldValue }) => {
              const goalsByCategory = getFieldValue(getMainFormItemName());
              return (
                <div>
                  {
                    map(goalsByCategory, (
                      goals: BehaviorGoalItem[],
                      category: BehaviorGoalCategoryEnum,
                    ) => (
                      goals.length > 0
                        ? (
                          <div key={category} className="flex fd-c w100">
                            <BehaviorGoalCategoryLabelComponent
                              category={goals[0].category}
                              customCategory={goals[0].customCategory}
                            />
                            <Form.List
                              key={category}
                              name={makeObjectNamePathFromMain(category)}
                            >
                              {
                                (fields, { remove }) => (
                                  map(fields, (field) => (
                                    <div key={field.key} className="flex gap2 ai-c mb12">
                                      <FormItem
                                        {...field}
                                        required
                                        name={makeListNamePath('behaviorGoalValue', field.name)}
                                        className="w100 mb0"
                                      >
                                        <Input.TextArea />
                                      </FormItem>
                                      <DeleteOutlined
                                        onClick={() => remove(field.name)}
                                      />
                                    </div>
                                  ))
                                )
                              }
                            </Form.List>
                          </div>
                        ) : null
                    ))
                  }
                </div>
              );
            }
          }
        </FormItem>
        <BehaviorGoalAddNewComponent
          onAdd={handleAddCategory}
        />
      </div>
      {formButtons}
    </Form>
  );
};
