import {
  Button,
  Form,
  FormProps,
  Space,
} from 'antd';
import { ReactNode, useState } from 'react';
import { MedicationContextProvider } from '../../../../contexts/MedicationContext/MedicationContext';
import { useMedicationContainerForm } from '../../../../hooks/useMedicationForm/useMedicationContainerForm';
import { Nullable } from '../../../../uc-api-sdk';
import { InterventionSubmitCancelButtons } from '../../../intervention/component/InterventionSubmitCancelButtons/InterventionSubmitCancelButtons';
import { MedicationType } from '../../type/type';
import { AddMedicationDrawerComponent } from '../AddMedicationDrawerComponent/AddMedicationDrawerComponent';
import { MedicationComplianceFormItemsComponent } from '../MedicationTabFormComponent/MedicationComplianceFormItemsComponent';
import { FormType } from '../../../Input/types';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { NoDataWithActionComponent } from '../../../../uiComponent/NoDataWithActionComponent/NoDataWithActionComponent';

export interface MedicationManagementType {
  reconciliationCompliance: string;
  reconciliationComplianceOther?: string;
  otherInfo: string;
  extraNote: string;
}

export interface ChildrenValue {
  addMedication: ReactNode;
}

export interface MedicalHistoryMedicationSectionComponentProps extends FormType<unknown> {
  initialValues?: FormProps['initialValues'];
  onDelete?: (memberId: string) => void;
  onInsert?: (value: MedicationType) => void;
  onUpdate?: (value: MedicationType, id: Nullable<string> | undefined) => void;
  onFinish?: (v: MedicationManagementType) => void;
  onCancel?: () => void;
  children?: (value: ChildrenValue) => ReactNode;
  mode: 'Edit' | 'View';
  patientId?: string;
  onClickAddMedication?: () => void;
  discontinuedMedications: MedicationType[],
  prescribedMedications: MedicationType[],
}

export const MedicalHistoryMedicationSectionComponent = ({
  initialValues,
  onDelete,
  onInsert,
  onUpdate,
  onFinish,
  onCancel,
  children,
  mode,
  onValuesChange,
  patientId,
  onClickAddMedication,
  formButtons = <InterventionSubmitCancelButtons onCancel={onCancel} />,
  discontinuedMedications,
  prescribedMedications,
}: MedicalHistoryMedicationSectionComponentProps) => {
  const hook = useMedicationContainerForm();
  const [prevMeds, setPrevMeds] = useState(0);
  const [curMeds, setCurMeds] = useState(0);

  const handleSubmit = async (v: MedicationManagementType) => {
    onFinish?.(v);
  };

  const renderFormItems = () => (
    <>
      <MedicationComplianceFormItemsComponent
        visible
      />
      <div className="pb20 mt20">
        <Space>
          {formButtons}
        </Space>
      </div>
    </>
  );

  const renderItems = () => (
    <Space direction="vertical" size={20} className="mb20">
      <div>
        <div className="text-gray-scale-2">Medication Reconciliation & Compliance</div>
        <DisplayOrEmptyComponent value={initialValues?.reconciliationCompliance} />
      </div>
      <div>
        <div className="text-gray-scale-2">Other Medication Information</div>
        <DisplayOrEmptyComponent value={initialValues?.otherInfo} />
      </div>
    </Space>
  );

  const renderChildren = () => {
    const hasMedication = !!(prescribedMedications.length || discontinuedMedications.length);
    if (!hasMedication) {
      return (
        <NoDataWithActionComponent
          description="No medication record at this time."
        >
          <AddMedicationDrawerComponent
            patientId={patientId}
            onClickAddMedication={onClickAddMedication}
          >
            {
              ({ onClick }) => (
                <Button
                  type="link"
                  onClick={onClick}
                >
                  Add Medication
                </Button>
              )
            }
          </AddMedicationDrawerComponent>
        </NoDataWithActionComponent>
      );
    }
    return children?.({
      addMedication: (
        <AddMedicationDrawerComponent
          patientId={patientId}
          disabled={hook.formDisabled}
          onClickAddMedication={onClickAddMedication}
        />
      ),
    });
  };

  return (
    <MedicationContextProvider
      prevMeds={prevMeds}
      curMeds={curMeds}
      setPrevMeds={setPrevMeds}
      setCurMeds={setCurMeds}
      onDelete={onDelete}
      onInsert={onInsert}
      onUpdate={onUpdate}
    >
      {
        mode === 'Edit' ? (
          <Form
            form={hook.form}
            className="mb20"
            id="medicationForm"
            onFinish={hook.handleSubmit(handleSubmit)}
            initialValues={initialValues}
            onValuesChange={onValuesChange}
            disabled={hook.formDisabled}
            layout="vertical"
          >
            {renderFormItems()}
          </Form>
        ) : renderItems()
      }
      {renderChildren()}
    </MedicationContextProvider>
  );
};
