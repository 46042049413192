import { useEffect, useState } from 'react';
import { LabResultClass } from '../../../../uc-api-sdk';
import { ClientPos, ModalComponent } from '../../../../uiComponent/ModalComponent/ModalComponent';
import { PrevNextComponent } from '../../../../uiComponent/PrevNextComponent/PrevNextComponent';
import './LabResultModalComponent.scss';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import { LabResultDeleteButtonContainer } from '../../container/LabResultTableContainer/LabResultDeleteButtonContainer';
import { EditLabResultContainer } from '../../container/EditLabResultContainer/EditLabResultContainer';
import { LabResultCollectedDateComponent } from '../LabResultCollectedDateComponent/LabResultCollectedDateComponent';
import { MediumDrawerWidth } from '../../../../constants/drawer';
import { ExtendableDrawerComponent } from '../../../../uiComponent/ExtendableDrawerComponent/ExtendableDrawerComponent';
import { DefaultExtendableDrawers } from '../../../../uiComponent/ExtendableDrawerComponent/controller/DefaultExtendableDrawers';
import { LabResultEditButtonComponent } from '../LabResultEditButtonComponent/LabResultEditButtonComponent';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';

export interface LabResultModalComponentProps {
  labResult?: LabResultClass;
  onClose?: () => void;
  goToPreLabResult: () => void;
  goToNextLabResult: () => void;
  disablePre?: boolean;
  disableNext?: boolean;
  clientPos?: ClientPos;
}

export const LabResultModalComponent = ({
  labResult,
  onClose,
  goToNextLabResult,
  goToPreLabResult,
  disableNext = false,
  disablePre = false,
  clientPos,
}: LabResultModalComponentProps) => {
  const { info: { id } = {} } = usePatientContext();
  const {
    value: open,
    setTrue: setOpen,
    setFalse: setClose,
  } = useBoolean(false);
  useUpdateListener('LAB_DELETE', setClose);
  const [
    editLabResult,
    setEditLabResult,
  ] = useState<LabResultClass | undefined>(undefined);

  useEffect(() => {
    if (open && labResult) {
      setEditLabResult(labResult);
    }
    if (!open) {
      setEditLabResult(undefined);
    }
  }, [open, labResult]);

  const getTitle = () => (
    <div className="flex jc-sb b6 fs20">
      <PrevNextComponent
        gotoNext={goToNextLabResult}
        gotoPre={goToPreLabResult}
        disablePre={disablePre}
        disableNext={disableNext}
      >
        {labResult?.templateName}
      </PrevNextComponent>
    </div>
  );

  return (
    <>
      <ModalComponent
        className="lab-result-modal-component h100"
        clientPos={clientPos}
        footer={null}
        title={undefined}
        open={!!labResult}
        width={520}
        leftOrRight="right"
        onCancel={onClose}
      >
        <FixedComponent>
          <FixedComponent.Child>
            {getTitle()}
          </FixedComponent.Child>
          <FixedComponent.Child>
            <div className="lab-result-modal-component mt20">
              <div className="text-secondary-gray">Test Date*</div>
              <div className="mb20">
                <LabResultCollectedDateComponent labResult={labResult} />
              </div>
              <EditLabResultContainer
                id={labResult?.id ?? undefined}
                templateId={labResult?.templateId ?? undefined}
                mode="View"
                isModalView
              />
            </div>
          </FixedComponent.Child>
          <FixedComponent.Child isFixed className="border-t">
            <div className="flex gap2 mt20">
              <LabResultEditButtonComponent
                patientId={id}
                onClick={setOpen}
              />
              <LabResultDeleteButtonContainer
                size="middle"
                id={labResult?.id ?? undefined}
              />
            </div>
          </FixedComponent.Child>
        </FixedComponent>
      </ModalComponent>
      <ExtendableDrawerComponent
        id={DefaultExtendableDrawers.editLabResult}
        open={open}
        onClose={setClose}
        footer={null}
        width={MediumDrawerWidth}
        title={editLabResult?.templateName}
        maskClosable={false}
        destroyOnClose
      >
        <EditLabResultContainer
          id={editLabResult?.id ?? undefined}
          templateId={editLabResult?.templateId ?? undefined}
          onCancel={setClose}
          mode="Edit"
          patientId={id}
        />
      </ExtendableDrawerComponent>
    </>
  );
};
