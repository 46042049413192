import React, { FC } from 'react';
import { useBOResultToTable } from '../../hook/useBOResultToTable/useBOResultToTable';
import { TimeOfDayTableComponent } from '../TimeOfDayTableComponent/TimeOfDayTableComponent';
import { BOTableCellComponent } from './BOTableCellComponent';
import { MeasurementType } from '../../type/MeasurementTypes';
import { FromDateToDateDayjs } from '../../type';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { BOTooltipComponent } from '../TooltipComponent/BOTooltipComponent';

export interface BOTableDataProcessorComponentProps extends FromDateToDateDayjs {
  data: MeasurementType[];
}

export const BOTableDataProcessorComponent: FC<BOTableDataProcessorComponentProps> = ({
  data,
  fromDate,
  toDate,
}) => {
  const processedData = useBOResultToTable(data, fromDate, toDate);

  return (
    <>
      <TooltipComponent
        className="mb10 w-mc"
        title={<BOTooltipComponent />}
        placement="topLeft"
        type="info-icon"
        color="white"
        overlayStyle={{ minWidth: '400px' }}
      >
        <span>Log book</span>
      </TooltipComponent>
      <TimeOfDayTableComponent
        data={processedData}
        render={(v) => <BOTableCellComponent data={v} />}
      />
    </>
  );
};
