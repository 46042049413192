import { ReactNode } from 'react';
import { ALL_CLINIC_SELECT_OPTION, ClinicSelectComponent, ClinicSelectComponentProps } from '../../../../uiComponent/ClinicSelectComponent/ClinicSelectComponent';
import { SelectType } from '../../../Input/types';
import { MedicalOrganization } from '../../../../uc-api-sdk';

export const DEFAULT_CLINIC_IN_FILTER_ID = 'clinicInFilter';

export interface ClinicInFilterChildrenProps
  extends SelectType<string> {
  getClinicData?: ClinicSelectComponentProps['getClinicData'];
  filters?: MedicalOrganization;
}

export interface ClinicInFilterComponentProps extends Omit<SelectType<string>, 'children'> {
  onChange: (value?: string) => void;
  dataId?: string;
  title?: ReactNode;
  getClinicData?: ClinicSelectComponentProps['getClinicData'];
  getAllClinics?: boolean;
  userId?: string;
  children?: (props: ClinicInFilterChildrenProps) => ReactNode;
}

export const ClinicInFilterComponent = ({
  id,
  dataId = DEFAULT_CLINIC_IN_FILTER_ID,
  value,
  onChange,
  title = (<span>Search in</span>),
  getClinicData,
  getAllClinics,
  children = (props) => (
    <ClinicSelectComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      includesAllOption
      getAllClinics={getAllClinics}
    />
  ),
}: ClinicInFilterComponentProps) => {
  const handleOnChange = (value?: string) => {
    const v = value === ALL_CLINIC_SELECT_OPTION.value ? undefined : value;
    onChange?.(v);
  };

  return (
    <div
      data-id={dataId}
      className="flex fd-c py10 pr20 fs-normal text-secondary-gray mt10 mb10"
    >
      {title}
      {
        children({
          id,
          value,
          onChange: handleOnChange,
          getClinicData,
          // import props
          onMouseDown: (e) => { e.stopPropagation(); },
        })
      }
    </div>
  );
};
