import { useRef } from 'react';

interface FormValuesChange<ValueType> {
  lastValuesChanged?: Partial<ValueType>;
  values?: ValueType;
}

export const useFormValuesChange = <ValueType = unknown>() => {
  const ref = useRef<FormValuesChange<ValueType> | undefined>();
  const onValuesChange = (
    changedValues: Partial<ValueType>,
    values: ValueType,
  ) => {
    ref.current = {
      lastValuesChanged: changedValues,
      values,
    };
  };

  const resetValuesChange = () => {
    ref.current = undefined;
  };

  return {
    changes: ref,
    onValuesChange,
    resetValuesChange,
  };
};
