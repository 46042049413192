import React, { useEffect, useState } from 'react';
import {
  Button,
} from 'antd';
import {
  PaperClipOutlined,
} from '@ant-design/icons';
import './MessageInputComponent.scss';
import { RcFile } from 'antd/lib/upload';
import ModalButton from '../../../../uiComponent/ModalButton/ModalButton';
import { Icons } from '../../../../icons/Icons';
import { ArticlesMainContainer } from '../../../articles/containers/ArticlesMainContainer';
import { MessageUploadPreviewComponent } from '../MessageUploadPreviewComponent/MessageUploadPreviewComponent';
import { UploadComponent, UploadComponentProps } from '../../../../uiComponent/UploadComponent/UploadComponent';
import { useMessagePatientContext } from '../../../../contexts/MessageContext/MessagePatientContext';
import { useMultilingualTranslation } from '../../../foodLog/hook/useMultilingual';
import { TranslateInputValue, TranslationInputCommonComponent } from '../../../foodLog/component/TranslationInputCommonComponent/TranslationInputCommonComponent';

export interface MessageInputComponentProps {
  action: UploadComponentProps['action'];
  value: TranslateInputValue;
  uploadPreviewFile?: RcFile;
  disabled?: boolean;
  isSending?: boolean;
  sendDisabled?: boolean;
  placeholder?: string;
  removeUploadPreviewFile?: () => void;
  onSend?: () => void;
  onChange?: (value: TranslateInputValue) => void;
}

export const MessageInputComponent = ({
  action,
  value,
  uploadPreviewFile,
  disabled,
  isSending,
  sendDisabled,
  placeholder,
  onSend,
  onChange,
  removeUploadPreviewFile,
}: MessageInputComponentProps) => {
  const {
    patientId,
  } = useMessagePatientContext();

  // control the translation area not to be automatically triggered when there is a draft text
  const [autoTranslate, setAutoTranslate] = useState(false);
  const { multilingualTranslate } = useMultilingualTranslation();

  const handleSend = () => (
    onSend?.()
  );

  const handleValueChange = (value?: TranslateInputValue) => {
    if (value) {
      onChange?.(value);
    }
  };

  const handlePressEnter: React.KeyboardEventHandler<HTMLTextAreaElement> = (evt) => {
    if (evt.shiftKey) return;
    evt.preventDefault();
    handleSend();
  };

  const TranslatedText = async () => {
    const translated = await multilingualTranslate(value.originalText || '', value.language || '');
    const newValue = {
      ...value,
      translatedText: translated,
      showTranslated: true,
    };
    onChange?.(newValue);
  };

  const handleTranslate = async () => {
    await TranslatedText();
    setAutoTranslate(true);
  };

  useEffect(() => {
    if (autoTranslate && value.language) {
      TranslatedText();
    }
  }, [value.language, autoTranslate]);

  return (
    <div className="message-input-component">
      <TranslationInputCommonComponent
        value={value}
        placeholder={placeholder || 'Type message...'}
        disabled={disabled}
        messageHistoryArea
        onPressEnter={handlePressEnter}
        onChange={handleValueChange}
      />
      <div className="flex jc-sb ai-e mt10">
        <div className="message-input-component__actions__upload-preview mt10">
          <MessageUploadPreviewComponent
            file={uploadPreviewFile}
            onRemove={removeUploadPreviewFile}
            disabled={disabled}
          />
        </div>
        <div className="message-input-component__actions">
          <Icons.TranslateIcon onClick={handleTranslate} />
          <div className="message-input-component__actions__attach-article">
            <ModalButton
              buttonType="default"
              button={(
                <Icons.MessageArticleIcon
                  size={21}
                  hexColor="#000"
                />
              )}
              width="60vw"
            >
              <ArticlesMainContainer
                patientId={patientId}
              />
            </ModalButton>
          </div>
          <UploadComponent
            name="message-file"
            accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            className="message-file"
            showUploadList={false}
            action={action}
            disabled={disabled}
          >
            <PaperClipOutlined />
          </UploadComponent>
          <Button
            type="primary"
            block
            onClick={handleSend}
            loading={isSending}
            disabled={disabled || sendDisabled}
            className="message-send-btn"
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};
