import { useState } from 'react';
import { v4 } from 'uuid';
import { MixpanelEvents } from '../../contexts/MixpanelContext/MixpanelEvents';
import { MainSendArgs, useMixpanelMainContext } from '../../contexts/MixpanelContext/MixpanelMainContext';
import useDebounce from '../useDebounce/useDebounce';

export interface MpTrackingHelperProps extends Omit<MainSendArgs, 'event'> {
  eventStart?: MixpanelEvents,
  eventEnd?: MixpanelEvents,
}

export const useMpTrackingHelper = (args: MpTrackingHelperProps) => {
  const { send } = useMixpanelMainContext();
  const [eventId] = useState(v4());
  const debouncedSend = useDebounce(send, 100, []);

  const startEvent = (newProps?: MpTrackingHelperProps['properties']) => {
    if (!args.eventStart) return;
    debouncedSend({
      event: args.eventStart,
      parent: args.parent,
      patientId: args.patientId,
      properties: {
        ...args.properties,
        ...newProps,
        uuid: eventId
      }
    });
  };

  const endEvent = (newProps?: MpTrackingHelperProps['properties']) => {
    if (!args.eventEnd) return;
    debouncedSend({
      event: args.eventEnd,
      parent: args.parent,
      patientId: args.patientId,
      properties: {
        ...args.properties,
        ...newProps,
        uuid: eventId
      }
    });
  };

  return { startEvent, endEvent };
};
