import { InsuranceRelationshipEnum } from '../../uc-api-sdk';

export interface InsuranceRelationshipToInsuredEnumComponentProps {
  value?: InsuranceRelationshipEnum;
}

export const InsuranceRelationshipToInsuredEnumComponent = ({
  value,
}: InsuranceRelationshipToInsuredEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case InsuranceRelationshipEnum.SUBSCRIBER:
        return 'Self';
      case InsuranceRelationshipEnum.DEPENDENT:
        return 'Dependent';
      default:
        return '';
    }
  };

  return (<>{getText()}</>);
};
