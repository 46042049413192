import { upperFirst } from 'lodash';
import React, { FC, useMemo } from 'react';
import { BOSummaryKey } from '../../type';

export interface BOSummaryTypeComponentProps {
  type: BOSummaryKey;
}

export const BOSummaryTypeComponent: FC<BOSummaryTypeComponentProps> = ({
  type,
}) => {
  const value = useMemo(() => {
    switch (type) {
      case 'criticalLow': return 'Critical Low';
      default: return upperFirst(type);
    }
  }, [type]);

  return (
    <div>
      {value}
    </div>
  );
};
