const languageMapping: { [key: string]: string } = {
  EL: 'English',
  CT: 'Chinese (Traditional)',
  MN: 'Chinese (Simplified)',
  SP: 'Spanish',
  VN: 'Vietnamese',
  TA: 'Filipino',
  JP: 'Japanese'
};

export const useDefaultLanguageHelper = (
  spokenLanguage?: string | undefined,
  appLanguage?: string | undefined,
) => {
  const mappedSpokenLanguage = languageMapping[spokenLanguage || ''] ?? undefined;
  const mappedAppLanguage = languageMapping[appLanguage || ''] ?? undefined;

  if (mappedSpokenLanguage === mappedAppLanguage) {
    return mappedAppLanguage;
  }
  if (mappedAppLanguage === 'English') {
    return mappedSpokenLanguage;
  }
  return mappedAppLanguage || 'English';
};
