import { map } from 'lodash';
import React, { FC, useMemo } from 'react';
import { FromDateToDateDayjs } from '../../type';
import { HSChartComponent } from './HSChartComponent';
import { Measurement } from '../../../../uc-api-sdk';
import { HSResultHelper } from '../../helper/HSResultHelper';
import { NonNullUndefined } from '../../../../types/common';

export interface HSChartDataProcessorComponentProps extends FromDateToDateDayjs {
  data: Measurement[];
}

export const HSChartDataProcessorComponent: FC<HSChartDataProcessorComponentProps> = ({
  data,
  fromDate,
  toDate,
}) => {
  const processedData = useMemo(() => (
    map(data as NonNullUndefined<Measurement>[], (v) => (
      HSResultHelper.toDataTable(v)
    ))
  ), [data]);

  return (
    <HSChartComponent
      data={processedData}
      fromDate={fromDate}
      toDate={toDate}
    />
  );
};
