import { useCallback } from 'react';
import { useBillableTimeContext } from '../../../../contexts/BillableTimeContext/BillableTimeContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useRouter } from '../../../../hooks/useRouter/useRouter';
import { TimeTrackingEventEnum, useTimeTrackingInsert } from '../../../../uc-api-sdk';
import { useBillableInfoContext } from '../../context/BillableInfoContext/BillableInfoContext';
import { BillableTimeInterventionDetailEnum } from '../../contants/contants';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';

export interface BillableEventOptions {
  eventType: TimeTrackingEventEnum;
  component: string | undefined;
  patientId: string | undefined;
  detail?: BillableTimeInterventionDetailEnum | undefined;
}

export const useBillableEventTracker = () => {
  const { token } = useLoggedInUserFromContext();
  const { sessionId } = useBillableTimeContext();
  const { pathname } = useRouter();
  const { isBillable, isEventBillable } = useBillableInfoContext();
  const timeTrackingInsert = useTimeTrackingInsert({});

  const sendTimeTracking = useCallback((arg: BillableEventOptions) => {
    if (arg.eventType !== TimeTrackingEventEnum.INTERVENTION && !isBillable) return;
    if (arg.eventType === TimeTrackingEventEnum.INTERVENTION && !isEventBillable) return;

    if (sessionId) {
      const loginReq = timeTrackingInsert.send({
        params: {
          event: {
            sessionId,
            timestamp: Date.now(),
            url: pathname,
            eventType: arg.eventType,
            component: arg.component,
            patientId: arg.patientId,
            detail: arg.detail,
          },
        },
      });
      ApiRequestHelper.tryCatch(loginReq, 'Failed Login tracking!');
    } else {
      console.error('SessionId is missing for billable time!');
    }
  }, [sessionId, pathname, isBillable, token]);

  return {
    sendTimeTracking,
  };
};
