import React, { FC, ReactNode } from 'react';

export const EmptyString = '-- --';
export const EmptyStringMini = '--';

export interface EmptyComponentProps {
  type?: 'default' | 'reactNode' | 'mini';
  text?: ReactNode;
}

export const EmptyComponent: FC<EmptyComponentProps> = ({
  type = 'default',
  text = EmptyString,
}) => {
  const renderReactNode = (child: ReactNode) => (
    <span data-testid="empty-component">{child}</span>
  );

  switch (type) {
    case 'mini': return renderReactNode('--');
    case 'reactNode': return renderReactNode(text);
    case 'default':
    default: return renderReactNode('-- --');
  }
};

export default EmptyComponent;
