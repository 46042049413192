import { Event, EventProps } from 'react-big-calendar';
import dayjs from 'dayjs';
import { CalendarVisitEventComponent } from '../CalendarVisitEventComponent/CalendarVisitEventComponent';
import { CalendarEvent } from '../BigCalendarComponent/BigCalendarComponent';
import { CalendarGoogleEventComponent } from '../CalendarGoogleEventComponent/CalendarGoogleEventComponent';
import { CalendarMultiPurposeEventComponent } from '../CalendarMultiPurposeEventComponent/CalendarMultiPurposeEventComponent';
import { CalendarSourceEnum, ClinicEvent } from '../../../../uc-api-sdk';
import { AccountHelper } from '../../../../helpers/account';
import { CalendarNoAccessComponent } from '../CalendarNoAccessComponent/CalendarNoAccessComponent';

export const CalendarEventComponent = ({
  event,
}: EventProps<Event>) => {
  const e = event as CalendarEvent<ClinicEvent>;

  if (e.info?.accessToView === false) {
    return (
      <CalendarNoAccessComponent
        startTime={dayjs(event.start)}
        endTime={dayjs(event.end)}
      />
    );
  }
  if (e.info?.visitType) {
    return (
      <CalendarVisitEventComponent
        startTime={dayjs(event.start)}
        endTime={dayjs(event.end)}
        visitType={e.info.visitType}
        visitMethod={e.info.visitMethod ?? undefined}
        patientName={AccountHelper.getFullName(e.info.patientInfo)}
        clinicNumber={e.info.clinicInfo?.orgNumber ?? ''}
        className={e.className}
      />
    );
  }
  if (e.info?.calendarSource === CalendarSourceEnum.OTHER) {
    return (
      <CalendarMultiPurposeEventComponent
        startTime={dayjs(event.start)}
        endTime={dayjs(event.end)}
        title={event.title}
        isAllDay={event.allDay || false}
        className={e.className}
      />
    );
  }
  return (
    <CalendarGoogleEventComponent
      startTime={dayjs(event.start)}
      endTime={dayjs(event.end)}
      title={event.title}
      isAllDay={event.allDay || false}
      className={e.className}
    />
  );
};
