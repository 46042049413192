import _ from 'lodash';

export class StringHelper {
  public static isAlphaNumeric(str: string) {
    return str.match(/^[a-z0-9]+$/i) !== null;
  }

  public static isAlpha(str: string) {
    return str.match(/^[a-z]+$/i) !== null;
  }

  public static getInitials(str: string) {
    const arr = str.split(' ');
    return arr.map((s) => s[0]).join('');
  }

  public static camelCasefy(str: string) {
    if (typeof str !== 'string') {
      return '';
    }
    return _.startCase(str?.toLowerCase());
  }
}
