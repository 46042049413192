import { useMemo } from 'react';
import dayjs from 'dayjs';
import TimezoneService from '../../../../helpers/timezone/timezoneService';

export interface TranscribeDueDateComponentProps {
  dueDate?: string | null;
}

export const TranscribeDueDateComponent = ({
  dueDate,
}: TranscribeDueDateComponentProps) => {
  const dataString: string = useMemo(() => {
    if (!dueDate) return '-- --';
    const date = TimezoneService.calcDateTimeDayjs(dueDate ?? '');
    const remainingDays = date.diff(dayjs(), 'days', true);
    const currentDateTime = dayjs();
    const isDueToday = currentDateTime.isSame(dueDate, 'day');
    if (isDueToday) {
      return 'Due today';
    }
    if (remainingDays > 0 && remainingDays < 1) {
      return 'Due in 1 day';
    }
    if (remainingDays > 1) {
      return `Due in ${Math.ceil(remainingDays)} days`;
    }
    return 'Overdue';
  }, [dueDate]);

  return (
    <span>{dataString}</span>
  );
};
