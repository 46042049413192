import { Space, Tabs, TabsProps } from 'antd';
import { Dayjs } from 'dayjs';
import { map } from 'lodash';
import { ReactNode, useMemo, useState } from 'react';
import { DATE_DAY_TIME_TIMEZONE } from '../../../../constants/timeFormat';
import { Title } from '../../../../uiComponent/Title/Title';
import { MeasurementService } from '../../helper/MeasurementService';
import { Severity } from '../../type';
import { MeasurementDelayedInfoComponent } from '../MeasurementDelayedInfoComponent/MeasurementDelayedInfoComponent';
import './LogbookComponent.scss';

export interface LogbookDataType {
  date: Dayjs;
  timezone: string;
  hadActivity: boolean;
  hadInsulin: boolean;
  hadMedicine: boolean;
  delayedUpload: boolean;
  moodTags: string[];
  severity: Severity;
  userNote: string;
}

export interface LogbookComponentProps<T extends MeasurementService> {
  data: T[];
  renderTitle: (v: T) => ReactNode;
  renderLogbookInfo: (v: T) => ReactNode;
  renderTab: (v: T, isSelected: boolean, onSelect: () => void) => ReactNode;
}

export const LogbookComponent = <T extends MeasurementService>({
  data,
  renderTitle,
  renderLogbookInfo,
  renderTab,
}: LogbookComponentProps<T>) => {
  const [tabSelected, setTabSelected] = useState('0');

  const tabItems = useMemo<TabsProps['items']>(() => {
    const items = map(data, (v, i) => {
      const key = i.toString();
      return {
        key,
        label: (
          <div className="logbookTab">
            {renderTab(v, key === tabSelected, () => setTabSelected(key))}
          </div>
        ),
        children: (
          <div className="mb20">
            <Space>
              {v.date.format(DATE_DAY_TIME_TIMEZONE)}
              {v.delayedUpload && <MeasurementDelayedInfoComponent />}
            </Space>
            {renderLogbookInfo(v)}
          </div>
        ),
      };
    });
    return items;
  }, [data, tabSelected]);

  const selectedData = data[+tabSelected];

  return (
    <div>
      <Title>
        {renderTitle(selectedData)}
        {' '}
        Measurement
      </Title>
      <Tabs items={tabItems} activeKey={tabSelected} />
    </div>
  );
};
