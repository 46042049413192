import Lottie from 'lottie-react';
import classNames from 'classnames';
import data from './data.json';
import { LottieProps } from '../type';

export const SuccessLottie = ({
  width = 295,
  height = 295,
  className = '',
  ...rest
}: LottieProps) => (
  <div
    className={classNames({
      'success-lottie': true,
      [className]: !!className,
    })}
    style={{
      width,
      height,
    }}
  >
    <Lottie
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      animationData={data}
    />
  </div>
);
