import { Form } from 'antd';
import {
  every, includes, values
} from 'lodash';
import { FormType } from '../../../Input/types';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useCreateVisitForm } from '../../hook/useCreateVisitForm';
import { ParticipantsCheckboxGroupComponent } from '../ParticipantsCheckboxGroupComponent/ParticipantsCheckboxGroupComponent';
import { ParticipantsSelectFormItemComponent } from '../CreateVisitFormComponent/ParticipantsSelectComponent';
import CancelSubmitButtonsComponent from '../../../../uiComponent/CancelSubmitButtonsComponent/CancelSubmitButtonsComponent';
import CardComponent from '../../../../uiComponent/CardComponent/CardComponent';
import { ParticipantsEnum } from '../../type/participants';

export interface VisitParticipantsValue {
  participants?: ParticipantsEnum[];
  rdhc?: string;
  ca?: string;
  md?: string;
  ma?: string;
}

export interface VisitPariticipantReassignFormComponentProps
  extends FormType<VisitParticipantsValue> {
  userId?: string;
  addCurrentUserToOption?: boolean;
}

export const VisitPariticipantReassignFormComponent = ({
  userId,
  addCurrentUserToOption,
  initialValues,
  onSubmit,
  onCancel,
}: VisitPariticipantReassignFormComponentProps) => {
  const formHook = useCreateVisitForm();

  return (
    <Form
      layout="vertical"
      onFinish={onSubmit}
      initialValues={initialValues}
    >
      <CardComponent className="border">
        <FormItem
          info={formHook.getInfo('participants')}
          rules={formHook.getRequiredRules('participants')}
        >
          <ParticipantsCheckboxGroupComponent />
        </FormItem>
        <ParticipantsSelectFormItemComponent
          addCurrentUserToOption={addCurrentUserToOption}
        />
      </CardComponent>
      <FormItem
        noStyle
        shouldUpdate
      >
        {
          ({ getFieldsValue }) => {
            const fieldsValue = getFieldsValue();
            // disable when specified user doesn't exist in new selection
            // because only participant can start a visit
            const isDisabled = (
              !!userId
              && every(values(fieldsValue), (v) => !includes(v, userId))
            );
            return (
              <CancelSubmitButtonsComponent
                className="gap2"
                submitText="Check in"
                onCancel={onCancel}
                shouldBeDisabled={() => isDisabled}
              />
            );
          }
        }
      </FormItem>
    </Form>
  );
};
