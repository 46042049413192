import { useMemo } from 'react';
import { MessageVitalSingleComponent, MessageVitalSingleComponentProps } from '../MessageVitalSingleComponent/MessageVitalSingleComponent';
import { MeasurementResultTypeEnum, VitalEnumType } from '../../../../../uc-api-sdk';
import { BGMessagePayload, VitalMessagePayload } from '../../../../../services/CHSServices/types/data';
import { MessageVitalSingleDisplayValueComponent } from '../MessageVitalSingleDisplayComponent/MessageVitalSingleDisplayValueComponent';
import { MessageVitalSingleDisplaySeverityComponent } from '../MessageVitalSingleDisplayComponent/MessageVitalSingleDisplaySeverityComponent';

export interface MessageBGComponentProps extends Omit<MessageVitalSingleComponentProps, 'vitalName' | 'children'> {
  value: BGMessagePayload['value'];
  unit: VitalMessagePayload['unit'];
  severity: BGMessagePayload['severity'];
}

export const MessageBGComponent = ({
  measuredAt,
  text,
  value,
  unit,
  severity,
}: MessageBGComponentProps) => {
  const bgSeverityText = useMemo(() => {
    switch (severity) {
      case 'CriticallyLow':
        return 'Critical Low';
      case 'CriticallyHigh':
        return 'Critical High';
      default:
        return severity;
    }
  }, [severity]);
  return (
    <MessageVitalSingleComponent
      vitalName={MeasurementResultTypeEnum.BG}
      measuredAt={measuredAt}
      text={text}
    >
      <MessageVitalSingleDisplayValueComponent
        value={value}
        unit={unit}
      />
      <MessageVitalSingleDisplaySeverityComponent
        vitalType={VitalEnumType.BG}
        severity={bgSeverityText}
      />
    </MessageVitalSingleComponent>
  );
};
