import { Dayjs } from 'dayjs';
import { USA_DATE } from '../../../../constants/timeFormat';
import { PlotBand, PlotBandRange } from '../../type';
import { BgMealTypeEnum, AlertSeverityEnum } from '../../../../uc-api-sdk';
import TimezoneService from '../../../../helpers/timezone/timezoneService';

export interface BGChartMeasurement {
  date: Dayjs;
  beforeMeal: boolean;
  mealType: BgMealTypeEnum;
  bloodGlucose: number;
  severity: AlertSeverityEnum;
}

export interface CreateBGPlotBand {
  showBeforeMeal: boolean;
  showAfterMeal: boolean;
  beforeMealRange?: PlotBandRange;
  afterMealRange?: PlotBandRange;
}

const createBGPlotBand = ({
  showBeforeMeal,
  showAfterMeal,
  beforeMealRange,
  afterMealRange,
}: CreateBGPlotBand) => {
  const bands: PlotBand[] = [];
  if (showAfterMeal && afterMealRange) {
    bands.push({
      ...afterMealRange,
      color: '#f0fcfc',
    });
  }
  if (showBeforeMeal && beforeMealRange) {
    bands.push({
      ...beforeMealRange,
      color: '#fef9f3',
    });
  }
  return bands;
};

export interface CreateBGChartOptions<T> extends CreateBGPlotBand {
  series: T;
  fromDate: Dayjs;
  toDate: Dayjs;
  max: number;
}

export const createBGChartOptions = <T>({
  series,
  fromDate,
  toDate,
  max,
  showAfterMeal,
  showBeforeMeal,
  beforeMealRange,
  afterMealRange,
}: CreateBGChartOptions<T>) => ({
    chart: {
      type: 'line',
    },
    title: null,
    plotBackgroundColor: '#0099FF',
    xAxis: {
      type: 'datetime',
      tickWidth: 0,
      tickInterval: 24 * 3600 * 1000,
      min: fromDate.valueOf(),
      max: toDate.valueOf(),
      labels: {
        formatter: (xAxis: { value: Date; }) => (
          TimezoneService.calcDateTimeDayjs(xAxis.value).format(USA_DATE)
        ),
      },
    },
    yAxis: {
      title: {
        text: 'mg/dL',
      },
      min: 0,
      max,
      gridLineColor: 'transparent',
      plotBands: createBGPlotBand({
        showAfterMeal,
        showBeforeMeal,
        beforeMealRange,
        afterMealRange,
      }),
    },
    legend: {
      align: 'left',
      verticalAlign: 'top',
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      dateTimeLabelFormats: {
        day: '%m/%e/%Y',
      },
    },
    time: {
      useUTC: false,
      timezone: TimezoneService.guessTimezone(),
    },
    credits: {
      enabled: false,
    },
    series,
  });
