import dayjs from 'dayjs';
import {
  FC, useMemo, useState,
} from 'react';
import { PeriodValue } from '../../../../types/time';
import { Title } from '../../../../uiComponent/Title/Title';
import { PrintVitalsContainer } from '../../container/PrintVitalsContainer/PrintVitalsContainer';
import { PrintPeriods, PrintPeriodSelectComponent } from './PrintPeriodSelectComponent';

export interface PrintVitalFormComponentProps {
  memberId: string;
}

export const PrintVitalFormComponent: FC<PrintVitalFormComponentProps> = ({
  memberId,
}) => {
  const defaultPeriod = PrintPeriods.OneWeek.value;
  const [period, setPeriod] = useState<PeriodValue | undefined>(defaultPeriod);

  const dates = useMemo(() => {
    const p = period || defaultPeriod;
    return {
      fromDate: dayjs().add(-p.value, p.unit).add(1, 'day').startOf('day'),
      toDate: dayjs().endOf('day'),
    };
  }, [defaultPeriod, period]);

  return (
    <div>
      <Title noMargin type="secondary">Choose a time range</Title>
      <div className="mb20">
        <PrintPeriodSelectComponent value={period} onChange={setPeriod} />
      </div>
      <PrintVitalsContainer
        fromDate={dates.fromDate}
        toDate={dates.toDate}
        memberId={memberId}
      />
    </div>
  );
};
