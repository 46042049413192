import React from 'react';
import { delay } from 'lodash';
import { VisitViewComponent, VisitViewComponentProps } from '../../component/VisitViewComponent/VisitViewComponent';
import { ClinicEvent, useClinicEventDelete } from '../../../../uc-api-sdk';
import { CalendarEvent } from '../../component/BigCalendarComponent/BigCalendarComponent';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';

export interface VisitViewContainerProps extends
  Pick<VisitViewComponentProps, 'onClose' | 'onEdit' | 'onDelete'> {
  visit: CalendarEvent<ClinicEvent>;
}

export const VisitViewContainer = ({
  visit,
  onClose,
  onEdit,
  onDelete,
}: VisitViewContainerProps) => {
  const deleteReq = useClinicEventDelete({});
  const updateHook = useUpdate('VISIT_DELETED');

  const handleDelete = async () => {
    try {
      if (!visit.info?.id) {
        throw new Error('Visit id is missing!');
      }
      const res = await deleteReq.send({ params: { id: visit.info.id } });
      ApiRequestHelper.throwIfError(res, 'Failed to delete the visit!');
      // update outstanding item count
      updateHook.updateValue();
      delay(() => onDelete?.(), 500);
    } catch (err) {
      ApiRequestHelper.showError(err);
    }
  };

  if (visit?.info?.patientInfo) {
    return (
      <VisitViewComponent
        patientInfo={visit.info.patientInfo}
        visitInfo={visit.info}
        isSubmitting={deleteReq.isLoading}
        onClose={onClose}
        onEdit={onEdit}
        onDelete={handleDelete}
      />
    );
  }
  return null;
};
