/* eslint-disable react/jsx-props-no-spreading */
import { ReactNode } from 'react';
import { CheckboxGroupProps } from 'antd/es/checkbox';
import { Checkbox, Space, SpaceProps } from 'antd';
import classNames from 'classnames';
import { map } from 'lodash';
import { InputTypeArray } from '../../features/Input/types';
import './CheckboxGroupEnumComponent.scss';

export interface OptionRendererProps<T extends string> {
  value: T;
}

export interface CheckboxGroupEnumComponentProps<T extends string> extends
  Omit<CheckboxGroupProps, 'onChange' | 'value' | 'options'>, InputTypeArray<T> {
  options: T[] | Record<string, T>;
  optionRenderer: (props: OptionRendererProps<T>) => ReactNode;
  spaceProps?: SpaceProps;
}

export const CheckboxGroupEnumComponent = <T extends string>({
  value,
  onChange,
  options,
  optionRenderer,
  spaceProps,
  className = '',
  ...props
}: CheckboxGroupEnumComponentProps<T>) => {
  const handleChange: CheckboxGroupProps['onChange'] = (v) => {
    onChange?.(v as T[]);
  };

  return (
    <Checkbox.Group
      value={value || []}
      onChange={handleChange}
      className={classNames({
        'checkbox-enum-component': true,
        [className]: !!className,
      })}
      {...props}
    >
      <Space
        {...spaceProps}
        className={classNames({
          'checkbox-enum-component-space': true,
          [spaceProps?.className || '']: !!spaceProps?.className,
        })}
      >
        {map(options, (v: T) => (
          <Checkbox
            className="checkbox-enum-component-checkbox"
            key={v.toString()}
            value={v}
          >
            {optionRenderer({ value: v })}
          </Checkbox>
        ))}
      </Space>
    </Checkbox.Group>
  );
};
