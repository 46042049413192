import {
  useTicketAddComment,
  useTicketDeleteComment,
  useTicketEditComment,
  useTicketGetCommentListByTicketId,
} from '../../../uc-api-sdk';

export const useTicketGetCommentListByTicketIdRequest = (
  ticketId: string,
) => {
  const req = useTicketGetCommentListByTicketId({
    options: {
      sendOnMount: true,
    },
    params: {
      ticketId,
    },
  });

  return req;
};

export const useTicketEditCommentRequest = () => {
  const { send, ...rest } = useTicketEditComment({});
  const sendReq = (id: string, comment: string) => send({
    params: {
      id,
      request: {
        comment,
      },
    },
  });
  return { ...rest, send: sendReq };
};

export const useTicketDeleteCommentRequest = () => {
  const { send, ...rest } = useTicketDeleteComment({});
  const sendReq = (id: string) => send({ params: { id } });
  return { ...rest, send: sendReq };
};

export const useTicketAddCommentRequest = () => {
  const { send, ...rest } = useTicketAddComment({});
  const sendReq = (ticketId: string, comment: string) => send({
    params: {
      request: { comment, ticketId },
    },
  });
  return { ...rest, send: sendReq };
};
