import { ReactNode } from 'react';
import classNames from 'classnames';
import { minimizedClass, useMinimizableContext } from './Minimizable';

export interface MinimizableChildComponentProps {
  children: ReactNode;
  className?: string;
}

export const MinimizableChildComponent = ({
  children,
  className = ''
}: MinimizableChildComponentProps) => {
  const { isMinimized } = useMinimizableContext();
  return (
    <div
      className={classNames({
        'minimizable-children': true,
        [minimizedClass]: isMinimized,
        [className]: !!className,
      })}
    >
      {children}
    </div>
  );
};
