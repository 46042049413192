import { Select } from 'antd';
import { ReactNode, useState } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { SelectType } from '../../features/Input/types';
import { useDeepCompareMemo } from '../../hooks/useDeepCompareEffect';
import EmployeeInfo from '../../hooks/useUserInfo/employeeInfo';
import { checkLimitAndGetOptionData } from './helper';
import { AssigneeSelectOption, AssigneeSelectTypeValue, SelectionLimit } from './type';
import { Employee, RoleAssignment } from '../../types/user';

export interface AssigneeSelectComponentProps
  extends SelectType<AssigneeSelectTypeValue, AssigneeSelectOption> {
  assigneeDataList?: Employee[];
  getData?: (data: Employee[] | undefined) => void;
  labelRenderer?: (data: EmployeeInfo) => ReactNode;
  searchableLabelRenderer?: (data: EmployeeInfo) => string;
  selectionLimit?: SelectionLimit;
  showMessageOnLimit?: boolean;
}

export const AssigneeSelectComponent = ({
  id,
  value,
  onChange,
  onSearch,
  showSearch,
  mode,
  defaultActiveFirstOption = false,
  assigneeDataList,
  getData,
  labelRenderer = (d) => d.fullNameWithAllRoles,
  searchableLabelRenderer = (d) => d.fullNameWithAllRoles,
  selectionLimit, // only works with multiple mode
  showMessageOnLimit, // only works with multiple mode
  ...selectProps
}: AssigneeSelectComponentProps) => {
  const [selectedValue, setSelectedValue] = useState<AssigneeSelectTypeValue>(value);

  const handleInternalOnChange = (value: AssigneeSelectTypeValue) => {
    onChange?.(value);
    setSelectedValue(value);
  };

  const handleMultipleOnChange = (
    options: AssigneeSelectOption[],
  ) => {
    const newOptionData = checkLimitAndGetOptionData(
      options,
      selectionLimit,
      {
        showMessageOnLimit,
      },
    );
    handleInternalOnChange?.(newOptionData.map((opt) => opt?.id as string));
    getData?.(newOptionData);
  };

  const handleOnChange: AssigneeSelectComponentProps['onChange'] = (
    value,
    options,
  ) => {
    if (mode && ['tags', 'multiple'].includes(mode)) {
      handleMultipleOnChange(options as AssigneeSelectOption[]);
      return;
    }
    handleInternalOnChange?.(value);
    getData?.([(options as AssigneeSelectOption)?.data.employeeData]);
  };

  const options = useDeepCompareMemo(() => (
    assigneeDataList?.map((d) => {
      const employeeInfo = new EmployeeInfo({
        employee: d,
        roleAssignments: d.assignedRoles as RoleAssignment[],
      });
      return {
        label: labelRenderer(employeeInfo),
        searchableLabel: searchableLabelRenderer(employeeInfo),
        value: d.id,
        data: employeeInfo,
      };
    })
  ), [assigneeDataList]);

  useUpdateEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <Select
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...selectProps}
      id={id}
      value={selectedValue}
      onChange={handleOnChange}
      defaultActiveFirstOption={defaultActiveFirstOption}
      options={options}
      mode={mode}
      onSearch={onSearch}
      showSearch={showSearch || !!onSearch}
      optionFilterProp="searchableLabel"
      showArrow
    />
  );
};
