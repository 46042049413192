import { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { useMixpanelContext } from '../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelDurationEvents } from '../../contexts/MixpanelContext/MixpanelEvents';
import { DATE_TIME_CALENDAR } from '../../constants/timeFormat';

export const useRenderDuration = (
  key?: MixpanelDurationEvents,
  patientId?: string,
) => {
  const startTimeRef = useRef(dayjs().utc());
  const { sendDuration } = useMixpanelContext();

  useEffect(() => {
    if (!key) return;
    const startTime = startTimeRef.current;
    const stopTime = dayjs().utc();
    const duration = stopTime.valueOf() - startTime.valueOf();
    sendDuration({
      event: key,
      patientId,
      duration,
      startTime: startTime.format(DATE_TIME_CALENDAR),
      stopTime: stopTime.format(DATE_TIME_CALENDAR),
    });
  }, []);
};
