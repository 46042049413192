import { ReactNode } from 'react';

export interface TechWorklistGuideStepComponentProps {
  stepNumber: number;
  stepText: string;
  children?: ReactNode; // for extra action
}

export const TechWorklistGuideStepComponent = ({
  stepNumber,
  stepText,
  children,
}: TechWorklistGuideStepComponentProps) => (
  <div className="tech-worklist-guide-step">
    <div className="flex">
      <div className="tech-worklist-guide-step-number">
        Step
        {' '}
        {stepNumber}
        :
      </div>
      {stepText}
    </div>
    {
      children
      && (
        <div className="flex f-w mt5">
          <div className="tech-worklist-guide-step-number hidden" />
          <div className="flex f-w ai-c">
            {children}
          </div>
        </div>
      )
    }
  </div>
);
