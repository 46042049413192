/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { CacheValueContextProvider } from './CacheValueContext';

export interface RequestCacheValueInfo {
  id?: string;
  typeName: string;
  params?: any;
  value?: any;
}

export type IdCreator = (value: RequestCacheValueInfo) => string;

export interface CacheOptionContextProps {
  idCreator?: IdCreator;
  onChange?: (value: { [key: string]: any }) => void;
  children: ReactNode;
}

export interface CacheOptionContextValue {
  saveToCache: (v: RequestCacheValueInfo) => void;
}

export const CacheOptionContext = createContext<CacheOptionContextValue | undefined>(undefined);

export const useCacheOptionContext = () => {
  const contextValue = useContext(CacheOptionContext);
  return contextValue || {} as CacheOptionContextValue;
};

export const defaultIdCreator = ({ id, typeName, params }: RequestCacheValueInfo) => (
  `${typeName}(${JSON.stringify(params)})${(id ? (`:${id}`) : '')}`
);

export const CacheOptionContextProvider: FC<CacheOptionContextProps> = ({
  idCreator,
  onChange,
  children,
}) => {
  const [value, setValue] = useState<{ [key: string]: any }>({});

  const saveToCache = useCallback((v: RequestCacheValueInfo) => {
    const key = (idCreator || defaultIdCreator)(v);
    setValue((allValues) => {
      const newValues = {
        ...allValues,
        [key]: v.value,
      };
      onChange?.(newValues);
      return newValues;
    });
  }, [setValue, idCreator, onChange]);

  const cacheOptionContextValue: CacheOptionContextValue = useMemo(() => ({
    saveToCache,
  }), []);

  const getFromCache = useCallback((v: RequestCacheValueInfo) => {
    const key = (idCreator || defaultIdCreator)(v);
    return value[key];
  }, [value]);

  const cacheValueContextValue = useMemo(() => ({
    values: value,
    getFromCache,
  }), [value, getFromCache]);

  return (
    <CacheOptionContext.Provider value={cacheOptionContextValue}>
      <CacheValueContextProvider value={cacheValueContextValue}>
        {children}
      </CacheValueContextProvider>
    </CacheOptionContext.Provider>
  );
};
