/* eslint-disable no-shadow */
export const OPTIONS = [
  {
    label: 'Medications taken as prescribed',
    value: 'Medications taken as prescribed',
  },
  {
    label: 'Medications taken as prescribed, but sometimes forgets',
    value: 'Medications taken as prescribed, but sometimes forgets',
  },
  {
    label: 'Some medications patient often self titrates or stops independent of/in conflict with physician advice',
    value: 'Some medications patient often self titrates or stops independent of/in conflict with physician advice',
  },
  {
    label: 'Not taking medications because of non-compliance',
    value: 'Not taking medications because of non-compliance',
  },
  {
    label: 'Medication reconciliation not done...Patient will do at follow up with MD or with RD online',
    value: 'Medication reconciliation not done...Patient will do at follow up with MD or with RD online',
  },
  {
    label: 'Patient does not have any medications prescribed',
    value: 'Patient does not have any medications prescribed',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export enum OPTION_TYPES {
  OPTION = 'OPTION',
  OTHER = 'OTHER'
}
