import { Image, Typography } from 'antd';
import boTooltip from '../../../../assets/icons/bo-tooltip.svg';
import boTooltipRight from '../../../../assets/icons/bo-tooltip-color.svg';

const { Text } = Typography;

export const BOTooltipComponent = () => (
  <div>
    <Text className="ml12 mt12 b5">
      How to Read BO Color Code
    </Text>
    <div className="flex">
      <Image className="ml12 mt20" src={boTooltip} preview={false} width={155} />
      <Image className="mt20 mb10 mr20 ml40" src={boTooltipRight} preview={false} width={163} />
    </div>
  </div>
);
