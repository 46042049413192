import { Input, InputProps } from 'antd';
import { FC } from 'react';
import { InputType } from '../../features/Input/types';

export type MedicationDosageInputComponentProps = InputType<string>

export const MedicationDosageInputComponent: FC<MedicationDosageInputComponentProps> = ({
  value,
  onChange,
}) => {
  const handleChange: InputProps['onChange'] = (evt) => {
    const v = evt.target.value;
    onChange?.(v);
  };
  return (
    <Input
      data-testid="medDosageInput"
      value={value}
      onChange={handleChange}
      min={0}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    />
  );
};
