import { adminPortalUrl, carePortalUrl } from '../../hooks/useChangePage/useChangePage';
import { ROLE_ENUM } from '../../types/roles';
import { RoleTypeEnum } from '../../uc-api-sdk';

export type RoleToHomePage = {
  [key in keyof typeof ROLE_ENUM]?: string
};

export const roleToHomePage: RoleToHomePage = {
  [ROLE_ENUM.MANAGER]: adminPortalUrl,
  [ROLE_ENUM.SUPER_ADMIN]: adminPortalUrl,
  [ROLE_ENUM.ADMIN]: adminPortalUrl,
  [ROLE_ENUM.RD]: carePortalUrl,
  [ROLE_ENUM.CA]: carePortalUrl,
  [ROLE_ENUM.HC]: carePortalUrl,
  [ROLE_ENUM.PROVIDER]: carePortalUrl,
  [ROLE_ENUM.MA]: carePortalUrl,
};

export const internalUserRoles = [
  ROLE_ENUM.RD,
  ROLE_ENUM.CA,
  ROLE_ENUM.HC,
  ROLE_ENUM.BILLER,
  ROLE_ENUM.MANAGER,
];

export const externalUserRoles = [
  ROLE_ENUM.MA,
  ROLE_ENUM.PROVIDER,
  ROLE_ENUM.QUALITY_TEAM_MEMBER,
  ROLE_ENUM.CLINICAL_GROUP_BILLER,
];

export const adminPortalRoles = [
  ROLE_ENUM.SUPER_ADMIN,
  ROLE_ENUM.MANAGER,
  ROLE_ENUM.ADMIN,
];

export const carePortalRoles = [
  ROLE_ENUM.PROVIDER,
  ROLE_ENUM.MA,
  ROLE_ENUM.CALL_CENTER_REPRESENTATIVE,
  ROLE_ENUM.RD,
  ROLE_ENUM.HC,
  ROLE_ENUM.CA,
];

export const internalRoleTypes = [
  RoleTypeEnum.RD,
  RoleTypeEnum.HC,
  RoleTypeEnum.CA,
];

export const billerPortalRoles = [
  RoleTypeEnum.BILLER,
];

export const careRelatedRoles = [
  RoleTypeEnum.RD,
  RoleTypeEnum.HC,
  RoleTypeEnum.CA,
  RoleTypeEnum.PROVIDER,
  RoleTypeEnum.MA,
];

export const clinicBillingRoles = [
  RoleTypeEnum.CLINICAL_MANAGER,
  RoleTypeEnum.CLINICAL_GROUP_BILLER,
];
