import {
  ReactNode,
  useEffect,
  useState,
} from 'react';
import {
  Select,
  Input,
  Spin,
  InputProps,
  PaginationProps,
  SelectProps,
  Pagination,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import './PaginatedSelectComponent.scss';

const INPUT_PARENT_CLASS = 'search-dropdown__input';

export interface SelectOptionProps<V> {
  value: V
}
export interface PaginatedSelectComponentProps
  extends Omit<InputProps, 'value' | 'onChange'> {
  containerClassName?: string;
  selectedValues?: string[] | null;
  onSelectChange: (newSelectedKeys: string) => void;
  onDeselectChange?: (newSelectedKeys: string) => void;
  searchValue?: string;
  onSearchChange?: (newInput: string) => void;
  disabled?: boolean;
  isLoading?: boolean;
  pagination: PaginationProps;
  clearIcon?: SelectProps['clearIcon'];
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  children: ReactNode;
  notFoundContent?: string;
  keepDropdownOpenOnSelect?: boolean;
}
export const PaginatedSelectComponent = ({
  containerClassName = '',
  selectedValues = null,
  onSelectChange,
  onDeselectChange,
  searchValue,
  onSearchChange,
  disabled,
  isLoading,
  clearIcon,
  pagination,
  open = false,
  onOpenChange,
  children,
  notFoundContent,
  placeholder,
  keepDropdownOpenOnSelect = false,
}: PaginatedSelectComponentProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(open);
  const hasSelection = selectedValues && selectedValues.length > 0;

  const handleOpenDropdown = () => {
    setIsDropdownOpen(true);
    onOpenChange?.(true);
  };

  useEffect(() => {
    setIsDropdownOpen(open);
  }, [open]);

  const handleInputChange: InputProps['onChange'] = (e) => {
    const searchValue = e.target.value;
    onSearchChange?.(searchValue);
    handleOpenDropdown();
  };

  const inputHasFocus = () => {
    const parentElement = document.activeElement?.parentElement;
    return parentElement?.classList.contains(INPUT_PARENT_CLASS);
  };

  const handleOnDropdownVisibleChange = (
    open: boolean,
  ) => {
    const isInputFocused = inputHasFocus();
    if (keepDropdownOpenOnSelect && isInputFocused) {
      // prevent close dropdown when input regain focus
      return;
    }
    setIsDropdownOpen(open);
  };

  return (
    <div
      className={classnames({
        'search-dropdown': true,
        [containerClassName]: !!containerClassName,
      })}
    >
      <Input
        className={INPUT_PARENT_CLASS}
        disabled={disabled}
        prefix={(
          <SearchOutlined />
        )}
        placeholder={placeholder}
        autoFocus={false}
        value={searchValue}
        onChange={handleInputChange}
        onPressEnter={handleOpenDropdown}
        onClick={handleOpenDropdown}
        suffix={(
          isLoading
            ? <Spin />
            : null
        )}
        onBlur={() => setIsDropdownOpen(false)}
      />
      <div
        className={classnames({
          'search-dropdown__select': true,
          'search-dropdown__hide': !hasSelection,
        })}
      >
        <Select
          mode="multiple"
          allowClear
          clearIcon={clearIcon}
          disabled={disabled}
          autoFocus
          defaultActiveFirstOption={false}
          showSearch={false}
          value={selectedValues}
          optionLabelProp="label"
          onSelect={onSelectChange}
          onDeselect={onDeselectChange}
          notFoundContent={notFoundContent}
          popupClassName="search-dropdown__popup"
          open={isDropdownOpen}
          onFocus={handleOpenDropdown}
          onDropdownVisibleChange={handleOnDropdownVisibleChange}
        >
          <>
            {children}
            <Select.Option
              key="paginationSelectOption"
              value="pagination"
              className={classnames({
                'pagination-select-option': true,
              })}
              disabled
            >
              <Pagination
                size="default"
                showSizeChanger={false}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...pagination}
              />
            </Select.Option>
          </>
        </Select>
      </div>
    </div>
  );
};
