import Title from 'antd/lib/typography/Title';
import { CancelManualSubmitButtonComponent } from '../../../../uiComponent/CancelManualSubmitButtonComponent/CancelManualSubmitButtonComponent';

export interface DeleteEnrolledPatientRedirectComponentProps {
  fullName: string;
  onCancel?: () => void;
}

export const DeleteEnrolledPatientRedirectComponent = ({
  fullName,
  onCancel,
}: DeleteEnrolledPatientRedirectComponentProps) => {
  const handleCancel = () => {
    onCancel?.();
  };

  const handleSubmit = () => {
    onCancel?.();
    setTimeout(() => {
      (document.querySelector('.discharge-patient-button') as HTMLButtonElement)?.click();
    }, 500);
  };

  return (
    <div>
      <Title level={5}>
        {
          `Delete [${fullName}]`
        }
      </Title>
      <p>
        This is an enrolled patient. To proceed, please discharge this patient
        from the enrolled program first, then try delete again.
      </p>
      <CancelManualSubmitButtonComponent
        submitText="Proceed to Discharge"
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        className="jc-e mb0"
      />
    </div>
  );
};
