import { Select } from 'antd';
import React, { FC, useState } from 'react';
import { TicketDueDateSelectComponent } from '../../../../selectComponents/TicketComponent/TicketDueDateSelectComponent/TicketDueDateSelectComponent';
import { TicketPrioritySelectComponent } from '../../../../selectComponents/TicketComponent/TicketPrioritySelectComponent/TicketPrioritySelectComponent';
import { TicketDescriptionTextAreaComponent } from '../../../../selectComponents/TicketComponent/TicketDescriptionTextAreaComponent/TicketDescriptionTextAreaComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useCreateEditTicketForm } from '../../hook/useCreateEditTicketForm';
import { CoWorkerDropdownComponent } from '../../../../uiComponent/CoWorkerDropdownComponent/CoWorkerDropdownComponent';
import { PatientSearchSelectContainer } from '../../../patient/container/PatientSearchSelectContainer/PatientSearchSelectContainer';
import '../../style/style.scss';
import { PatientSearchSelectItemComponent, PatientSearchSelectItemComponentProps } from '../PatientSearchSelectItemComponent/PatientSearchSelectItemComponent';
import { RoleTypeEnum } from '../../../../uc-api-sdk';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { PatientProfileContainer } from '../../../patient/container/PatientProfileContainer/PatientProfileContainer';

export interface TicketFormItemComponentProps {
  title: string;
  patientInputDisable: boolean;
  clinicId?: string;
}

export const TicketFormItemComponent: FC<TicketFormItemComponentProps> = ({
  title,
  patientInputDisable = false,
  clinicId,
}) => {
  const [selectedClinicId, setSelectedClinicId] = useState(clinicId);
  const { isProvider } = useLoggedInUserFromContext();
  const hook = useCreateEditTicketForm();
  const optionRenderer = (d: PatientSearchSelectItemComponentProps['data']) => (
    <Select.Option key={d.id} label={d.fullName}>
      <PatientSearchSelectItemComponent data={d} />
    </Select.Option>
  );

  return (
    <div>
      <div className="ticketTitle mb20">{title}</div>
      <FormItem
        info={hook.getInfo('priorityLevel')}
        className="required-field"
        required
      >
        <TicketPrioritySelectComponent />
      </FormItem>
      <FormItem
        info={hook.getInfo('memberId')}
        className="required-field"
        required
        valuePropName="value"
      >
        <PatientSearchSelectContainer
          popupClassName="createEditTicketForm__patient-search-select"
          placement="bottomRight"
          allowClear
          optionRenderer={optionRenderer}
          getByPatientIdOnSelect={(e) => { setSelectedClinicId(e?.clinicId || ''); }}
          disabled={patientInputDisable}
        />
      </FormItem>
      <FormItem
        dependencies={[hook.getName('memberId')]}
      >
        {({ getFieldValue }) => (
          <PatientProfileContainer patientId={getFieldValue('memberId')}>
            {(info, loading) => {
              const assignedMembersIds: string[] = [];
              if (info?.patientInfo?.profile?.doctorUser?.id) {
                assignedMembersIds.push(info.patientInfo.profile.doctorUser.id);
              }
              if (info?.patientInfo?.assignedToRDUser?.id) {
                assignedMembersIds.push(info.patientInfo.assignedToRDUser.id);
              }
              if (info?.patientInfo?.assignedToCAUser?.id) {
                assignedMembersIds.push(info.patientInfo.assignedToCAUser.id);
              }
              return (
                <FormItem
                  info={hook.getInfo('assignedTo')}
                  className="required-field"
                  required
                >
                  <CoWorkerDropdownComponent
                    loading={loading}
                    placeholder="Select a patient first to choose the assignee"
                    disabled={!getFieldValue('memberId')}
                    filterRoles={[RoleTypeEnum.MA,
                      RoleTypeEnum.PROVIDER,
                      RoleTypeEnum.RD,
                      RoleTypeEnum.HC,
                      RoleTypeEnum.CA]}
                    clinicIdList={selectedClinicId ? [selectedClinicId] : undefined}
                    externalEmployeeForPatientId={getFieldValue('memberId')}
                    internalEmployeeForPatientId={isProvider ? getFieldValue('memberId') : undefined}
                    needToFilter
                    assignedMembers={assignedMembersIds}
                  />
                </FormItem>
              );
            }}
          </PatientProfileContainer>
        )}
      </FormItem>
      <FormItem
        info={hook.getInfo('dueDate')}
        className="required-field"
        required
      >
        <TicketDueDateSelectComponent />
      </FormItem>
      <FormItem
        info={hook.getInfo('description')}
        className="required-field"
        required
      >
        <TicketDescriptionTextAreaComponent />
      </FormItem>
    </div>
  );
};
