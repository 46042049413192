import { Card, Radio } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import './SelectHCsPatientsCheckBoxComponent.scss';
import { PriorityCoverEnum } from '../../../../uc-api-sdk';

export interface SelectHCsPatientsCheckBoxComponentProps {
  onCardSelect: (v: PriorityCoverEnum) => void;
  selected?: boolean;
  cardValue: PriorityCoverEnum;
}

export const SelectHCsPatientsCheckBoxComponent = ({
  onCardSelect,
  selected = false,
  cardValue,
}: SelectHCsPatientsCheckBoxComponentProps) => {
  // this should be modified with SelectPatientsBaseCheckboxComponent
  const renderContent = (name: string) => (
    name === 'Patients with High Priority Items'
      ? (
        <div className="flex gap3">
          <Radio
            value={cardValue}
            checked={false}
            onChange={() => onCardSelect(cardValue)}
          />
          <div className="pr24">
            <div className="patient-title">
              {name}
            </div>
            <div className="high-priority-patients-checked-items">
              <div className="flex mb5">
                <CheckCircleFilled className="check-mark" />
                <div>
                  Messages
                </div>
              </div>
              <div className="flex mb5">
                <CheckCircleFilled className="check-mark" />
                <div>
                  High Priority Alerts
                </div>
              </div>
              <div className="flex mb5">
                <CheckCircleFilled className="check-mark" />
                <div>
                  MTPR
                </div>
              </div>
            </div>
            <div className="border-t card-footer">
              <div className="card-footer-text">
                Select if On-Call or Covering RDs/HCs Out of Office 5 Days or Less
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex gap3">
          <Radio
            value={cardValue}
            checked={false}
            onChange={() => onCardSelect(cardValue)}
          />
          <div className="pr24">
            <div className="patient-title">
              {name}
            </div>
            <div className="all-patients-checked-items">
              <div className="flex mb5">
                <CheckCircleFilled className="check-mark" />
                <div>
                  Messages
                </div>
              </div>
              <div className="flex mb5">
                <CheckCircleFilled className="check-mark" />
                <div>
                  High Priority Alerts
                </div>
              </div>
              <div className="flex mb5">
                <CheckCircleFilled className="check-mark" />
                <div>
                  Food Diary
                  {' '}
                  <i className="coming-soon">(Coming soon)</i>
                </div>
              </div>
              <div className="flex mb5">
                <CheckCircleFilled className="check-mark" />
                <div>
                  Medium Priority Alerts
                </div>
              </div>
              <div className="flex mb5">
                <CheckCircleFilled className="check-mark" />
                <div>
                  MTPR
                </div>
              </div>
              <div className="flex mb5">
                <CheckCircleFilled className="check-mark" />
                <div>
                  Low Priority Alerts
                </div>
              </div>
            </div>
            <div className="border-t card-footer">
              <div className="card-footer-text">
                Select if Covering RDs/HCs Out of Office for 5+ Days
              </div>
            </div>
          </div>
        </div>
      )
  );

  const renderPatientsWithHighPriorityItems = () => (
    <Card
      className="patients-radio-card"
      title={null}
      style={{ border: selected ? '2px solid #1890ff' : '1px solid #d9d9d9' }}
      onClick={() => onCardSelect(cardValue)}
      hoverable
    >
      {renderContent('Patients with High Priority Items')}
    </Card>
  );

  const renderAllPatients = () => (
    <Card
      className="patients-radio-card"
      title={null}
      style={{ border: selected ? '2px solid #1890ff' : '1px solid #d9d9d9' }}
      onClick={() => onCardSelect(cardValue)}
      hoverable
    >
      {renderContent('All Patients')}
    </Card>
  );

  return (
    cardValue === PriorityCoverEnum.HIGH
      ? renderPatientsWithHighPriorityItems()
      : renderAllPatients()
  );
};
