import { Table } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';
import { HSChartData } from '../HSChartComponent/HSChartComponent';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { HSTablePrintColumns } from './HSTablePrintColumns';

export interface HSTablePrintComponentProps {
  data: HSChartData[];
}

export const HSTablePrintComponent: FC<HSTablePrintComponentProps> = ({
  data,
}) => {
  const columns = useTableColumns(HSTablePrintColumns, ['date', 'measurement']);

  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={false}
      className={classNames({
        'table-header-cell-center': true,
        'bg-logbook-table': true,
        'table-cell-no-padding': true,
      })}
      bordered
      rowKey={(v) => v.id}
    />
  );
};
