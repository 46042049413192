import { TableProps } from 'antd';
import { find, isArray } from 'lodash';
import { useMemo } from 'react';
import { SorterResult } from '../../types/table';
import { Filter } from '../useTableChange/useTableChange';

export type Sorter<T> = Pick<SorterResult<T>, 'columnKey' | 'order'>;
export type Sorters<T> = Sorter<T> | Sorter<T>[];

export interface UseTableColumnWithFiltersAndSortersArg<T> {
  columns: TableProps<T>['columns'];
  filters?: Filter<string>;
  sorters?: Sorters<T>;
  except?: string[];
}

export const useTableColumnWithFiltersAndSorters = <T, >({
  columns,
  filters,
  sorters,
  except = [],
}: UseTableColumnWithFiltersAndSortersArg<T>) => {
  const getSingleSorterOrder = (sorter: Sorter<T>, columnKey: string) => (
    sorter.columnKey === columnKey ? sorter?.order : null
  );

  const getOrder = (sorters: Sorters<T>, columnKey?: string) => {
    if (sorters === undefined || columnKey === undefined) return null;
    return isArray(sorters)
      ? find(sorters as Sorter<T>[], (s) => getSingleSorterOrder(s, columnKey))
      : getSingleSorterOrder(sorters, columnKey);
  };

  const res = useMemo(() => columns?.map((column) => {
    const filteredValue = (column?.key && filters?.[column.key.toString()]) ?? null;
    const sortOrder = getOrder(sorters || [], column?.key?.toString());
    if (except.includes(column?.key?.toString() || '')) {
      return {
        ...column, filters: null, filterDropdown: '', filterIcon: '', sortOrder: null
      };
    }
    return {
      filteredValue,
      sortOrder,
      ...column,
    };
  }), [
    columns,
    filters,
    sorters,
    except
  ]);

  return res as TableProps<T>['columns'];
};
