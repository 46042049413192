import { Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { TimeService } from '../../../../helpers/time/timeService';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import { TimeUpdaterComponent } from '../../../../uiComponent/TimeUpdaterComponent/TimeUpdaterComponent';

export interface StartEndDurationTimeComponentProps {
  startTime?: Dayjs | string;
  endTime?: Dayjs | string;
}

export const StartEndDurationTimeComponent = ({
  startTime,
  endTime,
}: StartEndDurationTimeComponentProps) => {
  const duration = startTime && TimeService.getDifferenceInMinutesAndSeconds(
    TimezoneService.calcDateTimeDayjs(startTime),
    endTime ? TimezoneService.calcDateTimeDayjs(endTime) : dayjs(),
  );

  return (
    <Space size="large">
      <div>
        Start time:
        {' '}
        <DisplayDateComponent value={startTime} format="TIME" useTimezoneService />
      </div>
      {endTime && (
        <div>
          End time:
          {' '}
          <DisplayDateComponent value={endTime} format="TIME" useTimezoneService />
        </div>
      )}
      <div>
        Duration:
        {' '}
        {!endTime
          ? (
            <span className="text-link">
              <TimeUpdaterComponent startTime={startTime} updateRate={1000} />
            </span>
          ) : (
            <span>{duration}</span>
          )}
      </div>
    </Space>
  );
};
