import React, { FC } from 'react';
import { MeasurementResultTypeEnum } from '../../../../uc-api-sdk';
import { VitalChartTableSummaryViewComponentProps } from '../VitalChartTableSummaryViewComponent/VitalChartTableSummaryViewComponent';
import { VitalFetchProps } from '../../type';
import { VitalChartViewComponent } from '../VitalChartViewComponent/VitalChartViewComponent';
import { TMChartDataProcessorComponent } from '../TMChartComponent/TMChartDataProcessorComponent';

export interface BPVitalComponentProps extends VitalFetchProps {

}

export const TMVitalComponent: FC<BPVitalComponentProps> = ({
  memberId,
  fromDate,
  toDate,
}) => {
  const chartView: VitalChartTableSummaryViewComponentProps['chartView'] = ({ data, fromDate, toDate }) => (
    <TMChartDataProcessorComponent
      data={data?.results?.content || []}
      fromDate={fromDate}
      toDate={toDate}
    />
  );

  return (
    <VitalChartViewComponent
      type={MeasurementResultTypeEnum.TM}
      memberId={memberId}
      title="Temperature"
      chartView={chartView}
      needStats={false}
      fromDate={fromDate}
      toDate={toDate}
    />
  );
};
