import {
  Button, DatePicker, Form, InputNumber, Space
} from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { isNil } from 'lodash';
import { MMM_DD_YYYY } from '../../../constants/timeFormat';
import { FormType } from '../../../features/Input/types';
import { createRequiredRule } from '../../../helpers/form';
import { TimeService } from '../../../helpers/time/timeService';
import { OfflineTimeService } from '../../../services/OfflineTimeService';
import { FixedComponent } from '../../FixedComponent/FixedComponent';
import FormItem from '../../FormItem/FormItem';
import { AddOfflineActivityFormItemComponent } from '../AddOfflineActivityFormItemComponent/AddOfflineActivityFormItemComponent';
import { ActivityTypeEnum } from '../type';

export interface SubmitValue {
  offlineActivities: ActivityTypeEnum[];
  otherActivity?: string;
  dateOfService: string;
  timeSpent: number;
  startTime?: string;
  endTime?: string;
  note?: string;
  nonBillable?: boolean;
}
export interface AddOfflineTimeFormComponentProps extends FormType<SubmitValue>{
  form?: FormInstance<SubmitValue>;
}

export const AddOfflineTimeFormComponent = ({
  onSubmit,
  form,
  isLoading,
  onCancel,
}: AddOfflineTimeFormComponentProps) => {
  const handleSubmit = (s: SubmitValue) => {
    if (s.offlineActivities?.includes(ActivityTypeEnum.OTHER)) {
      onSubmit?.(s);
    } else {
      onSubmit?.({
        ...s,
        otherActivity: undefined,
      });
    }
  };

  const onValuesChange = (changedValues: SubmitValue, allValues: SubmitValue) => {
    // if startTime is bigger than endTime, set endTime to undefined
    if (changedValues.startTime
      && allValues.endTime
      && (TimeService.getMinutesFromTimeLabel(changedValues.startTime)
        > TimeService.getMinutesFromTimeLabel(allValues.endTime))) {
      form?.setFieldsValue({
        endTime: undefined,
      });
    }
    if ((changedValues.startTime
      || changedValues.endTime
      || changedValues.offlineActivities)
      && allValues.timeSpent) {
      form?.validateFields(['timeSpent']);
    }
  };

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit}
      className="h100"
      form={form}
      onValuesChange={onValuesChange}
      disabled={isLoading}
    >
      <FixedComponent>
        <FixedComponent.Child>
          <Space direction="vertical" size={20}>
            <div>
              Choose the activities that you spent outside of this portal
              (excluding face-to-face interactions) and input the total time that you spent.
            </div>
            <div className="w100">
              <AddOfflineActivityFormItemComponent />
            </div>
            <FormItem
              label="Date of Service"
              name="dateOfService"
              required
            >
              <DatePicker format={MMM_DD_YYYY} />
            </FormItem>
            <FormItem
              shouldUpdate={OfflineTimeService.isCallChanged}
            >
              {({ getFieldValue }) => {
                const startTime = getFieldValue('startTime');
                const endTime = getFieldValue('endTime');
                const hasCall = getFieldValue('offlineActivities')?.includes(ActivityTypeEnum.CALL);
                const nonBillable = getFieldValue('nonBillable');
                const duration = TimeService.getMinutesFromTimeLabel(endTime)
                  - TimeService.getMinutesFromTimeLabel(startTime);
                return (
                  <FormItem
                    label="Total offline time to add"
                    name="timeSpent"
                    rules={[
                      createRequiredRule(),
                      {
                        validator: (_, v) => {
                          if (isNil(v)) {
                            return Promise.resolve();
                          }
                          if (v < duration && hasCall && !nonBillable) {
                            return Promise.reject(new Error('Total offline time input should not be shorter than the call duration.'));
                          }
                          if (v > 60) {
                            return Promise.reject(new Error('Offline time input limited to 60 minutes'));
                          }
                          if (v < 1) {
                            return Promise.reject(new Error('Invalid input'));
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      precision={0}
                      min={1}
                      addonAfter="Mins"
                    />
                  </FormItem>
                );
              }}
            </FormItem>
          </Space>
        </FixedComponent.Child>
        <FixedComponent.Child isFixed>
          <FormItem className="ant-drawer-footer">
            <Space size={20}>
              <Button onClick={onCancel}>Cancel</Button>
              <Button htmlType="submit" type="primary">
                Submit
              </Button>
            </Space>
          </FormItem>
        </FixedComponent.Child>
      </FixedComponent>
    </Form>
  );
};
