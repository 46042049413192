import { useEffect, useState } from 'react';
import { MixpanelEvents, MixpanelEventsParents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useMpTrackingHelper } from '../../../../hooks/useMpTrackingHelper/useMpTrackingHelper';
import {
  MonthlyReviewStatusEnum,
  ProviderNoteCategoryEnum,
  usePatientGetClinicalGoalById, usePatientUpdateMonthlyReview
} from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { MTPRFormComponent, SubmitValue } from '../../component/MTPRFormComponent/MTPRFormComponent';

export interface MTPRFormContainerProps extends NestedFormComponentChildProps<SubmitValue> {
  patientId: string;
  finalStatus?: MonthlyReviewStatusEnum;
  initialValues?: Partial<SubmitValue>;
  showSuccessMessage?: boolean;
  monthlyReviewId?: string;
  type?: ProviderNoteCategoryEnum;
  isMtprDisabled?: boolean;
  setIsMTPRDisabled?: (isDisabled: boolean) => void;
}

export const MTPRFormContainer = ({
  patientId,
  finalStatus = MonthlyReviewStatusEnum.DONE,
  initialValues: _initialValues,
  onSubmit,
  onError,
  onCancel,
  onValuesChange,
  formButtons,
  disabled,
  showSuccessMessage = true,
  monthlyReviewId,
  type,
  isMtprDisabled,
  setIsMTPRDisabled,
}: MTPRFormContainerProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const monthlyReviewUpdateInfo = usePatientUpdateMonthlyReview({});
  const fetchClinicalGoalHook = usePatientGetClinicalGoalById({
    params: { memberId: patientId },
  });

  const { send } = useBillableTimeInterventionComp();

  const { startEvent, endEvent } = useMpTrackingHelper(
    {
      eventStart: MixpanelEvents.MTPRStart,
      eventEnd: MixpanelEvents.MTPREnd,
      parent: MixpanelEventsParents.Outstanding,
      patientId,
      properties: {
        mtprId: monthlyReviewId,
      },
    }
  );

  useEffect(startEvent, []);

  const handleSubmit = async (values?: SubmitValue) => {
    if (isMtprDisabled) {
      onSubmit?.();
    }
    send({
      patientId,
      component: BillableTimeInterventionComponentEnum.ConductMTPR,
      detail: BillableTimeInterventionDetailEnum.OutstandingMtprSubmit,
    });
    setIsSubmitting(true);
    ApiRequestHelper.tryCatch(
      monthlyReviewUpdateInfo.send({
        params: {
          memberId: patientId,
          update: {
            status: finalStatus,
            note: values?.note,
            goals: values?.clinicalGoals,
            id: monthlyReviewId,
            type,
          },
        },
      }),
      {
        success: showSuccessMessage ? 'Updated monthly review successfully!' : '',
        error: 'Failed to update monthly review',
        onSuccess: (data) => {
          setIsSubmitting(false);
          onSubmit?.();
          endEvent({
            properties: {
              mtprId: data?.data?.id || monthlyReviewId,
            }
          });
          endEvent();
        },
        onError: (error) => {
          setIsSubmitting(false);
          onError?.(error);
        },
      }
    );
  };

  return (
    <FetchComponent info={fetchClinicalGoalHook}>
      {
        (value, info) => {
          const initialLoading = value?.data === undefined;
          const clinicalGoals = value?.data?.rawValue?.clinicalGoals || [];
          const isLoading = !!info.isLoading;
          return (
            <LoadingOverlayComponent
              isLoading={initialLoading}
              showSkeleton={false}
            >
              <MTPRFormComponent
                patientId={patientId}
                isLoading={isLoading}
                initialValues={{
                  ..._initialValues,
                  clinicalGoals,
                }}
                onSubmit={handleSubmit}
                onCancel={onCancel}
                onValuesChange={onValuesChange}
                formButtons={formButtons}
                disabled={disabled || isSubmitting || isMtprDisabled}
                setIsMTPRDisabled={setIsMTPRDisabled}
              />
            </LoadingOverlayComponent>
          );
        }
      }
    </FetchComponent>
  );
};
