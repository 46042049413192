import { useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { TicketCommentMessages } from '../constant/text';

// TODO-TYPE-CHANGE
export const useCreateEditTicketForm = () => {
  const factory = useFormHookFactory({
    title: {
      name: 'title',
      label: '',
      required: true,
    },
    priorityLevel: {
      name: 'priorityLevel',
      label: 'Priority',
      required: true,
    },
    type: {
      name: 'type',
      label: 'Task Type',
      required: true,
    },
    memberId: {
      name: 'memberId',
      label: 'Patient',
      required: true,
    },
    dueDate: {
      name: 'dueDate',
      label: 'Due Date',
      required: true,
    },
    assignedTo: {
      name: 'assignedTo',
      label: 'Assignee',
      required: true,
    },
    description: {
      name: 'description',
      label: 'Description',
      required: true,
    },
    comment: {
      name: 'comment',
      label: '',
      required: true,
      emptyMessage: TicketCommentMessages.Edit,
    },
    commentOnly: {
      name: 'commentOnly',
      label: '',
    },
  });

  return factory;
};
