import { Divider } from 'antd';
import { ReactNode } from 'react';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
// import { ProgressComponent } from '../ProgressComponent/ProgressComponent';
import { Title } from '../../../../uiComponent/Title/Title';
import { Icons } from '../../../../icons/Icons';
import { EnrollmentStepsWithContext } from '../EnrollmentStepsComponent/EnrollmentStepsWContextComponent';

export interface EnrollmentWrapperComponentProps {
  children: ReactNode;
  onClose?: () => void;
}

export const EnrollmentWrapperComponent = ({
  children,
  onClose,
}: EnrollmentWrapperComponentProps) => (
  <FixedComponent>
    <FixedComponent.Child isFixed>
      <div className="flex jc-sb">
        <Title>Enrollment</Title>
        <Icons.CloseIcon onClick={onClose} />
      </div>
    </FixedComponent.Child>

    <FixedComponent.Child isFixed>
      <div className="flex ai-c jc-sb">
        <EnrollmentStepsWithContext />
        {/* <div>
          <ProgressComponent />
        </div> */}
      </div>
      <Divider className="mt0" />
    </FixedComponent.Child>

    <FixedComponent.Child>
      {children}
    </FixedComponent.Child>
  </FixedComponent>
);
