import React from 'react';
import { Drawer, DrawerProps } from 'antd';
import classNames from 'classnames';

export interface PullUpDrawerComponentProps extends
  Omit<DrawerProps, 'placement' | 'getContainer'> {
}

export const PullUpDrawerComponent = ({
  className = '',
  ...props
}: PullUpDrawerComponentProps) => (
  <Drawer
    className={classNames({
      'pull-up-drawer-component': true,
      [className]: !!className,
    })}
    placement="bottom"
    getContainer={false}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);
