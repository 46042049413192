import { ReactNode } from 'react';
import { Typography } from 'antd';
import { AssignDeviceInfo } from '../../../../uc-api-sdk';
import { Icon } from '../../../../icons/Icon';
import { MAGIC_STICK } from '../../../../assets/commonIconInfo';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { DeviceImageComponent } from '../../DeviceImageComponent';
import { DeviceLabelComponent } from '../DeviceLabelComponent/DeviceLabelComponent';
import { BloodPressureDeviceLanguageComponent } from '../BloodPressureDeviceLanguageComponent/BloodPressureDeviceLanguageComponent';

const { Text } = Typography;

export interface DeviceItemComponentProps {
  device?: AssignDeviceInfo;
  extra?: ReactNode;
}

export const DeviceItemComponent = ({
  device,
  extra,
}: DeviceItemComponentProps) => (
  <div className="flex gap5 ai-c">
    <div className="device-item">
      <DeviceImageComponent deviceModel={device?.deviceModel ?? undefined} width={82} />
    </div>
    <div className="flex fd-c">
      <div className="b6">
        <DeviceLabelComponent device={device || undefined} showImage={false} />
        <Icon info={MAGIC_STICK} className="pl5" />
      </div>
      <Text>
        Device ID:
        {' '}
        <Text type="secondary">
          <DisplayOrEmptyComponent value={device?.deviceId} />
        </Text>
      </Text>
      {device?.deviceLanguage
        && (
          <Text>
            Device Language:
            {' '}
            <Text type="secondary">
              <BloodPressureDeviceLanguageComponent value={device.deviceLanguage} />
            </Text>
          </Text>
        )}
      {extra}
    </div>
  </div>
);
