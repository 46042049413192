/* eslint-disable react/jsx-props-no-spreading */
import { CloseOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/lib/table';
import { Button, Checkbox, Form } from 'antd';
import { NamePath } from 'rc-field-form/lib/interface';
import { ICDProgramCheckboxFormItemComponent } from '../../features/ICDCode/component/ICDProgramCheckboxFormItem/ICDProgramCheckboxFormItem';
import { FormHealthCondition } from '../../features/ICDCode/component/ICDTableComponent/ICDTableComponent';

export const makeRemoveICDColumn = (
  removeFunction: (index: number) => void,
  disabled?: boolean,
): ColumnType<FormHealthCondition> => ({
  key: 'removeAction',
  dataIndex: 'code',
  width: '6%',
  render: (_, __, index: number) => (
    <Button
      type="text"
      onClick={() => removeFunction(index)}
      disabled={disabled}
    >
      <CloseOutlined />
    </Button>
  ),
});

const makeFormItemName = (
  record: FormHealthCondition,
  itemName: string,
) => [record.formField?.name, itemName] as NamePath;

export const icdTableColumns = (
  disabled?: boolean,
) => ({
  code: () => ({
    key: 'code',
    dataIndex: 'code',
    width: '12%',
    title: 'Code',
    render: (text: string, record: FormHealthCondition) => (
      !record.formField
        ? <div>{text}</div>
        : (
          <Form.Item
            {...record.formField}
            name={makeFormItemName(record, 'code')}
          >
            <div>{text}</div>
          </Form.Item>
        )
    ),
  }),
  condition: () => ({
    key: 'condition',
    dataIndex: 'condition',
    title: 'Condition',
    render: (text: string, record: FormHealthCondition) => (
      !record.formField
        ? <div>{text}</div>
        : (
          <Form.Item
            {...record.formField}
            name={makeFormItemName(record, 'condition')}
          >
            <div>{text}</div>
          </Form.Item>
        )
    ),
  }),
  isBillable: () => ({
    key: 'isBillable',
    dataIndex: 'isBillable',
    title: 'Billable',
    width: '10%',
    render: (
      isBillable: boolean | null,
      record: FormHealthCondition,
    ) => (
      (!record.formField)
        ? <Checkbox checked={!!isBillable} disabled={disabled} />
        : (
          <ICDProgramCheckboxFormItemComponent
            {...record.formField}
            name={makeFormItemName(record, 'isBillable')}
            disabled={disabled}
          />
        )
    ),
  }),
});
