import { Dayjs } from 'dayjs';
import { ReactNode } from 'react';
import './CalendarEventBaseComponent.scss';
import classNames from 'classnames';
import { Space } from 'antd';
import { CalendarEventTimeComponent } from '../CalendarEventTimeComponent/CalendarEventTimeComponent';

export interface CalendarEventBaseComponentProps {
  startTime: Dayjs;
  endTime: Dayjs;
  title: ReactNode;
  isAllDay?: boolean;
  icon?: ReactNode;
  className?: string;
}

export const CalendarEventBaseComponent = ({
  startTime,
  endTime,
  title,
  isAllDay = false,
  icon,
  className = '',
}: CalendarEventBaseComponentProps) => {
  const diff = endTime.diff(startTime, 'minutes');
  const isLessThan30 = diff <= 30;
  const isMoreThan15 = diff > 15;

  return (
    <div
      className={classNames({
        'calendar-event-base-component': true,
        [className]: !!className,
        flex: isLessThan30,
        mt5: isMoreThan15,
      })}
    >
      <Space className="calendar-event-title">
        {icon}
        <div>{title}</div>
      </Space>
      {!isAllDay && (
        <CalendarEventTimeComponent
          startTime={startTime}
          endTime={endTime}
          showDay={false}
        />
      )}
    </div>
  );
};
