import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useEmployeeRemoveFromWatch } from '../../../../uc-api-sdk';
import { WatchedIndicatorComponent } from '../../component/WatchedIndicatorComponent/WatchedIndicatorComponent';

export const WatchedIndicatorContainer = () => {
  const { info } = usePatientContext();
  const { id, patientInfoService } = info || {};
  const { watchInfo } = patientInfoService || {};
  const { isWatched } = watchInfo || {};

  const patientFetch = useUpdate('PATIENT_INFO_UPDATED');

  const handleRefresh = () => {
    patientFetch.updateValue();
  };

  const updateHook = useEmployeeRemoveFromWatch({});
  const handleRemoveFromWatch = async () => {
    const req = updateHook.send({
      params: {
        removeRequest: {
          id: id || '',
        },
      },
    });
    await ApiRequestHelper.tryCatch(
      req,
      {
        success: 'Patient removed from watchlist',
        error: 'Fail to remove Patient',
        onSuccess: () => {
          handleRefresh();
        },
        onError: () => {
          handleRefresh();
        }
      },
    );
  };

  const handleOnclick = () => {
    if (isWatched) {
      handleRemoveFromWatch().then();
    }
  };

  return (
    <WatchedIndicatorComponent
      patientId={id}
      watched={isWatched || false}
      onClick={handleOnclick}
      handleRefresh={handleRefresh}
    />
  );
};
