import React from 'react';
import { PriorityLevelEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface PriorityLevelEnumComponentProps {
  value?: PriorityLevelEnum;
  type?: 'short' | 'long' | 'full';
}

export const PriorityLevelEnumComponent = ({
  value,
  type = 'short',
}: PriorityLevelEnumComponentProps) => {
  const getFullName = () => {
    switch (value) {
      case PriorityLevelEnum.LOW: return 'Low';
      case PriorityLevelEnum.MEDIUM: return 'Medium';
      case PriorityLevelEnum.HIGH: return 'High';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  const getShortName = () => {
    switch (value) {
      case PriorityLevelEnum.LOW: return 'LOW';
      case PriorityLevelEnum.MEDIUM: return 'MED';
      case PriorityLevelEnum.HIGH: return 'HIGH';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  const getLongestName = () => {
    switch (value) {
      case PriorityLevelEnum.LOW: return 'Low-priority Alert';
      case PriorityLevelEnum.MEDIUM: return 'Med-priority Alert';
      case PriorityLevelEnum.HIGH: return 'High-priority Alert';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  const getName = () => {
    switch (type) {
      case 'short': return getShortName();
      case 'long': return getFullName();
      case 'full': return getLongestName();
      default: return <DisplayOrEmptyComponent />;
    }
  };

  return (<>{getName()}</>);
};
