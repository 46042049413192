import classNames from 'classnames';
import { TagComponent } from '../../../../uiComponent/TagComponent/TagComponent';
import './PatientCarePriorityTagComponent.scss';

export interface PatientCarePriorityTagComponentProps {
  text?: string;
  className?: string;
}

export const PatientCarePriorityTagComponent = {
  HIGH: ({
    text = 'HIGH',
    className = '',
  }: PatientCarePriorityTagComponentProps) => (
    <TagComponent
      type="error"
      background="default"
      iconType="none"
      className={classNames({
        'w100 jc-c priorityEnumTagComponent': !className,
        [className]: !!className,
      })}
    >
      {text}
    </TagComponent>
  ),
  MED: ({
    text = 'MED',
    className = '',
  }: PatientCarePriorityTagComponentProps) => (
    <TagComponent
      type="warning"
      background="default"
      iconType="none"
      className={classNames({
        'w100 jc-c priorityEnumTagComponent': !className,
        [className]: !!className,
      })}
    >
      {text}
    </TagComponent>
  ),
  LOW: ({
    text = 'LOW',
    className = '',
  }: PatientCarePriorityTagComponentProps) => (
    <TagComponent
      type="processing"
      background="default"
      iconType="none"
      className={classNames({
        'text-primary-green bg-green': true,
        'w100 jc-c priorityEnumTagComponent': !className,
        [className]: !!className,
      })}
    >
      {text}
    </TagComponent>
  ),
};
