import { StorageKeyEnum, useSessionStorage } from '../../../../hooks';
import { Icons } from '../../../../icons/Icons';

export interface EhrOpenInTabButtonComponentProps {

}

export const EhrOpenInTabButtonComponent = () => {
  const [ehrUrl] = useSessionStorage<string | undefined>(StorageKeyEnum.EHR_URL);

  const handleOpenInNewTab = () => {
    if (ehrUrl) {
      window.open(ehrUrl, '_blank');
    }
  };

  if (!ehrUrl) {
    return null;
  }

  return (
    <Icons.Popout onClick={handleOpenInNewTab} />
  );
};
