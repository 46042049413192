import { Dayjs } from 'dayjs';
import { MessagePayload } from '../../../../services/CHSServices/types/data';
import { MessageUserInfo } from '../../../../types/message';
import { MessageItemComponent } from '../MessageItemComponent/MessageItemComponent';
import { MessageHistoryItemComponent } from '../MessageHistoryItemComponent/MessageHistoryItemComponent';

export interface MessageHistoryItemWrapperComponentProps {
  dateTime: Dayjs;
  publisher?: MessageUserInfo;
  messagePayload: MessagePayload;
  messageId: string;
}

export const MessageHistoryItemWrapperComponent = ({
  dateTime,
  publisher,
  messagePayload,
  messageId,
}: MessageHistoryItemWrapperComponentProps) => {
  const {
    displayName,
    userRole,
  } = messagePayload;

  return (
    <MessageItemComponent
      dateTime={dateTime}
      displayName={displayName}
      publisher={publisher}
      userRole={userRole}
    >
      <MessageHistoryItemComponent
        messagePayload={messagePayload}
        messageId={messageId}
      />
    </MessageItemComponent>
  );
};
