import React from 'react';
import { map } from 'lodash';
import { ColumnType } from 'antd/lib/table';
import { OrderItemEnum, OrderStaging } from '../../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { OrderItemEnumListComponent } from '../../../../enumComponent/OrderItemEnumListComponent/OrderItemEnumListComponent';
import { OrderItemEnumComponent } from '../../../../enumComponent/OrderItemEnumComponent/OrderItemEnumComponent';
import { OrderNoteComponent } from './OrderNoteComponent';

export const createColumns = () => ({
  orderItems: (): ColumnType<OrderStaging> => ({
    title: 'Item',
    dataIndex: 'orderItems',
    key: 'orderItems',
    render: (value, v) => (
      <OrderItemEnumListComponent value={v} />
    ),
    width: '30%',
    filters: map(OrderItemEnum, (value) => (
      { text: (<OrderItemEnumComponent value={value} />), value }
    )),
  }),
  createdAt: (): ColumnType<OrderStaging> => ({
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (v: string) => (<DisplayDateComponent value={v} format="USA_DATE" />),
    sorter: true,
  }),
  orderByName: (): ColumnType<OrderStaging> => ({
    title: 'Ordered by',
    dataIndex: 'orderByName',
    key: 'orderByName',
    render: (value, v) => (<DisplayOrEmptyComponent value={v.orderByName} />),
    sorter: true,
  }),
  shipTo: (): ColumnType<OrderStaging> => ({
    title: 'Deliver method',
    dataIndex: 'shipTo',
    key: 'shipTo',
    render: (value, v) => (
      v.pickup ? 'Pick up from clinic' : 'Ship to patient'
    )
  }),
  note: (): ColumnType<OrderStaging> => ({
    title: 'Notes',
    dataIndex: 'note',
    key: 'note',
    render: (value, v) => (
      <OrderNoteComponent order={v} />
    ),
    width: '30%',
  }),
});
