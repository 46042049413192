import { Form } from 'antd';
import { TagComponent, TagType } from '../TagComponent/TagComponent';

import './PasswordRequirementFormItemComponent.scss';

export interface PasswordRequirementFormItemComponentProps {
  name: string;
  displayText: string;
  showRequirement?: boolean;
  isFulfilled: boolean;
  hasInput?: boolean;
}

export const PasswordRequirementFormItemComponent = ({
  name,
  displayText,
  showRequirement = true,
  isFulfilled = false,
  hasInput,
}: PasswordRequirementFormItemComponentProps) => {
  const getType = (): TagType => {
    if (!hasInput) return 'default';
    if (isFulfilled) return 'success';
    return 'error';
  };

  return (
    <Form.Item
      data-testid="password-requirement-form-item-component"
      name={name}
      hidden={!showRequirement}
      shouldUpdate
    >
      <TagComponent
        background="none"
        iconType="noFilled"
        type={getType()}
      >
        {displayText}
      </TagComponent>
    </Form.Item>
  );
};
