import { ReactNode } from 'react';
import classNames from 'classnames';

import './CardHeaderComponent.scss';

export interface CardHeaderComponentProps {
  children: ReactNode;
  className?: string;
}

export const CardHeaderComponent = ({
  children,
  className = '',
}: CardHeaderComponentProps) => (
  <div
    className={classNames({
      flex: true,
      'ai-c': true,
      'card-header-component': true,
      [className]: !!className,
    })}
  >
    {children}
  </div>
);
