import { Divider } from 'antd';
import { FC } from 'react';
import { UNIFIEDCARE_PILOT_LOGO } from '../../../../assets/logoIconInfo';
import { NumberService } from '../../../../helpers/number';
import { Icon } from '../../../../icons/Icon';
import { NonNullUndefined } from '../../../../types/common';
import {
  Measurement, MeasurementResponse, VitalEnumType
} from '../../../../uc-api-sdk';
import { MeasurementHelper } from '../../helper/MeasurementHelper';
import { FromDateToDateDayjs } from '../../type';
import { BGSummaryTableDataProcessorComponent } from '../BGSummaryTableComponent/BGSummaryTableDataProcessorComponent';
import { BGTablePrintDataProcessorComponent } from '../BGTablePrintComponent/BGTablePrintDataProcessorComponent';
import { BOSummaryTableDataProcessorComponent } from '../BOSummaryTableComponent/BOSummaryTableDataProcessorComponent';
import { BOTablePrintDataProcessorComponent } from '../BOTablePrintComponent/BOTablePrintDataProcessorComponent';
import { BPSummaryTableDataProcessorComponent } from '../BPSummaryTableComponent/BPSummaryTableDataProcessorComponent';
import { BPTablePrintDataProcessorComponent } from '../BPTablePrintComponent/BPTablePrintDataProcessorComponent';
import { HSTablePrintDataProcessorComponent } from '../HSTablePrintComponent/HSTablePrintDataProcessorComponent';
import { SummaryTwoIndexComponent } from '../SummaryColumnComponents/SummaryTwoIndexComponent';
import { SummaryItem } from '../VitalSummaryComponent/VitalSummaryComponent';
import './PrintVitalComponent.scss';
import { PrintVitalTableTitleComponent } from './PrintVitalTableTitleComponent';

export interface PrintVitalComponentProps extends FromDateToDateDayjs {
  vitals: VitalEnumType[];
  data: MeasurementResponse;
  title: string;
}

export const PrintVitalComponent: FC<PrintVitalComponentProps> = ({
  vitals,
  data,
  fromDate,
  toDate,
  title,
}) => {
  const showVital = (vital: VitalEnumType) => vitals.includes(vital);

  const renderTitle = (title: string) => (
    <>
      <div className="flex jc-sb">
        <div>
          <div className="print-vital-title">
            {title}
          </div>
          <div className="fs12">
            Vital Data Summary
          </div>
        </div>
        <Icon info={UNIFIEDCARE_PILOT_LOGO} className="header-logo" />
      </div>
      <Divider className="print-vital-divider" />
    </>
  );

  const renderTableTitle = (name: string) => (
    <PrintVitalTableTitleComponent name={name} fromDate={fromDate} toDate={toDate} />
  );

  const dataArr = (
    data?.results?.content || []
  ) as NonNullUndefined<Measurement>[];

  return (
    <>
      {renderTitle(title)}
      <div className="flex gap3 fd-c">
        {showVital(VitalEnumType.BP) && (
        <>
          <div>
            {renderTableTitle('Blood Pressure Log')}
            <BPTablePrintDataProcessorComponent
              data={MeasurementHelper.getBPMeasurements(dataArr)}
            />
          </div>
          <div>
            {renderTableTitle('Blood Pressure Summary')}
            <div className="flex gap3 bold">
              <SummaryItem title="Total Measure" value={data?.stats?.bp?.all?.count || 0} />
              <SummaryItem
                title="Average BP"
                value={(
                  <SummaryTwoIndexComponent
                    value={
                        NumberService.numberArrayToFixed(data?.stats?.bp?.all?.average || [], 0)
                      }
                  />
                  )}
              />
              <SummaryItem title="In Normal" value={`${(data?.stats?.bp?.all?.inRangePercentage || 0) * 100}%`} />

            </div>
            <BPSummaryTableDataProcessorComponent data={data?.stats?.bp || {}} />
          </div>
        </>
        )}
        {showVital(VitalEnumType.BG) && (
        <>
          <div>
            {renderTableTitle('Blood Glucose Log')}
            <BGTablePrintDataProcessorComponent
              data={MeasurementHelper.getBGMeasurements(dataArr)}
            />
          </div>
          <div>
            {renderTableTitle('Blood Glucose Summary')}
            <BGSummaryTableDataProcessorComponent data={data?.stats?.bg || {}} />
          </div>
        </>
        )}
        {showVital(VitalEnumType.PO) && (
        <>
          <div>
            {renderTableTitle('Blood Oxygen Log')}
            <BOTablePrintDataProcessorComponent
              data={MeasurementHelper.getBOMeasurements(dataArr)}
            />
          </div>
          <div>
            {renderTableTitle('Blood Oxygen Summary')}
            <BOSummaryTableDataProcessorComponent data={data?.stats?.po || {}} />
          </div>
        </>
        )}
        {showVital(VitalEnumType.HS) && (
        <div>
          {renderTableTitle('Weight Log')}
          <HSTablePrintDataProcessorComponent
            data={MeasurementHelper.getHSMeasurements(dataArr)}
          />
        </div>
        )}
      </div>
    </>
  );
};
