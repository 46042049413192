/* eslint-disable import/prefer-default-export */
import { BgStat } from '../../../../uc-api-sdk';
import { BGSummaryKey } from '../../type';
import { useResultToSummaryTable } from '../useResultToSummaryTable/useResultToSummaryTable';

export const useBGResultToSummaryTable = (data: BgStat) => {
  const order: BGSummaryKey[] = [
    'fasting',
    'beforeMeal',
    'afterMeal',
    'bedtimeOvernightHypo',
    'bedtime',
    'overnight',
    'criticalHigh',
    'criticalLow',
  ];

  const response = useResultToSummaryTable(data as Required<BgStat>, order);

  return response;
};
