import { isEmpty, map } from 'lodash';
import { Divider } from 'antd';
import { Nutrition } from '../../../../../uc-api-sdk';
import { FreqComponent } from '../../../../../uiComponent/FreqComponent/FreqComponent';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';
import { PrintCarePlanSimpleItemComponent } from '../PrintCarePlanSimpleItemComponent/PrintCarePlanSimpleItemComponent';
import { DietEnumListComponent } from '../../../../../enumComponent/DietEnumComponent/DietEnumListComponent';
import DisplayOrEmptyComponent from '../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { PrintCarePlanNutritionIntakeComponent } from './PrintCarePlanNutritionIntakeComponent';

export interface PrintCarePlanNutritionComponentProps {
  nutrition?: Nutrition | null;
}

export const PrintCarePlanNutritionComponent = ({
  nutrition,
}: PrintCarePlanNutritionComponentProps) => {
  if (isEmpty(nutrition)) {
    return null;
  }

  return (
    <>
      <div className="page-break" />
      <TextComponent className="pop-text">
        Nutrition
      </TextComponent>

      {
        map(nutrition?.intake, (nutritionIntake) => (
          <PrintCarePlanNutritionIntakeComponent
            key={nutritionIntake.meal}
            nutritionIntake={nutritionIntake}
          />
        ))
      }

      <PrintCarePlanSimpleItemComponent
        label="Fast Food Frequency:"
        content={(
          <FreqComponent
            value={nutrition?.fastFoodFreq?.value ?? undefined}
            unit={nutrition?.fastFoodFreq?.unit ?? undefined}
          />
        )}
      />
      <PrintCarePlanSimpleItemComponent
        label="Sweet Beverage Frequency:"
        content={(
          <FreqComponent
            value={nutrition?.sweetBeverageFreq?.value ?? undefined}
            unit={nutrition?.sweetBeverageFreq?.unit ?? undefined}
          />
        )}
      />
      <PrintCarePlanSimpleItemComponent
        label="Previous Calorie Management Regimens:"
        content={(
          <DietEnumListComponent
            value={nutrition?.previousDiets ? nutrition?.previousDiets : []}
            splitter=", "
          />
        )}
      />
      <PrintCarePlanSimpleItemComponent
        label="Nutrition Understanding:"
        content={(
          <DisplayOrEmptyComponent
            value={nutrition?.nutritionUnderstanding ?? undefined}
          />
        )}
      />
      <PrintCarePlanSimpleItemComponent
        label="Additional Comment:"
        content={(
          <DisplayOrEmptyComponent value={nutrition?.additionalComments} />
        )}
      />
      <Divider />
    </>
  );
};
