/* eslint-disable react/jsx-props-no-spreading */
import { Row } from 'antd';
import { CheckboxOptionType } from 'antd/es/checkbox';
import Checkbox, { CheckboxGroupProps } from 'antd/lib/checkbox';
import { map } from 'lodash';

export interface CheckboxGroupComponentProps extends CheckboxGroupProps {
  isVertical?: boolean;
}

export const CheckboxGroupComponent = ({
  isVertical = true,
  options,
  ...props
}: CheckboxGroupComponentProps) => {
  if (isVertical) {
    return (
      <Checkbox.Group {...props}>
        {map(options, (v: CheckboxOptionType, i: number) => (
          <Row key={i}>
            <Checkbox
              key={v.value.toString() || i.toString()}
              value={v.value}
            >
              {v.label}
            </Checkbox>
          </Row>
        ))}
      </Checkbox.Group>
    );
  }
  return (
    <Checkbox.Group options={options} {...props} />
  );
};
