import { Select } from 'antd';
import { map } from 'lodash';
import { FC } from 'react';
import { SelectType } from '../../features/Input/types';
import { USStatesType } from '../../types/USStates';
import US_STATE_MAP from './USStateMap';

export interface USStateSelectComponentProps extends SelectType<USStatesType> {
  showFullName?: boolean;
}

export const USStateSelectComponent: FC<USStateSelectComponentProps> = ({
  showFullName,
  value,
  onChange,
}) => {
  const getName = (abbreviation: string, fullName: string) => (
    showFullName ? fullName : abbreviation
  );

  return (
    <div className="us-state-select-component">
      <Select
        value={value}
        onChange={onChange}
        showSearch
      >
        {map(US_STATE_MAP, (v, k) => (
          <Select.Option key={k} value={k}>{getName(k, v)}</Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default USStateSelectComponent;
