import { ReactNode } from 'react';

export interface SideMenuItemProps {
  icon: ReactNode;
  label: string;
  key: string;
  onClick: () => void;
}

// This should be called as a function otherwise the Menu component
// will not allow this component
export const getMenuItem = ({
  icon,
  label,
  key,
  onClick,
}: SideMenuItemProps) => ({
  key,
  label,
  onClick,
  icon: (
    <div>
      <div className="menu-item-shadow" />
      <div>
        {icon}
      </div>
      <div className="menu-item-label">
        {label}
      </div>
    </div>
  )
});
