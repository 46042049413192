import { map } from 'lodash';
import React, { FC, useMemo } from 'react';
import { MeasurementType } from '../../type/MeasurementTypes';
import { BOChartComponent, BOChartData } from './BOChartComponent';
import { FromDateToDateDayjs } from '../../type';
import { BOMeasurementService } from '../../helper/BOMeasurementService';

export interface BOChartDataProcessorComponentProps extends FromDateToDateDayjs {
  data: MeasurementType[];
}

export const BOChartDataProcessorComponent: FC<BOChartDataProcessorComponentProps> = ({
  data,
  fromDate,
  toDate,
}) => {
  const processedData = useMemo(() => (
    map(data, (v): BOChartData => {
      const m = new BOMeasurementService(v);
      return {
        date: m.date,
        patientNote: m.userNote,
        poSeverity: m.poSeverity,
        bloodOxygen: m.bloodOxygen.value,
        isCopd: m.isCopd,
      };
    })
  ), [data]);

  return (
    <BOChartComponent
      data={processedData}
      fromDate={fromDate}
      toDate={toDate}
    />
  );
};
