import { useMemo } from 'react';
import { map } from 'lodash';
import { Tabs, TabsProps } from 'antd';
import { Ticket } from '../../../../uc-api-sdk';
import { TicketViewContainer } from '../../container/TicketViewContainer/TicketViewContainer';

export interface TicketTabsComponentProps {
  dataSource: Ticket[];
}
export const TicketTabsComponent = ({
  dataSource,
}: TicketTabsComponentProps) => {
  const tabItems: TabsProps['items'] = useMemo(() => {
    const result = map(dataSource, (k, i) => {
      const t = {
        key: `${i + 1}`,
        label: `Task ${i + 1}`,
        children: (
          <TicketViewContainer ticketId={k.id ?? ''} patientInputDisable />
        ),
      };
      return t;
    });
    return result;
  }, [dataSource]);

  return (
    <Tabs
      className="tab-fixed-header"
      animated={false}
      tabBarStyle={{ height: 50 }}
      id="Task"
      items={tabItems}
      destroyInactiveTabPane
    />
  );
};
