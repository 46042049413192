import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { FC, ReactNode } from 'react';
import './PrevNextComponent.scss';

export interface PrevNextComponentProps {
  children: ReactNode;
  disableNext?: boolean;
  disablePre?: boolean;
  gotoNext: () => void;
  gotoPre: () => void;
}

export const PrevNextComponent: FC<PrevNextComponentProps> = ({
  children,
  disableNext,
  disablePre,
  gotoNext,
  gotoPre,
}) => (
  <>
    <Button
      className="pre"
      onClick={() => gotoPre()}
      disabled={disablePre}
    >
      <LeftOutlined />
    </Button>
    {children}
    <Button
      className="next"
      onClick={() => gotoNext()}
      disabled={disableNext}
    >
      <RightOutlined />
    </Button>
  </>
);
