import { Dayjs } from 'dayjs';
import { USA_DATE } from '../../../../constants/timeFormat';
import { SeverityHelper } from '../../helper/severityHelper';
import { Point } from '../../type';
import TimezoneService from '../../../../helpers/timezone/timezoneService';

export interface BOChartSeriesCreatorOptions<T extends Point> {
  normal: T[];
  low: T[];
  criticalLow: T[];
}

export const createBOChartSeries = <T extends Point>(info: BOChartSeriesCreatorOptions<T>) => ([
  {
    data: [],
    name: 'COPD',
    findNearestPointBy: 'xy',
    lineWidth: 0,
    color: 'black',
    marker: {
      symbol: 'triangle',
      radius: 4,
    },
    events: {
      legendItemClick() {
        return false;
      },
    },
  },
  {
    data: info.normal,
    name: 'Normal',
    findNearestPointBy: 'xy',
    severity: 'NORMAL',
    lineWidth: 0,
    color: '#37cc8d',
    lineColor: '#37cc8d',
    states: {
      hover: {
        lineWidthPlus: 0,
      },
    },
    marker: {
      enabled: true,
      symbol: 'circle',
      radius: 4,
    },
  },
  {
    data: info.low,
    name: 'Low',
    findNearestPointBy: 'xy',
    severity: 'LOW',
    lineWidth: 0,
    lineColor: '#fdb543',
    color: '#fdb543',
    states: {
      hover: {
        lineWidthPlus: 0,
      },
    },
    marker: {
      enabled: true,
      radius: 4,
      symbol: 'circle',
    },
  },
  {
    data: info.criticalLow,
    name: 'Critical Low',
    findNearestPointBy: 'xy',
    severity: 'CRITICAL_LOW',
    lineWidth: 0,
    lineColor: '#EE6161',
    color: '#EE6161',
    states: {
      hover: {
        lineWidthPlus: 0,
      },
    },
    marker: {
      enabled: true,
      radius: 4,
      symbol: 'circle',
    },
  },
]);

export const createBOChartOptions = (
  series: ReturnType<typeof createBOChartSeries>,
  fromDate: Dayjs,
  toDate: Dayjs,
) => ({
  chart: {
    type: 'line',
  },
  title: null,
  plotBackgroundColor: '#0099FF',
  xAxis: {
    type: 'datetime',
    tickWidth: 2,
    tickInterval: 24 * 3600 * 1000,
    min: fromDate.valueOf(),
    max: toDate.valueOf(),
    labels: {
      formatter: (xAxis: { value: Date; }) => (
        TimezoneService.calcDateTimeDayjs(xAxis.value).format(USA_DATE)
      ),
    },
  },
  yAxis: {
    title: {
      text: 'Percentage (%)',
    },
    max: 100,
    gridLineColor: 'transparent',
    plotBands: [{
      from: 0,
      to: 100,
      color: 'rgb(119,119,119,0.05)',
    }],
  },
  legend: {
    align: 'right',
    verticalAlign: 'top',
  },
  tooltip: {
    crosshairs: true,
    dateTimeLabelFormats: {
      day: '%m/%e/%Y',
    },
    // @ts-ignore
    pointFormatter() {
      // @ts-ignore
      const { y, severity, notes } = this;
      return `
        ${SeverityHelper.getPoSeverityText(severity)}: <b>${y}%</b>
        <br />
        Notes: ${notes}
      `;
    },
  },

  time: {
    useUTC: false,
    timezone: TimezoneService.guessTimezone(),
  },
  credits: {
    enabled: false,
  },
  series,
});
