import { ReactNode } from 'react';
import { Icons, IconsProps } from '../../icons/Icons';
import { LinkButton, LinkButtonProps } from '../LinkButton/LinkButton';

export interface NoDataWithActionComponentProps {
  iconSize?: IconsProps['size'];
  icon?: ReactNode;
  description?: ReactNode;
  actionLinkText?: ReactNode;
  actionLinkOnClick?: () => void;
  actionLinkProps?: LinkButtonProps;
  children?: ReactNode; // replace link button
}

export const NoDataWithActionComponent = ({
  iconSize,
  icon = <Icons.EmptyImg size={iconSize} />,
  description,
  actionLinkText,
  actionLinkOnClick,
  actionLinkProps,
  children,
}: NoDataWithActionComponentProps) => (
  <div className="flex jc-c ai-c w100 h100 fd-c gap2">
    {icon}
    {
        description
        && (
          <div className="no-data-description">
            {description}
          </div>
        )
      }
    <div className="no-data-action">
      {
        children
        || (
          (actionLinkText && actionLinkOnClick)
          && (
            <LinkButton
              useBlueColor
              onClick={actionLinkOnClick}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...actionLinkProps}
            >
              {actionLinkText}
            </LinkButton>
          )
        )
      }
    </div>
  </div>
);
