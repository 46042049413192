import {
  useMemo,
  useState,
} from 'react';
import { MenuProps } from 'antd';
import classnames from 'classnames';
import DropdownComponent from '../../../../uiComponent/DropdownComponent/DropdownComponent';
import { CallCenterStatusDropdownItem } from './constants';
import { useCallCenterContext } from '../../../../contexts/CallCenterContext/CallCenterContext';
import { getCallCenterStatusMenuItems } from './helpers';
import { CallCenterStatusButtonComponent } from './CallCenterStatusButtonComponent';

import './CallCenterStatusDropdownComponent.scss';
import { useCallCenterServices } from '../../../../contexts/CallCenterContext/services/CallCenterServicesContext';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';

export const CallCenterStatusDropdownComponent = () => {
  const {
    isLoggingIn,
  } = useCallCenterServices();
  const {
    status,
    handleSetOnline,
    handleSetOffline,
    handleOpenControlPanel,
  } = useCallCenterContext();
  const { send } = useMixpanelContext();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const isLoggedIn = status !== undefined;

  const menu = useMemo((): MenuProps => ({
    items: getCallCenterStatusMenuItems(isLoggedIn),
    onClick: (info) => {
      switch (info.key as CallCenterStatusDropdownItem['key']) {
        case 'set-offline':
          send({ event: MixpanelEvents.HeaderAWSOffline });
          handleSetOffline();
          break;
        case 'set-online':
          send({ event: MixpanelEvents.HeaderAWSOnline });
          handleSetOnline();
          break;
        case 'open-ccp':
          send({ event: MixpanelEvents.HeaderAWSControlPanel });
          handleOpenControlPanel();
          break;
        default:
          break;
      }
      setDropdownOpen(false);
    },
  }), [
    isLoggedIn,
    handleOpenControlPanel,
    handleSetOnline,
    handleSetOffline,
  ]);

  const handleDropdownVisibleChange = (visible: boolean) => {
    if (visible) {
      send({ event: MixpanelEvents.HeaderAWS });
    }
    setDropdownOpen(visible);
  };

  return (
    <div className="flex">
      <DropdownComponent
        openState={dropdownOpen}
        setOpenState={handleDropdownVisibleChange}
        menu={menu}
        className={classnames({
          'call-center-status-dropdown': true,
        })}
        overlayClassName="call-center-status-dropdown-overlay"
        placement="bottomLeft"
      >
        <CallCenterStatusButtonComponent
          isLoggedIn={isLoggedIn}
          status={status}
          isLoading={isLoggingIn}
          showCaret
        />
        <div
          className={classnames({
            // 'call-center-agent-status-indicator': true,
            'logged-in': isLoggedIn,
            // [status as string]: !!status,
          })}
        />
      </DropdownComponent>
    </div>
  );
};
