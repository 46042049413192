import { map } from 'lodash';
import { ProgramCategoryEnum } from '../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface ProgramCategoriesComponentProps {
  value: ProgramCategoryEnum[];
  splitter?: string;
}

export const ProgramCategoriesComponent = ({
  value,
  splitter = '/'
}: ProgramCategoriesComponentProps) => {
  const getName = (v: ProgramCategoryEnum) => {
    switch (v) {
      case ProgramCategoryEnum.CCM: return 'CCM';
      case ProgramCategoryEnum.RPM: return 'RPM';
      case ProgramCategoryEnum.MNT: return 'MNT';
      case ProgramCategoryEnum.VALUE_BASED: return 'VBC';
      case ProgramCategoryEnum.MEMBERSHIP: return 'Membership';
      default: return undefined;
    }
  };

  return (
    <DisplayOrEmptyComponent value={map(value, getName).join(splitter)} />
  );
};
