import dayjs from 'dayjs';
import { map } from 'lodash';
import { Divider } from 'antd';
import { UNIFIEDCARE_PILOT_LOGO } from '../../../../../assets/logoIconInfo';
import { AccountHelper } from '../../../../../helpers/account';
import { Icon } from '../../../../../icons/Icon';
import {
  CarePlan, GenderEnum, Height, Weight
} from '../../../../../uc-api-sdk';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';
import { Title } from '../../../../../uiComponent/Title/Title';

import './PrintCarePlanPreviewComponent.scss';
import { GenderEnumComponent } from '../../../../../enumComponent/GenderEnumComponent/GenderEnumComponent';
import { HeightComponent } from '../../../../../uiComponent/HeightComponent/HeightComponent';
import { WeightComponent } from '../../../../../uiComponent/WeightComponent/WeightComponent';
import { ProgramCategoryEnumListComponent } from '../../../../../enumComponent/ProgramCategoriesComponent/ProgramCategoryEnumListComponent';
import { EnrolledProgramService } from '../../../../../services/EnrolledProgramService';
import { VitalEnumTypeListComponent } from '../../../../../enumComponent/VitalsEnumTypeComponent/VitalEnumTypeListComponent';
import { PrintLifeStyleComponent } from '../PrintLifeStyleComponent/PrintLifeStyleComponent';
import { PrintVisitSummaryComponent } from '../PrintVisitSummaryComponent/PrintVisitSummaryComponent';
import { PrintReturnReminderComponent } from '../PrintReturnReminderComponent/PrintReturnReminderComponent';
import { PrintCarePlanHealthConditionsComponent } from '../PrintCarePlanHealthConditionsComponent/PrintCarePlanHealthConditionsComponent';
import { PrintCarePlanDiseaseHistoryComponent } from '../PrintCarePlanDiseaseHistoryComponent/PrintCarePlanDiseaseHistoryComponent';
import { PrintCarePlanMedicationComponent } from '../PrintCarePlanMedicationComponent/PrintCarePlanMedicationComponent';
import { PrintCarePlanSocialSupportComponent } from '../PrintCarePlanSocialSupportComponent/PrintCarePlanSocialSupportComponent';
import { PrintCarePlanNutritionComponent } from '../PrintCarePlanNutritionComponent/PrintCarePlanNutritionComponent';
import { PrintCarePlanClinicalGoalComponent } from '../PrintCarePlanClinicalGoalComponent/PrintCarePlanClinicalGoalComponent';
import { PrintCarePlanVitalsMonitoringGoalComponent } from '../PrintCarePlanVitalsMonitoringGoalComponent/PrintCarePlanVitalsMonitoringGoalComponent';
import { PrintCarePlanBehavioralGoalComponent } from '../PrintCarePlanBehavioralGoalComponent/PrintCarePlanBehavioralGoalComponent';
import { PrintCarePlanUserComponent } from '../PrintCarePlanUserComponent/PrintCarePlanUserComponent';
import DisplayOrEmptyComponent from '../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface PrintCarePlanPreviewComponentProps {
  carePlan?: CarePlan;
}

export const PrintCarePlanPreviewComponent = ({
  carePlan,
}: PrintCarePlanPreviewComponentProps) => {
  const {
    assignees,
    profile,
    enrolledProgram,
    visit,
    medicalRecordId,
    healthCondition,
    diseaseHistory,
    medication,
    lifeStyle,
    nutrition,
    clinicGoal,
    behaviorGoal,
    source,
    careTeamMembers,
  } = carePlan || {};
  const isMigration = source === 'MIGRATION';
  const enrolledProgramService = new EnrolledProgramService(enrolledProgram ?? undefined);
  return (
    <div className="print-care-plan-preview">
      <div className="print-care-plan-preview-header">
        <Title noMargin>
          CARE PLAN
        </Title>
        <Icon
          className="unified-care-logo"
          info={UNIFIEDCARE_PILOT_LOGO}
        />
      </div>
      <div className="print-care-plan-preview-profile">
        <TextComponent className="pop-text">
          {AccountHelper.getFullName(profile)}
        </TextComponent>
        <div className="print-care-plan-preview-profile__details">
          <span>
            {dayjs().endOf('day').diff(dayjs(profile?.birthday), 'years')}
            {' y/o'}
          </span>
          <GenderEnumComponent value={profile?.gender as GenderEnum} />
          <span>
            MRN:
            {' '}
            <DisplayOrEmptyComponent value={medicalRecordId} />
          </span>
          <HeightComponent
            label="Ht:"
            height={profile?.height as Height}
          />
          <WeightComponent
            label="Wt:"
            weight={profile?.weight as Weight}
          />
          <span>
            Enrolled programs:
            {' '}
            <ProgramCategoryEnumListComponent
              value={enrolledProgramService.getPrograms()}
              type="short"
            />
          </span>
          <span>
            Vitals:
            {' '}
            <VitalEnumTypeListComponent
              value={enrolledProgramService.getVitals() || []}
              type="short"
              splitter=", "
            />
          </span>
        </div>
      </div>
      <Divider className="print-care-plan-preview__divider bold-divider" />
      <div className="print-care-plan-preview-program-description">
        <span className="program-description__title">
          Program Description & Care Coordination:
        </span>
        The primary care physician collaborates with care team members
        to set up the patient's care plan and provide periodic in-clinic visits
        to review progress. The Unified Care clinicians remotely monitor
        patients vitals and provide point-of-care support
        and real-time behavioral coaching under the supervision of the primary care physician.
      </div>
      <div className="print-care-plan-preview-care-team">
        <div className="page-break" />
        <TextComponent className="pop-text">
          Care Team
        </TextComponent>
        <div className="print-care-plan-preview-care-team__names">
          <PrintCarePlanUserComponent
            profile={profile?.doctorUser}
          />
          <PrintCarePlanUserComponent
            profile={assignees?.assignedToRDUser}
          />
          <PrintCarePlanUserComponent
            profile={assignees?.assignedToCAUser}
          />
          {
            isMigration
              ? (
                map(careTeamMembers, (member, idx) => (
                  <PrintCarePlanUserComponent
                    key={idx}
                    profile={{ firstName: member, lastName: '' }}
                  />
                ))
              ) : null
          }
        </div>
      </div>
      <div className="print-care-plan-preview-visit-summary">
        <div className="page-break" />
        <PrintVisitSummaryComponent visit={visit} />
      </div>
      <div className="print-care-plan-preview-reminder">
        <div className="page-break" />
        <PrintReturnReminderComponent />
      </div>
      <Divider className="print-care-plan-preview__divider" />
      <div className="print-care-plan-preview-health-condition">
        <PrintCarePlanHealthConditionsComponent
          healthConditions={healthCondition?.healthConditions}
        />
      </div>
      <div className="print-care-plan-preview-notice">
        <TextComponent italic color="gray2">
          Patient's medical history, lifestyle, and medication compliance have been reviewed.
        </TextComponent>
      </div>
      <div className="print-care-plan-preview-disease-history">
        <PrintCarePlanDiseaseHistoryComponent
          diseaseHistory={diseaseHistory?.diseaseHistory}
        />
      </div>
      <div className="print-care-plan-preview-medication-reconciliation-compliance">
        <PrintCarePlanMedicationComponent
          medication={medication}
        />
      </div>
      <div className="print-care-plan-preview-social-support">
        <PrintCarePlanSocialSupportComponent
          profile={profile}
        />
      </div>
      <div className="print-care-plan-preview-lifestyle">
        <PrintLifeStyleComponent
          lifeStyle={lifeStyle?.doc}
        />
      </div>
      <div className="print-care-plan-preview-nutrition">
        <PrintCarePlanNutritionComponent
          nutrition={nutrition?.doc}
        />
      </div>
      <Divider className="print-care-plan-preview__divider" />
      <div className="print-care-plan-preview-program-description">
        <div className="page-break" />
        <span className="program-description__title">
          Planned Interventions:
        </span>
        Remote Vitals Monitoring and Chronic Care Management -
        Patient is provided with blood pressure, blood glucose, weight scale, pulse oximeter
        or a combination of these vitals to measure vitals per care plan goals and
        take anymedications as prescribed. Unified Care mobile app and CCM and/or RPM services
        are prescribed to the patient. Care team will monitor patient's vitals remotely and
        provide real time advice for lifestyle coaching. Medication compliance will be
        enhanced via mobile app reminders. Instructed on best practices for taking a measurement.
      </div>
      <div className="print-care-plan-preview-clinical-goals">
        <PrintCarePlanClinicalGoalComponent
          clinicalGoals={clinicGoal?.doc?.clinicalGoals || []}
        />
      </div>
      <div className="print-care-plan-preview-vitals-monitoring-goal">
        <PrintCarePlanVitalsMonitoringGoalComponent
          monitoringVitals={enrolledProgramService.getMonitoringVitals() || []}
        />
      </div>
      <div className="print-care-plan-preview-behavioral-goal">
        <PrintCarePlanBehavioralGoalComponent
          behavioralGoals={behaviorGoal?.doc?.behaviorGoals || []}
        />
      </div>
      <Divider className="print-care-plan-preview__divider my10i" />
      <div className="print-care-plan-preview-announcement pb20">
        <TextComponent italic color="gray2">
          This Care Plan was created under the supervision
          of the provider per the terms in the partnership agreement.
        </TextComponent>
      </div>
    </div>
  );
};
