import { SearchOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import classNames from 'classnames';
import React, {
  ReactElement, ReactNode, useEffect, useState
} from 'react';
import './TableSearchComponent.scss';
import { RoleTypeEnum } from '../../../../uc-api-sdk';

export interface TableSearchComponentProps extends FilterDropdownProps {
  children: ReactNode | ((props: Pick<FilterDropdownProps, 'selectedKeys' | 'setSelectedKeys'>) => React.ReactNode);
  className?: string;
  containerId?: string;
  relative?: boolean;
  selectedClinic?: string;
  filterRoles?: RoleTypeEnum[];
}

export const TableSearchComponent = ({
  children,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  close,
  className = '',
  containerId,
  relative = false,
  selectedClinic,
  filterRoles,
}: TableSearchComponentProps) => {
  const childrenWithProps: ReactNode = React.Children.map(children as ReactNode, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as ReactElement, { setSelectedKeys, selectedKeys });
    }
    return child;
  });

  const [previousClinic, setPreviousClinic] = useState<string>();

  useEffect(() => {
    setPreviousClinic(selectedClinic);
    if (selectedClinic && filterRoles?.includes(RoleTypeEnum.PROVIDER) && previousClinic) {
      clearFilters?.();
      confirm({ closeDropdown: false });
    }
  }, [selectedClinic]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classNames({
        'table-search-component-search-component': true,
        [className]: !!className,
        relativeSelect: relative,
      })}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <div className="table-search-component-search-field" id={containerId}>
        {childrenWithProps}
      </div>
      <div className="buttons-wrapper">
        <Button
          type="primary"
          onClick={() => {
            confirm();
            close();
          }}
          icon={<SearchOutlined />}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            clearFilters?.();
            confirm();
            close();
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );
};
