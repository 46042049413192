import { Button, Space } from 'antd';
import React, { FC } from 'react';

export interface CancelDeleteButtonsComponentProps {
  onCancel?: () => void;
  onDelete?: () => void;
  cancelBtnText?: string,
  deleteBtnText?: string,
}

export const CancelDeleteButtonsComponent: FC<CancelDeleteButtonsComponentProps> = ({
  onCancel,
  onDelete,
  cancelBtnText = 'Cancel',
  deleteBtnText = 'Yes, delete',
}) => (
  <div className="flex jc-e mt20">
    <Space>
      <Button onClick={onCancel}>
        {cancelBtnText}
      </Button>
      <Button type="primary" onClick={onDelete} danger>
        {deleteBtnText}
      </Button>
    </Space>
  </div>
);

export default CancelDeleteButtonsComponent;
