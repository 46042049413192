// @ts-nocheck
import { ColumnType } from 'antd/lib/table';
import { EditOutlined } from '@ant-design/icons';
import parse from 'html-react-parser';
import { map } from 'lodash';
import { ReferenceLevelEnum } from '../../../../uc-api-sdk';
import { HemoglobinA1cTemplateId, LabResultEditableItem } from '../../constants';
import { ReferenceLevelEnumComponent } from '../ReferenceLevelEnumComponent';
import './LabResultDetailTableColumn.scss';
import { LabResultFlagComponent } from './LabResultFlagComponent';
import { AntdEllipsisComponent } from '../../../../uiComponent/AntdEllipsisComponent/AntdEllipsisComponent';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';

interface LabResultDetailColumnType extends ColumnType<LabResultEditableItem> {
  editable?: boolean;
}

export const labResultDetailColumns = (isValueEditable: boolean) => ({
  name: (
    onEdit?: (record: LabResultEditableItem) => void,
  ): LabResultDetailColumnType => ({
    title: 'Test',
    dataIndex: 'name',
    width: '30%',
    editable: true,
    sorter: (a: LabResultEditableItem, b: LabResultEditableItem) => (a.name?.localeCompare(b.name ?? '') ?? 0),
    render: (name: string, record: LabResultEditableItem) => (
      <div className="flex">
        <TooltipComponent
          showIcon={false}
          title={record?.isManual ? 'Manually added' : ''}
        >
          <AntdEllipsisComponent>
            {name || <EmptyComponent />}
          </AntdEllipsisComponent>
        </TooltipComponent>
        {
          (record?.isManual && !record?.isManualEditable)
          && <EditOutlined onClick={() => onEdit?.(record)} />
        }
      </div>
    ),
  }),
  value: (): LabResultDetailColumnType => ({
    title: 'Result',
    dataIndex: 'value',
    width: '38%',
    editable: isValueEditable,
    render: (v: string, l: LabResultEditableItem) => (
      <div role="presentation" className="ml10 mr10 flex">
        <div className="lab-result-value">{v}</div>
        <div className="lab-result-unit">{parse(l.unit ?? '')}</div>
      </div>
    ),
  }),
  flag: (template: string, flags: ReferenceLevelEnum[]): LabResultDetailColumnType => ({
    title: 'Flag',
    dataIndex: 'flag',
    key: 'flag',
    editable: true,
    render: (flag: string, item: LabResultEditableItem) => {
      const isNumeric = (val: string): boolean => !Number.isNaN(Number(val));
      if (
        (item.value && isNumeric(item.value))
        || (item.isManual && flag)
      ) {
        return (
          <LabResultFlagComponent
            value={Number(item.value)}
            name={item.name ?? undefined}
            referenceLevels={item.referenceLevels ?? undefined}
            template={template}
            flag={flag}
          />
        );
      }
      return (<div />);
    },
    onFilter:
      (value: string | number | boolean, record) => record?.flag?.indexOf(String(value)) === 0,
    filters: (template === HemoglobinA1cTemplateId || isValueEditable)
      ? undefined : map(flags, (value) => ({
        text: (<ReferenceLevelEnumComponent value={value} />),
        value,
      })),
  }),
  isInHouse: (): LabResultDetailColumnType => ({
    title: 'In-house Test',
    dataIndex: 'isInHouse',
    key: 'isInHouse',
    editable: true,
    width: '12%',
  }),
});
