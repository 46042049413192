import { AntdEllipsisComponent } from '../../../../uiComponent/AntdEllipsisComponent/AntdEllipsisComponent';
import './MessageDisplaySenderComponent.scss';

export interface MessageDisplaySenderComponentProps {
  displayName: string;
}

export const MessageDisplaySenderComponent = ({
  displayName,
}: MessageDisplaySenderComponentProps) => (
  <AntdEllipsisComponent className="message-sender-name">
    {displayName || '--'}
  </AntdEllipsisComponent>
);
