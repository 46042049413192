import { Dayjs } from 'dayjs';
import { USA_DATE } from '../../../../constants/timeFormat';
import { PlotBand, Point } from '../../type';
import TimezoneService from '../../../../helpers/timezone/timezoneService';

const createTooltip = () => ({
  // eslint-disable-next-line object-shorthand, func-names
  pointFormatter: function (): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { systolic, diastolic, heartRate } = this;
    return `
      Systolic: <b>${systolic}</b><br>
      Diastolic: <b>${diastolic}</b><br>
      Heart Rate: <b>${heartRate || '--'}</b>
    `;
  },
});

export interface CreateBPChartSeries<T extends Point> {
  systolicData: T[];
  diastolicData: T[];
  onSystolicLegendClick: () => void;
  onDiastolicLegendClick: () => void;
}

export const createBPChartSeries = <T extends Point>(info: CreateBPChartSeries<T>) => ([
  {
    name: 'Diastolic Blood Pressure',
    data: info.diastolicData,
    type: 'line',
    color: '#7396b6',
    marker: {
      enabled: false,
    },
    tooltip: createTooltip(),
    findNearestPointBy: 'xy',
    states: {
      hover: {
        halo: null,
      },
    },
    events: {
      legendItemClick() {
        info.onDiastolicLegendClick();
        return true;
      },
    },
  },
  {
    name: 'Systolic Blood Pressure',
    data: info.systolicData,
    type: 'line',
    color: '#88c4bd',
    marker: {
      enabled: false,
    },
    tooltip: createTooltip(),
    findNearestPointBy: 'xy',
    states: {
      hover: {
        halo: null,
      },
    },
    events: {
      legendItemClick() {
        info.onSystolicLegendClick();
        return true;
      },
    },
  },
  {
    name: 'Critical',
    data: [],
    color: '#EE6161',
    states: {
      hover: {
        lineWidthPlus: 0,
      },
    },
    lineWidth: 0,
    marker: {
      enabled: true,
      symbol: 'circle',
      radius: 4,
      lineWidth: 0,
      lineColor: null,
    },
    events: {
      legendItemClick() {
        return false;
      },
    },
    tooltip: createTooltip(),
    findNearestPointBy: 'xy',
  },
  {
    name: 'Risk',
    data: [],
    color: '#FFBD04',
    marker: {
      enabled: true,
      symbol: 'circle',
      radius: 4,
      lineWidth: 0,
      lineColor: null,
    },
    states: {
      hover: {
        halo: null,
      },
    },
    events: {
      legendItemClick() {
        return false;
      },
    },
    tooltip: createTooltip(),
    findNearestPointBy: 'xy',
  },
]);

export interface CreateBPPlotBand {
  showSystolicBand: boolean;
  showDiastolicBand: boolean;
}

const createBPPlotBand = ({
  showSystolicBand,
  showDiastolicBand,
}: CreateBPPlotBand) => {
  const bands: PlotBand[] = [];
  if (showDiastolicBand) {
    bands.push({
      from: 60,
      to: 80,
      color: '#f4f6fd',
    });
  }
  if (showSystolicBand) {
    bands.push({
      from: 90,
      to: 130,
      color: '#f0fcfc',
    });
  }
  return bands;
};

export interface CreateBPChartOptions extends CreateBPPlotBand {
  series: ReturnType<typeof createBPChartSeries>;
  fromDate: Dayjs;
  toDate: Dayjs;
  max: number;
}

export const createBPChartOptions = ({
  series,
  fromDate,
  toDate,
  max,
  showSystolicBand,
  showDiastolicBand,
}: CreateBPChartOptions) => ({
  chart: {
    type: 'line',
  },
  title: null,
  plotBackgroundColor: '#0099FF',
  xAxis: {
    type: 'datetime',
    tickWidth: 0,
    tickInterval: 24 * 3600 * 1000,
    min: fromDate.valueOf(),
    max: toDate.valueOf(),
    labels: {
      formatter: (xAxis: { value: Date; }) => (
        TimezoneService.calcDateTimeDayjs(xAxis.value).format(USA_DATE)
      ),
    },
  },
  yAxis: {
    title: 'mmHg',
    gridLineColor: 'transparent',
    min: 0,
    max,
    plotBands: createBPPlotBand({ showDiastolicBand, showSystolicBand }),
  },
  legend: {
    align: 'left',
    verticalAlign: 'top',
  },
  tooltip: {
    crosshairs: true,
    dateTimeLabelFormats: {
      day: '%m/%e/%Y',
    },
  },
  time: {
    useUTC: false,
    timezone: TimezoneService.guessTimezone(),
  },
  credits: {
    enabled: false,
  },
  series,
});
