import {
  AssignDeviceInfo, DeviceModelEnum, DeviceTypeEnum, VitalEnumType
} from '../../../../uc-api-sdk';
import { InputTypeArray } from '../../../Input/types';
import { SupportDeviceEnum } from '../../../device/type';
import { useDeviceCheckboxGroupHelper } from '../../hook/useDeviceCheckboxGroupHelper/useDeviceCheckboxGroupHelper';
import { BPClearDeviceCheckboxComponent } from '../BPClearDeviceCheckboxComponent/BPClearDeviceCheckboxComponent';

export interface ClearDevicesCheckboxGroupComponentProps
  extends Partial<InputTypeArray<AssignDeviceInfo>> {
  vitals: VitalEnumType[];
  supportWifi?: boolean;
  supportHotspot?: boolean;
}

export const ClearDevicesCheckboxGroupComponent = ({
  vitals,
  value,
  onChange,
  supportWifi = true,
  supportHotspot = true,
}: ClearDevicesCheckboxGroupComponentProps) => {
  const {
    handleChange,
    getDevice,
    doesSameDeviceTypeExist,
  } = useDeviceCheckboxGroupHelper(vitals, value, onChange);

  if (!supportWifi && !supportHotspot) {
    return null;
  }
  return (
    <>
      {
        supportWifi
        && (
          <BPClearDeviceCheckboxComponent
            key={DeviceModelEnum.BPM1}
            model={DeviceModelEnum.BPM1}
            value={getDevice(DeviceModelEnum.BPM1)}
            onChange={handleChange(DeviceModelEnum.BPM1)}
            supportDevice={SupportDeviceEnum.WIFI_ROUTER}
            disabled={doesSameDeviceTypeExist(DeviceTypeEnum.BP, DeviceModelEnum.BPM1)}
          />
        )
      }
      {
        supportHotspot
        && (
          <BPClearDeviceCheckboxComponent
            key={DeviceModelEnum.BPM1_HOTSPOT}
            model={DeviceModelEnum.BPM1_HOTSPOT}
            value={getDevice(DeviceModelEnum.BPM1_HOTSPOT)}
            onChange={handleChange(DeviceModelEnum.BPM1_HOTSPOT)}
            supportDevice={SupportDeviceEnum.HOTSPOT}
            disabled={doesSameDeviceTypeExist(DeviceTypeEnum.BP, DeviceModelEnum.BPM1_HOTSPOT)}
          />
        )
      }
    </>
  );
};
