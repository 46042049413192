import './PriorAuthFormItemLayoutComponent.scss';

export interface PriorAuthFormItemLayoutComponentProps {
  children: React.ReactNode;
}

export const PriorAuthFormItemLayoutComponent = ({
  children
}: PriorAuthFormItemLayoutComponentProps) => (
  <div className="prior-auth-form-item">
    {children}
  </div>
);
