import React from 'react';
import { InputType } from '../../../Input/types';
import { YesNoRadioComponent } from '../../../../uiComponent/YesNoRadioComponent/YesNoRadioComponent';

export interface JoinProgramRadioComponentProps extends InputType<boolean> {
}

export const JoinProgramRadioComponent = ({
  value,
  onChange,
}: JoinProgramRadioComponentProps) => (
  <div>
    <p>Will this patient join the program?</p>
    <YesNoRadioComponent
      value={value}
      onChange={onChange}
    />
  </div>
);
