import { LeftOutlined, ReloadOutlined } from '@ant-design/icons';
import { Modal, ModalProps } from 'antd';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import { useModalConfigContext } from '../../../../contexts/ModalConfigContext/ModalConfigContext';

export interface LLMNavigationBarComponentProps {
  onBack?: () => void;
  onBackModalProps?: ModalProps;
  onReprocess?: () => void;
  onReprocessModalProps?: ModalProps;
}

export const LLMNavigationBarComponent = ({
  onBack,
  onBackModalProps = {},
  onReprocess,
  onReprocessModalProps = {},
}: LLMNavigationBarComponentProps) => {
  const { getContainer } = useModalConfigContext();

  const handleGoBack = () => {
    Modal.confirm({
      title: 'Are you sure to go back?',
      content: 'Selections and edits made to the data on this page will not be saved.',
      onOk: onBack,
      okText: 'Yes',
      getContainer,
      wrapClassName: 'absolute',
      ...onBackModalProps,
    });
  };

  const handleReprocess = () => {
    Modal.confirm({
      title: 'Are you sure to Reprocess?',
      content: 'AI will reprocess the uploaded files. Selections and edits made to the data on this page will not be saved.',
      onOk: onReprocess,
      okText: 'Yes',
      getContainer,
      wrapClassName: 'absolute',
      ...onReprocessModalProps,
    });
  };

  return (
    <div className="flex jc-sb">
      <div>
        {
          onBack
          && (
            <LinkButton
              useBlueColor
              onClick={handleGoBack}
              className="mb20"
            >
              <LeftOutlined />
              Back
            </LinkButton>
          )
        }
      </div>
      <div>
        {
          onReprocess
          && (
            <LinkButton
              useBlueColor
              onClick={handleReprocess}
              className="mb20"
            >
              <ReloadOutlined />
              Reprocess
            </LinkButton>
          )
        }
      </div>
    </div>
  );
};
