import { Button } from 'antd';
import { WorklistDrawerComponent } from '../../../worklist/component/WorklistDrawerComponent/WorklistDrawerComponent';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';

export interface OnboardingCardResumeVisitButtonComponentProps {
  patientId: string;
  clinicEventId: string;
}

export const OnboardingCardResumeVisitButtonComponent = ({
  patientId,
  clinicEventId,
}: OnboardingCardResumeVisitButtonComponentProps) => {
  const {
    isOpen,
    open,
    close
  } = useOpen();

  return (
    <>
      <Button
        type="primary"
        onClick={open}
        disabled={!patientId || !clinicEventId}
      >
        Resume
      </Button>
      <WorklistDrawerComponent
        patientId={patientId}
        clinicEventId={clinicEventId}
        isOpen={isOpen}
        onClose={close}
      />
    </>
  );
};
