import { Divider, Space } from 'antd';
import { Dayjs } from 'dayjs';
import { FC, ReactNode } from 'react';
import {
  BehaviorChangeEnum, GenderEnum, GenderIdentityEnum, Nullable, PatientTechLevelEnum, Phone
} from '../../../../uc-api-sdk';
import { DeletePatientModalContainer } from '../../container/DeletePatientContainer/DeletePatientContainer';
import { DemographicBasicInfoComponent } from '../DemographicBasicInfoComponent/DemographicBasicInfoComponent';
import { DemographicCareTeamInfoComponent } from '../DemographicClinicInfoComponent/DemographicClinicInfoComponent';
import { DemographicContactInfoComponent } from '../DemographicContactInfoComponent/DemographicContactInfoComponent';
import { DemographicSocialSupportComponent } from '../DemographicSocialSupportComponent/DemographicSocialSupportComponent';

export interface PatientProfileDemographicsComponentProps {
  memberId: string;
  fullName?: string;
  dob?: Dayjs;
  gender?: Nullable<GenderEnum>;
  genderIdentity?: Nullable<GenderIdentityEnum>;
  height?: number[];
  weight?: string;
  race?: string;
  languages?: string[];
  nickName?: string;
  behavior?: Nullable<BehaviorChangeEnum>;
  techLevel?: Nullable<PatientTechLevelEnum>;
  username?: string;
  clinicName?: ReactNode;
  providerName?: string;
  medicalId?: string;
  assignedToCA?: string;
  assignedToRD?: string;
  mobilePhone?: Phone;
  otp?: boolean;
  smartPhone?: boolean;
  homePhone?: Phone;
  email?: string;
  address?: string;
  socialSupport?: Nullable<string>;
  contactInfo?: Nullable<string>;
  otherProviders?: Nullable<string>;
}

export const PatientProfileDemographicsComponent: FC<PatientProfileDemographicsComponentProps> = ({
  memberId,
  fullName,
  nickName,
  dob,
  gender,
  genderIdentity,
  height,
  weight,
  race,
  languages,
  behavior,
  techLevel,
  username,
  clinicName,
  providerName,
  medicalId,
  assignedToCA,
  assignedToRD,
  mobilePhone,
  otp = false,
  smartPhone = false,
  homePhone,
  email,
  address,
  socialSupport,
  contactInfo,
  otherProviders,
}) => (
  <Space direction="vertical" size={40} className="w100 breakword">
    <DemographicBasicInfoComponent
      fullName={fullName}
      dob={dob}
      gender={gender}
      genderIdentity={genderIdentity}
      height={height}
      weight={weight}
      race={race}
      languages={languages}
      behavior={behavior}
      techLevel={techLevel}
      userName={username}
      memberId={memberId}
      nickName={nickName}
    />
    <DemographicCareTeamInfoComponent
      clinicName={clinicName}
      providerName={providerName}
      medicalId={medicalId}
      assignedToCA={assignedToCA}
      assignedToRD={assignedToRD}
    />
    <DemographicContactInfoComponent
      mobilePhone={mobilePhone}
      otp={otp}
      smartPhone={smartPhone}
      homePhone={homePhone}
      email={email}
      address={address}
    />
    <DemographicSocialSupportComponent
      socialSupport={socialSupport || undefined}
      contactInfo={contactInfo || undefined}
      otherProviders={otherProviders || undefined}
    />
    <div>
      <Divider />
      <DeletePatientModalContainer memberId={memberId} fullName={fullName || ''} />
    </div>
  </Space>
);
