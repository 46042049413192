import { Modal } from 'antd';

import './VitalRemovePrecheckModalComponent.scss';
import { VitalRemovePrecheckContainer, VitalRemovePrecheckContainerProps } from '../../container/VitalRemovePrecheckContainer/VitalRemovePrecheckContainer';

export interface VitalRemovePrecheckModalComponentProps extends VitalRemovePrecheckContainerProps {
  open?: boolean;
}

export const VitalRemovePrecheckModalComponent = ({
  patientId,
  vitalType,
  open,
  onConfirm,
  onCancel,
}: VitalRemovePrecheckModalComponentProps) => (
  <Modal
    open={open}
    onCancel={onCancel}
    footer={null}
    maskClosable={false}
    closable={false}
    width={450}
    destroyOnClose
  >
    <VitalRemovePrecheckContainer
      patientId={patientId}
      vitalType={vitalType}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  </Modal>
);
