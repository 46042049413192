import { useMemo } from 'react';
import { filter, uniq } from 'lodash';
import { DischargeReasonEnumComponent } from '../../../../enumComponent/DischargeReasonEnumComponent/DischargeReasonEnumComponent';
import { DischargeReasonEnum } from '../../../../uc-api-sdk';
import { EnumSelectComponent, EnumSelectComponentProps } from '../../../../uiComponent/EnumSelectComponent/EnumSelectComponent';
import { InputType } from '../../../Input/types';

export interface DischargeReasonSelectComponentProps extends InputType<DischargeReasonEnum> { }

export const DischargeReasonSelectComponent = ({
  value,
  onChange,
}: DischargeReasonSelectComponentProps) => {
  const optionRenderer: EnumSelectComponentProps<DischargeReasonEnum>['optionRenderer'] = (
    option,
  ) => (
    <DischargeReasonEnumComponent value={option.value} />
  );

  const prioritizedReason = useMemo(() => (
    uniq([
      DischargeReasonEnum.DEVICE_ISSUES,
      DischargeReasonEnum.SWITCH_TO_ANOTHER_DEVICE,
      DischargeReasonEnum.DEVICE_UNDERUSED,
      DischargeReasonEnum.TECHNICAL_CHALLENGES_TO_USE,
      DischargeReasonEnum.PROGRAM_OVERWHELMING,
      DischargeReasonEnum.PCP_CHANGE,
      DischargeReasonEnum.INSURANCE_RELATED_ISSUES,
      DischargeReasonEnum.REFUSE_TO_MONITOR_VITALS,
      ...filter(DischargeReasonEnum, (dcEnum) => dcEnum !== DischargeReasonEnum.value)
    ])
  ), []);

  return (
    <EnumSelectComponent
      value={value}
      onChange={onChange}
      options={prioritizedReason}
      optionRenderer={optionRenderer}
    />
  );
};
