import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import { SubTitle } from './SubTitle';
import { PageTitle } from './PageTitle';
import './Title.scss';

export interface TitleProps {
  children: ReactNode;
  noMargin?: boolean;
  className?: string;
  type?: 'default' | 'secondary';
}

interface SubComponents {
  SubTitle: typeof SubTitle;
  PageTitle: typeof PageTitle;
}

export const Title: FC<TitleProps> & SubComponents = ({
  children,
  noMargin = false,
  className = '',
  type = 'default',
}: TitleProps) => (
  <div
    className={
      classNames({
        title: true,
        mb10: !noMargin,
        [type]: true,
        [className]: !!className,
      })
    }
  >
    {children}
  </div>
);

Title.SubTitle = SubTitle;
Title.PageTitle = PageTitle;
