import { useWorklistContext } from '../../../../contexts/WorklistContext/WorklistContext';
import { cloneChildren } from '../../../../lib/layout';

export interface WorklistContentComponentProps {
  children: React.ReactNode;
}

export const WorklistContentComponent = ({
  children,
  ...props
}: WorklistContentComponentProps) => {
  const { clinicEvent } = useWorklistContext();

  return (
    <>
      {
        cloneChildren(children, {
          visitId: clinicEvent?.id,
          ...props,
        })
      }
    </>
  );
};
