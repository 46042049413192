import { Divider } from 'antd';
import { isEmpty } from 'lodash';
import { UcMedicationManagement } from '../../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';

export interface PrintCarePlanMedicationComponentProps {
  medication?: UcMedicationManagement | null;
}

export const PrintCarePlanMedicationComponent = ({
  medication,
}: PrintCarePlanMedicationComponentProps) => {
  if (isEmpty(medication)) {
    return null;
  }
  return (
    <>
      <div className="page-break" />
      <TextComponent className="pop-text">
        Medication Reconciliation and Compliance
      </TextComponent>
      <TextComponent italic color="gray2" className="mb5">
        Patient's updated medication list and compliance status was reviewed.
        Other OTC or nonprescription medications (if any)
        will be listed on patients medication list.
      </TextComponent>
      <DisplayOrEmptyComponent value={medication?.reconciliationCompliance} />
      <Divider />
    </>
  );
};
