import React, { FC } from 'react';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import './FoodLogProviderCommentListComponent.scss';

export interface FoodLogProviderCommentComponentProps {
  commentedBy?: string;
  dateTime: string;
  comment: string | JSX.Element;
}

export const FoodLogProviderCommentComponent: FC<FoodLogProviderCommentComponentProps> = ({
  commentedBy,
  dateTime,
  comment,
}) => (
  <div className="FoodLogProviderCommentComponent">
    <div className="flex jc-sb">
      <div className="b5">
        {commentedBy}
      </div>
      <div className="FoodLogProviderCommentDateTime">
        <DisplayDateComponent value={dateTime} format="DATE_TIME" />
      </div>
    </div>
    <p>
      {comment}
    </p>
  </div>
);
