import {
  ReactNode,
  createContext,
  useContext,
} from 'react';
import { Form } from 'antd';
import { FormOptions } from '../../hooks/useFormHookFactory/useFormHookFactory';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';

export interface FormConnectorContextValue {
  form?: FormOptions['form'];
}

const FormConnectorContext = createContext<
    FormConnectorContextValue | undefined
  >(undefined);

export const useFormConnectorContext = () => {
  const context = useContext(FormConnectorContext);
  return context as FormConnectorContextValue;
};

export interface FormConnectorContextProviderProps {
  children: ReactNode;
  form: FormOptions['form'];
  ignoreWrappingForm?: boolean;
}
// to pass form instance to a form created by formFactory in children without nesting forms
export const FormConnectorContextProvider = ({
  children,
  form,
  ignoreWrappingForm,
}: FormConnectorContextProviderProps) => {
  const wrappingForm = Form.useFormInstance(); // check if any form wrapping the context

  const contextValue = useGetContextValue<FormConnectorContextValue>({
    form: ignoreWrappingForm ? form : (wrappingForm || form),
  }, [ignoreWrappingForm]);

  return (
    <FormConnectorContext.Provider value={contextValue}>
      {children}
    </FormConnectorContext.Provider>
  );
};
