import { useEffect, useState } from 'react';
import { useBillableTimeContext } from '../../contexts/BillableTimeContext/BillableTimeContext';
import { useLoggedInUserFromContext } from '../../contexts/loggedInUserContext';
import EnvConfig from '../../configs/envConfig/envConfig';
import { useAuthGetLoginResponse, useAuthRefreshToken } from '../../uc-api-sdk';
import { ApiRequestHelper } from '../../helpers/ApiRequest';
import { AuthLoginResponse } from '../../features/auth/types';
import EmployeeInfo from '../useUserInfo/employeeInfo';
import { useMixpanelContext } from '../../contexts/MixpanelContext/MixpanelContext';
import useDebounce from '../useDebounce/useDebounce';
import { MixpanelEvents } from '../../contexts/MixpanelContext/MixpanelEvents';

const REFRESH_TOKEN_TIME = EnvConfig.refreshTokenTime;

export const useRefreshSessionToken = () => {
  const [startRefresh, setStartRefresh] = useState(false);
  const { isUserLoggedIn, token, setLoginInfo } = useLoggedInUserFromContext();
  const { isActive, lastIdle } = useBillableTimeContext();
  const { send } = useMixpanelContext();
  const authMeInfo = useAuthGetLoginResponse({ options: { sendOnMount: false, retry: 0 } });
  const refreshTokenInfo = useAuthRefreshToken({ options: { sendOnMount: false, retry: 0 } });

  useEffect(() => {
    if (!isUserLoggedIn) {
      return;
    }
    if (isActive && lastIdle.timeLength > REFRESH_TOKEN_TIME) {
      // user is idle for more than [REFRESH_TOKEN_TIME]
      send({
        event: MixpanelEvents.UserRefreshToken,
        properties: {
          idleTime: lastIdle.timeLength,
        },
      });
      // this is to prevent multiple refresh token request, not sure about the cause yet
      setStartRefresh(true);
    }
  }, [isUserLoggedIn, isActive, lastIdle.timeLength]);

  const handleRefresh = useDebounce(() => {
    ApiRequestHelper.tryCatch(
      authMeInfo.send(),
      {
        success: '',
        error: '',
        onSuccess: (res) => {
          if (res === null) {
            // invalid or expired
            // axios interceptor will handle this
            return;
          }
          refreshTokenInfo.send();
        },
        onError: () => {
          // ignore for now
        }
      }
    );
  }, 5000, [token], { leading: true });

  useEffect(() => {
    if (startRefresh) {
      handleRefresh();
      setStartRefresh(false);
    }
  }, [startRefresh, handleRefresh]);

  useEffect(() => {
    const refreshData = refreshTokenInfo.data || {};
    if (refreshData.code === 200 && refreshData.data) {
      setLoginInfo(refreshData.data as AuthLoginResponse<EmployeeInfo>);
      return;
    }
    if (refreshData.code) {
      // something is wrong when refresh token
      // record event but ignore handling
      send({
        event: MixpanelEvents.UserRefreshToken,
        properties: {
          code: refreshData.code,
          message: refreshData.msg
        },
      });
    }
  }, [refreshTokenInfo]);

  return null;
};
