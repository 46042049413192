import React, { FC, ReactNode } from 'react';
import { Button, Modal, Space } from 'antd';
import { useBoolean } from 'usehooks-ts';
import { ClickableDiv } from '../ClickableDiv/ClickableDiv';

export interface DeleteModalComponentProps {
  text?: ReactNode;
  open?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  children?: ReactNode;
  isLoading?: boolean;
}

export const DeleteModalComponent: FC<DeleteModalComponentProps> = ({
  text,
  open = false,
  onConfirm,
  onCancel,
  children,
  isLoading = false,
}) => {
  const { value: isOpen, setTrue, setFalse } = useBoolean();

  const handleConfirm = async () => {
    await onConfirm?.();
    setFalse();
  };

  const handleCancel = () => {
    onCancel?.();
    setFalse();
  };

  return (
    <>
      <ClickableDiv onClick={setTrue}>
        {children}
      </ClickableDiv>
      <Modal
        onCancel={onCancel}
        open={children ? isOpen : open}
        footer={null}
        title={null}
        width={300}
        closable={false}
        centered
      >
        <>
          <div className="b5 mb10">
            {text}
          </div>
          <Space>
            <Button
              className="b5 p0"
              type="link"
              onClick={handleConfirm}
              danger
              disabled={isLoading}
              loading={isLoading}
            >
              Delete
            </Button>
            <Button
              className="b5 p0"
              type="link"
              onClick={handleCancel}
              disabled={isLoading}
              loading={isLoading}
            >
              Cancel
            </Button>
          </Space>
        </>
      </Modal>
    </>
  );
};
