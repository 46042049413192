import { Image, Typography } from 'antd';
import tooltipRight from '../../../../assets/icons/bp-tooltip-color.jpg';
import tooltipLeft from '../../../../assets/icons/bp-tooltip.svg';

const { Text } = Typography;

export const BPTooltipComponent = () => (
  <div>
    <Text className="ml12 mt12 b5">
      How to Read BP Log Book
    </Text>
    <div className="flex">
      <Image className="ml12 mt20" src={tooltipLeft} preview={false} width={194} />
      <Image className="mt20 mb10 mr20 ml40" src={tooltipRight} preview={false} width={267} />
    </div>
  </div>
);
