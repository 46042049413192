import { Button, Form } from 'antd';
import { forEach } from 'lodash';
import { useState } from 'react';
import {
  PatientTechLevelEnum,
  VitalEnumType,
  AssignDeviceInfo
} from '../../../../uc-api-sdk';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import { FormType } from '../../../Input/types';
import { SubmitValue, useAssignDeviceForm } from '../../../patient/hook/useAssignDeviceForm';
import './AssignDeviceFormComponent.scss';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { EnrollmentAddressFormItemComponent } from '../EnrollmentAddressFormItemComponent/EnrollmentAddressFormItemComponent';
import { useDeepCompareMemo } from '../../../../hooks/useDeepCompareEffect';
import { DeviceHelper } from '../../../device/helper';
import { EnrollmentDeliveryMethodFormItemComponent } from '../EnrollmentDeliveryMethodFormItemComponent/EnrollmentDeliveryMethodFormItemComponent';
import { EnrollmentDeviceSelectionFormItemComponent } from '../EnrollmentDeviceSelectionFormItemComponent/EnrollmentDeviceSelectionFormItemComponent';
import { EnrollmentTechLevelFormItemComponent } from '../EnrollmentTechLevelFormItemComponent/EnrollmentTechLevelFormItemComponent';
import { EnrollmentNotEligibleNoteComponent } from '../EnrollmentNonEligibleNoteComponent/EnrollmentNotEligibleNoteComponent';
import { useIsEnrollmentDeviceEligible } from '../../hook/useIsEnrollmentDeviceEligible/useIsEnrollmentDeviceEligible';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { usePatientMedicalOrganizationContext } from '../../../../contexts/PatientMedicalOrganizationContext/PatientMedicalOrganizationContext';

export interface AssignDeviceFormComponentBaseProps {
  vitals?: VitalEnumType[];
  hasSmartDevice: boolean;
  onTechLevelSelect?: () => void;
  onDeliveryMethodSelect?: () => void;
  onDevicesSelect?: () => void;
}

export interface AssignDeviceFormComponentProps extends
  FormType<SubmitValue>, AssignDeviceFormComponentBaseProps {}

export const AssignDeviceFormComponent = ({
  initialValues,
  onSubmit,
  isLoading,
  vitals = [],
  hasSmartDevice,
  onValuesChange,
  onTechLevelSelect,
  onDeliveryMethodSelect,
  onDevicesSelect
}: AssignDeviceFormComponentProps) => {
  const hook = useAssignDeviceForm();
  const [
    techLevel,
    setTechLevel,
  ] = useState<PatientTechLevelEnum | undefined>(initialValues?.techLevel);
  const {
    isLoading: isLoadingMedicalOrganization,
    medicalOrganization,
  } = usePatientMedicalOrganizationContext();
  const {
    inClinicInventory,
    nonSmartPhoneAlternatives,
  } = medicalOrganization?.serviceConfig || {};
  const isEligibleToEnroll = useIsEnrollmentDeviceEligible({
    vitals,
    hasSmartDevice,
    techLevel,
    nonSmartPhoneAlternatives,
  });
  // specifically check for false, not undefined
  const isNotEligible = isEligibleToEnroll === false;

  const processedInitialValues = useDeepCompareMemo(() => {
    const loanDevice = initialValues?.loanDevice;
    const deviceList = [] as AssignDeviceInfo[];
    forEach(initialValues?.deviceList, (deviceAssignment) => {
      const { devices, vitalType } = deviceAssignment;
      const device = (
        devices?.[0]
        || DeviceHelper.getDefaultDeviceByVitalType(vitalType as VitalEnumType)
      );
      if (device) deviceList.push(device);
    });

    const shippingAddress = {
      address: initialValues?.shippingAddress?.streetName,
      address2: initialValues?.shippingAddress?.unit,
      city: initialValues?.shippingAddress?.city,
      state: initialValues?.shippingAddress?.state,
      zipcode: initialValues?.shippingAddress?.postCode,
    };
    return {
      ...initialValues,
      shippingAddress,
      deviceList,
      loanDevice,
    };
  }, [initialValues]);

  const handleTechLevelSelect = (value: PatientTechLevelEnum) => {
    if ([techLevel, value].includes(PatientTechLevelEnum.LOW)) {
      // reset loanDevice and deviceList when tech level changed to LOW or from LOW
      hook.form.setFieldValue(hook.getName('loanDevice'), undefined);
      hook.form.setFieldValue(hook.getName('deviceList'), []);
    }
    setTechLevel(value);
    onTechLevelSelect?.();
  };

  if (isLoadingMedicalOrganization) {
    return <LoadingOverlayComponent isLoading />;
  }

  return (
    <Form
      className="assignDeviceFormComponent"
      initialValues={processedInitialValues}
      onFinish={hook.handleSubmit(onSubmit)}
      scrollToFirstError
      form={hook.form}
      disabled={isLoading}
      onValuesChange={onValuesChange}
      layout="vertical"
      preserve={false}
    >
      <FixedComponent className="f1">
        <FixedComponent.Child>
          <EnrollmentTechLevelFormItemComponent
            onChange={handleTechLevelSelect}
          />
          {
            isNotEligible
              ? (<EnrollmentNotEligibleNoteComponent />)
              : (
                <>
                  <EnrollmentDeliveryMethodFormItemComponent
                    onChange={onDeliveryMethodSelect}
                    disablePickup={!inClinicInventory}
                  />
                  <FormItem
                    noStyle
                    shouldUpdate={hook.shouldUpdate(['techLevel', 'deliveryMethod'])}
                  >
                    {
                      ({ getFieldValue }) => {
                        const showDevices = hook.shouldShowDevice(getFieldValue);
                        if (!showDevices) return null;
                        return (
                          <>
                            <div>Check on the devices that will be giving out today.</div>
                            <EnrollmentDeviceSelectionFormItemComponent
                              hasSmartDevice={hasSmartDevice}
                              vitals={vitals}
                              onChange={onDevicesSelect}
                              nonSmartPhoneAlternatives={nonSmartPhoneAlternatives}
                            />
                            <EnrollmentAddressFormItemComponent />
                          </>
                        );
                      }
                    }
                  </FormItem>
                </>
              )
          }
        </FixedComponent.Child>
        <FixedComponent.Child isFixed>
          <FormItem
            shouldUpdate={hook.shouldUpdate(['loanDevice', 'deviceList'])}
            hidden={isNotEligible}
          >
            {({ getFieldValue }) => (
              hook.shouldShowConfirmButton(getFieldValue)
                ? (
                  <Button
                    className="mt40"
                    type="primary"
                    htmlType="submit"
                  >
                    Confirm
                  </Button>
                ) : null
            )}
          </FormItem>
        </FixedComponent.Child>
      </FixedComponent>
    </Form>
  );
};
