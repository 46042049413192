import { Button, Card } from 'antd';
import { FC } from 'react';
import { CareNoteCardSettingsComponent } from '../../features/patient/component/CareNoteCardSettingsComponent/CareNoteCardSettingsComponent';
import { StringHelper } from '../../helpers/string';
import { Icons } from '../../icons/Icons';
import { CareNote, CareNoteTagEnum } from '../../uc-api-sdk';
import { CardHeaderComponent } from '../CardHeaderComponent/CardHeaderComponent';
import { CardHeaderExtraComponent } from '../CardHeaderExtraComponent/CardHeaderExtraComponent';

import { CareNoteDurationInfoComponent } from '../CareNoteDurationInfoComponent/CareNoteDurationInfoComponent';
import { TagComponent } from '../TagComponent/TagComponent';
import './CareNotePopupCardComponent.scss';

export interface CareNotePopupCardComponentProps {
  note: CareNote;
  hideCard: () => void;
  timezone?: string;
}

export const CareNotePopupCardComponent: FC<CareNotePopupCardComponentProps> = ({
  note,
  hideCard,
  timezone
}) => (
  <Card className="CareNotePopupCardComponent secondary-br">
    <CardHeaderComponent>
      <div className="flex flex-wrap mr10">
        {note.tags?.filter(t => t !== null).map(
          (tag: string) => (
            tag ? (
              <TagComponent
                type={tag?.toLowerCase() === 'visit' ? 'warning' : 'processing'}
                iconType="none"
                key={tag}
              >
                {StringHelper.camelCasefy(tag)}
              </TagComponent>
            ) : ''
          ),
        )}
      </div>
      <CardHeaderExtraComponent>
        <CareNoteCardSettingsComponent
          note={note}
        />
      </CardHeaderExtraComponent>
    </CardHeaderComponent>
    {note.tags?.includes(CareNoteTagEnum.VISIT)
      && (
      <CareNoteDurationInfoComponent
        timezone={timezone}
        startTime={note.startTime || ''}
        endTime={note.endTime || ''}
      />
      )}
    <p>{note.content}</p>
    <div className="flex jc-e">
      <Button
        type="text"
        onClick={hideCard}
        className="border-b px0 flex gap1 ai-c text-gray-scale-2"
      >
        View Less
        {' '}
        <Icons.UpArrow />
      </Button>
    </div>
  </Card>
);
