import { Button } from 'antd';
import { map } from 'lodash';
import { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { useProviderViewAccessibleClinics } from '../../../patients/hook/useProviderViewAccessibleClinics/useProviderViewAccessibleClinics';
import { ProviderViewTablesSiderContentComponent } from '../../component/ProviderViewTablesSiderContentComponent/ProviderViewTablesSiderContentComponent';
import { PROVIDER_TABLE_TABS } from '../../contants';
import { ClinicCountButtonContainer } from '../ClinicCountButtonContainer/ClinicCountButtonContainer';
import { StorageKeyEnum, useSessionStorage } from '../../../../hooks';
import { getAllPatientsCount, getWatchedPatientCount } from '../../../patients/hook/usePatientsTableSearch/usePatientsTableSearch';
import { getProviderAssignedPatientsCount } from '../../../patients/hook/useProviderViewPatientTableSearch/useProviderViewPatientTableSearch';

export interface ProviderViewTablesSiderContainerProps {
  currentTab: PROVIDER_TABLE_TABS;
  onClick: (t: PROVIDER_TABLE_TABS) => void;
  onClickClinic: (orgId: string) => void;
  lastSelectedOrgId?: string;
}

export const ProviderViewTablesSiderContainer = ({
  currentTab,
  onClick,
  onClickClinic,
  lastSelectedOrgId
}: ProviderViewTablesSiderContainerProps) => {
  const {
    enrolledPtCount,
    unEnrolledPtCount,
    refetch: refetchAssignedPatientCount
  } = getProviderAssignedPatientsCount();
  const {
    watchedPtCount,
    refetch: refetchWatchedPatientCount
  } = getWatchedPatientCount();
  const {
    request: {
      data,
      refetch: refetchClinics,
    } = {}
  } = useProviderViewAccessibleClinics();
  const clinics = data?.data?.content || [];
  const {
    patientInClinicResults,
    refetch: refetchClinicResults
  } = getAllPatientsCount();

  const clinicCounts = useMemo(() => {
    const clinicCountObj = {} as Record<string, number>;
    map(patientInClinicResults, (clinicResult) => {
      const { clinicId, count } = clinicResult || {};
      if (clinicId) {
        clinicCountObj[clinicId] = count || 0;
      }
    });
    return clinicCountObj;
  }, [patientInClinicResults]);

  useUpdateListener('PATIENT_CREATE', () => {
    refetchAssignedPatientCount?.();
    refetchWatchedPatientCount();
    refetchClinics?.();
    refetchClinicResults();
  });
  useUpdateListener('WATCH_LIST_UPDATED', () => {
    refetchWatchedPatientCount();
  });

  const handleClinicClick = (type: PROVIDER_TABLE_TABS, orgId: string) => {
    onClick(type);
    onClickClinic(orgId);
  };

  const tabs1 = [
    {
      label: 'My enrolled patients',
      count: enrolledPtCount || 0,
      key: PROVIDER_TABLE_TABS.ENROLLED_PATIENTS,
      type: PROVIDER_TABLE_TABS.ENROLLED_PATIENTS,
    },
    {
      label: 'My Unenrolled patients',
      count: unEnrolledPtCount || 0,
      key: PROVIDER_TABLE_TABS.UNENROLLED_PATIENTS,
      type: PROVIDER_TABLE_TABS.UNENROLLED_PATIENTS,
    },
    {
      label: 'Patients watch list',
      count: watchedPtCount || 0,
      key: PROVIDER_TABLE_TABS.WATCHLIST,
      type: PROVIDER_TABLE_TABS.WATCHLIST,
    },
  ];

  const [, setTableName] = useSessionStorage<string | undefined>(
    StorageKeyEnum.PATIENT_TABLE_NAME
  );

  const tabs2 = map(clinics, (clinic) => (
    <ClinicCountButtonContainer
      key={clinic.id}
      clinic={clinic}
      lastSelectedOrgId={lastSelectedOrgId}
      currentTab={currentTab}
      handleClinicClick={handleClinicClick}
      count={clinicCounts[clinic.id as string]}
    />
  ));

  useEffect(() => {
    let label = '';
    map(tabs1, (tab) => {
      if (tab.key === currentTab) {
        label = tab.label;
      }
    });
    setTableName(label);
  }, []);

  const tabMenu1 = tabs1.map((tab) => (
    <Button
      key={tab.key}
      className={classNames({
        'w100 ta-l mt10': true,
        active: tab.key === currentTab,
      })}
      type="text"
      size="middle"
      onClick={() => {
        setTableName(tab.label);
        onClick(tab.key);
      }}
    >
      {`${tab.label} (${tab.count || 0})`}
    </Button>
  ));

  return (
    <ProviderViewTablesSiderContentComponent
      tabMenu1={tabMenu1}
      tabMenu2={tabs2}
    />
  );
};
