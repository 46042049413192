/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Divider } from 'antd';
import { CreateVisitContainer } from '../../../visit/container/CreateVisitContainer/CreateVisitContainer';
import { CalendarGoogleFullEventComponent } from '../CalendarGoogleFullEventComponent/CalendarGoogleFullEventComponent';
import { PopoverContentRendererProps } from '../BigCalendarEventComponent/BigCalendarEventComponent';
import { Icons } from '../../../../icons/Icons';
import { VisitViewContainer } from '../../container/VisitViewContainer/VisitViewContainer';
import { EventViewContainer } from '../../container/EventViewContainer/EventViewContainer';
import { CalendarSourceEnum, ClinicEvent } from '../../../../uc-api-sdk';
import { useFetchComponentContext } from '../../../../contexts/FetchComponentContext/FetchComponentContext';
import { NEW_EVENT_ID } from '../../constant';
import { useCreateVisitContext } from '../../context/CreateVisitContext/CreateVisitContext';
import { DiscardUnsavedChangesModalComponent } from '../../../../uiComponent/DiscardUnsavedChangesModalComponent/DiscardUnsavedChangesModalComponent';

import './CalendarPopoverContentComponent.scss';

export interface CalendarPopoverContentComponentProps
  extends PopoverContentRendererProps<ClinicEvent> {

}

export const CalendarPopoverContentComponent = ({
  onClose,
  ...props
}: CalendarPopoverContentComponentProps) => {
  const calendarContainerContext = useFetchComponentContext();
  const calendarContext = useCreateVisitContext();

  const refetch = () => {
    calendarContainerContext?.refetch();
  };

  const handleSubmit = () => {
    onClose();
    refetch?.();
  };

  const handleOpenEditMode = () => {
    calendarContext?.setTempEventAsSelectedEvent(true);
  };

  const handleCloseEditMode = () => {
    calendarContext?.setTempEventAsSelectedEvent(false);
  };

  const handleCloseEdit = () => {
    handleCloseEditMode();
    handleSubmit();
  };

  const renderCloseButton = (title: string) => (
    <>
      <div className="flex jc-sb px20 pb10 ai-c">
        <h3 className="m0">{title}</h3>
        <DiscardUnsavedChangesModalComponent
          showDiscard={calendarContext?.isFieldsTouched}
          onConfirm={onClose}
        >
          <Icons.CloseIcon />
        </DiscardUnsavedChangesModalComponent>
      </div>
      <Divider className="mt0" />
    </>
  );

  const renderContent = () => {
    if (props?.id === NEW_EVENT_ID) {
      return (
        <>
          {renderCloseButton('Create Visit')}
          <CreateVisitContainer
            disabledFields={props.disabledFields}
            onClose={handleSubmit}
          />
        </>
      );
    }
    if (calendarContext?.selectedEvent?.isEdit) {
      return (
        <>
          {renderCloseButton('Edit Visit')}
          <CreateVisitContainer
            visit={props.info}
            isEdit
            onClose={handleCloseEdit}
          />
        </>
      );
    }
    if (props?.info?.visitType) {
      return (
        <VisitViewContainer
          visit={props}
          onDelete={handleSubmit}
          onEdit={handleOpenEditMode}
          onClose={onClose}
        />
      );
    }
    if (props?.info?.calendarSource === CalendarSourceEnum.OTHER) {
      return (
        <EventViewContainer
          onClose={onClose}
          onDelete={handleSubmit}
          onEdit={handleOpenEditMode}
          visit={props}
        />
      );
    }
    return (
      <CalendarGoogleFullEventComponent
        onClose={onClose}
        {...props}
      />
    );
  };

  return (
    <div className="calendar-pop-over-content">
      {renderContent()}
    </div>
  );
};
