import { AverageValue } from '../../../uc-api-sdk';
import { UpDownTrend } from '../../../uiComponent/UpDownTrendComponent/UpDownTrendComponent';

export const useAverageTrend = <T extends AverageValue>(
  averageValue: (T | undefined)[],
  comparator?: (latest?: T, prev?: T) => boolean | null,
) => {
  const [latestAvg, prevAvg] = averageValue || [];

  const getTrend = (_comparator = comparator) => {
    if (!_comparator) {
      console.warn('Invalid comparator');
      return null;
    }
    const res = _comparator?.(latestAvg, prevAvg);
    if (res === null) return null;
    return res ? UpDownTrend.UP : UpDownTrend.DOWN;
  };

  return {
    getTrend,
    latestAvg,
  };
};
