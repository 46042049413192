import { Divider, Drawer } from 'antd';
import { ReactNode } from 'react';
import classNames from 'classnames';
import { VitalsMonitoringScheduleFormComponent, VitalsMonitoringScheduleFormComponentProps } from '../VitalsMonitoringScheduleFormComponent/VitalsMonitoringScheduleFormComponent';
import { VitalEnumType } from '../../../../uc-api-sdk';
import CancelSubmitButtonsComponent from '../../../../uiComponent/CancelSubmitButtonsComponent/CancelSubmitButtonsComponent';

import './SingleVitalMonitoringScheduleDrawerComponent.scss';
import { MediumDrawerWidth } from '../../../../constants/drawer';
import { Title } from '../../../../uiComponent/Title/Title';

export interface SingleVitalMonitoringScheduleDrawerComponentProps {
  vitalType: VitalEnumType;
  open?: boolean;
  onClose?: () => void;
  title?: ReactNode;
  className?: string;
  onSubmit?: VitalsMonitoringScheduleFormComponentProps['onSubmit'];
  autoSetDefault?: boolean;
}

export const SingleVitalMonitoringScheduleDrawerComponent = ({
  vitalType,
  open,
  onClose,
  title = 'Review Vitals Monitoring Goal',
  className = '',
  onSubmit,
  autoSetDefault,
}: SingleVitalMonitoringScheduleDrawerComponentProps) => {
  // stretch out to fit BG schedule table
  const width = vitalType === VitalEnumType.BG ? 600 : MediumDrawerWidth;
  const handleSubmit: VitalsMonitoringScheduleFormComponentProps['onSubmit'] = (
    values
  ) => {
    onSubmit?.(values);
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={(
        <Title noMargin>
          {title}
        </Title>
      )}
      width={width}
      className={classNames({
        'single-vital-schedule-drawer': true,
        [className]: !!className
      })}
      headerStyle={{ borderBottom: 'none' }}
      bodyStyle={{ paddingTop: 0 }}
      destroyOnClose
    >
      <VitalsMonitoringScheduleFormComponent
        currentVitals={[vitalType]}
        autoSetDefault={autoSetDefault}
        initialValues={{
          [vitalType]: {},
        }}
        onSubmit={handleSubmit}
        allowRemoveVital={false}
        formButtons={(
          <div className="mb20">
            <Divider />
            <CancelSubmitButtonsComponent
              submitText="Add Vitals"
              onCancel={onClose}
              className="flex gap2 jc-e"
            />
          </div>
        )}
      />
    </Drawer>
  );
};
