import { isEmpty } from 'lodash';
import { DiseaseHistoryItem } from '../../../../../uc-api-sdk';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';
import { PrintDiseaseHistoryComponent } from '../../../../vitals/component/PrintVitalComponent/component/PrintDiseaseHistoryComponent/PrintDiseaseHistoryComponent';

export interface PrintCarePlanDiseaseHistoryComponentProps {
  diseaseHistory?: DiseaseHistoryItem[] | null;
}

export const PrintCarePlanDiseaseHistoryComponent = ({
  diseaseHistory,
}: PrintCarePlanDiseaseHistoryComponentProps) => {
  if (isEmpty(diseaseHistory)) {
    return null;
  }
  return (
    <>
      <div className="page-break" />
      <TextComponent className="pop-text">
        Disease History
      </TextComponent>
      <PrintDiseaseHistoryComponent
        diseaseHistoryList={diseaseHistory || []}
      />
    </>
  );
};
