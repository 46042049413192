import React, { FC, ReactNode } from 'react';
import './OverlayComponent.scss';
import classNames from 'classnames';

export interface OverlayComponentProps {
  children: ReactNode;
  showOverlay?: boolean;
  overlay: ReactNode;
}

export const OverlayComponent: FC<OverlayComponentProps> = ({
  children,
  showOverlay = false,
  overlay,
}) => (
  <div
    className={classNames({
      'overlay-container': true,
      'show-overlay': showOverlay,
    })}
  >
    <div className="child-component">{children}</div>
    {showOverlay && (
      <div className="overlay">
        <div className="overlay-content">
          {overlay}
        </div>
      </div>
    )}
  </div>
);
