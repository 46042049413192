import { ReactNode } from 'react';
import { Typography } from 'antd';
import { Icon } from '../../../../icons/Icon';
import { MANUAL_INPUT } from '../../../../assets/commonIconInfo';

const { Text } = Typography;

export interface DeviceManualInputItemComponentProps {
  extra?: ReactNode;
}

export const DeviceManualInputItemComponent = ({
  extra,
}: DeviceManualInputItemComponentProps) => (
  <div className="flex gap5 ai-c">
    <Icon info={MANUAL_INPUT} size={{ width: 82, height: 66 }} />
    <div className="flex fd-c">
      <div className="flex gap2">
        <Text className="b6">
          Manual Input
        </Text>
      </div>
      <Text className="b1">
        Patient will enter vital results in App manually.
      </Text>
      {extra}
    </div>
  </div>
);
