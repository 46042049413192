import { PatientProfileTabEnum, usePatientPageControllerContext } from '../../patient/context/PatientPageControllerContext/PatientPageControllerContext';

export const useOutstandingFoodLog = () => {
  const {
    onTabChange,
  } = usePatientPageControllerContext() || {};

  const handleFocusFoodLog = () => {
    if (!onTabChange) {
      console.warn('Patient page context is not found');
      return;
    }
    onTabChange(PatientProfileTabEnum.Intervention, 'foodlog');
  };

  return {
    handleFocusFoodLog,
  };
};
