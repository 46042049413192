import React, { useState } from 'react';
import { LabTemplateSelectComponent } from './LabTemplateSelectComponent';
import {
  LabResultTemplate,
  Sort$Direction,
  TemplatesSearchParams,
  useTemplatesSearch,
} from '../../../../uc-api-sdk';
import { SelectType } from '../../../Input/types';
import { CreateLabType } from '../../../transcribing/component/TranscribeLabResultFormItemComponent/TranscribeLabResultFormItemComponent';
import { useDebounce } from '../../../../hooks/useDebounce/useDebounce';
import { useScrollPagination } from '../../../../hooks/useScrollPagination/useScrollPagination';

export interface LabTemplateContainerProps extends Omit<SelectType<string>, 'onSelect'> {
  onSelect:(i?: CreateLabType) => void;
  disabled: boolean;
}

export const LabTemplateContainer = ({
  onSelect,
  disabled,
  value,
  onChange,
}: LabTemplateContainerProps) => {
  const [searchTerm, setSearchTerm] = useState('');

  const getSearchParams = (page = 1): TemplatesSearchParams => ({
    searchRequest: {
      filter: {
        templateNameNear: searchTerm,
      },
      pageInfo: {
        page,
        size: 10,
        sort: [
          {
            property: 'isCommon',
            direction: Sort$Direction.DESC,
          }
        ],
      },
    },
  });

  const info = useTemplatesSearch({
    options: {
      sendOnMount: true,
    },
    params: getSearchParams(),
  });

  const {
    allData,
    handleOnScroll,
  } = useScrollPagination({
    requestInfo: info,
    fetchParams: getSearchParams,
  });

  const handleSearch = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const labResultList: CreateLabType[] | undefined = (allData as LabResultTemplate[])?.map(item => {
    const value: CreateLabType = {
      id: item.id || '',
      name: item.templateName || '',
      isCommon: item.isCommon || false,
      info: item.items ?? [],
      templateId: item.templateId ?? '',
      templateName: item.templateName ?? '',
      updatedAt: item.updatedAt ?? '',
    };
    return value;
  });

  const handleChange = (value?: string) => {
    const selectedItem = labResultList?.find(item => item?.id === value);
    onSelect(selectedItem);
    onChange?.(value);
  };

  const debounceHandleSearch = useDebounce(handleSearch);

  return (
    <LabTemplateSelectComponent
      allowClear
      data={labResultList || []}
      onSearch={debounceHandleSearch}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      onPopupScroll={handleOnScroll}
    />
  );
};
