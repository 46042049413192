import { ReactNode } from 'react';
import { DeviceModelEnum } from '../../../../uc-api-sdk';
import { CardInfoTextComponent } from '../../../../uiComponent/CardTypographyComponent/CardInfoTextComponent/CardInfoTextComponent';
import { CardTextComponent } from '../../../../uiComponent/CardTypographyComponent/CardTextComponent/CardTextComponent';
import { DeviceInfoComponent } from '../DeviceInfoComponent/DeviceInfoComponent';

export interface AppDeviceInfoComponentProps {
  deviceId?: string;
  deviceModel?: DeviceModelEnum;
  className?: string;
  boxShadow?: boolean;
  children?: ReactNode;
}

export const AppDeviceInfoComponent = ({
  deviceId,
  deviceModel,
  className = '',
  boxShadow = true,
  children,
}: AppDeviceInfoComponentProps) => {
  if (!deviceModel) {
    return <div>No device model found</div>;
  }
  return (
    <DeviceInfoComponent
      deviceModel={deviceModel}
      className={className}
      boxShadow={boxShadow}
    >
      {
        deviceId
        && (
          <div className="flex">
            <CardInfoTextComponent size="small">
              Mac Address:
            </CardInfoTextComponent>
            <CardTextComponent size="small">
              {deviceId}
            </CardTextComponent>
          </div>
        )
      }
      {children}
    </DeviceInfoComponent>
  );
};
