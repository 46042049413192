import { useMemo } from 'react';
import {
  GetOptions, MakeRequestHookConfig, UseRequestFactory, useRequestFactory,
} from '../../../uc-api-sdk/staticFiles/useReqHook';
import { chsServices } from '../CHSServices';
import { NotificationGroupParams, NotificationGroupResponseData } from '../types';

export const useGetNotificationChannelGroup = <RCN = undefined>(
  configs?: MakeRequestHookConfig<NotificationGroupParams, GetOptions, RCN>,
) => {
  const baseConfigs: UseRequestFactory<
    NotificationGroupParams,
    NotificationGroupResponseData,
    typeof chsServices.getNotificationChannelGroup,
    GetOptions,
    NotificationGroupResponseData
  > = useMemo(() => ({
    reqFunc: (...args: Parameters<typeof chsServices.getNotificationChannelGroup>) => (
      chsServices.getNotificationChannelGroup(...args)
    ),
    ResponseClassCreator: chsServices.getResponse,
    typeName: 'useGetNotificationChannelGroup',
  }), []);

  return useRequestFactory(baseConfigs, configs);
};
