import { Button, Checkbox, Col } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import React, { FC, useState } from 'react';
import { VitalType } from '../../../helper/VitalHelper';
import { VitalsEnumTypeComponent } from '../../../../../enumComponent/VitalsEnumTypeComponent/VitalsEnumTypeComponent';
import { VitalEnumType } from '../../../../../uc-api-sdk';

export interface VitalsSelectionComponentProps {
  excludedVitals: VitalType[];
  onChange?: (measurements: VitalType[]) => void;
  onCancel?: () => void;
}

export const VitalsSelectionComponent: FC<VitalsSelectionComponentProps> = ({
  excludedVitals,
  onChange,
  onCancel,
}) => {
  const [selectedVitals, setSelectedVitals] = useState<VitalType[]>([]);

  const handleClick = () => {
    onChange?.(selectedVitals);
    onCancel?.();
  };

  const handleChange: CheckboxGroupProps['onChange'] = (v) => {
    setSelectedVitals(v as VitalType[]);
  };

  const renderCheckbox = (type: VitalEnumType) => (
    !excludedVitals.includes(type) && (
      <Col>
        <Checkbox value={type}>
          <VitalsEnumTypeComponent value={type} />
        </Checkbox>
      </Col>
    )
  );

  return (
    <div>
      <div>
        <Checkbox.Group onChange={handleChange}>
          {renderCheckbox(VitalEnumType.BP)}
          {renderCheckbox(VitalEnumType.BG)}
          {renderCheckbox(VitalEnumType.HS)}
          {renderCheckbox(VitalEnumType.TM)}
          {renderCheckbox(VitalEnumType.PO)}
        </Checkbox.Group>
      </div>
      <div className="mt10">
        <Button onClick={handleClick}>Add Vitals</Button>
        <Button type="text" onClick={onCancel}>
          <span className="underline">Cancel</span>
        </Button>
      </div>
    </div>
  );
};
