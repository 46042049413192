import { BloodGlucose, BloodPressure } from '../../uc-api-sdk';

interface Measurement {
  value?: number | null;
  unit?: string | null;
}

export class MeasureUnitHelper {
  static defaultBPUnit = 'mm/Hg';

  static defaultBGUnit = 'mg/dL';

  private static getValue = (
    measurement?: Measurement | null,
  ) => {
    const { value } = measurement || {};
    return value;
  };

  static getBP = (
    bp?: BloodPressure | null,
  ) => this.getValue(bp);

  static getBG = (
    bg?: BloodGlucose | null,
  ) => this.getValue(bg);
}
