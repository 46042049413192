import {
  Col, Row, Skeleton, Space, Typography,
} from 'antd';
import { Link } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import React, { FC } from 'react';
import { Avatar } from '../../../../uiComponent/Avatar/Avatar';
import { ComplexityTag } from '../../../complexity/component/ComplexityTag/ComplexityTag';
import { PatientVitalListComponent } from '../../../patient/component/PatientVitalListComponent/PatientVitalListComponent';
import {
  GenderEnum,
  LastMeasurementResponse,
  PatientComplexityEnum,
  ProgramCategoryEnum,
  VitalEnumType,
} from '../../../../uc-api-sdk';
import { BirthdayComponent } from '../../../../uiComponent/BirthdayComponent/BirthdayComponent';
import { ProgramCategoriesComponent } from '../../../patient/component/ProgramCategoriesComponent/ProgramCategoriesComponent';
import useChangePage from '../../../../hooks/useChangePage/useChangePage';

import './PatientInfoComponent.scss';
import { GenderEnumComponent } from '../../../../enumComponent/GenderEnumComponent/GenderEnumComponent';

const { Text } = Typography;

export interface PatientInfoComponentProps {
  isLoading?: boolean;
  id: string;
  vitals?: VitalEnumType[];
  fullName?: string;
  gender?: GenderEnum;
  dob?: Dayjs;
  complexity?: PatientComplexityEnum;
  provider?: string;
  programCategories?: ProgramCategoryEnum[];
  lastMeasurementDate?: LastMeasurementResponse[];
}

export const PatientInfoComponent: FC<PatientInfoComponentProps> = ({
  isLoading,
  id,
  fullName,
  gender,
  dob,
  complexity,
  provider,
  programCategories = [],
  vitals,
  lastMeasurementDate,
}) => {
  const { createCarePortalPatientUrl } = useChangePage();

  const renderPatientInfo = () => (
    <div className="patient-info-component">
      <div className="flex ai-c gap2">
        <Space>
          <Avatar name={fullName} />
          <div>
            <div>
              <Space wrap className="patient-info-component__name-complexity">
                <Link to={createCarePortalPatientUrl({ patientId: id })}>{fullName}</Link>
                {complexity && <ComplexityTag level={complexity} />}
              </Space>
            </div>
            <Text type="secondary">
              <GenderEnumComponent value={gender} type="short" />
              {', '}
              <BirthdayComponent value={dob} />
            </Text>
          </div>
        </Space>
      </div>
      <Row>
        <Col span={10}>
          <Text type="secondary">
            Enroll program
          </Text>
        </Col>
        <Col>
          <Text>
            <ProgramCategoriesComponent value={programCategories} />
          </Text>
          <Text className="mx5">|</Text>
          <PatientVitalListComponent value={vitals} lastMeasurementDate={lastMeasurementDate} />
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <Text type="secondary">
            Assigned provider
          </Text>
        </Col>
        <Col>
          {provider}
        </Col>
      </Row>
    </div>
  );

  return (
    <div className="border default-br p20 flex fd-c gap3">
      {isLoading ? <Skeleton active /> : renderPatientInfo()}
    </div>
  );
};
