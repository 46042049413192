import dayjs from 'dayjs';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import {
  CalendarSourceEnum, ClinicEvent, useClinicEventInsert,
  VisitMethodEnum,
  VisitTypeEnum
} from '../../../../uc-api-sdk';
import { FormType } from '../../../Input/types';
import { SubmitValue } from '../../component/CreateVisitFormComponent/CreateVisitFormComponent';
import { StartNowCreateVisitFormComponent, StartNowCreateVisitFormComponentProps } from '../../component/StartNowCreateVisitFormComponent/StartNowCreateVisitFormComponent';
import { useCreateVisitForm } from '../../hook/useCreateVisitForm';
import { useGetParticipantWithCurrentUser } from '../../hook/useGetParticipantWithCurrentUser';
import { useCheckInVisit } from '../../hook/useCheckInVisit';
import { ClinicEventsService } from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';

export interface StartNowCreateVisitFormContainerProps
  extends FormType<SubmitValue>,
  StartNowCreateVisitFormComponentProps {
  patientId: string;
  defaultVisitMethod?: VisitMethodEnum;
  visitType?: VisitTypeEnum;
  onCheckedIn?: (clinicEventId: string) => void;
  onCreatedVisit?: (clinicEventId: string) => void;
}

export const StartNowCreateVisitFormContainer = ({
  patientId,
  defaultVisitMethod = VisitMethodEnum.IN_CLINIC,
  visitType,
  excludeParticipantIds,
  getEndTime,
  shouldDisableSubmit,
  onCheckedIn,
  onCreatedVisit,
  onCancel,
}: StartNowCreateVisitFormContainerProps) => {
  const createVisitInfo = useClinicEventInsert({});
  const createVisitHook = useUpdate('VISIT_CREATED');
  const {
    parseParticipants,
  } = useCreateVisitForm();
  const assignedParticipants = useGetParticipantWithCurrentUser({
    visitType
  });
  const {
    handleCheckIn,
  } = useCheckInVisit();

  const handleOnCreateVisitSuccess = (clinicEventService: ClinicEventsService) => {
    createVisitHook.updateValue();
    // check in visit
    handleCheckIn(clinicEventService, {
      onCheckedIn: () => onCheckedIn?.(clinicEventService.id || '')
    });
  };

  const handleSubmit: StartNowCreateVisitFormComponentProps['onSubmit'] = (
    values,
  ) => {
    const startTime = dayjs();
    ApiRequestHelper.tryCatch(
      createVisitInfo.send({
        params: {
          document: {
            patientId,
            calendarSource: CalendarSourceEnum.UC_VISIT,
            visitType,
            visitMethod: values.visitMethod,
            visitParticipants: parseParticipants(values),
            startTime: startTime.toISOString().replace('Z', ''),
            endTime: getEndTime(startTime).toISOString().replace('Z', ''),
          },
        },
      }),
      {
        success: '',
        error: '',
        onSuccess: (res) => {
          const clinicEventService = new ClinicEventsService(res?.data as ClinicEvent);
          handleOnCreateVisitSuccess(clinicEventService);
          onCreatedVisit?.(clinicEventService.id || '');
        }
      }
    );
  };

  return (
    <StartNowCreateVisitFormComponent
      excludeParticipantIds={excludeParticipantIds}
      getEndTime={getEndTime}
      shouldDisableSubmit={shouldDisableSubmit}
      initialValues={{
        visitMethod: defaultVisitMethod,
        ...assignedParticipants,
      }}
      onSubmit={handleSubmit}
      onCancel={onCancel}
    />
  );
};
