import { Checkbox, CheckboxProps } from 'antd';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { VisitTypeEnum } from '../../../../uc-api-sdk';
import { ClinicEventsService } from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { OnboardingCardDescriptionComponent } from './OnboardingCardDescriptionComponent';
import { OnboardingCardResumeVisitButtonComponent } from './OnboardingCardResumeVisitButtonComponent';
import { OnboardingCardComponent } from './OnboardingCardComponent';

export interface TechOnboardingCardComponentProps {
  patientInfo: PatientInfo;
  onWaiveChange?: (value: boolean) => void;
}

const DESCRIPTION = 'Help patient set up the App, teach patient use devices and take measurements.';

export const TechOnboardingCardComponent = ({
  patientInfo,
  onWaiveChange,
}: TechOnboardingCardComponentProps) => {
  const { send: sendMixpanel } = useMixpanelContext();
  const isWaived = patientInfo.enrollmentInfo?.waiveTechOnboarding;
  const techOnboardingVisitEvent = patientInfo.enrollmentInfo?.techOnboardingVisitEvent
    ? new ClinicEventsService(patientInfo.enrollmentInfo?.techOnboardingVisitEvent)
    : {} as ClinicEventsService;
  const {
    id,
    startTime,
    isCheckedIn,
    isCompleted,
  } = techOnboardingVisitEvent;

  const handleOnWaiveChange: CheckboxProps['onChange'] = (e) => {
    const isWaived = !!e.target.checked;
    onWaiveChange?.(isWaived);
    sendMixpanel({ event: MixpanelEvents.WaiveOnboarding });
  };

  const renderActions = () => {
    if (isCheckedIn && !isCompleted) {
      return (
        <OnboardingCardResumeVisitButtonComponent
          patientId={patientInfo.id}
          clinicEventId={id || ''}
        />
      );
    }

    if (!id) {
      return (
        <Checkbox
          checked={!!isWaived}
          onChange={handleOnWaiveChange}
          disabled={!!startTime}
        >
          Waive Tech onboarding
        </Checkbox>
      );
    }

    return null;
  };

  const renderDescription = () => (
    <OnboardingCardDescriptionComponent
      description={DESCRIPTION}
    >
      {renderActions()}
    </OnboardingCardDescriptionComponent>
  );

  return (
    <OnboardingCardComponent
      title="Tech-onboarding"
      description={renderDescription()}
      patientInfo={patientInfo}
      clinicEvent={techOnboardingVisitEvent}
      visitType={VisitTypeEnum.TECH_ONBOARDING}
      showButtons={!isWaived}
      mixpanelEvent={MixpanelEvents.CreateOnboardingVisitDrawerOpen}
    />
  );
};
