import React, { FC } from 'react';
import { useBGResultToSummaryTable } from '../../hook/useBGResultToSummaryTable/useBGResultToSummaryTable';
import { BGSummaryTableComponent } from './BGSummaryTableComponent';
import { BgStat } from '../../../../uc-api-sdk';

export interface BGSummaryTableDataProcessorComponentProps {
  data: BgStat;
}

export const BGSummaryTableDataProcessorComponent: FC<
  BGSummaryTableDataProcessorComponentProps
> = ({
  data,
}) => {
  const processedData = useBGResultToSummaryTable(data);
  const totalCount = data.all?.count || 0;

  return (
    <BGSummaryTableComponent data={processedData} totalCount={totalCount} />
  );
};
