import { Employee } from '../../../../types/user';
import { AssigneeSelectComponent } from '../../../../uiComponent/AssigneeSelectComponent/AssigneeSelectComponent';
import { AssigneeSelectContainer, AssigneeSelectContainerProps } from '../../../../uiContainer/AssigneeSelectContainer/AssigneeSelectContainer';

export interface EnrollmentAssignedCaRdSelectComponentProps {
  id?: string;
  value?: string[];
  onChange?: (value: string[]) => void;
  roles?: AssigneeSelectContainerProps['roles'];
  ucOrgIds?: AssigneeSelectContainerProps['ucOrgIds'];
  clinicIds?: AssigneeSelectContainerProps['clinicIds'];
  onSubmit?: (assignees?: Employee[]) => Promise<void>;
  autoSubmit?: boolean;
  isLoading?: boolean;
  patientId?: string;
  needToFilter?: boolean;
}

export const EnrollmentAssignedCaRdSelectComponent = ({
  id,
  value,
  onChange,
  roles,
  ucOrgIds,
  clinicIds,
  onSubmit,
  autoSubmit,
  isLoading,
  patientId,
  needToFilter,
}: EnrollmentAssignedCaRdSelectComponentProps) => {
  const handleOnChange = (
    values: unknown,
  ) => {
    onChange?.(values as string[]);
  };

  const handleAutoSubmit = (
    selectedAssignees?: Employee[],
  ) => {
    if (autoSubmit) {
      onSubmit?.(selectedAssignees);
    }
  };

  const handleGetAssignees = (assignees?: Employee | Employee[]) => {
    if (!assignees) return;
    let newAssignees = [];
    if (Array.isArray(assignees)) newAssignees = assignees;
    else newAssignees = [assignees];
    handleAutoSubmit(newAssignees);
  };

  return (
    <AssigneeSelectContainer
      roles={roles}
      ucOrgIds={ucOrgIds}
      clinicIds={clinicIds}
      internalEmployeeForPatientId={patientId}
      needToFilter={needToFilter}
    >
      {
        ({ assigneeDataList, isLoading: isLoadingAssigneeData }) => (
          <AssigneeSelectComponent
            id={id}
            value={value}
            onChange={handleOnChange}
            assigneeDataList={assigneeDataList}
            loading={isLoading || isLoadingAssigneeData}
            getData={handleGetAssignees}
            showSearch
          />
        )
      }
    </AssigneeSelectContainer>
  );
};
