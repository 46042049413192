import { filter, map, sum } from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { FC, useMemo } from 'react';
import { createBGChartSeries } from './BGLineChartHelper';
import { BGChartMeasurement, createBGChartOptions } from './BGChartHelper';
import { BGChartHelper } from '../../helper/BGChartHelper';
import { FromDateToDateDayjs, Point } from '../../type';
import { findMaxWithStep, getYValue } from '../../helper/chartHelper';
import { useBGThresholdPlotBand } from '../../hook/useBGThresholdPlotBand/useBGThresholdPlotBand';

export interface BGLineChartComponentProps extends FromDateToDateDayjs {
  data: BGChartMeasurement[][];
}

export const BGLineChartComponent: FC<BGLineChartComponentProps> = ({
  data,
  fromDate,
  toDate,
}) => {
  const {
    normalRanges,
    showAfterMeal,
    showBeforeMeal,
    showBreakfastMeal,
    showOverNightMeal,
    toggleAfterMeal,
    toggleBeforeMeal,
    toggleBreakfastMeal,
    toggleOverNightMeal,
  } = useBGThresholdPlotBand();
  const getAverage = (measurements: BGChartMeasurement[]) => (
    +(sum(map(measurements, (v) => v.bloodGlucose)) / measurements.length).toFixed(2)
  );

  const getData = (
    filterMeasurements: ((v: BGChartMeasurement[]) => BGChartMeasurement[]),
  ) => {
    const values = map(data, (curr) => {
      const date = curr[0].date.clone().startOf('day').valueOf();
      const filteredArr = filterMeasurements(curr);
      if (filteredArr.length === 0) {
        return undefined;
      }
      return {
        x: date,
        y: getAverage(filteredArr),
      };
    });
    return filter(values, (v) => v) as Point[];
  };

  const options = useMemo(() => {
    const averageBeforeMeal = getData(BGChartHelper.getBeforeMeal);
    const averageAfterMeal = getData(BGChartHelper.getAfterMeal);
    const averageBeforeBreakfast = getData(BGChartHelper.getBeforeBreakfast);
    const averageOvernightBedtime = getData(BGChartHelper.getOvernightBedtime);
    const series = createBGChartSeries({
      averageBeforeMeal,
      averageAfterMeal,
      averageBeforeBreakfast,
      averageOvernightBedtime,
      onAverageBeforeBreakfastClick: toggleBreakfastMeal,
      onAverageOvernightBedtimeClick: toggleOverNightMeal,
      onAverageBeforeMealClick: toggleBeforeMeal,
      onAverageAfterMealClick: toggleAfterMeal,
    });
    const max = findMaxWithStep(
      [
        ...averageBeforeMeal,
        ...averageAfterMeal,
        ...averageBeforeBreakfast,
        ...averageOvernightBedtime,
      ].map(getYValue),
      50,
      600,
    );
    return createBGChartOptions({
      series,
      fromDate,
      toDate,
      max,
      showAfterMeal,
      showBeforeMeal: showBeforeMeal || showBreakfastMeal || showOverNightMeal,
      beforeMealRange: normalRanges.beforeMealNormalRange,
      afterMealRange: normalRanges.afterMealNormalRange,
    });
  }, [
    data,
    fromDate,
    toDate,
    showBreakfastMeal,
    showOverNightMeal,
    showAfterMeal,
    showBeforeMeal,
    normalRanges,
  ]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
};
