import { NamePath } from 'antd/lib/form/interface';
import FormItem, { FormItemProps } from '../../../../uiComponent/FormItem/FormItem';
import { FreqSelectComponent } from '../../../../uiComponent/FreqSelectComponent/FreqSelectComponent';
import { MeasureFrequencyInputComponent } from '../../../../uiComponent/MeasureFrequencyComponent/MeasureFrequencyComponent';

import './DiseaseHistoryMeasureFrequencyFormItemComponent.scss';
import { FrequencyUnitEnum } from '../../../../uc-api-sdk';

export interface DiseaseHistoryMeasureFrequencyFormItemComponentProps extends FormItemProps {
  measureFrequencyUnitName?: NamePath;
}

export const DiseaseHistoryMeasureFrequencyFormItemComponent = ({
  measureFrequencyUnitName,
  name,
  ...props
}: DiseaseHistoryMeasureFrequencyFormItemComponentProps) => (
  <FormItem
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    name={name}
  >
    <MeasureFrequencyInputComponent
      className="disease-history-measure-frequency"
      addonAfter={(
        <FormItem
          name={measureFrequencyUnitName}
          initialValue={FrequencyUnitEnum.WEEKLY}
        >
          <FreqSelectComponent />
        </FormItem>
      )}
    />
  </FormItem>
);
