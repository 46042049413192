import { map } from 'lodash';
import { FC, ReactNode } from 'react';
import { MeasurementTagsComponent } from '../../../../uiComponent/MeasurementTagsComponent/MeasurementTagsComponent';
import { LogbookSingleInfoComponent } from './LogbookSingleInfoComponent';

export interface LogbookInfoComponentProps {
  hadActivity: boolean;
  hadInsulin: boolean;
  hadMedicine: boolean;
  moodTags: string[];
  patientNote: string;
  others?: Array<{ title: string, content: ReactNode }>;
  children?: ReactNode;
}

export const LogbookInfoComponent: FC<LogbookInfoComponentProps> = ({
  hadActivity,
  hadInsulin,
  hadMedicine,
  moodTags = [],
  patientNote = '',
  others = [],
  children
}) => {
  if (children) {
    return <div>{children}</div>;
  }
  return (
    <div>
      <LogbookSingleInfoComponent
        title="Tags"
        content={(
          <MeasurementTagsComponent
            hadActivity={hadActivity}
            hadInsulin={hadInsulin}
            hadMedicine={hadMedicine}
          />
        )}
      />
      <LogbookSingleInfoComponent
        title="Mood and Symptoms"
        content={moodTags?.join(', ')}
      />
      <LogbookSingleInfoComponent
        title="Patient Notes (Measurement)"
        content={patientNote}
        emptyMessage="No Comment"
      />
      {map(others, (v, k) => (
        <LogbookSingleInfoComponent key={k} title={v.title} content={v.content} />
      ))}
    </div>
  );
};
