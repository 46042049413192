import { ChronicDiseaseEnum, TreatmentTypeEnum, VitalEnumType } from '../../../../uc-api-sdk';
import { TreatmentCheckboxGroup, TreatmentCheckboxGroupProps } from '../../../../uiComponent/TreatmentCheckboxGroup/TreatmentCheckboxGroup';
import { DiseaseHistory } from '../../helper';

export interface DiseaseHistoryTreatmentCheckboxGroupComponentProps
  extends TreatmentCheckboxGroupProps {
  disease?: ChronicDiseaseEnum | null;
}

export const DiseaseHistoryTreatmentCheckboxGroupComponent = ({
  disease,
  value,
  onChange,
}: DiseaseHistoryTreatmentCheckboxGroupComponentProps) => {
  const getTreatment = () => {
    const associatedVital = DiseaseHistory.getVitalFromChronicDisease(disease);
    switch (associatedVital) {
      case VitalEnumType.BP:
        return [
          TreatmentTypeEnum.DIET,
          TreatmentTypeEnum.EXERCISE,
          TreatmentTypeEnum.ORAL_MEDICATION,
          TreatmentTypeEnum.HERBAL_MEDICATION,
          TreatmentTypeEnum.NO_TREATMENT,
        ];
      case VitalEnumType.BG:
        return [
          TreatmentTypeEnum.DIET,
          TreatmentTypeEnum.EXERCISE,
          TreatmentTypeEnum.ORAL_MEDICATION,
          TreatmentTypeEnum.INSULIN,
          TreatmentTypeEnum.HERBAL_MEDICATION,
          TreatmentTypeEnum.NO_TREATMENT,
        ];
      case VitalEnumType.PO:
        return [
          TreatmentTypeEnum.DIET,
          TreatmentTypeEnum.EXERCISE,
          TreatmentTypeEnum.OXYGEN_THERAPY,
          TreatmentTypeEnum.ORAL_MEDICATION,
          TreatmentTypeEnum.HERBAL_MEDICATION,
          TreatmentTypeEnum.NO_TREATMENT,
        ];
      default:
        return [];
    }
  };

  return (
    <TreatmentCheckboxGroup
      options={getTreatment()}
      value={value}
      onChange={onChange}
    />
  );
};
