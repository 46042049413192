import { ButtonType } from 'antd/lib/button';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { ExtendableDrawerButtonComponent } from '../../../../uiComponent/ExtendableDrawerComponent/component/ExtendableDrawerButtonComponent';

export interface LabResultEditButtonComponentProp {
  content?: 'Edit' | 'View Result';
  btnType?: ButtonType;
  patientId?: string;
  onClick: () => void;
}
export const LabResultEditButtonComponent = ({
  content = 'Edit',
  btnType = 'default',
  patientId,
  onClick,
}: LabResultEditButtonComponentProp) => {
  const { send } = useBillableTimeInterventionComp();

  const handleOnClick = () => {
    if (content === 'View Result') {
      send({
        patientId,
        component: BillableTimeInterventionComponentEnum.Transcribing,
        detail:
          // eslint-disable-next-line max-len
          BillableTimeInterventionDetailEnum.TranscribingVisitTranscribeLabResultReviewLabResultReview,
      });
    }
    onClick();
  };

  return (
    <ExtendableDrawerButtonComponent
      buttonText={content}
      buttonProps={{
        type: btnType,
      }}
      onClick={handleOnClick}
    />
  );
};
