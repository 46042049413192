import { ButtonProps } from 'antd';
import { ReactNode } from 'react';
import ModalButton from '../ModalButton/ModalButton';

export interface CommonChronicDiagnosesModalComponentProps {
  button?: ReactNode;
  buttonType?: ButtonProps['type'];
}

export const CommonChronicDiagnosesModalComponent = ({
  button = 'See common chronic diagnoses',
  buttonType = 'link',
}: CommonChronicDiagnosesModalComponentProps) => (
  <ModalButton
    button={button}
    buttonType={buttonType}
  >
    [Empty]
  </ModalButton>
);
