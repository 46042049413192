import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Space } from 'antd';
import React, { FC } from 'react';
import EmptyComponent from '../EmptyComponent/EmptyComponent';

export interface YesNoComponentProps {
  yes?: boolean | null;
  showIcon?: boolean;
  showText?: boolean;
  showEmptyWhenNil?: boolean
}

export const YesNoComponent: FC<YesNoComponentProps> = ({
  yes,
  showIcon = true,
  showText = true,
  showEmptyWhenNil = true,
}) => {
  const renderIcon = () => (
    <div>
      {yes
        ? <CheckCircleFilled className="success-svg" />
        : <CloseCircleFilled className="danger-svg" />}
    </div>
  );

  const renderText = () => (
    (showEmptyWhenNil && typeof yes !== 'boolean')
      ? <EmptyComponent />
      : (
        <div>
          {yes ? 'Yes' : 'No'}
        </div>
      )
  );

  return (
    <Space>
      {showIcon && renderIcon()}
      {showText && renderText()}
    </Space>
  );
};

export default YesNoComponent;
