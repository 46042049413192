import { useState } from 'react';
import { ClinicEventsService } from '../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { makeUnsavedNotesKey, useUnsavedNotes } from '../../../hooks/useUnsavedNotes/useUnsavedNotes';

export type WorklistRequiredField = 'VISIT_SUMMARY';

type WorklistErrors<E extends string> = {
  [field in E | WorklistRequiredField]?: boolean;
}

export const getWorklistUnsavedNoteKey = (clinicEventId?: string) => (
  makeUnsavedNotesKey('worklist', clinicEventId || '')
);

export const useWorklistActions = <E extends string>(
  clinicEvent: ClinicEventsService,
) => {
  const {
    getUnsavedNotes,
    setUnsavedNotes,
  } = useUnsavedNotes(getWorklistUnsavedNoteKey(clinicEvent.id));
  const [
    visitSummary,
    setVisitSummary,
  ] = useState<string | undefined>(clinicEvent.visitSummary ?? getUnsavedNotes());
  const [
    errors,
    setErrors,
  ] = useState<WorklistErrors<E | WorklistRequiredField>>({});

  const isVisitSummaryChanged = (
    visitSummary !== undefined
    && visitSummary !== clinicEvent.visitSummary
  );

  const handleSetError = (
    key: E | WorklistRequiredField,
    value: boolean,
  ) => {
    setErrors((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSetVisitSummary = (value?: string) => {
    handleSetError('VISIT_SUMMARY', false);
    setVisitSummary(value);
    setUnsavedNotes(value);
  };

  const validateVisitSummary = () => {
    if (!visitSummary) {
      handleSetError('VISIT_SUMMARY', true);
      return false;
    }
    return true;
  };

  return {
    visitSummary,
    isVisitSummaryChanged,
    setVisitSummary: handleSetVisitSummary,
    errors,
    handleSetError,
    validateVisitSummary,
  };
};
