import React from 'react';
import classNames from 'classnames';
import { SearchOutlined } from '@ant-design/icons';
import './FilterSearchIconComponent.scss';

interface FilterSearchIconComponentProps {
  isFiltered: boolean;
}

export const FilterSearchIconComponent = ({
  isFiltered,
}: FilterSearchIconComponentProps) => (
  <SearchOutlined
    className={classNames({
      'filter-search-icon-component': true,
      'is-active': isFiltered,
    })}
  />
);
