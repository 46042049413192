import { useMedicationContext } from '../../../../contexts/MedicationContext/MedicationContext';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { DefaultExtendableDrawers } from '../../../../uiComponent/ExtendableDrawerComponent/controller/DefaultExtendableDrawers';
import { ExtendableDrawerComponent } from '../../../../uiComponent/ExtendableDrawerComponent/ExtendableDrawerComponent';
import { Title } from '../../../../uiComponent/Title/Title';
import { AddMedicationContainer } from '../../container/AddMedicationContainer';
import { MedicationType } from '../../type/type';
import './AddMedicationDrawerComponent.scss';
import { ExtendableDrawerButtonComponent, ExtendableDrawerButtonComponentProps } from '../../../../uiComponent/ExtendableDrawerComponent/component/ExtendableDrawerButtonComponent';

const DEFAULT_DRAWER_ID = DefaultExtendableDrawers.addMedication;
export interface AddMedicationDrawerComponentProps {
  title?: string;
  disabled?: boolean;
  patientId?: string;
  onClickAddMedication?: () => void;
  children?: ExtendableDrawerButtonComponentProps['trigger'];
}
export const AddMedicationDrawerComponent = ({
  title = 'Add Medication',
  disabled,
  patientId,
  onClickAddMedication,
  children,
}: AddMedicationDrawerComponentProps) => {
  const {
    value: open,
    setTrue: setOpen,
    setFalse: setClose,
  } = useBoolean(false);
  const { send: sendMixpanel } = useMixpanelContext();
  const { onInsert } = useMedicationContext();
  const handleAddMedicationSubmit = (value: MedicationType) => {
    onInsert?.(value);
  };

  const handleOnClickAddMedication = () => {
    onClickAddMedication?.();
    sendMixpanel({ event: MixpanelEvents.AddMedicationDrawerOpen });
    setOpen();
  };

  return (
    <>
      <ExtendableDrawerButtonComponent
        buttonText={title}
        buttonProps={{
          type: 'primary',
          ghost: true,
          disabled,
        }}
        onClick={handleOnClickAddMedication}
        trigger={children}
      />
      <ExtendableDrawerComponent
        id={DEFAULT_DRAWER_ID}
        open={open}
        onClose={setClose}
        className="drawerComponent"
        footer={null}
        placement="right"
        width={520}
        maskClosable={false}
      >
        <Title>
          {title}
        </Title>
        <AddMedicationContainer
          onSubmit={handleAddMedicationSubmit}
          close={setClose}
          patientId={patientId}
        />
      </ExtendableDrawerComponent>
    </>
  );
};
