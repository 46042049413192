import { Drawer } from 'antd';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';

import { ModalConfigContextProvider } from '../../../../contexts/ModalConfigContext/ModalConfigContext';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { Title } from '../../../../uiComponent/Title/Title';
import { PatientProfileContainer } from '../../../patient/container/PatientProfileContainer/PatientProfileContainer';
import { OrderSuppliesReplacementFormContainer } from '../../container/OrderSuppliesReplacementFormContainer/OrderSuppliesReplacementFormContainer';
import './OrderSuppliesReplacementDrawerButtonComponent.scss';

const getModalContainer = () => (
  document.querySelector('.order-device-supplies-drawer .ant-drawer-content-wrapper') || document.body
) as HTMLDivElement;

export interface OrderSuppliesReplacementDrawerButtonComponentProps {
  patientId: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

export const OrderSuppliesReplacementDrawerButtonComponent = ({
  patientId,
  children = 'Order Supplies or Replacements',
  onClick,
}: OrderSuppliesReplacementDrawerButtonComponentProps) => {
  const {
    isOpen,
    open,
    close,
  } = useOpen();

  const handleOnClick = () => {
    open();
    onClick?.();
  };

  const renderTitle = () => (
    <div className="order-device-supplies-drawer-title">
      <Title>
        Order Supplies or Replacements
      </Title>
      <TextComponent>
        Based on the vital enrollment and monitoring method,
        here are the available options for ordering.
      </TextComponent>
      <TextComponent>
        Kindly review and choose the item you will distribute today.
      </TextComponent>
    </div>
  );

  return (
    <>
      <LinkButton
        onClick={handleOnClick}
        underlineOnHover
        useBlueColor
      >
        {children}
      </LinkButton>
      <Drawer
        open={isOpen}
        onClose={close}
        width={930}
        className="order-device-supplies-drawer"
        title={renderTitle()}
        destroyOnClose
        maskClosable={false}
        keyboard={false}
      >
        <ModalConfigContextProvider
          getContainer={getModalContainer}
        >
          <PatientProfileContainer
            patientId={patientId}
            getEnrolledProgram
            getDevices
          >
            {
              (patientInfo) => {
                if (
                  patientInfo === undefined
                  || !patientInfo.patientInfoService
                ) {
                  return null;
                }

                return (
                  <OrderSuppliesReplacementFormContainer
                    patientInfo={patientInfo}
                    onSubmit={close}
                    onCancel={close}
                  />
                );
              }
            }
          </PatientProfileContainer>
        </ModalConfigContextProvider>
      </Drawer>
    </>
  );
};
