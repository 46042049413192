import { orderBy, uniqueId } from 'lodash';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useLoggedInUserFromContext } from '../../../contexts/loggedInUserContext';
import { ClinicEvent } from '../../../uc-api-sdk';
import { CalendarEvent } from '../component/BigCalendarComponent/BigCalendarComponent';
import TimezoneService from '../../../helpers/timezone/timezoneService';
import { UserColorService } from '../helper/UserColorService/UserColorService';
import { BigCalendarHelper } from '../helper/BigCalendarHelper/BigCalendarHelper';

export interface CalendarEventProcessorOptions {
  isClinicView?: boolean;
}

export const useCalendarEventProcessor = (
  events: ClinicEvent[] | null | undefined,
  options?: CalendarEventProcessorOptions,
) => {
  const { userId } = useLoggedInUserFromContext();

  const processCalendarEvent = (calendarEvent: ClinicEvent) => {
    const ownerId = calendarEvent.owner?.employeeId as string;
    const memberId = userId === ownerId ? 'current' : ownerId;
    let start = TimezoneService.calcDateTimeDayjs(calendarEvent.startTime as string);
    let end = TimezoneService.calcDateTimeDayjs(calendarEvent.endTime as string);
    let isAllDayEvent = false;

    // All-day and multi-day events are from beginning of the startTime to beginning of the endTime
    // So, we need to subtract 1 hour from the endTime to make it end of the day
    if (BigCalendarHelper.checkIsMultiDayEvent(
      calendarEvent?.startTime ?? undefined,
      calendarEvent?.endTime ?? undefined
    )) {
      start = dayjs(calendarEvent.startTime).startOf('day');
      end = dayjs(calendarEvent.endTime).add(-1, 'day').endOf('day');
      isAllDayEvent = true;
    }

    const response: CalendarEvent<ClinicEvent> = {
      id: calendarEvent.calendarEventId || uniqueId('BUSY_'),
      uId: uniqueId(),
      title: calendarEvent.summary || '(No title)',
      start: start.toDate(),
      end: end.toDate(),
      colors: UserColorService.getColor(
        memberId,
        calendarEvent.calendarSource ?? undefined,
        options?.isClinicView,
      ),
      description: calendarEvent.description ?? '',
      allDay: isAllDayEvent,
      info: calendarEvent,
    };
    return response;
  };

  const processedEvents = useMemo(() => (
    orderBy(events || [], (v) => v.owner?.employeeId === userId, 'desc')
      .map(processCalendarEvent)
  ), [events, options?.isClinicView]);

  return {
    processedEvents,
  };
};
