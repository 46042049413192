import { map } from 'lodash';
import { VisitParticipant } from '../../../../uc-api-sdk';
import { CalendarParticipantRoleComponent } from '../../../calendar/component/CalendarParticipantListComponent/CalendarParticipantRoleComponent';
import { ListComponent } from '../../../../uiComponent/ListComponent/ListComponent';
import { DisplayUserNameComponent } from '../../../user/component/DisplayUserNameComponent/DisplayUserNameComponent';

export interface DisplayParticipantsComponentProps{
  participants: VisitParticipant[] | null | undefined;
  isOtherEvent?: boolean;
}

export const DisplayParticipantsComponent = ({
  participants,
  isOtherEvent,
}: DisplayParticipantsComponentProps) => {
  const participantsList = map(participants, (participant) => (
    <span>
      <DisplayUserNameComponent value={participant.participantInfo} />
      {!isOtherEvent && (
      <>
        (
        <CalendarParticipantRoleComponent value={participant.role} />
        )
      </>
      )}
    </span>
  ));

  return (
    <ListComponent
      value={participantsList}
      splitter=", "
      renderItem={(v) => v}
    />
  );
};
