import { Button } from 'antd';
import { FC, ReactNode } from 'react';
import useChangePage from '../../../../hooks/useChangePage/useChangePage';
import './TicketPatientNameComponent.scss';

export interface TicketPatientNameComponentProps {
  memberId?: string;
  patientName?: string;
  content?: ReactNode;
}

export const TicketPatientNameComponent: FC<TicketPatientNameComponentProps> = ({
  memberId,
  patientName,
  content,
}) => {
  const {
    goToCarePortalPatient,
  } = useChangePage();

  const goToPatientProfile = () => {
    if (memberId) {
      goToCarePortalPatient({ patientId: memberId });
    }
  };

  return (
    <div>
      <Button
        type="text"
        className="clickable-patient-name-btn"
        onClick={(e) => {
          e.stopPropagation();
          goToPatientProfile();
        }}
      >
        {patientName}
      </Button>
      <div>{content}</div>
    </div>
  );
};
