import React, { useMemo } from 'react';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  CloseOutlined,
  CheckOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Tag } from 'antd';
import { TagProps } from 'antd/es/tag';
import classNames from 'classnames';
import './TagComponent.scss';

export type TagType = 'success' | 'error' | 'warning' | 'processing' | 'default' | 'done' | 'info';
export type IconType = 'none' | 'default' | 'solidDot' | 'halfSolid' | 'noFilled';
export type BackgroundType = 'none' | 'default' | 'oval';
export type IconSize = 'small' | 'middle';

interface TagComponentProps extends TagProps {
  type?: TagType;
  iconType?: IconType;
  iconColor?: string;
  background?: BackgroundType;
  className?: string;
  children?: React.ReactNode;
  iconSize?: IconSize;
}

export const TagComponent = ({
  type = 'default',
  iconType = 'default',
  background = 'default',
  iconColor, // iconColor used to change icon color
  children,
  className = '',
  iconSize = 'small',
  ...props
}: TagComponentProps) => {
  const getDefaultIcon = () => {
    const iconClass = iconColor ? `${iconColor}-halfFilled` : '';
    switch (type) {
      case 'success':
        return <CheckCircleOutlined className={iconClass} />;
      case 'error':
        return <CloseCircleOutlined className={iconClass} />;
      case 'warning':
        return <ExclamationCircleOutlined className={iconClass} />;
      case 'info':
        return <InfoCircleOutlined className={iconClass} />;
      case 'processing':
      default: return undefined;
    }
  };

  // used to get an icon with a semi-transparent background fill
  const getHalfSolidIcon = () => {
    const iconClass = `${type}-halfSolid`;
    const iconClassColor = iconColor ? `${iconColor}-halfSolid` : '';
    switch (type) {
      case 'success':
        return (
          <CheckCircleOutlined
            className={classNames({
              [iconClass]: true,
              [iconClassColor]: true
            })}
          />
        );
      case 'error':
        return (
          <CloseCircleOutlined
            className={classNames({
              [iconClass]: true,
              [iconClassColor]: true
            })}
          />
        );
      case 'warning':
        return (
          <ExclamationCircleOutlined
            className={classNames({
              [iconClass]: true,
              [iconClassColor]: true
            })}
          />
        );
      case 'info':
        return (
          <InfoCircleOutlined
            className={classNames({
              [iconClass]: true,
              [iconClassColor]: true
            })}
          />
        );
      case 'processing':
      default: return undefined;
    }
  };

  const getSolidDotIcon = (additionalClass = '') => (
    <span
      className={classNames({
        'solid-dot': true,
        [`solid-dot-${type}`]: true,
        [additionalClass]: additionalClass,
        [`solid-dot-${iconSize}`]: true,
      })}
    />
  );

  const getNoFilledIcon = () => {
    const iconClass = 'tag-noFilled-icon';
    switch (type) {
      case 'success':
        return <CheckOutlined className={iconClass} />;
      case 'error':
        return <CloseOutlined className={iconClass} />;
      case 'default':
        return getSolidDotIcon(iconClass);
      default: return undefined;
    }
  };

  const getIcon = () => {
    switch (iconType) {
      case 'default':
        return getDefaultIcon();
      case 'solidDot':
        return getSolidDotIcon();
      case 'halfSolid':
        return getHalfSolidIcon();
      case 'noFilled':
        return getNoFilledIcon();
      default:
        return undefined;
    }
  };

  const info = useMemo(() => ({
    icon: getIcon(),
    color: type,
  }), [type, iconType, iconColor]);

  return (
    <Tag
      className={classNames({
        tag: true,
        [`tag-${type}`]: true,
        [`bg-${background}`]: true,
        [iconColor ? `${iconColor}-halfFilled` : '']: iconColor,
        [className]: !!className,
      })}
      icon={info.icon}
      color={info.color}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <span className={classNames({
        'tag-text': iconType === 'solidDot' || iconType === 'halfSolid',
        'tag-text-color': !!iconColor,
      })}
      >
        {children}
      </span>
    </Tag>
  );
};
