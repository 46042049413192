import { useCallback } from 'react';
import { useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import { FormOptions } from '../../../../hooks/useFormHookFactory/useFormHookFactory';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { PriorAuthApprovalFormItemComponent } from '../PriorAuthApprovalFormItemComponent/PriorAuthApprovalFormItemComponent';
import { PriorAuthDeniedFormItemComponent } from '../PriorAuthDeniedFormItemComponent/PriorAuthDeniedFormItemComponent';
import { PriorAuthStatusSelectComponent } from '../PriorAuthStatusSelectComponent/PriorAuthStatusSelectComponent';
import { PriorAuthSubmissionDateFormItemComponent } from '../PriorAuthSubmissionDateFormItemComponent/PriorAuthSubmissionDateFormItemComponent';

import './PriorAuthCardFormItemComponent.scss';
import { PriorAuthSubmissionNoteComponent } from '../PriorAuthLayout/PriorAuthSubmissionNoteComponent';
import { PriorAuthCaseStatusEnum } from '../../../../uc-api-sdk';
import { PriorAuthViewCardComponent } from '../PriorAuthLayout/PriorAuthViewCardComponent';
import { CPTCodeFormItemComponent } from '../CPTCodeFormItemComponent/CPTCodeFormItemComponent';
import { PriorAuthCommentFormItemComponent } from '../PriorAuthCommentFormItemComponent/PriorAuthCommentFormItemComponent';

export interface PriorAuthCardFormItemComponentProps {
  form?: FormOptions['form'];
}

export const PriorAuthCardFormItemComponent = ({
  form,
}: PriorAuthCardFormItemComponentProps) => {
  const insuranceForm = useInsuranceForm({ form });

  const renderFormItem = useCallback((
    status: PriorAuthCaseStatusEnum,
  ) => {
    switch (status) {
      case PriorAuthCaseStatusEnum.NEED_SUBMISSION:
        return (
          <>
            <CPTCodeFormItemComponent />
            <PriorAuthCommentFormItemComponent />
            <PriorAuthSubmissionNoteComponent alwaysShow />
          </>
        );
      case PriorAuthCaseStatusEnum.PENDING_RESULT:
        return (
          <>
            <PriorAuthSubmissionDateFormItemComponent />
            <CPTCodeFormItemComponent />
            <PriorAuthCommentFormItemComponent />
          </>
        );
      case PriorAuthCaseStatusEnum.APPROVED:
        return (
          <>
            <PriorAuthApprovalFormItemComponent />
            <CPTCodeFormItemComponent />
            <PriorAuthCommentFormItemComponent />
          </>
        );
      case PriorAuthCaseStatusEnum.DENIED:
        return (
          <>
            <PriorAuthDeniedFormItemComponent />
            <PriorAuthCommentFormItemComponent />
          </>
        );
      default:
        return null;
    }
  }, []);

  return (
    <div className="prior-auth-card-form white-card">
      <FormItem
        noStyle
        shouldUpdate={insuranceForm.shouldUpdate(['priorAuthActiveCase'])}
      >
        {
          ({ getFieldValue }) => {
            const shouldHide = insuranceForm.doesHaveReadOnlyActiveCase(getFieldValue);
            const isDisabled = insuranceForm.shouldDisablePriorAuthStatus(getFieldValue);
            return (
              <FormItem
                info={insuranceForm.getInfo('priorAuthStatus')}
                required
                hidden={shouldHide}
              >
                <PriorAuthStatusSelectComponent
                  disabled={isDisabled}
                />
              </FormItem>
            );
          }
        }
      </FormItem>
      <FormItem
        noStyle
        shouldUpdate={insuranceForm.shouldUpdate(['priorAuthStatus', 'priorAuthActiveCase'])}
      >
        {
          ({ getFieldValue }) => {
            const priorAuthStatus = insuranceForm.getValue('priorAuthStatus', getFieldValue);
            const priorAuthActiveCase = insuranceForm.getValue('priorAuthActiveCase', getFieldValue);
            if (
              priorAuthActiveCase
              && ([
                PriorAuthCaseStatusEnum.APPROVED,
                PriorAuthCaseStatusEnum.DENIED
              ].includes(priorAuthActiveCase?.status || '' as PriorAuthCaseStatusEnum))
            ) {
              return (
                <PriorAuthViewCardComponent
                  priorAuth={priorAuthActiveCase}
                />
              );
            }
            return renderFormItem(priorAuthStatus);
          }
        }
      </FormItem>
    </div>
  );
};
