import { Select, SelectProps } from 'antd';
import { BehaviorChangeEnum } from '../../../uc-api-sdk';
import { PatientBehaviorChangeEnumComponent } from '../PatientBehaviorChangeEnumComponent/PatientBehaviorChangeEnumComponent';

export interface BehaviorChangeProps extends Omit<SelectProps, 'options'> { }

export const BehaviorChangeOptions = Object.keys(BehaviorChangeEnum).map(
  (e) => ({
    value: e as BehaviorChangeEnum,
    label: <PatientBehaviorChangeEnumComponent value={e as BehaviorChangeEnum} />,
  }),
);

export const PatientBehaviorChangeInputComponent = (props: BehaviorChangeProps) => (
  <Select
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    options={BehaviorChangeOptions}
  />
);
