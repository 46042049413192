import { Button, Form, Input } from 'antd';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { FormType } from '../../../Input/types';
import { useDischargeForm, SubmitValue } from '../../hook/useDischargeForm';
import { DischargeReasonSelectComponent } from '../DischargeReasonSelectComponent/DischargeReasonSelectComponent';
import { AssignDeviceInfo } from '../../../../uc-api-sdk';
import { WarningBoxComponent } from '../../../../uiComponent/WarningBoxComponent/WarningBoxComponent';

import './DischargeFormComponent.scss';
import { DischargeDevicesInfoComponent } from '../DischargeDevicesInfoComponent/DischargeDevicesInfoComponent';

export interface DischargeFormComponentProps extends FormType<SubmitValue> {
  disabled?: boolean;
  toBeRemovedDevices?: AssignDeviceInfo[];
}

export const DischargeFormComponent = ({
  onCancel,
  onSubmit,
  onValuesChange,
  disabled,
  toBeRemovedDevices,
}: DischargeFormComponentProps) => {
  const dischargeForm = useDischargeForm();

  return (
    <Form
      form={dischargeForm.form}
      disabled={disabled || dischargeForm.formDisabled}
      onFinish={dischargeForm.handleSubmitAndReset(onSubmit)}
      onValuesChange={onValuesChange}
      layout="vertical"
      className="discharge-form"
    >
      <FormItem
        info={dischargeForm.getInfo('validation')}
        rules={dischargeForm.getInfo('validation').rules}
      >
        <Input
          onPaste={(e) => e.preventDefault()}
        />
      </FormItem>
      <FormItem
        info={dischargeForm.getInfo('dischargeReason')}
        required
      >
        <DischargeReasonSelectComponent />
      </FormItem>
      <FormItem
        info={dischargeForm.getInfo('note')}
        required
      >
        <Input.TextArea
          placeholder={dischargeForm.getInfo('note').placeholder}
          autoSize={{ minRows: 3 }}
        />
      </FormItem>
      {
        !!toBeRemovedDevices?.length
        && (
          <WarningBoxComponent className="block mb20">
            <span>This patient's device is</span>
            {' '}
            <DischargeDevicesInfoComponent
              dischargeDevices={toBeRemovedDevices}
            />
            {'. '}
            <span>
              After discharge, the device will be automatically unassigned.
              Kindly inform the patient to return the device accordingly after discharge.
            </span>
          </WarningBoxComponent>
        )
      }
      <div className="flex ai-c jc-e mt20">
        <Button
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          danger
        >
          Discharge
        </Button>
      </div>
    </Form>
  );
};
