import { useEffect } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { OtpSwitchComponent, OtpSwitchComponentProps } from '../../features/patient/component/OtpSwitchComponent/OtpSwitchComponent';
import { ApiRequestHelper } from '../../helpers/ApiRequest';
import useBoolean from '../../hooks/useBoolean/useBoolean';
import useDebounce from '../../hooks/useDebounce/useDebounce';
import { PhoneTypeEnum, usePatientCheckPhoneNumberExist } from '../../uc-api-sdk';
import { usePhoneExistWarningModal } from '../../uiHooks/usePhoneExistWarningModal/usePhoneExistWarningModal';

export interface OTPSwitchContainerProps extends OtpSwitchComponentProps {
  phone?: string;
  initialPhone?: string;
  parentContainer?: string;
}

export const OTPSwitchContainer = ({
  phone,
  parentContainer,
  initialPhone,
  ...props
}: OTPSwitchContainerProps) => {
  const { value: numberIsValid, setTrue, setFalse } = useBoolean(true);
  const checkPhoneNumber = usePatientCheckPhoneNumberExist({
    options: {
      sendOnMount: false,
    }
  });

  const checkPhoneStatus = useDebounce((phone, onSuccess?) => {
    const result = checkPhoneNumber.send({
      params: {
        phone: {
          number: phone,
          areaCode: '1',
          type: PhoneTypeEnum.MOBILE
        }
      }
    });

    ApiRequestHelper.tryCatch(result, {
      error: 'Error checking phone number',
      success: undefined,
      onSuccess: (data) => {
        if (data?.data && phone !== initialPhone) {
          setFalse();
        } else if (!data?.data) {
          setTrue();
          onSuccess?.();
        }
      },
    });
  }, 300, [checkPhoneNumber, initialPhone]);

  const handlePhoneCheck = useDebounce((phone) => {
    if (!phone || String(phone).length < 10) {
      props.onChange?.(false);
      return;
    }

    if (String(phone) === String(initialPhone) && numberIsValid) {
      props.onChange?.(true);
      return;
    }

    checkPhoneStatus(phone, () => {
      props.onChange?.(true);
    });
  });

  const handleSwitchChange = (checked: boolean) => {
    if (checked && numberIsValid) {
      props.onChange?.(true);
    } else if (checked && !numberIsValid) {
      usePhoneExistWarningModal({
        querySelector: parentContainer,
      });
    } else {
      props.onChange?.(false);
    }
  };

  useUpdateEffect(() => {
    if (String(phone).length === 10) {
      handlePhoneCheck(phone);
    } else {
      props.onChange?.(false);
    }
  }, [phone]);

  useEffect(() => {
    if (initialPhone && String(initialPhone).length === 10) {
      checkPhoneStatus(initialPhone);
    }
  }, []);

  return (
    <OtpSwitchComponent
      className="otp-switch-container"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      disabled={!phone || String(phone).length < 10}
      onChange={handleSwitchChange}
    />
  );
};
