import { DatePicker } from 'antd';
import { useState } from 'react';
import { RangePickerDateProps } from 'antd/lib/date-picker/generatePicker';
import type { RangePickerProps } from 'antd/es/date-picker';
import moment, { Moment } from 'moment';
import { HIATUS_SPAN_DATE_FORMAT } from '../../../../constants/timeFormat';

export interface PauseServiceRangePickerComponentProps extends RangePickerDateProps<Moment> { }

const { RangePicker } = DatePicker;

export type RangeValue = [Moment | null, Moment | null] | null;

export const PauseServiceRangePickerComponent = ({
  ...props
}: PauseServiceRangePickerComponentProps) => {
  const [dates, setDates] = useState<RangeValue>(null);

  const disabledDate: RangePickerProps['disabledDate'] = (current: Moment) => {
    if (current && current.isBefore(moment().endOf('day').add(-1, 'day'))) {
      return true;
    }
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'months') >= 6;
    const tooEarly = dates[1] && dates[1].diff(current, 'months') >= 6;
    return !!tooEarly || !!tooLate;
  };

  return (
    <RangePicker
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onCalendarChange={val => setDates(val)}
      disabledDate={disabledDate}
      format={HIATUS_SPAN_DATE_FORMAT}
    />
  );
};
