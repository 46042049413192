import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useIsFirstRender } from 'usehooks-ts';
import { useRouter } from '../../../../hooks/useRouter/useRouter';
import { CalendarPageParams } from '../../../../hooks/useChangePage/types';

export interface Value extends CalendarPageParams {
  setParams: (v: CalendarPageParams | undefined) => void;
  checkOwnPatients?: boolean;
  setCheckOwnPatients?: Dispatch<SetStateAction<boolean | undefined>>;
}

export type { CalendarPageParams };

export const CalendarContext = React.createContext<Value>({
  setParams: () => { console.log('setParams has not been implemented yet!'); },
  checkOwnPatients: false,
});

export interface CalendarProviderProps {
  initialValues?: CalendarPageParams;
  useUrl?: boolean;
  children: React.ReactNode;
}

export const CalendarProvider: FC<CalendarProviderProps> = ({
  initialValues,
  useUrl = false,
  children,
}) => {
  const [calendarValue, setCalendarValue] = useState<CalendarPageParams>(initialValues || {});
  const [checkOwnPatients, setCheckOwnPatients] = useState<boolean | undefined>(false);
  const isFirstRender = useIsFirstRender();
  const { setSearchParams, query } = useRouter();

  const setParams = useCallback((v: CalendarPageParams | undefined) => {
    if (useUrl) {
      setSearchParams(v || {});
    }
    setCalendarValue(v || {});
  }, [setCalendarValue]);

  // TODO: do this when calendar can be fully test
  // useEffect(() => {
  //   if (useUrl) {
  //     const { date, eventId } = (query || {}) as CalendarPageParams;
  //     const initialCalendarValue: CalendarPageParams = {
  //       date,
  //       eventId,
  //     };
  //     setCalendarValue(initialCalendarValue);
  //   }
  // }, []);

  const contextValue = useMemo<Value>(() => {
    // need to return the info on first render
    if (isFirstRender && useUrl) {
      const initialCalendarValue = (query || {}) as CalendarPageParams;
      setCalendarValue(initialCalendarValue);
      return {
        ...initialCalendarValue,
        setParams,
      };
    }
    return {
      ...calendarValue,
      setParams,
      checkOwnPatients,
      setCheckOwnPatients,
    };
  }, [
    calendarValue,
    checkOwnPatients,
    setCheckOwnPatients,
    setParams,
  ]);

  return (
    <CalendarContext.Provider value={contextValue}>
      {children}
    </CalendarContext.Provider>
  );
};

export const useCalendarContext = () => {
  const VisitContextValue = useContext(CalendarContext);
  return VisitContextValue;
};
