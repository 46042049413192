import {
  groupBy,
  forEach,
  filter,
  sortBy,
  omit
} from 'lodash';
import { LLMUploadFileInfo } from '../../container/LLMUploadContainer/LLMUploadContainer';
import { LLMLabResultViewSourceData } from '../type';
import { HemoglobinA1cTemplateId } from '../../../transcribing/constants';
import { useLLMSourceHelper } from '../../hook/useLLMSourceHelper';
import { TranscribedLabResult } from '../../../../uc-api-sdk';

export const useLLMLabResultSource = (
  results: TranscribedLabResult[],
  uploadFileInfoList?: LLMUploadFileInfo[],
) => {
  const {
    getFileForSource,
    processSourceData,
  } = useLLMSourceHelper<
    TranscribedLabResult,
    LLMLabResultViewSourceData
  >(results, uploadFileInfoList);

  const makeSourceData = () => (
    processSourceData((result) => {
      const {
        results: allResults,
        dateCollected,
        templateId,
        templateName,
      } = result;
      const correspondingFile = getFileForSource(result.s3FileKey);
      // use only the first imageIndex available
      const repImageIndex = filter(
        allResults,
        (r) => r.imageIndex !== undefined
      )[0]?.imageIndex;
      return {
        key: templateName || '',
        file: correspondingFile.file,
        imageIndex: repImageIndex ?? -1,
        dateCollected,
        templateId,
      };
    })
  );

  const makeSourceNavigatorData = (sourceData = makeSourceData()) => {
    const groupedByKey = groupBy(sourceData, (d) => (
      d.templateId === HemoglobinA1cTemplateId
        ? d.templateId
        : d.key
    ));
    forEach(groupedByKey, (dataInGroup, key) => {
      const nonEmptyDateCollected = (
        filter(dataInGroup, (d) => d.dateCollected) as LLMLabResultViewSourceData[]
      );
      groupedByKey[key] = sortBy(nonEmptyDateCollected, (d) => d.dateCollected);
    });
    const a1c = groupedByKey[HemoglobinA1cTemplateId];
    return {
      [a1c?.[0]?.key || '']: a1c,
      ...omit(groupedByKey, HemoglobinA1cTemplateId)
    };
  };

  return {
    makeSourceData,
    makeSourceNavigatorData,
  };
};
