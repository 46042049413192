import { useMemo } from 'react';
import {
  GetOptions, MakeRequestHookConfig, UseRequestFactory, useRequestFactory,
} from '../../../uc-api-sdk/staticFiles/useReqHook';
import { RewriteWithAIRequestParams } from '../types';
import { rewriteWithAIServices } from '../RewriteWithAIServices';

export const useRewriteVisitNote = <RCN = undefined>(
  configs?: MakeRequestHookConfig<RewriteWithAIRequestParams, GetOptions, RCN>,
) => {
  const baseConfigs: UseRequestFactory<
    RewriteWithAIRequestParams,
    string,
    typeof rewriteWithAIServices.getRewritedVisitNote,
    GetOptions,
    string
  > = useMemo(() => ({
    reqFunc: (...args: Parameters<typeof rewriteWithAIServices.getRewritedVisitNote>) => (
      rewriteWithAIServices.getRewritedVisitNote(...args)
    ),
    ResponseClassCreator: rewriteWithAIServices.getResponse,
    typeName: 'useRewriteVisitNote',
  }), []);

  return useRequestFactory(baseConfigs, configs);
};
