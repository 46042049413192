import dayjs from 'dayjs';
import { ColumnType } from 'antd/lib/table';
import { DisplayDateComponent } from '../../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import {
  CarePlan,
  CarePlanContent,
  CarePlanUpdateFromEnum,
  LimitedUser
} from '../../../../../uc-api-sdk';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';
import { CarePlanUpdateFromEnumComponent } from '../../../../../enumComponent/CarePlanUpdateFromEnumComponent/CarePlanUpdateFromEnumComponent';
import { PrintCarePlanUserComponent } from '../PrintCarePlanUserComponent/PrintCarePlanUserComponent';
import { PrintCarePlanPreviewButtonContainer } from '../../container/PrintCarePlanPreviewButtonContainter/PrintCarePlanPreviewButtonContainter';
import { CarePlanUpdatedContentListComponent } from '../../../../../uiComponent/CarePlanUpdatedContentListComponent/CarePlanUpdatedContentListComponent';
import { EllipsisComponent } from '../../../../../uiComponent/EllipsisComponent/EllipsisComponent';

export const printCarePlanColumns = () => ({
  version: (): ColumnType<CarePlan> => ({
    key: 'updatedAt',
    title: 'Version',
    dataIndex: 'updatedAt',
    width: '15%',
    render: (_updatedAt: string, carePlan: CarePlan) => (
      <>
        <DisplayDateComponent value={dayjs(carePlan.version, 'YYYYMMDD')} format="USA_DATE" />
        {/* @ts-ignore */}
        {carePlan.latest && <TextComponent italic>(Up to date)</TextComponent>}
      </>
    ),
    sorter: true,
    defaultSortOrder: 'descend',
  }),
  updatedAt: () => ({
    key: 'updatedFrom',
    title: 'Updated at',
    dataIndex: 'updateFrom',
    width: '15%',
    render: (value: CarePlanUpdateFromEnum) => (
      <CarePlanUpdateFromEnumComponent value={value} />
    ),
  }),
  updatedContent: () => ({
    key: 'updatedContent',
    title: 'Updated content',
    dataIndex: 'updatedContent',
    ellipsis: true,
    width: '40%',
    render: (updatedContent: CarePlanContent) => (
      <EllipsisComponent>
        <CarePlanUpdatedContentListComponent
          carePlanUpdatedContents={updatedContent}
        />
      </EllipsisComponent>
    ),
  }),
  updatedBy: () => ({
    key: 'updatedBy',
    title: 'Updated by',
    dataIndex: 'updatedByUser',
    render: (updatedByUser: LimitedUser) => (
      <EllipsisComponent>
        <PrintCarePlanUserComponent
          profile={updatedByUser}
          split={false}
        />
      </EllipsisComponent>
    ),
  }),
  action: () => ({
    key: 'action',
    title: '',
    dataIndex: 'id',
    width: 80,
    render: (carePlanId: string, carePlan: CarePlan) => (
      <div className="flex jc-e">
        <PrintCarePlanPreviewButtonContainer
          key={carePlanId}
          carePlan={carePlan}
        />
      </div>
    ),
  }),
});
