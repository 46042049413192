import React, { FC } from 'react';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import './FoodLogPatientInfoComponent.scss';
import LanguagesComponent from '../../../../uiComponent/LanguagesComponent/LanguagesComponent';

export interface FoodLogPatientInfoComponentProps {

}

export const FoodLogPatientInfoComponent: FC<FoodLogPatientInfoComponentProps> = () => {
  const { info } = usePatientContext();
  const patientInfo = info?.patientInfo;

  if (!patientInfo) {
    return null;
  }

  return (
    <div className="foodLogPatientInfoComponent">
      <span className="patientName">
        {patientInfo.profile?.firstName}
        {' '}
        {patientInfo.profile?.lastName}
      </span>
      {' '}
      App Language: (
      <LanguagesComponent
        value={patientInfo.profile?.appLanguage || undefined}
        type="fullName"
      />
      )
    </div>
  );
};
