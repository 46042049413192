import { useEffect } from 'react';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext/LoggedInUserContext';
import { useRewriteMessage } from '../../../../services/RewriteWithAIServices/hooks/useRewriteMessage';
import { RewriteWithAIComponent } from '../../component/RewriteWithAIComponent/RewriteWithAIComponent';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';

export interface RewriteMessageWithAIContainerProps {
  originalMessage: string;
}

export const RewriteMessageWithAIContainer = ({
  originalMessage,
}: RewriteMessageWithAIContainerProps) => {
  const { userId } = useLoggedInUserFromContext();
  const rewriteMessageHook = useRewriteMessage({});

  const handleRewriteMessage = useDebounce(() => {
    if (originalMessage) {
      rewriteMessageHook.send({
        params: {
          content: {
            member_id: userId || '',
            message: originalMessage,
          },
        },
      });
    }
  }, 1000, [originalMessage, rewriteMessageHook, userId]);

  useEffect(() => {
    handleRewriteMessage();
  }, [originalMessage]);

  return (
    <RewriteWithAIComponent
      originalContent={originalMessage}
      isLoading={rewriteMessageHook.isLoading}
      newContent={rewriteMessageHook.data || originalMessage}
      onRefetch={handleRewriteMessage}
    />
  );
};
