import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import { AccountHelper, AccountNames } from '../../../../helpers/account';

export interface DisplayUserNameComponentProps<T extends AccountNames> {
  value: T | null | undefined;
}

export const DisplayUserNameComponent = <T extends AccountNames> ({
  value,
}: DisplayUserNameComponentProps<T>) => {
  const getFullName = () => (
    value ? AccountHelper.getFullNameNickname(value) : <EmptyComponent />
  );

  return (
    <>{getFullName()}</>
  );
};
