import { ReactNode } from 'react';
import { Button, Drawer } from 'antd';
import { PreviewConsentComponent } from './PreviewConsentComponent';
import { LargeDrawerWidth } from '../../../../constants/drawer';
import { ClickableDiv } from '../../../../uiComponent/ClickableDiv/ClickableDiv';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { SignatureCollectionMethodEnum } from '../../../../uc-api-sdk';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { ConsentFormFetchContainer, ConsentFormFetchContainerProps } from '../../container/ConsentFormFetchContainer/ConsentFormFetchContainer';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';

export interface PreviewConsentDrawerComponentProps {
  children: ReactNode;
  patientInfo: PatientInfo;
  onResignOpen?: () => void;
  fetchByContainer?: ConsentFormFetchContainerProps['fetchByContainer'];
}

export const PreviewConsentDrawerComponent = ({
  children,
  patientInfo,
  onResignOpen,
  fetchByContainer,
}: PreviewConsentDrawerComponentProps) => {
  const {
    isOpen: isPreviewOpen,
    open: openPreview,
    close: closePreview,
  } = useOpen();

  const handleResignConsent = () => {
    onResignOpen?.();
    closePreview();
  };

  return (
    <>
      <ClickableDiv onClick={openPreview}>
        {children}
      </ClickableDiv>
      <Drawer
        open={isPreviewOpen}
        onClose={closePreview}
        width={LargeDrawerWidth}
        destroyOnClose
      >
        <ConsentFormFetchContainer
          patientId={patientInfo.id}
          patientInfo={patientInfo}
          fetchByContainer={fetchByContainer}
        >
          {
            ({ consentService, isLoading }) => (
              <LoadingOverlayComponent isLoading={isLoading}>
                <div className="flex fd-c h100">
                  <div className="f1">
                    <PreviewConsentComponent
                      signatureMethod={(
                        consentService?.signatureMethod as SignatureCollectionMethodEnum
                      )}
                      fileUrl={(
                        consentService?.fileUrl || ''
                      )}
                    />
                  </div>
                  <div className="mt30">
                    <Button type="primary" onClick={closePreview}>Close</Button>
                    {
                      onResignOpen
                      && <Button type="text" onClick={handleResignConsent}>Resign the consent</Button>
                    }
                  </div>
                </div>
              </LoadingOverlayComponent>
            )
          }
        </ConsentFormFetchContainer>
      </Drawer>
    </>
  );
};
