import { Button, Dropdown } from 'antd';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import { startCase } from 'lodash';

import './MessageTranslatedComponent.scss';
import { GoogleTranslateLanguageType } from '../../../../enumComponent/GoogleTranslateLanguageEnumComponent/GoogleTranslateLanguageEnumComponent';

export interface MessageTranslatedComponentProps {
  translatedText?: string;
  language?: string;
  setLanguage?: (language: string) => void;
}

export const MessageTranslatedComponent = ({
  translatedText,
  language,
  setLanguage
}: MessageTranslatedComponentProps) => {
  const handleMenuClick: MenuItemType['onClick'] = (e) => {
    setLanguage?.(e.key);
  };

  const items: MenuItemType[] = Object.values(GoogleTranslateLanguageType).map((lang) => ({
    label: lang,
    key: lang,
    onClick: handleMenuClick,
  }));

  return (
    <div className="translated-label">
      <div className="translated-text-content fs12">
        <div className="translated-to">
          Translated to
          {' '}
          <Dropdown
            menu={{ items }}
            trigger={['click']}
            placement="bottomLeft"
            overlayClassName="translate-language-dropdown"
          >
            <Button type="link" className="fs12 p0">
              {startCase(language) || 'English'}
            </Button>
          </Dropdown>
          :
        </div>
        <div>
          {translatedText}
        </div>
      </div>
    </div>
  );
};
