import { Row, Col, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { DeviceModelGroupEnumComponent } from '../../../../enumComponent/DeviceModelGroupEnumComponent/DeviceModelGroupEnumComponent';
import {
  DeviceModelEnum,
  LoanDevice
} from '../../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { DeviceItemComponent } from './DeviceItemComponent';
import ModalButton, { ModalButtonProps } from '../../../../uiComponent/ModalButton/ModalButton';
import { Title } from '../../../../uiComponent/Title/Title';
import { LoanerPhoneUnassignFormContainer } from '../../container/LoanerPhoneUnassignFormContainer/LoanerPhoneUnassignFormContainer';

import './LoanerPhoneItemComponent.scss';

const { Text } = Typography;

export interface LoanerPhoneItemComponentProps {
  patientId: string;
  loanDevice?: LoanDevice | null;
}

export const LoanerPhoneItemComponent = ({
  patientId,
  loanDevice,
}: LoanerPhoneItemComponentProps) => {
  if (!loanDevice?.deviceId) {
    return null;
  }

  const renderEditButton: ModalButtonProps['trigger'] = (open) => <EditOutlined onClick={open} />;

  return (
    <Row>
      <Col span={5}>
        <Row>
          <Text>
            <DeviceModelGroupEnumComponent value={DeviceModelEnum.LOAN_DEVICE} />
          </Text>
        </Row>
      </Col>
      <Col span={9} className="overflow-h">
        <Row className="flex jc-sb">
          <DeviceItemComponent
            device={{
              deviceModel: DeviceModelEnum.LOAN_DEVICE,
              deviceId: loanDevice?.deviceId,
            }}
          />
        </Row>
      </Col>
      <Col span={9} className="flex ai-c overflow-h">
        <div className="flex m40 ai-c gap5 initial">
          Last used:
          {' '}
          <Text type="secondary">
            <DisplayDateComponent
              value={loanDevice?.updatedAt}
              format="USA_DATE"
              fallbackText={<DisplayOrEmptyComponent />}
            />
          </Text>
        </div>
      </Col>
      <Col span={1}>
        <ModalButton
          wrapClassName="loaner-phone-unassign-modal"
          trigger={renderEditButton}
          buttonType="text"
          maskClosable={false}
          closable={false}
          destroyOnClose
        >
          {
            ({ close }) => (
              <LoanerPhoneUnassignFormContainer
                patientId={patientId}
                title={(<Title>Unified Care Loaner Phone</Title>)}
                initialValues={loanDevice}
                submitText="Unassign"
                onCancel={close}
                disabled
              />
            )
          }
        </ModalButton>
      </Col>
    </Row>
  );
};
