import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { sorterHelper } from '../../../../hooks/useTableChange/sorterHelper';
import { useTableChange } from '../../../../hooks/useTableChange/useTableChange';
import {
  Order,
  OrderItemEnum,
  OrderStaging,
  SearchRequest,
  usePatientSearchOrders
} from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { OrderTableComponent } from '../../component/OrderTableComponent/OrderTableComponent';

export interface OrderTableContainerProps {
  patientId?: string;
}

export const OrderTableContainer = ({
  patientId,
}: OrderTableContainerProps) => {
  const tableChange = useTableChange<OrderStaging, SearchRequest<Order>['filter']>({
    sortProcessor: (sort) => sorterHelper(sort),
    filterProcessor: (filters) => {
      const newFilters: Order = {};
      if (filters.orderItems) {
        newFilters.orderNamesIn = { in: filters.orderItems as OrderItemEnum[] };
      }
      return newFilters;
    },
  });

  const request = usePatientSearchOrders({
    options: {
      sendOnMount: true,
    },
    params: {
      request: {
        filter: {
          memberId: patientId || '',
          ...tableChange.processedFilter,
        },
        pageInfo: {
          size: 10,
          ...tableChange.pageInfo,
        },
      },
    },
  });

  useUpdateListener('ORDER_CREATED', request.refetch);

  return (
    <FetchComponent
      info={request}
      alwaysShowChildren
      showErrorOverlay={false}
      emptyOverlay={<h2>No Data!!!</h2>}
    >
      {(value, info) => {
        const data = info.data?.data?.content || [];
        return (
          <OrderTableComponent
            dataSource={data}
            onChange={tableChange.handleTableChange}
            pagination={info.pagination}
          />
        );
      }}
    </FetchComponent>
  );
};
