import { find } from 'lodash';
import { SupplyTypeEnum } from '../type';
import { OrderDeviceFormValues, OrderSuppliesFormValues } from './useOrderFormHook';
import { DeviceModelEnum } from '../../../uc-api-sdk';
import { ConnectedDeviceInfo } from '../../device/type';

export interface DeviceToReturnValue {
  typeModel: DeviceModelEnum | SupplyTypeEnum;
  deviceId?: string;
  newDeviceId?: string;
  needAction?: boolean;
}

export const useGetDevicesToReturn = () => {
  const getDevicesToReturn = (
    selectedSuppliesList: OrderSuppliesFormValues,
    selectedDevicesList: OrderDeviceFormValues,
    allMostRecentDevices?: ConnectedDeviceInfo[],
  ) => {
    const devicesToReturn = [] as DeviceToReturnValue[];

    const hasRouterSelected = !!selectedSuppliesList?.WIFI_ROUTER?.isChecked;
    if (hasRouterSelected) {
      devicesToReturn.push({ typeModel: SupplyTypeEnum.WIFI_ROUTER });
    }

    const hasHotspotSelected = !!selectedSuppliesList?.HOTSPOT?.isChecked;
    if (hasHotspotSelected) {
      devicesToReturn.push({ typeModel: SupplyTypeEnum.HOTSPOT });
    }

    const selectedList = {
      ...selectedDevicesList,
      ...selectedSuppliesList,
    };

    // check all devices that need action
    [
      DeviceModelEnum.BPM1,
      DeviceModelEnum.BPM1_HOTSPOT,
      DeviceModelEnum.LOAN_DEVICE,
      DeviceModelEnum.PY_802_LTE,
      DeviceModelEnum.BIOLAND
    ].forEach((deviceModel) => {
      const currentDeviceId = find(allMostRecentDevices, { deviceModel })?.deviceId;
      const newDeviceId = selectedList[deviceModel]?.deviceId;
      if (currentDeviceId && newDeviceId) {
        devicesToReturn.push({
          typeModel: deviceModel,
          deviceId: currentDeviceId,
          newDeviceId: selectedList[deviceModel].deviceId,
          needAction: true,
        });
      }
    });

    return devicesToReturn;
  };

  return getDevicesToReturn;
};
