import moment, { Moment } from 'moment';
import { useCallback } from 'react';
import { ShouldShow, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { TranscribeA1CFormComponentProps } from '../../../features/transcribing/component/TranscribeA1CFormComponent/TranscribeA1CFormComponent';
import { GetRulesFunc } from '../../../types/form';

interface UseTranscribeForm {
  patientId: TranscribeA1CFormComponentProps['patientId'];
  compliantTestWindowStartDay: TranscribeA1CFormComponentProps['compliantTestWindowStartDay'];
  compliantTestWindowEndDay: TranscribeA1CFormComponentProps['compliantTestWindowEndDay'];
}

export const useTranscribeForm = ({
  compliantTestWindowStartDay,
  compliantTestWindowEndDay,
}: UseTranscribeForm) => {
  const factory = useFormHookFactory({
    testDate: {
      name: 'testDate',
      label: 'Test Date',
    },
    testResult: {
      name: 'testResult',
      label: 'Test Result',
    },
    isInHouse: {
      name: 'isInHouse',
      label: ' ',
    },
    // CheckBox Patient doesn't have A1C record
    hasNoA1CRecord: {
      name: 'hasNoA1CRecord',
      label: ' ',
    },
    // Leave Note Link
    isA1CLeaveNote: {
      name: 'isA1CLeaveNote',
      label: '',
    },
    // Input Note Message
    isInputNote: {
      name: 'isInputNote',
      label: '',
    },
    // InputArea and Submit Button
    isA1CNoteSubmit: {
      name: 'isA1CNoteSubmit',
      label: '',
    },
  });

  // test date
  const getTestDateRules = useCallback<GetRulesFunc>(() => ([
    ...factory.getRequiredRules('testDate'),
  ]), [factory]);

  // test result warning logic
  const getTestResultRules = useCallback<GetRulesFunc>(() => ([
    ...factory.getRequiredRules('testResult'),
    {
      validator(_, value) {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(value)) {
          return Promise.reject(new Error('Invalid input'));
        }
        return Promise.resolve();
      },
    },
  ]), [factory]);

  // valid submit logic
  const checkValidDate = (
    date: Moment,
  ): boolean => date.isAfter(compliantTestWindowStartDay)
    && date.isBefore(moment(compliantTestWindowEndDay).add(1, 'days'));

  // when selected hasNoA1CRecord Checkbox
  const shouldShowDoNotHaveA1CAlert: ShouldShow = useCallback((getFieldValue) => (
    getFieldValue(factory.getName('hasNoA1CRecord'))
  ), []);

  // when clicked hasNoA1CRecord Checkbox
  const shouldShowA1CLeaveNote: ShouldShow = useCallback((getFieldValue) => (
    getFieldValue(factory.getName('hasNoA1CRecord'))
  ), []);

  // when clicked Leave Note Link
  const shouldShowA1CNoteInputArea: ShouldShow = useCallback((getFieldValue) => (
    getFieldValue(factory.getName('hasNoA1CRecord'))
    && getFieldValue(factory.getName('isA1CNoteSubmit'))
  ), []);

  // reset isA1CNoteSubmit Value
  const resetA1CNoteSubmitValue = useCallback(() => {
    const currentValue = factory.form.getFieldValue(factory.getName('isA1CNoteSubmit'));
    const newValue = !currentValue;
    factory.form.setFieldsValue({ isA1CNoteSubmit: newValue });
  }, [factory.form, factory.getName]);

  // set A1C Note Input Value
  const handleA1CNoteChange = useCallback((value: string) => {
    factory.form.setFieldValue('isInputNote', value);
  }, [factory.form]);

  // get A1C Note Input Value
  const getA1CInputNoteValue = useCallback(() => (
    factory.form.getFieldValue('isInputNote')
  ), []);

  // reset A1C Note Input Value
  const resetA1CInputNote = useCallback(() => (
    factory.form.setFieldsValue({ isInputNote: '' })
  ), []);

  return {
    ...factory,
    getTestDateRules,
    getTestResultRules,
    checkValidDate,
    shouldShowDoNotHaveA1CAlert,
    resetA1CNoteSubmitValue,
    shouldShowA1CLeaveNote,
    shouldShowA1CNoteInputArea,
    handleA1CNoteChange,
    getA1CInputNoteValue,
    resetA1CInputNote,
  };
};
