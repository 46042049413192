import { MedicationStatusEnum as MedicationStatus } from '../../uc-api-sdk';

export const MED_STATUS = [
  {
    value: MedicationStatus.TAKING,
    label: 'Taking',
    text: 'Taking',
  },
  {
    value: MedicationStatus.TAKING_IRREGULARLY,
    label: 'Taking Irregularly',
    text: 'Taking Irregularly',
  },
  {
    value: MedicationStatus.NOT_SURE,
    label: 'Not Sure',
    text: 'Not Sure',
  },
  {
    value: MedicationStatus.NOT_TAKING,
    label: 'Not Taking',
    text: 'Not Taking',
  },
];
