import { Form } from 'antd';
import { PatientHealthConditionComplexityContainer } from '../../container/PatientHealthConditionComplexityContainer/PatientHealthConditionComplexityContainer';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { usePatientComplexityForm } from '../../hook/usePatientComplexityForm';
import { FormOptions } from '../../../../hooks/useFormHookFactory/useFormHookFactory';

export interface PatientHealthConditionComplexityFormItemComponentProps
  extends Pick<FormOptions, 'form'> {
  disabled?: boolean;
}

export const PatientHealthConditionComplexityFormItemComponent = ({
  form,
  disabled,
}: PatientHealthConditionComplexityFormItemComponentProps) => {
  const complexityForm = usePatientComplexityForm({ form });

  return (
    <Form.Item
      noStyle
      shouldUpdate={complexityForm.shouldUpdateComplexity(complexityForm.getName('icdTable'))}
    >
      {
        ({ getFieldValue }) => {
          const healthConditions = complexityForm.getValue('icdTable', getFieldValue);

          return (
            <FormItem
              name={complexityForm.getName('patientComplexity')}
            >
              <PatientHealthConditionComplexityContainer
                healthConditions={healthConditions}
                disabled={disabled}
              />
            </FormItem>
          );
        }
      }
    </Form.Item>
  );
};
