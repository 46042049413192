import { isEmpty } from 'lodash';
import { USA_DATE } from '../../../constants/timeFormat';
import { WeightService } from '../../../helpers/weight';
import { PhoneType } from '../../../types/phone';
import { Patient, Phone } from '../../../uc-api-sdk';
import { TranscribeVisitFormValues } from '../component/TranscribeVisitDrawerFormComponent/TranscribeVisitDrawerFormComponent';

export const makePatientParams = (v: TranscribeVisitFormValues) => {
  const params = {} as Patient;
  const profile = {} as NonNullable<Patient['profile']>;

  if (v.email !== undefined) {
    params.email = v.email;
  }

  let phone: Phone[] | undefined;
  if (v.mobilePhone !== undefined) {
    // better correct phone data with initial phone values
    phone = [{
      type: PhoneType.MOBILE,
      number: String(v.mobilePhone),
    }];
  }
  if (v.homePhone !== undefined) {
    phone = [...(phone || []), {
      type: PhoneType.HOME,
      number: String(v.homePhone),
    }];
  }
  params.phone = phone;

  if (v.medicalId !== undefined) {
    params.medicalRecordId = v.medicalId;
  }

  if (v.address && v.city && v.state && v.zipcode) {
    params.address = [
      {
        streetName: v.address,
        unit: v.address2 || null,
        city: v.city,
        state: v.state,
        postCode: String(v.zipcode),
      },
    ];
  }

  if (v.providerId) {
    profile.doctorId = v.providerId;
  }

  if (v.weight !== undefined) {
    profile.weight = {
      unit: WeightService.defaultWeightUnit,
      value: (
        typeof v.weight === 'number'
          ? v.weight
          : parseInt(v.weight, 10)
      ),
    };
  }

  if (v.height !== undefined) {
    profile.height = {
      unit: 'cm',
      value: v.height,
    };
  }

  if (Array.isArray(v.spokenLanguage)) {
    profile.languages = v.spokenLanguage;
  }

  if (v.firstName) {
    profile.firstName = v.firstName;
  }

  if (v.lastName) {
    profile.lastName = v.lastName;
  }

  if (v.nickName) {
    profile.nickName = v.nickName;
  }

  if (v.dob) {
    profile.birthday = v.dob.format(USA_DATE);
  }

  if (!isEmpty(profile)) {
    params.profile = profile;
  }

  return params;
};
