import { ReactNode } from 'react';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { usePatientUpdateUnableToEngage } from '../../../../uc-api-sdk';
import ModalButton from '../../../../uiComponent/ModalButton/ModalButton';
import { UnableToEngageFormValues, UnableToEngageModalContentComponent } from '../UnableToEngageModalContentComponent/UnableToEngageModalContentComponent';

export interface UnableToEngageModalContainerProps {
  patientId: string;
  initialValues?: UnableToEngageFormValues;
  button?: ReactNode;
}

export const UnableToEngageModalContainer = ({
  patientId,
  initialValues,
  button = 'Unable to Engage',
}: UnableToEngageModalContainerProps) => {
  const hook = usePatientUpdateUnableToEngage({});
  const refetch = useUpdate('PATIENT_INFO_UPDATED');
  const refetchCareNotes = useUpdate('UNABLE_TO_ENGAGE_UPDATED');
  const onSubmit = async (values: UnableToEngageFormValues) => {
    const request = hook.send({
      params: {
        memberId: patientId,
        request: {
          ...values,
          hiatusUnableToEngage: true,
        }
      }
    });

    await ApiRequestHelper.tryCatch(request, {
      success: 'Updated successfully',
      error: 'An error occurred, please try again.',
      onSuccess: () => {
        refetch.updateValue();
      },
    });
  };

  const onRemove = async () => {
    const request = hook.send({
      params: {
        memberId: patientId,
        request: {
          hiatusUnableToEngage: false,
        },
      }
    });

    await ApiRequestHelper.tryCatch(request, {
      success: 'Updated successfully',
      error: 'An error occurred, please try again.',
      onSuccess: () => {
        refetch.updateValue();
        refetchCareNotes.updateValue();
      },
    });
  };

  return (
    <ModalButton
      button={button}
      buttonType="link"
      destroyOnClose
      closable={false}
    >
      {
        ({ close }) => (
          <UnableToEngageModalContentComponent
            onSubmit={initialValues ? onRemove : onSubmit}
            onClose={close}
            initialValues={initialValues}
          />
        )
      }
    </ModalButton>
  );
};
