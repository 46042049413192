import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

export interface Value {
  justAddedLabResultId?: string;
  setJustAddedLabResultId?: Dispatch<SetStateAction<string | undefined>>;
}

export const LabResultContext = React.createContext<Value>({});

export interface LabResultProviderProps {
  children: React.ReactNode;
}

export const LabResultProvider = ({
  children,
}: LabResultProviderProps) => {
  const [justAddedLabResultId, setJustAddedLabResultId] = useState<string | undefined>(undefined);

  const contextValue = useMemo<Value>(() => ({
    justAddedLabResultId,
    setJustAddedLabResultId,
  }), [justAddedLabResultId, setJustAddedLabResultId]);

  return (
    <LabResultContext.Provider value={contextValue}>
      {children}
    </LabResultContext.Provider>
  );
};

export const useLabResultContext = () => useContext<Value>(LabResultContext);
