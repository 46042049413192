import { map } from 'lodash';
import { ReactNode } from 'react';

export interface ListComponentProps<T extends ReactNode> {
  value: T[];
  renderItem: (v: T) => ReactNode;
  rowKey?: (v: T) => string;
  splitter?: ReactNode;
}

export const ListComponent = <T extends ReactNode, >({
  value,
  renderItem,
  rowKey,
  splitter = '/',
}: ListComponentProps<T>) => {
  const items = map(value, (v, i) => (
    <span key={rowKey?.(v) || i}>
      {renderItem(v)}
      {i < value.length - 1 ? splitter : null}
    </span>
  ));

  return (<span>{items}</span>);
};
