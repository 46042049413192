import React, {
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { reduce } from 'lodash';
import { EnrollmentSectionsStatuses, EnrollmentSectionsEnum } from './type';
import { enrollmentSectionsStatusInitialValue, enrollmentSectionsWeight } from './constant';

interface Value {
  progress: number;
  sections: EnrollmentSectionsStatuses;
  onSectionDone: (section: EnrollmentSectionsEnum) => void;
}

export const EnrollmentProgressContext = React.createContext<Value>({
  progress: 0,
  sections: enrollmentSectionsStatusInitialValue,
  onSectionDone: () => console.error('onSectionDone not implemented yet!'),
});

export interface EnrollmentProgressProviderProps {
  children: ReactNode;
  initialValue?: EnrollmentSectionsStatuses;
}

export const EnrollmentProgressProvider = ({
  children,
  initialValue,
}: EnrollmentProgressProviderProps) => {
  const [sectionsStatues, setSectionStatuses] = useState<EnrollmentSectionsStatuses>(
    initialValue || enrollmentSectionsStatusInitialValue,
  );

  const onSectionDone = useCallback<Value['onSectionDone']>((v) => {
    setSectionStatuses((statuses) => ({ ...statuses, [v]: true }));
  }, [setSectionStatuses]);

  const calculateProgress = (): number => (
    reduce(sectionsStatues, (res, v, sectionsKey) => {
      if (v) {
        return res + enrollmentSectionsWeight[sectionsKey as EnrollmentSectionsEnum];
      }
      return res;
    }, 0)
  );

  const contextValue = useMemo<Value>(() => ({
    progress: calculateProgress(),
    sections: sectionsStatues,
    onSectionDone,
  }), [
    sectionsStatues,
    onSectionDone,
  ]);

  return (
    <EnrollmentProgressContext.Provider value={contextValue}>
      {children}
    </EnrollmentProgressContext.Provider>
  );
};

export const useEnrollmentProgressContext = () => {
  const patientContextValue = useContext(EnrollmentProgressContext);
  return patientContextValue;
};
