import { Button, Form, Space } from 'antd';
import { CareNote } from '../../../../../uc-api-sdk';
import { CareNoteContentComponent } from '../CareNoteContentComponent/CareNoteContentComponent';
import { CareNoteTagInputComponent } from '../CareNoteTagInputComponent/CareNoteTagInputComponent';
import { useCareNoteHook } from '../hook/useCareNoteHook';
import FormItem from '../../../../../uiComponent/FormItem/FormItem';
import { patientCareNoteTags } from '../../../../../constants/careNoteTags';

export interface CareNoteCreateEditFormComponentProps {
  mode?: 'CREATE' | 'EDIT';
  onFinish?: (values: CareNote) => void;
  onCancel?: () => void;
  initialValues?: Partial<CareNote>;
  isLoading?: boolean;
}
export const CareNoteCreateEditFormComponent = ({
  mode = 'CREATE',
  onFinish,
  onCancel,
  initialValues,
  isLoading,
}: CareNoteCreateEditFormComponentProps) => {
  const careNoteForm = useCareNoteHook();

  return (
    <Form
      layout="vertical"
      form={careNoteForm.form}
      onFinish={careNoteForm.handleSubmitAndReset(onFinish)}
      initialValues={initialValues}
      disabled={isLoading}
    >
      <FormItem
        info={careNoteForm.getInfo('tags')}
        required
      >
        <CareNoteTagInputComponent
          careNoteTagOptions={patientCareNoteTags}
        />
      </FormItem>

      <div id="temp" />
      <FormItem
        info={careNoteForm.getInfo('content')}
        required
      >
        <CareNoteContentComponent />
      </FormItem>
      {
        mode === 'CREATE'
        && <p className="danger">*Please note, once a care note is created, it can not be removed.</p>
      }
      <Space>
        <Button type="primary" htmlType="submit">Submit</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </Space>
    </Form>
  );
};
