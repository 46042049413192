import { map } from 'lodash';
import { ClinicalGoalItemComponent } from '../ClinicalGoalItemComponent/ClinicalGoalItemComponent';
import { ClinicalGoalItem } from '../../../../../uc-api-sdk';
import { useDeepCompareMemo } from '../../../../../hooks/useDeepCompareEffect';
import { clinicGoalConditionOrder } from '../../../constant/clinicGoal';
import { orderByArray } from '../../../../../helpers/order/orderByArray';

interface ClinicalGoalCardListComponentProps {
  value: ClinicalGoalItem[]
}

export const ClinicalGoalCardListComponent = ({
  value,
}: ClinicalGoalCardListComponentProps) => {
  const orderedValue = useDeepCompareMemo(() => (
    orderByArray(value, clinicGoalConditionOrder, 'condition')
  ), [value]);

  return (
    <div>
      {map(orderedValue, (goal, index) => (
        <ClinicalGoalItemComponent key={index} goal={goal} />
      ))}
    </div>
  );
};
