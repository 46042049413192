import { Dayjs } from 'dayjs';
import { ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import DisplayOrEmptyComponent from '../DisplayComponent/DisplayOrEmptyComponent';
import { getDaysToExpiration } from './helper';

import './ExpirationStatusComponent.scss';
import { PluralComponent } from '../PluralComponent/PluralComponent';
import { NOTIFIED_CALENDAR_ICON } from '../../assets/commonIconInfo';
import { Icon } from '../../icons/Icon';

const defaultRenderExpired = () => (
  <>
    <Icon
      info={NOTIFIED_CALENDAR_ICON}
      asSVG
      className="mr5"
    />
    Expired
  </>
);

const defaultRenderExpiring = (daysToExpiration: number) => (
  <>
    <Icon
      info={NOTIFIED_CALENDAR_ICON}
      asSVG
      className="mr5"
    />
    Expires
    {' '}
    {
      daysToExpiration > 0
        ? (
          <>
            in
            {' '}
            <PluralComponent
              value={Math.abs(daysToExpiration)}
              unit="day"
            />
          </>
        ) : (
          'today'
        )
    }
  </>
);

export interface ExpirationStatusComponent {
  expirationDate?: Dayjs; // timezone need to be handled in parent
  daysToShowExpiring?: number; // when days to expired is greater than this number, it will not show
  renderStatus?: (
    daysPastExpiration: number,
    defaultRenderExpired?: () => ReactNode,
    defaultRenderExpiring?: (daysToExpiration: number) => ReactNode,
  ) => ReactNode;
  showWhenEmpty?: boolean;
  emptyText?: string;
  className?: string;
}

export const ExpirationStatusComponent = ({
  expirationDate,
  daysToShowExpiring,
  renderStatus,
  showWhenEmpty,
  emptyText = '-- --',
  className = ''
}: ExpirationStatusComponent) => {
  const daysToExpired = useMemo(() => (
    getDaysToExpiration(expirationDate)
  ), [expirationDate]);

  const render = () => {
    if (!expirationDate && !showWhenEmpty) {
      return null;
    }

    if (!expirationDate || daysToExpired === undefined) {
      return (<DisplayOrEmptyComponent emptyText={emptyText} />);
    }

    if (renderStatus) {
      return renderStatus(
        daysToExpired,
        defaultRenderExpired,
        defaultRenderExpiring
      );
    }

    if (daysToExpired < 0) {
      return (
        <div className="expired">
          {defaultRenderExpired()}
        </div>
      );
    }

    if (
      typeof daysToShowExpiring === 'number'
      && daysToExpired > daysToShowExpiring
    ) {
      return null;
    }

    return (
      <div className="expiring">
        {defaultRenderExpiring(daysToExpired)}
      </div>
    );
  };

  return (
    <div
      className={classNames({
        'expiration-status': true,
        [className]: !!className,
      })}
    >
      {render()}
    </div>
  );
};
