import { Col, Row } from 'antd';
import React, { FC, ReactNode } from 'react';

export interface VitalTableAndSummaryViewComponentProps {
  table: ReactNode;
  summary: ReactNode;
  summaryTable: ReactNode;
}

export const VitalTableAndSummaryViewComponent: FC<VitalTableAndSummaryViewComponentProps> = ({
  table,
  summary,
  summaryTable,
}) => (
  <Row gutter={[30, 30]}>
    <Col lg={24} xl={12}>
      <div>
        {table}
      </div>
    </Col>
    <Col lg={24} xl={12}>
      <div>
        <div>
          {summary}
        </div>
        <div>
          <div className="text-gray-scale-2 fs16">Summary</div>
          {summaryTable}
        </div>
      </div>
    </Col>
  </Row>
);
