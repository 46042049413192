import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { AlertCardComponent } from '../../../../uiComponent/AlertCardComponent/AlertCardComponent';
import { UnableToEngageModalContainer } from '../UnableToEngageModalButtonComponent/UnableToEngageModalButtonComponent';

export const UnableToEngageAlertComponent = () => {
  const { info } = usePatientContext();
  const {
    hiatusUnableToEngage,
    unableToEngageReason,
    unableToEngageUpdateTime
  } = info?.enrolledProgramService?.getEngagementDetail() || {};
  const { id = '' } = info || {};

  return hiatusUnableToEngage ? (
    <UnableToEngageModalContainer
      button={(
        <AlertCardComponent
          type="error"
          message="Unable to Engage, all compliance alerts paused."
          align="left"
        />
      )}
      patientId={id}
      initialValues={{
        unableToEngageReason: unableToEngageReason || '',
        unableToEngageUpdateTime: unableToEngageUpdateTime || '',
      }}
    />
  ) : null;
};
