import { Select, SelectProps } from 'antd';
import { map } from 'lodash';
import { FC, useMemo } from 'react';
import { RaceEnumComponent } from '../../../enumComponent/RaceEnumComponent/RaceEnumComponent';
import { RaceEnum } from '../../../uc-api-sdk';

export interface PatientRaceInputComponentProps extends SelectProps { }

export const PatientRaceInputComponent: FC<PatientRaceInputComponentProps> = ({
  value,
  onChange,
}) => {
  const options = useMemo(() => map(RaceEnum, (r) => (
    { label: <RaceEnumComponent key={r} value={r} />, value: r }
  )), []);
  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};
