import { useState, useEffect } from 'react';
import { intersection, isEqual } from 'lodash';
import { ALL_CATEGORIES } from './useArticleCategories';
import { SEARCH_ARTICLES_PAGE_SIZE } from '../constants';
import { WebflowLanguageEnum } from '../types';
import { usePostSearchArticles } from './api/usePostSearchArticles';
import { Article } from '../../../uc-api-sdk';
import useDebounce from '../../../hooks/useDebounce/useDebounce';

export const INITIAL_CATEGORY = ALL_CATEGORIES;

export interface UseSearchArticleDataSource {
  data: Article[],
  totalPage: number,
  totalSize: number,
}
export interface UseSearchArticlesChangedValues {
  changedSearchValue?: string,
  changedCategory?: string,
  changedLanguages?: WebflowLanguageEnum[],
  changedPage?: number,
}
export interface UseSearchArticlesResult {
  searchValue: string,
  category: string,
  page: number,
  onChange: (changedValues: UseSearchArticlesChangedValues) => void,
  resetToInitial: () => void,
  articlesDataSource?: UseSearchArticleDataSource,
  isLoadingArticlesDataSource: boolean,
}
export interface UseSearchArticlesHook {
  initialSearchValue?: string,
  initialCategory?: string,
  initialLanguages?: WebflowLanguageEnum[],
  initialPage?: number,
  sendOnMount?: boolean,
}
export const useSearchArticles = (
  props: UseSearchArticlesHook,
): UseSearchArticlesResult => {
  const {
    initialSearchValue = '',
    initialCategory = INITIAL_CATEGORY,
    initialLanguages = [],
    initialPage = 1,
    sendOnMount = true,
  } = props;
  const [searchValue, setSearchValue] = useState<UseSearchArticlesResult['searchValue']>(initialSearchValue);
  const [category, setCategory] = useState<UseSearchArticlesResult['category']>(initialCategory);
  const [languages, setLanguages] = useState<UseSearchArticlesHook['initialLanguages']>(initialLanguages);
  const [page, setPage] = useState<UseSearchArticlesResult['page']>(initialPage);
  const [isLoadingArticlesDataSource, setIsLoadingArticlesDataSource] = useState(false);
  const [articlesDataSource, setArticlesDataSource] = useState<UseSearchArticlesResult['articlesDataSource']>();
  const searchArticlesInfo = usePostSearchArticles({
    options: { sendOnMount: false },
    params: {
      filter: {},
      pageInfo: {
        page: 0, size: SEARCH_ARTICLES_PAGE_SIZE, sort: [], pagination: true,
      },
    },
  });

  const searchAndSetArticlesDataSource = useDebounce(async (
    searchValue: string,
    selectedCategory: string,
    selectedLanguages: WebflowLanguageEnum[] = [],
    page = 1,
  ) => {
    if (isLoadingArticlesDataSource) return;

    setIsLoadingArticlesDataSource(true);
    try {
      let category: string | undefined = selectedCategory;
      if (selectedCategory === ALL_CATEGORIES) {
        category = undefined;
      }
      const languagesSupported = Object.values(WebflowLanguageEnum);
      const languages = intersection(selectedLanguages, languagesSupported);

      const res = await searchArticlesInfo.send({
        params: {
          filter: {
            nameRegex: searchValue,
            languageIn: {
              in: !languages.length ? ['en'] : languages,
            },
            // summaryRegex: searchString, [0316] not support OR search yet
            // @ts-ignore
            category,
          },
          pageInfo: {
            page,
            size: SEARCH_ARTICLES_PAGE_SIZE,
            sort: [],
            pagination: true,
          },
        },
      });
      if (!res || res.code !== 200) {
        throw new Error('Server error');
      }
      const {
        content = [],
        totalPage,
        totalSize,
      } = res.data || {};
      setArticlesDataSource({
        data: content as Article[],
        totalPage: totalPage || 0,
        totalSize: totalSize || content?.length || 0,
      });
    } catch (error) {
      console.error('Failed to search articles, ', error);
    }
    setIsLoadingArticlesDataSource(false);
  });

  const onChange: UseSearchArticlesResult['onChange'] = ({
    changedSearchValue = searchValue,
    changedCategory = category,
    changedLanguages = languages,
    changedPage = page,
  }) => {
    if (changedSearchValue !== searchValue) setSearchValue(changedSearchValue);
    if (changedCategory !== category) setCategory(changedCategory);
    if (!isEqual(changedLanguages, languages)) setLanguages(changedLanguages);
    if (changedPage !== page) setPage(changedPage);
  };

  const resetToInitial = () => {
    setSearchValue(initialSearchValue);
    setCategory(initialCategory);
    setLanguages(initialLanguages);
    setPage(initialPage);
  };

  useEffect(() => {
    if (!sendOnMount) return;
    searchAndSetArticlesDataSource(searchValue, category, languages, page);
  }, [sendOnMount, searchValue, category, languages, page]);

  return {
    searchValue,
    category,
    page,
    onChange,
    resetToInitial,
    articlesDataSource,
    isLoadingArticlesDataSource,
  };
};
