import { CheckboxFilterComponent } from '../component/CheckboxFilterComponent/CheckboxFilterComponent';
import { TableFilterType } from '../type/filter';
import { FilterIcon } from './FilterIcon';

export const createGendersCheckboxFilter = (): TableFilterType => {
  const options = [
    {
      label: 'Male',
      value: 'M',
    },
    {
      label: 'Female',
      value: 'F',
    },
  ];

  return ({
    filterDropdown: ((props) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <CheckboxFilterComponent {...props} filterOptions={options} />
    )),
    filterIcon: FilterIcon,
  });
};
