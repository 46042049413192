/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import { LogbookCellComponentProps } from './LogbookCellComponent';

export interface LogbookCellGroupComponentProps {
  children: ReactNode | ReactNode[];
  onClick?: () => void;
}

export const LogbookCellGroupComponent: FC<LogbookCellGroupComponentProps> = ({
  children,
  onClick,
}) => {
  let hasNote = false;
  let topRightIcon: ReactNode | undefined;

  const count = React.Children.count(children);
  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) return;
    if ((child.props as LogbookCellComponentProps).hasNote) {
      hasNote = true;
    }
    if ((child.props as LogbookCellComponentProps).topRightIcon) {
      topRightIcon = (child.props as LogbookCellComponentProps).topRightIcon;
    }
  });

  const firstChild = React.Children.map(children, (child, i) => {
    // Ignore all children but the first child
    if (i < 1) return child;
    return null;
  });

  return (
    <div
      className={classNames({
        logbookCellGroup: true,
        pointer: !!onClick,
      })}
      onClick={onClick}
    >
      {firstChild}
      {count > 1 && <div className="count">{count}</div>}
      {topRightIcon && <div className="topRightIcon">{topRightIcon}</div>}
      {hasNote && <div className="noteTag" />}
    </div>
  );
};
