export enum OrderType {
  SUPPLIES = 'SUPPLIES',
  DEVICES = 'DEVICES',
}

export enum SupplyTypeEnum {
  BG_STRIP = 'BG_STRIP',
  LANCETS = 'LANCETS',
  BPM1_CUFF = 'BPM1_CUFF',
  EASE_CUFF = 'EASE_CUFF',
  WIFI_ROUTER = 'WIFI_ROUTER',
  HOTSPOT = 'HOTSPOT'
}
