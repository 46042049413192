import map from 'lodash/map';

export default class StrictFormData<T extends {
  [key: string]: unknown;
}> {
  // eslint-disable-next-line no-useless-constructor, no-empty-function
  constructor(private form: T) { }

  public get(): FormData {
    const form = new FormData();

    map(this.form, (value: string, key: string) => {
      if (key !== undefined) {
        form.append(key, value);
      }
    });

    return form;
  }

  keys() {
    return Object.keys(this.form);
  }

  values() {
    return Object.values(this.form);
  }

  entries() {
    return Object.entries(this.form);
  }
}
