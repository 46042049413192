import { Modal } from 'antd';
import { AITranscribeOnboardingComponent, AITranscribeOnboardingComponentProps } from './AITranscribeOnboardingComponent';
import { ModalWidth880 } from '../../constants/modal';

import './AITranscribeOnboardingModalComponent.scss';

export interface AITranscribeOnboardingModalComponentProps
  extends AITranscribeOnboardingComponentProps {
  open?: boolean;
  onClose?: () => void;
}

export const AITranscribeOnboardingModalComponent = ({
  open,
  onClose,
}: AITranscribeOnboardingModalComponentProps) => (
  <Modal
    open={open}
    width={ModalWidth880}
    className="ai-transcribe-onboarding-modal"
    centered
    footer={null}
    maskClosable={false}
    closable={false}
    destroyOnClose
  >
    <AITranscribeOnboardingComponent
      onDone={onClose}
    />
  </Modal>
);
