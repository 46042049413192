import { ReactNode } from 'react';
import { Icons } from '../../icons/Icons';
import { ErrorBoundaryCardComponent } from './ErrorBoundaryCardComponent';
import { ErrorBoundaryComponent } from './ErrorBoundaryComponent';
import { ErrorSimulatorPlaceHolderComponent } from './simulator/ErrorSimulator';
import './ErrorBoundaryFallbackComponent.scss';
import { ErrorBoundaryPage } from './errorBoundaryIdentifier';

const ErrorBoundaryPageFallbackComponent = () => (
  <div className="error-boundary-page-fallback-component">
    <ErrorBoundaryCardComponent
      icon={<Icons.AppCrashIcon />}
      message={`Oops, the care portal page crashed due to an error. Please navigate away then try again. If the issue persists, please reach out to IT
        support for assistance.`}
    />
  </div>
);

export interface ErrorBoundaryPageComponentProps {
  page: keyof typeof ErrorBoundaryPage;
  children: ReactNode;
}

export const ErrorBoundaryPageComponent = ({
  page,
  children,
}: ErrorBoundaryPageComponentProps) => (
  <ErrorBoundaryComponent
    identifier={page}
    FallbackComponent={ErrorBoundaryPageFallbackComponent}
  >
    <>
      {children}
      <ErrorSimulatorPlaceHolderComponent spot="Page" />
    </>
  </ErrorBoundaryComponent>
);
