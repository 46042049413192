import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { DischargeReasonEnumComponent } from '../../../../enumComponent/DischargeReasonEnumComponent/DischargeReasonEnumComponent';
import { AlertCardComponent } from '../../../../uiComponent/AlertCardComponent/AlertCardComponent';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { DischargeDevicesInfoComponent } from '../DischargeDevicesInfoComponent/DischargeDevicesInfoComponent';

import './DischargeAlertInfoComponent.scss';

export const DischargeAlertInfoComponent = () => {
  const { info: { enrolledProgramService } = {} } = usePatientContext();
  const dischargedDetail = enrolledProgramService?.getDischargedDetail?.();
  const isDischarged = enrolledProgramService?.isDischarged?.();

  const renderTooltip = () => {
    const { reason, note, devices } = dischargedDetail || {};
    return (
      <TextComponent size="medium" color="white">
        <TextComponent color="white" bold>
          <DischargeReasonEnumComponent value={reason} />
        </TextComponent>
        <div>{note}</div>
        {
          !!devices?.length
          && (
            <div className="block">
              The patient's device was
              {' '}
              <DischargeDevicesInfoComponent
                dischargeDevices={devices || []}
              />
              {'. '}
              After discharge, the device has been automatically unassigned.
            </div>
          )
        }
      </TextComponent>
    );
  };

  return isDischarged ? (
    <div>
      <AlertCardComponent
        type="error"
        message={(
          <div className="flex ai-c">
            <div>
              Patient was discharged on
            </div>
            <DisplayDateComponent value={dischargedDetail?.dischargedAt} format="USA_DATE" />
            <TooltipComponent
              type={null}
              title={renderTooltip()}
              overlayClassName="discharge-alert-info-tooltip"
              showArrow={false}
            >
              <LinkButton showUnderline>
                See Discharge Reason
              </LinkButton>
            </TooltipComponent>
          </div>
        )}
      />
    </div>
  ) : null;
};
