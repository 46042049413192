export interface MessageHistoryEmptyComponentProps { }

export const MessageHistoryEmptyComponent = () => (
  <div className="message-history-empty-component">
    <div>There are no messages on this channel</div>
    <br />
    <div>Please double check this patient&apos;s profile.</div>
    <div>If you think this is an error, please report this issue</div>
  </div>
);
