import { last, map } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { DiseaseHistory, DiseaseHistoryItem, usePatientUpsertDiseaseHistory } from '../../../../uc-api-sdk';
import { DiseaseHistoryFormComponent } from '../../component/DiseaseHistoryFormComponent/DiseaseHistoryFormComponent';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { FormType } from '../../../Input/types';
import { DiseaseHistorySubmitValue } from '../../hook/useDiseaseHistoryForm';

export interface DiseaseHistoryFormContainerProps extends FormType<DiseaseHistorySubmitValue> {
  patientId: string;
  visitId?: string;
  diseaseHistory?: DiseaseHistory | null;
  onSubmit?: () => void;
}

export const DiseaseHistoryFormContainer = ({
  patientId,
  visitId,
  diseaseHistory,
  isLoading,
  onSubmit,
  onError,
  onValuesChange,
  formButtons,
}: DiseaseHistoryFormContainerProps) => {
  const diseaseHistoryUpdateInfo = usePatientUpsertDiseaseHistory({});

  const handleUpdate = useDebounce(async (values: DiseaseHistorySubmitValue) => {
    const { diseaseHistoryList, diseaseHistoryNote = '' } = values;
    const request = {
      diseaseHistory: diseaseHistoryList,
      notes: [{ note: diseaseHistoryNote }],
      visitId,
    };
    const req = diseaseHistoryUpdateInfo.send({
      params: {
        memberId: patientId,
        request,
      },
    });
    ApiRequestHelper.tryCatch(req, {
      success: 'Updated disease history successfully.',
      error: 'Failed to update disease history.',
      onSuccess: onSubmit,
      onError,
    });
  });

  const loading = diseaseHistory === undefined || isLoading || diseaseHistoryUpdateInfo.isLoading;

  const diseaseHistoryList = useMemo(() => (
    map(diseaseHistory?.diseaseHistory || [], (v) => ({
      ...v,
      diagnosedYear: v.diagnosedYear
        ? moment().set('year', v.diagnosedYear)
        : v.diagnosedYear,
    } as DiseaseHistoryItem))
  ), [diseaseHistory?.diseaseHistory]);

  return (
    <LoadingOverlayComponent
      isLoading={loading}
      showSkeleton
    >
      <DiseaseHistoryFormComponent
        isLoading={loading}
        initialValues={{
          diseaseHistoryList,
          diseaseHistoryNote: last(diseaseHistory?.notes || [])?.note,
        }}
        onSubmit={handleUpdate}
        onValuesChange={onValuesChange}
        formButtons={formButtons}
      />
    </LoadingOverlayComponent>
  );
};
