import {
  Form,
  DatePicker,
  Checkbox,
  Row,
  Col,
  Input,
  Button
} from 'antd';
import { useState } from 'react';
import { Moment } from 'moment';
import { CloseOutlined } from '@ant-design/icons';
import { USA_DATE } from '../../../../constants/timeFormat';
import { useTranscribeForm } from '../../../../sample/hook/formHooks/useTranscribeForm';
import { TagComponent } from '../../../../uiComponent/TagComponent/TagComponent';
import { ResultInputComponent } from '../../../../sample/InputComponent/TranscribeFormItemComponent/ResultInputComponent/ResultInputComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import FormSubmitButton from '../../../../uiComponent/FormSubmitButton/FormSubmitButton';
import './TranscribeA1CFormComponent.scss';
import { Note } from '../../../../uc-api-sdk';
import { A1CNotesTimelineComponent } from '../A1CNotesTimelineComponent/A1CNotesTimelineComponent';

export interface A1CSubmitValue {
  testDate: Moment;
  testResult: string;
  isInHouse: boolean;
}

export interface NoA1CSubmitValue {
  isInputNote: string;
}

export interface TranscribeA1CFormComponentProps {
  onSubmit?: (value: A1CSubmitValue, isWithinValidRange: boolean) => void;
  onNoA1CSubmit?: (value: string) => void;
  initialValues?: Partial<A1CSubmitValue>;
  compliantTestWindowStartDay?: string;
  compliantTestWindowEndDay?: string;
  patientId: string;
  notes: Note[];
  hasNoA1CRecord: boolean;
}

export const TranscribeFormA1CComponent = ({
  onSubmit,
  onNoA1CSubmit,
  patientId,
  initialValues,
  compliantTestWindowStartDay,
  compliantTestWindowEndDay,
  notes,
  hasNoA1CRecord,
}: TranscribeA1CFormComponentProps) => {
  const transcribeForm = useTranscribeForm({
    compliantTestWindowStartDay,
    compliantTestWindowEndDay,
    patientId,
  });
  const [isValidSubmitted, setIsValidSubmitted] = useState(false);

  const handleA1CResultAdd = (testResult: A1CSubmitValue) => {
    let isWithinValidRange = false;
    if (transcribeForm.checkValidDate(testResult.testDate)) {
      isWithinValidRange = true;
    }
    setIsValidSubmitted(isWithinValidRange);
    onSubmit?.(testResult, isWithinValidRange);
  };

  const handleNoA1CNoteSubmit = (isInputNote: string) => {
    onNoA1CSubmit?.(isInputNote);
  };

  const handleNoA1CNoteOnClick = () => {
    handleNoA1CNoteSubmit(transcribeForm.getA1CInputNoteValue());
    transcribeForm.resetA1CNoteSubmitValue();
    transcribeForm.resetA1CInputNote();
  };

  const handleLeaveNoteOnClick = () => {
    transcribeForm.resetA1CNoteSubmitValue();
  };

  const handleLeaveNoteCloseIcon = () => {
    transcribeForm.resetA1CNoteSubmitValue();
  };

  const handleNoA1CNoteInputChange = (e: { target: { value: string; }; }) => {
    transcribeForm.form.setFieldsValue({ isInputNote: e.target.value });
    transcribeForm.handleA1CNoteChange(e.target.value);
  };

  return (
    <>
      <div className="a1c-test-window">
        {isValidSubmitted ? (
          <TagComponent
            type="success"
            background="none"
            iconType="default"
            iconColor="blue"
          >
            Great! Patient's A1C is compliant now.
          </TagComponent>
        ) : (
          <span>
            Compliant Test Window:
            {' '}
            {compliantTestWindowStartDay}
            {' '}
            -
            {' '}
            {compliantTestWindowEndDay}
          </span>
        )}
      </div>

      <Form
        className="transcribe-form-component"
        form={transcribeForm.form}
        layout="vertical"
        onFinish={transcribeForm.handleSubmitAndReset(handleA1CResultAdd)}
        disabled={isValidSubmitted}
        initialValues={{
          ...initialValues,
          isInHouse: false,
          hasNoA1CRecord,
        }}
      >
        <Row gutter={5}>
          <Col span={8}>
            {/* test date  */}
            <FormItem
              info={transcribeForm.getNameAndLabel('testDate')}
              rules={transcribeForm.getTestDateRules()}
            >
              <DatePicker
                className="w100"
                format={USA_DATE}
              />
            </FormItem>
          </Col>

          <Col span={8}>
            {/* result component */}
            <FormItem
              info={transcribeForm.getInfo('testResult')}
              rules={transcribeForm.getTestResultRules()}
            >
              <ResultInputComponent />
            </FormItem>
          </Col>

          <Col span={8}>
            {/* inHouse CheckBox */}
            <FormItem
              info={transcribeForm.getInfo('isInHouse')}
              valuePropName="checked"
            >
              <Checkbox
                className="in-house-test-checkbox"
              >
                In-house Test
              </Checkbox>
            </FormItem>
          </Col>
        </Row>

        {/* Add A1C Button */}
        {/* add button active if user select date and input valid result */}
        <FormItem noStyle>
          <FormSubmitButton
            className="mt10"
            text="Add"
            rightAlign={false}
            isLoading={false}
            // eslint-disable-next-line no-restricted-globals
            shouldBeDisabled={(values) => (!values.testDate || values.testResult === '' || isNaN(values.testResult))}
          />
        </FormItem>

        {/* no A1C Record Checkbox  */}
        <div className="transcribe-form-component-engagement">
          <FormItem
            info={transcribeForm.getInfo('hasNoA1CRecord')}
            valuePropName="checked"
            hidden={isValidSubmitted}
          >
            {/* show no A1C Record Checkbox when (isValidSubmitted == false)  */}
            <Checkbox>
              No A1C record in target compliance window
            </Checkbox>
          </FormItem>
        </div>

        {/* Leave Note Link */}
        <FormItem
          noStyle
          shouldUpdate={transcribeForm.shouldUpdate(['hasNoA1CRecord', 'isA1CNoteSubmit'])}
          hidden={isValidSubmitted}
        >
          {({ getFieldValue }) => {
            const show = transcribeForm.shouldShowA1CLeaveNote(getFieldValue);
            const showButton = !transcribeForm.shouldShowA1CNoteInputArea(getFieldValue);
            return (
              show ? (
                <>
                  {!!notes.length && (
                    <div className="pl20 mt20">
                      <A1CNotesTimelineComponent notes={notes} />
                    </div>
                  )}
                  {showButton && (
                    <Button
                      type="link"
                      className="a1c-leave-note-link p0 mt10"
                      onClick={handleLeaveNoteOnClick}
                    >
                      {notes.length ? 'Leave another note' : 'Leave note'}
                    </Button>
                  )}
                </>
              ) : null
            );
          }}
        </FormItem>

        {/* Leave Note Submit Button */}
        {/* 1)if user submitted note then render [ Leave another note ] */}
        {/* 2)if user have not submitted note render [ input area & submit button ] */}
        {/* 3)if user did not click leave-note-link then render [ null ] */}
        <FormItem
          noStyle
          shouldUpdate={transcribeForm.shouldUpdate(['hasNoA1CRecord', 'isA1CNoteSubmit', 'isInputNote'])}
          hidden={isValidSubmitted}
        >
          {({ getFieldValue }) => {
            const show = transcribeForm.shouldShowA1CNoteInputArea(getFieldValue);
            return show ? (
              <FormItem
                info={transcribeForm.getInfo('isA1CNoteSubmit')}
              >
                <div className="a1c-note-input-wrapper">
                  <Input.TextArea
                    placeholder="Add Note Here"
                    value={getFieldValue('isInputNote')}
                    onChange={handleNoA1CNoteInputChange}
                  />
                  <CloseOutlined
                    className="a1c-note-close-icon"
                    onClick={handleLeaveNoteCloseIcon}
                  />
                </div>
                <Button
                  className="a1c-note-submit-button"
                  type="primary"
                  disabled={!getFieldValue('isInputNote')}
                  onClick={handleNoA1CNoteOnClick}
                >
                  Submit
                </Button>
              </FormItem>
            ) : null;
          }}
        </FormItem>
      </Form>
    </>
  );
};
