import { ReactNode } from 'react';

export interface YesNoDisplayComponentProps {
  value?: boolean | null;
  yesDisplay?: ReactNode;
  noDisplay?: ReactNode;
  nilDisplay?: ReactNode;
}

export const YesNoDisplayComponent = ({
  value,
  yesDisplay = 'Yes',
  noDisplay = 'No',
  nilDisplay = null,
}: YesNoDisplayComponentProps) => {
  const renderDisplay = (value: boolean) => (
    <span className="yes-no-display">
      {value ? yesDisplay : noDisplay}
    </span>
  );

  return (
    typeof value === 'boolean'
      ? renderDisplay(value)
      : <div>{nilDisplay}</div>
  );
};
