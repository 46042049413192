import {
  GoogleOAuthProvider,
  GoogleLogin,
  CredentialResponse,
} from '@react-oauth/google';
import EnvConfig from '../../../../configs/envConfig/envConfig';

import './GoogleLoginComponent.scss';

export interface GoogleLoginComponentProps {
  onSuccess: (credential?: string) => void,
  onError?: () => void
}
export const GoogleLoginComponent = ({
  onSuccess,
  onError,
}: GoogleLoginComponentProps) => (
  <div
    className="google-login-button"
    data-testid="googleLoginButton"
  >
    <GoogleOAuthProvider
      clientId={EnvConfig.googleClientId}
    >
      <GoogleLogin
        onSuccess={(res: CredentialResponse) => {
          onSuccess?.(res.credential);
        }}
        onError={() => {
          onError?.();
        }}
        width="400px"
        size="large"
      />
    </GoogleOAuthProvider>
  </div>
);
