import { ExpandAltOutlined, MinusOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';
import classNames from 'classnames';
import { MinimizableContextValue, minimizedClass, useMinimizableContext } from './Minimizable';
import { ClickableDiv } from '../../uiComponent/ClickableDiv/ClickableDiv';

export interface MinimizableControlComponentProps {
  children: ReactNode | ((props: MinimizableContextValue) => ReactNode);
  className?: string;
  disabled?: boolean;
  onMinimized?: () => void;
  onMaximized?: () => void;
  customButtons?: ReactNode;
}

export const MinimizableControlComponent = ({
  children,
  className = '',
  disabled,
  onMinimized,
  onMaximized,
  customButtons,
}: MinimizableControlComponentProps) => {
  const {
    isMinimized,
    maximize,
    minimize,
  } = useMinimizableContext();

  const handleClick = () => {
    if (disabled) return;
    if (isMinimized) {
      maximize();
      onMaximized?.();
    } else {
      minimize();
      onMinimized?.();
    }
  };

  return (
    <div
      className={classNames({
        'minimizable-control': true,
        [minimizedClass]: isMinimized,
      })}
    >
      {
        typeof children === 'function'
          ? (
            children?.({ isMinimized, maximize, minimize })
          ) : (
            <div
              className={classNames({
                'flex jc-sb ai-c': true,
                [className]: !!className
              })}
            >
              <div className="f1">
                {children}
              </div>
              <ClickableDiv
                className="minimizable-control__buttons"
                onClick={handleClick}
              >
                {
                  isMinimized
                    ? <ExpandAltOutlined onClick={maximize} disabled={disabled} />
                    : <MinusOutlined onClick={minimize} disabled={disabled} />
                }
              </ClickableDiv>
              <div className="minimizable-control__custom-buttons">
                {customButtons}
              </div>
            </div>
          )
      }
    </div>
  );
};
