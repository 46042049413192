import { isNil } from 'lodash';
import { DiseaseHistoryItem, FrequencyUnitEnum } from '../../../../uc-api-sdk';
import { FreqComponent } from '../../../../uiComponent/FreqComponent/FreqComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface DiseaseHistoryMeasureFrequencyDisplayComponentProps {
  diseaseHistory?: DiseaseHistoryItem;
  itemClassName?: string;
}

export const DiseaseHistoryMeasureFrequencyDisplayComponent = ({
  diseaseHistory,
  itemClassName = 'disease-history-item'
}: DiseaseHistoryMeasureFrequencyDisplayComponentProps) => (
  <div className={itemClassName}>
    <span className="label">Measure Frequency:</span>
    {
      (!isNil(diseaseHistory?.measureFrequency?.times)
        && (diseaseHistory?.measureFrequency?.frequency))
        ? (
          <FreqComponent
            value={diseaseHistory?.measureFrequency?.times ?? 0}
            unit={diseaseHistory?.measureFrequency?.frequency as FrequencyUnitEnum}
          />
        ) : <DisplayOrEmptyComponent />
    }

  </div>
);
