import React, { KeyboardEventHandler, useCallback, useState } from 'react';
import { Input } from 'antd';
import { InputType } from '../../../Input/types';

import './MessageTextAreaComponent.scss';
import { RewriteWithAIModalButtonComponent } from '../../../rewriteWithAI/component/RewriteWithAIModalButtonComponent/RewriteWithAIModalButtonComponent';

const { TextArea } = Input;

export interface MessageTextAreaComponentProps extends InputType<string> {
  isTranslation?: boolean;
  placeholder?: string;
  disabled?: boolean;
  onPressEnter?: KeyboardEventHandler<HTMLTextAreaElement>
}

export const MessageTextAreaComponent = ({
  value,
  placeholder,
  disabled,
  onChange,
  isTranslation,
  onPressEnter,
}: MessageTextAreaComponentProps) => {
  const [originalContent, setOriginalContent] = useState(''); // Manage original content separately

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    onChange?.(newValue);
  };

  const handleRewriteButtonClick = useCallback(() => {
    setOriginalContent(value ?? '');
  }, [value]);

  return (
    <div className={isTranslation ? 'text-area' : ''}>
      <TextArea
        placeholder={placeholder || 'Type message...'}
        rows={3}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        onPressEnter={onPressEnter}
        className="w100"
      />
      {value && (
      <RewriteWithAIModalButtonComponent
        mode="rewriteMessage"
        originalContent={originalContent}
        onClick={handleRewriteButtonClick}
      />
      )}
    </div>
  );
};
