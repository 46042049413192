import dayjs, { Dayjs } from 'dayjs';
import { MessageChannelSectionHrComponent } from '../MessageChannelSectionHrComponent/MessageChannelSectionHrComponent';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';

export interface MessageChannelSectionHrDateComponentProps {
  date: Dayjs;
}

export const MessageChannelSectionHrDateComponent = ({
  date,
}: MessageChannelSectionHrDateComponentProps) => {
  const isSameDay = dayjs().isSame(date, 'day');

  return (
    <MessageChannelSectionHrComponent
      showDivider={false}
    >
      {
        isSameDay
          ? <span>Today</span>
          : (
            <DisplayDateComponent value={date} format="MESSAGE_DATE_FORMAT" />
          )
      }
    </MessageChannelSectionHrComponent>
  );
};
