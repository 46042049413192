import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { useTableColumnWithFiltersAndSorters } from '../../../../hooks/useTableColumnWithFiltersAndSorters/useTableColumnWithFiltersAndSorters';
import { PatientsTableComponent } from '../../../patient/component/PatientsTableComponent/PatientsTableComponent';
import { PatientTableProps } from '../../../patients/type';
import { createProviderViewPatientColumns } from '../ProviderViewTablesComponent/columns';

export interface ProviderViewWatchedPatientsTableComponentProps extends PatientTableProps {
}

export const ProviderViewWatchedPatientsTableComponent = ({
  totalSize,
  filters,
  sorters,
  ...props
}: ProviderViewWatchedPatientsTableComponentProps) => {
  const columns = useTableColumns(
    createProviderViewPatientColumns,
    ['name', 'age', 'gender', 'clinic', 'addedTime', 'note', 'action'],
  );

  const columnsWithFilters = useTableColumnWithFiltersAndSorters({
    columns,
    filters,
    sorters,
  });

  return (
    <PatientsTableComponent
      totalSize={totalSize}
      columns={columnsWithFilters}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};
