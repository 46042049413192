import { BGHelper } from '../../../vitals/helper/BGHelper';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { EmptyStringMini } from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import { GlucoseLevels } from '../../container/FoodLogProviderFormContainer/FoodLogProviderFormContainer';

interface FoodLogGlucoseResultsComponentProps {
  data?: GlucoseLevels;
}

const GlucoseResult = ({
  label, value, time
}: { label: string; value?: number; time?: string }) => (
  <div>
    {label}
    :
    {' '}
    {value != null ? BGHelper.mmollToMgdl(value) : EmptyStringMini}
    {' '}
    {value != null ? BGHelper.getMgdlUnit() : ''}
    {time && (
      <span className="secondary-gray">
        {' '}
        (
        <DisplayDateComponent
          value={time}
          format="TIME_AM_PM"
        />
        )
      </span>
    )}
  </div>
);

export const FoodLogGlucoseResultsComponent = ({
  data,
}: FoodLogGlucoseResultsComponentProps) => (
  <div>
    <div className="b6">Glucose Results:</div>
    <GlucoseResult
      label="Before Meal"
      value={data?.beforeMeal}
      time={data?.beforeMealTime}
    />
    <GlucoseResult
      label="After Meal"
      value={data?.afterMeal}
      time={data?.afterMealTime}
    />
  </div>
);
