import { Measurement, ResultSourceEnum } from '../../../uc-api-sdk';
import { MeasurementType } from '../type/MeasurementTypes';
import { HSResultHelper } from './HSResultHelper';
import { MeasurementService } from './MeasurementService';

export class HSMeasurementService extends MeasurementService {
  constructor(measurement: Measurement) {
    super(measurement as MeasurementType);
  }

  static getColor(source: ResultSourceEnum) {
    switch (source) {
      case ResultSourceEnum.VISIT:
      case ResultSourceEnum.PROFILE:
      case ResultSourceEnum.EHR:
      case ResultSourceEnum.TRANSCRIBING:
        return '#00C8BC';
      default:
        return '#D5D8DC';
    }
  }

  get getColor() {
    return HSMeasurementService.getColor(this.value.resultSource);
  }

  get resultSource() {
    return this.value.resultSource;
  }

  get isInClinic() {
    return [
      ResultSourceEnum.VISIT,
      ResultSourceEnum.PROFILE,
      ResultSourceEnum.EHR,
      ResultSourceEnum.TRANSCRIBING
    ].includes(this.value.resultSource);
  }

  get getCellValue() {
    return HSResultHelper.toDataTable(this.value);
  }
}
