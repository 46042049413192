import React from 'react';
import { GenderIdentityEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface GenderIdentityEnumComponentProps {
  value?: GenderIdentityEnum;
}

export const GenderIdentityEnumComponent = ({ value }: GenderIdentityEnumComponentProps) => {
  const getFullName = () => {
    switch (value) {
      case GenderIdentityEnum.MAN: return 'Man';
      case GenderIdentityEnum.WOMAN: return 'Woman';
      case GenderIdentityEnum.NON_BINARY: return 'Non-binary';
      case GenderIdentityEnum.SELF_IDENTIFY: return 'Self-identity';
      case GenderIdentityEnum.PREFER_NOT_TO_SAY: return 'Prefer not to say';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  return (<>{getFullName()}</>);
};
