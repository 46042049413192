/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable max-len */
// This file should be removed once the code gen starts generating baseController
import { useMemo } from 'react';
import {
  APIResponse, APIResponseClass, PageResponse, Article, articleService,
} from '../../../../uc-api-sdk';
import {
  GetOptions, MakeRequestHookConfig, UseRequestFactory, useRequestFactory,
  BaseAPIResponse, BaseSearchParams, BaseSortInfo,
} from '../../../../uc-api-sdk/staticFiles/useReqHook';

interface NoPageSizeBaseSearchParams<T> extends Omit<BaseSearchParams<T>, 'pageInfo'> {
  pageInfo: {
    page?: number;
    size?: number;
    pagination: boolean;
    sort?: BaseSortInfo[];
  }
}

export const createGetArticleObject = (value: APIResponse<PageResponse<Article>>) => new APIResponseClass<PageResponse<Article>>(value);

export const usePostSearchArticles = <RCN = undefined>(configs: MakeRequestHookConfig<NoPageSizeBaseSearchParams<Article>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    NoPageSizeBaseSearchParams<Article>,
    BaseAPIResponse<PageResponse<Article>>,
    // @ts-ignore
    typeof articleService.search,
    GetOptions,
    APIResponseClass<PageResponse<Article>>
  > = useMemo(() => ({
    reqFunc: (...args: Parameters<typeof articleService.search>) => (
      articleService.search(...args)
    ),
    ResponseClassCreator: createGetArticleObject,
    typeName: 'usePostSearchArticles',
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};
