import classNames from 'classnames';
import {
  createContext, ReactNode, useCallback,
  useContext
} from 'react';
import { LargeDrawerWidth, MediumDrawerWidth } from '../../../../constants/drawer';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { useGetContextValue } from '../../../../hooks/useGetContextValue/useGetContextValue';
import { Transcribe } from '../../../../uc-api-sdk';
import { DefaultExtendableDrawers } from '../../../../uiComponent/ExtendableDrawerComponent/controller/DefaultExtendableDrawers';
import { ExtendableDrawerController, ExtendableDrawerControllerProps } from '../../../../uiComponent/ExtendableDrawerComponent/controller/ExtendableDrawerController';
import { ExtendableDrawerComponent, ExtendDrawerStyleEnum } from '../../../../uiComponent/ExtendableDrawerComponent/ExtendableDrawerComponent';
import { DEFAULT_TRANSCRIBE_DRAWER_ROOT_ID } from '../../constants';
import { TranscribeContainer, TranscribeContainerProps } from '../../container/TranscribeContainer/TranscribeContainer';

import './TranscribeDrawerComponent.scss';
import { BlockUnmountContextProvider, useBlockUnmountContext } from '../../../../contexts/BlockUnmountContext/BlockUnmountContext';

export interface TranscribeDrawerComponentControllerProps
  extends Omit<ExtendableDrawerControllerProps, 'rootId' | 'children'> {
  rootId?: DefaultExtendableDrawers;
}

export interface TranscribeDrawerComponentContextValue {
  handleOpenTranscribeDrawer: (transcribeId: string, transcribeData?: Transcribe) => void;
  handleCloseTranscribeDrawer: () => void;
}

const TranscribeDrawerComponentContext = createContext<
  TranscribeDrawerComponentContextValue | undefined
>(undefined);

export const useTranscribeDrawer = () => {
  const context = useContext(TranscribeDrawerComponentContext);
  return (context || {}) as TranscribeDrawerComponentContextValue;
};

export interface TranscribeDrawerWrapperProps {
  children: ReactNode | ((props: TranscribeDrawerComponentContextValue) => ReactNode);
}

export interface TranscribeDrawerComponentProps extends TranscribeDrawerComponentControllerProps {
  transcribeId?: string;
  transcribeData?: Transcribe;
  beforeOpen?: (trancribeId: string, transcribeData?: Transcribe) => Promise<void> | void;
  afterOpen?: (trancribeId: string, transcribeData?: Transcribe) => void;
  beforeClose?: () => Promise<void> | void;
  afterClose?: () => void;
  children: [
    TranscribeContainerProps['children'],
    TranscribeDrawerWrapperProps['children'] | undefined,
  ];
  defaultOpen?: boolean;
  className?: string;
}

const TranscribeDrawer = ({
  transcribeId,
  transcribeData,
  beforeOpen,
  afterOpen,
  beforeClose,
  afterClose,
  children,
  defaultOpen,
  rootId = DEFAULT_TRANSCRIBE_DRAWER_ROOT_ID,
  rootWidth = LargeDrawerWidth,
  extendedWidthMap = MediumDrawerWidth,
  extendDrawerStyleMap = ExtendDrawerStyleEnum.ReplaceOthers,
  className = '',
  ...controllerProps
}: TranscribeDrawerComponentProps) => {
  const updateHook = useUpdate('TRANSCRIBE_DRAWER_CLOSED');
  const {
    value: openTranscribeDrawer,
    setTrue: setOpenTranscribeDrawer,
    setFalse: setCloseTranscribeDrawer,
  } = useBoolean(defaultOpen);
  const [DrawerBody, Wrapper] = children;
  const {
    checkIfBlocked,
    blockPrompt,
  } = useBlockUnmountContext();

  const handleOpenTranscribeDrawer: TranscribeDrawerComponentContextValue['handleOpenTranscribeDrawer'] = async (
    transcribeId,
    transcribeData,
  ) => {
    await beforeOpen?.(transcribeId, transcribeData);
    setOpenTranscribeDrawer();
    afterOpen?.(transcribeId, transcribeData);
  };

  const handleCloseTranscribeDrawer: TranscribeDrawerComponentContextValue['handleCloseTranscribeDrawer'] = async () => {
    await beforeClose?.();
    setCloseTranscribeDrawer();
    updateHook.updateValue();
    afterClose?.();
  };

  const contextValue = useGetContextValue<TranscribeDrawerComponentContextValue>({
    handleOpenTranscribeDrawer,
    handleCloseTranscribeDrawer,
  });

  const renderWrapper = useCallback(() => {
    if (!Wrapper) return '';
    if (typeof Wrapper === 'function') {
      return Wrapper({
        handleOpenTranscribeDrawer,
        handleCloseTranscribeDrawer,
      });
    }
    return Wrapper;
  }, [Wrapper]);

  return (
    <TranscribeDrawerComponentContext.Provider value={contextValue}>
      {renderWrapper()}
      <ExtendableDrawerController
        rootId={rootId}
        rootWidth={rootWidth}
        extendedWidthMap={extendedWidthMap}
        extendDrawerStyleMap={extendDrawerStyleMap}
        extendedOnCloseMap={{
          [rootId]: {
            beforeOnClose: async () => {
              if (checkIfBlocked?.()) {
                const unblocked = await blockPrompt?.();
                if (!unblocked) throw new Error('');
              }
            }
          }
        }}
        onExtendableDrawerButtonClick={async () => {
          if (checkIfBlocked?.()) {
            const unblocked = await blockPrompt?.();
            if (!unblocked) return false;
          }
          return true;
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...controllerProps}
      >
        <ExtendableDrawerComponent
          id={rootId}
          open={openTranscribeDrawer}
          onClose={handleCloseTranscribeDrawer}
          width={rootWidth}
          className={classNames({
            'transcribe-extendable-drawer': true,
            'transcribe-fix-btn': true,
            [className]: !!className,
          })}
          maskClosable={false}
          keyboard={false}
        >
          <TranscribeContainer
            transcribeId={transcribeId}
            transcribeData={transcribeData}
          >
            {DrawerBody}
          </TranscribeContainer>
        </ExtendableDrawerComponent>
      </ExtendableDrawerController>
    </TranscribeDrawerComponentContext.Provider>
  );
};

export const TranscribeDrawerComponent = (props: TranscribeDrawerComponentProps) => (
  <BlockUnmountContextProvider>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <TranscribeDrawer {...props} />
  </BlockUnmountContextProvider>
);
