import React, { FC } from 'react';
import { MeasurementResultTypeEnum } from '../../../../uc-api-sdk';
import { BPSummaryTableDataProcessorComponent } from '../BPSummaryTableComponent/BPSummaryTableDataProcessorComponent';
import { BPTableDataProcessorComponent } from '../BPTableComponent/BPTableDataProcessorComponent';
import { BPChartDataProcessorComponent } from '../BPChartComponent/BPChartDataProcessorComponent';
import { VitalChartTableSummaryViewComponent, VitalChartTableSummaryViewComponentProps } from '../VitalChartTableSummaryViewComponent/VitalChartTableSummaryViewComponent';
import { VitalFetchProps } from '../../type';
import { MeasurementType } from '../../type/MeasurementTypes';

export interface BPVitalComponentProps extends VitalFetchProps {

}

export const BPVitalComponent: FC<BPVitalComponentProps> = ({
  memberId,
  fromDate,
  toDate,
}) => {
  const chartView: VitalChartTableSummaryViewComponentProps['chartView'] = ({ data, fromDate, toDate }) => (
    <BPChartDataProcessorComponent
      data={(data?.results?.content || []) as MeasurementType[]}
      fromDate={fromDate}
      toDate={toDate}
    />
  );
  const tableView: VitalChartTableSummaryViewComponentProps['tableView'] = ({ data, fromDate, toDate }) => (
    <BPTableDataProcessorComponent
      data={(data?.results?.content || []) as MeasurementType[]}
      fromDate={fromDate}
      toDate={toDate}
    />
  );
  const summaryView: VitalChartTableSummaryViewComponentProps['summaryTableView'] = ({ data }) => (
    <BPSummaryTableDataProcessorComponent
      data={data?.stats?.bp || {}}
    />
  );

  return (
    <VitalChartTableSummaryViewComponent
      type={MeasurementResultTypeEnum.BP}
      memberId={memberId}
      title="Blood Pressure"
      unit="mm/Hg"
      chartView={chartView}
      tableView={tableView}
      summaryTableView={summaryView}
      fromDate={fromDate}
      toDate={toDate}
    />
  );
};
