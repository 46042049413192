/* eslint-disable no-inline-styles/no-inline-styles */
import { SwapRightOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { createRequiredRule } from '../../helpers/form';
import { TimeService } from '../../helpers/time/timeService';
import FormItem from '../FormItem/FormItem';
import { TimeStringSelectComponent } from '../TimeStringSelectComponent/TimeStringSelectComponent';

export const OfflineCallSettingsComponent = () => (
  <div className="bg-gray5 default-br p20 flex fd-c gap2 w100">
    <div>
      <div className="text-gray-scale-2 mb10">
        Please only add offline calls that are not tech assistance only
        or troubleshooting only, as these calls are non-billable:
      </div>
      <div className="text-gray-scale-2 hasRequiredLabel">
        Offline call time
      </div>
      <div className="flex gap2">
        <FormItem
          name="startTime"
          required
          className="w140"
          rules={[
            createRequiredRule(),
            {
              validator: (_, value) => TimeService.validateTimeLabel(value)
            }
          ]}
        >
          <TimeStringSelectComponent placeholder="Start time" />
        </FormItem>
        <SwapRightOutlined style={{ color: 'rgba(0,0,0,0.4)' }} />
        <FormItem dependencies={['startTime']}>
          {({ getFieldValue }) => {
            const startTime = getFieldValue('startTime');
            return (
              <FormItem
                name="endTime"
                required
                className="w140"
                initialValue={startTime}
                rules={[
                  createRequiredRule(),
                  {
                    validator: (_, value) => TimeService.validateTimeLabel(value)
                  }
                ]}
              >
                <TimeStringSelectComponent placeholder="End time" minValue={startTime} />
              </FormItem>
            );
          }}
        </FormItem>
      </div>
    </div>
    <FormItem
      name="note"
      label="Call Note"
      required
    >
      <TextArea
        placeholder="Enter call note"
        autoSize={{ minRows: 3, maxRows: 5 }}
      />
    </FormItem>
  </div>
);
