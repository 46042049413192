import { ReactNode } from 'react';
import { Card } from 'antd';
import { compact, find, map } from 'lodash';
import { WarningFilled } from '@ant-design/icons';
import { OrderSuppliesFormValues, OrderDeviceFormValues } from '../../hook/useOrderFormHook';
import { useGetDevicesToReturn } from '../../hook/useGetDevicesToReturn';
import { ConnectedDeviceInfo } from '../../../device/type';
import { useDeepCompareMemo } from '../../../../hooks/useDeepCompareEffect';
import { SupplyTypeEnum } from '../../type';
import { DeviceModelEnum } from '../../../../uc-api-sdk';
import { SupplyEnumComponent } from '../SupplyEnumComponent/SupplyEnumComponent';
import { SupplyImageComponent } from '../SupplyImageComponent/SupplyImageComponent';
import { OrderDeviceWithIdComponent } from '../OrderDeviceWithIdComponent/OrderDeviceWithIdComponent';
import { DeviceImageComponent } from '../../../device/DeviceImageComponent';

export interface UnassignDevice {
  deviceId: string;
  deviceModel: DeviceModelEnum;
  newDeviceId: string;
}

interface OrderReturnDeviceValue {
  name: ReactNode;
  image: ReactNode;
}

export interface OrderReturnReminderComponentProps {
  suppliesList: OrderSuppliesFormValues;
  devicesList: OrderDeviceFormValues;
  allMostRecentDevices?: ConnectedDeviceInfo[];
  unassignedDevices?: UnassignDevice[];
  onUnassign?: (unassignDevice: UnassignDevice) => void;
}

export const OrderReturnReminderComponent = ({
  suppliesList,
  devicesList,
  allMostRecentDevices,
  unassignedDevices,
  onUnassign,
}: OrderReturnReminderComponentProps) => {
  const getDevicesToReturn = useGetDevicesToReturn();
  const devicesToReturn = getDevicesToReturn(suppliesList, devicesList, allMostRecentDevices);

  const isUnassigned = (deviceId: string) => find(unassignedDevices, { deviceId }) !== undefined;

  const devices = useDeepCompareMemo(() => {
    const processedDevices = map(devicesToReturn, (device) => {
      const { deviceId, typeModel, newDeviceId } = device;
      switch (typeModel) {
        case SupplyTypeEnum.WIFI_ROUTER:
        case SupplyTypeEnum.HOTSPOT:
          return {
            name: <SupplyEnumComponent value={typeModel} />,
            image: <SupplyImageComponent value={typeModel} />,
          };
        case DeviceModelEnum.BPM1:
        case DeviceModelEnum.BPM1_HOTSPOT:
        case DeviceModelEnum.LOAN_DEVICE:
        case DeviceModelEnum.PY_802_LTE:
        case DeviceModelEnum.BIOLAND:
          return {
            name: (
              <OrderDeviceWithIdComponent
                deviceModel={typeModel}
                deviceId={deviceId}
                onUnassign={(
                  (!isUnassigned(deviceId as string) && onUnassign)
                    ? () => (
                      onUnassign({
                        deviceId: deviceId as string,
                        deviceModel: typeModel as DeviceModelEnum,
                        newDeviceId: newDeviceId as string,
                      })
                    ) : undefined
                )}
              />
            ),
            image: <DeviceImageComponent deviceModel={typeModel} />,
          };
        default:
          return null;
      }
    });
    return compact(processedDevices) as OrderReturnDeviceValue[];
  }, [
    devicesToReturn,
    map(unassignedDevices, 'id'),
  ]);

  if (!devices || devices.length === 0) {
    return null;
  }

  return (
    <>
      <div className="flex f-w ai-city">
        {/* eslint-disable-next-line no-inline-styles/no-inline-styles */}
        <WarningFilled style={{ color: 'var(--secondary-orange)' }} />
        Please note the following problematic device is required to return.
      </div>
      <Card className="no-border default-radius bg-gray7">
        <div className="flex f-w">
          {
            map(devices, (device, index) => (
              <div key={index} className="flex f-w ai-c mb10 mr50">
                <div className="order-info__image mr10">
                  {device.image}
                </div>
                <div>
                  {device.name}
                </div>
              </div>
            ))
          }
        </div>
      </Card>
    </>
  );
};
