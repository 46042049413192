import { InfoCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd/lib';
import { GenderTexts } from '../../../features/patient/constants';
import { FormOptions, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';

export const useScreeningForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    conditions: {
      name: 'conditions',
      label: '',
    },
    firstName: {
      name: 'firstName',
      label: 'First Name',
      required: true,
    },
    lastName: {
      name: 'lastName',
      label: 'Last Name',
      required: true,
    },
    clinicId: {
      name: 'clinicId',
      label: 'Clinic',
      required: true,
    },
    doctorId: {
      name: 'doctorId',
      label: 'Provider',
      required: true,
    },
    gender: {
      name: 'gender',
      label: 'Sex at birth',
      tooltip: {
        tip: GenderTexts.genderTooltip,
        type: 'info-icon',
      },
      required: true,
    },
    birthday: {
      name: 'birthday',
      label: 'DOB',
      required: true,
    },
    phoneType1: {
      name: 'phoneType1',
      label: 'Phone 1',
    },
    phone1: {
      name: 'phone1',
      label: ' ',
    },
    phoneType2: {
      name: 'phoneType2',
      label: 'Phone 2',
    },
    phone2: {
      name: 'phone2',
      label: ' ',
    },
    email: {
      name: 'email',
      label: 'Email',
    },
    spokenLanguage: {
      name: 'spokenLanguage',
      label: 'Preferred Spoken Language',
    },
    race: {
      name: 'race',
      label: 'Race',
    },
    medicalRecordId: {
      name: 'medicalRecordId',
      label: (
        <>
          <span className="mr10">Medical Record Number (MRN)</span>
          <Tooltip title="Medical Record Number also called medical record ID">
            <InfoCircleFilled />
          </Tooltip>
        </>
      ),
    },
    nickName: {
      name: 'nickName',
      label: 'Nickname',
    },
    loginId: {
      name: 'loginId',
      label: 'User Id',
      required: true,
    },
    isTestUser: {
      name: 'isTestUser',
      label: 'The patient is a test patient.',
    },
    canUseForLogin: {
      name: 'canUseForLogin',
      label: 'Enable OTP login',
    },
    insuranceList: {
      name: 'insuranceList',
      label: '',
    }
  }, options);

  return factory;
};
