export enum PeriodUnitEnum {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export interface PeriodValue {
  key: string;
  value: number;
  unit: PeriodUnitEnum;
}
