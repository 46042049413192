import classNames from 'classnames';
import { kebabCase, toUpper } from 'lodash';
import { TagComponent } from '../../../../../uiComponent/TagComponent/TagComponent';
import DisplayOrEmptyComponent from '../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { VitalEnumType } from '../../../../../uc-api-sdk';

import './MessageVitalSingleDisplaySeverityComponent.scss';

export interface MessageVitalSingleDisplaySeverityComponentProps {
  vitalType: VitalEnumType;
  severity: string;
  backgroundColor?: string;
}

export const MessageVitalSingleDisplaySeverityComponent = ({
  vitalType,
  severity,
  backgroundColor,
}: MessageVitalSingleDisplaySeverityComponentProps) => (
  <TagComponent
    className={classNames({
      'message-vital-single-value__severity': true,
      [`${vitalType}-${toUpper(kebabCase(severity))}`]: !backgroundColor,
    })}
    background="oval"
    style={{ backgroundColor }}
  >
    <DisplayOrEmptyComponent value={toUpper(severity)} />
  </TagComponent>
);
