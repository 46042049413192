import React, { FC } from 'react';
import { Select, SelectProps } from 'antd';
import { TICKET_PRIORITIES } from './constants';
import './style.scss';

export interface TicketPrioritySelectComponentProps {
  value?: string;
  onChange?: SelectProps['onChange'];
}

const { Option } = Select;

export const TicketPrioritySelectComponent: FC<TicketPrioritySelectComponentProps> = ({
  value,
  onChange,
}) => (
  <Select
    value={value}
    popupClassName="ticketPriorityDropdown"
    className={`ticketPriority ${value?.toLowerCase()}`}
    onChange={onChange}
  >
    {TICKET_PRIORITIES.map((ticket) => (
      <Option
        key={ticket.value}
        className={ticket.value.toLowerCase()}
        value={ticket.value}
      >
        {ticket.label}
      </Option>
    ))}
  </Select>
);
