import { useState } from 'react';
import { useTranslateTranslate } from '../../../uc-api-sdk';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { _StorageKeyEnum } from '../../../hooks/useSessionStorage/storageKey';

export const useTranslation = (messageId?: string) => {
  const [translatedNote, setTranslatedNote] = useState<string | undefined>(undefined);
  const [showTranslation, setShowTranslation] = useState<boolean>(true);
  const [storedTranslation, setStoredTranslation] = useSessionStorage<string | null>(
    `${_StorageKeyEnum.TRANSLATION_PREFIX}${messageId}` as _StorageKeyEnum
  );
  const translateAPI = useTranslateTranslate({});

  const translate = async (note: string) => {
    try {
      const response = await translateAPI.send({
        params: {
          request: {
            content: note,
            targetLanguage: 'English',
          }
        }
      });

      if (response && response.data) {
        const { translatedContent, sourceLanguage } = response.data;
        setStoredTranslation(translatedContent || '');
        setTranslatedNote(translatedContent || '');
        if (sourceLanguage === 'English') {
          setShowTranslation(false);
          setStoredTranslation(null);
        } else {
          setShowTranslation(true);
        }
      } else {
        console.error('Translation response is null or undefined');
      }
    } catch (error) {
      console.error('Translation error:', error);
    }
  };

  return {
    storedTranslation,
    translatedNote,
    showTranslation,
    translate,
  };
};
