import { ResultSourceEnum } from '../../uc-api-sdk';

export interface ResultSourceEnumComponentProps {
  value?: ResultSourceEnum;
}

export const ResultSourceEnumComponent = ({
  value,
}: ResultSourceEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case ResultSourceEnum.PROFILE: return 'CT manual update via profile';
      case ResultSourceEnum.MANUALLY_INPUT: return 'Patient manual input from app';
      case ResultSourceEnum.MEASUREMENT: return 'Weight device sync from app';
      case ResultSourceEnum.VISIT: return 'CT update during the visit';
      case ResultSourceEnum.TRANSCRIBING: return 'CT manual transcribing';
      case ResultSourceEnum.EHR: return 'EHR digital sync';
      default: return null;
    }
  };

  return (
    <span>{getText()}</span>
  );
};
