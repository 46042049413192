import { Button, Card } from 'antd';
import { InputTypeArray } from '../../Input/types';
import { TechOnboardingChecklistComponent } from './TechOnboardingChecklistComponent';
import { OnboardingChecklistItemEnum } from '../../../uc-api-sdk';
import { ComingSoonTooltipComponent } from '../../../uiComponent/ComingSoonTooltipComponent/ComingSoonTooltipComponent';

export interface TechOnboardingChecklistCardComponentProps extends
  InputTypeArray<OnboardingChecklistItemEnum> {
}

export const TechOnboardingChecklistCardComponent = ({
  value,
  onChange,
  disabled,
}: TechOnboardingChecklistCardComponentProps) => (
  <Card>
    <div className="mb20">Checklist</div>
    <TechOnboardingChecklistComponent
      value={value}
      onChange={onChange}
      disabled={disabled}
    />

    <div className="flex mt20">
      <ComingSoonTooltipComponent>
        <Button type="link" className="p0">Troubleshooting</Button>
      </ComingSoonTooltipComponent>
    </div>
  </Card>
);
