import { PatientTechLevelEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import './PatientTechLevelEnumComponent.scss';

export interface PatientTechLevelEnumComponentProps {
  value: PatientTechLevelEnum;
  type?: 'short' | 'long' | 'colored';
}

export const PatientTechLevelEnumComponent = ({
  value,
  type = 'short',
}: PatientTechLevelEnumComponentProps) => {
  const getLongText = () => {
    switch (value) {
      case PatientTechLevelEnum.LOW: return <span>Low (not be able to use App)</span>;
      case PatientTechLevelEnum.MEDIUM: return <span>Medium</span>;
      case PatientTechLevelEnum.HIGH: return <span>High</span>;
      default: return <DisplayOrEmptyComponent />;
    }
  };
  const getColored = () => {
    switch (value) {
      case PatientTechLevelEnum.LOW: return <span className="techLevelLow">Low</span>;
      case PatientTechLevelEnum.MEDIUM: return <span className="techLevelMedium">Medium</span>;
      case PatientTechLevelEnum.HIGH: return <span className="techLevelHigh">High</span>;
      default: return <DisplayOrEmptyComponent />;
    }
  };

  const getText = () => {
    switch (value) {
      case PatientTechLevelEnum.LOW: return <span>Low</span>;
      case PatientTechLevelEnum.MEDIUM: return <span>Medium</span>;
      case PatientTechLevelEnum.HIGH: return <span>High</span>;
      default: return <DisplayOrEmptyComponent />;
    }
  };

  switch (type) {
    case 'colored':
      return <>{getColored()}</>;
    case 'short':
      return <>{getText()}</>;
    default:
      return <>{getLongText()}</>;
  }
};
