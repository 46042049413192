import { CardInfoTextComponent } from '../../../../uiComponent/CardTypographyComponent/CardInfoTextComponent/CardInfoTextComponent';
import { DeviceInfoComponent, DeviceInfoComponentProps } from '../../../device/component/DeviceInfoComponent/DeviceInfoComponent';

export interface NonAppDeviceInfoComponentProps extends Omit<DeviceInfoComponentProps, 'children'> {
  deviceId?: string;
}

export const NonAppDeviceInfoComponent = ({
  deviceId,
  deviceModel,
  extraHeader,
  boxShadow,
}: NonAppDeviceInfoComponentProps) => (
  <DeviceInfoComponent
    deviceModel={deviceModel}
    extraHeader={extraHeader}
    boxShadow={boxShadow}
  >
    <div className="flex fd-c">
      <CardInfoTextComponent size="small">
        Device ID
      </CardInfoTextComponent>
      <span>{deviceId}</span>
    </div>
  </DeviceInfoComponent>
);
