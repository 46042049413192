import { DatePicker, DatePickerProps } from 'antd';
import { FC } from 'react';
import moment, { Moment } from 'moment';
import { MEDICATION_DATE_FORMAT } from '../../constants/timeFormat/index';

export type MedicationStartDateInputComponentProps = DatePickerProps & {
  disabledAfterDate?: string | Moment | null
};

export const MedicationStartDateInputComponent: FC<MedicationStartDateInputComponentProps> = ({
  value,
  onChange,
  disabledAfterDate,
  className,
}) => {
  const disabledDate: DatePickerProps['disabledDate'] = (current) => (disabledAfterDate ? current > moment(disabledAfterDate).startOf('day') : false);
  return (
    <DatePicker
      value={value ? moment(value) : null}
      className={`${className} w100`}
      onChange={onChange}
      format={MEDICATION_DATE_FORMAT}
      disabledDate={disabledDate}
    />
  );
};
