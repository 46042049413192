import { Tag } from 'antd';
import EnvConfig from '../../configs/envConfig/envConfig';

import './EnvironmentTagComponent.scss';

export const EnvironmentTagComponent = () => (
  EnvConfig.envName
    ? (
      <Tag
        className="env-tag"
        color="#FFF4E8"
      >
        {EnvConfig.envName}
      </Tag>
    ) : null
);
