import { ReactNode } from 'react';

export interface PrintDiseaseHistoryTitleComponentProps {
  title: ReactNode;
}

export const PrintDiseaseHistoryTitleComponent = ({
  title,
}: PrintDiseaseHistoryTitleComponentProps) => (
  <div className="fs2 b5">
    {title}
  </div>
);
