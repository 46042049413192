import { FormListFieldData } from 'antd';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useDiseaseHistoryForm } from '../../hook/useDiseaseHistoryForm';
import { DiseaseHistoryConditionComponent } from '../DiseaseHistoryDisplayComponent/DiseaseHistoryConditionComponent';

export interface DiseaseHistoryConditionFormItemComponentProps {
  listFieldName?: FormListFieldData['name'];
}

export const DiseaseHistoryConditionFormItemComponent = ({
  listFieldName,
}: DiseaseHistoryConditionFormItemComponentProps) => {
  const diseaseHistoryForm = useDiseaseHistoryForm();
  return (
    <FormItem noStyle shouldUpdate>
      {
        ({ getFieldValue }) => {
          const disease = diseaseHistoryForm.getListValue('disease', getFieldValue, listFieldName);
          const customDisease = diseaseHistoryForm.getListValue('customDisease', getFieldValue, listFieldName);
          return (
            <div className="flex fd-c jc-s b6">
              <DiseaseHistoryConditionComponent
                disease={disease}
                customDisease={customDisease}
              />
            </div>
          );
        }
      }
    </FormItem>
  );
};
