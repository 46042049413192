import { Button, ButtonProps } from 'antd';

export interface ForgotPasswordButtonComponentProps extends ButtonProps {
  onClick?: () => void;
}

export const ForgotPasswordButtonComponent = ({
  children = 'Forgot your password?',
  type = 'link',
  onClick,
}: ForgotPasswordButtonComponentProps) => {
  const handleOnClick = () => {
    onClick?.();
  };

  return (
    <Button
      type={type}
      className="forgot-password-component"
      data-testid="forgot-password"
      onClick={handleOnClick}
    >
      {children}
    </Button>
  );
};
