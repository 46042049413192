import React, { FC, ReactNode } from 'react';
import { Button, Form, FormInstance } from 'antd';
import classNames from 'classnames';
import './FormSubmitButton.scss';

export interface FormSubmitButtonProps {
  text?: ReactNode;
  rightAlign?: boolean;
  className?: string;
  shouldBeDisabled?: (values: ReturnType<FormInstance['getFieldsValue']>) => boolean;
  isLoading?: boolean;
}

export const FormSubmitButton: FC<FormSubmitButtonProps> = ({
  text = 'Submit',
  rightAlign = true,
  className = '',
  isLoading = true,
  shouldBeDisabled,
}) => {
  const checkIfDisabled = (getFieldsValue: FormInstance['getFieldsValue']) => {
    const currValues = getFieldsValue();
    if (shouldBeDisabled) {
      return shouldBeDisabled(currValues);
    }
    return false;
    /*
    return some(currValues, (v) => {
      switch (typeof v) {
        case 'number': return false;
        case 'boolean': return false;
        default: return isEmpty(v);
      }
    });
    */
  };

  return (
    <div
      data-testid="submitButtonContainer"
      className={classNames({ 'form-submit-button-right': rightAlign, [className]: className })}
    >
      <Form.Item noStyle shouldUpdate>
        {({ getFieldsValue }) => (
          <Form.Item noStyle>
            <Button
              data-testid="submitButton"
              type="primary"
              htmlType="submit"
              disabled={checkIfDisabled(getFieldsValue)}
              loading={isLoading && checkIfDisabled(getFieldsValue)}
            >
              {text}
            </Button>
          </Form.Item>
        )}
      </Form.Item>
    </div>
  );
};

export default FormSubmitButton;
