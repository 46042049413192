import { AverageValue, ConditionEnum, MonthlyMeasurement } from '../../../../uc-api-sdk';
import { MTPRMeasurementSummaryTitleComponent } from '../MTPRMeasurementSummaryTitleComponent/MTPRMeasurementSummaryTitleComponent';
import { MTPRMeasurementSummaryComponent } from '../MTPRMeasurementSummaryComponent/MTPRMeasurementSummaryComponent';
import { BloodPressureAverageComponent } from '../BloodPressureAverageComponent/BloodPressureAverageComponent';
import { MTPRMeasurementSummaryItemComponent } from '../MTPRMeasurementSummaryItemComponent/MTPRMeasurementSummaryItemComponent';
import { MeasurementAverageTooltipComponent } from '../MeasurementAverageTooltipComponent/MeasurementAverageTooltipComponent';
import { MeasureUnitHelper } from '../../../../helpers/measurement/measureUnit';
import { useBPAverageTrend } from '../../hook/useBPAverageTrend';

export interface MTPRBloodPressureComponentProps {
  monthlyMeasurement: MonthlyMeasurement;
}

export const MTPRBloodPressureComponent = ({
  monthlyMeasurement,
}: MTPRBloodPressureComponentProps) => {
  const BPAverageValue = monthlyMeasurement.averageValue || [];
  const {
    latestAvg,
    systolicTrend,
    diastolicTrend,
  } = useBPAverageTrend(BPAverageValue);

  const renderAvgValue = (value: AverageValue) => (
    (MeasureUnitHelper.getBP(value.systolicBloodPressure)
      && MeasureUnitHelper.getBP(value.diastolicBloodPressure))
      ? (
        <span>
          {MeasureUnitHelper.getBP(value.systolicBloodPressure)?.toFixed(0)}
          /
          {MeasureUnitHelper.getBP(value.diastolicBloodPressure)?.toFixed(0)}
          {' '}
          {MeasureUnitHelper.defaultBPUnit}
        </span>
      ) : null
  );

  return (
    <>
      <MTPRMeasurementSummaryTitleComponent
        condition={ConditionEnum.BLOOD_PRESSURE_MANAGEMENT}
        lastMeasurementDate={monthlyMeasurement.lastMeasurementDate}
      />
      <MTPRMeasurementSummaryComponent
        measurementDays={monthlyMeasurement.measurementDays}
        totalMeasurements={monthlyMeasurement.totalMeasurements}
        normalPercentage={monthlyMeasurement.normalPercentage}
      >
        <MTPRMeasurementSummaryItemComponent title="Average BP">
          <MeasurementAverageTooltipComponent
            tooltipTitle="Average BP"
            valueRenderer={renderAvgValue}
            averageValue={BPAverageValue}
          >
            <BloodPressureAverageComponent
              systolicAvg={MeasureUnitHelper.getBP(latestAvg?.systolicBloodPressure)}
              diastolicAvg={MeasureUnitHelper.getBP(latestAvg?.diastolicBloodPressure)}
              systolicAvgTrend={systolicTrend}
              diastolicAvgTrend={diastolicTrend}
            />
          </MeasurementAverageTooltipComponent>
        </MTPRMeasurementSummaryItemComponent>
      </MTPRMeasurementSummaryComponent>
    </>
  );
};
