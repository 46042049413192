import { TabsProps } from 'antd';
import { isEmpty, map } from 'lodash';
import { useMemo } from 'react';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { useMessagePopupContext } from '../../../../contexts/MessageContext/MessagePopupContext';
import { MixpanelProvider } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEventsParents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { PatientInfo, usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { OutstandingItemEnumComponent } from '../../../../enumComponent/OutstandingItemEnumComponent/OutstandingItemEnumComponent';
import { useDeepCompareEffectWithInitialRender } from '../../../../hooks/useDeepCompareEffect';
import { OutstandingItems, RoleTypeEnum } from '../../../../uc-api-sdk';
import { BubbleTabComponent } from '../../../../uiComponent/BubbleTabComponent/BubbleTabComponent';
import OverlayLoading from '../../../../uiComponent/OverlayLoading/OverlayLoading';
import { usePatientPageControllerContext } from '../../../patient/context/PatientPageControllerContext/PatientPageControllerContext';
import { TicketTabsContainer } from '../../../ticket/container/TicketTabsContainer/TicketTabsContainer';
import {
  ENROLLED_CA_ORDER, ENROLLED_RD_ORDER, OutstandingEnum, UNENROLLED_ORDER
} from '../../constant/outstandingOrder';
import { AlertTabContainer } from '../../container/AlertContainer/AlertTabContainer';
import { OutstandingManualMTPRContainer } from '../../container/OutstandingManualMTPRContainer/OutstandingManualMTPRContainer';
import { OutstandingTranscribingContainer } from '../../container/OutstandingTranscribingContainer/OutstandingTranscribingContainer';
import { OutstandingVisitContainer } from '../../container/OutstandingVisitContainer/OutstandingVisitContainer';
import { useOutstandingFoodLog } from '../../hook/useOutstandingFoodLog';
import { OnboardingTodosComponent } from '../OnboardingTodosComponent/OnboardingTodosComponent';
import { OutstandingFoodDairyComponent } from '../OutstandingFoodDairyComponent/OutstandingFoodDairyComponent';
import { OutstandingMTPRComponent } from '../OutstandingMTPRComponent/OutstandingMTPRComponent';
import { TabItemsNumberComponent } from '../TabItemsNumberComponent/TabItemsNumberComponent';
import './OutstandingComponent.scss';
import { OutstandingMessageContainer } from '../../container/OutstandingMessageContainer/OutstandingMessageContainer';

type Tab = Exclude<TabsProps['items'], undefined>[0];

export interface OutstandingComponentProps {
  patientId: string;
  outstanding?: OutstandingItems;
  isMessageResolved?: boolean;
}

export const OutstandingComponent = ({
  patientId,
  outstanding = {},
  isMessageResolved,
}: OutstandingComponentProps) => {
  const patientPageContext = usePatientPageControllerContext();
  const {
    showMessageInOutstandingTabs,
    isManualMTPROpen,
    closeManualMTPR,
  } = patientPageContext;
  const { info } = usePatientContext();
  const { handleOpenChat } = useMessagePopupContext() || {};
  const { handleFocusFoodLog } = useOutstandingFoodLog();

  const handleTabChange = (activeKey: string) => {
    patientPageContext.onOutstandingChange(activeKey as OutstandingEnum);
  };

  const outstandingItemsMap = useMemo(() => {
    const items = new Map<OutstandingEnum, Tab>();
    if (outstanding.visitCount) {
      items.set(
        OutstandingEnum.VISITS,
        {
          key: OutstandingEnum.VISITS,
          label: (
            <TabItemsNumberComponent
              num={outstanding.visitCount}
              name={<OutstandingItemEnumComponent value={OutstandingEnum.VISITS} />}
            />
          ),
          children: (
            <OutstandingVisitContainer
              patientId={patientId}
              visitIds={outstanding.visitIds ?? []}
            />
          )
        }
      );
    }
    if (outstanding.onboardingToDoCount) {
      items.set(
        OutstandingEnum.ONBOARDING_TODOS,
        {
          key: OutstandingEnum.ONBOARDING_TODOS,
          label: (
            <TabItemsNumberComponent
              num={outstanding.onboardingToDoCount}
              name={<OutstandingItemEnumComponent value={OutstandingEnum.ONBOARDING_TODOS} />}
            />
          ),
          children: (
            <OnboardingTodosComponent
              showAssignDevices={
                !!outstanding.onboardingToDoDetail?.hasAssignedDevice
              }
              showScheduleOnboardingVisit={
                !!outstanding.onboardingToDoDetail?.hasOnboardingVisit
              }
              showScheduleInitialVisit={
                !!outstanding.onboardingToDoDetail?.hasInitialVisit
              }
            />
          ),
        }
      );
    }
    if (outstanding.alertCount || outstanding.snoozedAlertCount) {
      items.set(
        OutstandingEnum.ALERTS,
        {
          key: OutstandingEnum.ALERTS,
          label: (
            <TabItemsNumberComponent
              num={outstanding.alertCount ?? 0}
              name={<OutstandingItemEnumComponent value={OutstandingEnum.ALERTS} />}
            />
          ),
          children: <AlertTabContainer patientId={patientId} />
        }
      );
    }
    if (outstanding.taskCount) {
      items.set(
        OutstandingEnum.TASKS,
        {
          key: OutstandingEnum.TASKS,
          label: (
            <TabItemsNumberComponent
              num={outstanding.taskCount}
              name={<OutstandingItemEnumComponent value={OutstandingEnum.TASKS} />}
            />
          ),
          children: <TicketTabsContainer ids={outstanding.taskIds ?? []} />
        }
      );
    }
    if (outstanding.transcribeCount) {
      items.set(
        OutstandingEnum.TRANSCRIBING,
        {
          key: OutstandingEnum.TRANSCRIBING,
          label: (
            <TabItemsNumberComponent
              num={outstanding.transcribeCount}
              name={<OutstandingItemEnumComponent value={OutstandingEnum.TRANSCRIBING} />}
            />
          ),
          children: (
            <OutstandingTranscribingContainer
              patientId={patientId}
              data={outstanding.transcribeDetails}
            />
          ),
        }
      );
    }
    if (showMessageInOutstandingTabs && !isMessageResolved) {
      items.set(
        OutstandingEnum.MESSAGES,
        {
          key: OutstandingEnum.MESSAGES,
          label: 'Message',
          children: <OutstandingMessageContainer
            memberId={patientId}
          />
        }
      );
    }
    if (outstanding.monthlyReviewCount) {
      items.set(
        OutstandingEnum.MONTHLY_REVIEW,
        {
          key: OutstandingEnum.MONTHLY_REVIEW,
          label: (
            <TabItemsNumberComponent
              num={outstanding.monthlyReviewCount}
              name={<OutstandingItemEnumComponent value={OutstandingEnum.MONTHLY_REVIEW} />}
            />
          ),
          children: (
            <OutstandingMTPRComponent
              patientId={patientId}
              showSuccessMessage={false}
              monthlyReviewId={outstanding.monthlyReviewId ?? undefined}
            />
          )
        }
      );
    }
    if (outstanding.referralCount) {
      items.set(
        OutstandingEnum.REFERRAL,
        {
          key: OutstandingEnum.REFERRAL,
          label: (
            <TabItemsNumberComponent
              num={outstanding.referralCount}
              name={<OutstandingItemEnumComponent value={OutstandingEnum.REFERRAL} />}
            />
          ),
          children: 'Referral'
        }
      );
    }
    if (outstanding.enrollmentPrepCount) {
      items.set(
        OutstandingEnum.ENROLLMENT_PREPARATION,
        {
          key: OutstandingEnum.ENROLLMENT_PREPARATION,
          label: (
            <TabItemsNumberComponent
              num={outstanding.enrollmentPrepCount}
              name={<OutstandingItemEnumComponent value={OutstandingEnum.ENROLLMENT_PREPARATION} />}
            />
          ),
          children: 'Enrollment Preparation'
        }
      );
    }
    if (isManualMTPROpen) {
      items.set(
        OutstandingEnum.MANUAL_MONTHLY_REVIEW,
        {
          key: OutstandingEnum.MANUAL_MONTHLY_REVIEW,
          label: (<div>MTPR</div>),
          children: (
            <OutstandingManualMTPRContainer
              patientId={patientId}
              onClose={closeManualMTPR}
            />
          ),
          closable: true,
        }
      );
    }
    if (outstanding.foodLogCount) {
      items.set(
        OutstandingEnum.FOOD_DAIRY,
        {
          key: OutstandingEnum.FOOD_DAIRY,
          label: 'Food Diary',
          children: (<OutstandingFoodDairyComponent patientId={patientId} />),
        }
      );
    }
    return items;
  }, [outstanding, isMessageResolved, isManualMTPROpen]);

  const getOutstandingItem = (itemKey: OutstandingEnum) => {
    const item = outstandingItemsMap.get(itemKey);
    if (!isEmpty(item)) {
      return {
        closable: false, // default value
        ...item,
      } as Tab;
    }
    return undefined;
  };

  const unenrolledItems = useMemo(() => (
    map(UNENROLLED_ORDER, getOutstandingItem).filter(v => !!v)
  ), [outstandingItemsMap]);

  const enrolledCaItems = useMemo(() => (
    map(ENROLLED_CA_ORDER, getOutstandingItem).filter(v => !!v)
  ), [outstandingItemsMap]);

  const enrolledRdItems = useMemo(() => (
    map(ENROLLED_RD_ORDER, getOutstandingItem).filter(v => !!v)
  ), [outstandingItemsMap]);

  const { userInfo } = useLoggedInUserFromContext();
  const userRole = useMemo(() => {
    if (userInfo?.allRoleTypes) {
      if (userInfo.allRoleTypes.includes(RoleTypeEnum.RD)
        || userInfo.allRoleTypes.includes(RoleTypeEnum.HC)) {
        return RoleTypeEnum.RD;
      }
      return RoleTypeEnum.CA;
    }
    return RoleTypeEnum.CA;
  }, [userInfo?.allRoleTypes]);

  const renderTabs = (info?: PatientInfo, role?: RoleTypeEnum) => {
    if (info?.enrolledProgramService.isEnrolled()) {
      if (role === RoleTypeEnum.RD) {
        return enrolledRdItems;
      }
      return enrolledCaItems;
    }
    return unenrolledItems;
  };

  const tabItems = renderTabs(info, userRole).filter((v) => v) as Tab[];

  const handleOnTabClick = (activeKey: string) => {
    switch (activeKey) {
      case OutstandingEnum.MESSAGES:
        handleOpenChat?.();
        break;
      case OutstandingEnum.FOOD_DAIRY:
        handleFocusFoodLog();
        break;
      default:
        break;
    }
  };

  const handleOnTabClose: TabsProps['onEdit'] = (
    targetKey,
  ) => {
    switch (targetKey) {
      case OutstandingEnum.MANUAL_MONTHLY_REVIEW:
        closeManualMTPR();
        break;
      default:
        break;
    }
  };

  useDeepCompareEffectWithInitialRender(() => {
    // correct active tab key
    // 1. when no tab key is selected
    // 2. when tab key is selected but not available in tabItems anymore
    // => pick the first tabItems
    if (
      tabItems.length
      && (
        !patientPageContext.selectedOutstanding
        || !map(tabItems, 'key').includes(patientPageContext.selectedOutstanding)
      )
    ) {
      handleTabChange(tabItems?.[0]?.key);
    }
  }, [
    patientPageContext.selectedOutstanding,
    map(tabItems, 'key'),
  ]);

  return (
    <MixpanelProvider context={MixpanelEventsParents.Outstanding}>
      <div className="outstanding-component">
        <div className="flex jc-sb ai-c">
          <div className="b5 fs16">
            Outstanding Items
          </div>
        </div>
        {info?.isLoading && <OverlayLoading loading />}
        <BubbleTabComponent
          className="tab-fixed-header"
          activeKey={patientPageContext.selectedOutstanding}
          onChange={handleTabChange}
          items={tabItems}
          type="editable-card"
          hideAdd
          onEdit={handleOnTabClose} // no add tab action
          onTabClick={handleOnTabClick}
        />
      </div>
    </MixpanelProvider>
  );
};
