import { Modal } from 'antd';
import { cloneDeep, uniqueId } from 'lodash';
import { useMemo } from 'react';

export interface useNotImplementedOptions {
  showModal: boolean;
}

const initialOptions = {
  showModal: false,
};

// eslint-disable-next-line import/prefer-default-export
export const useNotImplemented = (
  baseName: string,
  options: useNotImplementedOptions = initialOptions,
) => {
  const opts = Object.assign(cloneDeep(initialOptions), options);
  const constructName = (type: string) => `${baseName}.${type}`;

  const response = useMemo(() => ({
    click: (evt?: React.MouseEventHandler<HTMLElement>) => {
      const id = uniqueId();
      const warning = `${constructName('Click')} Not implemented yet!\n$ID: ${id}`;
      console.log(warning, evt);
      if (opts.showModal) {
        Modal.warning({
          title: 'Click Not implemented!',
          content: warning,
        });
      }
    },
    onChange: (...value: unknown[]) => {
      const id = uniqueId();
      const warning = `${constructName('onChange')} Not implemented yet!\nID: ${id}`;
      console.log(warning, value);
      if (opts.showModal) {
        Modal.warning({
          title: 'onChange Not implemented!',
          content: warning,
        });
      }
    },
  }), [baseName]);

  return response;
};
