import React from 'react';
import { TitleContentTextComponent } from '../../../../uiComponent/TitleContentTextComponent/TitleContentTextComponent';
import { Lifestyle } from '../../../../uc-api-sdk';
import { ActivityLevelEnumComponent } from '../../../../enumComponent/ActivityLevelEnumComponent/ActivityLevelEnumComponent';
import { SleepingRoutineEnumComponent } from '../../../../enumComponent/SleepingRoutineEnumComponent/SleepingRoutineEnumComponent';
import { EatingRoutineEnumComponent } from '../../../../enumComponent/EatingRoutineEnumComponent/EatingRoutineEnumComponent';
import { FoodAccessEnumComponent } from '../../../../enumComponent/FoodAccessEnumComponent/FoodAccessEnumComponent';
import { LifestyleSmokingOrDrinkingComponent } from './LifestyleSmokingOrDrinkingComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { HistoryDrawerComponent } from '../../../../uiComponent/HistoryComponent/HistoryDrawerComponent';
import { FreqEnumComponent } from '../../../../enumComponent/FreqEnumComponent/FreqEnumComponent';

export interface LifestyleViewComponentProps {
  value?: Lifestyle;
}
export const LifestyleViewComponent = ({
  value,
}: LifestyleViewComponentProps) => (
  <div className="flex fd-c mt20">
    <TitleContentTextComponent
      title="Physical Activity Level"
      content={(
        <>
          <ActivityLevelEnumComponent
            value={value?.physicalActivity?.activityLevel ?? undefined}
          />
          { value?.physicalActivity?.activityValue
          && value?.physicalActivity?.activityFreq ? (
            <div>
              {value?.physicalActivity?.activityValue}
              {' '}
              times per
              {' '}
              <FreqEnumComponent value={value?.physicalActivity?.activityFreq ?? undefined} type="short" />
              {value?.physicalActivity?.activityDuration ? (
                <>
                  , duration
                  {' '}
                  {value?.physicalActivity?.activityDuration}
                  {' '}
                  mins
                </>
              ) : null}
            </div>
            ) : null }
        </>
      )}
    />
    <TitleContentTextComponent
      title="How often do you get 7-8 hours of sleep?"
      content={(
        <SleepingRoutineEnumComponent
          value={value?.sleepingRoutine ?? undefined}
        />
      )}
    />
    <TitleContentTextComponent
      title="Eating Routine"
      content={(
        <EatingRoutineEnumComponent
          value={value?.eatingRoutine ?? undefined}
        />
      )}
    />
    <TitleContentTextComponent
      title="Food Accessibility"
      content={(
        <FoodAccessEnumComponent
          value={value?.foodAccessibility ?? undefined}
        />
      )}
    />
    <TitleContentTextComponent
      title="Does Patient Smoke?"
      content={(
        <LifestyleSmokingOrDrinkingComponent
          value={value?.smokingHistory?.smokingOrDrinking ?? undefined}
          quitYear={value?.smokingHistory?.quitYear ?? undefined}
          activityFreq={value?.smokingHistory?.activityFreq ?? undefined}
          activityValue={value?.smokingHistory?.activityValue ?? undefined}
          type="smoke"
        />
      )}
    />
    <TitleContentTextComponent
      title="Does Patient Drink?"
      content={(
        <LifestyleSmokingOrDrinkingComponent
          value={value?.drinkingHistory?.smokingOrDrinking ?? undefined}
          quitYear={value?.drinkingHistory?.quitYear ?? undefined}
          activityFreq={value?.drinkingHistory?.activityFreq ?? undefined}
          activityValue={value?.drinkingHistory?.activityValue ?? undefined}
          drinkChoice={value?.drinkingHistory?.drinkChoice ?? undefined}
          drinkQuantity={value?.drinkingHistory?.drinkQuantity ?? undefined}
          type="drink"
        />
      )}
    />
    <TitleContentTextComponent
      title="Typical Lifestyle Pattern"
      content={(
        <DisplayOrEmptyComponent value={value?.lifestylePattern} />
      )}
    />
    {
      value?.notes && value?.notes.length !== 0 && (
        <HistoryDrawerComponent history={value?.notes} isLifeStyle />
      )
    }
  </div>
);
