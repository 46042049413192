import { HemoglobinA1cTemplateId } from '../../constants';
import { ReferenceLevel } from '../../../../uc-api-sdk';
import { A1cTemplateFlagComponent } from './A1cTemplateFlagComponent';
import { OtherLabFlagComponent, OtherLabFlagComponentProps } from './OtherLabFlagComponent';

export interface LabResultFlagComponentProps {
  value?: number;
  referenceLevels?: ReferenceLevel[];
  template?: string;
  name?: string;
  flag?: OtherLabFlagComponentProps['flag']
}

export const LabResultFlagComponent = ({
  value,
  referenceLevels,
  template,
  name,
  flag,
}: LabResultFlagComponentProps) => {
  if (template === HemoglobinA1cTemplateId) {
    return (
      <A1cTemplateFlagComponent
        value={Number(value)}
        name={name}
        referenceLevels={referenceLevels ?? undefined}
      />
    );
  }
  return (
    <OtherLabFlagComponent
      value={Number(value)}
      name={name}
      referenceLevels={referenceLevels ?? undefined}
      flag={flag}
    />
  );
};
