import dayjs, { Dayjs } from 'dayjs';
import { useDeepCompareMemo } from '../../../hooks/useDeepCompareEffect';
import {
  Nullable,
  VisitMethodEnum,
  VisitStatusEnum,
  ZoomStatusEnum
} from '../../../uc-api-sdk';
import { useLoggedInUserFromContext } from '../../../contexts/loggedInUserContext';
import { ClinicEventsService } from '../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';

interface DisabledVisitStatus {
  disabled: boolean;
  disabledReason: string | null;
}

export interface VisitStatusHelperProps {
  startTime: Dayjs;
  isCheckedIn?: boolean;
  visitParticipantsId?: string[];
  visitStatus?: Nullable<VisitStatusEnum>;
  visitMethod?: Nullable<VisitMethodEnum>;
  zoomStatus?: Nullable<ZoomStatusEnum>;
  isCompleted?: boolean;
}
export const useVisitStatusHelper = ({
  startTime,
  isCheckedIn,
  visitParticipantsId,
  visitMethod,
  visitStatus,
  zoomStatus,
  isCompleted,
}: VisitStatusHelperProps) => {
  const { userId } = useLoggedInUserFromContext();
  const isZoomStarted = zoomStatus === ZoomStatusEnum.IN_PROGRESS;
  const isNoShow = visitStatus === VisitStatusEnum.NO_SHOW;
  const isUserParticipant = (
    ClinicEventsService.ifUserParticipant(visitParticipantsId || [], userId)
  );
  const isChartingNotCompleted = !isCompleted;
  const canStartVisit = startTime.diff(dayjs(), 'minutes') < 60; // visit is within 60 minutes
  const isVisitOnSameDay = dayjs().isSame(startTime, 'day');
  const canReschedule = (isUserParticipant && isNoShow && !isVisitOnSameDay);
  const shouldShowCTAByParticipants = isCheckedIn ? isUserParticipant : true;
  const showCTA = (
    shouldShowCTAByParticipants
    && isChartingNotCompleted
    && canStartVisit
    && (
      // if no show, always show button to non-participant users when visit is on the same day
      isNoShow
        ? isVisitOnSameDay || canReschedule
        : true
    )
  );
  const showReschedule = (
    isChartingNotCompleted
    && canReschedule
  );

  const isDisabled = (): DisabledVisitStatus => {
    switch (visitMethod) {
      case VisitMethodEnum.VIRTUAL:
        if (visitStatus === VisitStatusEnum.CHECKED_OUT) {
          break;
        }
        if (
          isZoomStarted // patient joined
          || visitStatus === VisitStatusEnum.ONGOING // CT joined
        ) {
          break;
        }
        return { disabled: true, disabledReason: 'Waiting for patient to call in' };
      default:
    }
    return { disabled: false, disabledReason: null };
  };

  const getCheckInText = (extraStep?: boolean) => {
    if (!isCheckedIn) return extraStep ? 'Go Check In' : 'Check In';
    return visitStatus === VisitStatusEnum.ONGOING ? 'Resume' : 'Resume Charting';
  };

  return useDeepCompareMemo(
    () => {
      const { disabled, disabledReason } = isDisabled();
      return {
        showCTA,
        getCheckInText,
        showReschedule,
        disabled,
        disabledReason,
      };
    },
    [
      visitParticipantsId,
      visitStatus,
      startTime,
      isCheckedIn,
      isCompleted,
      isDisabled,
      zoomStatus,
    ]
  );
};
