import { QuestionCircleFilled } from '@ant-design/icons';
import { Icon } from '../../../../icons/Icon';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import { TechWorklistGuideStepComponent } from './TechWorklistGuideStepComponent';
import { DOCUMENT_ICON, ORDER_BOX_ICON } from '../../../../assets/commonIconInfo';

import './TechWorklistGuideComponent.scss';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { OrderSuppliesReplacementDrawerButtonComponent } from '../../../order/component/OrderSuppliesReplacementDrawerButtonComponent/OrderSuppliesReplacementDrawerButtonComponent';

const troubleshootingGuideUrl = 'https://ucfe.ihealthnext.com/manuals/troubleshooting.pdf';

export interface TechWorklistGuideComponentProps {
  patientId: string;
}

export const TechWorklistGuideComponent = ({
  patientId,
}: TechWorklistGuideComponentProps) => {
  const openReviewTroubleshootingGuide = () => {
    window.open(troubleshootingGuideUrl, '_blank');
  };

  return (
    <div className="tech-worklist-guide">
      <TextComponent size="x-large" bold color="gray1">
        <QuestionCircleFilled className="mr10" />
        When patient has question about the devices or App
      </TextComponent>
      <ul className="mt10">
        <li>
          <TechWorklistGuideStepComponent
            stepNumber={1}
            stepText="Ensure that the patient is
          following the standard instructions for using the devices."
          />
        </li>
        <li>
          <TechWorklistGuideStepComponent
            stepNumber={2}
            stepText="Troubleshoot and assist the patient in resolving the issue."
          >
            <Icon info={DOCUMENT_ICON} hexColor="#3177C9" />
            <LinkButton
              className="no-style-btn"
              onClick={openReviewTroubleshootingGuide}
              useBlueColor
            >
              Review Troubleshooting Guide
            </LinkButton>
          </TechWorklistGuideStepComponent>
        </li>
        <li>
          <TechWorklistGuideStepComponent
            stepNumber={3}
            stepText="If the problem persistsand cannot be resolved,
          initiate the replacement and ordering proces."
          >
            <Icon info={ORDER_BOX_ICON} />
            <OrderSuppliesReplacementDrawerButtonComponent
              patientId={patientId}
            />
          </TechWorklistGuideStepComponent>
        </li>
      </ul>
    </div>
  );
};
