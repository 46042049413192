import { useCallback, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { filter, find, isEqual } from 'lodash';
import { BigCalendarComponent, BigCalendarProps, CalendarEvent } from '../BigCalendarComponent/BigCalendarComponent';
import { ClinicEvent } from '../../../../uc-api-sdk';
import { DayCalendarToolbarComponent } from '../DayCalendarToolbarComponent/DayCalendarToolbarComponent';
import { PullUpDrawerComponent } from '../../../../uiComponent/PullUpDrawerComponent/PullUpDrawerComponent';
import { DayCalendarEvenDrawerContentComponent } from '../DayCalendarEvenDrawerContentComponent/DayCalendarEvenDrawerContentComponent';
import { CalendarEventComponent } from '../CalendarEventComponent/CalendarEventComponent';
import { useDeepCompareEffect } from '../../../../hooks/useDeepCompareEffect';
import { NEW_EVENT_ID } from '../../constant';

export interface DayCalendarComponentProps {
  date: Dayjs;
  onChange: (date: Dayjs) => void;
  events?: CalendarEvent<ClinicEvent>[];
  isLoading?: boolean;
  refetch?: () => void;
  onClose?: () => void;
}

export const DayCalendarComponent = ({
  date,
  onChange,
  events = [],
  isLoading = false,
  refetch,
  onClose,
}: DayCalendarComponentProps) => {
  const dateMoment = useMemo(() => moment(date?.toDate()), [date]);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent<ClinicEvent>>();

  // not allowing dragging
  const handleSelectingSlot = useCallback<Exclude<BigCalendarProps['onSelecting'], undefined>>(() => false, []);

  const handleNavigate: BigCalendarProps['onNavigate'] = (d) => {
    const newD = dayjs(d);
    onChange(newD);
  };

  const handleSelect: BigCalendarProps['onSelect'] = (v) => {
    setSelectedEvent(v as CalendarEvent<ClinicEvent>);
  };

  const allEvents = useMemo(() => {
    if (selectedEvent) {
      const filteredEvents = filter(events, (v) => v.uId !== selectedEvent.uId) || [];
      return [...filteredEvents, selectedEvent];
    }
    return [...events];
  }, [events, selectedEvent]);

  const handleClose = (closeOuter?: boolean) => {
    setSelectedEvent(undefined);
    if (closeOuter === true) {
      onClose?.();
    }
  };

  useDeepCompareEffect(() => {
    const event = find(allEvents, (v) => (v.id === selectedEvent?.id));
    if (event && !isEqual(event?.info, selectedEvent?.info)) {
      setSelectedEvent(event);
    }
  }, [allEvents, selectedEvent]);

  return (
    <>
      <BigCalendarComponent
        newEventId={NEW_EVENT_ID}
        loading={isLoading}
        view="day"
        date={dateMoment}
        events={allEvents}
        eventRenderer={CalendarEventComponent}
        onSelecting={handleSelectingSlot}
        onSelect={handleSelect}
        onNavigate={handleNavigate}
        selectedEventId={selectedEvent?.id}
        toolbar={DayCalendarToolbarComponent}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onView={() => {}}
      />
      <PullUpDrawerComponent
        open={!!selectedEvent}
        closable={false}
        destroyOnClose
        onClose={() => handleClose()}
        height="95%"
      >
        <div className="pb12 h100">
          <DayCalendarEvenDrawerContentComponent
            event={selectedEvent}
            onClose={handleClose}
            refetch={refetch}
          />
        </div>
      </PullUpDrawerComponent>
    </>
  );
};
