import { useMemo } from 'react';
import { useBoolean } from 'usehooks-ts';

export const useOpen = (initialValue = false) => {
  const {
    value,
    setTrue,
    setFalse,
    setValue,
    toggle,
  } = useBoolean(initialValue);
  const res = useMemo(() => ({
    isOpen: value,
    open: setTrue,
    close: setFalse,
    setValue,
    toggle,
  }), [
    value,
    setTrue,
    setFalse,
    setValue,
    toggle,
  ]);

  return res;
};
