import React, { useState } from 'react';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useEmployeeAddToWatch } from '../../../../uc-api-sdk';
import {
  AddPatientToWatchlistComponent
} from '../../component/AddPatientToWatchlistComponent/AddPatientToWatchlistComponent';

export interface AddPatientToWatchlistContainerProps {
  patientId?: string;
  onClose?: () => void;
  onSuccess?: () => void;
}

export const AddPatientToWatchlistContainer = ({
  patientId,
  onClose,
  onSuccess
}: AddPatientToWatchlistContainerProps) => {
  const updateHook = useEmployeeAddToWatch({});
  const [note, setNote] = useState<string>();

  const handleSubmit = async () => {
    const req = updateHook.send({
      params: {
        addRequest: {
          id: patientId || '',
          note,
        },
      },
    });
    await ApiRequestHelper.tryCatch(
      req,
      {
        success: 'Patient added to watchlist',
        error: 'Fail to add Patient',
        onSuccess: () => {
          onSuccess?.();
          setNote('');
        }
      },
    );
  };
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  return (
    <AddPatientToWatchlistComponent
      onCancel={onClose}
      onSubmit={handleSubmit}
      onChange={onChange}
      note={note}
    />
  );
};
