import { Button, Space } from 'antd';
import classNames from 'classnames';
import FormItem from '../../../../uiComponent/FormItem/FormItem';

export interface InterventionSubmitCancelButtonsProps {
  className?: string;
  onCancel?: () => void;
  showOnTouch?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formHook?: any;
  disabled?: boolean;
}

export const InterventionSubmitCancelButtons = ({
  onCancel,
  className = '',
  showOnTouch = false,
  formHook,
  disabled,
}: InterventionSubmitCancelButtonsProps) => {
  const renderButtons = () => (
    <Space
      className={classNames({
        mt10: true,
        [className]: !!className,
      })}
    >
      <Button htmlType="submit" type="primary" disabled={disabled}>Save</Button>
      {onCancel && <Button onClick={onCancel}>Cancel</Button>}
    </Space>
  );

  return showOnTouch ? (
    <FormItem noStyle shouldUpdate>
      {() => (
        formHook?.isTouched && renderButtons()
      )}
    </FormItem>
  ) : renderButtons();
};
