import { useUpdateListener } from '../../../contexts/UpdateContext/UpdateContext';
import useDebounce from '../../../hooks/useDebounce/useDebounce';

export const updateItemCountAfterActions = (refetch: () => void) => {
  const debouncedRefetch = useDebounce(refetch, 100, [refetch]);

  useUpdateListener('ALERT_RESOLVED', debouncedRefetch);
  useUpdateListener('TICKET_RESOLVED', debouncedRefetch);
  useUpdateListener('TICKET_CREATE', debouncedRefetch);
  useUpdateListener('VISIT_CREATED', debouncedRefetch);
  useUpdateListener('VISIT_DELETED', debouncedRefetch);
  useUpdateListener('TRANSCRIBE_FINISHED', debouncedRefetch);
  useUpdateListener('WORKLIST_CLOSED', debouncedRefetch);
  useUpdateListener('ALERT_SNOOZE_OR_UN_SNOOZE', debouncedRefetch);
  useUpdateListener('DEVICE_ASSIGNED', debouncedRefetch);
  useUpdateListener('MTPR_FINISHED', debouncedRefetch);
  useUpdateListener('FOOD_LOG_SUBMITTED', debouncedRefetch);
  useUpdateListener('OUTSTANDING_MANUAL_HANDLER', debouncedRefetch);

  // scenario 1: GIVEN visit due alert exit, WHEN create/update visit,
  //             THEN update item count, AND remove alert
  useUpdateListener('VISIT_UPDATED', debouncedRefetch);

  // scenario 2: GIVEN A1C due transcribing exit, WHEN create/update lab,
  //             THEN remove transcribing,
  //             AND update OutstandingItems(transcribeCount && transcribeDetails)
  useUpdateListener('LAB_DELETE', debouncedRefetch);
  useUpdateListener('LAB_EDITED', debouncedRefetch);
  useUpdateListener('LAB_CREATED', debouncedRefetch);
};
