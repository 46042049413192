import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';

export const EnrollmentNotEligibleNoteComponent = () => (
  <div className="flex fd-c jc-c ai-c px100 py20 bg-gray4">
    <TextComponent bold>
      This patient is currently not eligible to enroll.
    </TextComponent>
    <TextComponent centerText>
      Based on what you've selected,
      it looks like we do not have an alternative device for this patient
      to use for monitoring. Please double check with patient or click close to exit the flow.
    </TextComponent>
  </div>
);
