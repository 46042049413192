import {
  Children, FC, ReactNode, cloneElement, isValidElement,
} from 'react';
import { FCComponent, ReactNodeWithDisplayName } from './type';
import { LayoutItemComponent, LayoutItemComponentProps } from '../../uiComponent/LayoutItemComponent/LayoutItemComponent';
import { IsUnknown } from '../types';

export const cloneChildren = (
  children: ReactNode,
  props: unknown,
) => (
  Children.map(children, (child) => {
    if (!isValidElement(child)) return child;
    const childProps = props || {};
    return cloneElement(child, childProps, child.props.children);
  })
);

export const getChildByDisplayName = (
  displayName: string,
  children: ReactNode,
): [ReactNode | null, ReactNode[]] => {
  let targetChild = null;
  const restChildren = [] as ReactNode[];

  Children.map(children, (child) => {
    if (!isValidElement(child)) return;
    if (
      displayName
      && (
        child as unknown as ReactNodeWithDisplayName
      ).type?.displayName === displayName
    ) {
      targetChild = child;
      return;
    }
    restChildren.push(child);
  });

  return [targetChild, restChildren];
};

export const makeLayoutItem = <T, >(
  displayName: string,
  CustomLayout?: FCComponent<T>,
) => {
  const Component = CustomLayout
    || (
      ({ children }: LayoutItemComponentProps) => (
        <LayoutItemComponent>{children}</LayoutItemComponent>
      )
    );
  (Component as FC).displayName = displayName;
  return Component as IsUnknown<T, FCComponent<LayoutItemComponentProps>, FCComponent<T>>;
};
