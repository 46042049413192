import classNames from 'classnames';
import { ChronicDiseaseEnum } from '../../../../uc-api-sdk';
import { DiseaseHasTestedComponent } from '../../../../enumComponent/DiseaseTestedBeforeLabel/DiseaseHasTestedComponent';

export interface DiseaseTestedBeforeLabelProps {
  disease?: ChronicDiseaseEnum | null;
  className?: string;
}

// @return
// Tested home BP before?
// or Tested home BG before?
// or Tested home Blood Oxygen before?
export const DiseaseTestedBeforeLabel = ({
  disease,
  className = '',
}: DiseaseTestedBeforeLabelProps) => (
  <div
    className={classNames({
      label: true,
      [className]: !!className,
    })}
  >
    <DiseaseHasTestedComponent disease={disease} />
    {' '}
    before?
  </div>
);
