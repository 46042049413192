import classNames from 'classnames';
import './PDFViewerComponent.scss';

export interface PDFViewerComponentProps {
  title?: string;
  fileUrl?: string;
  width?: string | number;
  height?: string | number;
  border?: boolean;
}

export const PDFViewerComponent = ({
  title,
  fileUrl,
  width = '100%',
  height,
  border = false,
}: PDFViewerComponentProps) => (
  <iframe
    className={classNames({
      'pdf-viewer-component': true,
      'show-border': !!border,
    })}
    title={title}
    src={fileUrl}
    width={width}
    height={height}
  />
);
