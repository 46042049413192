import React, { FC } from 'react';
import classNames from 'classnames';
import { PriorityLevelEnum } from '../../../../uc-api-sdk';
import './MedicalAlertPriorityLevelComponent.scss';
import {
  PriorityLevelEnumComponent
} from '../../../../enumComponent/PriorityLevelEnumComponent/PriorityLevelEnumComponent';

export interface MedicalAlertLevelComponentProps {
  value: PriorityLevelEnum;
}

export const MedicalAlertPriorityLevelComponent: FC<MedicalAlertLevelComponentProps> = ({
  value,
}) => (
  <div
    className={classNames({
      'medical-alert-priority-level-component': true,
      [value]: true,
    })}
  >
    <PriorityLevelEnumComponent value={value} />
  </div>
);
