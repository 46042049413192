export const missingRestfulImplementation = (hookName: string) => {
  throw new Error(`The restful hook implementation for ${hookName} is missing!`);
};
export const missingGQLImplementation = (hookName: string) => {
  throw new Error(`The gql hook implementation for ${hookName} is missing!`);
};
export const missingToken = () => { throw new Error('Missing token!'); };
export const missingGqlServerUrl = () => { throw new Error('Missing GQL server url!'); };
export const missingGqlServerApiKey = () => { throw new Error('Missing GQL server api key!'); };
export const missingRestfulServerUrl = () => { throw new Error('Missing RESTful server url!'); };
export const missingRestfulServerApiKey = () => { throw new Error('Missing RESTful server api key!'); };
export const unacceptableRequestType = () => new Error('Function type doesn\'t exist');
export const missingGoogleClientId = () => new Error('Missing Google client id');
export const missingDomain = (name: string) => new Error(`Missing ${name} Domain`);
export const missingUrl = (name: string) => new Error(`Missing ${name} url`);
export const missingMixPanelToken = () => new Error('Missing mixpanel token!');
export const missingUnicareCopilotUrl = () => new Error('Missing Unicare Copilot/AI Admin url!');
