import { Moment, unitOfTime as UnitOfTime } from 'moment';
import { useCallback, useState } from 'react';
import { FromDateToDate } from '../../features/vitals/type';

export type UseFromDateToDateArg = {
  type: 'date';
  initial: Moment;
  unitOfTime: UnitOfTime.StartOf;
} | {
  type: 'fromToDate';
  initialStartToDate: FromDateToDate;
  unitOfTime: UnitOfTime.StartOf;
}

const calcValue = (value: UseFromDateToDateArg): Moment => {
  if (value.type === 'date') {
    return value.initial.clone();
  }
  return value.initialStartToDate.fromDate.clone();
};

const calcFromToDate = (value: UseFromDateToDateArg): FromDateToDate => {
  if (value.type === 'date') {
    return {
      fromDate: value.initial.clone().startOf(value.unitOfTime),
      toDate: value.initial.clone().endOf(value.unitOfTime),
    };
  }
  return {
    fromDate: value.initialStartToDate.fromDate.clone(),
    toDate: value.initialStartToDate.toDate.clone(),
  };
};

export const useFromDateToDate = (v: UseFromDateToDateArg) => {
  const [value, setValue] = useState<Moment>(calcValue(v));
  const [fromToDate, setFromToDate] = useState<FromDateToDate>(calcFromToDate(v));

  const handleChangeMonthAndGetStartEndOfMonth = useCallback((v: Moment | undefined | null) => {
    if (v) {
      setValue(v);
      setFromToDate({
        fromDate: v.clone().startOf('month'),
        toDate: v.clone().endOf('month'),
      });
    }
  }, [setValue, setFromToDate]);

  return {
    value,
    setValue,
    fromToDate,
    setFromToDate,
    handleChangeMonthAndGetStartEndOfMonth,
  };
};
