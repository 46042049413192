import { Table } from 'antd';
import classNames from 'classnames';
import { FC } from 'react';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { createMedicationColumns } from '../../../../tables/MedicationsTables/columns';
import { Medication } from '../../../../uc-api-sdk';

export interface MedicationDataProps {
  data: Medication[];
}

export const PrintMedicationComponent: FC<
  MedicationDataProps
> = ({
  data,
}) => {
  const columns = useTableColumns(createMedicationColumns, ['name', 'dosage']);
  columns[0] = { ...columns[0], sorter: false };
  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={false}
      className={classNames({
        'bg-logbook-table': true,
      })}
      bordered
      rowKey={(v) => v.id}
    />
  );
};
