import { CheckboxOptionType } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { RadioGroupComponent } from '../../../../uiComponent/RadioGroupComponent/RadioGroupComponent';
import { TableFilterComponent } from '../TableFilterComponent/TableFilterComponent';

export interface RadioFilterComponentProps extends FilterDropdownProps {
  filterOptions: CheckboxOptionType[];
}

export const RadioFilterComponent = ({
  filterOptions,
  ...props
}: RadioFilterComponentProps) => (
  <TableFilterComponent
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    clearFilters={() => props.setSelectedKeys([])}
  >
    <div className="p5">
      <RadioGroupComponent
        direction="vertical"
        options={filterOptions}
        value={props.selectedKeys[0] as string}
        onChange={(v) => props.setSelectedKeys([v] as React.Key[])}
      />
    </div>
  </TableFilterComponent>
);
