export enum EnrollmentSectionsEnum {
  Diagnosis = 'Diagnosis',
  VitalsAndPrograms = 'VitalsAndPrograms',
  Insurance = 'Insurance',
  BasicInfo = 'BasicInfo',
  Consent = 'Consent',
  TechLevel = 'TechLevel',
  DeliveryMethod = 'DeliveryMethod',
  Devices = 'Devices',
  TechOnboarding = 'TechOnboarding',
  InitialAssessment = 'InitialAssessment',
}

export type EnrollmentSectionsStatuses = Record<EnrollmentSectionsEnum, boolean>;

export type EnrollmentSectionsWeight = Record<EnrollmentSectionsEnum, number>;
