import classNames from 'classnames';
import { ReactNode } from 'react';
import { CommonChronicDiagnosesModalComponent } from '../CommonChronicDiagnosesModalComponent/TranscribeCommonChronicDiagnosesModalComponent';

import './ChronicDiagnosesNoteComponent.scss';

export interface ChronicDiagnosesNoteComponentProps {
  children?: ReactNode;
  className?: string;
  showCommonChronicDiagnoses?: boolean;
}

export const ChronicDiagnosesNoteComponent = ({
  children = 'Add all chronic diagnoses from EMR(No need to include the acute / temporary dx).',
  className = '',
  showCommonChronicDiagnoses = true,
}: ChronicDiagnosesNoteComponentProps) => (
  <div
    className={classNames({
      'chronic-diagnoses-note': true,
      [className]: !!className,
    })}
  >
    <span>
      {children}
    </span>
    {
      showCommonChronicDiagnoses
      && (
        <CommonChronicDiagnosesModalComponent />
      )
    }
  </div>
);
