import { ClinicEvent } from '../../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';

export interface FollowUpVisitMTPREligibilityComponentProps {
  followUpVisit: ClinicEvent;
}

export const FollowUpVisitMTPREligibilityComponent = ({
  followUpVisit,
}: FollowUpVisitMTPREligibilityComponentProps) => (
  <div>
    This patient has a MTPR in a follow up visit scheduled this month on
    {' '}
    <DisplayDateComponent value={followUpVisit.startTime} format="USA_DATE" />
    {', '}
    do you still want to create this MTPR?
  </div>
);
