import { map } from 'lodash';
import dayjs from 'dayjs';
import { MedicalAlertDashboardDataSource, MedicalAlertDashboardTableComponent } from '../../component/MedicalAlertDashboardTableComponent/MedicalAlertDashboardTableComponent';
import { TableCacheNameEnum, useTableChange } from '../../../../hooks/useTableChange/useTableChange';
import { sorterHelper } from '../../../../hooks/useTableChange/sorterHelper';
import {
  PriorityLevelEnum,
  SmartAlertTaskStatus,
  useMedicalAlertSearchPatientAlerts,
  SearchRequest,
  PatientAlertSearchRequest,
} from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { useMedicalAlertDashboardContext } from '../../context/MedicalAlertDashboardContext';

export const MedicalAlertDashboardTableContainer = () => {
  const {
    selectedClinicId,
    setSelectedClinicId,
    selectedAlertType,
    setSelectedAlertType,
    selectedFromToDate,
    setSelectedFromToDate,
  } = useMedicalAlertDashboardContext();

  const tableChange = useTableChange<MedicalAlertDashboardDataSource, PatientAlertSearchRequest>({
    filter: {
      status: [SmartAlertTaskStatus.TODO],
    },
    name: TableCacheNameEnum.MedicalAlertDashboard,
    sortProcessor: (sorter) => sorterHelper(sorter),
    filterProcessor: (filters) => {
      const newFilters: SearchRequest<PatientAlertSearchRequest>['filter'] = {};
      if (filters.memberName) {
        newFilters.patientIds = filters.memberName as string[];
      }
      if (filters.alerts) {
        newFilters.priorities = filters.alerts as PriorityLevelEnum[];
      }
      if (filters.assignedRdHC) {
        newFilters.assigneeIds = filters.assignedRdHC as string[];
      }
      if (filters.provider) {
        newFilters.providerIds = filters.provider as string[];
      }
      if (filters.status) {
        newFilters.status = filters.status as SmartAlertTaskStatus[];
      }
      return newFilters;
    }
  });

  const medicalAlertHook = useMedicalAlertSearchPatientAlerts({
    options: { sendOnMount: true },
    params: {
      searchRequest: {
        filter: {
          clinicIds: selectedClinicId,
          alertTypes: selectedAlertType,
          triggeredOnTsStart: dayjs(selectedFromToDate?.fromDate).valueOf(),
          triggeredOnTsEnd: dayjs(selectedFromToDate?.toDate).valueOf(),
          ...tableChange.processedFilter
        },
        pageInfo: tableChange.pageInfo,
      }
    }
  });

  return (
    <FetchComponent
      info={medicalAlertHook}
      showOnRefetch
    >
      {(data, info) => {
        const result: MedicalAlertDashboardDataSource[] = data?.data
          ? map(medicalAlertHook.data?.data?.content, (v) => ({
            id: v.memberId as string,
            memberId: v.memberId as string,
            memberName: v.memberName as string,
            alerts: v.alertItemList?.map((alertItem) => ({
              alertName: alertItem.alertName as string,
              reason: alertItem.reason as string,
              priority: alertItem.priority as PriorityLevelEnum,
            })) || [],
            provider: v.providerName as string,
            assignedRdHC: v.assignedTo as string,
            triggeredOn: v.alertItemList?.map((alertItem) => (
              alertItem.triggeredOnTs as number
            )) || [],
            resolvedOn: v.alertItemList?.map((alertItem) => (
              alertItem.resolvedOnTs as number
            )) || [],
            resolvedNote: v.alertItemList?.map((alertItem) => (
              alertItem.resolvedNote as string
            )) || [],
            status: v.alertItemList?.map((alertItem) => (
              alertItem.status as SmartAlertTaskStatus
            )) || [],
          })) : [];
        return (
          <MedicalAlertDashboardTableComponent
            dataSource={result}
            sorters={tableChange.sort}
            filters={tableChange.filter}
            onChange={tableChange.handleTableChange}
            clinic={selectedClinicId?.at(0)}
            onChangeClinic={(clinic) => setSelectedClinicId(clinic)}
            onChangeFromDateToDate={(value) => setSelectedFromToDate(value)}
            onChangeAlertType={(type) => setSelectedAlertType(type)}
            fromDateToDate={selectedFromToDate}
            isLoading={info.isLoading}
            pagination={info.pagination}
            alertType={selectedAlertType}
            patientsCount={info.data?.data?.totalSize || 0}
          />
        );
      }}
    </FetchComponent>
  );
};
