import {
  ReactNode,
  createContext,
  useContext,
} from 'react';
import classNames from 'classnames';
import { notification } from 'antd';
import { isEmpty } from 'lodash';
import { ArgsProps } from 'antd/lib/notification';
import dayjs from 'dayjs';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import { CalendarNotificationComponent } from '../../features/calendar/component/CalendarNotificationComponent/CalendarNotificationComponent';
import { ClinicEvent, useClinicEventGet } from '../../uc-api-sdk';
import { ApiRequestHelper } from '../../helpers/ApiRequest';
import { Icons } from '../../icons/Icons';
import useChangePage from '../../hooks/useChangePage/useChangePage';
import { URL_DATE } from '../../constants/timeFormat';

import './CalendarNotification.scss';

export type NotificationConfigs = Omit<ArgsProps, 'key' | 'message'> & {
  closeIfExist?: boolean;
};

export interface CalendarNotificationContextValue {
  makeCalendarNotification: (
    clinicEvent: ClinicEvent,
    configs?: NotificationConfigs,
  ) => void;
  openNotification: (
    clinicEventId: string,
    configs?: NotificationConfigs,
  ) => void;
  closeNotification: (clinicEventId: string) => void;
}

const CalendarNotificationContext = createContext<
  CalendarNotificationContextValue | undefined
>(undefined);

export const useCalendarNotificationContext = () => {
  const context = useContext(CalendarNotificationContext);
  if (!context) throw new Error('CalendarNotificationContext not found');
  return context as CalendarNotificationContextValue;
};

export interface CalendarNotificationContextProviderProps {
  children: ReactNode;
}
export const CalendarNotificationContextProvider = ({
  children,
}: CalendarNotificationContextProviderProps) => {
  const changePage = useChangePage();
  const clinicEventInfo = useClinicEventGet({ options: { sendOnMount: false } });

  const makeKey = (clinicEventId: string) => `calendar-notification-${clinicEventId}`;

  const handleCloseNotification = (clinicEventId: string) => {
    notification.close(makeKey(clinicEventId));
  };

  const handleOnClickCTAButton = (clinicEvent: ClinicEvent) => {
    const {
      id: clinicEventId = '',
      calendarEventId,
      startTime,
      patientInfo,
    } = clinicEvent || {};
    const patientId = patientInfo?.id || '';
    if (patientId) {
      changePage.goToCarePortalPatient({ patientId });
    } else {
      window.open(changePage.createCalendarUrl({
        eventId: calendarEventId || '',
        date: dayjs(startTime).format(URL_DATE),
      }), '_blank');
    }
    handleCloseNotification(clinicEventId || '');
  };

  const makeCalendarNotification: CalendarNotificationContextValue['makeCalendarNotification'] = (
    clinicEvent,
    configs,
  ) => {
    const clinicEventId = clinicEvent?.id || '';
    const {
      closeIfExist = true,
      ...notificationConfigs
    } = configs || {};
    const {
      className = '',
      ...restNotificationProps
    } = notificationConfigs || {};
    if (closeIfExist) {
      handleCloseNotification(clinicEventId);
    }
    notification.open({
      key: makeKey(clinicEventId),
      duration: 0,
      className: classNames({
        'calendar-notification-component': true,
        [className]: !!className,
      }),
      message: 'Calendar Notification',
      icon: <Icons.NotificationBellIcon />,
      description: (
        <CalendarNotificationComponent
          key={clinicEventId}
          clinicEvent={clinicEvent}
          onClick={() => {
            handleOnClickCTAButton(clinicEvent);
          }}
        />
      ),
      ...restNotificationProps,
    });
  };

  const openNotification: CalendarNotificationContextValue['openNotification'] = (
    clinicEventId,
    configs,
  ) => {
    ApiRequestHelper.tryCatch(
      clinicEventInfo.send({
        params: {
          id: clinicEventId,
        },
      }),
      {
        success: '',
        error: '',
        onSuccess: (res) => {
          const clinicEvent = res?.data;
          if (isEmpty(clinicEvent)) {
            console.warn('Event not found');
            return;
          }
          makeCalendarNotification(clinicEvent, configs);
        },
      }
    );
  };

  const closeNotification = (clinicEventId: string) => {
    handleCloseNotification(clinicEventId);
  };

  const contextValue = useGetContextValue<CalendarNotificationContextValue>({
    makeCalendarNotification,
    openNotification,
    closeNotification
  });

  return (
    <CalendarNotificationContext.Provider value={contextValue}>
      {children}
    </CalendarNotificationContext.Provider>
  );
};
