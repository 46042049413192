import { USA_DATE } from '../../../../constants/timeFormat';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import { AssociatedInfo } from '../../../../uc-api-sdk';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { MedicalOrgService } from '../../../adminPortal2/medicalOrg/service/MedicalOrgService';
import './PotentialClinicTooltipComponent.scss';

export interface PotentialClinicTooltipComponentProps {
  clinics?: AssociatedInfo[];
}

export const PotentialClinicTooltipComponent = ({ clinics = [] }
  : PotentialClinicTooltipComponentProps) => {
  const { isProvider } = useLoggedInUserFromContext();

  const title = (
    <div className="associated-clinic-list">
      <p>
        This patient is a potential patient in the clinics below:
      </p>
      <div className="flex fd-c gap2">
        {clinics.map((clinic) => (
          <div key={clinic?.clinicInfo?.id || ''} className="flex jc-sb">
            <div className="associated-clinic-alias">{MedicalOrgService.getLimitedClinicName(clinic?.clinicInfo || {}, isProvider)}</div>
            <div className="associated-clinic-time">
              {
                TimezoneService.calcDateTimeDayjs(
                  clinic?.uploadedTime || '',
                ).format(USA_DATE)
              }

            </div>
          </div>
        ))}
      </div>
    </div>

  );
  if ((clinics?.length || 0) <= 1) {
    return null;
  }
  return (
    <TooltipComponent
      type="info-icon"
      overlayClassName="potential-clinic-tooltip-component-overlay"
      title={title}
      placement="bottom"
      className="potential-clinic-tooltip-component"
    />
  );
};
