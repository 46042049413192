import { MutationOptions } from '../types';
import StrictFormData from '../formData/strictFormData';
import { PathType } from '../helper';
import usePostPutDelete, { ReqType } from '../usePostPutDelete/usePostPutDelete';

const usePostPutWithFormData = (type: ReqType.POST | ReqType.PUT) => <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Variables extends StrictFormData<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Data extends object = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PathParams extends object = any>(
    path: PathType,
    options?: MutationOptions,
  ) => () => {
    const customHeaders = options?.headers || {};
    const {
      mutateAsync: _mutateAsync,
      ...rest
    } = usePostPutDelete(type)<FormData, Data, PathParams>(
      path,
      {
        ...options,
        headers: {
          'Content-Type': 'multipart/form-data',
          ...customHeaders,
        },
      },
    )();

    const mutateAsync = (strictFormData: Variables, pathParams = {} as PathParams) => {
      const formData: FormData = strictFormData.get();
      return _mutateAsync(formData, pathParams);
    };

    return {
      mutateAsync,
      ...rest,
    };
  };

export default usePostPutWithFormData;
