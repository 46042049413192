import { flatten, map } from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { FC, useMemo } from 'react';
import { createBGPointChartSeries } from './BGPointChartHelper';
import { BGChartMeasurement, createBGChartOptions } from './BGChartHelper';
import { createMarker, findMaxWithStep, getYValue } from '../../helper/chartHelper';
import { BGChartHelper } from '../../helper/BGChartHelper';
import { SeverityHelper } from '../../helper/severityHelper';
import { useBGThresholdPlotBand } from '../../hook/useBGThresholdPlotBand/useBGThresholdPlotBand';
import { FromDateToDateDayjs } from '../../type';

export interface BGPointChartComponentProps extends FromDateToDateDayjs {
  data: BGChartMeasurement[][];
}

export const BGPointChartComponent: FC<BGPointChartComponentProps> = ({
  data,
  fromDate,
  toDate,
}) => {
  const {
    normalRanges,
    showAfterMeal,
    showBeforeMeal,
    showBreakfastMeal,
    showOverNightMeal,
    toggleAfterMeal,
    toggleBeforeMeal,
    toggleBreakfastMeal,
    toggleOverNightMeal,
  } = useBGThresholdPlotBand();
  const getData = (
    filterMeasurements: ((v: BGChartMeasurement[]) => BGChartMeasurement[]),
  ) => {
    const values = map(data, (pointArr) => {
      const filteredMeasurements = filterMeasurements(pointArr);
      return map(filteredMeasurements, (point) => ({
        x: point.date.valueOf(),
        y: point.bloodGlucose,
        severity: point.severity,
        marker: createMarker({ severity: point.severity }),
      }));
    });
    return flatten(values);
  };

  const options = useMemo(() => {
    const beforeBreakfast = getData(BGChartHelper.getBeforeBreakfast);
    const beforeMeal = getData(BGChartHelper.getBeforeMeal);
    const afterMeal = getData(BGChartHelper.getAfterMeal);
    const overnightBedtime = getData(BGChartHelper.getOvernightBedtime);
    const critical = getData(SeverityHelper.getCritical);
    const risk = getData(SeverityHelper.getRisk);
    const series = createBGPointChartSeries({
      beforeBreakfast,
      beforeMeal,
      afterMeal,
      overnightBedtime,
      critical,
      risk,
      onBeforeBreakfastClick: toggleBreakfastMeal,
      showOverNightMeal,
      onOvernightClick: toggleOverNightMeal,
      onBeforeMealClick: toggleBeforeMeal,
      showAfterMeal,
      onAfterMealClick: toggleAfterMeal,
    });
    const max = findMaxWithStep(
      [
        ...beforeBreakfast,
        ...beforeMeal,
        ...afterMeal,
        ...overnightBedtime,
        ...critical,
        ...risk,
      ].map(getYValue),
      50,
      600,
    );
    return createBGChartOptions({
      series,
      fromDate,
      toDate,
      max,
      showAfterMeal,
      showBeforeMeal: showBeforeMeal || showBreakfastMeal,
      beforeMealRange: normalRanges.beforeMealNormalRange,
      afterMealRange: normalRanges.afterMealNormalRange,
    });
  }, [
    data,
    fromDate,
    toDate,
    showAfterMeal,
    showBeforeMeal,
    showBreakfastMeal,
    normalRanges,
  ]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
};
