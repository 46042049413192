import { ReactNode } from 'react';
import { isEmpty } from 'lodash';
import { EnrollmentRequest } from '../../../../uc-api-sdk';
import { EnrollmentSectionsEnum, EnrollmentSectionsStatuses } from './type';
import { EnrollmentProgressProvider } from './EnrollmentProgressContext';

export interface EnrollmentProgressEnhancedProviderProps {
  enrollment: EnrollmentRequest | null;
  children: ReactNode;
}

export const EnrollmentProgressEnhancedProvider = ({
  enrollment,
  children,
}: EnrollmentProgressEnhancedProviderProps) => {
  const enrollmentProgressInitialValue: EnrollmentSectionsStatuses = {
    [EnrollmentSectionsEnum.Diagnosis]:
      !isEmpty(enrollment?.programEnrollment?.patientDiagnoses),
    [EnrollmentSectionsEnum.VitalsAndPrograms]:
      !isEmpty(enrollment?.programEnrollment?.vitalTypes)
      || !isEmpty(enrollment?.programEnrollment?.programCategories),
    [EnrollmentSectionsEnum.Insurance]:
      !isEmpty(enrollment?.programEnrollment?.patientInsurance),
    [EnrollmentSectionsEnum.BasicInfo]:
      !isEmpty(enrollment?.basicInformation),
    [EnrollmentSectionsEnum.Consent]: false,
    [EnrollmentSectionsEnum.TechLevel]:
      !isEmpty(enrollment?.patientDevice?.patientTechLevel),
    [EnrollmentSectionsEnum.DeliveryMethod]:
      !isEmpty(enrollment?.patientDevice?.deviceDeliveryMethod),
    [EnrollmentSectionsEnum.Devices]:
      !isEmpty(enrollment?.patientDevice?.deviceList),
    [EnrollmentSectionsEnum.TechOnboarding]:
      !isEmpty(enrollment?.techOnboardingVisitEvent),
    [EnrollmentSectionsEnum.InitialAssessment]:
      !isEmpty(enrollment?.initialAssessmentVisitEvent),
  };

  return (
    <EnrollmentProgressProvider initialValue={enrollmentProgressInitialValue}>
      {children}
    </EnrollmentProgressProvider>
  );
};
