import { TableCacheNameEnum } from '../../../../hooks/useTableChange/useTableChange';
import { PatientListTypeEnum } from '../../../../uc-api-sdk';
import { PatientsTableContainer } from '../../../patients/container/PatientsTableContainer/PatientsTableContainer';
import { ProviderViewMyClinicPatientsTableComponent } from '../../component/ProviderViewMyClinicPatientsTableComponent/ProviderViewMyClinicPatientsTableComponent';

export interface ProviderViewClinicPatientsContainerProps {
  orgId: string;
}

export const ProviderViewClinicPatientsContainer = ({
  orgId,
}: ProviderViewClinicPatientsContainerProps) => (
  <PatientsTableContainer
    type={PatientListTypeEnum.ALL}
    name={`${TableCacheNameEnum.AllPatients}${orgId}`}
    clinicId={orgId}
    key={orgId}
  >
    {({
      totalSize,
      pagination,
      dataSource,
      onChange,
      filters,
      sorters,
      isLoading,
      exportData,
    }) => (
      <ProviderViewMyClinicPatientsTableComponent
        totalSize={totalSize}
        pagination={pagination}
        dataSource={dataSource}
        onChange={onChange}
        filters={filters}
        sorters={sorters}
        loading={isLoading}
        selectedOrgId={orgId}
        exportData={exportData}
      />
    )}
  </PatientsTableContainer>
);
