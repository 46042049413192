import { AccountHelper } from '../../../../helpers/account';

export interface UserNameTitleBase {
  firstName?: string | null;
  lastName?: string | null;
  title?: string | null;
}

export interface UserNameTitleComponentProps<T extends UserNameTitleBase> {
  value?: T | null;
}

export const UserNameTitleComponent = <T extends UserNameTitleBase>({
  value,
}: UserNameTitleComponentProps<T>) => (
    value
      ? (
        <span className="user-name-title-component">
          {AccountHelper.getFullName(value)}
          {value?.title && ', '}
          {value?.title}
        </span>
      )
      : null
  );
