export enum ParticipantsEnum {
  RdHc = 'RDHC',
  CA = 'CA',
  MD = 'MD',
  MA = 'MA',
}

export enum ParticipantsFormEnum {
  RdHc = 'rdhc',
  CA = 'ca',
  MD = 'md',
  MA = 'ma',
}
