import { getDocument } from 'pdfjs-dist';
import { filenameReservedCharacter } from '../../constants/regex/filenameReservedCharacter';

// eslint-disable-next-line import/no-unresolved
import 'pdfjs-dist/legacy/build/pdf.worker.min.mjs'; // For Webpack or similar bundlers

export const pdfStringHeaders = 'data:application/pdf;base64,';

export const getBase64 = (file: File, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(file);
};

export const getAsyncBase64 = (file: File): Promise<string> => (
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result as string));
    reader.readAsDataURL(file);
  })
);

export const sanitizeFilename = (
  filename: string,
  replacement = ''
) => (
  // remove/ replace all reserved characters
  filename.replace(filenameReservedCharacter, replacement)
);

export const isImageFile = (file?: File): boolean | undefined => (
  file
    ? (
      file?.type?.includes('image/')
      || /\.(jpe?g|png|gif|bmp)$/i.test(file?.name)
    ) : undefined
);

export const isPDFFile = (file?: File): boolean | undefined => (
  file
    ? (
      file?.type?.includes('pdf')
      || /\.pdf$/i.test(file?.name)
    ) : undefined
);

const convertImageToBase64 = async (file: File) => {
  try {
    if (!isImageFile(file)) {
      console.warn('File is not image');
      return null;
    }
    const base64 = await getAsyncBase64(file);
    return [base64];
  } catch (error) {
    console.error('Error reading or encoding the image file:', error);
    return null;
  }
};

const convertPDFToImageBase64 = async (
  file: File,
) => {
  try {
    if (!isPDFFile(file)) {
      console.warn('File is not pdf');
      return null;
    }
    const arrayBuffer = await file.arrayBuffer();

    const loadingTask = getDocument({ data: arrayBuffer ?? undefined });
    const pdf = await loadingTask.promise;
    const { numPages } = pdf;
    const promises = Array(numPages).fill(0).map(async (_, pageNum) => {
      const page = await pdf.getPage(pageNum + 1);
      const viewport = page.getViewport({ scale: 1 });
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d') as CanvasRenderingContext2D;
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: context, viewport }).promise;

      // Convert canvas to image data URL
      const imageDataUrl = canvas.toDataURL('image/png');
      return imageDataUrl;
    });
    return await Promise.all(promises);
  } catch (error) {
    console.error('Error reading or encoding the pdf file:', error);
    return null;
  }
};

export const convertFileToBase64 = async (file: File) => {
  try {
    if (isImageFile(file)) {
      return convertImageToBase64(file);
    }
    if (isPDFFile(file)) {
      return convertPDFToImageBase64(file);
    }
    return null;
  } catch (error) {
    console.error('Error reading or encoding the file:', error);
    return null;
  }
};
