import {
  useState
} from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { useLoggedInUserFromContext } from '../../contexts/loggedInUserContext';
import { SelectType } from '../../features/Input/types';
import useDebounce from '../../hooks/useDebounce/useDebounce';
import { useScrollPagination } from '../../hooks/useScrollPagination/useScrollPagination';
import {
  AllAccessMedicalOrgRequest,
  LimitedClinic,
  MedicalOrganization,
  useMedicalOrganizationSearchAllAccessMedicalOrg,
} from '../../uc-api-sdk';
import { DEFAULT_CLINIC_SEARCH_PAGE_SIZE } from './constant';
import { ClinicSelectContainerHelper } from './helper';

interface ChildrenProps {
  clinicSearchInfo: ReturnType<typeof useMedicalOrganizationSearchAllAccessMedicalOrg>;
  allClinics: LimitedClinic[];
  isLoading: boolean;
  handleOnSearch: SelectType<string>['onSearch'];
  handleOnScroll: ReturnType<typeof useScrollPagination>['handleOnScroll'];
}

export interface ClinicSelectContainerProps {
  defaultSearchValue?: string;
  filters?: MedicalOrganization & AllAccessMedicalOrgRequest;
  defaultSearchSize?: number;
  onSearch?: (value: string) => void;
  children: (props: ChildrenProps) => JSX.Element;
  dataIdentifier?: string;
  disableScrollPagination?: boolean;
  paginatedSearch?: boolean;
}

export const ClinicSelectContainer = ({
  defaultSearchValue = '',
  filters = {},
  defaultSearchSize = DEFAULT_CLINIC_SEARCH_PAGE_SIZE,
  onSearch,
  children,
  dataIdentifier,
  disableScrollPagination = false,
  paginatedSearch = true,
}: ClinicSelectContainerProps) => {
  const [localSearchValue, setLocalSearchValue] = useState(defaultSearchValue);
  const [localSearchSize] = useState(defaultSearchSize);
  const clinicSearchInfo = useMedicalOrganizationSearchAllAccessMedicalOrg({});
  const { isProvider } = useLoggedInUserFromContext();

  const handleMakeParams: typeof ClinicSelectContainerHelper.makeParams = (
    args,
  ) => (
    ClinicSelectContainerHelper.makeParams({
      ...args,
      pagination: !!paginatedSearch
    })
  );

  const {
    allData,
    handleOnScroll,
  } = useScrollPagination({
    requestInfo: clinicSearchInfo,
    fetchParams: (page) => handleMakeParams({
      value: localSearchValue,
      page,
      size: localSearchSize,
      filters,
    }),
    dataIdentifier,
    disabled: disableScrollPagination,
  });

  const handleOnSearch: SelectType<LimitedClinic>['onSearch'] = (
    value,
  ) => {
    setLocalSearchValue(value);
    onSearch?.(value);
    if (!value) return;
    clinicSearchInfo.send({
      params: handleMakeParams({
        value,
        size: localSearchSize,
        filters,
      }),
    });
  };

  const debouncedSearchValue = useDebounce(handleOnSearch);

  useEffectOnce(() => {
    clinicSearchInfo.send({
      params: handleMakeParams({
        value: localSearchValue,
        size: localSearchSize,
        filters,
      }),
    });
  });

  return (
    <>
      {
        children({
          clinicSearchInfo,
          isLoading: clinicSearchInfo.isLoading,
          allClinics: ClinicSelectContainerHelper.makeAndSortClinics(
            allData as LimitedClinic[],
            { isProvider }
          ).map((clinic) => clinic.clinicData) as LimitedClinic[],
          handleOnSearch: debouncedSearchValue,
          handleOnScroll,
        })
      }
    </>
  );
};
