import { WarningFilled } from '@ant-design/icons';
import { TagComponent } from '../../../../uiComponent/TagComponent/TagComponent';
import { CardTextComponent } from '../../../../uiComponent/CardTypographyComponent/CardTextComponent/CardTextComponent';
import { DeviceModelEnum } from '../../../../uc-api-sdk';
import { DeviceUnassignComponent } from '../DeviceUnassignComponent/DeviceUnassignComponent';

export interface DeviceMonitorUnassignInfoComponentProps {
  deviceId: string;
  deviceModel: DeviceModelEnum;
  onUnassign: () => void;
}

export const DeviceMonitorUnassignInfoComponent = ({
  deviceId,
  deviceModel,
  onUnassign,
}: DeviceMonitorUnassignInfoComponentProps) => (
  <div className="mt50">
    <TagComponent
      background="none"
      icon={<WarningFilled />}
      type="warning"
      iconType="solidDot"
      className="pl0i"
    >
      <CardTextComponent>
        Please unassign the device below to proceed the action
      </CardTextComponent>
    </TagComponent>
    <DeviceUnassignComponent
      deviceId={deviceId}
      deviceModel={deviceModel}
      onUnassign={onUnassign}
    />
  </div>
);
