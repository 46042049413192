import {
  ReactNode,
  createContext,
  useContext,
} from 'react';
import { compact, values } from 'lodash';
import { PatientInfo } from '../PatientInfoContext/PatientInfoContext';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import { useGetDeviceInfo } from '../../features/device/hook/useGetDeviceInfo';
import { VitalEnumType, DeviceModelEnum, LoanDevice } from '../../uc-api-sdk';
import { ConnectedDeviceInfo } from '../../features/device/type';
import { DeviceMonitorMethodEnum } from '../../enumComponent/DeviceMonitorMethodEnumComponent/DeviceMonitorMethodEnumComponent';

export interface OrderSuppliesContextValue {
  allMostRecentDevices?: ConnectedDeviceInfo[];
  patientVitals?: VitalEnumType[];
  isBPM1WithRouter: boolean;
  isBPM1WithHotspot: boolean;
  isEaseDevice: boolean;
  loanerPhone?: LoanDevice;
}

const OrderSuppliesContext = createContext<
  OrderSuppliesContextValue | undefined
>(undefined);

export const useOrderSuppliesContext = () => {
  const context = useContext(OrderSuppliesContext);
  return (context || {}) as OrderSuppliesContextValue;
};

export interface OrderSuppliesContextProviderProps {
  patientInfo: PatientInfo;
  children: ReactNode;
}
export const OrderSuppliesContextProvider = ({
  patientInfo,
  children,
}: OrderSuppliesContextProviderProps) => {
  const {
    enrolledProgramService,
    devicesService,
  } = patientInfo;
  const {
    getMostRecentDevicesByType,
    getAllMostRecentDevices,
    getLoanerPhoneDevice,
    getMonitorMethodByType,
  } = useGetDeviceInfo(devicesService?.getPatientDevices());

  const allMostRecentDevices = compact(getAllMostRecentDevices());
  const loanerPhone = getLoanerPhoneDevice();

  const BPDevice = values(getMostRecentDevicesByType(VitalEnumType.BP))?.[0];
  const isBPM1WithRouter = BPDevice?.deviceModel === DeviceModelEnum.BPM1;
  const isBPM1WithHotspot = BPDevice?.deviceModel === DeviceModelEnum.BPM1_HOTSPOT;
  const isEaseDevice = (
    BPDevice?.deviceModel === DeviceModelEnum.BP3L
    || getMonitorMethodByType(VitalEnumType.BP) === DeviceMonitorMethodEnum.APP_MONITOR
  );

  const patientVitals = enrolledProgramService?.getVitals();

  const contextValue = useGetContextValue<OrderSuppliesContextValue>({
    allMostRecentDevices,
    patientVitals,
    isBPM1WithRouter,
    isBPM1WithHotspot,
    isEaseDevice,
    loanerPhone,
  });

  return (
    <OrderSuppliesContext.Provider value={contextValue}>
      {children}
    </OrderSuppliesContext.Provider>
  );
};
