import { useEffect } from 'react';
import { usePrevious } from '../usePrevious/usePrevious';

export const useEffectFirstTimeWithValue = <T>(value: T | undefined, func: (value: T) => void) => {
  const previous = usePrevious(value);

  useEffect(() => {
    if (previous === undefined && value !== undefined) {
      func(value);
    }
  }, [previous, value]);
};
