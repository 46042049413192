import { Select, SelectProps } from 'antd';
import { range } from 'lodash';
import { DefaultOptionType, FilterFunc } from 'rc-select/lib/Select';
import { useMemo } from 'react';
import { TimeService } from '../../../../helpers/time/timeService';
import { CalendarSourceEnum } from '../../../../uc-api-sdk';
import { InputType } from '../../../Input/types';

export interface TimeSelectComponentProps extends InputType<number> {
  disabled?: boolean;
  minValue?: number;
  isInit?: boolean;
  source?: CalendarSourceEnum;
  placeholder?: string;
}

export const TimeSelectComponent = ({
  value,
  onChange,
  disabled,
  minValue,
  isInit = false,
  source,
  placeholder
}: TimeSelectComponentProps) => {
  const options = useMemo<SelectProps['options']>(() => {
    const startTime = minValue ? minValue / 60 : 5;
    const endTime = 21;
    // startTime * 15 * 4 for number of 15 minutes in a day
    // endTime * 15 * 4 for number of 15 minutes in a day
    // 15 for every 15 minutes
    const res: SelectProps['options'] = [];
    if (isInit) {
      const startTime = (minValue || 0) + 30;
      if (minValue) {
        range(startTime, startTime + 3 * 15, 15).forEach((v) => {
          res.push({
            value: v,
            label: TimeService.getTimeLabel(v),
            disabled: undefined,
          });
        });
      }
      return res;
    }
    range(startTime * 4 * 15, endTime * 15 * 4 + 15, 15).forEach((v) => {
      res.push(
        {
          value: v,
          label: TimeService.getTimeLabel(v),
          // disabled: minValue ? v <= minValue : undefined,
          disabled: undefined,
        }
      );
    });
    if (value) {
      // if after 5 pm
      if (value > endTime * 60) {
        res.push({
          value,
          label: TimeService.getTimeLabel(value),
          disabled: undefined,
        });
      }
      // if before 9 pm
      if (value < startTime * 60) {
        res.unshift({
          value,
          label: TimeService.getTimeLabel(value),
          disabled: undefined,
        });
      }
    }

    return res;
  }, [minValue, value, source, isInit]);

  const filterOption: FilterFunc<DefaultOptionType> = (input, option) => {
    let trimmedInput = input;
    if (input.startsWith('0') && input.length === 1) {
      return String(option?.label).length === 7;
    }
    if (input.startsWith('0')) {
      trimmedInput = input.replace(/^0+/, '');
    }

    return String(option?.label)?.toLowerCase()
      .startsWith(trimmedInput.toLowerCase());
  };

  return (
    <Select
      disabled={disabled}
      value={value}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      showSearch
      optionFilterProp="label"
      filterOption={filterOption}
    />
  );
};
