import React, { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import './TextWithSubAndLinkComponent.scss';

export interface TextWithSubAndLinkProps {
  text: ReactNode;
  sub?: string;
  link?: string;
}

export const TextWithSubAndLink: FC<TextWithSubAndLinkProps> = ({
  text,
  sub,
  link,
}) => {
  const navigate = useNavigate();
  const handleDirectToLink = () => {
    if (link) {
      navigate(link);
    }
  };
  return (
    <div className="text-with-sub">
      <div
        data-testid="main-text"
        onClick={handleDirectToLink}
        className="main-text with-link"
        role="button"
        aria-hidden="true"
      >
        {text}
      </div>
      <div data-testid="sub-text" className="sub-text">{sub}</div>
    </div>
  );
};

export default TextWithSubAndLink;
