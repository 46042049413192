import {
  intersection, isEmpty, keyBy, map
} from 'lodash';
import { Divider } from 'antd';
import { MonitoringVital, VitalEnumType } from '../../../../../uc-api-sdk';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';
import { VitalsEnumTypeComponent } from '../../../../../enumComponent/VitalsEnumTypeComponent/VitalsEnumTypeComponent';
import { VitalsMonitoringExerciseComponent } from '../../../../vitals/component/VitalsMonitoringScheduleComponent/VitalsMonitoringExerciseComponent/VitalsMonitoringExerciseComponent';
import DisplayOrEmptyComponent from '../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { useDeepCompareMemo } from '../../../../../hooks/useDeepCompareEffect';
import { vitalsMonitoringOrder } from '../../../../../lib/constants/vitalsMonitoringOrder';
import { FreqComponent } from '../../../../../uiComponent/FreqComponent/FreqComponent';
import EmptyComponent from '../../../../../uiComponent/EmptyComponent/EmptyComponent';

export interface PrintCarePlanVitalsMonitoringGoalComponentProps {
  monitoringVitals?: MonitoringVital[] | null;
}

export const PrintCarePlanVitalsMonitoringGoalComponent = ({
  monitoringVitals
}: PrintCarePlanVitalsMonitoringGoalComponentProps) => {
  const [vitalsTypeInOrder, vitalsByType] = useDeepCompareMemo(() => {
    const vitalsType = map(monitoringVitals, 'type');
    const vitalsTypeInOrder = intersection(vitalsMonitoringOrder, vitalsType);
    const vitalsByType = keyBy(monitoringVitals, 'type');
    return [vitalsTypeInOrder, vitalsByType];
  }, [monitoringVitals]);

  const renderGoal = (monitoringVital: MonitoringVital) => {
    const {
      type,
      target,
      repeat,
      templateDetails,
    } = monitoringVital;

    switch (type) {
      case VitalEnumType.BG:
        return (
          <DisplayOrEmptyComponent
            value={(
              templateDetails?.description
              || templateDetails?.name
            )}
          />
        );
      case VitalEnumType.EXERCISE:
        return (
          <VitalsMonitoringExerciseComponent
            value={monitoringVital}
          />
        );
      default:
        if (!target) {
          return <EmptyComponent />;
        }
        return (
          <FreqComponent
            value={target}
            unit={repeat ?? undefined}
          />
        );
    }
  };

  if (isEmpty(vitalsTypeInOrder)) {
    return null;
  }

  return (
    <>
      <div className="page-break" />
      <TextComponent className="pop-text">
        Vitals Monitoring Goal
      </TextComponent>

      {
        map(vitalsTypeInOrder, (vitalType, idx) => {
          const monitoringVital = vitalsByType[vitalType as VitalEnumType];
          return (
            <div key={idx} className="mb10">
              <TextComponent size="medium" bold>
                <VitalsEnumTypeComponent
                  value={monitoringVital.type as VitalEnumType}
                  type={monitoringVital.type === VitalEnumType.BG ? 'long' : 'short'}
                />
              </TextComponent>
              <div>
                {renderGoal(monitoringVital)}
              </div>
            </div>
          );
        })
      }

      <Divider />
    </>
  );
};
