import { ExportOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useBoolean } from 'usehooks-ts';
import { PatientResult } from '../../uc-api-sdk';
import { ExportDataFunction } from '../../features/patients/container/PatientsTableContainer/PatientsTableContainer';

export interface ModalExportDataComponentProps {
  exportData?: ExportDataFunction;
  columns?: ColumnsType<PatientResult>;
  totalSize?: number;
  isProvider: boolean;
}

export const ModalExportDataComponent = ({
  exportData,
  columns,
  totalSize,
  isProvider,
}: ModalExportDataComponentProps) => {
  const {
    value: loading,
    setTrue,
    setFalse,
  } = useBoolean();

  const key = 'downloading';
  const [messageApi, contextHolder] = message.useMessage();

  const openMessage = () => {
    messageApi.open({
      key,
      type: 'loading',
      content: 'Downloading patient list',
    });
  };

  const onExportData = async () => {
    openMessage();
    setTrue();
    await exportData?.(columns, isProvider, totalSize ?? 0);
    setFalse();
    messageApi.open({
      key,
      type: 'success',
      content: 'Downloaded!',
      duration: 2,
    });
  };

  return (
    <>
      {contextHolder}
      <Button
        icon={<ExportOutlined />}
        type="text"
        onClick={onExportData}
        loading={loading}
      >
        Export
      </Button>
    </>
  );
};
