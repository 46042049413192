import { Button, Modal } from 'antd';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import { ConnectedDeviceInfo } from '../../type';
import { BloodPressureDeviceInfoCardComponent } from './BloodPressureDeviceInfoCardComponent';
import { BloodPressureDeviceFormModalButtonComponent, BloodPressureDeviceFormModalButtonComponentProps } from '../BloodPressureDeviceModalButtonComponent/BloodPressureDeviceFormModalButtonComponent';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { useModalConfigContext } from '../../../../contexts/ModalConfigContext/ModalConfigContext';
import { Title } from '../../../../uiComponent/Title/Title';

export interface BloodPressureAssignedDeviceInfoComponentProps {
  device: ConnectedDeviceInfo;
  onUnassign?: (device: ConnectedDeviceInfo) => void;
  onUpdate?: (device: Partial<ConnectedDeviceInfo>) => void;
}

export const BloodPressureAssignedDeviceInfoComponent = ({
  device,
  onUnassign,
  onUpdate,
}: BloodPressureAssignedDeviceInfoComponentProps) => {
  const { isOpen, open, close } = useOpen();
  const { getContainer } = useModalConfigContext();

  const handleOnUpdate: BloodPressureDeviceFormModalButtonComponentProps['onSubmit'] = (
    value
  ) => {
    onUpdate?.(value);
  };

  return (
    <>
      <BloodPressureDeviceInfoCardComponent deviceModel={device.deviceModel}>
        <div className="flex jc-sb ai-c bg-gray5 p10 bdr-r">
          <div>
            Device ID:
            {' '}
            {device.deviceId}
          </div>
          <div className="flex gap3">
            {
              !!onUnassign
              && (
                <LinkButton
                  useBlueColor
                  onClick={open}
                >
                  Unassign
                </LinkButton>
              )
            }
            {
              !!onUpdate
              && (
                <BloodPressureDeviceFormModalButtonComponent
                  deviceModel={device.deviceModel}
                  device={device}
                  onSubmit={handleOnUpdate}
                >
                  <LinkButton useBlueColor>
                    Edit
                  </LinkButton>
                </BloodPressureDeviceFormModalButtonComponent>
              )
            }
          </div>
        </div>
      </BloodPressureDeviceInfoCardComponent>
      <Modal
        open={isOpen}
        onCancel={close}
        closable={false}
        maskClosable={false}
        destroyOnClose
        getContainer={getContainer}
        width={417}
        footer={null}
      >
        <Title className="fs16">
          Are you sure you want to unassign this device from the patient?
        </Title>
        <div>
          After the device is unassigned,
          the patient's measurements will no longer be uploaded to the system.
        </div>
        <div className="flex gap2 jc-e mt30">
          <Button
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => onUnassign?.(device)}
          >
            Unassign
          </Button>
        </div>
      </Modal>
    </>
  );
};
