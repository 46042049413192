class EmployeeProfilePhotoService {
  static getEmployeeProfilePhotoPublicUrl(fileKey?: string) {
    if (fileKey) {
      const publicS3Bucket = process.env.PUBLIC_S3_BUCKET || 'https://static.dev-new.ihealthunifiedcare.com';
      return `${publicS3Bucket}/${fileKey}`;
    }
    return undefined;
  }

  static isProfileDisabled(avatarUrl: string | undefined | null) {
    return avatarUrl === null;
  }
}

export default EmployeeProfilePhotoService;
