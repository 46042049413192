import 'amazon-connect-streams';
import { message } from 'antd';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useEffectOnce, useEventListener, useUpdateEffect } from 'usehooks-ts';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import EnvConfig from '../../../configs/envConfig/envConfig';
import { CallCenterSubscriptions } from './subscriptions';
import {
  ConnectAgent,
  ConnectCore,
  ConnectSuccessFailOptions,
  NextCallback,
  ConnectAgentStatus,
  ConnectAgentManualState,
  ConnectFrameMediaDevice,
  ConnectError,
  ConnectErrorContext,
} from '../types';
import {
  ConnectionInfo,
  AgentManualStates,
} from './types';
import useDebounce from '../../../hooks/useDebounce/useDebounce';
import { useGetContextValue } from '../../../hooks/useGetContextValue/useGetContextValue';
import { StorageKeyEnum, useSessionStorage } from '../../../hooks';

const { ccpDomain } = EnvConfig;
const ccpUrl = `${ccpDomain}/ccp-v2`;
const ccpUrlForLogin = `${ccpDomain}/login`;
const ccpUrlForLogout = `${ccpDomain}/logout`;

export const ccpLoginPopupId = 'ccp-login-popup';
export const ccpContainerId = 'ccp-frame-container';
const subs = new CallCenterSubscriptions();
const STATUS_INTERVAL_MS = 10000;
const STATUS_INTERVAL_ATTEMPT = 6;

const getContainer = () => (document.querySelector(`#${ccpContainerId}`) as HTMLElement);
const printInfo = (...args: unknown[]) => console.info('', new Date(), '[CCP]', ...args);
export const logoutCCP = async () => {
  await fetch(
    ccpUrlForLogout,
    {
      credentials: 'include',
      mode: 'no-cors',
    },
  );
  connect.core.terminate();
  return true;
};

const handleWindowIOStreamError = (
  cb?: () => void,
) => {
  // @ts-ignore
  // eslint-disable-next-line func-names
  connect.WindowIOStream.prototype.send = function (message) {
    let token = '';
    try {
      token = JSON.parse(sessionStorage.getItem(StorageKeyEnum.TOKEN) || '');
    } catch (error) {
      /* no-op */
    }
    try {
      this.output.postMessage(message, this.domain);
    } catch (error) {
      if (!token) {
        // ignore when logged out
        return;
      }
      // error could happen when connect-streams try to get status from iframe
      // but iframe is not initialized due to some unknown reason
      // try to restart services
      cb?.();
    }
  };
};

export interface CallCenterServicesValue {
  isStartingServices: boolean;
  isServicesStarted: boolean;
  agentStartTime?: Date;
  agentStatus?: ConnectAgentStatus;
  connectionInfo: ConnectionInfo | undefined;
  promptLogin: () => void;
  logout: (next?: NextCallback) => Promise<void>;
  setAgentState: (
    newState: ConnectAgentManualState,
    cb?: ConnectSuccessFailOptions,
    enqueueNextState?: boolean,
  ) => void,
  makePhoneCall: (phoneNumber: string, cb?: ConnectSuccessFailOptions) => void;
  errors: ConnectError[] | undefined;
  frameMediaDevices?: ConnectFrameMediaDevice[] | [];
  isLoggingIn: boolean;
}
const CallCenterServices = createContext<CallCenterServicesValue | undefined>(undefined);
export const useCallCenterServices = () => {
  const callCenterServices = useContext(CallCenterServices);
  return callCenterServices || {} as CallCenterServicesValue;
};
export interface CallCenterServicesProvideProps {
  children: ReactNode
}
export const CallCenterServicesProvider = ({
  children,
}: CallCenterServicesProvideProps) => {
  const [isStartingServices, setIsStartingServices] = useState(false);
  const [isServicesStarted, setIsServicesStarted] = useState<boolean>(false);
  const [agentStartTime, setAgentStartTime] = useState<CallCenterServicesValue['agentStartTime']>(undefined);
  const [connectionInfo, _setConnectionInfo] = useState<CallCenterServicesValue['connectionInfo']>();
  // states for connect
  const [agent, setAgent] = useState<ConnectAgent | undefined>(undefined);
  const [agentStatus, setAgentStatus] = useState<CallCenterServicesValue['agentStatus']>();
  const [errors, setErrors] = useSessionStorage<CallCenterServicesValue['errors']>(
    'connectErrors' as StorageKeyEnum
  );
  const [, setFrameMediaDevices] = useSessionStorage<CallCenterServicesValue['frameMediaDevices']>(
    'connectInfo' as StorageKeyEnum,
    []
  );
  const [terminateSignal, setTerminateSignal] = useState(false);
  const [restartServicesSignal, setRestartServicesSignal] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const ccpStatusChecker = useRef<NodeJS.Timeout>();

  const handleSetError = (error: ConnectErrorContext) => {
    setErrors((prev) => (
      (prev ?? []).concat({
        createdAt: new Date(),
        error
      })
    ));
  };

  const agentManualStates = useMemo((): AgentManualStates => {
    if (agent) {
      let newAgentManualStates = {} as AgentManualStates;
      agent.getAgentStates().forEach((state) => {
        const key = state.name as ConnectAgentManualState;
        newAgentManualStates = {
          ...newAgentManualStates,
          [key]: state,
        };
      });
      return newAgentManualStates;
    }
    return undefined;
  }, [agent?.getAgentStates()?.map((s) => s.name)]);

  const setAgentState: CallCenterServicesValue['setAgentState'] = useCallback((
    newState,
    cb,
    enqueueNextState = true,
  ) => {
    if (agentManualStates?.[newState]) {
      agent?.setState(
        agentManualStates[newState] as connect.AgentStateDefinition,
        cb,
        { enqueueNextState },
      );
    }
  }, [agent, agentManualStates]);

  const removeAllIFrames = () => {
    const container = getContainer();
    while (container?.firstElementChild) {
      // manually remove iframe
      container.removeChild(container.firstElementChild as HTMLIFrameElement);
    }
  };

  const handleSetConnectionInfo = (
    key: keyof ConnectionInfo | null,
    value?: unknown,
  ) => {
    if (key === null) {
      _setConnectionInfo(undefined);
      return;
    }
    _setConnectionInfo((prevInfo) => ({
      ...prevInfo,
      [key]: value,
    } as ConnectionInfo));
  };

  const setConnectionInfoTime = (
    key: keyof Pick<ConnectionInfo, 'startTime' | 'endTime'>,
  ) => handleSetConnectionInfo(key, new Date());

  const closeLoginPrompt = () => {
    window.loginPopup?.close();
    window.loginPopup = null;
  };

  const cleanStates = () => {
    setAgent(undefined);
    setAgentStartTime(undefined);
    setAgentStatus(undefined);
    handleSetConnectionInfo(null);
    setFrameMediaDevices([]);
  };

  const terminate = useDebounce((
    shouldTerminate = true,
  ) => {
    if (shouldTerminate) {
      connect.core.terminate();
    }
    removeAllIFrames();
    cleanStates();
    setTerminateSignal(false);
    setIsServicesStarted(false);
    setIsStartingServices(false);
  });

  const logout: CallCenterServicesValue['logout'] = async (
    next,
  ) => {
    const res = await logoutCCP();
    if (res) {
      terminate(false);
      next?.();
    }
  };

  const restartServices = () => {
    setTerminateSignal(true);
    setRestartServicesSignal(true);
  };

  const addCoreAndAgentSubscriptions = () => {
    // -- CORE SUBSCRIPTIONS
    // empty

    // -- AGENT SUBSCRIPTIONS
    connect.agent((_agent) => {
      subs.agentOnRefresh(_agent, async (updatedAgentStatus) => {
        setAgent(_agent);
        setAgentStatus(updatedAgentStatus);
        try {
          const frameMediaDevices = await (connect.core as ConnectCore).getFrameMediaDevices(1000);
          setFrameMediaDevices(frameMediaDevices);
        } catch (error) {
          handleSetError({ message: (error as Error).message });
        }
      });

      subs.agentOnError(_agent, (error) => {
        handleSetError(error);
      });
    });
  };

  const addContactSubscriptions = () => {
    // -- CONTACT SUBSCRIPTIONS
    connect.contact((_contact) => {
      subs.contactOnConnecting(_contact, (contactInfo) => {
        handleSetConnectionInfo('contactInfo', contactInfo);
      });
      subs.contactOnConnected(_contact, (contactInfo) => {
        setConnectionInfoTime('startTime');
        handleSetConnectionInfo('contactInfo', contactInfo);
      });
      subs.contactOnEnded(_contact, (contactInfo) => {
        handleSetConnectionInfo('contactInfo', contactInfo);
      });
      subs.contactOnACW(_contact, () => {
        setConnectionInfoTime('endTime');
      });
      subs.contactOnDestroy(_contact, () => {
        handleSetConnectionInfo(null);
      });
    });
  };

  const addEventBusSubscriptions = () => {
    // -- EVENT BUS SUBSCRIPTIONS
    const eventBus = (connect.core as ConnectCore).getEventBus();

    eventBus.subscribe('terminated', () => {
      // logout from ccp
      restartServices();
    });

    eventBus.subscribe('auth_fail', () => {
      // logout from other tab/ window
      restartServices();
    });
  };

  const initialize = useDebounce((
    _container: HTMLElement,
  ) => {
    connect.core.initCCP(
      _container,
      {
        ccpUrl,
        loginPopup: false,
        softphone: {
          allowFramedSoftphone: true,
        },
        // @ts-ignore
        storageAccess: {
          canRequest: false
        },
      },
    );
    addCoreAndAgentSubscriptions();
    addContactSubscriptions();
    addEventBusSubscriptions();
  });

  const makePhoneCall: CallCenterServicesValue['makePhoneCall'] = useCallback((
    phoneNumber,
    cb,
  ) => {
    const endpoint = connect.Endpoint.byPhoneNumber(phoneNumber);
    agent?.connect(
      endpoint,
      {
        success: () => {
          cb?.success?.();
        },
        failure: (error) => {
          cb?.failure?.(error);
        },
      },
    );
  }, [agent]);

  useUpdateEffect(() => {
    if (agent && agentManualStates) { // user logged in
      setIsLoggingIn(false);
      closeLoginPrompt();
      if (ccpStatusChecker.current) {
        clearInterval(ccpStatusChecker.current);
      }
      setAgentStartTime(new Date());
      // set agent to Online
      setAgentState('Available');
    }
  }, [agent, JSON.stringify(agentManualStates)]);

  const startServices = () => {
    const container = getContainer();
    // START SERVICES
    if (container) {
      setIsStartingServices(true);
      initialize(container);
    }
  };

  const promptLogin = () => {
    closeLoginPrompt(); // close the previous
    const newLoginPopupWindow = window.open(
      ccpUrlForLogin,
      ccpLoginPopupId,
      'width=420,height=520,position=absolute,top=0,left=0',
    );
    newLoginPopupWindow?.focus();
    window.loginPopup = newLoginPopupWindow;
    restartServices();
    // keep checking for login status
    // this is to work-around any failed initialization from ccp
    clearInterval(ccpStatusChecker?.current);
    setIsLoggingIn(true);
    let attempt = 0;
    ccpStatusChecker.current = setInterval(() => {
      const isInitialized = (connect.core as ConnectCore).initialized;
      const loginPopupOpen = !window.loginPopup?.closed;
      const cleanUp = () => {
        setIsLoggingIn(false);
        closeLoginPrompt();
        clearInterval(ccpStatusChecker.current);
      };
      if (
        !isInitialized
        && attempt === STATUS_INTERVAL_ATTEMPT
      ) {
        message.error(
          'Failed to get AWS Agent status. If you already logged in, please try to refresh the page.',
          3
        );
        cleanUp();
        return;
      }
      if (!loginPopupOpen || isInitialized) {
        cleanUp();
        return;
      }
      printInfo(`checking for login status, ${attempt}`);
      // refresh iframe
      const iframeElement = getContainer().querySelector('iframe');
      iframeElement?.setAttribute?.('src', `${iframeElement.src}`);
      attempt += 1;
    }, STATUS_INTERVAL_MS);
  };

  // handle services termination and restart
  useUpdateEffect(() => {
    if (terminateSignal) {
      terminate();
    } else if (restartServicesSignal) {
      printInfo('restarting services');
      startServices();
      setRestartServicesSignal(false);
    }
  }, [terminateSignal, restartServicesSignal, startServices]);

  const debouncedRestartServices = useDebounce(restartServices, 5000);

  useEffectOnce(() => {
    restartServices();
    handleWindowIOStreamError(debouncedRestartServices);

    return () => {
      handleWindowIOStreamError();
    };
  });

  useEventListener('unload', () => {
    closeLoginPrompt();
    clearInterval(ccpStatusChecker.current);
  });

  const value = useGetContextValue<CallCenterServicesValue>({
    isStartingServices,
    isServicesStarted,
    agentStartTime,
    connectionInfo,
    promptLogin,
    logout,
    agentStatus,
    setAgentState,
    makePhoneCall,
    errors,
    // frameMediaDevices,
    isLoggingIn
  });

  return (
    <ErrorBoundary>
      <CallCenterServices.Provider value={value}>
        {children}
      </CallCenterServices.Provider>
    </ErrorBoundary>
  );
};
