import React from 'react';
import { Button } from 'antd';

export interface VisitRescheduleComponentProps {
  onClick?: () => void;
}

export const VisitRescheduleComponent = ({
  onClick,
}: VisitRescheduleComponentProps) => (
  <Button type="primary" onClick={onClick} className="reschedule-btn">
    Reschedule
  </Button>
);
