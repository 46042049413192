import { DatePicker as DatePickerAntd } from 'antd';
import moment, { Moment } from 'moment';
import React, { FC } from 'react';
import { PickerDateProps } from 'antd/lib/date-picker/generatePicker';

export interface DatePickerProps extends PickerDateProps<Moment> {
  disabledPast?: boolean;
  disabledFuture?: boolean;
  includeToday?: boolean;
}

export const DatePicker: FC<DatePickerProps> = ({
  disabledPast,
  disabledFuture,
  includeToday,
  ...props
}) => {
  const disabledDate: DatePickerProps['disabledDate'] = (current) => {
    if (current) {
      if (disabledPast) return current < moment().endOf('day').add(includeToday ? 0 : -1, 'day');
      if (disabledFuture) return current > moment().endOf('day').add(includeToday ? -1 : 0, 'day');
    }
    return false;
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <DatePickerAntd data-testid="date-picker" {...props} disabledDate={disabledDate} />
  );
};
