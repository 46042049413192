import { Dayjs } from 'dayjs';
import { useCallback, useState } from 'react';
import { map, some } from 'lodash';
import { DatePicker, DatePickerProps } from 'antd';
import classNames from 'classnames';
import { Moment } from 'moment/moment';
import { useTableColumns } from '../../../../../hooks/table/useTableColumns/useTableColumns';
import { createLLMLabResultColumns, MISSING_ERROR_CLSNAME } from '../../createLLMLabResultColumns';
import { MMDDYY } from '../../../../../constants/timeFormat';
import { MissingErrorComponent } from '../../../../../uiComponent/MissingErrorComponent/MissingErrorComponent';
import { LLMLabResultTableComponent, LLMLabResultTableComponentProps } from '../LLMLabResultTableComponent/LLMLabResultTableComponent';
import { LLMLabResultFormComponentProps, LLMLabResultTableItem } from '../../type';
import TimezoneService from '../../../../../helpers/timezone/timezoneService';
import { useLLMLabResultRecordCheck } from '../../hook/useLLMLabResultCheck';
import { LLMLabResultSameDateWarning } from '../LLMLabResultSameDateWarning/LLMLabResultSameDateWarning';
import { useDeepCompareMemo } from '../../../../../hooks/useDeepCompareEffect';
import { useLabResultHelper } from '../../../../transcribing/hook/useLabResultHelper';
import { TranscribedLabResult } from '../../../../../uc-api-sdk';

export interface LLMLabResultOtherFormComponentProps extends LLMLabResultFormComponentProps { }

export const LLMLabResultOtherFormComponent = ({
  templateId,
  templateName,
  dataSource,
  onChange,
  disabled,
}: LLMLabResultOtherFormComponentProps) => {
  const [
    updatedDateCollected,
    setUpdatedDateCollected
  ] = useState<Dayjs | undefined>(
    dataSource?.[0]?.dateCollected
      ? TimezoneService.calcDateTimeDayjs(dataSource?.[0]?.dateCollected)
      : undefined
  );
  const [
    lastValues,
    setLastValues,
  ] = useState<LLMLabResultTableItem[]>([...dataSource || []]);
  const {
    postProcessInfo,
    checkDuplicateConflict,
  } = useLLMLabResultRecordCheck();
  const {
    getCollectedDate
  } = useLabResultHelper();

  const hasRecordOnDateCollected = useDeepCompareMemo(() => {
    const postProcessProcessedResult = (
      postProcessInfo.data?.data?.processedResult || []
    ) as TranscribedLabResult[];
    const results = postProcessProcessedResult?.[0]?.results || dataSource;
    return some(results, ({ duplicate, conflict }) => duplicate || conflict);
  }, [
    dataSource,
    postProcessInfo.data?.data?.processedResult,
  ]);

  const showMissingDate = !disabled && !updatedDateCollected;

  const columns = useTableColumns(
    createLLMLabResultColumns(),
    ['name', 'value'],
    [disabled]
  );

  const parseData = (
    data: LLMLabResultTableItem[],
    dateCollected?: Dayjs,
  ) => (
    map(
      data,
      (item) => ({
        ...item,
        dateCollected,
      })
    )
  );

  const handleOnChange = (
    changed: Partial<LLMLabResultTableItem>[],
    values: LLMLabResultTableItem[]
  ) => {
    onChange?.(parseData(values, updatedDateCollected));
    setLastValues(values);
  };

  const handleChangeDateCollected: DatePickerProps['onChange'] = (date) => {
    const newData = parseData(lastValues, date as unknown as Dayjs);
    onChange?.(newData);
    setLastValues(newData);
    setUpdatedDateCollected(date as unknown as Dayjs ?? undefined);
    if (!date) {
      return;
    }
    const processedResults = [{
      templateId,
      templateName,
      ...getCollectedDate(date as unknown as Dayjs),
      results: newData,
    }];
    checkDuplicateConflict(processedResults);
  };

  const handleGetUpdatedData = useCallback<NonNullable<LLMLabResultTableComponentProps['getUpdatedData']>>((
    data,
    tableDataIndex,
  ) => {
    // remove dateCollected from data
    if (!updatedDateCollected) {
      return {
        ...data,
        dateCollected: undefined,
      };
    }
    // save new processed data to form based on new date collected
    const postProcessProcessedResult = (
      postProcessInfo.data?.data?.processedResult || []
    ) as TranscribedLabResult[];
    const newParsedData = postProcessProcessedResult?.[0]?.results?.[tableDataIndex];
    if (!newParsedData) {
      return data;
    }
    return {
      ...data,
      dateCollected: updatedDateCollected,
      conflict: newParsedData.conflict,
      duplicate: newParsedData.duplicate,
      existingValue: newParsedData.duplicate ? newParsedData.value : newParsedData.existingValue,
    };
  }, [
    updatedDateCollected,
    postProcessInfo.data?.data?.processedResult
  ]);

  return (
    <div className="flex fd-c gap1">
      <div className="flex ai-bl w100">
        <div className="w140 mb10">
          <DatePicker
            value={updatedDateCollected as unknown as Moment}
            onChange={handleChangeDateCollected}
            format={MMDDYY}
            disabled={disabled}
            className={classNames({
              'error-border': showMissingDate
            })}
          />
          {
            showMissingDate
            && (<MissingErrorComponent className={MISSING_ERROR_CLSNAME} />)
          }
        </div>
        {
          hasRecordOnDateCollected
          && (
            <div className="flex f-w">
              <LLMLabResultSameDateWarning />
            </div>
          )
        }
      </div>
      <LLMLabResultTableComponent
        className="llm-lab-result-others-table"
        columns={columns}
        dataSource={dataSource}
        getUpdatedData={handleGetUpdatedData}
        onValuesChange={handleOnChange}
        disabled={disabled}
      />
    </div>
  );
};
