import { message } from 'antd';
import { APIResponse } from '../../uc-api-sdk';

type ErrorWithMessage = {
  message: string
}
type MsgType = 'error' | 'success';
type MsgInfo = Record<MsgType, string | undefined>;
type Msg = string | MsgInfo;
export type Response<T> = APIResponse<T> | null | undefined;
export interface Options<T> extends MsgInfo {
  onSuccess?: (res: Response<T>) => void;
  onError?: (v: unknown) => void;
}

export class ApiRequestHelper {
  private static DEFAULT_MSG = 'Something went wrong with the request!';

  private static getMessage(type: MsgType, msg?: Msg) {
    let msgStr = '';
    if (typeof msg === 'string') {
      msgStr = msg;
    } else {
      msgStr = msg?.[type] || '';
    }
    return msgStr;
  }

  private static showSuccessMessage(msg?: Msg) {
    if (typeof msg === 'string') return;
    const successMsg = this.getMessage('success', msg);
    if (successMsg) {
      message.success(successMsg);
    }
  }

  public static throwIfError<T>(res: Response<T>, msg?: Msg) {
    if (res?.code && (res.code < 200 || res.code >= 300)) {
      throw new Error(this.getMessage('error', msg) || res.msg || this.DEFAULT_MSG);
    }
    return undefined;
  }

  public static async tryCatch<T>(
    reqPromise: Promise<Response<T>>,
    options?: Options<T> | string,
  ) {
    try {
      const res = await reqPromise;
      this.throwIfError(res, options);
      this.showSuccessMessage(options);
      if (typeof options !== 'string') options?.onSuccess?.(res);
      return res;
    } catch (err) {
      ApiRequestHelper.showError(err);
      if (typeof options !== 'string') options?.onError?.(err);
      return undefined;
    }
  }

  private static isErrorWithMessage(error: unknown): error is ErrorWithMessage {
    return (
      typeof error === 'object'
      && error !== null
      && 'message' in error
      && typeof (error as Record<string, unknown>).message === 'string'
    );
  }

  public static showError(err: unknown) {
    if (this.isErrorWithMessage(err)) {
      message.error(err.message);
    } else {
      console.error(err);
    }
  }
}
