import { TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ReactNode } from 'react';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { TableCacheNameEnum } from '../../../../hooks/useTableChange/useTableChange';
import { Patient, PatientListTypeEnum, PatientResult } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { myAssignedFilter, usePatientsTableSearch } from '../../hook/usePatientsTableSearch/usePatientsTableSearch';

export type ExportDataFunction = (
  column: ColumnsType<PatientResult> | undefined,
  isProvider: boolean,
  totalSize: number,
) => void;

export interface PatientsTableChildrenProps {
  dataSource: TableProps<Patient>['dataSource'];
  onChange: TableProps<Patient>['onChange'];
  pagination: TableProps<Patient>['pagination'];
  totalSize: number;
  isLoading: boolean;
  refetch: ReturnType<typeof usePatientsTableSearch>['patients']['refetch'];
  filters: ReturnType<typeof usePatientsTableSearch>['tableChange']['filter'];
  sorters: ReturnType<typeof usePatientsTableSearch>['tableChange']['sort'];
  exportData: ExportDataFunction;
}

export interface PatientsTableContainerProps {
  clinicId?: string;
  type: PatientListTypeEnum;
  watchlist?: boolean;
  myAssigned?: boolean;
  name?: TableCacheNameEnum | string;
  needToFilter?: boolean;
  doctorId?: string;
  children: (props: PatientsTableChildrenProps) => ReactNode;
  isPagination?: boolean;
}

export const PatientsTableContainer = ({
  clinicId,
  type,
  watchlist,
  myAssigned = false,
  name,
  needToFilter = true,
  doctorId,
  children,
  isPagination = true,
}: PatientsTableContainerProps) => {
  const { patients, tableChange, exportData } = usePatientsTableSearch(
    type,
    name,
    isPagination,
    {
      needToFilter,
      patient: {
        filterWatchedPatient: watchlist,
        ...(clinicId && {
          clinicIdsIn: {
            in: [clinicId],
          },
        }),
        profile: {
          ...(!!doctorId && { doctorId })
        }
      },
      ...(myAssigned && myAssignedFilter()),
    }
  );

  useUpdateListener('PATIENT_CREATE', patients.refetch);
  useUpdateListener('GET_WATCH_LIST', patients.refetch);

  return (
    <FetchComponent
      info={patients}
      // table can be overflow, FetchComponent overlay does not support
      // use Antd Table loading
      showLoadingOverlay={false}
      alwaysShowChildren
      showEmptyOverlay={false}
    >
      {(data, info) => {
        const { content, totalSize } = info.data?.data || {};
        return (
          children({
            dataSource: content ?? [],
            onChange: tableChange.handleTableChange,
            pagination: info.pagination,
            totalSize: totalSize ?? 0,
            isLoading: info.isLoading,
            refetch: patients.refetch,
            filters: tableChange.filter,
            sorters: tableChange.sort,
            exportData,
          })
        );
      }}
    </FetchComponent>
  );
};
