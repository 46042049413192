import {
  ReactNode,
  createContext,
  useContext,
  useState,
} from 'react';
import { omit } from 'lodash';
import { useEffectOnce } from 'usehooks-ts';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import useDebounce from '../../hooks/useDebounce/useDebounce';

type AnonymousFn = (...args: unknown[]) => unknown;

export interface EventContextValue {
  events: Record<string, Event>;
  eventName: string;
  triggerEvent: (eventName?: string) => void;
  subscribeToEvent: (cb: AnonymousFn, eventName?: string) => AnonymousFn;
}

const EventContext = createContext<
  EventContextValue | undefined
>(undefined);

export const useEventContext = (eventName?: string) => {
  const context = useContext(EventContext);
  if (!context) return {} as EventContextValue;
  if (!eventName) return context;
  return {
    ...context,
    triggerEvent: () => context.triggerEvent(eventName),
    subscribeToEvent: (
      cb: AnonymousFn,
    ) => context.subscribeToEvent(cb, eventName),
  } as EventContextValue;
};

export interface EventContextProviderProps {
  eventName: string;
  children: ReactNode;
}

export const EventContextProvider = ({
  eventName,
  children,
}: EventContextProviderProps) => {
  const { events: nestedEvents } = useEventContext();
  const [events, setEvents] = useState<Record<string, Event>>(nestedEvents || {});

  useEffectOnce(() => {
    if (eventName) {
      setEvents((prev) => ({
        ...prev,
        [eventName]: new CustomEvent(eventName, { bubbles: false }),
      }));

      return () => {
        setEvents((prev) => omit(prev, eventName));
      };
    }
    return () => 0;
  });

  const debouncedSubscribeEvent = useDebounce((
    cb: AnonymousFn,
    eventName = '',
  ) => {
    window.addEventListener(eventName, cb);
  }, 200);

  const subscribeToEvent = (
    cb: AnonymousFn,
    eventName = '',
  ) => {
    debouncedSubscribeEvent(cb, eventName);
    return () => window.removeEventListener(eventName, cb);
  };

  const triggerEvent = (eventName = '') => {
    if (events[eventName]) window.dispatchEvent(events[eventName]);
  };

  const contextValue = useGetContextValue<EventContextValue>({
    events,
    eventName,
    triggerEvent,
    subscribeToEvent,
  });

  return (
    <EventContext.Provider value={contextValue}>
      {children}
    </EventContext.Provider>
  );
};
