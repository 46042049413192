import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { WorklistSocialSupportFormContainer, WorklistSocialSupportFormContainerProps } from '../../container/WorklistSocialSupportFormContainer/WorklistSocialSupportFormContainer';

export interface WorklistSocialSupportComponentProps
  extends WorklistSocialSupportFormContainerProps {}

export const WorklistSocialSupportComponent = ({
  patientId,
  patientInfoService,
  onSubmit,
  onError,
  onValuesChange,
}: WorklistSocialSupportComponentProps) => (
  <PatientCommonCardComponent
    title=""
    updateInfo={patientInfoService.updateInfo}
    content={(<div />)}
    formContent={(
      <>
        <TextComponent
          className="mb10"
          bold
        >
          Social Support
        </TextComponent>
        <WorklistSocialSupportFormContainer
          patientId={patientId}
          patientInfoService={patientInfoService}
          onSubmit={onSubmit}
          onError={onError}
          onValuesChange={onValuesChange}
        />
      </>
    )}
    showEdit={false}
    isEditing
  />
);
