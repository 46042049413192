import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { useInsuranceUpdateHandler } from '../../../Insurance/hook/insuranceUpdateHandler';
import { PatientInsuranceComponent, PatientInsuranceComponentProps } from '../../component/PatientInsuranceComponent/PatientInsuranceComponent';
import { InsuranceStatus } from '../../constants';

export interface PatientInsuranceContainerProps {
  onSubmit?: () => void;
}

export const PatientInsuranceContainer = ({
  onSubmit,
}: PatientInsuranceContainerProps) => {
  const { info } = usePatientContext();
  const {
    id = '',
    patientInsurance,
    refetch,
    isLoadingObj: {
      patientInsuranceLoading = false,
    } = {},
    patientInfo,
  } = info || {};
  const {
    updateInsuranceInfo,
    updateInsurance,
    updateInsuranceNote,
  } = useInsuranceUpdateHandler(id);

  const debouncedRefetch = useDebounce(() => refetch?.(['patientInsuranceRefetch']), 500, [refetch]);
  useUpdateListener('INSURANCE_UPDATED', debouncedRefetch);
  useUpdateListener('INSURANCE_ELIGIBILITY_UPDATED', debouncedRefetch);

  const handleSubmit: PatientInsuranceComponentProps['onSubmit'] = async (
    values,
    successMsg,
  ) => {
    updateInsurance(
      values,
      {
        success: successMsg || InsuranceStatus.success,
        error: InsuranceStatus.error,
        onSuccess: () => {
          onSubmit?.();
          refetch?.(['patientInsuranceRefetch']);
        },
        onError: () => {
          refetch?.(['patientInsuranceRefetch']);
        }
      },
    );
  };

  const handleSaveNote = (note: string) => {
    updateInsuranceNote(
      note,
      {
        success: 'Note updated successfully',
        error: 'Failed to update note',
        onSuccess: () => {
          refetch?.(['patientInsuranceRefetch']);
        },
      }
    );
  };

  if (patientInsuranceLoading || patientInsurance === undefined) {
    return <LoadingOverlayComponent />;
  }

  return (
    <PatientInsuranceComponent
      patient={patientInfo || {}}
      insurance={patientInsurance || {}}
      isLoading={updateInsuranceInfo.isLoading}
      onSubmit={handleSubmit}
      onSaveNote={handleSaveNote}
    />
  );
};
