import { CallCenterContextValue } from '../../../../contexts/CallCenterContext/CallCenterContext';
import { CallCenterMediaStatus, CallCenterStatusName } from '../../../../contexts/CallCenterContext/types';
import { CallCenterStatusDropdownItem, callCenterStatusDropdownItems } from './constants';

interface GetStatusTooltipTitleProps {
  status: CallCenterStatusName,
  mediaStatus?: CallCenterContextValue['mediaStatus'],
}

export const getCallCenterStatusMenuItems = (
  isLoggedIn: boolean,
): CallCenterStatusDropdownItem[] => {
  if (isLoggedIn) {
    return callCenterStatusDropdownItems;
  }
  return callCenterStatusDropdownItems.filter((i) => i.key === 'open-ccp');
};

const isIOAvailable = (mediaStatus?: CallCenterMediaStatus) => (
  mediaStatus?.audioinput?.default?.[0] && mediaStatus?.audiooutput?.default?.[0]
);

export const getStatusTooltipTitle = (props: GetStatusTooltipTitleProps): string => (
  props.status
    ? `\
    Status: ${props.status}
    I/O status: ${isIOAvailable(props.mediaStatus) ? '✅' : '❌'}
    `
    : ''
);
