import { Button, Space } from 'antd';
import { Icons } from '../../../../icons/Icons';
import './FeedbackButtonComponent.scss';

const feedbackLink = 'https://docs.google.com/forms/d/e/1FAIpQLSfSJhe7ujX_8kemQppY6pE8-NEuxyvux3QpmKJuRdL7igEx2g/viewform?usp=sf_link';

export interface FeedbackButtonComponentProps {

}

export const FeedbackButtonComponent = () => (
  <a
    href={feedbackLink}
    target="_blank"
    rel="noopener noreferrer"
    className="feedback-btn-component"
  >
    <Button type="text">
      <Space>
        <Icons.FeedbackIcon />
        Send feedback
      </Space>
    </Button>
  </a>
);
