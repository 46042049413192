import { useTableChange } from '../../../../../hooks/useTableChange/useTableChange';
import { CarePlan, Sort$Direction, usePatientSearchCarePlan } from '../../../../../uc-api-sdk';
import { FetchComponent } from '../../../../../uiComponent/FetchComponent/FetchComponent';
import { PrintCarePlanTableComponent } from '../../component/PrintCarePlanTableComponent/PrintCarePlanTableComponent';
import { sorterHelper } from '../../../../../hooks/useTableChange/sorterHelper';

export interface PrintCarePlanHistoryContainerProps {
  patientId: string;
}

export const PrintCarePlanHistoryContainer = ({
  patientId,
}: PrintCarePlanHistoryContainerProps) => {
  const tableChange = useTableChange<CarePlan, unknown>({
    sort: [{
      direction: Sort$Direction.DESC,
      property: 'updatedAt',
    }],
    sortProcessor: (sorter) => sorterHelper(sorter),
  });

  const carePlanInfo = usePatientSearchCarePlan({
    params: {
      request: {
        filter: { memberId: patientId },
        pageInfo: tableChange.pageInfo,
      },
    },
    options: { sendOnMount: true }
  });

  return (
    <FetchComponent
      info={carePlanInfo}
      alwaysShowChildren
    >
      {
        (info) => {
          const carePlanData = info?.rawValue?.data?.content || [] as CarePlan[];

          return (
            <PrintCarePlanTableComponent
              dataSource={carePlanData}
              onChange={tableChange.handleTableChange}
              pagination={{
                current: info?.rawValue?.data?.page || 1,
                pageSize: info?.rawValue?.data?.size || 10,
                total: info?.rawValue?.data?.totalSize || 0,
              }}
            />
          );
        }
      }
    </FetchComponent>
  );
};
