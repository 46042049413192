import { Card } from 'antd';
import { ReactNode } from 'react';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import {
  LimitedClinic, Transcribe, TranscribeTypeEnum
} from '../../../../uc-api-sdk';
import { CardHeaderComponent } from '../../../../uiComponent/CardHeaderComponent/CardHeaderComponent';
import { CardHeaderExtraComponent } from '../../../../uiComponent/CardHeaderExtraComponent/CardHeaderExtraComponent';
import { ClinicNameDisplayComponent } from '../../../../uiComponent/DisplayComponent/ClinicDisplayComponent/ClinicNameDisplayComponent';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { SubTitle } from '../../../../uiComponent/Title/SubTitle';
import { Title } from '../../../../uiComponent/Title/Title';
import { TranscribeDueDateComponent } from '../TranscribeDisplayComponent/TranscribeDueDateComponent';
import { TranscribeDrawerNextPatientButtonComponent } from '../TranscribeNextPatientButtonComponent/TranscribeDrawerNextPatientButtonComponent';
import { TranscribeDrawerTitleComponent } from '../TranscribeTitleComponent/TranscribeDrawerTitleComponent';
import { TranscribingPatientInfoCardComponent } from '../TranscribingPatientInfoCardComponent/TranscribingPatientInfoCardComponent';

import './TranscribeDrawerLayoutComponent.scss';

export interface TranscribeDrawerLayoutComponentProps {
  transcribeType: TranscribeTypeEnum;
  transcribeData: Transcribe;
  children: ReactNode;
  showNextPatientButton?: boolean;
  handleGoToNextTranscribe: () => Promise<void>;
  isLoadingNextTranscribe?: boolean;
  isNextPatientButtonDisabled?: boolean;
}

export const TranscribeDrawerLayoutComponent = ({
  transcribeType,
  transcribeData,
  children,
  showNextPatientButton,
  handleGoToNextTranscribe,
  isLoadingNextTranscribe,
  isNextPatientButtonDisabled,
}: TranscribeDrawerLayoutComponentProps) => {
  const {
    info: {
      patientInfoService,
      enrolledProgramService,
      billableTimeService,
      refetch,
    } = {},
  } = usePatientContext();

  const isLoading = !patientInfoService || !enrolledProgramService || !billableTimeService;

  const transcribeVisitType = transcribeData.visitType || undefined;
  const clinic = (patientInfoService?.patient?.clinic || {}) as LimitedClinic;

  return (
    <LoadingOverlayComponent
      isLoading={isLoading}
      showSkeleton
    >
      <Card className="transcribe-drawer-card" bordered={false}>
        <CardHeaderComponent>
          <div>
            <Title>
              <TranscribeDrawerTitleComponent
                transcribeType={transcribeType}
                transcribeVisitType={transcribeVisitType}
              />
            </Title>
            <SubTitle className="flex gap3 ai-c">
              <span className="inline-flex">
                Clinic:
                <span>
                  <ClinicNameDisplayComponent
                    clinic={clinic}
                    nickname={clinic.nickName}
                    showSequenceNum
                  />
                </span>
              </span>
              <TranscribeDueDateComponent
                dueDate={transcribeData?.dueDate}
              />
            </SubTitle>
          </div>
          {
            showNextPatientButton
            && (
              <CardHeaderExtraComponent>
                <TranscribeDrawerNextPatientButtonComponent
                  isLoading={isLoadingNextTranscribe}
                  onClick={() => handleGoToNextTranscribe()}
                  disabled={isNextPatientButtonDisabled}
                />
              </CardHeaderExtraComponent>
            )
          }
        </CardHeaderComponent>
        <TranscribingPatientInfoCardComponent
          data={{
            patientInfoService,
            billableTimeService,
            enrolledProgramService,
            refetch,
          }}
        />
        {children}
      </Card>
    </LoadingOverlayComponent>
  );
};
