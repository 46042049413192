import {
  compact,
  Dictionary,
  filter,
  intersection,
  isEmpty,
  join,
  map,
  uniq
} from 'lodash';
import moment from 'moment';
import { USA_DATE } from '../../constants/timeFormat';
import { careRelatedRoles } from '../../lib/constants/roles';
import EmployeeProfilePhotoService from '../../services/EmployeeProfilePhotoService';
import { ROLE_NAME_MAP } from '../../types/roles';
import { Employee, EmployeeProfile, RoleAssignment } from '../../types/user';
import { EmployeeStatus, RoleOrgIds, RoleTypeEnum } from '../../uc-api-sdk';
import { _getFullName, _getFullNameWithCredential } from './employee';

export interface EmployeeInformation {
  employee: Employee,
  roleAssignments?: RoleAssignment[],
  refetchEmployee?: () => void,
  refetchRoleAssignments?: () => void,
}

class EmployeeInfo {
  protected employee: Employee;

  protected roleAssignments: RoleAssignment[];

  protected refetchEmployee: EmployeeInformation['refetchEmployee'] | undefined;

  protected refetchRoleAssignments: EmployeeInformation['refetchRoleAssignments'] | undefined;

  static getFullNameWithTitle<T extends EmployeeProfile>(employeeProfile?: T) {
    const { title } = employeeProfile || {};
    const result = _getFullName({ employeeProfile });
    if (!result) return '';
    if (title) return `${result} (${title})`;
    return result;
  }

  constructor(info: EmployeeInformation) {
    this.employee = info.employee;
    this.roleAssignments = info.roleAssignments || [];
    this.refetchEmployee = info.refetchEmployee;
    this.refetchRoleAssignments = info.refetchRoleAssignments;
  }

  get employeeData() {
    return this.employee;
  }

  get roles() {
    return this.roleAssignments;
  }

  get roleOrgIds() {
    const rolesDict: Dictionary<string[]> = {};

    this.roles.forEach((role) => {
      if (!rolesDict[role.roleType]) {
        rolesDict[role.roleType] = [role.organizationId];
      }
      rolesDict[role.roleType].push(role.organizationId);
    });

    return Object.keys(rolesDict).map((roleType) => ({
      roleType: roleType as RoleTypeEnum,
      orgIds: rolesDict[roleType]
    } as RoleOrgIds));
  }

  get allRoleTypes() {
    return uniq(this.roles.map((r) => r.roleType));
  }

  get allRoleNames() {
    return this.allRoleTypes.map((roleType) => ROLE_NAME_MAP[roleType]);
  }

  get id() {
    return this.employee.id || 'invalid';
  }

  get profile() {
    return this.employee?.employeeProfile;
  }

  get fullName() {
    return _getFullName(this.employee);
  }

  get fullNameWithCredential() {
    return _getFullNameWithCredential(this.employee);
  }

  get fullNameWithTitle() {
    return EmployeeInfo.getFullNameWithTitle(this.profile);
  }

  get fullNameWithAllRoles() {
    const allRoleNames = this.allRoleNames.join('/');
    const allRoleNamesDisplay = allRoleNames ? `, ${allRoleNames}` : '';
    return `${_getFullName(this.employee)}${allRoleNamesDisplay}`;
  }

  get email() {
    return this.employee.email;
  }

  get loginId() {
    return this.employee.loginId;
  }

  get accountStatus() {
    return this.employee.status as EmployeeStatus;
  }

  get languages() {
    return this.profile?.language;
  }

  get createdDate() {
    const { createdAt } = this.employee;
    return createdAt ? moment(createdAt).format(USA_DATE) : '-- --';
  }

  get dataAnalytics() {
    return this.employee.dataAnalytics;
  }

  get avatarFileKey() {
    return this.profile?.avatar?.fileKey || undefined;
  }

  get avatar() {
    const isDeactivated = this.accountStatus === EmployeeStatus.DEACTIVATED;
    if (isDeactivated) return null; // null to disable profile photo

    const privateUrl = this.profile?.avatar?.url;
    if (privateUrl) return privateUrl;
    return EmployeeProfilePhotoService.getEmployeeProfilePhotoPublicUrl(
      this.avatarFileKey,
    );
  }

  get duration() {
    return this.employee?.duration;
  }

  get lastActivity() {
    return this.employee?.lastLoginTime || undefined;
  }

  get credentials() {
    return this.profile?.credentials;
  }

  get defaultClinicId() {
    return this.employee?.defaultClinicId || '';
  }

  get userType() {
    return this.employee?.userType;
  }

  hasRole(role: RoleTypeEnum) {
    return this.allRoleTypes.includes(role);
  }

  static getRoleNames(roleTypes?: RoleTypeEnum[]) {
    const roleNames = map(
      roleTypes,
      (roleType) => (roleType ? ROLE_NAME_MAP[roleType] : undefined)
    );
    return compact(roleNames);
  }

  getRoleTypesExceptFor(roleTypes: RoleTypeEnum[]) {
    const filteredRoles = filter(
      this.allRoleTypes,
      (roleType) => !roleTypes.includes(roleType)
    );
    return filteredRoles;
  }

  getRoleTypesByCareGroupId(
    careGroupId?: string | null,
    getAllIfNotExists = true,
  ): RoleTypeEnum[] {
    const allRolesInCareGroup = map(this.roles, (r) => {
      if (careGroupId && careGroupId === r.organizationId && r.roleType) {
        return r.roleType;
      }
      return null;
    }).filter((v) => !!v) as RoleTypeEnum[];
    if (isEmpty(allRolesInCareGroup) && getAllIfNotExists) {
      this.getRoleTypesExceptFor([
        RoleTypeEnum.ADMIN,
        RoleTypeEnum.SUPER_ADMIN,
      ]).forEach((roleType) => {
        allRolesInCareGroup.push(roleType);
      });
    }
    return uniq(allRolesInCareGroup);
  }

  getFullNameWithRolesByCareGroupId(
    careGroupId?: string | null,
    options?: {
      showOnlyCareRelatedRoles?: boolean,
      showAllWhenRoleNotExists?: boolean,
      delimiter?: string,
    },
  ) {
    const {
      showOnlyCareRelatedRoles = true,
      showAllWhenRoleNotExists = true,
      delimiter = '/',
    } = options || {};
    let roleTypesByCareGroupId = this.getRoleTypesByCareGroupId(
      careGroupId,
      !!showAllWhenRoleNotExists
    );
    if (showOnlyCareRelatedRoles) {
      roleTypesByCareGroupId = intersection(careRelatedRoles, roleTypesByCareGroupId);
    }
    const roleNames = EmployeeInfo.getRoleNames(roleTypesByCareGroupId);
    if (roleNames && roleNames.length > 0) {
      return `${this.fullName}, ${join(roleNames, delimiter)}`;
    }
    return this.fullName;
  }

  refetchEmployeeData = () => {
    this.refetchEmployee?.();
  };

  refetchRoles = () => {
    this.refetchRoleAssignments?.();
  };
}

export default EmployeeInfo;
