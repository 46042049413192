import TimezoneService from '../../../helpers/timezone/timezoneService';
import { NonNullUndefined } from '../../../types/common';
import {
  BloodOxygen,
  Measurement,
  PerfusionIndex,
  PoSeverityEnum,
} from '../../../uc-api-sdk';
import { MeasurementType } from '../type/MeasurementTypes';
import { MeasurementService } from './MeasurementService';

export class BOMeasurementService extends MeasurementService {
  constructor(measurement: Measurement) {
    super(measurement as MeasurementType);
  }

  get beforeMeal() {
    return this.value.beforeMeal;
  }

  get mealType() {
    return this.value.mealType;
  }

  get isCopd() {
    return this.value.isCopd ?? false;
  }

  get poSeverity() {
    return this.value.poSeverity ?? PoSeverityEnum.NORMAL;
  }

  get timeOfDay() {
    return TimezoneService.getTimeOfDay(this.value.date, this.value.timezone);
  }

  get bloodOxygen() {
    return { ...this.value.blood_oxygen as NonNullUndefined<BloodOxygen> };
  }

  get perfusionIndex() {
    return { ...this.value.perfusion_index as NonNullUndefined<PerfusionIndex> };
  }

  get chartSeverity() {
    return this.value.poSeverity ?? PoSeverityEnum.NORMAL;
  }

  get severityColor() {
    switch (this.poSeverity) {
      case PoSeverityEnum.CRITICAL_LOW: return '#f0666b';
      case PoSeverityEnum.LOW: return '#fdb543';
      case PoSeverityEnum.NORMAL: return '#37cc8d';
      default: return '#37cc8d';
    }
  }
}
