import { Input } from 'antd';
import { useInsuranceForm } from '../../../../../hooks/formHook/useInsuranceForm';
import { InsurancePolicyNumberComponent } from '../../InsuranceCardComponent/InsuranceCardLayoutComponent';
import FormItem from '../../../../../uiComponent/FormItem/FormItem';

export interface InsurancePolicyNumberFormItemComponentProps {
  fieldName?: number;
  span?: number;
  required?: boolean;
  shouldValidate?: boolean;
}

export const InsurancePolicyNumberFormItemComponent = ({
  fieldName,
  span,
  required,
  shouldValidate,
}: InsurancePolicyNumberFormItemComponentProps) => {
  const insuranceForm = useInsuranceForm();
  return (
    <InsurancePolicyNumberComponent
      span={span}
      required={required}
    >
      <FormItem
        name={insuranceForm.makeListNamePath('policyNumber', fieldName)}
        rules={insuranceForm.getPolicyNumberRules(required, shouldValidate)}
      >
        <Input />
      </FormItem>
    </InsurancePolicyNumberComponent>
  );
};
