import React from 'react';
import './AlertNotesComponent.scss';
import { map } from 'lodash';
import { Note } from '../../uc-api-sdk';
import { ListComponent } from '../ListComponent/ListComponent';

export interface AlertNotesComponentProps {
  notes?: Note[]
}

export const AlertNotesComponent = ({
  notes = [],
}: AlertNotesComponentProps) => {
  const notesList = (
    map(notes, (note) => (
      <span>
        {note?.note}
      </span>
    ))
  );
  return (
    <div className="alert-notes-component">
      <ListComponent
        value={notesList}
        renderItem={(v) => v}
        splitter=","
      />
    </div>
  );
};
