import React, { FC } from 'react';
import { useBPResultToSummaryTable } from '../../hook/useBPResultToSummaryTable/useBPResultToSummaryTable';
import { BPSummaryTableComponent } from './BPSummaryTableComponent';
import { BpStat } from '../../../../uc-api-sdk';

export interface BPSummaryTableDataProcessorComponentProps {
  data: BpStat;
}

export const BPSummaryTableDataProcessorComponent: FC<
  BPSummaryTableDataProcessorComponentProps
> = ({
  data,
}) => {
  const processedData = useBPResultToSummaryTable(data);
  const totalCount = data.all?.count || 0;

  return (
    <BPSummaryTableComponent data={processedData} totalCount={totalCount} />
  );
};
