import { Modal, ModalProps } from 'antd';
import { PrintCarePlanHistoryContainer } from '../../container/PrintCarePlanHistoryContainer/PrintCarePlanHistoryContainer';

export interface PrintCarePlanModalComponentProps extends ModalProps {
  patientId: string;
}

export const PrintCarePlanModalComponent = ({
  patientId,
  open,
  onCancel,
}: PrintCarePlanModalComponentProps) => (
  <Modal
    open={open}
    onCancel={onCancel}
    width={1200}
    destroyOnClose
    title="Care Plan Version History"
    footer={null}
  >
    <PrintCarePlanHistoryContainer
      patientId={patientId}
    />
  </Modal>
);
