import { map } from 'lodash';
import { useCallback } from 'react';
import { ApiRequestHelper } from '../../../helpers/ApiRequest';
import { usePatientDeletePatientDevice } from '../../../uc-api-sdk';

export const useUnassignDevices = () => {
  const deviceUnassignInfo = usePatientDeletePatientDevice({});

  const unassignDevices = useCallback((
    patientId: string,
    deviceIds: string[]
  ) => (
    Promise.all(map(deviceIds, (deviceId) => {
      if (!deviceId) return true;
      return ApiRequestHelper.tryCatch(
        deviceUnassignInfo.send({
          params: {
            memberId: patientId,
            deviceId,
          }
        }),
        `Failed to unassign ${deviceId}`,
      );
    }))
  ), []);

  return {
    unassignDevices,
    deviceUnassignInfo,
  };
};
