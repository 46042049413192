import { useLoggedInUserFromContext } from '../../contexts/loggedInUserContext';
import useDebounce from '../useDebounce/useDebounce';

export const makeUnsavedNotesKey = (
  spaceName: string, // place
  identifier: string
) => `${spaceName}_${identifier}`;

export const useUnsavedNotes = (
  key: string,
) => {
  const {
    userId
  } = useLoggedInUserFromContext();

  const parseKeyWithUserId = (key: string) => (
    `${userId}_${key}`
  );

  const getUnsavedNotes = () => {
    const parsedKey = parseKeyWithUserId(key);
    const unsavedNotes = sessionStorage.getItem(parsedKey);
    return unsavedNotes ? JSON.parse(unsavedNotes) : undefined;
  };

  const setUnsavedNotes = useDebounce((notes?: string) => {
    const parsedKey = parseKeyWithUserId(key);
    sessionStorage.setItem(parsedKey, JSON.stringify(notes));
  }, 200, [key]);

  const finishUnsavedNotes = () => {
    const parsedKey = parseKeyWithUserId(key);
    sessionStorage.removeItem(parsedKey);
  };

  return {
    getUnsavedNotes,
    setUnsavedNotes,
    finishUnsavedNotes,
  };
};
