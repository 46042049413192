import { UserTypeEnum } from '../../../uc-api-sdk';
import {
  ClinicalOrgStatusEnum, DeprecatedOrganizationTypeEnum, EntityType
} from '../types';

export const ENTITY_TEXT = {
  [EntityType.BillingDept]: {
    text: 'Billing Dept',
  },
  [EntityType.CallCenterDept]: {
    text: 'Call Center Dept',
  },
  [EntityType.CareCenter]: {
    text: 'Care Center',
  },
  [EntityType.CareDept]: {
    text: 'Care Dept',
  },
  [EntityType.CareGroup]: {
    text: 'Care Group',
  },
};

export const ENTITY_CHILD = {
  [EntityType.BillingDept]: undefined,
  [EntityType.CallCenterDept]: undefined,
  [EntityType.CareCenter]: EntityType.CareGroup,
  [EntityType.CareDept]: EntityType.CareCenter,
  [EntityType.CareGroup]: EntityType.CareGroup,
};

export const ORGANIZATION_NAME_MAP = {
  [DeprecatedOrganizationTypeEnum.CARE_DEPARTMENT]: 'Care Dept',
  [DeprecatedOrganizationTypeEnum.CALL_DEPARTMENT]: 'Call Center Dept',
  [DeprecatedOrganizationTypeEnum.BILLING_DEPARTMENT]: 'Billing Dept',
} as { [key in DeprecatedOrganizationTypeEnum]: string };

export const CLINICAL_ORG_STATUS_TEXT = {
  [ClinicalOrgStatusEnum.ActivationPending]: 'Activation pending',
  [ClinicalOrgStatusEnum.Active]: 'Active',
  [ClinicalOrgStatusEnum.OnHiatus]: 'On hiatus',
  [ClinicalOrgStatusEnum.Terminated]: 'Terminated',
  [ClinicalOrgStatusEnum.ServiceEnded]: 'All service ended',
  [ClinicalOrgStatusEnum.Deleted]: 'Deleted',
};

export const USER_TYPE_TEXT = {
  [UserTypeEnum.CLINICAL_STAFF]: 'Clinical Staff',
  [UserTypeEnum.IHEALTH_EMPLOYEE]: 'iHealth Employee',
};

export const CLIENT_PRG_SVC_FORM_INPUT = {
  inClinicInventory: {
    name: 'inClinicInventory',
    label: '',
  },
  programAndServices: {
    name: 'programAndServices',
    label: '',
  },
  ccmRpmConsentForm: {
    name: 'ccmRpmConsentForm',
    label: 'CCM/RPM consent form',
  },
  valueBaseConsentForm: {
    name: 'valueBaseConsentForm',
    label: 'Value-based Care consent form',
  },
  mntConsentForm: {
    name: 'mntConsentForm',
    label: 'MNT consent form',
  },
  membershipConsentForm: {
    name: 'membershipConsentForm',
    label: 'Membership consent form',
  },
  ccmRpmRevenueModel: {
    name: 'ccmRpmRevenueModel',
    label: '',
  },
  pepmModel: {
    name: 'pepmModel',
    label: '',
  },
  pbpmModel: {
    name: 'pbpmModel',
    label: '',
  },
  pmapmModel: {
    name: 'pmapmModel',
    label: '',
  },
  patientReceiveAutoMsg: {
    name: 'patientReceiveAutoMessage',
    label: '',
  },
  nonSmartphoneAlt: {
    name: 'nonSmartphoneAlt',
    label: '',
  },
  ucAptServiceHoursFrom: {
    name: 'ucAptServiceHoursFrom',
    label: 'From',
  },
  ucAptServiceHoursTo: {
    name: 'ucAptServiceHoursTo',
    label: 'To',
  },
  ucAptServiceHoursDays: {
    name: 'ucAptServiceHoursDays',
    label: 'Days',
  },
  callCenterLocalNum: {
    name: 'callCenterLocalNum',
    label: 'Call center local number',
  },
  onSiteMembersMembers: {
    name: 'onSiteMembersMembers',
    label: 'On-site members',
  },
  onSiteMembersDays: {
    name: 'onSiteMembersDays',
    label: 'On-site days',
  },
  ucCareGroups: {
    name: 'ucCareGroups',
    label: '',
  },
  mainCa: {
    name: 'mainCa',
    label: 'Main CA',
  },
  mainRd: {
    name: 'mainRd',
    label: 'Main RD',
  },
  mainHc: {
    name: 'mainHc',
    label: 'Main HC',
  },
  coBrandingName: {
    name: 'coBrandName',
    label: 'Co-branding name',
  },
  coBrandingLogo: {
    name: 'logo',
    label: 'Logo',
  },
};

export const CREATE_CLINIC_FORM_INPUT = {
  isClient: {
    name: 'isClient',
    label: 'This clinical org is a client.',
  },
  businessName: {
    name: 'businessName',
    label: 'Business name',
  },
  sameNicknameAsName: {
    name: 'sameNicknameAsName',
    label: 'Use business name as clinical org\'s nickname',
  },
  nickname: {
    name: 'nickname',
    label: 'Nickname',
  },
  belongsToClinicalOrg: {
    name: 'belongsToClinicalOrg',
    label: 'Does this clinical org belong to a clinical org?',
  },
  parentClinicOrg: {
    name: 'parentClinicOrg',
    label: 'Clinical org it belongs to',
  },
  phone: {
    name: 'phone',
    label: 'Phone number',
  },
  address: {
    name: 'address',
    label: 'Address',
  },
  address2: {
    name: 'address2',
    label: 'Address 2 (Optional)',
  },
  state: {
    name: 'state',
    label: 'State',
  },
  city: {
    name: 'city',
    label: 'City',
  },
  zip: {
    name: 'zip',
    label: 'Zip',
  },
  timezone: {
    name: 'timezone',
    label: 'Time zone',
  },
  ehrVendor: {
    name: 'ehrVendor',
    label: 'Ehr vendor',
  },
  patientLanguages: {
    name: 'patientLanguages',
    label: 'Patient Languages',
  },
};
