import { useMemo } from 'react';
import {
  GetOptions, MakeRequestHookConfig, UseRequestFactory, useRequestFactory,
} from '../../../uc-api-sdk/staticFiles/useReqHook';
import { chsServices } from '../CHSServices';
import { GetChannelHistoryParams, GetChannelHistoryResponseData } from '../types';

export const useGetChannelHistory = <RCN = undefined>(
  configs?: MakeRequestHookConfig<GetChannelHistoryParams, GetOptions, RCN>,
) => {
  const baseConfigs: UseRequestFactory<
    GetChannelHistoryParams,
    GetChannelHistoryResponseData,
    typeof chsServices.getChannelHistory,
    GetOptions,
    GetChannelHistoryResponseData
  > = useMemo(() => ({
    reqFunc: (...args: Parameters<typeof chsServices.getChannelHistory>) => (
      chsServices.getChannelHistory(...args)
    ),
    ResponseClassCreator: chsServices.getResponse,
    typeName: 'useGetChannelHistory',
  }), []);

  return useRequestFactory(baseConfigs, configs);
};
