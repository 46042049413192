import { Checkbox, Space, Typography } from 'antd';
import { MedicalAlertResult, PriorityLevelEnum } from '../../../../uc-api-sdk';
import './MedicalAlertTitleComponent.scss';
import { TagComponent } from '../../../../uiComponent/TagComponent/TagComponent';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { Icon } from '../../../../icons/Icon';
import { MAGIC_STICK } from '../../../../assets/commonIconInfo';
import { MedicalAlertDueTimeComponent } from '../MedicalAlertDueTimeComponent/MedicalAlertDueTimeComponent';

export interface MedicalAlertTitleComponentProps {
  medicalAlertResult: MedicalAlertResult;
}
const { Text } = Typography;

export const MedicalAlertTitleComponent = ({
  medicalAlertResult,
}: MedicalAlertTitleComponentProps) => {
  const titleColor = (priority: PriorityLevelEnum) => {
    switch (priority) {
      case PriorityLevelEnum.HIGH:
        return 'error';
      case PriorityLevelEnum.MEDIUM:
        return 'warning';
      case PriorityLevelEnum.LOW:
        return 'success';
      default:
        return 'success';
    }
  };

  const title = () => (
    <TagComponent
      type={titleColor(medicalAlertResult.priority as PriorityLevelEnum)}
      iconType="solidDot"
      background="none"
      iconSize="middle"
      className="alert-title"
    >
      <Space>
        <Text className="b5">
          {medicalAlertResult.displayName}
        </Text>
        <TooltipComponent type={null} title={medicalAlertResult.alerts?.[0].description}>
          <Icon className="ai-c" info={MAGIC_STICK} />
        </TooltipComponent>
      </Space>
    </TagComponent>
  );

  return (
    <div className="flex jc-sb">
      <Space>
        <Checkbox value={medicalAlertResult} key={medicalAlertResult.displayName} />
        {title()}
      </Space>
      <div className="medical-alert-title-date ai-c">
        <MedicalAlertDueTimeComponent dueDate={medicalAlertResult.dueDate as string} />
      </div>
    </div>
  );
};
