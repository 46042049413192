import { TabPaneProps, Tabs, TabsProps } from 'antd';
import { useMemo, useState } from 'react';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { MpTrackingHelperProps } from '../../../../hooks/useMpTrackingHelper/useMpTrackingHelper';
import { ComplianceAlert, MedicalAlertResult, RoleTypeEnum } from '../../../../uc-api-sdk';
import { TabTitleComponent } from '../../../../uiComponent/TabTitleComponent/TabTitleComponent';
import { AlertText } from '../../constant/text';
import { MedicalAlertFormComponent, MedicalAlertFormSubmitValue } from '../MedicalAlertFormComponent/MedicalAlertFormComponent';
import { AlertFormComponent, AlertFormSubmitValue } from './AlertFormComponent';

type AlertTabKey = 'Compliance' | 'Medical';
export interface AlertTab extends Omit<TabPaneProps, 'key'> {
  key: AlertTabKey;
  label: React.ReactNode;
}
export interface AlertTabComponentProps {
  compliance?: ComplianceAlert[];
  complianceLoading?: boolean;
  medical?: MedicalAlertResult[];
  medicalAlertCount?: number;
  snoozed?: ComplianceAlert[];
  handleResolve: (v: AlertFormSubmitValue) => void;
  handleResolveMedicalAlert?: (v: MedicalAlertFormSubmitValue) => void;
  onComplianceAlertStart?: () => void;
  onComplianceAlertEnd?: (value?: MpTrackingHelperProps['properties']) => void;
  onMedicalAlertStart?: () => void;
}
export const AlertTabComponent = ({
  compliance,
  complianceLoading,
  medical,
  snoozed,
  medicalAlertCount,
  handleResolve,
  handleResolveMedicalAlert,
  onComplianceAlertStart,
  onComplianceAlertEnd,
  onMedicalAlertStart
}: AlertTabComponentProps) => {
  const { getCurrentRole } = useLoggedInUserFromContext();
  const userRole = useMemo(() => getCurrentRole(), []);
  const [selectedTab, setSelectedTab] = useState<AlertTabKey | string | undefined>();

  const medicalTabItem: AlertTab = {
    key: 'Medical',
    label: (
      <TabTitleComponent
        title={AlertText.Medical}
        tooltip={AlertText.Medical}
        count={medicalAlertCount ?? 0}
      />
    ),
    children: (
      <MedicalAlertFormComponent
        data={medical}
        onSubmit={handleResolveMedicalAlert}
        onMedicalAlertStart={onMedicalAlertStart}
      />
    ),
  };

  const complianceTabItem = {
    key: 'Compliance',
    label: (
      <TabTitleComponent
        title={AlertText.Compliance}
        tooltip={AlertText.Compliance}
        count={compliance?.length ?? 0}
      />
    ),
    children: (
      <AlertFormComponent
        complianceLoading={complianceLoading}
        data={compliance}
        snoozed={snoozed}
        onSubmit={handleResolve}
        type="compliance"
        onComplianceAlertStart={onComplianceAlertStart}
        onComplianceAlertEnd={onComplianceAlertEnd}
      />
    ),
  };

  const tabItems: TabsProps['items'] = useMemo(() => {
    let result = [medicalTabItem, complianceTabItem];
    if (userRole === RoleTypeEnum.CA) {
      result = [complianceTabItem, medicalTabItem];
    } else if (userRole === RoleTypeEnum.RD || userRole === RoleTypeEnum.HC) {
      result = [medicalTabItem, complianceTabItem];
    }
    // make the tab with value selected
    if (compliance?.length === 0 && snoozed?.length === 0
      && medical && medical.length > 0) {
      setSelectedTab('Medical');
    } else if (((compliance && compliance.length > 0) || (snoozed && snoozed.length > 0))
      && medical?.length === 0) {
      setSelectedTab('Compliance');
    }
    return result;
  }, [compliance, medical, medicalAlertCount, snoozed, complianceLoading]);

  return (
    <Tabs
      activeKey={selectedTab}
      onChange={setSelectedTab}
      className="tab-fixed-header alert-tab-component"
      animated={false}
      id="Alert"
      items={tabItems}
      destroyInactiveTabPane
    />
  );
};
