import { Select } from 'antd';
import { find } from 'lodash';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { PaginatedSelectComponent, PaginatedSelectComponentProps } from '../../../../uiComponent/PaginatedSelectComponent/PaginatedSelectComponent';
import { ICDDisplayComponent } from '../ICDDisplayComponent/ICDDisplayComponent';
import { Icd10Billable } from '../../../../uc-api-sdk';
import { DEFAULT_ICD_SEARCH_SIZE } from '../../constant';

export interface ICDSearchDropdownComponentProps {
  onSearchChange: PaginatedSelectComponentProps['onSearchChange'];
  onSelectChange: (icdCode?: Icd10Billable) => void;
  disabled?: boolean;
  isLoading?: boolean;
  data?: Icd10Billable[];
  onChangePage?: (page: number) => void;
  page?: number;
  totalSize?: number;
}

export const ICDSearchDropdownComponent = ({
  onSearchChange,
  onSelectChange,
  disabled,
  isLoading,
  data,
  onChangePage,
  page = 1,
  totalSize = 0,
}: ICDSearchDropdownComponentProps) => {
  const {
    value: openDropdown,
    setValue: setOpenDropdown,
  } = useBoolean();

  const handleOnSearchChange: PaginatedSelectComponentProps['onSearchChange'] = (
    searchValue: string,
  ) => {
    onSearchChange?.(searchValue);
  };

  const handleOnSelectChange: PaginatedSelectComponentProps['onSelectChange'] = (
    selectedValue,
  ) => {
    const icdCode = find(data, { id: selectedValue }) as Icd10Billable;
    onSelectChange?.(icdCode);
    setOpenDropdown(false);
  };

  const handleOnChangePage: PaginatedSelectComponentProps['pagination']['onChange'] = (
    page,
  ) => {
    onChangePage?.(page);
  };

  return (
    <PaginatedSelectComponent
      disabled={disabled}
      isLoading={isLoading}
      onSearchChange={handleOnSearchChange}
      onSelectChange={handleOnSelectChange}
      open={openDropdown}
      onOpenChange={setOpenDropdown}
      placeholder="Search code or name to add"
      pagination={{
        current: page,
        total: totalSize,
        pageSize: DEFAULT_ICD_SEARCH_SIZE,
        onChange: handleOnChangePage,
      }}
    >
      {
        data?.map((d) => (
          <Select.Option
            key={d.id}
            value={d.id}
          >
            <ICDDisplayComponent
              code={d.code}
              title={d.title}
            />
          </Select.Option>
        ))
      }
    </PaginatedSelectComponent>
  );
};
