import { AutoComplete, SelectProps } from 'antd';
import './ClinicalGoalConditionSearchSelectComponent.scss';

export interface ClinicalGoalConditionSearchSelectComponentProps
extends SelectProps {
}

export const ClinicalGoalConditionSearchSelectComponent = ({
  value,
  onChange,
  onSearch,
  options,
}: ClinicalGoalConditionSearchSelectComponentProps) => (
  <AutoComplete
    placeholder="Search or Enter Free Text"
    className="select-component"
    value={value}
    filterOption
    onSearch={onSearch}
    onSelect={onChange}
    options={options}
    notFoundContent={null}
    optionFilterProp="searchableLabel"
  />
);
