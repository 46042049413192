import {
  Col,
  Row,
  Space
} from 'antd';
import { PatientInfoRefetchObj } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import {
  ProgramCategoryEnumListComponent
} from '../../../../enumComponent/ProgramCategoriesComponent/ProgramCategoryEnumListComponent';
import {
  VitalEnumTypeListComponent
} from '../../../../enumComponent/VitalsEnumTypeComponent/VitalEnumTypeListComponent';
import { EnrolledProgramService } from '../../../../services/EnrolledProgramService';
import { PatientBillableTimeService } from '../../../../services/PatientBillableTimeService';
import { PatientService } from '../../../../services/PatientService';
import { HeaderPopoverComponent } from '../../../../uiComponent/HeaderPopoverComponent/HeaderPopoverComponent';
import { LabelWithValueComponent } from '../../../../uiComponent/LabelWithValueComonent/LabelWithValueComponent';
import { Title } from '../../../../uiComponent/Title/Title';
import {
  PatientDobGenderComponent
} from '../../../patient/component/PatientDobGenderComponent/PatientDobGenderComponent';
import {
  PatientCareProgressTagComponent
} from '../../../patient/component/TagsComponent/PatientInfoProgressTagComponent/PatientCareProgressTagComponent';
import {
  PatientMeasureProgressTagComponent
} from '../../../patient/component/TagsComponent/PatientInfoProgressTagComponent/PatientMeasureProgressTagComponent';
import './TranscribingPatientInfoCardComponent.scss';

export interface TranscribingPatientInfoCardComponentProps {
  data: {
    patientInfoService?: PatientService;
    billableTimeService?: PatientBillableTimeService;
    enrolledProgramService?: EnrolledProgramService;
    refetch?: (keys?: (keyof PatientInfoRefetchObj)[]) => void;
  };
}

export const TranscribingPatientInfoCardComponent = ({
  data,
}: TranscribingPatientInfoCardComponentProps) => {
  const enrolledPrograms = data?.enrolledProgramService?.getPrograms();

  return (
    <div className="transcribing-patient-info-card-component fd-c">
      <div className="flex ai-c jc-sb">
        <Space className="flex ai-bl">
          <Title>
            {data.patientInfoService?.getFullName()}
          </Title>
          <HeaderPopoverComponent
            current={data?.billableTimeService?.getBillableTime() || 0}
            max={data?.billableTimeService?.getBillableTimeTarget() || 20}
            qualified={data?.billableTimeService?.isQualified() || false}
            patientId={data?.patientInfoService?.patient?.id as string}
            refetch={data?.refetch}
            progressRenderer={PatientCareProgressTagComponent}
          />
          <HeaderPopoverComponent
            current={data?.billableTimeService?.getMeasurementDays() || 0}
            max={data?.billableTimeService?.getMeasurementDaysTarget() || 16}
            qualified={data?.billableTimeService?.isDaysQualified() || false}
            patientId={data?.patientInfoService?.patient?.id as string}
            progressRenderer={PatientMeasureProgressTagComponent}
          />
        </Space>
      </div>
      <Row gutter={[4, 4]} className="pt12 ai-bl">
        <Col span={6}>
          <LabelWithValueComponent
            value={(
              <PatientDobGenderComponent
                dob={data?.patientInfoService?.getBirthday()}
                gender={data?.patientInfoService?.getGender()}
              />
            )}
          />
        </Col>
        <Col span={6}>
          <LabelWithValueComponent
            label="Status: "
            value={(
              <div className="flex ai-bl">
                <span className="p0 autoH fs12">
                  Enrolled -
                </span>
                <div>
                  {
                    !!enrolledPrograms
                    && (
                      <ProgramCategoryEnumListComponent
                        value={enrolledPrograms}
                        type="short"
                        splitter=", "
                      />
                    )
                  }
                </div>
              </div>
            )}
          />
        </Col>
        <Col span={6}>
          <LabelWithValueComponent
            label="Vitals: "
            value={(
              <VitalEnumTypeListComponent
                value={data?.enrolledProgramService?.getVitals() || []}
                type="short"
                splitter=", "
              />
            )}
          />
        </Col>
      </Row>
    </div>
  );
};
