import { useMemo } from 'react';
import { ArrowDownOutlined, ArrowUpOutlined, MinusOutlined } from '@ant-design/icons';
import { HSMessagePayload } from '../../../../../services/CHSServices/types/data';

import './MessageVitalDisplayWeightChangeComponent.scss';

export interface MessageVitalDisplayWeightChangeComponentProps {
  weightChange: HSMessagePayload['weight_change'];
  unit: string;
  iconSize?: number;
}

export const MessageVitalDisplayWeightChangeComponent = ({
  weightChange,
  unit,
  iconSize = 15,
}: MessageVitalDisplayWeightChangeComponentProps) => {
  const weightChangeNum = Number(weightChange);

  const WeightChangeIcon = useMemo(() => {
    if (weightChangeNum === 0) {
      return <MinusOutlined className="weight-change__no-change" style={{ fontSize: iconSize }} />;
    }
    if (weightChangeNum > 0) {
      return <ArrowUpOutlined className="weight-change__up" style={{ fontSize: iconSize }} />;
    }
    return <ArrowDownOutlined className="weight-change__down" style={{ fontSize: iconSize }} />;
  }, [weightChangeNum]);

  return (
    <span className="message-vital-weight-change flex ai-c jc-c">
      {WeightChangeIcon}
      {' '}
      {Math.abs(weightChangeNum).toFixed(1)}
      {' '}
      {unit}
    </span>
  );
};
