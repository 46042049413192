import React, { ReactNode } from 'react';
import './PullUpDrawerWrapperComponent.scss';
import classNames from 'classnames';

export interface PullUpDrawerWrapperComponentProps {
  className?: string;
  children: ReactNode;
}

export const PullUpDrawerWrapperComponent = ({
  className = '',
  children,
}: PullUpDrawerWrapperComponentProps) => (
  <div
    className={classNames({
      'pull-up-drawer-wrapper-component': true,
      [className]: !!className,
    })}
  >
    {children}
  </div>
);
