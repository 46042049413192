import { FC, useMemo } from 'react';
import { map } from 'lodash';
import moment from 'moment';
import FoodLogTableComponent, { FoodLogTableData } from './FoodLogTableComponent';
import { FoodLog } from '../../type';
import { DateHelper } from '../../../../helpers/date';
import TimezoneService from '../../../../helpers/timezone/timezoneService';

export interface FoodLogProcessorComponentProps {
  data: FoodLog[];
  onReview?: () => void;
}

export const FoodLogProcessorComponent:
  FC<FoodLogProcessorComponentProps> = ({
    data,
    onReview,
  }) => {
    const procData = useMemo(() => {
      const grouped = DateHelper.groupByDate(data, (v) => (
        TimezoneService.calcDateTime(v.uploadedAt, v.timezone)
      ));
      const result = map(grouped, (v, k): FoodLogTableData => ({ data: v, date: moment(k) }));
      return result;
    }, [data]);

    return (
      <div data-testid="FoodLogProcessorComponent">
        <FoodLogTableComponent
          data={procData}
          onReview={onReview}
        />
      </div>
    );
  };
