import React, { ReactNode } from 'react';
import './SectionTitleComponent.scss';

interface Props {
  title: ReactNode;
}

export const SectionTitleComponent: React.FC<Props> = ({ title }) => (
  <div className="section-title-component">
    <h2 className="section-title">{title}</h2>
    <hr />
  </div>
);
