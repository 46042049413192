import { map } from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment, { Moment } from 'moment';
import React, { FC, useMemo } from 'react';
import { createBPChartOptions, createBPChartSeries } from './BPChartHelper';
import { createMarker, findMaxWithStep, getYValue } from '../../helper/chartHelper';
import { FromDateToDateDayjs, Severity } from '../../type';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';

export interface BPChartData {
  date: Moment | Date;
  systolic: number;
  diastolic: number;
  heartRate: number | undefined;
  severity: Severity;
}

export interface BPChartComponentProps extends FromDateToDateDayjs {
  data: BPChartData[];
}

export const BPChartComponent: FC<BPChartComponentProps> = ({
  data,
  fromDate,
  toDate,
}) => {
  const { value: showDiastolicBand, toggle: toggleDiastolic } = useBoolean(true);
  const { value: showSystolicBand, toggle: toggleSystolic } = useBoolean(true);
  const getData = (getYValue: (v: BPChartData) => number) => map(data, (curr) => {
    const date = moment(curr.date).startOf('day').valueOf();
    return {
      x: date,
      y: getYValue(curr),
      systolic: curr.systolic,
      diastolic: curr.diastolic,
      heartRate: curr.heartRate,
      marker: createMarker({ severity: curr.severity, symbol: 'circle' }),
    };
  });

  const options = useMemo(() => {
    const systolicData = getData((v) => +v.systolic.toFixed(2));
    const diastolicData = getData((v) => +v.diastolic.toFixed(2));
    const series = createBPChartSeries({
      systolicData,
      diastolicData,
      onDiastolicLegendClick: toggleDiastolic,
      onSystolicLegendClick: toggleSystolic,
    });
    const max = findMaxWithStep(
      [
        ...systolicData,
        ...diastolicData,
      ].map(getYValue),
      50,
      300,
    );
    return createBPChartOptions({
      series,
      fromDate,
      toDate,
      max,
      showDiastolicBand,
      showSystolicBand,
    });
  }, [
    data,
    fromDate,
    toDate,
    showSystolicBand,
    showDiastolicBand,
  ]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
};
