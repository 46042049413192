import ModalButton, { ModalButtonProps } from '../../../../uiComponent/ModalButton/ModalButton';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import { InsuranceEligibilityHistoryComponent } from '../InsuranceEligibilityHistoryComponent/InsuranceEligibilityHistoryComponent';
import { ModalWidth880 } from '../../../../constants/modal';
import { Title } from '../../../../uiComponent/Title/Title';
import { EligibilityReport } from '../../../../uc-api-sdk';

export interface InsuranceEligibilityHistoryModalComponentProps
  extends Omit<ModalButtonProps, 'children'> {
  eligibilityReports?: EligibilityReport[];
}

export const InsuranceEligibilityHistoryModalComponent = ({
  title = 'Eligibility Verification History',
  onCancel,
  trigger = (open) => (
    <LinkButton
      useBlueColor
      onClick={open}
    >
      View History
    </LinkButton>
  ),
  eligibilityReports,
}: InsuranceEligibilityHistoryModalComponentProps) => {
  const handleCancel: ModalButtonProps['onCancel'] = (e) => {
    onCancel?.(e);
  };
  return (
    <ModalButton
      title={(<Title noMargin>{title}</Title>)}
      width={ModalWidth880}
      trigger={trigger}
      onCancel={handleCancel}
      maskClosable={false}
      destroyOnClose
    >
      <InsuranceEligibilityHistoryComponent
        eligibilityReports={eligibilityReports || []}
      />
    </ModalButton>
  );
};
