import { Space } from 'antd';
import { AccountHelper } from '../../../../helpers/account';
import { VisitParticipant } from '../../../../uc-api-sdk';
import { CalendarParticipantRoleComponent } from './CalendarParticipantRoleComponent';

export interface CalendarParticipantComponentProps {
  value: VisitParticipant;
}

export const CalendarParticipantComponent = ({
  value,
}: CalendarParticipantComponentProps) => (
  <Space className="ai-s">
    {value.role && (
      <div>
        <CalendarParticipantRoleComponent value={value.role} />
        :
      </div>
    )}
    <div>
      {
        AccountHelper.getFullName(value.participantInfo)
        || value.participantInfo?.email
      }
    </div>
  </Space>
);
