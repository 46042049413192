/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Tabs, TabsProps } from 'antd';
import {
  map,
  groupBy,
  forEach,
  includes,
  flatMap,
  filter,
  flatten,
  values,
  trim,
} from 'lodash';
import { useState } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { SearchOutlined } from '@ant-design/icons';
import {
  BehaviorGoalCategoryEnum,
  BehaviorGoalItem,
  BehaviorGoalTemplate,
  BehaviorGoalTypeEnum,
} from '../../../../../uc-api-sdk';
import { SelectType } from '../../../../Input/types';
import { BehaviorGoalTypeEnumComponent } from '../../../../../enumComponent/BehaviorGoalTypeEnumComponent/BehaviorGoalTypeEnumComponent';
import { CheckboxGroupComponent } from '../../../../../uiComponent/CheckboxGroupComponent/CheckboxGroupComponent';

import './BehaviorGoalSearchSelectComponent.scss';
import { BehaviorGoalCategoryEnumComponent } from '../../../../../enumComponent/BehaviorGoalCategoryEnumComponent/BehaviorGoalCategoryEnumComponent';
import { SelectWithPrefixIconComponent } from '../../../../../uiComponent/SelectWithPrefixIconComponent/SelectWithPrefixIconComponent';
import { useDeepCompareMemo } from '../../../../../hooks/useDeepCompareEffect';
import useDebounce from '../../../../../hooks/useDebounce/useDebounce';
import { useOnClickOutside } from '../../../../../hooks/useOnClickOutside/useOnClickOutside';

export type BehaviorGoalSelectValue = {
  [category in BehaviorGoalCategoryEnum]?: BehaviorGoalItem[];
}

export type BehaviorGoalGroupedValues = {
  [category in BehaviorGoalCategoryEnum]: {
    [type in BehaviorGoalTypeEnum]?: BehaviorGoalItem[];
  }
}

export interface BehaviorGoalSearchSelectComponentProps
  extends Omit<SelectType<BehaviorGoalSelectValue>, 'options'> {
  options: BehaviorGoalTemplate[];
  type?: BehaviorGoalTypeEnum;
  onTypeChange?: (type: BehaviorGoalTypeEnum) => void;
}

export const BehaviorGoalSearchSelectComponent = ({
  value,
  onChange,
  type: controlledType = values(BehaviorGoalTypeEnum)[0],
  onTypeChange,
  options,
  ...props
}: BehaviorGoalSearchSelectComponentProps) => {
  const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined);
  const [
    type,
    setType,
  ] = useState<BehaviorGoalTypeEnum | undefined>(controlledType);
  const [
    searchValue,
    setSearchValue,
  ] = useState<string>('');
  const { ref: dropdownDivRef } = useOnClickOutside(() => setIsOpen(undefined));

  const debouncedSetSearchValue = useDebounce(setSearchValue);

  const optionsGroupedByType = useDeepCompareMemo(() => (
    groupBy(options, 'type')
  ), [options]);

  const filteredOptionsGroupedByType = useDeepCompareMemo(() => {
    const trimmedSearchValue = trim(searchValue);
    let filteredOptions = options;
    if (trimmedSearchValue) {
      filteredOptions = map(options, (option) => {
        const filteredOption = { ...option };
        const newValues = [] as string[];
        const searchValueRegex = new RegExp(`.*${searchValue}.*`, 'gi');
        let hasMatch = false;
        forEach(option.values, (optionValue) => {
          if (searchValueRegex.test(optionValue)) {
            hasMatch = true;
            newValues.push(optionValue);
          }
        });
        if (hasMatch) {
          filteredOption.values = newValues;
          return filteredOption;
        }
        return null;
      }) as BehaviorGoalTemplate[];
    }
    return groupBy(filter(filteredOptions, (option) => !!option), 'type');
  }, [searchValue, options]);

  const [
    groupedValues,
    flattenMapValue,
  ] = useDeepCompareMemo(() => {
    const groupedValues = {} as BehaviorGoalGroupedValues;
    forEach(value, (
      goalItems,
      category,
    ) => {
      const valuesByType = groupBy(goalItems, 'behaviorGoalType');
      groupedValues[category as BehaviorGoalCategoryEnum] = valuesByType;
    });
    const flattenMapValue = flatMap(value);
    return [groupedValues, flattenMapValue];
  }, [value]);

  const handleSetType = (type: BehaviorGoalTypeEnum) => {
    setType(type);
    onTypeChange?.(type);
  };

  useUpdateEffect(() => {
    if (controlledType) handleSetType(controlledType);
  }, [controlledType]);

  const getCheckboxValue = (
    category: BehaviorGoalCategoryEnum,
  ) => {
    if (!type) return [];
    const goalValueItems = groupedValues?.[category]?.[type] || [];
    const goalValues = map(goalValueItems, 'behaviorGoalValue');
    return goalValues as string[];
  };

  const handleOnChange = (
    category: BehaviorGoalCategoryEnum,
    values: string[],
    templates: string[],
  ) => {
    const parsedValues = map(values, (value) => ({
      behaviorGoalType: type,
      category,
      behaviorGoalValue: value,
    }) as BehaviorGoalItem);
    // onChange only affect to group of values in:
    // selected type and given category
    const categoryValues = value?.[category];
    let newCategoryValues = [] as BehaviorGoalItem[];
    if (!categoryValues) {
      // never exists, then use all current selections
      newCategoryValues = parsedValues;
    } else {
      const savedItems = [] as BehaviorGoalItem[];
      forEach(categoryValues, (item) => {
        if (
          // save items not have the same type
          item.behaviorGoalType !== type
          // OR save items not have value in templates
          || !includes(templates, item.behaviorGoalValue)
        ) {
          savedItems.push(item);
        }
      });
      newCategoryValues = [
        ...savedItems,
        ...parsedValues,
      ];
    }
    const newValue = {
      ...value,
      [category]: newCategoryValues
    };
    onChange?.(newValue);
  };

  const renderSelectedCount = (type: BehaviorGoalTypeEnum) => {
    const optionByTypeValues = flatten(map(optionsGroupedByType[type], 'values'));
    const count = filter(flattenMapValue, (item) => (
      item.behaviorGoalType === type
      // count only item exists in option templates
      && includes(optionByTypeValues, item.behaviorGoalValue)
    )).length;
    return (
      count > 0
        ? (
          // ie. (1)
          <span className="ml10">
            (
            {count}
            )
          </span>
        )
        : null
    );
  };

  const renderCategories = (type: BehaviorGoalTypeEnum) => {
    const groupedByCategory = filteredOptionsGroupedByType[type];
    return map(groupedByCategory, ({ category, values }) => (
      <div className="flex fd-c f-w" key={category}>
        <div className="my10 b5">
          <BehaviorGoalCategoryEnumComponent value={category as BehaviorGoalCategoryEnum} />
        </div>
        <CheckboxGroupComponent
          options={map(values, (value) => ({ label: value, value }))}
          value={getCheckboxValue(category as BehaviorGoalCategoryEnum)}
          onChange={(checkedValues) => {
            handleOnChange(
              category as BehaviorGoalCategoryEnum,
              checkedValues as string[],
              values || [],
            );
          }}
          isVertical
        />
      </div>
    ));
  };

  const renderDropdown = () => {
    const items = [] as NonNullable<TabsProps['items']>;
    forEach(BehaviorGoalTypeEnum, (type: BehaviorGoalTypeEnum) => {
      if (filteredOptionsGroupedByType[type]) {
        items.push({
          key: type,
          label: (
            <div>
              <BehaviorGoalTypeEnumComponent value={type} />
              {renderSelectedCount(type)}
            </div>
          ),
          children: renderCategories(type),
        });
      }
    });

    return (
      <div
        ref={dropdownDivRef}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsOpen(true);
        }}
        className="flex"
        aria-hidden
        role="button"
      >
        <Tabs
          activeKey={type}
          onTabClick={(key) => {
            handleSetType(key as BehaviorGoalTypeEnum);
          }}
          items={items}
          tabPosition="left"
          className="behavior-goal-select-dropdown-tabs"
        />
      </div>
    );
  };

  return (
    <SelectWithPrefixIconComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      open={isOpen}
      prefixIcon={<SearchOutlined />}
      value={value}
      onChange={onChange}
      showSearch
      showArrow
      defaultActiveFirstOption={false}
      dropdownRender={renderDropdown}
      className="mb20"
      onSearch={debouncedSetSearchValue}
    />
  );
};
