import { isEmpty } from 'lodash';
import { ReactNode } from 'react';
import { Transcribe, useTranscribeSearch } from '../../../../uc-api-sdk';

export interface TranscribeContainerChildrenProps {
  transcribeData?: Transcribe;
  refetchTranscribeData?: ReturnType<typeof useTranscribeSearch>['refetch'];
  isLoadingTranscribe?: boolean;
}

export interface TranscribeContainerProps {
  transcribeId?: string;
  transcribeData?: Transcribe;
  children: (props: TranscribeContainerChildrenProps) => ReactNode;
}

export const TranscribeContainer = ({
  transcribeId,
  transcribeData,
  children,
}: TranscribeContainerProps) => {
  const transcribeInfo = useTranscribeSearch({
    params: {
      searchRequest: {
        filter: { id: transcribeId },
      },
    },
    options: { sendOnMount: isEmpty(transcribeData) && !!transcribeId },
  });

  const data = transcribeInfo.data?.data?.content?.[0] as Transcribe || transcribeData;

  return (
    <>
      {
        children({
          transcribeData: data,
          refetchTranscribeData: transcribeInfo.refetch,
          isLoadingTranscribe: transcribeInfo.isLoading,
        })
      }
    </>
  );
};
