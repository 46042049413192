import React, { ReactNode } from 'react';
import classNames from 'classnames';

export interface ScreenSizeChildComponentProps {
  children: ReactNode;
  className?: string;
}

export const ScreenSizeChildComponent = ({
  children,
  className = '',
}: ScreenSizeChildComponentProps) => (
  <div
    className={classNames({
      'screen-size-child-component': true,
      [className]: !!className,
    })}
  >
    {children}
  </div>
);
