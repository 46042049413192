import { PatientDataHistoryEntityEnum } from '../../uc-api-sdk';
import { ListComponent } from '../../uiComponent/ListComponent/ListComponent';
import { EnumListComponent } from '../type';
import { PatientDataHistoryEntityEnumComponent } from './PatientDataHistoryEntityEnumComponent';

export interface PatientDataHistoryEntityEnumListComponentProps extends
  EnumListComponent<PatientDataHistoryEntityEnum> {
}

export const PatientDataHistoryEntityEnumListComponent = ({
  value,
  splitter,
}: PatientDataHistoryEntityEnumListComponentProps) => (
  <ListComponent
    value={value}
    splitter={splitter}
    renderItem={(v) => (
      <PatientDataHistoryEntityEnumComponent
        key={v}
        value={v}
      />
    )}
  />
);
