import { Form, FormProps, Input } from 'antd';
import { ReactNode, useMemo } from 'react';

import { getChildByDisplayName, makeLayoutItem } from '../../lib/layout';
import CancelSubmitButtonsComponent from '../CancelSubmitButtonsComponent/CancelSubmitButtonsComponent';
import { PasswordRequirementFormItemComponent } from './PasswordRequirementFormItemComponent';

import PasswordFormService, { PasswordRulesType } from '../../services/PasswordFormService';
import './PasswordFormComponent.scss';

const PasswordContainerFooterDisplayName = 'Footer';

export interface PasswordFormComponentProps {
  onSubmit: FormProps['onFinish'];
  onCancel?: () => void;
  disabled?: boolean;
  hasConfirmation?: boolean;
  characterCount?: number;
  containNumber?: boolean;
  containLowerCase?: boolean;
  containUpperCase?: boolean;
  containSpecialCharacter?: boolean;
  requirementMap?: { [key: string]: string };
  showRequirements?: boolean;
  passwordName?: string;
  passwordLabel?: string;
  confirmPasswordName?: string;
  confirmPasswordLabel?: string;
  children?: ReactNode;
}

export const DEFAULT_REQUIREMENT_MAP = {
  characterCount: '[count] characters minimum',
  containNumber: 'One number',
  containLowerCase: 'One lowercase letter',
  containUpperCase: 'One UPPERCASE letter',
  containSpecialCharacter: 'One special character',
} as { [key: string]: string };

export const PasswordFormComponent = ({
  onSubmit,
  onCancel,
  disabled,
  hasConfirmation = true,
  characterCount = 8,
  containNumber = true,
  containLowerCase = true,
  containUpperCase = true,
  containSpecialCharacter = true,
  requirementMap,
  showRequirements = true,
  passwordName = 'password',
  passwordLabel = 'New Password',
  confirmPasswordName = 'confirmPassword',
  confirmPasswordLabel = 'Confirm New Password',
  children,
}: PasswordFormComponentProps) => {
  const [form] = Form.useForm();
  const handleFinish: FormProps['onFinish'] = (
    values: Record<string, string>,
  ) => {
    const { password } = values;
    onSubmit?.(password);
  };

  const handleCancel = () => {
    onCancel?.();
  };

  const passwordRules: PasswordRulesType = {
    characterCount,
    containNumber,
    containLowerCase,
    containUpperCase,
    containSpecialCharacter,
  };

  const [FooterComponent] = useMemo(() => (
    getChildByDisplayName(PasswordContainerFooterDisplayName, children)
  ), [children]);

  return (
    <Form
      data-testid="password-input-form-component"
      form={form}
      className="password-input-form"
      onFinish={handleFinish}
      preserve={false}
      layout="vertical"
    >
      <Form.Item
        name={passwordName}
        label={passwordLabel}
        rules={[
          {
            validator: PasswordFormService.getPasswordValidator(form, passwordName, passwordRules)
          },
        ]}
      >
        <Input.Password
          visibilityToggle
          onChange={() => {
            form.setFieldsValue({
              [confirmPasswordName]: '',
            });
          }}
        />
      </Form.Item>
      <Form.Item
        name={confirmPasswordName}
        label={confirmPasswordLabel}
        hidden={!hasConfirmation}
        rules={[
          { validator: PasswordFormService.getConfirmPasswordValidator(form) },
        ]}
      >
        <Input.Password visibilityToggle />
      </Form.Item>
      <Form.Item noStyle shouldUpdate hidden={!showRequirements}>
        {
          (formI) => (
            <div className="password-input-form__requirements">
              {
                Object.entries(passwordRules).map((ent: [string, number | string]) => {
                  const [key, value] = ent;
                  if (value) {
                    const hasInput = form.getFieldValue(passwordName);
                    const isRequirementFulfilled = PasswordFormService.isRequirementFulFilled(
                      formI,
                      key,
                    );
                    const displayText = (requirementMap || DEFAULT_REQUIREMENT_MAP)[key] || '';
                    const replaced = displayText.replaceAll('[count]', (value as number).toString());
                    return (
                      <PasswordRequirementFormItemComponent
                        key={key}
                        name={key}
                        displayText={replaced}
                        isFulfilled={isRequirementFulfilled}
                        hasInput={hasInput}
                      />
                    );
                  }
                  return null;
                })
              }
            </div>
          )
        }
      </Form.Item>

      {
        FooterComponent
        || (
          <div className="password-input-form__buttons">
            <CancelSubmitButtonsComponent
              onCancel={handleCancel}
              submitText="Submit"
              disabled={disabled}
            />
          </div>
        )
      }
    </Form>
  );
};

PasswordFormComponent.Footer = makeLayoutItem(PasswordContainerFooterDisplayName);
export default PasswordFormComponent;
