import { DeviceModelEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface DeviceModelEnumComponentProps {
  value?: DeviceModelEnum;
  type?: 'long' | 'short';
}

export const getFullName = (value?: DeviceModelEnum) => {
  switch (value) {
    case DeviceModelEnum.BP3L: return 'iHealth Ease';
    case DeviceModelEnum.BP5: return 'iHealth BP5 (Feel)';
    case DeviceModelEnum.BP7250: return 'OMRON (BP7250)';
    case DeviceModelEnum.BG5: return 'iHealth BG5 (Smart)';
    case DeviceModelEnum.BG1: return 'iHealth Align';
    case DeviceModelEnum.BG1S: return 'iHealth Align';
    case DeviceModelEnum.BG5S: return 'iHealth Gluco+';
    case DeviceModelEnum.PO3: return 'iHealth Air';
    case DeviceModelEnum.HS2: return 'iHealth Lina';
    case DeviceModelEnum.HS4S: return 'iHealth Lite';
    case DeviceModelEnum.HS6: return 'HS6';
    case DeviceModelEnum.HS2S: return 'iHealth Nexus';
    case DeviceModelEnum.TM3: return 'TM3';
    case DeviceModelEnum.TS28B: return 'iHealth TS28B';
    case DeviceModelEnum.PY_802_LTE: return 'BP Cellular Pylo';
    case DeviceModelEnum.BIOLAND: return 'BG Cellular Bioland';
    case DeviceModelEnum.LOAN_DEVICE: return 'Loaner phone (iPhone 6s)';
    case DeviceModelEnum.BPM1:
    case DeviceModelEnum.BPM1_HOTSPOT:
      return 'iHealth BPM1';
    default: return null;
  }
};

export const getShortName = (value?: DeviceModelEnum) => {
  switch (value) {
    case DeviceModelEnum.BP3L: return 'BP3L';
    case DeviceModelEnum.BP5: return 'BP5';
    case DeviceModelEnum.BP7250: return 'OMRON (BP7250)';
    case DeviceModelEnum.BG5: return 'BG5';
    case DeviceModelEnum.BG1: return 'BG1';
    case DeviceModelEnum.BG1S: return 'BG1S';
    case DeviceModelEnum.BG5S: return 'BG5S';
    case DeviceModelEnum.PO3: return 'PO3';
    case DeviceModelEnum.HS2: return 'HS2';
    case DeviceModelEnum.HS4S: return 'HS4S';
    case DeviceModelEnum.HS6: return 'HS6';
    case DeviceModelEnum.HS2S: return 'HS2S';
    case DeviceModelEnum.TM3: return 'TM3';
    case DeviceModelEnum.TS28B: return 'TS28B';
    case DeviceModelEnum.PY_802_LTE: return 'BP Cellular Pylo';
    case DeviceModelEnum.BIOLAND: return 'BG Cellular Bioland';
    case DeviceModelEnum.BPM1: return 'BPM1 (with Wi-Fi Router)';
    case DeviceModelEnum.BPM1_HOTSPOT: return 'BPM1 (with Hotspot)';
    default: return null;
  }
};

export const DeviceModelEnumComponent = ({
  value,
  type = 'long',
}: DeviceModelEnumComponentProps) => {
  const name = type === 'short' ? getShortName(value) : getFullName(value);
  return <DisplayOrEmptyComponent value={name} />;
};
