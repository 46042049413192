import { VitalEnumType } from '../../uc-api-sdk';

export const vitalsMonitoringOrder = [
  VitalEnumType.BG,
  VitalEnumType.BP,
  VitalEnumType.PO,
  VitalEnumType.HS,
  VitalEnumType.TM,
  VitalEnumType.EXERCISE,
];
