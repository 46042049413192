import { Card } from 'antd';
import classNames from 'classnames';
import { ReactNode, useMemo } from 'react';
import { getChildByDisplayName, makeLayoutItem } from '../../../../lib/layout';
import { FixedChildComponent } from '../../../../uiComponent/FixedComponent/FixedChildComponent';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import { ScreenSizeComponent } from '../../../../uiComponent/ScreenSizeComponent/ScreenSizeComponent';

const PatientProfileLayoutHeaderComponentName = 'HeaderComponent';
const PatientProfileLayoutPatientCardComponentName = 'PatientCardComponent';
const PatientProfileLayoutBodyComponentName = 'BodyComponent';
const PatientProfileLayoutExtraLeftComponentName = 'ExtraLeftComponent';
const PatientProfileLayoutExtraRightComponentName = 'ExtraRightComponent';

export interface PatientProfileLayoutComponentProps {
  children: ReactNode;
}

export const PatientProfileLayoutComponent = ({
  children,
}: PatientProfileLayoutComponentProps) => {
  const [
    Header,
    ExtraLeft,
    PatientCard,
    Body,
    ExtraRight,
  ] = useMemo(() => ([
    getChildByDisplayName(PatientProfileLayoutHeaderComponentName, children)[0],
    getChildByDisplayName(PatientProfileLayoutExtraLeftComponentName, children)[0],
    getChildByDisplayName(PatientProfileLayoutPatientCardComponentName, children)[0],
    getChildByDisplayName(PatientProfileLayoutBodyComponentName, children)[0],
    getChildByDisplayName(PatientProfileLayoutExtraRightComponentName, children)[0],
  ]), [children]);

  return (
    <ScreenSizeComponent className="patient-profile-page">
      <FixedComponent>
        {Header}
        <div className="patient-profile-page__content">
          {ExtraLeft}
          <ScreenSizeComponent.Child className="patient-profile-page__main">
            <FixedComponent className="patient-profile-page__main-content">
              {PatientCard}
              <div className="patient-profile-page__main-body">
                {Body}
              </div>
            </FixedComponent>
          </ScreenSizeComponent.Child>
          {ExtraRight}
        </div>
      </FixedComponent>
    </ScreenSizeComponent>
  );
};

interface VisibilityComponentProps {
  children: ReactNode;
  hidden?: boolean;
}

const VisibilityComponent = ({
  children,
  hidden,
}: VisibilityComponentProps) => {
  if (hidden) return null;
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (<>{children}</>);
};

interface HeaderComponentProps extends VisibilityComponentProps {
  isFixed?: boolean;
}

const HeaderComponent = ({
  children,
  isFixed = true,
  hidden,
}: HeaderComponentProps) => (
  <VisibilityComponent hidden={hidden}>
    <FixedChildComponent
      isFixed={isFixed}
      className="patient-profile-page__header-wrapper"
    >
      {children}
    </FixedChildComponent>
  </VisibilityComponent>
);

interface PatientCardComponentProps extends VisibilityComponentProps {
  isFixed?: boolean;
}

const PatientCardComponent = ({
  children,
  isFixed = true,
  hidden,
}: PatientCardComponentProps) => (
  <VisibilityComponent hidden={hidden}>
    <FixedChildComponent
      isFixed={isFixed}
      className="patient-profile-page__patient-card-wrapper"
    >
      {children}
    </FixedChildComponent>
  </VisibilityComponent>
);

const BodyComponent = ({
  children,
  hidden,
}: VisibilityComponentProps) => (
  <VisibilityComponent hidden={hidden}>
    <FixedChildComponent className="patient-profile-page__body-wrapper">
      <Card className="patient-profile-page__body">
        {children}
      </Card>
    </FixedChildComponent>
  </VisibilityComponent>
);

interface ExtraComponentProps extends VisibilityComponentProps {
  right?: boolean;
}

const ExtraComponent = ({
  children,
  hidden,
  right,
}: ExtraComponentProps) => (
  <VisibilityComponent hidden={hidden}>
    <ScreenSizeComponent.Child
      className={classNames({
        'patient-profile-page__extra': true,
        'patient-profile-page__extra-right': !!right,
        'patient-profile-page__extra-left': !right,
      })}
    >
      <Card className="patient-profile-page__extra-body">
        {children}
      </Card>
    </ScreenSizeComponent.Child>
  </VisibilityComponent>
);

// eslint-disable-next-line react/jsx-props-no-spreading
const ExtraLeftComponent = (props: ExtraComponentProps) => <ExtraComponent {...props} />;
// eslint-disable-next-line react/jsx-props-no-spreading
const ExtraRightComponent = (props: ExtraComponentProps) => <ExtraComponent {...props} right />;

PatientProfileLayoutComponent.Header = makeLayoutItem(
  PatientProfileLayoutHeaderComponentName,
  HeaderComponent,
);
PatientProfileLayoutComponent.PatientCard = makeLayoutItem(
  PatientProfileLayoutPatientCardComponentName,
  PatientCardComponent,
);
PatientProfileLayoutComponent.Body = makeLayoutItem(
  PatientProfileLayoutBodyComponentName,
  BodyComponent,
);
PatientProfileLayoutComponent.ExtraLeft = makeLayoutItem(
  PatientProfileLayoutExtraLeftComponentName,
  ExtraLeftComponent,
);
PatientProfileLayoutComponent.ExtraRight = makeLayoutItem(
  PatientProfileLayoutExtraRightComponentName,
  ExtraRightComponent,
);
