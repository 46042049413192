import dayjs from 'dayjs';
import { map } from 'lodash';
import { useMemo } from 'react';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { CalendarSourceEnum, useClinicEventSearch } from '../../../../uc-api-sdk';
import { BaseSortDirection } from '../../../../uc-api-sdk/staticFiles/useReqHook';
import { PatientVisitsComponent } from '../../component/PatientVisitsComponent/PatientVisitsComponent';
import { ClinicEventsService } from '../../component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';

import OverlayLoading from '../../../../uiComponent/OverlayLoading/OverlayLoading';
import { PatientEmptyVisitsComponent } from '../../component/PatientEmptyVisitsComponent/PatientEmptyVisitsComponent';
import '../../component/PatientVisitsListComponent/PatientVisitsListComponent.scss';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';

export interface PatientVisitsContainerProps {
}

export const PatientVisitsContainer = () => {
  const { info } = usePatientContext();
  const { id: patientId = '' } = info || {};

  const fetchClinicEventList = useClinicEventSearch({
    options: { sendOnMount: true },
    params: {
      filter: {
        calendarSourceIn: { in: [CalendarSourceEnum.UC_VISIT, CalendarSourceEnum.OTHER] },
        patientId,
        startTimeRange: {
          gte: dayjs('2021-01-01').toISOString(),
        },
      },
      pageInfo: {
        page: 1,
        size: 50,
        pagination: true,
        sort: [{
          property: 'startTime',
          direction: BaseSortDirection.DESC,
        }],
      },
    },
  });

  const debouncedFetchClinicEventListRefetch = useDebounce(
    fetchClinicEventList.refetch,
    250,
    [patientId],
  );

  useUpdateListener('VISIT_CREATED', debouncedFetchClinicEventListRefetch);
  useUpdateListener('VISIT_UPDATED', debouncedFetchClinicEventListRefetch);
  useUpdateListener('VISIT_DELETED', debouncedFetchClinicEventListRefetch);
  useUpdateListener('TRANSCRIBE_DRAWER_CLOSED', debouncedFetchClinicEventListRefetch);
  useUpdateListener('WORKLIST_CLOSED', debouncedFetchClinicEventListRefetch);

  const isLoading = (
    fetchClinicEventList.data?.data?.content === undefined || fetchClinicEventList.isLoading
  );

  const clinicListInfo = useMemo(() => (
    map(fetchClinicEventList.data?.data?.content, (event) => (
      new ClinicEventsService(event)
    ))
  ), [fetchClinicEventList.data]);

  const clinicListDataByYear = useMemo(() => {
    const yearsMap: Record<number, ClinicEventsService[]> = {};
    clinicListInfo.forEach(event => {
      const year = dayjs(event.startTime).year();
      if (!yearsMap[year]) {
        yearsMap[year] = [];
      }
      yearsMap[year].push(event);
    });
    return map(yearsMap, (v, k) => ({ year: k, events: v }));
  }, [clinicListInfo]);

  const mapToArray = () => (
    map(clinicListDataByYear, ({ year, events }) => ({ year: year.toString(), events }))
      .reverse()
  );

  return (
    <>
      <OverlayLoading loading={isLoading} />
      {mapToArray().length === 0
        ? <PatientEmptyVisitsComponent />
        : (
          <PatientVisitsComponent
            clinicList={mapToArray()}
            onEditDone={fetchClinicEventList.refetch}
          />
        )}
    </>
  );
};
