import {
  ReactNode,
  createContext,
  useContext
} from 'react';
import classNames from 'classnames';
import { useUpdateEffect } from 'usehooks-ts';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import useBoolean from '../../hooks/useBoolean/useBoolean';

import './Minimizable.scss';

export const minimizedClass = 'minimized';

export interface MinimizableContextValue {
  isMinimized: boolean;
  minimize: () => void;
  maximize: () => void;
}

const MinimizableContext = createContext<
  MinimizableContextValue | undefined
>(undefined);

export const useMinimizableContext = () => {
  const context = useContext(MinimizableContext);
  // if (!context) throw new Error('MinimizableContext not found');
  return context as MinimizableContextValue;
};

export interface MinimizableContextProviderProps {
  isMinimized?: boolean;
  defaultIsMinimized?: boolean;
  children: ReactNode | ((props: Partial<MinimizableContextValue>) => ReactNode);
}
export const Minimizable = ({
  isMinimized: _isMinimized,
  defaultIsMinimized,
  children,
}: MinimizableContextProviderProps) => {
  const minimizableContext = useMinimizableContext();

  if (minimizableContext) {
    throw new Error('minimizable should not be nested');
  }

  const {
    value: isMinimized,
    setTrue: minimize,
    setFalse: maximize,
    setValue: setIsMinimized,
  } = useBoolean(_isMinimized || defaultIsMinimized);

  useUpdateEffect(() => {
    if (typeof _isMinimized === 'boolean') {
      setIsMinimized(_isMinimized);
    }
  }, [_isMinimized]);

  const contextValue = useGetContextValue<MinimizableContextValue>({
    isMinimized,
    minimize,
    maximize,
  });

  return (
    <MinimizableContext.Provider value={contextValue}>
      <div
        className={classNames({
          minimizable: true,
          [minimizedClass]: isMinimized,
        })}
      >
        {
          typeof children === 'function'
            ? (
              children?.({
                isMinimized,
                minimize,
                maximize,
              })
            ) : children
        }
      </div>
    </MinimizableContext.Provider>
  );
};
