import { Button, Col, Typography } from 'antd';
import { ReactElement, ReactNode } from 'react';

const { Text } = Typography;
export interface InfoComponentProps {
  type?: 'button' | 'string';
  onClick?: () => void;
  name: ReactNode;
  value?: string | string[] | number[] | undefined | ReactNode;
  span?: number;
  render?: ReactElement | (() => ReactNode);
}

export const InfoComponent = ({
  type,
  onClick,
  name,
  value = '--',
  span,
  render,
}: InfoComponentProps) => {
  if (render) {
    if (typeof render === 'function') {
      return <Col span={span}>{render()}</Col>;
    }
    return <Col span={span}>{render}</Col>;
  }
  if (type === 'button') {
    return (
      <Col span={span}>
        <Button onClick={onClick}><Text type="secondary">{name}</Text></Button>
      </Col>
    );
  }
  return (
    <Col span={span}>
      <Text type="secondary">{name}</Text>
      <div>{value}</div>
    </Col>
  );
};
