import { FormOptions, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';

export const useDeletePatientForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    reason: {
      name: 'reason',
      label: 'Reason',
    },
    delete: {
      name: 'delete',
      label: 'Delete',
    }
  }, options);

  return factory;
};
