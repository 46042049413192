import classNames from 'classnames';
import { CancelManualSubmitButtonComponent, CancelManualSubmitButtonComponentProps } from '../../uiComponent/CancelManualSubmitButtonComponent/CancelManualSubmitButtonComponent';
import { useNestedFormControlContext } from './NestedFormControlContext';

export interface NestedFormSubmitButtonComponentProps
  extends Omit<CancelManualSubmitButtonComponentProps, 'onSubmit'> {
  isLoading?: boolean;
}

export const NestedFormSubmitButtonComponent = ({
  isLoading,
  className = '',
  ...restProps
}: NestedFormSubmitButtonComponentProps) => {
  const {
    isSubmitting,
    handleOnSubmit,
    disabled,
  } = useNestedFormControlContext();

  return (
    <CancelManualSubmitButtonComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
      className={classNames({
        'nested-form-submit-button': true,
        [className]: !!className,
      })}
      onSubmit={handleOnSubmit}
      loading={isLoading || isSubmitting}
      disabled={disabled}
    />
  );
};
