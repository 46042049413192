import {
  ReactNode,
  useEffect,
} from 'react';
import { TimeTrackingEventEnum, useTimeTrackingInsert } from '../../../../uc-api-sdk';
import EnvConfig from '../../../../configs/envConfig/envConfig';
import { StorageKeyEnum, useSessionStorage } from '../../../../hooks/useSessionStorage';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useBillableTimeContext } from '../../../../contexts/BillableTimeContext/BillableTimeContext';

const HEARTBEAT_INTERVAL = EnvConfig.heartbeatIntervalTime * 1000;

export interface HeartbeatEventContainerProps {
  children: ReactNode;
}

export const HeartbeatEventContainer = ({
  children,
}: HeartbeatEventContainerProps) => {
  const { sessionId } = useBillableTimeContext();
  const [token] = useSessionStorage<string>(StorageKeyEnum.TOKEN);
  const timeTrackingInsert = useTimeTrackingInsert({});

  const sendTimeTracking = () => {
    if (sessionId) {
      const heartbeatReq = timeTrackingInsert.send({
        params: {
          event: {
            sessionId,
            timestamp: Date.now(),
            eventType: TimeTrackingEventEnum.HEARTBEAT,
          },
        },
      });
      ApiRequestHelper.tryCatch(heartbeatReq, 'Failed Heartbeat tracking!');
    } else {
      console.error('SessionId is missing for billable time!');
    }
  };

  useEffect(() => {
    if (!token || !sessionId) return undefined;
    const timer = setInterval(() => {
      sendTimeTracking();
    }, HEARTBEAT_INTERVAL);

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [sessionId, token]);

  return <div>{children}</div>;
};
