import { Modal } from 'antd';
import classNames from 'classnames';
import { map } from 'lodash';
import { ReactNode, useCallback, useState } from 'react';
import { LinkButton } from '../LinkButton/LinkButton';
import { ModalWidth880 } from '../../constants/modal';
import { PDFViewerComponent } from '../PDFViewerComponent/PDFViewerComponent';

import './UploadFileListComponent.scss';

const DEFAULT_VIEW_SIZE = 700;

export interface UploadFileListComponentProps {
  title?: ReactNode;
  fileList?: File[];
  renderFileName?: (file: File, openPreview: (file: File) => void) => ReactNode;
  className?: string;
  emptyText?: ReactNode;
  canPreview?: boolean;
}

export const UploadFileListComponent = ({
  title = 'File Viewer',
  fileList,
  renderFileName,
  className = '',
  emptyText = null,
  canPreview = true,
}: UploadFileListComponentProps) => {
  const [previewUrl, setPreviewUrl] = useState<string | undefined>();
  if (!fileList?.length) {
    return <div>{emptyText}</div>;
  }

  const handleOpenPreview = async (file: File) => {
    if (!canPreview) return;

    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result as string);
    };
    fileReader.readAsDataURL(file);
  };

  const handleClosePreview = () => {
    setPreviewUrl(undefined);
  };

  const renderPreviewContent = useCallback((previewUrl?: string) => {
    if (!previewUrl) {
      return (
        <span>Unknown</span>
      );
    }
    if (previewUrl?.includes('pdf;')) {
      return (
        <PDFViewerComponent
          fileUrl={previewUrl}
          height={DEFAULT_VIEW_SIZE}
        />
      );
    }
    if (previewUrl?.includes('image/')) {
      return (
        <img
          src={previewUrl}
          alt={previewUrl}
          height={DEFAULT_VIEW_SIZE}
        />
      );
    }
    return (
      <span>Unknown format</span>
    );
  }, []);

  return (
    <div className="upload-file-list">
      {
        map(fileList, (file, idx) => (
          <div
            key={idx}
            className={classNames({
              'upload-file-list__item': true,
              mt5: true,
              [className]: !!className,
            })}
          >
            {
              renderFileName?.(file, handleOpenPreview)
              || (
                <LinkButton
                  useBlueColor
                  underlineOnHover={canPreview}
                  onClick={() => handleOpenPreview(file)}
                >
                  {file.name}
                </LinkButton>
              )
            }
          </div>
        ))
      }
      <Modal
        title={title}
        open={!!previewUrl}
        onCancel={handleClosePreview}
        // eslint-disable-next-line no-inline-styles/no-inline-styles
        style={{ top: 10 }}
        closable
        footer={null}
        width={ModalWidth880}
        className="upload-file-list__preview-modal"
        destroyOnClose
      >
        {renderPreviewContent(previewUrl)}
      </Modal>
    </div>
  );
};
