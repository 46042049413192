import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import {
  TranscribedLabResult, TranscribeResourceType,
  useTranscribingJobPostProcess
} from '../../../../uc-api-sdk';
import { HemoglobinA1cTemplateId } from '../../../transcribing/constants';
import { LLMLabResultTableItem } from '../type';

export const useLLMLabResultRecordCheck = () => {
  const { info: { id } = {} } = usePatientContext();
  const postProcessInfo = useTranscribingJobPostProcess({});

  const checkDuplicateConflict = (
    processedResult: TranscribedLabResult[],
  ) => {
    if (processedResult.length === 0) {
      return;
    }
    postProcessInfo.send({
      params: {
        request: {
          patientId: id,
          resourceType: TranscribeResourceType.LAB_RESULT,
          processedResult,
        }
      }
    });
  };

  const checkIfCheckedRecordValid = (record: LLMLabResultTableItem) => (
    !!record.dateCollected
    && (
      record.templateId === HemoglobinA1cTemplateId
        ? record.value : true
    )
  );

  return {
    postProcessInfo,
    checkDuplicateConflict,
    checkIfCheckedRecordValid,
  };
};
