import { FC } from 'react';
import { useLoggedInUserFromContext } from '../../contexts/loggedInUserContext/LoggedInUserContext';
import Avatar, { AvatarProps } from '../Avatar/Avatar';

export interface CurrentUserAvatarComponentProps {
  size?: AvatarProps['size'];
}

export const CurrentUserAvatarComponent: FC<CurrentUserAvatarComponentProps> = ({
  size,
}) => {
  const { avatarInfo } = useLoggedInUserFromContext();
  return (
    <Avatar
      src={avatarInfo?.url || undefined}
      size={size}
    />
  );
};
