import { useEffect, useRef, useState } from 'react';
import {
  every,
  filter, map, partialRight
} from 'lodash';
import { Divider } from 'antd';
import dayjs from 'dayjs';
import { TranscribedLabResult, TranscribeJob, TranscribedLabResultItem } from '../../../../../uc-api-sdk';
import { HemoglobinA1cTemplateId } from '../../../../transcribing/constants';
import { LLMResultCardListComponent } from '../../../component/LLMResultCardListComponent/LLMResultCardListComponent';
import { LLMLabResultOtherFormComponent } from '../LLMLabResultOtherFormComponent/LLMLabResultOtherFormComponent';
import { FixedComponent } from '../../../../../uiComponent/FixedComponent/FixedComponent';
import { FixedChildComponent } from '../../../../../uiComponent/FixedComponent/FixedChildComponent';
import { LLMReviewButtonComponent } from '../../../component/LLMReviewButtonComponent/LLMReviewButtonComponent';
import useDebounce from '../../../../../hooks/useDebounce/useDebounce';
import { LLMLabResultA1CFormComponent } from '../LLMLabResultA1CFormComponent/LLMLabResultA1CFormComponent';
import { LLMComponentWrapper } from '../../../component/LLMComponentWrapper/LLMComponentWrapper';
import { LLMUploadFileInfo } from '../../../container/LLMUploadContainer/LLMUploadContainer';
import { LLMUploadFileInfoComponent } from '../../../component/LLMUploadFileInfoComponent/LLMUploadFileInfoComponent';
import { Icon } from '../../../../../icons/Icon';
import { DOUBLE_ARROW_DOWN } from '../../../../../assets/commonIconInfo';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';
import { useLLMLabResultSource } from '../../hook/useLLMLabResultSource';
import { LLMLabResultNavigatorComponent, LLMLabResultNavigatorComponentProps } from '../LLMLabResultNavigatorComponent/LLMLabResultNavigatorComponent';
import { LLMNavigationBarComponent } from '../../../component/LLMNavigationBarComponent/LLMNavigationBarComponent';

import './LLMTranscribeLabResultComponent.scss';
import { MISSING_ERROR_CLSNAME } from '../../createLLMLabResultColumns';
import { scrollToFirstError } from '../../../../../helpers/error/scrollToFirstError';
import { LLMLabResultTableItem, LLMLabResultViewSourceData } from '../../type';
import { LLMTranscribeLabResultUploadGuide } from './LLMTranscribeLabResultUploadGuide';
import { useLLMLabResultRecordCheck } from '../../hook/useLLMLabResultCheck';

export interface LLMTranscribeLabResultBaseProps {
  data?: TranscribeJob<TranscribedLabResult>;
  uploadFileInfoList?: LLMUploadFileInfo[];
  onSubmit?: (values: TranscribedLabResult[]) => void;
  onBack?: () => void;
  onReprocess?: () => void;
  disabled?: boolean;
}

export interface LLMTranscribeLabResultComponentProps
  extends Omit<LLMTranscribeLabResultBaseProps, 'data' | 'onSubmit'> {
  onSubmit: (values: TranscribedLabResult[], jobId: string) => void;
  patientId: string;
}

const LLMTranscribeLabResultBase = ({
  data,
  uploadFileInfoList,
  onSubmit,
  onBack,
  onReprocess,
  disabled,
}: LLMTranscribeLabResultBaseProps) => {
  const [
    results,
    setResults,
  ] = useState<TranscribedLabResult[] | undefined>();
  const {
    makeSourceData,
    makeSourceNavigatorData
  } = useLLMLabResultSource(results || [], uploadFileInfoList);
  const {
    checkIfCheckedRecordValid
  } = useLLMLabResultRecordCheck();
  const updatedResults = useRef<TranscribedLabResult[]>([]);
  const checkedList = useRef<boolean[]>([]);

  const handleChangeValues = (
    values: TranscribedLabResultItem[],
    idx: number,
  ) => {
    if (updatedResults.current?.[idx]) {
      updatedResults.current[idx].results = values;
    }
  };

  const handleSetCheckedList = (list: boolean[]) => {
    checkedList.current = list;
  };

  // make sure required field (ie dateCollected) is filled
  const validateResults = (
    results: TranscribedLabResult[],
  ) => (
    results.every((r) => {
      const checkedResults = filter(
        r.results,
        (item: LLMLabResultTableItem) => item.isChecked
      );
      return every(
        checkedResults,
        checkIfCheckedRecordValid
      );
    })
  );

  const handleCompleteReview = () => {
    const toSubmitResults = filter(
      updatedResults.current as TranscribedLabResult[],
      (r, idx) => (
        !!checkedList.current[idx]
        && !!r.results?.length
      )
    );
    if (!validateResults(toSubmitResults)) {
      scrollToFirstError(`.llm-transcribe-labresult .${MISSING_ERROR_CLSNAME}`);
      return;
    }
    onSubmit?.(toSubmitResults);
  };

  const debouncedHandleCompleteReview = useDebounce(
    handleCompleteReview,
    1000,
    [handleCompleteReview],
    { leading: true }
  );

  const processResultItems = (
    processedResults: (TranscribedLabResult | null | undefined)[],
  ) => (
    // add dateCollected to each result item, because dateCollected at top level
    map(processedResults, (r) => {
      const { results, dateCollected } = r || {};
      const makeItem = (item: TranscribedLabResultItem) => {
        const isHemoA1C = r?.templateId === HemoglobinA1cTemplateId;
        return {
          ...item,
          dateCollected: (
            dateCollected
              ? dayjs(dateCollected)
              : null
          ),
          // fill existingValue if duplicate
          existingValue: item.duplicate ? item.value : item.existingValue,
          isChecked: isHemoA1C ? !!item.value : true,
        } as TranscribedLabResultItem;
      };
      return {
        ...r,
        results: map(results, makeItem),
      };
    }).flat()
  );

  const processResult = (
    dataProcessedResult?: TranscribedLabResult[],
  ) => {
    // move hemoA1C to top
    const hemoA1CResults = [] as TranscribedLabResult[];
    const others = [] as TranscribedLabResult[];
    dataProcessedResult?.forEach((item) => {
      if (item.templateId === HemoglobinA1cTemplateId) {
        hemoA1CResults.push(item);
      } else {
        others.push(item);
      }
    });
    // group hemoA1C results
    const processedList = [...others];
    if (hemoA1CResults.length > 0) {
      const A1CResults = map(hemoA1CResults, (r) => r.results).flat();
      const hemoA1C = {
        ...hemoA1CResults[0],
        results: map(A1CResults, (item) => ({ ...item, templateId: HemoglobinA1cTemplateId })),
      } as TranscribedLabResult;
      processedList.unshift(hemoA1C);
    }
    const processedResultsWithDatedItem = processResultItems(processedList);
    return processedResultsWithDatedItem as TranscribedLabResult[];
  };

  const renderSourceNavigator = (
    mappedSource: LLMLabResultViewSourceData[],
    onClick: LLMLabResultNavigatorComponentProps['onClick'],
  ) => (
    <LLMLabResultNavigatorComponent
      navigatorData={makeSourceNavigatorData(mappedSource)}
      onClick={onClick}
    />
  );

  useEffect(() => {
    const processedResults = processResult(data?.processedResult || []);
    setResults(processedResults);
    updatedResults.current = [...processedResults];
    checkedList.current = map(processedResults, (item) => !!item);
  }, []);

  if (!results?.length) {
    return null;
  }

  return (
    <FixedComponent className="llm-transcribe-labresult">
      <FixedChildComponent className="mb20">
        <LLMNavigationBarComponent
          onBack={onBack}
          onReprocess={onReprocess}
        />
        <LLMUploadFileInfoComponent
          uploadFileInfoList={uploadFileInfoList}
        />
        <Divider>
          <Icon info={DOUBLE_ARROW_DOWN} />
        </Divider>
        <TextComponent bold>
          Review, Select, then Import
        </TextComponent>
        <TextComponent color="gray2">
          Lab results have been extracted from the uploaded files.
          Review them with original files, then select which to import to patient profile:
        </TextComponent>
        <LLMResultCardListComponent
          data={results || []}
          renderTitle={(item) => item.templateName}
          getSourceData={makeSourceData}
          getSourceNavigator={renderSourceNavigator}
          defaultCheckList={checkedList.current}
          onCheckListChange={handleSetCheckedList}
          defaultDisabledList={map(results, (item) => !item.results?.length)}
        >
          {
            ({
              item,
              isChecked,
              isDisabled: isCardDisabled,
              index
            }) => {
              const isHemoA1C = item.templateId === HemoglobinA1cTemplateId;
              const isDisabled = (
                disabled || !item.results?.length || !isChecked || isCardDisabled
              );
              const onChange = partialRight(handleChangeValues, index);
              const FormComponent = (
                isHemoA1C
                  ? LLMLabResultA1CFormComponent
                  : LLMLabResultOtherFormComponent
              );

              return (
                <FormComponent
                  key={index}
                  templateId={item.templateId}
                  templateName={item.templateName}
                  dataSource={item.results || []}
                  onChange={onChange}
                  disabled={isDisabled}
                />
              );
            }
          }
        </LLMResultCardListComponent>
      </FixedChildComponent>
      <FixedChildComponent isFixed className="border-t">
        <LLMReviewButtonComponent
          onClick={debouncedHandleCompleteReview}
          disabled={disabled}
        />
      </FixedChildComponent>
    </FixedComponent>
  );
};

export const LLMTranscribeLabResultComponent = ({
  patientId,
  onSubmit,
  disabled,
}: LLMTranscribeLabResultComponentProps) => (
  <LLMComponentWrapper
    patientId={patientId}
    guide={<LLMTranscribeLabResultUploadGuide />}
  >
    {
      ({
        transcribeInfo,
        goBackToUpload,
        reprocessJob
      }) => (
        <LLMTranscribeLabResultBase
          data={transcribeInfo.result as TranscribeJob<TranscribedLabResult>}
          onSubmit={partialRight(onSubmit, transcribeInfo.jobId || '')}
          onBack={goBackToUpload}
          onReprocess={reprocessJob}
          uploadFileInfoList={transcribeInfo.uploadFileInfoList}
          disabled={disabled}
        />
      )
    }
  </LLMComponentWrapper>
);
