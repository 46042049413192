import { ChartingStatusEnum } from '../../../../../enumComponent/ChartingStatusEnumComponent/ChartingStatusEnum';
import { CheckInButtonContainer } from '../../../../worklist/container/CheckInButtonContainer/CheckInButtonContainer';
import {
  ChartingStatusEnumComponent
} from '../../../../../enumComponent/ChartingStatusEnumComponent/ChartingStatusEnumComponent';
import { PrintCarePlanPreviewButtonContainer } from '../../../../print/PrintCarePlan/container/PrintCarePlanPreviewButtonContainter/PrintCarePlanPreviewButtonContainter';

import './VisitChartingComponent.scss';
import { ClinicEventsService } from '../PatientVisitsItemComponent/ClinicEventsService';

export interface VisitChartingComponentProps {
  visit: ClinicEventsService;
  value: ChartingStatusEnum | undefined | null;
  checkedIn?: boolean;
  disabled?: boolean;
}

export const VisitChartingComponent = ({
  visit,
  value,
  checkedIn,
  disabled,
}: VisitChartingComponentProps) => (
  value !== ChartingStatusEnum.CHARTING
    ? (
      <CheckInButtonContainer
        visit={visit}
        checkedIn={checkedIn}
        disabled={disabled}
      >
        <ChartingStatusEnumComponent
          value={value}
        />
      </CheckInButtonContainer>
    ) : (
      <PrintCarePlanPreviewButtonContainer
        key={visit.id}
        visitId={visit.id}
        buttonText={(<ChartingStatusEnumComponent value={value} />)}
        buttonType="link"
        className="no-style-btn underline"
      />
    )
);
