import React, { useCallback } from 'react';
import { VitalsMonitoringBGTableComponent, VitalsMonitoringBGTableComponentProps } from './VitalsMonitoringBGTableComponent';
import { useVitalsMonitoringBGToTable } from '../../../../hook/useVitalsMonitoringBGToTable/useVitalsMonitoringBGToTable';
import { InputType } from '../../../../../Input/types';
import { useVitalsMonitoringBGTableToSchedule } from '../../../../hook/useVitalsMonitoringBGTableToSchedule/useVitalsMonitoringBGTableToSchedule';
import { WeeklyMealSchedule } from '../../../../../../uc-api-sdk';

export interface VitalsMonitoringBGTableProcessorComponentProps extends
  InputType<WeeklyMealSchedule> {
}

export const VitalsMonitoringBGTableProcessorComponent = ({
  value,
  onChange,
}: VitalsMonitoringBGTableProcessorComponentProps) => {
  const processedDate = useVitalsMonitoringBGToTable(value);
  const { transform } = useVitalsMonitoringBGTableToSchedule();

  const handleChange = useCallback<VitalsMonitoringBGTableComponentProps['onChange']>((newValue) => {
    const result = transform(newValue);
    onChange?.(result);
  }, [onChange]);

  return (
    <VitalsMonitoringBGTableComponent
      value={processedDate}
      onChange={handleChange}
    />
  );
};
