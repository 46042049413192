import { Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { makeAddressString } from '../../helpers/address';
import { Address } from '../../uc-api-sdk';
import { AddressFormValues } from '../AddressFormItemComponent/AddressFormItemComponent';
import { ClipboardButtonComponent } from '../ClipboardButtonComponent/ClipboardButtonComponent';

export interface CopyAddressButtonComponentProps {
  value: Address | AddressFormValues
}

export const CopyAddressButtonComponent = ({
  value,
}: CopyAddressButtonComponentProps) => (
  <ClipboardButtonComponent
    value={makeAddressString(value)}
  >
    <Button>
      <CopyOutlined />
      Copy Address
    </Button>
  </ClipboardButtonComponent>
);
