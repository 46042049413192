import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useEffectOnce } from 'usehooks-ts';
import { Modal } from 'antd';
import useDebounce from '../../useDebounce/useDebounce';
import { useLogout } from '../../../features/auth/hooks/useLogout';
import { expiredTokenRegex } from '../../../constants/regex/session';

export const useAxiosResponseInterceptor = () => {
  const { onLogout } = useLogout();

  // auto-logout when token for requests are invalid / expired
  const handleExpiredToken = useDebounce((errorMessage: string) => {
    // make sure to show only 1 modal
    Modal.destroyAll();
    // axiosError.response?.data: 'Session invalid or expired, please login again.'
    Modal.error({
      content: errorMessage,
      centered: false,
    });
    // evict user
    onLogout();
  });

  const handleAxiosError = (axiosError: AxiosError) => {
    if (
      axiosError.response?.data
      && expiredTokenRegex.test(axiosError.response?.data as string)
    ) {
      handleExpiredToken(axiosError.response?.data as string);
      return axiosError;
    }
    return Promise.reject(axiosError);
  };

  const parseXSessionToken = (
    config: AxiosRequestConfig,
  ) => {
    let token;
    try {
      // get token directly from sessionStorage
      // to override stale token when useDebounce with useReqHook
      token = JSON.parse(sessionStorage.getItem('token') || 'null');
    } catch (error) {
      // ignore error
      return config;
    }
    if (!token) {
      return config;
    }
    return {
      ...config,
      headers: {
        ...(config.headers || {}),
        'x-session-token': token,
      },
    };
  };

  useEffectOnce(() => {
    axios.interceptors.request.use(
      (config) => {
        const parsedConfig = parseXSessionToken(config);
        return parsedConfig;
      }
    );

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (axios.isAxiosError(error)) {
          return handleAxiosError(error);
        }
        return Promise.reject(error);
      }
    );
  });
};
