/* eslint-disable react/jsx-props-no-spreading */
import { Checkbox, Select, SelectProps } from 'antd';
import React, { FC } from 'react';

import './CheckboxSelectComponent.scss';

export type CheckboxSelectComponentProps = SelectProps

const renderOption = ({
  isSelected,
}: {
  isSelected: boolean,
}) => (
  <Checkbox data-testid="checkbox" checked={isSelected} />
);

const CheckboxSelectComponent: FC<CheckboxSelectComponentProps> = (
  props,
) => (
  <div className="checkbox-select-component" data-testid="checkbox-select-component">
    <Select
      {...props}
      mode="multiple"
      popupClassName="checkbox-select-component__popup"
      menuItemSelectedIcon={renderOption}
    />
  </div>
);

export default CheckboxSelectComponent;
