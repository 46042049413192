import { useMemo } from 'react';
import { TaskRepeatEnum } from '../../../../../uc-api-sdk';

export interface VitalsMonitoringExerciseFrequencyComponentProps {
  target?: number;
  repeat?: TaskRepeatEnum;
}

// steps/ day
export const VitalsMonitoringExerciseFrequencyComponent = ({
  target = 0,
  repeat,
}: VitalsMonitoringExerciseFrequencyComponentProps) => {
  const dayFactor = useMemo((): number => {
    switch (repeat) {
      case TaskRepeatEnum.DAILY:
        return 1;
      case TaskRepeatEnum.WEEKLY:
        return 7;
      case TaskRepeatEnum.MONTHLY:
        return 30;
      default:
        return -1;
    }
  }, [repeat]);

  return (
    <div className="flex">
      {
        dayFactor !== -1
          ? (
            <>
              <span>{Math.floor(target / dayFactor)}</span>
              <span>steps/ day</span>
            </>
          ) : 'Unknown unit'

      }
    </div>
  );
};
