import { ColumnType } from 'antd/lib/table';
import { filter, map } from 'lodash';
import { AccountHelper } from '../../../../helpers/account';
import {
  PriorityLevelEnum,
  SmartAlertTaskStatus,
  Note,
  ComplianceAlert,
  ComplianceAlertTypeEnum,
  RoleTypeEnum,
} from '../../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { SmartAlertTaskStatusEnumComponent } from '../../../../enumComponent/SmartAlertTaskStatusComponent/SmartAlertTaskStatusComponent';
import { createEmployeeSearchFilter } from '../../../table/filter/EmployeeSearchFilter';
import {
  PriorityLevelEnumComponent
} from '../../../../enumComponent/PriorityLevelEnumComponent/PriorityLevelEnumComponent';
import { AlertNotesComponent } from '../../../../uiComponent/AlertNotesComponent/AlertNotesComponent';
import { LabelWithValueComponent } from '../../../../uiComponent/LabelWithValueComonent/LabelWithValueComponent';
import { ComplianceAlertTypeEnumComponent, ComplianceAlertTypeOthers } from '../../../../enumComponent/SmartAlertTypeEnumComponent/SmartAlertTypeEnumComponent';
import { MedicalAlertPriorityLevelComponent } from '../MedicalAlertPriorityLevelComponent/MedicalAlertPriorityLevelComponent';
import { MedicalAlertTaskStatusCellComponent } from '../MedicalAlertTableComponent/MedicalAlertTaskStatusCellComponent';

export const createColumns = () => ({
  createdAt: (): ColumnType<ComplianceAlert> => ({
    title: 'Generated time',
    sorter: true,
    render: (value: string) => (
      <DisplayDateComponent value={value} format="DATE_COMMA_TIME_AM_PM" />
    ),
    defaultSortOrder: 'descend',
  }),
  priority: (): ColumnType<ComplianceAlert> => ({
    title: 'Priority',
    render: (value) => (<MedicalAlertPriorityLevelComponent value={value} />),
    filters: map(filter(PriorityLevelEnum, it => it !== PriorityLevelEnum.level), (value) => ({
      text: (<PriorityLevelEnumComponent value={value} />),
      value,
    })),
  }),
  alertType: (): ColumnType<ComplianceAlert> => ({
    title: 'Alert',
    render: (value) => <ComplianceAlertTypeEnumComponent value={value} />,
    filters: map([
      ComplianceAlertTypeEnum.NEW_PATIENT_ENGAGEMENT_14_DAY,
      ComplianceAlertTypeEnum.NEW_PATIENT_ENGAGEMENT_1_DAY,
      ComplianceAlertTypeEnum.INACTIVE_14_DAY,
      ComplianceAlertTypeEnum.A1C_DUE,
      ComplianceAlertTypeEnum.VISIT_DUE_INIT,
      ComplianceAlertTypeOthers,
    ], (value) => ({
      text: (<ComplianceAlertTypeEnumComponent value={value} />),
      value,
    })),
  }),
  notes: (): ColumnType<ComplianceAlert> => ({
    title: 'Notes',
    width: '14%',
    render: (notes?: Note[]) => (
      <div className="medical-alert-status-component">
        <LabelWithValueComponent
          showTooltip
          value={<AlertNotesComponent notes={notes} />}
        />
      </div>
    ),
  }),
  status: (): ColumnType<ComplianceAlert> => ({
    title: 'Status',
    render: (value) => <MedicalAlertTaskStatusCellComponent value={value} />,
    filterMultiple: false,
    filters: map([
      SmartAlertTaskStatus.TODO,
      SmartAlertTaskStatus.DONE,
      SmartAlertTaskStatus.MUTE,
    ], (value) => ({
      text: (<SmartAlertTaskStatusEnumComponent value={value} />),
      value,
    })),
  }),
  closedBy: (): ColumnType<ComplianceAlert> => ({
    title: 'Closed by',
    render: (v, alert) => (
      <DisplayOrEmptyComponent
        value={AccountHelper.getFullName(alert.closedByUser)}
      />
    ),
    ...createEmployeeSearchFilter([
      RoleTypeEnum.MA,
      RoleTypeEnum.PROVIDER,
      RoleTypeEnum.RD,
      RoleTypeEnum.HC,
      RoleTypeEnum.CA,
      RoleTypeEnum.NP,
    ]),
  }),
  closedAt: (): ColumnType<ComplianceAlert> => ({
    title: 'Close time',
    sorter: true,
    render: (value, alert) => (
      <DisplayDateComponent
        value={alert.snoozedDate ? alert.snoozedDate : alert.closeAt}
        format="DATE_COMMA_TIME_AM_PM"
      />
    ),
  }),
});
