import { Tabs, TabsProps } from 'antd';
import classNames from 'classnames';
import './BubbleTabComponent.scss';

export interface BubbleTabComponentProps extends TabsProps {
}

export const BubbleTabComponent = ({
  className = '',
  ...props
}: BubbleTabComponentProps) => (
  <Tabs
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    className={classNames({
      'bubble-tab-component': true,
      [className]: !!className,
    })}
  />
);
