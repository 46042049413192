import { AccountHelper } from '../helpers/account';
import { PatientAssignees, RoleTypeEnum } from '../uc-api-sdk';

export interface PatientAssigneesServiceArg {
  patientAssignees: PatientAssignees;
}

export interface Assignee {
  role: RoleTypeEnum;
  id: string;
}

export class PatientAssigneesService {
  private patientAssignees: PatientAssignees;

  constructor(patientAssignees: PatientAssignees) {
    this.patientAssignees = patientAssignees;
  }

  public getRdAssigneesId() {
    const RD = this.patientAssignees.assignedToRD;
    return RD ? [RD] : [];
  }

  public getCaAssigneesId() {
    const CA = this.patientAssignees.assignedToCA;
    return CA ? [CA] : [];
  }

  public getAllAssigneesId() {
    return [...this.getRdAssigneesId(), ...this.getCaAssigneesId()];
  }

  public getAssignees() {
    const assignees: Assignee[] = [];
    const RD = this.patientAssignees.assignedToRD;
    const CA = this.patientAssignees.assignedToCA;
    if (RD) {
      assignees.push({
        role: RoleTypeEnum.RD,
        id: RD,
      });
    }
    if (CA) {
      assignees.push({
        role: RoleTypeEnum.CA,
        id: CA,
      });
    }
    return assignees;
  }

  public getAssignedCaName() {
    return AccountHelper.getFullName(this.patientAssignees.assignedToCAUser);
  }

  public getAssignedRdName() {
    return AccountHelper.getFullName(this.patientAssignees.assignedToRDUser);
  }
}
