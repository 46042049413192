import { message } from 'antd';
import { FC, useCallback } from 'react';
import { useFetchComponentContext } from '../../../../contexts/FetchComponentContext/FetchComponentContext';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { Ticket, useTicketGet, useTicketResolveTicket } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { BillableTimeContainer } from '../../../billableTime/container/BillableTimeContainer/BillableTimeContainer';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';
import { BillableComponentEnum } from '../../../billableTime/hook/useBillableEventTracker/useBillableEventTrackerComp';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { TicketCommentComponentProps } from '../../component/TicketCommentComponent/TicketCommentComponent';
import { SubmitValue } from '../../component/TicketResolveAndCommentFormComponent/TicketResolveAndCommentFormComponent';
import { TicketViewComponent } from '../../component/TicketViewComponent/TicketViewComponent';
import { TicketTexts } from '../../constant/text';
import {
  useTicketAddCommentRequest,
  useTicketDeleteCommentRequest,
  useTicketEditCommentRequest,
  useTicketGetCommentListByTicketIdRequest
} from '../../hook/useTicketComment';

export interface ViewTicketContainerProps {
  ticketId: string;
  patientInputDisable?: boolean;
}

export const TicketViewContainer: FC<ViewTicketContainerProps> = ({
  ticketId,
  patientInputDisable = false,
}) => {
  const info = useFetchComponentContext();
  const resolveTicket = useTicketResolveTicket({});
  const updateHook = useUpdate('TICKET_RESOLVED');
  const { send } = useBillableTimeInterventionComp();
  const { send: sendMixPanel } = useMixpanelContext();

  const ticketRequest = useTicketGet({
    params: {
      id: ticketId,
    },
  });

  const commentList = useTicketGetCommentListByTicketIdRequest(ticketId);

  const addCommentRequest = useTicketAddCommentRequest();

  const addComment = useCallback(async (comment: string) => {
    const addCommentResult = await addCommentRequest.send(ticketId, comment);

    if (addCommentResult?.code === 200) {
      message.success('Comment added.');
      commentList.refetch();
      // To update activity history
      ticketRequest.refetch();
    } else {
      throw new Error('Failed to add comment!');
    }
  }, [ticketId, ticketRequest]);

  const resolve = useCallback(async ({
    comment,
    generateCareNote,
  }: { comment: string, generateCareNote: boolean }) => {
    sendMixPanel({ event: MixpanelEvents.TasksResolveTask });
    const resolveResult = await resolveTicket.send({
      params: {
        request: {
          comment: generateCareNote ? `Description: ${ticketRequest?.data?.data?.description}\n`.concat(`Resolve note: ${comment}`)
            : comment,
          generateCareNote,
        },
        id: ticketId,
      },
    });
    if (resolveResult?.code === 200) {
      message.success(TicketTexts.resolved);
      // add useUpdateListener('TICKET_RESOLVED', xx);
      // to close the drawer, update counts and table
      updateHook.updateValue();
    } else if (resolveResult?.code === 1001) {
      message.error(TicketTexts.error1001);
    } else {
      message.warning(TicketTexts.error);
    }
  }, [resolveTicket, ticketId]);

  const handleSubmit = async (v: SubmitValue) => {
    if (v.isCommentOnly) {
      sendMixPanel({ event: MixpanelEvents.TasksCommentTask });
      await addComment(v.comment);
    } else {
      resolve({
        comment: v.comment,
        generateCareNote: v.generateCareNote
      });
    }
  };

  const editCommentRequest = useTicketEditCommentRequest();

  const handleEditComment = useCallback<
  Exclude<TicketCommentComponentProps['onEdit'], undefined>
  >(async (id, value) => {
    sendMixPanel({ event: MixpanelEvents.TasksEditTask });
    const update = await editCommentRequest.send(id, value);

    if (update?.code === 200) {
      message.success(TicketTexts.editSuccess);
      commentList.refetch();
      // To update activity history
      ticketRequest.refetch();
    } else {
      message.warning(TicketTexts.error);
    }
  }, [ticketId, ticketRequest]);

  const deleteCommentRequest = useTicketDeleteCommentRequest();

  const handleDeleteComment = async (id: string) => {
    sendMixPanel({ event: MixpanelEvents.TasksDeleteTask });
    const remove = await deleteCommentRequest.send(id);

    if (remove?.code === 200 && remove.data === 'success') {
      message.success(TicketTexts.deleteSuccess);
      commentList.refetch();
      // To update activity history
      ticketRequest.refetch();
    } else {
      message.warning(TicketTexts.error);
    }
    return deleteCommentRequest;
  };

  const handleEditTicketSucceeded = () => {
    ticketRequest.refetch();
    commentList.refetch();
    info?.refetch();
  };

  return (
    <FetchComponent info={ticketRequest}>
      {(value) => {
        const data: Ticket = value?.rawValue?.data;
        const handleBillableTimeResolveTask = () => {
          send({
            patientId: data.patientUser?.id ?? undefined,
            component: BillableTimeInterventionComponentEnum.TaskAssignment,
            detail: BillableTimeInterventionDetailEnum.TaskCreateTaskEditVisitResolve,
          });
        };
        return (
          <>
            <BillableTimeContainer
              key={data.id}
              patientId={data.patientUser?.id ?? undefined}
              comp={BillableComponentEnum.Task}
            />
            <TicketViewComponent
              data={{
                comments: (commentList.dataObj?.data || []),
                ticketInfo: data,
              }}
              onResolve={handleBillableTimeResolveTask}
              onSubmit={handleSubmit}
              refetch={handleEditTicketSucceeded}
              handleDeleteComment={handleDeleteComment}
              handleEditComment={handleEditComment}
              patientInputDisable={patientInputDisable}
            />
          </>
        );
      }}
    </FetchComponent>
  );
};
