import dayjs from 'dayjs';
import {
  createContext, ReactNode, useContext, useMemo
} from 'react';
import { functionNotImplYet } from '../../../contexts/helper';
import { useLoggedInUserFromContext } from '../../../contexts/loggedInUserContext';
import { StorageKeyEnum, useSessionStorage } from '../../../hooks';
import { FromDateToDateDayjs } from '../../../types/date';
import { MedicalAlertCategoryEnum } from '../../../uc-api-sdk';

const contextNotImplementedYet = functionNotImplYet('MedicalAlertDashboardContext');

interface MedicalAlertDashboardContextValue {
  selectedClinicId: string[] | undefined;
  setSelectedClinicId: (id: string[]) => void;
  selectedAlertType: MedicalAlertCategoryEnum[] | undefined;
  setSelectedAlertType: (alertType: MedicalAlertCategoryEnum[]) => void;
  selectedFromToDate: FromDateToDateDayjs | undefined;
  setSelectedFromToDate: (fromToDate: FromDateToDateDayjs) => void;
}

const MedicalAlertDashboardContext = createContext<MedicalAlertDashboardContextValue>({
  selectedClinicId: [],
  setSelectedClinicId: contextNotImplementedYet('setSelectedClinicId'),
  selectedAlertType: [],
  setSelectedAlertType: contextNotImplementedYet('setSelectedAlertType'),
  selectedFromToDate: undefined,
  setSelectedFromToDate: contextNotImplementedYet('setSelectedFromToDate'),
});

export interface MedicalAlertDashboardContextProviderProps {
  children: ReactNode;
}

export const MedicalAlertDashboardContextProvider = ({
  children,
}: MedicalAlertDashboardContextProviderProps) => {
  const { isProvider, userInfo } = useLoggedInUserFromContext();
  const { defaultClinicId } = userInfo || {};
  const [
    selectedClinicId,
    setSelectedClinicId,
  ] = useSessionStorage<string[]>(
    StorageKeyEnum.SELECTED_MEDICAL_ALERT_DASHBOARD_CLINIC,
    defaultClinicId ? [defaultClinicId] : undefined
  );

  const [
    selectedAlertType,
    setSelectedAlertType,
  ] = useSessionStorage<MedicalAlertCategoryEnum[]>(
    StorageKeyEnum.SELECTED_MEDICAL_ALERT_DASHBOARD_ALERT_TYPE,
    isProvider
      ? [MedicalAlertCategoryEnum.PROVIDER]
      : [MedicalAlertCategoryEnum.PROVIDER, MedicalAlertCategoryEnum.CLINICAL]
  );

  const [
    selectedFromToDate,
    setSelectedFromToDate,
  ] = useSessionStorage<FromDateToDateDayjs>(
    StorageKeyEnum.SELECTED_MEDICAL_ALERT_DASHBOARD_FROM_TO_DATE,
    {
      fromDate: dayjs().add(-7, 'days'),
      toDate: dayjs(),
    }
  );

  const value = useMemo<MedicalAlertDashboardContextValue>(() => ({
    selectedClinicId,
    setSelectedClinicId,
    selectedAlertType,
    setSelectedAlertType,
    selectedFromToDate,
    setSelectedFromToDate,
  }), [
    selectedClinicId,
    setSelectedClinicId,
    selectedAlertType,
    setSelectedAlertType,
    selectedFromToDate,
    setSelectedFromToDate
  ]);

  return (
    <MedicalAlertDashboardContext.Provider value={value}>
      {children}
    </MedicalAlertDashboardContext.Provider>
  );
};

export const useMedicalAlertDashboardContext = () => {
  const contextValue = useContext(MedicalAlertDashboardContext);
  return contextValue;
};
