import { Timeline } from 'antd';
import useBoolean from '../../../hooks/useBoolean/useBoolean';
import { MedicationHistoryItem } from '../../../uc-api-sdk';
import { DisplayDateComponent } from '../../DisplayComponent/DisplayDateComponent';
import { DefaultExtendableDrawers } from '../../ExtendableDrawerComponent/controller/DefaultExtendableDrawers';
import { ExtendableDrawerComponent } from '../../ExtendableDrawerComponent/ExtendableDrawerComponent';
import './MedicationHistoryDrawerComponent.scss';
import { ExtendableDrawerButtonComponent } from '../../ExtendableDrawerComponent/component/ExtendableDrawerButtonComponent';

const DEFAULT_DRAWER_ID = DefaultExtendableDrawers.viewMedication;

export interface MedicationHistoryDrawerComponentProps {
  name: string | undefined,
  histories?: MedicationHistoryItem[] | null;
}

export const MedicationHistoryDrawerComponent = ({
  name,
  histories = [],
}: MedicationHistoryDrawerComponentProps) => {
  const {
    value: open,
    toggle: toggleOpen,
  } = useBoolean(false);
  return (
    <>
      <ExtendableDrawerButtonComponent
        buttonText="History"
        buttonProps={{
          type: 'text',
        }}
        onClick={toggleOpen}
      />
      <ExtendableDrawerComponent
        id={DEFAULT_DRAWER_ID}
        className="historyDrawerComponent"
        footer={null}
        placement="right"
        width={520}
        open={open}
        onClose={toggleOpen}
      >
        <div className="fs2 mb20 b7">{name}</div>
        <Timeline mode="left">
          {histories?.map((h, index) => (
            <Timeline.Item className={index === 0 ? 'blue' : 'grey'} key={h.modifiedAt?.toString()}>
              {index === 0 && <div>Latest update</div>}
              <div className={`historyTime mb5 ${index === 0 ? 'b6' : ''}`}>
                <DisplayDateComponent
                  value={h?.modifiedAt}
                  format="MEDICATION_HISTORY_DATE_FORMAT"
                />
              </div>
              <div>
                {histories.length === 1 || index === histories.length - 1
                  ? h?.newValue
                  : `Changed the ${h?.field} from ${h?.originalValue} to ${h?.newValue}`}

              </div>
            </Timeline.Item>
          ))}
        </Timeline>
      </ExtendableDrawerComponent>
    </>
  );
};
