import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import ModalButton from '../../../../uiComponent/ModalButton/ModalButton';
import { DischargeModalContentComponent } from '../DischargeModalContentComponent/DischargeModalContentComponent';

export interface DischargeModalButtonComponentProps {
  patientInfo: PatientInfo;
}

export const DischargeModalButtonComponent = ({
  patientInfo,
}: DischargeModalButtonComponentProps) => (
  <ModalButton
    button="Discharge Patient"
    buttonType="link"
    destroyOnClose
    buttonClassName="discharge-patient-button"
  >
    {
      ({ close }) => (
        <DischargeModalContentComponent
          patientInfo={patientInfo}
          onSubmit={close}
          onCancel={close}
        />
      )
    }
  </ModalButton>
);
