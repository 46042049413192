import { Dayjs } from 'dayjs';
import { getDaysToExpiration } from '../../uiComponent/ExpirationStatusComponent/helper';

export const REMIND_DAYS_TO_EXPIRATION = 14;

export const checkShouldRenewPriorAuth = (expirationDate?: Dayjs) => {
  const daysToExpiration = getDaysToExpiration(expirationDate);
  if (!expirationDate || daysToExpiration === undefined) {
    return false;
  }
  return daysToExpiration >= 0 && daysToExpiration <= REMIND_DAYS_TO_EXPIRATION;
};
