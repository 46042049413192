import { Icd10Billable } from '../../../../uc-api-sdk';

export interface ICDCodeDisplayComponentProps extends Icd10Billable {}

export const ICDDisplayComponent = ({
  code,
  title,
}: ICDCodeDisplayComponentProps) => (
  <span>
    {code}
    {': '}
    {title}
  </span>
);
