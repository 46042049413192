import { useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { SelectType } from '../../features/Input/types';
import { useScrollPagination } from '../../hooks/useScrollPagination/useScrollPagination';
import {
  AllAccessMedicalOrgRequest,
  LimitedClinic,
  MedicalOrganization,
  MedicalOrganizationSearchAllMedicalOrgParams, MedicalOrganizationStatus,
  useMedicalOrganizationSearchAllMedicalOrg
} from '../../uc-api-sdk';
import useDebounce from '../../hooks/useDebounce/useDebounce';

interface ChildrenProps {
  allClinics: LimitedClinic[];
  isLoading: boolean;
  handleOnSearch: SelectType<string>['onSearch'];
  handleOnScroll: ReturnType<typeof useScrollPagination>['handleOnScroll'];
}

export interface AllClinicSearchContainerProps {
  defaultSearchValue?: string;
  defaultSearchSize?: number;
  filters?: MedicalOrganization & AllAccessMedicalOrgRequest;
  onSearch?: (value: string) => void;
  children: (props: ChildrenProps) => JSX.Element;
  sendOnMount?: boolean;
  paginatedSearch?: boolean;
}

export const AllClinicSearchContainer = ({
  defaultSearchValue = '',
  defaultSearchSize = 15,
  filters,
  onSearch,
  children,
  sendOnMount = true,
  paginatedSearch = true,
}: AllClinicSearchContainerProps) => {
  const [localSearchValue, setLocalSearchValue] = useState(defaultSearchValue);
  const [localSearchSize] = useState(defaultSearchSize);
  const makeParams = (
    searchValue = localSearchValue,
    page = 1,
  ): MedicalOrganizationSearchAllMedicalOrgParams => ({
    searchRequest: {
      filter: {
        ...filters,
        fullNameNear: searchValue,
        statusIn: {
          in: [
            MedicalOrganizationStatus.ACTIVE,
            MedicalOrganizationStatus.TERMINATION_INITIATED,
          ],
        },
      },
      pageInfo: {
        page,
        size: localSearchSize,
        pagination: !!paginatedSearch,
      }
    }
  });
  const careUnitInfo = useMedicalOrganizationSearchAllMedicalOrg({});

  const {
    allData,
    handleOnScroll,
  } = useScrollPagination({
    requestInfo: careUnitInfo,
    fetchParams: (page) => makeParams(localSearchValue, page),
  });

  const handleOnSearch: SelectType<LimitedClinic>['onSearch'] = (
    value,
  ) => {
    setLocalSearchValue(value);
    onSearch?.(value);
    if (!value) return;
    careUnitInfo.send({
      params: makeParams(value),
    });
  };

  const debouncedSearch = useDebounce(handleOnSearch);

  useEffectOnce(() => {
    if (!sendOnMount) return;
    careUnitInfo.send({
      params: makeParams(),
    });
  });

  return (
    <>
      {
        children({
          isLoading: careUnitInfo.isLoading,
          allClinics: allData as LimitedClinic[],
          handleOnSearch: debouncedSearch,
          handleOnScroll,
        })
      }
    </>
  );
};
