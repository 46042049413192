import { useCallback, useMemo } from 'react';
import { FormProps } from 'antd';
import { map, pick } from 'lodash';
import { FormOptions, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { useAssignDeviceInfoForm } from '../../enrollment/hook/useAssignDeviceInfoForm/useAssignDeviceInfoForm';
import { DeviceMonitorSubmitValue, useDeviceMonitorForm } from './useDeviceMonitorForm';
import { SubmitValue } from '../../enrollment/component/ClearDeviceAssignFormComponent/ClearDeviceAssignFormComponent';

export const useDeviceMonitorFormProvider = (options?: FormOptions) => {
  const assignDeviceInfoForm = useAssignDeviceInfoForm(options);
  const deviceMonitorForm = useDeviceMonitorForm(options);

  const factory = useFormHookFactory({
    deviceMonitorForm: {
      name: 'deviceMonitorForm',
    },
    deviceAssignmentForm: {
      name: 'deviceAssignmentForm',
    },
    ...assignDeviceInfoForm.formInput,
    ...deviceMonitorForm.formInput,
  }, options);

  const handleSubmitDeviceAssignment: (onSubmit?: (v: SubmitValue) => Promise<void> | void) => FormProps['onFinish'] = (
    useCallback((onSubmit) => (values) => {
      onSubmit?.(
        pick(values, map(Object.values(assignDeviceInfoForm.formInput), 'name')) as SubmitValue
      );
    }, [])
  );

  const handleSubmitDeviceMonitor: (onSubmit?: (v: DeviceMonitorSubmitValue) => Promise<void> | void) => FormProps['onFinish'] = (
    useCallback((onSubmit) => (values) => {
      onSubmit?.(
        pick(values, map(Object.values(deviceMonitorForm.formInput), 'name')) as DeviceMonitorSubmitValue
      );
    }, [])
  );

  const res = useMemo(() => ({
    ...factory,
    handleSubmitDeviceMonitor,
    handleSubmitDeviceAssignment,
  }), [
    factory,
    handleSubmitDeviceMonitor,
    handleSubmitDeviceAssignment,
  ]);

  return res;
};
