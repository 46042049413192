import { FC } from 'react';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { AccountHelper } from '../../../../helpers/account';
import { CareNote } from '../../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { CareNoteEditDrawer } from '../CareNoteEditDrawer/CareNoteEditDrawer';
import { CareNoteHistoryDrawer } from '../CareNoteHistoryDrawer/CareNoteHistoryDrawer';
import './CareNoteCardSettingsComponent.scss';

export interface SettingProps {
  note: CareNote;
  isEditable?: boolean;
  showHistories?: boolean;
}
export const CareNoteCardSettingsComponent: FC<SettingProps> = ({
  note,
  isEditable = true,
  showHistories = true,
}) => {
  const c = useLoggedInUserFromContext();
  const { userId } = c;
  return (
    <div className="flex ai-c gap2 careNoteCardSettingsComponent">
      {
        (
          isEditable
          && note.createdByUser?.id === userId
        ) && <CareNoteEditDrawer note={note} />
      }
      {
        (
          showHistories
          && note.histories
        ) && <CareNoteHistoryDrawer histories={[...note.histories].reverse()} />
      }
      <span>
        {
          AccountHelper.getFullNameWithTitle(
            note.createdByUser || {},
            { delimiter: '/' }
          )
        }
      </span>
      <DisplayDateComponent
        value={note.createdAt}
        format="MEDICATION_HISTORY_DATE_FORMAT"
      />
    </div>
  );
};
