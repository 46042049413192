import { FC } from 'react';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { PatientTablesSiderContentComponent } from '../../component/PatientTablesSiderContentComponent/PatientTablesSiderContentComponent';
import { PATIENT_TABLE_TABS } from '../../constants';
import {
  getAllPatientsCount,
  getMyAssignedPatientsCount,
  getWatchedPatientCount,
} from '../../../patients/hook/usePatientsTableSearch/usePatientsTableSearch';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';

export interface PatientTablesSiderContainerProps {
  currentTab: PATIENT_TABLE_TABS;
  onClick: (t: PATIENT_TABLE_TABS) => void;
}

export const PatientTablesSiderContainer: FC<PatientTablesSiderContainerProps> = ({
  currentTab,
  onClick,
}) => {
  // fetch my patients
  const {
    assignedPtCount,
    refetch: refetchAssignedPatientCount,
  } = getMyAssignedPatientsCount();
  // fetch all patients
  const {
    allPtCount,
    enrolledPtCount,
    unEnrolledPtCount,
    refetch: refetchAllPatientsCount,
  } = getAllPatientsCount();
  // fetch watched patients
  const {
    watchedPtCount,
    refetch: refetchWatchedPatientCount,
  } = getWatchedPatientCount();

  const debouncedRefetchWatchedList = useDebounce(
    refetchWatchedPatientCount,
    250,
    [refetchWatchedPatientCount]
  );

  useUpdateListener('PATIENT_CREATE', () => {
    refetchAssignedPatientCount();
    refetchAllPatientsCount();
    debouncedRefetchWatchedList();
  });
  useUpdateListener('WATCH_LIST_UPDATED', () => {
    debouncedRefetchWatchedList();
  });

  return (
    <PatientTablesSiderContentComponent
      onClick={onClick}
      enrolledPatients={enrolledPtCount || 0}
      unenrolledPatients={unEnrolledPtCount || 0}
      allPatients={allPtCount || 0}
      watchListPatients={watchedPtCount || 0}
      myAssignedPatients={assignedPtCount || 0}
      currentTab={currentTab}
    />
  );
};
