import {
  ReactNode,
  createContext,
  useContext
} from 'react';
import { ClinicEventsService } from '../../features/patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import { PatientInfo } from '../PatientInfoContext/PatientInfoContext';

export interface WorklistContextValue {
  patientInfo?: PatientInfo;
  clinicEvent?: ClinicEventsService;
}

const WorklistContext = createContext<
    WorklistContextValue | undefined
  >(undefined);

export const useWorklistContext = () => {
  const context = useContext(WorklistContext) || {};
  return context as WorklistContextValue;
};

export interface WorklistContextProviderProps {
  children: ReactNode;
  patientInfo?: PatientInfo;
  clinicEvent: ClinicEventsService;
}
export const WorklistContextProvider = ({
  children,
  patientInfo,
  clinicEvent,
}: WorklistContextProviderProps) => {
  const contextValue = useGetContextValue<WorklistContextValue>({
    patientInfo,
    clinicEvent,
  });

  return (
    <WorklistContext.Provider value={contextValue}>
      {children}
    </WorklistContext.Provider>
  );
};
