import { first, isEmpty } from 'lodash';
import { Modal } from 'antd';
import { useState } from 'react';
import {
  ClinicEvent,
  MonthlyReview,
  ProviderNoteCategoryEnum,
  usePatientGetMtprEligibility,
} from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { FollowUpVisitMTPREligibilityComponent } from '../../../mtpr/component/MTPREligibilityPrompt/FollowUpVisitMTPREligibility';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import { OutstandingMTPRComponent } from '../../component/OutstandingMTPRComponent/OutstandingMTPRComponent';
import { CompleteMTPREligibilityComponent } from '../../../mtpr/component/MTPREligibilityPrompt/CompleteMTPREligibilityPrompt';
import { usePatientPageControllerContext } from '../../../patient/context/PatientPageControllerContext/PatientPageControllerContext';

export interface OutstandingManualMTPRContainerProps {
  patientId: string;
  onClose: () => void;
  onCreate?: () => void;
}

export const OutstandingManualMTPRContainer = ({
  patientId,
  onClose,
  onCreate,
}: OutstandingManualMTPRContainerProps) => {
  const {
    isManualMTPREligibilityConfirmed,
    confirmManualMTPREligibility,
    resetManualMTPREligibility,
  } = usePatientPageControllerContext();
  const [startManualMTPR, setStartManualMTPR] = useState(!!isManualMTPREligibilityConfirmed);
  const mtprEligibilityInfo = usePatientGetMtprEligibility({
    params: { memberId: patientId },
    options: { sendOnMount: true },
  });

  const handleCreateManualMTPR = () => {
    setStartManualMTPR(true);
    confirmManualMTPREligibility?.();
    onCreate?.();
  };

  const handleOnClose = () => {
    setStartManualMTPR(false);
    resetManualMTPREligibility?.();
    onClose?.();
  };

  const renderModalBody = (
    completeMTPR: MonthlyReview,
    followUpVisit: ClinicEvent,
  ) => {
    if (completeMTPR) {
      return <CompleteMTPREligibilityComponent completeMTPR={completeMTPR} />;
    }
    if (followUpVisit) {
      return <FollowUpVisitMTPREligibilityComponent followUpVisit={followUpVisit} />;
    }
    return null;
  };

  return (
    <FetchComponent
      info={mtprEligibilityInfo}
    >
      {
        (value) => {
          if (value?.rawValue?.data === undefined) {
            return <LoadingOverlayComponent isLoading />;
          }

          const {
            completedMtprs,
            followUpVisits
          } = value?.rawValue?.data || {};

          const mostRecentCompleteMTPR = first(completedMtprs) as MonthlyReview;
          const upcomingFollowUpVisit = first(followUpVisits) as ClinicEvent;
          const shouldConfirmManualMTPREligibility = (
            !isEmpty(mostRecentCompleteMTPR)
            || !isEmpty(upcomingFollowUpVisit)
          );

          if (!startManualMTPR && shouldConfirmManualMTPREligibility) {
            return (
              <Modal
                open
                width={366}
                footer={null}
                closable={false}
                maskClosable={false}
              >
                <div className="px10 pt5">
                  {
                    renderModalBody(
                      mostRecentCompleteMTPR,
                      upcomingFollowUpVisit,
                    )
                  }
                  <div className="flex gap3 ai-c jc-s mt20">
                    <LinkButton
                      useBlueColor
                      onClick={handleCreateManualMTPR}
                    >
                      Create
                    </LinkButton>
                    <LinkButton
                      onClick={handleOnClose}
                    >
                      Cancel
                    </LinkButton>
                  </div>
                </div>
              </Modal>
            );
          }

          return (
            <OutstandingMTPRComponent
              patientId={patientId}
              onDone={handleOnClose}
              type={ProviderNoteCategoryEnum.MTPR_MANUAL}
            />
          );
        }
      }
    </FetchComponent>
  );
};
