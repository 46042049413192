import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { UpdateValueTypes } from './UpdateValueTypes';
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect';

type UpdateValueMap = { [key in UpdateValueTypes]: number | undefined; }

export interface Value {
  prevValueMap: UpdateValueMap;
  syncPrevValue: (key: UpdateValueTypes, map: UpdateValueMap) => void;
  valueMap: UpdateValueMap;
  updateValue: (key: UpdateValueTypes, forceUpdate?: boolean) => void;
  registerListener: (key: UpdateValueTypes) => void;
  deregisterListener: (key: UpdateValueTypes) => void;
}

export const UpdateContext = React.createContext<Value>({
  prevValueMap: {} as UpdateValueMap,
  syncPrevValue: () => {
    console.error('Update context does not exist yet!');
  },
  valueMap: {} as UpdateValueMap,
  updateValue: () => {
    console.error('Update context does not exist yet!');
  },
  registerListener: () => {
    console.error('Update context does not exist yet!');
  },
  deregisterListener: () => {
    console.error('Update context does not exist yet!');
  },
});

export interface UpdateContextProviderProps {
  children: React.ReactNode;
}

export const UpdateContextProvider: FC<UpdateContextProviderProps> = ({
  children,
}) => {
  const [map, setMap] = useState({} as UpdateValueMap);
  const [prevMap, setPrevMap] = useState({} as UpdateValueMap);
  const [mapListeners, setMapListeners] = useState({} as UpdateValueMap);

  const registerListener = useCallback((key: UpdateValueTypes) => {
    setMapListeners((v) => {
      const newValue = { ...v };
      newValue[key] = (v[key] || 0) + 1;
      return newValue;
    });
  }, [setMapListeners]);

  const deregisterListener = useCallback((key: UpdateValueTypes) => {
    setMapListeners((v) => {
      const newValue = { ...v };
      if (Number(v[key]) > 0) {
        newValue[key] = Number(v[key]) + -1;
      }
      return newValue;
    });
  }, [setMapListeners]);

  const updateValue = useCallback((
    key: UpdateValueTypes,
    forceUpdate?: boolean,
  ) => {
    if (!forceUpdate && !mapListeners[key]) {
      // Don't need to update when there is no listener and not forced
      return;
    }
    setMap((v) => {
      const newValue = { ...v };
      newValue[key] = (v[key] || 0) + 1;
      return newValue;
    });
  }, [mapListeners, setMap]);

  const syncPrevValue = useCallback((
    key: UpdateValueTypes,
    map: UpdateValueMap,
  ) => {
    setPrevMap((prev) => {
      const newValue = { ...prev };
      newValue[key] = map[key];
      return newValue;
    });
  }, [setPrevMap]);

  const value = useMemo(() => ({
    prevValueMap: prevMap || {} as UpdateValueMap,
    syncPrevValue,
    valueMap: map,
    updateValue,
    registerListener,
    deregisterListener,
  }), [prevMap, map, updateValue]);

  return (
    <UpdateContext.Provider value={value}>
      {children}
    </UpdateContext.Provider>
  );
};

/**
 * This updates the given key
 * @param name the key
 */
export const useUpdate = (name: UpdateValueTypes) => {
  const { updateValue } = useContext(UpdateContext);
  const updateValueRef = useRef<() => void>(() => updateValue(name));

  useDeepCompareEffect(() => {
    updateValueRef.current = () => updateValue(name);
  }, [name, updateValue]);

  return {
    updateValue: () => updateValueRef.current(),
  };
};

/**
 * listens to updates for the given key
 * @param name key
 * @param onUpdate the function that is ran when the the key is updated
 */
export const useUpdateListener = (name: UpdateValueTypes, onUpdate: () => void) => {
  const {
    valueMap,
    prevValueMap,
    syncPrevValue,
    registerListener,
    deregisterListener,
  } = useContext(UpdateContext);

  useEffectOnce(() => {
    registerListener(name);
    return () => deregisterListener(name);
  });

  useEffect(() => {
    if (prevValueMap[name] !== valueMap[name]) {
      onUpdate();
      syncPrevValue(name, valueMap);
    }
  }, [name, prevValueMap, valueMap]);
};
