import dayjs from 'dayjs';
import { Col, Popover } from 'antd';
import { TitleWithContent } from '../../../../../uiComponent/TitleWithContent/TitleWithContent';
import { ClinicEventsService } from './ClinicEventsService';
import { CLINIC_EVENT_DATE_FORMAT, TIME_AM_PM } from '../../../../../constants/timeFormat';
import EmptyComponent from '../../../../../uiComponent/EmptyComponent/EmptyComponent';

import './PatientVisitsItemComponent.scss';
import {
  DisplayParticipantsComponent
} from '../../../../outstanding/component/DisplayParticipantsComponent/DisplayParticipantsComponent';
import {
  TranscribeStatusButtonComponent
} from '../../../../transcribing/component/TranscribeStatusButtonComponent/TranscribeStatusButtonComponent';
import { VisitChartingComponent } from '../VisitChartingComponent/VisitChartingComponent';
import { VisitStatusEnum } from '../../../../../uc-api-sdk';
import { VisitsStatusComponent } from '../../../../calendar/component/VisitsStatusComponent/VisitsStatusComponent';
import { VisitMethodComponent } from '../../../../calendar/component/VisitMethodComponent/VisitMethodComponent';

export interface PatientVisitsItemComponentProps {
  value: ClinicEventsService;
}

export const PatientVisitsItemComponent = ({
  value,
}: PatientVisitsItemComponentProps) => {
  const renderVisitsStatus = () => {
    let durationInMinutes = 0;
    if (value.checkInTime && value.checkOutTime) {
      durationInMinutes = value.checkOutTime.diff(value.checkInTime, 'minutes');
    } else if (value.checkInTime && value.visitsStatus === VisitStatusEnum.ONGOING) {
      durationInMinutes = dayjs().diff(value.checkInTime, 'minutes');
    }
    const durationTime = `${durationInMinutes} mins`;

    const checkInFormatted = value.checkInTime?.format(TIME_AM_PM);
    const checkOutFormatted = value.checkOutTime?.format(TIME_AM_PM);

    let content: React.ReactNode;

    switch (value.visitsStatus) {
      case VisitStatusEnum.CHECKED_OUT:
        content = (
          <div>
            <p>
              Check-in at:
              {' '}
              {checkInFormatted}
            </p>
            <p>
              Check-out at:
              {' '}
              {checkOutFormatted}
            </p>
            <p>
              Duration:
              {' '}
              {durationTime}
            </p>
          </div>
        );
        break;
      case VisitStatusEnum.ONGOING:
        content = (
          <div>
            <p>
              Check-in at:
              {' '}
              {checkInFormatted}
            </p>
            <p>
              Duration:
              {' '}
              <span
                className="ongoing-duration-time"
              >
                {durationTime}
              </span>
            </p>
          </div>
        );
        break;
      default:
        content = null;
    }

    if (
      value.visitsStatus === VisitStatusEnum.CHECKED_OUT
      || value.visitsStatus === VisitStatusEnum.ONGOING
    ) {
      return (
        <Popover content={content} trigger="hover">
          <span className="interactive-tag">
            <VisitsStatusComponent visitStatus={value.visitsStatus} startTime={value.startTime} />
          </span>
        </Popover>
      );
    }
    return (
      <span className="interactive-tag">
        <VisitsStatusComponent visitStatus={value.visitsStatus} startTime={value.startTime} />
      </span>
    );
  };

  const { isOtherEvent } = value;

  return (
    <div className="patient-page-visit-component">
      <Col span={3}>
        <TitleWithContent
          title="Scheduled Time"
          content={value.startTime?.format(CLINIC_EVENT_DATE_FORMAT)}
        />
      </Col>
      <Col span={3}>
        <TitleWithContent
          title={isOtherEvent ? 'Title' : 'Visit Method'}
          content={
            isOtherEvent
              ? value.otherEventTitle
              : value.visitMethod && <VisitMethodComponent value={value.visitMethod} />
          }
        />
      </Col>
      <Col span={3}>
        <TitleWithContent
          title="Participant"
          content={(
            <DisplayParticipantsComponent
              isOtherEvent={isOtherEvent}
              participants={value.visitParticipants}
            />
          )}
        />
      </Col>
      <Col span={3}>
        <TitleWithContent
          title="Description"
          content={value.description || <EmptyComponent />}
        />
      </Col>
      <Col span={3}>
        {!isOtherEvent && (
          <TitleWithContent
            title="Transcribing"
            content={(
              <TranscribeStatusButtonComponent
                transcribeId={value.transcribeId}
                transcribeStatus={value.transcribeStatus}
              />
            )}
          />
        )}
      </Col>
      <Col span={4}>
        {!isOtherEvent && (
          <TitleWithContent
            title="Visit Status"
            content={renderVisitsStatus()}
          />
        )}
      </Col>
      <Col span={3}>
        {
          (
            !isOtherEvent && value.visitsStatus
            && value.visitsStatus === VisitStatusEnum.CHECKED_OUT
          ) && (
            <TitleWithContent
              title="Charting"
              className="visit-charting-button"
              content={(
                <VisitChartingComponent
                  visit={value}
                  value={value.chartingStatus}
                  checkedIn={value.isCheckedIn}
                  disabled={value.isCompleted}
                />
              )}
            />
          )
        }
      </Col>
    </div>
  );
};
