import {
  ReactNode,
  useMemo,
  useState
} from 'react';
import { keyBy } from 'lodash';
import { message } from 'antd';
import { usePatientContext } from '../../../contexts/PatientInfoContext/PatientInfoContext';
import { useGetPatientArticles } from '../hooks/api/useGetPatientArticles';
import { useArticleCategories } from '../hooks/useArticleCategories';
import { FetchComponent } from '../../../uiComponent/FetchComponent/FetchComponent';
import { languageToWebflowLanguageMap } from '../constants';
import { LanguageType } from '../../../types/languages';
import { PatientArticleMap, WebflowLanguageEnum } from '../types';
import { ArticlesSearchContainer, ArticlesSearchContainerProps } from './ArticlesSearchContainer';
import { useSendAndSaveArticles } from '../hooks/useSendAndSaveArticles';
import { ArticlesSentHistoryTableContainer } from './ArticlesSentHistoryTableContainer';
import { useUpdate, useUpdateListener } from '../../../contexts/UpdateContext/UpdateContext';

import './ArticlesMainContainer.scss';
import useDebounce from '../../../hooks/useDebounce/useDebounce';

export interface ArticlesMainContainerProps {
  patientId?: string;
  title?: ReactNode;
  disabled?: boolean;
}
export const ArticlesMainContainer = ({
  patientId: _patientId = '',
  title = "Send Articles to Patient's App",
  disabled,
}: ArticlesMainContainerProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    info: {
      id: patientId = _patientId,
      patientInfoService,
    } = {},
  } = usePatientContext();
  const {
    articleCategories,
    isLoadingArticleCategories,
  } = useArticleCategories();
  const patientArticlesInfo = useGetPatientArticles({
    options: {
      sendOnMount: true,
    },
    params: {
      filter: {
        patientId,
      },
    },
  });
  const {
    sendAndSaveArticles,
  } = useSendAndSaveArticles(patientId);
  const updateHook = useUpdate('ARTICLES_SENT');
  useUpdateListener('ARTICLES_SENT', patientArticlesInfo.refetch);

  const preferredLanguages = useMemo(
    (): ArticlesSearchContainerProps['preferredLanguages'] => {
      const spokenLanguage = patientInfoService?.getSpokenLanguage();
      const appLanguage = patientInfoService?.getAppLanguage();
      if (!spokenLanguage && !appLanguage) {
        return [languageToWebflowLanguageMap.EL];
      }
      const preferred = [] as string[];
      if (spokenLanguage) {
        preferred.push(...spokenLanguage);
      }
      if (appLanguage && !spokenLanguage?.includes(appLanguage)) {
        preferred.push(appLanguage);
      }
      return preferred.map((p) => (
        languageToWebflowLanguageMap[p as LanguageType] || p
      )) as WebflowLanguageEnum[];
    },
    [patientInfoService],
  );

  const patientArticlesObject = useMemo((): PatientArticleMap => (
    patientArticlesInfo.data?.data.content
      ? keyBy(patientArticlesInfo.data.data.content, 'articleId')
      : {}
  ), [patientArticlesInfo.data?.data.content]);

  const handleOnSubmit: ArticlesSearchContainerProps['onSubmit'] = async (
    selectedArticles,
  ) => {
    setIsSubmitting(true);

    const handleSuccess = (successMessage: string) => {
      message.success(successMessage);
      updateHook.updateValue();
    };

    const handleError = (errorMessage: Error) => {
      message.error(errorMessage?.message || '');
    };

    await sendAndSaveArticles(selectedArticles, {
      onSuccess: (__, hasSendError) => {
        const errorMessage = hasSendError ? ', but failed for some. Please double check' : '';
        handleSuccess(`Article(s) sent${errorMessage}`);
      },
      onError: handleError,
    });

    setIsSubmitting(false);
  };

  const debouncedHandleOnSubmit = useDebounce(handleOnSubmit, 500, [handleOnSubmit]);

  return (
    <div className="articles-container">
      <div className="articles-container__title">
        {title}
      </div>
      <FetchComponent
        info={patientArticlesInfo}
        alwaysShowChildren
      >
        {
          (value, info) => {
            const isLoading = !!isLoadingArticleCategories
              || !!info.isLoading
              || isSubmitting;

            return (
              <>
                <ArticlesSearchContainer
                  preferredLanguages={preferredLanguages}
                  disabled={disabled}
                  articleCategories={articleCategories}
                  patientArticles={patientArticlesObject}
                  isLoading={isLoading}
                  onSubmit={debouncedHandleOnSubmit}
                  submitText="Submit"
                />
                <ArticlesSentHistoryTableContainer
                  patientArticles={value?.data.content || []}
                  totalSize={Number(patientArticlesInfo.data?.data?.totalSize)}
                />
              </>
            );
          }
        }
      </FetchComponent>
    </div>
  );
};
