import { isEmpty } from 'lodash';
import { Divider } from 'antd';
import { ActivityLevelEnumComponent } from '../../../../../enumComponent/ActivityLevelEnumComponent/ActivityLevelEnumComponent';
import { EatingRoutineEnumComponent } from '../../../../../enumComponent/EatingRoutineEnumComponent/EatingRoutineEnumComponent';
import { FoodAccessEnumComponent } from '../../../../../enumComponent/FoodAccessEnumComponent/FoodAccessEnumComponent';
import { FreqEnumComponent } from '../../../../../enumComponent/FreqEnumComponent/FreqEnumComponent';
import { SleepingRoutineEnumComponent } from '../../../../../enumComponent/SleepingRoutineEnumComponent/SleepingRoutineEnumComponent';
import { Lifestyle } from '../../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { LifestyleSmokingOrDrinkingComponent } from '../../../../intervention/component/LifestyleComponent/LifestyleSmokingOrDrinkingComponent';
import { PrintCarePlanSimpleItemComponent } from '../PrintCarePlanSimpleItemComponent/PrintCarePlanSimpleItemComponent';

import './PrintLifeStyleComponent.scss';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';
import EmptyComponent from '../../../../../uiComponent/EmptyComponent/EmptyComponent';

export interface PrintLifeStyleComponentProps {
  lifeStyle?: Lifestyle | null;
}

export const PrintLifeStyleComponent = ({
  lifeStyle
}: PrintLifeStyleComponentProps) => {
  if (isEmpty(lifeStyle)) {
    return null;
  }

  return (
    <>
      <div className="page-break" />
      <TextComponent className="pop-text">
        Lifestyle
      </TextComponent>
      <PrintCarePlanSimpleItemComponent
        label="Physical Activity Level:"
        content={(
          <ActivityLevelEnumComponent
            value={lifeStyle?.physicalActivity?.activityLevel ?? undefined}
          />
        )}
      />
      <PrintCarePlanSimpleItemComponent
        label="How often:"
        content={(
          lifeStyle?.physicalActivity?.activityValue
            && lifeStyle?.physicalActivity?.activityFreq
            ? (
              <>
                {lifeStyle?.physicalActivity?.activityValue}
                {' '}
                times per
                {' '}
                <FreqEnumComponent value={lifeStyle?.physicalActivity?.activityFreq ?? undefined} type="short" />
                {lifeStyle?.physicalActivity?.activityDuration ? (
                  <>
                    , duration
                    {' '}
                    {lifeStyle?.physicalActivity?.activityDuration}
                    {' '}
                    mins
                  </>
                ) : null}
              </>
            ) : <EmptyComponent />
        )}
      />
      <PrintCarePlanSimpleItemComponent
        label="How often do you get 7-8 hours of sleep?"
        content={(
          <SleepingRoutineEnumComponent
            value={lifeStyle?.sleepingRoutine ?? undefined}
          />
        )}
      />
      <PrintCarePlanSimpleItemComponent
        label="Eating Routine:"
        content={(
          <EatingRoutineEnumComponent
            value={lifeStyle?.eatingRoutine ?? undefined}
          />
        )}
      />
      <PrintCarePlanSimpleItemComponent
        label="Food Accessibility:"
        content={(
          <FoodAccessEnumComponent
            value={lifeStyle?.foodAccessibility ?? undefined}
          />
        )}
      />
      <PrintCarePlanSimpleItemComponent
        label="Does Patient Smoke?"
        content={(
          <LifestyleSmokingOrDrinkingComponent
            value={lifeStyle?.smokingHistory?.smokingOrDrinking ?? undefined}
            quitYear={lifeStyle?.smokingHistory?.quitYear ?? undefined}
            activityFreq={lifeStyle?.smokingHistory?.activityFreq ?? undefined}
            activityValue={lifeStyle?.smokingHistory?.activityValue ?? undefined}
            type="smoke"
          />
        )}
      />
      <PrintCarePlanSimpleItemComponent
        label="Does Patient Drink?"
        content={(
          <LifestyleSmokingOrDrinkingComponent
            value={lifeStyle?.drinkingHistory?.smokingOrDrinking ?? undefined}
            quitYear={lifeStyle?.drinkingHistory?.quitYear ?? undefined}
            activityFreq={lifeStyle?.drinkingHistory?.activityFreq ?? undefined}
            activityValue={lifeStyle?.drinkingHistory?.activityValue ?? undefined}
            drinkChoice={lifeStyle?.drinkingHistory?.drinkChoice ?? undefined}
            drinkQuantity={lifeStyle?.drinkingHistory?.drinkQuantity ?? undefined}
            type="drink"
          />
        )}
      />
      <PrintCarePlanSimpleItemComponent
        label="Typical Lifestyle Pattern:"
        content={(
          <DisplayOrEmptyComponent value={lifeStyle?.lifestylePattern} />
        )}
      />
      <Divider />
    </>
  );
};
