import { Input, InputProps, Space } from 'antd';
import { InputType } from '../../../../features/Input/types';
import { HeightService } from '../../../../helpers/height';
import { HeightInputSuffixComponent } from '../HeightInputSuffixComponent/HeightInputSuffixComponent';

export interface HeightInputComponentProps extends InputType<number> { }

export const HeightInputComponent = ({
  value = 0,
  onChange,
}: HeightInputComponentProps) => {
  const [feet, inch] = HeightService.cmToFeet(value ?? 0.0);
  const onFeetChange: InputProps['onChange'] = (v) => {
    const newVal = HeightService.feetToCm(Number(v.target.value), inch);
    onChange?.(newVal);
  };
  const onInchChange: InputProps['onChange'] = (v) => {
    const newVal = HeightService.feetToCm(feet, Number(v.target.value));
    onChange?.(newVal);
  };

  return (
    <Space.Compact>
      <Input
        type="number"
        value={feet}
        min={0}
        onChange={onFeetChange}
        suffix={<HeightInputSuffixComponent text="ft." />}
      />
      <Input
        type="number"
        value={inch}
        min={0}
        max={12}
        onChange={onInchChange}
        suffix={<HeightInputSuffixComponent text="in." />}
      />
    </Space.Compact>
  );
};
