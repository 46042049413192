import React from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Space } from 'antd';
import './NotQualifiedComponent.scss';

export const NotQualifiedComponent = () => (
  <div className="not-qualified-component">
    <Space>
      <ExclamationCircleFilled className="not-qualified-component__icon" />
      <span className="not-qualified-component__text">Not eligible for billing</span>
    </Space>
  </div>
);
