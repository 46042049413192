import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { AlertCardComponent } from '../../../../uiComponent/AlertCardComponent/AlertCardComponent';

export const TestPatientNoteComponent = () => {
  const { info } = usePatientContext();

  const { isTestUser } = info?.patientInfo || {};

  if (isTestUser) {
    return (
      <AlertCardComponent
        type="info"
        message="This is a testing patient."
        warningBorder
      />
    );
  }
  return (
    null
  );
};
