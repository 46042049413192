import { Space } from 'antd';
import { useState } from 'react';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { EnrollmentDrawerComponent } from '../../../enrollment/component/EnrollmentDrawerComponent/EnrollmentDrawerComponent';
import { OutstandingItemsCardComponent } from '../OutstandingItemsCardComponent/OutstandingItemsCardComponent';
import { Step } from '../../../enrollment/context/EnrollmentStepContext/EnrollmentStepContext';

export interface OnboardingTodosComponentProps {
  showAssignDevices: boolean;
  showScheduleOnboardingVisit: boolean;
  showScheduleInitialVisit: boolean;
}

export const OnboardingTodosComponent = ({
  showAssignDevices,
  showScheduleOnboardingVisit,
  showScheduleInitialVisit,
}: OnboardingTodosComponentProps) => {
  const { id = '' } = usePatientContext().info || {};
  const [step, setStep] = useState<Step>();
  const { isOpen, open, close } = useOpen();

  const goToAssignDevices = () => {
    setStep(Step.AssignDevices);
    open();
  };

  const goToScheduleOnboarding = () => {
    setStep(Step.OnboardingVisits);
    open();
  };

  return (
    <div>
      <p>
        Finish the following tasks when patient is with you.
      </p>
      <Space direction="vertical" className="w100">
        {showAssignDevices && (
          <OutstandingItemsCardComponent
            title="Assign Devices"
            onStartNow={goToAssignDevices}
          />
        )}
        {showScheduleOnboardingVisit && (
          <OutstandingItemsCardComponent
            title="Schedule Onboarding Visits"
            onStartNow={goToScheduleOnboarding}
          />
        )}
        {showScheduleInitialVisit && (
          <OutstandingItemsCardComponent
            title="Schedule Initial Visits"
            onStartNow={goToScheduleOnboarding}
          />
        )}
      </Space>
      <EnrollmentDrawerComponent
        patientId={id}
        onClose={close}
        isOpen={isOpen}
        initialStep={step}
      />
    </div>
  );
};
