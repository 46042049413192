import {
  filter,
  groupBy,
  maxBy,
  minBy,
  orderBy,
  range,
  reduce,
} from 'lodash';
import { Dayjs } from 'dayjs';
import { USA_DATE } from '../../../constants/timeFormat';
import { MeasurementResultTypeEnum } from '../../../uc-api-sdk';
import TimezoneService from '../../../helpers/timezone/timezoneService';

export interface BaseMeasurement {
  date: number | string | Dayjs;
  timezone?: string;
  type?: MeasurementResultTypeEnum;
}

/* eslint-disable import/prefer-default-export */
export class MeasurementHelper {
  private static getDate<T extends BaseMeasurement>(data: T) {
    return data.date.valueOf();
  }

  // finds the from date and to date based on
  public static getFromAndToDate<T extends BaseMeasurement>(data: T[]) {
    return {
      fromDate: minBy(data, MeasurementHelper.getDate),
      toDate: maxBy(data, MeasurementHelper.getDate),
    };
  }

  // create a table with date as the key and array of measurements as the value
  public static dateValueDict<T extends BaseMeasurement>(data: T[]) {
    return reduce(data, (res, v) => {
      const key = TimezoneService.calcDateTimeDayjs(v.date, v.timezone).format(USA_DATE);
      if (!res[key]) {
        res[key] = [];
      }
      res[key].push(v);
      return res;
    }, {} as Record<string, T[]>);
  }

  // groups by date
  public static groupByDate<T extends BaseMeasurement>(data: T[]) {
    return groupBy(data, (v) => TimezoneService.calcDateTimeDayjs(v.date).format(USA_DATE));
  }

  // sort each array based on the time
  public static sortByDate<T extends BaseMeasurement, K extends string>(
    unsortedObject: Partial<Record<K, T[] | undefined>>,
  ) {
    return reduce(unsortedObject, (res, curr, key) => {
      if (curr) {
        res[key as K] = this.sortArrayByDate(curr);
      }
      return res;
    }, {} as Partial<Record<K, T[] | undefined>>);
  }

  public static sortArrayByDate<T extends BaseMeasurement>(
    unsortedArray: T[],
  ) {
    return orderBy(unsortedArray, (v) => this.getDate(v), 'desc');
  }

  public static createArrayOfDateData<T>(
    fromDate: Dayjs,
    toDate: Dayjs,
    getData: (date: Dayjs) => T,
  ) {
    const days = toDate.clone().diff(fromDate, 'days');
    return range(0, days + 1).map((day) => {
      const date = fromDate.clone().add(day, 'days');
      return {
        date,
        data: getData(date),
      };
    });
  }

  private static filterBasedOnType<T extends BaseMeasurement>(
    data: T[],
    types: MeasurementResultTypeEnum[],
  ) {
    return filter(data, (v) => v.type && types.includes(v.type));
  }

  public static getBPMeasurements<T extends BaseMeasurement>(data: T[]) {
    return this.filterBasedOnType(data, [MeasurementResultTypeEnum.BP]) as T[];
  }

  public static getBGMeasurements<T extends BaseMeasurement>(data: T[]) {
    return this.filterBasedOnType(data, [MeasurementResultTypeEnum.BG]) as T[];
  }

  public static getBOMeasurements<T extends BaseMeasurement>(data: T[]) {
    return this.filterBasedOnType(data, [MeasurementResultTypeEnum.PO]) as T[];
  }

  public static getHSMeasurements<T extends BaseMeasurement>(data: T[]) {
    return this.filterBasedOnType(data, [MeasurementResultTypeEnum.HS]) as T[];
  }

  public static getTMMeasurements<T extends BaseMeasurement>(data: T[]) {
    return this.filterBasedOnType(data, [MeasurementResultTypeEnum.TM]) as T[];
  }

  public static getExerciseMeasurements<T extends BaseMeasurement>(data: T[]) {
    return this.filterBasedOnType(data, [MeasurementResultTypeEnum.EXERCISE]) as T[];
  }
}
