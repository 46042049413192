import { EnrollmentDateSegmentEnum } from '../../uc-api-sdk';

export interface EnrollmentDateSegmentEnumComponentProps {
  value: EnrollmentDateSegmentEnum;
}

export const EnrollmentDateSegmentEnumComponent = ({
  value
}: EnrollmentDateSegmentEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case EnrollmentDateSegmentEnum.ONE_WEEK:
        return 'Last 7 days';
      case EnrollmentDateSegmentEnum.TWO_WEEKS:
        return 'Last 14 days';
      case EnrollmentDateSegmentEnum.ONE_MONTH:
        return 'Last 30 days';
      case EnrollmentDateSegmentEnum.THREE_MONTHS:
        return 'Last 90 days';
      default:
        return null;
    }
  };
  return <span className="enrollment-date-segment-enum">{getText()}</span>;
};
