import { TableCacheNameEnum } from '../../../../hooks/useTableChange/useTableChange';
import { PatientListTypeEnum } from '../../../../uc-api-sdk';
import { MyAssignedPatientsTableComponent } from '../../component/MyAssignedPatientsTableComponent/MyAssignedPatientsTableComponent';
import { PatientsTableContainer } from '../PatientsTableContainer/PatientsTableContainer';

export interface MyAssignedPatientsTableContainerProps {

}

export const MyAssignedPatientsTableContainer = () => (
  <PatientsTableContainer
    type={PatientListTypeEnum.ENROLLED}
    name={TableCacheNameEnum.MyAssignedPatients}
    myAssigned
    needToFilter={false}
  >
    {({
      totalSize,
      pagination,
      dataSource,
      onChange,
      filters,
      sorters,
      isLoading,
      exportData,
    }) => (
      <MyAssignedPatientsTableComponent
        totalSize={totalSize}
        pagination={pagination}
        dataSource={dataSource}
        onChange={onChange}
        filters={filters}
        sorters={sorters}
        loading={isLoading}
        exportData={exportData}
      />
    )}
  </PatientsTableContainer>
);
