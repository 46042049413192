import { Popover, PopoverProps, Typography } from 'antd';
import classNames from 'classnames';
import { HowToTakeScreenshotComponent } from './HowToTakeScreenshotComponent';

export interface HowToTakeScreenshotTooltipComponentProps extends PopoverProps { }

export const HowToTakeScreenshotTooltipComponent = ({
  trigger = ['click'],
  overlayClassName = '',
  ...props
}: HowToTakeScreenshotTooltipComponentProps) => (
  <Popover
    trigger={trigger}
    content={<HowToTakeScreenshotComponent />}
    overlayClassName={classNames({
      'how-to-take-screenshot-tooltip': true,
      [overlayClassName]: !!overlayClassName,
    })}
    destroyTooltipOnHide
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <Typography.Text underline className="pointer">
      How to take a screenshot
    </Typography.Text>
  </Popover>
);
