import React from 'react';
import { Col, Row, Table } from 'antd';
import './IntakeTableComponent.scss';
import { MealIntake, Nullable } from '../../../../../uc-api-sdk';
import { useTableColumns } from '../../../../../hooks/table/useTableColumns/useTableColumns';
import { intakeTableColumns } from './useIntakeTableColumns';

export interface MealType {
  name: string;
  time: string;
}

export interface DataType {
  key: string;
  meal: MealType;
  foodTypeAmount: string;
  mealFreq: string;
  skipFreq: string;
}
export interface NutritionTableComponentProps {
  intake?: Nullable<MealIntake[]>;
}

export const IntakeTableComponent = ({
  intake,
}: NutritionTableComponentProps) => {
  const columns = useTableColumns(
    intakeTableColumns,
    ['meal', 'foodTypeAmount', 'mealFreq', 'skipFreq'],
  );
  const data: DataType[] = intake
    ? intake.map((mealIntake, index) => ({
      key: `${index}-${mealIntake.meal}`,
      meal: {
        name: `${mealIntake.meal}`,
        time: `${mealIntake.timeRange ? mealIntake.timeRange : 'N/A'}`,
      },
      foodTypeAmount: `${mealIntake.foodTypeAmount ? mealIntake.foodTypeAmount : 'N/A'}`,
      mealFreq: `${mealIntake.mealFreq ? mealIntake.mealFreq : 'N/A'}`,
      skipFreq: `${mealIntake.skipFreq ? mealIntake.skipFreq : 'N/A'}`,
    }))
    : [];
  return (
    <div className="intake-table-component">
      <div className="text-title">
        24-hour Recall or Typical intake
      </div>
      <Row>
        <Col span={18}>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
          />
        </Col>
      </Row>
    </div>
  );
};
