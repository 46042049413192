import React, { FC, ReactNode } from 'react';
import { Card } from 'antd';
import { find } from 'lodash';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { MeasurementResultTypeEnum, MeasurementResponse } from '../../../../uc-api-sdk';
import { VitalFetchContainerProps, VitalFetchContainer } from '../../container/VitalFetchContainer/VitalFetchContainer';
import VitalHeaderComponent from '../VitalHeaderComponent/VitalHeaderComponent';
import { VitalTableAndSummaryViewComponent } from '../VitalTableAndSummaryViewComponent/VitalTableAndSummaryViewComponent';
import { MeasurementRendererArgs, VitalFetchProps } from '../../type';
import { VitalSummaryComponent } from '../VitalSummaryComponent/VitalSummaryComponent';
import TimezoneService from '../../../../helpers/timezone/timezoneService';

export interface VitalChartTableSummaryViewComponentProps extends
  VitalFetchProps {
  type: MeasurementResultTypeEnum;
  title: string;
  unit?: string;
  chartView: (v: MeasurementRendererArgs) => ReactNode;
  tableView: (v: MeasurementRendererArgs) => ReactNode;
  summaryTableView: (v: MeasurementRendererArgs) => ReactNode;
}

export const VitalChartTableSummaryViewComponent: FC<VitalChartTableSummaryViewComponentProps> = ({
  memberId,
  type,
  title,
  unit,
  chartView,
  tableView,
  summaryTableView,
  fromDate,
  toDate,
}) => {
  const { value: isChartView, toggle } = useBoolean();

  const getLastMeasurementAt = (d?: MeasurementResponse) => {
    // eslint-disable-next-line no-underscore-dangle
    const vital = find(d?.lastMeasurements || [], (v) => v._id === type);
    return vital && vital.latestMeasurementDate
      ? TimezoneService.calcDateTimeDayjs(vital.latestMeasurementDate)
      : undefined;
  };

  const renderChild: VitalFetchContainerProps['children'] = (data, info) => {
    const v = {
      data,
      fromDate,
      toDate,
    };
    return (
      <div>
        <div className="mb20">
          <VitalHeaderComponent
            title={title}
            unit={unit}
            switchText="Chart view"
            lastMeasurementAt={getLastMeasurementAt(data)}
            onSwitchChange={toggle}
          />
        </div>
        {isChartView
          ? chartView({
            // for the chart view we need to set the
            // empty data to {} to avoid the chart error
            data: info.isLoading ? {} : data,
            fromDate,
            toDate,
          })
          : (
            <VitalTableAndSummaryViewComponent
              table={tableView(v)}
              summary={<VitalSummaryComponent type={type} value={v.data?.stats ?? undefined} />}
              summaryTable={summaryTableView(v)}
            />
          )}
      </div>
    );
  };

  return (
    <Card>
      <VitalFetchContainer
        fromDate={fromDate}
        toDate={toDate}
        memberId={memberId}
        type={type}
        needStats
      >
        {renderChild}
      </VitalFetchContainer>
    </Card>
  );
};
