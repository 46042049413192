import { TableProps } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { FilterValue } from 'antd/lib/table/interface';
import {
  filter as lodashFilter, isEmpty, isNil, size
} from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { GeneralConfigs } from '../../configs/GeneralConfig/GeneralConfig';
import { SorterResult } from '../../types/table';
import { PageInfo, SortInfo } from '../../uc-api-sdk';
import { useTableCache } from '../useTableCache/useTableCache';

export enum TableCacheNameEnum {
  PatientCare = 'PatientCare',
  MyAssignedPatients = 'MyAssignedPatients',
  WatchList = 'WatchList',
  EnrolledPatients = 'EnrolledPatients',
  UnenrolledPatients = 'UnenrolledPatients',
  AllPatients = 'AllPatients',
  MedicalAlertDashboard = 'MedicalAlertDashboard',
  BillingReportDashBoard = 'BillingReportDashboard',
  InsurAndEligDashboard = 'InsurAndEligDashboard'
}

export type Filter<K extends string = string> = Record<K, FilterValue | null>;
export type Sorter<T> = SorterResult<T> | SorterResult<T>[] | undefined;

export type SetFilters = (value: Filter | ((v: Filter) => Filter)) => void;
export type SetSorters<T> = (value: Sorter<T> | ((v: Sorter<T>) => Sorter<T>)) => void;
export type SetPage = (value: number) => void;

export interface UseTableChangeArg<T, F, S> {
  name?: TableCacheNameEnum | string;
  filter?: F;
  sort?: S;
  page?: number;
  pageSize?: number;
  isCountOnly?: boolean;
  filterProcessor?: (filters: Filter) => F;
  sortProcessor?: (sort: SorterResult<T> | SorterResult<T>[]) => S;
  cachePagination?: boolean;
}

interface Filters<F> {
  rawFilters: Filter;
  processedFilters: F | undefined;
}

interface Sorters<T> {
  rawSorters: Sorter<T>;
  processedSorters: SortInfo[] | undefined;
}

export const useTableChange = <T, F>({
  name,
  filter: initialFilter,
  sort: initialSort,
  page: initialPage = 1,
  pageSize: initialPageSize = GeneralConfigs.pagination.defaultPageSize,
  isCountOnly = false,
  filterProcessor,
  sortProcessor,
  cachePagination,
}: UseTableChangeArg<T, F, SortInfo[]> = {}) => {
  const [filters, setFiltersBase] = useState<Filters<F>>({
    rawFilters: {},
    processedFilters: initialFilter || {} as F,
  });
  const [sorters, setSortersBase] = useState<Sorters<T>>({
    rawSorters: undefined,
    processedSorters: initialSort,
  });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: initialPage,
    pageSize: initialPageSize,
    showSizeChanger: true,
  });

  const setSorters = useCallback<SetSorters<T>>((value) => {
    setSortersBase((v) => {
      const newSorters = typeof value === 'function' ? value(v?.rawSorters) : value;
      return {
        rawSorters: newSorters,
        processedSorters: newSorters === undefined ? undefined : sortProcessor?.(newSorters),
      };
    });
  }, [setSortersBase]);

  const setFilters = useCallback<SetFilters>((value) => {
    setFiltersBase((v) => {
      const newFilters = typeof value === 'function' ? value(v?.rawFilters) : value;
      return {
        rawFilters: newFilters,
        processedFilters: newFilters === undefined ? {} as F : filterProcessor?.(newFilters),
      };
    });
  }, [setFiltersBase]);

  useTableCache({
    tableName: name,
    pagination,
    filters: filters.rawFilters,
    sorters: sorters.rawSorters,
    processedFilters: filters.processedFilters,
    processedSorters: sorters.processedSorters,
    setPagination,
    setFilters,
    setSorters,
    cachePagination,
  });

  const pageInfo = useMemo<PageInfo>(() => ({
    page: pagination.current,
    size: pagination.pageSize,
    sort: sorters.processedSorters,
    pagination: true,
    countOnly: isCountOnly,
  }), [
    isCountOnly,
    initialPageSize,
    sorters,
    pagination,
  ]);

  const handleTableChange = useCallback<Exclude<TableProps<T>['onChange'], undefined>>((
    newPagination,
    newFilter = {},
    newSort = [],
  ) => {
    setPagination(newPagination);
    setFilters((v) => ({ ...v, ...newFilter }));
    // @ts-ignore
    setSorters(newSort);
  }, [
    setPagination,
    setSorters,
    setFilters,
  ]);

  const reset = useCallback(() => {
    setSorters(initialSort as Sorter<T> || []);
    setFilters(initialFilter as Filter || {});
    setPagination({
      current: initialPage,
      pageSize: initialPageSize,
    });
  }, [
    setPagination,
    setSorters,
    setFilters,
  ]);

  const goToPageOne = useCallback(() => {
    setPagination({
      ...pagination,
      current: initialPage,
    });
  }, [
    pagination,
    setPagination,
  ]);

  const hasFiltersAndSorters = useMemo(() => (
    !isEmpty(sorters.rawSorters) || !isEmpty(filters.rawFilters)
  ), [sorters, filters]);

  const filterCount = useMemo(() => (
    size(lodashFilter(filters.processedFilters || {}, (v) => !isNil(v)))
  ), [filters.processedFilters]);

  const response = useMemo(() => ({
    filter: filters.rawFilters,
    setFilter: setFilters,
    sort: sorters.rawSorters,
    setSort: setSorters,
    pagination,
    setPagination,
    goToPageOne,
    pageInfo,
    handleTableChange,
    processedFilter: filters.processedFilters,
    processedSort: sorters.processedSorters,
    reset,
    hasFiltersAndSorters,
    filterCount,
    name,
  }), [
    filters,
    sorters,
    pagination,
    pageInfo,
    setPagination,
    setFilters,
    setSorters,
    goToPageOne,
    handleTableChange,
    reset,
    hasFiltersAndSorters,
    filterCount,
    name,
  ]);

  return response;
};
