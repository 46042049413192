import { Button, Drawer, Typography } from 'antd';
import { useUpdateEffect, useWindowSize } from 'usehooks-ts';
import { useEffect } from 'react';
import { OutstandingContainer } from '../../container/OutstandingContainer/OutstandingContainer';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { MediumDrawerWidth } from '../../../../constants/drawer';
import './OutstandingDrawerButtonComponent.scss';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { useRouterState } from '../../../../hooks/useRouterState/useRouterState';
import { PatientPageState } from '../../../../hooks/useChangePage/types';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { usePatientPageControllerContext } from '../../../patient/context/PatientPageControllerContext/PatientPageControllerContext';
import { PatientProfilePageMinWidth } from '../../../patient/page/PatientProfilePageComponent/PatientProfilePageComponent';
import { updateItemCountAfterActions } from '../../hook/useOutstandingGetItemCount';
import { usePatientOutstandingItems } from '../../hook/usePatientOutstandingItems/usePatientOutstandingItems';

const { Text } = Typography;

export interface OutstandingDrawerButtonComponentProps {
  patientId?: string;
}

export const OutstandingDrawerButtonComponent = ({
  patientId,
}: OutstandingDrawerButtonComponentProps) => {
  const { state, removeState } = useRouterState();
  const {
    value: open,
    setTrue: setOpen,
    setFalse: setClose,
  } = useBoolean(false);
  const { info } = usePatientContext();
  const {
    totalOutstandingItemCount,
    refetchOutstandingItems,
  } = usePatientOutstandingItems(info);
  const { showOutstandingButton } = usePatientPageControllerContext();
  const size = useWindowSize();

  updateItemCountAfterActions(refetchOutstandingItems);
  useUpdateListener('MANUAL_MTPR_CREATED', setOpen);

  const handleOpen = () => {
    if (totalOutstandingItemCount) {
      setOpen();
    }
  };

  useUpdateEffect(() => {
    if (state && (state as PatientPageState)?.showOutstandingDrawer) {
      setOpen();
      removeState(['showOutstandingDrawer']);
    }
  }, [state]);

  useEffect(() => {
    if (showOutstandingButton && !totalOutstandingItemCount) {
      setClose();
    }
  }, [showOutstandingButton, totalOutstandingItemCount]);

  if (
    !showOutstandingButton // not always showing button
    && (
      size.width > PatientProfilePageMinWidth
      || !totalOutstandingItemCount
    )
  ) {
    return null;
  }

  return (
    <>
      <Button
        type="link"
        className="outstanding-drawer-button"
        onClick={handleOpen}
      >
        <Text ellipsis>
          Outstanding Items
          {' '}
          <Text className="b7 ml5">{totalOutstandingItemCount}</Text>
        </Text>
      </Button>
      <Drawer
        open={open}
        onClose={setClose}
        footer={null}
        placement="right"
        width={MediumDrawerWidth}
        destroyOnClose
      >
        <OutstandingContainer patientId={patientId ?? ''} />
      </Drawer>
    </>
  );
};
