import { useState } from 'react';
import { Button, Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { StickyNoteValue } from './types';
import { StickyNoteMetadataComponent } from './StickyNoteMetadataComponent';

export interface StickyNoteInputComponentProps {
    value: StickyNoteValue;
    onSubmit: (id: string, value: string) => void;
    onCancel: (id: string) => void;
}

export const StickyNoteInputComponent = ({
  value,
  onSubmit,
  onCancel,
}: StickyNoteInputComponentProps) => {
  const [note, setNote] = useState(value?.note || '');

  const handleChange: TextAreaProps['onChange'] = (evt) => {
    setNote(evt.target.value);
  };

  const handleSubmit = () => {
    onSubmit(value.id || '', note);
  };

  const handleCancel = () => {
    onCancel(value.id || '');
  };

  return (
    <div className="sticky-note-input-component">
      <StickyNoteMetadataComponent
        updatedAt={value.updatedAt}
        updatedByUser={value.updatedByUser}
      />
      <Input.TextArea value={note} onChange={handleChange} autoSize />
      <div className="flex mt10">
        <Button
          className="sticky-note-input-component__submit-btn"
          size="small"
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <Button
          className="sticky-note-input-component__cancel-btn"
          size="small"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
