import { Col, Row } from 'antd';
import { IntakeTableComponent } from '../IntakeTableComponent/IntakeTableComponent';
import { TitleWithContent } from '../../../../../uiComponent/TitleWithContent/TitleWithContent';
import './NutritionComponent.scss';
import { DietEnumListComponent } from '../../../../../enumComponent/DietEnumComponent/DietEnumListComponent';
import { NutritionFormComponentProps } from '../NutritionFormComponent/NutritionFormComponent';
import { FreqComponent } from '../../../../../uiComponent/FreqComponent/FreqComponent';
import { HistoryDrawerComponent } from '../../../../../uiComponent/HistoryComponent/HistoryDrawerComponent';
import { HistoryInfo } from '../../../../../uiComponent/HistoryComponent/HistoryItemComponent';

export interface NutritionComponentProps {
  value?: NutritionFormComponentProps['initialValues'];
  history?: HistoryInfo[];
}

export const NutritionComponent = ({
  value,
  history,
}: NutritionComponentProps) => (
  <div className="nutrition-component">
    <IntakeTableComponent
      intake={value?.intake}
    />
    <Row className="pt20" gutter={[8, 8]}>
      <Col span={6}>
        <TitleWithContent
          title="Fast Food Frequency"
          content={(
            <FreqComponent
              value={value?.fastFoodFreq}
              unit={value?.fastFoodFreqUnit}
            />
          )}
        />
      </Col>
      <Col span={6}>
        <TitleWithContent
          title="Sweet Beverage Frequency"
          content={(
            <FreqComponent
              value={value?.sweetBeverageFreq}
              unit={value?.sweetBeverageFreqUnit}
            />
          )}
        />
      </Col>
    </Row>
    <Row className="pt20">
      <Col span={24}>
        <TitleWithContent
          title="Previous Calorie Management Regimens"
          content={(
            <DietEnumListComponent
              value={value?.previousDiets ? value?.previousDiets : []}
              splitter=", "
            />
          )}
        />
      </Col>
    </Row>
    <Row className="pt20">
      <Col span={24}>
        <TitleWithContent
          title="Nutrition Understanding"
          content={value?.nutritionUnderstanding}
        />
      </Col>
    </Row>
    <Row className="pt20">
      <Col span={24}>
        <TitleWithContent
          title="Additional Comments"
          content={`${value?.additionalComments ? value?.additionalComments : 'N/A'}`}
        />
      </Col>
    </Row>
    <HistoryDrawerComponent history={history} />
  </div>
);
