export const ADMIN_PORTAL2_TEXTS = {
  tooltips: {
    noAssociatedCareUnit: 'This medical organization is not associated to any care unit.',
    billingEntityName: 'Netsuite name is the Alias of this Medical Organization.',
    netSuiteCustomerId: 'Netsuite customer ID is the customer ID of this Medical Organization in Netsuite.',
    officeNpi: 'Office NPI is used when completing claims information for certain clinics.',
    taxId: 'Tax ID is used by iHealth accounting to send invoices.',
    medicalOrgRecipientsEmails: 'Medical Organization recipients of billing report.',
    ihealthInternalRecipientsEmails: 'iHealth Group Leader or Account Managers(Additional to Recipients)',
    ihealthInternalCCRecipientsEmails: 'iHealth recipients of billing report, such as company leaders, billers etc.',
    addressName: "Address name refers to a label or identifier assigned to a specific address for easy reference or identification. It could be a name given to a particular people or location, such as 'Dr. Joe,' 'iHealth,' 'school,' etc.",
    medicalTooltip: 'Within this medical organization, providers will be able to access the patients of other providers.',
  },
  titles: {
    medicalOrgAlias: 'Alias',
    type: 'Type',
    associatedCareUnit: 'Associated Care Unit',
    createdAt: 'Creation Date',
    status: 'Status',
    userType: 'User Type',
    belongTo: 'Belong to',
    lastSignIn: 'Last Sign-in'
  },
  fieldNames: {
    billingEntityName: 'NetSuite name',
    netSuiteCustomerId: 'NetSuite customer ID',
    officeNpi: 'Office NPI',
    taxId: 'Tax ID',
    medicalOrgRecipientsEmails: 'Medical organization recipients email',
    ihealthInternalRecipientsEmails: 'iHealth internal recipients email',
    ihealthInternalCCRecipientsEmails: 'iHealth internal CC recipients email',
    businessAddress: 'Business address',
    addressName: 'Address name',
    cityStateZip: 'City, State, Zip',
  }
};

export const ADMIN_PORTAL_TEXTS = {
  tooltips: {
    billableTimeProgressBarTooltip: 'Please note that the current session time includes inactive time spent on the patient profile, and recorded time may not be fully billable.',
    billableTimeInTable: '<p>"Eligible for Billing" indicates that the care provided during the specified period meets the minimum criteria required for billing or reimbursement. Eligibility criteria for Chronic Care Management (CCM) may differ from those of Remote Patient Monitoring (RPM).</p><p>* RPM-Enrolled patients are "Eligible" when the care team has accrued the minimum billable time requirement of 20 minutes, and engaged in at least one phone or video call lasting more than 1 minute.</p><p>* CCM-Enrolled patients are eligible if the care team has accrued at least 20 minutes of billable time. </p><p>Note that actual billing report results may vary based on payer requirements or a patient\'s insurance coverage, or other RPM programs enrolled, resulting in non-coverage.</p>',
    measurementDaysInTable: '<p>"Eligible for Measurement" signifies that all RPM-enrolled patients become eligible when they digitally submit vital readings on a minimum of 16 days within a 30-day time frame </p><p>Note that actual billing report results may vary based on payer requirements or a patient\'s insurance coverage, or other RPM programs enrolled, resulting in non-coverage.</p>',
    otherEventsTooltip: '"Other Event" can be created to engage with potential or enrolled patients visiting the clinic. These events will be displayed in the clinic event view.',
  }
};

export const ADMIN_PORTAL_USER_TEXTS = {
  roleBased: {
    header: 'Role-based information',
    title: 'Permission to view data insights in care portal',
    description: 'If the user is permitted, the "Data insight" tab will be shown on his/her care portal.',
  },
};
