import { FC } from 'react';
import { PriorityEnumComponent } from '../../../../enumComponent/PriorityEnumComponent/PriorityEnumComponent';
import { PriorityLevelEnum } from '../../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { TicketPatientNameComponent } from '../TicketPatientNameComponent/TicketPatientNameComponent';
import { TicketTitleValueItemComponent } from '../TicketTitleValueItemComponent/TicketTitleValueItemComponent';
import './TicketInfoComponent.scss';

export interface TicketInfoComponentProps {
  priorityLevel?: PriorityLevelEnum;
  ticketNumberDisplay?: string;
  patient?: string;
  dueDate?: string;
  creator?: string;
  assignee?: string;
  description?: string;
  memberId?: string;
}

export const TicketInfoComponent: FC<TicketInfoComponentProps> = ({
  priorityLevel,
  ticketNumberDisplay,
  patient,
  dueDate,
  creator,
  assignee,
  description,
  memberId,
}) => (
  <>
    <TicketTitleValueItemComponent title="Task ID" content={ticketNumberDisplay} />
    <TicketTitleValueItemComponent
      title="Priority"
      content={(
        <PriorityEnumComponent value={priorityLevel} />
      )}
    />
    <TicketTitleValueItemComponent
      title="Patient"
      content={(
        <TicketPatientNameComponent
          memberId={memberId ?? undefined}
          patientName={patient}
        />
      )}
    />
    <TicketTitleValueItemComponent title="Due Date" content={<DisplayDateComponent value={dueDate} format="USA_DATE" />} />
    <TicketTitleValueItemComponent title="Creator" content={creator} />
    <TicketTitleValueItemComponent title="Assignee" content={assignee} />
    <TicketTitleValueItemComponent title="Description" />
    <div className="ticket-info-description">
      {description}
    </div>
  </>
);
