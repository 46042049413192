import { ReactNode } from 'react';
import { InsuranceEligibilityStatusEnumComponent } from '../../../../enumComponent/EligibilityStatusEnumComponent/EligibilityStatusEnumComponent';
import { EligibilityReport, EligibilityStatusEnum } from '../../../../uc-api-sdk';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { Title } from '../../../../uiComponent/Title/Title';
import { UpdatedByAtComponent } from '../../../../user/component/UpdatedByAtComponent/UpdatedByAtComponent';

import './InsuranceEligibilityReportComponent.scss';

export interface InsuranceEligibilityReportComponentProps {
  status?: EligibilityStatusEnum;
  inquiryInfo?: EligibilityReport;
  title?: ReactNode;
  children?: ReactNode; // html template / pdf
}

export const InsuranceEligibilityReportComponent = ({
  status,
  inquiryInfo,
  title,
  children,
}: InsuranceEligibilityReportComponentProps) => (
  <div className="eligibility-report">
    <Title className="flex ai-c jc-c mb20">
      {
        title
        || (
          <>
            <InsuranceEligibilityStatusEnumComponent
              value={status}
              showIconOnly
            />
            Eligibility Result:
            {' '}
            <InsuranceEligibilityStatusEnumComponent
              value={status}
              showIcon={false}
            />
          </>
        )
      }
    </Title>
    <TextComponent
      color="gray2"
      italic
    >
      <div className="flex mb20 ai-c jc-e">
        Inquired on
        {' '}
        <UpdatedByAtComponent
          info={{
            updatedAt: inquiryInfo?.serviceDate,
            updatedByUser: inquiryInfo?.inquiredByUser,
          }}
        />
      </div>
    </TextComponent>
    <div>
      <b>Note</b>
      {' '}
      Eligibility verification results may vary for each payer and patient.
      If you have any questions about coverage and plan benefits,
      please consult the iHealth Unified Care Billing team.
    </div>
    <div className="my40">
      {children}
    </div>
  </div>
);
