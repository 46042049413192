import { ReactNode } from 'react';
import { Select, SelectProps } from 'antd';
import { map } from 'lodash';
import { InputType } from '../../features/Input/types';
import { BasicEnumComponent } from '../../enumComponent/BasicEnumComponent/BasicEnumComponent';

export interface OptionRendererProps<T> {
  value: T;
}

export interface EnumSelectComponentProps<T extends string> extends InputType<T> {
  options?: T[] | Record<string, T>;
  optionRenderer?: (props: OptionRendererProps<T>) => ReactNode;
  disable?: boolean;
  placeholder?: string;
  className?: string;
  mode?: SelectProps['mode'];
  showSearch?: SelectProps['showSearch'];
  optionFilterProp?: SelectProps['optionFilterProp'];
  filterOption?: SelectProps['filterOption'];
  allowClear?: boolean;
}
export const EnumSelectComponent = <T extends string>({
  options = [],
  optionRenderer = BasicEnumComponent,
  disable = false,
  value,
  onChange,
  placeholder,
  className = '',
  mode,
  showSearch,
  optionFilterProp,
  filterOption,
  allowClear,
}: EnumSelectComponentProps<T>) => {
  const renderOptions = (option: T, index: number) => (
    <Select.Option
      key={index}
      value={option}
    >
      <div>{optionRenderer({ value: option })}</div>
    </Select.Option>
  );

  const handleChange: SelectProps['onChange'] = (v) => {
    onChange?.(v as T);
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      className={className}
      mode={mode}
      showSearch={showSearch}
      optionFilterProp={optionFilterProp}
      filterOption={filterOption}
      // to get support from antd form
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(disable ? { disabled: true } : {})}
      allowClear={allowClear}
    >
      {map(options, renderOptions)}
    </Select>
  );
};
