import { ReactNode, useEffect } from 'react';
import { useAnchor } from './AnchorContext';

export interface AnchorLinkComponentProps {
  children: ReactNode;
  title: string;
  id: string;
  href: string;
}

export const AnchorLinkComponent = ({
  children,
  title,
  id,
  href,
}: AnchorLinkComponentProps) => {
  const { addNewAnchor } = useAnchor();

  useEffect(() => {
    addNewAnchor({
      title,
      href,
      key: id.toLocaleLowerCase(),
    });
  }, []);

  return (
    <div id={id.toLocaleLowerCase()} className="anchor-link-component">
      {children}
    </div>
  );
};
