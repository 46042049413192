import { Table } from 'antd';
import { TableProps } from 'antd/es/table';
import { uniqueId } from 'lodash';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { OrderStaging } from '../../../../uc-api-sdk';
import { createColumns } from './column';

export interface OrderTableComponentProps extends Omit<TableProps<OrderStaging>, 'column'> {
}

export const OrderTableComponent = ({
  pagination,
  dataSource,
  ...props
}: OrderTableComponentProps) => {
  const columns = useTableColumns(
    createColumns(),
    ['orderItems', 'createdAt', 'orderByName', 'shipTo', 'note'],
  );

  return (
    <Table<OrderStaging>
      size="middle"
      columns={columns}
      pagination={pagination}
      dataSource={dataSource}
      rowKey={(v: OrderStaging) => `${v.id}-${uniqueId()}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};
