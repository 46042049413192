import { useMemo } from 'react';
import { map } from 'lodash';
import { useClinicEventSearch } from '../../../../uc-api-sdk';
import { OutstandingVisitComponent } from '../../component/OutstandingVisitComponent/OutstandingVisitComponent';
import { ClinicEventsService } from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';

export interface OutstandingVisitContainerProps {
  patientId: string;
  visitIds: string[];
}

export const OutstandingVisitContainer = ({
  patientId,
  visitIds,
}: OutstandingVisitContainerProps) => {
  const outstandingVisitsSearch = useClinicEventSearch({
    options: { sendOnMount: true },
    params: {
      filter: {
        patientId,
        idIn: { in: visitIds }
      },
    },
  });

  const debouncedOutstandingVisitSearchRefetch = useDebounce(
    outstandingVisitsSearch.refetch,
    250,
    [patientId, visitIds],
  );
  useUpdateListener('VISIT_CREATED', debouncedOutstandingVisitSearchRefetch);
  useUpdateListener('VISIT_UPDATED', debouncedOutstandingVisitSearchRefetch);
  useUpdateListener('VISIT_DELETED', debouncedOutstandingVisitSearchRefetch);
  useUpdateListener('WORKLIST_CLOSED', debouncedOutstandingVisitSearchRefetch);
  useUpdateListener('VIDEO_VISIT_STATUS_UPDATED', debouncedOutstandingVisitSearchRefetch);
  useUpdateListener('VIDEO_VISIT_ENDED', debouncedOutstandingVisitSearchRefetch);
  useUpdateListener('TRANSCRIBE_FINISHED', debouncedOutstandingVisitSearchRefetch);

  const outstandingVisitList = useMemo(() => (
    map(outstandingVisitsSearch.data?.data?.content, (event) => (
      new ClinicEventsService(event)
    ))
  ), [outstandingVisitsSearch.data]);

  return (
    <div>
      {map(outstandingVisitList, (visit) => (
        <OutstandingVisitComponent
          key={visit.id}
          visit={visit}
        />
      ))}
    </div>
  );
};
