import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface VersionDisplayComponentProps {
  versionName: string;
  versionNumber?: string;
}

export const VersionDisplayComponent = ({
  versionName,
  versionNumber,
}: VersionDisplayComponentProps) => (
  <div>
    <span>
      {versionName}
    </span>
    <span>
      :
      {' '}
      v.
      <DisplayOrEmptyComponent value={versionNumber} />
    </span>
  </div>
);
