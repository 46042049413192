import { BaseSortDirection } from '../../uc-api-sdk/staticFiles/useReqHook';
import {
  WebflowLanguageAbbreviationMap,
  WebflowLanguageEnum,
  LanguageToWebflowLanguageMap,
} from './types';

export const SEARCH_ARTICLES_PAGE_SIZE = 20;

export const SENT_HISTORY_TABLE_PAGE_SIZE = 10;

export const NO_ARTICLE_FOUND = 'No article found';

export const SENT_HISTORY_TABLE_DEFAULT_SORT = [
  {
    property: 'updatedAt',
    direction: BaseSortDirection.DESC,
  },
];

export const webflowLanguageAbbreviationMap = {
  [WebflowLanguageEnum.en]: 'ENG',
  [WebflowLanguageEnum.zhTw]: 'CAN',
  [WebflowLanguageEnum.zhCn]: 'MAN',
  [WebflowLanguageEnum.es]: 'SPA',
  [WebflowLanguageEnum.vi]: 'VIE',
} as WebflowLanguageAbbreviationMap;

export const languageToWebflowLanguageMap = {
  EL: WebflowLanguageEnum.en,
  CT: WebflowLanguageEnum.zhTw,
  MN: WebflowLanguageEnum.zhCn,
  SP: WebflowLanguageEnum.es,
  VN: WebflowLanguageEnum.vi,
} as LanguageToWebflowLanguageMap;

export const articleMetaDataFields = [
  'id',
  'url',
  'name',
  'thumbnailImageUrl',
  'summary',
  'slug',
  'hasVideo',
  'isNew',
  'language',
  'publishDate',
  'updatedAt',
  'createdAt',
];
