import { ReactNode } from 'react';
import classNames from 'classnames';
import { getChildByDisplayName, makeLayoutItem } from '../../../../lib/layout';
import { useDeepCompareMemo } from '../../../../hooks/useDeepCompareEffect';

import './DiseaseHistoryItemLayoutComponent.scss';

const ConditionDisplayName = 'Condition';
const InfoDisplayName = 'Info';

export interface DiseaseHistoryItemLayoutComponentProps {
  className?: string;
  children: ReactNode;
}

export const DiseaseHistoryItemLayoutComponent = ({
  className = '',
  children,
}: DiseaseHistoryItemLayoutComponentProps) => {
  const [
    Condition,
    Info,
  ] = useDeepCompareMemo(() => ([
    getChildByDisplayName(ConditionDisplayName, children)[0],
    getChildByDisplayName(InfoDisplayName, children)[0],
  ]), [children]);

  return (
    <div
      className={classNames({
        'disease-history-item-layout': true,
        'flex ai-s jc-s f-w my20': true,
        [className]: !!className,
      })}
    >
      <div className="disease-history-item-layout__condition">
        {Condition}
      </div>
      <div className="disease-history-item-layout__info f1">
        {Info}
      </div>
    </div>
  );
};

DiseaseHistoryItemLayoutComponent.Condition = makeLayoutItem(ConditionDisplayName);
DiseaseHistoryItemLayoutComponent.Info = makeLayoutItem(InfoDisplayName);
