import { DeviceModelEnum } from '../../../../uc-api-sdk';
import { ConnectedDeviceInfo } from '../../type';
import { DeviceAssignmentFormComponent } from '../DeviceAssignmentFormComponent/DeviceAssignmentFormComponent';
import { DeviceUnassignComponent } from '../DeviceUnassignComponent/DeviceUnassignComponent';
import { NestedFormComponent } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';

export interface DeviceAssignUnassignComponentProps {
  deviceModel: DeviceModelEnum;
  assignedDevice?: ConnectedDeviceInfo;
  onAssign: (device: Partial<ConnectedDeviceInfo>) => void;
  onUnassign: (device: ConnectedDeviceInfo) => void;
  disabled?: boolean;
}

export const DeviceAssignUnassignComponent = ({
  deviceModel,
  assignedDevice,
  onAssign,
  onUnassign,
  disabled,
}: DeviceAssignUnassignComponentProps) => {
  const {
    deviceId: assignedDeviceId,
    deviceModel: assignedDeviceModel,
  } = assignedDevice || {};

  if (
    assignedDevice
    && assignedDeviceId
    && (assignedDeviceModel === deviceModel)
  ) {
    return (
      <DeviceUnassignComponent
        deviceId={assignedDeviceId}
        deviceModel={deviceModel}
        onUnassign={() => onUnassign(assignedDevice)}
        disabled={disabled}
      />
    );
  }

  return (
    <NestedFormComponent>
      <DeviceAssignmentFormComponent
        deviceModel={deviceModel}
        onSubmit={onAssign}
        boxShadow={false}
        disabled={disabled}
      />
    </NestedFormComponent>
  );
};
