import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { useInterval } from 'usehooks-ts';
import { TimeService } from '../../helpers/time/timeService';

export interface TimeUpdaterComponentProps {
  startTime?: Dayjs | string;
  updateRate?: number | null;
  stopWatchMaxInSecond?: number;
  showSecond?: boolean;
}

export const TimeUpdaterComponent = ({
  startTime,
  updateRate = 1000,
  stopWatchMaxInSecond = 0,
  showSecond = false,
}: TimeUpdaterComponentProps) => {
  const [time, setTime] = useState(dayjs());
  const [delay, setDelay] = useState(updateRate);
  let diff: string | number;
  if (showSecond) {
    diff = TimeService.getDifferenceInMinutesAndSeconds(dayjs(startTime), time);
  } else {
    diff = TimeService.getDifferenceInMinutes(dayjs(startTime), time);
  }

  useInterval(() => {
    if (stopWatchMaxInSecond !== 0
       && TimeService.getDifferenceInMinutes(dayjs(startTime), time) * 60 >= stopWatchMaxInSecond) {
      setDelay(null);
    } else {
      setTime(dayjs());
    }
  }, delay);

  return (
    <span>
      {diff}
      {/* show '+' when stopwatch reach max */}
      {delay === null ? '+' : ''}
    </span>
  );
};
