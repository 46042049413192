import { Select, SelectProps } from 'antd';
import { FC } from 'react';
import './SelectDropdownComponent.scss';

export interface DropdownOptions {
  value: string;
  label: string;
}

export interface SelectDropdownComponentProps {
  options: DropdownOptions[];
  value?: string;
  onChange?: SelectProps['onChange'];
}

export const SelectDropdownComponent: FC<SelectDropdownComponentProps> = ({
  options, value = '', onChange,
}) => (
  <Select
    popupClassName="default-br"
    data-testid="selectDropdown"
    className="w100 my10 selectDropdownPopup"
    placeholder="select"
    optionFilterProp="children"
    onChange={onChange}
    options={options}
    defaultActiveFirstOption={false}
    defaultValue={value}
  />
);
