import {
  BPM1_CUFF_IMG,
  EASE_CUFF_IMG,
  HOTSPOT_IMG,
  LANCETS_IMG,
  BG_STRIP_IMG,
  WIFI_ROUTER_IMG,
} from '../../../../assets/deviceIconInfo';
import { SupplyTypeEnum } from '../../type';

export interface SupplyImageComponentProps {
  value: SupplyTypeEnum;
}

export const SupplyImageComponent = ({
  value,
}: SupplyImageComponentProps) => {
  const getImageInfo = () => {
    switch (value) {
      case SupplyTypeEnum.BG_STRIP:
        return BG_STRIP_IMG;
      case SupplyTypeEnum.LANCETS:
        return LANCETS_IMG;
      case SupplyTypeEnum.BPM1_CUFF:
        return BPM1_CUFF_IMG;
      case SupplyTypeEnum.EASE_CUFF:
        return EASE_CUFF_IMG;
      case SupplyTypeEnum.WIFI_ROUTER:
        return WIFI_ROUTER_IMG;
      case SupplyTypeEnum.HOTSPOT:
        return HOTSPOT_IMG;
      default:
        return { src: '', alt: '' };
    }
  };

  const { src, alt } = getImageInfo();

  return (
    <img
      className="supply-image"
      src={src}
      alt={alt}
    />
  );
};
