import React from 'react';
import classNames from 'classnames';
import PatientSearchSelectComponent, { PatientSearchSelectComponentInfo, PatientSearchSelectComponentProps } from '../../../patient/component/PatientSearchSelectComponent/PatientSearchSelectComponent';

export interface EmployeeSelectComponentInfo<T> extends PatientSearchSelectComponentInfo<T> {
}

export interface EmployeeSelectComponentProps<T> extends PatientSearchSelectComponentProps<T> {
}

export const EmployeeSelectComponent = <T, >({
  className = '',
  ...props
}: EmployeeSelectComponentProps<T>) => (
  <PatientSearchSelectComponent
    className={classNames({
      'employee-search-component': true,
      [className]: !!className,
    })}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
  );
