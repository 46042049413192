/* eslint-disable no-underscore-dangle */
import { Employee } from '../../types/user';

export const _getFirstName = (employee: Employee) => employee.employeeProfile?.firstName;

export const _getLastName = (employee: Employee) => employee.employeeProfile?.lastName;

export const _getFullName = (employee: Employee) => {
  const firstName = _getLastName(employee);
  const lastName = _getLastName(employee);
  if (firstName && lastName) {
    return `${_getFirstName(employee)} ${_getLastName(employee)}`;
  }
  if (firstName) {
    return firstName;
  }
  if (lastName) {
    return lastName;
  }
  return undefined;
};

export const _getLanguage = (employee: Employee) => (
  employee.employeeProfile?.language
);

export const _getCredential = (employee: Employee) => (
  employee.employeeProfile?.credentials?.join(', ')
);

export const _getDegree = (employee: Employee) => (
  employee.employeeProfile?.degree
);

export const _getSpecialties = (employee: Employee) => (
  employee.employeeProfile?.specialties
);

export const _getWorkState = (employee: Employee) => (
  employee.employeeProfile?.workState
);

export const _getWorkTimezone = (employee: Employee) => (
  employee.employeeProfile?.workTimezone
);

export const _getDirectAddress = (employee: Employee) => (
  employee.employeeProfile?.directAddress
);

export const _getNPI = (employee: Employee) => (
  employee.employeeProfile?.npi
);

export const _getPhone = (employee: Employee) => (
  employee.employeeProfile?.phone
);

export const _getFullNameWithCredential = (employee: Employee) => {
  const fullName = _getFullName(employee);
  const credential = _getCredential(employee);
  return `${fullName}${credential ? `, ${credential}` : ''}`;
};
