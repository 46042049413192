import React, { FC } from 'react';
import './FoodLogPatientCommentComponent.scss';

export interface FoodLogPatientCommentComponentProps {
  comment: string;
}

export const FoodLogPatientCommentComponent: FC<FoodLogPatientCommentComponentProps> = ({
  comment,
}) => (
  <div className="patientNotes">
    {comment}
  </div>
);
