import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { InitialAssessmentCardComponent } from '../../component/OnboardingCardComponent/InitialAssessmentCardComponent';

export interface InitialAssessmentCardContainerProps {
  patientInfo: PatientInfo;
}

export const InitialAssessmentCardContainer = ({
  patientInfo,
}: InitialAssessmentCardContainerProps) => (
  <InitialAssessmentCardComponent
    patientInfo={patientInfo}
  />
);
