import { isEqual, map } from 'lodash';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useEffectOnce, useUpdateEffect } from 'usehooks-ts';

const useMemoizedValue = <T>(value: T) => {
  const ref = useRef<T>();

  useEffectOnce(() => () => {
    // clean up on unmount
    ref.current = undefined;
  });

  if (!isEqual(value, ref?.current)) {
    ref.current = value;
  }

  return ref.current;
};

// this will be called during initial render
export const useDeepCompareEffectWithInitialRender = <T>(
  callback: () => void,
  dependencies: T[],
) => {
  useEffect(
    callback,
    map(dependencies, useMemoizedValue),
  );
};

// this will NOT be called during initial render
export const useDeepCompareEffect = <T>(
  callback: () => void,
  dependencies: T[],
) => {
  useUpdateEffect(
    callback,
    map(dependencies, useMemoizedValue),
  );
};

export const useDeepCompareCallback: typeof useCallback = (
  callback,
  dependencies,
) => (
  useCallback(
    callback,
    map(dependencies, useMemoizedValue),
  )
);

export const useDeepCompareMemo: typeof useMemo = (
  callback,
  dependencies,
) => (
  useMemo(
    callback,
    map(dependencies, useMemoizedValue),
  )
);
