import { first } from 'lodash';
import { TableProps } from 'antd';
import {
  Ticket,
} from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { TicketTabsComponent } from '../../component/TicketTabsComponent/TicketTabsComponent';
import { useTicketSearchInOutstanding } from '../../hook/useGetTicket';
import { TicketViewContainer } from '../TicketViewContainer/TicketViewContainer';

export interface TicketTabsContainerProps extends TableProps<Ticket> {
  ids: string[],
}
export const TicketTabsContainer = ({
  ids,
}: TicketTabsContainerProps) => {
  const ticketListInfo = useTicketSearchInOutstanding(ids);

  useUpdateListener('TICKET_RESOLVED', ticketListInfo.refetch);
  useUpdateListener('TICKET_EDITED', ticketListInfo.refetch);
  useUpdateListener('TICKET_CREATE', ticketListInfo.refetch);

  const content = (data: Ticket[]) => {
    if (data.length > 1) {
      return <TicketTabsComponent dataSource={data} />;
    }
    if (data.length === 1) {
      return <TicketViewContainer ticketId={first(data)?.id ?? ''} patientInputDisable />;
    }
    return null;
  };

  return (
    <FetchComponent
      info={ticketListInfo}
      alwaysShowChildren
      showErrorOverlay={false}
      showLoadingOverlay={false}
      emptyOverlay={<h2>There Are No Tasks!!!</h2>}
    >
      {(value) => {
        const data: Ticket[] = value?.rawValue?.data?.content;
        return data && data.length && content(data);
      }}
    </FetchComponent>
  );
};
