import React, { FC } from 'react';
import { AutoCompleteProps } from 'antd';
import { MedicationNameSelectComponent } from '../../../selectComponents/MedicationNameSelectComponent/MedicationNameSelectComponent';
import { Medication, useMedicationGetMedNames } from '../../../uc-api-sdk';
import { FetchComponent } from '../../../uiComponent/FetchComponent/FetchComponent';

export interface MeidcationNameContainerProps {
  value?: string;
  onChange?: AutoCompleteProps['onChange'],
}
export const MeidcationNameContainer: FC<MeidcationNameContainerProps> = ({
  value,
  onChange,
}) => {
  const medNames = useMedicationGetMedNames({
    options: { sendOnMount: true },
    params: {},
  });
  return (
    <FetchComponent
      info={medNames}
      alwaysShowChildren
      showEmptyOverlay={false}
    >
      {(v) => {
        const names = (v?.rawValue?.data || []).map((m: Medication) => m.name);
        return (
          <MedicationNameSelectComponent
            names={names}
            value={value}
            onChange={onChange}
          />
        );
      }}
    </FetchComponent>
  );
};
