import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { AnchorComponent } from '../../../../uiComponent/AnchorComponent/AnchorComponent';
import { AnchorLinkComponent } from '../../../../uiComponent/AnchorComponent/AnchorLinkComponent';
import { AppInfoContainer } from '../../container/AppInfoContainer/AppInfoContainer';
import { DeviceTableContainer } from '../../container/DeviceTableContainer/DeviceTableContainer';
import { PatientCommonCardTitleComponent } from '../../../patient/component/PatientCommonCardTitleComponent/PatientCommonCardTitleComponent';
import { OrderTableContainer } from '../../container/OrderTableContainer/OrderTableContainer';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { OrderSuppliesReplacementDrawerButtonComponent } from '../../../order/component/OrderSuppliesReplacementDrawerButtonComponent/OrderSuppliesReplacementDrawerButtonComponent';

import './DeviceComponent.scss';

export const DeviceTabAnchors = {
  device: {
    label: 'Device',
    href: '#device',
  },
  appInfo: {
    label: 'App Info',
    href: '#appInfo',
  },
  order: {
    label: 'Orders',
    href: '#order',
  },
};

export const DeviceComponent = () => {
  const { info } = usePatientContext();
  const { id = '' } = info || {};
  return (
    <AnchorComponent targetOffset={32} hideAnchorLinks>
      <div className="flex fd-c w100 gap4 device-component">
        <div className="flex jc-e order-drawer-button">
          <OrderSuppliesReplacementDrawerButtonComponent
            patientId={id}
          />
        </div>
        <AnchorLinkComponent
          title={DeviceTabAnchors.device.label}
          id="device"
          href={DeviceTabAnchors.device.href}
        >
          <PatientCommonCardTitleComponent>
            Devices
          </PatientCommonCardTitleComponent>
          <DeviceTableContainer
            patientId={id}
          />
        </AnchorLinkComponent>
        <AnchorLinkComponent
          title={DeviceTabAnchors.appInfo.label}
          id="appInfo"
          href={DeviceTabAnchors.appInfo.href}
        >
          <PatientCommonCardComponent
            title="App Info"
            showEdit={false}
            content={(
              <AppInfoContainer
                patientId={id}
              />
            )}
          />
        </AnchorLinkComponent>
        <AnchorLinkComponent
          title={DeviceTabAnchors.order.label}
          id="order"
          href={DeviceTabAnchors.order.href}
        >
          <PatientCommonCardTitleComponent className="mb20">
            Orders
          </PatientCommonCardTitleComponent>
          <OrderTableContainer
            patientId={id}
          />
        </AnchorLinkComponent>
      </div>
    </AnchorComponent>
  );
};
