import { AccountHelper } from '../../../../../helpers/account';
import { LimitedUser } from '../../../../../uc-api-sdk';
import EmptyComponent from '../../../../../uiComponent/EmptyComponent/EmptyComponent';

export interface PrintCarePlanUserComponentProps {
  profile?: LimitedUser | null;
  split?: boolean;
  showWhenEmpty?: boolean;
}

export const PrintCarePlanUserComponent = ({
  profile,
  split = true,
  showWhenEmpty,
}: PrintCarePlanUserComponentProps) => {
  const renderEmpty = () => (showWhenEmpty ? <EmptyComponent /> : null);

  return (
    profile
      ? (
        <span>
          {AccountHelper.getFullName(profile)}
          {
            profile?.title
              ? (
                <span>
                  {', '}
                  {profile?.title?.replaceAll('/', ', ')}
                </span>
              ) : null
          }
          {split && '; '}
        </span>
      ) : renderEmpty()
  );
};
