import {
  Button,
  Divider,
  Form,
  Popconfirm,
} from 'antd';
import { useBoolean } from 'usehooks-ts';
import {
  ReactNode,
  useMemo,
  useState,
} from 'react';
import {
  filter,
  findIndex,
  isEqual,
  map,
  uniq,
  uniqBy,
} from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import { DeviceUnassignPromptTitleComponent } from '../DeviceUnassignPromptTitleComponent/DeviceUnassignPromptTitleComponent';
import { CuffSizeEnum, MonitorMethodEnum, VitalEnumType } from '../../../../uc-api-sdk';
import { FixedChildComponent } from '../../../../uiComponent/FixedComponent/FixedChildComponent';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import { DeviceHelper } from '../../helper';
import { DeviceMonitorWarningComponent } from '../DeviceMonitorWarningComponent/DeviceMonitorWarningComponent';
import { FormType } from '../../../Input/types';
import { ConnectedDeviceInfo } from '../../type';
import { deviceModelWithCuffSize } from '../../constant';
import { AppDeviceInfoComponent } from '../AppDeviceInfoComponent/AppDeviceInfoComponent';
import { DeviceCuffSizeComponent } from '../DeviceCuffSizeComponent/DeviceCuffSizeComponent';
import { getDefaultDeviceByVitalType, mapMethodToNonApp } from '../../hook/useGetDeviceInfo';
import { useDeviceMonitorFormProvider } from '../../hook/useDeviceMonitorFormProvider';
import { DeviceMonitorMethodFormItemComponent } from '../DeviceMonitorMethodFormItemComponent/DeviceMonitorMethodFormItemComponent';
import { DisabledButtonWithFeedbackComponent } from '../../../../uiComponent/DisabledButtonWithFeedbackComponent/DisabledButtonWithFeedbackComponent';

import './DeviceMonitorFormComponent.scss';

interface InitialValues {
  monitorMethod?: MonitorMethodEnum;
  connectedDevice?: ConnectedDeviceInfo;
  cuffSize?: CuffSizeEnum;
  initialConnectedDevices?: Record<MonitorMethodEnum, ConnectedDeviceInfo>;
}

export interface SubmitValue extends InitialValues {
  devicesToUnassignIds?: string[];
}

export interface DeviceMonitorFormComponentChildrenProps {
  selectedMonitorMethod?: MonitorMethodEnum;
  assignedDevice?: ConnectedDeviceInfo;
  onAssign: (device: Partial<ConnectedDeviceInfo>) => void;
  onUnassign: (device: ConnectedDeviceInfo) => void;
}

export interface DeviceMonitorFormComponentProps extends FormType<SubmitValue> {
  vitalType: VitalEnumType;
  initialValues?: InitialValues;
  onSubmit: (values?: SubmitValue) => void;
  children?: (props: DeviceMonitorFormComponentChildrenProps) => ReactNode;
}

export const DeviceMonitorFormComponent = ({
  vitalType,
  initialValues,
  isLoading,
  onSubmit,
  onCancel,
  children,
}: DeviceMonitorFormComponentProps) => {
  const {
    monitorMethod: initialMonitorMethod,
    connectedDevice: initialConnectedDeviceByMethod,
    cuffSize: initialCuffSize,
    initialConnectedDevices = {} as Record<MonitorMethodEnum, ConnectedDeviceInfo>,
  } = initialValues || {};
  const {
    value: isPopconfirmOpen,
    setValue: setIsPopconfirmOpen,
    setTrue: openPopconfirm,
    setFalse: closePopconfirm,
  } = useBoolean();
  const [
    connectedDevice,
    setConnectedDevice,
  ] = useState<ConnectedDeviceInfo | undefined>(initialConnectedDeviceByMethod);
  const [
    cuffSize,
    setCuffSize,
  ] = useState<CuffSizeEnum | undefined>(initialCuffSize);
  const [
    devicesToUnassign,
    setDevicesToUnassign,
  ] = useState<ConnectedDeviceInfo[]>([]);
  const [
    selectedMonitorMethod,
    setSelectedMonitorMethod,
  ] = useState<MonitorMethodEnum | undefined>(
    mapMethodToNonApp(initialMonitorMethod) as MonitorMethodEnum
    || MonitorMethodEnum.APP_MONITOR
  );
  const deviceMonitorFormProvider = useDeviceMonitorFormProvider();

  const devicesToUnassignIds = useMemo(() => map(devicesToUnassign, 'deviceId'), [devicesToUnassign]);

  const isConnectedDeviceRequired = ![
    MonitorMethodEnum.APP_MONITOR,
    MonitorMethodEnum.MANUALLY_INPUT,
  ].includes(selectedMonitorMethod as MonitorMethodEnum);

  const devicesNeedToUnassign = useMemo(() => (
    filter(initialConnectedDevices, (d) => {
      const deviceMethod = DeviceHelper.getMethodByModel(d.deviceModel);
      return (
        !devicesToUnassignIds.includes(d.deviceId)
        && ![selectedMonitorMethod, MonitorMethodEnum.APP_MONITOR].includes(deviceMethod)
      );
    })
  ), [
    selectedMonitorMethod,
    initialConnectedDevices,
    devicesToUnassignIds,
  ]);

  const isDeviceRequiredToSubmit = (isConnectedDeviceRequired && !connectedDevice?.deviceId);

  const isSubmitDisabled = (
    isLoading
    || !selectedMonitorMethod
    || isDeviceRequiredToSubmit
    || (
      initialMonitorMethod === selectedMonitorMethod
      && isEqual(initialConnectedDeviceByMethod, connectedDevice)
      && (initialConnectedDeviceByMethod?.cuffSize ?? undefined) === (cuffSize ?? undefined)
      && devicesToUnassignIds.length < 1
    )
  );

  const handleSetConnectedDevice = (device: ConnectedDeviceInfo) => {
    const deviceMethod = DeviceHelper.getMethodByModel(device.deviceModel);
    if (deviceMethod) {
      setConnectedDevice(device);
    }
  };

  const handleUnsetConnectedDevice = (device: ConnectedDeviceInfo) => {
    const deviceMethod = DeviceHelper.getMethodByModel(device.deviceModel);
    if (deviceMethod === selectedMonitorMethod) {
      setConnectedDevice(undefined);
    }
  };

  const handleOnAssign = (device: Partial<ConnectedDeviceInfo>) => {
    handleSetConnectedDevice(device as ConnectedDeviceInfo);
  };

  const handleOnUnassign = (device: ConnectedDeviceInfo) => {
    const isDeviceAssigned = (
      findIndex(
        Object.values(initialConnectedDevices),
        (d) => d.deviceId === device.deviceId,
      ) !== -1
    );
    if (isDeviceAssigned) {
      // add to unassign list
      const newUnassignList = [...devicesToUnassign, device];
      setDevicesToUnassign(uniqBy(newUnassignList, 'deviceId'));
    }
    handleUnsetConnectedDevice(device);
  };

  const handleOnValuesChange = (monitorMethod?: MonitorMethodEnum) => {
    const mappedMonitorMethod = mapMethodToNonApp(monitorMethod) as MonitorMethodEnum;
    if (selectedMonitorMethod !== mappedMonitorMethod) {
      const connectedDevice = initialConnectedDevices[mappedMonitorMethod];
      setConnectedDevice(
        !devicesToUnassignIds.includes(connectedDevice?.deviceId || '')
          ? connectedDevice
          : undefined
      );
      setSelectedMonitorMethod(mappedMonitorMethod);
      setCuffSize(undefined);
    }
  };

  const handleOnSubmit = () => {
    if (devicesNeedToUnassign.length) {
      openPopconfirm();
      return;
    }
    if (
      initialMonitorMethod === selectedMonitorMethod
      && selectedMonitorMethod === MonitorMethodEnum.NON_APP_MONITOR
      && connectedDevice?.deviceId
    ) {
      if (
        connectedDevice?.deviceId !== initialConnectedDeviceByMethod?.deviceId
        && connectedDevice?.deviceMethod === initialConnectedDeviceByMethod?.deviceMethod
        && connectedDevice?.deviceModel === initialConnectedDeviceByMethod?.deviceModel
      ) {
        // when only the same non-app device's Id is updated, unassign old deviceId
        devicesToUnassignIds.push(initialConnectedDeviceByMethod?.deviceId as string);
      }
    }
    onSubmit?.({
      devicesToUnassignIds: uniq(devicesToUnassignIds),
      monitorMethod: selectedMonitorMethod,
      connectedDevice,
      cuffSize,
    });
  };

  const renderAppDeviceInfo = () => {
    const appDevice = initialConnectedDevices.APP_MONITOR || getDefaultDeviceByVitalType(vitalType);
    return (
      <AppDeviceInfoComponent
        deviceId={appDevice.deviceId}
        deviceModel={appDevice.deviceModel}
      >
        {
          deviceModelWithCuffSize.includes(appDevice.deviceModel)
          && (
            <DeviceCuffSizeComponent
              value={cuffSize}
              onChange={setCuffSize}
            />
          )
        }
      </AppDeviceInfoComponent>
    );
  };

  const renderChildren = () => {
    if (selectedMonitorMethod === MonitorMethodEnum.APP_MONITOR) {
      return renderAppDeviceInfo();
    }
    return children?.({
      selectedMonitorMethod,
      assignedDevice: connectedDevice,
      onAssign: handleOnAssign,
      onUnassign: handleOnUnassign,
    });
  };

  const renderDisabledSubmitPopover = () => {
    if (isDeviceRequiredToSubmit) {
      return (
        <div>
          A device is required for the Non-App Monitoring method.
          To proceed, please assign a device or choose another monitoring method.
        </div>
      );
    }
    return (
      <DeviceUnassignPromptTitleComponent
        devicesNeedToUnassign={devicesNeedToUnassign}
      />
    );
  };

  return (
    <FixedComponent>
      <FixedChildComponent>
        <div className="sub-title mb30 display-none">
          {/* only show for DeviceMonitorFormWithDefaultComponent for now */}
          Choose the monitoring method and associated devices
          to facilitate the patient's remote vitals monitoring.
        </div>
        <Form
          form={deviceMonitorFormProvider.form}
          name={deviceMonitorFormProvider.getName('deviceMonitorForm')}
          initialValues={{
            monitorMethod: (
              DeviceHelper.isNonAppMonitor(selectedMonitorMethod)
                ? MonitorMethodEnum.NON_APP_MONITOR
                : selectedMonitorMethod
            ),
          }}
          onValuesChange={(changedValues) => {
            const monitorMethod = changedValues[deviceMonitorFormProvider.getName('monitorMethod')];
            handleOnValuesChange(monitorMethod);
          }}
          onFinish={deviceMonitorFormProvider.handleSubmitDeviceMonitor(onSubmit)}
          disabled={isLoading}
        >
          <DeviceMonitorMethodFormItemComponent
            vitalType={vitalType}
          />
        </Form>
        <div className="mt30">
          {renderChildren()}
        </div>
        <DeviceMonitorWarningComponent
          connectedDevices={Object.values(initialConnectedDevices)}
          unassignDeviceIds={devicesToUnassignIds}
          currentMonitorMethod={selectedMonitorMethod}
          onUnassign={(connectedDevice) => handleOnUnassign(connectedDevice)}
        />
      </FixedChildComponent>
      <FixedChildComponent isFixed>
        <Divider />
        <div className="flex gap2 jc-e">
          <Button
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Popconfirm
            open={isPopconfirmOpen}
            onOpenChange={setIsPopconfirmOpen}
            title={renderDisabledSubmitPopover}
            icon={<InfoCircleOutlined />}
            onConfirm={closePopconfirm}
            okText="Got it"
            showCancel={false}
            showArrow={false}
            placement="topRight"
            overlayClassName="device-monitor-form-popconfirm"
            trigger={[]}
          >
            <DisabledButtonWithFeedbackComponent
              type="primary"
              disabled={isSubmitDisabled}
              onClick={handleOnSubmit}
              onClickFeedback={() => {
                if (isDeviceRequiredToSubmit) {
                  openPopconfirm();
                }
              }}
            >
              Submit
            </DisabledButtonWithFeedbackComponent>
          </Popconfirm>
        </div>
      </FixedChildComponent>
    </FixedComponent>
  );
};
