import {
  useEffect,
} from 'react';
import { useMessageServicesContext } from '../../contexts/MessageContext/MessageServicesContext';
import {
  ArticleMessagePayload,
  FileUploadMessagePayload,
  MessagePayload,
  MessageType,
  PN_APNS,
  PN_GCM,
} from '../../services/CHSServices/types/data';
import { useLoggedInUserFromContext } from '../../contexts/loggedInUserContext';
import { usePublishMessage } from '../../services/CHSServices/hooks/usePublishMessage';
import { StorageKeyEnum, useSessionStorage } from '../useSessionStorage';
import { UNKNOWN_VALUE } from '../../services/CHSServices/constants';
import { usePatientContext } from '../../contexts/PatientInfoContext/PatientInfoContext';
import { Patient } from '../../uc-api-sdk';

const publicS3bucket = '';

export const getIOSNotificationConfig = (
  userAvatarLink?: string,
): PN_APNS => ({
  aps: {
    alert: 'You have a new message!',
    bigPictureUrl: userAvatarLink,
    sound: 'default',
    'mutable-content': 1,
  },
  pn_bundle_ids: [
    'com.ihealthlabs.sharecare',
    'com.ihealthlabs.sharecarebp',
  ],
});

export const getAndroidNotificationConfig = (
  userAvatarLink?: string,
): PN_GCM => ({
  data: {
    message: 'You have a new message!',
    bigPictureUrl: userAvatarLink,
    sound: 'default',
  },
});

export interface UseSendMessageOptions {
  patientId: string;
  patientInfo?: Patient | null;
  shouldIncludePushNotification?: boolean;
}

export const useSendMessage = ({
  patientId,
  patientInfo: _patientInfo,
  shouldIncludePushNotification = true,
}: UseSendMessageOptions) => {
  const {
    userInfo,
    getDisplayName,
  } = useLoggedInUserFromContext();
  const {
    info,
  } = usePatientContext();
  const patientInfo = _patientInfo || info?.patientInfo;
  const { channelMap } = useMessageServicesContext();
  const [token] = useSessionStorage<string>(StorageKeyEnum.TOKEN);
  const {
    send,
    isLoading: isSending,
    data: sendResponse,
    error: sendError,
  } = usePublishMessage({
    options: { sendOnMount: false, retry: 0 },
  });

  useEffect(() => {
    if (!patientId) {
      console.warn('No patient id found');
    }
  }, [patientId, channelMap]);

  const sendMessage = (
    payload: Omit<MessagePayload, 'displayName'>,
  ) => {
    if (!patientId) {
      return {
        success: false,
        error: 'Invalid input',
      };
    }

    const {
      type,
      text,
      publisher,
      ...others
    } = payload;

    const userAvatarFileKey = userInfo?.avatar;
    const userAvatarLink = userAvatarFileKey ? `${publicS3bucket}/${userAvatarFileKey}` : undefined;

    const userDisplayName = getDisplayName(patientInfo);

    const msgObj: MessagePayload = {
      type,
      text,
      publisher: publisher || userInfo?.id || UNKNOWN_VALUE,
      displayName: userDisplayName || UNKNOWN_VALUE,
      ...others,
    };

    if (shouldIncludePushNotification) {
      // for push notifications on iOS
      msgObj.pn_apns = getIOSNotificationConfig(userAvatarLink);

      // Google Cloud Messaging / Firebase Cloud Messaging
      msgObj.pn_gcm = getAndroidNotificationConfig(userAvatarLink);
    }

    if ((payload as FileUploadMessagePayload).fileKey) {
      msgObj.type = msgObj.type || MessageType.FILE_UPLOAD;
      msgObj.text = msgObj.text || 'Uploaded a file';
      (msgObj as FileUploadMessagePayload).fileKey = (payload as FileUploadMessagePayload).fileKey;
    }

    if ((payload as ArticleMessagePayload).articleMetaData) {
      (msgObj as ArticleMessagePayload).articleMetaData = (
        payload as ArticleMessagePayload
      ).articleMetaData;
    }

    return send({
      params: {
        channel: patientId,
        payload: JSON.stringify(msgObj),
      },
      headers: {
        'x-session-token': token || '',
      },
    });
  };

  return {
    sendMessage,
    isSending,
    sendError,
    sendResponse,
  };
};
