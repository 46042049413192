import { Dayjs } from 'dayjs';
import { CalendarSourceEnum, ClinicEvent, VisitTypeEnum } from '../../../uc-api-sdk';

export interface UseCalendarFilterArg {
  myVisitTypes?: VisitTypeEnum[];
  showMyGoogleEvents?: boolean;
  showMyOtherEvents?: boolean;
  screenedPatientsClinic?: string;
  fromDate: Dayjs;
  toDate: Dayjs;
}

export const useCalendarFilter = ({
  myVisitTypes,
  showMyGoogleEvents,
  showMyOtherEvents,
  fromDate,
  toDate,
}: UseCalendarFilterArg): ClinicEvent => {
  const getMyCalendarSource = () => {
    const sources: CalendarSourceEnum[] = [];
    if ((myVisitTypes?.length || 0) > 0) {
      sources.push(CalendarSourceEnum.UC_VISIT);
    }
    if (showMyGoogleEvents) {
      sources.push(CalendarSourceEnum.GOOGLE_CALENDAR);
    }
    if (showMyOtherEvents) {
      sources.push(CalendarSourceEnum.OTHER);
    }
    return sources;
  };

  return {
    myVisitTypeIn: {
      in: myVisitTypes,
    },
    myCalendarSourceIn: {
      in: getMyCalendarSource(),
    },
    startTimeRange: {
      gte: fromDate.toISOString(),
      lte: toDate.toISOString(),
    },
  };
};
