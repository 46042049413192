import { SearchOutlined } from '@ant-design/icons';
import {
  AutoComplete, AutoCompleteProps
} from 'antd';
import _ from 'lodash';
import { FC } from 'react';
import './MedicationNameSelectComponent.scss';

export type MedicationNameSelectComponentProps = {
  value?: string;
  onChange?: AutoCompleteProps['onChange'],
  names: string[],
}

export const MedicationNameSelectComponent: FC<MedicationNameSelectComponentProps> = ({
  value,
  onChange,
  names,
}) => {
  const dataNoDup = _.uniq(names).sort(); // there are duplicates!!!!!
  return (
    <div className="medicationNameWrapper">
      <AutoComplete
        showSearch
        allowClear
        defaultActiveFirstOption
        data-testid="medNameInput"
        defaultValue={value}
      // value={value}
        filterOption={(inputValue, option) => String(option ? option.value : '').toUpperCase()
          .indexOf(inputValue.toUpperCase()) !== -1}
        options={dataNoDup.map((m) => ({ label: m, value: m }))}
        onChange={onChange}
      />
      <SearchOutlined className="searchIcon" />
    </div>
  );
};
