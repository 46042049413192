import { FC } from 'react';
import InputNumber from 'antd/lib/input-number';
import { WeightService } from '../../../../helpers/weight';
import FormItem, { FormItemProps } from '../../../FormItem/FormItem';
import { MissingErrorComponent } from '../../../MissingErrorComponent/MissingErrorComponent';

export interface WeightInputFormItemComponentProps extends FormItemProps {
  disabled?: boolean;
}

export const WeightInputFormItemComponent: FC<WeightInputFormItemComponentProps> = ({
  disabled = false,
  info,
  name = info?.name,
  label = info?.label,
  required,
}) => {
  const formatValue = (value?: number) => (
    WeightService.hasNoRecord(value) ? '' : Number(value).toFixed(2)
  );

  return (
    <FormItem
      info={{ name, label }}
      rules={[
        {
          required,
          message: <MissingErrorComponent />,
          validator: (rule, value) => {
            if (required && WeightService.hasNoRecord(value)) {
              return Promise.reject(new Error(''));
            }
            return Promise.resolve();
          }
        },
      ]}
    >
      <InputNumber
        disabled={disabled}
        min={0}
        formatter={(value, { userTyping, input }) => (
          userTyping
            ? input
            : formatValue(value)
        )}
        parser={(value) => (
          parseFloat(value || '') as 0
        )}
      />
    </FormItem>
  );
};
