import { DeviceMonitorMethodEnum } from '../../../enumComponent/DeviceMonitorMethodEnumComponent/DeviceMonitorMethodEnumComponent';
import { CuffSizeEnum, AssignDeviceInfo, DeviceModelEnum } from '../../../uc-api-sdk';

export enum SupportDeviceEnum {
  WIFI_ROUTER = 'WIFI_ROUTER',
  HOTSPOT = 'HOTSPOT',
}

export interface DeviceDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  patientId: string;
}

export interface ConnectedDeviceInfo extends AssignDeviceInfo {
  deviceId: string;
  deviceModel: DeviceModelEnum;
  deviceMethod?: DeviceMonitorMethodEnum;
  bpReadOut?: boolean;
  deviceLanguage?: string;
  cuffSize?: CuffSizeEnum;
}
