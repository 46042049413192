import { useCallback, useMemo } from 'react';
import { useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';

export const useTicketCommentForm = () => {
  const factory = useFormHookFactory({
    comment: {
      name: 'comment',
      label: '',
    },
    isCommentOnly: {
      name: 'isCommentOnly',
      label: '',
    },
    generateCareNote: {
      name: 'generateCareNote',
      label: 'Generate A Care Note',
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getSubmitButtonText = useCallback((getFieldValue: any) => (
    getFieldValue(factory.getName('isCommentOnly')) ? 'Submit' : 'Resolve Task'
  ), []);

  const response = useMemo(() => ({
    getSubmitButtonText,
    ...factory,
  }), [factory]);

  return response;
};
