import {
  Col, Row, Typography,
} from 'antd';
import { FC } from 'react';
import { TextWithLinkComponent } from '../../../../uiComponent/TextWithLinkComponent/TextWithLinkComponent';
import { VisitTypeComponent } from '../../../visit/component/VisitTypeComponent/VisitTypeComponent';
import {
  TranscribeStatusEnum,
  VisitMethodEnum,
  VisitParticipant,
  VisitTypeEnum,
} from '../../../../uc-api-sdk';
import { VisitMethodComponent } from '../../../calendar/component/VisitMethodComponent/VisitMethodComponent';

import './VisitInfoComponent.scss';
import { CalendarParticipantListComponent } from '../../../calendar/component/CalendarParticipantListComponent/CalendarParticipantListComponent';
import { TranscribeStatusButtonComponent } from '../../../transcribing/component/TranscribeStatusButtonComponent/TranscribeStatusButtonComponent';
import { RejoinZoomButtonComponent, RejoinZoomButtonComponentProps } from '../../../zoom/component/RejoinZoomButtonComponent/RejoinZoomButtonComponent';

const { Title } = Typography;

export interface VisitInfoComponentProps extends RejoinZoomButtonComponentProps {
  participants?: VisitParticipant[];
  visitType?: VisitTypeEnum;
  visitMethod?: VisitMethodEnum;
  transcribeId?: string;
  transcribeStatus?: TranscribeStatusEnum;
  description?: string;
}
export const VisitInfoComponent: FC<VisitInfoComponentProps> = ({
  participants = [],
  visitType,
  visitMethod,
  transcribeId,
  transcribeStatus,
  description = '',
  isOnGoing,
  zoomEntry,
}) => (
  <div className="visit-info-component flex gap3 fd-c">
    <Row>
      <Col span={8}>
        <Title level={5}>
          Visit type
        </Title>
      </Col>
      {visitType && (
        <Col>
          <VisitTypeComponent value={visitType} type="full" />
        </Col>
      )}
    </Row>
    <Row>
      <Col span={8}>
        <Title level={5}>
          Participants
        </Title>
      </Col>
      <Col>
        <CalendarParticipantListComponent participants={participants} />
      </Col>
    </Row>
    <Row>
      <Col span={8}>
        <Title level={5}>
          Visit method
        </Title>
      </Col>
      <div className="flex ai-c">
        {visitMethod && (
          <Col>
            <VisitMethodComponent value={visitMethod} />
          </Col>
        )}
        <RejoinZoomButtonComponent
          visitMethod={visitMethod}
          isOnGoing={isOnGoing}
          zoomEntry={zoomEntry}
        />
      </div>
    </Row>
    <Row>
      <Col span={8}>
        <Title level={5}>
          Transcribing
        </Title>
      </Col>
      <Col>
        <TranscribeStatusButtonComponent
          transcribeId={transcribeId}
          transcribeStatus={transcribeStatus}
          className="visit-info-component__transcribe-drawer"
        />
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <Title level={5}>
          Description
        </Title>
      </Col>
      <Col>
        <p>
          <TextWithLinkComponent value={description} />
        </p>
      </Col>
    </Row>
  </div>
);
