import { CheckCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import { Space } from 'antd';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { WarningComponent } from '../WarningComponent/WarningComponent';
import './AlertCardComponent.scss';

export interface AlertCardComponentProps {
  type: 'success' | 'warning' | 'error' | 'info';
  message: ReactNode;
  warningBorder?: boolean;
  align?: 'left' | 'center' | 'right';
  showCloseIcon?: boolean;
  paddingClassName?: string;
  onClose?: () => void;
}

export const AlertCardComponent = ({
  type,
  message,
  warningBorder = false,
  align = 'center',
  showCloseIcon,
  paddingClassName,
  onClose,
}: AlertCardComponentProps) => {
  const renderSuccess = () => (
    <Space>
      <CheckCircleFilled className="success-svg" />
      <div>{message}</div>
    </Space>
  );

  const renderWarning = () => (
    <WarningComponent
      textColor="none"
      size="medium"
      text={message}
      iconShape="circle"
      showCloseIcon={showCloseIcon}
      onClose={onClose}
    />
  );

  const renderError = () => (
    <WarningComponent
      textColor="none"
      size="medium"
      text={message}
    />
  );

  const renderInfo = () => (
    <Space>
      <InfoCircleFilled color="#F2C81A" className="info" />
      <div>{message}</div>
    </Space>
  );

  const render = () => {
    switch (type) {
      case 'success': return renderSuccess();
      case 'warning': return renderWarning();
      case 'info': return renderInfo();
      case 'error':
      default: return renderError();
    }
  };

  return (
    <div
      className={classNames({
        'alert-card-component': true,
        [type]: !!type,
        'warning-border': warningBorder,
        [align]: !!align,
        [paddingClassName || '']: !!paddingClassName,
      })}
    >
      {render()}
    </div>
  );
};
