import { Typography } from 'antd';
import React from 'react';
import './ProviderViewTablesSiderContentComponent.scss';

const { Text } = Typography;

export interface ProviderViewTablesSiderContentComponentProps {
  tabMenu1: React.ReactNode;
  tabMenu2: React.ReactNode;
}

export const ProviderViewTablesSiderContentComponent = ({
  tabMenu1,
  tabMenu2,
}: ProviderViewTablesSiderContentComponentProps) => (
  <div
    className="ProviderViewTablesSiderContentComponent flex fd-c ai-s"
  >
    <Text type="secondary" className="tab-title">
      My patients
    </Text>
    {tabMenu1}
    <Text type="secondary" className="tab-title mt30">
      Patients in my clinics
    </Text>
    {tabMenu2}
  </div>
);
