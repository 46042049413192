import { Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { UserColorService } from '../../helper/UserColorService/UserColorService';
import { Color } from '../../helper/CalendarColorService/CalendarColorService';
import { CustomTagProps } from '../../../../types/tag';
import { CalendarSourceEnum } from '../../../../uc-api-sdk';
import './EmployeeSelectLabelComponent.scss';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';

export interface EmployeeSelectLabelComponentProps extends CustomTagProps {
  source?: CalendarSourceEnum;
}

export const EmployeeSelectLabelComponent = ({
  label,
  value,
  closable,
  onClose,
  source,
}: EmployeeSelectLabelComponentProps) => {
  const [color, setColor] = useState<Color>();
  const { userId = '' } = useLoggedInUserFromContext();

  useEffect(() => {
    setColor(UserColorService.getColor(value === userId ? 'current' : value, source));
    return () => UserColorService.removeUser(value);
  }, [value]);

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      color={color?.backgroundColor}
      className="employee-select-label-component"
      style={{ color: color?.textColor, border: `2px solid ${color?.borderColor}` }}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
    >
      {label}
    </Tag>
  );
};
