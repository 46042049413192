import { useMemo } from 'react';
import { WorklistTabsComponent } from '../WorklistTabsComponent/WorklistTabsComponent';
import { LifestyleContainer } from '../../../intervention/container/LifestyleContainer/LifestyleContainer';
import { NutritionContainer } from '../../../intervention/container/NutritionContainer/NutritionContainer';
import { NestedFormComponent } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';
import { BasicMeasuresComponent } from '../../../patient/component/BasicMeasuresComponent/BasicMeasuresComponent';
import { PatientService } from '../../../../services/PatientService';
import { WorklistTabProps } from '../WorklistTabsComponent/type';
import { WorklistContentComponent } from '../WorklistContentComponent/WorklistContentComponent';
import { WorklistSocialSupportComponent } from '../WorklistSocialSupportComponent/WorklistSocialSupportComponent';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';

export enum LifestyleAssessmentTabKey {
  Lifestyle = 'Lifestyle',
  NutritionIntake = 'NutritionIntake',
}

export interface LifestyleAssessmentComponentProps
  extends WorklistTabProps<LifestyleAssessmentTabKey> {
  patientId: string;
  patientInfoService: PatientService;
  onSubmit?: () => void;
}

export const LifestyleAssessmentComponent = ({
  patientId,
  patientInfoService,
  onSubmit,
  defaultActiveKey,
}: LifestyleAssessmentComponentProps) => {
  const debouncedOnSubmit = useDebounce(() => onSubmit?.(), 500, [onSubmit]);
  const items = useMemo(() => ([
    {
      key: LifestyleAssessmentTabKey.Lifestyle,
      label: 'Lifestyle',
      children: (
        <div className="flex fd-c gap3">
          <NestedFormComponent>
            <BasicMeasuresComponent
              patientInfoService={patientInfoService}
            />
            <WorklistContentComponent>
              <LifestyleContainer
                patientId={patientId}
                title=""
                isEditing
                showEdit={false}
                formButtons={null}
              />
            </WorklistContentComponent>
          </NestedFormComponent>
          <NestedFormComponent>
            <WorklistSocialSupportComponent
              patientId={patientId}
              patientInfoService={patientInfoService}
              onSubmit={debouncedOnSubmit}
            />
          </NestedFormComponent>
        </div>
      )
    },
    {
      key: LifestyleAssessmentTabKey.NutritionIntake,
      label: 'Nutrition Intake',
      forceRender: false,
      children: (
        <NestedFormComponent>
          <BasicMeasuresComponent
            patientInfoService={patientInfoService}
          />
          <WorklistContentComponent>
            <NutritionContainer
              patientId={patientId}
              title=""
              isEditing
              showEdit={false}
              formButtons={null}
            />
          </WorklistContentComponent>
        </NestedFormComponent>
      ),
    }
  ]), [patientId]);

  return (
    <WorklistTabsComponent
      items={items}
      defaultActiveKey={defaultActiveKey}
    />
  );
};
