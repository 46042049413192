import { useEffect, useState } from 'react';
import { FetchComponent } from '../../../uiComponent/FetchComponent/FetchComponent';
import { SelectType } from '../../Input/types';
import { ICDSearchDropdownComponent } from '../component/ICDSearchDropdownComponent/ICDSearchDropdownComponent';
import { Icd10Billable, useIcd10BillableGetAvailableIcd } from '../../../uc-api-sdk';
import useDebounce from '../../../hooks/useDebounce/useDebounce';
import { ICDCodeHelpers } from '../helper';

export interface ICDSearchDropdownContainerProps extends SelectType<Icd10Billable> {
  onSearchChange?: (input: string) => void;
  disabled?: boolean;
}

export const ICDSearchDropdownContainer = ({
  onChange,
  onSearchChange,
  disabled,
}: ICDSearchDropdownContainerProps) => {
  const [searchValue, setSearchValue] = useState<string>();
  const [page, setPage] = useState(1);
  const availableIcdInfo = useIcd10BillableGetAvailableIcd({
    params: ICDCodeHelpers.makeSearchParams('I10', 1),
    options: {
      sendOnMount: true,
    },
  });

  const handleOnSearchChange = (searchValue: string) => {
    setSearchValue(searchValue);
    onSearchChange?.(searchValue);
  };

  const handleOnSelectChange = (icdCode?: Icd10Billable) => {
    onChange?.(icdCode);
  };

  const handleSearch = useDebounce((
    searchValue: string,
    page: number,
  ) => {
    availableIcdInfo.send({
      params: ICDCodeHelpers.makeSearchParams(searchValue, page),
    });
  }, 500);

  useEffect(() => {
    if (searchValue !== undefined) {
      handleSearch(searchValue, page);
    }
  }, [searchValue, page]);

  return (
    <FetchComponent
      info={availableIcdInfo}
      alwaysShowChildren
    >
      {
        (searchInfoValue, info) => {
          const {
            content,
            totalSize,
          } = searchInfoValue?.data?.rawValue || {};

          return (
            <ICDSearchDropdownComponent
              data={content || []}
              onSearchChange={handleOnSearchChange}
              onSelectChange={handleOnSelectChange}
              isLoading={info.isLoading}
              disabled={disabled}
              page={page}
              onChangePage={setPage}
              totalSize={totalSize || 0}
            />
          );
        }
      }
    </FetchComponent>
  );
};
