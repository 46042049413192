import { useMemo, useState } from 'react';
import { Menu, MenuProps } from 'antd';
import { OrderOfBenefitEnumComponent } from '../../../../enumComponent/OrderOfBenefitEnumComponent/OrderOfBenefitEnumComponent';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import DropdownComponent from '../../../../uiComponent/DropdownComponent/DropdownComponent';
import { BenefitOrderEnum } from '../../../../uc-api-sdk';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';

enum MenuKey {
  edit = 'edit',
  delete = 'delete',
}

export interface InsuranceCardTitleComponentProps {
  provider: string;
  benefitOrder: BenefitOrderEnum;
  isEditable?: boolean;
  isDeletable?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  showOptions?: boolean;
}

export const InsuranceCardTitleComponent = ({
  provider,
  benefitOrder,
  isEditable = true,
  isDeletable = true,
  onEdit,
  onDelete,
  showOptions = true,
}: InsuranceCardTitleComponentProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const items: MenuProps['items'] = useMemo(() => [
    {
      key: MenuKey.edit,
      label: 'Edit',
      disabled: !isEditable,
      onClick: () => {
        onEdit?.();
        setIsOpen(false);
      }
    },
    {
      key: MenuKey.delete,
      label: 'Delete',
      disabled: !isDeletable,
      onClick: () => {
        onDelete?.();
        setIsOpen(false);
      },
    },
  ], [isEditable, isDeletable, onEdit, onDelete]);

  return (
    <div className="insurance-card__title">
      <div className="flex f-w ai-bl gap2 f1">
        <TextComponent
          size="large"
          bold
        >
          {provider}
        </TextComponent>
        <TextComponent
          size="small"
          color="gray2"
          italic
        >
          {
            benefitOrder
              ? <OrderOfBenefitEnumComponent value={benefitOrder} />
              : <EmptyComponent />
          }

        </TextComponent>
      </div>
      {
        showOptions && (
          <DropdownComponent
            overlay={(
              <Menu items={items} />
            )}
            className="insurance-card__options"
            openState={isOpen}
            setOpenState={setIsOpen}
          />
        )
      }
    </div>
  );
};
