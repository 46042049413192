import { map } from 'lodash';
import { Form, Select } from 'antd';
import { useCallback, useMemo } from 'react';
import { useInsuranceForm } from '../../../../../hooks/formHook/useInsuranceForm';
import { InsuranceItemComponent, InsuranceItemComponentProps } from '../../InsuranceItemComponent/InsuranceItemComponent';
import FormItem from '../../../../../uiComponent/FormItem/FormItem';
import { BenefitOrderEnum } from '../../../../../uc-api-sdk';
import { OrderOfBenefitEnumComponent } from '../../../../../enumComponent/OrderOfBenefitEnumComponent/OrderOfBenefitEnumComponent';
import { useInsuranceFormContext } from '../../../context/InsuranceFormContext';

export interface InsuranceOrderOfBenefitFormItemComponentProps {
  fieldName?: number,
  itemProps?: InsuranceItemComponentProps;
  span?: number;
}

export const InsuranceOrderOfBenefitFormItemComponent = ({
  fieldName,
  itemProps,
  span = 5,
}: InsuranceOrderOfBenefitFormItemComponentProps) => {
  const form = Form.useFormInstance();
  const insuranceForm = useInsuranceForm({ form });
  const {
    getCurrentEditableInsurance,
    hasInsuranceOrder,
  } = useInsuranceFormContext();
  const initialValue = (
    getCurrentEditableInsurance()?.benefitOrder
    || insuranceForm.form.getFieldsValue(true)?.benefitOrder
  );
  const shouldDisableOption = useCallback((benefitOrder: BenefitOrderEnum) => {
    if (initialValue === benefitOrder) {
      // current insurance's order of benefit
      return false;
    }
    return hasInsuranceOrder(benefitOrder);
  }, [initialValue, hasInsuranceOrder]);

  const options = useMemo(() => (
    map(BenefitOrderEnum, (_, key) => ({
      value: key,
      label: <OrderOfBenefitEnumComponent value={key as BenefitOrderEnum} />,
      disabled: shouldDisableOption(key as BenefitOrderEnum),
    }))
  ), [shouldDisableOption]);

  return (
    <InsuranceItemComponent
      label={insuranceForm.getLabel('benefitOrder')}
      content={(
        <FormItem
          name={insuranceForm.makeListNamePath('benefitOrder', fieldName)}
          required
        >
          <Select
            options={options}
          />
        </FormItem>
      )}
      span={span}
      required
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...itemProps}
    />
  );
};
