import { LabResult, LabResultClass } from '../../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';

export interface LabResultCollectedDateComponentProps {
  labResult?: LabResult | LabResultClass;
}

export const LabResultCollectedDateComponent = ({
  labResult,
}: LabResultCollectedDateComponentProps) => (
  <DisplayDateComponent
    value={labResult?.dateCollected}
    format="USA_DATE"
    useTimezoneService={false}
  />
);
