import { useCallback, useMemo } from 'react';
import {
  flatten, omit, snakeCase,
  toUpper,
  values
} from 'lodash';
import { FormProps } from 'antd';
import { FormOptions } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { useIterableFormHook } from '../../../hooks/useIterableFormListHook/useIterableFormHook';
import {
  BehaviorGoalCategoryEnum,
  BehaviorGoalItem,
  BehaviorGoalTypeEnum,
} from '../../../uc-api-sdk';
import { SubmitValue } from '../component/BehaviorGoalComponent/BehaviorGoalCardFormComponent/BehaviorGoalCardFormComponent';

export interface FormValues {
  behaviorGoals: {
    [x in BehaviorGoalTypeEnum]: BehaviorGoalItem[];
  }
}

export const useBehaviorGoalForm = (options?: FormOptions) => {
  const factory = useIterableFormHook('behaviorGoals', {
    behaviorGoalType: {
      name: 'behaviorGoalType',
      label: '',
    },
    behaviorGoalValue: {
      name: 'behaviorGoalValue',
      label: '',
    },
    goalStatus: {
      name: 'goalStatus',
      label: 'Status',
    },
    updatedDate: {
      name: 'updatedDate',
      label: '',
    },
    category: {
      name: 'category',
      label: '',
    },
  }, options);

  const makeObjectNamePathFromMain = (
    behaviorGoalCategory: BehaviorGoalCategoryEnum,
  ) => [factory.getMainFormItemName(), behaviorGoalCategory];

  const getObjectValue = (
    behaviorGoalType: BehaviorGoalTypeEnum,
    fieldName: number,
    inputKey: keyof typeof factory.formInput,
  ) => factory.form.getFieldValue([
    factory.getMainFormItemName(), behaviorGoalType, fieldName, inputKey,
  ]);

  const getBehaviorGoals = (formValues: FormValues) => {
    const { behaviorGoals } = formValues;
    return {
      ...formValues,
      behaviorGoals: flatten(values(behaviorGoals)).map((goal) => omit(goal, 'updatedDate')),
    } as SubmitValue;
  };

  const handleSubmit: (onSubmit?: (submitValue: SubmitValue) => Promise<void> | void) => FormProps['onFinish'] = useCallback((onSubmit) => (formValues: FormValues) => {
    onSubmit?.(getBehaviorGoals(formValues));
  }, []);

  const onValuesChange: (onValuesChange: FormProps['onValuesChange']) => FormProps['onValuesChange'] = (
    useCallback((onValuesChange) => (
      changedValues,
      values,
    ) => {
      onValuesChange?.(changedValues, getBehaviorGoals(values));
    }, [])
  );

  const handleAddCategory = (category: string) => {
    const { form, getMainFormItemName } = factory;
    const { getFieldValue, setFieldValue } = form;
    const currentBehaviorGoals = getFieldValue(getMainFormItemName());
    const newCategoryAsEnum = toUpper(snakeCase(category));
    const behaviorGoalValue = '';
    if (currentBehaviorGoals[newCategoryAsEnum]) {
      currentBehaviorGoals[newCategoryAsEnum].push({
        category: newCategoryAsEnum,
        behaviorGoalValue
      });
    } else {
      currentBehaviorGoals[category] = [{
        customCategory: category,
        behaviorGoalValue
      }];
    }
    setFieldValue(getMainFormItemName(), currentBehaviorGoals);
  };

  const response = useMemo(() => ({
    ...factory,
    makeObjectNamePathFromMain,
    getObjectValue,
    handleSubmit,
    onValuesChange,
    handleAddCategory,
  }), [
    factory,
    makeObjectNamePathFromMain,
    getObjectValue,
    handleSubmit,
    onValuesChange,
    handleAddCategory,
  ]);

  return response;
};
