import { Button, Card, Form } from 'antd';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import { FormType } from '../../../Input/types';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import './SelectCoverRangeFormComponent.scss';
import { PriorityCoverEnum, RoleTypeEnum } from '../../../../uc-api-sdk';
import { useSelectCoverRangeForm } from '../../hook/useSelectCoverRangeForm';
import { SelectPatientsHCRadioComponent } from '../SelectHCsPatientsRadioComponent/SelectPatientsHCRadioComponent';
import { SelectPatientsCARadioComponent } from '../SelectPatientsCARadioComponent/SelectPatientsCARadioComponent';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { OnCallCollapseComponent } from '../../../oncall/component/OnCallCollapseComponent/OnCallCollapseComponent';

export interface SelectCoverRangeFormSubmitValue {
  membersRD?: string[];
  membersHC?: string[];
  membersCA?: string[];
  priorityRD?: PriorityCoverEnum;
  priorityHC?: PriorityCoverEnum;
  priorityCA?: PriorityCoverEnum;
}

export interface SelectCoverRangeFormComponentProps extends
  FormType<SelectCoverRangeFormSubmitValue> {
  onClose?: () => void;
  multipleRoles: boolean;
  membersWithRolesMap: Map<RoleTypeEnum, object[]>;
}

export const SelectCoverRangeFormComponent = ({
  onSubmit,
  onClose,
  initialValues,
  multipleRoles = false,
  membersWithRolesMap,
}: SelectCoverRangeFormComponentProps) => {
  const selectCoverRangeForm = useSelectCoverRangeForm();
  const { value: isLoading, setTrue, setFalse } = useBoolean();

  const handleSubmit = async (v: SelectCoverRangeFormSubmitValue) => {
    try {
      const submit = selectCoverRangeForm.handleSubmit(onSubmit);
      setTrue();
      await submit?.(v);
      setFalse();
      onClose?.();
    } catch (e) {
      setFalse();
      console.error(e);
    }
  };

  const renderCollapse = (roles: RoleTypeEnum[]) => (
    <OnCallCollapseComponent
      userRolesList={roles}
      membersWithRolesMap={membersWithRolesMap}
      expanded={!multipleRoles}
      mode="Patient Care"
      showSelectAllCheckbox
    />
  );

  const renderPatientRadio = (role: RoleTypeEnum) => {
    if (role === RoleTypeEnum.RD || role === RoleTypeEnum.HC) {
      return (<SelectPatientsHCRadioComponent />);
    }
    return (<SelectPatientsCARadioComponent />);
  };

  const renderContent = () => (
    <>
      <div className="render-select-member-to-cover">
        <div className="render-title">Select Members to Cover</div>
        <div className="render-text">
          Please select the members you want to cover for patient care duties
        </div>
      </div>
      <Card className="render-card-body">
        <div className="flex jc-e mb20">
          <FormItem
            noStyle
            shouldUpdate={selectCoverRangeForm.shouldUpdate(['membersCA', 'membersHC', 'membersRD'])}
          >
            {({ getFieldValue }) => {
              const getRD = getFieldValue('membersRD') as string[];
              const getHC = getFieldValue('membersHC') as string[];
              const getCA = getFieldValue('membersCA') as string[];
              let countRD = 0;
              let countHC = 0;
              let countCA = 0;
              if (getRD.length > 0) {
                countRD = getRD.includes('all') ? getRD.length - 1 : getRD.length;
              }
              if (getHC.length > 0) {
                countHC = getHC.includes('all') ? getHC.length - 1 : getHC.length;
              }
              if (getCA.length > 0) {
                countCA = getCA.includes('all') ? getCA.length - 1 : getCA.length;
              }
              const membersSize = countRD + countHC + countCA;
              return (
                <>
                  {membersSize}
                  {' '}
                  selected
                </>
              );
            }}
          </FormItem>
        </div>
        {
          !!membersWithRolesMap.get(RoleTypeEnum.RD)?.length && (
          <FormItem noStyle info={selectCoverRangeForm.getInfo('membersRD')}>
            {renderCollapse([RoleTypeEnum.RD, RoleTypeEnum.HC])}
          </FormItem>
          )
        }
        {
          !(membersWithRolesMap.get(RoleTypeEnum.RD)?.length)
          && !!membersWithRolesMap.get(RoleTypeEnum.HC)?.length
          && (
            <FormItem noStyle info={selectCoverRangeForm.getInfo('membersHC')}>
              {renderCollapse([RoleTypeEnum.HC])}
            </FormItem>
          )
        }
        {
          !!membersWithRolesMap.get(RoleTypeEnum.CA)?.length && (
            <FormItem noStyle info={selectCoverRangeForm.getInfo('membersCA')}>
              {renderCollapse([RoleTypeEnum.CA])}
            </FormItem>
          )
        }
      </Card>
      <div className="render-select-member-to-cover">
        <div className="render-title">Select Patients to Take Care of</div>
        <div className="render-text">
          Please select patients you'll take care of for selected members.
        </div>
      </div>
      {
        multipleRoles
          ? (
            <>
              <FormItem noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const v = getFieldValue('membersHC');
                  return v && v.length > 0 && (
                    <>
                      <div>
                        For selected HC(s):
                      </div>
                      <FormItem
                        noStyle
                        info={selectCoverRangeForm.getInfo('priorityHC')}
                      >
                        <SelectPatientsHCRadioComponent />
                      </FormItem>
                    </>
                  );
                }}
              </FormItem>
              <FormItem noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  const v = getFieldValue('membersCA');
                  return v && v.length > 0 && (
                    <>
                      <div>
                        For selected CA(s):
                      </div>
                      <FormItem
                        noStyle
                        info={selectCoverRangeForm.getInfo('priorityCA')}
                      >
                        <SelectPatientsCARadioComponent />
                      </FormItem>
                    </>
                  );
                }}
              </FormItem>
            </>
          ) : (
            <>
              {
                membersWithRolesMap.get(RoleTypeEnum.RD)
                && membersWithRolesMap.get(RoleTypeEnum.HC) && (
                  <FormItem noStyle info={selectCoverRangeForm.getInfo('priorityRD')}>
                    {renderPatientRadio(RoleTypeEnum.RD)}
                  </FormItem>
                )
              }
              {
                membersWithRolesMap.get(RoleTypeEnum.HC)
                && !membersWithRolesMap.get(RoleTypeEnum.RD) && (
                  <FormItem noStyle info={selectCoverRangeForm.getInfo('priorityHC')}>
                    {renderPatientRadio(RoleTypeEnum.HC)}
                  </FormItem>
                )
              }
              {
                membersWithRolesMap.get(RoleTypeEnum.CA) && (
                  <FormItem noStyle info={selectCoverRangeForm.getInfo('priorityCA')}>
                    {renderPatientRadio(RoleTypeEnum.CA)}
                  </FormItem>
                )
              }
            </>
          )
        }
    </>
  );

  return (
    <Form
      form={selectCoverRangeForm.form}
      onFinish={handleSubmit}
      initialValues={initialValues}
      className="select-cover-range-form-component"
    >
      <FixedComponent>
        <FixedComponent.Child>
          <div className="pb20">
            {renderContent()}
          </div>
        </FixedComponent.Child>
        <FixedComponent.Child isFixed>
          <FormItem
            noStyle
            shouldUpdate={selectCoverRangeForm.shouldUpdate(
              ['membersCA', 'membersHC', 'membersRD', 'priorityCA', 'priorityHC', 'priorityRD']
            )}
          >
            {({ getFieldValue }) => {
              const disabled = selectCoverRangeForm.getConfirmButtonDisabled(getFieldValue);
              return (
                <div className="confirm-footer border-t">
                  <div>
                    In coverage mode,
                    you will see cases for both your assigned patients and the members you cover.
                  </div>
                  <div className="mt20">
                    <Button
                      type="primary"
                      block
                      htmlType="submit"
                      loading={isLoading}
                      disabled={disabled}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              );
            }}
          </FormItem>
        </FixedComponent.Child>
      </FixedComponent>
    </Form>
  );
};
