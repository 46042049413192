import { first } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { LabResultClass } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { LabResultA1CTableComponent } from '../../component/LabResultTableComponent/LabResultA1CTableComponent';
import { HemoglobinA1cTemplateId } from '../../constants';
import { useLabResultInfo } from '../../hook/useLabResultSearch';
import { useLabResultTemplatesSearch } from '../../hook/useLabResultTemplatesSearch';

export interface LabResultA1CTableContainerProps {
  patientId?: string;
  setLatestCreatedAt?: (l?: LabResultClass) => void;
}

export const LabResultA1CTableContainer = ({
  patientId,
  setLatestCreatedAt,
}: LabResultA1CTableContainerProps) => {
  const { info, tableChange, onDelete } = useLabResultInfo(patientId, HemoglobinA1cTemplateId);

  useUpdateListener('A1C_UPDATED', info.refetch);
  useUpdateListener('LAB_EDITED', info.refetch);
  useUpdateListener('LAB_DELETE', onDelete);
  useUpdateListener('LAB_CREATED', info.refetch);

  const { items } = useLabResultTemplatesSearch(HemoglobinA1cTemplateId);
  const reference = useMemo(() => first(items)?.referenceLevels, [items]);

  return (
    <FetchComponent
      info={info}
      alwaysShowChildren
      showErrorOverlay={false}
      showLoadingOverlay={false}
      emptyOverlay={<h2>No Data!!!</h2>}
    >
      {(value, info) => {
        const {
          content = [],
        } = (value?.rawValue?.data || {});
        useEffect(() => {
          // get the latest created lab;
          setLatestCreatedAt?.(first(value?.rawValue?.data.content || []));
        }, [info.data]);

        return (
          <LabResultA1CTableComponent
            dataSource={content}
            onChange={tableChange.handleTableChange}
            pagination={info.pagination}
            referenceLevels={reference ?? undefined}
          />
        );
      }}
    </FetchComponent>
  );
};
