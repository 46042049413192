import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

export enum UpDownTrend {
  UP = 'UP',
  DOWN = 'DOWN',
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE',
}

export interface UpDownTrendComponentProps {
  trend?: UpDownTrend;
  upTrendColor?: string;
  downTrendColor?: string;
}

export const UpDownTrendComponent = ({
  trend,
  upTrendColor,
  downTrendColor,
}: UpDownTrendComponentProps) => {
  const getTrend = () => {
    switch (trend) {
      case UpDownTrend.UP:
      case UpDownTrend.INCREASE:
        return <ArrowUpOutlined style={{ color: upTrendColor }} />;
      case UpDownTrend.DOWN:
      case UpDownTrend.DECREASE:
        return <ArrowDownOutlined style={{ color: downTrendColor }} />;
      default:
        return null;
    }
  };

  return (<>{getTrend()}</>);
};
