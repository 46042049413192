import { useMemo } from 'react';
import { PriorAuthCaseStatusEnum } from '../../../../uc-api-sdk';
import { PriorAuthConfirmContentComponent } from './PriorAuthConfirmContentComponent';

export interface PriorAuthConfirmStatusContentComponentProps {
  status: PriorAuthCaseStatusEnum;
  onCancel?: () => void;
  onConfirm?: () => void;
}

export const PriorAuthConfirmStatusContentComponent = ({
  status,
  onCancel,
  onConfirm,
}: PriorAuthConfirmStatusContentComponentProps) => {
  const statusText = useMemo(() => {
    switch (status) {
      case PriorAuthCaseStatusEnum.APPROVED:
        return 'Approved';
      case PriorAuthCaseStatusEnum.DENIED:
        return 'Denied';
      default:
        return 'Unknown';
    }
  }, [status]);

  return (
    <PriorAuthConfirmContentComponent
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      Are you confirming patient insurance prior-authorization is "
      {statusText}
      "?
      Once the status changes, you are not able to modify it anymore.
      Are you sure to submit?
    </PriorAuthConfirmContentComponent>
  );
};
