import { map } from 'lodash';
import { CareNote } from '../../../../uc-api-sdk';
import { CareNoteCardComponent } from '../../../patient/component/CareNoteCardComponent/CareNoteCardComponent';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';

export interface MTPRNoteHistoryListComponentProps {
  careNotes?: CareNote[];
}

export const MTPRNoteHistoryListComponent = ({
  careNotes
}: MTPRNoteHistoryListComponentProps) => (
  careNotes?.length
    ? (
      <div className="flex fd-c gap2 py12">
        {
          map(careNotes, (note) => (
            <CareNoteCardComponent
              key={note.id}
              note={note}
              isEditable={false}
              showHistories={false}
            />
          ))
        }
      </div>
    ) : (
      <div className="flex f1 jc-c ai-c h100">
        <TextComponent color="gray2">
          No Monthly Treatment Plan Review Note yet
        </TextComponent>
      </div>
    )
);
