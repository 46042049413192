import { CloseOutlined } from '@ant-design/icons';
import { RefObject } from 'react';
import { ClinicEvent } from '../../../../uc-api-sdk';
import { DiscardUnsavedChangesModalComponent } from '../../../../uiComponent/DiscardUnsavedChangesModalComponent/DiscardUnsavedChangesModalComponent';
import { ModalComponent } from '../../../../uiComponent/ModalComponent/ModalComponent';
import { useCreateVisitContext } from '../../context/CreateVisitContext/CreateVisitContext';
import { CalendarEvent } from '../BigCalendarComponent/BigCalendarComponent';
import './CalendarEventModalComponent.scss';
import { CalendarEventModalContentComponent } from './CalendarEventModalContentComponent';

export interface CalendarEventModalComponentProps {
  event?: CalendarEvent<ClinicEvent>;
  reference?: RefObject<HTMLDivElement | undefined>;
  onClose?: () => void;
}

export const CalendarEventModalComponent = ({
  event,
  reference,
  onClose,
}: CalendarEventModalComponentProps) => {
  const calendarContext = useCreateVisitContext();

  const handleCloseModal = () => {
    onClose?.();
  };

  const getTitle = () => {
    if (event?.isEdit) {
      return undefined;
    }
    return null;
  };

  const isRight = () => {
    const rightDays = [0, 1, 2];
    const day = event?.start?.getDay() as number;
    if (rightDays.includes(day)) return true;
    return false;
  };

  const renderCloseButton = () => (
    <DiscardUnsavedChangesModalComponent
      showDiscard={calendarContext?.isFieldsTouched}
      onConfirm={handleCloseModal}
    >
      <CloseOutlined />
    </DiscardUnsavedChangesModalComponent>
  );

  return (
    <ModalComponent
      className="calendar-event-modal-component"
      footer={null}
      title={getTitle()}
      open={!!event}
      closeIcon={renderCloseButton()}
      reference={reference}
      middleY
      width={407}
      leftOrRight={isRight() ? 'right' : 'left'}
    >
      {event && (
        <CalendarEventModalContentComponent
          event={event}
          onClose={handleCloseModal}
        />
      )}
    </ModalComponent>
  );
};
