import React from 'react';
import { Space } from 'antd';
import { View } from 'react-big-calendar';
import { Dayjs } from 'dayjs';
import { MONTH_YEAR, MONTH_DAY_YEAR } from '../../../../constants/timeFormat';
import { BigCalendarHelper } from '../../helper/BigCalendarHelper/BigCalendarHelper';
import './CalendarTitleComponent.scss';
import { PrevNextButtonsComponent } from '../../../../uiComponent/PrevNextButtonsComponent/PrevNextButtonsComponent';
import { Title } from '../../../../uiComponent/Title/Title';

export interface CalendarTitleComponentProps {
  date: Dayjs;
  showMonthOnly?: boolean;
  dayFormat?: string;
  showButtons?: boolean;
  onGoNext?: () => void;
  onGoPrev?: () => void;
  view: View;
}

export const CalendarTitleComponent = ({
  date,
  showMonthOnly = false,
  dayFormat = MONTH_DAY_YEAR,
  onGoNext,
  onGoPrev,
  showButtons = true,
  view: bigCalendarView,
}: CalendarTitleComponentProps) => {
  const view = BigCalendarHelper.viewToDayjsUnit(bigCalendarView);
  const startOfWeekMonth = date.clone().startOf(view).format(MONTH_YEAR);
  const endOfWeekMonth = date.clone().endOf(view).format(MONTH_YEAR);

  const getTitle = () => {
    if (bigCalendarView === 'day') {
      return date.format(dayFormat);
    }
    return startOfWeekMonth === endOfWeekMonth || showMonthOnly
      ? startOfWeekMonth
      : (
        <>
          {startOfWeekMonth}
          {' - '}
          {endOfWeekMonth}
        </>
      );
  };

  return (
    <Space className="calendar-title-component">
      <Title.PageTitle
        className="calendar-month-title"
        showMarginBot={false}
        showMarginTop={false}
      >
        {getTitle()}
      </Title.PageTitle>
      {showButtons && (
        <PrevNextButtonsComponent
          onGoPrev={onGoPrev}
          onGoNext={onGoNext}
        />
      )}
    </Space>
  );
};
