import InfoCircleFilled from '@ant-design/icons/lib/icons/InfoCircleFilled';
import { Space } from 'antd';
import classNames from 'classnames';
import { ReactNode } from 'react';

export interface MissingErrorComponentProps {
  children?: ReactNode;
  icon?: ReactNode;
  className?: string;
}

export const MissingErrorComponent = ({
  children = 'Missing!',
  icon = <InfoCircleFilled className="danger-svg" />,
  className = '',
}: MissingErrorComponentProps) => (
  <Space>
    {icon}
    <div
      className={classNames({
        'text-red': true,
        [className]: !!className,
      })}
    >
      {children}
    </div>
  </Space>
);
