import { Button, ButtonProps } from 'antd';
import classNames from 'classnames';
import FormSubmitButton, { FormSubmitButtonProps } from '../FormSubmitButton/FormSubmitButton';

export interface CancelSubmitButtonsComponentProps extends FormSubmitButtonProps {
  onCancel?: () => void;
  submitText?: string;
  cancelText?: string
  disabled?: boolean;
  className?: string;
  type?: ButtonProps['type'];
}

export const CancelSubmitButtonsComponent = ({
  onCancel,
  submitText = 'Save',
  cancelText = 'Cancel',
  disabled,
  className = '',
  type,
  isLoading = false,
  shouldBeDisabled,
}: CancelSubmitButtonsComponentProps) => (
  <div
    className={classNames({
      'flex jc-e mt20': true,
      [className]: true,
    })}
  >
    {
      onCancel
      && (
        <Button
          type={type}
          onClick={onCancel}
          disabled={disabled}
        >
          {cancelText}
        </Button>
      )
    }
    <FormSubmitButton
      isLoading={isLoading}
      shouldBeDisabled={shouldBeDisabled}
      text={submitText}
    />
  </div>
);

export default CancelSubmitButtonsComponent;
