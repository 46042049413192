import { useRef } from 'react';

export interface UseRetryOptions {
  retryAttempts?: number;
  delay?: number; // ms
}

export const useRetry = (
  options: UseRetryOptions = {},
) => {
  const count = useRef(0);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const increment = () => {
    count.current += 1;
  };
  const reset = () => {
    count.current = 0;
  };

  const clearTO = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = undefined;
  };

  const resetRetry = () => {
    clearTO();
    reset();
  };

  const retry = (
    func: () => void,
    _options?: UseRetryOptions,
  ) => {
    const {
      retryAttempts = 1,
      delay = 500,
    } = options || _options || {};
    if (count.current > retryAttempts) {
      resetRetry();
      return false;
    }
    timeoutRef.current = setTimeout(() => {
      func();
      clearTO();
    }, delay);
    increment();
    return true;
  };

  return {
    retry,
    resetRetry,
  };
};
