import { useState } from 'react';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { AnchorComponent } from '../../../../uiComponent/AnchorComponent/AnchorComponent';
import { AnchorLinkComponent } from '../../../../uiComponent/AnchorComponent/AnchorLinkComponent';
import { CalendarDrawerComponent } from '../../../calendar/component/CalendarDrawerComponent/CalendarDrawerComponent';
import { PatientCommonCardTitleComponent } from '../PatientCommonCardTitleComponent/PatientCommonCardTitleComponent';
import { MakeSchedulesComponent } from '../PatientVisitsListComponent/MakeSchedulesComponent/MakeSchedulesComponent';
import { ClinicEventsService } from '../PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { PatientVisitListComponent } from '../PatientVisitsListComponent/PatientVisitsListComponent';

interface PatientVisitsComponentProps {
  onEditDone?: () => void;
  clinicList: {
    year: string;
    events: ClinicEventsService[]
  }[];
}

export const PatientVisitsComponent = ({
  clinicList,
  onEditDone,
}: PatientVisitsComponentProps) => {
  const [selectedVisit, setSelectedVisit] = useState<ClinicEventsService>();

  const handleEdit = (v: ClinicEventsService) => {
    setSelectedVisit(v);
  };

  const handleClose = () => {
    setSelectedVisit(undefined);
    onEditDone?.();
  };
  useUpdateListener('GO_TO_CHECK_IN', handleClose);

  return (
    <AnchorComponent targetOffset={32}>
      <div className="flex jc-e">
        <MakeSchedulesComponent />
      </div>
      {clinicList.map(({ year, events }) => (
        <AnchorLinkComponent
          key={year}
          id={year}
          href={`#${year}`}
          title={year}
        >
          <PatientCommonCardTitleComponent
            className="patient-clinic-events-year"
          >
            {year}
          </PatientCommonCardTitleComponent>
          <PatientVisitListComponent
            data={events}
            onEdit={handleEdit}
          />
        </AnchorLinkComponent>
      ))}
      <CalendarDrawerComponent
        isOpen={!!selectedVisit}
        onClose={handleClose}
        calendarInitialValue={{
          date: selectedVisit?.startTime?.toISOString(),
          eventId: selectedVisit?.calendarEventId,
          participants: selectedVisit?.visitParticipantsId,
        }}
      />
    </AnchorComponent>
  );
};
