import { SelectType } from '../../features/Input/types';
import { LanguageType } from '../../types/languages';
import LanguageSelectComponent from '../LanguageSelectComponent/LanguageSelectComponent';

export interface PatientSpokenLanguageSelectComponentProps extends SelectType<LanguageType[]> {}

// Note: currently, saving only 1 language as spoken language
export const PatientSpokenLanguageSelectComponent = ({
  value,
  onChange,
  ...props
}: PatientSpokenLanguageSelectComponentProps) => {
  const handleOnChange = (value?: unknown) => {
    const spokenLanguage = (value ? [value] : []) as LanguageType[];
    onChange?.(spokenLanguage);
  };

  return (
    <LanguageSelectComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={value?.[0] as LanguageType}
      onChange={handleOnChange}
    />
  );
};
