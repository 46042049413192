import { Timeline } from 'antd';
import { map } from 'lodash';
import { Note } from '../../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';

export interface A1CNotesTimelineComponentProps {
  notes?: Note[];
}

export const A1CNotesTimelineComponent = ({
  notes = [],
}: A1CNotesTimelineComponentProps) => (
  <Timeline className="a1c-note-container" reverse>
    {map(notes, (note, i) => (
      <Timeline.Item
        key={i}
      >
        <DisplayDateComponent
          value={note.createdAt}
          format="DATE_TIME_AM_PM"
        />
        {'. '}
        {note.note}
      </Timeline.Item>
    ))}
  </Timeline>
);
