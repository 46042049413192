import { DietEnum } from '../../uc-api-sdk';

export interface DietEnumComponentProps {
  value: DietEnum;
}

export const DietEnumComponent = ({
  value,
}: DietEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case DietEnum.NONE: return 'None';
      case DietEnum.WHOLE_30: return 'Whole 30 diet';
      case DietEnum.LOW_SUGAR: return 'Low sugar';
      case DietEnum.DIET_PILLS: return 'Diet pills';
      case DietEnum.EXERCISE: return 'Exercise';
      case DietEnum.ATKINS: return 'Atkins diet';
      case DietEnum.WEIGHT_WATCHERS: return 'Weight watchers diet';
      case DietEnum.LOW_CARB: return 'Low carb diet';
      case DietEnum.LOW_SODIUM: return 'Low sodium diet';
      case DietEnum.CALORIE_RESTRICTION: return 'Calorie Restriction';
      case DietEnum.BLOOD_TYPE: return 'Blood type diet';
      case DietEnum.SOUTH_BEACH: return 'South beach diet';
      case DietEnum.KETO: return 'Keto diet';
      case DietEnum.HIGH_PROTEIN: return 'High protein diet';
      case DietEnum.LOW_FAT: return 'Low fat diet';
      default: return null;
    }
  };

  return (
    <span>{getText()}</span>
  );
};
