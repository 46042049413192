import React from 'react';
import {
  Col, Row, Typography,
} from 'antd';
import { omit } from 'lodash';
import { Icon } from '../../../../icons/Icon';
import { UNIFIEDCARE_LOGO } from '../../../../assets/logoIconInfo';
import './AppInfoComponent.scss';
import { TitleContentTextComponent } from '../../../../uiComponent/TitleContentTextComponent/TitleContentTextComponent';
import { TagComponent } from '../../../../uiComponent/TagComponent/TagComponent';
import { AppInfoResponse, AuthorityInfo, PhoneReportInfoPermissionStatusEnum } from '../../../../uc-api-sdk';
import { permissionKeyLabelMapping } from '../../constant';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { SendDownloadAppMsgBtnContainer } from '../../../unifiedCareApp/container/SendDownloadAppMsgBtnContainer/SendDownloadAppMsgBtnContainer';

const { Text } = Typography;

export interface AppInfoComponentProp {
  info?: AppInfoResponse;
  patientId: string;
}

export const AppInfoComponent = ({
  info,
  patientId,
}: AppInfoComponentProp) => {
  const authorityInfo: Partial<Record<keyof AuthorityInfo, PhoneReportInfoPermissionStatusEnum | null>> = omit(info?.authorityInfo ?? {}, ['openAppCountsAfterLogin']);
  const keys = Object.keys(authorityInfo) as (keyof AuthorityInfo)[];
  const grantedStatusArray = keys
    .filter((key) => (authorityInfo[key] === PhoneReportInfoPermissionStatusEnum.granted));
  const statusArray = keys
    .filter((key) => (authorityInfo[key] !== PhoneReportInfoPermissionStatusEnum.granted));
  const labelsGrantedArray = grantedStatusArray.map((key) => permissionKeyLabelMapping[key]);
  const labelsOtherArray = statusArray.map((key) => permissionKeyLabelMapping[key]);

  return (
    <Row className="app-info">
      <Col span={4}>
        <Row>
          <Text>
            Unified Care App
          </Text>
        </Row>
        <Row>
          <Text type="secondary">
            App Version:
            <DisplayOrEmptyComponent value={info?.mobileInfo?.appVersion} />
          </Text>
        </Row>
        <Row className="mt60">
          <SendDownloadAppMsgBtnContainer patientId={patientId} title="App download SMS" />
        </Row>
      </Col>
      <Col span={2}>
        <div className="app-info-logo-container flex ai-c jc-c">
          <Icon className="app-info-logo" info={UNIFIEDCARE_LOGO} />
        </div>
      </Col>
      <Col span={18}>
        <Row justify="space-between">
          <Col span={5}>
            <TitleContentTextComponent
              title="Last used"
              content={(
                <DisplayDateComponent
                  value={info?.updatedAt}
                  format="USA_DATE"
                  fallbackText={<DisplayOrEmptyComponent />}
                />
              )}
            />
          </Col>
          <Col span={5}>
            <TitleContentTextComponent
              title="Initial Used"
              content={(
                <DisplayDateComponent
                  value={info?.initialUsed}
                  format="USA_DATE"
                  fallbackText={<DisplayOrEmptyComponent />}
                />
              )}
            />
          </Col>
          <Col span={5}>
            <TitleContentTextComponent
              title="Device Name"
              content={
                <DisplayOrEmptyComponent value={info?.mobileInfo?.phoneModel} />
              }
            />
          </Col>
          <Col span={4}>
            <TitleContentTextComponent
              title="Operation Version"
              content={<DisplayOrEmptyComponent value={info?.mobileInfo?.phoneOSVersion} />}
            />
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={18}>
            <TitleContentTextComponent
              title="App Authority"
              content={(
                <div className="app-info-tag">
                  <TagComponent type="success" iconType="halfSolid" background="none" iconColor="blue">
                    <div className="breakword">
                      {(labelsGrantedArray && labelsGrantedArray.length > 0) ? labelsGrantedArray.join(', ') : <DisplayOrEmptyComponent />}
                    </div>
                  </TagComponent>
                  <TagComponent type="error" iconType="halfSolid" background="none">
                    <div className="breakword">
                      {(labelsOtherArray && labelsOtherArray.length > 0) ? labelsOtherArray.join(', ') : <DisplayOrEmptyComponent />}
                    </div>
                  </TagComponent>
                </div>
              )}
            />
          </Col>
          <Col span={4}>
            <TitleContentTextComponent title="Reward Points" content={<DisplayOrEmptyComponent value={info?.rewardPoints} />} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
