import { Dayjs } from 'dayjs';
import { CalendarEventBaseComponent } from '../CalendarEventBaseComponent/CalendarEventBaseComponent';

export interface CalendarNoAccessComponentProps {
  startTime: Dayjs;
  endTime: Dayjs;
}

export const CalendarNoAccessComponent = ({
  startTime,
  endTime,
}: CalendarNoAccessComponentProps) => (
  <CalendarEventBaseComponent
    title="Busy"
    startTime={startTime}
    endTime={endTime}
  />
);
