import { includes } from 'lodash';
import { APIResponse } from '../../../uc-api-sdk';

// refer to ucbe /exception/UcExceptionCodeEnum.java
enum RESTRICTED_ACCESS_CODE {
  UNAUTHORIZED_PATIENT = 10003,
}

export class RestrictedAccess {
  static getRestrictedAccessMessage(code: RESTRICTED_ACCESS_CODE) {
    switch (code) {
      case RESTRICTED_ACCESS_CODE.UNAUTHORIZED_PATIENT:
        return 'not authorized';
      default:
        return null;
    }
  }

  static isPatientRestricted(response?: APIResponse<unknown>) {
    const { code, msg } = response || {};
    if (
      code === RESTRICTED_ACCESS_CODE.UNAUTHORIZED_PATIENT
      && includes(msg, this.getRestrictedAccessMessage(code))
    ) {
      console.info('[INFO] Restricted patient access:', code);
      return true;
    }
    return false;
  }
}
