import { Button } from 'antd';
import { usePatientSendSMS, MsgTemplateTypeEnum } from '../../../../uc-api-sdk';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';

export interface SendDownloadAppMsgBtnContainerProps {
  patientId: string;
  phoneNumber?: string;
  title?: string;
  buttonText?: string;
}

export const SendDownloadAppMsgBtnContainer = ({
  phoneNumber,
  patientId,
  title = 'Send download message',
  buttonText,
}: SendDownloadAppMsgBtnContainerProps) => {
  const patientSms = usePatientSendSMS({});

  const handleSendDownloadMessage = async () => {
    const req = patientSms.send({
      params: {
        memberId: patientId,
        request: {
          msgType: MsgTemplateTypeEnum.APP_INVITATION,
          phoneNumber,
        },
      },
    });
    await ApiRequestHelper.tryCatch(req, {
      error: 'Could not send the message!',
      success: 'Download link sent!',
    });
  };

  return (
    <Button
      type={buttonText ? 'text' : 'link'}
      onClick={handleSendDownloadMessage}
      className="p0"
      disabled={patientSms.isLoading}
      loading={patientSms.isLoading}
    >
      {buttonText || title}
    </Button>
  );
};
