import { Radio, RadioGroupProps } from 'antd';
import { InputType } from '../../features/Input/types';

export interface YesNoRadioComponentProps extends InputType<boolean> {
}

export const YesNoRadioComponent = ({
  onChange,
  ...props
}: YesNoRadioComponentProps) => {
  const handleChange: RadioGroupProps['onChange'] = (v) => {
    onChange?.(v.target.value as boolean);
  };

  return (
    <Radio.Group
      options={[
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ]}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onChange={handleChange}
    />
  );
};
