import { Form, Input, InputProps } from 'antd';
import { FormItemProps } from 'antd/es/form/FormItem';
import classNames from 'classnames';
import { FC } from 'react';
import EmailService from '../../services/EmailService';

export type EmailInputFormItemComponentProps = FormItemProps & {
  name?: string,
  label?: string,
  className?: string,
  isRequired?: boolean,
  size?: InputProps['size'],
  placeholder?: InputProps['placeholder']
};

const EmailInputFormItemComponent: FC<EmailInputFormItemComponentProps> = ({
  name = 'email',
  label = 'Email',
  isRequired = false,
  className = '',
  rules,
  size,
  placeholder,
  initialValue,
}) => {
  const getRules = () => {
    const requiredRules = [
      { validator: EmailService.getEmailValidator(isRequired) },
    ];
    return requiredRules as FormItemProps['rules'];
  };

  return (
    <Form.Item
      data-testid="email-input-form-item"
      className={classNames({
        [className]: !!className,
        'email-input-form-item': true,
      })}
      name={name}
      label={label}
      rules={rules || getRules()}
      initialValue={initialValue}
    >
      <Input
        size={size}
        placeholder={placeholder}
      />
    </Form.Item>
  );
};

export default EmailInputFormItemComponent;
