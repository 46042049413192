import React from 'react';
import { Button } from 'antd';
import { MessageChannelSectionHrComponent } from '../MessageChannelSectionHrComponent/MessageChannelSectionHrComponent';

export interface MessageChannelSectionHrMoreMessageComponentProps {
  onClick?: () => void;
}

export const MessageChannelSectionHrMoreMessageComponent = ({
  onClick,
}: MessageChannelSectionHrMoreMessageComponentProps) => (
  <MessageChannelSectionHrComponent>
    <Button type="primary" ghost onClick={onClick}>More messages</Button>
  </MessageChannelSectionHrComponent>
);
