import { map } from 'lodash';
import { ClinicEventsService } from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { FutureFollowUpScheduleTimeComponent } from '../FutureFollowUpScheduleTimeComponent.tsx/FutureFollowUpScheduleTimeComponent';

import './FutureFollowUpScheduleComponent.scss';
import { WorklistCalendarDrawerEditButtonComponent } from '../WorklistCalendarDrawerEditButtonComponent/WorklistCalendarDrawerEditButtonComponent';
import { VisitTypeComponent } from '../../../visit/component/VisitTypeComponent/VisitTypeComponent';
import { VisitTypeEnum } from '../../../../uc-api-sdk';

export interface FutureFollowUpScheduleComponentProps {
  clinicEvents: ClinicEventsService[];
}

export const FutureFollowUpScheduleComponent = ({
  clinicEvents,
}: FutureFollowUpScheduleComponentProps) => (
  <div className="future-follow-up-schedule">
    <div className="b7">
      Upcoming Visit:
    </div>
    <div className="flex f1 fd-c gap3">
      {
        map(clinicEvents, (clinicEvent) => (
          <div
            key={clinicEvent.id}
            className="flex f-w"
          >
            <VisitTypeComponent
              value={clinicEvent.visitType as VisitTypeEnum}
              type="full"
            />
            {' '}
            on
            <div className="flex f-w">
              <FutureFollowUpScheduleTimeComponent
                clinicEvent={clinicEvent}
              />
            </div>
            <WorklistCalendarDrawerEditButtonComponent
              clinicEvent={clinicEvent}
            />
          </div>
        ))
      }
    </div>
  </div>
);
