import {
  filter,
  forEach, omit, values
} from 'lodash';
import { Dayjs } from 'dayjs';
import { message } from 'antd';
import { LabResult, useTranscribingJobBatchInsert } from '../../../../../uc-api-sdk';
import { FetchComponent } from '../../../../../uiComponent/FetchComponent/FetchComponent';
import { FlowerSpin } from '../../../../../uiComponent/FlowerSpin/FlowerSpin';
import { LLMTranscribeLabResultComponent, LLMTranscribeLabResultComponentProps } from '../../component/LLMTranscribeLabResultComponent/LLMTranscribeLabResultComponent';
import { LLMLabResultTableItem } from '../../type';
import { ApiRequestHelper } from '../../../../../helpers/ApiRequest';
import { useUpdate } from '../../../../../contexts/UpdateContext/UpdateContext';

import './LLMTranscribeLabResultContainer.scss';
import { useLLMBlockPrompt } from '../../../hook/useLLMBlockPrompt';
import { useLabResultHelper } from '../../../../transcribing/hook/useLabResultHelper';

export interface LLMTranscribeLabResultContainerProps {
  patientId: string;
  onSubmit?: (jobId?: string) => void;
}

export const LLMTranscribeLabResultContainer = ({
  patientId,
  onSubmit,
}: LLMTranscribeLabResultContainerProps) => {
  const submitReviewedResultInfo = useTranscribingJobBatchInsert({});
  const {
    unblockUnmountLLM,
  } = useLLMBlockPrompt();
  const {
    getCollectedDate
  } = useLabResultHelper();
  const updateHook = useUpdate('LAB_CREATED');
  const handleSubmit: LLMTranscribeLabResultComponentProps['onSubmit'] = (
    labResults,
    jobId,
  ) => {
    if (!jobId) {
      message.error('Something went wrong. Please note down changes and reprocess', 3);
      return;
    }
    const userReviewedResult = [] as LabResult[];
    labResults.forEach((labResult) => {
      const {
        templateId,
        templateName,
        results,
      } = labResult;
      const groupedByDate = {} as Record<string, LabResult>;
      forEach(results, (result: LLMLabResultTableItem) => {
        const {
          dateCollected: dateCollectedDayjs,
          isChecked = true, // only A1C has this field
        } = result;
        const {
          dateCollected,
          localDateCollected,
        } = getCollectedDate(dateCollectedDayjs as Dayjs);
        if (!localDateCollected || !isChecked) return;
        groupedByDate[localDateCollected] = {
          userId: patientId,
          templateId,
          templateName,
          dateCollected,
          localDateCollected,
          results: [
            ...groupedByDate[localDateCollected]?.results || [],
            omit(result, ['dateCollected', 'referenceLevels']),
          ],
        };
      });
      const nonEmptyResults = filter(groupedByDate, (value) => !!value?.results?.length);
      userReviewedResult.push(...values(nonEmptyResults));
    });
    const res = submitReviewedResultInfo.send({
      params: {
        request: {
          jobId,
          userReviewedResult,
        },
      }
    });
    ApiRequestHelper.tryCatch(res, {
      success: '',
      error: 'Failed to add lab results',
      onSuccess: () => {
        unblockUnmountLLM?.();
        message.success('Lab results added', 3);
        updateHook.updateValue();
        onSubmit?.(jobId);
      },
    });
  };

  return (
    <div className="llm-transcribe-labresult-container">
      <FetchComponent
        info={submitReviewedResultInfo}
        loadingOverlay={(
          <div className="flex fd-c ai-c jc-c w100 h100">
            <FlowerSpin />
            <div>
              Importing selected results, please wait…
            </div>
          </div>
        )}
        alwaysShowChildren
      >
        <LLMTranscribeLabResultComponent
          patientId={patientId}
          onSubmit={handleSubmit}
          disabled={submitReviewedResultInfo.isLoading}
        />
      </FetchComponent>
    </div>
  );
};
