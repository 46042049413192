import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { StickyNoteMetadata } from './types';
import { StickyNoteMetadataComponent } from './StickyNoteMetadataComponent';
import { UserNameTitleComponent } from '../../features/user/component/UserNameTitleComponent/UserNameTitleComponent';
import { DisplayDateComponent } from '../DisplayComponent/DisplayDateComponent';
import { TextComponent } from '../TextComponent/TextComponent';

export interface StickyNoteSingleMemoComponentProps extends StickyNoteMetadata {
  id: string;
  value: string;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  showButtons?: boolean;
}

export const StickyNoteSingleMemoComponent = ({
  id,
  value,
  onEdit,
  onDelete,
  updatedAt,
  updatedByUser,
  createdAt,
  createdByUser,
  showButtons = true,
}: StickyNoteSingleMemoComponentProps) => {
  const shouldShowCreatedBy = (
    createdAt && updatedAt && (createdAt !== updatedAt)
    && createdByUser
  );
  const handleEdit = () => {
    onEdit(id);
  };

  const handleDelete = () => {
    Modal.confirm({
      content: 'Are you sure you want to delete this sticky note?',
      okText: 'Delete',
      okButtonProps: {
        danger: true
      },
      onOk: () => onDelete(id)
    });
  };

  return (
    <>
      <div className="sticky-note-single-memo-component">
        <div>
          <StickyNoteMetadataComponent updatedAt={updatedAt} updatedByUser={updatedByUser} />
          <div className="sticky-note-single-memo-component__note">{value}</div>
        </div>
        {showButtons && (
          <div className="flex text-gray-scale-3">
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={handleDelete}
            />
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={handleEdit}
            />
          </div>
        )}
      </div>
      <div className="">
        {
          shouldShowCreatedBy
          && (
            <TextComponent size="small" italic color="gray2">
              (Originally written by
              {' '}
              <UserNameTitleComponent value={createdByUser} />
              {' '}
              on
              {' '}
              <DisplayDateComponent
                value={createdAt}
                format="USA_DATE"
              />
              )
            </TextComponent>
          )
        }
      </div>
    </>
  );
};
