import { first } from 'lodash';
import React, { useEffect } from 'react';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { OtherLabTableComponent } from '../../component/LabResultTableComponent/OtherLabTableComponent';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { useLabResultInfo } from '../../hook/useLabResultSearch';
import { LabResultA1CTableContainerProps } from './LabResultA1CTableContainer';
import { LabResultClass } from '../../../../uc-api-sdk';

export interface OtherLabTableContainerProps extends LabResultA1CTableContainerProps {
}

export const OtherLabTableContainer = ({
  patientId,
  setLatestCreatedAt,
}: OtherLabTableContainerProps) => {
  const {
    info, tableChange, allInfo, refetchInfo
  } = useLabResultInfo(patientId);
  useUpdateListener('LAB_EDITED', refetchInfo);
  useUpdateListener('LAB_DELETE', refetchInfo);
  useUpdateListener('LAB_CREATED', refetchInfo);

  useEffect(() => {
    allInfo.refetch();
  }, [tableChange.processedSort]);

  return (
    <FetchComponent
      info={info}
      alwaysShowChildren
      showErrorOverlay={false}
      showLoadingOverlay={false}
      emptyOverlay={<h2>No Data!!!</h2>}
    >
      {(value, info) => {
        const {
          content = [],
        } = (value?.rawValue?.data || {});
        useEffect(() => {
          // get the latest created lab;
          setLatestCreatedAt?.(first(value?.rawValue?.data.content || []));
        }, [info.data]);

        return (
          <OtherLabTableComponent
            allOtherLabResult={allInfo.data?.data?.content as LabResultClass[] || []}
            dataSource={content}
            onChange={tableChange.handleTableChange}
            pagination={info.pagination}
          />
        );
      }}
    </FetchComponent>
  );
};
