export const isOldMobileAppVersion = (latestVersion: string, patientsVersion: string) => {
  if (!latestVersion || !patientsVersion) {
    return false;
  }
  const latestVersionParts = latestVersion?.split('.');
  const patientsVersionParts = patientsVersion?.split('.');
  const k = Math.min(latestVersionParts.length, patientsVersionParts.length);

  for (let p = 0; p < k; p += 1) {
    const latestVersionPart = parseInt(latestVersionParts[p], 10);
    const patientsVersionPart = parseInt(patientsVersionParts[p], 10);
    if (latestVersionPart > patientsVersionPart) {
      return true;
    }
  }
  if (latestVersionParts.length <= patientsVersionParts.length) {
    return false;
  }

  return true;
};

export default isOldMobileAppVersion;
