import { Checkbox, FormItemProps, Form } from 'antd';
import { NamePath } from 'rc-field-form/lib/interface';

export interface ICDProgramCheckboxFormItemComponentProps {
  name: NamePath;
  label?: string;
  rules?: FormItemProps['rules'];
  disabled?: boolean;
}

export const ICDProgramCheckboxFormItemComponent = ({
  name,
  label,
  rules,
  disabled,
  ...restProps
}: ICDProgramCheckboxFormItemComponentProps) => (
  <Form.Item
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...restProps}
    name={name}
    label={label}
    rules={rules}
    valuePropName="checked"
    className="icd-program-checkbox"
  >
    <Checkbox
      disabled={disabled}
      data-testid="icdProgramCheckbox"
    />
  </Form.Item>
);
