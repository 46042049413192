import { forEach, map, omit } from 'lodash';
import { useMemo } from 'react';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { OrderFormValues } from '../../hook/useOrderFormHook';
import { OrderSuppliesContextProvider } from '../../../../contexts/OrderSuppliesContext/OrderSuppliesContext';
import { OrderSuppliesReplacementFormComponent } from '../../component/OrderSuppliesReplacementFormComponent/OrderSuppliesReplacementFormComponent';
import {
  DeviceDeliveryMethodEnum,
  DeviceModelEnum,
  LoanDevice,
  OrderItem,
  OrderItemEnum,
  PatientDevice,
  usePatientCreateUserOrder,
  usePatientDeletePatientDevice,
  usePatientUpdateLoanDevice,
  usePatientUpdatePatientDevice
} from '../../../../uc-api-sdk';
import { SupplyTypeEnum } from '../../type';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { UnassignDevice } from '../../component/OrderReturnReminderComponent/OrderReturnReminderComponent';
import { DeviceHelper } from '../../../device/helper';

const mapDeviceTypeToOrderItemEnum = (
  name: SupplyTypeEnum | DeviceModelEnum
): OrderItemEnum => {
  switch (name) {
    case SupplyTypeEnum.BG_STRIP:
    case SupplyTypeEnum.LANCETS:
      return OrderItemEnum.BLOOD_GLUCOSE_SUPPLY;
    case SupplyTypeEnum.WIFI_ROUTER: return OrderItemEnum.BPM1_ROUTER;
    case SupplyTypeEnum.HOTSPOT: return OrderItemEnum.BPM1_HOTSPOT;
    case DeviceModelEnum.BPM1:
    case DeviceModelEnum.BPM1_HOTSPOT:
      return OrderItemEnum.IHEALTH_CLEAR;
    case DeviceModelEnum.PY_802_LTE:
      return OrderItemEnum.CELLULAR_PYLO;
    case DeviceModelEnum.BIOLAND:
      return OrderItemEnum.CELLULAR_BIOLAND;
    case DeviceModelEnum.BP3L: return OrderItemEnum.IHEALTH_EASE;
    case DeviceModelEnum.BG5S: return OrderItemEnum.IHEALTH_GLUCO;
    case DeviceModelEnum.HS2S: return OrderItemEnum.IHEALTH_NEXUS;
    case DeviceModelEnum.PO3: return OrderItemEnum.IHEALTH_AIR;
    default: return name as unknown as OrderItemEnum;
  }
};

export interface OrderSuppliesReplacementFormContainerProps {
  patientInfo: PatientInfo;
  isLoading?: boolean;
  onSubmit?: (value?: OrderFormValues) => void;
  onCancel?: () => void;
}

export const OrderSuppliesReplacementFormContainer = ({
  patientInfo,
  isLoading,
  onSubmit,
  onCancel,
}: OrderSuppliesReplacementFormContainerProps) => {
  const { id, patientInfoService } = patientInfo;
  const {
    isLoading: isLoadingOrder,
    send,
  } = usePatientCreateUserOrder({});
  const deviceUpdateInfo = usePatientUpdatePatientDevice({});
  const deviceUnassignInfo = usePatientDeletePatientDevice({});
  const loanDeviceUpdateInfo = usePatientUpdateLoanDevice({});
  const orderCreateHook = useUpdate('ORDER_CREATED');
  const deviceUpdateHook = useUpdate('DEVICE_MONITOR_UPDATED');

  const debouncedDeviceUpdate = useDebounce(
    deviceUpdateHook.updateValue,
    250,
    [deviceUpdateHook.updateValue]
  );

  const initialValues = useMemo(() => ({
    address: patientInfoService?.getAddress().address ?? undefined,
    address2: patientInfoService?.getAddress().address2 ?? undefined,
    city: patientInfoService?.getAddress().city ?? undefined,
    state: patientInfoService?.getAddress().state ?? undefined,
    zipcode: patientInfoService?.getAddress().zipcode ?? undefined,
  }), [
    patientInfoService?.getAddress()
  ]);

  const handleUnassignDevice = async (deviceId: string) => (
    ApiRequestHelper.tryCatch(
      deviceUnassignInfo.send({
        params: {
          memberId: id,
          deviceId,
        }
      }),
      {
        onSuccess: () => debouncedDeviceUpdate(),
        success: '',
        error: `Failed to unassign ${deviceId}`,
      }
    )
  );

  const handleUnassignLoanDevice = async () => (
    ApiRequestHelper.tryCatch(
      loanDeviceUpdateInfo.send({
        params: {
          memberId: id,
          updateRequest: [] as LoanDevice[],
        }
      }),
      {
        onSuccess: () => debouncedDeviceUpdate(),
        success: '',
        error: 'Failed to unassign loaner phone.',
      }
    )
  );

  const handleOnSubmit = async (
    values: OrderFormValues,
    unassignDevices?: UnassignDevice[],
  ) => {
    const {
      devicesList,
      suppliesList,
      deliveryMethod,
      ...address
    } = values;

    // --- Unassign device ---
    if (unassignDevices?.length) {
      await Promise.all(map(unassignDevices, (device) => {
        const { deviceId, deviceModel } = device;
        if (!deviceId) return true;
        switch (deviceModel) {
          case DeviceModelEnum.LOAN_DEVICE:
            return handleUnassignLoanDevice();
          default:
            return handleUnassignDevice(deviceId);
        }
      }));
    }

    // --- Update device ---
    const newPatientDevices = {
      deviceList: [],
      loanDevices: [],
    } as PatientDevice;
    forEach(devicesList, (device) => {
      if (device?.isChecked && device?.deviceId) {
        newPatientDevices.deviceList?.push({
          vitalType: DeviceHelper.getVitalTypeByModel(device.deviceModel as DeviceModelEnum),
          monitorMethod: device.deviceMethod,
          devices: [omit(device, ['isChecked', 'reason'])],
        });
      }
    });
    const loanerPhone = suppliesList?.LOAN_DEVICE;
    if (loanerPhone?.isChecked && loanerPhone?.deviceId) {
      newPatientDevices.loanDevices?.push({
        memberId: id,
        deviceId: loanerPhone.deviceId,
      });
    }

    if (newPatientDevices.deviceList?.length !== 0) {
      await ApiRequestHelper.tryCatch(
        deviceUpdateInfo.send({
          params: {
            memberId: id,
            updateRequest: {
              deviceList: (
                newPatientDevices.deviceList?.length !== 0
                  ? newPatientDevices.deviceList
                  : undefined
              ),
            }
          }
        }),
        {
          onSuccess: () => debouncedDeviceUpdate(),
          success: 'Device updated successfully',
          error: 'Failed to update device',
        }
      );
    }

    if (newPatientDevices.loanDevices?.length !== 0) {
      await ApiRequestHelper.tryCatch(
        loanDeviceUpdateInfo.send({
          params: {
            memberId: id,
            updateRequest: newPatientDevices.loanDevices as LoanDevice[],
          }
        }),
        {
          onSuccess: () => debouncedDeviceUpdate(),
          success: 'Device updated successfully',
          error: 'Failed to update device',
        }
      );
    }

    // --- Create order ---
    const orderItems = [] as OrderItem[];
    forEach(suppliesList, (
      supply,
      name
    ) => {
      const { isChecked, ...item } = supply || {};
      if (!isChecked) return;
      orderItems.push({
        orderName: (
          mapDeviceTypeToOrderItemEnum(
            name as (SupplyTypeEnum | DeviceModelEnum.LOAN_DEVICE)
          )
        ),
        ...item
      });
    });
    forEach(devicesList, (device, name) => {
      const { isChecked, ...item } = device || {};
      if (!isChecked) return;
      orderItems.push({
        orderName: (
          mapDeviceTypeToOrderItemEnum(
            name as (SupplyTypeEnum | DeviceModelEnum.LOAN_DEVICE)
          )
        ),
        ...item,
      });
    });

    const pickup = deliveryMethod === DeviceDeliveryMethodEnum.PICK_UP;
    const shipTo = {
      fullName: patientInfoService?.getFullName(),
      address: {
        streetName: address.address,
        unit: address.address2,
        city: address.city,
        state: address.state,
        postCode: address.zipcode,
      },
    };

    const params = {
      memberId: id,
      order: {
        pickup,
        shipTo: pickup ? undefined : shipTo,
        orderItems,
        orderFromPortal: true,
      },
    };

    ApiRequestHelper.tryCatch(
      send({ params }),
      {
        onSuccess: () => {
          orderCreateHook.updateValue();
          onSubmit?.();
        },
        success: 'Order created successfully',
        error: 'Failed to create order',
      }
    );
  };

  const debouncedOrderSubmit = useDebounce(handleOnSubmit, 500, [handleOnSubmit]);

  if (isLoading) {
    return null;
  }

  return (
    <OrderSuppliesContextProvider patientInfo={patientInfo}>
      <OrderSuppliesReplacementFormComponent
        initialValues={initialValues}
        onSubmit={debouncedOrderSubmit}
        onCancel={onCancel}
        isLoading={isLoadingOrder}
      />
    </OrderSuppliesContextProvider>
  );
};
