import { useCallback } from 'react';
import { TimeTrackingEventEnum } from '../../../../uc-api-sdk';
import { useBillableEventTracker } from './useBillableEventTracker';
import { BillableTimeInterventionDetailEnum } from '../../contants/contants';

export interface BillableTimeInterventionOptions {
  component: string | undefined;
  patientId?: string | undefined;
  detail?: BillableTimeInterventionDetailEnum | undefined;
}

export const useBillableTimeInterventionComp = () => {
  const { sendTimeTracking } = useBillableEventTracker();

  const send = useCallback(({
    patientId,
    component,
    detail,
  }: BillableTimeInterventionOptions) => {
    sendTimeTracking({
      eventType: TimeTrackingEventEnum.INTERVENTION,
      patientId,
      component,
      detail,
    });
  }, [sendTimeTracking]);

  return {
    send
  };
};
