import { Button, Typography } from 'antd';
import { map } from 'lodash';
import classNames from 'classnames';
import { FC, useEffect } from 'react';
import { PATIENT_TABLE_TABS } from '../../constants';
import './PatientTablesSiderContentComponent.scss';
import { StorageKeyEnum, useSessionStorage } from '../../../../hooks';

const { Text } = Typography;

export interface PatientTablesSiderContentComponentProps {
  onClick: (t: PATIENT_TABLE_TABS) => void;
  myAssignedPatients: number;
  enrolledPatients: number;
  unenrolledPatients: number;
  allPatients: number;
  watchListPatients: number;
  currentTab: PATIENT_TABLE_TABS,
}

export const PatientTablesSiderContentComponent: FC<PatientTablesSiderContentComponentProps> = ({
  onClick,
  myAssignedPatients,
  enrolledPatients,
  unenrolledPatients,
  allPatients,
  watchListPatients,
  currentTab,
}) => {
  const tabs1 = [
    {
      label: 'My assigned patients',
      count: myAssignedPatients,
      key: PATIENT_TABLE_TABS.ASSIGNED,
    },
    {
      label: 'Patients watch list',
      count: watchListPatients,
      key: PATIENT_TABLE_TABS.WATCHLIST,
    },
  ];
  const tabs2 = [
    {
      label: 'Enrolled patients',
      count: enrolledPatients,
      key: PATIENT_TABLE_TABS.ENROLLED_PATIENTS,
    },
    {
      label: 'Unenrolled patients',
      count: unenrolledPatients,
      key: PATIENT_TABLE_TABS.UNENROLLED_PATIENTS,
    },
    {
      label: 'All patients',
      count: allPatients,
      key: PATIENT_TABLE_TABS.ALL_PATIENTS,
    },
  ];

  const [, setTableName] = useSessionStorage<string | undefined>(
    StorageKeyEnum.PATIENT_TABLE_NAME
  );

  useEffect(() => {
    let label = '';
    map(tabs1, (tab) => {
      if (tab.key === currentTab) {
        label = tab.label;
      }
    });

    map(tabs2, (tab) => {
      if (tab.key === currentTab) {
        label = tab.label;
      }
    });
    setTableName(label);
  }, []);

  const renderMyPatientsTabs = () => tabs1.map((tab) => (
    <Button
      key={tab.key}
      className={classNames({
        'w100 ta-l mt10': true,
        active: tab.key === currentTab,
      })}
      type="text"
      size="middle"
      onClick={() => {
        setTableName(tab.label);
        onClick(tab.key);
      }}
    >
      {`${tab.label} (${tab.count || 0})`}
    </Button>
  ));

  const renderMyCareGroupPatientsTabs = () => tabs2.map((tab) => (
    <Button
      key={tab.key}
      className={classNames({
        'w100 ta-l mt10': true,
        active: tab.key === currentTab,
      })}
      type="text"
      size="middle"
      onClick={() => {
        setTableName(tab.label);
        onClick(tab.key);
      }}
    >
      {`${tab.label} (${tab.count || 0})`}
    </Button>
  ));

  return (
    <div
      className="PatientTablesSiderContentComponent flex fd-c ai-s"
    >
      <Text type="secondary" className="tab-title">
        My patients
      </Text>
      {renderMyPatientsTabs()}
      <Text type="secondary" className="tab-title mt30">
        My care group's patients
      </Text>
      {renderMyCareGroupPatientsTabs()}
    </div>
  );
};
