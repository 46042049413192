import { Col, Row } from 'antd';
import { TitleWithContent } from '../../../../uiComponent/TitleWithContent/TitleWithContent';
import './OutstandingVisitCardItemsComponent.scss';
import { DisplayParticipantsComponent } from '../DisplayParticipantsComponent/DisplayParticipantsComponent';
import {
  TranscribeStatusButtonComponent
} from '../../../transcribing/component/TranscribeStatusButtonComponent/TranscribeStatusButtonComponent';
import { CheckInButtonContainer } from '../../../worklist/container/CheckInButtonContainer/CheckInButtonContainer';
import { VisitMethodComponent } from '../../../calendar/component/VisitMethodComponent/VisitMethodComponent';
import {
  ClinicEventsService
} from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import {
  VisitChartingComponent
} from '../../../patient/component/PatientVisitsListComponent/VisitChartingComponent/VisitChartingComponent';
import { VisitsStatusComponent } from '../../../calendar/component/VisitsStatusComponent/VisitsStatusComponent';
import { useVisitStatusHelper } from '../../../calendar/hook/useVisitStatusHelper';
import {
  VisitRescheduleComponent
} from '../../../calendar/component/VisitRescheduleComponent/VisitRescheduleComponent';
import { useZoomContext } from '../../../../contexts/ZoomContext/ZoomContext';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { RejoinZoomButtonComponent } from '../../../zoom/component/RejoinZoomButtonComponent/RejoinZoomButtonComponent';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';

export interface OutstandingVisitCardItemsComponentProps {
  visit: ClinicEventsService;
  onReschedule?: () => void;
}

export const OutstandingVisitCardItemsComponent = ({
  visit,
  onReschedule,
}: OutstandingVisitCardItemsComponentProps) => {
  const {
    getCheckInText,
    showCTA,
    showReschedule,
    disabled,
    disabledReason,
  } = useVisitStatusHelper({
    startTime: visit.startTime,
    isCheckedIn: visit.isCheckedIn,
    visitParticipantsId: visit.visitParticipantsId,
    visitMethod: visit.visitMethod,
    visitStatus: visit.visitsStatus,
    zoomStatus: visit.zoomStatus,
    isCompleted: visit.isCompleted,
  });
  const { send } = useMixpanelContext();
  const { openZoom } = useZoomContext();

  const handleOnCheckedIn = () => {
    if (visit.id && visit.isVideoVisit) {
      send({ event: MixpanelEvents.WorkVisitOpenVideoAndCheckInBtn });
      openZoom(visit.zoomEntry);
    } else {
      send({ event: MixpanelEvents.WorkVisitCheckInBtn });
    }
  };

  return (
    <div className="outstanding-visit-card-items-component">
      <Col>
        <Row>
          <TitleWithContent
            title="Participant"
            content={<DisplayParticipantsComponent participants={visit.visitParticipants} />}
          />
        </Row>

        <Row>
          <TitleWithContent
            title="Visit method"
            content={(
              <div className="flex ai-c">
                <div>
                  {
                    visit.visitMethod
                    && <VisitMethodComponent value={visit.visitMethod} />
                  }
                </div>
                <RejoinZoomButtonComponent
                  visitMethod={visit.visitMethod}
                  isOnGoing={visit.isOnGoing}
                  zoomEntry={visit.zoomEntry}
                />
              </div>
            )}
          />
        </Row>

        <Row>
          <TitleWithContent
            title="Transcribing"
            content={(
              <TranscribeStatusButtonComponent
                transcribeId={visit.transcribeId}
                transcribeStatus={visit.transcribeStatus}
              />
            )}
          />
        </Row>

        <Row>
          <TitleWithContent
            title="Description"
            content={visit.description}
          />
        </Row>

        <Row>
          <TitleWithContent
            title="Visit Status"
            content={(
              <VisitsStatusComponent
                visitStatus={visit.visitsStatus}
                startTime={visit.startTime}
              />
            )}
          />
        </Row>
        {
          visit.isCheckedOut
          && (
            <Row>
              <TitleWithContent
                title="Charting"
                className="visit-charting-button"
                content={(
                  <VisitChartingComponent
                    visit={visit}
                    value={visit.chartingStatus}
                    checkedIn={visit.isCheckedIn}
                    disabled={visit.isCompleted}
                  />
                )}
              />
            </Row>
          )
        }
        {
          showCTA
          && (
            showReschedule
              ? <VisitRescheduleComponent onClick={onReschedule} />
              : (
                <TooltipComponent
                  type={null}
                  title={disabledReason}
                >
                  <CheckInButtonContainer
                    visit={visit}
                    checkedIn={visit.isCheckedIn}
                    buttonText={getCheckInText()}
                    onCheckedIn={handleOnCheckedIn}
                    disabled={disabled}
                  />
                </TooltipComponent>
              )
          )
        }
      </Col>
    </div>
  );
};
