import {
  compact,
  entries,
  join,
  map,
  startCase,
} from 'lodash';
import { useMemo } from 'react';
import { CarePlanContent } from '../../uc-api-sdk';

export interface CarePlanUpdatedContentListComponentProps {
  carePlanUpdatedContents: CarePlanContent;
}

type CarePlanContentValue = [keyof CarePlanContent, boolean];

export const CarePlanUpdatedContentListComponent = ({
  carePlanUpdatedContents,
}: CarePlanUpdatedContentListComponentProps) => {
  const getContentValue = (cpContent: CarePlanContentValue) => {
    const [content, value] = cpContent || [];
    if (value === false) return null;
    return startCase(content?.replace('has', ''));
  };

  const allUpdatedContents = useMemo(() => (
    compact(
      map(
        entries(carePlanUpdatedContents),
        getContentValue
      )
    )
  ), [carePlanUpdatedContents]);

  return (
    <span className="care-plan-updated-content">
      {
        allUpdatedContents?.length > 0
          ? join(allUpdatedContents, ', ')
          : 'N/A'
      }
    </span>
  );
};
