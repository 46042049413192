import dayjs from 'dayjs';
import { ReactNode, useMemo } from 'react';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import './MedicalAlertDueTimeComponent.scss';

export interface MedicalAlertDueTimeComponentProps {
  dueDate: string | undefined;
}
export const MedicalAlertDueTimeComponent = ({
  dueDate,
}: MedicalAlertDueTimeComponentProps) => {
  const dataString: ReactNode = useMemo(() => {
    if (!dueDate) return (<DisplayOrEmptyComponent />);
    const date = TimezoneService.calcDateTimeDayjs(dueDate ?? '');
    const currentTime = dayjs();
    const remainingHours = date.diff(currentTime, 'hours', true);
    if (remainingHours > 0 && remainingHours < 1) {
      return 'Due in 1 hr';
    }
    if (remainingHours > 1 && remainingHours < 2) {
      return 'Due in 2 hrs';
    }
    if (remainingHours > 2) {
      return `Due in ${Math.ceil(remainingHours)} hrs`;
    }
    return 'Overdue';
  }, [dueDate]);

  return (
    dataString === 'Overdue'
      ? (
        <div className="medical-alert-overdue">
          {dataString}
        </div>
      ) : (
        <div className="medical-alert-due-time">
          {dataString}
        </div>
      )
  );
};
