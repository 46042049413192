import { Modal, ModalProps } from 'antd';

export interface PriorAuthModalComponentProps extends ModalProps { }

export const PriorAuthModalComponent = ({
  open,
  onCancel,
  children
}: PriorAuthModalComponentProps) => (
  <Modal
    open={open}
    centered
    onCancel={onCancel}
    width={600}
    maskClosable={false}
    footer={null}
    destroyOnClose
  >
    {children}
  </Modal>
);
