import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import { FixedChildComponent } from './FixedChildComponent';
import './FixedComponent.scss';

export interface FixedComponentProps {
  children: ReactNode[];
  className?: string;
}

interface SubComponents {
  Child: typeof FixedChildComponent;
}

export const FixedComponent: FC<FixedComponentProps> & SubComponents = ({
  children,
  className = '',
}: FixedComponentProps) => (
  <div
    className={classNames({
      [className]: !!className,
      fixedComponent: true,
    })}
  >
    {children}
  </div>
);

FixedComponent.Child = FixedChildComponent;
