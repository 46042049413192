import React from 'react';
import { Button, Space } from 'antd';
import {
  HeaderPopoverContentComponentProps,
} from '../../../../uiComponent/HeaderPopoverComponent/HeaderPopoverComponent';
import { QualifiedComponent } from '../../../../uiComponent/QualifiedComponent/QualifiedComponent';
import './BillableTimePopoverContentComponent.scss';
import { NotQualifiedComponent } from '../../../../uiComponent/NotQualifiedComponent/NotQualifiedComponent';
import {
  AddOfflineTimeDrawerComponent,
} from '../../../../uiComponent/OfflineTimeComponent/AddOfflineTimeDrawerComponent/AddOfflineTimeDrawerComponent';
import {
  PatientProfileTabEnum,
  usePatientPageControllerContext,
} from '../../context/PatientPageControllerContext/PatientPageControllerContext';

export interface BillableTimePopoverContentComponentProps
  extends HeaderPopoverContentComponentProps {
}

export const BillableTimePopoverContentComponent = ({
  onClose,
  max,
  current,
  qualified,
  patientId,
  refetch,
}: BillableTimePopoverContentComponentProps) => {
  const { onTabChange } = usePatientPageControllerContext();

  const handleBillableTimeDetails = () => {
    onClose?.();
    onTabChange(PatientProfileTabEnum.Profile, 'billinghistory');
  };

  const cur = current ?? 0;
  const ma = max ?? 0;

  return (
    <div className="billable-time-popover">
      <div className="fd-c ai-s">
        {qualified ? <QualifiedComponent /> : <NotQualifiedComponent />}
        <div className="my12">
          {cur < ma ? `Spend ${ma - cur} ${ma - cur > 1 ? 'mins' : 'min'} or more to be eligible for billing.`
            : 'You have reached the minimum threshold to bill.'}
        </div>
      </div>
      <Space className="fd-c ai-s" size={0}>
        <AddOfflineTimeDrawerComponent patientId={patientId || ''} refetch={refetch} onClosePopover={onClose} />
        <Button type="link" className="p0" onClick={handleBillableTimeDetails}>
          Check Time Activity Log
        </Button>
      </Space>
    </div>
  );
};
