import { useCallback } from 'react';
import { Modal, ModalFuncProps } from 'antd';
import { BlockUnmountKey, useBlockUnmountContext } from '../../../contexts/BlockUnmountContext/BlockUnmountContext';

export const useLLMBlockPrompt = () => {
  const {
    checkIfBlocked,
    blockPrompt,
    setBlockPrompt,
    blockUnmount,
    unblockUnmount,
  } = useBlockUnmountContext();

  const makeClosePrompt = useCallback((modalProps?: ModalFuncProps): Promise<boolean> => (
    new Promise((resolve) => {
      Modal.confirm({
        title: 'Are you sure to close?',
        content: 'Processed files and your changes will not be saved.',
        okText: 'Yes',
        cancelText: 'No',
        ...modalProps,
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
      });
    })
  ), []);

  const setLLMBlockPrompt = useCallback((blockPrompt = makeClosePrompt) => {
    setBlockPrompt?.(BlockUnmountKey.LLM, blockPrompt);
  }, []);

  return {
    checkIfBlocked,
    blockPrompt,
    makeClosePrompt,
    setLLMBlockPrompt,
    blockUnmountLLM: () => blockUnmount?.(BlockUnmountKey.LLM),
    unblockUnmountLLM: () => unblockUnmount?.(BlockUnmountKey.LLM),
  };
};
