import { RcFile } from 'antd/lib/upload';

export interface MessageImagePreviewComponentProps {
  file: RcFile;
}

export const MessageImagePreviewComponent = ({
  file,
}: MessageImagePreviewComponentProps) => {
  const imageUrl = URL.createObjectURL(file);
  return (
    <img
      src={imageUrl}
      className="image-preview"
      alt="upload-preview"
    />
  );
};
