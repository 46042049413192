import {
  filter, map,
  uniq
} from 'lodash';
import { Button, Table } from 'antd';
import { ColumnType, TableProps } from 'antd/lib/table';
import {
  useCallback,
  useMemo
} from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { LabResultDetailEditableCellComponent } from './LabResultDetailEditableCellComponent';
import './LabResultDetailTableComponent.scss';
import { labResultDetailColumns } from './LabResultDetailTableColumn';
import { HemoglobinA1cTemplateId, LabResultEditableItem } from '../../constants';
import { ReferenceLevelEnum } from '../../../../uc-api-sdk';
import { LabResultDetailEditableRowComponent } from './LabResultDetailEditableRowComponent';

export const MANUAL_RESULT_KEY_PREFIX = 'M-';

export interface LabResultDetailTableComponentProps {
  labResultItem?: LabResultEditableItem[];
  handleSave?: (row: LabResultEditableItem) => void;
  handleRemove?: (index: number) => void;
  template?: string;
  isValueEditable: boolean;
}
export const LabResultDetailTableComponent = ({
  labResultItem,
  handleSave,
  handleRemove,
  template = HemoglobinA1cTemplateId,
  isValueEditable,
}: LabResultDetailTableComponentProps) => {
  const isA1C = template === HemoglobinA1cTemplateId;

  const checkIfManualResultEditable = (record: LabResultEditableItem) => {
    const { isManual, isManualEditable } = record || {};
    return !!isManual && !!isManualEditable;
  };

  const handleAddRow = () => {
    const uniqueId = new Date().getTime();
    const newRow: LabResultEditableItem = {
      dbKey: `${MANUAL_RESULT_KEY_PREFIX}${uniqueId}`,
      name: '',
      value: '',
      unit: '',
      flag: '',
      isManual: true,
      isManualNew: true,
      isManualEditable: true,
    };
    handleSave?.(newRow);
  };

  const handleSaveManual = (
    record: LabResultEditableItem,
  ) => {
    const newData = {
      ...record,
      isManualEditable: false,
      isManualNew: false,
    };
    handleSave?.(newData);
  };

  const handleCancelManual = (
    data: LabResultEditableItem,
    index: number,
  ) => {
    if (data.isManualNew) {
      // remove the new row if it's not saved first time
      handleRemove?.(index);
      return;
    }
    handleSave?.({ ...data, isManualEditable: false });
  };

  const handleStartEditManual = (record: LabResultEditableItem) => {
    handleSave?.({
      ...record,
      isManualEditable: true,
    });
  };

  const components: TableProps<LabResultEditableItem>['components'] = {
    body: {
      row: LabResultDetailEditableRowComponent,
      cell: LabResultDetailEditableCellComponent,
    },
  };

  const flags: ReferenceLevelEnum[] = useMemo(() => uniq(
    map(
      filter(labResultItem, (f) => f.value !== null && f.flag !== null),
      (l: LabResultEditableItem) => l.flag
    ) as ReferenceLevelEnum[]
  ), [labResultItem]);

  const defaultColumns = useTableColumns(
    labResultDetailColumns(isValueEditable),
    template === HemoglobinA1cTemplateId
      ? ['name', 'value', { name: 'flag', args: [template, flags] }, 'isInHouse']
      : [
        { name: 'name', args: [handleStartEditManual] },
        'value',
        { name: 'flag', args: [template, flags] }
      ],
    [template, isValueEditable, flags, handleStartEditManual],
  );

  const columns = useMemo(() => defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      render: null,
      onCell: (record: LabResultEditableItem) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        handleSave,
        render: col.render,
      }),
    };
  }), [defaultColumns, isValueEditable, template]);

  const ManualSaveButtons = useCallback((
    record: LabResultEditableItem,
    index: number,
  ) => (
    <div className="flex">
      <Button
        type="primary"
        onClick={() => handleSaveManual(record)}
      >
        { record.isManualNew ? 'Add' : 'Save' }
      </Button>
      <Button
        onClick={() => handleCancelManual(record, index)}
      >
        Cancel
      </Button>
    </div>
  ), []);

  const AddRow = useCallback(() => (
    (!isA1C && isValueEditable)
      ? (
        <Button
          block
          onClick={handleAddRow}
        >
          <div className="flex ai-c jc-c">
            <PlusOutlined />
            Add a new row
          </div>
        </Button>
      ) : null
  ), [isA1C, isValueEditable]);

  const nonNullItems = filter(
    labResultItem,
    (v: LabResultEditableItem) => v.value
  ) as LabResultEditableItem[];

  const dataSource = isValueEditable ? labResultItem : nonNullItems;

  return (
    <div className="lab-result-detail-table-component">
      <Table
        components={components}
        dataSource={dataSource}
        columns={columns as (ColumnType<LabResultEditableItem>)[]}
        pagination={false}
        rowKey={(r) => r.dbKey ?? ''}
        footer={AddRow}
        expandable={{
          showExpandColumn: false,
          expandedRowRender: ManualSaveButtons,
          expandedRowKeys: (
            filter(dataSource, checkIfManualResultEditable)
              .map((r) => r.dbKey || '')
          ),
          rowExpandable: checkIfManualResultEditable,
        }}
      />
    </div>
  );
};
