import { EligibilityReport, EligibilityStatusEnum } from '../../../../uc-api-sdk';
import { HTMLDisplayComponent } from '../../../../uiComponent/HTMLDisplayComponent/HTMLDisplayComponent';
import { InsuranceEligibilityReportComponent } from '../InsuranceEligibilityReportComponent/InsuranceEligibilityReportComponent';

export interface InsuranceEligibilityHistoryMainComponentProps {
  eligibilityReport: EligibilityReport;
}

export const InsuranceEligibilityHistoryMainComponent = ({
  eligibilityReport,
}: InsuranceEligibilityHistoryMainComponentProps) => (
  <InsuranceEligibilityReportComponent
    status={eligibilityReport.eligibilityStatus as EligibilityStatusEnum}
    inquiryInfo={eligibilityReport}
  >
    <HTMLDisplayComponent
      htmlString={eligibilityReport.fullHtml}
    />
  </InsuranceEligibilityReportComponent>
);
