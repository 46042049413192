import { Table } from 'antd';
import { FC } from 'react';
import classNames from 'classnames';
import { SelectedColumnWithArgs, TableColumnCreator, useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { BPSummaryTableColumns } from './BPSummaryTableColumns';
import { BPSummaryKey } from '../../type';
import '../vitalsTable.scss';

export interface BPSummaryTableData {
  type: BPSummaryKey;
  count: number;
  average: number[];
  range: number[];
  inRangePercentage: number;
  totalCount?: number;
}

export interface BPSummaryTableComponentProps {
  data: Partial<BPSummaryTableData>[];
  totalCount?: number;
}

export const BPSummaryTableComponent: FC<BPSummaryTableComponentProps> = ({
  data,
  totalCount,
}) => {
  const columns = useTableColumns(
    BPSummaryTableColumns,
    [
      'type',
        { name: 'count', args: [totalCount] } as SelectedColumnWithArgs<TableColumnCreator<BPSummaryTableData>, 'count'>,
        'average',
        'range',
        'inRangePercentage'
    ],
    [totalCount]
  );

  return (
    <Table
      className={classNames({
        'summary-table': true,
        'vitals-un-bordered-table': true,
      })}
      dataSource={data}
      columns={columns}
      pagination={false}
      rowKey={(v) => v.type}
    />
  );
};
