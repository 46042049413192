import { Input } from 'antd';
import FormItem, { FormItemProps } from '../../../../uiComponent/FormItem/FormItem';
import { Icon } from '../../../../icons/Icon';
import { NOTE_ICON } from '../../../../assets/commonIconInfo';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { MissingErrorComponent } from '../../../../uiComponent/MissingErrorComponent/MissingErrorComponent';

export interface MTPRNoteFormItemComponentProps extends FormItemProps { }

export const MTPRNoteFormItemComponent = ({
  initialValue,
  required,
  label = (
    <div className="flex mb10">
      <Icon info={NOTE_ICON} />
      <TextComponent size="large" bold>
        Treatment Plan Review Note
      </TextComponent>
    </div>
  ),
}: MTPRNoteFormItemComponentProps) => (
  <FormItem
    name="note"
    label={label}
    rules={[{
      required,
      message: (<MissingErrorComponent>Review Note is Missing</MissingErrorComponent>),
    }]}
    required={required}
    className="mtpr-note"
    initialValue={initialValue}
  >
    <Input.TextArea
      autoSize={{ minRows: 3 }}
      disabled={!required}
    />
  </FormItem>
);
