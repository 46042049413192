import { Article, MeasurementResultTypeEnum } from '../../../uc-api-sdk';

export enum SystemMessageType {
  HAPPY_BIRTHDAY = 'HAPPY_BIRTHDAY',
}

export enum MessageSubType {
  SYSTEM = 'systemMsg',
  ONLINE_CLASS = 'ONLINE_CLASS',
}

export enum MessageType {
  ACK = 'ACK',
  TEXT = 'text',
  FILE_UPLOAD = 'fileUpload',
  MEASUREMENT = 'measurement',
  MEASUREMENTS = 'measurements',
  MSR = 'MSR',
  FOOD_LOG_REQUEST = 'commentFoodLog',
  FOOD_LOG_REVIEWED = 'foodReview'
}

export interface PN_APNS {
  aps: {
    alert: string;
    bigPictureUrl?: string;
    sound: 'default';
    'mutable-content': 1;
  };
  pn_bundle_ids: [
    'com.ihealthlabs.sharecare',
    'com.ihealthlabs.sharecarebp',
  ];
}

export interface PN_GCM {
  data: {
    message: string;
    bigPictureUrl?: string;
    sound: 'default';
  };
}

export interface ArticleMetaData extends Pick<
  Article,
  'id' |
  'url' |
  'name' |
  'thumbnailImageUrl' |
  'summary' |
  'slug' |
  'hasVideo' |
  'isNew' |
  'language' |
  'publishDate' |
  'updatedAt' |
  'createdAt'
> { }

export enum MessageVitalDurationEnum {
  TwoWeek = 'TWO_WEEK',
}

export type MessageDate = string | number;

export type MessageVitalSeverity = 'Low' | 'Normal' | 'High' | 'Critical';

export type BPSeverity = MessageVitalSeverity
  | 'Elevated'
  | 'Hypertension Stage 1'
  | 'Hypertension Stage 2'
  | 'Hypertensive Crisis';

export type BGSeverity = MessageVitalSeverity
  | 'CriticallyLow'
  | 'CriticallyHigh';

export interface BaseMessagePayload {
  displayName?: string;
  member?: string;
  publisher?: string;
  subType?: MessageSubType;
  systemMsgType?: SystemMessageType;
  type: MessageType;
  userRole?: string;
  text?: string;
  originalText?: string;
  originalTextLanguage?: string;
  pn_apns?: PN_APNS;
  pn_gcm?: PN_GCM;
  translation?: string;
}

export type TextMessagePayload = BaseMessagePayload;

export interface FileUploadMessagePayload extends BaseMessagePayload {
  type: MessageType.FILE_UPLOAD;
  fileKey?: string;
}

export interface FoodLogMessagePayload extends BaseMessagePayload {
  type: MessageType.FOOD_LOG_REQUEST;
  foodLogId: string;
}

export interface MSRMessagePayload extends BaseMessagePayload {
  type: MessageType.MSR;
  msrId: string;
}

export interface ArticleMessagePayload extends BaseMessagePayload {
  type: MessageType.TEXT;
  articleMetaData?: ArticleMetaData;
}

export interface VitalMessagePayload extends BaseMessagePayload {
  type: MessageType.MEASUREMENT;
  vitalName: MeasurementResultTypeEnum;
  unit: string;
  severity: BGSeverity;
  measuredAt: MessageDate;
}

export interface BGMessagePayload extends VitalMessagePayload {
  vitalName: MeasurementResultTypeEnum.BG;
  value: string;
}

export interface BPMessagePayload extends Omit<VitalMessagePayload, 'severity'> {
  vitalName: MeasurementResultTypeEnum.BP;
  severity: BPSeverity;
  diastolic: number;
  systolic: number;
  arrhythmia: boolean;
}

export interface POMessagePayload extends VitalMessagePayload {
  vitalName: MeasurementResultTypeEnum.PO;
  oxygen_saturation: string;
}

export interface TMMessagePayload extends VitalMessagePayload {
  vitalName: MeasurementResultTypeEnum.TM;
  temperature: string | number;
}

export interface HSMessagePayload extends VitalMessagePayload {
  vitalName: MeasurementResultTypeEnum.HS;
  weight: string | number;
  weight_change: string | number;
}

export interface VitalDurationMessagePayload extends VitalMessagePayload {
  duration: MessageVitalDurationEnum;
  dateFrom: MessageDate;
  dateTo: MessageDate;
}

export type VitalsMessagePayload = BGMessagePayload
  | BPMessagePayload
  | POMessagePayload
  | TMMessagePayload
  | HSMessagePayload
  | VitalDurationMessagePayload;

export type MessagePayload =
  TextMessagePayload
  | FileUploadMessagePayload
  | FoodLogMessagePayload
  | MSRMessagePayload
  | ArticleMessagePayload
  | VitalsMessagePayload;

export interface MessageTag {
  can: boolean;
  channel: string;
  messageid: string;
  messagetime: number;
  read: boolean;
  tagged: boolean;
  taggedBy: string;
  taggedtime: number;
  taggedto: string;
}

export interface MessageHistoryMessages {
  author: string;
  channel: string;
  messageId: string;
  payload: MessagePayload;
  tag: MessageTag;
  fetchAt: string; // timestamp
  timestamp: string;
  dateTime: number;
}

export interface MessageHistory {
  key: string; // patientId
  lastClientACK: number; // timestamp for patient's last ACK
  tagMessageOffset: number;
  clientUnread: number;
  teamUnread: number;
  messages: MessageHistoryMessages[];
}
