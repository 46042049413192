import { CheckCircleFilled, CopyOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  Button, Divider, Skeleton, Typography, message
} from 'antd';
import { useEffect, useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import './RewriteWithAIComponent.scss';
import { Icon } from '../../../../icons/Icon';
import { REWRITE_WITH_AI_ICON } from '../../../../assets/commonIconInfo';

const { Paragraph } = Typography;

export interface RewriteWithAIComponentProps {
  originalContent: string;
  newContent: string;
  isLoading?: boolean;
  onRefetch?: () => void;
}

export const RewriteWithAIComponent = ({
  originalContent,
  newContent,
  isLoading = false,
  onRefetch,
}: RewriteWithAIComponentProps) => {
  const [editedContent, setEditedContent] = useState<string>(newContent);
  const handleTextOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditedContent(e.target.value);
  };

  useEffect(() => {
    setEditedContent(newContent);
  }, [newContent]);

  const handleCopyNewContent = () => {
    navigator.clipboard.writeText(editedContent);
    message.success('Copied successfully!');
  };

  const handleOnRefetch = () => {
    onRefetch?.();
  };

  return (
    <div className="rewrite-with-ai-component">
      <div className="text-secondary-gray mb10 mt5">Your original writing</div>
      <Paragraph
        ellipsis={{
          rows: 3,
          expandable: true,
          symbol: 'Show more',
        }}
      >
        {originalContent}
      </Paragraph>
      <Divider className="divider" />
      {
        isLoading ? (
          <div>
            <div className="flex gap1 mb10">
              <Icon className="ai-c" info={REWRITE_WITH_AI_ICON} />
              <div className="text-blue">Improving writing</div>
            </div>
            <Skeleton active />
          </div>
        ) : (
          <div>
            <div className="mb10">
              <div className="flex gap1 mb5">
                <CheckCircleFilled className="check-mark" />
                <div className="text-blue">Improved</div>
              </div>
              <TextArea
                value={editedContent}
                autoSize={{ minRows: 3 }}
                onChange={handleTextOnChange}
              />
            </div>
            <div className="flex jc-e gap0">
              <Button
                onClick={handleCopyNewContent}
                className="p0 text-secondary-gray"
                type="link"
                icon={<CopyOutlined />}
              />
              <Button
                onClick={handleOnRefetch}
                className="p0 text-secondary-gray"
                type="link"
                icon={<ReloadOutlined />}
              />
            </div>
          </div>
        )
      }
    </div>
  );
};
