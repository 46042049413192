import { ReactNode } from 'react';
import classNames from 'classnames';
import { CardTextComponent, CardTextComponentProps } from '../CardTextComponent/CardTextComponent';
import { Title } from '../../Title/Title';

export interface CardTitleComponentProps {
  children: ReactNode;
  className?: string;
  noMargin?: boolean;
  size?: CardTextComponentProps['size'];
}

export const CardTitleComponent = ({
  children,
  className = '',
  noMargin,
  size = 'large',
}: CardTitleComponentProps) => {
  if (typeof children === 'string') {
    return (
      <Title
        className={className}
        noMargin={noMargin}
      >
        {children}
      </Title>
    );
  }
  return (
    <CardTextComponent
      className={classNames({
        'card-title-component': true,
        [className]: !!className,
      })}
      bold
      size={size}
    >
      {children}
    </CardTextComponent>
  );
};
