import { useEffect } from 'react';
import { useDeepCompareEffect } from '../../../hooks/useDeepCompareEffect';
import { useOnCallContext } from '../../patientCare/context/OnCallContext/OnCallContext';
import { OnCallType } from '../../patientCare/context/OnCallMainContext/OnCallMainContext';

export const useOnCallEffect = (
  onCallType: OnCallType,
  callback: () => void,
  dependencies?: unknown[],
) => {
  const {
    isOnCall,
    onCallData,
  } = useOnCallContext({ type: onCallType });

  useEffect(() => {
    callback();
  }, []);

  useDeepCompareEffect(callback, [
    isOnCall,
    onCallData?.updatedAt,
    ...(dependencies || [])
  ]);
};
