import { isBoolean } from 'lodash';
import { DiseaseHistoryItem } from '../../../../../../uc-api-sdk';
import { YesNoDisplayComponent } from '../../../../../../uiComponent/YesNoDisplayComponent/YesNoDisplayComponent';
import { PrintDiseaseHistoryDisplayComponent } from './PrintDiseaseHistoryDisplayComponent/PrintDiseaseHistoryDisplayComponent';
import { PrintDiseaseHistorySpecialistComponent } from '../PrintDiseaseHistorySpecialistComponent/PrintDiseaseHistorySpecialistComponent';

export interface PrintDiseaseHistoryGeneralComponentProps {
  diseaseHistory: DiseaseHistoryItem;
}

export const PrintDiseaseHistoryGeneralComponent = ({
  diseaseHistory,
}: PrintDiseaseHistoryGeneralComponentProps) => (
  <>
    <PrintDiseaseHistoryDisplayComponent
      label="Years with this condition"
      value={diseaseHistory.yearsWithCondition}
      extraValue={`year${(diseaseHistory.yearsWithCondition || 0) <= 1 ? '' : 's'}`}
    />
    {
      isBoolean(diseaseHistory.isTakingMedication)
      && (
        <YesNoDisplayComponent
          value={diseaseHistory.isTakingMedication}
          yesDisplay="Patient is taking medication"
          noDisplay="Patient is not taking medication"
        />
      )
    }
    <PrintDiseaseHistorySpecialistComponent
      diseaseHistory={diseaseHistory}
    />
    <PrintDiseaseHistoryDisplayComponent
      label="Other treatment and notes"
      value={diseaseHistory.otherTreatmentAndNotes}
    />
  </>
);
