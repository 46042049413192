import { PageComponent } from '../../../../uiComponent/PageComponent/PageComponent';
import { MedicalAlertDashboardTableContainer } from '../../container/MedicalAlertDashboardTableContainer/MedicalAlertDashboardTableContainer';

export const MedicalAlertDashboardComponent = () => (
  <PageComponent title="Medical Alert Dashboard">
    <MedicalAlertDashboardTableContainer />
  </PageComponent>
);

export default MedicalAlertDashboardComponent;
