import {
  Empty, Select, Table, TableProps, SelectProps
} from 'antd';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { MedicalAlertCategoryEnum, PriorityLevelEnum, SmartAlertTaskStatus } from '../../../../uc-api-sdk';
import { createColumns } from './columns';
import { ClinicSelectComponent } from '../../../../uiComponent/ClinicSelectComponent/ClinicSelectComponent';
import { FromDateToDateDayjs } from '../../../../types/date';
import './MedicalAlertDashboardTableComponent.scss';
import { DateRangeSelectComponent } from '../../../../uiComponent/DateRangeSelectComponent/DateRangeSelectComponent';
import { Filter, Sorter } from '../../../../hooks/useTableChange/useTableChange';
import { useTableColumnWithFiltersAndSorters } from '../../../../hooks/useTableColumnWithFiltersAndSorters/useTableColumnWithFiltersAndSorters';

export interface AlertItem {
  alertName: string;
  reason: string;
  priority: PriorityLevelEnum;
}

export interface MedicalAlertDashboardDataSource {
  id: string;
  memberId: string;
  memberName: string;
  alerts: AlertItem[];
  provider: string;
  assignedRdHC: string;
  triggeredOn: number[];
  resolvedOn: number[];
  resolvedNote: string[];
  status: SmartAlertTaskStatus[];
}

export interface MedicalAlertDashboardTableComponentProps
extends Omit<TableProps<MedicalAlertDashboardDataSource>, 'dataSource' | 'columns'> {
  dataSource: MedicalAlertDashboardDataSource[];
  isLoading?: boolean;
  patientsCount?: number;
  onChangeClinic: (v: string[]) => void;
  onChangeAlertType: (v: MedicalAlertCategoryEnum[]) => void;
  onChangeFromDateToDate: (v: FromDateToDateDayjs) => void;
  fromDateToDate?: FromDateToDateDayjs;
  clinic?: string;
  alertType?: MedicalAlertCategoryEnum[];
  filters: Filter<string>;
  sorters: Sorter<MedicalAlertDashboardDataSource>
}

export const MedicalAlertDashboardTableComponent = ({
  dataSource,
  isLoading,
  patientsCount,
  onChangeAlertType,
  onChangeClinic,
  onChangeFromDateToDate,
  pagination,
  onChange,
  fromDateToDate,
  clinic,
  alertType,
  filters,
  sorters,
}: MedicalAlertDashboardTableComponentProps) => {
  const columns = useTableColumns(
    createColumns(clinic as string),
    [
      'memberName',
      'alerts',
      'provider',
      'assignedRdHC',
      'triggeredOn',
      'resolvedOn',
      'resolvedNote',
      'status'
    ],
    [clinic]
  );

  const columnsWithFilters = useTableColumnWithFiltersAndSorters({
    columns,
    filters: { status: [SmartAlertTaskStatus.TODO], ...filters },
    sorters,
  });

  const renderClinicSelector = () => (
    <div className="w20 text-secondary-gray">
      <div className="flex gap0">
        <div>Clinic</div>
        <div className="warning">*</div>
      </div>
      <ClinicSelectComponent
        selectFirstClinicByDefault
        value={clinic as string}
        onChangeClinicList={onChangeClinic}
      />
    </div>
  );

  const labelRender: SelectProps['tagRender'] = (props) => {
    if (alertType?.length === 2) {
      if (props.label === 'Clinical alerts' && props.value === MedicalAlertCategoryEnum.CLINICAL) {
        return <span>All medical alerts</span>;
      }
      if (props.label === 'Provider alert' && props.value === MedicalAlertCategoryEnum.PROVIDER) {
        return <span />;
      }
    }
    return <span>{props.label}</span>;
  };

  const renderAlertTypeSelector = () => (
    <div className="w20 text-secondary-gray">
      <div className="flex gap0">
        <div>Alert type</div>
        <div className="warning">*</div>
      </div>
      <Select
        showArrow
        showSearch={false}
        placeholder="Select alert types"
        value={alertType}
        className="w100 alert-type-select"
        mode="multiple"
        tagRender={labelRender}
        onChange={onChangeAlertType}
        options={[
          { label: 'Provider alert', value: MedicalAlertCategoryEnum.PROVIDER },
          { label: 'Clinical alerts', value: MedicalAlertCategoryEnum.CLINICAL },
        ]}
      />
    </div>
  );

  const renderDateRangePicker = () => (
    <div className="text-secondary-gray">
      <div className="flex gap0">
        <div>Date</div>
        <div className="warning">*</div>
      </div>
      <DateRangeSelectComponent
        value={fromDateToDate as FromDateToDateDayjs}
        onChange={onChangeFromDateToDate}
        styleType="short"
      />
    </div>
  );

  const emptyNode = (
    <div className="mt100 mb100">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="There is no medical alert yet." />
    </div>
  );

  return (
    <div>
      <div className="flex mb30 gap3">
        {renderClinicSelector()}
        {renderAlertTypeSelector()}
        {renderDateRangePicker()}
      </div>
      <div className="text-secondary-gray mb20 b5 fs16">
        {patientsCount || 0}
        {' patients'}
      </div>
      <Table
        className="medical-alert-dashboard-table"
        dataSource={dataSource}
        loading={isLoading}
        columns={columnsWithFilters}
        size="middle"
        pagination={pagination}
        onChange={onChange}
        rowKey={(v) => v.id}
        locale={{ emptyText: emptyNode }}
      />
    </div>
  );
};
