import { flatten } from 'lodash';
import { ReactNode } from 'react';
import { TableContextProvider, useTableContext } from '../../../../contexts/TableContext/TableContext';
import { sorterHelper } from '../../../../hooks/useTableChange/sorterHelper';
import { TableCacheNameEnum } from '../../../../hooks/useTableChange/useTableChange';
import {
  PatientCareDetail, PatientCareItemEnum, PatientTypeEnum, PriorityLevelEnum, ProgramCategoryEnum,
  VitalEnumType, WorkTypeEnum
} from '../../../../uc-api-sdk';
import { PatientCareFilter } from '../../hook/usePatientCareTableOnChange';

export interface PatientCareTableContextProps {
  children: ReactNode;
}

export const PatientCareTableContext = ({
  children,
}: PatientCareTableContextProps) => (
  <TableContextProvider<PatientCareDetail, PatientCareFilter>
    params={{
      cachePagination: false,
      name: TableCacheNameEnum.PatientCare,
      sortProcessor: (sorter) => sorterHelper(sorter),
      filterProcessor: (filters) => {
        const fil: PatientCareFilter = {};
        if (filters.firstName?.length) {
          fil.idIn = {
            in: filters.firstName as string[]
          };
        }
        if (filters.programs?.length) {
          fil.programsIn = {
            in: filters.programs as ProgramCategoryEnum[]
          };
        }
        if (filters.vitals?.length) {
          fil.vitalsIn = {
            in: filters.vitals as VitalEnumType[]
          };
        }
        const flattenDoctorIds = flatten(filters.doctorId);
        fil.doctorIdIn = flattenDoctorIds.length > 0
          ? { in: flattenDoctorIds as unknown as string[] } : null;

        if (filters.clinicId) {
          fil.clinicIdIn = {
            in: (
              typeof filters.clinicId[0] === 'object'
                ? filters.clinicId[0]
                : filters.clinicId as unknown as string[]
            )
          };
        }
        if (filters.workTypes) {
          fil.workTypesIn = {
            in: filters.workTypes as WorkTypeEnum[],
          };
        }
        if (filters.items?.length) {
          fil.itemsIn = {
            in: filters.items as PatientCareItemEnum[]
          };
        }
        if (filters.priority?.length) {
          fil.priorityIn = {
            in: filters.priority as PriorityLevelEnum[]
          };
        }
        if (filters.patientType?.length) {
          fil.patientTypeIn = {
            in: filters.patientType as PatientTypeEnum[]
          };
        }
        if (filters.includeTestPatient) {
          fil.includeTestPatient = filters.includeTestPatient as unknown as boolean;
        }
        return fil;
      }
    }}
  >
    {children}
  </TableContextProvider>
);

export const usePatientCareTableContext = () => {
  const value = useTableContext<PatientCareDetail, PatientCareFilter>();
  return value;
};
