import { Drawer } from 'antd';
import { ExtraLargeDrawerWidth } from '../../../../constants/drawer';
import { MixpanelProvider } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEventsParents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { CalendarPageParams, CalendarProvider } from '../../context/CalendarContext/CalendarContext';
import { VisitInitialValue } from '../../hook/useCalendarHelper';
import { CalendarWithContextComponent } from '../CalendarWithContextComponent/CalendarWithContextComponent';

export interface CalendarDrawerComponentProps {
  isOpen: boolean;
  onClose?: () => void;
  visitInitialValue?: VisitInitialValue;
  calendarInitialValue?: CalendarPageParams;
  createVisitOnMount?: boolean;
}

export const CalendarDrawerComponent = ({
  isOpen,
  onClose,
  visitInitialValue,
  calendarInitialValue,
  createVisitOnMount
}: CalendarDrawerComponentProps) => (
  <Drawer
    open={isOpen}
    width={ExtraLargeDrawerWidth}
    closable={false}
    destroyOnClose
  >
    <CalendarProvider initialValues={calendarInitialValue}>
      <MixpanelProvider context={MixpanelEventsParents.CalendarPopup}>
        <CalendarWithContextComponent
          onClose={onClose}
          visitInitialValue={visitInitialValue}
          createVisitOnMount={createVisitOnMount}
        />
      </MixpanelProvider>
    </CalendarProvider>
  </Drawer>
);
