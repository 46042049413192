export { DeprecatedOrganizationTypeEnum } from '../../../types/medicalOrg';
export { OrganizationTypeEnum } from '../../../uc-api-sdk';

export enum EntityType {
  CareDept = 'careDept',
  CallCenterDept = 'callCenterDept',
  CareCenter = 'careCenter',
  BillingDept = 'billingDept',
  CareGroup = 'careGroup',
}

export enum ClinicalOrgStatusEnum {
  ActivationPending = 'PENDING',
  Active = 'ACTIVE',
  OnHiatus = 'HIATUS',
  ServiceEnded = 'SERVICE_ENDED',
  Terminated = 'TERMINATED',
  Deleted = 'DELETED',
}

export enum BillingDepartmentMemberStatusEnum {
  ActivationPending = 'activationPending',
  Active = 'active',
  Deactivated = 'deactivated',
}

export enum CcmRpmRevenueModelEnum {
  PerCode = 'PerCode',
  PEPM = 'PEPM',
  PBPM = 'PBPM',
  PMAPM = 'PMAPM',
}

export enum PEPMRevenueModelEnum {
  PEPM15 = 'PEPM15',
  PEPM29 = 'PEPM29',
  PEPM40 = 'PEPM40',
}

export enum PBPMRevenueModelEnum {
  PBPM35 = 'PBPM35',
  PBPM40 = 'PBPM40',
  PBPM45 = 'PBPM45',
}

export enum PMAPMRevenueModelEnum {
  PMAPM40 = 'PMAPM40',
}

export enum PerformancePeriodEnum {
  ThisMonth = 'thisMonth',
  LastMonth = 'lastMonth',
  ThisWeek = 'thisWeek',
  LastWeek = 'lastWeek',
  Recent3Months = 'recent3Months',
}
