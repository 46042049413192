import { useMemo } from 'react';
import { MessageVitalSingleComponent, MessageVitalSingleComponentProps } from '../MessageVitalSingleComponent/MessageVitalSingleComponent';
import { BPMessagePayload, VitalMessagePayload } from '../../../../../services/CHSServices/types/data';
import { BpSeverityEnum, MeasurementResultTypeEnum, VitalEnumType } from '../../../../../uc-api-sdk';
import { MessageVitalSingleDisplayValueComponent } from '../MessageVitalSingleDisplayComponent/MessageVitalSingleDisplayValueComponent';
import { MessageVitalSingleDisplaySeverityComponent } from '../MessageVitalSingleDisplayComponent/MessageVitalSingleDisplaySeverityComponent';
import { BPMeasurementService } from '../../../../vitals/helper/BPMeasurementService';

export interface MessageBPComponentProps extends Omit<MessageVitalSingleComponentProps, 'vitalName' | 'children'> {
  systolic: BPMessagePayload['systolic'];
  diastolic: BPMessagePayload['diastolic'];
  unit: VitalMessagePayload['unit'];
  severity: BPMessagePayload['severity'];
}

export const MessageBPComponent = ({
  systolic,
  diastolic,
  measuredAt,
  unit,
  severity,
  text,
}: MessageBPComponentProps) => {
  const [bpSeverityColor, bpSeverityText] = useMemo(() => {
    let severityEnum;
    let inShort = severity as string;
    switch (severity) {
      case 'Low':
        severityEnum = BpSeverityEnum.LOW;
        break;
      case 'Normal':
        severityEnum = BpSeverityEnum.NORMAL;
        break;
      case 'Elevated':
        severityEnum = BpSeverityEnum.ELEVATED;
        break;
      case 'Hypertension Stage 1':
        severityEnum = BpSeverityEnum.HYPERTENSION_STAGE_1;
        inShort = 'Stage 1';
        break;
      case 'Hypertension Stage 2':
        severityEnum = BpSeverityEnum.HYPERTENSION_STAGE_2;
        inShort = 'Stage 2';
        break;
      case 'Hypertensive Crisis':
        severityEnum = BpSeverityEnum.HYPERTENSIVE_CRISIS;
        inShort = 'Crisis';
        break;
      default:
    }
    return [
      BPMeasurementService.getSeverityColor(severityEnum),
      inShort
    ];
  }, [severity]);

  return (
    <MessageVitalSingleComponent
      vitalName={MeasurementResultTypeEnum.BP}
      measuredAt={measuredAt}
      text={text}
    >
      <MessageVitalSingleDisplayValueComponent
        value={`${systolic}/${diastolic}`}
        unit={unit}
      />
      <MessageVitalSingleDisplaySeverityComponent
        vitalType={VitalEnumType.BP}
        severity={bpSeverityText || ''}
        backgroundColor={bpSeverityColor}
      />
    </MessageVitalSingleComponent>
  );
};
