import { message } from 'antd';
import React, { useEffect } from 'react';
import { useBillableTimeContext } from '../../../../contexts/BillableTimeContext/BillableTimeContext';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { useRouter } from '../../../../hooks/useRouter/useRouter';
import {
  LoginResponse,
  TimeTrackingEventEnum,
  useAuthLogin,
  useTimeTrackingInsert,
} from '../../../../uc-api-sdk';
import { LoginCardComponent, LoginCardComponentProps } from '../../component/LoginCardComponent/LoginCardComponent';

export interface LoginContainerProps {
  onLogin?: (loginInfo: LoginResponse<unknown>) => void;
}

const LoginContainer: React.FC<LoginContainerProps> = ({
  onLogin,
}) => {
  const { setLoginInfo } = useLoggedInUserFromContext();
  const { resetSessionId } = useBillableTimeContext();
  const { pathname } = useRouter();
  const timeTrackingInsert = useTimeTrackingInsert({});

  const authLoginInfo = useAuthLogin({
    options: {
      sendOnMount: false,
    },
    params: {
      loginRequest: {},
    },
  });

  const addTimeTracking = (token?: string) => {
    if (!token) {
      console.error('Token is missing!');
      return;
    }

    const sessionId = resetSessionId();

    const timeTrackingReq = timeTrackingInsert.send({
      params: {
        event: {
          sessionId,
          eventType: TimeTrackingEventEnum.LOGIN,
          timestamp: Date.now(),
          url: pathname,
        },
      },
      headers: {
        'x-session-token': token,
      }
    });

    ApiRequestHelper.tryCatch(timeTrackingReq, 'Failed Login tracking!');
  };

  const handleLogin: LoginCardComponentProps['onLogin'] = useDebounce(async (loginVars) => {
    const loginReq = authLoginInfo.send({
      params: {
        loginRequest: loginVars,
      },
    });

    await ApiRequestHelper.tryCatch(loginReq, {
      error: 'Failed to login!',
      success: undefined,
      onSuccess: (res) => addTimeTracking(res?.data?.sessionToken ?? undefined),
    });
  }, 100, [resetSessionId]);

  useEffect(() => {
    if (authLoginInfo.isLoading) return;
    if (authLoginInfo.data?.code === 200) {
      setLoginInfo?.(authLoginInfo.data?.data);
      onLogin?.(authLoginInfo.data?.data);
      return;
    }
    if (authLoginInfo.data?.msg) {
      message.error(authLoginInfo.data.msg);
    }
  }, [authLoginInfo.isLoading]);
  return (
    <LoginCardComponent
      onLogin={handleLogin}
      isLoading={authLoginInfo.isLoading}
    />
  );
};

export default LoginContainer;
