import { TranscribeOutstandingDetail, TranscribeOutstandingTypeEnum } from '../../../../uc-api-sdk';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { OutstandingTranscribingComponent } from '../../component/OutstandingTranscribingComponent/OutstandingTranscribingComponent';

export interface OutstandingTranscribingContainerProps {
  patientId?: string;
  data: TranscribeOutstandingDetail[] | null | undefined;
}

export const OutstandingTranscribingContainer = ({
  patientId,
  data,
}: OutstandingTranscribingContainerProps) => {
  const { send } = useBillableTimeInterventionComp();

  const handleOnClick = (type: TranscribeOutstandingTypeEnum) => {
    if (type === TranscribeOutstandingTypeEnum.INIT) {
      send({
        patientId,
        component: BillableTimeInterventionComponentEnum.Transcribing,
        detail:
        BillableTimeInterventionDetailEnum.OutstandingTranscribingInitialTranscribeStartNow,
      });
    }
    if (type === TranscribeOutstandingTypeEnum.FOLLOW_UP) {
      send({
        patientId,
        component: BillableTimeInterventionComponentEnum.Transcribing,
        detail:
        BillableTimeInterventionDetailEnum.OutstandingTranscribingFollowUpTranscribeStartNow,
      });
    }
    if (type === TranscribeOutstandingTypeEnum.A1C) {
      send({
        patientId,
        component: BillableTimeInterventionComponentEnum.Transcribing,
        detail:
        BillableTimeInterventionDetailEnum.OutstandingTranscribingA1CTranscribeStartNow
      });
    }
  };

  return (
    <OutstandingTranscribingComponent
      data={data}
      onClick={handleOnClick}
    />
  );
};
