/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
import React, {
  FC,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import { Modal, ModalProps } from 'antd';
import './ComplexityInfo.scss';
import { Title } from '../../../../uiComponent/Title/Title';
import { PatientComplexityEnum } from '../../../../uc-api-sdk';
import { ComplexityEnumComponent } from '../../../../enumComponent/ComplexityEnumComponent/ComplexityEnumComponent';

export interface ComplexityInfoProps {
  isEnrolled: boolean;
  level?: PatientComplexityEnum;
  showNote?: boolean;
  children: ReactNode;
}

export const ComplexityInfo: FC<ComplexityInfoProps> = ({
  children, isEnrolled, level, showNote,
}) => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const header = (
    level
      ? (
        <div className="flex f-w">
          <span>What does</span>
          <ComplexityEnumComponent value={level as PatientComplexityEnum} />
          <span>mean?</span>
        </div>
      ) : 'What is Condition Complexity Level?'
  );
  const content = useMemo(() => (
    level === PatientComplexityEnum.NULL_CODES
      ? (
        <>
          ICD-10 code has been added, but no ICD-10 code has been selected as billable
          thus patient's Condition Complexity Level can't be defined. Please mark the
          applicable ICD-10 code as billable in the patient profile
          {' '}
          {'>'}
          {' '}
          ICD code section.
        </>
      )
      : (
        <>
          <Title className="mb2">{header}</Title>
          <p className="mb2">
            Patient's Condition Complexity Level is defined based on patient's
            conditions and can be changed manually as well.
            For enrolled patients, patients with different Condition
            Complexity Levels will be managed by different roles in the UnifedCare care team.
          </p>
          {!isEnrolled && <p>Once enrolled:</p>}
          <p>
            <span className="bold">The Non-complex patient</span>
            {' '}
            will be managed by Health Coach (HC) Or Registered Dietitian (RD)
            + Clinical Assistant (CA).
          </p>
          <p>
            <span className="bold">The Complex patients</span>
            {' '}
            will be managed by Registered Dietitian (RD) + Clinical Assistant (CA).
          </p>
          {showNote && (
            <p className="mt2 complexity-note">
              Note: If you want to modify a patient's complexity level,
              you can update the ICD codes to adjust the complexity.
            </p>
          )}
        </>
      )
  ), [level]);

  const showModal: React.DOMAttributes<HTMLDivElement>['onClick'] = (evt) => {
    evt.stopPropagation();
    setShowInfoModal(true);
  };

  const closeModal: ModalProps['onCancel'] = (evt) => {
    evt.stopPropagation();
    setShowInfoModal(false);
  };

  return (
    <>
      <span
        className="complexity-level-wrapper"
        onClick={showModal}
      >
        {children}
      </span>
      <Modal
        open={showInfoModal}
        onCancel={closeModal}
        footer={null}
        width={650}
      >
        <div className="patient-complexity-modal-content">
          {content}
        </div>
      </Modal>
    </>
  );
};

export default ComplexityInfo;
