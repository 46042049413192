import React from 'react';
import { SleepingRoutineEnumComponent } from '../../../../enumComponent/SleepingRoutineEnumComponent/SleepingRoutineEnumComponent';
import { InputType } from '../../../Input/types';
import { EnumSelectComponent } from '../../../../uiComponent/EnumSelectComponent/EnumSelectComponent';

export enum SleepingRoutineEnum {
  NONE = 'NONE',
  DAILY = 'DAILY',
  WEEK_4_6_TIMES = 'WEEK_4_6_TIMES',
  WEEK_2_3_TIMES = 'WEEK_2_3_TIMES',
  WEEK_LESS_THAN_2 = 'WEEK_LESS_THAN_2',
  IRREGULAR_ROUTINE = 'IRREGULAR_ROUTINE',
}

export interface SleepingRoutineSelectComponentProps extends
  InputType<SleepingRoutineEnum> {
}

export const SleepingRoutineSelectComponent = ({
  value,
  onChange,
  disabled,
}: SleepingRoutineSelectComponentProps) => (
  <EnumSelectComponent
    options={SleepingRoutineEnum}
    optionRenderer={SleepingRoutineEnumComponent}
    value={value}
    onChange={onChange}
    disable={disabled}
  />
);
