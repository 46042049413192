import { Form } from 'antd';
import { FormType } from '../../../Input/types';
import { usePOBaselineForm } from '../../hook/usePOBaselineForm/usePOBaselineForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { POBaselineInputComponent } from './POBaselineInputComponent';
import { InterventionSubmitCancelButtons } from '../../../intervention/component/InterventionSubmitCancelButtons/InterventionSubmitCancelButtons';

export interface SubmitValue {
  baseline: number;
}

export interface POBaselineFormComponentProps extends FormType<SubmitValue> {
}

export const POBaselineFormComponent = ({
  onSubmit,
  onCancel,
  initialValues,
  isLoading,
}: POBaselineFormComponentProps) => {
  const formHook = usePOBaselineForm();

  const handleCancel = () => {
    formHook.handleReset();
    onCancel?.();
  };

  return (
    <Form
      layout="vertical"
      form={formHook.form}
      onFinish={formHook.handleSubmitAndReset(onSubmit)}
      initialValues={initialValues}
      disabled={isLoading}
      onFieldsChange={formHook.handleTouched}
    >
      <FormItem
        info={formHook.getInfo('baseline')}
      >
        <POBaselineInputComponent />
      </FormItem>
      <div>
        <InterventionSubmitCancelButtons
          onCancel={handleCancel}
          showOnTouch
          formHook={formHook}
        />
      </div>
    </Form>
  );
};
