import { Form } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { compact, difference, map } from 'lodash';
import { VisitMethodRadioGroupComponent } from '../VisitMethodRadioGroupComponent/VisitMethodRadioGroupComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useCreateVisitForm } from '../../hook/useCreateVisitForm';
import { SubmitValue } from '../CreateVisitFormComponent/CreateVisitFormComponent';
import { FormType } from '../../../Input/types';
import CancelSubmitButtonsComponent from '../../../../uiComponent/CancelSubmitButtonsComponent/CancelSubmitButtonsComponent';
import { ParticipantsCheckboxGroupComponent } from '../ParticipantsCheckboxGroupComponent/ParticipantsCheckboxGroupComponent';
import { ParticipantsSelectFormItemComponent } from '../CreateVisitFormComponent/ParticipantsSelectComponent';
import { VisitOverlapTimeContainer } from '../../container/VisitOverlapTimeContainer/VisitOverlapTimeContainer';
import { StartNowOverlapWarningComponent } from './StartNowOverlapWarningComponent';

export interface StartNowCreateVisitFormComponentProps extends FormType<SubmitValue> {
  getEndTime: (startTime: Dayjs | string) => Dayjs;
  excludeParticipantIds?: string[]; // to exclude from checking overlapping visit for participants
  shouldDisableSubmit?: (values: SubmitValue) => boolean;
}

export const StartNowCreateVisitFormComponent = ({
  getEndTime,
  excludeParticipantIds,
  initialValues,
  onSubmit,
  onCancel,
  shouldDisableSubmit,
}: StartNowCreateVisitFormComponentProps) => {
  const createVisitForm = useCreateVisitForm();
  return (
    <Form
      form={createVisitForm.form}
      initialValues={initialValues}
      onFinish={createVisitForm.handleSubmit(onSubmit)}
      layout="vertical"
      colon={false}
    >
      <FormItem
        info={createVisitForm.getInfo('visitMethod')}
        required
      >
        <VisitMethodRadioGroupComponent
          allowVideoCall={false}
        />
      </FormItem>
      <div>
        <FormItem
          info={createVisitForm.getInfo('participants')}
          rules={createVisitForm.getRequiredRules('participants')}
        >
          <ParticipantsCheckboxGroupComponent />
        </FormItem>
        <ParticipantsSelectFormItemComponent
          addCurrentUserToOption
        />
      </div>
      <FormItem
        noStyle
        shouldUpdate={createVisitForm.shouldUpdate(['rdhc', 'ca', 'md', 'ma'])}
      >
        {
          ({ getFieldsValue }) => {
            const values = getFieldsValue();
            const selectedParticipants = createVisitForm.parseParticipants(values);
            const participantIds = compact(map(selectedParticipants, 'participantId')) as string[];
            const finalParticipantIds = difference(participantIds, excludeParticipantIds || []);
            const startTime = dayjs();
            return (
              <VisitOverlapTimeContainer
                startTime={startTime}
                endTime={getEndTime(startTime)}
                participants={finalParticipantIds}
              >
                {
                  ({ hasOverlap }) => {
                    if (!hasOverlap) return null;
                    return <StartNowOverlapWarningComponent />;
                  }
                }
              </VisitOverlapTimeContainer>
            );
          }
        }
      </FormItem>
      <FormItem
        noStyle
        shouldUpdate
      >
        {
          ({ getFieldsValue }) => (
            <CancelSubmitButtonsComponent
              submitText="Check-in"
              onCancel={onCancel}
              className="start-now-create-visit-form-buttons"
              shouldBeDisabled={() => !!shouldDisableSubmit?.(getFieldsValue() as SubmitValue)}
            />
          )
        }
      </FormItem>
    </Form>
  );
};
