import { FC } from 'react';
import { AddEditMedicationFormComponent } from '../component/AddEditMedicationFormComponent/AddEditMedicationFormComponent';
import { MedicationType } from '../type/type';
import { useBillableTimeInterventionComp } from '../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../billableTime/contants/contants';
import { useMixpanelContext } from '../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../contexts/MixpanelContext/MixpanelEvents';

export interface AddMedicationContainerProps {
  onSubmit: (v: MedicationType) => void;
  close?: () => void;
  patientId?: string;
}

export const AddMedicationContainer:
  FC<AddMedicationContainerProps> = ({ onSubmit, close, patientId }) => {
    const { send } = useBillableTimeInterventionComp();
    const { send: sendMixpanel } = useMixpanelContext();

    const handleSubmit = (v: MedicationType) => {
      send({
        patientId,
        component: BillableTimeInterventionComponentEnum.Transcribing,
        detail:
          BillableTimeInterventionDetailEnum.TranscribingVisitTranscribeMedicationListAddMedication,
      });
      sendMixpanel({ event: MixpanelEvents.AddMedication });
      onSubmit(v);
      close?.();
    };

    return (
      <AddEditMedicationFormComponent
        onSubmit={handleSubmit}
      />
    );
  };
