import dayjs from 'dayjs';
import { Moment } from 'moment';
import { useState } from 'react';
import { BILLING_DATE } from '../../../../constants/timeFormat';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { BillingTimeTableComponent } from '../../component/BillingComponent/BillingTimeTableComponent';
import { claimedTime } from '../../hook/claimedTime';

export interface BillingTimeContainerProps {
  patientId?: string;
}

export const BillingTimeContainer = ({
  patientId,
}: BillingTimeContainerProps) => {
  const [date, setDate] = useState(dayjs().format(BILLING_DATE));
  const { info, tableChange } = claimedTime(
    patientId,
    { gte: Number(date), lte: Number(date) },
  );

  const onDateChange = (value: Moment | null) => {
    if (value) setDate(value?.format(BILLING_DATE));
    if (patientId && date) {
      info.send({
        params: {
          searchRequest: {
            filter: {
              memberId: patientId,
              monthOfYearRange: {
                gte: Number(date),
                lte: Number(date),
              },
            },
          }
        },
      });
    }
  };
  useUpdateListener('BILLABLE_TIME_UPDATED', () => info.refetch());
  return (
    <FetchComponent
      info={info}
      alwaysShowChildren
      showErrorOverlay={false}
      showLoadingOverlay={false}
      emptyOverlay={<h2>No Data!!!</h2>}
    >
      {(value, info) => {
        const {
          content = [],
        } = (value?.rawValue?.data || {});
        return (
          <BillingTimeTableComponent
            onDateChange={onDateChange}
            dataSource={content}
            onChange={tableChange.handleTableChange}
            pagination={info.pagination}
          />
        );
      }}
    </FetchComponent>
  );
};
