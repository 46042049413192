import React from 'react';
import { InputNumber, InputNumberProps } from 'antd';
import { FreqSelectComponent, FreqSelectComponentProps } from '../FreqSelectComponent/FreqSelectComponent';
import { FrequencyUnitEnum } from '../../uc-api-sdk';
import { InputType } from '../../features/Input/types';

interface Freq {
  frequency?: number;
  frequencyType?: FrequencyUnitEnum | string;
}

export interface FreqInputComponentProps extends InputType<Freq> {
}

export const FreqInputComponent = ({
  value,
  onChange,
}: FreqInputComponentProps) => {
  const handleNumberChange: InputNumberProps['onChange'] = (v) => {
    onChange?.({
      ...value,
      frequency: v as number,
    });
  };

  const handleUnitChange: FreqSelectComponentProps['onChange'] = (v) => {
    onChange?.({
      ...value,
      frequencyType: v,
    });
  };

  return (
    <InputNumber
      precision={0}
      min={0}
      placeholder="Times"
      value={value?.frequency}
      onChange={handleNumberChange}
      addonAfter={(
        <FreqSelectComponent
          value={value?.frequencyType}
          onChange={handleUnitChange}
        />
      )}
    />
  );
};
