import React, { FC, useContext } from 'react';
import { useCreateVisitForm } from '../../../visit/hook/useCreateVisitForm';

export type Form = ReturnType<typeof useCreateVisitForm>;
export const CreateVisitFormContext = React.createContext<Form | undefined>(undefined);

export interface CreateVisitProviderProps {
  value?: Form;
  children: React.ReactNode;
}

export const CreateVisitFormProvider: FC<CreateVisitProviderProps> = ({
  value,
  children,
}) => (
  <CreateVisitFormContext.Provider value={value}>
    {children}
  </CreateVisitFormContext.Provider>
);

export const useCreateVisitFormContext = () => {
  const VisitContextValue = useContext(CreateVisitFormContext);
  return VisitContextValue;
};
