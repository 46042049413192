import React from 'react';
import { Nullable } from '../../../../uc-api-sdk';

export interface PatientLanguageComponentProps {
  spokenLanguage?: Nullable<string[]>;
  appLanguage?: string;
}

export const PatientLanguageComponent = ({
  spokenLanguage,
  appLanguage
}: PatientLanguageComponentProps) => {
  if ((!spokenLanguage || spokenLanguage.length === 0) && !appLanguage) {
    return (
      <span>
        N/A
      </span>
    );
  }
  if (spokenLanguage && spokenLanguage.length > 0 && appLanguage) {
    return (
      <span>
        {`Spoken-${spokenLanguage}, App-${appLanguage}`}
      </span>
    );
  }
  return (
    <span>
      {`${spokenLanguage && spokenLanguage.length > 0 ? `Spoken-${spokenLanguage}` : ''}${appLanguage ? `App-${appLanguage}` : ''}`}
    </span>
  );
};
