import classNames from 'classnames';
import { getRange, getTooltip } from '../../constants';
import './OtherLabFlagComponent.scss';
import { ReferenceLevel, ReferenceLevelEnum } from '../../../../uc-api-sdk';
import { ReferenceLevelEnumComponent } from '../ReferenceLevelEnumComponent';
import { LabResultFlagComponentProps } from './LabResultFlagComponent';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';

export interface OtherLabFlagComponentProps extends LabResultFlagComponentProps {
  flag?: ReferenceLevelEnum; // use flag directly if it's manually created
}

export const OtherLabFlagComponent = ({
  value,
  flag,
  referenceLevels,
  name,
}: OtherLabFlagComponentProps) => {
  const r: ReferenceLevel | undefined | 0 = getRange(value, referenceLevels) ?? undefined;
  const referenceTag = flag || r?.description;

  return (
    <TooltipComponent
      title={getTooltip(name ?? '', r)}
      placement="top"
      type={null}
      className={classNames({
        'a1c-status-component': true,
        [`${referenceTag}`]: true,
      })}
    >
      <div
        className={classNames({
          'lab-status-component': true,
          [`${referenceTag}`]: true,
        })}
      >
        <ReferenceLevelEnumComponent value={referenceTag ?? undefined} />
      </div>
    </TooltipComponent>
  );
};
