import modal from 'antd/lib/modal';
import { Empty, Table, TableProps } from 'antd';
import moment from 'moment';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { createColumns } from './BillableTimeReviewTableColumn';
import './BillableTimeReviewTableComponent.scss';
import { BillableTimeReviewDatePickerComponent } from '../BillableTimeReviewDatePickerComponent/BillableTimeReviewDatePickerComponent';
import { BillableProcessStatusEnum } from '../../../../uc-api-sdk';

export interface BillableTimeReviewTableDataSource {
  id: string;
  memberId: string;
  memberName: string;
  medicalOrganization: string;
  startTime: number;
  activity: string[];
  originBillableTime: number;
  correctedBillableTime?: number;
  totalTime: number;
  status: BillableProcessStatusEnum;
  comment?: string;
  orgNumber?: string;
  reviewerName?: string;
}

export interface BillableTimeReviewTableComponentProps
extends Omit<TableProps<BillableTimeReviewTableDataSource>, 'dataSource' | 'columns'> {
  dataSource: BillableTimeReviewTableDataSource[];
  confirmApproval?: (id: string) => void;
  isLoading?: boolean;
  reviewRequiredCount?: number;
  totalCount?: number;
  date?: moment.Moment;
  onChangeMonthAndYear?: (v: moment.Moment | undefined) => void;
}

export const BillableTimeReviewTableComponent = ({
  dataSource,
  confirmApproval,
  isLoading,
  pagination,
  onChange,
  reviewRequiredCount = 0,
  totalCount = 0,
  date,
  onChangeMonthAndYear,
}: BillableTimeReviewTableComponentProps) => {
  const reviewedCount = totalCount - reviewRequiredCount;
  const handleConfirmApproval = (id: string) => {
    modal.confirm({
      width: 476,
      title: 'Are You Sure to confirm the Approval?',
      icon: null,
      content: 'Does the billable time accurately reflects the time you spent with the patient? Once confirmed, the time will be added to patients’ billable time and can’t be modified further.',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: () => confirmApproval?.(id),
    });
  };

  const columns = useTableColumns(
    createColumns(),
    [
      'memberName',
      'medicalOrganization',
      'startTime',
      'activity',
      'totalTime',
      'originBillableTime',
      { name: 'action', args: [handleConfirmApproval] }
    ],
  );

  const emptyNode = (
    <div className="mt100 mb100">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="There is no member yet." />
    </div>
  );

  return (
    <div className="billable-time-review-table-component">
      <div className="flex jc-sb mb10 ai-c">
        <BillableTimeReviewDatePickerComponent value={date} onChange={onChangeMonthAndYear} />
        <div>
          Item reviewed out of Total:
          <span className="b7">
            {reviewedCount}
            /
            {totalCount}
          </span>
        </div>
      </div>
      <Table
        dataSource={dataSource}
        loading={isLoading}
        columns={columns}
        size="middle"
        pagination={pagination}
        onChange={onChange}
        rowKey={(v) => v.id}
        locale={{ emptyText: emptyNode }}
      />
    </div>
  );
};
