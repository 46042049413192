import { ReactNode } from 'react';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import ModalButton from '../../../../uiComponent/ModalButton/ModalButton';
import { MarkInsuranceNonCoveredModalFormComponent, MarkInsuranceNonCoveredModalFormComponentProps } from '../MarkInsuranceNonCoveredModalFormComponent/MarkInsuranceNonCoveredModalFormComponent';
import { NonCovered } from '../../../../uc-api-sdk';

export interface MarkInsuranceNonCoveredModalComponentProps {
  nonCovered?: NonCovered;
  disabled?: boolean;
  trigger?: (open: () => void) => ReactNode;
  onSave?: MarkInsuranceNonCoveredModalFormComponentProps['onSubmit'];
}

export const MarkInsuranceNonCoveredModalComponent = ({
  nonCovered,
  disabled,
  trigger = (open) => (
    <LinkButton
      onClick={open}
      showUnderline
      disabled={disabled}
    >
      Edit
    </LinkButton>
  ),
  onSave,
}: MarkInsuranceNonCoveredModalComponentProps) => (
  <ModalButton
    trigger={trigger}
    disabled={disabled}
    destroyOnClose
  >
    <MarkInsuranceNonCoveredModalFormComponent
      initialValue={nonCovered}
      onSubmit={onSave}
    />
  </ModalButton>
);
