import { DiseaseHistoryItem } from '../../../../uc-api-sdk';
import { DiseaseHistoryConditionComponent } from '../DiseaseHistoryDisplayComponent/DiseaseHistoryConditionComponent';
import { DiseaseHistoryItemLayoutComponent } from '../DiseaseHistoryItemLayoutComponent/DiseaseHistoryItemLayoutComponent';
import { DiseaseHistory } from '../../helper';
import { DiseaseHistoryTemplateGeneralDisplayComponent } from '../DiseaseHistoryDisplayComponent/DiseaseHistoryTemplateGeneralDisplayComponent';
import { DiseaseHistoryTemplateByVitalDisplayComponent } from '../DiseaseHistoryDisplayComponent/DiseaseHistoryTemplateByVitalDisplayComponent';

const { Condition, Info } = DiseaseHistoryItemLayoutComponent;

export interface DiseaseHistoryListItemComponentProps {
  diseaseHistory: DiseaseHistoryItem;
  className?: string;
}

export const DiseaseHistoryListItemComponent = ({
  diseaseHistory,
  className = '',
}: DiseaseHistoryListItemComponentProps) => {
  const renderInfo = () => {
    const isGeneral = DiseaseHistory.isGeneralChronicDisease(diseaseHistory.disease);
    if (isGeneral) {
      return (<DiseaseHistoryTemplateGeneralDisplayComponent diseaseHistory={diseaseHistory} />);
    }
    return (<DiseaseHistoryTemplateByVitalDisplayComponent diseaseHistory={diseaseHistory} />);
  };
  return (
    <DiseaseHistoryItemLayoutComponent
      className={className}
    >
      <Condition>
        <DiseaseHistoryConditionComponent
          disease={diseaseHistory.disease}
          customDisease={diseaseHistory.customDisease}
        />
      </Condition>
      <Info>
        {renderInfo()}
      </Info>
    </DiseaseHistoryItemLayoutComponent>
  );
};
