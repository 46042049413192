/* eslint-disable class-methods-use-this */
import axios from 'axios';
import EnvConfig from '../../configs/envConfig/envConfig';
import { RewriteWithAIRequestFunction, RewriteWithAIRequestParams } from './types';

const rewriteWithAIDomain = EnvConfig.unicareCopilotUrl;

export class RewriteWithAIServices {
  protected endPoints = {
    rewriteMessage: `${rewriteWithAIDomain}/tools/rewrite-message`,
    rewriteVisitNote: `${rewriteWithAIDomain}/tools/rewrite-note`,
  };

  getResponse = <T>(value: T) => value;

  getRewritedMessage: RewriteWithAIRequestFunction<RewriteWithAIRequestParams, string> = (
    { params, headers },
  ) => axios.post(this.endPoints.rewriteMessage, params, { headers });

  getRewritedVisitNote: RewriteWithAIRequestFunction<RewriteWithAIRequestParams, string> = (
    { params, headers },
  ) => axios.post(this.endPoints.rewriteVisitNote, params, { headers });
}

export const rewriteWithAIServices = new RewriteWithAIServices();
