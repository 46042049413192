import { Tag, Checkbox, Col } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
import {
  ArticleSearchOptionLanguageTagComponent,
  ArticleSearchOptionLanguageTagComponentProps,
} from './ArticleSearchOptionLanguageTagComponent';
import { Icons } from '../../../../icons/Icons';
import { Article } from '../../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';

import './ArticleSearchOptionComponent.scss';

export interface ArticleSearchOptionComponentProps {
  article: Article,
  isSelected: boolean,
  sentOn?: string,
  excludeLanguageTags?: ArticleSearchOptionLanguageTagComponentProps['excludeLanguageTags']
}
export const ArticleSearchOptionComponent = (
  {
    article: {
      name,
      url,
      isNew = false,
      hasVideo = false,
      language,
      coreConcept,
    },
    sentOn,
    isSelected,
    excludeLanguageTags,
  }: ArticleSearchOptionComponentProps,
) => (
  <div className="article-select-option">
    <Col span={18} className="article-select-option-info">
      {
        url
          ? (
            <span
              onClick={(e) => {
                if (!url) return;
                e.stopPropagation();
                window.open(url, '_blank');
              }}
              className="article-select-option-info__link"
              aria-hidden="true"
              role="button"
              data-testid="articleSearchOptionLink"
            >
              {name}
            </span>
          )
          : name
      }
      {
        isNew
          ? <Tag color="cyan">New</Tag>
          : null
      }
      {
        coreConcept
          ? (
            <Tag className="core-concept-tag">
              {`Core Concept ${coreConcept}`}
            </Tag>
          ) : null
      }
      <ArticleSearchOptionLanguageTagComponent
        language={language as string}
        excludeLanguageTags={excludeLanguageTags}
      />
      {
        hasVideo
          ? (
            <Tag
              className="new-video-tag"
            >
              <Icons.VideoPlayButton hexColor="#FFF" />
              With Video
            </Tag>
          ) : null
      }
    </Col>
    <Col span={6} className="article-select-option-icon">
      {
        isSelected
          ? <Checkbox checked />
          : (
            <div className="article-select-option-icon__last-sent">
              {
                sentOn !== undefined
                  ? (
                    <>
                      <span>Last sent on</span>
                      {' '}
                      <DisplayDateComponent value={sentOn} format="USA_DATE" />
                    </>
                  )
                  : null
              }
              <PlusSquareOutlined />
            </div>
          )
      }
    </Col>
  </div>
);
