import { Modal } from 'antd';

export const showConsentFormSentModal = () => {
  Modal.info({
    centered: true,
    icon: null,
    title: 'Consent form has been sent!',
    content: 'Patient will receive a text message with UnifiedCare App download link.'
    + 'Please assist patient to download and install the App.',
    okText: 'Got it',
  });
};
