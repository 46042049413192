import React, { FC } from 'react';
import { BGHelper } from '../../helper/BGHelper';
import { MeasurementResultTypeEnum } from '../../../../uc-api-sdk';
import { BGChartDataProcessorComponent } from '../BGChartComponent/BGChartDataProcessorComponent';
import { BGSummaryTableDataProcessorComponent } from '../BGSummaryTableComponent/BGSummaryTableDataProcessorComponent';
import { BGTableDataProcessorComponent } from '../BGTableComponent/BGTableDataProcessorComponent';
import { VitalChartTableSummaryViewComponentProps, VitalChartTableSummaryViewComponent } from '../VitalChartTableSummaryViewComponent/VitalChartTableSummaryViewComponent';
import { VitalFetchProps } from '../../type';
import { MeasurementType } from '../../type/MeasurementTypes';

export interface BGVitalComponentProps extends VitalFetchProps {

}

export const BGVitalComponent: FC<BGVitalComponentProps> = ({
  memberId,
  fromDate,
  toDate,
}) => {
  const chartView: VitalChartTableSummaryViewComponentProps['chartView'] = ({ data, fromDate, toDate }) => (
    <BGChartDataProcessorComponent
      data={(data?.results?.content || []) as MeasurementType[]}
      fromDate={fromDate}
      toDate={toDate}
    />
  );
  const tableView: VitalChartTableSummaryViewComponentProps['tableView'] = ({ data, fromDate, toDate }) => (
    <BGTableDataProcessorComponent
      data={(data?.results?.content || []) as MeasurementType[]}
      fromDate={fromDate}
      toDate={toDate}
    />
  );
  const summaryView: VitalChartTableSummaryViewComponentProps['summaryTableView'] = ({ data }) => (
    <BGSummaryTableDataProcessorComponent
      data={data?.stats?.bg || {}}
    />
  );

  return (
    <VitalChartTableSummaryViewComponent
      type={MeasurementResultTypeEnum.BG}
      memberId={memberId}
      title="Blood Glucose"
      unit={BGHelper.getMgdlUnit()}
      chartView={chartView}
      tableView={tableView}
      summaryTableView={summaryView}
      fromDate={fromDate}
      toDate={toDate}
    />
  );
};
