import { Select } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { RenderDOMFunc } from 'rc-select/lib/interface';
import { TableSearchComponent } from '../../features/table/component/TableSearchComponent/TableSearchComponent';
import { RoleTypeEnum } from '../../uc-api-sdk';
import { CoWorkerDropdownComponent, CoWorkerDropdownComponentProps } from '../CoWorkerDropdownComponent/CoWorkerDropdownComponent';
import './MultiProviderDropdownComponent.scss';

export interface MultiProviderDropdownComponentProps extends FilterDropdownProps {
  coWorkerDropdownProps?: Omit<CoWorkerDropdownComponentProps, 'children'>,
}

export const MultiProviderDropdownComponent = ({
  coWorkerDropdownProps,
  ...props
}: MultiProviderDropdownComponentProps) => (
  <TableSearchComponent
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    containerId={coWorkerDropdownProps?.containerId}
    relative
    className="w400"
  >
    <CoWorkerDropdownComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...coWorkerDropdownProps}
      filterRoles={[RoleTypeEnum.PROVIDER]}
    >
      {
        ({ isLoading, data }) => (
          <Select
            value={props.selectedKeys}
            loading={isLoading}
            popupClassName="multiProviderDropdownComponent"
            showSearch
            mode="multiple"
            optionFilterProp="children"
            options={
              data?.map(
                (e) => ({ label: e.fullNameWithAllRoles, value: e.id })
              )
            }
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())}
            onChange={(id) => {
              props.setSelectedKeys(id || []);
            }}
            getPopupContainer={
              coWorkerDropdownProps?.containerId
                ? (() => document.getElementById(coWorkerDropdownProps?.containerId || '')) as RenderDOMFunc
                : undefined
            }
          />
        )
      }
    </CoWorkerDropdownComponent>
  </TableSearchComponent>
);
