import { CptCodeUnitEnum } from '../../../../uc-api-sdk';
import { CPTCodes } from './types';

export const defaultCPTCodesByCategory = {
  [CptCodeUnitEnum.THREE_MONTH_UNITS]: {
    [CPTCodes.G0506]: 1,
    [CPTCodes.CPT99490]: 3,
    [CPTCodes.CPT99439]: 6,
    [CPTCodes.CPT99487]: 3,
    [CPTCodes.CPT99489]: 18,
    [CPTCodes.CPT99453]: 1,
    [CPTCodes.CPT99454]: 3,
    [CPTCodes.CPT99457]: 3,
    [CPTCodes.CPT99458]: 18,
  },
  [CptCodeUnitEnum.SIX_MONTH_UNITS]: {
    [CPTCodes.G0506]: 1,
    [CPTCodes.CPT99490]: 6,
    [CPTCodes.CPT99439]: 12,
    [CPTCodes.CPT99487]: 6,
    [CPTCodes.CPT99489]: 36,
    [CPTCodes.CPT99453]: 1,
    [CPTCodes.CPT99454]: 6,
    [CPTCodes.CPT99457]: 6,
    [CPTCodes.CPT99458]: 36,
  }
} as Record<CptCodeUnitEnum, Record<CPTCodes, number>>;
