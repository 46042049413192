import { Radio } from 'antd';
import { ReactNode } from 'react';
import './SelectPatientsBaseCheckboxComponent.scss';
import { PriorityCoverEnum } from '../../../../uc-api-sdk';

export interface SelectPatientsBaseCheckboxComponentProps {
  title?: string;
  body?: string | ReactNode;
  footer?: string;
  cardValue?: PriorityCoverEnum;
  onChange?: () => void;
  children?: ReactNode;
}

export const SelectPatientsBaseCheckboxComponent = ({
  title,
  body,
  footer,
  cardValue,
  onChange,
  children,
}: SelectPatientsBaseCheckboxComponentProps) => (
  <div className="flex gap3">
    <Radio
      value={cardValue}
      checked={false}
      onChange={onChange}
    />
    <div className="pr24">
      <div className="render-patient-title">
        {title}
      </div>
      {body}
      {children}
      {
        footer && (
          <div className="border-t card-footer">
            <div className="card-footer-text">
              {footer}
            </div>
          </div>
        )
      }
    </div>
  </div>
);
