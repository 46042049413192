import { message } from 'antd';
import { difference, pick } from 'lodash';
import { Article, usePatientArticlesBulkUpsertPatientArticles } from '../../../uc-api-sdk';
import { articleMetaDataFields } from '../constants';
import { ArticleMessagePayload, MessageType } from '../../../services/CHSServices/types/data';
import { useMessagePatientContext } from '../../../contexts/MessageContext/MessagePatientContext';

export interface UseSendAndSaveArticlesCallback {
  onSuccess?: (resp: string, hasError?: boolean) => void,
  onError?: (error: Error) => void,
}
export interface UseSendAndSaveArticlesHook {
  patientId: string,
}
export const useSendAndSaveArticles = (
  patientId: UseSendAndSaveArticlesHook['patientId'],
) => {
  const {
    handleSendMessage,
  } = useMessagePatientContext();
  const upsertArticlesInfo = usePatientArticlesBulkUpsertPatientArticles({});

  const handleSendArticles = async (
    articles: Article[],
  ) => {
    let errorMessage = '';
    const loader = message.loading('Sending articles. Please wait');
    const sentResults = await Promise.all(
      articles.map(async (article) => {
        const {
          id,
          url,
        } = article || {};
        if (!url) {
          return null;
        }
        const metaData = pick(article, articleMetaDataFields);
        const payload: ArticleMessagePayload = {
          type: MessageType.TEXT,
          text: article.url as string,
          articleMetaData: metaData,
        };
        const res = await handleSendMessage(payload);
        if (res && res.success) {
          return id;
        }
        return null;
      }),
    );
    loader();
    const sentIds = sentResults.filter((r) => !!r) as string[];

    if (sentIds.length !== articles.length) {
      const failedToSendIds = difference(
        articles.map((a) => a.id),
        sentIds,
      );
      console.error(failedToSendIds.join(', '));
      errorMessage = 'Failed to send some article(s)';
    }

    return {
      sentIds,
      errorMessage,
    };
  };

  const handleSaveArticles = async (
    articleIds: Article['id'][],
    callbacks?: UseSendAndSaveArticlesCallback,
  ) => {
    const {
      onSuccess,
      onError,
    } = callbacks || {};
    try {
      if (articleIds.length) {
        const patientArticlesArr = articleIds.map((aId) => ({
          patientId,
          articleId: aId,
        }));
        const res = await upsertArticlesInfo.send({
          params: {
            patientArticlesArr,
          },
        });
        if (res?.code !== 200) {
          throw new Error(`Server error, ${res?.msg}`);
        }
        onSuccess?.(res?.data as string);
      }
    } catch (error) {
      onError?.(error as Error);
    }
  };

  const handleSendAndSaveArticles = async (
    articles: Article[],
    callbacks?: UseSendAndSaveArticlesCallback,
  ) => {
    const {
      onSuccess,
      onError,
    } = callbacks || {};
    const {
      sentIds,
      errorMessage,
    } = await handleSendArticles(articles);

    return handleSaveArticles(sentIds, {
      onSuccess: (res) => {
        onSuccess?.(res, !!errorMessage);
      },
      onError,
    });
  };

  return {
    sendArticles: handleSendArticles,
    saveArticles: handleSaveArticles,
    sendAndSaveArticles: handleSendAndSaveArticles,
  };
};
