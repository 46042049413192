import { Button, Form } from 'antd';
import { FormType } from '../../../Input/types';
import { DeviceSearchSelectFormItemComponent } from '../DeviceSearchSelectFormItemComponent/DeviceSearchSelectFormItemComponent';
import { DeviceSearchSelectContainerProps } from '../../container/DeviceSearchSelectContainer/DeviceSearchSelectContainer';
import { DeviceInfoComponent, DeviceInfoComponentProps } from '../DeviceInfoComponent/DeviceInfoComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useDeviceMonitorFormProvider } from '../../hook/useDeviceMonitorFormProvider';
import { DeviceHelper } from '../../helper';
import { SubmitValue } from '../../../enrollment/component/ClearDeviceAssignFormComponent/ClearDeviceAssignFormComponent';

export interface DeviceAssignmentFormComponentProps
  extends FormType<SubmitValue>,
  Omit<DeviceInfoComponentProps, 'children'> {
  deviceModel: DeviceSearchSelectContainerProps['deviceModel'];
  disabled?: boolean;
}

export const DeviceAssignmentFormComponent = ({
  deviceModel,
  initialValues,
  isLoading,
  onSubmit,
  formButtons,
  disabled,
  boxShadow,
}: DeviceAssignmentFormComponentProps) => {
  const deviceMonitorFormProvider = useDeviceMonitorFormProvider();

  return (
    <Form
      form={deviceMonitorFormProvider.form}
      name={deviceMonitorFormProvider.getName('deviceAssignmentForm')}
      disabled={disabled || isLoading}
      initialValues={{
        deviceModel,
        deviceMethod: DeviceHelper.getMethodByModel(deviceModel),
        ...initialValues,
      }}
      onFinish={deviceMonitorFormProvider.handleSubmitDeviceAssignment(onSubmit)}
      layout="vertical"
    >
      <FormItem
        name={deviceMonitorFormProvider.getName('deviceModel')}
        required
        hidden
      >
        <div />
      </FormItem>
      <FormItem
        name={deviceMonitorFormProvider.getName('deviceMethod')}
        hidden
      >
        <div />
      </FormItem>
      <DeviceInfoComponent
        deviceModel={deviceModel}
        boxShadow={boxShadow}
      >
        <DeviceSearchSelectFormItemComponent
          deviceModel={deviceModel}
          required
        />
        {
          formButtons
          || (
            <Button
              type="primary"
              htmlType="submit"
              className="my10"
              ghost
            >
              Assign Device
            </Button>
          )
        }
      </DeviceInfoComponent>
    </Form>
  );
};
