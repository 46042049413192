import classNames from 'classnames';
import { map } from 'lodash';
import { InputNumber } from 'antd';
import { InputType } from '../../../../Input/types';

import './CPTCodeUnitsComponent.scss';
import { AntdEllipsisComponent } from '../../../../../uiComponent/AntdEllipsisComponent/AntdEllipsisComponent';
import { CPTCodes } from '../types';

export type CPTCodesValue = {
  [x in CPTCodes]?: number | undefined;
}

export interface CPTCodeUnitsComponentProps
  extends InputType<CPTCodesValue> {
  readOnly?: boolean;
}

export const CPTCodeUnitsComponent = ({
  value,
  onChange,
  readOnly,
  disabled,
}: CPTCodeUnitsComponentProps) => {
  const handleOnChange = (
    code: CPTCodes,
    newValue: number | null,
  ) => {
    onChange?.({
      ...value,
      [code]: newValue
    });
  };

  const renderValue = (code: CPTCodes) => (
    readOnly
      ? (
        <>
          {' - '}
          <AntdEllipsisComponent
            className="cpt-code-units-value"
          >
            {value?.[code]}
          </AntdEllipsisComponent>
        </>
      ) : (
        <InputNumber
          value={value?.[code]}
          onChange={(value) => handleOnChange(code, value)}
          disabled={disabled}
        />
      )
  );

  const renderCodes = (codes: CPTCodes[]) => (
    <div className="cpt-code-units-row mt20 flex f-w">
      {
        map(codes, (code) => (
          <div
            key={code}
            className="flex ai-c mr10 cpt-code-units-item"
          >
            <div>{code}</div>
            <div>{renderValue(code)}</div>
          </div>
        ))
      }
    </div>
  );

  return (
    <div
      className={classNames({
        'cpt-code-units': true,
        'border default-border-radius': !readOnly,
        readOnly,
      })}
    >
      <div>
        {
          renderCodes([
            CPTCodes.G0506
          ])
        }
      </div>
      <div>
        {
          renderCodes([
            CPTCodes.CPT99490,
            CPTCodes.CPT99439,
            CPTCodes.CPT99487,
            CPTCodes.CPT99489
          ])
        }
      </div>
      <div>
        {renderCodes([
          CPTCodes.CPT99453,
          CPTCodes.CPT99454,
          CPTCodes.CPT99457,
          CPTCodes.CPT99458
        ])}
      </div>
    </div>
  );
};
