import {
  Col,
  Row,
  Select, SelectProps, Space,
} from 'antd';
import { Dayjs } from 'dayjs';
import { map } from 'lodash';
import { LimitedUser, PatientDataHistoryEntityEnum } from '../../../../uc-api-sdk';
import { InputType } from '../../../Input/types';
import { PatientDataHistoryEntityEnumListComponent } from '../../../../enumComponent/PatientDataHistoryEntityEnumComponent/PatientDataHistoryEntityEnumListComponent';
import { AccountHelper } from '../../../../helpers/account';
import './VersionHistorySelectComponent.scss';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { EllipsisComponent } from '../../../../uiComponent/EllipsisComponent/EllipsisComponent';

export interface VersionHistory {
  version: string;
  date: Dayjs;
  isLatest: boolean;
  updatedByUser: LimitedUser;
  entries: PatientDataHistoryEntityEnum[];
}

export interface VersionValue {
  version: string;
  isLatest: boolean;
}

export interface VersionHistorySelectComponentProps extends InputType<VersionValue> {
  onClick?: () => void;
  data: VersionHistory[];
  onFilterChange?: () => void;
}

export const VersionHistorySelectComponent = ({
  data,
  value,
  onChange,
  onClick,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFilterChange,
}: VersionHistorySelectComponentProps) => {
  const renderLabel = (d: VersionHistory) => (
    <Space>
      {d.isLatest && (
        <div>
          Latest Version
        </div>
      )}
      <DisplayDateComponent value={d.date} format="USA_DATE" />
    </Space>
  );

  const getValue = (d: VersionHistory) => {
    const v: VersionValue = { version: d.version, isLatest: d.isLatest };
    return JSON.stringify(v);
  };

  const renderOption = (d: VersionHistory) => (
    <Select.Option key={d.version} value={getValue(d)} optionLabel={renderLabel(d)}>
      <Row>
        <Col span={8}>
          <DisplayDateComponent value={d.date} format="USA_DATE" />
          {d.isLatest && ' (Latest)'}
        </Col>
        <Col span={8}>
          {AccountHelper.getFullName(d.updatedByUser)}
          {', '}
          {d.updatedByUser.title}
        </Col>
        <Col span={8}>
          <EllipsisComponent>
            <PatientDataHistoryEntityEnumListComponent value={d.entries} />
          </EllipsisComponent>
        </Col>
      </Row>
    </Select.Option>
  );

  const handleChange: SelectProps['onChange'] = (v) => {
    onChange?.(v ? JSON.parse(v) : undefined);
  };

  const dropdownRender: SelectProps['dropdownRender'] = (menu) => (
    <div>
      <Row className="p10">
        <Col span={8}>
          <TextComponent color="gray3" bold>
            Date
          </TextComponent>
        </Col>
        <Col span={8}>
          <TextComponent color="gray3" bold>
            Updated by
          </TextComponent>
        </Col>
        <Col span={8}>
          <TextComponent color="gray3" bold>
            Updated Content
          </TextComponent>
        </Col>
      </Row>
      {menu}
    </div>
  );

  return (
    <Select
      onClick={onClick}
      className="version-history-select-component"
      dropdownRender={dropdownRender}
      popupClassName="version-history-dropdown"
      placement="bottomRight"
      onChange={handleChange}
      value={value ? JSON.stringify(value) : undefined}
      optionLabelProp="optionLabel"
    >
      {map(data, renderOption)}
    </Select>
  );
};
