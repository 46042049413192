import { BillableTimeSegmentEnum } from '../../uc-api-sdk';

export interface BillableTimeSegmentEnumComponentProps {
  value: BillableTimeSegmentEnum;
}

export const BillableTimeSegmentEnumComponent = ({
  value
}: BillableTimeSegmentEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case BillableTimeSegmentEnum.TEN_MINUTES:
        return '0-10 min';
      case BillableTimeSegmentEnum.TEN_TO_FIFTEEN_MINUTES:
        return '10-15 min';
      case BillableTimeSegmentEnum.FIFTEEN_TO_TWENTY_MINUTES:
        return '15-20 min';
      case BillableTimeSegmentEnum.TWENTY_TO_THIRTY_FIVE_MINUTES:
        return '20-35 min';
      case BillableTimeSegmentEnum.THIRTY_FIVE_TO_FORTY_MINUTES:
        return '35-40 min';
      case BillableTimeSegmentEnum.FORTY_TO_FIFTY_FIVE_MINUTES:
        return '40-55 min';
      case BillableTimeSegmentEnum.FIFTY_FIVE_TO_SIXTY_MINUTES:
        return '55-60 min';
      case BillableTimeSegmentEnum.SIXTY_TO_ABOVE_MINUTES:
        return '60+ min';
      default:
        return null;
    }
  };
  return <span className="billable-time-segment-enum">{getText()}</span>;
};
