/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import { APIResponse, PageResponse } from '../../uc-api-sdk';
import { useTableChange } from '../useTableChange/useTableChange';
import { useRequestFactory } from '../../uc-api-sdk/staticFiles/useReqHook';

export interface Info<D = unknown> {
  data: D | null | undefined;
  refetch: () => void;
}

export const useDeletePaginated = (
  info: ReturnType<typeof useRequestFactory<any, any, any, any, any, any>>,
  tableChange: ReturnType<typeof useTableChange<any, any>>
) => {
  const onDelete = useCallback(() => {
    const { data } = (info.data || {}) as APIResponse<PageResponse<unknown>>;

    if (data?.page !== undefined) {
      const page = data?.page || 1;
      const totalPage = data?.totalPage || 0;
      const totalSize = data?.totalSize || 0;
      const size = data?.size || 10;

      const isTheLastData = (totalSize - 1) % size === 0;

      if (page > 1 && page === totalPage && isTheLastData && totalSize - 1 > 1) {
        tableChange.setPagination({
          current: (totalSize - 1) / size,
          pageSize: size,
          total: totalSize - 1,
        });
      }
    }

    return info.refetch();
  }, [
    info.data,
    tableChange.pagination,
    tableChange.setPagination,
  ]);

  return {
    onDelete,
  };
};
