import TimezoneService from '../../../helpers/timezone/timezoneService';
import { NonNullUndefined } from '../../../types/common';
import { Measurement } from '../../../uc-api-sdk';

export class MeasurementService {
  protected value: NonNullUndefined<Measurement>;

  constructor(measurement: Measurement) {
    this.value = measurement as NonNullUndefined<Measurement>;
  }

  get weight() {
    return this.value.body_weight;
  }

  get date() {
    return TimezoneService.calcDateTimeDayjs(this.value.date, this.value.timezone);
  }

  get hadActivity() {
    return this.value.hadActivity;
  }

  get hadInsulin() {
    return this.value.hadInsulin;
  }

  get hadMedicine() {
    return this.value.hadMedicine;
  }

  get moodTags() {
    return this.value.moodTags;
  }

  get delayedUpload() {
    return this.value.delayedUpload;
  }

  get userNote() {
    return this.value.user_notes;
  }

  get timezone() {
    return this.value.timezone;
  }

  get timeOfDay() {
    return TimezoneService.getTimeOfDay(this.value.date, this.value.timezone);
  }
}
