import React, { FC } from 'react';
import { Measurement } from '../../../../uc-api-sdk';
import { useMeasurementResultToTablePrint } from '../../hook/useMeasurementResultToTablePrint/useMeasurementResultToTablePrint';
import { MeasurementType } from '../../type/MeasurementTypes';
import { BPMeasurementService } from '../../helper/BPMeasurementService';
import { TimeOfDayTablePrintComponent } from '../TimeOfDayTablePrintComponent/TimeOfDayTablePrintComponent';
import { BPTableCellComponent } from '../BPTableComponent/BPTableCellComponent';

export interface BPTableDataProcessorComponentProps {
  data: Measurement[];
}

export const BPTablePrintDataProcessorComponent: FC<BPTableDataProcessorComponentProps> = ({
  data,
}) => {
  const processedData = useMeasurementResultToTablePrint(
    data as MeasurementType[],
    (curr) => new BPMeasurementService(curr),
  );

  return (
    <TimeOfDayTablePrintComponent
      data={processedData}
      render={(v) => <BPTableCellComponent data={[v]} />}
    />
  );
};
