import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { filter, uniq } from 'lodash';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';

export enum BlockUnmountKey {
  Insurance = 'Insurance',
  LLM = 'LLM',
}

type BlockPrompt = Promise<boolean>; // true to unblock, false to block

interface BlockPromptOptions {
  keys?: (
    BlockUnmountKey[]
    // or decide which keys to block and order of prompts
    | ((currentKeys: BlockUnmountKey[]) => BlockUnmountKey[])
  );
  next?: () => void;
  onBlocked?: () => void;
}

export interface BlockUnmountContextValue {
  checkIfBlocked?: (keys?: BlockUnmountKey[]) => boolean;
  blockUnmount?: (key: BlockUnmountKey) => void;
  unblockUnmount?: (key: BlockUnmountKey) => void;
  blockPrompt: (options?: BlockPromptOptions) => Promise<boolean>;
  setBlockPrompt?: (
    key: BlockUnmountKey,
    prompt: () => BlockPrompt,
  ) => void;
}

const BlockUnmountContext = createContext<
  BlockUnmountContextValue | undefined
>(undefined);

export const useBlockUnmountContext = () => {
  const context = useContext(BlockUnmountContext);
  return (
    context
    || {
      checkIfBlocked: () => false,
      blockPrompt: ({ next } = {}) => next?.(),
    }
  ) as BlockUnmountContextValue;
};

type BlockUnmountPromptRecord = {
  [key in BlockUnmountKey]?: () => BlockPrompt;
}

export interface BlockUnmountContextProviderProps {
  children: ReactNode;
  initialIsBlocked?: BlockUnmountKey[];
}
export const BlockUnmountContextProvider = ({
  children,
  initialIsBlocked = [],
}: BlockUnmountContextProviderProps) => {
  const isBlocked = useRef<BlockUnmountKey[] | undefined>(initialIsBlocked);
  const blockPrompts = useRef<BlockUnmountPromptRecord | undefined>({});

  const blockUnmount = (key: BlockUnmountKey) => {
    isBlocked.current = uniq([
      key,
      ...isBlocked.current || [],
    ]);
  };

  const unblockUnmount = (key: BlockUnmountKey) => {
    isBlocked.current = filter(isBlocked.current, (k) => k !== key);
  };

  const handleSetBlockPrompt = (
    key: BlockUnmountKey,
    prompt: () => BlockPrompt,
  ) => {
    blockPrompts.current = {
      ...blockPrompts.current,
      [key]: prompt,
    };
  };

  const checkBlockPrompt = async (
    keys = isBlocked.current,
  ): Promise<boolean> => {
    const [firstKey, ...restKeys] = keys || [];
    if (firstKey) {
      if (
        isBlocked?.current?.includes(firstKey)
        && blockPrompts.current?.[firstKey]
        && !await blockPrompts.current?.[firstKey]?.()
      ) {
        return false; // blocked
      }
      unblockUnmount?.(firstKey);
      return checkBlockPrompt(restKeys);
    }
    return true; // unblocked
  };

  const blockPrompt: BlockUnmountContextValue['blockPrompt'] = async ({
    keys,
    next,
    onBlocked
  } = {}) => {
    const toCheckKeys = typeof keys === 'function'
      ? keys(isBlocked.current || [])
      : keys;
    if (!await checkBlockPrompt(toCheckKeys)) {
      onBlocked?.();
      return false; // blocked
    }
    next?.();
    return true; // unblocked
  };

  const checkIfBlocked = useCallback((keys?: BlockUnmountKey[]) => {
    if (keys) {
      return !!filter(isBlocked.current, (k) => keys.includes(k)).length;
    }
    return !!isBlocked.current?.length;
  }, []);

  useEffect(() => () => {
    isBlocked.current = undefined;
    blockPrompts.current = undefined;
  }, []);

  const contextValue = useGetContextValue<BlockUnmountContextValue>({
    checkIfBlocked,
    blockUnmount,
    unblockUnmount,
    blockPrompt,
    setBlockPrompt: handleSetBlockPrompt,
  });

  return (
    <BlockUnmountContext.Provider value={contextValue}>
      {children}
    </BlockUnmountContext.Provider>
  );
};
