import { Card, Radio, RadioGroupProps } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import './SelectPatientsCARadioComponent.scss';
import { useEffectOnce } from 'usehooks-ts';
import { InputType } from '../../../Input/types';
import { SelectPatientsBaseCheckboxComponent } from '../SelectPatientsBaseCheckboxComponent/SelectPatientsBaseCheckboxComponent';
import { PriorityCoverEnum } from '../../../../uc-api-sdk';

export interface SelectPatientsCARadioComponentProps extends InputType<PriorityCoverEnum> {

}

export const SelectPatientsCARadioComponent = ({
  value,
  onChange,
}: SelectPatientsCARadioComponentProps) => {
  const handleChange: RadioGroupProps['onChange'] = (v) => {
    onChange?.(v.target.value);
  };

  useEffectOnce(() => {
    onChange?.(PriorityCoverEnum.ALL);
  });

  const renderBody = () => (
    <div className="all-patients-inner-checked-items-ca">
      <div className="flex mb5">
        <CheckCircleFilled className="check-mark-ca" />
        <div>
          Compliance Alerts
        </div>
      </div>
      <div className="flex mb5">
        <CheckCircleFilled className="check-mark-ca" />
        <div>
          Onboarding to-dos
        </div>
      </div>
    </div>
  );

  const renderCheckboxCard = () => (
    <Card
      className="all-patient-for-ca"
      title={null}
    >
      <SelectPatientsBaseCheckboxComponent
        title="All Patients"
        body={renderBody()}
        cardValue={PriorityCoverEnum.ALL}
      />
    </Card>
  );

  return (
    <div className="mb30">
      <Radio.Group
        value={value}
        className="mr10"
        onChange={handleChange}
      >
        {renderCheckboxCard()}
      </Radio.Group>
    </div>
  );
};
