import { useEffectOnce } from 'usehooks-ts';
import {
  HealthConditionItem,
  PatientComplexity,
  PatientComplexityEnum,
  usePatientGetPatientComplexityByIcdCodes,
} from '../../../../uc-api-sdk';
import { useDeepCompareEffect } from '../../../../hooks/useDeepCompareEffect';
import { PatientComplexitySource } from '../../../../generates';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { ComplexityEditComponent } from '../../../complexity/component/ComplexityEditComponent/ComplexityEditComponent';

export interface PatientHealthConditionComplexityContainerProps {
  healthConditions: HealthConditionItem[];
  value?: PatientComplexity;
  onChange?: (complexity: Partial<PatientComplexity>) => void;
  disabled?: boolean;
}

export const PatientHealthConditionComplexityContainer = ({
  healthConditions,
  value,
  onChange,
  disabled,
}: PatientHealthConditionComplexityContainerProps) => {
  const isManualComplexity = value?.source === PatientComplexitySource.ManualInput;

  const searchComplexityInfo = usePatientGetPatientComplexityByIcdCodes({
    options: { sendOnMount: false },
  });

  const fetchComplexityByHealthConditions = useDebounce(async (
    healthConditions: HealthConditionItem[],
  ) => {
    const res = await searchComplexityInfo.send({
      params: { conditions: healthConditions },
    });
    if (res?.code !== 200) {
      console.error('Failed to updated complexity level');
    }
    const updatedComplexityLevel = res?.data as PatientComplexityEnum;
    const newComplexity = {
      level: updatedComplexityLevel,
      source: PatientComplexitySource.IcdCode,
    };
    onChange?.(newComplexity);
  });

  const handleUpdate = (newComplexity: PatientComplexity) => {
    onChange?.(newComplexity);
  };

  useEffectOnce(() => {
    if (!value && healthConditions) {
      fetchComplexityByHealthConditions(healthConditions);
    }
  });

  useDeepCompareEffect(() => {
    if (!isManualComplexity && healthConditions) {
      fetchComplexityByHealthConditions(healthConditions);
    }
  }, [
    healthConditions,
    isManualComplexity,
  ]);

  return (
    <ComplexityEditComponent
      patientComplexity={value}
      onUpdate={handleUpdate}
      disabled={disabled}
    />
  );
};
