/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
} from 'react';

export interface CacheValueContextValue {
  values: { [key: string]: any };
  getFromCache: (v: any) => any;
}

export interface CacheValueContextProps {
  children: ReactNode;
  value: CacheValueContextValue;
}

export const CacheValueContext = createContext<CacheValueContextValue | undefined>(undefined);

export const useCache = () => {
  const contextValue = useContext(CacheValueContext);
  return contextValue || {} as CacheValueContextValue;
};

export const CacheValueContextProvider: FC<CacheValueContextProps> = ({
  children,
  value,
}) => (
  <CacheValueContext.Provider value={value}>
    {children}
  </CacheValueContext.Provider>
);
