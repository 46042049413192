import {
  ReactNode,
  createContext,
  useContext,
} from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import useBoolean from '../../hooks/useBoolean/useBoolean';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';

export interface MessagePopupContextValue {
  isChatOpen: boolean;
  handleOpenChat: () => void;
  handleCloseChat: () => void;
}

const MessagePopupContext = createContext<
    MessagePopupContextValue | undefined
  >(undefined);

export const useMessagePopupContext = () => {
  const context = useContext(MessagePopupContext);
  return context as MessagePopupContextValue;
};

export interface MessagePopupContextProviderProps {
  children: ReactNode;
  open?: boolean;
}
export const MessagePopupContextProvider = ({
  children,
  open,
}: MessagePopupContextProviderProps) => {
  const {
    value: isChatOpen,
    setTrue: handleOpenChat,
    setFalse: handleCloseChat,
    setValue,
  } = useBoolean(open);

  useUpdateEffect(() => {
    if (typeof open === 'boolean') setValue(open);
  }, [open]);

  const contextValue = useGetContextValue<MessagePopupContextValue>({
    isChatOpen,
    handleOpenChat,
    handleCloseChat
  });

  return (
    <MessagePopupContext.Provider value={contextValue}>
      {children}
    </MessagePopupContext.Provider>
  );
};
