import { OrderType, SupplyTypeEnum } from '../../type';
import { OrderInfoComponent } from '../OrderInfoComponent/OrderInfoComponent';

export const TestStripLancetSuppliesComponent = () => (
  <>
    <OrderInfoComponent
      type={OrderType.SUPPLIES}
      supplyType={SupplyTypeEnum.BG_STRIP}
    />
    <OrderInfoComponent
      type={OrderType.SUPPLIES}
      supplyType={SupplyTypeEnum.LANCETS}
    />
  </>
);
