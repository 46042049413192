import { useTemplatesGetBehaviorGoalTemplate } from '../../../../uc-api-sdk';
import { BehaviorGoalSearchSelectComponent, BehaviorGoalSearchSelectComponentProps } from '../../component/BehaviorGoalComponent/BehaviorGoalSearchSelectComponent/BehaviorGoalSearchSelectComponent';

export interface BehaviorGoalSearchSelectContainerProps {
  value?: BehaviorGoalSearchSelectComponentProps['value'];
  onChange?: BehaviorGoalSearchSelectComponentProps['onChange'];
}

export const BehaviorGoalSearchSelectContainer = ({
  value,
  onChange,
}: BehaviorGoalSearchSelectContainerProps) => {
  const templateSearchInfo = useTemplatesGetBehaviorGoalTemplate({
    params: {
      searchRequest: {
        filter: {},
        pageInfo: { pagination: false }
      }
    },
    options: { sendOnMount: true },
  });

  const templates = templateSearchInfo.data?.data?.content || [];

  return (
    <BehaviorGoalSearchSelectComponent
      value={value}
      onChange={onChange}
      options={templates}
      loading={templateSearchInfo.isLoading}
    />
  );
};
