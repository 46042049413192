import React, {
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useUpdateEffect } from 'usehooks-ts';

export enum Step {
  ProgramEnrollment,
  AssignDevices,
  OnboardingVisits,
  Consent,
}

interface Value {
  currStep: Step;
  completedSteps: Step[];
  goTo: (v: Step) => void;
  onDone: (v: Step) => void;
}

export const EnrollmentStepContext = React.createContext<Value>({
  currStep: Step.ProgramEnrollment,
  completedSteps: [],
  goTo: () => console.error('goTo not implemented yet!'),
  onDone: () => console.error('onDone not implemented yet!'),
});

export interface EnrollmentStepProviderProps {
  initialStep?: Step;
  initialCompletedStatuses?: Step[];
  children: ReactNode;
}

export const EnrollmentStepProvider = ({
  initialStep = Step.ProgramEnrollment,
  initialCompletedStatuses = [],
  children,
}: EnrollmentStepProviderProps) => {
  const [step, setStep] = useState<Step>(initialStep);
  const [completed, setCompleted] = useState<Step[]>(initialCompletedStatuses);

  useUpdateEffect(() => {
    if (step === undefined) {
      setStep(initialStep);
    }
    if (completed === undefined) {
      setCompleted(initialCompletedStatuses);
    }
  }, [step, initialStep, completed, initialCompletedStatuses]);

  const goTo = useCallback<Value['goTo']>((v) => {
    setStep(v);
  }, [setStep]);

  const onDone = useCallback<Value['onDone']>((v) => {
    setCompleted((steps) => ([...steps, v]));
  }, [setCompleted]);

  const contextValue = useMemo<Value>(() => ({
    currStep: step,
    completedSteps: completed,
    goTo,
    onDone,
  }), [
    step,
    completed,
    goTo,
    onDone,
  ]);

  return (
    <EnrollmentStepContext.Provider value={contextValue}>
      {
        step !== undefined
          ? children
          : null
      }
    </EnrollmentStepContext.Provider>
  );
};

export const useEnrollmentStepContext = () => {
  const patientContextValue = useContext(EnrollmentStepContext);
  return patientContextValue;
};
