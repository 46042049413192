import {
  Form,
  FormProps,
  Table,
  TableProps,
} from 'antd';
import React, { Key, useRef } from 'react';
import {
  map,
  omit,
  sortBy,
} from 'lodash';
import { ColumnType } from 'antd/lib/table';
import FormItem from '../FormItem/FormItem';
import { TableFormData } from './type';
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect';

export interface TableFormComponentProps<T> extends Omit<TableProps<T>, 'columns'> {
  columns: ColumnType<unknown>[];
  formName: string;
  form?: FormProps['form'];
  // getUpdatedData is for in case you want to update tableDataSource
  // tableDataSource is processed dataSource
  getUpdatedData?: (
    data: T,
    tableDataIndex: number,
    tableDataSource: T[],
  ) => T;
  onValuesChange?: FormProps['onValuesChange'];
  onFinish?: FormProps['onFinish'];
  formButtons?: React.ReactNode;
  disabled?: boolean;
}

export const TableFormComponent = <T extends TableFormData>({
  formName,
  form: parentForm,
  getUpdatedData,
  onValuesChange,
  onFinish,
  formButtons,
  disabled,
  columns,
  dataSource,
  onChange,
  rowKey = ((o, idx) => idx as Key),
  ...tableProps
}: TableFormComponentProps<T>) => {
  const form = parentForm || Form.useForm()[0];
  // to keep all values from dataSource
  const allValues = useRef<T[]>([]);

  const mergeAllValues = (allV: unknown, keepIndex?: boolean) => {
    const formAllValues = (allV as Record<string, T[]>)[formName];
    const merged = allValues.current.map((item, idx) => ({
      // allValues.current is behind 1 cycle,
      // formAllValues is the latest
      ...item, ...formAllValues[idx]
    }));
    // get back to original order
    const mergedWithOriginalOrder = sortBy(merged, 'tableDataIndex');
    // remove extra field on return
    return map(mergedWithOriginalOrder, (item) => {
      if (keepIndex) {
        return item;
      }
      return omit(item, 'tableDataIndex');
    });
  };

  const handleOnFinish: FormProps<T>['onFinish'] = (allV) => {
    onFinish?.(mergeAllValues(allV));
  };

  const handleOnValuesChange: FormProps<T>['onValuesChange'] = (changedValues, allV) => {
    onValuesChange?.(
      changedValues[formName],
      mergeAllValues(allV),
    );
  };

  const handleOnTableChange: TableProps<T>['onChange'] = (pagination, filters, sorter, extra) => {
    const { currentDataSource } = extra;
    form.setFieldsValue({ [formName]: currentDataSource });
    onChange?.(pagination, filters, sorter, extra);
  };

  const handleDataSourceChange = () => {
    if (!getUpdatedData) return;
    const tableDataSource = mergeAllValues(form.getFieldsValue(), true) as T[];
    const newProcessedDataSource = map(tableDataSource, (data) => {
      const newData = getUpdatedData(
        data,
        data.tableDataIndex,
        tableDataSource
      );
      return newData;
    });
    form.setFieldsValue({ [formName]: newProcessedDataSource });
  };

  useDeepCompareEffect(() => {
    handleDataSourceChange?.();
  }, [dataSource, getUpdatedData]);

  return (
    <Form
      className="table-form--form"
      form={form}
      onValuesChange={handleOnValuesChange}
      onFinish={handleOnFinish}
      component={false}
      disabled={disabled}
      initialValues={{ [formName]: dataSource }}
    >
      <FormItem noStyle shouldUpdate>
        {
          ({ getFieldValue }) => {
            const updatedDataSource = getFieldValue(formName) as T[];
            allValues.current = updatedDataSource;
            return (
              <Table
                className="table-form--table"
                columns={columns as ColumnType<T>[]}
                dataSource={updatedDataSource}
                rowKey={rowKey}
                onChange={handleOnTableChange}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...tableProps}
                /*
                * remove rowSelection
                * better create a column with checkbox for rowSelection
                */
                rowSelection={undefined}
              />
            );
          }
        }
      </FormItem>
      {formButtons}
    </Form>
  );
};
