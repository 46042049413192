import { CurrentUserAvatarComponent } from '../../../../uiComponent/CurrentUserAvatarComponent/CurrentUserAvatarComponent';
import { TranslationInputComponent, TranslationInputComponentProps } from '../TranslationInputComponent/TranslationInputComponent';
import { InputType } from '../../../Input/types';

export interface TranslateInputValue {
  language?: string;
  originalText?: string;
  translatedText?: string;
  showTranslated?: boolean;
}

export interface FoodLogCommentComponentProps extends InputType<TranslateInputValue> {
  placeholder?: string;
  showAvatar?: boolean;
  disabled?: boolean;
  messageHistoryArea?: boolean;
  onChange?: (value: TranslateInputValue | undefined) => void;
  onLanguageChange?: (language?: string) => void;
  onPressEnter?: TranslationInputComponentProps['onPressEnter'];
}

export const TranslationInputCommonComponent = ({
  value,
  showAvatar,
  placeholder,
  disabled,
  messageHistoryArea,
  onChange,
  onLanguageChange,
  onPressEnter,
}: FoodLogCommentComponentProps) => {
  const handleOriginalTextChange: TranslationInputComponentProps['setOriginalText'] = (newValue) => {
    onChange?.(value
      ? {
        ...value,
        translatedText: undefined,
        originalText: newValue,
        showTranslated: false,
      }
      : {
        translatedText: undefined,
        originalText: newValue,
        showTranslated: true,
      });
  };

  const handleLanguageChange: TranslationInputComponentProps['setLanguage'] = (newValue) => {
    onChange?.(value
      ? {
        ...value,
        language: newValue
      }
      : {
        language: newValue
      });
    onLanguageChange?.(newValue);
  };

  return (
    <div className="flex gap2">
      {showAvatar && <CurrentUserAvatarComponent /> }
      <div className="w100">
        <TranslationInputComponent
          placeholder={placeholder}
          language={value?.language}
          originalText={value?.originalText}
          translatedText={value?.translatedText}
          showTranslated={value?.showTranslated}
          disabled={disabled}
          messageHistoryArea={messageHistoryArea}
          setOriginalText={handleOriginalTextChange}
          setLanguage={handleLanguageChange}
          onPressEnter={onPressEnter}
        />
      </div>
    </div>
  );
};
