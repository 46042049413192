import { DownOutlined } from '@ant-design/icons';
import Paragraph from 'antd/lib/typography/Paragraph';
import _ from 'lodash';
import { FC, useState } from 'react';
import './CareNoteCardMessageComponent.scss';

export interface CareNoteCardMessageComponentProps {
  message: string;
  showPopup: () => void;
}

export const CareNoteCardMessageComponent: FC<CareNoteCardMessageComponentProps> = ({
  message,
  showPopup,
}) => {
  const [key, setKey] = useState<string>();

  const reset = () => {
    showPopup();
    setKey(_.uniqueId());
  };

  const viewMore = (
    <span className="viewMore text-gray-scale-2 border-b">
      <span className="mr5">View More</span>
      <DownOutlined className="flex ai-c" />
    </span>
  );

  return (
    <Paragraph
      key={key}
      className="careNoteMessage"
      ellipsis={{
        rows: 4,
        expandable: true,
        symbol: viewMore,
        onExpand: reset,
      }}
    >
      {message}
    </Paragraph>
  );
};
