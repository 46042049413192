import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { UpDownTrend, UpDownTrendComponent } from '../../../../uiComponent/UpDownTrendComponent/UpDownTrendComponent';
import { AvgDownTrendColor, AvgUpTrendColor } from '../../constants';

export interface BloodPressureAverageComponentProps {
  systolicAvg?: number | null;
  diastolicAvg?: number | null;
  systolicAvgTrend?: UpDownTrend | null;
  diastolicAvgTrend?: UpDownTrend | null;
  showTrend?: boolean;
}

export const BloodPressureAverageComponent = ({
  systolicAvg,
  diastolicAvg,
  systolicAvgTrend,
  diastolicAvgTrend,
  showTrend = true,
}: BloodPressureAverageComponentProps) => {
  const renderTrend = (trend?: UpDownTrend | null) => {
    if (!showTrend || !trend) return null;
    return (
      <UpDownTrendComponent
        trend={trend}
        upTrendColor={AvgUpTrendColor}
        downTrendColor={AvgDownTrendColor}
      />
    );
  };

  if (!systolicAvg && !diastolicAvg) {
    return <span>N/A</span>;
  }

  return (
    <div className="flex gap0">
      <div>
        <DisplayOrEmptyComponent value={systolicAvg?.toFixed(0)} emptyText="--" />
        {renderTrend(systolicAvgTrend)}
      </div>
      <span>/</span>
      <div>
        <DisplayOrEmptyComponent value={diastolicAvg?.toFixed(0)} emptyText="--" />
        {renderTrend(diastolicAvgTrend)}
      </div>
    </div>
  );
};
