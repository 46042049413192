import { filter, map } from 'lodash';
import { ReactNode } from 'react';
import { useOrderSuppliesContext } from '../../../../contexts/OrderSuppliesContext/OrderSuppliesContext';
import { DeviceModelEnum, MonitorMethodEnum } from '../../../../uc-api-sdk';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useOrderFormHook } from '../../hook/useOrderFormHook';
import { OrderType } from '../../type';
import { BPClearReplacementComponent } from '../BPClearReplacementComponent/BPClearReplacementComponent';
import { BPEaseReplacementComponent } from '../BPEaseReplacementComponent/BPEaseReplacementComponent';
import { DeviceReplacementCheckboxComponent } from '../DeviceReplacementCheckboxComponent/DeviceReplacementCheckboxComponent';
import { OrderInfoComponent } from '../OrderInfoComponent/OrderInfoComponent';
import { ConnectedDeviceInfo } from '../../../device/type';
import { CellularDeviceReplacementComponent } from '../CellularDeviceReplacementComponent/CellularDeviceReplacementComponent';

interface OrderDevicesFormItemComponentProps {
  title?: ReactNode;
  onUncheck?: (deviceId: string) => void;
}

export const OrderDevicesFormItemComponent = ({
  title = null,
  onUncheck,
}: OrderDevicesFormItemComponentProps) => {
  const { orderDevicesForm } = useOrderFormHook();
  const {
    allMostRecentDevices,
  } = useOrderSuppliesContext();

  const nonManualInputDevices = filter(
    allMostRecentDevices,
    (device) => device.deviceMethod !== MonitorMethodEnum.MANUALLY_INPUT
  );

  const renderDeviceOption = (device: ConnectedDeviceInfo) => {
    const { deviceModel } = device;
    switch (deviceModel) {
      case DeviceModelEnum.BPM1:
      case DeviceModelEnum.BPM1_HOTSPOT:
        return (
          <BPClearReplacementComponent
            deviceModel={deviceModel}
            onUncheck={onUncheck}
          />
        );
      case DeviceModelEnum.BP3L:
        return (<BPEaseReplacementComponent />);
      case DeviceModelEnum.PY_802_LTE:
      case DeviceModelEnum.BIOLAND:
        return (
          <CellularDeviceReplacementComponent
            deviceModel={deviceModel}
            onUncheck={onUncheck}
          />
        );
      case DeviceModelEnum.BG1:
        // BG Align
        return (
          <DeviceReplacementCheckboxComponent>
            <OrderInfoComponent
              type={OrderType.DEVICES}
              deviceModel={DeviceModelEnum.BG5S}
            />
          </DeviceReplacementCheckboxComponent>
        );
      case DeviceModelEnum.HS2:
        // HS Lina
        return (
          <DeviceReplacementCheckboxComponent>
            <OrderInfoComponent
              type={OrderType.DEVICES}
              deviceModel={DeviceModelEnum.HS2S}
            />
          </DeviceReplacementCheckboxComponent>
        );
      default:
        return (
          <DeviceReplacementCheckboxComponent>
            <OrderInfoComponent
              type={OrderType.DEVICES}
              deviceModel={deviceModel}
            />
          </DeviceReplacementCheckboxComponent>
        );
    }
  };

  if (!nonManualInputDevices.length) {
    return null;
  }

  return (
    <>
      {title}
      {
        map(nonManualInputDevices, (device) => (
          <FormItem
            key={device.deviceModel}
            name={orderDevicesForm.makeDevicesFieldName(device.deviceModel)}
          >
            {renderDeviceOption(device)}
          </FormItem>
        ))
      }
    </>
  );
};
