import React from 'react';
import { Typography } from 'antd';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { MedicalAlertTableContainer } from '../../container/MedicalAlertTableContainer/MedicalAlertTableContainer';
import { ComplianceAlertTableContainer } from '../../container/ComplianceAlertContainer/ComplianceAlertTableContainer';

const { Text } = Typography;

export interface AlertInCardComponentProps {
  patientId?: string;
}

export const AlertInCardComponent = ({
  patientId,
}: AlertInCardComponentProps) => (
  <PatientCommonCardComponent
    title="Alert History"
    isEditing={false}
    showEdit={false}
    updateInfo={{}}
    content={(
      <>
        <div className="mb10 mt10">
          <Text>
            Medical Alerts
          </Text>
        </div>
        <MedicalAlertTableContainer memberId={patientId} />
        <div className="mb10 mt50">
          <Text>
            Compliance Alerts
          </Text>
        </div>
        <ComplianceAlertTableContainer memberId={patientId} />
      </>
    )}
  />
);
