import { FormOptions, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { HealthConditionItem } from '../../../uc-api-sdk';

export interface ICDCodeSubmitValue {
  icdTable: HealthConditionItem[];
}

export const useICDCodeForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    icdSelect: {
      name: 'icdSelect',
    },
    icdTable: {
      name: 'icdTable',
    },
  }, options);

  return factory;
};
