import { TranscribeTypeEnum, VisitTypeEnum } from '../../../../uc-api-sdk';
import { TranscribeA1CTitleComponent } from './TranscribeA1CTitleComponent';
import { TranscribeVisitTitleComponent } from './TranscribeVisitTitleComponent';

export interface TranscribeDrawerTitleComponentProps {
  transcribeType: TranscribeTypeEnum;
  transcribeVisitType?: VisitTypeEnum
}

export const TranscribeDrawerTitleComponent = ({
  transcribeType,
  transcribeVisitType,
}: TranscribeDrawerTitleComponentProps) => {
  if (transcribeType === TranscribeTypeEnum.A1C) {
    return <TranscribeA1CTitleComponent />;
  }
  if (transcribeType === TranscribeTypeEnum.VISIT) {
    return <TranscribeVisitTitleComponent visitType={transcribeVisitType} />;
  }

  return <span>Transcribe</span>;
};
