import { ReactNode } from 'react';

export interface TabItemsNumberComponentProps {
  num: number | undefined;
  name: ReactNode;
}

export const TabItemsNumberComponent = ({
  num,
  name,
}: TabItemsNumberComponentProps) => (
  <span>
    {name}
    {' ('}
    {num}
    {') '}
  </span>
);
