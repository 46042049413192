import { ReactNode } from 'react';
import TooltipComponent from '../TooltipComponent/TooltipComponent';

export interface ComingSoonTooltipComponentProps {

  children: ReactNode;
}

export const ComingSoonTooltipComponent = ({
  children
}: ComingSoonTooltipComponentProps) => (
  <div>
    <TooltipComponent
      title="Coming soon"
      type={null}
    >
      {children}
    </TooltipComponent>
  </div>
);
