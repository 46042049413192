import { Card } from 'antd';
import { MedicalAlertResult } from '../../../../uc-api-sdk';
import { MedicalAlertContentComponent } from '../MedicalAlertContentComponent/MedicalAlertContentComponent';
import { MedicalAlertTitleComponent } from '../MedicalAlertTitleComponent/MedicalAlertTitleComponent';
import './MedicalAlertComponent.scss';

export interface MedicalAlertComponentProps {
  medicalAlertResult: MedicalAlertResult;
}

export const MedicalAlertComponent = ({
  medicalAlertResult,
}: MedicalAlertComponentProps) => (
  <Card
    title={(
      <MedicalAlertTitleComponent
        medicalAlertResult={medicalAlertResult}
      />
    )}
    className="medical-alert-component"
  >
    <MedicalAlertContentComponent medicalAlertResult={medicalAlertResult} />
  </Card>
);
