import { Tag } from 'antd';
import { includes } from 'lodash';
import { WebflowLanguageEnum } from '../../types';
import { webflowLanguageAbbreviationMap } from '../../constants';

export interface ArticleSearchOptionLanguageTagComponentProps {
  language?: string,
  excludeLanguageTags?: WebflowLanguageEnum[],
}
export const ArticleSearchOptionLanguageTagComponent = (
  props: ArticleSearchOptionLanguageTagComponentProps,
) => {
  const {
    language,
    excludeLanguageTags,
  } = props;

  const tagText = webflowLanguageAbbreviationMap[language as WebflowLanguageEnum] || language;
  const isExcluded = includes(excludeLanguageTags, tagText);

  return tagText && !isExcluded
    ? (
      <Tag key={language} className="article-select-option-info__language">
        {tagText}
      </Tag>
    ) : null;
};
