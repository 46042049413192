import dayjs from 'dayjs';
import { ReactNode } from 'react';
import { InsuranceItemComponent } from '../InsuranceItemComponent/InsuranceItemComponent';
import { InsurancePriorAuthStatusEnumComponent } from '../../../../enumComponent/InsurancePriorAuthStatusEnumComponent/InsurancePriorAuthStatusEnumComponent';
import { PriorAuthSubmissionNoteComponent } from './PriorAuthSubmissionNoteComponent';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { CPTCodeUnitsComponent } from '../CPTCodeComponent/CPTCodeUnitsComponent/CPTCodeUnitsComponent';
import { ExpirationStatusComponent } from '../../../../uiComponent/ExpirationStatusComponent/ExpirationStatusComponent';
import { REMIND_DAYS_TO_EXPIRATION } from '../../helpers';
import { PriorAuthCaseStatusEnum } from '../../../../uc-api-sdk';
import { AntdEllipsisComponent } from '../../../../uiComponent/AntdEllipsisComponent/AntdEllipsisComponent';
import { TempPriorAuthCase } from '../../../../hooks/formHook/useInsuranceForm';
import { CPTCodeUnitCategoryEnumComponent } from '../../../../enumComponent/CPTCodeUnitCategoryEnumComponent/CPTCodeUnitCategoryEnumComponent';

export interface PriorAuthViewCardComponentProps {
  priorAuth: TempPriorAuthCase;
}

export const PriorAuthViewCardComponent = ({
  priorAuth,
}: PriorAuthViewCardComponentProps) => {
  const renderLabelWithRequireMark = (
    label?: ReactNode,
  ) => (
    <div className="flex gap0">
      <div className="require-mark-no-margin">
        {label}
      </div>
      :
    </div>
  );

  const renderStatus = () => (
    <InsuranceItemComponent
      label={renderLabelWithRequireMark('Status')}
      content={<InsurancePriorAuthStatusEnumComponent value={priorAuth.status} />}
    />
  );

  const renderDate = (
    date?: string | number | null,
    emptyText = '-- --',
  ) => (
    date
      ? (<DisplayDateComponent value={date} format="USA_DATE" />)
      : <DisplayOrEmptyComponent emptyText={emptyText} />
  );

  const renderSubmissionDate = () => (
    <InsuranceItemComponent
      label="Submission date:"
      content={renderDate(priorAuth.submissionDate)}
    />
  );

  const renderCPTCode = () => (
    <div className="mt20">
      <InsuranceItemComponent
        span={24}
        label={(
          <>
            CPT code units:
            {' '}
            <CPTCodeUnitCategoryEnumComponent value={priorAuth.cptCodeCategory} />
          </>
        )}
        content={(
          <CPTCodeUnitsComponent
            value={priorAuth.cptCodes || {}}
            readOnly
          />
        )}
      />
    </div>
  );

  const renderComments = () => (
    <InsuranceItemComponent
      span={20}
      label="Comments:"
      content={(
        <DisplayOrEmptyComponent
          value={priorAuth.comment}
          emptyText="-- --"
        />
      )}
    />

  );

  const renderLayout = () => {
    switch (priorAuth.status) {
      case PriorAuthCaseStatusEnum.NEED_SUBMISSION:
        return (
          <div>
            {renderStatus()}
            {renderCPTCode()}
            {renderComments()}
            <PriorAuthSubmissionNoteComponent alwaysShow />
          </div>
        );
      case PriorAuthCaseStatusEnum.PENDING_RESULT:
        return (
          <>
            <div className="flex f-w">
              {renderStatus()}
              {renderSubmissionDate()}
            </div>
            <div>
              {renderCPTCode()}
            </div>
            <div>
              {renderComments()}
            </div>
          </>
        );
      case PriorAuthCaseStatusEnum.APPROVED:
        return (
          <>
            <div className="flex f-w">
              {renderStatus()}
              {renderSubmissionDate()}
              <InsuranceItemComponent
                label={renderLabelWithRequireMark('Approved date')}
                content={(
                  renderDate(priorAuth.approvalDate)
                )}
              />
            </div>
            <div className="flex f-w">
              <InsuranceItemComponent
                label={renderLabelWithRequireMark('Authorization #')}
                content={(
                  <AntdEllipsisComponent>
                    {priorAuth.authorizationNumber || '-- --'}
                  </AntdEllipsisComponent>
                )}
              />
              <InsuranceItemComponent
                span={8}
                label={renderLabelWithRequireMark('Auth period')}
                content={(
                  <div>
                    {renderDate(priorAuth.authStartDate, 'N/A')}
                    <span> - </span>
                    {renderDate(priorAuth.authExpiredDate, 'N/A')}
                  </div>
                )}
              />
            </div>
            <div>
              {renderCPTCode()}
            </div>
            <div>
              {renderComments()}
            </div>
          </>
        );
      case PriorAuthCaseStatusEnum.DENIED:
        return (
          <>
            <div className="flex f-w">
              {renderStatus()}
              {renderSubmissionDate()}
              <InsuranceItemComponent
                label="Denied date:"
                content={(
                  renderDate(priorAuth.deniedDate)
                )}
              />
            </div>
            <div>
              {renderCPTCode()}
            </div>
            <InsuranceItemComponent
              span={20}
              label="Denied reason:"
              content={(
                <DisplayOrEmptyComponent
                  value={priorAuth.deniedReason}
                  emptyText="-- --"
                />
              )}
            />
            <div className="mt12">
              {renderComments()}
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="white-card">
      {
        typeof priorAuth.expirationDays === 'number'
        && (
          <div className="w100 flex jc-e mb20">
            <ExpirationStatusComponent
              expirationDate={dayjs().startOf('day').add(priorAuth.expirationDays, 'day')}
              daysToShowExpiring={REMIND_DAYS_TO_EXPIRATION}
            />
          </div>
        )
      }
      <div className="flex fd-c gap2">
        {renderLayout()}
      </div>
    </div>
  );
};
