import { isArray } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { MeasurementResponse, MeasurementResultTypeEnum, useMeasurementResultList } from '../../../../uc-api-sdk';
import { FetchComponent, FetchComponentStyleProps, Info } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { VitalFetchProps } from '../../type';

export interface VitalFetchContainerProps extends VitalFetchProps {
  type: MeasurementResultTypeEnum | MeasurementResultTypeEnum[];
  needStats: boolean;
  fetchComponentStyleProps?: FetchComponentStyleProps;
  children: (
    data: MeasurementResponse | undefined,
    info: Info,
    memberId?: string,
  ) => ReactNode;
}

export const VitalFetchContainer = ({
  memberId,
  fromDate,
  toDate,
  type,
  needStats,
  fetchComponentStyleProps,
  children,
}: VitalFetchContainerProps) => {
  const typeList = useMemo(() => {
    if (isArray(type)) {
      return type;
    }
    return [type];
  }, [type]);

  const measurementResult = useMeasurementResultList({
    options: { sendOnMount: true },
    params: {
      searchRequest: {
        filter: {
          memberId,
          dateRange: {
            gte: fromDate.toISOString(),
            lte: toDate.toISOString(),
          },
          needStats,
          needDataInVisits: true,
          typeList,
        },
        pageInfo: {
          pagination: false,
        },
      },
    },
  });

  return (
    <FetchComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(fetchComponentStyleProps || {})}
      info={measurementResult}
      showOnRefetch
    >
      {(_, info) => children(info.data?.data ?? undefined, info)}
    </FetchComponent>
  );
};
