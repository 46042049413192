import './TranslatedContentComponent.scss';

interface TranslatedContentComponentProps {
  translatedText: string;
  originalText: string;
  messageHistoryArea?: boolean;
  isPatient?: boolean;
}

export const TranslatedContentComponent = ({
  translatedText,
  originalText,
  messageHistoryArea,
  isPatient,
}: TranslatedContentComponentProps) => (
  <span className="translated-text-comment">
    {messageHistoryArea ? (
      <div>
        <div>{originalText}</div>
        <div className={`message-original-text-comment mt5 ${isPatient ? 'patient' : 'not-patient'}`}>
          <div className="fs-italic light-grey fs12">Translation (not visible to patient):</div>
          {translatedText}
        </div>
      </div>
    ) : (
      <div>
        <div>{translatedText}</div>
        <div className="original-text-comment mt5">
          <div className="fs-italic">Original text (not visible to patient):</div>
          {originalText}
        </div>
      </div>
    )}
  </span>
);
