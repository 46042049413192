import { FC } from 'react';
import { DatePicker, DatePickerProps } from 'antd';
import moment, { Moment } from 'moment';
import { MEDICATION_DATE_FORMAT } from '../../constants/timeFormat/index';

export interface MedicationEndDateInputComponentProps {
  value?: DatePickerProps['value'],
  onChange?: DatePickerProps['onChange'],
  className?: string,
  disabedBeforeDate?: string | Moment | null
}

export const MedicationEndDateInputComponent: FC<MedicationEndDateInputComponentProps> = ({
  value,
  onChange,
  className,
  disabedBeforeDate,
}) => {
  const disabledDate: DatePickerProps['disabledDate'] = (current) => (disabedBeforeDate ? current < moment(disabedBeforeDate).endOf('day') : false);

  return (
    <DatePicker
      value={value ? moment(value) : null}
      className={`${className} w100`}
      onChange={onChange}
      format={MEDICATION_DATE_FORMAT}
      disabledDate={disabledDate}
    />
  );
};
