import {
  Alert,
  Space,
  Tooltip
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { map } from 'lodash';
import { LimitedUser } from '../../../../uc-api-sdk';
import { AccountHelper } from '../../../../helpers/account';
import './PatientViewerComponent.scss';

export interface PatientViewerComponentProps {
  viewers?: LimitedUser[];
}

export const PatientViewerComponent = ({
  viewers = [],
}: PatientViewerComponentProps) => {
  const fullNames = map(viewers, (viewer) => AccountHelper.getFullName(viewer as LimitedUser));

  let message = '';
  const viewerNames = fullNames.join(', ');
  if (viewerNames) {
    const toBe = viewers.length > 1 ? 'are' : 'is';
    message = `${viewerNames} ${toBe} viewing patient profile.`;
  }

  if (!message) {
    return null;
  }

  const patientViewerTooltip = () => (
    <div>
      If another care team member is currently viewing this
      <br />
      patient's profile, please revisit later to avoid data conflicts
      <br />
      and potential loss of changes. To prevent double counting,
      <br />
      billable time will be credited to the first care team member
      <br />
      on the patient profile page.
    </div>
  );

  const renderPatientViewer = () => (
    <Space>
      <div>{message}</div>
      <Tooltip
        overlay={patientViewerTooltip()}
        overlayStyle={{ minWidth: '400px' }}
      >
        <InfoCircleOutlined />
      </Tooltip>
    </Space>
  );

  return (
    <div className="flex jc-c">
      <Alert
        type="info"
        message={renderPatientViewer()}
        className="patientViewerComponent"
      />
    </div>
  );
};
