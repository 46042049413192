import {
  reduce,
  forEach,
  includes,
  intersection,
  compact,
  uniq,
} from 'lodash';
import { useMemo } from 'react';
import { ClinicEvent, RoleTypeEnum, VisitTypeEnum } from '../../../uc-api-sdk';
import { ParticipantsEnum, ParticipantsFormEnum } from '../type/participants';
import { useCreateVisitForm } from './useCreateVisitForm';
import { useLoggedInUserFromContext } from '../../../contexts/loggedInUserContext';
import { useCreateVisitInitialValues } from './useCreateVisitInitialValues';

export const useGetParticipantWithCurrentUser = (visit?: ClinicEvent) => {
  const {
    userInfo
  } = useLoggedInUserFromContext();
  const {
    getParticipantsBasedOnVisitType,
  } = useCreateVisitForm();
  const {
    getParticipantsInitialValue,
  } = useCreateVisitInitialValues();
  const {
    visitType,
    visitParticipants,
  } = visit || {};
  const {
    participants,
  } = getParticipantsInitialValue(visitParticipants || []);
  const userRoleTypes = userInfo?.allRoleTypes;

  const assignedParticipants = useMemo(() => {
    const defaultParticipants = getParticipantsBasedOnVisitType(visitType as VisitTypeEnum);
    const defaultParticipantRoles = reduce(defaultParticipants, (res, current) => {
      if (current === ParticipantsEnum.RdHc) {
        res.push(RoleTypeEnum.RD);
        res.push(RoleTypeEnum.HC);
      } else if (current === ParticipantsEnum.MD) {
        res.push(RoleTypeEnum.PROVIDER);
        res.push(RoleTypeEnum.NP);
      } else {
        res.push(RoleTypeEnum[current as unknown as RoleTypeEnum]);
      }
      return res;
    }, [] as RoleTypeEnum[]);
    const participant = {
      id: userInfo?.id,
      role: undefined as ParticipantsEnum | undefined,
      formField: '',
    };
    // replace the participant if the user has the role based on the visit type
    forEach(defaultParticipantRoles, (role) => {
      if (!participant.role && includes(userRoleTypes, role)) {
        switch (role) {
          case RoleTypeEnum.RD:
          case RoleTypeEnum.HC:
            participant.role = ParticipantsEnum.RdHc;
            participant.formField = ParticipantsFormEnum.RdHc;
            break;
          case RoleTypeEnum.CA:
            participant.role = ParticipantsEnum.CA;
            participant.formField = ParticipantsFormEnum.CA;
            break;
          case RoleTypeEnum.PROVIDER:
          case RoleTypeEnum.NP:
            participant.role = ParticipantsEnum.MD;
            participant.formField = ParticipantsFormEnum.MD;
            break;
          case RoleTypeEnum.MA:
            participant.role = ParticipantsEnum.MA;
            participant.formField = ParticipantsFormEnum.MA;
            break;
          default:
        }
      }
    });

    // fill in the participant if the user has the role not based on the visit type
    if (participant.role === undefined) {
      if (
        intersection([RoleTypeEnum.RD, RoleTypeEnum.HC], userRoleTypes).length
      ) {
        participant.role = ParticipantsEnum.RdHc;
        participant.formField = ParticipantsFormEnum.RdHc;
      } else if (
        intersection([RoleTypeEnum.CA], userRoleTypes).length
      ) {
        participant.role = ParticipantsEnum.CA;
        participant.formField = ParticipantsFormEnum.CA;
      } else if (
        intersection([RoleTypeEnum.PROVIDER, RoleTypeEnum.NP], userRoleTypes).length
      ) {
        participant.role = ParticipantsEnum.MD;
        participant.formField = ParticipantsFormEnum.MD;
      } else if (
        intersection([RoleTypeEnum.MA], userRoleTypes).length
      ) {
        participant.role = ParticipantsEnum.MA;
        participant.formField = ParticipantsFormEnum.MA;
      }
    }

    return participant;
  }, [
    userInfo?.id,
    userRoleTypes,
    visitType,
  ]);

  const participantValues = useMemo(() => ({
    participants: uniq(compact(
      [
        ...(participants || []),
        assignedParticipants.role
      ]
    )),
    ...(
      assignedParticipants.formField
        ? {
          [assignedParticipants.formField]: assignedParticipants.id
        } : {}
    )
  }), [
    assignedParticipants,
  ]);

  return participantValues;
};
