import React, { FC } from 'react';
import { MeasurementResultTypeEnum } from '../../../../uc-api-sdk';
import { VitalChartTableSummaryViewComponentProps } from '../VitalChartTableSummaryViewComponent/VitalChartTableSummaryViewComponent';
import { VitalFetchProps } from '../../type';
import { VitalChartViewComponent } from '../VitalChartViewComponent/VitalChartViewComponent';
import { ExerciseChartDataProcessorComponent } from '../ExerciseChartComponent/ExerciseChartDataProcessorComponent';

export interface ExerciseVitalComponentProps extends VitalFetchProps {

}

export const ExerciseVitalComponent: FC<ExerciseVitalComponentProps> = ({
  memberId,
  fromDate,
  toDate,
}) => {
  const chartView: VitalChartTableSummaryViewComponentProps['chartView'] = ({ data, fromDate, toDate }) => (
    <ExerciseChartDataProcessorComponent
      data={data?.results?.content || []}
      stats={data?.stats?.exercise || {}}
      fromDate={fromDate}
      toDate={toDate}
    />
  );

  return (
    <VitalChartViewComponent
      type={MeasurementResultTypeEnum.EXERCISE}
      memberId={memberId}
      title="Exercise"
      chartView={chartView}
      needStats
      fromDate={fromDate}
      toDate={toDate}
    />
  );
};
