import { PatientTagSearchSelectContainer } from '../../reassignPatients/container/PatientTagSearchSelectContainer/PatientTagSearchSelectContainer';
import { TableSearchComponent } from '../component/TableSearchComponent/TableSearchComponent';
import { TableFilterType } from '../type/filter';
import { SearchIcon } from './SearchIcon';

export interface PatientTagSearchFilterProps {
}

export const createPatientTagSearchFilter = (): TableFilterType => ({
  filterDropdown: ((props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TableSearchComponent {...props}>
      <PatientTagSearchSelectContainer
        value={props.selectedKeys?.[0] as string | undefined}
        onChange={(id) => {
          props.setSelectedKeys(id ? [id] : []);
        }}
          // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </TableSearchComponent>
  )),
  filterIcon: SearchIcon,
});
