import { LimitedClinic } from '../../uc-api-sdk';

export interface Organization {
  id: string;
  refId: string;
  name: string;
  timezone: string;
}

export interface OnSiteInfo {
  isOnSite: boolean;
  clinic?: LimitedClinic;
}

export enum PortalPlatform {
  CarePortal = 'CarePortal',
  AdminPortal = 'AdminPortal',
  BillerPortal = 'BillerPortal'
}
