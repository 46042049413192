import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { LabResultInCardComponent } from '../../component/LabResultTableComponent/LabResultInCardComponent';

export interface LabResultInCardContainerProps {
  patientId?: string;
}

export const LabResultInCardContainer = ({
  patientId,
}: LabResultInCardContainerProps) => {
  const { send } = useBillableTimeInterventionComp();

  const handleClickAddLabResult = () => {
    send({
      patientId,
      component: BillableTimeInterventionComponentEnum.UpdateLabResults,
      detail: BillableTimeInterventionDetailEnum.MedicalHistoryLabResultAddLabResult,
    });
  };

  return (
    <LabResultInCardComponent
      patientId={patientId}
      onClickAddLabResult={handleClickAddLabResult}
    />
  );
};
