import classNames from 'classnames';
import { useWindowSize } from 'usehooks-ts';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { MessagePatientContextProvider } from '../../../../contexts/MessageContext/MessagePatientContext';
import { MessagePopupContextProvider } from '../../../../contexts/MessageContext/MessagePopupContext';
import { useMessageServicesContext } from '../../../../contexts/MessageContext/MessageServicesContext';
import { MixpanelProvider } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelDurationEvents, MixpanelEventsParents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { useRouter } from '../../../../hooks/useRouter/useRouter';
import { BillableTimeContainer } from '../../../billableTime/container/BillableTimeContainer/BillableTimeContainer';
import { BillableInfoProvider } from '../../../billableTime/context/BillableInfoContext/BillableInfoContext';
import { BillableComponentEnum } from '../../../billableTime/hook/useBillableEventTracker/useBillableEventTrackerComp';
import { MessagePopupComponent } from '../../../message/component/MessagePopupComponent/MessagePopupComponent';
import { OutstandingContainer } from '../../../outstanding/container/OutstandingContainer/OutstandingContainer';
import { AlertListComponent } from '../../component/AlertListComponent/AlertListComponent';
import { OldAppVersionNoteComponent } from '../../component/OldAppVersionNoteComponent/OldAppVersionNoteComponent';
import { PatientBillableTimeComponent } from '../../component/PatientBillableTimeComponent/PatientBillableTimeComponent';
import { PatientInfoCardComponent } from '../../component/PatientInfoCardComponent/PatientInfoCardComponentEnhanced';
import { PatientProfileComponent } from '../../component/PatientProfileComponent/PatientProfileComponent';
import { PatientProfileLayoutComponent } from '../../component/PatientProfileLayoutComponent/PatientProfileLayoutComponent';
import { TestPatientNoteComponent } from '../../component/TestPatientNoteComponent/TestPatientNoteComponent';
import { PatientProfileContainer } from '../../container/PatientProfileContainer/PatientProfileContainer';
import { PatientProfilePageControllerProvider } from '../../context/PatientPageControllerContext/PatientPageControllerContext';
import { useRenderDuration } from '../../../../hooks/useRenderDuration/useRenderDuration';
import { PatientViewerContainer } from '../../container/PatientViewerContainer/PatientViewerContainer';
import { PinnedPatientsBarComponent } from '../../../patientCare/component/PinnedPatientsBarComponent/PinnedPatientsBarComponent';
import './PatientProfilePageComponent.scss';

const {
  Header,
  PatientCard,
  Body,
  ExtraRight,
} = PatientProfileLayoutComponent;

// side menu min-width: 87px;
// __extra min-width: 350px;
// __main min-width: 921px;
// add margins between those;
export const PatientProfilePageMinWidth = 1480;

export interface PatientProfilePageComponentProps {
  patientId?: string;
  showPatientCare?: boolean;
}

const PatientProfilePageComponent = ({
  patientId: pId,
  showPatientCare = false,
}: PatientProfilePageComponentProps) => {
  const { query } = useRouter();
  const patientId = (query.patientId || pId) as string | undefined;
  const size = useWindowSize();
  const {
    checkChannelHasUnread
  } = useMessageServicesContext() || {};
  const {
    userId,
  } = useLoggedInUserFromContext();
  useRenderDuration(MixpanelDurationEvents.RenderPatientProfilePage);

  const hasNewMessage = checkChannelHasUnread?.(patientId || '');

  return patientId ? (
    <MixpanelProvider
      context={
        showPatientCare
          ? MixpanelEventsParents.PatientCare
          : MixpanelEventsParents.PatientProfile
      }
    >
      <PatientProfileContainer
        key={patientId}
        patientId={patientId}
        getEnrolledProgram
        getComplexity
        getAssignees
        getHealthCondition
        getPatient
        getDevices
        getBillableTime
        getOutstandingItemCount
        getInsurance
        getAppVersion
      >
        {(patientInfo, isLoading) => {
          const patientAssigneesId = patientInfo?.assigneesService?.getAllAssigneesId();
          const shouldAlwaysFetchHistory = patientAssigneesId?.includes(userId || '');
          return (
            // TODO should remove this type of css
            <div className={classNames({ 'normal-page': !showPatientCare })}>
              <PatientProfilePageControllerProvider isPatientCare={showPatientCare}>
                <PatientBillableTimeComponent />
                <BillableTimeContainer
                  patientId={patientId}
                  comp={BillableComponentEnum.PatientProfile}
                />
                <BillableInfoProvider isBillable={false}>
                  <MessagePopupContextProvider>
                    <MessagePatientContextProvider
                      key={patientId || ''}
                      patientId={patientId || ''}
                      patientInfo={patientInfo?.patientInfo}
                    >
                      <PatientProfileLayoutComponent>
                        <Header>
                          {showPatientCare && (
                            <PinnedPatientsBarComponent />
                          )}
                          <div className="patient-profile-page__alerts">
                            <PatientViewerContainer patientId={patientId} />
                            <TestPatientNoteComponent />
                            <OldAppVersionNoteComponent />
                          </div>
                        </Header>
                        <PatientCard>
                          <div className="flex fd-c">
                            <PatientInfoCardComponent
                              isLoading={isLoading}
                              patientInfo={patientInfo}
                              showEnrollmentDrawer={
                                query.showEnrollmentDrawer as string | undefined
                              }
                              hasNewMessage={hasNewMessage}
                            />
                            <AlertListComponent />
                          </div>
                        </PatientCard>
                        <Body>
                          <PatientProfileComponent />
                          {
                            patientId
                            && (
                              <MessagePopupComponent
                                alwaysFetchHistory={shouldAlwaysFetchHistory}
                              />
                            )
                          }
                        </Body>
                        {
                          size.width > PatientProfilePageMinWidth
                          && (
                            <ExtraRight>
                              <OutstandingContainer patientId={patientId || ''} />
                            </ExtraRight>
                          )
                        }
                      </PatientProfileLayoutComponent>
                    </MessagePatientContextProvider>
                  </MessagePopupContextProvider>
                </BillableInfoProvider>
              </PatientProfilePageControllerProvider>
            </div>
          );
        }}
      </PatientProfileContainer>
    </MixpanelProvider>
  ) : null;
};

export default PatientProfilePageComponent;
