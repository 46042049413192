import { Table, TableProps } from 'antd';
import { useTableColumns } from '../../../../../hooks/table/useTableColumns/useTableColumns';
import { printCarePlanColumns } from './printCarePlanColumns';
import { CarePlan } from '../../../../../uc-api-sdk';

export interface PrintCarePlanTableComponentProps {
  dataSource?: CarePlan[];
  onChange?: TableProps<CarePlan>['onChange'];
  pagination?: TableProps<CarePlan>['pagination'];
}

export const PrintCarePlanTableComponent = ({
  dataSource,
  onChange,
  pagination,
}: PrintCarePlanTableComponentProps) => {
  const columns = useTableColumns(
    printCarePlanColumns(),
    ['version', 'updatedAt', 'updatedContent', 'updatedBy', 'action'],
  );

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      onChange={onChange}
      pagination={pagination}
      rowKey={(r: CarePlan) => (
        `${r.version}-${r.updateFrom}-${r.id}`
      )}
    />
  );
};
