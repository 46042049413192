import React, { FC } from 'react';
import { isArray } from 'lodash';
import LANGUAGES_MAP from '../../selectComponents/LanguageSelectComponent/LanguagesMap';
import { LanguageType } from '../../types/languages';
import DisplayOrEmptyComponent from '../DisplayComponent/DisplayOrEmptyComponent';

export interface LanguagesComponentProps {
  value?: string | string[] | LanguageType | LanguageType[];
  type?: 'abbr' | 'fullName' | 'abbrAndFullName';
  showName?: boolean
}

export const LanguagesComponent: FC<LanguagesComponentProps> = ({
  value,
  type,
  showName = false,
}) => {
  const langArray = value === undefined || isArray(value) ? value : [value];

  const getName = (v: LanguageType | string) => {
    const language = LANGUAGES_MAP[v as LanguageType];
    if (language) {
      switch (type) {
        case 'abbr': return language.abbr;
        case 'fullName': return language.name;
        case 'abbrAndFullName': return `${language.abbr} (${language.name})`;
        // TODO: remove showName because it is the old impl
        default: return !showName ? language.abbr : `${language.abbr} (${language.name})`;
      }
    }
    return v;
  };

  const getLanguages = () => (
    langArray?.map(getName) || null
  );

  return (
    <DisplayOrEmptyComponent<string> value={getLanguages()?.join(', ')} />
  );
};

export default LanguagesComponent;
