import { capitalize, isEmpty } from 'lodash';
import { MealIntake } from '../../../../../uc-api-sdk';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';
import { PrintCarePlanSimpleItemComponent } from '../PrintCarePlanSimpleItemComponent/PrintCarePlanSimpleItemComponent';
import DisplayOrEmptyComponent from '../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface PrintCarePlanNutritionIntakeComponentProps {
  nutritionIntake?: MealIntake | null;
}
// meal ?: Nullable<BgMealTypeEnum>;
// timeRange ?: Nullable<string>;
// foodTypeAmount ?: Nullable<string>;
// mealFreq ?: Nullable<string>;
// skipFreq ?: Nullable<string>;
export const PrintCarePlanNutritionIntakeComponent = ({
  nutritionIntake,
}: PrintCarePlanNutritionIntakeComponentProps) => {
  if (isEmpty(nutritionIntake)) {
    return null;
  }
  return (
    <div className="mb12">
      <TextComponent size="medium" bold>
        {capitalize(nutritionIntake?.meal || '')}
      </TextComponent>
      <PrintCarePlanSimpleItemComponent
        label="Time Range:"
        content={nutritionIntake.timeRange}
      />
      <PrintCarePlanSimpleItemComponent
        label="Food Types and Amount:"
        content={(
          <DisplayOrEmptyComponent value={nutritionIntake.foodTypeAmount} />
        )}
      />
      <PrintCarePlanSimpleItemComponent
        label="Frequency:"
        content={(
          <DisplayOrEmptyComponent value={nutritionIntake.mealFreq} />
        )}
      />
      <PrintCarePlanSimpleItemComponent
        label="Skip:"
        content={(
          <DisplayOrEmptyComponent value={nutritionIntake.skipFreq} />
        )}
      />
    </div>
  );
};
