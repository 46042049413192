import React from 'react';
import { LabResultTableColumnActionComponent } from './LabResultTableColumnActionComponent';
import { LabResultClass } from '../../../../uc-api-sdk';

export interface OtherLabResultTableColumnActionComponentProp {
  labResult?: LabResultClass;
}
export const OtherLabResultTableColumnActionComponent = ({
  labResult,
}: OtherLabResultTableColumnActionComponentProp) => (
  <div className="flex jc-c ml10">
    <LabResultTableColumnActionComponent labResult={labResult} />
  </div>
);
