import { ReactNode } from 'react';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import { CalendarFilled } from '@ant-design/icons';
import { CalendarEventBaseComponent } from '../CalendarEventBaseComponent/CalendarEventBaseComponent';
import './CalendarMultiPurposeEventComponent.scss';

export interface CalendarMultiPurposeEventComponentProps {
  startTime: Dayjs;
  endTime: Dayjs;
  title: ReactNode;
  isAllDay: boolean;
  className?: string;
}

export const CalendarMultiPurposeEventComponent = ({
  startTime,
  endTime,
  title,
  isAllDay,
  className = '',
}: CalendarMultiPurposeEventComponentProps) => (
  <CalendarEventBaseComponent
    className={classNames({
      'calendar-multi-purpose-event-component': true,
      [className]: !!className,
    })}
    startTime={startTime}
    endTime={endTime}
    title={title}
    isAllDay={isAllDay}
    icon={<CalendarFilled />}
  />
);
