import { useState } from 'react';
import { Icons } from '../../../../icons/Icons';
import { CalendarDrawerComponent } from '../../../calendar/component/CalendarDrawerComponent/CalendarDrawerComponent';
import { ClinicEventsService } from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';

export interface WorklistCalendarDrawerEditButtonComponentProps {
  clinicEvent: ClinicEventsService;
}

export const WorklistCalendarDrawerEditButtonComponent = ({
  clinicEvent,
}: WorklistCalendarDrawerEditButtonComponentProps) => {
  const [openClinicEventId, setOpenClinicEventId] = useState<string | undefined>();
  const open = (clinicEventId?: string) => setOpenClinicEventId(clinicEventId);
  const close = () => setOpenClinicEventId(undefined);
  useUpdateListener('GO_TO_CHECK_IN', close);

  return (
    <>
      <Icons.EditIcon onClick={() => open(clinicEvent.id)} />
      <CalendarDrawerComponent
        key={clinicEvent.id}
        isOpen={clinicEvent.id === openClinicEventId}
        onClose={close}
        calendarInitialValue={{
          date: clinicEvent.startTime?.toISOString() ?? undefined,
          eventId: clinicEvent.calendarEventId ?? undefined,
          participants: clinicEvent.visitParticipantsId,
        }}
      />
    </>
  );
};
