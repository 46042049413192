import { Card } from 'antd';
import { CardTextComponent } from '../../../../uiComponent/CardTypographyComponent/CardTextComponent/CardTextComponent';
import { LabResultA1CTableContainer } from '../../container/LabResultTableContainer/LabResultA1CTableContainer';
import { TranscribeFormContainer } from '../TranscribeA1CFormComponent/TranscribeFormContainer';
import { Transcribe } from '../../../../uc-api-sdk';

export interface TranscribeA1CDrawerComponentProps {
  transcribeData: Transcribe;
  onValidSubmit?: () => void;
}

export const TranscribeA1CDrawerComponent = ({
  transcribeData,
  onValidSubmit,
}: TranscribeA1CDrawerComponentProps) => {
  const id = transcribeData?.id || '';
  const patientId = transcribeData?.patientId || '';

  return (
    <>
      <Card className="transcribe-a1c-form my30">
        <TranscribeFormContainer
          patientId={patientId}
          transcribingId={id}
          initialNotes={transcribeData.notes || []}
          initialStatus={transcribeData.status}
          onValidSubmit={onValidSubmit}
        />
      </Card>

      <CardTextComponent bold>
        History
      </CardTextComponent>

      <div className="mt10">
        <LabResultA1CTableContainer
          patientId={patientId}
        />
      </div>
    </>
  );
};
