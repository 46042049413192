import { TableCacheNameEnum } from '../../../../hooks/useTableChange/useTableChange';
import { PatientListTypeEnum } from '../../../../uc-api-sdk';
import { UnenrolledPatientsTableComponent } from '../../component/UnenrolledPatientsTableComponent/UnenrolledPatientsTableComponent';
import { PatientsTableContainer } from '../PatientsTableContainer/PatientsTableContainer';

export interface UnenrolledPatientsTableContainerProps {

}

export const UnenrolledPatientsTableContainer = () => (
  <PatientsTableContainer
    type={PatientListTypeEnum.UNENROLLED}
    name={TableCacheNameEnum.UnenrolledPatients}
  >
    {({
      totalSize,
      pagination,
      dataSource,
      onChange,
      filters,
      sorters,
      isLoading,
      exportData
    }) => (
      <UnenrolledPatientsTableComponent
        totalSize={totalSize}
        pagination={pagination}
        dataSource={dataSource}
        onChange={onChange}
        filters={filters}
        sorters={sorters}
        loading={isLoading}
        exportData={exportData}
      />
    )}
  </PatientsTableContainer>
);
