import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import './Title.scss';

export interface SubTitleProps {
  children: ReactNode;
  className?: string;
}

export const SubTitle: FC<SubTitleProps> = ({
  children,
  className,
}) => (
  <div className={classNames({ 'sub-title': true, [className || '']: true })}>
    {children}
  </div>
);
