import { EditOutlined } from '@ant-design/icons';
import { VisitTypeEnumComponent } from '../../../../enumComponent/VisitTypeEnumComponent/VisitTypeEnumComponent';
import { VisitTypeEnum } from '../../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import './OutstandingVisitCardHeaderComponent.scss';

export interface OutstandingVisitCardHeaderComponentProps{
  visitType: VisitTypeEnum | undefined;
  visitTime: string | undefined;
  onEdit?: () => void;
}

export const OutstandingVisitCardHeaderComponent = ({
  visitType,
  visitTime,
  onEdit,
}: OutstandingVisitCardHeaderComponentProps) => (
  <div className="outstanding-visit-header-component jc-sb">
    <div className="b7">
      <VisitTypeEnumComponent value={visitType} />
      <div className="edit">
        {onEdit && <EditOutlined onClick={onEdit} />}
      </div>
    </div>
    <div className="visitMethod-date">
      <span className="display-date-component">
        <DisplayDateComponent value={visitTime} format="CLINIC_EVENT_DATE_FORMAT" useNowWhenEmpty={false} />
      </span>
    </div>
  </div>
);
