import { DatePicker } from 'antd';
import { PickerDateProps } from 'antd/lib/date-picker/generatePicker';
import moment, { Moment } from 'moment';
import { FC } from 'react';
import { USA_DATE } from '../../../constants/timeFormat';

export interface PatientDobInputComponentProps extends PickerDateProps<Moment> { }

export const PatientDobInputComponent: FC<PatientDobInputComponentProps> = ({
  value,
  onChange,
  placeholder,
}) => (
  <DatePicker
    placeholder={placeholder}
    value={value}
    format={USA_DATE}
    onChange={onChange}
    className="w100"
    disabledDate={(current) => current && current > moment().endOf('day')}
  />
);
