import { BucketsTypeEnum, useFileManagerDownloadUrl } from '../../uc-api-sdk';
import { LoadingOverlayComponent } from '../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { PDFViewerComponent } from '../../uiComponent/PDFViewerComponent/PDFViewerComponent';
import { useDownloadFile } from '../../hooks/ajaxRequest/useDownloadFile/useDownloadFile';

export interface PDFViewerContainerProps {
  fileKey?: string;
  bucket?: BucketsTypeEnum,
  sendOnMount?: boolean;
  retry?: number;
  height?: number | string;
}

export const PDFViewerContainer = ({
  fileKey,
  bucket = BucketsTypeEnum.FILESUPLOAD,
  sendOnMount = true,
  retry = 0,
  height = '100%',
}: PDFViewerContainerProps) => {
  const fileDownloadInfo = useFileManagerDownloadUrl({
    params: {
      fileDownloadUrlInput: {
        bucket,
        fileKey,
      },
    },
    options: {
      sendOnMount,
      retry,
    },
  });

  const {
    isDownloading,
    downloadedFileUrl,
  } = useDownloadFile({
    downloadUrl: fileDownloadInfo.data?.data?.url,
    fileType: 'pdf',
  });

  return (
    <LoadingOverlayComponent
      isLoading={isDownloading}
    >
      <PDFViewerComponent
        fileUrl={downloadedFileUrl}
        height={height}
      />
    </LoadingOverlayComponent>
  );
};
