import { useCallback, useEffect } from 'react';
import { TimeTrackingEventEnum } from '../../../../uc-api-sdk';
import { useBillableEventTracker, BillableEventOptions } from './useBillableEventTracker';
import { useOnActiveStatusChange } from '../../../../hooks/useOnActiveStatusChange/useOnActiveStatusChange';

export enum BillableComponentEnum {
  PatientProfile = 'Patient Data Review',
  Task = 'Task',
  Transcribing = 'Transcribing'
}

export interface BillableEventCompOptions extends Omit<BillableEventOptions, 'eventType' | 'component'> {
  comp: BillableComponentEnum | undefined;
}

export const useBillableEventTrackerComp = (options: BillableEventCompOptions) => {
  const { sendTimeTracking } = useBillableEventTracker();

  const send = useCallback((eventType: TimeTrackingEventEnum) => {
    if (options.patientId) {
      sendTimeTracking({
        eventType,
        patientId: options.patientId,
        component: options.comp,
      });
    }
  }, [options, sendTimeTracking]);

  useEffect(() => {
    send(TimeTrackingEventEnum.COMPONENT_MOUNT);
    return () => send(TimeTrackingEventEnum.COMPONENT_UNMOUNT);
  }, [options.patientId, options.comp]);

  const handleActiveStatusChange = useCallback((isActive: boolean) => {
    send(isActive ? TimeTrackingEventEnum.ACTIVE : TimeTrackingEventEnum.IDLE);
  }, [send]);

  useOnActiveStatusChange(handleActiveStatusChange);
};
