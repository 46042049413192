import { ReactNode } from 'react';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { ConsentService } from '../../../../services/ConsentService';
import { usePatientGetConsentForm } from '../../../../uc-api-sdk';
import { useDeepCompareMemo } from '../../../../hooks/useDeepCompareEffect';

export interface ConsentFormFetchContainerChildrenProps {
  consentService?: ConsentService;
  isLoading: boolean;
}

export interface ConsentFormFetchContainerProps {
  patientId: string;
  patientInfo?: PatientInfo;
  children: (props: ConsentFormFetchContainerChildrenProps) => ReactNode;
  fetchByContainer?: boolean;
}

export const ConsentFormFetchContainer = ({
  patientId,
  patientInfo,
  children,
  fetchByContainer,
}: ConsentFormFetchContainerProps) => {
  const consentInfo = usePatientGetConsentForm({
    options: { sendOnMount: fetchByContainer },
    params: { memberId: patientId },
  });

  const isLoading = (
    (fetchByContainer && (consentInfo.data?.data === undefined || consentInfo.isLoading))
    || !!patientInfo?.isLoadingObj?.consentLoading
  );

  const consentService = useDeepCompareMemo(() => (
    fetchByContainer
      ? new ConsentService(consentInfo.data?.data ?? undefined)
      : patientInfo?.consentService
  ), [
    patientInfo?.consentService,
    fetchByContainer,
    consentInfo.data?.data,
  ]);

  return (
    <>
      {
        children({
          consentService,
          isLoading,
        })
      }
    </>
  );
};
