import './EditSelectCoverRangeDrawerComopnent.scss';
import { SelectCoverRangeContainer } from '../../container/SelectCoverRangeContainer/SelectCoverRangeContainer';
import { OnCallDrawerComponent } from '../../../oncall/component/OnCallDrawerComponent/OnCallDrawerComponent';

export interface EditSelectCoverRangeDrawerComponentProps {
  open?: boolean;
  onClose?: () => void;
}
export const EditSelectCoverRangeDrawerComponent = ({
  open,
  onClose,
}: EditSelectCoverRangeDrawerComponentProps) => (
  <OnCallDrawerComponent open={open} onClose={onClose}>
    <SelectCoverRangeContainer onClose={onClose} />
  </OnCallDrawerComponent>
);
