import React, { FC } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { VitalMonitoringRemoveButtonContainer } from '../../../container/VitalMonitoringRemoveButtonContainer/VitalMonitoringRemoveButtonContainer';
import { VitalType } from '../../../helper/VitalHelper';

export interface VitalMonitoringLabelComponentProps {
  vitalType: VitalType;
  name: string;
  isNew: boolean;
  canRemove: () => boolean;
  onRemove: () => void;
}

export const VitalMonitoringLabelComponent: FC<VitalMonitoringLabelComponentProps> = ({
  vitalType,
  name,
  isNew,
  canRemove,
  onRemove,
}) => {
  const handleSoftRemove = () => {
    if (canRemove()) {
      onRemove();
    }
  };

  return (
    <div className="vitals-monitoring-label flex jc-sb ai-c bold">
      <div>{name}</div>
      {isNew
        ? <CloseOutlined onClick={handleSoftRemove} />
        : (
          <VitalMonitoringRemoveButtonContainer
            vitalType={vitalType}
            onRemove={onRemove}
            canRemove={canRemove}
          />
        )}
    </div>
  );
};
