import classNames from 'classnames';
import React, { ReactNode } from 'react';

export interface FixedChildComponentProps {
  children: ReactNode;
  isFixed?: boolean;
  className?: string;
}

export const FixedChildComponent = ({
  children,
  isFixed,
  className = '',
}: FixedChildComponentProps) => (
  <div
    className={classNames({
      [className]: !!className,
      fixedChildComponent: true,
      scrollable: !isFixed,
      fixed: isFixed,
    })}
  >
    <div className="fixedChildComponentWrapper">
      {children}
    </div>
  </div>
);
