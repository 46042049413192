import { map } from 'lodash';
import { useEffect } from 'react';
import { Checkbox } from 'antd';
import { CheckboxGroupProps, CheckboxValueType } from 'antd/lib/checkbox/Group';
import { AlertItemComponent } from './AlertItemComponent';
import { InputTypeArray } from '../../../Input/types';
import { ComplianceAlert } from '../../../../uc-api-sdk';

export interface AlertCheckboxGroupComponentProps extends InputTypeArray<ComplianceAlert> {
  data?: ComplianceAlert[];
}

export const AlertCheckboxGroupComponent = ({
  value,
  data,
  onChange,
}: AlertCheckboxGroupComponentProps) => {
  const handleOnChange: CheckboxGroupProps['onChange'] = (checkedValue) => {
    onChange?.(checkedValue as unknown as ComplianceAlert[]);
  };

  useEffect(() => {
    onChange?.(data as ComplianceAlert[]);
  }, []);

  return (
    <Checkbox.Group
      value={value as unknown as CheckboxValueType[]}
      onChange={handleOnChange}
    >
      {
        map(data, (k, index) => (
          <AlertItemComponent
            key={index}
            data={k}
            showCheckbox
          />
        ))
      }
    </Checkbox.Group>
  );
};
