import { EnrollmentDateSegmentEnumComponent } from '../../../enumComponent/EnrollmentDateSegmentEnumComponent/EnrollmentDateSegmentEnumComponent';
import { EnrollmentDateSegmentEnum } from '../../../uc-api-sdk';
import { RadioFilterComponent } from '../component/RadioFilterComponent/RadioFilterComponent';
import { TableFilterType } from '../type/filter';
import { FilterIcon } from './FilterIcon';

export const createPatientEnrollmentDateFilter = (): TableFilterType => {
  const options = [
    EnrollmentDateSegmentEnum.ONE_WEEK,
    EnrollmentDateSegmentEnum.TWO_WEEKS,
    EnrollmentDateSegmentEnum.ONE_MONTH,
    EnrollmentDateSegmentEnum.THREE_MONTHS,
  ].map((p) => ({
    label: (
      <EnrollmentDateSegmentEnumComponent
        value={p as EnrollmentDateSegmentEnum}
      />
    ),
    value: p,
  }));
  return ({
    filterDropdown: ((props) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <RadioFilterComponent {...props} filterOptions={options} />
    )),
    filterIcon: FilterIcon,
  });
};
