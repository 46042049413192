import React, { FC } from 'react';
import { PopoverButton, PopoverButtonProps } from '../../../../../uiComponent/PopoverButton/PopoverButton';
import { VitalsSelectionComponent, VitalsSelectionComponentProps } from './VitalsSelectionComponent';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface VitalsSelectionPopupButtonProps extends VitalsSelectionComponentProps {

}

export const VitalsSelectionPopupButton: FC<VitalsSelectionPopupButtonProps> = ({
  onCancel,
  ...props
}) => {
  const renderContent: PopoverButtonProps['content'] = (close) => (
    <VitalsSelectionComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onCancel={() => {
        onCancel?.();
        close?.();
      }}
    />
  );

  return (
    <PopoverButton
      content={renderContent}
      title="Choose a vital you want to add"
      destroyPopoverOnHide
    >
      Add Vitals
    </PopoverButton>
  );
};
