import dayjs from 'dayjs';
import { map } from 'lodash';
import { BillableSegmentSourceEnum, useBillableTimeSegmentInsert } from '../../../uc-api-sdk';
import { SubmitValue } from '../../../uiComponent/OfflineTimeComponent/AddOfflineTimeFormComponent/AddOfflineTimeFormComponent';
import { ActivityTypeEnum } from '../../../uiComponent/OfflineTimeComponent/type';

export const useBillableTimeSegmentInsertReq = (memberId: string) => {
  const insertHook = useBillableTimeSegmentInsert({});

  const send = async (formValue: SubmitValue) => {
    const activities = map(formValue.offlineActivities, (it) => {
      if (it === ActivityTypeEnum.OTHER && formValue.otherActivity) {
        return formValue.otherActivity;
      }
      return it;
    });

    const req = insertHook.send({
      params: {
        param: {
          memberId: memberId || '',
          source: BillableSegmentSourceEnum.MANUAL,
          monthOfYear: parseInt(dayjs(formValue.dateOfService).format('YYYYMM'), 10),
          totalTime: formValue.timeSpent,
          startTime: dayjs(formValue.dateOfService).valueOf(),
          activity: activities,
        }
      },
    });
    return req;
  };

  return {
    send,
    insertHook,
  };
};
