import { ColumnType } from 'antd/es/table';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { MEDICATION_DATE_FORMAT } from '../../constants/timeFormat/index';
import { MedicationType as Medication } from '../../features/medication/type/type';
import { MED_TYPES } from '../../selectComponents/MedicationTypeSelectComponent/constants';
import { MED_STATUS } from '../../uiComponent/Medication/constants';
import { MedicationNameRendererComponent } from './MedicationNameRendererComponent/MedicationNameRendererComponent';
import { MedicationTableSettingsComponent } from './MedicationTableSettingsComponent/MedicationTableSettingsComponent';

type dateType = Moment | string | null | undefined;

const dateSorter = (a: dateType, b: dateType) => {
  if (a && b) {
    return moment(a).unix() - moment(b).unix();
  } if (!a) {
    return -1;
  }
  return 1;
};

export const createMedicationColumns = {
  name: (): ColumnType<Medication> => ({
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '15%',
    sorter: (a, b) => String(a.name)?.localeCompare(String(b.name)),
    render: (a, b) => (
      <MedicationNameRendererComponent
        name={b.name || ''}
        otherMD={b.otherMD || false}
      />
    ),
  }),
  type: (): ColumnType<Medication> => ({
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: '15%',
    filters: MED_TYPES,
    onFilter: (value, record) => record.type?.toLowerCase()
      === String(value).toLowerCase(),
    render: (v) => _.startCase(_.toLower(String(v))),
  }),
  status: (): ColumnType<Medication> => ({
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '15%',
    filters: MED_STATUS,
    onFilter: (value, record) => record.status?.toLowerCase()
      === String(value).toLowerCase(),
    render: (v) => (v ? _.startCase(_.toLower(String(v).replace('_', ' '))) : null),
  }),
  dosage: (): ColumnType<Medication> => ({
    title: 'Dosage',
    dataIndex: 'dosage',
    width: '15%',
    key: 'dosage',
  }),
  startDate: (): ColumnType<Medication> => ({
    title: 'Start Date',
    dataIndex: 'startDate',
    key: 'startDate',
    width: '15%',
    sorter: (a, b) => dateSorter(a.startDate, b.startDate),
    render: (v) => (v ? moment(v).format(MEDICATION_DATE_FORMAT) : ''),
  }),
  endDate: (): ColumnType<Medication> => ({
    title: 'End Date',
    dataIndex: 'endDate',
    key: 'endDate',
    width: '15%',
    sorter: (a, b) => dateSorter(a.endDate, b.endDate),
    render: (v) => (v ? moment(v).format(MEDICATION_DATE_FORMAT) : ''),
  }),
  instruction: (): ColumnType<Medication> => ({
    title: 'Instruction',
    dataIndex: 'instruction',
    key: 'instruction',
    width: '20%',
  }),
  settings: (): ColumnType<Medication> => ({
    title: '',
    key: 'settings',
    dataIndex: 'id',
    width: '5%',
    render: (v, record) => (
      <MedicationTableSettingsComponent medId={v} record={record} />
    ),
  }),
};
