import { Button, Form, Switch } from 'antd';
import { FormType } from '../../../Input/types';
import { useAssignDeviceInfoForm } from '../../hook/useAssignDeviceInfoForm/useAssignDeviceInfoForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { Title } from '../../../../uiComponent/Title/Title';
import { DeviceModelEnum } from '../../../../uc-api-sdk';
import { DeviceMonitorMethodEnum } from '../../../../enumComponent/DeviceMonitorMethodEnumComponent/DeviceMonitorMethodEnumComponent';
import { DeviceSearchSelectContainer } from '../../../device/container/DeviceSearchSelectContainer/DeviceSearchSelectContainer';
import { DeviceModelEnumComponent } from '../../../../enumComponent/DeviceModelEnumComponent/DeviceModelEnumComponent';
import ClearDeviceLanguageSelectComponent from '../../../../selectComponents/LanguageSelectComponent/ClearDeviceLanguageSelectComponent';
import LANGUAGES_MAP from '../../../../selectComponents/LanguageSelectComponent/LanguagesMap';

export interface SubmitValue {
  deviceId: string;
  deviceModel?: DeviceModelEnum;
  deviceMethod?: DeviceMonitorMethodEnum;
  deviceLanguage?: string;
  bpReadOut?: boolean;
}

export interface ClearDeviceAssignFormComponentProps extends FormType<SubmitValue> {}

export const ClearDeviceAssignFormComponent = ({
  initialValues,
  onSubmit,
}: ClearDeviceAssignFormComponentProps) => {
  const deviceInfoForm = useAssignDeviceInfoForm();

  return (
    <Form
      initialValues={initialValues}
      onFinish={deviceInfoForm.handleSubmitAndReset(onSubmit)}
      layout="vertical"
      className="my20"
    >
      <Title>
        Assign Patient
        {' '}
        <DeviceModelEnumComponent value={DeviceModelEnum.BPM1} />
        {' '}
        Device
      </Title>
      <Title.SubTitle>
        To assign device, please confirm the device
        ID and patient's language below.
      </Title.SubTitle>
      <FormItem
        info={deviceInfoForm.getInfo('deviceId')}
        required
      >
        <DeviceSearchSelectContainer
          deviceModel={DeviceModelEnum.BPM1}
        />
      </FormItem>
      <FormItem
        info={deviceInfoForm.getInfo('deviceLanguage')}
        initialValue={LANGUAGES_MAP.EL.value}
      >
        <ClearDeviceLanguageSelectComponent />
      </FormItem>
      <FormItem
        info={deviceInfoForm.getInfo('bpReadOut')}
        valuePropName="checked"
      >
        <Switch />
      </FormItem>
      <Button
        type="primary"
        htmlType="submit"
        className="mt40"
      >
        Assign Device
      </Button>
    </Form>
  );
};
