import {
  Button,
  Col, Form, Input, Modal, Row
} from 'antd';
import { Moment } from 'moment';
import { FC, useState } from 'react';
import dayjs from 'dayjs';
import { some } from 'lodash';
import useBoolean from '../../hooks/useBoolean/useBoolean';
import { PatientSpokenLanguageSelectComponent } from '../../selectComponents/PatientSpokenLanguageSelectComponent/PatientSpokenLanguageSelectComponent';
import { GenderEnum, Patient } from '../../uc-api-sdk';
import { OTPSwitchContainer } from '../../uiContainer/OTPSwitchContainer/OTPSwitchContainer';
import CancelSubmitButtonsComponent from '../CancelSubmitButtonsComponent/CancelSubmitButtonsComponent';
import { ClinicSelectComponent } from '../ClinicSelectComponent/ClinicSelectComponent';
import EmailInputFormItemComponent from '../EmailInputComponent/EmailInputFormItemComponent';
import FormItem from '../FormItem/FormItem';
import { useScreeningForm } from '../PatientBasicInfo/hook/useScreeningForm';
import { IsTestPatientInputComponent } from '../PatientBasicInfo/IsTestPatientInputComponent/IsTestPatientInputComponent';
import { PatientDobInputComponent } from '../PatientBasicInfo/PatientDobInputComponent/PatientDobInputComponent';
import { PatientFirstNameInputComponent } from '../PatientBasicInfo/PatientFirstNameInputComponent/PatientFirstNameInputComponent';
import { PatientGenderInputComponent } from '../PatientBasicInfo/PatientGenderInputComponent/PatientGenderInputComponent';
import { PatientLastNameInputComponent } from '../PatientBasicInfo/PatientLastNameInputComponent/PatientLastNameFormItemComponent';
import { PatientRaceInputComponent } from '../PatientBasicInfo/PatientRaceInputComponent/PatientRaceInputComponent';
import { PatientUsernameInputContainer } from '../PatientUsernameInputContainer/PatientUsernameInputContainer';
import PhoneInput from '../PhoneInput/PhoneInput';
import { SectionTitleComponent } from '../SectionTitleComponent/SectionTitleComponent';
import TooltipComponent from '../TooltipComponent/TooltipComponent';
import { MedicalIdInputComponent } from '../Transcribing/component/MedicalIdInputComponent/MedicalIdInputComponent';
import { ProviderSelectContainer } from '../Transcribing/component/ProviderSelectContainer/ProviderSelectContainer';
import { PatientCreateInsuranceComponent, PatientCreateInsuranceComponentProps } from './PatientCreateInsuranceComponent';
import { InsuranceCardSubmitValue } from '../../hooks/formHook/useInsuranceForm';

import './PatientCreateFormComponent.scss';

export interface PatientFormValues {
  firstName: string;
  lastName: string;
  birthday: Moment;
  gender?: GenderEnum;
  clinicId: string;
  doctorId: string;
  phone1?: string;
  phone2?: string;
  email?: string;
  race?: string;
  spokenLanguage?: string[];
  medicalRecordId?: string;
  nickName?: string;
  userId: string;
  canUseForLogin?: boolean;
  insuranceList?: InsuranceCardSubmitValue[];
  inquiryIdList?: string[];
}
export interface PatientCreateFormComponentProps {
  onSubmit?: (values: PatientFormValues) => void;
  onCancel?: () => void;
  isLoading?: boolean;
}

export interface UserIdProps {
  firstName: string | undefined;
  lastName: string | undefined;
  birthday: Moment | undefined;
}

export const PatientCreateFormComponent: FC<PatientCreateFormComponentProps> = ({
  onSubmit,
  onCancel,
  isLoading
}) => {
  const hook = useScreeningForm({});
  const { form } = hook;
  const [shouldValidate, setShouldUpdate] = useState(0);
  const [inquiryIdList, setInquiryIdList] = useState<string[]>([]);
  const initialValues = {
    canUseForLogin: false
  };

  const autoSetUsername = (fn?: string, ln?: string) => {
    const firstName = fn || form.getFieldValue('firstName');
    const lastName = ln || form.getFieldValue('lastName');
    const newId = `${firstName?.replaceAll(' ', '') || ''}${lastName?.replaceAll(' ', '') || ''}`.toLocaleLowerCase();
    form.setFieldsValue({ loginId: newId });
    setShouldUpdate(shouldValidate + 1);
  };

  const onValuesChange = (changedValues: object) => {
    if ('firstName' in changedValues || 'lastName' in changedValues) {
      autoSetUsername();
    }
  };

  const {
    value: isValid,
    setTrue: setValid,
    setFalse: setInvalid
  } = useBoolean(false);

  const handleOnClinicChange = () => {
    form.setFieldsValue({ doctorId: undefined });
  };

  const handleOnChangeInsurance: PatientCreateInsuranceComponentProps['onChange'] = (
    value
  ) => {
    const {
      firstName,
      lastName,
      wayStarInquiryId,
    } = value.eligibilityValue;
    form.setFieldsValue({
      firstName,
      lastName,
      birthday: dayjs(value.eligibilityValue.birthday),
      insuranceList: value.insuranceList,
    });
    autoSetUsername(firstName, lastName);
    // save all eligibility inquiry ids for mapping to created patient id later
    if (wayStarInquiryId) {
      setInquiryIdList((prev) => ([
        ...prev,
        wayStarInquiryId,
      ]));
    }
  };

  const handleSubmit = (values: PatientFormValues) => {
    const {
      insuranceList
    } = hook.form.getFieldsValue(true);
    const submitValue = {
      ...values,
      insuranceList,
      inquiryIdList,
    } as PatientFormValues;
    onSubmit?.(submitValue);
  };

  const handleCancel = () => {
    const insuranceList = (
      hook.getValue('insuranceList', hook.form.getFieldValue) as InsuranceCardSubmitValue[]
    );
    const hasVerifiedInsurance = some(
      insuranceList,
      (insurance) => insurance.wayStarInquiryId
    );
    if (hasVerifiedInsurance) {
      const container = document.querySelector('.create-patient-form') as HTMLElement;
      setTimeout(() => {
        const modalInstance = Modal.confirm({
          centered: true,
          content: (
            <div className="flex fd-c jc-c">
              <div>
                Are you sure to cancel creation?
                The insurance and other information entered will be lost.
              </div>
              <div className="flex jc-sb mt20">
                <Button
                  onClick={() => {
                    modalInstance.destroy();
                  }}
                >
                  Back
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    modalInstance.destroy();
                    onCancel?.();
                  }}
                >
                  Cancel Creation
                </Button>
              </div>
            </div>
          ),
          className: 'patient-create-cancel__insurance-prompt',
          getContainer: container,
        });
      }, 100);
      return;
    }
    onCancel?.();
  };

  return (
    <Form
      className="flex gap3 fd-c create-patient-form"
      form={hook.form}
      layout="vertical"
      scrollToFirstError
      initialValues={initialValues}
      onValuesChange={onValuesChange}
      onFinish={hook.handleSubmitAndReset(handleSubmit)}
      disabled={isLoading}
    >
      <div className="mt10">
        <SectionTitleComponent title="Set up basic information" />
        <Row gutter={9} className="mt10">
          <Col span={12}>
            <FormItem
              info={hook.getInfo('firstName')}
              required
            >
              <PatientFirstNameInputComponent />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              info={hook.getInfo('lastName')}
              required
            >
              <PatientLastNameInputComponent />
            </FormItem>
          </Col>
        </Row>
        <FormItem
          info={hook.getInfo('gender')}
          tooltipOnLabel={(
            <TooltipComponent
              type={hook.formInput.gender.tooltip.type}
              title={hook.getTooltip('gender')?.tip}
            >
              {hook.getInfo('gender').label}
            </TooltipComponent>
          )}
          required
        >
          <PatientGenderInputComponent />
        </FormItem>
        <FormItem
          info={hook.getInfo('birthday')}
          required
        >
          <PatientDobInputComponent />
        </FormItem>
        <Row gutter={9}>
          <Col span={12}>
            <FormItem
              info={hook.getInfo('spokenLanguage')}
            >
              <PatientSpokenLanguageSelectComponent />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              info={hook.getInfo('race')}
            >
              <PatientRaceInputComponent />
            </FormItem>
          </Col>
        </Row>
        <FormItem
          info={hook.getInfo('medicalRecordId')}
        >
          <MedicalIdInputComponent />
        </FormItem>
        <FormItem
          info={hook.getInfo('nickName')}
        >
          <Input />
        </FormItem>
        <FormItem
          info={hook.getInfo('clinicId')}
          required
        >
          <ClinicSelectComponent
            onChange={handleOnClinicChange}
          />
        </FormItem>
        <FormItem
          dependencies={[hook.getName('clinicId')]}
        >
          {({ getFieldValue }) => {
            const clinicId = hook.getValue('clinicId', getFieldValue);
            return (
              <FormItem
                info={hook.getInfo('doctorId')}
                required
              >
                <ProviderSelectContainer
                  clinicIds={clinicId ? [clinicId] : undefined}
                  showSearch
                />
              </FormItem>
            );
          }}
        </FormItem>
      </div>
      <div className="mt10">
        <SectionTitleComponent title="Insurance and Eligibility" />
        <div className="mt10">
          <FormItem
            noStyle
            shouldUpdate={hook.shouldUpdate(['firstName', 'lastName', 'birthday'])}
          >
            {
              ({ getFieldValue }) => {
                const patient = {
                  profile: {
                    firstName: hook.getValue('firstName', getFieldValue),
                    lastName: hook.getValue('lastName', getFieldValue),
                    birthday: hook.getValue('birthday', getFieldValue)
                  }
                } as Patient;
                return (
                  <PatientCreateInsuranceComponent
                    patient={patient}
                    onChange={handleOnChangeInsurance}
                  />
                );
              }
            }
          </FormItem>
        </div>
      </div>
      <div className="mt20">
        <SectionTitleComponent title="Login Information" />
        <FormItem
          className="mt10"
          info={hook.getInfo('loginId')}
          required
        >
          <PatientUsernameInputContainer
            form={form}
            setValid={setValid}
            setInvalid={setInvalid}
            shouldValidate={shouldValidate}
          />
        </FormItem>
      </div>
      <div className="mt20">
        <SectionTitleComponent title="Contact information" />
        <Row gutter={9} align="bottom" className="mt10">

          <Col span={17}>
            <FormItem
              name={hook.getName('phone1')}
              label="Mobile phone"
            >
              <PhoneInput prefix="+1" />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={9} align="bottom">
          <Col span={17}>
            <FormItem
              name={hook.getName('phone2')}
              label="Home phone"
            >
              <PhoneInput prefix="+1" />
            </FormItem>
          </Col>
        </Row>
        <FormItem dependencies={['phone1']}>
          {
            ({ getFieldValue }) => {
              const phone = getFieldValue('phone1');
              return (
                <FormItem
                  name={hook.getName('canUseForLogin')}
                  label=""
                >
                  <OTPSwitchContainer
                    phone={phone}
                    parentContainer=".create-patient-form"
                  />
                </FormItem>
              );
            }
          }
        </FormItem>
        <EmailInputFormItemComponent />
        <FormItem
          name={hook.getName('isTestUser')}
          valuePropName="checked"
          label={null}
        >
          <IsTestPatientInputComponent />
        </FormItem>
      </div>
      <div className="border-t my20">
        <CancelSubmitButtonsComponent
          className="jc-sb"
          submitText="Create"
          disabled={!isValid || isLoading}
          onCancel={handleCancel}
          isLoading={isLoading}
          shouldBeDisabled={() => false}
        />
      </div>
    </Form>
  );
};
