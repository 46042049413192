import dayjs, { Dayjs } from 'dayjs';
import advanced from 'dayjs/plugin/advancedFormat';
import dayjsTimezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { split, upperFirst } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import { CLINIC_TIMEZONE_FORMAT } from '../../constants/timeFormat';
import { TimezoneOffset, TimezoneType } from '../../types/timezone';

export type TimeOfDay = 'OVERNIGHT' | 'MORNING' | 'AFTERNOON' | 'EVENING';

dayjs.extend(utc);
dayjs.extend(dayjsTimezone);
dayjs.extend(advanced);
export class TimezoneService {
  static getOffset(timezone: TimezoneType) {
    const diff = moment().tz(timezone).utcOffset() / 60;
    const sign = diff < 0 ? '' : '+';
    return `${sign}${diff}:00`;
  }

  static timezoneToOffset(timezone: TimezoneType) {
    switch (timezone) {
      case TimezoneType.CT: return TimezoneOffset.CT;
      case TimezoneType.ET: return TimezoneOffset.ET;
      case TimezoneType.MT: return TimezoneOffset.MT;
      case TimezoneType.AT: return TimezoneOffset.AT;
      case TimezoneType.PT: return TimezoneOffset.PT;
      case TimezoneType.AK: return TimezoneOffset.AK;
      case TimezoneType.GT: return TimezoneOffset.GT;
      case TimezoneType.HT: return TimezoneOffset.HT;
      case TimezoneType.PhilippineT: return TimezoneOffset.PhilippineT;
      default: return null;
    }
  }

  static getTimezoneText(timezone: TimezoneType | TimezoneOffset) {
    switch (timezone) {
      case TimezoneOffset.ET:
      case TimezoneType.ET:
        return `(GMT${TimezoneService.getOffset(TimezoneType.ET)}) Eastern Standard Time`;
      case TimezoneOffset.CT:
      case TimezoneType.CT:
        return `(GMT${TimezoneService.getOffset(TimezoneType.CT)}) Central Standard Time`;
      case TimezoneOffset.MT:
      case TimezoneType.MT:
        return `(GMT${TimezoneService.getOffset(TimezoneType.MT)}) Mountain Standard Time`;
      case TimezoneOffset.AT:
      case TimezoneType.AT:
        return `(GMT${TimezoneService.getOffset(TimezoneType.AT)}) Arizona Standard Time`;
      case TimezoneOffset.PT:
      case TimezoneType.PT:
        return `(GMT${TimezoneService.getOffset(TimezoneType.PT)}) Pacific Standard Time`;
      case TimezoneOffset.AK:
      case TimezoneType.AK:
        return `(GMT${TimezoneService.getOffset(TimezoneType.AK)}) Alaska Standard Time`;
      case TimezoneOffset.HT:
      case TimezoneType.HT:
        return `(GMT${TimezoneService.getOffset(TimezoneType.HT)}) Hawaii Standard Time`;
      case TimezoneOffset.GT:
      case TimezoneType.GT:
        return `(GMT${TimezoneService.getOffset(TimezoneType.GT)}) Guam Standard Time`;
      case TimezoneOffset.PhilippineT:
      case TimezoneType.PhilippineT:
        return `(GMT${TimezoneService.getOffset(TimezoneType.PhilippineT)}) Philippine Standard Time`;
      default: return null;
    }
  }

  static getTimezoneName(timezone: TimezoneType | TimezoneOffset) {
    switch (timezone) {
      case TimezoneOffset.ET:
      case TimezoneType.ET:
        return 'Eastern Standard Time';
      case TimezoneOffset.CT:
      case TimezoneType.CT:
        return 'Central Standard Time';
      case TimezoneOffset.MT:
      case TimezoneType.MT:
        return 'Mountain Standard Time';
      case TimezoneOffset.AT:
      case TimezoneType.AT:
        return 'Arizona Standard Time';
      case TimezoneOffset.PT:
      case TimezoneType.PT:
        return 'Pacific Standard Time';
      case TimezoneOffset.AK:
      case TimezoneType.AK:
        return 'Alaska Standard Time';
      case TimezoneOffset.GT:
      case TimezoneType.GT:
        return 'Guam Standard Time';
      case TimezoneOffset.HT:
      case TimezoneType.HT:
        return 'Hawaii Standard Time';
      case TimezoneOffset.PhilippineT:
      case TimezoneType.PhilippineT:
        return 'Philippine Standard Time';
      default: return null;
    }
  }

  static calcDateTime(
    dateTime: number | Moment | Date | string | Dayjs,
    timezone?: string,
  ) {
    let dateTimeString: string | number | Date;
    if (typeof dateTime === 'string') {
      if (!dateTime.endsWith('z') && !dateTime.endsWith('Z')) {
        dateTimeString = `${dateTime}Z`;
      } else {
        dateTimeString = dateTime;
      }
    } else if ((dateTime as Moment | Dayjs).toDate) {
      dateTimeString = (dateTime as Moment | Dayjs).toDate();
    } else {
      dateTimeString = dateTime as number;
    }
    const response = moment(dateTimeString);
    if (timezone) {
      response.tz(timezone);
    }
    return response;
  }

  static calcDateTimeDayjs(
    dateTime: number | Moment | Date | string | Dayjs,
    timezone?: string,
  ) {
    const dt = dayjs(this.calcDateTime(dateTime, timezone)?.toDate());
    if (timezone) {
      return dt.tz(timezone);
    }
    return dt;
  }

  static getTimeOfDay(
    date: number | string | Moment | Date,
    timezone: string,
  ): TimeOfDay {
    const hourMinute = TimezoneService.calcDateTimeDayjs(date, timezone).format('H:m');
    const [hour, minute] = split(hourMinute, ':');
    const hourNum = +hour;
    const minuteNum = +minute;
    const checkHour = (h: number) => (
      hourNum < h
      || (hourNum === h && minuteNum === 0) // include hour
    );
    if (checkHour(4)) {
      return 'OVERNIGHT';
    }
    if (checkHour(12)) {
      return 'MORNING';
    }
    if (checkHour(18)) {
      return 'AFTERNOON';
    }
    // if (hour <= 23)
    return 'EVENING';
  }

  public static getTimeOfDayText(value: TimeOfDay) {
    return upperFirst(value.toLocaleLowerCase());
  }

  public static guessTimezone() {
    return dayjs.tz.guess();
  }

  public static getUTC(value: Moment | Date | Dayjs) {
    return value.toISOString().replace('Z', '');
  }

  public static convertTimeZone(tz?: string) {
    const now = dayjs();
    return tz ? `${tz} ${now.tz(tz).format(CLINIC_TIMEZONE_FORMAT)}` : undefined;
  }
}

export default TimezoneService;
