import { Table } from 'antd';
import classNames from 'classnames';
import { cloneDeep, isEqualWith } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTableColumns } from '../../../../../../hooks/table/useTableColumns/useTableColumns';
import { CellClick, vitalsMonitoringBGColumns } from './vitalsMonitoringBGColumns';
import './VitalsMonitoringBGTableComponent.scss';
import { InputTypeArrayRequired } from '../../../../../Input/types';
import { BgMealTypeEnum } from '../../../../../../uc-api-sdk';
import '../../../vitalsTable.scss';
import '../../../BGTableComponent/BGTableComponent.scss';
import { useVitalsMonitoringScheduleForm } from '../../../../hook/useVitalsMonitoringScheduleForm/useVitalsMonitoringScheduleForm';

export enum DayOfWeekEnum {
  Sunday = 1,
  Monday = 2,
  Tuesday = 4,
  Wednesday = 8,
  Thursday = 16,
  Friday = 32,
  Saturday = 64,
}

export interface BeforeAfterMeal {
  beforeMeal: boolean;
  afterMeal: boolean;
}

export interface VitalsMonitoringBGTableDayData {
  [BgMealTypeEnum.BREAKFAST]: BeforeAfterMeal;
  [BgMealTypeEnum.LUNCH]: BeforeAfterMeal;
  [BgMealTypeEnum.DINNER]: BeforeAfterMeal;
  [BgMealTypeEnum.BEDTIME]: boolean;
  [BgMealTypeEnum.OVERNIGHT]: boolean;
  [BgMealTypeEnum.SNACK]: unknown;
  [BgMealTypeEnum.OTHER]: unknown
}

export interface VitalsMonitoringBGTableData {
  day: DayOfWeekEnum;
  dayName: string;
  data: VitalsMonitoringBGTableDayData;
}

export interface VitalsMonitoringBGTableComponentProps extends
  InputTypeArrayRequired<VitalsMonitoringBGTableData> {
}

export const VitalsMonitoringBGTableComponent = ({
  value,
  onChange,
}: VitalsMonitoringBGTableComponentProps) => {
  const { formDisabled } = useVitalsMonitoringScheduleForm() || {};
  const [data, setData] = useState(value);

  const handleClick = useCallback<CellClick>((day, mealType, beforeMeal) => {
    if (formDisabled) return;
    setData((currValue) => {
      const newData = cloneDeep(currValue);
      const index = value.findIndex((v) => v.day === day);
      const v = newData[index].data;
      switch (mealType) {
        case BgMealTypeEnum.BEDTIME:
        case BgMealTypeEnum.OVERNIGHT:
          v[mealType] = !v[mealType];
          break;
        case BgMealTypeEnum.BREAKFAST:
        case BgMealTypeEnum.LUNCH:
        case BgMealTypeEnum.DINNER:
          if (beforeMeal) {
            v[mealType].beforeMeal = !v[mealType].beforeMeal;
          } else {
            v[mealType].afterMeal = !v[mealType].afterMeal;
          }
          break;
        default:
          break;
      }
      return newData;
    });
  }, [setData, onChange]);

  // send signal to parent if local data and prop value are different
  useEffect(() => {
    if (!isEqualWith(value, data)) {
      onChange(data);
    }
  }, [data]);

  // update the local data based on the prop
  useEffect(() => {
    if (!isEqualWith(value, data)) {
      setData(value);
    }
  }, [value, setData]);

  const columns = useTableColumns(
    vitalsMonitoringBGColumns(handleClick),
    ['day', 'breakfast', 'lunch', 'dinner', 'bedtime', 'overnight'],
  );

  return (
    <Table
      dataSource={value}
      columns={columns}
      pagination={false}
      className={classNames({
        'table-header-cell-center': true,
        'bg-logbook-table': true,
        'vital-scheduling-bg-table': true,
        'table-cell-no-padding': true,
        'vitals-bordered-table': true,
        'vitals-bordered-table-monitoring': true,
      })}
      bordered
      // each cell is 44px tall
      // we need to show 7 days at a time
      scroll={{ y: 44 * 7 }}
      rowKey={(v) => v.day}
    />
  );
};
