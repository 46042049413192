import { TableCacheNameEnum } from '../../../../hooks/useTableChange/useTableChange';
import { PatientListTypeEnum } from '../../../../uc-api-sdk';
import { EmptyWatchedListComponent } from '../../component/WatchedPatientsComponent/EmptyWatchedListComponent';
import { WatchedPatientsTableComponent } from '../../component/WatchedPatientsTableComponent/WatchedPatientsTableComponent';
import { PatientsTableContainer } from '../PatientsTableContainer/PatientsTableContainer';

export interface WatchedPatientsContainerProps {

}

export const WatchedPatientsContainer = () => (
  <PatientsTableContainer
    type={PatientListTypeEnum.ALL}
    name={TableCacheNameEnum.WatchList}
    watchlist
    needToFilter={false}
  >
    {({
      totalSize,
      pagination,
      dataSource,
      onChange,
      filters,
      sorters,
      isLoading,
      exportData,
    }) => (
      <WatchedPatientsTableComponent
        totalSize={totalSize}
        pagination={pagination}
        dataSource={dataSource}
        onChange={onChange}
        filters={filters}
        sorters={sorters}
        locale={{
          emptyText: <EmptyWatchedListComponent />
        }}
        loading={isLoading}
        exportData={exportData}
      />
    )}
  </PatientsTableContainer>
);
