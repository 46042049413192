import {
  Button, Popover,
} from 'antd';
import { ButtonType } from 'antd/lib/button';
import { useState } from 'react';
import { MpTrackingHelperProps } from '../../../../hooks/useMpTrackingHelper/useMpTrackingHelper';
import { ComplianceAlert } from '../../../../uc-api-sdk';
import { AlertSnoozeFormContainer } from '../../container/AlertContainer/AlertSnoozeFormContainer';

export interface AlertSnoozeButtonComponentProps {
 data?: ComplianceAlert[];
 type: ButtonType;
  title: string;
  onComplianceAlertEnd?: (value?: MpTrackingHelperProps['properties']) => void;
}

export const AlertSnoozeButtonComponent = ({
  data,
  type,
  title,
  onComplianceAlertEnd,
}: AlertSnoozeButtonComponentProps) => {
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Popover
      content={(
        <AlertSnoozeFormContainer
          data={data}
          hidePopOver={hide}
          onComplianceAlertEnd={onComplianceAlertEnd}
        />
      )}
      title="How long do you want to snooze?"
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Button type={type}>{title}</Button>
    </Popover>
  );
};
