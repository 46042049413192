import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import mixpanel from 'mixpanel-browser';
import { useUpdateEffect } from 'usehooks-ts';
import { v4 } from 'uuid';
import EnvConfig from '../../configs/envConfig/envConfig';
import { usePerformance } from '../../hooks/usePerformance/usePerformance';
import { useUserActive } from '../../uiHooks/useUserActive/useUserActive';
import { functionNotImplYet } from '../helper';
import { useLoggedInUserFromContext } from '../loggedInUserContext/LoggedInUserContext';
import { MixpanelDurationEvents, MixpanelEvents, MixpanelEventsParents } from './MixpanelEvents';

export interface MainSendArgs {
  event: MixpanelEvents;
  parent?: MixpanelEventsParents | null;
  patientId?: string | null;
  properties?: Record<string, unknown>;
}

export interface DurationSendArgs {
  event: MixpanelDurationEvents;
  duration: number;
  startTime?: string;
  stopTime?: string;
  patientId?: string;
}

export interface Value {
  send: (args: MainSendArgs) => void;
  sendDuration: (args: DurationSendArgs) => void;
}

const contextNotImplementedYet = functionNotImplYet('MixpanelMainContext');

export const MixpanelMainContext = React.createContext<Value>({
  send: contextNotImplementedYet('send'),
  sendDuration: contextNotImplementedYet('sendDuration'),
});

export interface MixpanelMainProviderProps {
  children: React.ReactNode;
}

export const MixpanelMainProvider = ({
  children,
}: MixpanelMainProviderProps) => {
  const { userId, userInfo } = useLoggedInUserFromContext();
  const { getAppMemoryUsage } = usePerformance();
  const isActive = useUserActive();
  const [uuid] = React.useState(v4());

  useEffect(() => {
    mixpanel.init(EnvConfig.mixPanelToken, {
      debug: true,
      track_pageview: false,
      persistence: 'localStorage',
    });
    mixpanel.identify(userId);
  }, []);

  const send = useCallback((args: MainSendArgs) => {
    const memoryUsage = getAppMemoryUsage();
    mixpanel.track(args.event, {
      ...args.properties,
      userFullName: userInfo?.fullName,
      patientId: args.patientId,
      parent: args.parent,
      memoryUsage,
    });
  }, [userInfo?.fullName]);

  const sendDuration = useCallback((args: DurationSendArgs) => {
    const { event, ...restArgs } = args;
    send({
      event: event as unknown as MixpanelEvents,
      properties: restArgs,
    });
  }, []);

  useEffect(() => {
    if (userId) {
      send({
        event: MixpanelEvents.UserSessionStart,
        properties: {
          uuid,
        },
      });
    }
    return () => {
      if (userId) {
        send({
          event: MixpanelEvents.UserSessionEnd,
          properties: {
            uuid,
          },
        });
      }
    };
  }, [userId]);

  useUpdateEffect(() => {
    if (userId) {
      if (isActive) {
        send({
          event: MixpanelEvents.UserSessionActive,
          properties: {
            uuid,
          },
        });
      } else {
        send({
          event: MixpanelEvents.UserSessionInactive,
          properties: {
            uuid,
          },
        });
      }
    }
  }, [isActive, userId]);

  const value = useMemo(() => ({
    send,
    sendDuration,
  }), [
    send,
    sendDuration,
  ]);

  return (
    <MixpanelMainContext.Provider value={value}>
      {children}
    </MixpanelMainContext.Provider>
  );
};

export const useMixpanelMainContext = () => {
  const context = useContext<Value>(MixpanelMainContext);
  return context;
};
