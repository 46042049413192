import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import { PriorAuthModalComponent } from '../PriorAuthLayout/PriorAuthModalComponent';
import { PriorAuthStartNewFormContainer } from '../../container/PriorAuthStartNewFormContainer/PriorAuthStartNewFormContainer';
import { PriorAuthCase, PriorAuthCaseStatusEnum } from '../../../../uc-api-sdk';

export interface PriorAuthActionButtonComponentProps {
  patientId: string;
  priorAuth?: PriorAuthCase;
  provider?: string;
  providerId?: string;
  policyNumber?: string;
}

// [4465] Allow to renew prior-auth case for all Approved and Denied statuses
export const PriorAuthActionButtonComponent = ({
  patientId,
  priorAuth,
  provider,
  providerId,
  policyNumber,
}: PriorAuthActionButtonComponentProps) => {
  const {
    isOpen,
    open,
    close
  } = useOpen();
  const {
    status,
  } = priorAuth || {};

  const getButtonText = () => {
    switch (status) {
      case PriorAuthCaseStatusEnum.APPROVED:
        return 'Renew';
      case PriorAuthCaseStatusEnum.DENIED:
        return 'Start a new Prior-auth';
      default:
        return null;
    }
  };

  if (
    ![
      PriorAuthCaseStatusEnum.APPROVED,
      PriorAuthCaseStatusEnum.DENIED
    ].includes(status || '' as PriorAuthCaseStatusEnum)
  ) {
    // no action for other status
    return null;
  }

  return (
    <>
      <LinkButton
        useBlueColor
        onClick={open}
      >
        {getButtonText()}
      </LinkButton>
      {
        isOpen
        && (
          <PriorAuthModalComponent
            open
            onCancel={close}
          >
            <PriorAuthStartNewFormContainer
              patientId={patientId}
              initialValues={{
                provider,
                insuranceProviderId: providerId,
                policyNumber,
              }}
              onCancel={close}
            />
          </PriorAuthModalComponent>
        )
      }
    </>
  );
};
