import { first } from 'lodash';
import { FC } from 'react';
import { baseAPIHelper } from '../../../../hooks/useTableChange/baseAPIHelper';
import { sorterHelper } from '../../../../hooks/useTableChange/sorterHelper';
import { useTableChange } from '../../../../hooks/useTableChange/useTableChange';
import {
  InFilter,
  MedicalAlert,
  MedicalAlertClass,
  PriorityLevelEnum,
  SearchRequest,
  SmartAlertTaskStatus,
  Sort$Direction,
  useMedicalAlertSearch,
} from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { MedicalAlertTableComponent } from '../../component/MedicalAlertTableComponent/MedicalAlertTableComponent';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';

export interface MedicalAlertTableContainerProps {
  memberId?: string;
}

export const MedicalAlertTableContainer: FC<MedicalAlertTableContainerProps> = ({
  memberId,
}) => {
  const defaultSorter = () => [{ direction: Sort$Direction.DESC, property: 'createdAt' }];

  const tableChange = useTableChange<
    MedicalAlertClass,
    SearchRequest<MedicalAlert>['filter']
  >({
    // don't use parameter filter here, it reset processedFilter initially
    // use { aInitialFilter, ...tableChange.processedFilter, }
    sort: defaultSorter(),
    sortProcessor: (sorter) => sorterHelper(sorter),
    filterProcessor: (filters) => {
      const newFilters: SearchRequest<MedicalAlert>['filter'] = {};
      if (filters.closedBy) {
        newFilters.closedBy = first(filters.closedBy) as string;
      }
      if (filters.priority) {
        const filterIn: InFilter<PriorityLevelEnum> = {
          in: filters.priority as PriorityLevelEnum[],
        };
        newFilters.priorityIn = filterIn;
      }
      if (filters.status) {
        const s: SmartAlertTaskStatus = filters.status[0] as SmartAlertTaskStatus;
        newFilters.status = s;
      }
      return newFilters;
    },
  });

  const convertedPage = baseAPIHelper(tableChange.processedSort, tableChange.pageInfo);

  const info = useMedicalAlertSearch({
    options: {
      sendOnMount: true,
    },
    params: {
      filter: { memberId, ...tableChange.processedFilter },
      pageInfo: convertedPage,
    },
  });

  useUpdateListener('MEDICAL_ALERT_UPDATED', info.refetch);

  return (
    <FetchComponent
      info={info}
      alwaysShowChildren
      showErrorOverlay={false}
      emptyOverlay={<h2>No Data!!!</h2>}
    >
      {(value, info) => (
        <MedicalAlertTableComponent
          dataSource={value?.rawValue?.data?.content}
          onChange={tableChange.handleTableChange}
          pagination={info.pagination}
        />
      )}
    </FetchComponent>
  );
};
