/* eslint-disable indent */
import { ReactNode } from 'react';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';

export interface MTPRMeasurementSummaryItemComponentProps<T> {
  title?: ReactNode;
  value?: T;
  emptyText?: string;
  children?: ReactNode;
}

export const MTPRMeasurementSummaryItemComponent = <T, >({
  title,
  value,
  emptyText = 'N/A',
  children = (
    <DisplayOrEmptyComponent
      value={value}
      emptyType="reactNode"
      emptyText={emptyText}
    />
  )
}: MTPRMeasurementSummaryItemComponentProps<T>) => (
  <div className="flex fd-c">
    <TextComponent>
      {title}
    </TextComponent>
    <TextComponent size="large" bold>
      {children}
    </TextComponent>
  </div>
);
