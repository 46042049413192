import { DeviceModelEnum } from '../../../../uc-api-sdk';
import { BloodPressureAssignUnassignComponent } from '../BloodPressureAssignUnassignComponent/BloodPressureAssignUnassignComponent';
import { DeviceAssignUnassignComponentProps } from '../DeviceAssignUnassignComponent/DeviceAssignUnassignComponent';
import { BloodPressureDeviceAssignmentPopconfirmWarningComponent } from './BloodPressureDeviceAssignmentPopoverWarningComponent';

export interface BPDeviceAssignmentComponentProps extends Omit<DeviceAssignUnassignComponentProps, 'deviceModel'> {
  deviceModel: DeviceModelEnum;
  disabled?: boolean;
}

export const BPDeviceAssignmentComponent = ({
  assignedDevice,
  onAssign,
  onUnassign,
  deviceModel,
  disabled,
}: BPDeviceAssignmentComponentProps) => (
  <BloodPressureDeviceAssignmentPopconfirmWarningComponent
    key={deviceModel}
    isDeviceAssignmentDisabled={disabled}
  >
    <BloodPressureAssignUnassignComponent
      deviceModel={deviceModel}
      assignedDevice={assignedDevice}
      onAssign={onAssign}
      onUnassign={onUnassign}
      disabled={disabled}
    />
  </BloodPressureDeviceAssignmentPopconfirmWarningComponent>
);
