import { ReactNode, useState } from 'react';
import { isEmpty, map } from 'lodash';
import { RcFile } from 'antd/lib/upload';
import { PresignedURLDetail, useTranscribingJobGetPresignedUrls } from '../../../../uc-api-sdk';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useUploadPresignedS3 } from '../../../../hooks/ajaxRequest/useUploadPresignedS3/useUploadPresignedS3';

export interface LLMUploadFileInfo {
  file: RcFile;
  presignedDetails?: PresignedURLDetail;
  uploadStatus?: PromiseSettledResult<File>['status'];
}

export interface LLMUploadContainerChildrenProps {
  upload: (files?: RcFile[]) => void;
  isUploading: boolean;
  fileList: RcFile[];
  handleFileListChange: (newFileList: RcFile[]) => void;
}

export interface LLMUploadContainerProps {
  patientId: string;
  defaultFileList?: RcFile[];
  onFileListChange?: (fileList: RcFile[]) => void;
  children: (props: LLMUploadContainerChildrenProps) => ReactNode; // upload component
  onUploadFinish?: (fileList?: LLMUploadFileInfo[]) => void;
}

export const LLMUploadContainer = ({
  patientId,
  defaultFileList,
  onFileListChange,
  children,
  onUploadFinish,
}: LLMUploadContainerProps) => {
  const getPresignedUrlInfo = useTranscribingJobGetPresignedUrls({});
  const { uploadToPresignedS3 } = useUploadPresignedS3();
  const [
    fileList,
    setFileList,
  ] = useState<RcFile[]>(defaultFileList || []);
  const [
    isUploading,
    setIsUploading,
  ] = useState(false);

  const uploadSingle = async (file: RcFile): Promise<LLMUploadFileInfo> => (
    new Promise((resolve, reject) => {
      // 1. get presigned url using filename
      ApiRequestHelper.tryCatch(
        getPresignedUrlInfo.send({
          params: {
            request: {
              patientId,
              fileNames: [file.name]
            }
          }
        }),
        {
          success: '',
          error: '',
          onSuccess: async (res) => {
            const presignedDetails = res?.data?.[0];
            if (isEmpty(presignedDetails) || !presignedDetails.s3PresignedUrl) {
              reject(new Error('No presigned Url'));
              return;
            }
            // 2. upload file to presigned url
            const { s3PresignedUrl } = presignedDetails;
            const uploadRes = await uploadToPresignedS3(
              s3PresignedUrl,
              file,
            );
            const uploadedFile = {
              file,
              presignedDetails,
            };
            // 3. Finish
            if (!uploadRes || !uploadRes.res) {
              reject(uploadedFile);
            }
            resolve(uploadedFile);
          },
          onError: () => {
            setIsUploading(false);
            reject(file.name);
          }
        }
      );
    })
  );

  const upload: LLMUploadContainerChildrenProps['upload'] = async (files = fileList) => {
    setIsUploading(true);
    const uploadResults = await Promise.allSettled(files?.map((file) => uploadSingle(file)));
    const uploadedFiles = map(files, (file, idx) => ({
      file,
      presignedDetails: (
        uploadResults[idx].status === 'rejected'
          ? undefined
          : (
            (uploadResults[idx] as PromiseFulfilledResult<LLMUploadFileInfo>)
              .value
              .presignedDetails
          )
      ),
      uploadStatus: uploadResults[idx].status,
    }));
    onUploadFinish?.(uploadedFiles);
    setIsUploading(false);
  };

  const handleFileListChange = (newFileList: RcFile[]) => {
    setFileList(newFileList);
    onFileListChange?.(newFileList);
  };

  return (
    <>
      {
        children({
          upload,
          isUploading,
          fileList,
          handleFileListChange,
        })
      }
    </>
  );
};
