import { DeviceHelper } from '../../helper';

export interface BloodPressureDeviceLanguageComponentProps {
  value: string;
}

export const BloodPressureDeviceLanguageComponent = ({
  value,
}: BloodPressureDeviceLanguageComponentProps) => {
  const getText = () => (
    DeviceHelper.BPM1DeviceLanguage[value as keyof typeof DeviceHelper.BPM1DeviceLanguage]?.label
    || value
  );
  return (<>{getText()}</>);
};
