import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import App from './App';

import { useLoggedInUserFromContext } from './contexts/loggedInUserContext';
import { useVisibility } from './contexts/VisibilityContext/VisibilityContext';
import { InternalToolLayoutComponent } from './features/adminPortal2/layout/InternalToolLayoutComponent/InternalToolLayoutComponent';
import { BillableTimeReviewPageComponent } from './features/billableTime/component/BillableTimeReviewPageComponent/BillableTimeReviewPageComponent';
import { BillerPortalLayoutComponent } from './features/billerPortal/layouts/BillerPortalLayoutComponent/BillerPortalLayoutComponent';
import { CarePortalLayoutComponent } from './features/carePortalLayout/CarePortalLayoutComponent/CarePortalLayoutComponent';
import { MedicalAlertDashboardComponent } from './features/medicalAlerts/component/MedicalAlertDashboardComponent/MedicalAlertDashboardComponent';
import { PatientTableDispatcherComponent } from './features/patient/component/PatientTableDispatcherComponent/PatientTableDispatcherComponent';
import useChangePage, { adminPortalUrl, billerPortalUrl, carePortalUrl } from './hooks/useChangePage/useChangePage';
import { adminPortalRoles } from './lib/constants/roles';
import { RoleTypeEnum } from './uc-api-sdk';
import { useRefreshSessionToken } from './hooks/useRefreshSessionToken/useRefreshSessionToken';

const MedicalOrgDashboardComponent = React.lazy(() => import('./features/adminPortal2/medicalOrg/component/MedicalOrgDashboardComponent/MedicalOrgDashboardComponent'));
const UserDirectoryPageComponent = React.lazy(() => import('./features/adminPortal2/users/component/UserDirectoryPageComponent/UserDirectoryPageComponent'));
const TicketDashboardContainer = React.lazy(() => import('./features/ticket/container/TicketDashboardContainer/TicketDashboardContainer'));
const PatientInfoQAComponent = React.lazy(() => import('./features/qa/component/PatientInfoQAComponent/PatientInfoQAComponent'));
const EnrollmentPageComponent = React.lazy(() => import('./features/enrollment/component/EnrollmentPageComponent/EnrollmentPageComponent'));
const MessagePageComponent = React.lazy(() => import('./features/message/component/MessagePageComponent/MessagePageComponent'));
const DataAnalyticsMainContainer = React.lazy(() => import('./features/dataAnalytics/DataAnalyticsMainContainer'));
const CalendarPageComponent = React.lazy(() => import('./features/calendar/component/CalendarPageComponent/CalendarPageComponent'));
const ProtectedLayout = React.lazy(() => import('./features/layout/ProtectedLayout/ProtectedLayout'));
const PlaygroundComponent = React.lazy(() => import('./features/playground/PlaygroundComponent'));
const MainArticleHookUsageSampleComponent = React.lazy(() => import('./sample/component/ArticleHookUsageSampleComponent/MainArticleHookUsageSampleComponent'));
const MainArticleWCacheSampleComponent = React.lazy(() => import('./sample/component/ArticleSampleWithCache/MainArticleWCacheSampleComponent'));
const MainArticleSimpleSampleComponent = React.lazy(() => import('./sample/component/ArticleSimpleSample/MainArticleSimpleSampleComponent'));
const MainArticleWNewFunctionSampleComponent = React.lazy(() => import('./sample/component/ArticleWNewFunction/MainArticleWNewFunctionSampleComponent'));
const MainComponentVsContainerComponent = React.lazy(() => import('./sample/component/ComponentVsContainerComponent/MainComponentVsContainerComponent'));
const MainInputComponentComponent = React.lazy(() => import('./sample/component/InputSampleComponent/MainInputSampleComponent'));
const MainSampleChangePageComponent = React.lazy(() => import('./sample/component/SampleChangePageComponent/MainSampleChangePageComponent'));
const MainSampleFormComponent = React.lazy(() => import('./sample/component/SampleFormComponent/MainSampleFormComponent'));
const MainTableSampleComponent = React.lazy(() => import('./sample/component/TableSampleComponent/MainTableSampleComponent'));
const VisibilitySampleComponent = React.lazy(() => import('./sample/component/VisibilitySampleComponent/VisibilitySampleComponent'));
const TaskDashboardComponent = React.lazy(() => import('./features/taskAssignment/component/TaskDashboardComponent/TaskDashboardComponent'));
const PatientCarePageComponent = React.lazy(() => import('./features/patientCare/component/PatientCarePageComponent/PatientCarePageComponent'));
const ArticlesPageLayout = React.lazy(() => import('./sample/component/ArticlesPageLayout'));
const HomePageComponent = React.lazy(() => import('./features/home/component/HomePageComponent/HomePageComponent'));
const ProvidersContainer = React.lazy(() => import('./contexts/ProvidersContainer/ProvidersContainer'));
const BillerPortalContainer = React.lazy(() => import('./contexts/BillerPortalContainer/BillerPortalContainer'));
const UserProfileComponent = React.lazy(() => import('./features/user/component/UserProfileComponent/UserProfileComponent'));
const PatientProfilePageComponent = React.lazy(() => import('./features/patient/page/PatientProfilePageComponent/PatientProfilePageComponent'));
const ResetPasswordComponent = React.lazy(() => import('./features/auth/component/ResetPasswordComponent/ResetPasswordComponent'));
const UserActivationContainer = React.lazy(() => import('./features/adminPortal2/container/UserActivationContainer/UserActivationContainer'));
const UtilitiesPageComponent = React.lazy(() => import('./features/utilities/component/UtilitiesPageComponent/UtilitiesPageComponent'));
const TranscribePageComponent = React.lazy(() => import('./features/transcribing/page/TranscribePageComponent'));
const ScreeningPageComponent = React.lazy(() => import('./features/screen/page/ScreeningPageComponent'));
const AdminPortalLayoutComponent = React.lazy(() => import('./features/adminPortal2/layout/AdminPortalLayoutComponent/AdminPortalLayoutComponent'));
const MedicalOrgPageComponent = React.lazy(() => import('./features/adminPortal2/medicalOrg/component/MedicalOrgPageComponent/MedicalOrgPageComponent'));
const ClinicOKRPageComponent = React.lazy(() => import('./features/adminPortal2/clinicOKR/component/ClinicOKRPageComponent/ClinicOKRPageComponent'));
const UsersBasePageComponent = React.lazy(() => import('./features/adminPortal2/users/component/UsersBasePageComponent/UsersBasePageComponent'));
const AdminPortalHomePageComponent = React.lazy(() => import('./features/adminPortal2/home/component/AdminPortalHomePageComponent/AdminPortalHomePageComponent'));
const CareOperationHierarchyPageComponent = React.lazy(() => import('./features/adminPortal2/careOperationHierarchy/component/CareOperationHierarchyPageComponent/CareOperationHierarchyPageComponent'));
const EhrRedirectComponent = React.lazy(() => import('./features/ehr/component/EhrRedirectComponent/EhrRedirectComponent'));
const EhrLoginPageComponent = React.lazy(() => import('./features/ehr/component/EhrLoginPageComponent/EhrLoginPageComponent'));
const EhrAuthorizationContainer = React.lazy(() => import('./features/ehr/container/EhrAuthorizationContainer/EhrAuthorizationContainer'));
const ReassignPatientsPageComponent = React.lazy(() => import('./features/reassignPatients/component/ReassignPatientsPageComponent/ReassignPatientsPageComponent'));
const VisitsAndEventsComponent = React.lazy(() => import('./features/visit/component/VisitsAndEventsLayoutComponent/VisitsAndEventsLayoutComponent'));
const DumpReportPageComponent = React.lazy(() => import('./features/billerPortal/reports/component/DumpReportPageComponent/DumpReportPageComponent'));
const AdminPortalUtilitiesPageComponent = React.lazy(() => import('./features/adminPortal2/utilities/component/AdminPortalUtilitiesPageComponent/AdminPortalUtilitiesPageComponent'));
const BatchMessagingPageComponent = React.lazy(() => import('./features/batchMessagingTool/component/BatchMessagingPageComponent/BatchMessagingPageComponent'));
const BillerPortalUtilitiesPage = React.lazy(() => import('./features/billerPortal/layouts/BillerPortalUtilitiesPage.tsx/BillerPortalUtilitiesPage'));
const BillerPortalBillableTimeReviewPageComponent = React.lazy(() => import('./features/billerPortal/utilities/component/BillerPortalBillableTimeReviewPageComponent/BillerPortalBillableTimeReviewPageComponent'));
const BillerPortalMissingInformationPatientPageComponent = React.lazy(() => import('./features/billerPortal/utilities/component/BillerPortalMissingInformationPatientPageComponent/BillerPortalMissingInformationPatientPageComponent'));
const PriorAuthPageComponent = React.lazy(() => import('./features/Insurance/component/PriorAuthPageComponent/PriorAuthPageComponent'));
const InsuranceAndEligibilityPageComponent = React.lazy(() => import('./features/Insurance/component/InsuranceAndEligibilityPageComponent/InsuranceAndEligibilityPageComponent'));
const ClinicStaffBillingPageComponent = React.lazy(() => import('./features/ClinicStaffBilling/component/ClinicStaffBillingPageComponent/ClinicStaffBillingPageComponent'));
const PaymentAndInvoicePageComponent = React.lazy(() => import('./features/ClinicStaffBilling/component/PaymentAndInvoicePageComponent/PaymentAndInvoicePageComponent'));
const BillingReportPageComponent = React.lazy(() => import('./features/billingReport/component/BillingReportPageComponent/BillingReportPageComponent'));
const BillableTimeDetailedLogPageComonent = React.lazy(() => import('./features/billableTime/component/BillableTimeDetailedLogPageComponent/BillableTimeDetailedLogPageComonent'));

export const RoutesComponent = () => {
  const page = useChangePage();
  const { isProvider, isClinicBiller, isClinicManager } = useLoggedInUserFromContext();
  const { showInsight } = useVisibility();

  useRefreshSessionToken();

  const getPortalRoute = () => {
    if (isProvider) {
      return '/care-portal/patients';
    }
    if (isClinicBiller || isClinicManager) {
      return '/care-portal/clinic-billing';
    }

    return '/care-portal/home';
  };

  return (
    <React.Suspense fallback={<div>loading...</div>}>
      <Routes>
        <Route path="/">
          <Route index element={<App />} />
          <Route path="/ehr">
            <Route index element={<EhrRedirectComponent />} />
            <Route path="/ehr/login" element={<EhrLoginPageComponent />} />
            <Route path="/ehr/refer" element={<EhrAuthorizationContainer />} />
          </Route>
          <Route path="/reset-password" element={<ResetPasswordComponent />} />
          <Route path="/onboard-password" element={<UserActivationContainer />} />
          <Route path={adminPortalUrl}>
            <Route
              element={(
                <ProtectedLayout roleWhiteList={adminPortalRoles} />
              )}
            >
              <Route element={<AdminPortalLayoutComponent />}>
                <Route
                  index
                  element={<Navigate to={page.createAdminPortalHomeUrl()} />}
                />
                <Route
                  path={page.createAdminPortalHomeUrl()}
                  element={<AdminPortalHomePageComponent />}
                />
                <Route
                  path={page.createAdminPortalCareOperationHierarchyUrl()}
                  element={<CareOperationHierarchyPageComponent />}
                />
                <Route
                  path={page.createAdminPortalMedicalOrganizationsUrl()}
                  element={<MedicalOrgDashboardComponent />}
                />
                <Route
                  path="/admin-portal/medical-orgs/:medicalOrgId"
                  element={<MedicalOrgPageComponent />}
                />
                <Route
                  path={page.createAdminPortalUsersUrl()}
                  element={<UserDirectoryPageComponent />}
                />
                <Route
                  path="/admin-portal/users/:id"
                  element={<UsersBasePageComponent />}
                />
                <Route
                  path={page.createAdminPortalUtilitiesUrl()}
                  element={<AdminPortalUtilitiesPageComponent />}
                />
                <Route
                  path={page.createAdminPortalSettingsUrl()}
                  element={<UserProfileComponent />}
                />
              </Route>
            </Route>
          </Route>
          <Route path={carePortalUrl}>
            <Route
              element={(
                <ProtectedLayout />
                // remove the comment below to support only care-portal user
                // roleWhiteList={carePortalRoles} />
              )}
            >
              <Route
                index
                element={(
                  <Navigate
                    to={getPortalRoute()}
                  />
                )}
              />
              <Route element={<ProvidersContainer />}>
                <Route
                  path="/care-portal/clinic-okr"
                  element={<ClinicOKRPageComponent />}
                />
                <Route element={<CarePortalLayoutComponent />}>
                  <Route path="/care-portal/home" element={<HomePageComponent />} />
                  <Route path="/care-portal/calendar" element={<CalendarPageComponent />} />
                  <Route path="/care-portal/enrollment" element={<EnrollmentPageComponent />} />
                  <Route path="/care-portal/patients" element={<PatientTableDispatcherComponent />} />
                  <Route path="/care-portal/patients/:patientId">
                    <Route index element={<PatientProfilePageComponent />} />
                  </Route>
                  <Route path="/care-portal/patient-care" element={<PatientCarePageComponent />} />
                  <Route path="/care-portal/patient-care/:patientId" element={<PatientCarePageComponent />} />
                  <Route path="/care-portal/visits-and-events" element={<VisitsAndEventsComponent />} />
                  <Route path="/care-portal/playground" element={<PlaygroundComponent />} />
                  <Route path="/care-portal/qa/">
                    <Route index element={<PatientInfoQAComponent />} />
                  </Route>
                  <Route path="/care-portal/qa/:patientId">
                    <Route index element={<PatientInfoQAComponent />} />
                  </Route>
                  <Route path="/care-portal/task-list" element={<TaskDashboardComponent />} />
                  <Route path="/care-portal/task" element={<TicketDashboardContainer />} />
                  <Route path="/care-portal/medical-alert-dashboard" element={<MedicalAlertDashboardComponent />} />
                  <Route path="/care-portal/clinic-billing/billing-report-page" element={<BillingReportPageComponent />} />
                  <Route
                    path="/care-portal/insights"
                    element={
                      showInsight
                        ? null
                        : <Navigate to="/403" />
                    }
                  >
                    <Route index element={<DataAnalyticsMainContainer />} />
                  </Route>
                  <Route path="/care-portal/messages" element={<MessagePageComponent />} />
                  <Route path="/care-portal/utilities">
                    <Route index element={<UtilitiesPageComponent />} />
                    <Route path="/care-portal/utilities/transcribe" element={<TranscribePageComponent />} />
                    <Route path="/care-portal/utilities/screening" element={<ScreeningPageComponent />} />

                    <Route path="/care-portal/utilities/billable-time-review" element={<BillableTimeReviewPageComponent />} />
                    <Route path="/care-portal/utilities/billable-time-detailed-log" element={<BillableTimeDetailedLogPageComonent />} />
                    <Route path="/care-portal/utilities/medical-alert-dashboard" element={<MedicalAlertDashboardComponent />} />
                    <Route path="/care-portal/utilities/prior-auth" element={<PriorAuthPageComponent />} />
                    <Route path="/care-portal/utilities/insurance-and-eligibility" element={<InsuranceAndEligibilityPageComponent />} />
                  </Route>
                  <Route path="/care-portal/settings" element={<UserProfileComponent />} />
                  <Route path="/care-portal/clinic-billing" element={<ClinicStaffBillingPageComponent />} />
                  <Route path="/care-portal/clinic-billing/payment-and-invoice" element={<PaymentAndInvoicePageComponent />} />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path={billerPortalUrl}>
            <Route
              element={(
                <ProtectedLayout roleWhiteList={[RoleTypeEnum.BILLER]} />
              )}
            >
              <Route element={<BillerPortalLayoutComponent />}>
                <Route
                  index
                  element={<Navigate to={page.createBillerPortalReportsUrl()} />}
                />
                <Route
                  path={page.createBillerPortalReportsUrl()}
                  element={<DumpReportPageComponent />}
                />
                <Route
                  path={page.createBillerPortalSettingsUrl()}
                  element={<UserProfileComponent />}
                />
                <Route
                  path={page.createBillerPortalUtilitiesUrl()}
                  element={<BillerPortalUtilitiesPage />}
                />
                <Route path={page.createBillerPortalUtilitiesUrl()}>
                  <Route index element={<BillerPortalUtilitiesPage />} />
                  <Route path="/biller-portal/utilities/billable-time-review" element={<BillerPortalBillableTimeReviewPageComponent />} />
                  <Route path="/biller-portal/utilities/prior-auth" element={<PriorAuthPageComponent />} />
                </Route>
                <Route path={page.createBillerPortalUtilitiesUrl()}>
                  <Route index element={<BillerPortalUtilitiesPage />} />
                  <Route path="/biller-portal/utilities/list-missing-information-report-version" element={<BillerPortalMissingInformationPatientPageComponent />} />
                </Route>
                <Route element={<BillerPortalContainer />}>
                  <Route path={`${billerPortalUrl}/patients/:patientId`}>
                    <Route index element={<PatientProfilePageComponent />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path="/internal-tool">
            <Route
              element={(
                <ProtectedLayout />
              )}
            >
              <Route element={<InternalToolLayoutComponent />}>
                <Route
                  path="/internal-tool/reassign-patients"
                  element={<ReassignPatientsPageComponent />}
                />
                <Route
                  path="/internal-tool/batch-messaging"
                  element={<BatchMessagingPageComponent />}
                />
              </Route>
            </Route>
          </Route>
          <Route path="/403" element={<div>Unauthorized access</div>} />
          <Route
            element={(
              <ProtectedLayout roleWhiteList={[RoleTypeEnum.SUPER_ADMIN]} />
            )}
          >
            <Route
              path="/playground"
              element={<PlaygroundComponent />}
            />
            <Route
              path="/internal-tool/reassign-patients"
              element={<ReassignPatientsPageComponent />}
            />
            <Route
              path="/sample"
              element={<ArticlesPageLayout />}
            />
            <Route
              index
              element={<MainArticleSimpleSampleComponent />}
            />
            <Route
              path="/sample/simple"
              element={<MainArticleSimpleSampleComponent />}
            />
            <Route
              path="/sample/new-function"
              element={<MainArticleWNewFunctionSampleComponent />}
            />
            <Route
              path="/sample/cache"
              element={<MainArticleWCacheSampleComponent />}
            />
            <Route
              path="/sample/request-hook"
              element={<MainArticleHookUsageSampleComponent />}
            />
            <Route
              path="/sample/visibility"
              element={<VisibilitySampleComponent />}
            />
            <Route
              path="/sample/Form"
              element={<MainSampleFormComponent />}
            />
            <Route
              path="/sample/change-page"
              element={<MainSampleChangePageComponent />}
            />
            <Route
              path="/sample/table"
              element={<MainTableSampleComponent />}
            />
            <Route
              path="/sample/input"
              element={<MainInputComponentComponent />}
            />
            <Route
              path="/sample/component-vs-container"
              element={<MainComponentVsContainerComponent />}
            />
          </Route>
        </Route>
      </Routes>
    </React.Suspense>
  );
};
