import { Table } from 'antd';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { articleTableColumns } from '../../../../tables/ArticlesTableColumns/useArticleHistoryColumns';
import { PatientArticles } from '../../../../uc-api-sdk';
import { SENT_HISTORY_TABLE_PAGE_SIZE } from '../../constants';

export interface ArticleSentHistoryTableComponentProps {
  isLoading?: boolean;
  totalSize: number;
  articles: PatientArticles[];
  disabled?: boolean;
}
export const ArticleSentHistoryTableComponent = (
  {
    isLoading,
    totalSize = 0,
    articles,
    disabled,
  }: ArticleSentHistoryTableComponentProps,
) => {
  const columns = useTableColumns(
    articleTableColumns,
    ['name', 'sentDate'],
  );

  return (
    <div className="article-sent-history-table-component">
      <div className="article-sent-history-table-component__label">
        Sending history
      </div>
      <Table
        columns={columns}
        dataSource={articles}
        loading={isLoading}
        size="small"
        rowKey={(r) => r.id}
        pagination={articles.length >= SENT_HISTORY_TABLE_PAGE_SIZE ? {
          pageSize: SENT_HISTORY_TABLE_PAGE_SIZE,
          total: totalSize,
          disabled,
        } : false}
      />
    </div>
  );
};
