import { isEmpty } from 'lodash';
import { CarePlan } from '../../../../../uc-api-sdk';
import { PrintCarePlanPreviewComponent } from '../PrintCarePlanPreviewComponent/PrintCarePlanPreviewComponent';
import { PrintButtonComponent, PrintButtonComponentProps } from '../../../../../uiComponent/PrintButtonComponent/PrintButtonComponent';

export interface PrintCarePlanPreviewButtonComponentProps
  extends Omit<PrintButtonComponentProps, 'children'> {
  carePlan?: CarePlan | null;
}

export const PrintCarePlanPreviewButtonComponent = ({
  carePlan,
  isLoading,
  buttonText,
  buttonType,
  className,
  onClick
}: PrintCarePlanPreviewButtonComponentProps) => (
  <PrintButtonComponent
    documentTitle="Care Plan"
    isLoading={isLoading}
    buttonText={buttonText}
    buttonType={buttonType}
    className={className}
    onClick={onClick}
  >
    {
      !isEmpty(carePlan) && (
        <PrintCarePlanPreviewComponent carePlan={carePlan} />
      )
    }
  </PrintButtonComponent>
);
