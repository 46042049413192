import { EnumSelectComponent } from '../../EnumSelectComponent/EnumSelectComponent';
import { GenderEnumComponent } from '../../../enumComponent/GenderEnumComponent/GenderEnumComponent';
import { GenderEnum } from '../../../uc-api-sdk';
import { InputType } from '../../../features/Input/types';

export interface PatientGenderInputComponentProps extends InputType<GenderEnum> {
}

export const PatientGenderInputComponent = ({
  value,
  onChange,
}: PatientGenderInputComponentProps) => (
  <EnumSelectComponent
    options={GenderEnum}
    optionRenderer={GenderEnumComponent}
    value={value}
    onChange={onChange}
  />
);
