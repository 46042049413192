import { map } from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { FC, useMemo } from 'react';
import { Dayjs } from 'dayjs';
import {
  createHSChartOptions,
  createHSChartSeries,
  createMarker,
} from './HSChartHelper';
import { FromDateToDateDayjs } from '../../type';
import TimezoneService from '../../../../helpers/timezone/timezoneService';

export interface HSChartData {
  id: string;
  date: Dayjs;
  patientNote: string;
  weightInKg: number;
  weightInLbs: number;
  weightVariation: number;
  resultSource: 'vital' | 'visit';
}

export interface HSChartComponentProps extends FromDateToDateDayjs {
  data: HSChartData[];
}

export const HSChartComponent: FC<HSChartComponentProps> = ({
  data,
  fromDate,
  toDate,
}) => {
  const getData = () => map(data, (curr) => {
    const date = TimezoneService.calcDateTimeDayjs(curr.date).startOf('day').valueOf();
    return {
      x: date,
      y: +curr.weightInLbs.toFixed(2),
      weightVariation: curr.weightVariation.toFixed(2),
      marker: createMarker(curr),
      notes: curr.patientNote,
    };
  });

  const options = useMemo(() => {
    const seriesData = getData();
    const series = createHSChartSeries({ data: seriesData });
    return createHSChartOptions(series, fromDate, toDate);
  }, [data, fromDate, toDate]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
};
