import {
  Button,
  Dropdown,
  Input,
  InputProps,
  MenuProps,
  Select,
  SelectProps,
} from 'antd';
import { map } from 'lodash';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { DefaultOptionType } from 'antd/lib/select';
import { InputType } from '../../../Input/types';
import { InsurancePayer, useInsuranceProviderSearchPayers } from '../../../../uc-api-sdk';
import { useScrollPagination } from '../../../../hooks/useScrollPagination/useScrollPagination';
import { useDeepCompareMemo } from '../../../../hooks/useDeepCompareEffect';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { useEffectWithPrevValue } from '../../../../hooks/useEffectWithPrevValue/useEffectWithPrevValue';

import './WaystarProviderSelectContainer.scss';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';

export interface WaystarProviderValue {
  name?: string;
  id?: string;
}

export enum WaystarProviderInputType {
  SEARCH = 'search',
  MANUAL = 'manual',
}

export interface WaystarProviderSelectContainerProps extends InputType<string> {
  initialProviderId?: string | null;
  onChangeProviderId?: (providerId?: string) => void;
  disableManualInput?: boolean;
}

const DEFAULT_PAGE_SIZE = 15;

export const WaystarProviderSelectContainer = ({
  value,
  onChange,
  initialProviderId,
  onChangeProviderId,
  disableManualInput,
}: WaystarProviderSelectContainerProps) => {
  const ref = useRef(null);
  const [
    searchStr,
    setSearchStr,
  ] = useState(value ?? undefined);
  const [
    inputType,
    setInputType,
  ] = useState<WaystarProviderInputType>(
    (value && !initialProviderId)
      ? WaystarProviderInputType.MANUAL
      : WaystarProviderInputType.SEARCH
  );
  const insuranceProviderSearch = useInsuranceProviderSearchPayers({});
  const {
    allData,
    handleOnScroll,
  } = useScrollPagination({
    requestInfo: insuranceProviderSearch,
    fetchParams: (page = 1) => ({
      request: {
        filter: { request: searchStr },
        pageInfo: { page, size: DEFAULT_PAGE_SIZE }
      },
    }),
  });

  const sendRequest = useDebounce((searchStr?: string) => {
    insuranceProviderSearch.send({
      params: {
        request: {
          filter: { request: searchStr || '' },
          pageInfo: { page: 1, size: DEFAULT_PAGE_SIZE }
        }
      }
    });
  }, 500);

  useEffect(() => {
    if (searchStr === '') {
      return; // when user clear the search, do not send request
    }
    const newSearchStr = searchStr || value;
    if (searchStr === undefined) {
      // initial render
      setSearchStr(value);
    }
    sendRequest(newSearchStr);
  }, [searchStr]);

  useEffect(() => {
    if (disableManualInput) {
      setInputType(WaystarProviderInputType.SEARCH);
    }
  }, [disableManualInput, inputType]);

  useEffectWithPrevValue(inputType, (prevInputType) => {
    if (prevInputType === undefined) return;
    // reset value when switch input type
    onChange?.(undefined);
    onChangeProviderId?.(undefined);
  });

  const options = useDeepCompareMemo(() => (
    map(allData as InsurancePayer[], (payerInfo) => ({
      key: payerInfo.id,
      label: (
        <div className="flex ai-c jc-sb">
          {payerInfo.payerName}
          {
            !payerInfo.wayStarPayerId
            && (
              <TooltipComponent
                type="info-icon"
                title="The insurance is manually added to the list and might not be accurate"
              />
            )
          }
        </div>
      ),
      value: payerInfo.payerName,
      payerInfo,
    }))
  ), [allData]);

  const goToPayerListTool = () => {
    window.open('https://login.zirmed.com/ui/Payers', '_blank');
  };

  const dropdownRender = useCallback<NonNullable<SelectProps['dropdownRender']>>((menu) => (
    <>
      <div>
        {menu}
      </div>
      <div className="p12">
        <div className="pt12 border-t text-gray-scale-1">
          Can't find the payer?
          Please use the
          {' '}
          <LinkButton
            showUnderline
            onClick={goToPayerListTool}
          >
            payer list tool
          </LinkButton>
          {' '}
          for help or
          {' '}
          <LinkButton
            useBlueColor
            onClick={() => setInputType(WaystarProviderInputType.MANUAL)}
            disabled={disableManualInput}
          >
            enter manually
          </LinkButton>
        </div>
      </div>
    </>
  ), []);

  const items = useDeepCompareMemo(() => {
    const getClassName = (key: WaystarProviderInputType) => classNames({
      'waystar-provider-select__option': true,
      'waystar-provider-select__option-selected': inputType === key,
    });
    return [
      {
        key: WaystarProviderInputType.SEARCH,
        label: (
          <>
            <SearchOutlined />
            {' '}
            Search
          </>
        ),
        className: getClassName(WaystarProviderInputType.SEARCH),
      },
      {
        key: WaystarProviderInputType.MANUAL,
        label: (
          <>
            <EditOutlined />
            {' '}
            Manual input
          </>
        ),
        className: getClassName(WaystarProviderInputType.MANUAL),
        disabled: disableManualInput,
      },
    ] as MenuProps['items'];
  }, [inputType, disableManualInput]);

  const handleOnChangeSelect: SelectProps['onChange'] = (value, option) => {
    const { payerInfo } = option as DefaultOptionType;
    onChange?.(value);
    onChangeProviderId?.(payerInfo?.wayStarPayerId);
  };

  const handleOnChangeInput: InputProps['onChange'] = (e) => {
    const { value } = e.target;
    if (value === '') {
      // it means whitespace=true for form item rule,
      // this is to trigger form validate fields
      onChange?.(undefined);
      return;
    }
    onChange?.(value);
  };

  return (
    <div className="flex ai-c gap0 mw100">
      {
        inputType === WaystarProviderInputType.MANUAL
          ? (
            <Input
              value={value}
              onChange={handleOnChangeInput}
              autoFocus
            />
          ) : (
            <Select
              ref={ref}
              value={value}
              onChange={handleOnChangeSelect}
              className="waystar-provider-select"
              options={inputType === WaystarProviderInputType.SEARCH ? options : undefined}
              dropdownMatchSelectWidth={false}
              onSearch={setSearchStr}
              searchValue={searchStr}
              showSearch
              autoClearSearchValue={false}
              onPopupScroll={handleOnScroll}
              loading={insuranceProviderSearch.isLoading}
              showArrow={false}
              dropdownRender={dropdownRender}
            />
          )
      }
      <Dropdown
        placement="bottomLeft"
        trigger={['click']}
        onOpenChange={() => {
          (ref.current as unknown as HTMLInputElement)?.blur();
        }}
        menu={{
          items,
          onClick: ({ domEvent, key }) => {
            domEvent.stopPropagation();
            setInputType(key as WaystarProviderInputType);
          },
        }}
        arrow={false}
      >
        <Button className="waystar-provider-select__input-type-btn">
          {
            inputType === WaystarProviderInputType.SEARCH
              ? <SearchOutlined />
              : <EditOutlined />
          }
        </Button>
      </Dropdown>
    </div>
  );
};
