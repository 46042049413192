import { map } from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Dayjs } from 'dayjs';
import React, { FC, useMemo } from 'react';
import { createExerciseChartOptions, createExerciseChartSeries } from './ExerciseChartHelper';
import { FromDateToDateDayjs } from '../../type';
import { createMarker } from '../../helper/chartHelper';
import TimezoneService from '../../../../helpers/timezone/timezoneService';

export interface ExerciseChartData {
  date: Dayjs;
  type: 'vital' | 'visit'
  steps: number;
}

export interface ExerciseGoalInfo {
  goal: number;
  goalUnit: string;
  total: number;
  abovePercentage: number;
  bellowPercentage: number;
  aboveTarget: number;
  belowTarget: number;
}

export interface ExerciseChartComponentProps extends FromDateToDateDayjs {
  goalInfo: ExerciseGoalInfo;
  data: ExerciseChartData[];
}

export const ExerciseChartComponent: FC<ExerciseChartComponentProps> = ({
  goalInfo,
  data,
  fromDate,
  toDate,
}) => {
  const getData = () => map(data, (curr) => {
    const date = TimezoneService.calcDateTimeDayjs(curr.date).startOf('day').valueOf();
    return {
      x: date,
      y: curr.steps,
      marker: createMarker({ color: '#FCA642', lineColor: 'white' }),
    };
  });

  const options = useMemo(() => {
    const seriesData = getData();
    const series = createExerciseChartSeries(seriesData);
    return createExerciseChartOptions(series, goalInfo, fromDate, toDate);
  }, [data, fromDate, toDate]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
};
