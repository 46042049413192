import { InfoCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';

interface MeasurementTimestampTextComponentProps {
  className?: string;
}

export const MeasurementTimestampTextComponent = ({
  className = '',
}: MeasurementTimestampTextComponentProps) => (
  <div
    className={classNames({
      'measurement-timestamp-text flex ai-c': true,
      [className]: !!className,
    })}
  >
    <InfoCircleOutlined />
    Measurement Timestamp: Local Time of Patient's Device Upload
  </div>
);
