import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { BillableReviewActionType, useBillableTimesReviewHandle } from '../../../../uc-api-sdk';
import { SubmitValue } from '../../component/BillableTimeReviewActionComponent/BillableTimeReviewActionComponent';
import { EnterActualBillableTimeFormComponent } from '../../component/EnterActualBillableTimeFormComponent/EnterActualBillableTimeFormComponent';

export interface EnterActualBillableTimeContainerProps {
  startDate: number;
  totalTime: number;
  totalBillableTime: number;
  close?: () => void;
  id: string;
}

export const EnterActualBillableTimeContainer = ({
  startDate,
  totalTime,
  totalBillableTime,
  close,
  id,
}: EnterActualBillableTimeContainerProps) => {
  const handleHook = useBillableTimesReviewHandle({});
  const updateHook = useUpdate('BILLABLE_TIME_REVIEW_UPDATED');

  const handleEnterActualBillableTime = async (submitValue: SubmitValue) => {
    if (!/^[0-9]+$/.test(submitValue.correctedBillableTime)) {
      return;
    }
    ApiRequestHelper.tryCatch(handleHook.send({
      params: {
        request: {
          id,
          correctedBillableTime: +submitValue.correctedBillableTime * 60000,
          action: BillableReviewActionType.CORRECT_AND_APPROVE,
          comment: submitValue.comment,
        }
      }
    }), {
      success: 'Billable time has been added to the patient.',
      error: 'Failed to enter the billable time.',
      onSuccess: () => {
        close?.();
        updateHook.updateValue();
      }
    });
  };

  return (
    <EnterActualBillableTimeFormComponent
      startDate={startDate}
      totalTime={totalTime}
      totalBillableTime={totalBillableTime}
      onCorrectBillableTime={handleEnterActualBillableTime}
    />
  );
};
