import { WeightService } from '../../../../helpers/weight';
import { Height, Weight } from '../../../../uc-api-sdk';
import { BMIComponent } from '../../../../uiComponent/BMIComponent/BMIComponent';
import { WeightComponent } from '../../../../uiComponent/WeightComponent/WeightComponent';

export interface PatientWeightComponentProps {
  weight?: Weight;
  height?: Height;
}

/**
 * @param weight patient Weight, in lbs
 * @param height patient Height, in cm
 * @param unitText
 */
export const PatientWeightComponent = ({
  weight,
  height,
}: PatientWeightComponentProps) => {
  if (WeightService.hasNoRecord(weight?.value)) {
    return (
      <span>N/A</span>
    );
  }
  return (
    <span className="flex f-w gap1">
      <WeightComponent
        weight={weight}
        label=""
      />
      {
        (weight?.value && height?.value)
          ? (
            <div className="flex gap0">
              (
              <BMIComponent
                weight={weight}
                height={height}
                label="BMI"
                showIndication={false}
              />
              )
            </div>
          ) : null
      }
    </span>
  );
};
