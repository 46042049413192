import { Button, ButtonProps, Popover } from 'antd';
import React, { FC, ReactNode } from 'react';
import useBoolean from '../../hooks/useBoolean/useBoolean';

export interface PopoverButtonProps {
  title?: ReactNode;
  content: ReactNode | ((close: () => void) => ReactNode);
  buttonProps?: ButtonProps;
  children: ReactNode;
  destroyPopoverOnHide?: boolean;
}

export const PopoverButton: FC<PopoverButtonProps> = ({
  title,
  content,
  buttonProps,
  children,
}) => {
  const { value, setValue, setFalse } = useBoolean();

  const renderContent = () => {
    if (typeof content === 'function') {
      return content(setFalse);
    }
    return content;
  };

  return (
    <Popover
      trigger="click"
      open={value}
      onOpenChange={setValue}
      content={renderContent()}
      title={title}
      destroyTooltipOnHide
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Button {...buttonProps}>{children}</Button>
    </Popover>
  );
};
