import { KeyboardEventHandler } from 'react';
import { MessageTranslatedComponent } from '../MessageTranslatedComponent/MessageTranslatedComponent';
import { MessageTextAreaComponent } from '../MessageTextAreaComponent/MessageTextAreaComponent';

export interface TranslationInputComponentProps {
  language?: string;
  originalText?: string;
  translatedText?: string;
  showTranslated?: boolean;
  placeholder?: string;
  disabled?: boolean;
  messageHistoryArea?: boolean;
  onPressEnter?: KeyboardEventHandler<HTMLTextAreaElement>
  setOriginalText?: (text?: string) => void;
  setLanguage?: (language: string) => void;
}

export const TranslationInputComponent = ({
  language,
  originalText,
  translatedText,
  showTranslated,
  placeholder,
  disabled,
  messageHistoryArea,
  setLanguage,
  setOriginalText,
  onPressEnter,
}: TranslationInputComponentProps) => {
  const handleOriginalTextChange = (text?: string) => {
    setOriginalText?.(text);
  };

  return (
    <div>
      {showTranslated && (
        <MessageTranslatedComponent
          translatedText={translatedText}
          language={language}
          setLanguage={setLanguage}
        />
      )}
      <MessageTextAreaComponent
        value={originalText}
        onChange={handleOriginalTextChange}
        isTranslation={showTranslated}
        placeholder={placeholder}
        onPressEnter={onPressEnter}
        disabled={disabled}
      />
      {showTranslated && (
        <p className="fs12 text-gray-scale-2 fs-italic mt5">
          {messageHistoryArea
            ? 'Patient will only receive the translated message upon sending.'
            : 'Patient will only receive the translated comment upon submitting.'}
        </p>
      )}
    </div>
  );
};
