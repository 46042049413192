import { get } from 'lodash';
import {
  LabResultItem,
  LabResultTemplateItem,
  ReferenceLevel,
  ReferenceLevelEnum,
  TranscribeStatusEnum,
  TranscribeTypeEnum,
} from '../../uc-api-sdk';
import { DefaultExtendableDrawers } from '../../uiComponent/ExtendableDrawerComponent/controller/DefaultExtendableDrawers';

export type EditLabResultMode = 'Edit' | 'View' | 'Create';

export const HemoglobinA1cTemplateId = 'hemoa1c';

export type LabResultEditableItem = LabResultItem & LabResultTemplateItem & {
  // table usage
  isManualNew?: boolean;
  isManualEditable?: boolean;
};

export const TRANSCRIBING_TABLE_PAGE_SIZE = 10;

export const TranscribeTexts = {
  createSuccess: 'Lab Result Added',
  editSuccess: 'Lab Result Edited',
  deleteSuccess: 'Lab Result Deleted',
  error: 'An error occurred. Please try again.',
};

export const DEFAULT_TRANSCRIBE_TYPE = TranscribeTypeEnum.VISIT;

export const DEFAULT_TRANSCRIBE_DRAWER_ROOT_ID = 'transcribeMainDrawer' as DefaultExtendableDrawers;

export const transcribeTypeToText = {
  [TranscribeTypeEnum.A1C]: 'A1C Transcribing Due',
  [TranscribeTypeEnum.VISIT]: 'Visit Transcribing Due',
};

export const TRANSCRIBE_TODO_STATUS = [
  TranscribeStatusEnum.TODO,
  TranscribeStatusEnum.NO_RECORD,
];

export const getRange = (value?: number, ref?: ReferenceLevel[]) => {
  if (value !== undefined && ref) {
    const level = ref.find((r) => {
      if (r.gt === null && r.lt
        && (r.isLte ? value <= Number(r.lt) : value < Number(r.lt))) {
        return true;
      }
      if (r.lt === null && r.gt
        && (r.isGte ? value >= Number(r.gt) : value > Number(r.gt))) {
        return true;
      }
      if (r.lt && r.gt
        && (r.isGte ? value >= Number(r.gt) : value > Number(r.gt))
        && (r.isLte ? value <= Number(r.lt) : value < Number(r.lt))) {
        return true;
      }
      return false;
    });
    return level;
  }
  return null;
};

/*
For A1C
  case ReferenceLevelEnum.NORMAL: return 'A1C < 5.7';
  case ReferenceLevelEnum.DIABETES: return '7.0 > A1C ≥ 6.5';
  case ReferenceLevelEnum.PRE_DIABETES: return '6.5 > A1C ≥ 5.7';
  case ReferenceLevelEnum.UNCONTROLLED: return 'A1C ≥ 7.0';
*/
export const getTooltip = (name: string, reference?: ReferenceLevel) => {
  const value = get(reference, 'description') ?? undefined;
  const signGte = reference?.isGte ? '≥' : '>';
  const signLte = reference?.isLte ? '≤' : '<';
  switch (value) {
    case ReferenceLevelEnum.LOW: return `${signLte} ${reference?.lt}`;
    case ReferenceLevelEnum.NORMAL: {
      if (!reference?.gt) {
        return `${name} ${signLte} ${reference?.lt}`;
      }
      if (!reference?.lt) {
        return `${reference?.gt} ${signLte} ${name}`;
      }
      return `${reference?.gt} ${signLte} ${name} ${signLte} ${reference?.lt}`;
    }
    case ReferenceLevelEnum.HIGH: return `${signGte} ${reference?.gt}`;
    case ReferenceLevelEnum.DIABETES:
    case ReferenceLevelEnum.PRE_DIABETES: return `${reference?.gt} ${signLte} ${name} ${signLte} ${reference?.lt}`;
    case ReferenceLevelEnum.UNCONTROLLED: return `${name} ${signGte} ${reference?.gt}`;
    default: return '';
  }
};
