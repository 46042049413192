import { CuffSizeEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface CuffSizeEnumComponentProps {
  value: CuffSizeEnum;
  shortText?: boolean;
}

export const CuffSizeEnumComponent = ({
  value,
  shortText = false,
}: CuffSizeEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case CuffSizeEnum.STANDARD: return 'Standard (22~36cm)';
      case CuffSizeEnum.MINI: return 'Mini (17~22cm)';
      case CuffSizeEnum.LARGE: return 'Large (30~42cm)';
      case CuffSizeEnum.EXTRA_LARGE: return 'Extra Large (42~48cm)';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  const getShortText = () => {
    switch (value) {
      case CuffSizeEnum.STANDARD: return 'Standard size';
      case CuffSizeEnum.MINI: return 'Mini size';
      case CuffSizeEnum.LARGE: return 'Large size';
      case CuffSizeEnum.EXTRA_LARGE: return 'Extra large size';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  return (
    shortText ? <>{getShortText()}</> : <>{getText()}</>
  );
};
