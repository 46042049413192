import dayjs from 'dayjs';
import { USA_DATE } from '../../../../constants/timeFormat';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { Nullable } from '../../../../uc-api-sdk';
import { EmptyString } from '../../../../uiComponent/EmptyComponent/EmptyComponent';

export interface PatientAgeComponentProps {
  dob?: Nullable<string>;
}

export const age = (dob?: string) => {
  if (!dob) {
    return EmptyString;
  }
  const birthday = dayjs(dob);
  return `${dayjs().diff(birthday, 'year')} yr (${birthday.format(USA_DATE)})`;
};

export const PatientAgeComponent = ({
  dob,
}: PatientAgeComponentProps) => {
  if (!dob) {
    return <DisplayOrEmptyComponent />;
  }
  return (
    <div>
      {age(dob)}
    </div>
  );
};
