import { Card, Tooltip, TooltipProps } from 'antd';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import './LabelWithValueComponent.scss';

export interface LabelWithValueComponentProps {
  label?: string;
  value?: ReactNode | null;
  showTooltip?: boolean;
  overlayClassName?: string;
  placement?: TooltipProps['placement'];
  title?: ReactNode;
}

export const LabelWithValueComponent: FC<LabelWithValueComponentProps> = ({
  label,
  value,
  showTooltip = false,
  overlayClassName = '',
  placement = 'bottom',
  title
}) => (
  showTooltip
    ? (
      <Tooltip
        title={title ?? value}
        overlayClassName={classNames({
          'tooltip-style': true,
          [overlayClassName]: !!overlayClassName
        })}
        placement={placement}
      >
        <Card bordered={false}>
          <div className="label-with-value-component flex ai-s">
            {label && <div className="label-text">{label}</div>}
            <div className="full-content-value-text">{value}</div>
          </div>
        </Card>
      </Tooltip>
    ) : (
      <Card bordered={false}>
        <div className="label-with-value-component flex ai-s">
          {label && <div className="label-text">{label}</div>}
          <div className="value-text">{value}</div>
        </div>
      </Card>
    )
);
