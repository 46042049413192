import { trim } from 'lodash';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { ConsentRequest, SignatureCollectionMethodEnum, usePatientUpdateConsentForm } from '../../../../uc-api-sdk';
import { ConsentFormComponent, ConsentFormComponentProps, SubmitValue } from '../../component/ConsentFormComponent/ConsentFormComponent';
import { showConsentFormSentModal } from '../../component/ConsentFormComponent/ConsentFormSentModal';

export interface ConsentFormContainerProps {
  patientInfo: PatientInfo;
  showSentModal?: boolean;
  onSubmit?: (v?: SubmitValue) => void;
}

export const ConsentFormContainer = ({
  patientInfo,
  showSentModal = true,
  onSubmit,
}: ConsentFormContainerProps) => {
  const consentUpdate = usePatientUpdateConsentForm({});

  const handleSubmit = async (v: SubmitValue) => {
    const sendReq = async () => {
      let userData: ConsentRequest['userData'] | undefined;
      if (
        v.signatureMethod === SignatureCollectionMethodEnum.TEAM_MEMBER_DEVICE
        && trim(v.initials)
      ) {
        userData = {
          initials: trim(v.initials),
        };
      }
      const res = await consentUpdate.send({
        params: {
          memberId: patientInfo.id,
          request: {
            consentType: v.signatureMethod,
            userData,
            consentContent: v.consentContent,
          },
        },
      });
      if (showSentModal) {
        showConsentFormSentModal();
      }
      onSubmit?.(v);
      return res;
    };
    await ApiRequestHelper.tryCatch(sendReq(), 'Failed to send consent!');
  };

  const initialValues: ConsentFormComponentProps['initialValues'] = {
    signatureMethod: patientInfo.consentService.signatureMethod
      || SignatureCollectionMethodEnum.APP,
  };

  return (
    <ConsentFormComponent
      onSubmit={handleSubmit}
      isLoading={consentUpdate.isLoading}
      initialValues={initialValues}
      patientId={patientInfo.id}
    />
  );
};
