import { Select, SelectProps } from 'antd';
import { map } from 'lodash';
import { useState } from 'react';
import { SelectType } from '../../../Input/types';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { useDeepCompareMemo } from '../../../../hooks/useDeepCompareEffect';
import { DeviceModelEnum, usePatientDeviceGetAvailableDevicesByModel } from '../../../../uc-api-sdk';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { DeviceHelper } from '../../helper';

export interface DeviceSearchSelectContainerProps extends SelectType<string> {
  deviceModel: DeviceModelEnum;
}

export const DeviceSearchSelectContainer = ({
  deviceModel,
  id,
  value,
  onChange,
}: DeviceSearchSelectContainerProps) => {
  const [searchValue, setSearchValue] = useState<string>();
  const devicesSearchInfo = usePatientDeviceGetAvailableDevicesByModel({});

  const handleOnSearch: SelectProps['onSearch'] = (
    searchValue,
  ) => {
    setSearchValue(searchValue);
    if (!searchValue) return;
    ApiRequestHelper.tryCatch(devicesSearchInfo.send({
      params: {
        searchRequest: {
          filter: {
            deviceModel: DeviceHelper.correctSearchDeviceModel(deviceModel),
            deviceIdNear: searchValue,
          },
          pageInfo: {
            page: 1,
            size: 15,
          },
        },
      },
    }), 'Something went wrong with device id search');
  };

  const debouncedHandleSearch = useDebounce(handleOnSearch, 500, [deviceModel]);

  const options = useDeepCompareMemo(() => (
    map(devicesSearchInfo.data?.data?.content, (d) => ({
      label: d.deviceId,
      value: d.deviceId,
    }))
  ), [devicesSearchInfo.data?.data]);

  return (
    <Select
      key={deviceModel}
      id={id}
      value={value}
      onChange={onChange}
      onSearch={debouncedHandleSearch}
      showSearch
      autoClearSearchValue={false}
      options={options}
      loading={devicesSearchInfo.isLoading}
      defaultActiveFirstOption={false}
      placeholder="Enter Device ID"
      // Don't show dropdown when there is no user input
      notFoundContent={!searchValue ? null : undefined}
    />
  );
};
