import { Select, SelectProps } from 'antd';
import { map, reduce } from 'lodash';
import { FC, useMemo } from 'react';
import { InputType } from '../../../../../../Input/types';
import { WeeklyMealSchedule } from '../../../../../../../uc-api-sdk';

export interface VitalMonitoringBGScheduleOption {
  label: string;
  description: string;
  value: string;
  schedule: WeeklyMealSchedule;
  isCustomized?: boolean;
  isDefault?: boolean;
}

export interface VitalMonitoringBGScheduleValue extends Omit<VitalMonitoringBGScheduleOption, 'label' | 'isCustomized' | 'value'> {
  name: string;
  value?: string;
}

export interface VitalMonitoringBGScheduleSelectComponentProps extends
  InputType<VitalMonitoringBGScheduleValue> {
  options: VitalMonitoringBGScheduleOption[];
}

export const VitalMonitoringBGScheduleSelectComponent: FC<
  VitalMonitoringBGScheduleSelectComponentProps
> = ({
  options,
  value,
  onChange,
}) => {
  const optionDict = useMemo(() => {
    const dict = reduce(options, (res, v) => {
      res[v.value] = v;
      return res;
    }, {} as Record<VitalMonitoringBGScheduleOption['value'], VitalMonitoringBGScheduleOption>);
    return dict;
  }, [options]);

  const optionNameDict = useMemo(() => {
    const dict = reduce(options, (res, v) => {
      res[v.label] = v;
      return res;
    }, {} as Record<VitalMonitoringBGScheduleOption['label'], VitalMonitoringBGScheduleOption>);
    return dict;
  }, [options]);

  const processedOptions = useMemo<VitalMonitoringBGScheduleOption[]>(() => {
    const opts = map(options, (v) => ({
      ...v,
      label: v.isCustomized ? `${v.label} (Customized)` : v.label,
    }));
    return opts;
  }, [options]);

  const handleChange: SelectProps['onChange'] = (v) => {
    onChange?.({
      name: optionDict[v].label,
      description: optionDict[v].description,
      schedule: optionDict[v].schedule,
    });
  };

  return (
    <Select
      options={processedOptions}
      value={value && (value.value || optionNameDict[value.name]?.value || value?.name)}
      onChange={handleChange}
    />
  );
};
