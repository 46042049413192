import { Form } from 'antd';
import {
  Children,
  ReactNode,
  cloneElement,
  createContext,
  isValidElement,
  useContext,
  useState,
} from 'react';
import { uniqueId } from 'lodash';
import { useEffectOnce, useUpdateEffect } from 'usehooks-ts';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import { FormType } from '../../features/Input/types';
import { useNestedFormControlContext } from './NestedFormControlContext';
import { FormOptions } from '../../hooks/useFormHookFactory/useFormHookFactory';

export interface NestedFormContextValue {
  form: FormOptions['form'];
  disabled?: boolean;
}

const NestedFormContext = createContext<
  NestedFormContextValue | undefined
>(undefined);

export const useNestedFormContext = () => {
  const context = useContext(NestedFormContext);
  return context;
};

export interface NestedFormComponentProps {
  form?: FormOptions['form'];
  formName?: string;
  isLoading?: boolean;
  onAfterSubmit?: () => void;
  children: ReactNode;
  detachForm?: boolean; // detach form instance from provider
  submitTimeout?: number; // in ms
}
export const NestedFormComponent = ({
  form,
  formName,
  isLoading,
  onAfterSubmit,
  children,
  detachForm,
  submitTimeout = 5000,
}: NestedFormComponentProps) => {
  const [formId] = useState(formName || uniqueId());
  const {
    isSubmitting,
    addForm,
    cancelFormSubmitActions,
    cleanup,
    afterSubmit,
    setFormValues,
    submitInProgress,
    disabled,
  } = useNestedFormControlContext();

  const internalForm = form || Form.useForm()[0];

  useEffectOnce(() => {
    addForm(formId, internalForm);

    return () => {
      cleanup(formId);
    };
  });

  useUpdateEffect(() => {
    if (detachForm) {
      cleanup(formId);
    } else {
      addForm(formId, internalForm);
    }
  }, [detachForm]);

  useUpdateEffect(() => {
    if (submitInProgress[formId]) {
      setTimeout(() => {
        cancelFormSubmitActions();
      }, submitTimeout);
    }
  }, [submitInProgress[formId], submitTimeout]);

  const contextValue = useGetContextValue<NestedFormContextValue>({
    form: internalForm,
    disabled,
  }, [
    internalForm,
  ]);

  return (
    <NestedFormContext.Provider
      key={formId}
      value={contextValue}
    >
      {
        Children.map(children, (child) => {
          if (!isValidElement(child)) return null;
          return cloneElement(child, {
            ...child.props,
            disabled: child.props.disabled || disabled || isSubmitting,
            onValuesChange: (changedValues: Partial<unknown>, values: unknown) => {
              (child.props as FormType<unknown>)?.onValuesChange?.(changedValues, values);
              setFormValues(formId, values);
            },
            onError: (error?: unknown) => {
              (child.props as FormType<unknown>)?.onError?.(error);
              cancelFormSubmitActions();
            },
            onSubmit: (values: unknown) => {
              (child.props as FormType<unknown>)?.onSubmit?.(values);
              onAfterSubmit?.();
              afterSubmit(formId);
            },
            isLoading: isLoading || isSubmitting,
          });
        })
      }
    </NestedFormContext.Provider>
  );
};
