import React from 'react';
import { ReferenceLevelEnum } from '../../../uc-api-sdk';

export interface ReferenceLevelEnumComponentProps {
  value?: ReferenceLevelEnum;
}

export const ReferenceLevelEnumComponent = ({ value }: ReferenceLevelEnumComponentProps) => {
  const getFullName = () => {
    switch (value) {
      case ReferenceLevelEnum.LOW: return 'Low';
      case ReferenceLevelEnum.NORMAL: return 'Normal';
      case ReferenceLevelEnum.HIGH: return 'High';
      case ReferenceLevelEnum.DIABETES: return 'Diabetes';
      case ReferenceLevelEnum.PRE_DIABETES: return 'Pre-diabetes';
      case ReferenceLevelEnum.UNCONTROLLED: return 'Uncontrolled';
      default: return '';
    }
  };

  return (<>{getFullName()}</>);
};
