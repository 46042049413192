import { useCallback } from 'react';
import { useInsuranceForm } from '../../../../../hooks/formHook/useInsuranceForm';
import FormItem from '../../../../../uiComponent/FormItem/FormItem';
import { InsuranceRelationshipToInsuredComponent } from '../../InsuranceCardComponent/InsuranceCardLayoutComponent';
import { EnumSelectComponent } from '../../../../../uiComponent/EnumSelectComponent/EnumSelectComponent';
import { InsuranceRelationshipToInsuredEnumComponent } from '../../../../../enumComponent/InsuranceRelationshipToInsuredEnumComponent/InsuranceRelationshipToInsuredEnumComponent';
import { InsuranceRelationshipEnum } from '../../../../../uc-api-sdk';

export interface InsuranceRelationshipToInsuredFormItemComponentProps {
  fieldName?: number;
  span?: number;
  required?: boolean;
}

export const InsuranceRelationshipToInsuredFormItemComponent = ({
  fieldName,
  span,
  required,
}: InsuranceRelationshipToInsuredFormItemComponentProps) => {
  const insuranceForm = useInsuranceForm();

  const optionRenderer = useCallback((option: { value: InsuranceRelationshipEnum }) => (
    <InsuranceRelationshipToInsuredEnumComponent value={option.value} />
  ), []);

  return (
    <InsuranceRelationshipToInsuredComponent
      span={span}
      required={required}
    >
      <FormItem
        name={insuranceForm.makeListNamePath('patientRelationship', fieldName)}
        required={required}
      >
        <EnumSelectComponent
          options={InsuranceRelationshipEnum}
          optionRenderer={optionRenderer}
          allowClear
        />
      </FormItem>
    </InsuranceRelationshipToInsuredComponent>
  );
};
