import { Drawer, DrawerProps } from 'antd';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { map } from 'lodash';
import useDebounce from '../../hooks/useDebounce/useDebounce';
import useBoolean from '../../hooks/useBoolean/useBoolean';
import { useExtendableDrawerController } from './controller/ExtendableDrawerController';
import { DefaultExtendableDrawers } from './controller/DefaultExtendableDrawers';
import { EventContextProvider, useEventContext } from '../../contexts/EventProvider/EventProvider';

import './ExtendableDrawerComponent.scss';

const SCROLLING_LAYER_CLASS = 'extendable-drawer-scrolling-layer';

const validateContainer = (drawerContainer?: HTMLElement | null) => {
  if (!drawerContainer) return false;
  return drawerContainer.classList.contains('ant-drawer');
};

const getPlacement = (drawerContainer: HTMLElement) => {
  const { classList } = drawerContainer;
  if (classList.contains('ant-drawer-top')) return 'top';
  if (classList.contains('ant-drawer-bottom')) return 'bottom';
  if (classList.contains('ant-drawer-left')) return 'left';
  return 'right';
};

const getWidthHeight = (el: HTMLElement) => {
  const {
    width,
    height,
  } = el.getBoundingClientRect();
  return {
    width,
    height,
  };
};

const getTransform = (
  placement: string,
  contentWrapper: HTMLElement,
) => {
  const {
    width,
    height,
  } = getWidthHeight(contentWrapper);
  switch (placement) {
    case 'top':
      return [`translateY(${height}px)`, `translateY(calc(${height}px * 2))`];
    case 'bottom':
      return [`translateY(-${height}px)`, `translateY(calc(-${height}px * 2))`];
    case 'right':
      return [`translateX(-${width}px)`, `translateX(calc(-${width}px * 2))`];
    case 'left':
      return [`translateX(${width}px)`, `translateX(calc(${width}px * 2))`];
    default:
      return [undefined, undefined];
  }
};

const getAntDrawerDiv = (wrapperId?: string) => (
  wrapperId ? document.querySelector(`.${wrapperId}`) : undefined
);

const getContainer = (wrapperId?: string) => {
  const antDrawerDiv = getAntDrawerDiv(wrapperId);
  let containerEl: HTMLElement | undefined;
  if (antDrawerDiv) {
    map(antDrawerDiv.childNodes, (child) => {
      if ((child as HTMLElement).classList.contains('ant-drawer-content-wrapper')) {
        containerEl = child as HTMLElement;
      }
    });
  }
  return containerEl;
};

const getZIndex = (wrapperId?: string) => {
  const container = getContainer(wrapperId);
  return container ? -1 : undefined;
};

const closeScrollingLayer = () => document.querySelector(`.${SCROLLING_LAYER_CLASS}`)?.remove();

export enum ExtendDrawerStyleEnum {
  // keep extend next from wrapper/wrapper's extended last drawer
  // if wrapperId is defined by 2 or more extendable drawers, new extendable will overlay
  Regular = 'Regular',
  // remove all wrapper's extended drawers, then extend this
  ReplaceOthers = 'ReplaceOthers',
}

export interface ExtendableDrawerComponentProps extends DrawerProps {
  wrapperId?: string;
  id: DefaultExtendableDrawers;
  open: boolean;
  onClose: () => void;
  showExtendedBorder?: boolean;
  wrapperClassName?: string;
  extendDrawerStyle?: ExtendDrawerStyleEnum;
}

// drawerRoot: div
// drawerContainer: .ant-drawer
// contentWrapper: .ant-drawer-content-wrapper
const Component = ({
  id,
  wrapperId,
  wrapperClassName,
  open,
  onClose,
  children,
  className = '',
  width,
  placement,
  destroyOnClose = true,
  showExtendedBorder = true,
  extendDrawerStyle = ExtendDrawerStyleEnum.Regular,
  ...drawerProps
}: ExtendableDrawerComponentProps) => {
  const {
    rootId,
    containerWidth,
    containerWidthRef,
    setContainerWidth,
    getExtendedWidth,
    getExtendedWrapperId,
    getExtendedOnClose,
    getExtendDrawerStyle,
  } = useExtendableDrawerController(id);
  const isRoot = rootId === id;
  const observerRef = useRef<MutationObserver>();
  const {
    triggerEvent,
  } = useEventContext(id);
  const myWrapperId = wrapperId || getExtendedWrapperId();
  const myExtendDrawerStyle = getExtendDrawerStyle() || extendDrawerStyle;
  const {
    value: isOpen,
    setValue: setIsOpen,
  } = useBoolean(open);
  const [transform, setTransform] = useState<string>();
  const [maskTransform, setMaskTransform] = useState<string>();
  const [hostPlacement, setHostPlacement] = useState<DrawerProps['placement']>();
  const [zIndex, setZIndex] = useState<DrawerProps['zIndex']>(getZIndex(myWrapperId));

  const updateRootContainerWidth = useDebounce((isRemove?: boolean) => {
    if (typeof width !== 'number') return;
    try {
      if (isRemove && isRoot) {
        setContainerWidth(0);
        return;
      }
      const currentContainerWidth = containerWidthRef?.current;
      if (typeof currentContainerWidth === 'number') {
        const widthValue = isRemove ? -width : width;
        const newContainerWidth = currentContainerWidth + widthValue;
        setContainerWidth(newContainerWidth);
      }
    } catch (e) {
      // ignore
    }
  }, 100);

  const cleanUpDrawerStyles = () => {
    setZIndex(getZIndex(myWrapperId));
    triggerEvent();
    updateRootContainerWidth(true);
    if (isRoot) {
      closeScrollingLayer();
    }
  };

  const handleOnClose = useDebounce(async () => {
    if (!open) return;
    const closed = await getExtendedOnClose(onClose)();
    if (closed) {
      cleanUpDrawerStyles();
    }
  }, 50, [
    getExtendedOnClose,
    cleanUpDrawerStyles,
  ]);

  const setDrawerStyles = (
    contentWrapper: HTMLElement,
    drawerContainer: HTMLElement,
  ) => {
    // set placement
    const drawerRoot = drawerContainer.parentElement;
    const newHostPlacement = getPlacement(drawerContainer);
    drawerRoot?.classList.add('extendable-drawer-host');
    drawerRoot?.classList.add(`extendable-to-${newHostPlacement}`);
    if (!showExtendedBorder) drawerRoot?.classList.add('extendable-hide-border');
    if (wrapperClassName) drawerRoot?.classList.add(wrapperClassName);
    setHostPlacement(newHostPlacement);

    // set transform
    const [
      newTransform,
    ] = getTransform(newHostPlacement, contentWrapper);
    setTransform(newTransform);
    // if (typeof width === 'string' && !['px', '%', 'vw'].includes(width)) {
    setMaskTransform(undefined);
  };

  const checkMutation: MutationCallback = (
    mutationList: MutationRecord[],
    observer,
  ) => {
    mutationList.forEach((mutation) => {
      if (
        mutation.type === 'attributes'
        && mutation.attributeName === 'class'
        && (mutation.target as HTMLElement).classList.contains('ant-drawer-content-wrapper-hidden')
      ) {
        handleOnClose();
        observer?.disconnect();
      }
    });
  };

  useEffect(() => {
    if (rootId && open) {
      // add width from all drawer modules mounted to root
      updateRootContainerWidth();
    }
    const contentWrapper = getContainer(myWrapperId);
    const drawerContainer = contentWrapper?.parentElement as HTMLElement;
    if (!contentWrapper || !drawerContainer || !validateContainer(drawerContainer)) {
      if (isRoot) {
        // close previous scrolling layer
        closeScrollingLayer();
      }
      setIsOpen(open);
      return () => 0;
    }
    observerRef.current = new MutationObserver(checkMutation);
    if (open) {
      // handle other extended drawers
      if (myExtendDrawerStyle === ExtendDrawerStyleEnum.ReplaceOthers) {
        // close others extended from given wrapperId
        const extending = drawerContainer.querySelectorAll('[data-id$="drawer-self-destroy"]');
        if (extending.length) {
          extending.forEach((e) => {
            // prevent close wrapper
            if ((e as HTMLElement).dataset?.id?.includes(myWrapperId)) return;
            (e as HTMLElement).click();
          });
        }
      }
      setDrawerStyles(contentWrapper, drawerContainer);
      setTimeout(() => {
        setIsOpen(true);
        observerRef.current?.observe(contentWrapper, {
          attributes: true,
        });
      }, 25);
      return () => 0;
    }
    return () => {
      observerRef.current?.disconnect();
      observerRef.current = undefined;
      cleanUpDrawerStyles();
    };
  }, [open]);

  const getRootContainer = () => {
    if (!open) return undefined;
    let container: HTMLElement;
    const existing = document.querySelector(`.${SCROLLING_LAYER_CLASS}`);
    const scrollingLayer = (
      existing
      || document.createElement('div')
    );
    if (!existing) {
      scrollingLayer.classList.add(SCROLLING_LAYER_CLASS);
      const drawerRoot = document.createElement('div');
      scrollingLayer.appendChild(drawerRoot);
      document.body.appendChild(scrollingLayer);
      container = drawerRoot as HTMLElement;
    } else {
      container = existing.childNodes?.[0] as HTMLElement;
    }
    return container;
  };

  return (
    <Drawer
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...drawerProps}
      open={isOpen}
      onClose={handleOnClose}
      className={classNames({
        [id]: !!id,
        'extendable-drawer': true,
        'extendable-drawer-root': isRoot,
        [className]: !!className,
      })}
      getContainer={() => {
        let container = getContainer(myWrapperId);
        if (isRoot) {
          container = getRootContainer();
        }
        return container as HTMLElement;
      }}
      push={false}
      contentWrapperStyle={{ transform, zIndex }}
      maskStyle={{
        transform: maskTransform,
        zIndex,
      }}
      style={{
        zIndex,
        // @ts-ignore
        '--total-width': rootId ? `${containerWidth}px` : width,
      }}
      placement={hostPlacement || placement}
      width={width || getExtendedWidth()}
      destroyOnClose={destroyOnClose}
    >
      {isOpen && children}
      <div
        data-id={`${id}-drawer-self-destroy`}
        onClick={handleOnClose}
        role="button"
        aria-hidden
      />
    </Drawer>
  );
};

export const ExtendableDrawerComponent = ({
  id,
  ...rest
}: ExtendableDrawerComponentProps) => (
  rest.open
    ? (
      <EventContextProvider
        eventName={id}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component id={id} {...rest} />
      </EventContextProvider>
    ) : null
);
