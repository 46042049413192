import { CheckboxOptionType } from 'antd';
import { VisitTypeEnum } from '../../../uc-api-sdk';

export const visitTypeOptions: CheckboxOptionType[] = [
  { value: VisitTypeEnum.INIT, label: 'Initial Assessment' },
  { value: VisitTypeEnum.TECH_ONBOARDING, label: 'Tech Onboarding' },
  { value: VisitTypeEnum.FOLLOW_UP, label: 'Follow-up' },
  { value: VisitTypeEnum.TECH, label: 'Tech Support' },
  { value: VisitTypeEnum.ADDITIONAL, label: 'Additional' },
  { value: VisitTypeEnum.MNT, label: 'MNT (Medical nutrition therapy)' },
];

export const allVisitTypes: VisitTypeEnum[] = [
  VisitTypeEnum.INIT,
  VisitTypeEnum.TECH_ONBOARDING,
  VisitTypeEnum.FOLLOW_UP,
  VisitTypeEnum.TECH,
  VisitTypeEnum.ADDITIONAL,
  VisitTypeEnum.MNT,
];
