import {
  Component,
  useEffect,
  useState,
} from 'react';

interface LoadSvgAsComponentResult {
  error?: string,
  isLoading: boolean,
  SVGComponent?: typeof Component,
}

const validateSvgSrc = (src: string) => /^(?!\.|\.\.).*(?:\.svg)$/i.test(src);

const getSVGComponent = async (
  src: string,
  onSuccess: (SVGComponent: LoadSvgAsComponentResult['SVGComponent']) => void,
  onError: (errorMsg: string) => void,
) => {
  try {
    const srcWithoutExtension = src?.replace(/.svg/, '');
    const loadedSVGIcon = (
      // credit to https://github.com/facebook/create-react-app/issues/5276#issuecomment-665628393
      // Added extension to make svgr/webpack to implicitly detect only file with .svg
      // Note: it can fail due to icon is not placed in src/assets
      // -svgo: set svgo to false to remove default removeViewBox=true, see https://github.com/boopathi/react-svg-loader/issues/269
      await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!../../assets${srcWithoutExtension}.svg`)
    );
    onSuccess(loadedSVGIcon.default);
  } catch (err) {
    onError(`import icon error, ${err}`);
  }
};

// dynamically load svg with path variables
export const useLoadSvgAsComponent = (
  src?: string,
): LoadSvgAsComponentResult => {
  const [isLoading, setIsLoading] = useState<LoadSvgAsComponentResult['isLoading']>(true);
  const [error, setError] = useState<LoadSvgAsComponentResult['error']>();
  const [SVGComponent, setSVGComponent] = useState<LoadSvgAsComponentResult['SVGComponent']>();

  useEffect(() => {
    if (!src || !validateSvgSrc(src)) {
      setError('Svg src is not valid');
      setIsLoading(false);
      return;
    }
    getSVGComponent(
      src,
      (_SVGComponent) => {
        setSVGComponent(_SVGComponent);
        setIsLoading(false);
      },
      (errorMsg) => {
        setError(errorMsg);
        setIsLoading(false);
      },
    );
  }, [src]);

  return {
    error,
    isLoading,
    SVGComponent,
  };
};
