import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { usePatientCheckCodeIsBillable } from '../../../../uc-api-sdk';
import { ICDTableComponent, ICDTableComponentProps } from '../../component/ICDTableComponent/ICDTableComponent';

export interface ICDTableContainerProps extends ICDTableComponentProps {}

export const ICDTableContainer = (props: ICDTableComponentProps) => {
  const { info } = usePatientContext();
  const billableInfo = usePatientCheckCodeIsBillable({
    options: { sendOnMount: false }
  });

  if (!info?.id) return null;

  const getBillableInfo = (icdCode: string) => (
    billableInfo.send({
      params: {
        memberId: info?.id,
        icdCode,
      },
      options: { cache: true },
    }).then((res) => res?.data)
  );

  return (
    <ICDTableComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      getBillableInfo={getBillableInfo}
    />
  );
};
