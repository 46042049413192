import { TagComponent } from '../../../../../uiComponent/TagComponent/TagComponent';

export const ChartingTagComponent = {
  NoChartingTag: () => (
    <TagComponent type="warning" iconType="halfSolid" background="none" className="incomplete">
      <u>Incomplete</u>
    </TagComponent>
  ),
  ChartingTag: () => (
    <TagComponent type="default" iconType="default" background="none" iconColor="blue" className="completed">
      Complete
    </TagComponent>
  ),
};
