import { DatePicker, DatePickerProps, Table } from 'antd';
import React from 'react';
import { TableProps } from 'antd/es/table';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { BillableTimeSegment } from '../../../../uc-api-sdk';
import { MMM_YYYY } from '../../../../constants/timeFormat';
import { createBillingTimeColumns } from './BillingTimeColumn';
import { PatientCommonCardTitleComponent } from '../../../patient/component/PatientCommonCardTitleComponent/PatientCommonCardTitleComponent';
import './BillingTimeTableComponent.scss';

export interface BillingTimeTableComponentProps extends Omit<TableProps<BillableTimeSegment>, 'column'> {
  onDateChange: DatePickerProps['onChange'];
}

export const BillingTimeTableComponent = ({
  onDateChange,
  ...props
}: BillingTimeTableComponentProps) => {
  const columns = useTableColumns(
    createBillingTimeColumns,
    ['startTime', 'totalTime', 'billableTime', 'status', 'provider', 'activity'],
  );
  const disabledDate: RangePickerProps['disabledDate'] = (current) => current && current < moment().subtract(2, 'year');

  return (
    <>
      <div className="flex mb20 gap2 ai-c">
        <PatientCommonCardTitleComponent>
          Time Activity Log
          <DatePicker
            onChange={onDateChange}
            picker="month"
            disabledDate={disabledDate}
            defaultValue={moment().startOf('month')}
            format={MMM_YYYY}
          />
        </PatientCommonCardTitleComponent>
      </div>
      <Table
        className="BillingTimeTableComponent"
        size="middle"
        rowKey={(v) => v.id}
        columns={columns}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </>
  );
};
