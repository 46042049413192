import {
  Modal, Button, ModalProps, ButtonProps, Tooltip,
} from 'antd';
import {
  FC, ReactNode
} from 'react';
import useBoolean from '../../hooks/useBoolean/useBoolean';

export interface ModalChildrenProps {
  close: () => void;
}

export interface ModalButtonProps extends Omit<ModalProps, 'children'> {
  children: ReactNode | ((props: ModalChildrenProps) => ReactNode);
  button?: ReactNode;
  buttonType?: ButtonProps['type'];
  buttonBlock?: ButtonProps['block'];
  buttonClassName?: ButtonProps['className'];
  disabled?: boolean;
  tooltipTitle?: string;
  trigger?: (onClick: () => void) => ReactNode;
  onClickSendBillableTime?: () => void;
}

export const ModalButton: FC<ModalButtonProps> = ({
  children,
  button,
  title,
  buttonType = 'primary',
  buttonBlock,
  buttonClassName,
  disabled,
  tooltipTitle,
  trigger,
  onClickSendBillableTime,
  onCancel,
  ...props
}) => {
  const { value, setFalse, setTrue } = useBoolean(false);

  const handleOpenModal = () => {
    onClickSendBillableTime?.();
    setTrue();
  };

  const handleCloseModal: ModalProps['onCancel'] = (e) => {
    setFalse();
    onCancel?.(e);
  };

  const getContent = () => {
    if (typeof children === 'function') {
      return children({
        close: setFalse,
      });
    }
    return children;
  };

  const getButton = () => (
    <Button
      type={buttonType}
      onClick={handleOpenModal}
      disabled={disabled}
      block={buttonBlock}
      className={buttonClassName}
    >
      {button}
    </Button>
  );

  const getTooltip = () => (
    tooltipTitle
      ? (
        <Tooltip title={tooltipTitle}>
          {getButton()}
        </Tooltip>
      )
      : getButton()
  );

  return (
    <>
      <Modal
        title={title}
        open={value}
        footer={null}
        onCancel={handleCloseModal}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {getContent()}
      </Modal>
      {
        trigger
          ? trigger(setTrue)
          : getTooltip()
      }
    </>
  );
};

export default ModalButton;
