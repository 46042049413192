import { Drawer } from 'antd';
import { ReactNode } from 'react';
import './OnCallDrawerComponent.scss';

export interface OnCallDrawerComponentProps {
  open?: boolean;
  onClose?: () => void;
  children?: ReactNode | undefined;
}
export const OnCallDrawerComponent = ({
  open,
  onClose,
  children,
}: OnCallDrawerComponentProps) => (
  <Drawer
    open={open}
    onClose={onClose}
    className="on-call-drawer"
    title="Select Cover Range"
    width={920}
    destroyOnClose
  >
    {children}
  </Drawer>
);
