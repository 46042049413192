import dayjs from 'dayjs';
import { SimpleDateRange } from '../uc-api-sdk';

export interface FollowUpVisitWindowServiceArg {
  windowRange: SimpleDateRange;
}

export class FollowUpVisitWindowService {
  private followUpVisitWindow: SimpleDateRange;

  constructor(followUpVisitWindow: SimpleDateRange) {
    this.followUpVisitWindow = followUpVisitWindow;
  }

  public get from() {
    return this.followUpVisitWindow.startDate
      ? dayjs(this.followUpVisitWindow.startDate)
      : undefined;
  }

  public get to() {
    return this.followUpVisitWindow.endDate
      ? dayjs(this.followUpVisitWindow.endDate)
      : undefined;
  }
}
