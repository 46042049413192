import { useState } from 'react';
import dayjs from 'dayjs';
import { useBoolean } from 'usehooks-ts';
import { FutureClinicEventContextProvider } from '../../../../contexts/FutureClinicEventContext/FutureClinicEventContext';
import { NestedFormControlProvider } from '../../../../contexts/NestedFormControlContext/NestedFormControlContext';
import {
  Sort$Direction,
  VisitTypeEnum, usePatientSearchMonthlyReview
} from '../../../../uc-api-sdk';
import { NonTechWorklistComponent } from '../../component/NonTechWorklistComponent/NonTechWorklistComponent';
import { SkipFollowUpScheduleSubmitValue } from '../../hook/useSkipFollowUpScheduleForm';
import { WorklistComponentCommonProps } from '../../types';
import { WorklistMTPRContextProvider } from '../../../../contexts/WorklistMTPRContext/WorklistMTPRContext';
import { YYYYMM } from '../../../../constants/timeFormat';
import { MTPRService } from '../../../mtpr/service';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';

export interface NonTechWorklistContainerProps extends WorklistComponentCommonProps { }

export const NonTechWorklistContainer = ({
  patientId,
  clinicEvent,
  isLoading,
  onClose,
  onClinicEventUpdate,
  onCheckOut,
  onCompleteCharting,
  disabled,
}: NonTechWorklistContainerProps) => {
  const [
    followupVisitSkipped,
    setSkipFollowUpSchedule,
  ] = useState(clinicEvent.followupVisitSkipped);
  const {
    value: worklistHasUpdate,
    setTrue: setWorklistHasUpdate,
  } = useBoolean(false);
  const workListUpdated = useUpdate('WORKLIST_UPDATED');
  const monthlyReviewInfo = usePatientSearchMonthlyReview({
    params: {
      searchRequest: {
        filter: {
          memberId: patientId,
          monthOfYear: parseInt(dayjs().endOf('d').format(YYYYMM), 10),
        },
        pageInfo: {
          page: 1,
          size: 1,
          sort: [
            { property: 'createdAt', direction: Sort$Direction.DESC },
          ]
        }
      },
    },
    options: {
      sendOnMount: MTPRService.isVisitEligible(clinicEvent.visitType)
    }
  });

  const monthlyReviewList = monthlyReviewInfo.data?.data;

  const handleOnAllFormValuesChange = (values: unknown) => {
    const {
      followupVisitSkipped: newSkipFollowUpSchedule,
    } = (values || {}) as SkipFollowUpScheduleSubmitValue;
    if (newSkipFollowUpSchedule !== undefined) setSkipFollowUpSchedule(newSkipFollowUpSchedule);
  };

  const handleCompleteCharting: WorklistComponentCommonProps['onCompleteCharting'] = (
    values,
    closeOnDone,
  ) => {
    workListUpdated.updateValue();
    onCompleteCharting?.(values, closeOnDone);
  };

  const handleOnClose = (hasUpdate?: boolean) => {
    if (hasUpdate || worklistHasUpdate) {
      workListUpdated.updateValue();
    }
    onClose?.();
  };

  return (
    <NestedFormControlProvider
      disabled={disabled}
      onAllFormValuesChange={handleOnAllFormValuesChange}
      onAfterSubmit={() => setWorklistHasUpdate()}
    >
      <FutureClinicEventContextProvider
        patientId={patientId}
        startTime={clinicEvent?.startTimeUtc}
      >
        <WorklistMTPRContextProvider
          clinicEventId={clinicEvent.id || ''}
          patientId={patientId}
          visitType={clinicEvent.visitType as VisitTypeEnum}
          monthlyReviewList={monthlyReviewList}
          refetch={monthlyReviewInfo.refetch}
          isLoading={isLoading}
        >
          <NonTechWorklistComponent
            patientId={patientId}
            clinicEvent={clinicEvent}
            isLoading={isLoading}
            onClose={handleOnClose}
            onClinicEventUpdate={onClinicEventUpdate}
            onCheckOut={onCheckOut}
            onCompleteCharting={handleCompleteCharting}
            followupVisitSkipped={followupVisitSkipped}
          />
        </WorklistMTPRContextProvider>
      </FutureClinicEventContextProvider>
    </NestedFormControlProvider>
  );
};
