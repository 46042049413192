import { Button } from 'antd';
import { ReactNode } from 'react';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { PreviewAndResignConsentComponent } from '../../../consentForm/component/PreviewAndResignConsentComponent/PreviewAndResignConsentComponent';

export interface PatientProfileConsentFormButtonComponentProps {
  patientInfo: PatientInfo;
  buttonText?: string;
  children?: ReactNode;
}

export const PatientProfileConsentFormButtonComponent = ({
  patientInfo,
  buttonText = 'Consent Form',
  children = (<Button>{buttonText}</Button>),
}: PatientProfileConsentFormButtonComponentProps) => (
  <PreviewAndResignConsentComponent
    patientInfo={patientInfo}
    fetchByContainer
  >
    {children}
  </PreviewAndResignConsentComponent>
);
