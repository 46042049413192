import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { useTableColumnWithFiltersAndSorters } from '../../../../hooks/useTableColumnWithFiltersAndSorters/useTableColumnWithFiltersAndSorters';
import { PatientsTableComponent } from '../../../patient/component/PatientsTableComponent/PatientsTableComponent';
import { PatientTableProps } from '../../../patients/type';
import { createProviderViewPatientColumns } from '../ProviderViewTablesComponent/columns';

export interface ProviderViewEnrolledPatientsTableComponentProps extends PatientTableProps {
}

export const ProviderViewEnrolledPatientsTableComponent = ({
  totalSize,
  filters,
  sorters,
  ...props
}: ProviderViewEnrolledPatientsTableComponentProps) => {
  const columns = useTableColumns(
    createProviderViewPatientColumns,
    ['name', 'age', 'gender', 'program', 'vitals', 'RPMDays', 'billableTime', 'billable', 'clinic', 'assignedRdHC', 'assignedCA', 'enrollmentDate', 'insuranceProvider', 'bpDeviceMacId', 'bpPairDate', 'bpDeviceLastUsed', 'bgDeviceMacId', 'bgPairDate', 'bgDeviceLastUsed'],
  );

  const columnsWithFilters = useTableColumnWithFiltersAndSorters({
    columns,
    filters,
    sorters: sorters || { columnKey: 'enrollmentDate', order: 'descend' },
  });

  return (
    <PatientsTableComponent
      totalSize={totalSize}
      columns={columnsWithFilters}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};
