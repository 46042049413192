/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable max-len */
// This file should be removed once the code gen starts generating baseController
import { useMemo } from 'react';
import {
  APIResponse, APIResponseClass, PageResponse, PatientArticles, patientArticlesService,
} from '../../../../uc-api-sdk';
import {
  GetOptions, MakeRequestHookConfig, UseRequestFactory, useRequestFactory,
  BaseAPIResponse, BaseGetParams, BaseSearchParams,
} from '../../../../uc-api-sdk/staticFiles/useReqHook';

interface PatientIdFilter {
  patientId: PatientArticles['patientId'],
}

export const createGetPatientArticlesObject = (value: APIResponse<PageResponse<PatientArticles>>) => new APIResponseClass<PageResponse<PatientArticles>>(value);

export const useGetPatientArticles = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<PatientIdFilter>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<PatientIdFilter>,
    BaseAPIResponse<PageResponse<PatientArticles>>,
    // @ts-ignore
    typeof patientArticlesService.search,
    GetOptions,
    APIResponseClass<PageResponse<PatientArticles>>
  > = useMemo(() => ({
    reqFunc: (...args: Parameters<typeof patientArticlesService.search>) => (
      patientArticlesService.search(...args)
    ),
    ResponseClassCreator: createGetPatientArticlesObject,
    typeName: 'useGetPatientArticles',
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};
