import { Drawer } from 'antd';
import { EnrollmentContainer } from '../../container/EnrollmentContainer/EnrollmentContainer';
import { LargeDrawerWidth } from '../../../../constants/drawer';
import { Step } from '../../context/EnrollmentStepContext/EnrollmentStepContext';

export interface EnrollmentDrawerComponentProps {
  patientId: string;
  initialStep?: Step;
  isOpen: boolean;
  onClose: () => void;
}

export const EnrollmentDrawerComponent = ({
  patientId,
  initialStep,
  isOpen,
  onClose,
}: EnrollmentDrawerComponentProps) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Drawer width={LargeDrawerWidth} open={isOpen} closable={false} destroyOnClose>
      <EnrollmentContainer
        patientId={patientId}
        onClose={handleClose}
        initialStep={initialStep}
      />
    </Drawer>
  );
};
