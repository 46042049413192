import { isEmpty, map } from 'lodash';
import { Divider } from 'antd';
import { ClinicGoalEnum, ClinicalGoalItem } from '../../../../../uc-api-sdk';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';
import { ClinicalGoalEnumComponent } from '../../../../../enumComponent/ClinicalGoalEnumComponent/ClinicalGoalEnumComponent';
import { ConditionEumComponent } from '../../../../../enumComponent/ConditionEumComponent/ConditionEumComponent';

export interface PrintCarePlanClinicalGoalComponentProps {
  clinicalGoals?: ClinicalGoalItem[] | null;
}

export const PrintCarePlanClinicalGoalComponent = ({
  clinicalGoals,
}: PrintCarePlanClinicalGoalComponentProps) => {
  if (isEmpty(clinicalGoals)) {
    return null;
  }
  return (
    <>
      <div className="page-break" />
      <TextComponent className="pop-text">
        Clinical Goal
      </TextComponent>

      {
        map(clinicalGoals, (clinicalGoal, idx) => (
          <div key={idx} className="mb10">
            <TextComponent size="medium" bold>
              {
                clinicalGoal.customCategory
                  ? clinicalGoal.customCategory
                  : <ConditionEumComponent value={clinicalGoal.condition ?? undefined} />
              }
            </TextComponent>
            <div>
              {
                clinicalGoal.customContent
                  ? clinicalGoal.customContent
                  : (
                    <ClinicalGoalEnumComponent
                      value={clinicalGoal.clinicalGoalName as ClinicGoalEnum}
                    />
                  )
              }
            </div>
          </div>
        ))
      }

      <Divider />
    </>
  );
};
