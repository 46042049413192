import moment, { Moment } from 'moment';
import { USA_DATE } from '../../../../constants/timeFormat';
import { makeAddressString } from '../../../../helpers/address';
import {
  BillingFeeModelEnum,
  EhrOrgMappingWithDetail,
  EhrServiceName,
  Employee,
  LimitedClinic,
  MedicalOrganization,
  MedicalOrganizationAssignment
} from '../../../../uc-api-sdk';

export class MedicalOrgService {
  public medicalOrg?: MedicalOrganization;

  protected assignment?: MedicalOrganizationAssignment;

  protected members?: Employee[];

  protected ehrSettings?: EhrOrgMappingWithDetail[];

  constructor(
    medicalOrg?: MedicalOrganization,
    assignment?: MedicalOrganizationAssignment,
    members?: Employee[],
    ehrSettings?: EhrOrgMappingWithDetail[]
  ) {
    this.medicalOrg = medicalOrg;
    this.assignment = assignment;
    this.members = members;
    this.ehrSettings = ehrSettings;
  }

  public get feeModel() {
    return this.medicalOrg?.feeModel;
  }

  public get feeModelType() {
    return this.feeModel?.type;
  }

  public get isSaasModel() {
    return this.medicalOrg?.isSaasModel || false;
  }

  public get feeList() {
    switch (this.feeModel?.type) {
      case BillingFeeModelEnum.PER_CODE:
        return Object.values(this.feeModel?.perCode || {});
      case BillingFeeModelEnum.PBPM:
        return this.feeModel?.pbpm ? [this.feeModel.pbpm.unitFee] : [];
      case BillingFeeModelEnum.PEPM:
        return this.feeModel?.pepm ? [this.feeModel.pepm.unitFee] : [];
      case BillingFeeModelEnum.PMAPM:
        return this.feeModel?.pmapm ? [this.feeModel.pmapm.unitFee] : [];
      default:
        return [];
    }
  }

  public get address1String() {
    return makeAddressString(this.medicalOrg?.profile?.addresses?.[0]);
  }

  public get careGroupId() {
    return this.assignment?.careGroupId;
  }

  public get ehrSettingsList() {
    return this.ehrSettings;
  }

  public get athenaPracticeId() {
    return this.ehrSettings?.find((setting) => setting.ehr === EhrServiceName.ATHENA)?.ehrOrgId;
  }

  public get athenaDepartments() {
    return this.ehrSettings?.filter((setting) => setting.ehr === EhrServiceName.ATHENA);
  }

  public get athenaDepartmentIds() {
    return this.athenaDepartments?.map((setting) => setting.ehrDepartmentId).filter(Boolean) || [];
  }

  public get careQualityMedicalOrg() {
    return this.ehrSettings?.find((setting) => setting.ehr === EhrServiceName.CAREQUALITY);
  }

  public get integrationFeature() {
    return this.medicalOrg?.integrationFeature;
  }

  public get enableAdvancedConfiguration() {
    return this.integrationFeature?.isAutoBillingEnabled
      || this.ehrSettingsList?.find((setting) => setting.ehr === EhrServiceName.ATHENA);
  }

  public get id() {
    return this.medicalOrg?.id;
  }

  public get name() {
    return this.medicalOrg?.profile?.alias;
  }

  public get orgNumber() {
    return this.medicalOrg?.profile?.orgNumber;
  }

  public get status() {
    return this.medicalOrg?.status;
  }

  public get type() {
    return this.medicalOrg?.type;
  }

  public get enableSharePatientAccess() {
    return this.medicalOrg?.enableSharePatientAccess ?? false;
  }

  public get businessName() {
    return this.medicalOrg?.profile?.businessName;
  }

  public get description() {
    return this.medicalOrg?.profile?.description;
  }

  public get address1() {
    return this.medicalOrg?.profile?.address;
  }

  public get address2() {
    return this.medicalOrg?.profile?.address2;
  }

  public get timezone() {
    return this.medicalOrg?.profile?.timezone;
  }

  public get profile() {
    return this.medicalOrg?.profile;
  }

  public get serviceConfig() {
    return this.medicalOrg?.serviceConfig;
  }

  public get careServiceConfig() {
    return this.medicalOrg?.careServiceConfig;
  }

  public get ehr() {
    return this.medicalOrg?.integrationFeature?.ehrServiceProvider;
  }

  public get programs() {
    return this.medicalOrg?.programParticipation;
  }

  public get iHealthMembers() {
    return this.members;
  }

  public get billingEntity() {
    return this.medicalOrg?.billingEntity;
  }

  public get careQualityStatus() {
    return this.medicalOrg?.careQualityStatus;
  }

  public get careQualityHistory() {
    return this.medicalOrg?.careQualityHistory;
  }

  public get terminationInitiatedDate(): Moment | null {
    return this.medicalOrg?.terminationInitiatedDate
      ? moment(this.medicalOrg.terminationInitiatedDate)
      : null;
  }

  public get terminationDate(): Moment | null {
    return this.medicalOrg?.terminationDate
      ? moment(this.medicalOrg.terminationDate)
      : null;
  }

  public get formattedInitiatedDate(): string {
    const initiatedDate = this.terminationInitiatedDate;
    return initiatedDate ? initiatedDate.format(USA_DATE) : 'N/A';
  }

  public get formattedTerminationDate(): string {
    const formattedTerminationDate = this.terminationDate;
    return formattedTerminationDate ? formattedTerminationDate.format(USA_DATE) : 'N/A';
  }

  static getMedicalOrgName(medicalOrg: MedicalOrganization, isProvider?: boolean) {
    return isProvider ? medicalOrg.profile?.businessName : `${medicalOrg.profile?.orgNumber} ${medicalOrg.profile?.alias}`.trim();
  }

  static getLimitedClinicName(clinic: LimitedClinic, isProvider?: boolean) {
    return isProvider ? clinic.businessName : `${clinic.orgNumber} ${clinic.alias}`.trim();
  }
}
