import {
  ReactNode,
  createContext,
  useContext,
  useMemo,
} from 'react';
import { useOnCallGet, useOnCallSave } from '../../../../uc-api-sdk';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';

export type OnCallType = 'patientCare' | 'task';

export interface OnCallMainContextProps {
  children: ReactNode;
}

export interface OnCallMainContextValue {
  onCallGet: ReturnType<typeof useOnCallGet>;
  onCallSave: ReturnType<typeof useOnCallSave>;
  isLoading: boolean;
}

const initialValue: OnCallMainContextValue = {
  // @ts-ignore
  onCallGet: undefined,
  // @ts-ignore
  onCallSave: undefined,
  isLoading: false,
};

export const OnCallMainContext = createContext<OnCallMainContextValue>(initialValue);

export const OnCallMainContextProvider = ({
  children,
}: OnCallMainContextProps) => {
  const { userId = '' } = useLoggedInUserFromContext();
  const onCallSave = useOnCallSave({});
  const onCallGet = useOnCallGet({
    options: { sendOnMount: true },
    params: { id: userId },
  });

  const isLoading = useMemo(() => (
    onCallGet?.isLoading || onCallSave?.isLoading || false
  ), [
    onCallGet?.isLoading,
    onCallSave?.isLoading,
  ]);

  const value = useMemo<OnCallMainContextValue>(() => ({
    onCallGet,
    onCallSave,
    isLoading,
  }), [
    onCallGet.data,
    onCallGet.error,
    onCallGet.isLoading,
    onCallSave.data,
    onCallSave.error,
    onCallSave.isLoading,
  ]);

  return (
    <OnCallMainContext.Provider value={value}>
      {children}
    </OnCallMainContext.Provider>
  );
};

export const useOnCallMainContext = () => {
  const value = useContext(OnCallMainContext);
  return value;
};
