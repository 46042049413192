import { MedicationTypeEnum } from '../../uc-api-sdk';

export const MED_TYPES = [
  {
    value: MedicationTypeEnum.ORAL,
    label: 'Oral',
    text: 'Oral',
  },
  {
    value: MedicationTypeEnum.EXTERNAL,
    label: 'External',
    text: 'External',
  },
  {
    value: MedicationTypeEnum.INJECTABLE,
    label: 'Injectable',
    text: 'Injectable',
  },
  {
    value: MedicationTypeEnum.INHALED,
    label: 'Inhalable',
    text: 'Inhalable',
  },
];
