import {
  ReactNode,
  createContext,
  useContext,
  useState,
} from 'react';
import {
  difference,
  intersection,
  last,
  map,
  some,
  sortBy,
  uniqBy,
} from 'lodash';
import dayjs from 'dayjs';
import { message } from 'antd';
import { useEffectOnce } from 'usehooks-ts';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import {
  MessageChannel,
  MessageChannelMap,
  MessageUserInfo,
  MessageUserMap,
} from '../../types/message';
import {
  MessageHistory,
  MessageHistoryMessages,
} from '../../services/CHSServices/types/data';
import { useGetNotificationChannelGroup } from '../../services/CHSServices/hooks/useGetNotificationChannelGroup';
import { useLoggedInUserFromContext } from '../loggedInUserContext';
import { useGetPaginatedChannels } from '../../services/CHSServices/hooks/useGetPaginatedChannels';
import { useGetChannelHistory } from '../../services/CHSServices/hooks/useGetChannelHistory';
import { useTagMessage } from '../../services/CHSServices/hooks/useTagMessage';
import { useMarkReadTagMessage } from '../../services/CHSServices/hooks/useMarkReadTagMessage';
import { useMarkUnreadTagMessage } from '../../services/CHSServices/hooks/useMarkUnreadTagMessage';
import { PaginatedChannelsParams } from '../../services/CHSServices/types';
import { MAX_MSG_PER_CHANNEL, UNKNOWN_VALUE } from '../../services/CHSServices/constants';
import { CHSServices, chsServices } from '../../services/CHSServices/CHSServices';
import {
  Patient,
  RoleTypeEnum,
  useEmployeeSearch,
  usePatientSearch,
} from '../../uc-api-sdk';
import EmployeeInfo from '../../hooks/useUserInfo/employeeInfo';
import { Employee } from '../../types/user';
import { useDeepCompareMemo } from '../../hooks/useDeepCompareEffect';
import { useInitChannel } from '../../services/CHSServices/hooks/useInitChannel';
import { MessageStorageContextProvider } from './MessageStorageContext';
import { useMixpanelContext } from '../MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../MixpanelContext/MixpanelEvents';

export interface MessageServicesContextValue {
  channelMap: MessageChannelMap;
  getChannel: (patientId?: string) => MessageChannel | undefined;
  userMap: MessageUserMap;
  loggedInUserHasUnread: boolean;
  handleSetChannel: (
    patientId: string,
    messageHistory: MessageHistory,
    isOngoing?: boolean,
  ) => void;
  handleSetUserMap: (
    userId: string,
    isPatient: boolean,
  ) => void;
  handleSetMultiplePatientMap: (
    patientIds: string[],
  ) => void;
  handleSetMultipleUserMap: (
    patientIds: string[],
    employeeIds?: string[],
  ) => void;
  handleSetPatientMapData: (
    patientInfo: Patient,
  ) => void;
  patientSearchLoading: boolean;
  paginatedChannelsLoading: boolean;
  getChannelHistoryLoading: boolean;
  handleGetNotificationChannelGroup: () => ReturnType<ReturnType<typeof useGetNotificationChannelGroup>['send']>;
  handleGetPaginatedChannels: (
    params: PaginatedChannelsParams
  ) => ReturnType<ReturnType<typeof useGetPaginatedChannels>['send']>;
  handleGetUnreadPaginatedChannels: () => void;
  handleGetChannelHistory: (
    patientIds: string[],
    fromTimestamp?: string,
  ) => ReturnType<ReturnType<typeof useGetChannelHistory>['send']>;
  handleInitChannel: (
    patientId: string,
  ) => ReturnType<ReturnType<typeof useInitChannel>['send']>;
  handleTagMessage: (
    messageId: string,
    taggedTo: string,
  ) => ReturnType<ReturnType<typeof useTagMessage>['send']>;
  handleToggleTagMessage: (
    messageId: string,
    isRead: boolean,
  ) => void;
  checkChannelHasUnread: (
    patientId: string,
  ) => boolean;
}

const MessageServicesContext = createContext<MessageServicesContextValue | undefined>(undefined);

export const useMessageServicesContext = () => {
  const context = useContext(MessageServicesContext);
  return (context || {}) as MessageServicesContextValue;
};

export interface MessageServicesContextProviderProps {
  children: ReactNode;
}
export const MessageServicesContextProvider = ({
  children,
}: MessageServicesContextProviderProps) => {
  const {
    loginInfo,
    userInfo,
    userId,
  } = useLoggedInUserFromContext();
  const { send } = useMixpanelContext();

  const [channelMap, setChannelMap] = useState<MessageServicesContextValue['channelMap']>({});
  const [userMap, setUserMap] = useState<MessageServicesContextValue['userMap']>({});

  const employeeSearchInfo = useEmployeeSearch({
    options: { sendOnMount: false },
  });
  const patientSearchInfo = usePatientSearch({});

  const notificationChannelGroupInfo = useGetNotificationChannelGroup();
  const paginatedChannelsInfo = useGetPaginatedChannels();
  const getChannelHistoryInfo = useGetChannelHistory();
  const tagMessageInfo = useTagMessage();
  const markReadTagMessageInfo = useMarkReadTagMessage();
  const markUnreadTagMessageInfo = useMarkUnreadTagMessage();
  const initChannelInfo = useInitChannel();

  const processChannel = (
    patientId: string,
    messageHistory: MessageHistory,
    oldChannelInfo = {} as MessageChannel,
  ): MessageChannel => {
    try {
      const {
        messages,
        tagMessageOffset,
        lastClientACK,
      } = messageHistory;
      const nonEmptyMessages = messages || [];
      const messageCount = nonEmptyMessages.length;
      const hasMoreMessage = messageCount === MAX_MSG_PER_CHANNEL;
      const lastMessageInHistory = last(nonEmptyMessages) || {} as MessageHistoryMessages;
      const tagMessageOffsetFrom = lastMessageInHistory.timestamp;

      const channelHistoryMessages = oldChannelInfo?.messages || [];
      const combinedHistoryWithDatetime = [
        ...nonEmptyMessages,
        ...channelHistoryMessages
      ].map((msg) => ({
        ...msg,
        dateTime: CHSServices.convertTimestamp(msg.timestamp),
      }));
      const dedupedHistoryMessages = uniqBy(combinedHistoryWithDatetime, (msg) => msg.dateTime);
      const readableMessages = CHSServices.getListOfReadableMessages(dedupedHistoryMessages);
      const sortedHistoryMessages = sortBy(readableMessages, (msg) => msg.dateTime);

      const fromTimestamp = chsServices.getFromTimestamp(sortedHistoryMessages);

      const {
        isUnread,
        unreadMessages,
        lastMsg,
      } = chsServices.handleUnreadAndLastMsg(
        {
          ...messageHistory,
          messages: sortedHistoryMessages,
        },
      );

      const channelInfo: MessageChannel = {
        ...oldChannelInfo,
        patientId,
        isUnread,
        unreadMessages,
        lastMsg,
        messages: sortedHistoryMessages,
        fromTimestamp,
        hasMoreMessage,
        tagMessageOffset,
        tagMessageOffsetFrom,
        lastClientACK,
      };
      return channelInfo;
    } catch (error) {
      send({
        event: MixpanelEvents.MessageError,
        properties: {
          error,
          patientId,
          messageHistory,
        },
      });
      return { ...oldChannelInfo };
    }
  };

  const handleSetChannel: MessageServicesContextValue['handleSetChannel'] = (
    patientId,
    messageHistory,
    isOngoing,
  ) => {
    setChannelMap((prevMap) => {
      if (!patientId) return prevMap;

      const patientChannel = processChannel(
        patientId,
        messageHistory,
        prevMap[patientId],
      );

      if (isOngoing !== undefined) {
        patientChannel.isOngoing = isOngoing;
      }

      return {
        ...prevMap,
        [patientId]: patientChannel,
      };
    });
  };

  const processPatientUserMap = (
    patient: Patient,
  ) => ({
    firstName: patient.profile?.firstName || UNKNOWN_VALUE,
    lastName: patient.profile?.lastName || UNKNOWN_VALUE,
    avatar: patient.profile?.avatar?.thumbnailLink,
  });

  const processEmployeeUserMap = (
    employee: Employee,
  ) => {
    const employeeInfo = new EmployeeInfo({ employee });
    return {
      firstName: employeeInfo.profile?.firstName || UNKNOWN_VALUE,
      lastName: employeeInfo.profile?.lastName || UNKNOWN_VALUE,
      avatar: employeeInfo?.avatar,
    };
  };

  const handleSetUserMap: MessageServicesContextValue['handleSetUserMap'] = async (
    userId,
    isPatient,
  ) => {
    if (!userId || userMap[userId]) return;
    try {
      let userInfo = {} as MessageUserInfo;
      if (isPatient) {
        const res = await patientSearchInfo.send({
          params: {
            filter: { id: userId },
            pageInfo: { pagination: false },
          },
        });
        if (!res?.data || res.code !== 200) {
          throw new Error('Failed to get patient info');
        }
        const patient = res.data?.content?.[0] || {};
        userInfo = processPatientUserMap(patient);
      } else if (!userMap[userId]) {
        const res = await employeeSearchInfo.send({
          params: {
            filter: { id: userId },
            pageInfo: { pagination: false }
          },
        });
        if (!res?.data || res.code !== 200) {
          throw new Error('Failed to get patient info');
        }
        userInfo = processEmployeeUserMap(res.data as Employee);
      }
      if (Object.keys(userInfo).length > 0) {
        setUserMap((prevMap) => ({
          ...prevMap,
          [userId]: userInfo,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSetMultiplePatientMap: MessageServicesContextValue['handleSetMultiplePatientMap'] = async (
    patientIds,
  ) => {
    if (!patientIds?.length) return;
    const patientIdsToSearch = difference(patientIds, Object.keys(userMap));
    const res = await patientSearchInfo.send({
      params: {
        filter: { idIn: { in: patientIdsToSearch } },
        pageInfo: { pagination: false }
      },
    });
    if (!res?.data || res.code !== 200) {
      console.error('Failed to get patient info', res?.msg);
      return;
    }
    const patients = res.data?.content || [];
    const patientsUserMap = Object.assign({}, ...patients.map((p) => ({
      [p.id as string]: processPatientUserMap(p),
    })));
    setUserMap((prevMap) => ({
      ...prevMap,
      ...patientsUserMap,
    }));
  };

  const handleSetMultipleEmployeeMap = async (
    employeeIds?: string[],
  ) => {
    if (!employeeIds || !employeeIds.length) return;
    const employeeIdsToSearch = difference(employeeIds, Object.keys(userMap));
    const res = await employeeSearchInfo.send({
      params: {
        filter: { idIn: { in: employeeIdsToSearch } },
        pageInfo: { pagination: false },
      }
    });
    if (!res?.data || res.code !== 200) {
      console.error('Failed to get patient info', res?.msg);
      return;
    }
    const employees = res.data?.content || [];
    const employeeUserMap = Object.assign({}, ...employees.map((e) => ({
      [e.id as string]: processEmployeeUserMap(e as Employee),
    })));
    setUserMap((prevMap) => ({
      ...prevMap,
      ...employeeUserMap,
    }));
  };

  const handleSetMultipleUserMap: MessageServicesContextValue['handleSetMultipleUserMap'] = (
    patientIds,
    employeeIds,
  ) => {
    handleSetMultiplePatientMap(patientIds);
    handleSetMultipleEmployeeMap(employeeIds);
  };

  const handleSetPatientMapData: MessageServicesContextValue['handleSetPatientMapData'] = (
    patientInfo,
  ) => {
    if (!patientInfo?.id) return;
    setUserMap((prevMap) => ({
      ...prevMap,
      [patientInfo.id as string]: processPatientUserMap(patientInfo),
    }));
  };

  const handleGetNotificationChannelGroup: MessageServicesContextValue['handleGetNotificationChannelGroup'] = () => (
    notificationChannelGroupInfo.send({
      params: { authKey: loginInfo?.chatInfo?.authKey || '' },
    })
  );

  const handleGetPaginatedChannels: MessageServicesContextValue['handleGetPaginatedChannels'] = (
    params,
  ) => (
    paginatedChannelsInfo.send({
      params,
    })
  );

  const handleGetChannelHistory: MessageServicesContextValue['handleGetChannelHistory'] = (
    patientIds,
    fromTimestamp,
  ) => {
    if (!patientIds?.length) return Promise.resolve([]);
    return getChannelHistoryInfo.send({
      params: {
        count: MAX_MSG_PER_CHANNEL,
        patientIds,
        origin: 'new',
        ignoreACK: true,
        fromTimestamp,
      },
    });
  };

  const handleInitChannel: MessageServicesContextValue['handleInitChannel'] = async (
    patientId,
  ) => {
    // response:
    // init=true, fresh=true => no channel before + init successfully
    // init=false, fresh = false/true => had channel before
    // init=true, fresh=false => issue with server
    const res = await initChannelInfo.send({
      params: {
        patientId,
        userId: userId || '',
      }
    });
    if (
      res
      && res.initChannel
      && !res.freshSubscription
    ) {
      message.error('Failed to subscribe the channel. Please refresh the page again.');
      return undefined;
    }
    return res;
  };

  const handleTagMessage: MessageServicesContextValue['handleTagMessage'] = (
    messageId,
    taggedTo,
  ) => (
    tagMessageInfo.send({
      params: {
        messageId,
        taggedBy: userId || '',
        taggedTo,
      },
    })
  );

  const handleToggleTagMessage: MessageServicesContextValue['handleToggleTagMessage'] = (
    messageId,
    isRead,
  ) => {
    const fn = isRead ? markUnreadTagMessageInfo.send : markReadTagMessageInfo.send;
    return fn({
      params: {
        messageId,
      },
    });
  };

  const getChannel: MessageServicesContextValue['getChannel'] = (
    patientId,
  ) => (
    patientId ? channelMap[patientId] : undefined
  );

  const checkChannelHasUnread: MessageServicesContextValue['checkChannelHasUnread'] = (patientId) => {
    const redDotFlag = true;
    const {
      isUnread,
    } = channelMap[patientId] || {};
    return redDotFlag && isUnread;
  };

  const handleGetUnreadPaginatedChannels: MessageServicesContextValue['handleGetUnreadPaginatedChannels'] = async () => {
    const now = dayjs().valueOf();
    // get channels
    const res = await handleGetPaginatedChannels({
      pageNumber: CHSServices.parseTimestamp(now),
      fromTimestamp: CHSServices.parseTimestamp(now),
      unread: true,
      pageSize: undefined,
    });
    const {
      Channels = [],
    } = res || {};

    // get history for each channel
    const patientIds = Channels.map((c) => c.split('-')[1]);
    const historyRes = await handleGetChannelHistory(
      patientIds,
    );
    const historyPatientIds = historyRes?.map((history) => history.key) || [];
    handleSetMultiplePatientMap(historyPatientIds);
    historyRes?.forEach((history) => {
      const { key } = history;
      handleSetChannel(key, history, true);
    });
  };

  useEffectOnce(() => {
    handleGetUnreadPaginatedChannels();
  });

  const loggedInUserHasUnread = useDeepCompareMemo(() => {
    const patientIds = map(channelMap, 'patientId');
    const isRDHC = intersection(
      [RoleTypeEnum.RD, RoleTypeEnum.HC],
      userInfo?.allRoleTypes
    ).length > 0;
    return isRDHC && some(patientIds, checkChannelHasUnread);
  }, [channelMap]);

  const contextValue = useGetContextValue<MessageServicesContextValue>({
    channelMap,
    getChannel,
    userMap,
    handleSetChannel,
    handleSetUserMap,
    handleSetMultiplePatientMap,
    handleSetMultipleUserMap,
    patientSearchLoading: patientSearchInfo.isLoading,
    paginatedChannelsLoading: paginatedChannelsInfo.isLoading,
    getChannelHistoryLoading: getChannelHistoryInfo.isLoading,
    handleGetNotificationChannelGroup,
    handleGetPaginatedChannels,
    handleGetUnreadPaginatedChannels,
    handleGetChannelHistory,
    handleInitChannel,
    handleTagMessage,
    handleToggleTagMessage,
    checkChannelHasUnread,
    handleSetPatientMapData,
    loggedInUserHasUnread,
  }, [
    patientSearchInfo.isLoading,
    paginatedChannelsInfo.isLoading,
    getChannelHistoryInfo.isLoading,
  ]);

  return (
    <MessageServicesContext.Provider value={contextValue}>
      <MessageStorageContextProvider>
        {children}
      </MessageStorageContextProvider>
    </MessageServicesContext.Provider>
  );
};
