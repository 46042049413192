import { ConditionEumComponent } from '../../../../enumComponent/ConditionEumComponent/ConditionEumComponent';
import { ConditionEnum } from '../../../../uc-api-sdk';
import { ConditionIconComponent } from '../../../../uiComponent/ConditionIconComponent/ConditionIconComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';

export interface MTPRConditionTitleWithIconComponentProps {
  condition?: ConditionEnum;
  customCategory?: string;
}

export const MTPRConditionTitleWithIconComponent = ({
  condition,
  customCategory,
}: MTPRConditionTitleWithIconComponentProps) => {
  const getTitle = () => {
    switch (condition) {
      case ConditionEnum.BLOOD_PRESSURE_MANAGEMENT:
        return <span>Blood Pressure</span>;
      case ConditionEnum.GLUCOSE_MANAGEMENT:
        return <span>Blood Glucose</span>;
      case ConditionEnum.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE:
        return <span>Chronic Obstructive Pulmonary Disease (COPD)</span>;
      case ConditionEnum.CHRONIC_KIDNEY_DISEASE:
        return <span>Chronic Kidney Disease (CKD)</span>;
      case ConditionEnum.CONGESTIVE_HEART_FAILURE:
        return <span>Congestive Heart Failure (CHF)</span>;
      default:
        break;
    }
    if (condition) {
      return <ConditionEumComponent value={condition} />;
    }
    return <DisplayOrEmptyComponent value={customCategory} />;
  };

  return (
    <div className="flex ai-c">
      <ConditionIconComponent
        condition={condition}
        size={{ width: 14, height: 16 }}
      />
      <TextComponent bold>
        {getTitle()}
      </TextComponent>
    </div>
  );
};
