import { filter } from 'lodash';
import { AlertSeverityEnum, BgMealTypeEnum } from '../../../uc-api-sdk';

export interface BGMeasurement {
  beforeMeal: boolean;
  mealType: BgMealTypeEnum;
  severity?: AlertSeverityEnum;
}

export class BGChartHelper {
  static getBeforeBreakfast<T extends BGMeasurement>(measurements: T[]) {
    return filter(measurements, (v) => v.beforeMeal && v.mealType === BgMealTypeEnum.BREAKFAST);
  }

  static getBeforeMeal<T extends BGMeasurement>(measurements: T[]) {
    return filter(measurements, (v) => v.beforeMeal);
  }

  static getAfterMeal<T extends BGMeasurement>(measurements: T[]) {
    return filter(measurements, (v) => !v.beforeMeal);
  }

  static getOvernightBedtime<T extends BGMeasurement>(measurements: T[]) {
    return filter(measurements, (v) => (
      v.mealType === BgMealTypeEnum.BEDTIME || v.mealType === BgMealTypeEnum.OVERNIGHT
    ));
  }
}
