import { DeviceModelEnum } from '../../../../uc-api-sdk';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import { NonAppDeviceInfoComponent } from '../../../enrollment/component/NonAppDeviceInfoComponent/NonAppDeviceInfoComponent';

export interface DeviceUnassignComponentProps {
  deviceId: string;
  deviceModel: DeviceModelEnum;
  onUnassign: () => void;
  isLoading?: boolean;
  disabled?: boolean;
}

export const DeviceUnassignComponent = ({
  deviceId,
  deviceModel,
  isLoading,
  onUnassign,
  disabled,
}: DeviceUnassignComponentProps) => (
  <NonAppDeviceInfoComponent
    deviceId={deviceId}
    deviceModel={deviceModel}
    extraHeader={(
      <LinkButton
        useBlueColor
        onClick={onUnassign}
        disabled={disabled || isLoading}
      >
        Unassign
      </LinkButton>
    )}
  />
);
