import { PatientTechLevelEnumComponent } from '../../../../enumComponent/PatientTechLevelEnumComponent/PatientTechLevelEnumComponent';
import { PatientTechLevelEnum } from '../../../../uc-api-sdk';
import { RadioGroupComponent } from '../../../../uiComponent/RadioGroupComponent/RadioGroupComponent';
import { InputTypeRequired } from '../../../Input/types';

export const TechLevelText = [
  {
    value: PatientTechLevelEnum.HIGH,
    label: <PatientTechLevelEnumComponent value={PatientTechLevelEnum.HIGH} type="long" />,
  },
  {
    value: PatientTechLevelEnum.MEDIUM,
    label: <PatientTechLevelEnumComponent value={PatientTechLevelEnum.MEDIUM} type="long" />,
  },
  {
    value: PatientTechLevelEnum.LOW,
    label: <PatientTechLevelEnumComponent value={PatientTechLevelEnum.LOW} type="long" />
  },
];

export interface TechLevelRadioComponentProps extends
  Partial<InputTypeRequired<PatientTechLevelEnum>> {

}

export const TechLevelRadioComponent = ({
  value,
  onChange,
}: TechLevelRadioComponentProps) => (
  <RadioGroupComponent
    options={TechLevelText}
    value={value}
    onChange={onChange}
    direction="vertical"
  />
);
