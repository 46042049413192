import { isEmpty } from 'lodash';
import React, { FC } from 'react';
import { SummaryEmptyComponent } from './SummaryEmptyComponent';

export interface SummaryFourIndexComponentProps {
  value: number[];
}

export const SummaryFourIndexComponent: FC<SummaryFourIndexComponentProps> = ({
  value,
}) => (
  isEmpty(value)
    ? <SummaryEmptyComponent />
    : (
      <div>
        {value[0]}
        -
        {value[1]}
        /
        {value[2]}
        -
        {value[3]}
      </div>
    )
);
