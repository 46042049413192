import { Radio, RadioGroupProps } from 'antd';
import React, { FC, useState } from 'react';
import { BGChartMeasurement } from './BGChartHelper';
import { BGLineChartComponent } from './BGLineChartComponent';
import { BGPointChartComponent } from './BGPointChartComponent';
import { FromDateToDateDayjs } from '../../type';

export interface BGChartsComponentProps extends FromDateToDateDayjs {
  data: BGChartMeasurement[][];
}

type ChartTypes = 'lineChart' | 'pointChart';

export const BGChartsComponent: FC<BGChartsComponentProps> = ({
  data,
  fromDate,
  toDate,
}) => {
  const [value, setValue] = useState<ChartTypes | undefined>('lineChart');

  const handleChange: RadioGroupProps['onChange'] = (evt) => {
    setValue(evt.target.value);
  };

  return (
    <div>
      <Radio.Group value={value} onChange={handleChange}>
        <Radio.Button value="lineChart">Trend View</Radio.Button>
        <Radio.Button value="pointChart">Point View</Radio.Button>
      </Radio.Group>
      {value === 'lineChart' && (
        <BGLineChartComponent
          data={data}
          fromDate={fromDate}
          toDate={toDate}
        />
      )}
      {value === 'pointChart' && (
        <BGPointChartComponent
          data={data}
          fromDate={fromDate}
          toDate={toDate}
        />
      )}
    </div>
  );
};
