import { some, values } from 'lodash';
import { FormOptions, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { useIterableFormHook } from '../../../hooks/useIterableFormListHook/useIterableFormHook';
import { useGetContextValue } from '../../../hooks/useGetContextValue/useGetContextValue';
import { useAddressForm } from '../../../hooks/formHook/useAddressForm';
import { CuffSizeEnum, DeviceDeliveryMethodEnum, DeviceModelEnum } from '../../../uc-api-sdk';
import { AddressFormValues } from '../../../uiComponent/AddressFormItemComponent/AddressFormItemComponent';
import { SupplyTypeEnum } from '../type';
import { FormInstanceType } from '../../../types/form';
import { DeviceMonitorMethodEnum } from '../../../enumComponent/DeviceMonitorMethodEnumComponent/DeviceMonitorMethodEnumComponent';

export interface OrderDeviceValue {
  isChecked?: boolean;
  reason?: string;
  cuffSize?: CuffSizeEnum;
  deviceModel?: DeviceModelEnum;
  deviceId?: string;
  deviceLanguage?: string;
  bpReadOut?: boolean;
  deviceMethod?: DeviceMonitorMethodEnum;
}

export type OrderDeviceFormValues = {
  [x in DeviceModelEnum]: OrderDeviceValue
}

export interface OrderSuppliesValue {
  isChecked?: boolean;
  reason?: string;
  deviceId?: string;
  cuffSize?: CuffSizeEnum;
}

export type OrderSuppliesFormValues = {
  [x in SupplyTypeEnum | DeviceModelEnum.LOAN_DEVICE]: OrderSuppliesValue
}

export interface OrderFormValues
  extends AddressFormValues {
  deliveryMethod: DeviceDeliveryMethodEnum;
  suppliesList: OrderSuppliesFormValues;
  devicesList: OrderDeviceFormValues;
}

export const useOrderFormHook = (options?: FormOptions) => {
  const addressForm = useAddressForm(options);

  const orderSuppliesForm = useIterableFormHook('suppliesList', {});

  const makeSuppliesFieldName = (
    supplyType: SupplyTypeEnum | DeviceModelEnum.LOAN_DEVICE,
  ) => [orderSuppliesForm.getMainFormItemName(), supplyType];

  const orderDevicesForm = useIterableFormHook('devicesList', {});

  const makeDevicesFieldName = (
    deviceModel: DeviceModelEnum,
  ) => [orderDevicesForm.getMainFormItemName(), deviceModel];

  const factory = useFormHookFactory({
    deliveryMethod: {
      name: 'deliveryMethod',
      label: '',
    },
    ...addressForm.formInput,
  }, {
    name: 'orderForm',
    ...options,
  });

  const isAnyOrderItemChecked = (
    getFieldsValue: FormInstanceType['getFieldsValue']
  ) => {
    const {
      suppliesList,
      devicesList,
    } = getFieldsValue() as OrderFormValues;
    return (
      some(values(suppliesList), (value) => !!value?.isChecked === true)
      || some(values(devicesList), (value) => !!value?.isChecked === true)
    );
  };

  return useGetContextValue({
    ...factory,
    orderSuppliesForm: {
      ...orderSuppliesForm,
      makeSuppliesFieldName
    },
    orderDevicesForm: {
      ...orderDevicesForm,
      makeDevicesFieldName,
    },
    addressForm,
    isAnyOrderItemChecked,
  }, [
    makeSuppliesFieldName,
    makeDevicesFieldName,
  ]);
};
