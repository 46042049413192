import { useMemo } from 'react';
import { Badge } from 'antd';
import { useInterval } from 'usehooks-ts';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { Icons } from '../../../../icons/Icons';
import { TicketStatusEnum, useTicketSearchTickets } from '../../../../uc-api-sdk';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { useOnCallContext } from '../../../patientCare/context/OnCallContext/OnCallContext';

export interface TicketSideMenuBtnContainerProps {

}

export const TicketSideMenuBtnContainer = () => {
  const { userId = '' } = useLoggedInUserFromContext();
  const { employeeIds, isOnCall } = useOnCallContext({ type: 'task' });

  const assignedTo = useMemo(() => {
    if (isOnCall) {
      return [...employeeIds, userId];
    }
    return [userId];
  }, [isOnCall, userId, employeeIds]);

  const info = useTicketSearchTickets({
    options: {
      sendOnMount: true,
    },
    params: {
      searchRequest: {
        filter: {
          status: [TicketStatusEnum.OPEN],
          assignedTo,
        },
        pageInfo: {
          countOnly: true,
        },
      },
    },
  });

  useUpdateListener('TICKET_CREATE', info.refetch);
  useUpdateListener('TICKET_EDITED', info.refetch);
  useUpdateListener('TICKET_RESOLVED', info.refetch);

  useInterval(info.refetch, 60000);

  const count = info.data?.data?.totalSize || 0;

  if (count === 0) {
    return <Icons.TicketMenu />;
  }
  return (
    <Badge count={count} size="small">
      <Icons.TicketMenu />
    </Badge>
  );
};
