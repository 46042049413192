import { ReactNode } from 'react';
import { Card } from 'antd';
import classNames from 'classnames';
import { DeviceLabelComponent } from '../DeviceLabelComponent/DeviceLabelComponent';
import { DeviceModelEnum } from '../../../../uc-api-sdk';

export interface BloodPressureDeviceInfoCardComponentProps {
  deviceModel: DeviceModelEnum;
  children: ReactNode;
}

export const BloodPressureDeviceInfoCardComponent = ({
  deviceModel,
  children,
}: BloodPressureDeviceInfoCardComponentProps) => (
  <Card
    className={classNames({
      'blood-pressure-device-info-card': true,
      'default-border-radius': true,
      mb10: true,
    })}
    bodyStyle={{ padding: 14 }}
  >
    <div className="flex fd-c">
      <div className="flex gap2 ai-c my10 bold">
        <DeviceLabelComponent
          deviceModel={deviceModel}
        />
      </div>
      <div>
        {children}
      </div>
    </div>
  </Card>
);
