import { ReactNode, useCallback } from 'react';
import { InterventionContainerProps } from '../../type';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { LifestyleViewComponent } from '../../component/LifestyleComponent/LifestyleViewComponent';
import { LifestyleFormComponent, LifestyleFormComponentProps, SubmitValue } from '../../component/LifestyleComponent/LifestyleFormComponent';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import {
  FrequencyUnitEnum,
  Lifestyle,
  Nullable,
  usePatientGetLifestyleById,
  usePatientUpdateLifestyle,
} from '../../../../uc-api-sdk';
import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';

export interface LifestyleContainerProps
  extends Omit<InterventionContainerProps, 'onSubmit' | 'onError'>,
  NestedFormComponentChildProps<SubmitValue> {
  title?: ReactNode;
  patientId: string;
}

export const LifestyleContainer = ({
  title = 'Lifestyle',
  version,
  visitId,
  showEdit,
  onEdit,
  isEditing,
  onSubmit,
  onCancel,
  patientId,
  onError,
  onValuesChange,
  formButtons,
}: LifestyleContainerProps) => {
  const fetchHook = usePatientGetLifestyleById({
    options: {
      sendOnMount: true,
    },
    params: { memberId: patientId, version },
  });

  const updateHook = usePatientUpdateLifestyle({});
  useUpdateListener('WORKLIST_UPDATED', fetchHook.refetch);

  const data: Nullable<Lifestyle> | undefined = fetchHook.data?.data;
  const initialValues: LifestyleFormComponentProps['initialValues'] = {
    activityLevel: data?.physicalActivity?.activityLevel ?? undefined,
    activityDuration: data?.physicalActivity?.activityDuration ?? undefined,
    howOften: {
      frequency: data?.physicalActivity?.activityValue ?? undefined,
      frequencyType: data?.physicalActivity?.activityFreq ?? FrequencyUnitEnum.WEEKLY,
    },
    sleepingRoutine: data?.sleepingRoutine ?? undefined,
    eatingRoutine: data?.eatingRoutine ?? undefined,
    foodAccessibility: data?.foodAccessibility ?? undefined,
    smoke: data?.smokingHistory?.smokingOrDrinking ?? undefined,
    smokeQuitYear: data?.smokingHistory?.quitYear ?? undefined,
    drink: data?.drinkingHistory?.smokingOrDrinking ?? undefined,
    drinkQuitYear: data?.drinkingHistory?.quitYear ?? undefined,
    howOftenSmoke: {
      frequency: data?.smokingHistory?.activityValue ?? undefined,
      frequencyType: data?.smokingHistory?.activityFreq ?? FrequencyUnitEnum.WEEKLY,
    },
    howOftenDrink: {
      frequency: data?.drinkingHistory?.activityValue ?? undefined,
      frequencyType: data?.drinkingHistory?.activityFreq ?? FrequencyUnitEnum.WEEKLY,
    },
    drinkChoice: data?.drinkingHistory?.drinkChoice ?? undefined,
    drinkQuantity: data?.drinkingHistory?.drinkQuantity ?? undefined,
    lifestylePattern: data?.lifestylePattern ?? undefined,
  };

  const updateLifestyle = useCallback(async (l: SubmitValue) => {
    const parametersReq: Lifestyle = {
      physicalActivity: {
        activityLevel: l.activityLevel,
        activityValue: l.howOften?.frequency,
        activityFreq: l.howOften?.frequencyType,
        activityDuration: l.activityDuration,
      },
      sleepingRoutine: l.sleepingRoutine,
      eatingRoutine: l.eatingRoutine,
      foodAccessibility: l.foodAccessibility,
      smokingHistory: {
        smokingOrDrinking: l.smoke,
        quitYear: l.smokeQuitYear,
        activityFreq: l.howOftenSmoke?.frequencyType,
        activityValue: l.howOftenSmoke?.frequency,
      },
      drinkingHistory: {
        smokingOrDrinking: l.drink,
        quitYear: l.drinkQuitYear,
        activityFreq: l.howOftenDrink?.frequencyType,
        activityValue: l.howOftenDrink?.frequency,
        drinkChoice: l.drinkChoice,
        drinkQuantity: l.drinkQuantity,
      },
      lifestylePattern: l.lifestylePattern,
    };
    if (l.howOften?.frequency && l.howOften?.frequencyType) {
      parametersReq.physicalActivity = {
        ...parametersReq.physicalActivity,
        activityValue: String(l.howOften?.frequency),
        activityFreq: l.howOften?.frequencyType,
      };
    }
    if (l.lifestylePattern !== initialValues?.lifestylePattern) {
      parametersReq.notes = [{ note: l.lifestylePattern }];
    }
    const request = await updateHook.send({
      params:
      {
        memberId: patientId,
        request: {
          ...parametersReq,
          visitId,
        }
      },
    });
    if (request?.code === 200) {
      fetchHook.refetch();
    }
    return request;
  }, [patientId, initialValues]);

  const handleSubmit: LifestyleFormComponentProps['onSubmit'] = (l) => {
    ApiRequestHelper.tryCatch(
      updateLifestyle(l),
      {
        success: 'Lifestyle Updated.',
        error: 'Failed to update lifestyle!',
        onSuccess: () => onSubmit?.(l),
        onError,
      },
    );
  };

  const handleCancel = () => {
    onCancel?.();
  };

  return (
    <PatientCommonCardComponent
      title={title}
      isEditing={isEditing}
      showEdit={showEdit}
      onEdit={onEdit}
      updateInfo={fetchHook.data?.data}
      content={<LifestyleViewComponent value={fetchHook.data?.data ?? undefined} />}
      formContent={(
        <LoadingOverlayComponent isLoading={data === undefined}>
          <LifestyleFormComponent
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            initialValues={initialValues}
            isLoading={updateHook.isLoading}
            onValuesChange={onValuesChange}
            formButtons={formButtons}
            lifeStyleNotes={fetchHook.data?.data?.notes || []}
          />
        </LoadingOverlayComponent>
      )}
    />
  );
};
