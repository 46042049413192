import React, { FC } from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import './FoodLogTabLabelComponent.scss';

export interface FoodLogTabLabelComponentProps {
  hasRating: boolean;
  imageUrl: string;
  alt: string;
}

export const FoodLogTabLabelComponent: FC<FoodLogTabLabelComponentProps> = ({
  hasRating,
  imageUrl,
  alt,
}) => (
  <div className="foodLogTabLabelComponent">
    <img src={imageUrl} alt={alt} />
    {hasRating && <CheckCircleFilled className="foodLogCheckMark" />}
  </div>
);
