import { Select, SelectProps } from 'antd';
import { FC } from 'react';
import { MED_STATUS } from './constants';

export interface MedicationStatusSelectComponentProps {
  value?: string,
  onChange?: SelectProps['onChange'],
}

export const MedicationStatusSelectComponent: FC<MedicationStatusSelectComponentProps> = ({
  onChange,
  value,
}) => (
  <Select
    options={MED_STATUS}
    onChange={onChange}
    value={value}
  />
);
