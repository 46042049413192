import { message } from 'antd';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useBoolean } from 'usehooks-ts';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext/LoggedInUserContext';
import {
  useTicketUpdate, useTicketAddComment, Ticket, TicketStatusEnum,
} from '../../../../uc-api-sdk';
import { CreateEditTicketFormComponent, SubmitValue, SubmitValueType } from '../../component/CreateEditTicketFormComponent/CreateEditTicketFormComponent';
import { CreateTicketContainerProps } from '../CreateTicketContainer/CreateTicketContainer';
import { CancelSubmitButtonsComponent } from '../../../../uiComponent/CancelSubmitButtonsComponent/CancelSubmitButtonsComponent';
import { EditTicketMode } from '../../constants';
import { TicketTexts } from '../../constant/text';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import TimezoneService from '../../../../helpers/timezone/timezoneService';

export interface EditTicketContainerProps extends CreateTicketContainerProps {
  ticket?: Ticket;
  onCancel?: () => void;
  title?: string;
  mode?: EditTicketMode;
  refetch?: () => void;
  patientInputDisable: boolean;
}

export interface CommentValue {
  comment: string;
  ticketId: string;
  employeeId: string;
}

export const EditTicketContainer = ({
  ticket,
  refetch,
  onCancel,
  title = 'Edit Task',
  mode = 'Edit',
  patientInputDisable = false,
}: EditTicketContainerProps) => {
  const updateHookAssignToMeCount = useUpdate('TICKET_EDITED');

  const useTicketService = useTicketUpdate({});
  const useTicketCommentService = useTicketAddComment({});
  const { value: isLoading, setTrue, setFalse } = useBoolean(false);
  const { userId } = useLoggedInUserFromContext();

  const initialValues: SubmitValueType = {
    priorityLevel: ticket?.priorityLevel,
    description: ticket?.description || '',
    dueDate: moment(ticket?.dueDate),
    assignedTo: ticket?.assignedToUser?.id,
    memberId: ticket?.memberId,
  };

  const addComment = useCallback(({
    comment,
    ticketId,
    employeeId,
  }: CommentValue) => useTicketCommentService.send(
    {
      params: {
        request: {
          comment,
          ticketId,
          employeeId,
        },
      },
    },
  ), [useTicketCommentService]);

  const editTicket = useCallback(
    async (v: SubmitValue) => {
      setTrue();
      const addCommentResult = await addComment({
        comment: v.comment || '',
        ticketId: ticket?.id || '',
        employeeId: userId || '',
      });

      let document: typeof initialValues = {};
      Object.keys(initialValues).forEach((prop) => {
        if (prop === 'dueDate') {
          if (v.dueDate && '_isAMomentObject' in v.dueDate
          && !v?.dueDate?.isSame(initialValues.dueDate?.toString(), 'day')) {
            document = {
              ...document,
              dueDate: TimezoneService.getUTC(v.dueDate)
            };
          }
        } else if (initialValues[prop] !== v[prop]) {
          document[prop] = v[prop];
        }
      });
      if (mode === 'ReOpen') {
        document = {
          ...document,
          assignedTo: v.assignedTo,
          status: TicketStatusEnum.OPEN,
        };
      }

      const updateResult = await useTicketService.send({
        params: {
          id: String(ticket?.id),
          document,
        },
      });

      if (addCommentResult?.code === 200) {
        message.success(TicketTexts.addComment);
      }
      if (updateResult?.code === 200 && addCommentResult?.code === 200) {
        if (mode === 'ReOpen') {
          message.success(TicketTexts.reopenSuccess);
        } else {
          message.success(TicketTexts.editSuccess);
        }

        // update the number of assigned to me(x);
        // if assignedTo change to/from 'me' when both edit and Reopen
        if (v.assignedTo === userId || ticket?.assignedTo === userId) {
          updateHookAssignToMeCount.updateValue();
        }
        refetch?.();
      } else {
        message.warning(TicketTexts.error);
      }
      setFalse();
      onCancel?.();
    },
    [useTicketService, useTicketCommentService, ticket?.id, onCancel],
  );

  return (
    <CreateEditTicketFormComponent
      onFinish={editTicket}
      mode={mode}
      initialValues={initialValues}
      onCancel={onCancel}
      title={title}
      isLoading={isLoading}
      patientInputDisable={patientInputDisable}
    >
      {({ onCancel }) => (
        <CancelSubmitButtonsComponent
          submitText={(mode === 'ReOpen') ? 'Reopen' : 'Submit'}
          disabled={isLoading}
          className="jc-sb"
          onCancel={onCancel}
          type="link"
        />
      )}
    </CreateEditTicketFormComponent>
  );
};
