import { Modal, Image, Button } from 'antd';
import WelcomePopupBg from '../../../assets/images/welcomePopupUpdate.png';
import './WelcomePopupModalComponent.scss';
import { useDoNotShowAgain } from '../../patient/hook/useDoNotShowAlertAgain';
import { useLoggedInUserFromContext } from '../../../contexts/loggedInUserContext';

export interface WelcomePopupModalComponentProps {}

export const WelcomePopupModalComponent = () => {
  const { userId } = useLoggedInUserFromContext();
  const [isWelcomed, setIsWelcomed] = useDoNotShowAgain('IsWelcomed', userId);

  const renderImage = () => (
    <Image className="render-image" src={WelcomePopupBg} preview={false} width={923} height={343} />
  );

  return (
    <Modal
      mask
      width={923}
      title={renderImage()}
      open={!isWelcomed}
      centered
      className="welcome-popup-modal-component"
      footer={null}
    >
      <div className="render-title">Welcome to Care Portal 2.0!</div>
      <div className="render-content">
        To ensure the best experience,
        please use the Chrome browser,
        and set your computer screen resolution to 1920*1080 or higher.
      </div>
      <div className="render-content">Please also use the “Send Feedback” feature to report any issues, thank you!</div>
      <div className="render-get-started">
        <Button onClick={() => setIsWelcomed(true)}>Get started</Button>
      </div>
    </Modal>
  );
};
