import {
  Checkbox,
  Form,
  Input,
  Select
} from 'antd';
import parse from 'html-react-parser';
import { get } from 'lodash';
import {
  ReactNode,
  useContext,
  useEffect,
  useMemo
} from 'react';
import { LabResultItem, ReferenceLevelEnum } from '../../../../uc-api-sdk';
import { LabResultDetailEditableContext } from './LabResultDetailEditableRowComponent';
import './LabResultDetailTableColumn.scss';
import './LabResultDetailTableComponent.scss';
import { LabResultFlagComponent } from './LabResultFlagComponent';
import { LabResultEditableItem } from '../../constants';

interface LabResultDetailEditableCellComponentProps {
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof LabResultItem;
  record: LabResultEditableItem;
  handleSave: (record: LabResultEditableItem) => void;
  render: (value: unknown, record: LabResultEditableItem) => ReactNode;
}

export const LabResultDetailEditableCellComponent = ({
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  render,
  ...restProps
}: LabResultDetailEditableCellComponentProps) => {
  const form = useContext(LabResultDetailEditableContext);
  const { isManualEditable } = record || {};

  const makeFormItemName = (key: string) => (record?.dbKey ? `${record?.dbKey}-${key}` : '');

  const flagOptions = useMemo(() => (
    [
      ReferenceLevelEnum.HIGH,
      ReferenceLevelEnum.NORMAL,
      ReferenceLevelEnum.LOW,
    ].map((value) => ({
      label: <LabResultFlagComponent flag={value} />,
      value,
    }))
  ), []);

  useEffect(() => {
    if (record) {
      form?.setFieldsValue({ [record?.dbKey ?? '']: record });
    }
  }, [editable]);

  let childNode = render?.(get(record, dataIndex), record) || children;

  const onValueChange = (key: string) => async () => {
    const values = await form?.validateFields();
    const formItemName = makeFormItemName(key);
    const newRecord = { ...record, [key]: get(values, formItemName) };
    handleSave(newRecord);
  };

  const renderValueInput = (showUnit = true) => (
    <Form.Item
      className="m0"
      name={makeFormItemName('value')}
      initialValue={record?.value}
    >
      <Input
        className="fs15"
        onKeyDown={(e) => {
          // enable cursor movement when on EditContainer
          e.stopPropagation();
        }}
        onChange={onValueChange('value')}
        suffix={
          (showUnit && record)
            ? <div className="lab-result-unit">{parse(record?.unit ?? '')}</div>
            : ''
        }
        placeholder={showUnit ? undefined : 'Value'}
      />
    </Form.Item>
  );

  if (editable && dataIndex === 'name' && isManualEditable) {
    childNode = (
      <Form.Item
        className="m0"
        name={makeFormItemName('name')}
        initialValue={record?.name}
      >
        <Input
          className="fs15"
          onChange={onValueChange('name')}
          placeholder="Test name"
        />
      </Form.Item>
    );
  }

  if (editable && dataIndex === 'value') {
    if (!isManualEditable) {
      childNode = renderValueInput();
    } else {
      childNode = (
        <div className="flex">
          <div className="w50">
            {renderValueInput(false)}
          </div>
          <Form.Item
            className="m0 w50"
            name={makeFormItemName('unit')}
            initialValue={record?.unit}
          >
            <Input
              className="fs15"
              onChange={onValueChange('unit')}
              placeholder="Unit"
            />
          </Form.Item>
        </div>
      );
    }
  }

  if (editable && dataIndex === 'flag' && isManualEditable) {
    childNode = (
      <Form.Item
        className="m0"
        name={makeFormItemName('flag')}
        initialValue={record?.flag}
      >
        <Select
          options={flagOptions}
          className="w100 fs15"
          onChange={onValueChange('flag')}
          placeholder="Flag"
        />
      </Form.Item>
    );
  }

  if (dataIndex === 'isInHouse') {
    childNode = (
      <Checkbox
        checked={record?.isInHouse ?? false}
        onChange={async (e) => {
          handleSave({ ...record, isInHouse: e.target.checked });
        }}
      />
    );
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <td {...restProps}>{childNode}</td>;
};
