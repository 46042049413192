import { Input } from 'antd';
import FormItem, { FormItemProps } from '../FormItem/FormItem';

export interface LeaveNoteFormItemComponentProps extends FormItemProps {
  name: string;
}

export const LeaveNoteFormItemComponent = ({
  name,
  ...restProps
}: LeaveNoteFormItemComponentProps) => (
  <FormItem
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...restProps}
    name={name}
  >
    <Input.TextArea
      autoSize={{ minRows: 2 }}
      placeholder="Leave Notes"
    />
  </FormItem>
);
