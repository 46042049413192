import { ChronicDiseaseEnum, VitalEnumType } from '../../../uc-api-sdk';

export class DiseaseHistory {
  static getVitalFromChronicDisease(disease?: ChronicDiseaseEnum | null) {
    switch (disease) {
      case ChronicDiseaseEnum.HYPERTENSION:
      case ChronicDiseaseEnum.CHRONIC_KIDNEY_DISEASE:
      case ChronicDiseaseEnum.CONGESTIVE_HEART_FAILURE:
        return VitalEnumType.BP;
      case ChronicDiseaseEnum.DIABETES:
      case ChronicDiseaseEnum.PRE_DIABETES:
        return VitalEnumType.BG;
      case ChronicDiseaseEnum.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE:
        // Blood Oxygen related
        return VitalEnumType.PO;
      default:
        return undefined;
    }
  }

  // general chronic disease = not related to any vital
  static isGeneralChronicDisease(disease?: ChronicDiseaseEnum | null) {
    const vital = DiseaseHistory.getVitalFromChronicDisease(disease);
    return vital === undefined;
  }
}
