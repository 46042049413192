import { map } from 'lodash';
import { useMemo } from 'react';
import { Nullable } from '../../../../uc-api-sdk';

export type ResultToSummaryTableData<V, K> = V & { type: K };

/* eslint-disable import/prefer-default-export */
export const useResultToSummaryTable = <
  K extends string,
  T extends Record<K, Nullable<T[K]> | undefined>,
>(data: T, order: K[]) => {
  const processedData = useMemo(() => {
    const result = map(order, (v) => {
      if (data[v]) {
        return { ...data[v], type: v } as ResultToSummaryTableData<T[K], K>;
      }
      return { type: v };
    });

    return result;
  }, [data, order]);

  return processedData;
};
