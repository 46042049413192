import { useMemo } from 'react';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { EnrolledProgramService } from '../../../../services/EnrolledProgramService';
import { usePatientUpsertPatientEnrolledProgram } from '../../../../uc-api-sdk';
import { PatientProfileVitalsMonitoringFormComponent, PatientProfileVitalsMonitoringFormComponentProps } from '../../component/PatientProfileVitalsMonitoringFormComponent/PatientProfileVitalsMonitoringFormComponent';
import { AddRemoveVitalsMonitoringSubmitValue } from '../../hook/useAddRemoveVitalsMonitoringForm';
import { FormType } from '../../../Input/types';

export interface PatientProfileVitalsMonitoringFormContainerProps
  extends FormType<AddRemoveVitalsMonitoringSubmitValue> {
  patientId: string;
  enrolledProgramService: EnrolledProgramService;
}

export const PatientProfileVitalsMonitoringFormContainer = ({
  patientId,
  enrolledProgramService,
  isLoading,
  onSubmit,
  onCancel,
  onError,
  onValuesChange,
  formButtons,
}: PatientProfileVitalsMonitoringFormContainerProps) => {
  const vitalsUpdateInfo = usePatientUpsertPatientEnrolledProgram({});
  const handleOnSubmit: PatientProfileVitalsMonitoringFormComponentProps['onSubmit'] = (
    values,
  ) => {
    onSubmit?.(values);
  };

  const debouncedHandleSubmit = useDebounce(handleOnSubmit, 250, [handleOnSubmit]);
  const initialValues = useMemo((): AddRemoveVitalsMonitoringSubmitValue => ({
    vitals: enrolledProgramService.getMonitoringVitals(),
  }), []);

  return (
    <PatientProfileVitalsMonitoringFormComponent
      patientId={patientId}
      initialValues={initialValues}
      isLoading={isLoading || vitalsUpdateInfo.isLoading}
      onSubmit={debouncedHandleSubmit}
      onCancel={onCancel}
      onError={onError}
      onValuesChange={onValuesChange}
      formButtons={formButtons}
    />
  );
};
