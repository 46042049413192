import { DeviceTypeEnum } from '../../uc-api-sdk';

export interface DeviceTypeEnumComponentProps {
  value: DeviceTypeEnum | null;
}

export const DeviceTypeEnumComponent = ({
  value,
}: DeviceTypeEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case DeviceTypeEnum.BG:
        return 'Blood Glucose Monitor';
      case DeviceTypeEnum.BP:
        return 'Blood Pressure Monitor';
      case DeviceTypeEnum.HS:
        return 'Scale';
      case DeviceTypeEnum.PO:
        return 'Blood Oxygen Monitor';
      case DeviceTypeEnum.TM:
        return 'Temperature Monitor';
      default:
        return null;
    }
  };
  return (
    <span>{getText()}</span>
  );
};
