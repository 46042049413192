import { DatePicker } from 'antd';
import { useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';

export interface PriorAuthSubmissionDateFormItemComponentProps { }

export const PriorAuthSubmissionDateFormItemComponent = () => {
  const insuranceForm = useInsuranceForm();
  return (
    <FormItem
      info={insuranceForm.getInfo('priorAuthSubmissionDate')}
    >
      <DatePicker
        format="MM/DD/YYYY"
        placeholder="MM/DD/YYYY"
      />
    </FormItem>
  );
};
