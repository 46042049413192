/* eslint-disable react/no-unused-prop-types */
// ref: https://www.omnicalculator.com/health/bmr-harris-benedict-equation#:~:text=How%20do%20I%20calculate%20BMR,4.7%20%C3%97%20age%20in%20years)
/*
Formula:
For men: 66.47 + (6.24 × weight in pounds) + (12.7 × height in inches) − (6.75 × age in years).
For women: BMR = 65.51 + (4.35 × weight in pounds) + (4.7 × height in inches) - (4.7 × age in years)
*/
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';
import { NumberService } from '../../helpers/number';
import { useDeepCompareMemo } from '../../hooks/useDeepCompareEffect';
import { GenderEnum, Height, Weight } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../DisplayComponent/DisplayOrEmptyComponent';
import { BMRTooltipComponent } from '../../features/vitals/component/TooltipComponent/BMRTooltipComponents';

export interface BMRComponentProps {
  label?: ReactNode;
  gender?: GenderEnum;
  age?: number;
  weight?: Weight;
  height?: Height;
  showLabelWhenEmpty?: boolean;
  toFixed?: number;
  showUnit?: boolean;
}

export const BMRComponent = ({
  label = 'BMR (Total Calories):',
  gender,
  age,
  weight,
  height,
  toFixed = 0,
  showUnit = true,
}: BMRComponentProps) => {
  const [
    weightInLb,
    heightInInch
  ] = useDeepCompareMemo(() => (
    NumberService.getStandardWeightAndHeight(weight, height)
  ), [weight, height]);

  const bmrTooltip = (
    <Tooltip
      overlay={<BMRTooltipComponent />}
      overlayStyle={{ minWidth: '520px' }}
    >
      <InfoCircleOutlined />
    </Tooltip>
  );

  const getValueText = () => {
    if (!gender || !age || !weightInLb || !heightInInch) {
      return null;
    }

    const getValueForMen = () => (
      (4.536 * weightInLb) + (15.88 * heightInInch) - (5 * age) + 5
    );

    const getValueForWomen = () => (
      (4.536 * weightInLb) + (15.88 * heightInInch) - (5 * age) - 161
    );

    const getValue = () => {
      switch (gender) {
        case GenderEnum.M: return getValueForMen();
        case GenderEnum.F: return getValueForWomen();
        default: return 0;
      }
    };

    return `${getValue().toFixed(toFixed)}${showUnit ? 'kcal/day' : ''}`;
  };

  return (
    <>
      <div>
        {/* ie BMR (Total Calories) */}
        {label}
      </div>
      <div>
        <DisplayOrEmptyComponent value={getValueText()} />
        {' '}
        {bmrTooltip}
      </div>
    </>
  );
};
