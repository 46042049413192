import { ReactNode } from 'react';
import classNames from 'classnames';
import { CardTextComponent, CardTextComponentProps } from '../CardTextComponent/CardTextComponent';

import './CardInfoTextComponent.scss';

export interface CardInfoTextComponentProps extends CardTextComponentProps {
  children: ReactNode;
}

export const CardInfoTextComponent = ({
  children,
  className = '',
  size,
  bold,
  italic,
  underline,
}: CardInfoTextComponentProps) => (
  <CardTextComponent
    className={classNames({
      'card-info-text-component': true,
      [className]: !!className,
    })}
    size={size}
    bold={bold}
    italic={italic}
    underline={underline}
  >
    {children}
  </CardTextComponent>
);
