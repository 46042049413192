import React, {
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

interface AnchorInfo {
  key: string;
  href: string;
  title: React.ReactNode;
  target?: string;
}

interface Value {
  anchors: Record<string, AnchorInfo>;
  anchorOrder: string[];
  addNewAnchor: (info: AnchorInfo) => void;
}

export const AnchorContext = React.createContext<Value>({
  anchors: {},
  anchorOrder: [],
  addNewAnchor: () => {
    console.error('Anchor context does not exist yet!');
  },
});

export interface AnchorProviderProps {
  children: React.ReactNode;
}

export const AnchorProvider: FC<AnchorProviderProps> = ({
  children,
}) => {
  const [anchors, setAnchors] = useState<Value['anchors']>({});
  const [anchorOrder, setAnchorOrder] = useState<Value['anchorOrder']>([]);

  const addNewAnchor = useCallback((newAnchor: AnchorInfo) => {
    setAnchors((v) => {
      if (newAnchor.key in v) return v;
      return { ...v, [newAnchor.key]: newAnchor };
    });
    setAnchorOrder((v) => {
      if (v.includes(newAnchor.key)) return v;
      return [...v, newAnchor.key];
    });
  }, [setAnchors, setAnchorOrder]);

  const contextValue = useMemo<Value>(() => ({
    anchors,
    anchorOrder,
    addNewAnchor,
  }), [
    anchors,
    anchorOrder,
    addNewAnchor,
  ]);

  return (
    <AnchorContext.Provider value={contextValue}>
      {children}
    </AnchorContext.Provider>
  );
};

export const useAnchor = () => {
  const anchorContextValue = useContext<Value>(AnchorContext);
  return anchorContextValue;
};
