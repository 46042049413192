import { Rule } from 'antd/lib/form';
import { Nullable, Phone, PhoneTypeEnum } from '../uc-api-sdk';

/* eslint-disable import/prefer-default-export */
export const phoneRegex = /^\d{10}$/;
export const phoneRegexCN = /^\d{11}$/;
export const phoneRegexIN = /^\d{10}$/;
export const phoneRegexAU = /^\d{9}$/;

const REQUIRED_ERROR_MESSAGE = 'Phone is required';
const INVALID_ERROR_MESSAGE = 'This phone number is invalid';
interface PhoneNumberObj {
  countryCode: string;
  number?: string;
  type?: string;
}
export class PhoneService {
  private phones?: Nullable<Phone[]>;

  constructor(phones?: Nullable<Phone[]>) {
    this.phones = phones;
  }

  public getMobilePhoneInfo() {
    return this.phones?.filter((p) => p.type === PhoneTypeEnum.MOBILE)?.[0];
  }

  public getHomePhoneInfo() {
    return this.phones?.filter((p) => p.type === PhoneTypeEnum.HOME)?.[0];
  }

  public getMobilePhoneNumber() {
    return this.getMobilePhoneInfo()?.number;
  }

  public getMobilePhoneOTP() {
    return this.getMobilePhoneInfo()?.canUseForLogin || false;
  }

  public getIsSmartPhone() {
    return this.getMobilePhoneInfo()?.isSmartPhone || false;
  }

  public getHomePhoneNumber() {
    return this.phones?.filter((p) => p.type === PhoneTypeEnum.HOME)?.[0]?.number;
  }

  static getPhoneValidator(isRequired = false, countryCode = '1') {
    return (_rule: Rule, value: PhoneNumberObj) => (
      new Promise((resolve, reject) => {
        if (!value && isRequired) {
          reject(REQUIRED_ERROR_MESSAGE);
        }
        if (value && !PhoneService.validatePhoneNumber(value, countryCode)) {
          reject(INVALID_ERROR_MESSAGE);
        }
        resolve(true);
      })
    );
  }

  static validatePhoneNumber(
    phoneNumberInfo: number | string | PhoneNumberObj,
    countryCode: string,
  ) {
    let phoneNumber: number | string | PhoneNumberObj | undefined = '';
    let countryCodeTemp = 0;
    if (typeof phoneNumberInfo !== 'object') {
      phoneNumber = phoneNumberInfo;
      countryCodeTemp = parseInt(countryCode, 10);
    } else {
      phoneNumber = phoneNumberInfo.number;
      countryCodeTemp = parseInt(phoneNumberInfo.countryCode, 10);
    }
    switch (countryCodeTemp) {
      case 1:
        return !!phoneNumber?.toString().match(phoneRegex);
      case 63:
        return !!phoneNumber?.toString().match(phoneRegexAU);
      case 86:
        return !!phoneNumber?.toString().match(phoneRegexCN);
      case 91:
        return !!phoneNumber?.toString().match(phoneRegexIN);
      default:
        return !!phoneNumber?.toString().match(phoneRegex);
    }
  }
}
