import { intersection } from 'lodash';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from 'react';
import { useLogout } from '../../features/auth/hooks/useLogout';
import { AuthLoginResponse } from '../../features/auth/types';
import { ApiRequestHelper } from '../../helpers/ApiRequest';
import { StorageKeyEnum, useSessionStorage } from '../../hooks';
import { useAxiosResponseInterceptor } from '../../hooks/ajaxRequest/useAxiosResponseInterceptor/useAxiosResponseInterceptor';
import { determinePortalPlatform } from '../../hooks/useChangePage/useChangePage';
import { useDeepCompareEffect, useDeepCompareMemo } from '../../hooks/useDeepCompareEffect';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import EmployeeInfo from '../../hooks/useUserInfo/employeeInfo';
import {
  adminPortalRoles,
  billerPortalRoles,
  carePortalRoles,
  careRelatedRoles
} from '../../lib/constants/roles';
import { RoleType, ROLE_ENUM } from '../../types/roles';
import { Employee, RoleAssignment } from '../../types/user';
import {
  BucketsTypeEnum,
  FileDownloadUrlOutput,
  MedicalOrganizationStatus,
  Nullable,
  Patient,
  RoleTypeEnum,
  useClinicAssignmentListClinics,
  useEmployeeGetEmployeeWithRoles,
  useFileManagerDownloadUrl,
  UserTypeEnum
} from '../../uc-api-sdk';
import { OnSiteInfo, PortalPlatform } from './types';

export interface SessionUserInfo {
  employee: EmployeeInfo['employee'],
  roleAssignments: EmployeeInfo['roleAssignments'],
}
export interface ClinicIdToCareGroupIds {
  [key: string]: string[]
}
export interface LoggedInUserContextValue {
  userId?: string;
  isUserLoggedIn: boolean;
  loginInfo?: AuthLoginResponse<EmployeeInfo>;
  setLoginInfo: (
    newLoginInfo?: LoggedInUserContextValue['loginInfo'],
    cb?: () => unknown,
  ) => void;
  isSuperAdmin: boolean;
  isAdmin: boolean;
  isBiller: boolean;
  onSiteInfo?: OnSiteInfo;
  setOnSiteInfo: (info: OnSiteInfo) => void;
  userInfo?: EmployeeInfo;
  token?: string;
  updateToken: (token: string) => void;
  clinicIdToCareGroupIds?: ClinicIdToCareGroupIds;
  logout: () => void;
  doesUserRoleIncludes: (types: (RoleTypeEnum | ROLE_ENUM)[]) => boolean;
  getCurrentRole: () => RoleAssignment['roleType'] | undefined;
  isProvider: boolean;
  isInternalStaff: boolean;
  isClinicManager: boolean;
  isClinicBiller: boolean;
  isClinicalStaff: boolean;
  currentPlatform?: PortalPlatform;
  setCurrentPlatform: (platform: PortalPlatform) => void;
  setSessionUserInfo?: (info: SessionUserInfo) => void;
  refetchEmployeeInfo?: () => void;
  avatarInfo?: Nullable<FileDownloadUrlOutput>;
  setAvatarInfo: (info: Nullable<FileDownloadUrlOutput>) => void;
  defaultClinicId?: string;
  getDisplayName: <T extends Patient>(patient?: T | null) => string;
}
const LoggedInUserContext = createContext<LoggedInUserContextValue | undefined>(undefined);

export const useLoggedInUserFromContext = () => {
  const context = useContext(LoggedInUserContext);
  return (context || {}) as LoggedInUserContextValue;
};

export interface LoggedInUserContextProviderProps {
  children: ReactNode,
}
export const LoggedInUserProvider = ({
  children,
}: LoggedInUserContextProviderProps) => {
  const [
    sessionUserInfo,
    setSessionUserInfo,
  ] = useSessionStorage<SessionUserInfo>(StorageKeyEnum.CURRENT_USER);
  const [
    loginInfo,
    _setLoginInfo,
  ] = useSessionStorage<LoggedInUserContextValue['loginInfo']>(StorageKeyEnum.LOGIN_INFO);
  const [
    onSiteInfo,
    setOnSiteInfo,
  ] = useSessionStorage<LoggedInUserContextValue['onSiteInfo']>(StorageKeyEnum.ONSITE_INFO);
  const [
    token,
    setToken,
  ] = useSessionStorage<LoggedInUserContextValue['token']>(StorageKeyEnum.TOKEN);
  const [
    clinicIdToCareGroupIds,
    setClinicIdToCareGroupIds,
  ] = useSessionStorage<LoggedInUserContextValue['clinicIdToCareGroupIds']>(StorageKeyEnum.CLINIC_TO_CARE_GROUPS);
  const [
    currentPlatform,
    setCurrentPlatform,
  ] = useSessionStorage<LoggedInUserContextValue['currentPlatform']>(StorageKeyEnum.CURRENT_PLATFORM);

  const [
    defaultClinicId, setDefaultClinicId
  ] = useSessionStorage<string>(StorageKeyEnum.DEFAULT_CLINIC, '');

  const [avatarInfo, setAvatarInfo] = useSessionStorage<LoggedInUserContextValue['avatarInfo']>(StorageKeyEnum.AVATAR);

  const clinicAssignmentsInfo = useClinicAssignmentListClinics({});
  const newEmployeeInfo = useEmployeeGetEmployeeWithRoles({
    options: {
      sendOnMount: !!sessionUserInfo?.employee?.id,
    },
    params: {
      id: sessionUserInfo?.employee?.id || '',
    }
  });

  useEffect(() => {
    if (newEmployeeInfo.data?.data) {
      const { employee, roles } = newEmployeeInfo.data.data;
      setSessionUserInfo({
        employee: employee as Employee,
        roleAssignments: roles as RoleAssignment[],
      });
      setDefaultClinicId(employee?.defaultClinicId || '');
    }
  }, [newEmployeeInfo.data?.data]);

  const { onLogout } = useLogout();

  useAxiosResponseInterceptor();

  const updateToken = useCallback((token: string) => {
    setToken(token);
  }, []);

  const fetchClinicAssignments = (userInfo: EmployeeInfo) => {
    // get clinic assignments
    const roleAssignments = userInfo.roles;
    const excludedRoleTypes = [RoleTypeEnum.MANAGER, RoleTypeEnum.SUPER_ADMIN];
    const careGroupIds = [] as string[];
    roleAssignments.forEach((ra) => {
      if (!excludedRoleTypes.includes(ra.roleType) && ra.organizationId) {
        careGroupIds.push(ra.organizationId);
      }
    });
    if (!careGroupIds.length) return;
    clinicAssignmentsInfo.send({
      params: {
        searchRequest: {
          filter: {
            clinic: {
              status: MedicalOrganizationStatus.ACTIVE,
            },
            // clinicId and careGroupId are not required, but generated type requires
            // @ts-ignore
            clinicAssignment: {
              careGroupIdIn: {
                in: careGroupIds,
              },
            },
          },
        },
      },
    });
  };

  const getUserAvatar = useFileManagerDownloadUrl({
    options: {
      sendOnMount: false,
      retry: 1,
    },
  });

  const useImage = (fileKey: string) => {
    const res = getUserAvatar.send({
      params: {
        fileDownloadUrlInput: {
          bucket: BucketsTypeEnum.PRIVATEIMAGEUPLOAD,
          fileKey,
        },
      },
    });
    ApiRequestHelper.tryCatch(res, {
      success: undefined,
      error: undefined,
      onSuccess(data) {
        setAvatarInfo(data?.data);
      },
    });
  };

  const userInfo = useDeepCompareMemo(() => {
    if (!sessionUserInfo) return undefined;
    const newUserInfo = new EmployeeInfo({
      employee: sessionUserInfo.employee,
      roleAssignments: sessionUserInfo.roleAssignments,
    });
    return newUserInfo;
  }, [sessionUserInfo]);

  useEffect(() => {
    if (userInfo?.profile?.avatar?.fileKey
      && userInfo?.profile?.avatar?.fileKey !== avatarInfo?.fileKey) {
      useImage(userInfo?.profile?.avatar?.fileKey);
    }
  }, [userInfo]);

  const getCurrentRole: LoggedInUserContextValue['getCurrentRole'] = () => userInfo?.roles?.[0]?.roleType;

  const doesUserRoleIncludes: LoggedInUserContextValue['doesUserRoleIncludes'] = (
    types,
  ) => (
    intersection(types, userInfo?.allRoleTypes).length > 0
  );

  const isAdmin = useMemo(() => doesUserRoleIncludes([RoleTypeEnum.ADMIN])
    && !doesUserRoleIncludes([RoleTypeEnum.SUPER_ADMIN]), [userInfo]);

  const isClinicalStaff = useMemo(() => (
    userInfo?.employeeData.userType === UserTypeEnum.CLINICAL_STAFF
  ), [userInfo?.employeeData?.userType]);

  const isInternalStaff: LoggedInUserContextValue['isInternalStaff'] = useMemo(() => (
    doesUserRoleIncludes([RoleTypeEnum.RD, RoleTypeEnum.CA, RoleTypeEnum.HC])
  ), [userInfo?.allRoleTypes]);

  const isProvider: LoggedInUserContextValue['isProvider'] = useMemo(() => (
    !doesUserRoleIncludes([RoleTypeEnum.RD, RoleTypeEnum.CA, RoleTypeEnum.HC])
    && doesUserRoleIncludes([RoleTypeEnum.MA, RoleTypeEnum.PROVIDER, RoleTypeEnum.NP])
  ), [userInfo?.allRoleTypes]);

  const isBiller: LoggedInUserContextValue['isBiller'] = useMemo(() => (
    doesUserRoleIncludes([RoleTypeEnum.BILLER])
  ), [userInfo]);

  const isClinicBiller: LoggedInUserContextValue['isClinicBiller'] = useMemo(() => (
    doesUserRoleIncludes([RoleTypeEnum.CLINICAL_GROUP_BILLER])
  ), [userInfo]);

  const isClinicManager: LoggedInUserContextValue['isClinicManager'] = useMemo(() => (
    doesUserRoleIncludes([RoleTypeEnum.CLINICAL_MANAGER])
  ), [userInfo]);

  const getDisplayName: LoggedInUserContextValue['getDisplayName'] = useCallback((
    patient, // to fallback role based on patient's assignment
  ) => {
    const fullName = userInfo?.fullName || '';
    const makeName = (suffix: string) => (
      suffix ? `${fullName}, ${suffix}` : fullName
    );
    const makeNameWithRoles = (roles: RoleTypeEnum[]) => {
      const careRelatedRolesOnly = intersection(careRelatedRoles, roles);
      const roleNames = EmployeeInfo.getRoleNames(careRelatedRolesOnly).join(', ');
      return makeName(roleNames);
    };
    if (!userInfo || !fullName) return '';
    // show credential first
    const credentials = userInfo.credentials?.join(', ');
    if (credentials) {
      return makeName(credentials);
    }

    const currentUserId = userInfo.id;
    const displayRoles = [];
    const {
      profile,
      assignedToRD,
      assignedToCA,
      medicalOrganizationAssignment,
    } = patient || {};
    const { doctorId } = profile || {};
    // else show role by patient's assignment if given
    // for external users
    if (doctorId === currentUserId && doesUserRoleIncludes([RoleTypeEnum.PROVIDER])) {
      displayRoles.push(RoleTypeEnum.PROVIDER);
      return makeNameWithRoles([RoleTypeEnum.PROVIDER]);
    }
    // for internal users
    const internalRoleTypes = [];
    if (assignedToRD === currentUserId) {
      // prefer RD, if applicable, to HC
      let roleType = doesUserRoleIncludes([RoleTypeEnum.RD])
        ? RoleTypeEnum.RD : undefined;
      roleType = roleType
        || (doesUserRoleIncludes([RoleTypeEnum.HC]) ? RoleTypeEnum.HC : undefined);
      if (roleType) {
        internalRoleTypes.push(roleType);
      }
      // continue
    }
    if (assignedToCA === currentUserId && doesUserRoleIncludes([RoleTypeEnum.CA])) {
      internalRoleTypes.push(RoleTypeEnum.CA);
    }
    if (internalRoleTypes.length) {
      return makeNameWithRoles(internalRoleTypes);
    }
    // else show role by careGroupId in patient's medical org
    const { careGroupId } = medicalOrganizationAssignment || {};
    if (careGroupId) {
      const roleTypes = userInfo.getRoleTypesByCareGroupId(careGroupId);
      if (roleTypes.length) {
        return makeNameWithRoles(roleTypes);
      }
    }

    return makeNameWithRoles(userInfo.allRoleTypes);
  }, [userInfo]);

  const getCurrentPlatform = () => {
    if (currentPlatform) {
      // determine correct platform based on pathname
      const correctPlatform = determinePortalPlatform();
      return correctPlatform;
    }
    // no platform selected, determine platform based on user role
    if (doesUserRoleIncludes(carePortalRoles)) {
      return PortalPlatform.CarePortal;
    }
    if (doesUserRoleIncludes(billerPortalRoles)) {
      return PortalPlatform.BillerPortal;
    }
    if (doesUserRoleIncludes(adminPortalRoles)) {
      return PortalPlatform.AdminPortal;
    }
    // user has no matching role
    return PortalPlatform.CarePortal;
  };

  useDeepCompareEffect(() => {
    if (!userInfo) return;
    fetchClinicAssignments(userInfo);
    const currentPlatform = getCurrentPlatform();
    setCurrentPlatform(currentPlatform);
  }, [userInfo]);

  const setLoginInfo: LoggedInUserContextValue['setLoginInfo'] = (
    newLoginInfo,
    cb,
  ) => {
    const {
      userInfo,
      roles,
      ...restLoginInfo
    } = newLoginInfo || {};
    _setLoginInfo(restLoginInfo);
    setToken(newLoginInfo?.sessionToken || undefined);

    const user = {
      employee: userInfo as Employee,
      roleAssignments: (roles || []) as RoleAssignment[],
    };
    setSessionUserInfo(user);
    // [1592] Remove this when not hiding AreYouOnSite
    setOnSiteInfo({
      isOnSite: false,
      clinic: undefined,
    });
    cb?.();
  };

  const logout: LoggedInUserContextValue['logout'] = () => onLogout(true);

  const checkIfUserIsLoggedIn = () => {
    // determine whether user completes all login steps
    if (!loginInfo) return false;

    // const isInternalUser = doesUserRoleIncludes(internalUserRoles);
    // if (isInternalUser && !onSiteInfo) return false;
    return true;
  };

  useEffect(() => {
    if (clinicAssignmentsInfo.data?.data?.content) {
      const newClinicAssignments = clinicAssignmentsInfo.data?.data?.content || [];
      // const userRoleByCareGroupId = keyBy(userInfo?.roles, (r) => r.organizationId);
      const clinicIdToCareGroupIds = {} as ClinicIdToCareGroupIds;
      newClinicAssignments.forEach((ca) => {
        const clinicId = ca?.clinic?.id;
        if (!clinicId) return;
        clinicIdToCareGroupIds[clinicId] = ca.careGroups?.map((cg) => cg.id as string) || [];
      });
      setClinicIdToCareGroupIds(clinicIdToCareGroupIds);
    }
  }, [
    clinicAssignmentsInfo.data?.data,
  ]);

  const contextValue = useGetContextValue<LoggedInUserContextValue>(({
    isUserLoggedIn: checkIfUserIsLoggedIn(),
    userId: userInfo?.id,
    loginInfo,
    setLoginInfo,
    setSessionUserInfo,
    onSiteInfo,
    setOnSiteInfo,
    userInfo,
    token,
    updateToken,
    clinicIdToCareGroupIds,
    isSuperAdmin: doesUserRoleIncludes([RoleTypeEnum.SUPER_ADMIN]),
    isAdmin,
    logout,
    doesUserRoleIncludes,
    getCurrentRole,
    isProvider,
    isInternalStaff,
    isBiller,
    currentPlatform,
    setCurrentPlatform,
    isClinicalStaff,
    refetchEmployeeInfo: newEmployeeInfo.refetch,
    avatarInfo,
    setAvatarInfo,
    defaultClinicId,
    getDisplayName,
    isClinicManager,
    isClinicBiller
  }), [
    checkIfUserIsLoggedIn,
    isProvider,
    isClinicalStaff,
    isClinicManager,
    isClinicBiller
  ]);

  return (
    <LoggedInUserContext.Provider value={contextValue}>
      {children}
    </LoggedInUserContext.Provider>
  );
};

export { RoleType };
