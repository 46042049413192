import React from 'react';
import { SleepingRoutineEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface SleepingRoutineEnumComponentProps {
  value?: SleepingRoutineEnum;
}

export const SleepingRoutineEnumComponent = ({ value }: SleepingRoutineEnumComponentProps) => {
  const getFullName = () => {
    switch (value) {
      case SleepingRoutineEnum.NONE: return 'None selected';
      case SleepingRoutineEnum.NO_ROUTINE: return 'No routine for sleeping; regular routine for meals at least once per day';
      case SleepingRoutineEnum.IRREGULAR_ROUTINE: return 'Irregular sleeping routine (sleeps and wakes at different times daily)';
      case SleepingRoutineEnum.REGULAR_ROUTINE: return 'Regular sleeping routine everyday';
      case SleepingRoutineEnum.MODERATELY_REGULAR_ROUTINE: return 'Moderately regular sleeping routine (3-6 days of the week)';
      case SleepingRoutineEnum.DAILY: return 'Daily';
      case SleepingRoutineEnum.WEEK_4_6_TIMES: return '4-6 nights per week';
      case SleepingRoutineEnum.WEEK_2_3_TIMES: return '2-3 nights per week';
      case SleepingRoutineEnum.WEEK_LESS_THAN_2: return 'Fewer 2 nights per week';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  return (<>{getFullName()}</>);
};
