import { Button, Modal } from 'antd';
import React from 'react';
import { ModalProps } from '../../../../types/modal';
import { LogbookComponent, LogbookComponentProps } from './LogbookComponent';
import { MeasurementService } from '../../helper/MeasurementService';

export interface LogbookModalComponentProps<T extends MeasurementService> extends ModalProps {
  logbookProps: LogbookComponentProps<T>;
}

export const LogbookModalComponent = <T extends MeasurementService>({
  logbookProps,
  open,
  onCancel,
}: LogbookModalComponentProps<T>) => (
  <Modal
    open={open}
    footer={null}
    onCancel={onCancel}
  >
    <LogbookComponent
      data={logbookProps.data}
      renderTitle={logbookProps.renderTitle}
      renderLogbookInfo={logbookProps.renderLogbookInfo}
      renderTab={logbookProps.renderTab}
    />
    <Button type="ghost" onClick={onCancel} className="mt20">Close</Button>
  </Modal>
  );
