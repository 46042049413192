import { Space } from 'antd';
import dayjs from 'dayjs';
import { CalendarEventTimeComponent } from '../CalendarEventTimeComponent/CalendarEventTimeComponent';
import { CalendarParticipantListComponent } from '../CalendarParticipantListComponent/CalendarParticipantListComponent';
import { TextWithLinkComponent } from '../../../../uiComponent/TextWithLinkComponent/TextWithLinkComponent';
import { PopoverContentRendererProps } from '../BigCalendarEventComponent/BigCalendarEventComponent';
import { Icons } from '../../../../icons/Icons';
import { ClinicEvent } from '../../../../uc-api-sdk';
import { PatientVisitCardComponent } from '../PatientVisitCardComponent/PatientVisitCardComponent';
import { PatientProfileContainer } from '../../../patient/container/PatientProfileContainer/PatientProfileContainer';
import './CalendarGoogleFullEventComponent.scss';
import { DeleteModalComponent } from '../../../../uiComponent/DeleteModalComponent/DeleteModalComponent';

export interface CalendarGoogleFullEventComponentProps
  extends PopoverContentRendererProps<ClinicEvent> {
    showPatient?: boolean;
    onDelete?: () => void;
    onEdit?: () => void;
    isSubmitting?: boolean;
}

export const CalendarGoogleFullEventComponent = ({
  title,
  start,
  end,
  description,
  info,
  onClose,
  showPatient,
  onDelete,
  onEdit,
  isSubmitting,
}: CalendarGoogleFullEventComponentProps) => (
  <div className="py10 px20">
    <div className="flex jc-e">
      <Space size="large">
        {onDelete && (
          <DeleteModalComponent
            text="Delete this event"
            onConfirm={onDelete}
            isLoading={isSubmitting}
          >
            <Icons.TrashCanIcon />
          </DeleteModalComponent>
        )}
        {onEdit && <Icons.EditIcon onClick={onEdit} />}
        <Icons.CloseIcon onClick={onClose} />
      </Space>
    </div>
    <div className="mb20">
      <h2 className="m0">{title}</h2>
      <CalendarEventTimeComponent
        startTime={dayjs(start)}
        endTime={dayjs(end)}
        showDay
      />
    </div>
    {showPatient && (
      <div className="w100">
        {info?.patientId ? (
          <div className="mb20">
            <div className="event-info-title">Patient</div>
            <PatientProfileContainer
              patientId={info.patientId}
              showLoadingOverlay={false}
              getEnrolledProgram
              getAssignees
              getComplexity
              getFollowUpVisitRange
              getLastMeasurementDate
            >
              {(info, loading) => (
                <PatientVisitCardComponent
                  isLoading={loading}
                  patientInfo={info}
                />
              )}
            </PatientProfileContainer>
          </div>
        ) : (
          <Space className="mb20" wrap>
            <div className="event-info-title">Patient</div>
            <span className="text-gray-scale-3">No patient selected</span>
          </Space>
        )}
      </div>
    )}
    <Space align="start" className="mb20">
      <div className="event-info-title">Participants</div>
      <CalendarParticipantListComponent
        participants={info?.visitParticipants ?? undefined}
      />
    </Space>
    {description && (
      <div>
        <div className="event-info-title">Description</div>
        <p>
          <TextWithLinkComponent value={description} />
        </p>
      </div>
    )}
  </div>
);
