import {
  FC, ReactNode, useMemo
} from 'react';
import { StorageKeyEnum, useSessionStorage } from '../../hooks';
import { ReqHookContext, ReqHookContextValue } from '../../uc-api-sdk/staticFiles/useReqHook';
import EnvConfig from '../../configs/envConfig/envConfig';
import { CacheOptionContextProvider } from '../../uc-api-sdk/staticFiles/context/cache/CacheOptionContext';

export interface ApiProviderProps {
  children: ReactNode;
}

export const ApiProvider: FC<ApiProviderProps> = ({
  children,
}) => {
  const [token] = useSessionStorage(StorageKeyEnum.TOKEN);

  const ReqHookValue: ReqHookContextValue = useMemo(() => ({
    token: token as string,
    url: EnvConfig.restfulBaseServerUrl,
    isMock: false,
  }), [token]);

  return (
    <CacheOptionContextProvider>
      <ReqHookContext.Provider value={ReqHookValue}>
        {children}
      </ReqHookContext.Provider>
    </CacheOptionContextProvider>
  );
};
