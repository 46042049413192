import { useCallback, useState } from 'react';
import { Button } from 'antd';
import { useBoolean } from 'usehooks-ts';
import { InputType } from '../../../Input/types';
import { BPCuffSizeModalComponent } from '../BPCuffSizeModalComponent/BPCuffSizeModalComponent';
import {
  AssignDeviceInfo,
  CuffSizeEnum,
  DeviceModelEnum,
  DeviceTypeEnum,
} from '../../../../uc-api-sdk';
import { DeviceCheckboxWithAssignModalComponent, DeviceCheckboxWithAssignModalComponentProps } from '../DeviceCheckboxWithAssignModalComponent/DeviceCheckboxWithAssignModalComponent';
import { ClearDeviceAssignFormComponent, ClearDeviceAssignFormComponentProps } from '../ClearDeviceAssignFormComponent/ClearDeviceAssignFormComponent';
import { DeviceCheckboxComponentProps } from '../DeviceCheckboxComponent/DeviceCheckboxComponent';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import { BasicEnumComponent } from '../../../../enumComponent/BasicEnumComponent/BasicEnumComponent';
import { DeviceImageComponent } from '../../../device/DeviceImageComponent';

import './BPClearDeviceCheckboxComponent.scss';
import { SupportDeviceEnum } from '../../../device/type';
import { SupportDeviceEnumComponent } from '../../../../enumComponent/SupportDeviceEnumComponent/SupportDeviceEnumComponent';

export interface BPClearDeviceCheckboxComponentProps extends
  InputType<AssignDeviceInfo> {
  model: DeviceModelEnum;
  supportDevice: SupportDeviceEnum;
}

export const BPClearDeviceCheckboxComponent = ({
  value,
  onChange,
  model,
  supportDevice,
  disabled,
}: BPClearDeviceCheckboxComponentProps) => {
  const [tempValue, setTempValue] = useState<AssignDeviceInfo | undefined>(value);
  const {
    value: isOpen,
    setTrue: setOpenTrue,
    setFalse: setOpenFalse,
  } = useBoolean();

  const renderSupportDevice = () => (
    <>
      <DeviceImageComponent deviceModel={supportDevice} />
      <div className="support-device-info">
        <SupportDeviceEnumComponent value={supportDevice} />
      </div>
    </>
  );

  const name = (
    <div className="flex gap5">
      <span>iHealth BPM1</span>
      <span>+</span>
      {renderSupportDevice()}
    </div>
  );

  const extra = (
    <div className="flex ai-bl">
      <span>Cuff Size:</span>
      {
        value?.cuffSize
          ? (<BasicEnumComponent value={value.cuffSize} />)
          : <EmptyComponent />
      }
      <Button
        type="link"
        className="pl0"
        onClick={setOpenTrue}
        disabled={!value}
      >
        Edit
      </Button>
    </div>
  );

  const handleOnChange: DeviceCheckboxComponentProps['onChange'] = (v) => {
    setTempValue(v);
    onChange?.(v);
  };

  const handleChangeCuffSize = (v: CuffSizeEnum) => {
    handleOnChange({ ...(value || tempValue), cuffSize: v });
    setOpenFalse();
  };

  const handleOnDeviceSubmit: ClearDeviceAssignFormComponentProps['onSubmit'] = (values) => {
    handleOnChange({ ...(value || tempValue), ...values });
  };

  const renderModalContent: DeviceCheckboxWithAssignModalComponentProps['modalContent'] = useCallback((close) => (
    <ClearDeviceAssignFormComponent
      onSubmit={(v) => {
        handleOnDeviceSubmit(v);
        close();
      }}
    />
  ), [handleOnDeviceSubmit]);

  return (
    <div className="bp-clear-checkbox">
      <DeviceCheckboxWithAssignModalComponent
        key={`${model}-checkbox`}
        model={model}
        name={name}
        type={DeviceTypeEnum.BP}
        extra={extra}
        value={value}
        onChange={handleOnChange}
        modalContent={renderModalContent}
        disabled={disabled}
      />
      <BPCuffSizeModalComponent
        key={`${model}-cuff`}
        open={isOpen}
        onCancel={setOpenFalse}
        value={value?.cuffSize ?? undefined}
        onChange={handleChangeCuffSize}
      />
    </div>
  );
};
