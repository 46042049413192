import { CptCodeUnitEnum } from '../../uc-api-sdk';

export interface CPTCodeUnitCategoryEnumComponentProps {
  value?: CptCodeUnitEnum | null;
}

export const CPTCodeUnitCategoryEnumComponent = ({
  value
}: CPTCodeUnitCategoryEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case CptCodeUnitEnum.THREE_MONTH_UNITS:
        return '3-month units';
      case CptCodeUnitEnum.SIX_MONTH_UNITS:
        return '6-month units';
      default:
        return '';
    }
  };
  return (<>{getText()}</>);
};
