import {
  EnrollmentSectionsEnum,
  EnrollmentSectionsStatuses,
  EnrollmentSectionsWeight,
} from './type';

export const enrollmentSectionsStatusInitialValue: EnrollmentSectionsStatuses = {
  [EnrollmentSectionsEnum.Diagnosis]: false,
  [EnrollmentSectionsEnum.VitalsAndPrograms]: false,
  [EnrollmentSectionsEnum.Insurance]: false,
  [EnrollmentSectionsEnum.BasicInfo]: false,
  [EnrollmentSectionsEnum.Consent]: false,
  [EnrollmentSectionsEnum.TechLevel]: false,
  [EnrollmentSectionsEnum.DeliveryMethod]: false,
  [EnrollmentSectionsEnum.Devices]: false,
  [EnrollmentSectionsEnum.TechOnboarding]: false,
  [EnrollmentSectionsEnum.InitialAssessment]: false,
};

export const enrollmentSectionsWeight: EnrollmentSectionsWeight = {
  [EnrollmentSectionsEnum.Diagnosis]: 5,
  [EnrollmentSectionsEnum.VitalsAndPrograms]: 6,
  [EnrollmentSectionsEnum.Insurance]: 11,
  [EnrollmentSectionsEnum.BasicInfo]: 11,
  [EnrollmentSectionsEnum.Consent]: 0,
  [EnrollmentSectionsEnum.TechLevel]: 10,
  [EnrollmentSectionsEnum.DeliveryMethod]: 10,
  [EnrollmentSectionsEnum.Devices]: 13,
  [EnrollmentSectionsEnum.TechOnboarding]: 17,
  [EnrollmentSectionsEnum.InitialAssessment]: 17,
};
