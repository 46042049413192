/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable max-len */
// This file should be removed once the code gen starts generating baseController
import { useMemo } from 'react';
import {
  APIResponse, APIResponseClass, FoodLog, PageResponse, foodLogService,
} from '../../../uc-api-sdk';
import {
  MakeRequestHookConfig, UseRequestFactory, useRequestFactory,
  BaseSearchParams, BaseAPIResponse, AjaxOptions,
} from '../../../uc-api-sdk/staticFiles/useReqHook';

export const createFoodLogSearchObject = (value: APIResponse<PageResponse<FoodLog>>) => new APIResponseClass<unknown>(value);

export const useFoodLogSearch = <RCN = undefined>(configs: MakeRequestHookConfig<BaseSearchParams<FoodLog>, AjaxOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseSearchParams<FoodLog>,
    BaseAPIResponse<PageResponse<FoodLog>>,
    // @ts-ignore
    typeof foodLogService.search,
    AjaxOptions,
    APIResponseClass<unknown>
  > = useMemo(() => ({
    reqFunc: (...args: Parameters<typeof foodLogService.search>) => (
      foodLogService.search(...args)
    ),
    ResponseClassCreator: createFoodLogSearchObject,
    typeName: 'useFoodLogSearch',
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};
