import { ReactNode } from 'react';
import { Button, Checkbox, CheckboxProps } from 'antd';
import classNames from 'classnames';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';

export interface LLMReviewButtonComponentProps {
  defaultValue?: boolean;
  disabled?: boolean;
  className?: string;
  checkboxText?: ReactNode;
  buttonText?: ReactNode;
  onClick: () => void;
}

export const LLMReviewButtonComponent = ({
  defaultValue,
  onClick,
  disabled,
  className = '',
  checkboxText = "I've reviewed the results",
  buttonText = 'Import selected results',
}: LLMReviewButtonComponentProps) => {
  const {
    value: isChecked,
    setTrue: check,
    setFalse: uncheck,
  } = useBoolean(defaultValue);
  const isDisabledClick = disabled || !isChecked;

  const handleOnChange: CheckboxProps['onChange'] = (e) => {
    if (e.target.checked) {
      check();
    } else {
      uncheck();
    }
  };

  return (
    <div
      className={classNames({
        'flex ai-c jc-sb pt30 pb24': true,
        [className]: !!className,
      })}
    >
      <Checkbox
        checked={isChecked}
        onChange={handleOnChange}
        disabled={disabled}
      >
        {checkboxText}
      </Checkbox>
      <Button
        type="primary"
        onClick={onClick}
        disabled={isDisabledClick}
      >
        {buttonText}
      </Button>
    </div>
  );
};
