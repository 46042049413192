import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMessagePatientContext } from '../../../../contexts/MessageContext/MessagePatientContext';
import { useMessageServicesContext } from '../../../../contexts/MessageContext/MessageServicesContext';
import { MixpanelEvents, MixpanelEventsParents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { useMpTrackingHelper } from '../../../../hooks/useMpTrackingHelper/useMpTrackingHelper';
import { CHSServices } from '../../../../services/CHSServices/CHSServices';
import { ErrorBoundaryComponent } from '../../../../uiComponent/ErrorBoundaryComponent/ErrorBoundaryComponent';
import OverlayLoading from '../../../../uiComponent/OverlayLoading/OverlayLoading';
import { MessageHistoryComponent } from '../../component/MessageHistoryComponent/MessageHistoryComponent';

export interface MessageHistoryContainerProps { }

export const MessageHistoryContainer = () => {
  const {
    userMap,
    handleSetMultipleUserMap,
  } = useMessageServicesContext();
  const {
    patientChannel,
    isLoadingPatientChannel,
    patientId,
  } = useMessagePatientContext();

  const location = useLocation();
  const { startEvent, endEvent } = useMpTrackingHelper(
    {
      eventStart: MixpanelEvents.ChatStart,
      eventEnd: MixpanelEvents.ChatEnd,
      parent: location.pathname.includes('care-portal/messages') ? MixpanelEventsParents.MessagePage : MixpanelEventsParents.PatientProfile,
      patientId
    }
  );

  useEffect(
    () => {
      startEvent();
      return () => endEvent();
    },
    []
  );

  useEffect(() => {
    // load missing userMap
    const missingPatientIds = new Set();
    const missingEmployeeIds = new Set();
    patientChannel?.messages?.forEach((message) => {
      const { publisher, userRole } = message.payload;
      if (!publisher || userMap[publisher]) return;
      const isPatient = CHSServices.isPatient(userRole);
      if (isPatient) {
        missingPatientIds.add(publisher);
      } else {
        missingEmployeeIds.add(publisher);
      }
    });
    handleSetMultipleUserMap(
      Array.from(missingPatientIds) as string[],
      Array.from(missingEmployeeIds) as string[],
    );
  }, [patientChannel]);

  return (
    <ErrorBoundaryComponent identifier="MessageHistoryContainer">
      <OverlayLoading loading={isLoadingPatientChannel} />
      <MessageHistoryComponent />
    </ErrorBoundaryComponent>
  );
};
