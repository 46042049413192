import { DatePicker, Input } from 'antd';
import { useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { PriorAuthSubmissionDateFormItemComponent } from '../PriorAuthSubmissionDateFormItemComponent/PriorAuthSubmissionDateFormItemComponent';
import { CPTCodeFormItemComponent } from '../CPTCodeFormItemComponent/CPTCodeFormItemComponent';

export const PriorAuthDeniedFormItemComponent = () => {
  const insuranceForm = useInsuranceForm();
  return (
    <>
      <PriorAuthSubmissionDateFormItemComponent />
      <FormItem
        info={insuranceForm.getInfo('priorAuthDeniedDate')}
      >
        <DatePicker
          format="MM/DD/YYYY"
          placeholder="MM/DD/YYYY"
        />
      </FormItem>

      <CPTCodeFormItemComponent />

      <FormItem
        info={insuranceForm.getInfo('priorAuthDeniedReason')}
      >
        <Input.TextArea
          autoSize={{ minRows: 3, maxRows: 3 }}
        />
      </FormItem>
    </>
  );
};
