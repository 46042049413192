import { RoleTypeEnum } from '../../uc-api-sdk';

export interface MembersRolesTitleComponentProps {
  membersRolesList: RoleTypeEnum[];
}

export const MembersRolesTitleComponent = ({
  membersRolesList,
}: MembersRolesTitleComponentProps) => {
  const getText = () => {
    if (membersRolesList.length === 1) return `${membersRolesList[0]}s`;
    const firsts = membersRolesList.slice(0, membersRolesList.length - 1);
    const last = membersRolesList[membersRolesList.length - 1];
    return `${firsts.join('s, ')}s and ${last}s`;
  };

  return (
    <>{getText()}</>
  );
};
