import { useCallback } from 'react';
import { compact, map } from 'lodash';
import { useTableColumns } from '../../../../../hooks/table/useTableColumns/useTableColumns';
import { createLLMLabResultColumns } from '../../createLLMLabResultColumns';
import { LLMLabResultTableComponent, LLMLabResultTableComponentProps } from '../LLMLabResultTableComponent/LLMLabResultTableComponent';
import { LLMLabResultFormComponentProps, LLMLabResultTableItem } from '../../type';
import { useLLMLabResultRecordCheck } from '../../hook/useLLMLabResultCheck';
import { useLabResultHelper } from '../../../../transcribing/hook/useLabResultHelper';
import { TranscribedLabResult } from '../../../../../uc-api-sdk';

export interface LLMLabResultA1CFormComponentProps extends LLMLabResultFormComponentProps { }

export const LLMLabResultA1CFormComponent = ({
  templateId,
  templateName,
  dataSource,
  onChange,
  disabled,
}: LLMLabResultA1CFormComponentProps) => {
  const {
    postProcessInfo,
    checkDuplicateConflict,
  } = useLLMLabResultRecordCheck();
  const {
    getCollectedDate
  } = useLabResultHelper();

  const columns = useTableColumns(
    createLLMLabResultColumns(),
    ['rowSelection', 'dateCollected', 'isInHouse', 'value'],
    [disabled]
  );

  const handleOnChange = (
    changed: Partial<LLMLabResultTableItem>[],
    values: LLMLabResultTableItem[]
  ) => {
    onChange?.(values);
    if (!changed?.[0]?.dateCollected) {
      return;
    }
    const processedResults = map(values, (item) => {
      if (!item.dateCollected) {
        return null;
      }
      return {
        templateId,
        templateName,
        ...getCollectedDate(item.dateCollected),
        results: [item],
      };
    });
    checkDuplicateConflict(compact(processedResults));
  };

  const handleGetUpdatedData = useCallback<NonNullable<LLMLabResultTableComponentProps['getUpdatedData']>>((
    data,
    tableDataIndex,
  ) => {
    // save new processed data to form based on new date collected
    const postProcessProcessedResult = (
      postProcessInfo.data?.data?.processedResult || []
    ) as TranscribedLabResult[];
    const newParsedData = postProcessProcessedResult?.[tableDataIndex]?.results?.[0];
    if (!newParsedData) {
      return data;
    }
    return {
      ...data,
      conflict: newParsedData.conflict,
      duplicate: newParsedData.duplicate,
      existingValue: newParsedData.duplicate ? newParsedData.value : newParsedData.existingValue,
    };
  }, [postProcessInfo.data?.data?.processedResult]);

  return (
    <div className="flex fd-c gap1">
      <LLMLabResultTableComponent
        className="llm-lab-result-a1c-table"
        columns={columns}
        dataSource={dataSource}
        getUpdatedData={handleGetUpdatedData}
        onValuesChange={handleOnChange}
        disabled={disabled}
      />
    </div>
  );
};
