import React from 'react';
import { ColumnType } from 'antd/lib/table';
import { filter, map } from 'lodash';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import {
  BillableEventStatusEnum,
  BillableTimeSegment,
  RoleTypeEnum,
} from '../../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { AccountHelper } from '../../../../helpers/account';
import { LimitedUser } from '../../../../generates';
import { createEmployeeSearchFilter } from '../../../table/filter/EmployeeSearchFilter';
import { BillableEventStatusEnumComponent } from '../../../../enumComponent/BillableEventStatusEnumComponent/BillableEventStatusEnumComponent';
import { LabelWithValueComponent } from '../../../../uiComponent/LabelWithValueComonent/LabelWithValueComponent';
import { BillingTimeStatusColumnComponent } from './BillingTimeStatusColumnComponent';

export const createBillingTimeColumns = {
  startTime: (): ColumnType<BillableTimeSegment> => ({
    title: 'Time',
    render: (v?: string) => (
      <LabelWithValueComponent
        showTooltip
        value={(
          <DisplayDateComponent value={v} format="USA_DATE" />
        )}
      />
    ),
    sorter: true,
    width: '14%',
  }),
  totalTime: (): ColumnType<BillableTimeSegment> => ({
    title: 'Total time',
    render: (v: string) => (
      <LabelWithValueComponent
        showTooltip
        value={(
          <div className="flex">
            <DisplayOrEmptyComponent value={v} />
            mins
          </div>
        )}
      />
    ),
    sorter: true,
    width: '14%',
  }),
  billableTime: (): ColumnType<BillableTimeSegment> => ({
    title: 'Billable',
    render: (v: string) => (
      <LabelWithValueComponent
        showTooltip
        value={(
          <div className="flex">
            <DisplayOrEmptyComponent value={v} />
            mins
          </div>
        )}
      />
    ),
    width: '14%',
  }),
  status: (): ColumnType<BillableTimeSegment> => ({
    title: 'Status',
    render: (value, d) => (
      <LabelWithValueComponent
        showTooltip
        value={(
          <BillingTimeStatusColumnComponent
            status={d.status as BillableEventStatusEnum}
            showIcon
          />
        )}
      />
    ),
    filters: map(
      filter(BillableEventStatusEnum, it => it !== BillableEventStatusEnum.MERGED_DROP),
      (value) => ({
        text: (<BillableEventStatusEnumComponent value={value} />),
        value,
      })
    ),
    width: '16%',
  }),
  provider: (): ColumnType<BillableTimeSegment> => ({
    title: 'Contributed By',
    render: (v: LimitedUser) => (
      <LabelWithValueComponent
        showTooltip
        value={(
          <DisplayOrEmptyComponent value={AccountHelper.getFullName(v)} />
        )}
      />
    ),
    ...createEmployeeSearchFilter([
      RoleTypeEnum.MA,
      RoleTypeEnum.PROVIDER,
      RoleTypeEnum.RD,
      RoleTypeEnum.HC,
      RoleTypeEnum.CA,
      RoleTypeEnum.BILLER,
    ]),
    width: '14%',
  }),
  activity: (): ColumnType<BillableTimeSegment> => ({
    title: 'Activity',
    render: (v) => (
      <LabelWithValueComponent
        showTooltip
        value={v.join('; ')}
      />
    ),
  }),
};
