import { ListComponent, ListComponentProps } from '../../uiComponent/ListComponent/ListComponent';
import { ProgramCategoryEnum } from '../../uc-api-sdk';
import { ProgramCategoryEnumComponent, ProgramCategoryEnumComponentProps } from './ProgramCategoriesComponent';

export interface ProgramCategoryEnumListComponentProps {
  value: ProgramCategoryEnum[] | null | undefined;
  type: ProgramCategoryEnumComponentProps['type'];
  splitter?: ListComponentProps<ProgramCategoryEnum>['splitter'];
}

export const ProgramCategoryEnumListComponent = ({
  value,
  type,
  splitter,
}: ProgramCategoryEnumListComponentProps) => (
  <ListComponent
    value={value || []}
    splitter={splitter}
    renderItem={(v) => (
      <ProgramCategoryEnumComponent
        value={v}
        type={type}
      />
    )}
  />
);
