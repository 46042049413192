/* eslint-disable react/jsx-props-no-spreading */
import {
  CheckboxOptionType,
  Radio,
  RadioGroupProps,
  Space,
  SpaceProps,
} from 'antd';
import { map } from 'lodash';
import { InputTypeRequired } from '../../features/Input/types';

export interface RadioGroupComponentProps<T extends string> extends
  Partial<InputTypeRequired<T>>,
  Omit<RadioGroupProps, 'onChange' | 'value' | 'options'>
{
  options: CheckboxOptionType[];
  direction?: 'vertical' | 'horizontal';
  spaceProps?: SpaceProps;
}

export const RadioGroupComponent = <T extends string>({
  value,
  onChange,
  direction,
  options,
  spaceProps,
  ...props
}: RadioGroupComponentProps<T>) => {
  const handleChange: RadioGroupProps['onChange'] = (evt) => {
    onChange?.(evt.target.value);
  };

  return (
    <Radio.Group value={value} onChange={handleChange} {...props}>
      <Space {...spaceProps} direction={direction}>
        {map(options, ({ label, ...v }) => (
          <Radio {...v} key={v.value.toString()}>{label}</Radio>
        ))}
      </Space>
    </Radio.Group>
  );
};
