import { useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';

export const useUnableToEngageForm = () => {
  const factory = useFormHookFactory({
    unableToEngageReason: {
      name: 'unableToEngageReason',
      label: 'Unable to Engage Reason',
    },
  });

  return factory;
};
