import React from 'react';
import { TagComponent } from '../../../../../uiComponent/TagComponent/TagComponent';

export const MeetingStatusComponent = {
  Meeting: () => (
    <TagComponent type="success" background="none" iconType="default" iconColor="blue">
      Meeting
    </TagComponent>
  ),
  NotMeeting: () => (
    <TagComponent type="error" background="none" iconType="default" iconColor="red">
      Not Meeting
    </TagComponent>
  ),
  UnableToConclude: () => (
    <TagComponent type="warning" background="none" iconType="default" iconColor="grey">
      Unable to Conclude
    </TagComponent>
  ),
};
