import {
  Button, Modal
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { FormOptions } from '../../../../hooks/useFormHookFactory/useFormHookFactory';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { PriorAuthCheckboxComponent, PriorAuthCheckboxComponentProps } from '../PriorAuthLayout/PriorAuthCheckboxComponent';
import { useInsuranceUpdateHandler } from '../../hook/insuranceUpdateHandler';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { PriorAuthCase, PriorAuthCaseStatusEnum } from '../../../../uc-api-sdk';

export interface PriorAuthCheckboxFormItemContainerProps {
  form: FormOptions['form'];
  disabled?: boolean;
}

export const PriorAuthCheckboxFormItemContainer = ({
  form,
  disabled,
}: PriorAuthCheckboxFormItemContainerProps) => {
  const { info } = usePatientContext();
  const patientId = info?.id || '';
  const insuranceForm = useInsuranceForm({ form });
  const {
    cancelPriorAuthInfo,
    cancelPriorAuthCase,
    updateInsuranceInfo,
    updateInsurance
  } = useInsuranceUpdateHandler(patientId);
  const insuranceUpdate = useUpdate('INSURANCE_UPDATED');
  const isLoading = cancelPriorAuthInfo.isLoading || updateInsuranceInfo.isLoading;

  const handleConfirmUncheck = async (
    activeCase: PriorAuthCase,
    cb: () => void
  ) => {
    const {
      status
    } = activeCase;
    if (
      [
        // can ONLY cancel unfinished cases
        PriorAuthCaseStatusEnum.NEED_SUBMISSION,
        PriorAuthCaseStatusEnum.PENDING_RESULT
      ].includes(status || '' as PriorAuthCaseStatusEnum)
    ) {
      await cancelPriorAuthCase();
    } else {
      const currentFormValues = insuranceForm.form.getFieldsValue();
      await updateInsurance({
        ...currentFormValues,
        priorAuth: false,
      });
    }
    insuranceForm.form.setFieldsValue({
      [insuranceForm.getName('priorAuth')]: false,
      [insuranceForm.getName('priorAuthStatus')]: null,
      [insuranceForm.getName('priorAuthActiveCase')]: null,
    });
    insuranceUpdate.updateValue();
    cb();
  };

  const debouncedHandleConfirm = useDebounce(
    handleConfirmUncheck,
    500,
    [handleConfirmUncheck]
  );

  const handleOnChangePriorAuth = (
    isChecked?: boolean,
    activeCase?: PriorAuthCase,
  ) => {
    if (!patientId) {
      // required to call api request
      console.error('Patient id is not available');
      return;
    }
    if (!isChecked) {
      const modal = Modal.warn({});

      const handleOnCancel = () => {
        modal.destroy();
      };

      modal.update({
        icon: null,
        closeIcon: (
          <CloseOutlined
            onClick={handleOnCancel}
          />
        ),
        okButtonProps: { style: { display: 'none' } },
        content: (
          <div className="flex fd-c pt20 pb10">
            <div>
              Are you confirm Prior-authorization is not required anymore ?
            </div>
            <div className="w100 flex fd-c jc-c ai-c mt50">
              <Button
                type="primary"
                className="w140"
                onClick={() => {
                  if (!activeCase?.status) {
                    modal.destroy();
                    return;
                  }
                  debouncedHandleConfirm(
                    activeCase,
                    () => {
                      modal.destroy();
                    }
                  );
                }}
                disabled={isLoading}
              >
                Yes
              </Button>
              <LinkButton
                underlineOnHover
                onClick={handleOnCancel}
                disabled={isLoading}
              >
                Cancel
              </LinkButton>
            </div>
          </div>
        ),
        centered: true,
        width: 480,
        closable: true,
      });
    }
  };

  return (
    <FormItem
      noStyle
      shouldUpdate={insuranceForm.shouldUpdate(['priorAuthStatus', 'priorAuthActiveCase'])}
    >
      {
        ({ getFieldValue }) => {
          const priorAuthActiveCase = insuranceForm.getValue('priorAuthActiveCase', getFieldValue);
          const allowCancel = insuranceForm.shouldAllowCancelPriorAuthCase(getFieldValue);
          const customOnChange: PriorAuthCheckboxComponentProps['customOnChange'] = (
            priorAuthActiveCase
              ? (
                (e) => {
                  e.stopPropagation();
                  handleOnChangePriorAuth(e.target.value, priorAuthActiveCase);
                }
              ) : undefined
          );
          return (
            <FormItem
              name={insuranceForm.getName('priorAuth')}
            >
              <PriorAuthCheckboxComponent
                customOnChange={customOnChange}
                disabled={!allowCancel || disabled}
              />
            </FormItem>
          );
        }
      }
    </FormItem>
  );
};
