/* eslint-disable import/prefer-default-export */
import { PoStat } from '../../../../uc-api-sdk';
import { useResultToSummaryTable } from '../useResultToSummaryTable/useResultToSummaryTable';

export const useBOResultToSummaryTable = (data: PoStat) => {
  const order: (keyof PoStat)[] = [
    'overnight',
    'morning',
    'afternoon',
    'evening',
    'normal',
    'low',
    'criticalLow',
  ];

  const response = useResultToSummaryTable(data as Required<PoStat>, order);

  return response;
};
