import { ButtonType } from 'antd/lib/button';
import { MediumDrawerWidth } from '../../../../constants/drawer';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { LabResultClass } from '../../../../uc-api-sdk';
import { DefaultExtendableDrawers } from '../../../../uiComponent/ExtendableDrawerComponent/controller/DefaultExtendableDrawers';
import { ExtendableDrawerComponent } from '../../../../uiComponent/ExtendableDrawerComponent/ExtendableDrawerComponent';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { EditLabResultContainer } from '../EditLabResultContainer/EditLabResultContainer';
import { ExtendableDrawerButtonComponent } from '../../../../uiComponent/ExtendableDrawerComponent/component/ExtendableDrawerButtonComponent';

const DEFAULT_DRAWER_ID = DefaultExtendableDrawers.editLabResult;
export interface LabResultEditButtonContainerProp {
  content?: 'Edit' | 'View Result';
  btnType?: ButtonType;
  labResult?: LabResultClass;
  patientId?: string;
}
export const LabResultEditButtonContainer = ({
  content = 'Edit',
  btnType = 'default',
  labResult,
  patientId,
}: LabResultEditButtonContainerProp) => {
  const {
    value: open,
    setTrue: setOpen,
    setFalse: setClose,
  } = useBoolean(false);
  useUpdateListener('LAB_DELETE', setClose);
  const { send } = useBillableTimeInterventionComp();

  const handleOpen = () => {
    if (content === 'View Result') {
      send({
        patientId,
        component: BillableTimeInterventionComponentEnum.Transcribing,
        detail:
      BillableTimeInterventionDetailEnum.TranscribingVisitTranscribeLabResultReviewLabResultReview,
      });
    }
    setOpen();
  };

  return (
    <>
      <ExtendableDrawerButtonComponent
        buttonText={content}
        buttonProps={{
          type: btnType,
        }}
        onClick={handleOpen}
      />
      <ExtendableDrawerComponent
        id={DEFAULT_DRAWER_ID}
        open={open}
        onClose={setClose}
        footer={null}
        width={MediumDrawerWidth}
        title={labResult?.templateName}
        maskClosable={false}
      >
        <EditLabResultContainer
          id={labResult?.id ?? undefined}
          templateId={labResult?.templateId ?? undefined}
          onCancel={setClose}
          mode={content === 'View Result' ? 'View' : 'Edit'}
          patientId={patientId}
        />
      </ExtendableDrawerComponent>
    </>
  );
};
