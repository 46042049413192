import { DeviceModelEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface DeviceModelGroupEnumComponentProps {
  value?: DeviceModelEnum;
}

export const DeviceModelGroupEnumComponent = ({ value }: DeviceModelGroupEnumComponentProps) => {
  const getFullName = () => {
    switch (value) {
      case DeviceModelEnum.BP5:
      case DeviceModelEnum.BP3L:
      case DeviceModelEnum.PY_802_LTE:
      case DeviceModelEnum.BPM1:
      case DeviceModelEnum.BPM1_HOTSPOT:
      case DeviceModelEnum.BP7250: return 'Blood Pressure Monitor';
      case DeviceModelEnum.BG5:
      case DeviceModelEnum.BG1:
      case DeviceModelEnum.BG1S:
      case DeviceModelEnum.BIOLAND:
      case DeviceModelEnum.BG5S: return 'Blood Glucose Monitor';
      case DeviceModelEnum.PO3: return 'Pulse Oximeter';
      case DeviceModelEnum.HS2:
      case DeviceModelEnum.HS4S:
      case DeviceModelEnum.HS6:
      case DeviceModelEnum.HS2S: return 'Weight Monitor';
      case DeviceModelEnum.TM3:
      case DeviceModelEnum.TS28B: return 'Thermometer';
      case DeviceModelEnum.LOAN_DEVICE: return 'Unified Care Loaner Phone';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  return (<>{getFullName()}</>);
};
