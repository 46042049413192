import { TableCacheNameEnum } from '../../../../hooks/useTableChange/useTableChange';
import { PatientListTypeEnum } from '../../../../uc-api-sdk';
import { EmptyWatchedListComponent } from '../../../patients/component/WatchedPatientsComponent/EmptyWatchedListComponent';
import { PatientsTableContainer } from '../../../patients/container/PatientsTableContainer/PatientsTableContainer';
import { ProviderViewWatchedPatientsTableComponent } from '../../component/ProviderViewWatchedPatientsTableComponent/ProviderViewWatchedPatientsTableComponent';

export interface ProviderViewWatchedPatientsContainerProps {

}

export const ProviderViewWatchedPatientsContainer = () => (
  <PatientsTableContainer
    type={PatientListTypeEnum.ALL}
    name={TableCacheNameEnum.WatchList}
    watchlist
    needToFilter={false}
  >
    {({
      totalSize,
      pagination,
      dataSource,
      onChange,
      filters,
      sorters,
      isLoading,
      exportData,
    }) => (
      <ProviderViewWatchedPatientsTableComponent
        totalSize={totalSize}
        pagination={pagination}
        dataSource={dataSource}
        onChange={onChange}
        filters={filters}
        sorters={sorters}
        locale={{
          emptyText: <EmptyWatchedListComponent />
        }}
        loading={isLoading}
        exportData={exportData}
      />
    )}
  </PatientsTableContainer>
);
