import { max } from 'lodash';
import { SeverityHelper } from './severityHelper';
import { Point, Severity, PoSeverity } from '../type';

export interface CreateMarkerOptions {
  color?: string;
  lineColor?: string;
  severity?: Severity;
  poSeverity?: PoSeverity;
  symbol?: 'circle' | 'triangle' | 'square',
  enabled?: boolean;
  isCopd?: boolean;
}

export const createMarker = ({
  color,
  lineColor,
  severity,
  poSeverity,
  symbol,
  enabled,
  isCopd = false,
}: CreateMarkerOptions) => {
  const markerColor = color
    || (poSeverity && SeverityHelper.getPoColor(poSeverity))
    || (severity && SeverityHelper.getMarkerColor(severity))
    || undefined;

  const markerSymbol = symbol || (isCopd ? 'triangle' : 'circle');

  return {
    enabled,
    halo: null,
    symbol: markerSymbol,
    states: {
      hover: {
        lineWidth: 0,
        halo: null,
        animation: {
          duration: 20,
        },
      },
    },
    radius: 4,
    fillColor: markerColor,
    lineColor: lineColor || markerColor,
  };
};

export const findMaxWithStep = (arr: number[], step: number, defaultMax: number) => {
  const maxNumber = max(arr);
  if (maxNumber !== undefined) {
    return maxNumber + (step - (maxNumber % step));
  }
  return defaultMax;
};

export const getYValue = (v: Point) => v.y;
