import { InputNumber, InputNumberProps } from 'antd';

export interface MeasureFrequencyComponentProps extends InputNumberProps { }

export const MeasureFrequencyInputComponent = ({
  value,
  onChange,
  precision = 0,
  min = 0,
  placeholder = 'Times',
  ...props
}: MeasureFrequencyComponentProps) => (
  <InputNumber
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    value={value}
    onChange={onChange}
    precision={precision}
    min={min}
    placeholder={placeholder}
  />
);
