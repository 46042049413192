import { FutureClinicEventContextProvider } from '../../../../contexts/FutureClinicEventContext/FutureClinicEventContext';
import { TechOnboardingWorklistComponent, TechOnboardingWorklistComponentProps } from '../../component/TechOnboardingWorklistComponent/TechOnboardingWorklistComponent';

export interface TechOnboardingWorklistContainerProps
  extends TechOnboardingWorklistComponentProps { }

export const TechOnboardingWorklistContainer = ({
  patientId,
  clinicEvent,
  onCheckOut,
  onClose,
  onClinicEventUpdate,
  onCompleteCharting,
  disabled,
}: TechOnboardingWorklistContainerProps) => (
  <FutureClinicEventContextProvider
    patientId={patientId}
    startTime={clinicEvent.startTimeUtc}
  >
    <TechOnboardingWorklistComponent
      patientId={patientId}
      clinicEvent={clinicEvent}
      onCheckOut={onCheckOut}
      onClinicEventUpdate={onClinicEventUpdate}
      onClose={onClose}
      onCompleteCharting={onCompleteCharting}
      disabled={disabled}
    />
  </FutureClinicEventContextProvider>
);
