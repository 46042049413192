import { isEmpty } from 'lodash';
import { HealthConditionItem } from '../../../../../uc-api-sdk';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';
import { PrintHealthConditionsComponent } from '../../../../vitals/component/PrintVitalComponent/PrintHealthConditionsComponent';

export interface PrintCarePlanHealthConditionsComponentProps {
  healthConditions?: HealthConditionItem[] | null;
}

export const PrintCarePlanHealthConditionsComponent = ({
  healthConditions,
}: PrintCarePlanHealthConditionsComponentProps) => {
  if (isEmpty(healthConditions)) {
    return null;
  }
  return (
    <>
      <div className="page-break" />
      <TextComponent className="pop-text">
        Health Conditions
      </TextComponent>
      <PrintHealthConditionsComponent
        healthConditions={healthConditions || []}
      />
    </>
  );
};
