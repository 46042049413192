import { useMemo } from 'react';
import {
  GetOptions, MakeRequestHookConfig, UseRequestFactory, useRequestFactory,
} from '../../../uc-api-sdk/staticFiles/useReqHook';
import { chsServices } from '../CHSServices';
import { MarkUnreadTagMessageParams, MarkUnreadTagMessageResponseData } from '../types';

export const useMarkUnreadTagMessage = <RCN = undefined>(
  configs?: MakeRequestHookConfig<MarkUnreadTagMessageParams, GetOptions, RCN>,
) => {
  const baseConfigs: UseRequestFactory<
    MarkUnreadTagMessageParams,
    MarkUnreadTagMessageResponseData,
    typeof chsServices.markReadTagMessage,
    GetOptions,
    MarkUnreadTagMessageResponseData
  > = useMemo(() => ({
    reqFunc: (...args: Parameters<typeof chsServices.markReadTagMessage>) => (
      chsServices.markReadTagMessage(...args)
    ),
    ResponseClassCreator: chsServices.getResponse,
    typeName: 'useMarkUnreadTagMessage',
  }), []);

  return useRequestFactory(baseConfigs, configs);
};
