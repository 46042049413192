export enum PATIENT_TABLE_TABS {
  ENROLLED_PATIENTS = 'ENROLLED_PATIENTS',
  UNENROLLED_PATIENTS = 'UNENROLLED_PATIENTS',
  ALL_PATIENTS = 'ALL_PATIENTS',
  WATCHLIST = 'WATCHLIST',
  ASSIGNED = 'ASSIGNED',
}

export const InsuranceStatus = {
  success: 'Insurance updated.',
  error: 'An error occurred, please try again later.',
};

export const GenderTexts = {
  genderTooltip: 'The gender assigned to a child at birth based on the child\'s external anatomy. This selection will be used to calculate the BMR and Adjusted BW.',
  genderIdentityTooltip: 'The gender identity that represents a person\'s internal sense of gender, which may differ from the sex assigned at birth.',
};

export const SearchPatientPlaceholder = 'Search a patient by Name/ MRN/ DOB/ Tel';
