import { useEffectOnce, useUpdateEffect } from 'usehooks-ts';
import { useRef, useState } from 'react';
import { generateUUID } from 'pubnub';
import EnvConfig from '../../../configs/envConfig/envConfig';
import { useLoggedInUserFromContext } from '../../../contexts/loggedInUserContext';
import {
  ClientStatusEnum,
  ConnectionConfig,
  PONG_TASK_INTERVAL,
  WebSocketClient,
} from '../../../lib/notificationClient';
import useDebounce from '../../../hooks/useDebounce/useDebounce';
import { useMessageSubscription } from './useMessageSubscription';
import { StorageKeyEnum, useSessionStorage } from '../../../hooks';
import { useMessageServicesContext } from '../../../contexts/MessageContext/MessageServicesContext';
import { useEffectWithPrevValue } from '../../../hooks/useEffectWithPrevValue/useEffectWithPrevValue';
import { useDeepCompareEffect } from '../../../hooks/useDeepCompareEffect';
import { useMixpanelContext } from '../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../contexts/MixpanelContext/MixpanelEvents';

export enum NotificationKey {
  CONNECTION_ID = 'notificationClientConnectionId',
  CLIENT_STATUS = 'notificationClientStatus',
  DEBUG = 'notificationClientDebug', // read-only value
  ON_READY = 'notificationClientOnReady', // read-only value
  CLIENT_ID = 'notificationClientId', // read-only value
}

export const useNotificationClient = () => {
  const { token } = useLoggedInUserFromContext();
  const [connectionId, setConnectionId] = useSessionStorage<string>(
    NotificationKey.CONNECTION_ID as string as StorageKeyEnum,
    ''
  );
  const [debug] = useSessionStorage<boolean | undefined>(
    NotificationKey.DEBUG as string as StorageKeyEnum,
  );
  const [
    getUnreadPaginatedChannels,
    setGetUnreadPaginatedChannels
  ] = useState(false);
  const clientRef = useRef<WebSocketClient | undefined>(undefined);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const clientStatusRef = useRef<ClientStatusEnum | undefined>(undefined);
  const {
    handleMessageEvent,
  } = useMessageSubscription();
  const {
    handleGetUnreadPaginatedChannels,
  } = useMessageServicesContext();
  const [
    clientStatusChangeTime,
    setClientStatusChangeTime,
  ] = useState<number | undefined>();
  const {
    send
  } = useMixpanelContext();

  const startClient = (client: WebSocketClient) => {
    if (clientRef.current) {
      return;
    }
    clientRef.current = client;
    try {
      clientRef.current.start();
    } catch (error) {
      console.error(error);
    }
  };

  const createClient = (
    token: string,
    connectionId: string,
  ) => {
    const config: ConnectionConfig = {
      token,
      baseUrl: EnvConfig.notificationClientUrl, // notification server address
      connectionId: connectionId as string,
      ssl: true, // ssl certificate
      operation: {
        onReady: (timestamp) => {
          send({
            event: MixpanelEvents.NotificationClientIsReady,
          });
          sessionStorage.setItem(NotificationKey.ON_READY, new Date(timestamp).toISOString());
        },
        getClientId: (clientId) => {
          sessionStorage.setItem(NotificationKey.CLIENT_ID, clientId);
        },
        onMessage: (message): boolean => {
          handleMessageEvent(message);
          return true;
        },
        onTerminated: () => {
          clientRef.current = undefined;
          setConnectionId('');
        },
        onStatusChange: (status) => {
          const timestamp = new Date().getTime();
          send({
            event: MixpanelEvents.NotificationClientStatusChange,
            properties: {
              clientStatus: status,
              clientStatusChangeTime: timestamp,
            },
          });
          clientStatusRef.current = status;
          setClientStatusChangeTime(timestamp);
        },
      },
      timeout: {
        terminate: EnvConfig.afkTimerThreshold
      },
      debug,
    };
    return new WebSocketClient(config);
  };

  const debouncedGetUnreadPaginatedChannels = useDebounce(
    handleGetUnreadPaginatedChannels,
    500,
    [handleGetUnreadPaginatedChannels]
  );

  const initConnection = useDebounce(async (
    token?: string,
    connectionId?: string,
  ) => {
    if (
      ![
        ClientStatusEnum.CONNECTING,
        ClientStatusEnum.CONNECTED
      ].includes(clientStatusRef.current || '' as ClientStatusEnum)
      && token
      && connectionId
    ) {
      startClient(createClient(token, connectionId));
    }
  }, 500);

  useUpdateEffect(() => {
    if (getUnreadPaginatedChannels) {
      debouncedGetUnreadPaginatedChannels();
      setGetUnreadPaginatedChannels(false);
    }
  }, [getUnreadPaginatedChannels]);

  const terminateConnection = useDebounce((
    restartClient = true,
  ) => {
    // should terminate the connection when token is changed
    if (clientRef.current) {
      clientRef.current.terminate();
      clientRef.current = undefined;
    }
    if (restartClient) {
      setConnectionId('');
    }
  });

  useEffectOnce(() => {
    // set new connection id when page is loaded
    setConnectionId(generateUUID());

    return () => {
      terminateConnection(false);
    };
  });

  useUpdateEffect(() => {
    if (!connectionId) {
      // make sure connection id is always available
      const newConnectionId = generateUUID();
      setConnectionId(newConnectionId);
    } else {
      initConnection(token, connectionId);
    }
  }, [connectionId]);

  useEffectWithPrevValue(token, (prevToken) => {
    if (prevToken && token) {
      const shouldRestart = !!token;
      terminateConnection(shouldRestart);
    }
  });

  useDeepCompareEffect(() => {
    const clientStatus = clientStatusRef.current;
    if (clientStatus === ClientStatusEnum.CONNECTED) {
      send({
        event: MixpanelEvents.NotificationClientGetUnreadOnConnected,
      });
      setGetUnreadPaginatedChannels(true);
    }
    if (clientStatus === ClientStatusEnum.DISCONNECTED) {
      if (timeoutRef.current) return;
      timeoutRef.current = setTimeout(() => {
        // help with reconnecting if client failed to reconnect
        if (clientStatusRef.current === ClientStatusEnum.DISCONNECTED) {
          setConnectionId('');
          timeoutRef.current = undefined;
        }
      }, PONG_TASK_INTERVAL + 1000);
    }
    sessionStorage.setItem(NotificationKey.CLIENT_STATUS, clientStatus || '');
  }, [clientStatusChangeTime]);

  return null;
};
