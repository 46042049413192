import {
  ReactNode,
  createContext,
  useContext
} from 'react';
import { Dayjs } from 'dayjs';
import {
  find,
  map,
  isFunction,
  keyBy,
  omit,
} from 'lodash';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import {
  ConditionEnum,
  MonthlyMeasurement,
  VitalEnumType,
  useMeasurementGetMonthlyMeasurements,
  useTemplatesGetClinicGoalTemplate,
} from '../../uc-api-sdk';
import TimezoneService from '../../helpers/timezone/timezoneService';
import { usePatientContext } from '../PatientInfoContext/PatientInfoContext';

export type MonthlyMeasurementValue = {
  [key in ConditionEnum]?: MonthlyMeasurement & { vitals?: VitalEnumType[] };
}

export interface MonthlyMeasurementContextValue {
  startTime?: Dayjs;
  endTime?: Dayjs;
  monthlyMeasurement: MonthlyMeasurementValue;
  patientVitals?: VitalEnumType[];
}

const MonthlyMeasurementContext = createContext<
  MonthlyMeasurementContextValue | undefined
>(undefined);

export const useMonthlyMeasurementContext = () => {
  const context = useContext(MonthlyMeasurementContext);
  return context as MonthlyMeasurementContextValue;
};

export interface MonthlyMeasurementContextProviderProps {
  children: ReactNode | ((props: MonthlyMeasurementContextValue) => ReactNode);
  patientId: string;
}
export const MonthlyMeasurementContextProvider = ({
  children,
  patientId,
}: MonthlyMeasurementContextProviderProps) => {
  const monthlyMeasurementInfo = useMeasurementGetMonthlyMeasurements({
    params: { memberId: patientId },
  });
  const clinicalGoalTemplate = useTemplatesGetClinicGoalTemplate({});
  const { info: { enrolledProgramService } = {} } = usePatientContext();
  const patientVitals = enrolledProgramService?.getVitals();

  const contextValue = useGetContextValue<MonthlyMeasurementContextValue>((() => {
    let monthlyMeasurementData = monthlyMeasurementInfo.data?.data || [];
    const clinicalGoalTemplateData = clinicalGoalTemplate.data?.data || [];
    monthlyMeasurementData = map(monthlyMeasurementData, (item) => {
      const monthlyMeasurementCondition = item.condition;
      const { vitals } = find(
        clinicalGoalTemplateData,
        { condition: monthlyMeasurementCondition }
      ) || {};
      return { ...item, vitals } as NonNullable<MonthlyMeasurementValue[ConditionEnum]>;
    });
    const monthlyMeasurement = keyBy(monthlyMeasurementData, 'condition');
    const [mostRecentData] = monthlyMeasurementData;
    const { startTime, endTime } = mostRecentData?.averageValue?.[0] || {};
    return {
      startTime: startTime ? TimezoneService.calcDateTimeDayjs(startTime).endOf('d') : undefined,
      endTime: endTime ? TimezoneService.calcDateTimeDayjs(endTime).endOf('d') : undefined,
      monthlyMeasurement: omit(monthlyMeasurement, ConditionEnum.UNMAPPED),
      patientVitals,
    };
  })(), [
    monthlyMeasurementInfo.data?.data,
    clinicalGoalTemplate.data?.data,
    patientVitals,
  ]);

  return (
    <MonthlyMeasurementContext.Provider value={contextValue}>
      {
        isFunction(children)
          ? children(contextValue)
          : children
      }
    </MonthlyMeasurementContext.Provider>
  );
};
