import React, { ReactNode } from 'react';
import WarningImage from '../../../../assets/images/warning.png';

export interface ConsentFormMissingInfoComponentProps {
  description: ReactNode;
}

export const ConsentFormMissingInfoComponent = ({
  description,
}: ConsentFormMissingInfoComponentProps) => (
  <div className="w100 h100 flex jc-c ai-c fd-c">
    <img src={WarningImage} alt="warning" />
    <div>{description}</div>
  </div>
);
