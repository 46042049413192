import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';
import { useState } from 'react';

interface UploadPresignedS3Response {
  res: AxiosResponse | undefined;
  error?: Error;
}

interface UploadPresignedS3Options {
  headers?: AxiosRequestHeaders;
}

export const useUploadPresignedS3 = () => {
  const [progress, setProgress] = useState(0);

  const uploadToPresignedS3 = async (
    presignedS3Url: string,
    file: File,
    options?: UploadPresignedS3Options,
  ): Promise<UploadPresignedS3Response> => {
    const {
      headers
    } = options || {};
    try {
      const ContentType = headers?.['Content-Type'] || file.type;
      const res = await axios.put(presignedS3Url, file, {
        headers: {
          ...headers,
          'Content-Type': ContentType
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      });
      return {
        res,
        error: undefined,
      };
    } catch (error) {
      return {
        res: undefined,
        error: error as Error,
      };
    }
  };

  return {
    progress,
    uploadToPresignedS3,
  };
};
