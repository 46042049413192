import { Select, SelectProps } from 'antd';
import { map } from 'lodash';
import { CareNoteTagEnumComponent } from '../../../../../enumComponent/CareNoteTagEnumComponent/CareNoteTagEnumComponent';
import { CareNoteTagEnum } from '../../../../../uc-api-sdk';
import { InputTypeArray } from '../../../../Input/types';
import './CareNoteTagInputComponent.scss';

export interface CareNoteTagInputComponentProps extends InputTypeArray<CareNoteTagEnum> {
  careNoteTagOptions: CareNoteTagEnum[];
  unclosableTags?: CareNoteTagEnum[];
  placeholder?: string;
  popupClassName?: string;
}

export const CareNoteTagInputComponent = ({
  value = [],
  onChange,
  careNoteTagOptions,
  placeholder = 'Select',
  unclosableTags,
  popupClassName = '',
}: CareNoteTagInputComponentProps) => {
  const handleChange: SelectProps['onChange'] = (v) => {
    onChange?.(v);
  };

  const options = map(careNoteTagOptions, (v) => ({
    label: <CareNoteTagEnumComponent value={v} />,
    value: v,
    disabled: !!unclosableTags?.includes(v),
  }));

  return (
    <Select
      className="CareNoteTagInputComponent"
      mode="multiple"
      size="middle"
      placeholder={placeholder}
      onChange={handleChange}
      value={value}
      showSearch
      options={options}
      popupClassName={popupClassName}
    />
  );
};
