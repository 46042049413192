import { Select } from 'antd';
import { map } from 'lodash';
import React, { FC, useMemo, useState } from 'react';
import { InputType } from '../../features/Input/types';

// eslint-disable-next-line no-shadow
export enum DatePeriodEnum {
  SevenDays = 7,
  FourteenDays = 14,
  ThirtyDays = 30,
  SixtyDays = 60,
  NinetyDays = 90,
  HundredAndEightyDays = 180,
  ThreeHundredAndSixtyDays = 360,
}

export const allDropdownOptions = [
  DatePeriodEnum.SevenDays,
  DatePeriodEnum.FourteenDays,
  DatePeriodEnum.ThirtyDays,
  DatePeriodEnum.SixtyDays,
  DatePeriodEnum.NinetyDays,
  DatePeriodEnum.HundredAndEightyDays,
  DatePeriodEnum.ThreeHundredAndSixtyDays,
];

export interface DatePeriodDropdownComponentProps extends InputType<DatePeriodEnum> {
  dropdownOptions?: DatePeriodEnum[];
  defaultValue?: DatePeriodEnum | number;
}

export const DatePeriodDropdownComponent: FC<DatePeriodDropdownComponentProps> = ({
  dropdownOptions,
  defaultValue,
  value,
  onChange,
}) => {
  const options = useMemo(() => dropdownOptions || allDropdownOptions, [dropdownOptions]);
  const [initialValue] = useState(() => defaultValue || options[0]);

  const renderUnavailableValue = () => (
    <Select.Option
      key="freeSet"
      disabled
      value={!value || options.includes(value) ? 'freeSet' : value}
    >
      Free set
    </Select.Option>
  );

  return (
    <Select
      className="datePeriodDropdownComponent"
      defaultValue={initialValue}
      value={value}
      onSelect={onChange}
    >
      {map(options, (v, k) => (
        <Select.Option key={k} value={v}>
          {v}
          {' '}
          Days
        </Select.Option>
      ))}
      {renderUnavailableValue()}
    </Select>
  );
};

export default DatePeriodDropdownComponent;
