import { DatePicker } from 'antd';
import moment from 'moment';
import { FC } from 'react';
import { MMM_YYYY } from '../../../../constants/timeFormat';
import { useFromDateToDate } from '../../../../hooks/useFromDateToDate/useFromDateToDate';
import { PatientCommonCardTitleComponent } from '../../../patient/component/PatientCommonCardTitleComponent/PatientCommonCardTitleComponent';
import { FoodLogTableContainer } from '../../container/FoodLogTableContainer/FoodLogTableContainer';

export interface FoodLogComponentProps {
  patientId: string;
}
export const FoodLogComponent: FC<FoodLogComponentProps> = ({
  patientId,
}) => {
  const {
    value: date,
    fromToDate,
    handleChangeMonthAndGetStartEndOfMonth,
  } = useFromDateToDate({
    type: 'date',
    initial: moment(),
    unitOfTime: 'month',
  });

  return (
    <div className="food-log-component">
      <div className="flex jc-sb mb10">
        <PatientCommonCardTitleComponent>
          Food Diary
        </PatientCommonCardTitleComponent>
        <DatePicker
          picker="month"
          format={MMM_YYYY}
          value={date}
          clearIcon={false}
          onChange={handleChangeMonthAndGetStartEndOfMonth}
        />
      </div>
      <FoodLogTableContainer
        patientId={patientId}
        fromDate={fromToDate.fromDate}
        toDate={fromToDate.toDate}
      />
    </div>
  );
};
