import { intersection, keyBy } from 'lodash';
import { Space } from 'antd';
import { MonitoringVital, VitalEnumType } from '../../../../uc-api-sdk';
import { InterventionFrequencyComponent } from '../InterventionFrequencyComponent/InterventionFrequencyComponent';
import { useDeepCompareMemo } from '../../../../hooks/useDeepCompareEffect';
import { vitalsMonitoringOrder } from '../../../../lib/constants/vitalsMonitoringOrder';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { VitalsMonitoringExerciseComponent } from '../../../vitals/component/VitalsMonitoringScheduleComponent/VitalsMonitoringExerciseComponent/VitalsMonitoringExerciseComponent';
import { InterventionMonitoringLayoutComponent } from './InterventionVitalsMonitoringLayoutComponent';
import { VitalsMonitoringBGTableProcessorComponent } from '../../../vitals/component/VitalsMonitoringScheduleComponent/VitalsMonitoringBGComponent/VitalsMonitoringBGTableComponent/VitalsMonitoringBGTableProcessor';
import './InterventionVitalsMonitoringComponent.scss';

export interface InterventionVitalsMonitoringComponentProps {
  vitals: MonitoringVital[];
}

export const InterventionVitalsMonitoringComponent = ({
  vitals,
}: InterventionVitalsMonitoringComponentProps) => {
  const [vitalsTypeInOrder, vitalsByType] = useDeepCompareMemo(() => {
    const vitalsType = vitals.map((v) => v.type);
    const vitalsTypeInOrder = intersection(vitalsMonitoringOrder, vitalsType);
    const vitalsByType = keyBy(vitals, 'type');
    return [vitalsTypeInOrder, vitalsByType];
  }, [vitals]);

  const renderBG = (v: VitalEnumType) => {
    const bgVital = vitalsByType[v];
    const {
      name,
      schedule
    } = bgVital.templateDetails || {};
    return (
      <div className="w60">
        <InterventionMonitoringLayoutComponent
          key={v}
          vitalType={v}
        >
          <Space>
            <DisplayOrEmptyComponent
              value={name}
            />
          </Space>
          <div className="pt10">
            <VitalsMonitoringBGTableProcessorComponent
              value={schedule || {}}
            />
          </div>
        </InterventionMonitoringLayoutComponent>
      </div>
    );
  };

  return (
    <div className="flex jc-sb">
      {
        vitalsTypeInOrder.includes(VitalEnumType.BG)
        && renderBG(VitalEnumType.BG)
      }
      <div className="line-height-2 vital-type">
        {
          vitalsTypeInOrder.map((v) => {
            if (!v) return '';
            const vital = vitalsByType[v];
            const {
              target,
              repeat,
            } = vital;

            switch (v) {
              case VitalEnumType.BG:
                return null;
              case VitalEnumType.EXERCISE:
                return (
                  <InterventionMonitoringLayoutComponent key={v} vitalType={v}>
                    <VitalsMonitoringExerciseComponent
                      value={vital}
                    />
                  </InterventionMonitoringLayoutComponent>
                );
              default:
                return (
                  <InterventionMonitoringLayoutComponent key={v} vitalType={v}>
                    <InterventionFrequencyComponent
                      target={target || undefined}
                      repeat={repeat || undefined}
                    />
                  </InterventionMonitoringLayoutComponent>
                );
            }
          })
        }
      </div>
    </div>
  );
};
