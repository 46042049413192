import { MedRncDropdownComponent } from '../../../../uiComponent/MedicationRncComponent/MedRncDropdownComponent';
import { OtherMedicationInformationComponent } from '../../../../uiComponent/OtherMedicationInformationComponent/OtherMedicationInformationComponent';
import { useMedicationContainerForm } from '../../../../hooks/useMedicationForm/useMedicationContainerForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { OtherInputComponent } from '../../../../selectComponents/MedicationOtherInputComponent/OtherInputComponent';

export interface MedicationComplianceFormItemsComponentProps {
  visible?: boolean;
}

export const MedicationComplianceFormItemsComponent = ({
  visible = true,
}: MedicationComplianceFormItemsComponentProps) => {
  const hook = useMedicationContainerForm();

  return (
    <div>
      {
        visible
          ? (
            <>
              <FormItem
                className="required-field"
                required
                info={hook.getInfo('compliance')}
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: 'This is required!' }]}
              >
                <MedRncDropdownComponent />
              </FormItem>
              <FormItem
                noStyle
                shouldUpdate={hook.shouldUpdate(['compliance'])}
              >
                {
                  ({ getFieldValue }) => {
                    const isOther = hook.checkIfOtherCompliance(getFieldValue);
                    return isOther
                      ? (
                        <FormItem
                          className="required-field"
                          labelCol={{ span: 24 }}
                          name={hook.getName('complianceOther')}
                          required
                          rules={[{ required: true, message: 'This is required!' }]}
                          preserve={false}
                        >
                          <OtherInputComponent className="default-br" />
                        </FormItem>
                      ) : null;
                  }
                }
              </FormItem>
              <FormItem
                info={hook.getInfo('otherInfo')}
                labelCol={{ span: 24 }}
              >
                <OtherMedicationInformationComponent />
              </FormItem>
            </>
          ) : null
      }
    </div>
  );
};
