import { Button, Divider, message } from 'antd';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { MTPRFormContainer } from '../../../mtpr/container/MTPRFormContainer/MTPRFormContainer';
import { MonthlyMeasurementContextProvider } from '../../../../contexts/MonthlyMeasurementContext/MonthlyMeasurementContext';

import './OutstandingMTPRComponent.scss';
import { MTPRDataDateComponent } from '../../../mtpr/component/MTPRDataDateComponent/MTPRDataDateComponent';
import { MTPRTitleComponent } from '../../../mtpr/component/MTPRTitleComponent/MTPRTitleComponent';
import { MTPRTipComponent } from '../../../mtpr/component/MTPRTipComponent/MTPRTipComponent';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { ProviderNoteCategoryEnum } from '../../../../uc-api-sdk';

export interface OutstandingMTPRComponent {
  patientId: string;
  onDone?: () => void;
  showSuccessMessage?: boolean;
  monthlyReviewId?: string;
  type?: ProviderNoteCategoryEnum;
}

export const OutstandingMTPRComponent = ({
  patientId,
  onDone,
  showSuccessMessage,
  monthlyReviewId,
  type,
}: OutstandingMTPRComponent) => {
  const completeMTPRHook = useUpdate('MTPR_FINISHED');

  const handleOnSubmit = () => {
    message.destroy();
    message.success('Great! You submitted MTPR for this patient.', 3);
    completeMTPRHook.updateValue();
    onDone?.();
  };

  return (
    <MonthlyMeasurementContextProvider patientId={patientId}>
      {
        ({ startTime, endTime }) => (
          <div className="outstanding-mtpr">
            <div className="outstanding-mtpr-header flex gap1 fd-c">
              <MTPRTitleComponent />
              <TextComponent size="medium" color="gray2">
                <MTPRDataDateComponent
                  startTime={startTime}
                  endTime={endTime}
                />
              </TextComponent>
              <MTPRTipComponent />
              <Divider className="my12" />
            </div>
            <MTPRFormContainer
              patientId={patientId}
              onSubmit={handleOnSubmit}
              monthlyReviewId={monthlyReviewId}
              type={type}
              formButtons={(
                <div className="outstanding-mtpr-buttons flex ai-bl jc-e">
                  <Button
                    htmlType="submit"
                    type="primary"
                  >
                    Submit
                  </Button>
                </div>
              )}
              showSuccessMessage={showSuccessMessage}
            />
          </div>
        )
      }
    </MonthlyMeasurementContextProvider>
  );
};
