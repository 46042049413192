import { AlertCardComponent } from '../../../../uiComponent/AlertCardComponent/AlertCardComponent';

export interface RejectedAlertComponentProps {
}

export const RejectedAlertComponent = () => (
  <AlertCardComponent
    type="error"
    message="Patient is not able to enroll"
  />
);
