import { FC } from 'react';
import './ComplexityTag.scss';
import { PatientComplexityEnum } from '../../../../uc-api-sdk';
import { PatientStatusTagComponent } from '../../../patient/component/TagsComponent/PatientStatusTagComponent/PatientStatusTagComponent';

export interface ComplexityTagProps {
  level?: PatientComplexityEnum | null;
}

export const ComplexityTag: FC<ComplexityTagProps> = ({ level }) => {
  if (level === PatientComplexityEnum.COMPLEX) {
    return (
      <PatientStatusTagComponent.ComplexTag />
    );
  }
  if (level === PatientComplexityEnum.NON_COMPLEX) {
    return (
      <span className="non-complex-tag">
        <PatientStatusTagComponent.NonComplexTag />
      </span>
    );
  }

  return null;
};
