import { EnrolledProgramStatusEnum, Nullable } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface EnrolledProgramStatusEnumComponentProps {
  value?: Nullable<EnrolledProgramStatusEnum>
}

export const EnrolledProgramStatusEnumComponent = ({
  value
}: EnrolledProgramStatusEnumComponentProps) => {
  const getText = () => {
    if (!value) return 'Unenrolled';

    switch (value) {
      case EnrolledProgramStatusEnum.ENROLLED:
        return 'Enrolled';
      case EnrolledProgramStatusEnum.UNENROLLED:
        return 'Unenrolled';
      case EnrolledProgramStatusEnum.DISCHARGED:
        return 'Discharged';
      case EnrolledProgramStatusEnum.REJECTED:
        return 'Rejected';
      default:
        return <DisplayOrEmptyComponent value={value} />;
    }
  };

  return (<span>{getText()}</span>);
};
