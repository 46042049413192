import { DEFAULT_PAGINATION } from '../../../constants/pagination';
import { usePatientCareTableContext } from '../context/PatientCareTableContext/PatientCareTableContext';
import { PageInfo, usePatientCareGetMyPatientCareResponse } from '../../../uc-api-sdk';
import { useOnCallContext } from '../context/OnCallContext/OnCallContext';
import { useOnCallEffect } from '../../oncall/hook/useOnCallEffect';

export const usePatientCareTableSearch = () => {
  const {
    processedFilter: filters,
    processedSort: sorter,
    pageInfo,
    handleTableChange,
  } = usePatientCareTableContext();
  const { isOnCall } = useOnCallContext({ type: 'patientCare' });

  const page: PageInfo = {
    page: pageInfo?.page ?? 1,
    size: pageInfo?.size ?? DEFAULT_PAGINATION.pageSize,
    sort: ((sorter?.length || 0) > 0 ? sorter : []),
  };

  const patients = usePatientCareGetMyPatientCareResponse({});

  const fetchPatients = () => {
    patients.send({
      params: {
        request: {
          patientCareRequest: {
            filter: filters || {},
            pageInfo: page
          },
          onCall: isOnCall,
          includeTestPatient: (
            filters?.includeTestPatient || false
          ) as boolean,
        }
      },
    });
  };

  useOnCallEffect(
    'patientCare',
    () => fetchPatients(),
    [filters, page]
  );

  return {
    patients,
    handleTableChange,
  };
};
