import React from 'react';
import { Select } from 'antd';
import { InputType } from '../../../Input/types';
import { VisitTypeEnum } from '../../../../uc-api-sdk';

export interface VisitTypeSelectComponentProps extends InputType<VisitTypeEnum> {
  disabled?: boolean;
}

export const VisitTypeSelectComponent = ({
  value,
  onChange,
  disabled,
}: VisitTypeSelectComponentProps) => (
  <Select value={value} onChange={onChange} disabled={disabled}>
    <Select.Option value={VisitTypeEnum.INIT}>Initial Assessment</Select.Option>
    <Select.Option value={VisitTypeEnum.TECH_ONBOARDING}>Tech Onboarding</Select.Option>
    <Select.Option value={VisitTypeEnum.FOLLOW_UP}>Follow-up</Select.Option>
    <Select.Option value={VisitTypeEnum.TECH}>Tech Support</Select.Option>
    <Select.Option value={VisitTypeEnum.ADDITIONAL}>Additional</Select.Option>
    <Select.Option value={VisitTypeEnum.MNT} disabled>
      MNT (Medical nutrition therapy)
    </Select.Option>
  </Select>
);
