import { Dayjs } from 'dayjs';
import React from 'react';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { TimezoneType } from '../../../../types/timezone';

export interface PatientLocalTimeComponentProps {
  time: Dayjs | string | undefined;
  timezone?: string | TimezoneType;
  mode?: 'startTime' | 'medicalAlert' | 'measureTime';
}

export const PatientLocalTimeComponent = ({
  time,
  timezone,
  mode = 'startTime',
}: PatientLocalTimeComponentProps) => {
  const localStartTime = () => (
    <>
      Patient local start time will be
      {' '}
      <DisplayDateComponent
        value={time}
        timezone={timezone}
        format="TIME_TIMEZONE"
      />
    </>
  );

  const medicalAlertPatientTime = () => (
    <>
      Patient's time
      {' '}
      <DisplayDateComponent
        value={time}
        timezone={timezone}
        format="MEDICAL_ALERT_PATIENT_DATE"
      />
    </>
  );

  const medicalAlertPatientMeasureTime = () => (
    <>
      Delayed measurement received. Patient's measurement time was
      {' '}
      <DisplayDateComponent
        value={time}
        timezone={timezone}
        format="MEDICAL_ALERT_PATIENT_DATE"
      />
    </>
  );

  const getLocalTime = () => {
    switch (mode) {
      case 'startTime':
        return localStartTime();
      case 'medicalAlert':
        return medicalAlertPatientTime();
      case 'measureTime':
        return medicalAlertPatientMeasureTime();
      default:
        return localStartTime();
    }
  };

  return (
    time ? getLocalTime() : null
  );
};
