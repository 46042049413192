import { DatePicker, Divider, Space } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { RangePickerProps } from 'antd/lib/date-picker';
import { InputTypeRequired } from '../../features/Input/types';
import { FromDateToDateDayjs } from '../../types/date';
import { USA_DATE } from '../../constants/timeFormat';
import DatePeriodDropdownComponent, { DatePeriodDropdownComponentProps } from '../DateDropdownAndRangeComponent/DatePeriodDropdownComponent';
import { PrevNextComponent } from '../PrevNextComponent/PrevNextComponent';
import './DateRangeSelectComponent.scss';

export interface DateRangeSelectComponentProps extends
  InputTypeRequired<FromDateToDateDayjs> {
  styleType?: 'full' | 'short';

}

export const DateRangeSelectComponent = ({
  value,
  onChange,
  styleType = 'full',
}: DateRangeSelectComponentProps) => {
  const handleChange: RangePickerProps['onChange'] = (v) => {
    onChange?.({
      fromDate: dayjs(v?.[0]?.toDate()),
      toDate: dayjs(v?.[1]?.toDate()),
    });
  };

  const handleDatePeriodChange: DatePeriodDropdownComponentProps['onChange'] = (v) => {
    const date = value?.toDate || dayjs();
    onChange?.({
      fromDate: date.clone().startOf('day').add(-(v || 0), 'days'),
      toDate: date.clone().endOf('day'),
    });
  };

  const getDatePeriodValue = () => {
    if (value?.fromDate && value?.toDate) {
      return value.toDate.diff(value.fromDate, 'days');
    }
    return 0;
  };

  const handleGoNextOrPrev = (direction: 1 | -1) => () => {
    const diff = getDatePeriodValue();
    onChange?.({
      fromDate: value.fromDate.clone().startOf('day').add(direction * diff, 'days'),
      toDate: value.toDate.clone().endOf('day').add(direction * diff, 'days'),
    });
  };

  const renderRangePicker = () => (
    <DatePicker.RangePicker
      value={[
        moment(dayjs(value?.fromDate).toDate()),
        moment(dayjs(value?.toDate).toDate()),
      ]}
      format={USA_DATE}
      onChange={handleChange}
      allowClear={false}
      separator={<Divider type="vertical" className="range-divider" />}
      ranges={{
        'Last 7 Days': [moment().startOf('day').add(-7, 'days'), moment()],
        'Last 14 Days': [moment().startOf('day').add(-14, 'days'), moment()],
        'Last 30 Days': [moment().startOf('day').add(-30, 'days'), moment()],
        'Last 90 Days': [moment().startOf('day').add(-90, 'days'), moment()],
      }}
    />
  );

  return (
    styleType === 'full'
      ? (
        <div className="date-range-select-component">
          <Space>
            <PrevNextComponent
              gotoNext={handleGoNextOrPrev(1)}
              gotoPre={handleGoNextOrPrev(-1)}
            >
              {renderRangePicker()}
            </PrevNextComponent>
            <div>
              <DatePeriodDropdownComponent
                value={getDatePeriodValue()}
                onChange={handleDatePeriodChange}
              />
            </div>
          </Space>
        </div>
      )
      : renderRangePicker()
  );
};
