import { Dayjs } from 'dayjs';
import { DisplayDateComponent, DisplayDateComponentProps } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import './CalendarEventTimeComponent.scss';
import { BigCalendarHelper } from '../../helper/BigCalendarHelper/BigCalendarHelper';

export interface CalendarEventTimeComponentProps {
  startTime?: Dayjs;
  endTime?: Dayjs;
  showDay: boolean;
}

export const CalendarEventTimeComponent = ({
  startTime,
  endTime,
  showDay,
}: CalendarEventTimeComponentProps) => {
  const renderTime = (time?: Dayjs) => {
    if (!time) return '--';
    if (time.minute() === 0) {
      return <DisplayDateComponent value={time} format="SMALL_HOUR" />;
    }
    return <DisplayDateComponent value={time} format="SMALL_TIME" />;
  };

  const renderDate = (
    date?: Dayjs,
    format: DisplayDateComponentProps['format'] = 'FULL_DAY_OF_MONTH',
    showSeparator = true,
  ) => (
    showDay && (
      <>
        <DisplayDateComponent
          value={date}
          format={format}
        />
        {
          showSeparator
          && (
            <>
              &nbsp;
              &#xb7;
              &nbsp;
            </>

          )
        }
      </>
    )
  );

  const renderDateTime = () => {
    const isMultiDayEvent = BigCalendarHelper.checkIsMultiDayEvent(startTime, endTime);
    if (isMultiDayEvent) {
      const isAllDayEvent = BigCalendarHelper.checkIsAllDayEvent(startTime, endTime);
      return (
        isAllDayEvent
          ? (
            <>
              {renderDate(startTime, 'MMM_DD')}
              {' '}
              All day
            </>
          ) : (
            <>
              {renderDate(startTime, 'MMM_DD', false)}
              {' - '}
              {renderDate(endTime, 'MMM_DD', false)}
            </>
          )
      );
    }
    return (
      <>
        {renderDate(startTime)}
        {renderTime(startTime)}
        {' - '}
        {renderTime(endTime)}
        <DisplayDateComponent value={endTime} format="AM_PM" />
      </>
    );
  };

  return (
    <div className="calendar-event-time-component">
      {renderDateTime()}
    </div>
  );
};
