import { Button } from 'antd';
import { SUCCESS_SHIELD_ICON } from '../../../../assets/commonIconInfo';
import { Icon } from '../../../../icons/Icon';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { useRouter } from '../../../../hooks/useRouter/useRouter';

export interface PriorAuthStartSuccessComponentProps {
  onOk: () => void;
}

export const PriorAuthStartSuccessComponent = ({
  onOk,
}: PriorAuthStartSuccessComponentProps) => {
  const { pathname, changePage } = useRouter();
  const isInUtilitiesPage = (
    pathname === changePage.createCarePortalInsurancePriorAuthorizationUrl?.()
  );

  const utilitiesPathText = (
    !isInUtilitiesPage
      ? 'Utilities > Patient Insurance Prior - authorization > '
      : ''
  );

  return (
    <div className="flex gap3 fd-c pt60 pb20 px40 ai-c ta-c">
      <Icon
        info={SUCCESS_SHIELD_ICON}
      />
      <TextComponent bold>
        New Prior-auth Started
      </TextComponent>
      <div>
        Please go to "

        {utilitiesPathText}

        Need Submission"
        table to keep track of patient's new Prior-auth progress,
        and submit patient's information on the insurance website ASAP.
      </div>
      <Button
        type="primary"
        ghost
        onClick={onOk}
        className="mt70 w140"
      >
        Got it
      </Button>
    </div>
  );
};
