import {
  MessageHistoryMessages,
} from '../../services/CHSServices/types/data';
import { Patient, PatientComplexityEnum } from '../../uc-api-sdk';

export enum MessageACKPublisher {
  READ = 'team',
  UNREAD = 'unread',
}

export type MessageNotification = 'Notification';

export interface MessageChannel {
  messages: MessageHistoryMessages[];
  patientId: string;
  isUnread: boolean;
  unreadMessages: number;
  hasMoreMessage: boolean;
  fromTimestamp?: string;
  isOngoing?: boolean;
  tagMessageOffset?: number;
  tagMessageOffsetFrom?: string;
  lastMsg?: {
    text: string;
    dateTime?: MessageHistoryMessages['dateTime'];
    publisherName?: string;
  };
  lastClientACK: number;
}

export interface MessageChannelMap {
  [patientId: string]: MessageChannel;
}

export interface MessageUserInfo {
  firstName: string;
  lastName: string;
  avatar?: string | null;
  patientComplexityLevel?: PatientComplexityEnum;
  rawValues?: Patient;
}

export interface MessageUserMap {
  [userId: string]: MessageUserInfo;
}

export interface MessageLastPaginatedChannelsTimestamp {
  NextFromTimestamp?: string;
  NextPageNumber?: string;
}
