import React from 'react';
import { Icon, IconProps, IconAllProps } from './Icon';

export interface IconOptions {
  defaultSize?: { height?: number, width?: number };
}

export const makeIcon = (
  info: IconAllProps['info'],
  options: IconOptions = {},
) => (props: IconProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon info={info} size={options.defaultSize} {...props} />
);
