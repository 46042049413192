import { useEffectOnce } from 'usehooks-ts';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import { CalendarSourceEnum, ClinicEvent, VisitMethodEnum } from '../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { AccountHelper } from '../../../../helpers/account';
import { VisitTypeEnumComponent } from '../../../../enumComponent/VisitTypeEnumComponent/VisitTypeEnumComponent';
import { VisitMethodEnumComponent } from '../../../../enumComponent/VisitMethodEnumComponent/VisitMethodEnumComponent';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import notificationSound from '../../../../assets/sound/calendar-event-notification.mp3';
import { playSound } from '../../../../helpers/sound';

interface CalendarNotificationComponentProps {
  clinicEvent?: ClinicEvent;
  onClick?: () => void;
  shouldPlaySound?: boolean;
  isZoomNotification?: boolean;
}

export const CalendarNotificationComponent = ({
  clinicEvent,
  onClick,
  shouldPlaySound = true,
  isZoomNotification,
}: CalendarNotificationComponentProps) => {
  const {
    calendarSource,
    visitMethod,
    visitType,
    eventTitle,
    summary,
    patientInfo,
    startTime,
    endTime,
  } = clinicEvent || {};
  const patientId = patientInfo?.id || '';
  const patientName = AccountHelper.getFullName(patientInfo);

  const renderPatientName = () => (
    <DisplayOrEmptyComponent value={patientName} />
  );

  const renderTimeRange = () => (
    <>
      <DisplayDateComponent value={startTime} format="SMALL_TIME" />
      {' - '}
      <DisplayDateComponent value={endTime} format="TIME_AM_PM" />
    </>
  );

  const renderContent = () => {
    switch (calendarSource) {
      case CalendarSourceEnum.UC_VISIT:
        if (visitMethod === VisitMethodEnum.VIRTUAL && isZoomNotification) {
          return (
            <div>
              <TextComponent bold>
                <DisplayOrEmptyComponent value={patientName} />
                {' '}
                is waiting in a video visit
              </TextComponent>
              <TextComponent color="gray2">
                <VisitTypeEnumComponent value={visitType} />
                {', '}
                {renderTimeRange()}
              </TextComponent>
            </div>
          );
        }
        return (
          <div>
            <TextComponent bold>
              <VisitTypeEnumComponent value={visitType} />
              {' '}
              with
              {' '}
              {renderPatientName()}
            </TextComponent>
            <TextComponent color="gray2">
              <VisitMethodEnumComponent value={visitMethod} />
              {', '}
              {renderTimeRange()}
            </TextComponent>
          </div>
        );
      case CalendarSourceEnum.OTHER:
        return (
          <div>
            <TextComponent bold>
              {summary}
            </TextComponent>
            <TextComponent color="gray2">
              {
                patientId
                  ? (
                    <>
                      {renderPatientName()}
                      {', '}
                    </>
                  )
                  : null
              }
              {renderTimeRange()}
            </TextComponent>
          </div>
        );
      case CalendarSourceEnum.GOOGLE_CALENDAR:
        return (
          <div>
            <TextComponent bold>
              {eventTitle}
            </TextComponent>
            <TextComponent color="gray2">
              {renderTimeRange()}
            </TextComponent>
          </div>
        );
      default:
        return <EmptyComponent />;
    }
  };

  const getButtonText = () => {
    if (isZoomNotification) {
      // when video visit is started by patient
      return 'Go Check In';
    }
    switch (calendarSource) {
      case CalendarSourceEnum.UC_VISIT:
        return 'Go To Patient Page';
      case CalendarSourceEnum.OTHER:
        return (
          patientId
            ? 'Go To Patient Page'
            : 'Open in Calendar'
        );
      case CalendarSourceEnum.GOOGLE_CALENDAR:
        return 'Open in Calendar';
      default:
        return null;
    }
  };

  useEffectOnce(() => {
    if (shouldPlaySound) playSound(notificationSound);
  });

  return (
    <>
      <div className="flex ai-bl mt5">
        {renderContent()}
      </div>
      <LinkButton
        useBlueColor
        className="f14 b6 mt5"
        onClick={onClick}
      >
        {getButtonText()}
      </LinkButton>
    </>
  );
};
