import dayjs, { Dayjs } from 'dayjs';
import { ReactNode } from 'react';
import { MessageUserInfo } from '../../../../types/message';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { CHSServices } from '../../../../services/CHSServices/CHSServices';
import { MessageAvatarComponent } from '../MessageAvatarComponent/MessageAvatarComponent';
import { MessageDisplaySenderComponent } from '../MessageDisplayComponent/MessageDisplaySenderComponent';
import './MessageItemComponent.scss';

export interface MessageItemComponentProps {
  dateTime: Dayjs;
  displayName: string | undefined;
  publisher?: MessageUserInfo;
  // firstUnReadMessage:boolean;
  userRole: string | undefined;
  // children is the message (vital, img, ...)
  children: ReactNode;
}
export const getDayjsFromDateNumber = (date: number) => (
  dayjs(date.toString())
);

export const MessageItemComponent = ({
  dateTime,
  displayName = 'N/A',
  publisher,
  userRole,
  children,
}: MessageItemComponentProps) => {
  const avatarUrl = publisher?.avatar;
  const isSystemMessage = CHSServices.isSystemMessage(userRole);

  return (
    <div className="message-item">
      <MessageAvatarComponent
        url={avatarUrl}
        isSystemMessage={isSystemMessage}
      />
      <div className="message-item__content">
        <div className="message-item__sender inline-flex">
          <MessageDisplaySenderComponent
            displayName={displayName}
          />
          <DisplayDateComponent value={dateTime} format="TIME" />
        </div>
        {children}
      </div>
    </div>
  );
};
