import React from 'react';
import { AppInfoComponent } from '../../component/AppInfoComponent/AppInfoComponent';
import { usePhoneReportInfoGetAppInfoByPatientId } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';

export interface AppInfoContainerProp {
  patientId: string;
}

export const AppInfoContainer = ({
  patientId,
}: AppInfoContainerProp) => {
  const info = usePhoneReportInfoGetAppInfoByPatientId({
    options: {
      sendOnMount: true,
    },
    params: {
      memberId: patientId,
    },
  });
  return (
    <FetchComponent info={info}>
      {(value, info) => {
        const { data } = info.data || {};
        return (
          <AppInfoComponent info={data ?? undefined} patientId={patientId} />
        );
      }}
    </FetchComponent>
  );
};
