import {
  Drawer
} from 'antd';
import './WorklistDrawerComponent.scss';
import { NestedFormControlProvider } from '../../../../contexts/NestedFormControlContext/NestedFormControlContext';
import { WorklistContainer } from '../../container/WorklistContainer/WorklistContainer';
import { ErrorBoundaryComponent } from '../../../../uiComponent/ErrorBoundaryComponent/ErrorBoundaryComponent';
import { ErrorBoundaryIdentifier } from '../../../../uiComponent/ErrorBoundaryComponent/errorBoundaryIdentifier';
import { BlockUnmountContextProvider, useBlockUnmountContext } from '../../../../contexts/BlockUnmountContext/BlockUnmountContext';

export enum WorklistStepEnum {
  VITALS_DATA_REVIEW = 'VITALS_DATA_REVIEW',
  HEALTH_RECORDS_UPDATES = 'HEALTH_RECORDS_UPDATES',
  DISEASE_HISTORY_REVIEW = 'DISEASE_HISTORY_REVIEW',
  LIFESTYLE_ASSESSMENT = 'LIFESTYLE_ASSESSMENT',
  GOALS_EDUCATION_SETUP = 'GOALS_EDUCATION_SETUP',
  FOLLOW_UP_SCHEDULE = 'FOLLOW_UP_SCHEDULE',
}

export interface WorklistDrawerComponentProps {
  patientId: string;
  clinicEventId: string;
  isOpen: boolean;
  onClose?: () => void;
}

const WorklistDrawer = ({
  patientId,
  clinicEventId,
  isOpen,
  onClose,
}: WorklistDrawerComponentProps) => {
  const { blockPrompt } = useBlockUnmountContext();

  const handleOnClose = async () => {
    blockPrompt({
      next: () => {
        onClose?.();
      }
    });
  };

  return (
    <Drawer
      open={isOpen}
      onClose={() => 0}
      className="worklist-drawer-component"
      closable={false}
      push={false}
      destroyOnClose
    >
      <ErrorBoundaryComponent identifier={ErrorBoundaryIdentifier.WorklistContainer}>
        <NestedFormControlProvider>
          <WorklistContainer
            patientId={patientId}
            clinicEventId={clinicEventId}
            onClose={handleOnClose}
          />
        </NestedFormControlProvider>
      </ErrorBoundaryComponent>
    </Drawer>
  );
};

export const WorklistDrawerComponent = (props: WorklistDrawerComponentProps) => (
  <BlockUnmountContextProvider>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <WorklistDrawer {...props} />
  </BlockUnmountContextProvider>
);
