import React, { useState } from 'react';
import {
  Col,
  Row,
  Checkbox,
} from 'antd';
import { CheckboxGroupProps, CheckboxValueType } from 'antd/lib/checkbox/Group';
import '../NutritionComponent/NutritionComponent.scss';
import { map } from 'lodash';
import { DietEnum } from '../../../../../uc-api-sdk';
import { DietEnumComponent } from '../../../../../enumComponent/DietEnumComponent/DietEnumComponent';

export interface CalorieManagementComponentProps extends CheckboxGroupProps {
  defaultCheckedList?: CheckboxValueType[];
  onChange?: CheckboxGroupProps['onChange'];
}

export const CalorieManagementComponent = ({
  defaultCheckedList = [],
  onChange,
}: CalorieManagementComponentProps) => {
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList);
  const onGroupChange = (list: CheckboxValueType[]) => {
    if (list.length > 0 && list[list.length - 1] === DietEnum.NONE) {
      setCheckedList([DietEnum.NONE]);
      onChange?.([DietEnum.NONE]);
    } else {
      setCheckedList(list.filter(value => value !== DietEnum.NONE));
      onChange?.(list.filter(value => value !== DietEnum.NONE));
    }
  };
  return (
    <Checkbox.Group
      value={checkedList}
      onChange={onGroupChange}
    >
      <Row>
        {map(DietEnum).map(value => (
          <Col span={6} key={`${value}`}>
            <Checkbox value={value}>
              <DietEnumComponent value={value} />
            </Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  );
};
