import { Checkbox, CheckboxProps } from 'antd';
import { InputType } from '../../../Input/types';
import { useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';

export interface PriorAuthCheckboxComponentProps extends InputType<boolean> {
  customOnChange?: CheckboxProps['onChange'];
}

export const PriorAuthCheckboxComponent = ({
  value,
  onChange,
  customOnChange,
  disabled,
}: PriorAuthCheckboxComponentProps) => {
  const insuranceForm = useInsuranceForm();

  const handleOnChange: CheckboxProps['onChange'] = (e) => {
    if (customOnChange) {
      customOnChange?.(e);
      return;
    }
    onChange?.(e.target.checked);
  };

  return (
    <Checkbox
      checked={value}
      disabled={disabled}
      onChange={handleOnChange}
    >
      {insuranceForm.getLabel('priorAuth')}
    </Checkbox>
  );
};
