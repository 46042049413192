import { List } from 'antd';
import { DiseaseHistoryListItemComponent } from '../DiseaseHistoryListItemComponent/DiseaseHistoryListItemComponent';
import { DiseaseHistoryItem, Note } from '../../../../uc-api-sdk';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { HistoryDrawerComponent } from '../../../../uiComponent/HistoryComponent/HistoryDrawerComponent';

export interface DiseaseHistoryListComponentProps {
  diseaseHistoryList?: DiseaseHistoryItem[];
  notes?: Note[];
  isLoading?: boolean;
}

export const DiseaseHistoryListComponent = ({
  diseaseHistoryList,
  notes,
  isLoading,
}: DiseaseHistoryListComponentProps) => (
  <LoadingOverlayComponent
    isLoading={isLoading}
    showSkeleton
  >
    {
      diseaseHistoryList?.length
        ? (
          <List
            className="disease-history-list"
            dataSource={diseaseHistoryList}
            bordered={false}
            loading={isLoading}
            renderItem={(dh, idx) => (
              <DiseaseHistoryListItemComponent
                key={idx}
                diseaseHistory={dh}
              />
            )}
            itemLayout="vertical"
          />
        ) : (
          <span>No disease history found</span>
        )
    }
    <HistoryDrawerComponent history={notes} />
  </LoadingOverlayComponent>
);
