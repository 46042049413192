import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useAssignDeviceForm } from '../../../patient/hook/useAssignDeviceForm';
import { DeviceDeliveryMethodRadioComponent, DeviceDeliveryMethodRadioComponentProps } from '../DeviceDeliveryMethodRadioComponent/DeviceDeliveryMethodRadioComponent';

export interface EnrollmentDeliveryMethodFormItemComponentProps {
  onChange?: DeviceDeliveryMethodRadioComponentProps['onChange'];
  disablePickup?: DeviceDeliveryMethodRadioComponentProps['disablePickup'];
}

export const EnrollmentDeliveryMethodFormItemComponent = ({
  onChange,
  disablePickup,
}: EnrollmentDeliveryMethodFormItemComponentProps) => {
  const hook = useAssignDeviceForm();
  return (
    <FormItem
      shouldUpdate={hook.shouldUpdate(['techLevel'])}
      className="mb50"
    >
      {({ getFieldValue }) => (
        hook.shouldShowDeliveryMethod(getFieldValue) ? (
          <FormItem
            info={hook.getInfo('deliveryMethod')}
          >
            <DeviceDeliveryMethodRadioComponent
              onChange={onChange}
              disablePickup={disablePickup}
              direction="vertical"
            />
          </FormItem>
        ) : null
      )}
    </FormItem>
  );
};
