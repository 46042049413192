import { useCallback } from 'react';
import { InputType } from '../../../Input/types';
import { DeviceModelEnum, LoanDevice } from '../../../../uc-api-sdk';
import { DeviceCheckboxWithAssignModalComponent, DeviceCheckboxWithAssignModalComponentProps } from '../DeviceCheckboxWithAssignModalComponent/DeviceCheckboxWithAssignModalComponent';

import { LoanerPhoneAssignFormComponent, LoanerPhoneAssignFormComponentProps } from '../LoanerPhoneAssignFormComponent/LoanerPhoneAssignFormComponent';
import { DeviceModelEnumComponent } from '../../../../enumComponent/DeviceModelEnumComponent/DeviceModelEnumComponent';

export interface LoanerPhoneDeviceCheckboxComponentProps extends
  InputType<LoanDevice> { }

export const LoanerPhoneDeviceCheckboxComponent = ({
  value,
  onChange,
  disabled,
}: LoanerPhoneDeviceCheckboxComponentProps) => {
  const loanDeviceValue = value ? { deviceId: value.deviceId } : undefined;
  const handleOnDeviceSubmit: LoanerPhoneAssignFormComponentProps['onSubmit'] = (value) => {
    if (!value || !value.deviceId) {
      onChange?.(undefined);
      return;
    }
    onChange?.(value);
  };

  const renderModalContent: DeviceCheckboxWithAssignModalComponentProps['modalContent'] = useCallback((close) => (
    <LoanerPhoneAssignFormComponent
      initialValues={loanDeviceValue}
      onSubmit={(v) => {
        handleOnDeviceSubmit(v);
        close();
      }}
    />
  ), [handleOnDeviceSubmit]);

  return (
    <DeviceCheckboxWithAssignModalComponent
      key={DeviceModelEnum.LOAN_DEVICE}
      model={DeviceModelEnum.LOAN_DEVICE}
      name={<DeviceModelEnumComponent value={DeviceModelEnum.LOAN_DEVICE} />}
      type={null}
      title="Unified Care Loaner Phone"
      value={loanDeviceValue}
      onChange={onChange}
      modalContent={renderModalContent}
      disabled={disabled}
    />
  );
};
