import React from 'react';
import { delay } from 'lodash';
import { VisitViewComponentProps } from '../../component/VisitViewComponent/VisitViewComponent';
import { ClinicEvent, useClinicEventDelete } from '../../../../uc-api-sdk';
import { CalendarEvent } from '../../component/BigCalendarComponent/BigCalendarComponent';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { CalendarGoogleFullEventComponent } from '../../component/CalendarGoogleFullEventComponent/CalendarGoogleFullEventComponent';

export interface EventViewContainerProps extends
  Pick<VisitViewComponentProps, 'onClose' | 'onEdit' | 'onDelete'> {
  visit: CalendarEvent<ClinicEvent>;
}

export const EventViewContainer = ({
  visit,
  onClose,
  onEdit,
  onDelete,
}: EventViewContainerProps) => {
  const deleteReq = useClinicEventDelete({});
  const updateHook = useUpdate('VISIT_DELETED');

  const handleDelete = async () => {
    try {
      if (!visit.info?.id) {
        throw new Error('Visit id is missing!');
      }
      const res = await deleteReq.send({ params: { id: visit.info.id } });
      ApiRequestHelper.throwIfError(res, 'Failed to delete the visit!');
      // update outstanding item count
      updateHook.updateValue();
      delay(() => onDelete?.(), 500);
    } catch (err) {
      ApiRequestHelper.showError(err);
    }
  };

  return (
    <CalendarGoogleFullEventComponent
      onDelete={handleDelete}
      onEdit={onEdit}
      isSubmitting={deleteReq.isLoading}
      showPatient
      onClose={() => onClose?.()}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...visit}
    />
  );
};
