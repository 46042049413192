import {
  JsonTimeLocalDateClass,
  useTranscribeGetA1CDueDate,
} from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { AddLabResultDrawerComponent } from '../../component/LabResultTableComponent/AddLabResultDrawerComponent';

export interface AddLabResultDrawerContainerProps {
  patientId?: string;
  disabled?: boolean;
}

export const AddLabResultDrawerContainer = ({
  patientId,
  disabled,
}: AddLabResultDrawerContainerProps) => {
  const element = () => {
    if (patientId) {
      const info = useTranscribeGetA1CDueDate({
        params: {
          patientId,
        },
      });
      return (
        <FetchComponent
          info={info}
        >
          {(value) => {
            const {
              data = JsonTimeLocalDateClass,
            } = (value?.rawValue || {});
            return (
              <AddLabResultDrawerComponent
                patientId={patientId}
                data={data}
                placement="left"
                addButtonToRight={false}
                disabled={disabled}
              />
            );
          }}
        </FetchComponent>
      );
    }
    return (<div> </div>);
  };
  return (
    <>
      {element()}
    </>
  );
};
