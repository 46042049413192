import React, { FC } from 'react';
import { useBGResultToLineChart } from '../../hook/useBGResultToLineChart/useBGResultToLineChart';
import { MeasurementType } from '../../type/MeasurementTypes';
import { BGChartsComponent } from './BGChartsComponent';
import { FromDateToDateDayjs } from '../../type';

export interface BGChartDataProcessorComponentProps extends FromDateToDateDayjs {
  data: MeasurementType[];
}

export const BGChartDataProcessorComponent: FC<BGChartDataProcessorComponentProps> = ({
  data,
  fromDate,
  toDate,
}) => {
  const processedData = useBGResultToLineChart(data);

  return (
    <BGChartsComponent
      data={processedData}
      fromDate={fromDate}
      toDate={toDate}
    />
  );
};
