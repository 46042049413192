import { useCallback } from 'react';
import { ClearDeviceAssignFormComponentProps } from '../ClearDeviceAssignFormComponent/ClearDeviceAssignFormComponent';
import { DeviceCheckboxComponentProps } from '../DeviceCheckboxComponent/DeviceCheckboxComponent';
import { DeviceCheckboxWithAssignModalComponent, DeviceCheckboxWithAssignModalComponentProps } from '../DeviceCheckboxWithAssignModalComponent/DeviceCheckboxWithAssignModalComponent';
import { CellularDeviceAssignFormComponent } from '../CellularDeviceAssignFormComponent/CellularDeviceAssignFormComponent';

export interface CellularDeviceCheckboxComponentProps
  extends DeviceCheckboxComponentProps { }

export const CellularDeviceCheckboxComponent = ({
  value,
  onChange,
  ...restProps
}: CellularDeviceCheckboxComponentProps) => {
  const handleOnDeviceSubmit: ClearDeviceAssignFormComponentProps['onSubmit'] = (values) => {
    onChange?.({ ...value, ...values });
  };

  const renderModalContent: DeviceCheckboxWithAssignModalComponentProps['modalContent'] = useCallback((close) => (
    <CellularDeviceAssignFormComponent
      deviceModel={restProps.model}
      onSubmit={(v) => {
        handleOnDeviceSubmit(v);
        close();
      }}
    />
  ), [handleOnDeviceSubmit]);

  return (
    <DeviceCheckboxWithAssignModalComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
      value={value}
      onChange={onChange}
      modalContent={renderModalContent}
    />
  );
};
