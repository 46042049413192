import { map } from 'lodash';
import { SorterResult } from 'antd/lib/table/interface';
import { Direction } from '../../types/restful';
import { TableData } from '../../types/table';

// eslint-disable-next-line import/prefer-default-export
export const getOrder = ({ order }: SorterResult<unknown>) => (
  order === 'descend' ? Direction.DESC : Direction.ASC
);

export const getAndCancelOrder = ({ order }: SorterResult<object>) => {
  switch (order) {
    case 'ascend':
      return Direction.ASC;
    case 'descend':
      return Direction.DESC;
    default:
      return undefined;
  }
};

export class TableHelper {
  public static getDataWithDefaultIndexKey<T>(data: T[]) {
    return map(data, (d, i) => ({ tableKey: i.toString(), ...d } as TableData & T));
  }
}
