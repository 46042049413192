import React from 'react';
import { Icons } from '../../../../icons/Icons';
import { VisitMethodEnum } from '../../../../uc-api-sdk';

export interface VisitIconComponentProps {
  value: VisitMethodEnum;
}

export const VisitIconComponent = ({
  value,
}: VisitIconComponentProps) => {
  switch (value) {
    case VisitMethodEnum.IN_CLINIC: return <Icons.InClinicVisitIcon />;
    case VisitMethodEnum.VIRTUAL: return <Icons.VideoVisitIcon />;
    case VisitMethodEnum.PHONE: return <Icons.PhoneVisitIcon />;
    default: return null;
  }
};
