import classNames from 'classnames';
import { ReactNode } from 'react';

import './ClickableDiv.scss';

export interface ClickableDivProps {
  onClick: React.DOMAttributes<HTMLDivElement>['onClick'];
  children?: ReactNode;
  className?: string;
  testId?: string;
  showPointer?: boolean;
  disabled?: boolean;
}

export const ClickableDiv = ({
  onClick,
  children,
  className = '',
  testId = 'clickableDiv',
  disabled,
  showPointer = !disabled,
}: ClickableDivProps) => (
  <div
    className={classNames({
      'clickable-div': true,
      disabled,
      pointer: showPointer,
      [className]: !!className,
    })}
    data-testid={testId}
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      if (disabled) return;
      onClick?.(e);
    }}
    role="button"
    aria-hidden
  >
    {children}
  </div>
);
