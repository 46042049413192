import { RcFile } from 'antd/lib/upload';
import {
  createContext, ReactNode, useContext, useMemo, useState
} from 'react';

export interface MessageStorageContextProviderProps {
  children: ReactNode;
}

export interface DraftValue {
  message?: string;
  file?: RcFile;
}

export interface MessageStorageContextValue {
  getDraftById: (patientId: string) => DraftValue;
  setDraftMessage: (patientId: string, message: string) => void;
  setDraftFile: (patientId: string, file?: RcFile) => void;
  reset: () => void;
}

export const MessageStorageContext = createContext<MessageStorageContextValue | undefined>(
  undefined
);

export const useMessageStorageContext = () => {
  const context = useContext(MessageStorageContext);
  return (context || {}) as MessageStorageContextValue;
};

export const MessageStorageContextProvider = ({
  children
}: MessageStorageContextProviderProps) => {
  const [messageMap, setMessageMap] = useState<Record<string, string>>({});
  const [fileMap, setFileMap] = useState<Record<string, RcFile | undefined>>({});

  const getDraftById = (patientId: string) => ({
    message: messageMap[patientId],
    file: fileMap[patientId]
  });

  const setDraftMessage = (patientId: string, message: string) => {
    setMessageMap({
      ...messageMap,
      [patientId]: message
    });
  };

  const setDraftFile = (patientId: string, file?: RcFile) => {
    setFileMap({
      ...fileMap,
      [patientId]: file
    });
  };

  const reset = () => {
    setMessageMap({});
    setFileMap({});
  };

  const value = useMemo(() => ({
    getDraftById,
    setDraftMessage,
    setDraftFile,
    reset
  }), [getDraftById, setDraftMessage]);

  return (
    <MessageStorageContext.Provider
      value={value}
    >
      {children}
    </MessageStorageContext.Provider>
  );
};
