import {
  Button, Col, Row, Space
} from 'antd';
import { FC } from 'react';
import { EnrolledProgramStatusEnumComponent } from '../../../../enumComponent/EnrolledProgramStatusEnumComponent/EnrolledProgramStatusEnumComponent';
import { Icons } from '../../../../icons/Icons';
import { ClinicStatus, MedicalOrganizationStatus } from '../../../../uc-api-sdk';
import { ClinicNameDisplayComponent } from '../../../../uiComponent/DisplayComponent/ClinicDisplayComponent/ClinicNameDisplayComponent';
import { LabelWithValueComponent } from '../../../../uiComponent/LabelWithValueComonent/LabelWithValueComponent';
import { Title } from '../../../../uiComponent/Title/Title';
import { WatchedIndicatorContainer } from '../../container/WatchedIndicatorContainer/WatchedIndicatorContainer';
import { ActionsComponent } from '../ActionsComponent/ActionsComponent';
import { PatientDobGenderComponent } from '../PatientDobGenderComponent/PatientDobGenderComponent';
import { PatientInfoCardComponentProps } from '../PatientInfoCardComponent/PatientInfoCardComponent';
import { PatientLanguageComponent } from '../PatientLanguageComponent/PatientLanguageComponent';
import { PhoneCallDropdownComponent } from '../PhoneCallDropdownComponent/PhoneCallDropdownComponent';
import { PotentialClinicTooltipComponent } from '../PotentialClinicTooltipComponent/PotentialClinicTooltipComponent';
import './UnenrolledPatientInfoCardComponent.scss';

export const UnenrolledPatientInfoCardComponent: FC<PatientInfoCardComponentProps> = ({
  patientInfo,
  onClickStartEnrollment,
  onClickCreateTask,
  onClickCreateVisit,
}) => {
  const enrolledProgramStatus = patientInfo?.enrolledProgramService.getStatus();
  const { patientAssociatedClinics } = patientInfo?.patientInfoService || {};
  const { clinic } = patientInfo?.patientInfo || {};

  return (
    <div className="unenrolled-patient-info-card-component">
      <div className="flex ai-c jc-sb">
        <Space className="flex ai-c">
          <Title noMargin>
            {patientInfo?.patientInfoService?.getFullNameWithNickname()}
          </Title>
          <WatchedIndicatorContainer />
        </Space>
        <Space className="flex ai-c jc-e">
          <PhoneCallDropdownComponent
            patientInfo={patientInfo?.patientInfo || {}}
            enrolledProgramInfo={patientInfo?.enrollmentInfo || {}}
            isEnrolled={false}
          />
          <ActionsComponent
            onClickCreateTask={onClickCreateTask}
            onClickCreateVisit={onClickCreateVisit}
          />
        </Space>
      </div>
      <Row gutter={[30, 6]} className="row-pt15 patient-info-properties">
        <Col className="ai-c jc-s">
          <LabelWithValueComponent
            value={(
              <PatientDobGenderComponent
                dob={patientInfo?.patientInfoService?.getBirthday()}
                gender={patientInfo?.patientInfoService?.getGender()}
              />
            )}
          />
        </Col>
        <Col className="ai-c">
          <LabelWithValueComponent
            label="Status: "
            value={<EnrolledProgramStatusEnumComponent value={enrolledProgramStatus} />}
          />
        </Col>
        <Col className="ai-c">
          <LabelWithValueComponent
            label="Clinic: "
            value={(
              <div className="flex">
                <ClinicNameDisplayComponent
                  clinic={clinic ?? undefined}
                  showStatus={
                    [
                      ClinicStatus.TERMINATED,
                      ClinicStatus.TERMINATION_INITIATED,
                      MedicalOrganizationStatus.TERMINATED,
                      MedicalOrganizationStatus.TERMINATION_INITIATED
                    ].includes(
                      clinic?.medicalOrgStatus as MedicalOrganizationStatus
                      || clinic?.clinicStatus as ClinicStatus
                    )
                  }
                />
                <PotentialClinicTooltipComponent clinics={patientAssociatedClinics} />
              </div>
            )}
          />
        </Col>
        <Col className="ai-c">
          <LabelWithValueComponent
            label="Language: "
            value={(
              <PatientLanguageComponent
                spokenLanguage={patientInfo?.patientInfoService?.getSpokenLanguage()}
                appLanguage={patientInfo?.patientInfoService?.getAppLanguage()}
              />
            )}
          />
        </Col>
      </Row>
      <div className="flex ai-c jc-s row-pt15 gap25">
        <Button
          type="primary"
          className="flex b5"
          icon={<Icons.StartEnrollment size={18} />}
          onClick={onClickStartEnrollment}
        >
          Start Enrollment
        </Button>
      </div>
    </div>
  );
};
