import { Input, InputProps } from 'antd';
import { FC } from 'react';

export interface AddressCityInputComponentProps {
  value?: string;
  onChange?: InputProps['onChange'];
}

export const AddressCityInputComponent: FC<AddressCityInputComponentProps> = ({
  value,
  onChange,
}) => (
  <Input
    value={value}
    onChange={onChange}
  />
);
