import { Patient } from '../../../../uc-api-sdk';
import OverlayLoading from '../../../../uiComponent/OverlayLoading/OverlayLoading';
import { UpdatedByAtComponent } from '../../../../user/component/UpdatedByAtComponent/UpdatedByAtComponent';
import { InsuranceFormComponent, InsuranceFormComponentProps } from '../../../Insurance/component/InsuranceFormComponent/InsuranceFormComponent';
import { Insurance } from '../../../Insurance/hook/insuranceApis';
import { PatientCommonCardTitleComponent } from '../PatientCommonCardTitleComponent/PatientCommonCardTitleComponent';

import { InsuranceEligibilityInfoContainer } from '../../../Insurance/container/InsuranceEligibilityInfoContainer/InsuranceEligibilityInfoContainer';
import '../PatientCommonCardTitleComponent/PatientCommonCardTitleComponent.scss';

export interface PatientInsuranceComponentProps {
  patient: Patient;
  insurance: Insurance;
  isLoading: boolean;
  onSubmit: InsuranceFormComponentProps['onSave'];
  onSaveNote?: (note: string) => void;
}

export const PatientInsuranceComponent = ({
  patient,
  insurance,
  isLoading,
  onSubmit,
  onSaveNote
}: PatientInsuranceComponentProps) => (
  <div className="patient-insurance">
    <div className="mb5">
      <PatientCommonCardTitleComponent className="mb20">
        Insurance & Eligibility
      </PatientCommonCardTitleComponent>
      <div className="flex ai-c jc-sb">
        <div>
          <InsuranceEligibilityInfoContainer
            patientId={patient.id || ''}
          />
        </div>
        <div className="patient-common-card-last-time-update">
          <UpdatedByAtComponent
            info={insurance}
          />
        </div>
      </div>
    </div>
    <div>
      <OverlayLoading loading={isLoading} />
      <InsuranceFormComponent
        patient={patient}
        insurance={insurance}
        onSave={onSubmit}
        onSaveNote={onSaveNote}
        disabled={isLoading}
      />
    </div>
  </div>
);
