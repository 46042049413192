import React, { FC } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Space } from 'antd';

export interface MeasurementDelayedInfoComponentProps {

}

export const MeasurementDelayedInfoComponent: FC<MeasurementDelayedInfoComponentProps> = () => (
  <Space>
    <InfoCircleOutlined />
    Measurement uploaded with delay
  </Space>
);
