import React from 'react';
import { WarningComponent } from '../../../../uiComponent/WarningComponent/WarningComponent';

export interface VisitOverlapTimeComponentProps {
  hasOverlap: boolean;
}

export const VisitOverlapTimeComponent = ({
  hasOverlap,
}: VisitOverlapTimeComponentProps) => {
  if (hasOverlap) {
    return (
      <WarningComponent
        className="mb5"
        textColor="alert"
        showIcon={false}
        text="This visit overlaps with another visit the participant(s) have."
      />
    );
  }
  return null;
};
