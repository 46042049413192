import { Button } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import React, {
  ReactElement, ReactNode, useEffect,
} from 'react';
import './TableFilterComponent.scss';

export interface TableFilterComponentProps extends FilterDropdownProps {
  children: ReactNode | ((props: Pick<FilterDropdownProps, 'selectedKeys' | 'setSelectedKeys'>) => React.ReactNode);
  careUnitId?: string;
}

export const TableFilterComponent = ({
  careUnitId,
  children,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  close,
}: TableFilterComponentProps) => {
  const childrenWithProps: ReactNode = React.Children.map(children as ReactNode, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as ReactElement, { setSelectedKeys, selectedKeys });
    }
    return child;
  });

  useEffect(() => {
    if (careUnitId) {
      clearFilters?.();
      confirm({ closeDropdown: false });
    }
  }, [careUnitId]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className="table-filter-component-filter-component"
      onKeyDown={(e) => e.stopPropagation()}
    >
      <div className="table-filter-component-filter-field">
        {childrenWithProps}
      </div>
      <div className="buttons-wrapper">
        <Button
          className="clear-button"
          type="link"
          disabled={selectedKeys.length === 0}
          onClick={() => {
            clearFilters?.();
            confirm();
            close();
          }}
        >
          Reset
        </Button>
        <Button
          type="primary"
          onClick={() => {
            confirm();
            close();
          }}
        >
          Ok
        </Button>
      </div>
    </div>
  );
};
