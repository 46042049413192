import dayjs, { Dayjs } from 'dayjs';
import moment, { Moment } from 'moment';
import { FC } from 'react';

export interface MedicationHintComponentProps {
  date: string | Moment | Dayjs | null;
  className?: string;
}
export const MedicationHintComponent: FC<MedicationHintComponentProps> = ({
  date,
  className,
}) => {
  const today = moment.isMoment(date) ? moment() : dayjs();
  const cname = `modalFooterMessage ${className}`;
  if (date && date < today) {
    return (
      <p className={cname}>
        {'Based on the date of your selection, the medication has been discontinued. '
          + 'Upon saving, this medication will be moved to “Discontinued Medications”.'}
      </p>
    );
  }
  if (date && date >= today) {
    return (
      <p className={cname}>
        {'The medication will be discontinued and moved to "'
          + 'Discontinued Medications” automatically on the date of your choice.'}
      </p>
    );
  }
  return null;
};
