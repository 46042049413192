import { FormInstance } from 'antd';

type FormInstanceType = Omit<FormInstance, 'scrollToField' | 'getFieldInstance'>;

export class AreYouOnSiteHelpers {
  static clinicFieldName = 'clinic';

  static shouldDisableConfirm(
    form: FormInstanceType,
  ) {
    const clinic = form.getFieldValue(this.clinicFieldName);
    return !clinic;
  }
}
