import { DeviceDeliveryMethodEnum } from '../../../../uc-api-sdk';

export interface DeviceDeliveryMethodComponentProps {
  value: DeviceDeliveryMethodEnum;
}

export const DeviceDeliveryMethodComponent = ({
  value,
}: DeviceDeliveryMethodComponentProps) => {
  const getText = () => {
    switch (value) {
      case DeviceDeliveryMethodEnum.PICK_UP: return 'Pick up from clinic';
      case DeviceDeliveryMethodEnum.SHIP: return 'Ship to patient';
      default: return 'Unknown delivery method';
    }
  };

  return (
    <>{getText()}</>
  );
};
