import { map } from 'lodash';
import { Button, Popover, Tag } from 'antd';
import { useBoolean } from 'usehooks-ts';
import { useOnCallContext } from '../../context/OnCallContext/OnCallContext';
import { OnCallFor, RoleTypeEnum } from '../../../../uc-api-sdk';
import { EditSelectCoverRangeDrawerComponent } from '../EditSelectCoverRangeDrawerComponent/EditSelectCoverRangeDrawerComponent';
import { RoleTypeComponent } from '../../../../uiComponent/RoleTypeComponent/RoleTypeComponent';
import { PriorityCoverEnumComponent } from '../../../../enumComponent/PriorityCoverEnumComponent/PriorityCoverEnumComponent';
import './OnCallCoverageModeTag.scss';

export interface OnCallCoverageModeTagComponentProps {

}

export const OnCallCoverageModeTagComponent = () => {
  const { value: isOpen, setTrue, setFalse } = useBoolean();
  const { value: isTagOpen, setFalse: closeTag, setValue: setTagValue } = useBoolean();
  const {
    onCallFor,
    employeesNameWithRole,
  } = useOnCallContext({ type: 'patientCare' });

  const renderOnCallUsers = (emp: OnCallFor) => {
    if (emp.allSelected) {
      return (
        <>
          All
          {' '}
          <RoleTypeComponent value={emp.role ?? undefined} />
          s
        </>
      );
    }
    return (
      employeesNameWithRole([emp.role as RoleTypeEnum]).join(', ')
    );
  };

  const renderEmployeeAndPriority = () => (
    map(onCallFor, (v, i) => (
      <>
        {i > 0 && ' and '}
        {renderOnCallUsers(v)}
        {' '}
        to take care of
        {' '}
        <PriorityCoverEnumComponent value={v.priority ?? undefined} />
      </>
    ))
  );

  const handleEdit = () => {
    closeTag();
    setTrue();
  };

  const renderOverlay = () => (
    <div>
      <div>
        You're currently covering
        {' '}
        {renderEmployeeAndPriority()}
      </div>
      <Button type="link" className="p0" onClick={handleEdit}>Back to List View to edit</Button>
      <EditSelectCoverRangeDrawerComponent open={isOpen} onClose={setFalse} />
    </div>
  );

  return (
    <Popover
      content={renderOverlay()}
      overlayInnerStyle={{ width: 300 }}
      trigger="click"
      open={isTagOpen}
      onOpenChange={setTagValue}
    >
      <Tag color="processing" className="on-call-coverage-mode-tag">
        On-call Coverage Mode
      </Tag>
    </Popover>
  );
};
