import { map } from 'lodash';
import { ReactNode, useCallback } from 'react';
import { Popover } from 'antd';
import { ClickableDiv } from '../../../../uiComponent/ClickableDiv/ClickableDiv';
import { PatientProfileTabEnum, usePatientPageControllerContext } from '../../context/PatientPageControllerContext/PatientPageControllerContext';

import './PatientProfileTabLabelComponent.scss';

export type TabAnchor = {
  [key: string]: {
    label: string;
    href?: string;
  }
}

export interface PatientProfileTabLabelComponentProps {
  tabEnum: PatientProfileTabEnum;
  tabAnchors: TabAnchor;
  children: ReactNode;
}

export const PatientProfileTabLabelComponent = ({
  tabEnum,
  tabAnchors,
  children,
}: PatientProfileTabLabelComponentProps) => {
  const { onTabChange } = usePatientPageControllerContext();

  const handleOnClick = useCallback((href: string) => {
    const anchorLink = href.split('#')[1]?.toLocaleLowerCase();
    onTabChange(tabEnum, anchorLink);
  }, [tabEnum]);

  const renderContent = useCallback(() => (
    <div className="patient-profile-tab-label">
      {
        map(tabAnchors, (value, key) => (
          value.href
            ? (
              <ClickableDiv
                key={key}
                disabled={!value.href} // when something is wrong
                onClick={() => handleOnClick(value.href as string)}
                className="patient-profile-tab-label__item"
              >
                {value.label}
              </ClickableDiv>
            ) : null
        ))
      }
    </div>
  ), [tabAnchors]);

  return (
    <Popover
      placement="bottomLeft"
      trigger="hover"
      content={renderContent()}
      showArrow={false}
      overlayClassName="patient-profile-tab-label__popover"
      align={{
        offset: [0, -10]
      }}
      destroyTooltipOnHide
    >
      <div className="h100">{children}</div>
    </Popover>
  );
};
