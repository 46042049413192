import { useFormHookFactory } from '../useFormHookFactory/useFormHookFactory';

export const usePauseServiceForm = () => {
  const factory = useFormHookFactory({
    vitalMonitoring: {
      name: 'vitalMonitoring',
      label: 'Vitals Monitoring',
      tooltip: {
        tip: 'Pausing 7-day inactive alerts during a care team-defined period means the care team won\'t contact patients due to a lack of measurements.',
        type: 'info-icon',
      },
    },
    vitalMonitoringTimeSpan: {
      name: 'vitalMonitoringTimeSpan',
      label: 'Time span',
    },
    vitalMonitoringReason: {
      name: 'vitalMonitoringReason',
      label: 'Reason',
    },
    visitService: {
      name: 'visitService',
      label: 'Visit Service',
      tooltip: {
        tip: 'Pausing visit due alerts during a care team-defined period prevents the care team from receiving compliant alerts to schedule regular visits with patients.',
        type: 'info-icon',
      }
    },
    visitServiceTimeSpan: {
      name: 'visitServiceTimeSpan',
      label: 'Time span',
    },
    visitServiceReason: {
      name: 'visitServiceReason',
      label: 'Reason',
    },
    billing: {
      name: 'billing',
      label: 'Billing',
      tooltip: {
        tip:
  <>
    <div>
      Pausing Billing means that during this period, no measurements
      or time will count toward billing for the current cycle. Time
      and measurement outside the pause will still be eligible
      for billing within the same cycle.
    </div>
    <div>
      If the patient prefers not to receive a bill,
      mark them as "self pay" in the patient profile
      {' > '}
      insurance widget or discharge them as self-mode patients.
    </div>
  </>,
        type: 'info-icon',
      }
    },
    billingTimeSpan: {
      name: 'billingTimeSpan',
      label: 'Time span',
    },
    billingReason: {
      name: 'billingReason',
      label: 'Reason',
    },
    note: {
      name: 'note',
      label: 'Notes',
    },
  });
  return factory;
};
