import { isBoolean } from 'lodash';
import { DiseaseHistoryItem } from '../../../../../../uc-api-sdk';
import { YesNoDisplayComponent } from '../../../../../../uiComponent/YesNoDisplayComponent/YesNoDisplayComponent';
import { DiseaseHasTestedComponent } from '../../../../../../enumComponent/DiseaseTestedBeforeLabel/DiseaseHasTestedComponent';

export interface PrintDiseaseTestedBeforeComponentProps {
  diseaseHistory: DiseaseHistoryItem;
}

// @return
// Patient has tested BP/BG/none in the past
// Patient has not tested BP/BG/none in the past
export const PrintDiseaseTestedBeforeComponent = ({
  diseaseHistory,
}: PrintDiseaseTestedBeforeComponentProps) => {
  const {
    disease,
    hasTestedBefore,
  } = diseaseHistory;

  const renderHasTested = () => (
    <DiseaseHasTestedComponent
      disease={disease}
      capitalize={false}
    />
  );

  if (!isBoolean(hasTestedBefore)) {
    return null;
  }

  return (
    <YesNoDisplayComponent
      value={hasTestedBefore}
      yesDisplay={(
        <div className="flex">
          Patient has
          {' '}
          {renderHasTested()}
          {' '}
          in the past
        </div>
      )}
      noDisplay={(
        <div className="flex">
          Patient has not
          {' '}
          {renderHasTested()}
          {' '}
          in the past
        </div>
      )}
    />

  );
};
