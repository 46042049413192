import { useCallback } from 'react';
import { useModalConfigContext } from '../../../../contexts/ModalConfigContext/ModalConfigContext';
import ModalButton from '../../../../uiComponent/ModalButton/ModalButton';
import { ClickableDiv } from '../../../../uiComponent/ClickableDiv/ClickableDiv';
import { Title } from '../../../../uiComponent/Title/Title';
import { BloodPressureDeviceFormComponent, BloodPressureDeviceFormComponentProps } from '../BloodPressureDeviceFormComponent/BloodPressureDeviceFormComponent';
import { DeviceModelEnum } from '../../../../uc-api-sdk';
import { ConnectedDeviceInfo } from '../../type';

export interface BloodPressureDeviceFormModalButtonComponentProps {
  deviceModel: DeviceModelEnum;
  device?: ConnectedDeviceInfo;
  onSubmit?: BloodPressureDeviceFormComponentProps['onSubmit'];
  children?: React.ReactNode;
  width?: string | number;
  disabled?: boolean;
}

export const BloodPressureDeviceFormModalButtonComponent = ({
  deviceModel,
  device,
  onSubmit,
  children,
  width = 460,
  disabled,
}: BloodPressureDeviceFormModalButtonComponentProps) => {
  const { getContainer } = useModalConfigContext();

  const renderModalTrigger = useCallback((open: () => void) => (
    <ClickableDiv
      onClick={open}
      disabled={disabled}
      className="w-mc"
    >
      {children}
    </ClickableDiv>
  ), []);

  return (
    <ModalButton
      trigger={renderModalTrigger}
      width={width}
      getContainer={getContainer}
      destroyOnClose
    >
      {
        ({ close }) => (
          <>
            <Title>
              Device Setup
            </Title>
            <BloodPressureDeviceFormComponent
              deviceModel={deviceModel}
              initialValues={device}
              onCancel={close}
              onSubmit={(v) => {
                onSubmit?.(v);
                close();
              }}
            />
          </>
        )
      }
    </ModalButton>
  );
};
