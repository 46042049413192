import { useMemo } from 'react';
import { AverageValue, ConditionEnum, MonthlyMeasurement } from '../../../../uc-api-sdk';
import { UpDownTrendComponent } from '../../../../uiComponent/UpDownTrendComponent/UpDownTrendComponent';
import { useAverageTrend } from '../../hook/useAverageTrend';
import { MTPRMeasurementSummaryComponent } from '../MTPRMeasurementSummaryComponent/MTPRMeasurementSummaryComponent';
import { MTPRMeasurementSummaryItemComponent } from '../MTPRMeasurementSummaryItemComponent/MTPRMeasurementSummaryItemComponent';
import { MTPRMeasurementSummaryTitleComponent } from '../MTPRMeasurementSummaryTitleComponent/MTPRMeasurementSummaryTitleComponent';
import { MeasurementAverageTooltipComponent } from '../MeasurementAverageTooltipComponent/MeasurementAverageTooltipComponent';
import { MeasureUnitHelper } from '../../../../helpers/measurement/measureUnit';
import { AvgUpTrendColor, AvgDownTrendColor } from '../../constants';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface MTPRBloodGlucoseComponentProps {
  monthlyMeasurement: MonthlyMeasurement;
}

export const MTPRBloodGlucoseComponent = ({
  monthlyMeasurement,
}: MTPRBloodGlucoseComponentProps) => {
  const BGAverageValue = monthlyMeasurement.averageValue || [];
  const { getTrend, latestAvg } = useAverageTrend(
    BGAverageValue,
    (latest, prev) => {
      if (latest?.bloodGlucose?.value && prev?.bloodGlucose?.value) {
        return latest?.bloodGlucose?.value > prev?.bloodGlucose?.value;
      }
      return null;
    }
  );

  const bgAvgTrend = useMemo(getTrend, [getTrend]);

  const renderValue = (value: AverageValue) => (
    MeasureUnitHelper.getBG(value.bloodGlucose)
      ? (
        <span>
          {MeasureUnitHelper.getBG(value.bloodGlucose)?.toFixed(0)}
          {' '}
          {MeasureUnitHelper.defaultBGUnit}
        </span>
      )
      : null
  );

  return (
    <>
      <MTPRMeasurementSummaryTitleComponent
        condition={ConditionEnum.GLUCOSE_MANAGEMENT}
        lastMeasurementDate={monthlyMeasurement.lastMeasurementDate}
      />
      <MTPRMeasurementSummaryComponent
        measurementDays={monthlyMeasurement.measurementDays}
        totalMeasurements={monthlyMeasurement.totalMeasurements}
        normalPercentage={monthlyMeasurement.normalPercentage}
      >
        <MTPRMeasurementSummaryItemComponent title="Average BG">
          <MeasurementAverageTooltipComponent
            tooltipTitle="Average BG"
            valueRenderer={renderValue}
            averageValue={BGAverageValue}
          >
            <DisplayOrEmptyComponent
              value={MeasureUnitHelper.getBG(latestAvg?.bloodGlucose)?.toFixed(0)}
              emptyText="N/A"
            />
            {
              bgAvgTrend
                ? (
                  <UpDownTrendComponent
                    trend={bgAvgTrend}
                    upTrendColor={AvgUpTrendColor}
                    downTrendColor={AvgDownTrendColor}
                  />
                ) : null
            }
          </MeasurementAverageTooltipComponent>
        </MTPRMeasurementSummaryItemComponent>
      </MTPRMeasurementSummaryComponent>
    </>
  );
};
