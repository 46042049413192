import { WorklistStepEnum } from '../WorklistDrawerComponent/WorklistDrawerComponent';
import { VitalsDataReviewComponent } from '../VitalsDataReviewComponent/VitalsDataReviewComponent';
import { Title } from '../../../../uiComponent/Title/Title';
import { FixedChildComponent } from '../../../../uiComponent/FixedComponent/FixedChildComponent';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { LifestyleAssessmentComponent } from '../LifestyleAssessmentComponent/LifestyleAssessmentComponent';
import { GoalEducationComponent } from '../GoalEducationComponent/GoalEducationComponent';
import { WorklistStepEnumComponent } from '../../../../enumComponent/WorklistStepEnumComponent/WorklistStepEnumComponent';
import { HealthRecordUpdateComponent } from '../HealthRecordUpdateComponent/HealthRecordUpdateComponent';
import { WorklistDiseaseHistoryComponent } from '../WorklistDiseaseHistoryComponent/WorklistDiseaseHistoryComponent';
import {
  ClinicEventsService
} from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { FollowUpScheduleComponent } from '../FollowUpScheduleComponent/FollowUpScheduleComponent';

export interface WorklistSectionComponentProps {
  step: WorklistStepEnum;
  clinicEvent?: ClinicEventsService | null;
  followupVisitSkipped?: boolean;
}

export const WorklistSectionComponent = ({
  step,
  clinicEvent,
  followupVisitSkipped,
}: WorklistSectionComponentProps) => {
  const {
    info: {
      id,
      patientInfoService,
      refetch
    } = {},
  } = usePatientContext();
  if (!id || patientInfoService === undefined) return null;

  const renderSection = () => {
    switch (step) {
      case WorklistStepEnum.VITALS_DATA_REVIEW:
        return (
          <VitalsDataReviewComponent
            patientId={id}
          />
        );
      case WorklistStepEnum.HEALTH_RECORDS_UPDATES:
        return (
          <HealthRecordUpdateComponent
            patientId={id}
            patientInfoService={patientInfoService}
            onSubmit={refetch}
          />
        );
      case WorklistStepEnum.DISEASE_HISTORY_REVIEW:
        return (
          <WorklistDiseaseHistoryComponent
            patientId={id}
          />
        );
      case WorklistStepEnum.LIFESTYLE_ASSESSMENT:
        return (
          <LifestyleAssessmentComponent
            patientId={id}
            patientInfoService={patientInfoService}
            onSubmit={refetch}
          />
        );
      case WorklistStepEnum.GOALS_EDUCATION_SETUP:
        return (
          <GoalEducationComponent
            patientId={id}
          />
        );
      case WorklistStepEnum.FOLLOW_UP_SCHEDULE:
        return (
          <FollowUpScheduleComponent
            clinicEvent={clinicEvent}
            followupVisitSkipped={followupVisitSkipped}
          />
        );
      default:
        return <div>{step}</div>;
    }
  };

  return (
    <FixedComponent>
      <FixedChildComponent isFixed>
        <Title>
          <WorklistStepEnumComponent value={step} />
        </Title>
      </FixedChildComponent>
      <FixedChildComponent>
        {renderSection()}
      </FixedChildComponent>
    </FixedComponent>
  );
};
