import { OnboardingChecklistItemEnum } from '../../../../uc-api-sdk';

export interface TechOnboardingChecklistEnumComponentProps {
  value: OnboardingChecklistItemEnum;
}

export const TechOnboardingChecklistEnumComponent = ({
  value,
}: TechOnboardingChecklistEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case OnboardingChecklistItemEnum.AUTO_UPDATE_ENABLED:
        return 'Set App auto update, move APP to home screen.';
      case OnboardingChecklistItemEnum.CT_NUMBER_ADDED:
        return 'Add Care Team Number into Phone Book.';
      case OnboardingChecklistItemEnum.PATIENT_GUIDED:
        return 'Guide patient to take measurements, give feedback.';
      case OnboardingChecklistItemEnum.KEYFUNCTIONS_INTRODUCED:
        return 'Introduce Key App functions.';
      default: return null;
    }
  };

  return (
    <span>{getText()}</span>
  );
};
