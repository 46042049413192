import { AxiosResponse } from 'axios';
import EnvConfig from '../../../configs/envConfig/envConfig';
import { RESTfulResponse } from '../../../types/restful';
import { QueryOptions } from '../types';

export const createUrl = (path: string) => `${EnvConfig.restfulServerUrl}${path}`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PathFunction = (args: any) => string;
export type PathType = string | PathFunction;

export const createPath = <PathParams>(path: PathType, pathParams?: PathParams) => {
  if (typeof path === 'function') {
    return path(pathParams);
  } if (typeof path === 'string') {
    return path;
  }
  return path;
};

const getOption = <T>(baseOption: T | undefined, option: T | undefined, defaultValue: T) => {
  if (option === undefined) {
    return baseOption === undefined ? defaultValue : baseOption;
  }
  return option;
};

export const getOptions = (baseOptions?: QueryOptions, options?: QueryOptions) => {
  const sendOnMount = getOption(baseOptions?.sendOnMount, options?.sendOnMount, true);
  const log = getOption(baseOptions?.log, options?.log, false);
  const logError = getOption(baseOptions?.logError, options?.logError, false);
  const maxRetryCount = getOption(baseOptions?.retry, options?.retry, 3);
  const retryDelay = getOption(baseOptions?.retryDelay, options?.retryDelay, 2000);
  const throwError = getOption(baseOptions?.throwError, options?.throwError, true);

  return {
    sendOnMount,
    log,
    logError,
    maxRetryCount,
    retryDelay,
    throwError,
  };
};

export const getStatus = <T>(res: AxiosResponse<RESTfulResponse<T>>) => (
  res.status === 200 ? res.data.code : res.status
);
