export enum ErrorBoundaryPage {
  CARE_PORTAL_PAGE = 'CARE_PORTAL_PAGE',
  ADMIN_PORTAL_PAGE = 'ADMIN_PORTAL_PAGE',
  BILLER_PORTAL_PAGE = 'BILLER_PORTAL_PAGE',
  INTERNAL_TOOL_PAGE = 'INTERNAL_TOOL_PAGE',
}

export enum ErrorBoundaryIdentifier {
  // TODO: [t][4338] need to have better identifier, ie. request url
  FetchComponent = 'FetchComponent',
  PatientProfileComponent = 'PatientProfileComponent',
  WorklistContainer = 'WorklistContainer',
  MessageHistoryContainer = 'MessageHistoryContainer',
  PatientProfileMedicalHistoryTab = 'PatientProfileMedicalHistoryTab',
  PatientProfileCareNoteTab = 'PatientProfileCareNoteTab',
  PatientProfileInterventionTab = 'PatientProfileInterventionTab',
  PatientProfileVisitTab = 'PatientProfileVisitTab',
  PatientProfileProfileTab = 'PatientProfileMedicalHistoryTab',
  PatientProfileDeviceTab = 'PatientProfileDeviceTab',
}
