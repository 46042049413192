import { PatientSourceEnum } from '../../../../uc-api-sdk';
import { AlertCardComponent } from '../../../../uiComponent/AlertCardComponent/AlertCardComponent';
import { useUCVersion } from '../../../auth/hooks/useUCVersion';
import { PatientProfileContainer } from '../../container/PatientProfileContainer/PatientProfileContainer';
import { isOldMobileAppVersion } from '../../helper/helpers';
import './OldAppVersionNoteInMsgComponent.scss';

export interface OldAppVersionNoteInMsgComponentProps {
  patientId: string;
}

export const OldAppVersionNoteInMsgComponent = ({
  patientId,
}: OldAppVersionNoteInMsgComponentProps) => {
  const { mobileVersion } = useUCVersion();
  const latestAppVersion = mobileVersion?.split(' ').at(-1);

  const renderContent = () => (
    <div className="render-content">
      The patient's App Version is outdated and will not be able to receive your messages.
      Please kindly contact patient to prompt an update. Apologies for this inconvenience.
    </div>
  );

  return (
    <PatientProfileContainer
      patientId={patientId}
      getAppVersion
    >
      {(info) => {
        const appVersion = info?.patientAppVersion?.mobileInfo?.appVersion;
        const isPatientMigrated = info?.patientInfo?.patientSource === PatientSourceEnum.MIGRATION
        || info?.patientInfo?.source === PatientSourceEnum.MIGRATION;
        const isOldVersion = isOldMobileAppVersion(
          latestAppVersion as string,
          appVersion as string
        );

        if (isPatientMigrated && appVersion && isOldVersion) {
          return (
            <div className="old-app-version-note-in-msg-component">
              <AlertCardComponent
                type="warning"
                message={renderContent()}
                warningBorder
              />
            </div>
          );
        }
        return (
          null
        );
      }}
    </PatientProfileContainer>
  );
};
