import { useEffect } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { LimitedClinic } from '../../../../uc-api-sdk';
import { PROVIDER_TABLE_TABS } from '../../contants';
import { StorageKeyEnum, useSessionStorage } from '../../../../hooks';

export interface ClinicCountButtonContainerProps {
  clinic: LimitedClinic;
  currentTab: PROVIDER_TABLE_TABS;
  lastSelectedOrgId?: string;
  handleClinicClick: (type: PROVIDER_TABLE_TABS, orgId: string) => void;
  count?: number;
}

export const ClinicCountButtonContainer = ({
  clinic,
  lastSelectedOrgId,
  currentTab,
  handleClinicClick,
  count,
}: ClinicCountButtonContainerProps) => {
  const [, setTableName] = useSessionStorage<string | undefined>(
    StorageKeyEnum.PATIENT_TABLE_NAME
  );

  useEffect(() => {
    if (PROVIDER_TABLE_TABS.CLINIC === currentTab && clinic.id === lastSelectedOrgId) {
      setTableName(clinic.businessName ?? '');
    }
  }, []);

  return (
    <Button
      key={clinic.id || ''}
      className={classNames({
        'w100 ta-l mt10': true,
        active: PROVIDER_TABLE_TABS.CLINIC === currentTab && clinic.id === lastSelectedOrgId,
      })}
      type="text"
      size="middle"
      onClick={() => {
        setTableName(clinic.businessName ?? undefined);
        handleClinicClick(PROVIDER_TABLE_TABS.CLINIC, clinic.id || '');
      }}
    >
      {`${clinic.businessName} (${count || 0})`}
    </Button>
  );
};
