import { map } from 'lodash';
import React, { FC, useMemo } from 'react';
import { FromDateToDateDayjs } from '../../type';
import { TMChartComponent, TMChartData } from './TMChartComponent';
import { TMHelper } from '../../helper/TMHelper';
import { AlertSeverityEnum, Measurement } from '../../../../uc-api-sdk';
import { VitalHelper } from '../../helper/VitalHelper';
import TimezoneService from '../../../../helpers/timezone/timezoneService';

export interface TMChartDataProcessorComponentProps extends FromDateToDateDayjs {
  data: Measurement[];
}

export const TMChartDataProcessorComponent: FC<TMChartDataProcessorComponentProps> = ({
  data,
  fromDate,
  toDate,
}) => {
  const processedData = useMemo(() => (
    map(data, (v): TMChartData => ({
      date: TimezoneService.calcDateTimeDayjs(v.date as string),
      type: VitalHelper.getResultSource(v.resultSource),
      severity: v.severity || AlertSeverityEnum.NORMAL,
      temperatureValue: TMHelper.celsiusToFahrenheit(v.body_temperature?.value || 0),
      temperatureUnit: TMHelper.getFahrenheitUnit(),
      patientNote: v.user_notes || '--',
    }))
  ), [data]);

  return (
    <TMChartComponent
      data={processedData}
      fromDate={fromDate}
      toDate={toDate}
    />
  );
};
