import { Checkbox, Col, Row } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { InputTypeArray } from '../../../Input/types';
import { PatientDiagnosesEnum } from '../../../../uc-api-sdk';
import { PatientDiagnosesEnumComponent } from '../../../../enumComponent/PatientDiagnosesEnumComponent/PatientDiagnosesEnumComponent';

export interface PatientDiagnosesInputComponentProps extends InputTypeArray<PatientDiagnosesEnum> {
}

export const PatientDiagnosesInputComponent = ({
  value,
  onChange,
}: PatientDiagnosesInputComponentProps) => {
  const handleChange = (checkedValue: CheckboxValueType[]) => {
    onChange?.(checkedValue as PatientDiagnosesEnum[]);
  };

  const renderCheckbox = (diagnose: PatientDiagnosesEnum) => (
    <Checkbox value={diagnose}>
      <PatientDiagnosesEnumComponent value={diagnose} />
    </Checkbox>
  );

  return (
    <Checkbox.Group
      className="w100"
      value={value}
      onChange={handleChange}
    >
      <Row>
        <Col span="4">
          {renderCheckbox(PatientDiagnosesEnum.HTN)}
        </Col>
        <Col span="4">
          {renderCheckbox(PatientDiagnosesEnum.HLD)}
        </Col>
        <Col span="4">
          {renderCheckbox(PatientDiagnosesEnum.DM)}
        </Col>
        <Col span="5">
          {renderCheckbox(PatientDiagnosesEnum.PRE_DM)}
        </Col>
        <Col span="4">
          {renderCheckbox(PatientDiagnosesEnum.COPD)}
        </Col>
      </Row>
      <Row>
        <Col span="4">
          {renderCheckbox(PatientDiagnosesEnum.OBESITY)}
        </Col>
        <Col span="4">
          {renderCheckbox(PatientDiagnosesEnum.CKF)}
        </Col>
        <Col span="4">
          {renderCheckbox(PatientDiagnosesEnum.CHF)}
        </Col>
        <Col span="5">
          {renderCheckbox(PatientDiagnosesEnum.ESRD_DIALYSIS)}
        </Col>
        <Col span="4">
          {renderCheckbox(PatientDiagnosesEnum.OTHERS)}
        </Col>
      </Row>
    </Checkbox.Group>
  );
};
