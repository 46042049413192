import { useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';

// TODO-TYPE-CHANGE
export const useCreateEditLabResultForm = () => {
  const factory = useFormHookFactory({
    testDate: {
      name: 'testDate',
      label: 'Test date',
      required: true,
    },
    templateName: {
      name: 'templateName',
      label: 'Lab Name',
      required: false,
    },
  });

  return factory;
};
