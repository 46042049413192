import dayjs from 'dayjs';
import { map } from 'lodash';
import { LLMLabResultSourceNavigatorData, LLMLabResultViewSourceData } from '../../type';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';
import { LinkButton } from '../../../../../uiComponent/LinkButton/LinkButton';
import { USA_DATE } from '../../../../../constants/timeFormat';

import './LLMLabResultNavigatorComponent.scss';

export interface LLMLabResultNavigatorComponentProps {
  navigatorData: LLMLabResultSourceNavigatorData;
  onClick?: (fileName: string, imageIndex: number) => void;
}

export const LLMLabResultNavigatorComponent = ({
  navigatorData,
  onClick,
}: LLMLabResultNavigatorComponentProps) => {
  const handleOnClick = (
    data: LLMLabResultViewSourceData
  ) => {
    const { file, imageIndex } = data;
    if (imageIndex === -1) return;
    onClick?.(file.name, imageIndex);
  };

  return (
    <div>
      {
        map(navigatorData, (data, title) => (
          <div key={title} className="mb20">
            <TextComponent className="llm-labresult-navigator__title">
              {title}
            </TextComponent>
            <div className="flex fd-c">
              {
                map(data, (d, idx) => (
                  d.dateCollected
                    ? (
                      <LinkButton
                        key={idx}
                        onClick={() => handleOnClick(d)}
                        showUnderline={d.imageIndex !== -1}
                      >
                        {dayjs(new Date(d.dateCollected)).format(USA_DATE)}
                      </LinkButton>
                    ) : null
                ))
              }
            </div>
          </div>
        ))
      }
    </div>
  );
};
