import { Tooltip } from 'antd';
import { ReactNode } from 'react';
import './EllipsisComponent.scss';

export interface EllipsisComponentProps {
  width?: string | number;
  children?: ReactNode;
}

export const EllipsisComponent = ({
  width = '100%',
  children,
}: EllipsisComponentProps) => (
  <Tooltip overlay={children}>
    <div className="ellipsis-component" style={{ width }}>
      {children}
    </div>
  </Tooltip>
);
