import { DEFAULT_PAGINATION } from '../../../../constants/pagination';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { TableCacheNameEnum } from '../../../../hooks/useTableChange/useTableChange';
import {
  PageInfo, PatientFilter,
  PatientListTypeEnum, RoleTypeEnum, Sort$Direction, usePatientPatientLimitedList
} from '../../../../uc-api-sdk';
import { usePatientsTableOnChange } from '../usePatientsTableOnChange/usePatientsTableOnChange';
import { getMyAssignedPatientsCount } from '../usePatientsTableSearch/usePatientsTableSearch';

export const useProviderViewPatientsTableSearch = (
  type: PatientListTypeEnum,
  name?: TableCacheNameEnum,
  filters: PatientFilter = {},
) => {
  const defaultSorter = () => {
    switch (type) {
      case PatientListTypeEnum.ENROLLED:
        return [{ direction: Sort$Direction.DESC, property: 'enrolledProgram.enrollmentDate' }];
      case PatientListTypeEnum.UNENROLLED:
        return [{ direction: Sort$Direction.DESC, property: 'createdAt' }];
      case PatientListTypeEnum.ALL:
      {
        if (filters.patient?.filterWatchedPatient) {
          return [{ direction: Sort$Direction.DESC, property: 'watchInfo.addedTime' }];
        }
        return [{ direction: Sort$Direction.ASC, property: 'profile.firstName' }];
      }
      case PatientListTypeEnum.SCREENING:
        return [{ direction: Sort$Direction.DESC, property: 'createdAt' }];
      default:
        return [];
    }
  };
  const tableChange = usePatientsTableOnChange(defaultSorter(), name);

  const page: PageInfo = {
    page: tableChange.pageInfo.page ?? 1,
    size: tableChange.pageInfo.size ?? DEFAULT_PAGINATION.pageSize,
    sort: (tableChange.processedSort || []),
  };
  const { patient: basePatient, assignee, ...baseRest } = filters;
  const { patient, assignee: postAssignee, ...rest } = tableChange.processedFilter || {};
  const allFilters = {
    patient: {
      ...basePatient,
      ...patient,
    },
    ...baseRest,
    ...rest,
    listType: type,
    assignee: assignee || postAssignee,
  };

  const patients = usePatientPatientLimitedList({
    options: {
      sendOnMount: true,
    },
    params: {
      searchRequest: {
        filter: allFilters,
        pageInfo: page,
      },
    },
  });

  return {
    patients,
    tableChange,
    allFilters,
  };
};

export interface TableCountProps {
  type: PatientListTypeEnum,
  clinicId?: string,
  filterWatchedPatient?: boolean,
  myAssigned?: boolean,
  needToFilter?: boolean;
}

export const getProviderAssignedPatientsCount = () => {
  const { userId, doesUserRoleIncludes } = useLoggedInUserFromContext();
  // sync with logic in ProviderViewTablesComponent
  const isMA = doesUserRoleIncludes([RoleTypeEnum.MA]);
  const commonFilter = {
    type: PatientListTypeEnum.ALL,
    needToFilter: true,
  };
  if (isMA) {
    return getMyAssignedPatientsCount(commonFilter);
  }
  return getMyAssignedPatientsCount({
    ...commonFilter,
    patient: {
      profile: {
        doctorId: userId
      }
    }
  });
};
