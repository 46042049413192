import { ReactNode } from 'react';
import { Modal } from 'antd';
import { useBoolean } from 'usehooks-ts';
import { DeviceCheckboxComponent, DeviceCheckboxComponentProps } from '../DeviceCheckboxComponent/DeviceCheckboxComponent';
import { InputType } from '../../../Input/types';
import {
  AssignDeviceInfo,
} from '../../../../uc-api-sdk';

export interface DeviceCheckboxWithAssignModalComponentProps extends
  InputType<AssignDeviceInfo>, DeviceCheckboxComponentProps {
    modalContent: (close: () => void) => ReactNode;
}

export const DeviceCheckboxWithAssignModalComponent = ({
  value,
  onChange,
  modalContent,
  ...restProps
}: DeviceCheckboxWithAssignModalComponentProps) => {
  const {
    value: isOpen,
    setTrue: setOpenTrue,
    setFalse: setOpenFalse,
  } = useBoolean();

  const handleChange: DeviceCheckboxComponentProps['onChange'] = (v) => {
    if (v) {
      setOpenTrue();
    }
    onChange?.(v);
  };

  const handleCloseModal = () => {
    // reset checkbox if modal close before finished
    onChange?.(undefined);
    setOpenFalse();
  };

  return (
    <>
      <DeviceCheckboxComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restProps}
        value={value}
        onChange={handleChange}
      />
      <Modal
        open={isOpen}
        width={550}
        onCancel={handleCloseModal}
        footer={null}
        destroyOnClose
      >
        {modalContent(setOpenFalse)}
      </Modal>
    </>
  );
};
