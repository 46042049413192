import React, { FC } from 'react';

export interface TicketActivityUserComponentProps {
  user: string | undefined | null;
  addSpaceBefore?: boolean;
  addSpaceAfter?: boolean;
}

export const TicketActivityUserComponent: FC<TicketActivityUserComponentProps> = ({
  user,
  addSpaceBefore = true,
  addSpaceAfter = false,
}) => (
  <>
    {addSpaceBefore && ' '}
    <strong>{user || 'N/A'}</strong>
    {addSpaceAfter && ' '}
  </>
);
