import { useCallback, useState } from 'react';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import ModalButton, { ModalButtonProps } from '../../../../uiComponent/ModalButton/ModalButton';
import { EligibilityReport, Patient } from '../../../../uc-api-sdk';
import { InsuranceEligibilityVerifyFormContainer, InsuranceEligibilityVerifyFormContainerProps, InsuranceEligibilityVerifySyncStatus } from '../../container/InsuranceEligibilityVerifyFormContainer/InsuranceEligibilityVerifyFormContainer';
import { ModalWidth600 } from '../../../../constants/modal';
import { InsuranceCardSubmitValue, InsuranceEligibilityRequestSubmitValue } from '../../../../hooks/formHook/useInsuranceForm';
import { useInsuranceFormContext } from '../../context/InsuranceFormContext';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { WaystarResultModalComponent } from '../WaystarComponent/WaystarResultModalComponent/WaystarResultModalComponent';
import { InsurancePlanManualInputTooltipComponent } from './InsurancePlanManualInputTooltipComponent';

export interface InsuranceEligibilityVerifyButtonComponentProps {
  patient?: Patient;
  insurance?: Partial<InsuranceCardSubmitValue>;
  onDone?: (
    eligibilityReport?: EligibilityReport | null,
    eligibilityRequest?: InsuranceEligibilityRequestSubmitValue | null,
  ) => void;
  trigger?: ModalButtonProps['trigger'];
  autoSync?: boolean;
  isManualInput?: boolean;
}

export const InsuranceEligibilityVerifyButtonComponent = ({
  patient,
  insurance,
  onDone,
  trigger,
  autoSync = true,
  isManualInput = (!!insurance?.provider && !insurance?.insuranceProviderId),
}: InsuranceEligibilityVerifyButtonComponentProps) => {
  const formContext = useInsuranceFormContext();
  const patientInfo = patient || formContext.patient;
  const insuranceInfo = insurance || formContext.getCurrentEditableInsurance();
  const patientUpdateHook = useUpdate('PATIENT_INFO_UPDATED');
  const eligibilityUpdate = useUpdate('INSURANCE_ELIGIBILITY_UPDATED');
  const [
    eligibilityReport,
    setEligibilityReport,
  ] = useState<EligibilityReport | null>();
  const [
    eligibilityRequestValue,
    setEligibilityRequestValue,
  ] = useState<InsuranceEligibilityRequestSubmitValue | null>();
  const [
    syncStatus,
    setSyncStatus,
  ] = useState<InsuranceEligibilityVerifySyncStatus>();

  const triggerButton = useCallback((open: () => void) => (
    <div className="flex ai-c">
      <LinkButton
        useBlueColor
        onClick={open}
        disabled={isManualInput}
        className="no-style-btn"
      >
        Verify
      </LinkButton>
      <InsurancePlanManualInputTooltipComponent
        isManualInput={isManualInput}
      />
    </div>
  ), [isManualInput]);

  const handleCloseResultModal = () => {
    if (syncStatus?.isInsuranceSynced) {
      eligibilityUpdate.updateValue();
    }
    if (syncStatus?.isProfileSynced) {
      patientUpdateHook.updateValue();
    }
    onDone?.(
      eligibilityReport,
      eligibilityRequestValue,
    );
    setEligibilityReport(null);
  };

  const handleOnVerified: InsuranceEligibilityVerifyFormContainerProps['onVerified'] = (
    eligibilityReport,
    eligibilityRequestValue,
    syncStatus,
  ) => {
    setEligibilityReport(eligibilityReport);
    setEligibilityRequestValue(eligibilityRequestValue);
    setSyncStatus(syncStatus);
  };

  if (eligibilityReport) {
    return (
      <WaystarResultModalComponent
        eligibilityStatus={eligibilityReport.eligibilityStatus ?? undefined}
        inquiryInfo={eligibilityReport}
        report={eligibilityReport.shortHtml || ''}
        onDone={handleCloseResultModal}
      />
    );
  }

  return (
    <ModalButton
      trigger={trigger || triggerButton}
      width={ModalWidth600}
      maskClosable={false}
      keyboard={false}
      destroyOnClose
    >
      <div className="py20">
        <InsuranceEligibilityVerifyFormContainer
          patient={patientInfo}
          insurance={insuranceInfo}
          onVerified={handleOnVerified}
          autoSync={autoSync}
        />
      </div>
    </ModalButton>
  );
};
