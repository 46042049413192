import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { Title } from '../../../../uiComponent/Title/Title';
import { DischargeFormContainer, DischargeFormContainerProps } from '../../container/DischargeFormContainer/DischargeFormContainer';

export interface DischargeModalContentComponentProps {
  patientInfo: PatientInfo;
  onSubmit?: DischargeFormContainerProps['onSubmit'];
  onCancel?: DischargeFormContainerProps['onCancel'];
}

export const DischargeModalContentComponent = ({
  patientInfo,
  onSubmit,
  onCancel
}: DischargeModalContentComponentProps) => {
  const { id, patientInfoService, enrolledProgramService } = patientInfo;
  const enrolledVitals = enrolledProgramService?.getVitals() || [];

  return (
    <div>
      <Title>
        Discharge
        {' '}
        <span>
          {patientInfoService?.getFullName()}
        </span>
      </Title>
      <div>
        This is an irreversible process. To continue to discharge, please type
        {' "'}
        <span className="danger">DISCHARGE</span>
        {'" '}
        below:
      </div>
      <DischargeFormContainer
        patientId={id}
        enrolledVitals={enrolledVitals}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};
