import classNames from 'classnames';
import { AccountHelper } from '../../../helpers/account';
import { LimitedUser } from '../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../uiComponent/DisplayComponent/DisplayDateComponent';
import './UpdatedByAtComponent.scss';

export interface UpdatedByAt {
  updatedAt?: string | undefined | null;
  updatedByUser?: LimitedUser | undefined | null;
}

export interface UpdatedByAtComponentProps<T extends UpdatedByAt> {
  info?: T | null | undefined;
  prefix?: string;
  reverseOrder?: boolean;
}

export const UpdatedByAtComponent = <T extends UpdatedByAt>({
  info,
  prefix,
  reverseOrder = true,
}: UpdatedByAtComponentProps<T>) => {
  const { updatedAt, updatedByUser } = info || {};

  return (
    <div
      className={classNames({
        'updated-by-at-component': true,
        reverse: !!reverseOrder,
      })}
    >
      {prefix && <div>{prefix}</div>}
      {updatedByUser && (
        <span className="updated-by">
          {AccountHelper.getFullName(updatedByUser)}
          {updatedByUser.title ? `, ${updatedByUser.title}` : ''}
        </span>
      )}
      {updatedAt && (
        <div className="updated-at">
          <DisplayDateComponent
            value={updatedAt}
            format="DATE_TIME_AM_PM"
          />
        </div>
      )}
    </div>
  );
};
