import { Button, Space } from 'antd';

export interface CompleteChartingButtonComponentProps {
  onOk: () => void;
  onCancel: () => void;
  okText?: string;
  cancelText?: string;
}

export const CompleteChartingButtonComponent = ({
  onOk,
  onCancel,
  okText = "Yes, It's Complete",
  cancelText = "No, I'll Complete Later",
}: CompleteChartingButtonComponentProps) => (
  <Space className="w100" direction="vertical">
    <span>Is charting complete?</span>
    <div className="flex f-w jc-sb">
      <Button
        type="primary"
        className="f1"
        onClick={onOk}
        size="large"
      >
        {okText}
      </Button>
      <Button
        type="primary"
        className="f1"
        onClick={onCancel}
        size="large"
        ghost
      >
        {cancelText}
      </Button>
    </div>
  </Space>
);
