import { FC } from 'react';
import { CareNote } from '../../../../../uc-api-sdk';
import { FormType } from '../../../../Input/types';
import { CareNoteCollapsibleHistoryComponent } from '../../CareNoteCollapsibleHistoryComponent/CareNoteCollapsibleHistoryComponent';
import { CareNoteCreateEditFormComponent } from '../CareNoteCreateEditFormComponent/CareNoteCreateEditFormComponent';

export interface CareNoteFormComponentProps extends
  FormType<CareNote> {
  mode?: 'CREATE' | 'EDIT';
  onCancel?: () => void;
}

export const CareNoteFormComponent: FC<CareNoteFormComponentProps> = ({
  mode = 'CREATE',
  onCancel,
  onSubmit,
  isLoading,
  initialValues,
}) => {
  const histories = ([...(initialValues?.histories || [])]).reverse();
  return (
    <div>
      <CareNoteCreateEditFormComponent
        onFinish={onSubmit}
        mode={mode}
        onCancel={onCancel}
        initialValues={initialValues}
        isLoading={isLoading}
      />
      {mode === 'EDIT'
        && (
        <CareNoteCollapsibleHistoryComponent
          histories={histories}
        />
        )}
    </div>
  );
};
