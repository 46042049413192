import { useCallback, useMemo } from 'react';
import { useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { GetFieldValue } from '../../../types/form';

export const useAFKForm = () => {
  const factory = useFormHookFactory({
    timeSpent: {
      name: 'timeSpent',
      label: '',
    },
  });

  const showActivity = useCallback((getFieldValue: GetFieldValue) => (
    factory.getValue('timeSpent', getFieldValue) > 0
  ), [factory.getValue]);

  const response = useMemo(() => ({
    ...factory,
    showActivity
  }), [
    factory,
    showActivity
  ]);

  return response;
};
