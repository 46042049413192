export enum GoogleTranslateLanguageType {
  English = 'English',
  Chinese_Simplified = 'Chinese (Simplified)',
  Chinese_Traditional = 'Chinese (Traditional)',
  Spanish = 'Spanish',
  Vietnamese= 'Vietnamese',
  Filipino = 'Filipino',
  Japanese = 'Japanese',
  Afrikaans = 'Afrikaans',
  Albanian = 'Albanian',
  Amharic= 'Amharic',
  Arabic = 'Arabic',
  Armenian ='Armenian',
  Assamese= 'Assamese',
  Aymara = 'Aymara',
  Azerbaijani = 'Azerbaijani',
  Bambara = 'Bambara',
  Basque = 'Basque',
  Belarusian = 'Belarusian',
  Bengali = 'Bengali',
  Bhojpuri = 'Bhojpuri',
  Bosnian = 'Bosnian',
  Bulgarian = 'Bulgarian',
  Catalan = 'Catalan',
  Cebuano = 'Cebuano',
  Chichewa = 'Chichewa',
  Corsican = 'Corsican',
  Croatian = 'Croatian',
  Czech = 'Czech',
  Danish ='Danish',
  Divehi = 'Divehi',
  Dogri = 'Dogri',
  Dutch = 'Dutch',
  Esperanto = 'Esperanto',
  Estonian = 'Estonian',
  Ewe = 'Ewe',
  Finnish = 'Finnish',
  French = 'French',
  Frisian = 'Frisian',
  Galician = 'Galician',
  Ganda = 'Ganda',
  Georgian = 'Georgian',
  German = 'German',
  Greek = 'Greek',
  Guarani = 'Guarani',
  Gujarati = 'Gujarati',
  Haitian_Creole = 'Haitian Creole',
  Hausa = 'Hausa',
  Hawaiian = 'Hawaiian',
  Hebrew = 'Hebrew',
  Hindi = 'Hindi',
  Hmong = 'Hmong',
  Hungarian = 'Hungarian',
  Icelandic = 'Icelandic',
  Igbo = 'Igbo',
  Iloko = 'Iloko',
  Indonesian = 'Indonesian',
  Irish_Gaelic = 'Irish Gaelic',
  Italian = 'Italian',
  Javanese = 'Javanese',
  Kannada = 'Kannada',
  Kazakh = 'Kazakh',
  Khmer = 'Khmer',
  Kinyarwanda = 'Kinyarwanda',
  Konkani = 'Konkani',
  Korean = 'Korean',
  Krio = 'Krio',
  Kurdish_Kurmanji = 'Kurdish (Kurmanji)',
  Kurdish_Sorani = 'Kurdish (Sorani)',
  Kyrgyz = 'Kyrgyz',
  Lao = 'Lao',
  Latin = 'Latin',
  Latvian = 'Latvian',
  Lingala = 'Lingala',
  Lithuanian = 'Lithuanian',
  Luxembourgish = 'Luxembourgish',
  Macedonian = 'Macedonian',
  Maithili = 'Maithili',
  Malagasy = 'Malagasy',
  Malay = 'Malay',
  Malayalam = 'Malayalam',
  Maltese = 'Maltese',
  Maori = 'Maori',
  Marathi = 'Marathi',
  Meiteilon_Manipuri = 'Meiteilon (Manipuri)',
  Mizo = 'Mizo',
  Mongolian = 'Mongolian',
  Myanmar_Burmese = 'Myanmar (Burmese)',
  Nepali = 'Nepali',
  Northern_Sotho = 'Northern Sotho',
  Norwegian = 'Norwegian',
  Odia_Oriya = 'Odia (Oriya)',
  Oromo ='Oromo',
  Pashto = 'Pashto',
  Persian = 'Persian',
  Polish = 'Polish',
  Portuguese = 'Portuguese',
  Punjabi = 'Punjabi',
  Quechua = 'Quechua',
  Romanian = 'Romanian',
  Russian = 'Russian',
  Samoan = 'Samoan',
  Sanskrit = 'Sanskrit',
  Scots_Gaelic = 'Scots Gaelic',
  Serbian = 'Serbian',
  Sesotho = 'Sesotho',
  Shona = 'Shona',
  Sindhi = 'Sindhi',
  Sinhala = 'Sinhala',
  Slovak = 'Slovak',
  Slovenian = 'Slovenian',
  Somali = 'Somali',
  Sundanese = 'Sundanese',
  Swahili = 'Swahili',
  Swedish = 'Swedish',
  Tajik = 'Tajik',
  Tamil = 'Tamil',
  Tatar ='Tatar',
  Telugu = 'Telugu',
  Thai ='Thai',
  Tigrinya = 'Tigrinya',
  Tsonga = 'Tsonga',
  Turkish = 'Turkish',
  Turkmen = 'Turkmen',
  Twi = 'Twi',
  Ukrainian = 'Ukrainian',
  Urdu = 'Urdu',
  Uyghur ='Uyghur',
  Uzbek = 'Uzbek',
  Welsh = 'Welsh',
  Xhosa ='Xhosa',
  Yiddish ='Yiddish',
  Yoruba ='Yoruba',
  Zulu = 'Zulu'
}
