import { Moment } from 'moment';
import React, { FC } from 'react';
import { OBV_DAY, USA_DATE } from '../../constants/timeFormat';

export interface FoodLogTableDateComponentProps {
  date: Moment;
}

export const FoodLogTableDateComponent: FC<FoodLogTableDateComponentProps> = ({
  date,
}) => (
  <div className="flex jc-c ai-c fd-c">
    <div><b>{date.format(OBV_DAY)}</b></div>
    <div><b>{date.format(USA_DATE)}</b></div>
  </div>
);
