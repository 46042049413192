import { TableCacheNameEnum } from '../../../../hooks/useTableChange/useTableChange';
import { PatientListTypeEnum } from '../../../../uc-api-sdk';
import { EnrolledPatientsTableComponent } from '../../../patient/component/EnrolledPatientsTableComponent/EnrolledPatientsTableComponent';
import { PatientsTableContainer } from '../PatientsTableContainer/PatientsTableContainer';

export interface EnrolledPatientsTableContainerProps {

}

export const EnrolledPatientsTableContainer = () => (
  <PatientsTableContainer
    type={PatientListTypeEnum.ENROLLED}
    name={TableCacheNameEnum.EnrolledPatients}
  >
    {({
      totalSize,
      pagination,
      dataSource,
      onChange,
      filters,
      sorters,
      isLoading,
      exportData,
    }) => (
      <EnrolledPatientsTableComponent
        totalSize={totalSize}
        pagination={pagination}
        dataSource={dataSource}
        onChange={onChange}
        filters={filters}
        sorters={sorters}
        loading={isLoading}
        exportData={exportData}
      />
    )}
  </PatientsTableContainer>
);
