import { BehaviorGoalCategoryEnumComponent } from '../../../../../enumComponent/BehaviorGoalCategoryEnumComponent/BehaviorGoalCategoryEnumComponent';
import { BehaviorGoalCategoryEnum } from '../../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface BehaviorGoalCategoryComponentProps {
  category?: BehaviorGoalCategoryEnum | null;
  customCategory?: string | null;
}

export const BehaviorGoalCategoryComponent = ({
  category,
  customCategory,
}: BehaviorGoalCategoryComponentProps) => {
  if (category) {
    return <BehaviorGoalCategoryEnumComponent value={category} />;
  }
  return <DisplayOrEmptyComponent value={customCategory} />;
};
