import { map } from 'lodash';
import {
  DeviceAssignment,
  DeviceModelEnum,
  usePatientDeviceGetToBeRemovedDevice,
  VitalEnumType
} from '../../../../uc-api-sdk';
import { CancelManualSubmitButtonComponent } from '../../../../uiComponent/CancelManualSubmitButtonComponent/CancelManualSubmitButtonComponent';
import { NonAppDeviceInfoComponent } from '../../../enrollment/component/NonAppDeviceInfoComponent/NonAppDeviceInfoComponent';
import { Title } from '../../../../uiComponent/Title/Title';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { ConnectedDeviceInfo } from '../../../device/type';

export interface VitalRemovePrecheckContainerProps {
  patientId: string;
  vitalType: VitalEnumType;
  onConfirm?: (devices: ConnectedDeviceInfo[]) => void;
  onCancel?: () => void;
}

export const VitalRemovePrecheckContainer = ({
  patientId,
  vitalType,
  onConfirm,
  onCancel,
}: VitalRemovePrecheckContainerProps) => {
  const vitalRemovePrecheckInfo = usePatientDeviceGetToBeRemovedDevice({
    params: {
      memberId: patientId,
      removedVitals: [vitalType],
    },
    options: { sendOnMount: true }
  });

  const deviceList = vitalRemovePrecheckInfo.data?.data as DeviceAssignment['devices'];

  if (vitalRemovePrecheckInfo.isLoading) {
    return <LoadingOverlayComponent isLoading />;
  }

  const handleOnConfirm = () => {
    onConfirm?.(deviceList as ConnectedDeviceInfo[]);
  };

  return (
    <div className="vital-remove-precheck-modal-body">
      <Title className="mb20">
        By removing this vital, the system will detach the assigned device from this patient.
      </Title>
      {
        !deviceList?.length
          ? (
            <div>
              Patient won't be able to measure this vital through the App once it's removed.
            </div>
          ) : (
            <div>
              <div>Kindly inform the patient to return the following devices.</div>
              <div className="vital-remove-precheck-devices">
                {
                  map(deviceList, (device) => (
                    <NonAppDeviceInfoComponent
                      key={device.deviceId}
                      deviceId={device.deviceId as string}
                      deviceModel={device.deviceModel as DeviceModelEnum}
                      boxShadow={false}
                    />
                  ))
                }
              </div>
            </div>
          )
      }
      <div className="flex jc-e mt20">
        <CancelManualSubmitButtonComponent
          submitText="Remove this Vital"
          onSubmit={handleOnConfirm}
          onCancel={onCancel}
        />
      </div>
    </div>
  );
};
