import { last, some } from 'lodash';
import { FC } from 'react';
import { FoodLogTableItemComponent } from './FoodLogTableItemComponent';
import { FoodLog } from '../../type';
import { FoodLogModalComponent } from '../FoodLogModalComponent/FoodLogModalComponent';
import { useBoolean } from '../../../../hooks/useBoolean/useBoolean';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import { useFetchComponentContext } from '../../../../contexts/FetchComponentContext/FetchComponentContext';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';

export interface FoodLogTableItemWModalComponentProps {
  data: FoodLog[];
  onReview?: () => void;
}

export const FoodLogTableItemWModalComponent: FC<FoodLogTableItemWModalComponentProps> = ({
  data,
  onReview,
}) => {
  const { value: open, setTrue, setFalse } = useBoolean(false);
  const fetchInfo = useFetchComponentContext();
  const { send: sendMixpanel } = useMixpanelContext();

  const lastData = last(data);
  if (!data?.length) return null;

  const handleClose = () => {
    fetchInfo?.refetch();
    setFalse();
  };

  const handleOnReview = () => {
    onReview?.();
    setTrue();
    sendMixpanel({
      event: MixpanelEvents.PatientFoodLogViewBtn,
    });
  };

  return (
    <>
      <FoodLogTableItemComponent
        date={TimezoneService.calcDateTime(lastData?.uploadedAt || '', lastData?.timezone)}
        rate={lastData?.rating?.star || undefined}
        image={lastData?.images?.at(0)?.thumbnailLink || undefined}
        count={data?.length || undefined}
        needsReview={some(data, (v) => (v.rating?.star || 0) === 0)}
        onClick={handleOnReview}
      />
      <FoodLogModalComponent data={data} open={open} onClose={handleClose} />
    </>
  );
};
