import { useState } from 'react';
import { DeviceModelEnum } from '../../../../uc-api-sdk';
import { DeviceMonitorFormContainerChildrenProps } from '../../container/DeviceMonitorFormContainer/DeviceMonitorFormContainer';
import { DeviceHelper } from '../../helper';
import { BPDeviceAssignmentComponent } from '../BloodPressureDeviceAssignmentComponent/BloodPressureDeviceAssignmentComponent';
import { NestedFormControlProvider } from '../../../../contexts/NestedFormControlContext/NestedFormControlContext';
import { NestedFormComponent } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';

export interface BloodPressureDeviceAssignmentFormComponentProps { }

export const BloodPressureDeviceAssignmentFormComponent = ({
  selectedMonitorMethod,
  assignedDevice,
  onAssign,
  onUnassign
}: DeviceMonitorFormContainerChildrenProps) => {
  const [bpDeviceList] = useState([
    DeviceModelEnum.BPM1,
    DeviceModelEnum.BPM1_HOTSPOT,
    DeviceModelEnum.PY_802_LTE
  ]);

  if (DeviceHelper.isNonAppMonitor(selectedMonitorMethod)) {
    const isAssignmentDisabled = (deviceModel: DeviceModelEnum) => {
      const {
        deviceModel: assignedDeviceModel,
      } = assignedDevice || {};
      return (
        !!assignedDeviceModel
        && bpDeviceList.includes(assignedDeviceModel)
        && assignedDeviceModel !== deviceModel
      );
    };
    return (
      <div>
        <div className="mb10">Device</div>
        <NestedFormControlProvider>
          {
            bpDeviceList.map((deviceModel) => (
              <NestedFormComponent key={deviceModel}>
                <BPDeviceAssignmentComponent
                  deviceModel={deviceModel}
                  assignedDevice={assignedDevice}
                  onAssign={onAssign}
                  onUnassign={onUnassign}
                  disabled={isAssignmentDisabled(deviceModel)}
                />
              </NestedFormComponent>
            ))
          }
        </NestedFormControlProvider>
      </div>
    );
  }
  return null;
};
