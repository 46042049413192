import { map } from 'lodash';
import { MedicalOrganization, ProgramCategoryEnum } from '../uc-api-sdk';

export class MedicalOrganizationService {
  medicalOrganization: MedicalOrganization | undefined;

  constructor(medicalOrganization: MedicalOrganization) {
    this.medicalOrganization = medicalOrganization;
  }

  getMedicalOrganization() {
    return this.medicalOrganization;
  }

  availablePrograms = () => (
    map(this.medicalOrganization?.programParticipation, 'name')
  ) as ProgramCategoryEnum[];

  get isSaasModel() {
    return !!this.medicalOrganization?.isSaasModel;
  }

  isRiverBend = () => (
    ['6552752b5ba6220013e10245'].includes(this.medicalOrganization?.id || '')
  );

  isStThomas = () => (
    ['6532ad2739f0360013abfb2c'].includes(this.medicalOrganization?.id || '')
  );
}
