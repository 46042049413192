import { ReactNode, useEffect, useState } from 'react';
import { compact, isEmpty, map } from 'lodash';
import { Modal } from 'antd';
import { LLMDragDropUploadComponent, LLMDragDropUploadComponentProps } from '../../component/LLMDragDropUploadComponent/LLMDragDropUploadComponent';
import { LLMProcessContainer, LLMProcessContainerProps } from '../LLMProcessContainer/LLMProcessContainer';
import { LLMUploadFileInfo } from '../LLMUploadContainer/LLMUploadContainer';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { LLMTranscribeJobInfo } from '../../type';
import { useModalConfigContext } from '../../../../contexts/ModalConfigContext/ModalConfigContext';
import { useEffectWithPrevValue } from '../../../../hooks/useEffectWithPrevValue/useEffectWithPrevValue';
import { useTranscribingJobTranscribe } from '../../../../uc-api-sdk';
import { useLLMBlockPrompt } from '../../hook/useLLMBlockPrompt';

export interface LLMUploadProcessContainerProps {
  patientId: string;
  initialUploadedFiles?: LLMUploadFileInfo[];
  autoStart?: boolean;
  onJobCompleted?: (jobInfo: LLMTranscribeJobInfo<unknown>) => void;
  onJobIsProcessingChange?: (isProcessing: boolean) => void;
  guide?: ReactNode;
}

export const LLMUploadProcessContainer = ({
  patientId,
  initialUploadedFiles,
  autoStart = !!initialUploadedFiles?.length,
  onJobCompleted,
  onJobIsProcessingChange,
  guide,
}: LLMUploadProcessContainerProps) => {
  const {
    getContainer
  } = useModalConfigContext();
  const {
    value: isProcessingJob,
    setTrue: startProcessingJob,
    setFalse: stopProcessingJob,
  } = useBoolean(autoStart);
  const [uploadedFiles, setUploadedFiles] = useState<LLMUploadFileInfo[] | undefined>(
    initialUploadedFiles
  );
  const {
    makeClosePrompt,
    setLLMBlockPrompt,
    blockUnmountLLM,
    unblockUnmountLLM,
  } = useLLMBlockPrompt();

  const showFailModal = (
    reason: string,
  ) => {
    Modal.warn({
      title: 'Failed to process',
      content: reason,
      getContainer,
      wrapClassName: 'absolute',
    });
  };

  const getS3FileKeys = (uploadFileInfoList = uploadedFiles) => (
    compact(map(uploadFileInfoList, (fileInfo) => (
      fileInfo.presignedDetails?.s3FileKey
    )))
  );

  const uploadTranscribeInfo = useTranscribingJobTranscribe({
    params: { transcriptionRequest: { patientId, s3FileKeys: getS3FileKeys() } },
    options: { sendOnMount: autoStart },
  });
  const jobId = uploadTranscribeInfo.data?.data?.jobId || undefined;

  const handleRestartJob = () => {
    uploadTranscribeInfo.refetch();
    startProcessingJob();
  };

  const handleOnCompleteLLMTranscribe: LLMProcessContainerProps['onFinish'] = (
    result,
  ) => {
    stopProcessingJob();
    if (isEmpty(result?.processedResult)) {
      showFailModal('No relevant data was recognized from the uploaded files. Please try again.');
      // don't continue if there is no processed result
      return;
    }
    onJobCompleted?.({
      result,
      jobId: result.jobId || '',
      uploadFileInfoList: uploadedFiles,
      restartJob: handleRestartJob,
    });
  };

  const handleOnUpload: LLMDragDropUploadComponentProps['onUpload'] = async (uploadFileInfoList) => {
    const hasSuccessUpload = !!uploadFileInfoList?.find((fileInfo) => (
      fileInfo.uploadStatus === 'fulfilled'
    ));
    if (!hasSuccessUpload) {
      showFailModal('All files failed to upload. Please try again.');
      // don't continue when no file is uploaded successfully
      return;
    }
    uploadTranscribeInfo.send({
      params: {
        transcriptionRequest: {
          patientId,
          s3FileKeys: getS3FileKeys(uploadFileInfoList),
        },
      },
    });
    setUploadedFiles(uploadFileInfoList || []);
  };

  const handleOnFileListChange: LLMDragDropUploadComponentProps['onFileListChange'] = (
    fileList
  ) => {
    if (fileList?.length) {
      blockUnmountLLM?.();
    } else {
      unblockUnmountLLM?.();
    }
  };

  useEffect(() => {
    setLLMBlockPrompt(() => makeClosePrompt({
      content: 'Uploaded files will be not be saved.'
    }));
  }, []);

  useEffect(() => {
    onJobIsProcessingChange?.(isProcessingJob);
  }, [isProcessingJob]);

  useEffectWithPrevValue(uploadTranscribeInfo.isLoading, (prev) => {
    if (!!prev && !uploadTranscribeInfo.isLoading) {
      startProcessingJob();
    }
  });

  if (isProcessingJob) {
    return (
      <LLMProcessContainer
        jobId={jobId ?? undefined}
        onFinish={handleOnCompleteLLMTranscribe}
        onFail={handleOnCompleteLLMTranscribe}
      />
    );
  }

  return (
    <LLMDragDropUploadComponent
      patientId={patientId}
      defaultFileList={map(uploadedFiles, 'file')}
      onFileListChange={handleOnFileListChange}
      onUpload={handleOnUpload}
      guide={guide}
    />
  );
};
