import { InputType } from '../../../Input/types';
import { OrderCheckboxComponent } from '../OrderCheckboxComponent/OrderCheckboxComponent';
import { TestStripLancetSuppliesComponent } from './TestStripLancetSuppliesComponent';

export interface TestStripLancetFormItemComponentProps
  extends InputType<{ isChecked?: boolean }> { }

export const TestStripLancetFormItemComponent = ({
  value,
  onChange,
}: TestStripLancetFormItemComponentProps) => {
  const { isChecked } = value || {};
  const handleOnChange = (checked?: boolean) => {
    onChange?.({ isChecked: checked });
  };
  return (
    <OrderCheckboxComponent
      value={!!isChecked}
      onChange={handleOnChange}
    >
      <TestStripLancetSuppliesComponent />
    </OrderCheckboxComponent>
  );
};
