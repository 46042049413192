import {
  ReactNode,
  createContext,
  useCallback,
  useContext
} from 'react';
import { find } from 'lodash';
import { BenefitOrderEnum, Patient } from '../../../uc-api-sdk';
import { useGetContextValue } from '../../../hooks/useGetContextValue/useGetContextValue';
import { useInsuranceHelper } from '../hook/useInsuranceHelper';
import { InsuranceCardSubmitValue, InsuranceSubmitValue } from '../../../hooks/formHook/useInsuranceForm';

export interface InsuranceFormContextValue {
  patient: Patient;
  insurances: Partial<InsuranceCardSubmitValue>[];
  insuranceFormValues: InsuranceSubmitValue;
  shouldShowPA: (benefitOrder: BenefitOrderEnum) => boolean;
  getCurrentEditableInsurance: () => InsuranceCardSubmitValue;
  hasInsuranceOrder: (benefitOrder: BenefitOrderEnum) => boolean;
}

const InsuranceFormContext = createContext<
  InsuranceFormContextValue | undefined
>(undefined);

export const useInsuranceFormContext = () => {
  const context = useContext(InsuranceFormContext);
  if (!context) throw new Error('InsuranceFormContext not found');
  return context as InsuranceFormContextValue;
};

export interface InsuranceFormContextProviderProps {
  children: ReactNode;
  patient: Patient;
  insurances: Partial<InsuranceCardSubmitValue>[];
  insuranceFormValues: InsuranceSubmitValue;
  currentEditableCard?: number;
}
export const InsuranceFormContextProvider = ({
  children,
  patient,
  insurances,
  insuranceFormValues,
  currentEditableCard,
}: InsuranceFormContextProviderProps) => {
  const {
    shouldShowPA,
  } = useInsuranceHelper();

  const getCurrentEditableInsurance = useCallback(() => (
    insurances[currentEditableCard ?? -1] as InsuranceCardSubmitValue
  ), [currentEditableCard, insurances]);

  const hasInsuranceOrder = useCallback((benefitOrder: BenefitOrderEnum) => (
    !!find(insurances, { benefitOrder })
  ), [insurances]);

  const contextValue = useGetContextValue<InsuranceFormContextValue>({
    patient,
    insurances,
    insuranceFormValues,
    shouldShowPA,
    getCurrentEditableInsurance,
    hasInsuranceOrder,
  });

  return (
    <InsuranceFormContext.Provider value={contextValue}>
      {children}
    </InsuranceFormContext.Provider>
  );
};
