import { useMemo } from 'react';
import axios from 'axios';
import {
  APIResponse,
  APIResponseClass,
  RequestOption,
  InsuranceSubmitFormClass,
  InsuranceResponse,
  Nullable,
  PriorAuthCaseClass,
  PriorAuthCase,
  PatientInsuranceRenewPriorAuthParams,
} from '../../../uc-api-sdk';
import {
  MakeRequestHookConfig,
  GetOptions,
  UseRequestFactory,
  MethodTypeEnum,
  useRequestFactory,
  BaseGetParams,
  AjaxOptions,
} from '../../../uc-api-sdk/staticFiles/useReqHook';
import EnvConfig from '../../../configs/envConfig/envConfig';

const baseUrl = EnvConfig.restfulBaseServerUrl;
const basePath = '/v1/uc/insurance';

export interface Insurance
  extends Omit<InsuranceResponse, 'priorAuthCase' | 'insuranceDetail'> {
  insuranceDetail?: unknown;
  needRenew?: Nullable<boolean>;
}

export interface InsuranceRenewPriorAuthParams extends PatientInsuranceRenewPriorAuthParams {
  provider?: string;
  policyNumber?: string;
}

const getAll = (
  { params, headers }: RequestOption<BaseGetParams<string>>
) => (
  axios.get<APIResponse<Insurance>>(`${baseUrl}${basePath}/all/${params.id}`, { headers })
);

const renewPriorAuth = (
  { baseUrl, params, headers }: RequestOption<PatientInsuranceRenewPriorAuthParams>
) => {
  const { memberId, ...bodyParams } = params;
  return axios.put<APIResponse<PriorAuthCase>>(
    `${baseUrl}${basePath}/prior-auth/${memberId}/renew`,
    bodyParams,
    { headers }
  );
};

export const usePatientInsuranceGetAll = <RCN = undefined>(
  configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>
) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    APIResponse<Insurance>,
    typeof getAll,
    GetOptions,
    APIResponseClass<InsuranceSubmitFormClass>
  > = useMemo(() => ({
    options: { sendOnMount: true },
    reqFunc: (...args: Parameters<typeof getAll>) => (
      getAll(...args)
    ),
    ResponseClassCreator: (value: APIResponse<Insurance>) => (
      new APIResponseClass<InsuranceSubmitFormClass>(
        value,
        (value: Insurance) => new InsuranceSubmitFormClass(value)
      )
    ),
    typeName: 'usePatientInsuranceGet',
    type: MethodTypeEnum.GET,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};

export const createPatientInsuranceRenewPriorAuthObject = (
  value: APIResponse<PriorAuthCase>
) => (
  new APIResponseClass<PriorAuthCaseClass>(
    value,
    (value: PriorAuthCase) => new PriorAuthCaseClass(value)
  )
);

export const usePatientInsuranceRenewPriorAuth = <RCN = undefined>(
  configs: MakeRequestHookConfig<PatientInsuranceRenewPriorAuthParams, AjaxOptions, RCN>
) => {
  const baseConfigs: UseRequestFactory<
    PatientInsuranceRenewPriorAuthParams,
    APIResponse<PriorAuthCase>,
    typeof renewPriorAuth,
    AjaxOptions,
    APIResponseClass<PriorAuthCaseClass>
  > = useMemo(() => ({
    options: undefined,
    reqFunc: (...args: Parameters<typeof renewPriorAuth>) => (
      renewPriorAuth(...args)
    ),
    ResponseClassCreator: createPatientInsuranceRenewPriorAuthObject,
    typeName: 'usePatientInsuranceRenewPriorAuth',
    type: MethodTypeEnum.PUT,
  }), []);

  return useRequestFactory(baseConfigs, configs);
};
