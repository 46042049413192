import { Button, Form } from 'antd';
import { FormType } from '../../../Input/types';
import { useAssignDeviceInfoForm } from '../../hook/useAssignDeviceInfoForm/useAssignDeviceInfoForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { DeviceModelEnum } from '../../../../uc-api-sdk';
import { DeviceMonitorMethodEnum } from '../../../../enumComponent/DeviceMonitorMethodEnumComponent/DeviceMonitorMethodEnumComponent';
import { DeviceSearchSelectContainer } from '../../../device/container/DeviceSearchSelectContainer/DeviceSearchSelectContainer';
import { DeviceModelEnumComponent } from '../../../../enumComponent/DeviceModelEnumComponent/DeviceModelEnumComponent';

export interface SubmitValue {
  deviceId: string;
  deviceModel?: DeviceModelEnum;
  deviceMethod?: DeviceMonitorMethodEnum;
  deviceLanguage?: string;
  bpReadOut?: boolean;
}

export interface CellularDeviceAssignFormComponentProps extends FormType<SubmitValue> {
  deviceModel: DeviceModelEnum;
}

export const CellularDeviceAssignFormComponent = ({
  deviceModel,
  initialValues,
  onSubmit,
}: CellularDeviceAssignFormComponentProps) => {
  const deviceInfoForm = useAssignDeviceInfoForm();

  return (
    <Form
      initialValues={initialValues}
      onFinish={deviceInfoForm.handleSubmit(onSubmit)}
      layout="vertical"
      className="my20"
    >
      <h3>
        {/* Assign Patient BG Cellular Bioland */}
        Assign Patient
        {' '}
        <DeviceModelEnumComponent value={deviceModel} />
        {' '}
        Device
      </h3>
      <FormItem
        info={deviceInfoForm.getInfo('deviceId')}
        required
      >
        <DeviceSearchSelectContainer
          deviceModel={deviceModel}
        />
      </FormItem>
      <Button
        type="primary"
        htmlType="submit"
        className="mt40"
      >
        Assign Device
      </Button>
    </Form>
  );
};
