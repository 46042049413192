import { map } from 'lodash';
import { ProgramCategoryEnumComponent } from '../../../enumComponent/ProgramCategoriesComponent/ProgramCategoriesComponent';
import { ProgramCategoryEnum } from '../../../uc-api-sdk';

export const getBasicProgramCategoriesOptions = (vitals: ProgramCategoryEnum[]) => (
  map(vitals, (v) => ({
    value: v,
    label: <ProgramCategoryEnumComponent value={v} type="long" />,
  }))
);
