import { FormInstance } from 'antd/lib/form/Form';
import {
  assign,
  every,
  keys,
  map,
  omit,
  pick,
  values
} from 'lodash';
import {
  createContext, ReactNode, useCallback,
  useContext,
  useState
} from 'react';
import { ValidateErrorEntity } from '../../features/adminPortal2/types/componentTypes';
import { useDebounce } from '../../hooks/useDebounce/useDebounce';
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import { useRefState } from '../../hooks/useRefState/useRefState';

type ErrorMessage = string | 'Failed to submit';

export type NestedFormContextSubmitCallback = (
  (
    submitSent?: boolean,
    error?: ErrorMessage,
  ) => void
) | undefined;
interface SubmitOptions {
  onSubmit?: NestedFormContextSubmitCallback;
  submitValidForms?: boolean;
  forceSubmit?: boolean;
}

export interface NestedFormControlContextValue {
  handleOnSubmit: (options?: SubmitOptions) => void;
  addForm: (
    name: string,
    form: FormInstance,
  ) => void;
  cancelFormSubmitActions: () => void;
  cleanup: (
    name: string,
  ) => void;
  afterSubmit: (
    name: string,
  ) => void;
  isSubmitting: boolean;
  setFormValues: <T>(
    name: string,
    values: T,
  ) => void;
  submitInProgress: Record<string, boolean>;
  disabled?: boolean;
}

const NestedFormControlContext = createContext<
  NestedFormControlContextValue | undefined
>(undefined);

export const useNestedFormControlContext = () => {
  const context = useContext(NestedFormControlContext);
  return context as NestedFormControlContextValue;
};

export interface FormButtonsProps {
  isSubmitting: boolean;
  disabled?: boolean;
}

export interface NestedFormControlContextProviderProps<T> {
  children: ReactNode;
  disabled?: boolean;
  onAfterSubmit?: () => void;
  onFinish?: (
    values: T,
    formErrors?: ValidateErrorEntity<unknown>['errorFields'],
  ) => void;
  onAllFormValuesChange?: (values: unknown) => void;
}
export const NestedFormControlProvider = <T, >({
  children,
  disabled,
  onAfterSubmit,
  onFinish,
  onAllFormValuesChange,
}: NestedFormControlContextProviderProps<T>) => {
  const [
    getForms,
    setForms,
  ] = useRefState<Record<string, FormInstance>>({});
  const [
    getAllFormValues,
    setAllFormValues,
  ] = useRefState<Record<string, T>>({});
  const [
    submitInProgress,
    setSubmitInProgress,
  ] = useState<Record<string, boolean>>({});
  const [
    submitCallback,
    setSubmitCallback,
  ] = useState<NestedFormContextSubmitCallback>();
  const [
    isSubmitting,
    setIsSubmitting,
  ] = useState(false);

  const combineObjectValues = (object?: Record<string, unknown> | null) => (
    assign({}, ...values(object))
  );

  const handleNestedFormSubmit = async (
    formsToSubmit: ReturnType<typeof getForms>,
  ) => {
    const newSubmitProgress = assign(
      {},
      ...map(formsToSubmit, (v, k) => ({ [k]: true }))
    );
    // start submitting each form
    setSubmitInProgress(newSubmitProgress);
    map(formsToSubmit, (form: FormInstance) => form.submit());
  };

  const addForm: NestedFormControlContextValue['addForm'] = (
    name,
    form,
  ) => {
    setForms((prev) => {
      if (prev?.[name]) return prev;
      return {
        ...prev,
        [name]: form,
      };
    });
  };

  const cancelFormSubmitActions = () => {
    setIsSubmitting(false);
    setSubmitInProgress({});
  };

  const cleanup: NestedFormControlContextValue['cleanup'] = (
    name,
  ) => {
    setSubmitInProgress((prev) => omit(prev, name));
    setForms((prev) => omit(prev, name));
    setAllFormValues((prev) => omit(prev, name));
  };

  const afterSubmit: NestedFormControlContextValue['afterSubmit'] = (
    name,
  ) => {
    setSubmitInProgress((prev) => ({
      ...prev,
      [name]: false,
    }));
  };

  const setFormValues: NestedFormControlContextValue['setFormValues'] = useCallback((
    name,
    newValues,
  ) => {
    const newAllFormValues = {
      ...getAllFormValues(),
      [name]: newValues as unknown as T,
    };
    onAllFormValuesChange?.(combineObjectValues(newAllFormValues));
    setAllFormValues(newAllFormValues);
  }, []);

  const handleOnSubmit: NestedFormControlContextValue['handleOnSubmit'] = async (
    options = {},
  ) => {
    const {
      onSubmit,
      // ignore form that doesn't pass validation
      submitValidForms = true,
      // always submit validated forms even there is no change
      forceSubmit = false,
    } = options;

    let error = '';
    const forms = getForms() || {};
    if (!keys(forms).length) {
      onSubmit?.(false);
      return;
    }

    try {
      let formsToSubmit: typeof forms = forms;

      // validate forms
      const formValidationErrors = [] as ValidateErrorEntity<unknown>['errorFields'];
      await Promise.all(map(formsToSubmit, async (form, formName) => {
        try {
          await form.validateFields();
        } catch (error) {
          formValidationErrors.push(...(error as ValidateErrorEntity<unknown>).errorFields);
          // not supporting onFinishFailed, remove invalid form from submit chain
          formsToSubmit = omit(formsToSubmit, formName);
        }
      }));

      if (!submitValidForms && formValidationErrors.length) {
        onSubmit?.(false, 'Failed to submit');
        return;
      }

      // submit forms manually
      if (onFinish) {
        const formValues = combineObjectValues(getAllFormValues());
        onFinish(formValues as T, formValidationErrors);
        return;
      }

      if (!forceSubmit) {
        // only submit forms with changes
        formsToSubmit = pick(formsToSubmit, keys(getAllFormValues()));
      }

      if (formsToSubmit && keys(formsToSubmit).length) {
        if (onSubmit) setSubmitCallback(() => onSubmit);
        setIsSubmitting(true);
        handleNestedFormSubmit(formsToSubmit);
      } else {
        onSubmit?.(false);
      }
    } catch (err) {
      error = (err as Error)?.message || 'Failed to submit';
      console.error(error);
      onSubmit?.(false, error);
      setIsSubmitting(false);
    }
  };

  const debouncedHandleOnSubmit = useDebounce(
    handleOnSubmit,
    500,
    [handleNestedFormSubmit],
    { leading: true },
  );

  const contextValue = useGetContextValue<NestedFormControlContextValue>({
    handleOnSubmit: debouncedHandleOnSubmit,
    addForm,
    cancelFormSubmitActions,
    cleanup,
    afterSubmit,
    isSubmitting,
    setFormValues,
    submitInProgress,
    disabled,
  }, [
    debouncedHandleOnSubmit,
  ]);

  useDeepCompareEffect(() => {
    const isDone = (
      keys(submitInProgress).length
      && every(values(submitInProgress), (v) => !v)
    );
    if (isDone) {
      submitCallback?.(true);
      // clean up
      setIsSubmitting(false);
      setSubmitCallback(undefined);
      setAllFormValues({});
      // callback
      onAfterSubmit?.();
    }
  }, [submitInProgress]);

  return (
    <NestedFormControlContext.Provider value={contextValue}>
      {children}
    </NestedFormControlContext.Provider>
  );
};
