import React from 'react';
import { Outlet } from 'react-router-dom';
import { PreviewWarningContextProvider } from '../../../../contexts/PreviewWarningContext/PreviewWarningContext';
import { ErrorBoundaryAppComponent } from '../../../../uiComponent/ErrorBoundaryComponent/ErrorBoundaryAppComponent';
import { PreviewEnvWarningBannerComponent } from '../../../../uiComponent/PreviewEnvWarningComponent/PreviewEnvWarningBannerComponent';
import { BillerPortalSideMenuComponent } from '../BillerPortalSideMenuComponent/BillerPortalSideMenuComponent';
import { HeaderComponent } from '../../../carePortalLayout/HeaderComponent/HeaderComponent';
import { ErrorBoundaryPageComponent } from '../../../../uiComponent/ErrorBoundaryComponent/ErrorBoundaryPageComponent';

export const BillerPortalLayoutComponent = () => (
  <ErrorBoundaryAppComponent>
    <PreviewWarningContextProvider>
      <div className="biller-portal portal-layout">
        <div className="portal-header">
          <HeaderComponent isBillerPortal />
        </div>
        <PreviewEnvWarningBannerComponent />
        <div className="portal-body">
          <div className="portal-side-menu">
            <BillerPortalSideMenuComponent />
          </div>
          <div className="portal-content">
            <React.Suspense fallback="loading...">
              <ErrorBoundaryPageComponent page="BILLER_PORTAL_PAGE">
                <Outlet />
              </ErrorBoundaryPageComponent>
            </React.Suspense>
          </div>
        </div>
      </div>
    </PreviewWarningContextProvider>
  </ErrorBoundaryAppComponent>
);
