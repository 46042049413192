import { Drawer, Typography } from 'antd';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { PreviewConsentDrawerComponent } from '../../../consentForm/component/PreviewConsentComponent/PreviewConsentDrawerComponent';
import { LargeDrawerWidth } from '../../../../constants/drawer';
import { EnrollmentConsentFormContainer } from '../../container/EnrollmentConsentFormContainer/EnrollmentConsentFormContainer';

export interface EnrollmentPreviewAndResignConsentComponentProps {
  patientInfo: PatientInfo;
  onResign?: () => void;
}

export const EnrollmentPreviewAndResignConsentComponent = ({
  patientInfo,
  onResign,
}: EnrollmentPreviewAndResignConsentComponentProps) => {
  const {
    isOpen,
    open,
    close,
  } = useOpen();

  const handleOnSubmit = () => {
    close();
    onResign?.();
  };

  return (
    <>
      <PreviewConsentDrawerComponent
        patientInfo={patientInfo}
        onResignOpen={open}
      >
        <Typography.Text className="pointer" underline>
          Review consent form
        </Typography.Text>
      </PreviewConsentDrawerComponent>
      <Drawer open={isOpen} onClose={close} width={LargeDrawerWidth}>
        <EnrollmentConsentFormContainer
          patientInfo={patientInfo}
          showSentModal
          onSubmit={handleOnSubmit}
        />
      </Drawer>
    </>
  );
};
