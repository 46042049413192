import React from 'react';
import { map } from 'lodash';
import { EatingRoutineEnum } from '../../../../uc-api-sdk';
import { EatingRoutineEnumComponent } from '../../../../enumComponent/EatingRoutineEnumComponent/EatingRoutineEnumComponent';
import { InputType } from '../../../Input/types';
import { EnumSelectComponent } from '../../../../uiComponent/EnumSelectComponent/EnumSelectComponent';

export interface EatingRoutineSelectComponentProps extends
  InputType<EatingRoutineEnum> {
}

export const EatingRoutineSelectComponent = ({
  value,
  onChange,
  disabled,
}: EatingRoutineSelectComponentProps) => (
  <EnumSelectComponent
    options={map(EatingRoutineEnum)}
    optionRenderer={EatingRoutineEnumComponent}
    value={value}
    onChange={onChange}
    disable={disabled}
  />
);
