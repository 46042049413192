import { Modal } from 'antd';
import classNames from 'classnames';
import './usePhoneExistWarningModal.scss';

export interface UsePhoneExistWarningModalProps {
  querySelector?: string;
}

export const usePhoneExistWarningModal = ({
  querySelector
}: UsePhoneExistWarningModalProps) => {
  Modal.warning({
    title: null,
    content: 'The mobile number entered is already associated with other patient(s) (e. g. spouse or duplicate account). A mobile number can only be used for ONE patient for OTP login. Please set up a username and password instead, or alternatively, use a different mobile number.',
    getContainer: document.querySelector(querySelector || 'body') as HTMLElement,
    wrapClassName: classNames({
      'phone-exist-warning-modal': true,
      absolute: !!querySelector
    }),
    maskStyle: {
      ...(querySelector ? { position: 'absolute' } : {})
    }
  });
};
