import React, { FC, ReactNode } from 'react';
import { Button, Modal, Space } from 'antd';
import { useBoolean } from 'usehooks-ts';
import { ClickableDiv } from '../ClickableDiv/ClickableDiv';

export interface DiscardUnsavedChangesModalComponentProps {
  open?: boolean;
  showDiscard?: () => boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  children?: ReactNode;
}

export const DiscardUnsavedChangesModalComponent: FC<DiscardUnsavedChangesModalComponentProps> = ({
  open = false,
  showDiscard = () => true,
  onConfirm,
  onCancel,
  children,
}) => {
  const { value: isOpen, setTrue, setFalse } = useBoolean();

  const handleConfirm = () => {
    onConfirm?.();
    setFalse();
  };

  const handleCancel = () => {
    onCancel?.();
    setFalse();
  };

  const handleClick = () => {
    if (showDiscard()) {
      setTrue();
    } else {
      onConfirm?.();
    }
  };

  return (
    <>
      <ClickableDiv onClick={handleClick}>
        {children}
      </ClickableDiv>
      <Modal
        onCancel={onCancel}
        open={children ? isOpen : open}
        footer={null}
        title={null}
        width={300}
        closable={false}
        centered
      >
        <>
          <div className="b5 mb10">
            Discard unsaved changes?
          </div>
          <Space>
            <Button
              className="b5 p0"
              type="link"
              onClick={handleConfirm}
            >
              Discard
            </Button>
            <Button
              className="b5 p0"
              type="link"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Space>
        </>
      </Modal>
    </>
  );
};
