import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';

interface InsurancePlanManualInputTooltipComponentProps {
  isManualInput?: boolean;
}

export const InsurancePlanManualInputTooltipComponent = ({
  isManualInput = true
}: InsurancePlanManualInputTooltipComponentProps) => (
  isManualInput
    ? (
      <TooltipComponent
        type="info-icon"
        title="Manually input insurance plan cannot be verified"
        triggerOnHoverIcon
      />
    ) : null
);
