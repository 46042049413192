import { map } from 'lodash';
import { useMemo } from 'react';
import { BGChartMeasurement } from '../../component/BGChartComponent/BGChartHelper';
import { MeasurementHelper } from '../../helper/MeasurementHelper';
import { MeasurementType } from '../../type/MeasurementTypes';
import { BGMeasurementService } from '../../helper/BGMeasurementService';

export const useBGResultToLineChart = (data: MeasurementType[]) => {
  const processedData = useMemo(() => {
    const valueArr = map(data, (v): BGChartMeasurement => {
      const m = new BGMeasurementService(v);
      return {
        date: m.date,
        beforeMeal: m.beforeMeal,
        bloodGlucose: m.bloodGlucose.value,
        mealType: m.mealType,
        severity: m.chartSeverity,
      };
    });

    const groupedData = MeasurementHelper.groupByDate(valueArr);
    const groupedAndSortedData = map(groupedData, (v) => MeasurementHelper.sortArrayByDate(v));
    return groupedAndSortedData;
  }, [data]);

  return processedData;
};
