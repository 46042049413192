import { ReactNode, useState } from 'react';
import { PatientService } from '../../../../services/PatientService';
import { CardTextComponent } from '../../../../uiComponent/CardTypographyComponent/CardTextComponent/CardTextComponent';
import { BMIComponent } from '../../../../uiComponent/BMIComponent/BMIComponent';
import { BMRComponent } from '../../../../uiComponent/BMRComponent/BMRComponent';
import { ABWComponent } from '../../../../uiComponent/ABWComponent/ABWComponent';
import { HeightComponent } from '../../../../uiComponent/HeightComponent/HeightComponent';
import { WeightComponent } from '../../../../uiComponent/WeightComponent/WeightComponent';

import './BasicMeasuresComponent.scss';

export interface BasicMeasuresComponentProps {
  title?: ReactNode;
  patientInfoService: PatientService;
}

export const BasicMeasuresComponent = ({
  title = 'Basic Measures:',
  patientInfoService,
}: BasicMeasuresComponentProps) => {
  const [isBmiNormal, setIsBmiNormal] = useState<boolean>(false);

  const handleBmiChange = (isNormal: boolean) => {
    setIsBmiNormal(isNormal);
  };

  return (
    <div>
      <CardTextComponent size="medium">{title}</CardTextComponent>
      <div className="basic-measures">
        <HeightComponent
          height={patientInfoService.getHeightWithUnit()}
        />
        <WeightComponent
          weight={patientInfoService.getWeightWithUnit()}
        />
        <BMIComponent
          weight={patientInfoService.getWeightWithUnit()}
          height={patientInfoService.getHeightWithUnit()}
          onIsNormalChange={handleBmiChange}
        />
        <BMRComponent
          gender={patientInfoService.getGender()}
          age={patientInfoService.getAge()}
          weight={patientInfoService.getWeightWithUnit()}
          height={patientInfoService.getHeightWithUnit()}
        />
        <ABWComponent
          gender={patientInfoService.getGender()}
          weight={patientInfoService.getWeightWithUnit()}
          height={patientInfoService.getHeightWithUnit()}
          isBmiNormal={isBmiNormal}
        />
      </div>
    </div>
  );
};
