import { map } from 'lodash';
import { AssignDeviceInfo, DeviceModelEnum } from '../../../../uc-api-sdk';
import { DeviceModelEnumComponent } from '../../../../enumComponent/DeviceModelEnumComponent/DeviceModelEnumComponent';

export interface DischargeDevicesInfoComponentProps {
  dischargeDevices?: AssignDeviceInfo[];
}

export const DischargeDevicesInfoComponent = ({
  dischargeDevices,
}: DischargeDevicesInfoComponentProps) => {
  if (!dischargeDevices?.length) {
    return null;
  }
  return (
    <>
      {
        map(dischargeDevices, (device, idx) => (
          <div key={device.deviceId} className="initial">
            <DeviceModelEnumComponent
              value={device.deviceModel as DeviceModelEnum}
              type="short"
            />
            {', '}
            device ID:
            {' '}
            {device.deviceId}
            {idx !== dischargeDevices.length - 1 && '; '}
          </div>
        ))
      }
    </>
  );
};
