import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  message,
} from 'antd';
import {
  clone,
  filter,
  first,
  map,
  omit,
} from 'lodash';
import { Unpacked } from '../../lib/types';
import {
  CallCenterMakePhoneCallFunc,
  CallCenterPatientProfile, CallCenterSetAgentStateFunc,
  CallCenterStatusName,
  ConnectPatientAttributes,
  CallCenterControlPanelOpenStateType,
  CallCenterConnectionStatusType,
  CallCenterMediaStatus,
  CallCenterCallLog,
  CallLogInfo
} from './types';
import {
  getAttributeValue,
  getAvailabilityStateName,
  getConnectionStatus,
  isCallLogDone,
  parseAttribute,
  validatePhoneNumber,
} from './helpers';
import {
  CallCenterServicesProvider,
  CallCenterServicesValue,
  useCallCenterServices,
} from './services/CallCenterServicesContext';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import { StorageKeyEnum, useSessionStorage } from '../../hooks';
import { AccountHelper } from '../../helpers/account';
import { Language } from '../../generates';
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect';
import { useAuthGetLoginResponse } from '../../uc-api-sdk';
import { useMixpanelContext } from '../MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../MixpanelContext/MixpanelEvents';

export interface CallCenterContextValue {
  status: CallCenterStatusName;
  connectionStatus: CallCenterConnectionStatusType;
  controlPanelOpenState: CallCenterControlPanelOpenStateType;
  handleOpenControlPanel: () => void;
  handleCloseControlPanel: () => void;
  handleToggleControlPanel: (isMinimized?: boolean) => void;
  allCallPatientProfiles?: CallCenterPatientProfile[];
  callPatientProfile?: Unpacked<CallCenterContextValue['allCallPatientProfiles']>;
  setCallPatientProfile: (callPatientProfile: CallCenterContextValue['callPatientProfile']) => void;
  mediaStatus: CallCenterMediaStatus | undefined;
  currentContactId?: string;
  handleMakePhoneCall: CallCenterMakePhoneCallFunc;
  handleSetOnline: CallCenterSetAgentStateFunc;
  handleSetOffline: CallCenterSetAgentStateFunc;
  handleLogin: () => void;
  handleLogout: () => void;
}
const CallCenterContext = createContext<CallCenterContextValue | undefined>(undefined);

export const useCallCenterContext = () => {
  const context = useContext(CallCenterContext);
  return (context || {}) as CallCenterContextValue;
};

export interface CallCenterContextProviderProps {
  children?: ReactNode,
}
const CallCenterContextProviderChild = ({
  children,
}: CallCenterContextProviderProps) => {
  const {
    isServicesStarted,
    agentStatus,
    connectionInfo,
    promptLogin,
    logout,
    setAgentState,
    makePhoneCall,
    frameMediaDevices,
    agentStartTime,
  } = useCallCenterServices();
  const [
    status,
    setStatus,
  ] = useState<CallCenterContextValue['status']>(undefined);
  const [
    connectionStatus,
    setConnectionStatus,
  ] = useState<CallCenterContextValue['connectionStatus']>(undefined);
  const [
    controlPanelOpenState,
    setControlPanelOpenState,
  ] = useState<CallCenterContextValue['controlPanelOpenState']>('CLOSED');
  const [
    callPatientProfile,
    setCallPatientProfile,
  ] = useSessionStorage<CallCenterContextValue['callPatientProfile']>(StorageKeyEnum.CALL_PATIENT_PROFILE);
  const [
    allCallPatientProfiles,
    setAllCallPatientProfiles,
  ] = useSessionStorage<CallCenterContextValue['allCallPatientProfiles']>(StorageKeyEnum.ALL_CALL_PATIENT_PROFILES);
  const [
    mediaStatus,
    setMediaStatus,
  ] = useState<CallCenterContextValue['mediaStatus']>();
  const [callLogs, setCallLogs] = useSessionStorage<CallCenterCallLog>(StorageKeyEnum.CALL_LOGS);
  const isLoggedIn = !!agentStartTime;
  const authMeInfo = useAuthGetLoginResponse({ options: { sendOnMount: false, retry: 0 } });
  const { send: sendMixpanel } = useMixpanelContext();

  const handleLogin: CallCenterContextValue['handleLogin'] = async () => {
    // verify user's session before login
    await authMeInfo.send();
    promptLogin();
  };

  const handleLogout: CallCenterContextValue['handleLogout'] = () => {
    logout();
  };

  const handleOpenControlPanel: CallCenterContextValue['handleOpenControlPanel'] = () => {
    if (!isLoggedIn) {
      handleLogin();
      return;
    }
    setControlPanelOpenState('OPEN_MAXIMIZED');
  };

  const handleCloseControlPanel: CallCenterContextValue['handleCloseControlPanel'] = () => {
    setControlPanelOpenState('CLOSED');
  };

  const handleToggleControlPanel: CallCenterContextValue['handleToggleControlPanel'] = useCallback((
    isMinimized,
  ) => {
    if (isMinimized === true) {
      setControlPanelOpenState('MINIMIZED');
      return;
    }
    if (isMinimized === false) {
      setControlPanelOpenState('OPEN_MAXIMIZED');
      return;
    }
    const nextState: CallCenterControlPanelOpenStateType = controlPanelOpenState === 'OPEN_MAXIMIZED' ? 'MINIMIZED' : 'OPEN_MAXIMIZED';
    setControlPanelOpenState(nextState);
  }, [controlPanelOpenState]);

  const handleSetStatus = (
    newStatus: CallCenterStatusName,
  ) => {
    setStatus(newStatus);
  };

  const handleSetOnline = (cb?: Parameters<CallCenterServicesValue['setAgentState']>['1']) => {
    setAgentState('Available', cb);
  };

  const handleSetOffline = (cb?: Parameters<CallCenterServicesValue['setAgentState']>['1']) => {
    setAgentState('Offline', cb);
  };

  const handleSetPatientProfile = (
    currentPatientProfile: CallCenterContextValue['callPatientProfile'],
    allPatientProfiles = (allCallPatientProfiles || []),
  ) => {
    if (currentPatientProfile) {
      setCallPatientProfile(currentPatientProfile);
      const newAllPatientProfiles = [...allPatientProfiles];
      if (!newAllPatientProfiles.length) {
        newAllPatientProfiles.push(currentPatientProfile);
      }
      setAllCallPatientProfiles(newAllPatientProfiles);
      return;
    }
    setCallPatientProfile(undefined);
    setAllCallPatientProfiles(undefined);
  };

  const handleMakePhoneCall: CallCenterContextValue['handleMakePhoneCall'] = (
    phoneNumber,
    patientInfo,
    enrolledProgramInfo,
    cb,
  ) => {
    if (!validatePhoneNumber(phoneNumber)) {
      message.error('Invalid phone number');
      return;
    }
    if (!isLoggedIn) {
      handleLogin();
      return;
    }
    const {
      isEnrolled,
      info,
    } = enrolledProgramInfo || {};
    const patientCallInfo = {
      id: patientInfo.id as string,
      fullName: AccountHelper.getFullName(patientInfo.profile),
      enrolledProgram: {
        id: info?.id as string,
        isEnrolled,
      },
      language: first(patientInfo.profile?.languages) as Language,
      organization: {
        id: patientInfo.clinicId || undefined,
      },
    };
    sendMixpanel({
      event: MixpanelEvents.StartACall,
      patientId: patientInfo.id,
      properties: {
        isEnrolled,
        phoneNumber,
        enrolledProgramId: info?.id,
        clinicId: patientInfo.clinicId,
      }
    });
    makePhoneCall(phoneNumber, {
      success: () => {
        handleSetPatientProfile(patientCallInfo);
        cb?.success?.();
      },
      failure: (error) => {
        let msgText = 'Unknown';
        try {
          msgText = `${JSON.parse(error)?.message}`;
        } catch (e) {
          // ignore
        } finally {
          message.error(`Failed to make a call. Reason: ${msgText}`);
        }
      }
    });
  };

  useEffect(() => {
    if (!isServicesStarted) {
      handleCloseControlPanel();
      setStatus(undefined);
    }
  }, [isServicesStarted]);

  useDeepCompareEffect(() => {
    if (!agentStatus) {
      handleSetStatus(undefined);
      handleCloseControlPanel();
      return;
    }
    const { name, contacts } = agentStatus;
    const availabilityStateName = getAvailabilityStateName(name);
    if (availabilityStateName === 'Busy') {
      // notify user the current status
      setControlPanelOpenState('OPEN_MAXIMIZED');
    }
    if (!contacts || contacts.length < 1) {
      // clean up profile when agent is clear of contact
      handleSetPatientProfile(undefined);
    }
    handleSetStatus(availabilityStateName);
  }, [
    agentStatus?.name,
    agentStatus?.contacts.length,
  ]);

  useDeepCompareEffect(() => {
    if (connectionInfo?.contactInfo) {
      setConnectionStatus(getConnectionStatus(connectionInfo));
      const { contactInfo, endTime } = connectionInfo;
      const {
        isInbound,
        phoneNumber,
        isConnected,
        attributes,
      } = contactInfo;
      // call is finished
      if (endTime && !isConnected) {
        return;
      }
      let currentPatientProfile = parseAttribute(attributes) as CallCenterContextValue['callPatientProfile'];
      let parsedAllProfiles = [] as ConnectPatientAttributes[];
      try {
        parsedAllProfiles = JSON.parse(
          getAttributeValue(attributes.found) || '[]'
        ) as ConnectPatientAttributes[];
      } catch (error) {
        sendMixpanel({
          event: MixpanelEvents.CallCenterError,
          properties: {
            error,
            position: 'CallCenterContext'
          }
        });
      }
      const allPatientProfiles = parsedAllProfiles.map(parseAttribute);
      // [2368] to prevent showing profile when not found in UC db
      if (isInbound && !currentPatientProfile?.newPortal) {
        // contact is for old portal
        currentPatientProfile = { fullName: phoneNumber };
        handleSetPatientProfile(currentPatientProfile, undefined);
        return;
      }
      if (!isInbound && !callPatientProfile) {
        // if call made from CCP, it isn't fully supported to set/ retrieve call patient profile yet
        currentPatientProfile = { fullName: phoneNumber };
      } else if (callPatientProfile) { // patient profile is set prior
        if (callPatientProfile.fullName === phoneNumber) {
          setAllCallPatientProfiles(allPatientProfiles);
        }
        return;
      }
      handleSetPatientProfile(currentPatientProfile, allPatientProfiles);
    }
  }, [callPatientProfile, connectionInfo]);

  useDeepCompareEffect(() => {
    if (!frameMediaDevices) return;
    const newMediaStatus: CallCenterContextValue['mediaStatus'] = {
      audioinput: { default: [], others: [] },
      audiooutput: { default: [], others: [] },
      videoinput: { default: [], others: [] },
    };
    frameMediaDevices.forEach((mediaDevice) => {
      const { deviceId, kind } = mediaDevice;
      if (deviceId === 'default') {
        newMediaStatus[kind]?.default.push(mediaDevice);
      } else {
        newMediaStatus[kind]?.others.push(mediaDevice);
      }
    });
    setMediaStatus(newMediaStatus);
  }, [frameMediaDevices]);

  useDeepCompareEffect(() => {
    const prevCallLogs = clone(callLogs) || {};
    // === remove call log
    if (!connectionInfo) {
      // remove submitted call logs when mounted/ a call is destroyed
      const submittedContacts = filter(prevCallLogs, isCallLogDone);
      if (submittedContacts.length) {
        const submittedContactIds = map(submittedContacts, (v) => v.contactInfo.contactId);
        setCallLogs(omit(prevCallLogs, submittedContactIds));
      }
      return;
    }
    const { contactId, isConnected } = connectionInfo.contactInfo || {};
    const curCallLog = prevCallLogs[contactId] as CallLogInfo | undefined;
    // === create call log
    if (
      !curCallLog
      && isConnected
      && callPatientProfile?.id
      && callPatientProfile?.enrolledProgram?.isEnrolled
    ) {
      setCallLogs({
        ...prevCallLogs,
        [contactId]: {
          startTime: new Date(connectionInfo.startTime),
          patientProfile: callPatientProfile,
          contactInfo: connectionInfo.contactInfo,
        }
      });
    }
    // === update call log
    if (!curCallLog || isCallLogDone(curCallLog)) return;
    if (
      callPatientProfile
      && curCallLog.patientProfile.id !== callPatientProfile.id
    ) {
      // handle multiple profile case
      // switch patientProfile to selected profile
      setCallLogs({
        ...prevCallLogs,
        [contactId]: {
          ...curCallLog,
          patientProfile: callPatientProfile,
        }
      });
    }
    if (connectionInfo.endTime) {
      setCallLogs({
        ...prevCallLogs,
        [contactId]: {
          ...curCallLog,
          endTime: new Date(connectionInfo.endTime),
        }
      });
    }
  }, [callPatientProfile, connectionInfo, callLogs]);

  const contextValue = useGetContextValue<CallCenterContextValue>({
    status,
    connectionStatus,
    controlPanelOpenState,
    allCallPatientProfiles,
    callPatientProfile,
    setCallPatientProfile,
    mediaStatus,
    currentContactId: connectionInfo?.contactInfo?.contactId,
    handleMakePhoneCall,
    handleSetOnline,
    handleSetOffline,
    handleOpenControlPanel,
    handleCloseControlPanel,
    handleToggleControlPanel,
    handleLogin,
    handleLogout,
  }, [
    connectionInfo?.contactInfo?.contactId
  ]);

  return (
    <CallCenterContext.Provider value={contextValue}>
      {children}
    </CallCenterContext.Provider>
  );
};

export const CallCenterContextProvider = ({
  children,
}: CallCenterContextProviderProps) => (
  <CallCenterServicesProvider>
    <CallCenterContextProviderChild>
      {children}
    </CallCenterContextProviderChild>
  </CallCenterServicesProvider>
);
