import { Input, InputProps } from 'antd';
import { FC } from 'react';

export interface PatientFirstNameInputComponentProps extends InputProps { }

export const PatientFirstNameInputComponent: FC<PatientFirstNameInputComponentProps> = ({
  value,
  onChange,
}) => (
  <Input
    value={value}
    onChange={onChange}
  />
);
