import { useState } from 'react';
import { Button } from 'antd';
import { useBoolean } from 'usehooks-ts';
import { DeviceCheckboxComponent, DeviceCheckboxComponentProps } from '../DeviceCheckboxComponent/DeviceCheckboxComponent';
import { InputType } from '../../../Input/types';
import { BPCuffSizeModalComponent } from '../BPCuffSizeModalComponent/BPCuffSizeModalComponent';
import {
  AssignDeviceInfo,
  CuffSizeEnum,
  DeviceModelEnum,
  DeviceTypeEnum,
} from '../../../../uc-api-sdk';
import { BasicEnumComponent } from '../../../../enumComponent/BasicEnumComponent/BasicEnumComponent';

export interface BPDeviceCheckboxComponentProps extends
  InputType<AssignDeviceInfo> {
}

export const BPDeviceCheckboxComponent = ({
  value,
  onChange,
}: BPDeviceCheckboxComponentProps) => {
  const [tempValue, setTempValue] = useState<AssignDeviceInfo | undefined>(value);
  const {
    value: isOpen,
    setTrue: setOpenTrue,
    setFalse: setOpenFalse,
  } = useBoolean();

  const handleChange: DeviceCheckboxComponentProps['onChange'] = (v) => {
    if (v) {
      setTempValue(v);
      setOpenTrue();
    } else {
      onChange?.(undefined);
      setTempValue(undefined);
    }
  };

  const handleChangeCuffSize = (v: CuffSizeEnum) => {
    onChange?.({ ...(value || tempValue), cuffSize: v });
    setOpenFalse();
  };

  const extra = value?.cuffSize
    ? (
      <div>
        Cuff Size:
        {' '}
        <BasicEnumComponent value={value.cuffSize} />
        {' '}
        <Button type="link" className="pl0" onClick={setOpenTrue}>
          Edit
        </Button>
      </div>
    )
    : null;

  return (
    <>
      <DeviceCheckboxComponent
        model={DeviceModelEnum.BP3L}
        type={DeviceTypeEnum.BP}
        extra={extra}
        value={value}
        onChange={handleChange}
      />
      <BPCuffSizeModalComponent
        open={isOpen}
        onCancel={setOpenFalse}
        value={value?.cuffSize ?? undefined}
        onChange={handleChangeCuffSize}
      />
    </>
  );
};
