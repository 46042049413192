import { Table } from 'antd';
import classNames from 'classnames';
import { FC } from 'react';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { HSSummaryKey } from '../../type';
import '../vitalsTable.scss';
import { HSSummaryTableColumns } from './HSSummaryTableColumns';
import './HSSummaryTableColumns.scss';

export interface HSSummaryTableData {
  type: HSSummaryKey;
  total: number;
  average: number;
  min: number;
  max: number;
  bmi: number;
  variability: number;
}

export interface HSSummaryTableComponentProps {
  data: Partial<HSSummaryTableData>[];
}

export const HSSummaryTableComponent: FC<HSSummaryTableComponentProps> = ({
  data,
}) => {
  const columns = useTableColumns(HSSummaryTableColumns, ['type', 'average', 'variability', 'bmi', 'range']);
  return (
    <Table
      className={classNames({
        'summary-table': true,
        'vitals-un-bordered-table': true,
        'hs-vitals-table': true,
      })}
      dataSource={data}
      columns={columns}
      pagination={false}
      rowKey={(v) => v.type}
    />
  );
};
