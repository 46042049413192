import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { MixpanelProvider } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEventsParents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { StorageKeyEnum, useSessionStorage } from '../../../../hooks';
import { ROLE_ENUM } from '../../../../types/roles';
import { SiderComponent } from '../../../../uiComponent/SiderComponent/SiderComponent';
import { PatientTablesSiderHeaderComponent } from '../../../patient/component/PatientTablesSiderHeaderComponent/PatientTablesSiderHeaderComponent';
import { ProviderViewClinicPatientsContainer } from '../../container/ProviderViewClinicPatientsContainer/ProviderViewClinicPatientsContainer';
import { ProviderViewEnrolledPatientsTableContainer } from '../../container/ProviderViewEnrolledPatientsTableContainer/ProviderViewEnrolledPatientsTableContainer';
import { ProviderViewTablesSiderContainer } from '../../container/ProviderViewTablesSiderContainer/ProviderViewTablesSiderContainer';
import { ProviderViewUnenrolledPatientsTableContainer } from '../../container/ProviderViewUnenrolledPatientsTableContainer/ProviderViewUnenrolledPatientsTableContainer';
import { ProviderViewWatchedPatientsContainer } from '../../container/ProviderViewWatchedPatientsContainer/ProviderViewWatchedPatientsContainer';
import { PROVIDER_TABLE_TABS } from '../../contants';
import './ProviderViewTablesComponent.scss';

export const ProviderViewTablesComponent = () => {
  const { userId = '', doesUserRoleIncludes } = useLoggedInUserFromContext();
  const isMA = doesUserRoleIncludes([ROLE_ENUM.MA]);
  const [
    lastSelectedTable,
    setLastSelectedTable,
  ] = useSessionStorage<PROVIDER_TABLE_TABS>(
    StorageKeyEnum.PATIENTS_TABLE_LAST_SELECTED_TABLE,
    PROVIDER_TABLE_TABS.ENROLLED_PATIENTS
  );
  const [lastSelectedOrgId, setId] = useSessionStorage<string | undefined>(
    StorageKeyEnum.PROVIDER_VIEW_SELECTED_CLINIC
  );

  const renderComponent = () => {
    switch (lastSelectedTable) {
      case (PROVIDER_TABLE_TABS.ENROLLED_PATIENTS):
        return <ProviderViewEnrolledPatientsTableContainer doctorId={isMA ? undefined : userId} />;
      case (PROVIDER_TABLE_TABS.UNENROLLED_PATIENTS):
        return (
          <ProviderViewUnenrolledPatientsTableContainer
            doctorId={isMA ? undefined : userId}
          />
        );
      case (PROVIDER_TABLE_TABS.WATCHLIST):
        return <ProviderViewWatchedPatientsContainer />;
      case (PROVIDER_TABLE_TABS.CLINIC):
        return <ProviderViewClinicPatientsContainer key={lastSelectedOrgId} orgId={lastSelectedOrgId || ''} />;
      default:
        return <ProviderViewEnrolledPatientsTableContainer doctorId={isMA ? undefined : userId} />;
    }
  };

  const onClick = (tab: PROVIDER_TABLE_TABS) => {
    if (tab !== PROVIDER_TABLE_TABS.CLINIC) {
      setId(undefined);
    }
    setLastSelectedTable(tab);
  };

  return (
    <MixpanelProvider context={MixpanelEventsParents.PatientList}>
      <div className="providerViewTables flex h100">
        <div className="tableSider">
          <SiderComponent
            type="primary"
            header={<PatientTablesSiderHeaderComponent />}
            content={(
              <ProviderViewTablesSiderContainer
                currentTab={lastSelectedTable || PROVIDER_TABLE_TABS.ENROLLED_PATIENTS}
                onClick={onClick}
                onClickClinic={setId}
                lastSelectedOrgId={lastSelectedOrgId}
              />
            )}
          />
        </div>
        <div className="providerTableWrapper">
          {renderComponent()}
        </div>
      </div>
    </MixpanelProvider>
  );
};

export default ProviderViewTablesComponent;
