import { useCallback, useMemo } from 'react';
import { useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { AlertPlaceHolders } from '../constant/text';
import { GetFieldValue } from '../../../types/form';

export const useAlertForm = () => {
  const factory = useFormHookFactory({
    comment: {
      name: 'comment',
      label: '',
      emptyMessage: AlertPlaceHolders.Comment,
    },
    note: {
      name: 'note',
      label: '',
      emptyMessage: AlertPlaceHolders.Note,
    },
    noteOnly: {
      name: 'noteOnly',
      label: '',
    },
    isCommentOnly: {
      name: 'isCommentOnly',
      label: '',
    },
    alerts: {
      name: 'alerts',
      label: '',
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getSubmitButtonText = useCallback((getFieldValue: any) => (
    getFieldValue(factory.getName('isCommentOnly')) ? 'Submit' : 'Resolve'
  ), []);

  const getMedicalAlertSubmitButtonText = useCallback((getFieldValue: GetFieldValue) => (
    getFieldValue(factory.getName('noteOnly')) ? 'Submit' : 'Resolve'
  ), []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getPlaceholderText = useCallback((getFieldValue: any) => (
    getFieldValue(factory.getName('isCommentOnly')) ? AlertPlaceHolders.CommentOnly : AlertPlaceHolders.Resolve
  ), []);

  const response = useMemo(() => ({
    getSubmitButtonText,
    getPlaceholderText,
    getMedicalAlertSubmitButtonText,
    ...factory,
  }), [factory]);

  return response;
};
