import { Space } from 'antd';
import { StickyNoteMetadata } from './types';
import { UserNameTitleComponent } from '../../features/user/component/UserNameTitleComponent/UserNameTitleComponent';
import { DisplayDateComponent } from '../DisplayComponent/DisplayDateComponent';

export interface StickyNoteMetadataComponentProps extends StickyNoteMetadata {

}

export const StickyNoteMetadataComponent = ({
  updatedAt,
  updatedByUser
}: StickyNoteMetadataComponentProps) => (
  <div className="sticky-note-metadata-component">
    <Space wrap size={['small', 0]}>
      {updatedAt && (
        <DisplayDateComponent
          value={updatedAt}
          format="USA_DATE"
        />
      )}
      <UserNameTitleComponent value={updatedByUser} />
    </Space>
  </div>
);
