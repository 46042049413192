import React, { FC } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SummaryEmptyComponentProps {

}

export const SummaryEmptyComponent: FC<SummaryEmptyComponentProps> = () => (
  <div>
    N/A
  </div>
);
