import { CardInfoTextComponent } from '../../../../uiComponent/CardTypographyComponent/CardInfoTextComponent/CardInfoTextComponent';
import { DisplayDateComponent, DisplayDateComponentProps } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface PatientProfileDateDisplayComponentProps {
  value: DisplayDateComponentProps['value'];
  label: string;
  className?: string;
}

export const PatientProfileDateDisplayComponent = ({
  value,
  label,
  className = '',
}: PatientProfileDateDisplayComponentProps) => (
  <CardInfoTextComponent
    size="medium"
    className={className}
  >
    <div className="flex f-w">
      <span>
        {label}
      </span>
      <DisplayDateComponent
        value={value}
        format="USA_DATE"
        fallbackText={<DisplayOrEmptyComponent />}
        useTimezoneService
      />
    </div>
  </CardInfoTextComponent>
);
