import { upperFirst } from 'lodash';
import { BgMealTypeEnum } from '../../../uc-api-sdk';

// eslint-disable-next-line import/prefer-default-export
export class BGHelper {
  public static mmollToMgdl(value: number) {
    return +(18 * value).toFixed(2);
  }

  public static getMgdlUnit() {
    return 'mg/dL';
  }

  public static getBeforeMealText(beforeMeal: boolean) {
    return beforeMeal ? 'Before' : 'After';
  }

  public static getMealTypeText(value: BgMealTypeEnum) {
    return upperFirst(value.toLocaleLowerCase());
  }

  public static getMeasurementText(type: BgMealTypeEnum, beforeMeal: boolean) {
    const mealType = this.getMealTypeText(type);
    if (type === BgMealTypeEnum.OVERNIGHT || type === BgMealTypeEnum.BEDTIME) {
      return mealType;
    }
    return `${this.getBeforeMealText(beforeMeal)} ${mealType}`;
  }

  public static getValueUnitText(value: number, unit: string) {
    return `${value} ${unit}`;
  }
}
