import { useCallback, useMemo } from 'react';
import {
  find,
  findIndex,
  reduce
} from 'lodash';
import {
  AssignDeviceInfo,
  DeviceModelEnum,
  DeviceTypeEnum,
  VitalEnumType,
} from '../../../../uc-api-sdk';
import { InputType, InputTypeArray } from '../../../Input/types';

export const useDeviceCheckboxGroupHelper = (
  vitals: VitalEnumType[],
  value?: InputTypeArray<AssignDeviceInfo>['value'],
  onChange?: InputTypeArray<AssignDeviceInfo>['onChange'],
) => {
  const vitalsMap = useMemo(() => (
    reduce(vitals, (res, v) => {
      res[v] = true;
      return res;
    }, {} as Record<VitalEnumType, boolean>)
  ), [vitals]);

  const hasBPOnly = vitals.length === 1 && vitalsMap[VitalEnumType.BP];

  const handleChange = useCallback((
    model: DeviceModelEnum,
  ): InputType<AssignDeviceInfo>['onChange'] => (v) => {
    const newValues = [...(value || [])];
    const modelIndex = findIndex(newValues, { deviceModel: model });
    if (v && modelIndex === -1) {
      // add
      newValues.push(v);
    } else if (v) {
      // update
      newValues.splice(modelIndex, 1, v);
    } else {
      // remove device
      newValues.splice(modelIndex, 1);
    }
    onChange?.(newValues);
  }, [
    value,
    onChange,
  ]);

  const getDevice = useCallback((
    model: DeviceModelEnum,
  ) => (find(value, { deviceModel: model })), [value]);

  const doesSameDeviceTypeExist = useCallback((
    type: DeviceTypeEnum,
    model: DeviceModelEnum,
  ) => (
    !!find(value, (d: AssignDeviceInfo) => (
      d.deviceType === type && d.deviceModel !== model
    ))
  ), [
    value,
  ]);

  const helpers = useMemo(() => ({
    vitalsMap,
    hasBPOnly,
    handleChange,
    getDevice,
    doesSameDeviceTypeExist,
  }), [
    vitalsMap,
    hasBPOnly,
    handleChange,
    getDevice,
    doesSameDeviceTypeExist,
  ]);

  return helpers;
};
