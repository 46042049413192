import { Space } from 'antd';
import moment from 'moment';
import { FC, useCallback } from 'react';
import { MEDICATION_DATE_INSERT_FORMAT } from '../../../constants/timeFormat';
import { ApiRequestHelper } from '../../../helpers/ApiRequest';
import { DiscontinuedMedicationsTableComponent } from '../../../tables/MedicationsTables/DiscontinuedMedicationsTableComponent/DiscontinuedMedicationsTableComponent';
import { PrescribedMedicationsTableComponent } from '../../../tables/MedicationsTables/PrescribedMedicationsTableComponent/PrescribedMedicationsTableComponent';
import {
  MedicationStatusEnum as MedicationStatus,
  MedicationTypeEnum,
  Nullable,
  useMedicationDelete,
  useMedicationInsert,
  useMedicationUpdate,
  usePatientUpdateUcMedicationManagement
} from '../../../uc-api-sdk';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../billableTime/contants/contants';
import { useBillableTimeInterventionComp } from '../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { FormType } from '../../Input/types';
import { MedicalHistoryMedicationSectionComponent } from '../component/MedicalHistoryMedicationSectionComponent/MedicalHistoryMedicationSectionComponent';
import { MedicationManagementType } from '../component/MedicationTabFormComponent/MedicationTabFormComponent';
import { MEDICATION_STATUS } from '../constant/constant';
import { MedicationType } from '../type/type';

export interface MedicalHistoryMedicationContainerProps extends FormType<unknown>{
  memberId: string;
  visitId?: string;
  mode?: 'Edit' | 'View';
  onCancel?: () => void;
  onSubmit?: () => void;
  medicationManagement: MedicationManagementType;
  prescribedMedications: MedicationType[];
  discontinuedMedications: MedicationType[];
  onMedicationSubmit?: () => void;
}

export const MedicalHistoryMedicationContainer:
  FC<MedicalHistoryMedicationContainerProps> = ({
    memberId,
    visitId,
    mode = 'View',
    onCancel,
    onSubmit,
    medicationManagement,
    prescribedMedications,
    discontinuedMedications,
    onError,
    onValuesChange,
    formButtons,
    onMedicationSubmit,
  }) => {
    const deleteMedication = useMedicationDelete({});
    const insertMedication = useMedicationInsert({});
    const updateMedication = useMedicationUpdate({});
    const medManagementUpdate = usePatientUpdateUcMedicationManagement({});
    const { send } = useBillableTimeInterventionComp();

    const handleClickAddMedication = () => {
      send({
        patientId: memberId,
        component: BillableTimeInterventionComponentEnum.UpdateMedication,
        detail: BillableTimeInterventionDetailEnum.MedicalHistoryMedicationListAddMedication,
      });
    };

    const onDelete = useCallback(async (medId: string) => {
      const req = deleteMedication.send({
        params: {
          id: medId,
        },
      });
      await ApiRequestHelper.tryCatch(
        req,
        {
          success: MEDICATION_STATUS.deleteSuccess,
          error: MEDICATION_STATUS.error,
        },
      );
      onMedicationSubmit?.();
    }, [deleteMedication.send]);

    const onInsert = useCallback(async (value: MedicationType) => {
      const req = insertMedication.send({
        params: {
          document: {
            ...value,
            type: value.type as MedicationTypeEnum,
            status: value.status as MedicationStatus,
            startDate: value.startDate
              ? moment(value.startDate).format(MEDICATION_DATE_INSERT_FORMAT) : null,
            endDate: value.endDate
              ? moment(value.endDate).format(MEDICATION_DATE_INSERT_FORMAT) : null,
            memberId,
            active: true,
          },
        },
      });
      await ApiRequestHelper.tryCatch(
        req,
        {
          success: MEDICATION_STATUS.addSuccess,
          error: MEDICATION_STATUS.error,
        },
      );
      onMedicationSubmit?.();
    }, [memberId, insertMedication.send]);

    const onUpdate = useCallback(
      async (value: MedicationType, id: Nullable<string> | undefined) => {
        const unsets = [];
        if (!value.endDate) {
          unsets.push('endDate');
        }
        if (!value.startDate) {
          unsets.push('startDate');
        }

        const req = updateMedication.send({
          params: {
            id: id || '',
            document: {
              ...value,
              type: value.type as MedicationTypeEnum,
              status: value.status as MedicationStatus,
              startDate: value.startDate
                ? moment(value.startDate).format(MEDICATION_DATE_INSERT_FORMAT) : null,
              endDate: value.endDate
                ? moment(value.endDate).format(MEDICATION_DATE_INSERT_FORMAT) : null,
              memberId,
              id,
              active: true,
              $unset: {
                fields: unsets,
              },
            },
          },
        });
        await ApiRequestHelper.tryCatch(
          req,
          {
            success: MEDICATION_STATUS.updateSuccess,
            error: MEDICATION_STATUS.error,
          },
        );
        onMedicationSubmit?.();
      },
      [updateMedication.send, memberId],
    );

    const onSave = useCallback(async (v: MedicationManagementType) => {
      const req = medManagementUpdate.send({
        params: {
          memberId,
          request: {
            ...v,
            visitId,
          },
        },
      });
      ApiRequestHelper.tryCatch(
        req,
        {
          success: MEDICATION_STATUS.profileSuccess,
          error: MEDICATION_STATUS.error,
          onSuccess: onSubmit,
          onError,
        },
      );
    }, [memberId, medManagementUpdate.send]);

    return (
      <MedicalHistoryMedicationSectionComponent
        onDelete={onDelete}
        onInsert={onInsert}
        onUpdate={onUpdate}
        onFinish={onSave}
        onCancel={onCancel}
        initialValues={medicationManagement}
        mode={mode}
        onValuesChange={onValuesChange}
        formButtons={formButtons}
        patientId={memberId}
        onClickAddMedication={handleClickAddMedication}
        discontinuedMedications={discontinuedMedications}
        prescribedMedications={prescribedMedications}
      >
        {({ addMedication }) => (
          <Space direction="vertical" size={10}>
            {
              prescribedMedications.length > 0 ? (
                <PrescribedMedicationsTableComponent
                  medications={prescribedMedications}
                  addMedication={addMedication}
                />
              ) : <div className="flex jc-e">{addMedication}</div>
            }
            <DiscontinuedMedicationsTableComponent medications={discontinuedMedications} />
          </Space>
        )}
      </MedicalHistoryMedicationSectionComponent>
    );
  };
