import { Button, ButtonProps } from 'antd';
import { ReactNode, useCallback } from 'react';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useEmployeeRemoveFromWatch } from '../../../../uc-api-sdk';

export interface UnwatchPatientContainerProps extends ButtonProps {
  memberId: string;
  btnText?: ReactNode;
}

export const UnwatchPatientContainer = ({
  memberId,
  btnText = 'Unwatch',
  type = 'link',
  ...props
}: UnwatchPatientContainerProps) => {
  const api = useEmployeeRemoveFromWatch({});
  const watchListCountRefetch = useUpdate('WATCH_LIST_UPDATED');
  const watchListRefetch = useUpdate('GET_WATCH_LIST');
  const refetchPatient = useUpdate('PATIENT_INFO_UPDATED');
  const unwatchPatient = useCallback(
    async () => {
      const res = api.send({
        params: {
          removeRequest: {
            id: memberId,
          }
        }
      });
      await ApiRequestHelper.tryCatch(res, {
        success: 'Patient removed from watchlist',
        error: 'An error occurred. Please try again.',
        onSuccess: () => {
          refetchPatient?.updateValue();
          watchListCountRefetch?.updateValue();
          watchListRefetch?.updateValue();
        }
      });
    },
    [memberId]
  );

  return (
    <Button
      onClick={unwatchPatient}
      type={type}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {btnText}
    </Button>
  );
};
