/* eslint-disable react/jsx-props-no-spreading */
import {
  Card, Form, Space,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import classNames from 'classnames';
import { map } from 'lodash';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import { DiseaseHistoryAddAnotherConditionComponent } from '../DiseaseHistoryAddAnotherConditionComponent/DiseaseHistoryAddAnotherConditionComponent';
import { useDiseaseHistoryForm } from '../../hook/useDiseaseHistoryForm';
import { DiseaseHistoryListFieldItemComponent } from '../DiseaseHistoryListFieldItemComponent/DiseaseHistoryListFieldItemComponent';
import { ChronicDiseaseEnum, DiseaseHistoryItem } from '../../../../uc-api-sdk';

import './DiseaseHistoryFormListComponent.scss';

export const DiseaseHistoryFormListComponent = () => {
  const diseaseHistoryForm = useDiseaseHistoryForm();
  const [isAdding, setIsAdding] = useState(false);
  return (
    <div
      className={classNames({
        'disease-history-form-list': true,
        blur: !!isAdding,
      })}
    >
      <Form.List
        name={diseaseHistoryForm.getMainFormItemName()}
      >
        {
          (fields, { add, remove }) => {
            const handleAddCondition = (
              chronicDisease: string,
              associatedEnum?: ChronicDiseaseEnum,
            ) => {
              add({
                disease: associatedEnum || ChronicDiseaseEnum.CUSTOM_DISEASE,
                customDisease: associatedEnum ? undefined : chronicDisease,
                isManualInput: true,
              } as DiseaseHistoryItem);
              setIsAdding(false);
            };

            return (
              <>
                {
                  isAdding
                  && (
                    <div className="mask display-none" />
                  )
                }
                {
                  fields.map((field) => {
                    const { name: fieldName } = field;

                    const handleRemove = () => remove(fieldName);

                    return (
                      <FormItem key={fieldName} noStyle shouldUpdate>
                        {
                          ({ getFieldValue }) => {
                            const disease = (
                              diseaseHistoryForm.getListValue('disease', getFieldValue, fieldName)
                            );
                            const isRemovable = (
                              diseaseHistoryForm.isConditionRemovable(getFieldValue, fieldName)
                            );

                            return (
                              <DiseaseHistoryListFieldItemComponent
                                disease={disease}
                                field={field}
                                isRemovable={isRemovable}
                                onRemove={handleRemove}
                              />
                            );
                          }
                        }
                      </FormItem>
                    );
                  })
                }
                <LinkButton
                  onClick={() => setIsAdding(true)}
                  disabled={isAdding}
                  className="mt20 b5"
                  color="var(--uc-blue)"
                >
                  <Space>
                    <PlusOutlined />
                    Add new condition
                  </Space>
                </LinkButton>
                {
                  isAdding
                  && (
                    <FormItem noStyle shouldUpdate>
                      {
                        ({ getFieldValue }) => {
                          const diseaseHistory = (
                            getFieldValue(diseaseHistoryForm.getMainFormItemName())
                          ) as DiseaseHistoryItem[];
                          const disabledOptions = map(diseaseHistory, (disease) => {
                            if (disease.disease === ChronicDiseaseEnum.CUSTOM_DISEASE) {
                              return disease.customDisease || '';
                            }
                            return disease.disease as ChronicDiseaseEnum;
                          });
                          return (
                            <Card className="add-another-condition-card">
                              <DiseaseHistoryAddAnotherConditionComponent
                                onAdd={handleAddCondition}
                                onCancel={() => setIsAdding(false)}
                                disabledOptions={disabledOptions}
                              />
                            </Card>
                          );
                        }
                      }
                    </FormItem>
                  )
                }
              </>
            );
          }
        }
      </Form.List>
    </div>
  );
};
