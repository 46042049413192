import { MedicalOrganizationStatus } from '../../uc-api-sdk';
import EmptyComponent from '../../uiComponent/EmptyComponent/EmptyComponent';
import { BasicEnumComponent } from '../BasicEnumComponent/BasicEnumComponent';

export interface MedicalOrgStatusEnumComponentProps {
  value?: MedicalOrganizationStatus | null;
}

export const MedicalOrgStatusEnumComponent = ({
  value
}: MedicalOrgStatusEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case MedicalOrganizationStatus.ACTIVE:
      case MedicalOrganizationStatus.TERMINATED:
      case MedicalOrganizationStatus.TERMINATION_INITIATED:
      case MedicalOrganizationStatus.DELETED:
        return <BasicEnumComponent value={value} />;
      default:
        return <EmptyComponent />;
    }
  };

  return (
    <span>{getText()}</span>
  );
};
