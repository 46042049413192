import dayjs from 'dayjs';
import { DEFAULT_PAGINATION } from '../../../../constants/pagination';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { TableCacheNameEnum } from '../../../../hooks/useTableChange/useTableChange';
import {
  PageInfo,
  Patient, PatientFilter,
  PatientListTypeEnum,
  Sort$Direction,
  usePatientCount,
  usePatientPatientLimitedList,
  usePatientPatientList
} from '../../../../uc-api-sdk';
import { usePatientsTableOnChange } from '../usePatientsTableOnChange/usePatientsTableOnChange';
import { csv } from '../../../../helpers/csv';
import { convertToCSV } from '../../../../helpers/csv/patient.csv';
import { ExportDataFunction } from '../../container/PatientsTableContainer/PatientsTableContainer';
import { StorageKeyEnum, useSessionStorage } from '../../../../hooks';
import { EXPORT_DATE } from '../../../../constants/timeFormat';

export const usePatientsTableSearch = (
  type: PatientListTypeEnum,
  name?: TableCacheNameEnum | string,
  isPagination?: boolean,
  filters: PatientFilter = {},
) => {
  const defaultSorter = () => {
    switch (type) {
      case PatientListTypeEnum.ENROLLED:
        return [{ direction: Sort$Direction.DESC, property: 'patientListInfo.enrollmentDate' }];
      case PatientListTypeEnum.UNENROLLED:
        return [{ direction: Sort$Direction.DESC, property: 'createdAt' }];
      case PatientListTypeEnum.ALL:
        if (filters.patient?.filterWatchedPatient) {
          return [{ direction: Sort$Direction.DESC, property: 'watchInfo.addedTime' }];
        }
        return [{ direction: Sort$Direction.ASC, property: 'profile.firstName' }];
      case PatientListTypeEnum.SCREENING:
        return [{ direction: Sort$Direction.DESC, property: 'createdAt' }];
      default:
        return [];
    }
  };

  const tableChange = usePatientsTableOnChange(defaultSorter(), name);

  const page: PageInfo = {
    page: tableChange.pageInfo.page ?? 1,
    size: tableChange.pageInfo.size ?? DEFAULT_PAGINATION.pageSize,
    sort: (tableChange.processedSort || []),
  };
  const { patient: basePatient, assignee, ...baseRest } = filters;
  const { patient, assignee: postAssignee, ...rest } = tableChange.processedFilter || {};
  const allFilters = {
    patient: {
      ...basePatient,
      ...patient,
    },
    ...baseRest,
    ...rest,
    listType: type,
    assignee: assignee || postAssignee,
  };

  const payload = {
    options: {
      sendOnMount: true,
    },
    params: {
      searchRequest: {
        filter: allFilters,
        pageInfo: isPagination
          ? page : {
            ...page,
            pagination: false,
          },
      },
    }
  };

  const patients = usePatientPatientLimitedList(payload);

  const patientsExport = usePatientPatientList({
    ...payload,
    options: { sendOnMount: false },
  });

  const [tableName] = useSessionStorage<string | undefined>(
    StorageKeyEnum.PATIENT_TABLE_NAME
  );

  const exportData: ExportDataFunction = async (column, isProvider, totalSize) => {
    const res = await patientsExport.send({
      params: {
        searchRequest: {
          filter: allFilters,
          pageInfo: {
            ...page,
            page: 1,
            size: totalSize,
          },
        },
      },
    });
    const fileName = `${tableName}_${dayjs().format(EXPORT_DATE)}`;
    csv(convertToCSV(res?.data?.content ?? [], column, isProvider), fileName);
  };

  return {
    patients,
    tableChange,
    allFilters,
    exportData
  };
};

export interface InternalUserPatientTableCountProps extends PatientFilter {
  patientType?: 'STATUS' | 'WATCHED' | 'MY_PATIENT';
  type?: PatientListTypeEnum,
  clinicId?: string,
  needToFilter?: boolean;
  filterWatchedPatient?: boolean; // patient.filterWatchedPatient
}

export const usePatientsTableCount = ({
  patientType,
  type,
  needToFilter = false,
  filterWatchedPatient = false,
  ...patientFilters
}: InternalUserPatientTableCountProps) => {
  const patientFilter: PatientFilter = {
    listType: type,
    patientType,
    needToFilter,
    patient: {} as Patient,
    ...patientFilters
  };

  if (filterWatchedPatient) {
    (patientFilter.patient ?? {}).filterWatchedPatient = filterWatchedPatient;
  }

  const patients = usePatientCount({
    options: { sendOnMount: true },
    params: {
      patientFilter,
    },
  });
  return patients;
};

export const myAssignedFilter = () => {
  // for iHealth members only
  const {
    userInfo,
  } = useLoggedInUserFromContext();
  const filter = {
    assignee: {
      $or: {
        keys: ['assignedToRD', 'assignedToCA'],
        value: userInfo?.id,
      }
    }
  };
  return filter as PatientFilter['assignee'];
};

export const getMyAssignedPatientsCount = <T extends InternalUserPatientTableCountProps>(
  filters = myAssignedFilter() as T,
) => {
  const request = usePatientsTableCount({
    patientType: 'MY_PATIENT',
    type: PatientListTypeEnum.ENROLLED,
    ...filters,
  });
  return {
    ...(request?.data?.data || {}),
    request,
    refetch: request?.refetch,
  };
};

export const getAllPatientsCount = () => {
  const request = usePatientsTableCount({
    patientType: 'STATUS',
    type: PatientListTypeEnum.ALL,
    needToFilter: true
  });
  return {
    ...(request?.data?.data || {}),
    request,
    refetch: request?.refetch,
  };
};

export const getWatchedPatientCount = () => {
  const request = usePatientsTableCount({
    patientType: 'WATCHED',
    type: PatientListTypeEnum.ALL,
    filterWatchedPatient: true
  });
  return {
    ...(request?.data?.data || {}),
    request,
    refetch: request?.refetch,
  };
};
