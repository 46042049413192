import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { TagComponent } from '../../../../../uiComponent/TagComponent/TagComponent';

export interface LLMLabResultSameDateWarningProps { }

export const LLMLabResultSameDateWarning = () => (
  <TagComponent
    type="warning"
    background="none"
    icon={null}
    className="flex ai-c breakword pl0i"
  >
    Same lab exists for this date.
    <Tooltip
      placement="bottom"
      title="Same lab test found in the patient’s records for this date, possibly because it has been transcribed before. If the date is correct, you can still import this lab result to update any missing or incorrect data in the existing record."
    >
      <ExclamationCircleOutlined className="ml5" />
    </Tooltip>
  </TagComponent>
);
