import React, { FC, ReactNode } from 'react';

export interface FoodLogTitleValueItemComponentProps {
  title: ReactNode;
  content?: ReactNode;
}

export const FoodLogTitleValueItemComponent: FC<FoodLogTitleValueItemComponentProps> = ({
  title,
  content,
}) => (
  <div>
    <strong>{title}</strong>
    {' '}
    {content}
  </div>
);
