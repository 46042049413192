import { HsStat } from '../../../../uc-api-sdk';
import { HSSummaryKey } from '../../type/HSTypes';
import { useResultToSummaryTable } from '../useResultToSummaryTable/useResultToSummaryTable';

export const useHSResultToSummaryTable = (data: HsStat) => {
  const order: HSSummaryKey[] = [
    'overnight',
    'morning',
    'afternoon',
    'evening',
  ];

  const response = useResultToSummaryTable(data as Required<HsStat>, order);

  return response;
};
