import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useAssignDeviceInfoForm } from '../../../enrollment/hook/useAssignDeviceInfoForm/useAssignDeviceInfoForm';
import { DeviceSearchSelectContainer, DeviceSearchSelectContainerProps } from '../../container/DeviceSearchSelectContainer/DeviceSearchSelectContainer';

export interface DeviceSearchSelectFormItemComponentProps {
  deviceModel: DeviceSearchSelectContainerProps['deviceModel'];
  required?: boolean;
}

export const DeviceSearchSelectFormItemComponent = ({
  deviceModel,
  required,
}: DeviceSearchSelectFormItemComponentProps) => {
  const assignDeviceInfoForm = useAssignDeviceInfoForm();

  return (
    <FormItem
      name={assignDeviceInfoForm.getName('deviceId')}
      label={assignDeviceInfoForm.getLabel('deviceId')}
      required={required}
    >
      <DeviceSearchSelectContainer
        deviceModel={deviceModel}
      />
    </FormItem>
  );
};
