import React from 'react';
import { map } from 'lodash';
import { ActivityLevelEnum } from '../../../../uc-api-sdk';
import { ActivityLevelEnumComponent } from '../../../../enumComponent/ActivityLevelEnumComponent/ActivityLevelEnumComponent';
import { InputType } from '../../../Input/types';
import { EnumSelectComponent } from '../../../../uiComponent/EnumSelectComponent/EnumSelectComponent';

export interface PhysicalActivityLevelSelectComponentProps extends
  InputType<ActivityLevelEnum> {
}

export const PhysicalActivityLevelSelectComponent = ({
  value,
  onChange,
  disabled,
}: PhysicalActivityLevelSelectComponentProps) => (
  <EnumSelectComponent
    options={map(ActivityLevelEnum)}
    optionRenderer={ActivityLevelEnumComponent}
    value={value}
    onChange={onChange}
    disable={disabled}
  />
);
