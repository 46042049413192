import classNames from 'classnames';
import { ReactNode } from 'react';

export interface CardHeaderExtraComponentProps {
  children: ReactNode;
  className?: string;
}

export const CardHeaderExtraComponent = ({
  children,
  className = '',
}: CardHeaderExtraComponentProps) => (
  <div
    className={classNames({
      flex: true,
      f1: true,
      'jc-e': true,
      [className]: !!className,
    })}
  >
    {children}
  </div>
);
