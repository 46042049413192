import { Space, Typography } from 'antd';
import { useDeepCompareCallback } from '../../../../hooks/useDeepCompareEffect';
import EmployeeInfo from '../../../../hooks/useUserInfo/employeeInfo';
import { PatientComplexityEnum, LimitedUser } from '../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { ComplexityTag } from '../ComplexityTag/ComplexityTag';
import { ComplexLevelTooltipComponent } from '../../../../uiComponent/ComplexLevelTooltipComponent/ComplexLevelTooltipComponent';

export interface ComplexityComponentProps {
  complexityLevel?: PatientComplexityEnum;
  updatedByUser?: LimitedUser;
  className?: string;
}

export const ComplexityComponent = ({
  complexityLevel = PatientComplexityEnum.NULL_CODES,
  updatedByUser = {},
  className = '',
}: ComplexityComponentProps) => {
  const renderUpdatedByUser = useDeepCompareCallback(() => {
    if (!Object.keys(updatedByUser).length) return null;
    const userFullNameWithTitle = EmployeeInfo.getFullNameWithTitle(updatedByUser);
    return (
      <div className="ml5">
        Changed by
        {' '}
        <DisplayOrEmptyComponent value={userFullNameWithTitle} />
      </div>
    );
  }, [updatedByUser]);

  return (
    <Space className={className}>
      <Typography.Text strong>
        <Space>
          <span>Complexity Level</span>
          <div className="flex gap0">
            <ComplexLevelTooltipComponent />
            {': '}
          </div>
        </Space>
      </Typography.Text>
      <ComplexityTag
        level={complexityLevel}
      />
      {renderUpdatedByUser()}
    </Space>
  );
};
