import { useCallback } from 'react';
import { Button } from 'antd';
import {
  useEmployeeResetPassword,
  useEmployeeSetUserPassword,
  usePatientUpdatePassword,
} from '../../uc-api-sdk';
import { ApiRequestHelper } from '../../helpers/ApiRequest';
import { useDebounce } from '../../hooks/useDebounce/useDebounce';
import { logWarn } from '../../lib/helpers/logging';
import PasswordFormComponent, { PasswordFormComponentProps } from '../../uiComponent/PasswordFormComponent/PasswordFormComponent';
import { useLoggedInUserFromContext } from '../../contexts/loggedInUserContext';

type UserType = 'PATIENT' | 'EMPLOYEE';

export interface ChangePasswordContainerChildrenProps {
  onSubmit: (newPass: string) => void;
}

export interface ChangePasswordContainerProps
  extends Omit<PasswordFormComponentProps, 'children' | 'onSubmit'> {
  userType: UserType;
  userId: string;
  afterSubmit?: (newToken: string) => void;
  onError?: (error: Error) => void;
  children?: (props: ChangePasswordContainerChildrenProps) => JSX.Element;
  submitText?: string;
  resetPasswordToken?: string;
}

export const ChangePasswordContainer = ({
  userType,
  userId,
  afterSubmit,
  onError,
  children,
  submitText = 'Update Password',
  resetPasswordToken,
  ...passwordFormProps
}: ChangePasswordContainerProps) => {
  const { token = '' } = useLoggedInUserFromContext() || {};
  const resetPatientPasswordInfo = usePatientUpdatePassword({});
  const setEmployeePasswordInfo = useEmployeeSetUserPassword({});
  const resetEmployeePasswordInfo = useEmployeeResetPassword({});

  const updatePassword = useCallback((newPass: string) => {
    const isPatient = userType === 'PATIENT';
    if (isPatient) {
      return resetPatientPasswordInfo.send({
        params: {
          memberId: userId,
          spInput: { newPass },
        },
      });
    }
    if (userId && resetPasswordToken) {
      return resetEmployeePasswordInfo.send({
        params: {
          rpInput: {
            token: resetPasswordToken,
            newPass,
          }
        },
      });
    }
    if (userId && token) {
      return setEmployeePasswordInfo.send({
        params: {
          spInput: {
            id: userId,
            newPass,
          },
        },
      });
    }
    return undefined;
  }, [userType, userId, resetPasswordToken, token]);

  const onSubmit = useDebounce(async (newPass: string) => {
    try {
      const res = await updatePassword(newPass);
      ApiRequestHelper.throwIfError(res);
      const newToken = res?.data?.token || '';
      afterSubmit?.(newToken);
    } catch (error) {
      if (onError) {
        onError(error as Error);
        return;
      }
      ApiRequestHelper.showError(error);
    }
  }, 500, [updatePassword]);

  if (!userId) {
    logWarn('Invalid user id');
    return null;
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        children
          ? children({ onSubmit })
          : (
            <PasswordFormComponent
              onSubmit={onSubmit}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...passwordFormProps}
            >
              <PasswordFormComponent.Footer>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="change-password-submit-btn"
                >
                  {submitText}
                </Button>
              </PasswordFormComponent.Footer>
            </PasswordFormComponent>
          )
      }
    </>
  );
};
