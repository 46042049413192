import { Form } from 'antd';
import { DiseaseHistorySubmitValue, useDiseaseHistoryForm } from '../../hook/useDiseaseHistoryForm';
import { DiseaseHistoryFormListComponent } from '../DiseaseHistoryFormListComponent/DiseaseHistoryFormListComponent';
import { FormType } from '../../../Input/types';
import { LeaveNoteFormItemComponent } from '../../../../uiComponent/LeaveNoteFormItemComponent/LeaveNoteFormItemComponent';

export interface DiseaseHistoryFormComponentProps extends FormType<DiseaseHistorySubmitValue> {
  onSubmit?: (values: DiseaseHistorySubmitValue) => void;
  disabled?: boolean;
}

export const DiseaseHistoryFormComponent = ({
  initialValues,
  onSubmit,
  isLoading,
  disabled,
  onValuesChange,
  formButtons,
}: DiseaseHistoryFormComponentProps) => {
  const diseaseHistoryForm = useDiseaseHistoryForm();
  const handleSubmit = (values: DiseaseHistorySubmitValue) => {
    onSubmit?.(values);
  };

  return (
    <Form
      layout="vertical"
      form={diseaseHistoryForm.form}
      onFinish={diseaseHistoryForm.handleSubmit(handleSubmit)}
      disabled={diseaseHistoryForm.formDisabled || disabled || isLoading}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
    >
      <DiseaseHistoryFormListComponent />
      <LeaveNoteFormItemComponent
        className="my10"
        name={diseaseHistoryForm.getName('diseaseHistoryNote')}
      />
      {formButtons}
    </Form>
  );
};
