import { message } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MONTH_DATE_YEAR } from '../../../../constants/timeFormat';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents, MixpanelEventsParents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useMpTrackingHelper } from '../../../../hooks/useMpTrackingHelper/useMpTrackingHelper';
import {
  Note,
  TranscribeStatusEnum,
  useLabResultInsert,
  useTranscribeAddNote,
  useTranscribeGetA1CTestWindow,
  useTranscribeUpdate
} from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { HemoglobinA1cTemplateId } from '../../constants';
import {
  A1CSubmitValue,
  TranscribeFormA1CComponent,
} from './TranscribeA1CFormComponent';
import { useLabResultHelper } from '../../hook/useLabResultHelper';

interface TranscribeFormContainerProps {
  initialValues?: Partial<A1CSubmitValue>;
  patientId: string;
  transcribingId: string;
  initialNotes: Note[];
  initialStatus?: TranscribeStatusEnum | null;
  onValidSubmit?: () => void;
}

export const TranscribeFormContainer = ({
  initialValues,
  patientId,
  transcribingId,
  initialNotes,
  initialStatus,
  onValidSubmit,
}: TranscribeFormContainerProps) => {
  const { send: sendMixpanel } = useMixpanelContext();
  const { updateValue } = useUpdate('A1C_UPDATED');
  const updateTransHook = useUpdate('TRANSCRIBE_FINISHED');
  const updateNoteHook = useUpdate('TRANSCRIBE_NOTE_UPDATED');
  const { send } = useBillableTimeInterventionComp();
  const location = useLocation();
  const { startEvent, endEvent } = useMpTrackingHelper({
    eventStart: MixpanelEvents.A1cTranscribingStart,
    eventEnd: MixpanelEvents.A1cTranscribingEnd,
    parent: location.pathname.includes('care-portal/utilities/transcribe')
      ? MixpanelEventsParents.Transcribing
      : MixpanelEventsParents.PatientProfile,
    patientId,
  });
  const { parseCreateLabResultItem } = useLabResultHelper();

  useEffect(() => {
    startEvent();
  }, []);

  // get a1c test window from backend
  const fetchA1CTestWindow = useTranscribeGetA1CTestWindow({
    params: {
      patientId,
    },
  });

  // if result is valid
  const updateTranscribeInfo = useTranscribeUpdate({});

  const completeTranscribe = async (type: TranscribeStatusEnum) => {
    try {
      await updateTranscribeInfo.send({
        params: {
          id: transcribingId,
          document: {
            status: type,
          },
        },
      });
      updateTransHook.updateValue();
    } catch (error) {
      message.error('Failed to complete transcribe.');
    }
  };

  // submit a1c result to backend
  const insertA1CLabResult = useLabResultInsert({});
  const handleA1CResultAdd = async (testResult: A1CSubmitValue, isWithinValidRange: boolean) => {
    sendMixpanel({ event: MixpanelEvents.TranscribingAddA1C });
    endEvent();
    send({
      patientId,
      component: BillableTimeInterventionComponentEnum.Transcribing,
      detail: BillableTimeInterventionDetailEnum.TranscribingA1CTranscribeAdd,
    });
    const req = insertA1CLabResult.send({
      params: {
        document: parseCreateLabResultItem(patientId, {
          lab: {
            templateName: 'Hemoglobin A1c',
            templateId: HemoglobinA1cTemplateId,
          },
          testDate: dayjs(testResult.testDate.valueOf()),
          item: [
            {
              name: 'Hemoglobin A1c',
              unit: '%',
              value: testResult.testResult,
              isInHouse: testResult.isInHouse,
            }
          ]
        })
      },
    });
    ApiRequestHelper.tryCatch(
      req,
      {
        onSuccess: () => {
          if (isWithinValidRange) {
            completeTranscribe(TranscribeStatusEnum.COMPLETE);
            onValidSubmit?.();
          }
          updateValue();
        },
        success: '',
        error: 'Failed to submit A1C lab result.'
      }
    );
  };

  // submit no a1c note to backend
  const [note, setNote] = useState<Note[]>([]);
  const noA1CNoteInfo = useTranscribeAddNote({});

  const handleNoA1CSubmit = async (value: string) => {
    ApiRequestHelper.tryCatch(
      noA1CNoteInfo.send({
        params: {
          id: transcribingId,
          note: value,
        },
      }),
      {
        success: 'Note has been submitted.',
        error: 'Failed to submit note.',
        onSuccess: (res) => {
          setNote(res?.data || []);
          completeTranscribe(TranscribeStatusEnum.NO_RECORD);
          updateNoteHook.updateValue();
        }
      }
    );
  };

  return (
    <FetchComponent info={fetchA1CTestWindow}>
      {
        (value) => {
          const data = value?.rawValue?.data || {};
          const compliantTestWindowStartDay = data.startDate
            ? dayjs(data.startDate).format(MONTH_DATE_YEAR)
            : undefined;
          const compliantTestWindowEndDay = data.startDate
            ? dayjs(data.endDate).format(MONTH_DATE_YEAR)
            : undefined;
          return (
            <TranscribeFormA1CComponent
              onSubmit={handleA1CResultAdd}
              onNoA1CSubmit={handleNoA1CSubmit}
              initialValues={initialValues}
              compliantTestWindowStartDay={compliantTestWindowStartDay ?? undefined}
              compliantTestWindowEndDay={compliantTestWindowEndDay ?? undefined}
              patientId={patientId}
              notes={note.length ? note : initialNotes}
              hasNoA1CRecord={initialStatus === TranscribeStatusEnum.NO_RECORD}
            />
          );
        }
      }
    </FetchComponent>
  );
};
