import { logoutCCP } from '../../../contexts/CallCenterContext/services/CallCenterServicesContext';
import { useLiveX } from '../../../hooks/useLiveX/useLiveX';
import { useAuthLogout } from '../../../uc-api-sdk';

export const useLogout = () => {
  const logoutInfo = useAuthLogout({ options: { sendOnMount: false, retry: 0 } });
  const { clearConversations } = useLiveX();

  const onLogout = async (notifyServer?: boolean) => {
    try {
      if (notifyServer) {
        await logoutInfo.send({ params: {} });
      }
    } catch (error) {
      console.error(error);
    } finally {
      // logout AWS connect
      logoutCCP();
      // clean sessionStorage
      sessionStorage.clear();
      // clear LiveX conversations
      clearConversations();
      window.dispatchEvent(new Event('session-storage'));
    }
  };

  return {
    onLogout,
  };
};
