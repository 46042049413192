import { ReactNode, useState } from 'react';
import { Button, Input } from 'antd';
import { LinkButton } from '../LinkButton/LinkButton';
import { TextComponent } from '../TextComponent/TextComponent';
import { FaceRateComponent } from '../FaceRateComponent/FaceRateComponent';

export interface FeedbackComponentProps {
  title?: ReactNode;
  ratingQuestion: ReactNode;
  commentQuestion: ReactNode;
  commentPlaceholder?: string;
  optionalComment?: boolean;
  onSubmit: (rating?: number, comment?: string) => void;
  onSkip: () => void;
}

export const FeedbackComponent = ({
  title = 'Feedback',
  ratingQuestion = 'How was your experience?',
  commentQuestion = 'Was there any issue or anything can be improved?',
  commentPlaceholder,
  optionalComment = true,
  onSubmit,
  onSkip,
}: FeedbackComponentProps) => {
  const [rating, setRating] = useState<number | undefined>(undefined);
  const [comment, setComment] = useState<string | undefined>('');

  const handleOnSubmit = () => {
    onSubmit(rating, comment);
  };

  return (
    <div className="flex fd-c gap2 ai-c feedback-component">
      <TextComponent bold size="xx-large" className="w100 ta-c mb20">
        {title}
      </TextComponent>
      <div className="w100 feedback-component--rating">
        <div className="mb5 feedback-component--rating--question">
          {ratingQuestion}
        </div>
        <div className="flex jc-c w100 my30 px100">
          <FaceRateComponent
            value={rating}
            onChange={setRating}
          />
        </div>
      </div>
      <div className="w100 feedback-component--comment">
        <div className="mb5 feedback-component--comment--question">
          {commentQuestion}
        </div>
        <div className="my12">
          <Input.TextArea
            onChange={(e) => setComment(e.target.value)}
            placeholder={commentPlaceholder}
            autoSize={{ minRows: 5, maxRows: 5 }}
          />
        </div>
      </div>
      <div className="flex fd-c ai-c mt30 feedback-component--rating--buttons">
        <Button
          type="primary"
          className="w100px"
          onClick={handleOnSubmit}
          disabled={(
            rating === undefined
            || (!optionalComment && !comment)
          )}
        >
          Submit
        </Button>
        <LinkButton
          underlineOnHover
          onClick={onSkip}
        >
          Skip
        </LinkButton>
      </div>
    </div>
  );
};
