import { RcFile } from 'antd/lib/upload';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';

export interface MessagePDFPreviewComponentProps {
  file: RcFile;
}

export const MessagePDFPreviewComponent = ({
  file,
}: MessagePDFPreviewComponentProps) => {
  const fileName = file.name;
  return (
    <TooltipComponent
      title={fileName}
      placement="top"
      overlayClassName="pdf-preview-tooltip"
      type={null}
    >
      <div className="pdf-preview__wrapper">
        <span className="pdf-preview__file-name">
          {fileName}
        </span>
      </div>
    </TooltipComponent>
  );
};
