import { DeviceCheckboxComponent } from '../DeviceCheckboxComponent/DeviceCheckboxComponent';
import { InputTypeArray } from '../../../Input/types';
import {
  AssignDeviceInfo,
  DeviceModelEnum,
  DeviceTypeEnum,
  VitalEnumType,
} from '../../../../uc-api-sdk';
import { BPDeviceCheckboxComponent } from '../BPDeviceCheckboxComponent/BPDeviceCheckboxComponent';
import { useDeviceCheckboxGroupHelper } from '../../hook/useDeviceCheckboxGroupHelper/useDeviceCheckboxGroupHelper';

export interface DevicesCheckboxGroupComponentProps extends
  Partial<InputTypeArray<AssignDeviceInfo>> {
  vitals: VitalEnumType[];
}

export const DevicesCheckboxGroupComponent = ({
  value,
  onChange,
  vitals,
}: DevicesCheckboxGroupComponentProps) => {
  const {
    vitalsMap,
    getDevice,
    handleChange,
  } = useDeviceCheckboxGroupHelper(vitals, value, onChange);

  return (
    <>
      {(vitalsMap[VitalEnumType.BP]) && (
        <BPDeviceCheckboxComponent
          key={DeviceModelEnum.BP3L}
          value={getDevice(DeviceModelEnum.BP3L)}
          onChange={handleChange(DeviceModelEnum.BP3L)}
        />
      )}
      {vitalsMap[VitalEnumType.BG] && (
        <DeviceCheckboxComponent
          key={DeviceModelEnum.BG5S}
          model={DeviceModelEnum.BG5S}
          type={DeviceTypeEnum.BG}
          value={getDevice(DeviceModelEnum.BG5S)}
          onChange={handleChange(DeviceModelEnum.BG5S)}
        />
      )}
      {vitalsMap[VitalEnumType.HS] && (
        <DeviceCheckboxComponent
          key={DeviceModelEnum.HS2S}
          model={DeviceModelEnum.HS2S}
          type={DeviceTypeEnum.HS}
          value={getDevice(DeviceModelEnum.HS2S)}
          onChange={handleChange(DeviceModelEnum.HS2S)}
        />
      )}
      {vitalsMap[VitalEnumType.PO] && (
        <DeviceCheckboxComponent
          key={DeviceModelEnum.PO3}
          model={DeviceModelEnum.PO3}
          type={DeviceTypeEnum.PO}
          value={getDevice(DeviceModelEnum.PO3)}
          onChange={handleChange(DeviceModelEnum.PO3)}
        />
      )}
    </>
  );
};
