import React from 'react';
import { ColumnType } from 'antd/lib/table';
import { Input } from 'antd';
import _ from 'lodash';
import { IntakeTableProps } from '../NutritionFormComponent/NutritionFormComponent';
import FormItem from '../../../../../uiComponent/FormItem/FormItem';

export const intakeFormTableColumns = ({
  meal: () : ColumnType<IntakeTableProps> => ({
    title: 'Meal',
    dataIndex: 'meal',
    render: obj => (
      <div>
        {_.capitalize(obj || '')}
      </div>
    ),
  }),
  timeRange: (): ColumnType<IntakeTableProps> => ({
    title: 'Time Range',
    dataIndex: 'timeRange',
    render: (text, record) => (
      <FormItem name={`${record.meal}_timeRange`}>
        <Input.TextArea
          placeholder="Enter here"
          autoSize={{ minRows: 1, maxRows: 4 }}
          defaultValue={text}
        />
      </FormItem>
    ),
  }),
  foodTypeAmount: (): ColumnType<IntakeTableProps> => ({
    title: 'Food Type & Amount',
    dataIndex: 'foodTypeAmount',
    render: (text, record) => (
      <FormItem name={`${record.meal}_foodTypeAmount`}>
        <Input.TextArea
          placeholder="Enter here"
          autoSize={{ minRows: 1, maxRows: 4 }}
          defaultValue={text}
        />
      </FormItem>
    ),
  }),
  mealFreq: (): ColumnType<IntakeTableProps> => ({
    title: 'Frequency',
    dataIndex: 'mealFreq',
    align: 'center',
    render: (text, record) => (
      <FormItem name={`${record.meal}_mealFreq`}>
        <Input.TextArea
          placeholder="eg: 4X/wk"
          autoSize={{ minRows: 1, maxRows: 4 }}
          defaultValue={text}
        />
      </FormItem>
    ),
  }),
  skipFreq: (): ColumnType<IntakeTableProps> => ({
    title: 'Skip',
    dataIndex: 'skipFreq',
    align: 'center',
    render: (text, record) => (
      <FormItem name={`${record.meal}_skipFreq`}>
        <Input.TextArea
          placeholder="eg: 4X/wk"
          autoSize={{ minRows: 1, maxRows: 4 }}
          defaultValue={text}
        />
      </FormItem>
    ),
  }),
});
