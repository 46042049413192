/* eslint-disable react/jsx-props-no-spreading */
import {
  Checkbox,
  DatePicker,
  FormItemProps,
  Input,
  Tooltip,
} from 'antd';
import { ReactNode } from 'react';
import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import FormItem from '../../../uiComponent/FormItem/FormItem';
import { useLLMLabResultForm } from './hook/useLLMLabResultForm';
import { MMDDYY } from '../../../constants/timeFormat';
import { AntdEllipsisComponent } from '../../../uiComponent/AntdEllipsisComponent/AntdEllipsisComponent';
import { HTMLDisplayComponent } from '../../../uiComponent/HTMLDisplayComponent/HTMLDisplayComponent';
import { LLMLabResultTableItem } from './type';

import '../../transcribing/component/LabResultDetailTableComponent/LabResultDetailTableComponent.scss';
import { LLMLabResultSameDateWarning } from './component/LLMLabResultSameDateWarning/LLMLabResultSameDateWarning';
import { MissingErrorComponent } from '../../../uiComponent/MissingErrorComponent/MissingErrorComponent';
import { HemoglobinA1cTemplateId } from '../../transcribing/constants';

export const MISSING_ERROR_CLSNAME = 'llm-missing-error';

export const createLLMLabResultColumns = () => {
  const llmLabResultForm = useLLMLabResultForm();
  const makeErrorValidateStatus = (
    isError: boolean,
    message: ReactNode = <MissingErrorComponent className={MISSING_ERROR_CLSNAME} />,
  ) => (
    isError
      ? {
        required: true,
        validateStatus: 'error',
        help: message
      } as FormItemProps
      : {}
  );

  const makeDuplicateDateCollectedValidateStatus = (
    {
      isChecked,
      duplicate,
      conflict,
      value,
    }: LLMLabResultTableItem = {},
  ) => (
    (isChecked && value && (duplicate || conflict))
      ? {
        validateStatus: 'warning',
        help: <LLMLabResultSameDateWarning />
      } as FormItemProps
      : {}
  );

  const makeCloseAttentionValidateStatus = (
    {
      isChecked,
      unitMismatch,
    }: LLMLabResultTableItem = {},
  ) => (
    isChecked
      && unitMismatch
      ? {
        validateStatus: 'warning',
        help: 'Attention required'
      } as FormItemProps
      : {}
  );

  const makeExistingValueInfo = (
    {
      dateCollected,
      existingValue,
      value,
      unit,
      isChecked,
    }: LLMLabResultTableItem = {},
  ) => {
    const isDuplicate = (
      existingValue
      && existingValue === value
    );
    const validateStatus = isDuplicate ? 'default' : 'warning';
    return (
      isChecked
        && dateCollected
        && existingValue
        ? {
          validateStatus,
          help: (
            <>
              Existing record:
              {' '}
              {existingValue}
              {' '}
              <HTMLDisplayComponent
                htmlString={unit}
                nowrap
              />
            </>
          )
        } as FormItemProps
        : {}
    );
  };

  const renderValueInputTooltipText = (
    record: LLMLabResultTableItem
  ) => {
    const {
      existingValue,
      value,
      unitMismatch,
      processedUnit,
    } = record;
    const isConflict = (
      existingValue
      && existingValue !== value
    );
    if (isConflict) {
      return 'This value differs from the existing record. Please verify if the AI-processed value is accurate, and input the correct value here.';
    }
    if (unitMismatch) {
      return `The unit for the AI-processed result is: "${value} ${processedUnit}", which differs from the unit used in portal. Please verify against the source file. `;
    }
    return undefined;
  };

  return {
    rowSelection: (): ColumnType<LLMLabResultTableItem> => ({
      key: llmLabResultForm.getName('isChecked'),
      title: llmLabResultForm.getLabel('isChecked'),
      width: 30,
      render: (value, record, idx) => (
        <FormItem
          name={llmLabResultForm.makeObjectNamePath('isChecked', idx)}
          valuePropName="checked"
        >
          <Checkbox />
        </FormItem>
      ),
    }),
    name: (): ColumnType<LLMLabResultTableItem> => ({
      key: llmLabResultForm.getName('name'),
      title: llmLabResultForm.getLabel('name'),
      width: '50%',
      render: (value, record) => (
        <AntdEllipsisComponent>
          {record.name}
        </AntdEllipsisComponent>
      ),
      ellipsis: true,
      sorter: (a, b) => (a.name?.localeCompare(b.name ?? '') ?? 0),
    }),
    dateCollected: (): ColumnType<LLMLabResultTableItem> => ({
      key: llmLabResultForm.getName('dateCollected'),
      title: llmLabResultForm.getLabel('dateCollected'),
      width: 177,
      render: (value, record, idx) => (
        <FormItem
          name={llmLabResultForm.makeObjectNamePath('dateCollected', idx)}
          {...makeDuplicateDateCollectedValidateStatus(record)}
          {...makeErrorValidateStatus(!!record.isChecked && !record.dateCollected)}
        >
          <DatePicker
            format={MMDDYY}
          />
        </FormItem>
      ),
      ellipsis: true,
      sorter: (a, b) => dayjs(a.dateCollected).valueOf() - dayjs(b.dateCollected).valueOf(),
    }),
    isInHouse: (): ColumnType<LLMLabResultTableItem> => ({
      key: llmLabResultForm.getName('isInHouse'),
      title: llmLabResultForm.getLabel('isInHouse'),
      width: 80,
      render: (value, record, idx) => (
        <FormItem
          name={llmLabResultForm.makeObjectNamePath('isInHouse', idx)}
          valuePropName="checked"
          className="ta-c"
        >
          <Checkbox />
        </FormItem>
      ),
    }),
    value: (): ColumnType<LLMLabResultTableItem> => ({
      key: llmLabResultForm.getName('value'),
      title: llmLabResultForm.getLabel('value'),
      render: (value, record, idx) => (
        <Tooltip
          trigger="click"
          title={renderValueInputTooltipText(record)}
          destroyTooltipOnHide
        >
          <FormItem
            name={llmLabResultForm.makeObjectNamePath('value', idx)}
            initialValue={record.value}
            {...makeCloseAttentionValidateStatus(record)}
            // showing existing value has more priority than others
            {...makeExistingValueInfo(record)}
            {...makeErrorValidateStatus(
              record.templateId === HemoglobinA1cTemplateId
              && !!record.isChecked
              && !record.value,
            )}
          >
            <Input
              suffix={
                (
                  <div className="lab-result-unit">
                    <HTMLDisplayComponent htmlString={record.unit} />
                  </div>
                )
              }
            />
          </FormItem>
        </Tooltip>
      ),
    }),
  };
};
