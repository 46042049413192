import { ReactNode } from 'react';
import classNames from 'classnames';
import { Divider } from 'antd';
import './MessageChannelSectionHrComponent.scss';

export interface MessageChannelSectionHrComponentProps {
  children: ReactNode;
  showDivider?: boolean;
}

export const MessageChannelSectionHrComponent = ({
  children,
  showDivider = true,
}: MessageChannelSectionHrComponentProps) => (
  <div
    className={classNames({
      'message-channel-section-hr-component': true,
      'no-divider flex jc-c ai-c': !showDivider,
    })}
  >
    {
      showDivider
        ? <Divider>{children}</Divider>
        : children
    }
  </div>
);
