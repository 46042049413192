import { Checkbox, CheckboxProps } from 'antd';

export interface PatientIsTestUserCheckboxComponentProps
  extends CheckboxProps { }
export const PatientIsTestUserCheckboxComponent = (
  props: PatientIsTestUserCheckboxComponentProps,
) => (
  <Checkbox
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    It's a test patient
  </Checkbox>
);
