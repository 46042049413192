import { InfoCircleFilled } from '@ant-design/icons';
import { Space } from 'antd';
import { WorklistStepEnumComponent } from '../../../../enumComponent/WorklistStepEnumComponent/WorklistStepEnumComponent';
import { WorklistStepEnum } from '../WorklistDrawerComponent/WorklistDrawerComponent';

export interface WorklistStepTitleComponentProps {
  step: WorklistStepEnum;
  showRequiredMark?: boolean;
}

export const WorklistStepTitleComponent = ({
  step,
  showRequiredMark,
}: WorklistStepTitleComponentProps) => (
  <div className="flex gap3 ai-c">
    <WorklistStepEnumComponent value={step} />
    {
      showRequiredMark
      && (
        <Space>
          <InfoCircleFilled className="danger-svg" />
          <div className="text-red">Missing!</div>
        </Space>
      )
    }
  </div>
);
