import { ReactNode } from 'react';
import { split } from 'lodash';
import { HomeUrl } from '../../../../../hooks/useChangePage/useChangePage';
import { ClickableDiv } from '../../../../../uiComponent/ClickableDiv/ClickableDiv';
import { Icons } from '../../../../../icons/Icons';
import { useRouter } from '../../../../../hooks/useRouter/useRouter';

export interface LoggedInUserSettingsComponentProps {
  children?: ReactNode;
  onClick?: () => void;
  icon?: ReactNode,
}

export const LoggedInUserSettingsComponent = ({
  children,
  onClick,
  icon = <Icons.Settings hexColor="#000" />,
}: LoggedInUserSettingsComponentProps) => {
  const { pathname, changePage } = useRouter();

  const handleOnClick = () => {
    const [, portalUrl] = split(pathname, '/');
    changePage.goToLoggedInUserSettings(`/${portalUrl}` as HomeUrl);
    onClick?.();
  };

  return (
    <ClickableDiv
      onClick={handleOnClick}
      className="logged-in-user-settings flex gap2"
    >
      {
        children
        || (
          <>
            {icon}
            <span> Settings</span>
          </>
        )
      }
    </ClickableDiv>
  );
};
