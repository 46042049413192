import { useState } from 'react';
import { Checkbox } from 'antd';
import { ClinicEventsService } from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { FutureFollowUpScheduleComponent } from '../FutureFollowUpScheduleComponent/FutureFollowUpScheduleComponent';
import { FollowUpScheduleFormComponent, FollowUpScheduleFormComponentProps } from '../FollowUpScheduleFormComponent/FollowUpScheduleFormComponent';

import './FollowUpScheduleWithFutureClinicEventsComponent.scss';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';

export interface FollowUpScheduleWithFutureClinicEventsComponentProps
  extends FollowUpScheduleFormComponentProps {
  futureClinicEventServices: ClinicEventsService[];
}

export const FollowUpScheduleWithFutureClinicEventsComponent = ({
  visitId,
  futureClinicEventServices,
}: FollowUpScheduleWithFutureClinicEventsComponentProps) => {
  const [startNewSchedule, setStartNewSchedule] = useState(false);
  useUpdateListener('VISIT_CREATED', () => setStartNewSchedule(false));

  return (
    <div className="follow-up-schedule-with-existing-clinic-events">
      <FutureFollowUpScheduleComponent clinicEvents={futureClinicEventServices} />
      <Checkbox
        checked={startNewSchedule}
        onChange={(e) => setStartNewSchedule(e.target.checked)}
      >
        Still want to schedule another one?
      </Checkbox>
      {
        startNewSchedule
        && (
          <FollowUpScheduleFormComponent
            visitId={visitId}
            canSkipSchedule={false}
          />
        )
      }
    </div>
  );
};
