import { Checkbox, Form } from 'antd';
import { ReactNode } from 'react';
import { SkipFollowUpScheduleSubmitValue, useSkipFollowUpScheduleForm } from '../../hook/useSkipFollowUpScheduleForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';

export interface SkipFollowUpScheduleFormContainerProps
  extends NestedFormComponentChildProps<SkipFollowUpScheduleSubmitValue> {
  initialValues?: Partial<SkipFollowUpScheduleSubmitValue>;
  visitId?: string;
}

export const SkipFollowUpScheduleFormContainer = ({
  visitId,
  initialValues,
  onSubmit,
  onValuesChange,
  isLoading,
  formButtons,
}: SkipFollowUpScheduleFormContainerProps) => {
  const followupVisitSkippedForm = useSkipFollowUpScheduleForm();

  return (
    <Form
      form={followupVisitSkippedForm.form}
      initialValues={initialValues}
      onFinish={followupVisitSkippedForm.handleSubmit(onSubmit)}
      onValuesChange={onValuesChange}
      disabled={!visitId || followupVisitSkippedForm.formDisabled || isLoading}
    >
      <FormItem
        name={followupVisitSkippedForm.getName('followupVisitSkipped')}
        valuePropName="checked"
        initialValue={false}
      >
        <Checkbox>
          {followupVisitSkippedForm.getLabel('followupVisitSkipped') as ReactNode}
        </Checkbox>
      </FormItem>
      {formButtons}
    </Form>
  );
};
