import { ReactNode } from 'react';
import DisplayOrEmptyComponent from '../../../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface PrintDiseaseHistoryDisplayComponentProps {
  label?: ReactNode;
  value?: ReactNode;
  extraValue?: ReactNode;
}

export const PrintDiseaseHistoryDisplayComponent = ({
  label,
  value,
  extraValue,
}: PrintDiseaseHistoryDisplayComponentProps) => (
  <div className="flex">
    <span>
      {label}
      {label ? ': ' : ''}
    </span>
    <DisplayOrEmptyComponent value={value} />
    <span>{extraValue}</span>
  </div>
);
