import { ReactNode } from 'react';
import { Button } from 'antd';
import { useClinicEventCheckInVisit, VisitMethodEnum } from '../../../../uc-api-sdk';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { ClickableDiv } from '../../../../uiComponent/ClickableDiv/ClickableDiv';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { WorklistDrawerComponent } from '../../component/WorklistDrawerComponent/WorklistDrawerComponent';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';
import { useZoomContext } from '../../../../contexts/ZoomContext/ZoomContext';
import { VisitParticipantReassignModalComponent } from '../../../visit/component/VisitParticipantReassignModalComponent/VisitParticipantReassignModalComponent';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { ClinicEventsService } from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';

export interface CheckInButtonContainerProps {
  visit: ClinicEventsService;
  children?: ReactNode;
  checkedIn?: boolean;
  buttonText?: ReactNode;
  disabled?: boolean;
  onCheckedIn?: () => void;
}

export const CheckInButtonContainer = ({
  visit,
  children,
  checkedIn,
  buttonText = 'Check In',
  disabled,
  onCheckedIn,
}: CheckInButtonContainerProps) => {
  const patientId = visit.patientId || '';
  const visitId = visit.id || '';
  const isVideoVisit = visit.visitMethod === VisitMethodEnum.VIRTUAL;
  const { userId } = useLoggedInUserFromContext();
  const { isOpen, setValue } = useOpen(false);
  const {
    isOpen: isReassignParticipantsOpen,
    setValue: setIsReassignParticipantsOpen
  } = useOpen(false);
  const checkInHook = useClinicEventCheckInVisit({});
  const { closeNotification } = useZoomContext();
  const { send } = useBillableTimeInterventionComp();

  const handleCheckIn = () => {
    ApiRequestHelper.tryCatch(
      checkInHook.send({
        params: { id: visitId }
      }),
      {
        success: 'Check in success!',
        error: 'Failed to check in!',
        onSuccess: () => {
          setValue(true);
          onCheckedIn?.();
        },
      },
    );
  };

  const handleOnCheckIn = () => {
    if (isVideoVisit) {
      send({
        patientId,
        component: BillableTimeInterventionComponentEnum.Charting,
        detail: BillableTimeInterventionDetailEnum.WorkVisitVideoCallCheckIn,
      });
    } else {
      send({
        patientId,
        component: BillableTimeInterventionComponentEnum.Charting,
        detail: BillableTimeInterventionDetailEnum.WorkVisitsCheckIn,
      });
    }
    if (disabled) return;
    closeNotification(visitId);
    if (checkedIn) {
      setValue(true);
      return;
    }
    const isUserParticipant = (
      ClinicEventsService.ifUserParticipant(visit.visitParticipantsId, userId)
    );
    if (!isUserParticipant) {
      setIsReassignParticipantsOpen(true);
      return;
    }
    handleCheckIn();
  };

  const handleCloseReassign = () => {
    setIsReassignParticipantsOpen(false);
  };

  const handleSubmitReassign = () => {
    handleCloseReassign();
    handleCheckIn();
  };

  return (
    <div>
      {
        children
          ? (
            <ClickableDiv onClick={handleOnCheckIn}>
              {children}
            </ClickableDiv>
          ) : (
            <Button
              type="primary"
              disabled={disabled}
              onClick={handleOnCheckIn}
            >
              {buttonText}
            </Button>
          )
      }
      <WorklistDrawerComponent
        patientId={patientId}
        isOpen={isOpen}
        onClose={() => setValue(false)}
        clinicEventId={visitId}
      />
      <VisitParticipantReassignModalComponent
        isOpen={isReassignParticipantsOpen}
        close={handleCloseReassign}
        onSubmit={handleSubmitReassign}
        visit={visit}
      />
    </div>
  );
};
