import { useEffect } from 'react';
import { useLabResultContext } from '../context/LabResultContext/LabResultContext';

export const useLabResultTimer = () => {
  const { justAddedLabResultId, setJustAddedLabResultId } = useLabResultContext();

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (justAddedLabResultId) {
      timer = setTimeout(() => {
        if (timer) {
          setJustAddedLabResultId?.(undefined);
          clearTimeout(timer);
        }
      }, 2000);
    }
  }, [justAddedLabResultId]);

  return justAddedLabResultId;
};
