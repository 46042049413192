import {
  filter,
  findIndex,
  forEach,
  get,
  isEqual
} from 'lodash';

export const orderByArray = <T, K extends string>(
  value: T[],
  orderedArray: K[],
  key?: string, // keyof T
): T[] => {
  const specifiedOrder: T[] = Array(orderedArray.length);
  const unknownOrder: T[] = [];
  forEach(value, (v) => {
    const compareValue = (key ? get(v, key) : v) as K;
    const orderOfV = findIndex(orderedArray, (oaV) => isEqual(oaV, compareValue));
    if (orderOfV > -1) {
      specifiedOrder.splice(orderOfV, 1, v);
    } else {
      unknownOrder.push(v);
    }
  });
  const cleanSpecifiedOrder = filter(specifiedOrder, (v) => v !== undefined);
  return [...cleanSpecifiedOrder, ...unknownOrder];
};
