import React, { FC } from 'react';
import useBoolean from '../../hooks/useBoolean/useBoolean';
import { useMixpanelContext } from '../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../contexts/MixpanelContext/MixpanelEvents';
import { PatientCreateDrawerComponent } from './PatientCreateDrawerComponent';

export interface PatientCreateDrawerButtonComponentProps {
  children: React.ReactNode;
  onClick?: () => void;
  onSubmit?: () => void;
  showEnrollmentDrawer?: boolean;
}

// Not recommended to add to select component, it will trigger the select popup to open
export const PatientCreateDrawerButtonComponent: FC<PatientCreateDrawerButtonComponentProps> = ({
  children,
  onClick,
  onSubmit,
  showEnrollmentDrawer = false,
}) => {
  const { send: sendMixpanel } = useMixpanelContext();
  const { value: open, setFalse, setTrue } = useBoolean();

  const buttonWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(children as React.ReactElement, {
        onClick:
          () => {
            onClick?.();
            setTrue();
            sendMixpanel({ event: MixpanelEvents.PatientCreateDrawerOpen });
          }
      });
    }
    return child;
  });

  const handleSubmit = () => {
    setFalse();
    onSubmit?.();
  };

  return (
    <>
      {buttonWithProps}
      <PatientCreateDrawerComponent
        open={open}
        onClose={setFalse}
        onSubmit={handleSubmit}
        showEnrollmentDrawer={showEnrollmentDrawer}
      />
    </>
  );
};
