import {
  Button,
  Checkbox,
  CheckboxProps,
  Input,
} from 'antd';
import { useState } from 'react';
import { Title } from '../../../../uiComponent/Title/Title';
import { NonCovered } from '../../../../uc-api-sdk';

export interface MarkInsuranceNonCoveredModalFormComponentProps {
  initialValue?: NonCovered;
  onSubmit?: (value: NonCovered) => void;
}

export const MarkInsuranceNonCoveredModalFormComponent = ({
  initialValue,
  onSubmit,
}: MarkInsuranceNonCoveredModalFormComponentProps) => {
  const [isChecked, setIsChecked] = useState(initialValue?.isNonCovered ?? false);
  const [reason, setReason] = useState(initialValue?.reason ?? '');

  const shouldDisableSave = () => (isChecked && !reason);

  const handleCheckChange: CheckboxProps['onChange'] = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleOnSave = () => {
    const nonCoveredValue = {
      isNonCovered: isChecked,
      reason,
    } as NonCovered;
    onSubmit?.(nonCoveredValue);
  };

  return (
    <div className="mark-as-non-covered-form">
      <Title>
        Mark as "Insurance Non-Covered" patient?
      </Title>
      <div>
        An "Insurance Non-Covered" patient is the beneficiary of provider's
        pro-bono cases or special business agreements.
      </div>
      <div className="mt20 mb10">
        <Checkbox checked={isChecked} onChange={handleCheckChange}>
          This is an "Insurance Non-Covered" patient
        </Checkbox>
        {
          isChecked
            ? (
              <div className="mt10 pl24">
                <Input.TextArea
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="(Required) Please leave a note"
                />
              </div>
            ) : null
        }
      </div>
      <div className="mt30 flex jc-e">
        <Button
          type="primary"
          disabled={shouldDisableSave()}
          onClick={handleOnSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
