import React, {
  useContext,
} from 'react';
import { useTableChange } from '../../hooks/useTableChange/useTableChange';

export interface TableValue<T, F> extends ReturnType<typeof useTableChange<T, F>> {

}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TableContext = React.createContext<TableValue<any, any>>({
  filter: {},
  setFilter: () => console.error('setFilter: TableContext does not exist yet'),
  sort: [],
  setSort: () => console.error('setSort: TableContext does not exist yet'),
  pagination: { current: 1 },
  setPagination: () => console.error('setPagination: TableContext does not exist yet'),
  pageInfo: {},
  handleTableChange: () => console.error('handleTableChange: TableContext does not exist yet'),
  goToPageOne: () => console.error('goToPageOne: TableContext does not exist yet'),
  processedFilter: {},
  processedSort: [],
  reset: () => console.error('reset: TableContext does not exist yet'),
  hasFiltersAndSorters: false,
  filterCount: 0,
  name: undefined,
});

export interface TableContextProviderProps<T, F> {
  children: React.ReactNode;
  params: Parameters<typeof useTableChange<T, F>>[0];
}

export const TableContextProvider = <T, F>({
  children,
  params
}: TableContextProviderProps<T, F>) => {
  const tableChange = useTableChange<T, F>(params);

  return (
    <TableContext.Provider value={tableChange}>
      {children}
    </TableContext.Provider>
  );
};

export const useTableContext = <T, F>() => {
  const context = useContext(TableContext);
  return (context || {}) as TableValue<T, F>;
};
