import { EligibilityReport, EligibilityStatusEnum, useInsuranceProviderGetEligibilityReport } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { WaystarResultModalComponent, WaystarResultModalComponentProps } from '../../component/WaystarComponent/WaystarResultModalComponent/WaystarResultModalComponent';

export interface InsuranceEligibilityReportContainerProps {
  inquiryId?: string;
  onDone?: WaystarResultModalComponentProps['onDone'];
}

export const InsuranceEligibilityReportContainer = ({
  inquiryId = '',
  onDone,
}: InsuranceEligibilityReportContainerProps) => {
  const reportInfo = useInsuranceProviderGetEligibilityReport({
    params: { inquiryId },
    options: { sendOnMount: !!inquiryId }
  });

  if (!inquiryId) {
    return (
      <div>
        Failed to load eligibility report. Please try again.
      </div>
    );
  }

  return (
    <FetchComponent
      info={reportInfo}
    >
      {
        (info) => {
          const eligibilityReport = info?.rawValue?.data as EligibilityReport;
          return (
            <WaystarResultModalComponent
              eligibilityStatus={eligibilityReport?.eligibilityStatus as EligibilityStatusEnum}
              inquiryInfo={eligibilityReport}
              report={eligibilityReport?.shortHtml || ''}
              onDone={onDone}
            />
          );
        }
      }
    </FetchComponent>
  );
};
