import { Input, InputProps } from 'antd';
import { FC } from 'react';

export interface PatientLastNameInputComponentProps extends InputProps { }

export const PatientLastNameInputComponent: FC<PatientLastNameInputComponentProps> = ({
  value,
  onChange,
}) => (
  <Input
    value={value}
    onChange={onChange}
  />
);
