import { filter } from 'lodash';
import { useMemo } from 'react';
import { Employee } from '../../types/user';
import {
  APIResponse,
  EmployeeStatus,
  RoleTypeEnum,
  useEmployeeCoworkers
} from '../../uc-api-sdk';
import { CoWorkerHelpers } from '../../uiComponent/CoWorkerDropdownComponent/CoWorkerHelpers';
import EmployeeInfo from '../useUserInfo/employeeInfo';

export const PROVIDER_FILTER = [
  RoleTypeEnum.PROVIDER,
];
export const PORTAL_USER_FILTER = [
  RoleTypeEnum.PROVIDER,
  RoleTypeEnum.MA,
  RoleTypeEnum.RD,
  RoleTypeEnum.HC,
  RoleTypeEnum.CA,
];
export interface UseCoworkerHookResult {
  myCoWorkerList: EmployeeInfo[];
  isLoading: ReturnType<typeof useEmployeeCoworkers>['isLoading'];
  code: APIResponse<unknown>['code'];
  msg: APIResponse<unknown>['msg'];
  error: ReturnType<typeof useEmployeeCoworkers>['error'];
  refetch: ReturnType<typeof useEmployeeCoworkers>['refetch'];
}
export interface UseCoworkerHookOptions {
  roleFilter: RoleTypeEnum[];
  organizationIdFilter?: string[];
  ucOrgIdList?: string[];
  clinicIdList?: string[];
  statusListFilter?: EmployeeStatus[];
  sortByName?: boolean;
  sendOnMount?: boolean;
  inSameCareCenter?: boolean;
  limitOnCallRole?: boolean;
  needToFilter?: boolean;
  internalEmployeeForPatientId?: string;
  externalEmployeeForPatientId?: string;
  internalEmployeeForMyMedicalOrg?: boolean;
  reassignAssigneesClinicIdList?: string[];
}
export const useCoworker = ({
  roleFilter = [],
  organizationIdFilter = [],
  ucOrgIdList = [],
  clinicIdList = [],
  statusListFilter = [EmployeeStatus.ACTIVATED],
  sortByName = true,
  sendOnMount = true,
  inSameCareCenter,
  limitOnCallRole,
  needToFilter = true,
  internalEmployeeForPatientId,
  externalEmployeeForPatientId,
  internalEmployeeForMyMedicalOrg,
  reassignAssigneesClinicIdList,
} = {} as UseCoworkerHookOptions): UseCoworkerHookResult => {
  const myCoWorkerInfo = useEmployeeCoworkers({
    params: {
      searchRequest: {
        filter: {
          roleList: roleFilter,
          organizationIdList: filter(organizationIdFilter, (id) => !!id),
          ucOrgIdList,
          clinicIdList,
          statusList: statusListFilter,
          inSameCareCenter,
          limitOnCallRole,
          needToFilter,
          internalEmployeeForPatientId,
          externalEmployeeForPatientId,
          internalEmployeeForMyMedicalOrg,
          reassignAssigneesClinicIdList,
        },
      },
    },
    options: {
      sendOnMount,
    },
  });

  const myCoWorkerList = useMemo((): UseCoworkerHookResult['myCoWorkerList'] => {
    let list = myCoWorkerInfo.data?.data || [];
    if (sortByName) {
      list = CoWorkerHelpers.sortCoWorkerByName(list);
    }
    const cwEIList = [] as EmployeeInfo[];
    list.forEach((cw) => {
      const cwEI = new EmployeeInfo({
        employee: cw as Employee,
        roleAssignments: cw.assignedRoles || [],
      });
      if (cwEI.id !== 'invalid') {
        cwEIList.push(cwEI);
      }
    });
    return cwEIList.sort((a, b) => a.fullNameWithTitle.localeCompare(b.fullNameWithTitle));
  }, [myCoWorkerInfo.data?.data, sortByName]);

  return {
    myCoWorkerList,
    isLoading: myCoWorkerInfo.isLoading,
    code: myCoWorkerInfo.data?.code,
    msg: myCoWorkerInfo.data?.msg,
    error: myCoWorkerInfo.error,
    refetch: myCoWorkerInfo.refetch,
  };
};
