import {
  LimitedClinic,
  MedicalOrganization,
  MedicalOrganizationStatus,
  useMedicalOrganizationSearchAllAccessMedicalOrg
} from '../../uc-api-sdk';
import { ClinicOption } from '../../uiComponent/ClinicSelectComponent/ClinicSelectComponent';
import { ClinicNameDisplayComponent } from '../../uiComponent/DisplayComponent/ClinicDisplayComponent/ClinicNameDisplayComponent';
import { DEFAULT_CLINIC_SEARCH_PAGE_SIZE } from './constant';

export type MakeClinicOptionsProps = {
  clinic: LimitedClinic;
} | {
  sequenceNumber: LimitedClinic['sequenceNumber'];
  name: LimitedClinic['businessName'];
  nickname: LimitedClinic['nickName'];
  city: LimitedClinic['city'];
  state: LimitedClinic['state'];
}

export interface MakeClinicOptions {
  isProvider: boolean;
  Component?: (props: MakeClinicOptionsProps) => JSX.Element;
  searchableFieldName?: string;
  makeSearchable?: (clinic: LimitedClinic) => string;
}

export type ClinicSelectParams = NonNullable<Parameters<ReturnType<typeof useMedicalOrganizationSearchAllAccessMedicalOrg>['send']>['0']>['params'];
export type ClinicSelectFilters = ClinicSelectParams['searchRequest']['filter'];

export class ClinicSelectContainerHelper {
  static searchableFieldName = 'searchable';

  static makeParams({
    value = '',
    page = 1,
    size = DEFAULT_CLINIC_SEARCH_PAGE_SIZE,
    filters = {} as MedicalOrganization,
    pagination = true,
  }): ClinicSelectParams {
    return {
      searchRequest: {
        filter: {
          ...filters,
          medicalOrganization: {
            fullNameNear: value,
            status: MedicalOrganizationStatus.ACTIVE,
            ...filters,
          }
        },
        pageInfo: {
          pagination,
          page,
          size,
        }
      },
    };
  }

  static makeSearchable(clinic: LimitedClinic, isProvider?: boolean) {
    const { alias, orgNumber, businessName } = clinic;
    let searchable = `${isProvider ? businessName : alias}`;
    if (!isProvider && orgNumber) {
      searchable = `${orgNumber} ${alias}`;
    }
    return searchable.trim();
  }

  static makeOptions(
    clinics: LimitedClinic[],
    variables?: MakeClinicOptions,
  ) {
    const {
      Component = ClinicNameDisplayComponent,
      searchableFieldName = ClinicSelectContainerHelper.searchableFieldName,
      makeSearchable = ClinicSelectContainerHelper.makeSearchable,
      isProvider,
    } = variables || {};
    return clinics?.map((d) => ({
      label: (
        <Component
          clinic={d}
          sequenceNumber={d.sequenceNumber}
          name={d.businessName}
          nickname={d.nickName}
          city={d.city}
          state={d.state}
        />
      ),
      value: d.id,
      id: d.id,
      clinicData: d,
      [searchableFieldName]: makeSearchable(d, isProvider),
    }));
  }

  static groupByMyClinics(clinics: LimitedClinic[]) {
    const myClinics = [] as LimitedClinic[];
    const others = [] as LimitedClinic[];
    clinics.forEach((c) => {
      if (c.isMyClinics) myClinics.push(c);
      else others.push(c);
    });
    return {
      myClinics,
      others,
    };
  }

  static sortBySearchable(searchables: ClinicOption[]) {
    return searchables.sort((a, b) => {
      if (a.searchable.toLowerCase() > b.searchable.toLowerCase()) {
        return 1;
      } if (a.searchable.toLowerCase() < b.searchable.toLowerCase()) {
        return -1;
      }
      return 0;
    });
  }

  static sortByOrgNumber(clinics: ClinicOption[]) {
    return clinics.sort((a, b) => {
      if (Number(a.clinicData?.orgNumber) > Number(b.clinicData?.orgNumber)) {
        return 1;
      } if (Number(a.clinicData?.orgNumber) < Number(b.clinicData?.orgNumber)) {
        return -1;
      }
      return 0;
    });
  }

  static sortClinics(clinics: ClinicOption[], variables?: MakeClinicOptions) {
    const { isProvider } = variables || {};
    const searchables = clinics.map((org) => ({
      ...org,
      searchable: ClinicSelectContainerHelper.makeSearchable(org.clinicData || {}, isProvider)
    }));

    if (isProvider) {
      return ClinicSelectContainerHelper.sortBySearchable(searchables);
    }

    const hasNums = searchables.filter((org) => org.clinicData?.orgNumber);
    const noNums = searchables.filter((org) => !org.clinicData?.orgNumber);
    const sorted = ClinicSelectContainerHelper.sortByOrgNumber(hasNums.map((org) => ({
      ...org,
      searchable: ClinicSelectContainerHelper.makeSearchable(org.clinicData || {}, isProvider)
    })));

    const noNumsSorted = ClinicSelectContainerHelper.sortBySearchable(noNums.map((org) => ({
      ...org,
      searchable: ClinicSelectContainerHelper.makeSearchable(org.clinicData || {}, isProvider)
    })));
    return sorted.concat(noNumsSorted);
  }

  static makeAndSortClinics(clinics: LimitedClinic[], variables?: MakeClinicOptions) {
    return ClinicSelectContainerHelper.sortClinics(
      ClinicSelectContainerHelper.makeOptions(clinics, variables),
      variables
    );
  }
}
