import { Button } from 'antd';
import { useEffect, useMemo } from 'react';
import { Icons } from '../../../../icons/Icons';
import { DiscardUnsavedChangesModalComponent } from '../../../../uiComponent/DiscardUnsavedChangesModalComponent/DiscardUnsavedChangesModalComponent';
import { CalendarContainer } from '../../container/CalendarContainer/CalendarContainer';
import { CreateVisitProvider } from '../../context/CreateVisitContext/CreateVisitContext';
import { CreateVisitFormProvider } from '../../context/CreateVisitFormContext/CreateVisitFormContext';
import { useCalendarHelper, VisitInitialValue } from '../../hook/useCalendarHelper';

export interface CalendarWithContextComponentProps {
  onClose?: () => void;
  visitInitialValue?: VisitInitialValue;
  createVisitOnMount?: boolean;
}

export const CalendarWithContextComponent = ({
  onClose,
  visitInitialValue,
  createVisitOnMount
}: CalendarWithContextComponentProps) => {
  const {
    selectedEvent,
    visitInfo,
    visitForm,
    fromDateToDate,
    clinicIds,
    employeeIds,
    showMyGoogleEvents,
    showMyOtherEvents,
    visitTypes,
    handleNavigate,
    view,
    handleView,
    handleCreateButtonClick,
    handleSelect,
    handleSelectNewSlot,
    handleEventDrop,
    selectedDate,
    onPopoverClose,
    createVisitWithInitialValues,
    deselectEvent,
  } = useCalendarHelper({
    visitInitialValue,
  });

  const handleClose = () => {
    deselectEvent();
    onClose?.();
  };

  useEffect(() => {
    if (createVisitOnMount) {
      handleCreateButtonClick?.();
    }
  }, []);

  const extra = useMemo(() => (
    <Button type="primary" onClick={handleCreateButtonClick}>+ Create</Button>
  ), [handleCreateButtonClick]);

  useEffect(() => {
    // setTimeout is because if the drawer is not fully open the
    // visit popup will blink
    const timeout = setTimeout(createVisitWithInitialValues, 700);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <div className="w100 flex jc-e">
        <DiscardUnsavedChangesModalComponent
          showDiscard={() => !!selectedEvent?.isEdit}
          onConfirm={handleClose}
        >
          <Icons.CloseIcon />
        </DiscardUnsavedChangesModalComponent>
      </div>
      <CreateVisitFormProvider value={visitForm}>
        <CreateVisitProvider info={visitInfo}>
          <CalendarContainer
            selectedEvent={selectedEvent}
            date={selectedDate}
            fromDate={fromDateToDate?.fromDate}
            toDate={fromDateToDate?.toDate}
            onPopoverClose={onPopoverClose}
            onNavigate={handleNavigate}
            view={view}
            onView={handleView}
            employeeIds={employeeIds}
            clinicIds={clinicIds}
            visitTypes={visitTypes}
            showMyGoogleEvents={showMyGoogleEvents}
            showMyOtherEvents={showMyOtherEvents}
            onSelect={handleSelect}
            onEventDrop={handleEventDrop}
            onSelectNew={handleSelectNewSlot}
            extra={extra}
          />
        </CreateVisitProvider>
      </CreateVisitFormProvider>
    </>
  );
};
