import { ColumnType } from 'antd/es/table';
import React, { ReactNode } from 'react';
import { BEDTIME_ICON, OVERNIGHT_ICON } from '../../../../assets/commonIconInfo';
import { Icon } from '../../../../icons/Icon';
import { measurementTableDateColumnMaker } from '../MeasurementTable/MeasurementTableColumn';
import { BgMealTypeEnum } from '../../../../uc-api-sdk';
import { BGTableCellComponent } from '../BGTableComponent/BGTableCellComponent';
import { BGMeasurementService } from '../../helper/BGMeasurementService';

const createMealColumnWithoutBeforeMealType = (title: ReactNode, key: BgMealTypeEnum) => (
  (): ColumnType<BGMeasurementService> => ({
    title,
    key,
    render: (__value, data) => (
      data.mealType === key ? <BGTableCellComponent data={[data]} /> : null
    ),
    width: '11%',
  })
);

const createMealColumns = (
  title: string,
  key: BgMealTypeEnum,
) => (): ColumnType<BGMeasurementService> => ({
  title,
  // This is because antd doesn't have the correct type
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  children: [
    {
      title: 'B',
      key: `${key}Before`,
      render: (__value, data) => (
        data.mealType === key && data.beforeMeal
          ? <BGTableCellComponent data={[data]} />
          : null
      ),
      width: '11%',
    },
    {
      title: 'A',
      key: `${key}After`,
      render: (__value, data) => (
        data.mealType === key && !data.beforeMeal
          ? <BGTableCellComponent data={[data]} />
          : null
      ),
      width: '11%',
    },
  ] as ColumnType<BGMeasurementService>[],
});

/* eslint-disable import/prefer-default-export */
export const BGTablePrintColumns = {
  date: measurementTableDateColumnMaker('12%'),
  overnight: createMealColumnWithoutBeforeMealType(
    <Icon src={OVERNIGHT_ICON.src} alt={OVERNIGHT_ICON.alt} size={16} />,
    BgMealTypeEnum.OVERNIGHT,
  ),
  breakfast: createMealColumns('Breakfast', BgMealTypeEnum.BREAKFAST),
  lunch: createMealColumns('Lunch', BgMealTypeEnum.LUNCH),
  dinner: createMealColumns('Dinner', BgMealTypeEnum.DINNER),
  bedtime: createMealColumnWithoutBeforeMealType(
    <Icon src={BEDTIME_ICON.src} alt={BEDTIME_ICON.alt} size={16} />,
    BgMealTypeEnum.BEDTIME,
  ),
};
