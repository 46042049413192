/* eslint-disable react/jsx-props-no-spreading */
import {
  APP_CRASH_ICON,
  BATCH_ASSIGNMENT_ICON,
  BATCH_MESSAGING_ICON,
  BATCH_UPLOAD_ICON,
  BILLABLE_TIME_REVIEW_ICON,
  BILLING_AND_REPORT_ICON,
  BLOOD_DROP_2TONE_ICON,
  BLUE_STAR_ICON,
  CALL_ICON,
  CALL_UNENROLLED,
  CARE_CENTER_ICON_AP2,
  CARE_DIVISION_ICON_AP2,
  CARE_FOLDER_ICON,
  CARE_GROUP_ICON_AP2,
  CARE_UNIT_ROOT_ICON_AP2,
  CHAT_ICON,
  CHECK_CIRCLE_ICON,
  CLINICAL_ORG_ICON,
  CLINIC_BILLING_ICON,
  CLIPBOARD_PEN_ICON,
  CLOSE_ICON,
  COLLECT_DEVICES_ICON,
  COPY_ICON, DOWNLOAD_ICON, DOWN_ARROW_ICON,
  DROPDOWN_ICON,
  EDIT_ICON,
  EMPTY_IMG,
  ENROLLMENT_ICON,
  ENROLL_ICON,
  ERROR_ICON,
  EXCEL_SHEET_ICON,
  EXIT_ICON,
  FEATURE_COMING_SOON_ALERT_ICON,
  FEEDBACK_ICON,
  FILE_ICON, FILE_SEARCH_ICON, FILLED_USER_ICON,
  FIREWORKS_ICON,
  FOLDER_ICON,
  HEADER_ALERT_ICON,
  HEADER_CALENDAR_ICON,
  HEADER_MSG_ICON,
  HEADER_PHONE_ICON,
  HEART_2TONE_ICON,
  HISTORY_ICON,
  HOME_ICON,
  INELIGIBLE_TO_ENROLL_PATIENT_ALERT_ICON,
  INSIGHTS_ICON,
  IN_CLINIC_VISIT_ICON,
  LEFT_WHITE_CHEVRON_ICON,
  LOCATION_ICON,
  LOCK_ICON,
  MAGIC_STICK,
  MAXIMIZE_WINDOW_ICON,
  MEDICAL_ALERT_DASHBOARD_ICON,
  MENU_DOT_ICON,
  MESSAGE_ARTICLE_ICON,
  MESSAGE_GRAY_ICON,
  MESSAGE_MENU_ICON,
  MESSAGE_SYSTEM_AVATAR,
  MINIMIZE_WINDOW_ICON,
  MOVABLE_ICON, NOTE_SEARCH_ICON, NOTIFICATION_BELL_ICON,
  NOTIFY_PATIENTS_ICON,
  PATIENTS_ICON,
  PATIENT_DELETED_ICON,
  PATIENT_INFO_HIDE_ICON,
  PAYMENT_INVOICE_ICON,
  PEN_ICON,
  PHONE_VISIT_ICON,
  PIN_FILL_ICON,
  PIN_OUTLINE_ICON,
  PLUS_ICON,
  POPOUT_ICON,
  PREVIEW_CLOSE_ICON,
  PREVIEW_OPEN_ICON,
  RECENT_ICON,
  REFER_ICON,
  REIMBURSEMENT_ICON,
  ROUTINE_CARE_ICON,
  SCREENING_ICON,
  SETTINGS_ICON,
  SHIELD_ICON,
  SIDE_BAR_ALERTS_ICON,
  START_ENROLLMENT_ICON,
  STAR_ICON,
  SWITCH_ICON,
  TERMINATE_ICON,
  TICKET_MENU_ICON, TIMER_ICON, TRANSCRIBING_ICON,
  TRANSLATE_ICON,
  TRASH_CAN_ICON,
  TROUBLESHOOTING_ICON,
  TWO_WEEK_CALENDAR_ICON,
  UC_MOBILE_APP_ICON,
  UP_ARROW_ICON,
  USER_CHECK_ICON,
  USER_ICON,
  UTILITIES_MENU_ICON,
  UTILITY_SCREENING_ICON,
  UTILITY_TRANSCRIBING_ICON,
  VECTOR_TROPHY_ICON,
  VIDEO_PLAY_BUTTON_ICON,
  VIDEO_VISIT_ICON,
  VISITS_AND_EVENTS_TAB_ICON,
  VITALS_BG_ICON,
  VITALS_BP_ICON,
  VITALS_HS_ICON,
  VITALS_PO_ICON,
  VITALS_TM_ICON,
  WRITE_ICON,
  PDF_ICON,
} from '../assets/commonIconInfo';
import { makeIcon } from './helpers';
import { IconProps } from './Icon';

export interface IconsProps extends IconProps {
}

export const Icons = {
  Alert: makeIcon(HEADER_ALERT_ICON),
  Calendar: makeIcon(HEADER_CALENDAR_ICON),
  Message: makeIcon(HEADER_MSG_ICON),
  Phone: makeIcon(HEADER_PHONE_ICON),
  Home: makeIcon(HOME_ICON),
  RoutineCare: makeIcon(ROUTINE_CARE_ICON),
  Enrollment: makeIcon(ENROLLMENT_ICON),
  LeftWhiteChevron: makeIcon(LEFT_WHITE_CHEVRON_ICON),
  Patients: makeIcon(PATIENTS_ICON),
  Insight: makeIcon(INSIGHTS_ICON),
  PreviewOpen: makeIcon(PREVIEW_OPEN_ICON),
  PreviewClose: makeIcon(PREVIEW_CLOSE_ICON),
  MaximizeWindow: makeIcon(MAXIMIZE_WINDOW_ICON),
  MinimizeWindow: makeIcon(MINIMIZE_WINDOW_ICON),
  MedicalAlertDashboard: makeIcon(MEDICAL_ALERT_DASHBOARD_ICON),
  Star: makeIcon(STAR_ICON),
  IneligibleToEnrollPatientAlert: makeIcon(INELIGIBLE_TO_ENROLL_PATIENT_ALERT_ICON),
  FeatureComingSoonAlert: makeIcon(FEATURE_COMING_SOON_ALERT_ICON),
  VitalsBG: makeIcon(VITALS_BG_ICON),
  VitalsBP: makeIcon(VITALS_BP_ICON),
  VitalsPO: makeIcon(VITALS_PO_ICON),
  VitalsTM: makeIcon(VITALS_TM_ICON),
  VitalsHS: makeIcon(VITALS_HS_ICON),
  VideoPlayButton: makeIcon(VIDEO_PLAY_BUTTON_ICON),
  MessageGray: makeIcon(MESSAGE_GRAY_ICON),
  MessageArticleIcon: makeIcon(MESSAGE_ARTICLE_ICON),
  MessageMenu: makeIcon(MESSAGE_MENU_ICON),
  TicketMenu: makeIcon(TICKET_MENU_ICON),
  MessageSystemAvatar: makeIcon(MESSAGE_SYSTEM_AVATAR),
  VideoVisitIcon: makeIcon(VIDEO_VISIT_ICON),
  InClinicVisitIcon: makeIcon(IN_CLINIC_VISIT_ICON),
  PhoneVisitIcon: makeIcon(PHONE_VISIT_ICON),
  VectorTrophyIcon: makeIcon(VECTOR_TROPHY_ICON),
  UtilityScreeningIcon: makeIcon(UTILITY_SCREENING_ICON),
  UtilityTranscribingIcon: makeIcon(UTILITY_TRANSCRIBING_ICON),
  DropdownIcon: makeIcon(DROPDOWN_ICON),
  FilledUser: makeIcon(FILLED_USER_ICON),
  Lock: makeIcon(LOCK_ICON),
  PatientInfoHide: makeIcon(PATIENT_INFO_HIDE_ICON),
  Chat: makeIcon(CHAT_ICON),
  Call: makeIcon(CALL_ICON),
  MenuDot: makeIcon(MENU_DOT_ICON),
  DownArrow: makeIcon(DOWN_ARROW_ICON),
  UpArrow: makeIcon(UP_ARROW_ICON),
  Location: makeIcon(LOCATION_ICON),
  EmptyImg: makeIcon(EMPTY_IMG),
  TrashCanIcon: makeIcon(TRASH_CAN_ICON),
  EditIcon: makeIcon(EDIT_ICON),
  WriteIcon: makeIcon(WRITE_ICON),
  CloseIcon: makeIcon(CLOSE_ICON),
  ClinicalOrgIcon: makeIcon(CLINICAL_ORG_ICON),
  BloodDrop2Tone: makeIcon(BLOOD_DROP_2TONE_ICON),
  Heart2Tone: makeIcon(HEART_2TONE_ICON),
  TwoWeekCalendar: makeIcon(TWO_WEEK_CALENDAR_ICON),
  Popout: makeIcon(POPOUT_ICON),
  CareFolder: makeIcon(CARE_FOLDER_ICON),
  PatientEnrollment: makeIcon(ENROLL_ICON),
  TaskComplete: makeIcon(CHECK_CIRCLE_ICON),
  PatientTranscribing: makeIcon(TRANSCRIBING_ICON),
  PatientScreening: makeIcon(SCREENING_ICON),
  Fireworks: makeIcon(FIREWORKS_ICON),
  UtilitiesMenu: makeIcon(UTILITIES_MENU_ICON),
  StartEnrollment: makeIcon(START_ENROLLMENT_ICON),
  ReferIcon: makeIcon(REFER_ICON),
  CallUnenrolled: makeIcon(CALL_UNENROLLED),
  Settings: makeIcon(SETTINGS_ICON),
  Exit: makeIcon(EXIT_ICON),
  UcAppIcon: makeIcon(UC_MOBILE_APP_ICON),
  History: makeIcon(HISTORY_ICON),
  PlusIcon: makeIcon(PLUS_ICON),
  BlueStarIcon: makeIcon(BLUE_STAR_ICON),
  FeedbackIcon: makeIcon(FEEDBACK_ICON),
  TroubleshootingIcon: makeIcon(TROUBLESHOOTING_ICON),
  Wand: makeIcon(MAGIC_STICK),
  Movable: makeIcon(MOVABLE_ICON),
  User: makeIcon(USER_ICON),
  EditPenIcon: makeIcon(PEN_ICON),
  CareUnitRootIcon: makeIcon(CARE_UNIT_ROOT_ICON_AP2),
  CareCenterIcon: makeIcon(CARE_CENTER_ICON_AP2),
  CareDivisionIcon: makeIcon(CARE_DIVISION_ICON_AP2),
  CareGroupIcon: makeIcon(CARE_GROUP_ICON_AP2),
  ErrorIcon: makeIcon(ERROR_ICON),
  AppCrashIcon: makeIcon(APP_CRASH_ICON),
  ClipboardPen: makeIcon(CLIPBOARD_PEN_ICON),
  NotificationBellIcon: makeIcon(NOTIFICATION_BELL_ICON),
  ReviewBillableTimeIcon: makeIcon(BILLABLE_TIME_REVIEW_ICON),
  SideBarMedicalAlertIcon: makeIcon(SIDE_BAR_ALERTS_ICON),
  CopyIcon: makeIcon(COPY_ICON),
  SideBarVisitsAndEventsIcon: makeIcon(VISITS_AND_EVENTS_TAB_ICON),
  NotifyPatientsIcon: makeIcon(NOTIFY_PATIENTS_ICON),
  UserCheckIcon: makeIcon(USER_CHECK_ICON),
  SwitchIcon: makeIcon(SWITCH_ICON),
  CollectDevicesIcon: makeIcon(COLLECT_DEVICES_ICON),
  TerminateIcon: makeIcon(TERMINATE_ICON),
  BatchAssignmentIcon: makeIcon(BATCH_ASSIGNMENT_ICON),
  BatchMessagingIcon: makeIcon(BATCH_MESSAGING_ICON),
  TranslateIcon: makeIcon(TRANSLATE_ICON),
  Shield: makeIcon(SHIELD_ICON),
  BatchUploadIcon: makeIcon(BATCH_UPLOAD_ICON),
  FileIcon: makeIcon(FILE_ICON),
  ClinicBilling: makeIcon(CLINIC_BILLING_ICON),
  BillingAndReportIcon: makeIcon(BILLING_AND_REPORT_ICON),
  ReimbursementIcon: makeIcon(REIMBURSEMENT_ICON),
  PaymentInvoiceIcon: makeIcon(PAYMENT_INVOICE_ICON),
  PatientDeletedIcon: makeIcon(PATIENT_DELETED_ICON),
  PDFIcon: makeIcon(PDF_ICON),
  TimerIcon: makeIcon(TIMER_ICON),
  RecentIcon: makeIcon(RECENT_ICON),
  PinFillIcon: makeIcon(PIN_FILL_ICON),
  PinOutlineIcon: makeIcon(PIN_OUTLINE_ICON),
  FolderIcon: makeIcon(FOLDER_ICON),
  ExcelSheetIcon: makeIcon(EXCEL_SHEET_ICON),
  DownloadIcon: makeIcon(DOWNLOAD_ICON),
  NoteSearchIcon: makeIcon(NOTE_SEARCH_ICON),
  FILESearchIcon: makeIcon(FILE_SEARCH_ICON),
};
