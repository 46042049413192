import React, { FC, ReactNode } from 'react';
import { Avatar } from '../Avatar/Avatar';
import './CommentWAvatarComponent.scss';
import { DisplayDateComponent } from '../DisplayComponent/DisplayDateComponent';

export interface CommentWAvatarComponentProps {
  commentedBy?: string;
  comment: string;
  date?: string;
  avatarUrl?: string;
  extra?: ReactNode;
}

export const CommentWAvatarComponent: FC<CommentWAvatarComponentProps> = ({
  commentedBy,
  comment,
  date,
  avatarUrl,
  extra,
}) => (
  <div className="CommentWAvatarComponent flex gap2">
    <Avatar src={avatarUrl} />
    <div className="RightWrapper w100">
      <div>
        <div className="CommentAndDateWrapper">
          <div>
            {commentedBy}
          </div>
          <div>
            <DisplayDateComponent value={date} format="DATE_TIME" />
          </div>
        </div>
        <div className="Comment">{comment}</div>
      </div>
      {extra && (
        <div>
          {extra}
        </div>
      )}
    </div>
  </div>
);
