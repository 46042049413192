import Input, { TextAreaProps } from 'antd/lib/input';
import classNames from 'classnames';
import './TextAreaComponent.scss';
import { InputType } from '../../features/Input/types';

export interface TextareaComponentProps extends
  InputType<string>,
  Omit<TextAreaProps, 'onChange' | 'value'> {

}

export const TextareaComponent = ({
  className = '',
  onChange,
  ...props
}: TextareaComponentProps) => {
  const handleChange: TextAreaProps['onChange'] = (evt) => {
    onChange?.(evt.target.value);
  };

  return (
    <Input.TextArea
      className={classNames({
        'text-area-component': true,
        [className]: !!className,
      })}
      onChange={handleChange}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};
