import { FC } from 'react';
import { useMedicationContext } from '../../contexts/MedicationContext/MedicationContext';
import { Nullable } from '../../uc-api-sdk';
import CancelDeleteButtonsComponent from '../CancelDeleteButtonComponent/CancelDeleteButtonComponent';
import ModalButton from '../ModalButton/ModalButton';

export interface MedicationDeleteConfirmationModalProps {
  medId: Nullable<string> | undefined;
}

export const MedicationDeleteConfirmationModal:
  FC<MedicationDeleteConfirmationModalProps> = ({ medId }) => {
    const { onDelete } = useMedicationContext();
    const handleDelete = () => {
      if (medId) {
        onDelete?.(medId);
      }
    };

    return (
      <ModalButton
        button="Delete"
        buttonType="text"
        closable={false}
        maskStyle={{ backgroundColor: 'transparent' }}
        width={416}
      >
        {({ close }) => (
          <>
            <div className="fs2 my20 b7">
              Are you sure you want to delete this medication?
            </div>
            <p>Once it&apos;s deleted, this medication will not show in the medication history.</p>
            <CancelDeleteButtonsComponent
              onDelete={handleDelete}
              onCancel={close}
              cancelBtnText="No"
              deleteBtnText="Yes"
            />
          </>
        )}
      </ModalButton>
    );
  };
