import { CheckCircleFilled } from '@ant-design/icons';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { UpdatedByAt, UpdatedByAtComponent } from '../../../../user/component/UpdatedByAtComponent/UpdatedByAtComponent';

import './MTPRCompleteComponent.scss';

export interface MTPRCompleteComponentProps {
  info: UpdatedByAt;
  lastNote?: string | null;
}

export const MTPRCompleteComponent = ({
  info,
  lastNote,
}: MTPRCompleteComponentProps) => (
  <div className="border secondary-radius mtpr-complete">
    <CheckCircleFilled className="success-svg" />
    <span>Monthly Treatment Plan Review (MTPR) was done on</span>
    <UpdatedByAtComponent info={info} />
    <TooltipComponent type="info-icon-filled" title={lastNote} />
  </div>
);
