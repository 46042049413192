import { ReactNode } from 'react';

export interface PrintVitalTitleComponentProps {
  name: ReactNode;
}

export const PrintVitalTitleComponent = ({
  name,
}: PrintVitalTitleComponentProps) => (
  <div className="fs16 bold">
    {name}
  </div>
);
