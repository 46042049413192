import {
  Button, Form, InputNumber, Slider, Space
} from 'antd';
import useForm from 'antd/es/form/hooks/useForm';
import { SubmitValue } from '../../../../uiComponent/OfflineTimeComponent/AddOfflineTimeFormComponent/AddOfflineTimeFormComponent';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import { AddOfflineActivityFormItemComponent } from '../../../../uiComponent/OfflineTimeComponent/AddOfflineActivityFormItemComponent/AddOfflineActivityFormItemComponent';
import { FormItem } from '../../../../uiComponent/FormItem/FormItem';
import { FormType } from '../../../Input/types';
import { ActivityTypeEnum } from '../../../../uiComponent/OfflineTimeComponent/type';
import { useAFKForm } from '../../hook/useAFKForm';
import FormSubmitButton, { FormSubmitButtonProps } from '../../../../uiComponent/FormSubmitButton/FormSubmitButton';
import './AFKBillableTimeFormComponent.scss';
import EnvConfig from '../../../../configs/envConfig/envConfig';

export interface AFKBillableTimeFormComponentProps extends FormType<SubmitValue>{
  totalAfkTime: number;
  totalTime: number;
}

export const AFKBillableTimeFormComponent = ({
  onSubmit,
  onCancel,
  totalAfkTime,
  isLoading = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  totalTime,
}: AFKBillableTimeFormComponentProps) => {
  const [formHook] = useForm<SubmitValue>();
  const hook = useAFKForm();
  const max = Math.min(EnvConfig.afkMaxTime, totalAfkTime);
  const afkToShow = totalAfkTime > EnvConfig.afkMaxTime ? ` ${EnvConfig.afkMaxTime}mins` : ` ${totalAfkTime} ${totalAfkTime > 1 ? 'minutes' : 'minute'}`;

  const handleSubmit = (s: SubmitValue) => {
    if (s.offlineActivities?.includes(ActivityTypeEnum.OTHER)) {
      onSubmit?.(s);
    } else {
      onSubmit?.({
        ...s,
        otherActivity: undefined,
      });
    }
  };

  const shouldBeDisabled: FormSubmitButtonProps['shouldBeDisabled'] = (values) => {
    // disable submit button if offlineActivities is undefined or if it is loading
    const disable = values.offlineActivities === undefined || isLoading;
    return disable;
  };

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit}
      className="h100"
      form={formHook}
      initialValues={{ timeSpent: 0 }}
    >
      <FixedComponent>
        <FixedComponent.Child>
          <Space direction="vertical" size={10}>
            <div className="fs16 b6">
              We noticed you have been inactive for
              {totalAfkTime > EnvConfig.afkMaxTime && ' more than'}
              <span className="light-blue">
                {afkToShow}
              </span>
            </div>
            <div className="text-gray-scale-2 b4">
              Adjust to add the time below if you did any work outside of the portal
              to take care of this patient.
            </div>
            <div className="ml5 mr5">
              <FormItem info={hook.getInfo('timeSpent')}>
                <Slider
                  min={0}
                  max={max}
                />
              </FormItem>
            </div>
            <div className="flex jc-sb ai-c">
              <FormItem info={hook.getInfo('timeSpent')} className="w140">
                <InputNumber
                  min={0}
                  max={max}
                  precision={0}
                  addonAfter="Mins"
                />
              </FormItem>
              <span className="text-gray-scale-2 b4">
                {afkToShow}
              </span>
            </div>
          </Space>
          <FormItem
            noStyle
            shouldUpdate={hook.shouldUpdate(['timeSpent'])}
          >
            {({ getFieldValue }) => hook.showActivity(getFieldValue) && (
            <div className="mt30">
              <AddOfflineActivityFormItemComponent />
            </div>
            )}
          </FormItem>
        </FixedComponent.Child>
        <FixedComponent.Child isFixed>
          <div className="ant-drawer-footer mt20">
            <Space className="flex jc-e" size={20}>
              <Button onClick={onCancel}>Ignore It</Button>
              <FormSubmitButton
                text="Submit"
                shouldBeDisabled={shouldBeDisabled}
                isLoading={isLoading}
              />
            </Space>
          </div>
        </FixedComponent.Child>
      </FixedComponent>
    </Form>
  );
};
