import { EyeFilled, EyeTwoTone } from '@ant-design/icons';
import { Button, Popover, Tooltip } from 'antd';
import { useState } from 'react';
import {
  AddPatientToWatchlistContainer
} from '../../container/AddPatientToWatchlistContainer/AddPatientToWatchlistContainer';
import './WatchedIndicatorComponent.scss';

export interface WatchedIndicatorComponentProps {
  watched?: boolean;
  onClick?: ()=> void;
  patientId?: string;
  handleRefresh?: ()=> void;
}

export const WatchedIndicatorComponent = ({
  watched = false,
  onClick,
  patientId,
  handleRefresh,
}: WatchedIndicatorComponentProps) => {
  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);

  const hide = () => {
    setHovered(false);
    setClicked(false);
    handleRefresh?.();
  };

  const handleHoverChange = (open: boolean) => {
    if (clicked) return;
    setHovered(open);
    setClicked(false);
  };

  const handleClickChange = (open: boolean) => {
    if (watched) return;
    setHovered(false);
    setClicked(open);
  };

  return (
    <Popover
      className="watched-indicator-component"
      content={(
        <AddPatientToWatchlistContainer
          patientId={patientId}
          onClose={hide}
          onSuccess={hide}
        />
      )}
      overlayClassName="popover-overlay"
      placement="bottomLeft"
      trigger="click"
      open={clicked}
      onOpenChange={handleClickChange}
    >
      <Button
        type="link"
        icon={watched ? (
          <Tooltip
            overlayClassName="tooltip-overlay"
            title="Patient is in watchlist"
            trigger="hover"
            placement="bottomLeft"
          >
            <EyeTwoTone />
          </Tooltip>
        ) : (
          <Tooltip
            overlayClassName="tooltip-overlay"
            title="Add patient to watchlist"
            trigger="hover"
            placement="bottomLeft"
            open={hovered}
            onOpenChange={handleHoverChange}
          >
            <EyeFilled />
          </Tooltip>
        )}
        onClick={onClick}
      />
    </Popover>
  );
};
