import { SupportDeviceEnum } from '../../features/device/type';
import { DeviceModelEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface SupportDeviceEnumComponentProps {
  value?: SupportDeviceEnum;
  deviceModel?: DeviceModelEnum;
}

export const getText = (value?: SupportDeviceEnum, deviceModel?: DeviceModelEnum) => {
  if (deviceModel) {
    switch (deviceModel) {
      case DeviceModelEnum.BPM1: return 'Wi-Fi Router';
      case DeviceModelEnum.BPM1_HOTSPOT: return 'Hotspot';
      default: return null;
    }
  }
  switch (value) {
    case SupportDeviceEnum.WIFI_ROUTER: return 'Wi-Fi Router';
    case SupportDeviceEnum.HOTSPOT: return 'Hotspot';
    default: return null;
  }
};

export const SupportDeviceEnumComponent = ({
  value,
  deviceModel,
}: SupportDeviceEnumComponentProps) => {
  const text = getText(value, deviceModel);
  return (<DisplayOrEmptyComponent value={text} />);
};
