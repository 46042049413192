import dayjs from 'dayjs';

export interface DueInComponentProps {
  dueDate?: string;
}

export const DueInComponent = ({
  dueDate,
}: DueInComponentProps) => {
  const dueDateDayjs = dayjs(dueDate);
  const diffDays = dueDateDayjs.diff(dayjs(), 'day', true);
  return (
    <>
      {}
      {diffDays <= 0 ? ' Overdue'
        : (
          <>
            Due in
            {' '}
            {Math.ceil(diffDays)}
            d
          </>
        )}
    </>
  );
};
