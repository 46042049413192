import { map } from 'lodash';
import { DeviceMonitorMethodEnum } from '../../../../enumComponent/DeviceMonitorMethodEnumComponent/DeviceMonitorMethodEnumComponent';
import { ConnectedDeviceInfo } from '../../type';
import { DeviceMonitorUnassignInfoComponent } from '../DeviceMonitorUnassignInfoComponent/DeviceMonitorUnassignInfoComponent';
import { DeviceHelper } from '../../helper';

export interface DeviceMonitorWarningComponentProps {
  connectedDevices: ConnectedDeviceInfo[];
  unassignDeviceIds?: string[]
  currentMonitorMethod?: DeviceMonitorMethodEnum;
  onUnassign?: (connectedDevice: ConnectedDeviceInfo) => void;
}

export const DeviceMonitorWarningComponent = ({
  connectedDevices,
  unassignDeviceIds = [],
  currentMonitorMethod,
  onUnassign,
}: DeviceMonitorWarningComponentProps) => (
  <div className="pb50">
    {
      currentMonitorMethod
      && map(connectedDevices, (connectedDevice) => {
        const { deviceId, deviceModel } = connectedDevice;
        const deviceMethod = DeviceHelper.getMethodByModel(deviceModel);
        if (
          !unassignDeviceIds.includes(deviceId)
          && deviceMethod !== currentMonitorMethod
        ) {
          switch (deviceMethod) {
            case DeviceMonitorMethodEnum.CELLULAR_DEVICE:
            case DeviceMonitorMethodEnum.WIFI_DEVICE:
            case DeviceMonitorMethodEnum.NON_APP_MONITOR:
              return (
                <DeviceMonitorUnassignInfoComponent
                  key={deviceId}
                  deviceId={deviceId}
                  deviceModel={deviceModel}
                  onUnassign={() => onUnassign?.(connectedDevice)}
                />
              );
            default:
              return null;
          }
        }
        return null;
      })
    }
  </div>
);
