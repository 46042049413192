import { Modal } from 'antd';
import { useCallback, useState } from 'react';

export const useInsuranceBlockPrompt = () => {
  const [isInsuranceEdit, setIsInsuranceEdit] = useState(false);

  const insuranceBlockPrompt = useCallback<() => Promise<boolean>>(() => (
    new Promise((resolve) => {
      Modal.warn({
        title: 'Insurance is not completed',
        content: 'Please save or cancel your changes before proceeding.',
        onOk: () => resolve(false),
        onCancel: () => resolve(false),
      });
    })
  ), []);

  return {
    isInsuranceEdit,
    setIsInsuranceEdit,
    insuranceBlockPrompt,
  };
};
