import { FreqEnumComponent } from '../../enumComponent/FreqEnumComponent/FreqEnumComponent';
import { FrequencyUnitEnum } from '../../uc-api-sdk';
import { EnumSelectComponent } from '../EnumSelectComponent/EnumSelectComponent';
import { InputType } from '../../features/Input/types';

export interface FreqSelectComponentProps extends
  InputType<FrequencyUnitEnum | string> {
}

export const FreqSelectComponent = ({
  value,
  onChange,
}: FreqSelectComponentProps) => (
  <EnumSelectComponent
    options={FrequencyUnitEnum}
    optionRenderer={FreqEnumComponent}
    value={value}
    onChange={onChange}
  />
);
