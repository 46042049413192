import { Dayjs } from 'dayjs';
import { USA_DATE } from '../../../../constants/timeFormat';
import { SeverityHelper } from '../../helper/severityHelper';
import { TMChartData } from './TMChartComponent';
import { Point } from '../../type';
import TimezoneService from '../../../../helpers/timezone/timezoneService';

export const createMarker = (data: TMChartData) => ({
  marker: {
    symbol: data.type === 'vital' ? 'circle' : 'triangle',
    halo: null,
    radius: 6,
    states: {
      hover: {
        fillColor: '#FCA642',
        lineColor: 'white',
        lineWidth: 0,
        halo: null,
        animation: {
          duration: 20,
        },
      },
    },
  },
});

export interface TMSeriesInfo<T extends Point> {
  normal: T[];
  risk: T[];
  critical: T[];
}

export const createTMChartSeries = <T extends Point>(info: TMSeriesInfo<T>) => ([
  {
    data: info.normal,
    findNearestPointBy: 'xy',
    name: 'Normal',
    severity: 'NORMAL',
    lineWidth: 0,
    color: '#7bd002',
    lineColor: '#7bd002',
    states: {
      hover: {
        lineWidthPlus: 0,
      },
    },
    marker: {
      enabled: true,
      radius: 4,
      symbol: 'triangle',
    },
  },
  {
    data: info.critical,
    findNearestPointBy: 'xy',
    name: 'Critical',
    severity: 'CRITICAL',
    lineWidth: 0,
    lineColor: '#EE6161',
    color: '#EE6161',
    states: {
      hover: {
        lineWidthPlus: 0,
      },
    },
    marker: {
      enabled: true,
      radius: 4,
      symbol: 'triangle',
    },
  },
  {
    data: info.risk,
    findNearestPointBy: 'xy',
    name: 'Risk',
    severity: 'RISK',
    lineWidth: 0,
    lineColor: '#FFBD03',
    color: '#FFBD03',
    states: {
      hover: {
        lineWidthPlus: 0,
      },
    },
    marker: {
      enabled: true,
      radius: 4,
      symbol: 'triangle',
    },
  },
]);

export const createTMChartOptions = (
  series: ReturnType<typeof createTMChartSeries>,
  fromDate: Dayjs,
  toDate: Dayjs,
) => ({
  chart: {
    type: 'line',
  },
  title: null,
  plotBackgroundColor: '#0099FF',
  xAxis: {
    type: 'datetime',
    tickWidth: 2,
    tickInterval: 24 * 3600 * 1000,
    min: fromDate.valueOf(),
    max: toDate.valueOf(),
    labels: {
      formatter: (xAxis: { value: Date; }) => (
        TimezoneService.calcDateTimeDayjs(xAxis.value).format(USA_DATE)
      ),
    },
  },
  yAxis: {
    title: {
      text: 'Fahrenheit',
    },
    max: 112,
    gridLineColor: 'transparent',
    plotBands: [{
      from: 97,
      to: 99,
      color: 'rgb(119,119,119,0.05)',
    }],
  },
  legend: {
    align: 'left',
    verticalAlign: 'top',
  },
  tooltip: {
    crosshairs: true,
    dateTimeLabelFormats: {
      day: '%m/%e/%Y',
    },
    // @ts-ignore
    pointFormatter() {
      // @ts-ignore
      const { y, severity, notes } = this;
      return `
        ${SeverityHelper.getText(severity)}: <b>${y} F</b>
        </br>
        Notes: ${notes}
      `;
    },
  },
  time: {
    useUTC: false,
    timezone: TimezoneService.guessTimezone(),
  },
  credits: {
    enabled: false,
  },
  series,
});
