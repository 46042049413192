import { ColumnType } from 'antd/lib/table';
import dayjs from 'dayjs';
import parser from 'html-react-parser';
import {
  BillableMonthlySummary
} from '../../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { ADMIN_PORTAL_TEXTS } from '../../../adminPortal2/constants/texts';

export const createColumns = () => ({
  monthOfYear: (): ColumnType<BillableMonthlySummary> => ({
    title: 'Time',
    dataIndex: 'monthOfYear',
    render: (_, record?: BillableMonthlySummary) => {
      const formattedDateString = `${String(record?.monthOfYear)?.substring(0, 4)}-${String(record?.monthOfYear)?.substring(4, 6)}-01`;
      const parsedDate = dayjs(formattedDateString);
      return (
        <DisplayDateComponent value={parsedDate} format="MMM_YYYY" />
      );
    },
    sorter: true,
    defaultSortOrder: 'descend',
    width: '20%',
  }),
  billableTime: (): ColumnType<BillableMonthlySummary> => ({
    title: (
      <TooltipComponent
        type="info-icon"
        overlayClassName="page-title-tooltip-overlay"
        title={(
          <>
            {parser(ADMIN_PORTAL_TEXTS.tooltips.billableTimeInTable)}
          </>
        )}
      >
        Billable Time
      </TooltipComponent>
    ),
    render: (v: string, l: BillableMonthlySummary) => (
      <div className="flex inline-block ai-c">
        <div className="minW80">
          <DisplayOrEmptyComponent value={l.totalTime} />
          {' '}
          mins
        </div>
        <div className="pl24">
          {l.timeEligible ? 'Eligible' : 'Ineligible' }
        </div>
      </div>
    ),
    width: '40%',
  }),
  measurementDays: (): ColumnType<BillableMonthlySummary> => ({
    title: (
      <TooltipComponent
        type="info-icon"
        overlayClassName="page-title-tooltip-overlay"
        title={(
          <>
            {parser(ADMIN_PORTAL_TEXTS.tooltips.measurementDaysInTable)}
          </>
        )}
      >
        Measurement days
      </TooltipComponent>
    ),
    render: (v: string, l: BillableMonthlySummary) => (
      <div className="flex inline-block ai-c">
        <div className="minW80">
          <DisplayOrEmptyComponent value={v} />
          {' '}
          days
        </div>
        <div className="pl24">
          {l.measurementEligible ? 'Eligible' : 'Ineligible'}
        </div>
      </div>
    ),
    width: '40%',
  }),
});
