import { Form } from 'antd';
import { useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { InquiringProviderContainer } from '../../container/InquiringProviderContainer/InquiringProviderContainer';
import { InsuranceItemComponent, InsuranceItemComponentProps } from '../InsuranceItemComponent/InsuranceItemComponent';

export interface InquiringProviderNameFormItemComponentProps {
  itemProps?: InsuranceItemComponentProps;
  span?: number;
  required?: boolean;
}

export const InquiringProviderNameFormItemComponent = ({
  itemProps,
  span = 24,
  required = true
}: InquiringProviderNameFormItemComponentProps) => {
  const form = Form.useFormInstance();
  const insuranceForm = useInsuranceForm({ form });

  return (
    <InsuranceItemComponent
      label={insuranceForm.getLabel('inquiringProvider')}
      content={(
        <FormItem
          name={insuranceForm.getName('inquiringProvider')}
          required
        >
          <InquiringProviderContainer />
        </FormItem>
      )}
      span={span}
      required={required}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...itemProps}
    />

  );
};
