import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { SelectType } from '../../../../features/Input/types';
import { useCoworker } from '../../../../hooks/useCoworker/useCoworker';
import { EmployeeStatus, RoleTypeEnum } from '../../../../uc-api-sdk';
import { ProviderSelectComponent } from '../ProviderSelectComponent/ProviderSelectComponent';
import { useDeepCompareEffect } from '../../../../hooks/useDeepCompareEffect';
import EmployeeInfo from '../../../../hooks/useUserInfo/employeeInfo';

export interface ProviderSelectContainerProps extends SelectType<string> {
  clinicIds?: string[];
  sendOnMount?: boolean;
  externalEmployeeForPatientId?: string;
  needToFilter?: boolean;
  placeholder?: string;
  allowClear?: boolean;
  disabled?: boolean;
  isSelectedPatient?: boolean;
  unassignedOnly?: boolean;
  onProviderListLoaded?: (providers: EmployeeInfo[]) => void;
}

export const ProviderSelectContainer = ({
  value,
  onChange,
  clinicIds,
  sendOnMount,
  externalEmployeeForPatientId,
  needToFilter = externalEmployeeForPatientId ? false : undefined,
  placeholder,
  allowClear,
  disabled,
  isSelectedPatient,
  unassignedOnly,
  onProviderListLoaded,
  showSearch,
}: ProviderSelectContainerProps) => {
  const { info } = usePatientContext();
  const patientClinicId = info?.patientInfo?.clinicId as string;
  const searchClinicIds = clinicIds || (patientClinicId ? [patientClinicId] : undefined);

  const coworkerInfo = useCoworker({
    roleFilter: [RoleTypeEnum.PROVIDER],
    clinicIdList: searchClinicIds,
    sendOnMount: sendOnMount || !!searchClinicIds,
    externalEmployeeForPatientId,
    needToFilter,
    statusListFilter: [EmployeeStatus.ACTIVATED, EmployeeStatus.PENDING],
  });

  useDeepCompareEffect(() => {
    onProviderListLoaded?.(coworkerInfo.myCoWorkerList);
  }, [coworkerInfo.myCoWorkerList]);

  return (
    <ProviderSelectComponent
      loading={coworkerInfo.isLoading}
      providers={coworkerInfo.myCoWorkerList}
      value={value}
      onChange={onChange}
      allowClear={allowClear}
      placeholder={placeholder}
      disabled={disabled}
      showSearch={showSearch}
      isSelectedPatient={isSelectedPatient}
      unassignedOnly={unassignedOnly}
    />
  );
};
