import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { usePatientUpdateEnrollment } from '../../../../uc-api-sdk';
import { TechOnboardingCardComponent, TechOnboardingCardComponentProps } from '../../component/OnboardingCardComponent/TechOnboardingCardComponent';

export interface TechOnboardingCardContainerProps {
  patientInfo: PatientInfo;
}

export const TechOnboardingCardContainer = ({
  patientInfo,
}: TechOnboardingCardContainerProps) => {
  const enrollmentInfo = usePatientUpdateEnrollment({});

  const handleOnWaiveTechOnboardingChange: TechOnboardingCardComponentProps['onWaiveChange'] = (
    isWaived,
  ) => {
    ApiRequestHelper.tryCatch(
      enrollmentInfo.send({
        params: {
          memberId: patientInfo.id,
          request: { waiveTechOnboarding: isWaived }
        }
      }),
      {
        success: '',
        error: 'Failed to update waive tech onboarding. Please try again',
        onSuccess: () => {
          patientInfo.refetch(['enrollmentInfoRefetch']);
        }
      }
    );
  };

  const debouncedHandleOnWaiveTechOnboardingChange = useDebounce(handleOnWaiveTechOnboardingChange);

  return (
    <TechOnboardingCardComponent
      patientInfo={patientInfo}
      onWaiveChange={debouncedHandleOnWaiveTechOnboardingChange}
    />
  );
};
