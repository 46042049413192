import { ReactNode } from 'react';
import classNames from 'classnames';
import { PatientCommonCardTitleComponent } from '../PatientCommonCardTitleComponent/PatientCommonCardTitleComponent';

export interface PatientProfileProgramsItemComponentProps {
  title?: ReactNode;
  className?: string;
  children: ReactNode;
  isEditing?: boolean;
  formContent?: ReactNode;
}

export const PatientProfileProgramsItemComponent = ({
  title,
  className = '',
  children,
  isEditing,
  formContent,
}: PatientProfileProgramsItemComponentProps) => (
  <>
    <PatientCommonCardTitleComponent>
      {title}
    </PatientCommonCardTitleComponent>
    <div
      className={classNames({
        my20: true,
        [className]: !!className,
        'programs-edit': !!isEditing,
      })}
    >
      {
        (isEditing && formContent)
          ? formContent
          : children
      }
    </div>
  </>
);
