/* eslint-disable react/jsx-props-no-spreading */
import {
  Checkbox,
  CheckboxOptionType,
  Space,
} from 'antd';
import { map } from 'lodash';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { InputTypeArray } from '../../features/Input/types';

export interface CheckboxGroupComponentProps<T> extends
  InputTypeArray<T>,
  Omit<CheckboxGroupProps, 'onChange' | 'value' | 'options'>
{
  options: CheckboxOptionType[];
  direction?: 'vertical' | 'horizontal';
}

export const CheckboxGroupComponent = <T extends CheckboxValueType, >({
  value,
  onChange,
  direction,
  options,
  ...props
}: CheckboxGroupComponentProps<T>) => {
  const handleChange: CheckboxGroupProps['onChange'] = (v) => {
    onChange?.(v as T[]);
  };

  return (
    <Checkbox.Group value={value || []} onChange={handleChange} {...props}>
      <Space direction={direction}>
        {map(options, ({ label, ...v }) => (
          <Checkbox {...v} key={v.value.toString()}>{label}</Checkbox>
        ))}
      </Space>
    </Checkbox.Group>
  );
};
