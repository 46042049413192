import { useEffectOnce } from 'usehooks-ts';
import { CalendarSourceEnum, ClinicEvent, VisitMethodEnum } from '../../../../uc-api-sdk';
import notificationSound from '../../../../assets/sound/notification.mp3';
import { CalendarNotificationComponent } from '../../../calendar/component/CalendarNotificationComponent/CalendarNotificationComponent';
import { playSound } from '../../../../helpers/sound';

export interface ZoomNotificationComponentProps extends ClinicEvent {
  goCheckIn: (patientId: string, visitId?: string | null) => void;
}

export const ZoomNotificationComponent = ({
  goCheckIn,
  ...clinicEvent
}: ZoomNotificationComponentProps) => {
  const {
    id: visitId,
    patientInfo,
  } = clinicEvent || {};
  const patientId = patientInfo?.id || '';

  const handleGoCheckIn = () => {
    goCheckIn(patientId, visitId);
  };

  useEffectOnce(() => {
    playSound(notificationSound);
  });

  return (
    <div key={visitId} className="zoom-notification-body">
      <CalendarNotificationComponent
        key={visitId}
        clinicEvent={{
          ...clinicEvent,
          calendarSource: CalendarSourceEnum.UC_VISIT,
          visitMethod: VisitMethodEnum.VIRTUAL,
        }}
        onClick={handleGoCheckIn}
        shouldPlaySound={false}
        isZoomNotification
      />
    </div>
  );
};
