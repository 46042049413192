export const TicketTexts = {
  resolved: 'Task resolved',
  createSuccess: 'Task created',
  editSuccess: 'Task edited successfully',
  reopenSuccess: 'Task reopened successfully',
  deleteSuccess: 'Task deleted successfully',
  addComment: 'Comment added.',
  error: 'An error occurred. Please try again.',
  error1001: 'Only the assignee or creator can resolve the ticket.',
};

export const TicketPlaceHolders = {
  ReOpen: 'Add a comment to reopen',
  Edit: 'Add a comment for this edit',
  Resolve: 'Add a comment to resolve the task',
  CommentOly: 'Add a comment only',
};

export const TicketCommentMessages = {
  Close: 'A comment is required to resolve the task',
  ReOpen: 'A comment is required to reopen the task',
  Edit: 'A comment is required to edit the task',
  deleteSuccess: 'Task deleted successfully',
  error: 'An error occurred. Please try again',
  submit: 'Please enter a comment to submit',
};
