import { isBoolean } from 'lodash';
import { DiseaseHistoryItem } from '../../../../../../uc-api-sdk';
import { YesNoDisplayComponent } from '../../../../../../uiComponent/YesNoDisplayComponent/YesNoDisplayComponent';
import { DiseaseHistoryCurrentTreatmentComponent } from '../../../../../diseaseHistory/component/DiseaseHistoryDisplayComponent/DiseaseHistoryCurrentTreatmentComponent';
import { DiseaseHistoryMeasureFrequencyDisplayComponent } from '../../../../../diseaseHistory/component/DiseaseHistoryDisplayComponent/DiseaseHistoryMeasureFrequencyDisplayComponent';
import { PrintDiseaseHistorySpecialistComponent } from '../PrintDiseaseHistorySpecialistComponent/PrintDiseaseHistorySpecialistComponent';
import { PrintDiseaseHistoryDisplayComponent } from './PrintDiseaseHistoryDisplayComponent/PrintDiseaseHistoryDisplayComponent';
import { PrintDiseaseTestedBeforeComponent } from './PrintDiseaseTestedBeforeComponent';

export interface PrintDiseaseHistoryByVitalComponentProps {
  diseaseHistory: DiseaseHistoryItem;
}

export const PrintDiseaseHistoryByVitalComponent = ({
  diseaseHistory,
}: PrintDiseaseHistoryByVitalComponentProps) => (
  <>
    <PrintDiseaseHistoryDisplayComponent
      label="Years with this condition"
      value={diseaseHistory.yearsWithCondition}
      extraValue={`year${(diseaseHistory.yearsWithCondition || 0) <= 1 ? '' : 's'}`}
    />
    {
      isBoolean(diseaseHistory.hasSeenDoctor)
      && (
        <YesNoDisplayComponent
          value={diseaseHistory.hasSeenDoctor}
          yesDisplay="Has seen a doctor in the past 6 months"
          noDisplay="Has not seen a doctor in the past 6 months"
        />
      )
    }
    <PrintDiseaseHistorySpecialistComponent
      diseaseHistory={diseaseHistory}
    />
    <PrintDiseaseTestedBeforeComponent
      diseaseHistory={diseaseHistory}
    />
    <DiseaseHistoryMeasureFrequencyDisplayComponent
      diseaseHistory={diseaseHistory}
      itemClassName="flex"
    />
    <DiseaseHistoryCurrentTreatmentComponent
      diseaseHistory={diseaseHistory}
    />
  </>
);
