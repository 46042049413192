import _ from 'lodash';
import { LabResultTemplate, useTemplatesSearch } from '../../../uc-api-sdk';

export const useLabResultTemplatesSearch = (
  templateId?: string,
) => {
  const template = useTemplatesSearch({
    options: {
      sendOnMount: true,
    },
    params: {
      searchRequest: {
        filter: {
          templateId,
        },
      },
    },
  });
  const temp = _.first(template.dataObj?.data.content.rawValue as LabResultTemplate[]);
  return { template, items: temp?.items };
};
