import { Select } from 'antd';
import { useCallback } from 'react';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { AccountHelper } from '../../../../helpers/account';
import { CalendarSourceEnum, RoleTypeEnum } from '../../../../uc-api-sdk';
import { EmployeeMultipleSelectContainer } from '../../../Employee/container/EmployeeMultipleSelectContainer/EmployeeMultipleSelectContainer';
import { EmployeeSelectContainerProps } from '../../../Employee/container/EmployeeSelectContainer/EmployeeSelectContainer';
import { InputTypeArray } from '../../../Input/types';
import { MiniUserComponent } from '../../../user/component/MiniUserComponent/MiniUserComponent';
import './EmployeeCalendarSelectComponent.scss';
import { EmployeeSelectLabelComponent } from './EmployeeSelectLabelComponent';

export interface EmployeeCalendarSelectComponentProps extends InputTypeArray<string> {
  source?: CalendarSourceEnum;
  patientId?: string;
  roles?: RoleTypeEnum[];
}

export const EmployeeCalendarSelectComponent = ({
  value,
  onChange,
  source,
  patientId,
  roles,
}: EmployeeCalendarSelectComponentProps) => {
  const { isProvider } = useLoggedInUserFromContext();

  const optionRenderer = useCallback<Exclude<
    EmployeeSelectContainerProps['optionRenderer'], undefined>
  >((d) => (
    <Select.Option
      key={d.id}
      label={d.fullName}
    >
      <MiniUserComponent
        avatar={d.info.employeeProfile?.avatar?.url || undefined}
        name={AccountHelper.getFullName(d.info.employeeProfile)}
        role={d.info.employeeProfile?.title || undefined}
        email={d.info.email || undefined}
      />
    </Select.Option>
  ), []);

  const tagRender = useCallback<Exclude<EmployeeSelectContainerProps['tagRender'], undefined>>((props) => (
    <EmployeeSelectLabelComponent
      label={props.label}
      value={props.value}
      closable={props.closable}
      onClose={props.onClose}
      source={source}
    />
  ), [source]);

  return (
    <EmployeeMultipleSelectContainer
      className="employee-calendar-select-component"
      popupClassName="employee-calendar-popup"
      optionRenderer={optionRenderer}
      tagRender={tagRender}
      onChange={onChange}
      value={value}
      patientId={patientId}
      internalEmployeeForPatient={isProvider && !!patientId}
      shouldFilterBasedOnRole={isProvider || !!patientId}
      internalEmployeeForMyMedicalOrg={isProvider || undefined}
      roles={roles}
    />
  );
};
