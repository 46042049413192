import { useLocalStorage } from 'usehooks-ts';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { PatientSourceEnum } from '../../../../uc-api-sdk';
import { AlertCardComponent } from '../../../../uiComponent/AlertCardComponent/AlertCardComponent';
import { useUCVersion } from '../../../auth/hooks/useUCVersion';
import isOldMobileAppVersion from '../../helper/helpers';
import { StorageKeyEnum } from '../../../../hooks';
import './OldAppVersionNoteComponent.scss';

export const OldAppVersionNoteComponent = () => {
  const { info } = usePatientContext();
  const { mobileVersion } = useUCVersion();
  const patientId = info?.id;
  const storageKey = `${StorageKeyEnum.OLD_APP_VERSION_NOTE_CLOSED}_${patientId}`;

  const [isClosed, setIsClosed] = useLocalStorage<boolean>(
    storageKey,
    false
  );

  const handleClose = () => {
    setIsClosed(true);
  };

  const appVersion = info?.patientAppVersion?.mobileInfo?.appVersion;
  const isPatientMigrated = info?.patientInfo?.patientSource === PatientSourceEnum.MIGRATION
    || info?.patientInfo?.source === PatientSourceEnum.MIGRATION;
  const latestAppVersion = mobileVersion?.split(' ').at(-1);
  const isOldVersion = isOldMobileAppVersion(latestAppVersion as string, appVersion as string);

  if (!isClosed && isPatientMigrated && appVersion && isOldVersion) {
    return (
      <div>
        <AlertCardComponent
          paddingClassName="padding-small"
          showCloseIcon
          type="warning"
          message={(
            <div className="old-app-version-note-component">
              <b>The patient's App Version is outdated.</b>
              {' So the patient may not be able to '}
              <u>send/receive messages</u>
              {', '}
              <u>attend the virtual visit in the App</u>
              {', and '}
              <u>see the visit card and reminder</u>
              . Please kindly contact patient to prompt an update. Apologies for this inconvenience.
            </div>
          )}
          onClose={handleClose}
        />
      </div>
    );
  }

  return (
    null
  );
};
