import {
  InFilter,
  LabResult, LabResultClass, ReferenceLevelEnum, SearchRequest, Sort$Direction, useLabResultSearch,
} from '../../../uc-api-sdk';
import { HemoglobinA1cTemplateId } from '../constants';
import { useTableChange } from '../../../hooks/useTableChange/useTableChange';
import { sorterHelper } from '../../../hooks/useTableChange/sorterHelper';
import { baseAPIHelper } from '../../../hooks/useTableChange/baseAPIHelper';
import { useDeletePaginated } from '../../../hooks/useDeletePaginated/useDeletePaginated';

export const useLabResultInfo = (
  patientId?: string,
  templateId?: string,
) => {
  const tableChange = useTableChange<
    LabResultClass,
    SearchRequest<LabResult>['filter']
    >({
      sort: [{
        direction: Sort$Direction.DESC,
        property: 'dateCollected',
      }],
      sortProcessor: (sorter) => sorterHelper(sorter),
      filterProcessor: (filters) => {
        const newFilters: SearchRequest<LabResult>['filter'] = {};
        if (filters.flag) {
          const filterIn: InFilter<ReferenceLevelEnum> = {
            in: filters.flag as ReferenceLevelEnum[],
          };
          newFilters.resultFlagIn = filterIn;
        }
        return newFilters;
      },
      pageSize: 5,
    });

  const convertedPage = baseAPIHelper(tableChange.processedSort, tableChange.pageInfo);

  let filter: LabResult = {};
  if (templateId !== HemoglobinA1cTemplateId) {
    filter = {
      userId: patientId,
      templateIdIn: { nin: [HemoglobinA1cTemplateId] },
    };
  } else {
    filter = {
      userId: patientId,
      templateId: HemoglobinA1cTemplateId,
      resultFlagIn: tableChange.processedFilter?.resultFlagIn,
    };
  }

  const info = useLabResultSearch({
    options: {
      sendOnMount: true,
    },
    params: {
      filter,
      pageInfo: convertedPage,
    },
  });

  const allInfo = useLabResultSearch({
    options: {
      sendOnMount: false,
    },
    params: {
      filter,
      pageInfo: {
        pagination: false,
        ...convertedPage,
      },
    },
  });

  const { onDelete } = useDeletePaginated(info, tableChange);

  const refetchInfo = () => {
    info.refetch();
    allInfo.refetch();
  };

  return {
    info,
    tableChange,
    onDelete,
    allInfo,
    refetchInfo,
  };
};
