import { useCallback } from 'react';
import { map, reduce } from 'lodash';
import {
  CalendarSourceEnum,
  ClinicEvent,
  VisitParticipant,
  VisitParticipantRoleEnum,
} from '../../../uc-api-sdk';
import * as useCreateVisitForm from './useCreateVisitForm';
import { ParticipantsEnum } from '../type/participants';
import { SubmitValue } from '../component/CreateVisitFormComponent/CreateVisitFormComponent';
import { TimeService } from '../../../helpers/time/timeService';
import TimezoneService from '../../../helpers/timezone/timezoneService';
import { useLoggedInUserFromContext } from '../../../contexts/loggedInUserContext';

export const useCreateVisitInitialValues = () => {
  const { userId = '' } = useLoggedInUserFromContext();
  const createVisitForm = useCreateVisitForm.useCreateVisitForm();

  const getParticipantsInitialValue = useCallback((participants: VisitParticipant[]) => (
    reduce(participants, (res, curr) => {
      const { participantId } = curr;
      if (participantId) {
        if (curr.role === VisitParticipantRoleEnum.RD_HC) {
          res.participants?.push(ParticipantsEnum.RdHc);
          res.rdhc = participantId;
        }
        if (curr.role === VisitParticipantRoleEnum.CA) {
          res.participants?.push(ParticipantsEnum.CA);
          res.ca = participantId;
        }
        if (curr.role === VisitParticipantRoleEnum.MD) {
          res.participants?.push(ParticipantsEnum.MD);
          res.md = participantId;
        }
        if (curr.role === VisitParticipantRoleEnum.MA) {
          res.participants?.push(ParticipantsEnum.MA);
          res.ma = participantId;
        }
      }
      return res;
    }, { [createVisitForm.getName('participants')]: [] } as Partial<SubmitValue>)
  ), []);

  const getOtherEventParticipantsInitialValue = useCallback((participants: VisitParticipant[]) => (
    map(participants, (v) => v.participantId).filter((v): v is string => !!v)
  ), []);

  const getInitialValues = useCallback((info: ClinicEvent) => {
    const startTime = TimezoneService.calcDateTime(info?.startTime as string);
    const endTime = TimezoneService.calcDateTime(info?.endTime as string);
    const isVisit = info?.calendarSource === CalendarSourceEnum.UC_VISIT;
    const isOtherEvent = info?.calendarSource === CalendarSourceEnum.OTHER;

    return {
      [createVisitForm.getName('patient')]: info?.patientId,
      [createVisitForm.getName('eventType')]: info.calendarSource || CalendarSourceEnum.UC_VISIT,
      [createVisitForm.getName('eventTitle')]: info?.eventTitle,
      [createVisitForm.getName('visitType')]: info?.visitType,
      [createVisitForm.getName('visitMethod')]: info?.visitMethod,
      [createVisitForm.getName('visitDate')]: startTime.clone().startOf('day'),
      [createVisitForm.getName('startTime')]: TimeService.getTotalMins(startTime.clone()),
      [createVisitForm.getName('endTime')]: TimeService.getTotalMins(endTime.clone()),
      [createVisitForm.getName('description')]: info?.description,
      ...(isVisit ? getParticipantsInitialValue(info?.visitParticipants || []) : {}),
      [createVisitForm.getName('otherEventParticipants')]: (isOtherEvent
        ? getOtherEventParticipantsInitialValue(info.visitParticipants || [])
        : [userId]) as string[],
    };
  }, [getParticipantsInitialValue, getOtherEventParticipantsInitialValue]);

  return {
    getParticipantsInitialValue,
    getOtherEventParticipantsInitialValue,
    getInitialValues,
  };
};
