import { BillableEventStatusEnumComponent } from '../../../../enumComponent/BillableEventStatusEnumComponent/BillableEventStatusEnumComponent';
import { BillableEventStatusEnum } from '../../../../uc-api-sdk';
import TextWithSubAndLink from '../../../../uiComponent/TextWithSubAndLinkComponent/TextWithSubAndLinkComponent';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';

export interface BillingTimeStatusColumnComponentProps {
  status: BillableEventStatusEnum;
  showIcon?: boolean;
}

export const BillingTimeStatusColumnComponent = ({
  status,
  showIcon,
}: BillingTimeStatusColumnComponentProps) => {
  const renderReviewRequired = () => (
    <div className="flex gap0">
      <div className="link-color underlined">
        <TextWithSubAndLink
          text={<BillableEventStatusEnumComponent value={status} showIcon={showIcon} />}
          link="/care-portal/utilities/billable-time-review"
        />
      </div>
      <TooltipComponent
        title="Care Team must review and correct any questionable billable entries."
        placement="top"
        type="info-icon"
        highlightOnHover={false}
        className="text-secondary-gray"
      />
    </div>
  );

  const renderReviewExpired = () => (
    <TooltipComponent
      title="The deadline for reviewing the questionable billable entry is 12:00 AM on the 3rd of the month. After that, no further corrections can be made."
      placement="top"
      type="info-icon"
      highlightOnHover={false}
      className="text-secondary-gray"
    >
      <BillableEventStatusEnumComponent value={status} showIcon={showIcon} />
    </TooltipComponent>
  );

  switch (status) {
    case BillableEventStatusEnum.REVIEW_REQUIRED:
      return renderReviewRequired();
    case BillableEventStatusEnum.REVIEW_EXPIRED:
      return renderReviewExpired();
    default:
      return <BillableEventStatusEnumComponent value={status} showIcon={showIcon} />;
  }
};
