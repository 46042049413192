import { Button, Form } from 'antd';
import { useState } from 'react';
import { createRequiredRule } from '../../../../helpers/form';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { TicketCommentMessages, TicketPlaceHolders } from '../../constant/text';
import { useTicketCommentForm } from '../../hook/useTicketCommentForm';
import { TicketCommentFormItemComponent } from '../TicketCommentFormItemComponent/TicketCommentFormItemComponent';

export interface SubmitValue {
  comment: string;
  isCommentOnly: true;
  generateCareNote: boolean;
}

export interface TicketResolveAndCommentFormComponentProps {
  onSubmit?: (value: SubmitValue) => void;
  onEditClick?: () => void;
  onResolve?: () => void;
}

export const TicketResolveAndCommentFormComponent = ({
  onSubmit,
  onEditClick,
  onResolve,
}: TicketResolveAndCommentFormComponentProps) => {
  const hook = useTicketCommentForm();
  const { value: isLoading, setTrue, setFalse } = useBoolean();
  const [placeholder, setPlaceholder] = useState(TicketPlaceHolders.Resolve);
  const [ruleMessage, setRuleMessage] = useState('');

  const handleSubmit = async (v: SubmitValue) => {
    try {
      const submit = hook.handleSubmitAndReset(onSubmit);
      if (placeholder === TicketPlaceHolders.Resolve) onResolve?.();
      setTrue();
      await submit?.(v);
      setFalse();
    } catch (e) {
      setFalse();
    }
  };

  return (
    <Form
      form={hook.form}
      onFinish={handleSubmit}
      initialValues={{ generateCareNote: true }}
    >
      <div className="border-t">
        <div className="mt10 pl20 pr20 p10">
          <TicketCommentFormItemComponent
            commentFormItemProps={{
              info: hook.getInfo('comment'),
              dependencies: [hook.getName('isCommentOnly')],
              rules: [createRequiredRule(ruleMessage)]
            }}
            commentOnlyFormItemProps={{ info: hook.getInfo('isCommentOnly') }}
            generateCareNoteFormItemProps={{ name: hook.getName('generateCareNote'), className: 'mb0' }}
            showAddCommentOnly
            placeholder={placeholder}
          />
          <div className="flex jc-sb mb10">
            <Button
              type="link"
              className="b5"
              onClick={onEditClick}
            >
              Edit
            </Button>

            <FormItem
              noStyle
              shouldUpdate={hook.shouldUpdate(['isCommentOnly'])}
            >
              {({ getFieldValue }) => {
                setPlaceholder(hook.getValue('isCommentOnly', getFieldValue)
                  ? TicketPlaceHolders.CommentOly
                  : TicketPlaceHolders.Resolve);
                setRuleMessage(hook.getValue('isCommentOnly', getFieldValue) ? TicketCommentMessages.submit : TicketCommentMessages.Close);

                return (
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    disabled={isLoading}
                    className="b5"
                  >
                    {hook.getSubmitButtonText(getFieldValue)}
                  </Button>
                );
              }}
            </FormItem>
          </div>
        </div>
      </div>
    </Form>
  );
};
