import { GetFieldValue } from '../../types/form';
import { FormOptions, useFormHookFactory } from '../useFormHookFactory/useFormHookFactory';

export const useMedicationContainerForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    compliance: {
      name: 'reconciliationCompliance',
      label: 'Medication Reconciliation & Compliance',
      required: true,
    },
    complianceOther: {
      name: 'reconciliationComplianceOther',
      label: '',
    },
    otherInfo: {
      name: 'otherInfo',
      label: 'Other Medication Information',
    },
    notes: {
      name: 'extraNote',
      label: 'Notes',
    },
  }, options);

  const checkIfOtherCompliance = (getFieldValue: GetFieldValue) => (
    factory.getValue('compliance', getFieldValue) === 'Other'
  );

  return {
    ...factory,
    checkIfOtherCompliance,
  };
};
