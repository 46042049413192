import { QuestionCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useEffect } from 'react';
import useBoolean from '../../hooks/useBoolean/useBoolean';
import { useLiveX } from '../../hooks/useLiveX/useLiveX';
import './LiveXChatbotComponent.scss';

export interface GlobalWindow extends Window {
  livexChatbot?: {
    openStatus: () => 'open' | 'close';
    open: () => void;
    close: () => void;
  };
}

export const LiveXComponent = () => {
  const { insertLiveX } = useLiveX();
  insertLiveX();
  const globalWindow = window as GlobalWindow;
  const { livexChatbot } = globalWindow;
  const [state, setState] = React.useState(livexChatbot?.openStatus());
  const { value: isReady, setTrue: setReady } = useBoolean(!!livexChatbot);

  const waitForScript = () => { // useEffect in window object doesn't work
    if ('livexChatbot' in window) {
      setReady();
    } else {
      setTimeout(() => {
        waitForScript();
      }, 100);
    }
    return null;
  };

  const handleClick = () => {
    const s = livexChatbot?.openStatus();
    if (s === 'open') {
      livexChatbot?.close();
      setState('close');
    } else {
      livexChatbot?.open();
      setState('open');
    }
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const element = document.querySelector('livex-chatbot-header-buttons-container > livex-chatbot-icon-button:last-of-type');
      element?.addEventListener('click', () => {
        setState('close');
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });

    return () => {
      observer.disconnect();
    };
  }, [globalWindow.livexChatbot]);

  return isReady ? (
    <Button type="text" className="p0" onClick={handleClick}>
      <QuestionCircleFilled
        style={{ color: state === 'open' ? '#1890FF' : '#2D3238' }}
        className="header-control-button help-icon"
      />
    </Button>
  ) : <div>{waitForScript()}</div>;
};
