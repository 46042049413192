import { ReactNode } from 'react';

export type LayoutItemComponentProps = {
  children: ReactNode;
} & {
    [propKey in string]?: unknown;
  }

export const LayoutItemComponent = ({
  children,
}: LayoutItemComponentProps) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>{children}</>
);
