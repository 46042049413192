import { reduce } from 'lodash';
import { Nullable, OutstandingItems } from '../uc-api-sdk';

export class PatientOutstandingService {
  private item?: Nullable<OutstandingItems>;

  constructor(item?: Nullable<OutstandingItems>) {
    this.item = item;
  }

  get outstandingItemCount() { return this.item; }

  public getTotalCount() {
    const numberOutstanding = reduce(this.item, (result, value, key) => {
      let ans = result;
      if (key.includes('Count')) {
        ans += typeof value === 'number' ? value : 0;
      }
      return ans;
    }, 0);
    return numberOutstanding;
  }
}
