/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
import axios, { AxiosResponse } from 'axios';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useCacheOptionContext } from './context/cache/CacheOptionContext';
import { useCache } from './context/cache/CacheValueContext';
import { debounce } from 'lodash';

export interface Headers { [key: string]: string; }

export type ReqHookConfig<T extends (...args: any[]) => any> = Parameters<T>[0];

export enum MethodTypeEnum {
  POST = 'POST',
  PUT = 'PUT',
  GET = 'GET',
  DELETE = 'DELETE',
}

export interface ReqHookContextValue {
  url: string;
  token?: string;
  isMock?: boolean;
}

export const ReqHookContext = createContext<ReqHookContextValue | undefined>(undefined);

type TypeSelector<T, K> = T extends undefined ? K : T;

export interface ReqOptions {
  cache?: boolean;
}

export interface AjaxOptions extends ReqOptions{
  log?: boolean;
  logError?: boolean;
  throwError?: boolean;
  retry?: number;
  retryDelay?: number;
  sendOnMount?: boolean;
}

export interface GetOptions extends AjaxOptions {

}

const getOption = <T>(baseOption: T | undefined, option: T | undefined, defaultValue: T) => {
  if (option === undefined) {
    return baseOption === undefined ? defaultValue : baseOption;
  }
  return option;
};

export const getOptions = (baseOptions?: AjaxOptions, options?: AjaxOptions, type?: MethodTypeEnum) => {
  const sendOnMount = getOption(baseOptions?.sendOnMount, options?.sendOnMount, type === MethodTypeEnum.GET);
  const log = getOption(baseOptions?.log, options?.log, false);
  const logError = getOption(baseOptions?.logError, options?.logError, false);
  const maxRetryCount = getOption(baseOptions?.retry, options?.retry, 3);
  const retryDelay = getOption(baseOptions?.retryDelay, options?.retryDelay, 2000);
  const throwError = getOption(baseOptions?.throwError, options?.throwError, true);
  const cache = getOption(baseOptions?.cache, options?.cache, false);

  return {
    cache,
    sendOnMount,
    log,
    logError,
    maxRetryCount,
    retryDelay,
    throwError,
  };
};

export interface UseRequestFactory<
  P,
  R,
  F extends (args: { params: P, headers: { [key: string]: string } }) => Promise<AxiosResponse<R>>,
  O extends AjaxOptions,
  RC,
> {
  reqFunc: F;
  ResponseClassCreator: (value: R) => RC,
  options?: O;
  typeName: string;
  type?: MethodTypeEnum;
}

export interface ReqFuncParameters<P> { baseUrl: string, params: P, headers: Headers }
export interface MakeRequestHookConfig<
  P,
  O extends AjaxOptions,
  RCN = undefined
> {
  params?: P;
  headers?: Headers;
  options?: O;
  ResponseClassCreator?: (value: any) => RCN;
}

export const useRequestFactory = <
  P,
  R,
  F extends (args: { params: P, headers: { [key: string]: string } }) => Promise<AxiosResponse<R>>,
  O extends AjaxOptions,
  RC,
  RCN,
>(baseConfigs: UseRequestFactory<P, R, F, O, RC>, configs?: MakeRequestHookConfig<P, O, RCN>) => {
  const {
    cache,
    sendOnMount,
    log,
    logError,
    maxRetryCount,
    retryDelay,
    throwError,
  } = getOptions(baseConfigs?.options, configs?.options, baseConfigs.type);
  const responseCreator = (value: R) => (configs?.ResponseClassCreator ? configs.ResponseClassCreator(value) : baseConfigs.ResponseClassCreator(value)) as TypeSelector<RCN, RC>;
  const defaultParams = configs?.params;
  const context = useContext(ReqHookContext);
  if (!context) {
    throw new Error('Context is missing values!');
  }
  const { url: baseUrl, token, isMock = false } = context;
  let retryCount = 0;
  const isFirstTime = useRef(true);
  const stringifiedParams = useRef(JSON.stringify(defaultParams));
  const [prevParams, setPrevParams] = useState<P | undefined>(configs?.params);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const [status, setStatus] = useState<number>();
  const [data, setData] = useState<R | null>();
  const [dataObj, setDataObj] = useState<TypeSelector<RCN, RC> | null>();
  const { saveToCache } = useCacheOptionContext();
  const { getFromCache } = useCache();
  const headers = {
    'x-session-token': token,
    ...(configs?.headers || {}),
  };

  // @ts-ignore
  // eslint-disable-next-line max-len, max-len
  const sendRequest = async (option?: ReqOptions, p?: P, h?: Headers): Promise<R | undefined | null> => {
    const params = p === undefined ? prevParams : p;
    const shouldCache = getOption(cache, option?.cache, false);
    setPrevParams(p);
    setIsLoading(true);
    let response: R | undefined | null;
    try {
      const cacheInfo = { typeName: baseConfigs.typeName, params };
      const cacheValue = getFromCache(cacheInfo);
      if (shouldCache && cacheValue) {
        setIsLoading(false);
        setData(cacheValue as R || null);
        setDataObj(responseCreator(cacheValue));
        return cacheValue as R;
      }
      // @ts-ignore
      const res = await baseConfigs.reqFunc({ baseUrl, isMock, params, headers: { ...headers, ...h } });
      if (log) console.log(res);
      setStatus(res.status);
      setError(undefined);
      setData(res.data as R || null);
      setDataObj(responseCreator(res.data));
      if (shouldCache) {
        saveToCache({ ...cacheInfo, value: res.data });
      }
      response = res.data as R || null;
      retryCount = 0;
    } catch (err) {
      setError(err as Error);
      setData(undefined);
      setDataObj(undefined);
      // Retry
      if (retryCount < maxRetryCount) {
        retryCount += 1;
        return new Promise((resolve, reject) => {
          setTimeout(async () => {
            try {
              const v = await sendRequest(option, params as P, h);
              return resolve(v);
            } catch (e) {
              return reject(e);
            }
          }, retryDelay);
        });
      }

      if (logError) console.error(err);
      if (throwError) throw err;
      response = undefined;
    } finally {
      setIsLoading(false);
    }
    return response;
  };

  const debouncedSendRequest = useCallback(debounce(sendRequest, 50), [
    prevParams,
    setPrevParams,
    setIsLoading,
    setError,
    setStatus,
    setData,
    setDataObj,
    saveToCache,
    context.token,
    context.url,
  ]);

  useEffect(() => {
    const tempStringifiedParams = JSON.stringify(defaultParams);
    if (isFirstTime.current
      || tempStringifiedParams !== stringifiedParams.current
    ) {
      isFirstTime.current = false;
      stringifiedParams.current = tempStringifiedParams;
      if (sendOnMount) {
        retryCount = 0;
        debouncedSendRequest(undefined, defaultParams as P);
      }
    }
  }, [sendOnMount, defaultParams]);

  return {
    send: (config?: { options?: ReqOptions, params: P, headers?: Headers }) => sendRequest(config?.options, config?.params, config?.headers),
    refetch: () => sendRequest({ ...configs?.options, cache: false }, configs?.params, configs?.headers),
    isLoading,
    error,
    status,
    data,
    dataObj,
    debouncedSendRequest,
  };
};

export type Nullable<T> = T | null;

export enum BaseSortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}
export interface BaseSortInfo {
  direction: BaseSortDirection;
  property: string;
}
export interface BasePageInfo {
  page?: number;
  size?: number;
  sort?: BaseSortInfo[];
  pagination?: boolean;
}
export interface BaseInsertParams<T> {
  document: T;
}
export interface BaseUpdateParams<K, T> {
  id: K;
  document?: T;
  $set?: Record<string, object>;
}
export interface BaseDeleteParams<K> {
  id: K;
}
export interface BaseSearchParams<T> {
  filter: T;
  pageInfo?: BasePageInfo;
}
export interface BaseGetParams<K> {
  id: K;
}
export interface BasePageResponse<T> {
  totalPage: number;
  totalSize: number;
  content: T[];
}
export interface BaseAPIResponse<T> {
  code: number;
  data: T;
  msg: string;
}
export interface RequestOption<T, M = undefined> {
  baseUrl?: string;
  isMock?: boolean;
  params: T;
  headers?: Headers;
  mocker?: (mocker: M) => M;
}

export class BaseController<T, K> {
  protected baseURL = '';

  protected basePath = '';

  public constructor(baseURL?: string) {
    this.baseURL = baseURL || '';
  }

  public insert({ baseUrl, params, headers }: RequestOption<BaseInsertParams<T>>): Promise<BaseAPIResponse<T>> {
    return axios.post(`${baseUrl || this.baseURL}${this.basePath}`, { ...params.document }, { headers });
  }

  public update({ baseUrl, params, headers }: RequestOption<BaseUpdateParams<K, T>>): Promise<BaseAPIResponse<T>> {
    const { id, document, ...restParams } = params;
    return axios.put(`${baseUrl || this.baseURL}${this.basePath}/${id}`, { ...document, ...restParams }, { headers });
  }

  public delete({ baseUrl, params, headers }: RequestOption<BaseDeleteParams<K>>): Promise<BaseAPIResponse<T>> {
    return axios.delete(`${baseUrl || this.baseURL}${this.basePath}/${params.id}`, { headers });
  }

  public get({ baseUrl, params, headers }: RequestOption<BaseGetParams<K>>): Promise<BaseAPIResponse<T>> {
    return axios.get(`${baseUrl || this.baseURL}${this.basePath}/${params.id}`, { headers });
  }

  public search({ baseUrl, params, headers }: RequestOption<BaseSearchParams<T>>): Promise<BaseAPIResponse<BasePageResponse<T>>> {
    return axios.post(`${baseUrl || this.baseURL}${this.basePath}/search`, params, { headers });
  }
}
