import { Button } from 'antd';
import classNames from 'classnames';
import { useBoolean, useUpdateEffect } from 'usehooks-ts';
import React, { FC, useMemo, useState } from 'react';
import { CommentWAvatarComponent, CommentWAvatarComponentProps } from '../../../../uiComponent/CommentWAvatarComponent/CommentWAvatarComponent';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext/LoggedInUserContext';
import { CommentInputWAvatarComponent } from '../../../../uiComponent/CommentInputWAvatarComponent/CommentInputWAvatarComponent';
import { ConfirmationModalComponent } from '../../../../uiComponent/ConfirmationModalComponent/ConfirmationModalComponent';
import './TicketCommentComponent.scss';

export interface TicketCommentComponentProps extends CommentWAvatarComponentProps {
  id: string;
  commentedById: string;
  onDelete?: (id: string) => void;
  onEdit?: (id: string, value: string) => void;
  isEditing?: boolean;
}

export const TicketCommentComponent: FC<TicketCommentComponentProps> = ({
  id,
  date,
  comment,
  commentedBy,
  commentedById,
  onDelete,
  onEdit,
  isEditing,
  avatarUrl,
}) => {
  const { value: isEditMode, setTrue: setIsEditTrue, setFalse: setIsEditFalse } = useBoolean();
  const { value: isDeleting, setTrue, setFalse } = useBoolean();
  const { value: open, setTrue: showModal, setFalse: hideModal } = useBoolean(false);
  const [newComment, setNewComment] = useState<string | undefined>(comment);
  const { userId } = useLoggedInUserFromContext();
  const isCurrentUser = commentedById === userId;

  const handleEditSave = async () => {
    if (newComment) {
      await onEdit?.(id, newComment);
      setIsEditFalse();
    }
  };

  useUpdateEffect(() => {
    setNewComment(comment);
  }, [comment]);

  const handleDelete = async () => {
    setTrue();
    await onDelete?.(id);
    setFalse();
    hideModal();
  };

  const extra = useMemo(() => (
    <div className="EditDeleteButtons">
      <Button type="link" onClick={setIsEditTrue}>Edit</Button>
      <div className="DotDivider" />
      <Button type="link" onClick={showModal}>Delete</Button>

    </div>
  ), [onDelete, setIsEditTrue]);

  const inputExtra = useMemo(() => (
    <div className="EditButtons">
      <Button type="link" onClick={handleEditSave} loading={isEditing} disabled={isEditing}>Save</Button>
      <Button
        type="link"
        onClick={() => {
          setIsEditFalse();
          setNewComment(comment);
        }}
        className="CancelButton"
      >
        Cancel

      </Button>
    </div>
  ), [handleEditSave, setIsEditFalse]);

  return (
    <div className="CommentWAvatarComponent">
      {isEditMode ? (
        <CommentInputWAvatarComponent
          value={newComment}
          onChange={setNewComment}
          extra={inputExtra}
        />
      ) : (
        <div className={classNames({ mb10: !isCurrentUser })}>
          <CommentWAvatarComponent
            date={date}
            comment={comment}
            commentedBy={commentedBy}
            extra={isCurrentUser ? extra : null}
            avatarUrl={avatarUrl}
          />
        </div>
      )}
      <ConfirmationModalComponent
        open={open}
        onCancel={hideModal}
        onConfirm={handleDelete}
        text="Delete this comment?"
        cancelText="Cancel"
        confirmText="Delete"
        isLoading={isDeleting}
        danger
      />
    </div>
  );
};
