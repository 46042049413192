import { message } from 'antd';
import { ApiRequestHelper } from '../../../../../helpers/ApiRequest';
import { CarePlan, usePatientSearchCarePlan } from '../../../../../uc-api-sdk';
import { PrintButtonComponentProps } from '../../../../../uiComponent/PrintButtonComponent/PrintButtonComponent';
import { PrintCarePlanPreviewButtonComponent, PrintCarePlanPreviewButtonComponentProps } from '../../component/PrintCarePlanPreviewButtonComponent/PrintCarePlanPreviewButtonComponent';

export interface PrintCarePlanPreviewButtonContainerProps
  extends Omit<PrintCarePlanPreviewButtonComponentProps, 'carePlan'> {
  carePlan?: CarePlan | null;
  visitId?: string | null;
}

export const PrintCarePlanPreviewButtonContainer = ({
  visitId,
  carePlan,
  ...props
}: PrintCarePlanPreviewButtonContainerProps) => {
  const carePlanSearchInfo = usePatientSearchCarePlan({});

  const {
    isLoading,
    data,
  } = carePlanSearchInfo || {};

  const carePlanData = data?.data?.content?.[0];

  const handleOnClick: PrintButtonComponentProps['onClick'] = async (
    handlePrint
  ) => {
    ApiRequestHelper.tryCatch(
      carePlanSearchInfo.send({
        params: {
          request: {
            filter: {
              id: carePlan?.id,
              visitId,
              isFullVersion: true,
            },
          },
        },
      }),
      {
        onSuccess: (res) => {
          if (res?.code === 200 && (res?.data?.content?.length || 0) > 0) {
            handlePrint();
            return;
          }
          message.info('No care plan found.', 3);
        },
        success: '',
        error: 'Failed to fetch care plan.'
      }
    );
  };

  return (
    <PrintCarePlanPreviewButtonComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      carePlan={carePlanData}
      isLoading={isLoading}
      onClick={handleOnClick}
    />
  );
};
