import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { intersection } from 'lodash';
import { FC } from 'react';
import { CARE_NOTE_DATE, TIME_TIMEZONE } from '../../constants/timeFormat';
import TimezoneService from '../../helpers/timezone/timezoneService';
import { CareNoteTagEnum } from '../../uc-api-sdk';
import './CareNoteDurationInfoComponent.scss';

export const careNoteWithDuration = [CareNoteTagEnum.CALL_LOG, CareNoteTagEnum.VISIT];
dayjs.extend(duration);
export interface CareNoteDurationInfoComponentProps {
  tags?: CareNoteTagEnum[] | null;
  startTime: string;
  endTime: string;
  timezone?: string;
}

export const CareNoteDurationInfoComponent: FC<CareNoteDurationInfoComponentProps> = ({
  tags,
  startTime,
  endTime,
  timezone
}) => {
  const timeAt = TimezoneService.calcDateTimeDayjs(startTime, timezone);
  const timeTo = TimezoneService.calcDateTimeDayjs(endTime, timezone);
  const duration = dayjs.duration(timeTo.diff(timeAt)).format('HH:mm:ss');
  const dateString = `${timeAt.format(CARE_NOTE_DATE)} - ${timeTo.format(TIME_TIMEZONE)}`;

  if (!intersection(careNoteWithDuration, tags).length) {
    return null;
  }

  return (
    <div className="careNoteVisitInfo flex gap3">
      {duration
        && (
        <>
          <div>{`Duration: ${duration}`}</div>
          <div>{dateString}</div>
        </>
        )}
    </div>
  );
};
