export class HeightService {
  public static defaultHeightUnit = 'cm';

  public static oneInchToCm = 2.54;

  public static oneCmToInch = 0.393701;

  public static cmToFeet(heightInCm: number) {
    const inches = this.oneCmToInch * heightInCm;
    const rawFeet = inches / 12;
    const feet = Math.floor(rawFeet);
    const inch = Math.round((rawFeet - feet) * 12);
    return [feet, inch];
  }

  public static feetToCm(feet: number, inch: number) {
    return ((feet * 12) + inch) * this.oneInchToCm;
  }
}
