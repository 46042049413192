import { Checkbox, FormListFieldData } from 'antd';
import { ProgramCategoryEnumComponent } from '../../../../enumComponent/ProgramCategoriesComponent/ProgramCategoriesComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useProfileProgramEnrollmentForm } from '../../hook/useProfileProgramEnrollmentForm';
import { ProgramCategoryEnum } from '../../../../uc-api-sdk';

export interface PatientProfileProgramEnrollmentFormFieldData extends Omit<FormListFieldData, 'name'> {
  name: ProgramCategoryEnum | number;
}

export interface PatientProfileProgramEnrollmentFormItemComponentProps {
  category: ProgramCategoryEnum;
  required?: boolean;
  formField?: PatientProfileProgramEnrollmentFormFieldData;
}

export const PatientProfileProgramEnrollmentFormItemComponent = ({
  category,
  required,
  formField,
}: PatientProfileProgramEnrollmentFormItemComponentProps) => {
  const profileProgramEnrollmentForm = useProfileProgramEnrollmentForm();
  return (
    <FormItem
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...formField}
      name={profileProgramEnrollmentForm.makeObjectNamePath('category', formField?.name)}
      required={required}
      valuePropName="checked"
      getValueFromEvent={(e) => {
        if (e.target.checked) return category;
        return null;
      }}
    >
      <Checkbox>
        <ProgramCategoryEnumComponent
          value={category}
          type="long"
        />
      </Checkbox>
    </FormItem>
  );
};
