import classNames from 'classnames';
import { ReactNode } from 'react';
import { Col } from 'antd';
import { AntdEllipsisComponent } from '../../../../uiComponent/AntdEllipsisComponent/AntdEllipsisComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface InsuranceItemComponentProps {
  label: ReactNode;
  content: ReactNode;
  span?: number;
  offset?: number;
  ellipsis?: boolean;
  required?: boolean;
}

export const InsuranceItemComponent = ({
  label,
  content,
  span = 6,
  offset = 0,
  ellipsis = true,
  required,
}: InsuranceItemComponentProps) => {
  const renderContent = (content?: ReactNode) => {
    const contentNode = content || <DisplayOrEmptyComponent emptyText="-- --" />;
    return (
      ellipsis
        ? (
          <AntdEllipsisComponent>
            {contentNode}
          </AntdEllipsisComponent>
        ) : (
          <div>
            {contentNode}
          </div>
        )
    );
  };

  return (
    <Col
      span={span}
      offset={offset}
      className="flex fd-c"
    >
      <span
        className={classNames({
          'text-gray-scale-2 fs-small': true,
          'required-label': !!required,
        })}
      >
        {label}
      </span>
      {renderContent(content)}
    </Col>
  );
};
