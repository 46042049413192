import { Select, SelectProps } from 'antd';
import { HiatusReasonEnumComponent } from '../../../../enumComponent/HiatusReasonEnumComponent/HiatusReasonEnumComponent';
import { HiatusStatusReasonEnum } from '../../../../uc-api-sdk';

export interface PauseServiceReasonSelectComponentProps extends SelectProps { }

export const PauseServiceReasonSelectComponent = (
  props: PauseServiceReasonSelectComponentProps,
) => {
  const options = [
    HiatusStatusReasonEnum.INACTIVITY,
    HiatusStatusReasonEnum.EXTENDED_TRAVEL,
    HiatusStatusReasonEnum.HOSPITALIZATION,
    HiatusStatusReasonEnum.INSURANCE_MATTERS,
    HiatusStatusReasonEnum.HEALTH_WELL_CONTROL,
    HiatusStatusReasonEnum.PROGRAM_OVERWHELMING,
    HiatusStatusReasonEnum.FAMILY_ISSUES,
    HiatusStatusReasonEnum.OTHERS].map(
    (reason) => ({
      label: <HiatusReasonEnumComponent value={reason as HiatusStatusReasonEnum} />,
      value: reason as HiatusStatusReasonEnum,
    }),
  );
  return (
    <Select
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      mode="multiple"
      options={options}
    />
  );
};
