import { FC } from 'react';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { BgMealTypeEnum } from '../../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { FoodLogProviderFormContainer } from '../../container/FoodLogProviderFormContainer/FoodLogProviderFormContainer';
import { useDefaultLanguageHelper } from '../../hook/useDefaultLanguageHelper';
import { FoodLog } from '../../type';
import { FoodLogMealInfoComponent } from '../FoodLogMealInfoComponent/FoodLogMealInfoComponent';
import { FoodLogPatientInfoComponent } from '../FoodLogPatientInfoComponent/FoodLogPatientInfoComponent';
import './FoodLogTabContentComponent.scss';

export interface FoodLogTabContentComponentProps {
  data: FoodLog;
  onCancel?: () => void;
  onSubmit?: () => void;
  refetch: () => void;
  activeKey: string;
  index: string;
}

export const FoodLogTabContentComponent: FC<FoodLogTabContentComponentProps> = ({
  data,
  onCancel,
  onSubmit,
  refetch,
  activeKey,
  index,
}) => {
  const { info } = usePatientContext();
  const showGlucoseResults = data.mealType !== BgMealTypeEnum.SNACK;
  const spokenLanguage = info?.patientInfoService?.getSpokenLanguage()?.toLocaleString();
  const appLanguage = info?.patientInfoService?.getAppLanguage();
  const initialLanguage = useDefaultLanguageHelper(spokenLanguage, appLanguage);

  // get data for glucose levels
  const beforeMeal = data.beforeMealBg?.bloodGlucose?.value || undefined;
  const beforeMealTime = data.beforeMealBg?.bgUploadedAt || undefined;
  const afterMeal = data.afterMealBg?.bloodGlucose?.value || undefined;
  const afterMealTime = data.afterMealBg?.bgUploadedAt || undefined;
  const glucoseLevels = {
    beforeMeal, afterMeal, beforeMealTime, afterMealTime
  };

  return (
    <div className="flex jc-sb">
      <div>
        <FoodLogPatientInfoComponent />
        <FoodLogMealInfoComponent
          mealType={data.mealType as BgMealTypeEnum}
          imgUrl={data.images.at(0)?.link || ''}
          postedAt={(<DisplayDateComponent value={data.createdAt} format="DATE_TIME_AM_PM" timezone={data.timezone} />)}
          uploadedAt={(<DisplayDateComponent value={data.uploadedAt} format="DATE_TIME_AM_PM" timezone={data.timezone} />)}
          vitals={info?.enrolledProgramService.getVitals() || []}
          note={data.note || ''}
        />
      </div>
      <div className="foodLogProviderForm">
        <FoodLogProviderFormContainer
          activeKey={activeKey}
          id={data.id}
          showGlucoseResults={showGlucoseResults}
          providerComments={data.comments}
          glucoseLevels={glucoseLevels}
          initialValues={{
            rate: data.rating?.star || undefined,
            note: { originalText: '', language: initialLanguage },
          }}
          onCancel={onCancel}
          onSubmit={onSubmit}
          refetch={refetch}
          index={index}
        />
      </div>
    </div>
  );
};
