import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import ProviderViewTablesComponent from '../../../providerView/component/ProviderViewTablesComponent/ProviderViewTablesComponent';
import PatientTablesComponent from '../PatientTablesComponent/PatientTablesComponent';

export const PatientTableDispatcherComponent = () => {
  const { isClinicalStaff } = useLoggedInUserFromContext();
  if (!isClinicalStaff) {
    return <PatientTablesComponent />;
  }
  return <ProviderViewTablesComponent />;
};
