import { TaskRepeatEnum } from '../../../../uc-api-sdk';
import { PluralComponent } from '../../../../uiComponent/PluralComponent/PluralComponent';

export interface InterventionFrequencyComponentProps {
  target?: number;
  repeat?: TaskRepeatEnum;
}

export const InterventionFrequencyComponent = ({
  target,
  repeat,
}: InterventionFrequencyComponentProps) => {
  const frequencyText = () => {
    switch (repeat) {
      case TaskRepeatEnum.DAILY:
        return 'per day';
      case TaskRepeatEnum.WEEKLY:
        return 'per week';
      case TaskRepeatEnum.MONTHLY:
        return 'per month';
      default:
        return '';
    }
  };

  return (
    <div className="flex">
      <PluralComponent value={target} unit="time" />
      <span>{frequencyText()}</span>
    </div>
  );
};
