import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { filter, slice } from 'lodash';
import { StorageKeyEnum, useSessionStorage } from '../../../../hooks/useSessionStorage';

const RECENT_PATIENTS_LIMIT = 15;

export interface PatientInfo {
  id: string;
  name: string;
}

export interface Value {
  recentPatients: PatientInfo[];
  addRecentPatient: (patient: PatientInfo) => void;
}

export const RecentPatientsContext = createContext<Value>({
  recentPatients: [],
  addRecentPatient: () => { console.error('addRecentPatient has not been implemented yet!'); },
});

export interface RecentPatientsContextProps {
  children: ReactNode;
}

export const RecentPatientsContextProvider = ({
  children,
}: RecentPatientsContextProps) => {
  const [recentPatients = [], setRecentPatients] = useSessionStorage<PatientInfo[]>(
    StorageKeyEnum.RECENT_PATIENT_CARE_PATIENTS,
    [],
  );

  const addRecentPatient = useCallback((patient: PatientInfo) => {
    const newRecentPatients = [...recentPatients];
    const filteredRecentPatients = filter(newRecentPatients, (p) => (p.id !== patient.id));
    filteredRecentPatients.unshift(patient);
    const slicedArr = slice(filteredRecentPatients, 0, RECENT_PATIENTS_LIMIT);
    setRecentPatients(slicedArr);
  }, [recentPatients, setRecentPatients]);

  const value = useMemo<Value>(() => ({
    recentPatients,
    addRecentPatient,
  }), [
    recentPatients,
    addRecentPatient
  ]);

  return (
    <RecentPatientsContext.Provider value={value}>
      {children}
    </RecentPatientsContext.Provider>
  );
};

export const useRecentPatientsContext = () => {
  const value = useContext(RecentPatientsContext);
  return value;
};
