import { map } from 'lodash';
import { GenderIdentityEnum } from '../../../uc-api-sdk';
import { EnumSelectComponent } from '../../EnumSelectComponent/EnumSelectComponent';
import { InputType } from '../../../features/Input/types';
import { GenderIdentityEnumComponent } from '../../../enumComponent/GenderIdentityEnumComponent/GenderIdentityEnumComponent';

export interface PatientGenderIdentitySelectComponentProps extends InputType<GenderIdentityEnum> { }

export const PatientGenderIdentitySelectComponent = ({
  value,
  onChange,
}: PatientGenderIdentitySelectComponentProps) => (
  <EnumSelectComponent
    options={map(GenderIdentityEnum)}
    optionRenderer={GenderIdentityEnumComponent}
    value={value}
    onChange={onChange}
  />
);
