import { ReactNode } from 'react';
import { DeviceModelEnum, VitalEnumType } from '../../../../uc-api-sdk';
import { DeviceHelper } from '../../helper';
import { DeviceDrawerProps } from '../../type';
import { DeviceAssignUnassignComponent } from '../DeviceAssignUnassignComponent/DeviceAssignUnassignComponent';
import { DeviceMonitorDrawerComponent } from '../DeviceMonitorDrawerComponent/DeviceMonitorDrawerComponent';
import { DeviceMonitorFormComponentProps } from '../DeviceMonitorFormComponent/DeviceMonitorFormComponent';
import { NestedFormControlProvider } from '../../../../contexts/NestedFormControlContext/NestedFormControlContext';

export interface BloodGlucoseDeviceMonitorDrawerComponentProps
  extends DeviceDrawerProps {
  title?: ReactNode;
  onSubmit?: DeviceMonitorFormComponentProps['onSubmit'];
  useDefaultTemplate?: boolean;
}

export const BloodGlucoseDeviceMonitorDrawerComponent = ({
  patientId,
  onSubmit,
  isOpen,
  onClose,
  title,
  useDefaultTemplate,
}: BloodGlucoseDeviceMonitorDrawerComponentProps) => (
  <DeviceMonitorDrawerComponent
    open={isOpen}
    onClose={onClose}
    patientId={patientId}
    vitalType={VitalEnumType.BG}
    onSubmit={onSubmit}
    title={title}
    useDefaultTemplate={useDefaultTemplate}
  >
    {
      ({
        selectedMonitorMethod,
        assignedDevice,
        onAssign,
        onUnassign,
      }) => (
        DeviceHelper.isNonAppMonitor(selectedMonitorMethod)
          ? (
            <NestedFormControlProvider>
              <DeviceAssignUnassignComponent
                key={DeviceModelEnum.BIOLAND}
                deviceModel={DeviceModelEnum.BIOLAND}
                assignedDevice={assignedDevice}
                onAssign={onAssign}
                onUnassign={onUnassign}
              />
            </NestedFormControlProvider>
          ) : null
      )
    }
  </DeviceMonitorDrawerComponent>
);
