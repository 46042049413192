import { Dayjs } from 'dayjs';
import { VisitMethodEnum, VisitTypeEnum } from '../../../../uc-api-sdk';
import { CalendarEventBaseComponent } from '../CalendarEventBaseComponent/CalendarEventBaseComponent';
import { CalendarVisitTitleComponent } from '../CalendarVisitTitleComponent/CalendarVisitTitleComponent';

export interface CalendarVisitEventComponentProps {
  startTime: Dayjs;
  endTime: Dayjs;
  clinicNumber: string;
  patientName?: string;
  visitType?: VisitTypeEnum;
  visitMethod?: VisitMethodEnum;
  className?: string;
}

export const CalendarVisitEventComponent = ({
  startTime,
  endTime,
  clinicNumber,
  patientName,
  visitType,
  visitMethod,
  className = '',
}: CalendarVisitEventComponentProps) => (
  <CalendarEventBaseComponent
    startTime={startTime}
    endTime={endTime}
    title={(
      <CalendarVisitTitleComponent
        visitMethod={visitMethod}
        clinicNumber={clinicNumber}
        patientName={patientName}
        visitType={visitType}
      />
    )}
    className={className}
  />
);
