import { BehaviorGoalTypeEnum } from '../../uc-api-sdk';

export interface BehaviorGoalTypeEnumComponentProps {
  value: BehaviorGoalTypeEnum;
}

export const BehaviorGoalTypeEnumComponent = ({
  value,
}: BehaviorGoalTypeEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case BehaviorGoalTypeEnum.BLOOD_PRESSURE_MANAGEMENT: return 'Blood Pressure Management';
      case BehaviorGoalTypeEnum.CANCER: return 'Cancer';
      case BehaviorGoalTypeEnum.CHRONIC_KIDNEY_DISEASE: return 'Chronic Kidney Disease';
      case BehaviorGoalTypeEnum.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE: return 'Chronic Obstructive Pulmonary Disease';
      case BehaviorGoalTypeEnum.CONGESTIVE_HEART_FAILURE: return 'Congestive Heart Failure';
      case BehaviorGoalTypeEnum.BLOOD_GLUCOSE_MANAGEMENT:
      case BehaviorGoalTypeEnum.GLUCOSE_MANAGEMENT:
        return 'Glucose Management';
      case BehaviorGoalTypeEnum.LIPID_MANAGEMENT: return 'Lipid Management';
      case BehaviorGoalTypeEnum.UNDERWEIGHT: return 'Underweight';
      case BehaviorGoalTypeEnum.WEIGHT_REDUCTION: return 'Weight Reduction';
      case BehaviorGoalTypeEnum.WELLNESS: return 'Wellness';
      default: return '';
    }
  };

  return (
    <span>{getText()}</span>
  );
};
