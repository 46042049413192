import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { ClinicEventsService } from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';

export interface FutureFollowUpScheduleTimeComponentProps {
  clinicEvent: ClinicEventsService;
}

export const FutureFollowUpScheduleTimeComponent = ({
  clinicEvent,
}: FutureFollowUpScheduleTimeComponentProps) => (
  <>
    {/* 09/12/2023 at 03:00 pm PDT */}
    <DisplayDateComponent
      value={clinicEvent.startTimeUtc}
      useTimezoneService
      format="MMDDYYYY_AT_HHmm_a_z"
    />
    <div className="flex gap0">
      {/* (03:00 PM Patient local time) */}
      (
      <DisplayDateComponent
        value={clinicEvent.startTimeUtc}
        timezone={clinicEvent.patientInfo?.timezone || undefined}
        useTimezoneService
        format="HHmm_A_PATIENT_LOCAL_TIME"
      />
      )
    </div>
  </>
);
