import { ReactNode } from 'react';
import { EnrollmentStepProvider, Step } from './EnrollmentStepContext';
import { ConsentService } from '../../../../services/ConsentService';
import { EnrollmentService } from '../../../../services/EnrollmentService';
import { usePatientMedicalOrganizationContext } from '../../../../contexts/PatientMedicalOrganizationContext/PatientMedicalOrganizationContext';

export interface EnrollmentStepsEnhancedProviderProps {
  initialStep?: Step;
  consentService: ConsentService;
  enrollmentService: EnrollmentService | undefined;
  children: ReactNode;
}

export const EnrollmentStepsEnhancedProvider = ({
  initialStep,
  consentService,
  enrollmentService,
  children,
}: EnrollmentStepsEnhancedProviderProps) => {
  const {
    medicalOrganizationService,
    isLoading: isLoadingMedicalOrganization,
  } = usePatientMedicalOrganizationContext();

  const getInitialInfo = () => {
    const completedSteps: Step[] = [];
    let currStep = Step.OnboardingVisits;

    if (
      medicalOrganizationService?.isRiverBend()
      || medicalOrganizationService?.isStThomas()
    ) {
      currStep = Step.ProgramEnrollment;
    }

    if (enrollmentService?.hasDevices()) {
      completedSteps.push(Step.AssignDevices);
    } else if (!medicalOrganizationService?.isRiverBend()) {
      currStep = Step.AssignDevices;
    }
    if (consentService.hasConsent()) {
      completedSteps.push(Step.Consent);
    } else {
      currStep = Step.Consent;
    }
    if (
      enrollmentService?.isEnrolled()
      && consentService.isSigned()
      && !enrollmentService?.isRejected()
    ) {
      completedSteps.push(Step.ProgramEnrollment);
    } else {
      currStep = Step.ProgramEnrollment;
    }

    return {
      currStep,
      completedSteps,
    };
  };

  const initialInfo = (
    isLoadingMedicalOrganization ? undefined : getInitialInfo()
  );

  return (
    <EnrollmentStepProvider
      initialStep={initialStep || initialInfo?.currStep}
      initialCompletedStatuses={initialInfo?.completedSteps}
    >
      {children}
    </EnrollmentStepProvider>
  );
};
