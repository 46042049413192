import React, {
  FC,
  useContext,
  useMemo,
} from 'react';

export interface Value {
  isBillable: boolean;
  isEventBillable: boolean;
}

export const BillableInfoContext = React.createContext<Value>({
  isBillable: true,
  isEventBillable: true,
});

export interface BillableInfoProviderProps {
  isBillable?: boolean;
  isEventBillable?: boolean;
  children: React.ReactNode;
}

export const BillableInfoProvider: FC<BillableInfoProviderProps> = ({
  isBillable = true,
  isEventBillable = true,
  children,
}) => {
  const contextValue = useMemo<Value>(() => ({
    isBillable,
    isEventBillable,
  }), [isBillable, isEventBillable]);

  return (
    <BillableInfoContext.Provider value={contextValue}>
      {children}
    </BillableInfoContext.Provider>
  );
};

export const useBillableInfoContext = () => {
  const billableInfoContextValue = useContext<Value>(BillableInfoContext);
  return billableInfoContextValue;
};
