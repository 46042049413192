import React, { FC } from 'react';
import { useBOResultToSummaryTable } from '../../hook/useBOResultToSummaryTable/useBOResultToSummaryTable';
import { BOSummaryTableComponent } from './BOSummaryTableComponent';
import { PoStat } from '../../../../uc-api-sdk';

export interface BOSummaryTableDataProcessorComponentProps {
  data: PoStat;
}

export const BOSummaryTableDataProcessorComponent: FC<
  BOSummaryTableDataProcessorComponentProps
> = ({
  data,
}) => {
  const processedData = useBOResultToSummaryTable(data);

  return (
    <BOSummaryTableComponent data={processedData} />
  );
};
