import { APPLE_LOGO, WINDOWS_LOGO } from '../../assets/logoIconInfo';
import { Icon } from '../../icons/Icon';
import { TextComponent } from '../TextComponent/TextComponent';

export const HowToTakeScreenshotComponent = () => (
  <div>
    <TextComponent size="x-large" bold>
      How to Take a Screenshot
    </TextComponent>
    <div className="mt12">
      <div className="flex ai-c">
        <div className="w140">
          <Icon info={APPLE_LOGO} />
        </div>
        <div>
          <b>Mac:</b>
          {' '}
          Press Shift + Command + 4,
          then click and drag on the screen to select the area you want to capture.
        </div>
      </div>
      <div className="flex ai-c mt20">
        <div className="w140">
          <Icon info={WINDOWS_LOGO} />
        </div>
        <div>
          <b>Windows:</b>
          {' '}
          Press Windows Key + Shift + S,
          then click and drag on the screen to select the area you want to capture.
        </div>
      </div>
    </div>
    <TextComponent italic className="mt20">
      Please avoid using your phone to take photos of the EHR.
    </TextComponent>
  </div>
);
