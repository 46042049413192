import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { FormOptions, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { DischargeReasonEnum } from '../../../uc-api-sdk';

export interface SubmitValue {
  validation: string;
  dischargeReason: DischargeReasonEnum;
  note: string;
}

export const useDischargeForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    validation: {
      name: 'validation',
      label: '',
      rules: [{
        validator: (_rule: unknown, value?: string | null) => {
          if (isEmpty(value) || value !== 'DISCHARGE') {
            return Promise.reject(new Error('Invalid input'));
          }
          return Promise.resolve();
        }
      }],
      placeholder: '',
    },
    dischargeReason: {
      name: 'dischargeReason',
      label: 'Discharge reason',
      rules: [],
      placeholder: '',
    },
    note: {
      name: 'note',
      label: 'Note',
      rules: [],
      placeholder: 'Specify detailed reason here',
    },
  }, options);

  return useMemo(() => ({
    ...factory,
  }), [factory]);
};
