import { Table } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { TimeOfDayTableColumnRender, TimeOfDayTableData, TimeOfDayTablePrintColumns } from './TimeOfDayTablePrintColumns';
import '../TimeOfDayTableComponent/TimeOfDayTableComponent.scss';

export interface TimeOfDayTableComponentProps<T extends TimeOfDayTableData> {
  data: T[];
  render: TimeOfDayTableColumnRender<T>;
}

export const TimeOfDayTablePrintComponent = <T extends TimeOfDayTableData>({
  data,
  render,
}: TimeOfDayTableComponentProps<T>) => {
  const columns = useTableColumns(
    TimeOfDayTablePrintColumns(render),
    ['date', 'overnight', 'morning', 'afternoon', 'evening'],
  );

  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={false}
      className={classNames({
        'table-header-cell-center': true,
        'table-cell-no-padding': true,
        'time-of-day-logbook-table': true,
      })}
      bordered
      rowKey="id"
    />
  );
};
