import { HowToTakeScreenshotTooltipComponent } from '../../../../../uiComponent/HowToTakeScreenshotComponent/HowToTakeScreenshotTooltipComponent';

export interface LLMTranscribeLabResultUploadGuideProps { }

export const LLMTranscribeLabResultUploadGuide = () => (
  <>
    <span className="mr5">
      Upload PDFs and screenshots containing the patient's lab results for AI to process,
      then manually review and select the data you want to update to the patient's profile.
    </span>
    <HowToTakeScreenshotTooltipComponent
      placement="bottomLeft"
      overlayClassName="llm-lab-result-upload-guide--tooltip"
      showArrow
    />
  </>
);
