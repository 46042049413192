import React, { FC } from 'react';
import { EnrolledProgramStatus as EPStatus } from '../../../../generates';
import { _getStatus, _isPatientEnrolled } from '../../../../hooks/useEnrolledProgram/enrolledProgramHelpers';
import { EnrolledProgram } from '../../../../hooks/useEnrolledProgram/types';
import useEnrolledProgram from '../../../../hooks/useEnrolledProgram/useEnrolledProgram';

// eslint-disable-next-line no-shadow
export enum BINARY_STATUS {
  UNENROLLED = 'Unenrolled',
  ENROLLED = 'Enrolled',
}

export interface EnrolledProgramStatusProps {
  status?: EPStatus;
  enrolledProgram?: Partial<EnrolledProgram>;
  showBinaryStatus?: boolean;
}

export const EnrolledProgramStatus: FC<EnrolledProgramStatusProps> = ({
  status,
  enrolledProgram,
  showBinaryStatus = true,
}) => {
  const ep = useEnrolledProgram(enrolledProgram);
  const isEnrolled = status ? _isPatientEnrolled({ status } as EnrolledProgram) : ep.isEnrolled;
  const s = status ? _getStatus({ status } as EnrolledProgram) : ep.status;

  if (showBinaryStatus) {
    return (
      <span>{isEnrolled ? BINARY_STATUS.ENROLLED : BINARY_STATUS.UNENROLLED}</span>
    );
  }
  return (
    <span>{s}</span>
  );
};

export default EnrolledProgramStatus;
