import { ColumnType } from 'antd/lib/table';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { OtherLabResultTableColumnActionComponent } from './OtherLabResultTableColumnActionComponent';
import { LabResultClass } from '../../../../uc-api-sdk';
import { LabResultCollectedDateComponent } from '../LabResultCollectedDateComponent/LabResultCollectedDateComponent';

export const otherLabResultColumns = (
  setSelectedLabResult: (labResult: LabResultClass) => void
) => ({
  dateCollected: (): ColumnType<LabResultClass> => ({
    title: 'Test Date',
    render: (v: string, l: LabResultClass) => (
      <LabResultCollectedDateComponent labResult={l} />
    ),
    width: '20%',
    sorter: true,
    defaultSortOrder: 'descend',
    onCell: (record) => ({
      onClick: () => setSelectedLabResult(record),
    }),
  }),
  templateName: (): ColumnType<LabResultClass> => ({
    title: 'Lab Name',
    dataIndex: 'templateName',
    key: 'templateName',
    render: (v: string) => (
      <DisplayOrEmptyComponent value={v} />
    ),
    onCell: (record) => ({
      onClick: () => setSelectedLabResult(record),
    }),
  }),
  action: () => ({
    title: '',
    dataIndex: 'id',
    key: 'id',
    width: '4%',
    render: (_: string, l: LabResultClass) => (
      <OtherLabResultTableColumnActionComponent labResult={l} />
    ),
  }),
});
