import React, { ReactNode } from 'react';
import { Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './LabTemplateSelectComponent.scss';
import { SelectType } from '../../../Input/types';

export interface LabTemplateSelectComponentInfo<T> {
  id: string;
  name: string;
  isCommon: boolean;
  info: T;
  templateId?: string,
  templateName?: string,
  updatedAt?: string,
}

export interface LabTemplateSelectComponentProps<T>
  extends Omit<SelectType<string>, 'optionRenderer'>
{
  data: LabTemplateSelectComponentInfo<T>[];
  optionRenderer?: (info: LabTemplateSelectComponentInfo<T>) => ReactNode;
}

export const LabTemplateSelectComponent = <T, >({
  loading,
  allowClear,
  data,
  optionRenderer,
  onSearch,
  onChange,
  disabled,
  value,
  onPopupScroll,
  autoClearSearchValue = false,
}: LabTemplateSelectComponentProps<T>) => {
  const commonData = data.filter(item => item.isCommon);
  const otherData = data.filter(item => !item.isCommon);

  const renderOptions = (d: LabTemplateSelectComponentInfo<T>) => (
    <Select.Option key={d.id} value={d.id} name={d.name}>
      <div className="flex jc-sb w100">
        {d.name}
      </div>
    </Select.Option>
  );

  const renderGroupOptions = (
    title: string,
    groupData: LabTemplateSelectComponentInfo<T>[],
  ) => (
    <Select.OptGroup label={<span className="group-title">{title}</span>}>
      {groupData.map(optionRenderer || renderOptions)}
    </Select.OptGroup>
  );

  return (
    <Select
      showSearch
      showArrow
      allowClear={allowClear}
      autoClearSearchValue={autoClearSearchValue}
      loading={loading}
      disabled={disabled}
      value={value}
      className="Lab-search-select-component"
      placeholder="Search Lab Template"
      defaultActiveFirstOption={false}
      onSearch={onSearch}
      onChange={onChange}
      notFoundContent={null}
      suffixIcon={<SearchOutlined />}
      optionFilterProp="name"
      onPopupScroll={onPopupScroll}
    >
      {renderGroupOptions('Common Test Panels', commonData)}
      {renderGroupOptions('Other Test Panels', otherData)}
    </Select>
  );
};
