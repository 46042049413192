import {
  ReactNode,
  useMemo
} from 'react';
import { isEmpty } from 'lodash';
import {
  DeviceModelEnum,
  MonitorMethodEnum,
  VitalEnumType,
  usePatientGetPatientDevice,
  usePatientUpdatePatientDevice
} from '../../../../uc-api-sdk';
import { useGetDeviceInfo } from '../../hook/useGetDeviceInfo';
import { DeviceHelper } from '../../helper';
import { ConnectedDeviceInfo } from '../../type';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { DeviceMonitorFormComponent, DeviceMonitorFormComponentProps } from '../../component/DeviceMonitorFormComponent/DeviceMonitorFormComponent';
import { useUnassignDevices } from '../../hook/useUnassignDevices';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';

export interface DeviceMonitorFormContainerChildrenProps {
  selectedMonitorMethod?: MonitorMethodEnum;
  assignedDevice?: ConnectedDeviceInfo;
  onAssign: (device: Partial<ConnectedDeviceInfo>) => void;
  onUnassign: (device: ConnectedDeviceInfo) => void;
}

export interface DeviceMonitorFormContainerProps {
  children?: (props: DeviceMonitorFormContainerChildrenProps) => ReactNode;
  patientId: string;
  vitalType: VitalEnumType;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export const DeviceMonitorFormContainer = ({
  children,
  patientId,
  vitalType,
  onSubmit,
  onCancel,
}: DeviceMonitorFormContainerProps) => {
  const deviceGetInfo = usePatientGetPatientDevice({
    params: {
      memberId: patientId,
      vitalType,
    },
  });
  const deviceUpdateInfo = usePatientUpdatePatientDevice({});
  const {
    unassignDevices,
    deviceUnassignInfo,
  } = useUnassignDevices();
  const patientDevice = deviceGetInfo.data?.data;
  const isLoading = patientDevice === undefined;
  const isActionLoading = deviceUpdateInfo.isLoading || deviceUnassignInfo.isLoading;
  const {
    getMonitorMethodByType,
    getMostRecentDevicesByType,
    getCuffSizeByType,
  } = useGetDeviceInfo(patientDevice);
  const [
    initialMonitorMethod = MonitorMethodEnum.APP_MONITOR,
    initialConnectedDevices,
    initialCuffSize,
  ] = useMemo(() => ([
    getMonitorMethodByType(vitalType),
    getMostRecentDevicesByType(vitalType),
    getCuffSizeByType(vitalType),
  ]), [vitalType, patientDevice]);

  const initialConnectedDeviceByMethod = (
    initialConnectedDevices[initialMonitorMethod as MonitorMethodEnum]
    || {}
  ) as ConnectedDeviceInfo;

  const handleOnSubmit: DeviceMonitorFormComponentProps['onSubmit'] = async ({
    devicesToUnassignIds,
    monitorMethod,
    connectedDevice,
    cuffSize: selectedCuffSize,
  } = {}) => {
    // unassign requests
    await unassignDevices(patientId, devicesToUnassignIds || []);

    // update request
    const cuffSize = connectedDevice?.cuffSize || selectedCuffSize;
    ApiRequestHelper.tryCatch(
      deviceUpdateInfo.send({
        params: {
          memberId: patientId,
          updateRequest: {
            deviceList: [{
              vitalType,
              monitorMethod: (
                DeviceHelper.isNonAppMonitor(monitorMethod)
                  ? DeviceHelper.getMethodByModel(connectedDevice?.deviceModel as DeviceModelEnum)
                  : monitorMethod
              ),
              cuffSize: (isEmpty(connectedDevice) && cuffSize) || undefined,
              devices: (
                !isEmpty(connectedDevice)
                  ? [{
                    ...connectedDevice,
                    deviceId: connectedDevice.deviceId,
                    deviceModel: connectedDevice.deviceModel,
                    deviceMethod: DeviceHelper.getMethodByModel(connectedDevice.deviceModel),
                    cuffSize,
                  }]
                  : undefined
              ),
            }]
          }
        }
      }),
      {
        success: 'Updated device monitor successfully.',
        error: 'Failed to update device monitor.',
        onSuccess: () => onSubmit?.(),
      }
    );
  };

  const debouncedHandleSubmit = useDebounce(handleOnSubmit, 250, [handleOnSubmit]);

  return (
    <LoadingOverlayComponent isLoading={isLoading}>
      <DeviceMonitorFormComponent
        vitalType={vitalType}
        initialValues={{
          monitorMethod: initialMonitorMethod,
          connectedDevice: initialConnectedDeviceByMethod,
          cuffSize: initialCuffSize,
          initialConnectedDevices,
        }}
        isLoading={isActionLoading}
        onSubmit={debouncedHandleSubmit}
        onCancel={onCancel}
        // eslint-disable-next-line react/no-children-prop
        children={children}
      />
    </LoadingOverlayComponent>
  );
};
