import { Tabs } from 'antd';
import dayjs from 'dayjs';
import { map } from 'lodash';
import { InsuranceEligibilityHistoryMainComponent } from './InsuranceEligibilityHistoryMainComponent';

import './InsuranceEligibilityHistoryComponent.scss';
import { EligibilityReport } from '../../../../uc-api-sdk';
import { useDeepCompareMemo } from '../../../../hooks/useDeepCompareEffect';
import { USA_DATE } from '../../../../constants/timeFormat';

export interface InsuranceEligibilityHistoryComponentProps {
  eligibilityReports: EligibilityReport[]
}
export const InsuranceEligibilityHistoryComponent = ({
  eligibilityReports,
}: InsuranceEligibilityHistoryComponentProps) => {
  const items = useDeepCompareMemo(() => (
    map(eligibilityReports, (eligibilityReport) => ({
      key: eligibilityReport.inquiryId as string,
      label: dayjs(eligibilityReport.serviceDate).format(USA_DATE),
      children: (
        <InsuranceEligibilityHistoryMainComponent
          key={eligibilityReport.inquiryId}
          eligibilityReport={eligibilityReport}
        />
      ),
    }))
  ), [eligibilityReports]);
  return (
    <Tabs
      className="insurance-eligibility-history"
      tabBarExtraContent={{
        left: <div>Inquiry date:</div>,
      }}
      items={items}
      destroyInactiveTabPane
      tabPosition="left"
    />
  );
};
