import { Modal } from 'antd';
import React, { FC } from 'react';
import { FoodLogTabComponent } from '../FoodLogTabComponent/FoodLogTabComponent';
import { FoodLog } from '../../type';

export interface FoodLogModalComponentProps {
  data: FoodLog[];
  open: boolean;
  onClose: () => void;
}

export const FoodLogModalComponent: FC<FoodLogModalComponentProps> = ({
  data,
  open,
  onClose,
}) => (
  <Modal
    open={open}
    onCancel={onClose}
    destroyOnClose
    footer={null}
    width={1000}
  >
    <FoodLogTabComponent
      data={data}
      onCancel={onClose}
    />
  </Modal>
);
