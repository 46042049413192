import {
  ReactNode,
  createContext,
  useContext,
} from 'react';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import { useOpen } from '../../hooks/useBoolean/useOpen';
import { PreviewEnvWarningModalComponent } from '../../uiComponent/PreviewEnvWarningComponent/PreviewEnvWarningModalComponent';
import EnvConfig from '../../configs/envConfig/envConfig';
import { useDoNotShowAgain } from '../../features/patient/hook/useDoNotShowAlertAgain';
import { useLoggedInUserFromContext } from '../loggedInUserContext';
import { useDeepCompareEffectWithInitialRender } from '../../hooks/useDeepCompareEffect';

export interface PreviewWarningContextValue {
  openPreviewWarningModal: () => void;
}

const PreviewWarningContext = createContext<
  PreviewWarningContextValue | undefined
>(undefined);

export const usePreviewWarningContext = () => {
  const context = useContext(PreviewWarningContext);
  return context as PreviewWarningContextValue;
};

export interface PreviewWarningContextProviderProps {
  children: ReactNode;
}
export const PreviewWarningContextProvider = ({
  children,
}: PreviewWarningContextProviderProps) => {
  const {
    isOpen,
    open,
    close,
  } = useOpen();
  const { userId } = useLoggedInUserFromContext();
  const [isWelcomed] = useDoNotShowAgain('IsWelcomed', userId);

  const openPreviewWarningModal = () => {
    if (EnvConfig.showPreviewWarning) open();
  };

  const contextValue = useGetContextValue<PreviewWarningContextValue>({
    openPreviewWarningModal,
  });

  useDeepCompareEffectWithInitialRender(() => {
    if (EnvConfig.showPreviewWarning && isWelcomed) open();
  }, [isWelcomed]);

  return (
    <PreviewWarningContext.Provider value={contextValue}>
      {
        EnvConfig.showPreviewWarning
        && (
          <PreviewEnvWarningModalComponent
            isOpen={isOpen}
            onClose={close}
          />
        )
      }
      {children}
    </PreviewWarningContext.Provider>
  );
};
