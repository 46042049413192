import { Select, SelectProps } from 'antd';
import { map } from 'lodash';
import { useState } from 'react';
import { SelectType } from '../../../Input/types';
import { usePatientDeviceGetAvailableLoanDevice } from '../../../../uc-api-sdk';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useDeepCompareMemo } from '../../../../hooks/useDeepCompareEffect';

export interface LoanerPhoneSearchSelectContainerProps extends SelectType<string> { }

export const LoanerPhoneSearchSelectContainer = ({
  id,
  value,
  onChange,
  disabled,
}: LoanerPhoneSearchSelectContainerProps) => {
  const [searchValue, setSearchValue] = useState<string>();
  const availableLoanDeviceInfo = usePatientDeviceGetAvailableLoanDevice({});

  const handleOnSearch: SelectProps['onSearch'] = (value) => {
    setSearchValue(value);
    if (!value) return;
    ApiRequestHelper.tryCatch(
      availableLoanDeviceInfo.send({
        params: {
          searchRequest: {
            filter: {
              deviceIdNear: value,
            },
            pageInfo: {
              page: 1,
              size: 15,
            },
          },
        }
      }),
      'Something went wrong with device id search'
    );
  };

  const debouncedHandleSearch = useDebounce(handleOnSearch, 500);

  const options = useDeepCompareMemo(() => (
    map(availableLoanDeviceInfo.data?.data?.content, (device) => ({
      label: device.deviceId,
      value: device.deviceId,
    }))
  ), [
    availableLoanDeviceInfo.data?.data,
  ]);

  return (
    <Select
      id={id}
      value={value}
      onChange={onChange}
      onSearch={debouncedHandleSearch}
      loading={availableLoanDeviceInfo.isLoading}
      placeholder="Enter Device ID"
      showSearch
      options={options}
      autoClearSearchValue={false}
      defaultActiveFirstOption={false}
      // Don't show dropdown when there is no user input
      notFoundContent={!searchValue ? null : undefined}
      disabled={disabled}
    />
  );
};
