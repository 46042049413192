import {
  PageInfo, TicketFilter, useTicketSearch, useTicketSearchTickets
} from '../../../uc-api-sdk';
import { AjaxOptions } from '../../../uc-api-sdk/staticFiles/useReqHook';

interface GetTicketHookOptions extends AjaxOptions {

}

export interface GetTicketHook {
  filter?: TicketFilter;
  options?: GetTicketHookOptions;
  pageInfo?: PageInfo;
}

export const useGetTicket = (
  filter: GetTicketHook['filter'],
  pageInfo?: GetTicketHook['pageInfo'],
  options: GetTicketHook['options'] = {
    sendOnMount: true,
  },
) => {
  const taskCountInfo = useTicketSearchTickets({
    options,
    params: {
      searchRequest: {
        filter,
        pageInfo,
      },
    },
  });
  return taskCountInfo;
};

export const useTicketSearchInOutstanding = (ids: string[]) => {
  const search = useTicketSearch({
    options: { sendOnMount: true },
    params: {
      filter: {
        idIn: {
          in: ids,
        }
      }
    }
  });
  return search;
};
