import dayjs from 'dayjs';
import { CareNoteContextProvider } from '../../../../contexts/CareNoteContext/CareNoteContext';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { CareNote, useCareNoteSearch } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { CareNotesComponent } from '../../component/CareNotesComponent/CareNotesComponent';

export const CareNotesTabContainer = () => {
  const { info } = usePatientContext();
  const { id } = info?.patientInfo || {};
  const notes = useCareNoteSearch({
    options: {
      sendOnMount: !!id,
    },
    params: {
      filter: {
        memberId: id,
      },
      // @ts-ignore
      pageInfo: {
        pagination: false,
      },
    },
  });

  return (
    <CareNoteContextProvider
      refresh={notes.refetch}
    >
      <FetchComponent
        info={notes}
        alwaysShowChildren
      >
        {(data) => {
          const content = data?.rawValue?.data?.content || [];
          content.sort((a: CareNote, b: CareNote) => {
            if (dayjs(a.createdAt).isAfter(dayjs(b.createdAt))) {
              return -1;
            }
            return 1;
          });
          return (
            <CareNotesComponent
              careNotes={content}
            />
          );
        }}
      </FetchComponent>
    </CareNoteContextProvider>
  );
};
