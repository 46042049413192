import {
  ReactNode,
  createContext,
  useContext
} from 'react';
import { map } from 'lodash';
import { useEffectOnce } from 'usehooks-ts';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import { ClinicEventsService } from '../../features/patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import {
  APIResponse,
  ClinicEvent,
  Nullable,
  PageResponse,
  VisitTypeEnum,
  useClinicEventSearch,
} from '../../uc-api-sdk';
import { useDeepCompareMemo } from '../../hooks/useDeepCompareEffect';
import { BaseSearchParams, BaseSortDirection } from '../../uc-api-sdk/staticFiles/useReqHook';
import { useUpdateListener } from '../UpdateContext/UpdateContext';
import { usePatientContext } from '../PatientInfoContext/PatientInfoContext';

export interface FutureClinicEventContextValue {
  futureClinicEventServices: ClinicEventsService[];
  isLoading: boolean;
  refetch?: () => void;
  asyncRefetch: () => Promise<ClinicEvent[]>;
  hasFutureClinicEvent: boolean;
}

const FutureClinicEventContext = createContext<
  FutureClinicEventContextValue | undefined
>(undefined);

export const useFutureClinicEventContext = () => {
  const context = useContext(FutureClinicEventContext);
  if (!context) throw new Error('FutureClinicEventContext not found');
  return context as FutureClinicEventContextValue;
};

export interface FutureClinicEventContextProviderProps {
  children: ReactNode;
  patientId: string;
  startTime?: Nullable<string>;
  asyncRefetch?: () => Promise<APIResponse<PageResponse<ClinicEvent>> | null | undefined>;
  sendOnMount?: boolean;
  visitType?: VisitTypeEnum[];
}
export const FutureClinicEventContextProvider = ({
  children,
  patientId,
  startTime,
  asyncRefetch,
  sendOnMount = true,
  visitType,
}: FutureClinicEventContextProviderProps) => {
  const { info } = usePatientContext();
  const futureFollowUpClinicEventInfo = useClinicEventSearch({});
  const { isLoading } = futureFollowUpClinicEventInfo;
  const futureClinicEvents = futureFollowUpClinicEventInfo.data?.data?.content ?? [];

  const fetchClinicEventInfo = () => {
    const filter = {
      patientId,
      startTimeRange: { gt: startTime },
      hasCheckedOut: false,
    } as BaseSearchParams<ClinicEvent>['filter'];

    if (visitType) {
      filter.visitTypeIn = { in: visitType };
    }

    futureFollowUpClinicEventInfo.send({

      params: {
        filter,
        pageInfo: {
          page: 1,
          size: 100,
          sort: [
            { direction: BaseSortDirection.ASC, property: 'startTime' },
          ]
        }
      },
    });
  };

  const handleRefetch = () => {
    info?.refetch(['followUpVisitWindowRefetch']);
    fetchClinicEventInfo();
  };

  useEffectOnce(() => {
    if (sendOnMount) fetchClinicEventInfo();
  });

  useUpdateListener('VISIT_CREATED', handleRefetch);
  useUpdateListener('VISIT_UPDATED', handleRefetch);
  useUpdateListener('VISIT_DELETED', handleRefetch);

  const futureClinicEventServices = useDeepCompareMemo(() => (
    map(futureClinicEvents, (clinicEvent) => new ClinicEventsService(clinicEvent))
  ), [futureClinicEvents]);

  const handleAsyncRefetch = async () => {
    const res = await asyncRefetch?.();
    return res?.data?.content || [];
  };

  const contextValue = useGetContextValue<FutureClinicEventContextValue>({
    futureClinicEventServices,
    isLoading,
    refetch: handleRefetch,
    asyncRefetch: handleAsyncRefetch,
    hasFutureClinicEvent: futureClinicEventServices.length > 0,
  }, [
    handleAsyncRefetch,
    handleRefetch,
  ]);

  return (
    <FutureClinicEventContext.Provider value={contextValue}>
      {children}
    </FutureClinicEventContext.Provider>
  );
};
