/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, FormInstance, FormProps } from 'antd';
import { filter, map } from 'lodash';
import { FieldError } from 'rc-field-form/es/interface';
import {
  ReactNode, useCallback, useMemo, useState
} from 'react';
import { useBoolean } from 'usehooks-ts';
import { useFormConnectorContext } from '../../contexts/FormConnectorContext/FormConnectorContext';
import { useNestedFormContext } from '../../contexts/NestedFormControlContext/NestedFormComponent';
import { useNestedFormControlContext } from '../../contexts/NestedFormControlContext/NestedFormControlContext';
import { ValidateErrorEntity } from '../../features/adminPortal2/types/componentTypes';
import { createRequiredRule } from '../../helpers/form';
import { GetFieldValue } from '../../types/form';
import { TooltipStyle } from '../../uiComponent/TooltipComponent/TooltipComponent';

export interface TooltipInfo {
  tip: ReactNode;
  type?: TooltipStyle;
}

export interface FormInputInfo {
  name: string;
  label?: ReactNode;
  emptyMessage?: string;
  tooltip?: TooltipInfo;
  description?: string;
}

export interface FormInput {
  [key: string]: FormInputInfo;
}

export interface FormOptions {
  form?: FormInstance;
  name?: string;
}

export type GetInfo<T extends FormInput, K extends keyof T> = (input: K) => T[K];
export type GetName<T extends FormInput, K extends keyof T> = (input: K) => T[K]['name'];
export type GetLabel<T extends FormInput, K extends keyof T> = (input: K) => T[K]['label'];
export type GetNameAndLabel<T extends FormInput, K extends keyof T> = (input: K) => ({ name: T[K]['name'], label: T[K]['label'] });
export type GetDescription<T extends FormInput, K extends keyof T> = (input: K) => T[K]['description'];
export type GetTooltip<T extends FormInput, K extends keyof T> = (input: K) => (
  TooltipInfo | undefined
);
export type GetEmptyMessage<T extends FormInput, K extends keyof T> = (input: K) => T[K]['emptyMessage'];
export type GetValue<T extends FormInput, K extends keyof T> = (
  (input: K, getFieldValue: GetFieldValue) => any
);
export type HasValue<T extends FormInput, K extends keyof T> = (
  (input: K, getFieldValue: GetFieldValue) => any
);
export type ShouldUpdate<T extends FormInput, K extends keyof T> = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (input: K[]) => ((prev: any, curr: any) => boolean)
);
export type GetRequiredRules<T extends FormInput, K extends keyof T> = (
  (input: K) => ReturnType<typeof createRequiredRule>[]
);
export type ShouldShow = (getFieldValue: GetFieldValue) => boolean;

// This will only work if the formInput key matches the name
export type FormSubmitValue<T extends () => { formInput: FormInput }> = Record<keyof (ReturnType<T>['formInput']), any>;

export const useFormHookFactory = <
  T extends FormInput,
  K extends keyof T,
>(formInput: T, options: FormOptions = {}) => {
  const [formInputValue] = useState(formInput);
  const { value: isTouched, setValue: setIsTouched } = useBoolean();
  const { form: nestedForm } = useNestedFormContext() || {};
  const { form: formConnector } = useFormConnectorContext() || {};
  const {
    disabled: nestedFormDisabled,
  } = useNestedFormContext() || useNestedFormControlContext() || {};
  const form = options.form || nestedForm || formConnector || Form.useForm()[0];
  const formName = options.name;
  const formDisabled = !!nestedFormDisabled;

  const handleTouched = useCallback(() => { setIsTouched(true); }, [setIsTouched]);
  const handleReset = useCallback(() => {
    form.resetFields();
    setIsTouched(false);
  }, [form, setIsTouched]);
  const getInfo: GetInfo<T, K> = useCallback((input) => formInputValue[input], [formInputValue]);
  const getName: GetName<T, K> = useCallback((input) => formInputValue[input].name || '', [formInputValue]);
  const getLabel: GetLabel<T, K> = useCallback((input) => formInputValue[input].label || '', [formInputValue]);
  const getNameAndLabel: GetNameAndLabel<T, K> = useCallback((input) => ({
    name: formInputValue[input].name,
    label: formInputValue[input].label || '',
  }), [formInputValue]);
  const shouldUpdate: ShouldUpdate<T, K> = useCallback((input: K[]) => (prev, curr) => (
    input.some((v) => prev[formInputValue[v].name] !== curr[formInputValue[v].name])
  ), [formInputValue]);
  const getValue: GetValue<T, K> = useCallback((input, getFieldValue) => (
    getFieldValue(formInputValue[input].name)
  ), [formInputValue]);
  const hasValue: GetValue<T, K> = useCallback((input, getFieldValue) => (
    getFieldValue(formInputValue[input].name) !== undefined
  ), [formInputValue]);
  const getTooltip: GetTooltip<T, K> = useCallback((input) => (
    formInputValue[input].tooltip
  ), [formInputValue]);
  const getEmptyMessage: GetEmptyMessage<T, K> = useCallback((input) => (
    formInputValue[input].emptyMessage
  ), [formInputValue]);
  const handleSubmit: (onSubmit?: (...args: any[]) => Promise<void> | void) => FormProps['onFinish'] = useCallback((onSubmit) => (values) => {
    onSubmit?.(values);
  }, []);
  const handleSubmitAndReset: (onSubmit?: (...args: any[]) => Promise<void> | void) => FormProps['onFinish'] = useCallback((onSubmit) => async (values) => {
    await onSubmit?.(values);
    setIsTouched(false);
    form.resetFields();
  }, [form]);
  const handleValidationFailed: (onFinishFailed?: (errorInfo: ValidateErrorEntity<any>) => void) => FormProps['onFinishFailed'] = useCallback((onFinishedFailed) => (errorInfo) => {
    onFinishedFailed?.(errorInfo);
  }, []);
  const getRequiredRules: GetRequiredRules<T, K> = useCallback((input: K) => (
    [createRequiredRule(getEmptyMessage(input))]
  ), [formInputValue]);
  const resetValidationErrors = useCallback((customForm?: FormInstance) => {
    const { getFieldsError, setFields } = customForm || form;
    const fieldErrors = getFieldsError();
    const resetFields = filter(fieldErrors, (fieldError) => (
      (fieldError as FieldError).errors.length > 0
    ));
    setFields(map(resetFields, (resetField) => ({
      name: resetField.name,
      errors: undefined,
    })));
  }, [form]);

  const getDescription: GetDescription<T, K> = useCallback((input) => formInputValue[input].description || '', [formInputValue]);
  const result = useMemo(() => ({
    form,
    formDisabled,
    formName,
    formInput: formInputValue,
    getInfo,
    getName,
    shouldUpdate,
    getLabel,
    getNameAndLabel,
    getValue,
    hasValue,
    getTooltip,
    getDescription,
    getEmptyMessage,
    handleSubmit,
    handleSubmitAndReset,
    handleValidationFailed,
    getRequiredRules,
    isTouched,
    handleTouched,
    handleReset,
    resetValidationErrors,
  }), [
    form,
    formDisabled,
    formInputValue,
    getInfo,
    getName,
    shouldUpdate,
    getDescription,
    getLabel,
    getNameAndLabel,
    getValue,
    hasValue,
    getTooltip,
    getEmptyMessage,
    handleSubmit,
    handleSubmitAndReset,
    handleValidationFailed,
    getRequiredRules,
    isTouched,
    handleTouched,
    handleReset,
    resetValidationErrors,
  ]);

  return result;
};
