import { useNavigate } from 'react-router-dom';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { usePatientDeletePatient } from '../../../../uc-api-sdk';
import ModalButton from '../../../../uiComponent/ModalButton/ModalButton';
import { DeleteEnrolledPatientRedirectComponent } from '../../component/DeleteEnrolledPatientRedirectComponent/DeleteEnrolledPatientRedirectComponent';
import { DeletePatientFormComponent, DeletePatientFormValue } from '../../component/DeletePatientFormComponent/DeletePatientFormComponent';

export interface DeletePatientContainerProps {
  memberId: string;
  fullName: string;
}

export const DeletePatientModalContainer = ({
  memberId,
  fullName
}: DeletePatientContainerProps) => {
  const deletePatient = usePatientDeletePatient({
    options: {
      sendOnMount: false,
    }
  });

  const { info } = usePatientContext();
  const { enrolledProgramService } = info || {};
  const isEnrolled = enrolledProgramService?.isEnrolled();

  const navigate = useNavigate();

  const onSubmit = (v: DeletePatientFormValue) => {
    const req = deletePatient.send({
      params: {
        request: {
          patientId: memberId,
          deleteReason: v.reason
        }
      }
    });

    ApiRequestHelper.tryCatch(req, {
      success: 'Successfully deleted patient. You will be redirected to the previous page.',
      error: 'Failed to delete patient. Please try again.',
      onSuccess(res) {
        if (res?.code === 200) {
          setTimeout(() => {
            navigate(-1);
          }, 1500);
        }
      },
      onError(err) {
        console.log(err);
      }
    });
  };

  const shouldBeDisabled = (values: DeletePatientFormValue) => values.delete !== 'DELETE' || values.reason?.trim() === '';

  return (
    <div className="flex jc-e">
      <ModalButton
        button="Delete patient"
        buttonType="link"

      >
        {({ close }) => (isEnrolled ? (
          <DeleteEnrolledPatientRedirectComponent
            fullName={fullName}
            onCancel={close}
          />
        )
          : (
            <DeletePatientFormComponent
              onSubmit={onSubmit}
              fullName={fullName}
              onCancel={close}
              shouldBeDisabled={shouldBeDisabled}
              isLoading={deletePatient.isLoading}
            />
          ))}
      </ModalButton>
    </div>
  );
};
