import { useCallback } from 'react';
import { ApiRequestHelper, Options, Response } from '../../helpers/ApiRequest';

export const useApiRequestHelper = () => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
  const tryCatch = useCallback(<T extends unknown>(
    reqPromise: Promise<Response<T>>,
    options?: Options<T>,
  ) => ApiRequestHelper.tryCatch(reqPromise, options), []);

  return {
    tryCatch,
  };
};
