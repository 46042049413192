import { useMemo, useState } from 'react';
import { isNumber } from 'lodash';
import { useUpdateEffect } from 'usehooks-ts';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { OutstandingComponent } from '../../component/OutstandingComponent/OutstandingComponent';
import { updateItemCountAfterActions } from '../../hook/useOutstandingGetItemCount';
import { useMessagePopupContext } from '../../../../contexts/MessageContext/MessagePopupContext';
import { usePatientOutstandingItems } from '../../hook/usePatientOutstandingItems/usePatientOutstandingItems';
import { usePrevious } from '../../../../hooks/usePrevious/usePrevious';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';

export interface OutstandingContainerProps {
  patientId: string;
}

export const OutstandingContainer = ({
  patientId,
}: OutstandingContainerProps) => {
  const { info } = usePatientContext();
  const {
    outstandingItems,
    refetchOutstandingItems,
    totalOutstandingItemCount,
  } = usePatientOutstandingItems(info);
  const prevTotalItemCount = usePrevious(totalOutstandingItemCount);
  const { messageCount } = outstandingItems;
  const { isChatOpen } = useMessagePopupContext() || {};
  const [isResolvingMessage, setIsResolvingMessage] = useState(false);
  const outstandingUpdate = useUpdate('OUTSTANDING_COUNT_UPDATED');

  updateItemCountAfterActions(refetchOutstandingItems);

  useUpdateEffect(() => {
    if (
      prevTotalItemCount !== undefined
      && totalOutstandingItemCount === 0
      && totalOutstandingItemCount !== prevTotalItemCount
    ) {
      outstandingUpdate.updateValue();
    }
  }, [
    totalOutstandingItemCount,
    prevTotalItemCount,
  ]);

  const isMessageResolved = useMemo(() => {
    const hasUnread = isNumber(messageCount) && messageCount > 0;
    if (hasUnread) {
      if (isChatOpen) setIsResolvingMessage(true);
      return false;
    }
    // doesn't have unread message
    if (isResolvingMessage) {
      // outstanding message is considered as resolved when chat is closed
      if (isChatOpen) return false;
      // chat is closed
      setIsResolvingMessage(false);
    }
    return true;
  }, [
    messageCount,
    isChatOpen,
    isResolvingMessage,
  ]);

  if (totalOutstandingItemCount < 1 && isMessageResolved) {
    return null;
  }

  return (
    <OutstandingComponent
      patientId={patientId}
      outstanding={outstandingItems}
      isMessageResolved={isMessageResolved}
    />
  );
};
