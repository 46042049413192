import { map } from 'lodash';
import { BehaviorGoalItem } from '../../../../../uc-api-sdk';
import { useDeepCompareMemo } from '../../../../../hooks/useDeepCompareEffect';

import './BehaviorGoalCardListComponent.scss';
import { useBehaviorGoalHelpers } from '../../../hook/useBehaviorGoalHelpers';
import { BehaviorGoalCategoryLabelComponent } from '../BehaviorGoalCategoryLabelComponent/BehaviorGoalCategoryLabelComponent';

interface BehaviorGoalCardListComponentProps {
  value: BehaviorGoalItem[]
}

export const BehaviorGoalCardListComponent = ({
  value,
}: BehaviorGoalCardListComponentProps) => {
  const { groupBehaviorGoalsByCategory } = useBehaviorGoalHelpers(value);
  const groupedValue = useDeepCompareMemo(groupBehaviorGoalsByCategory, [value]);

  return (
    <div className="behavior-goal-list pr50">
      {
        map(groupedValue, (behaviorGoals, category) => (
          <div
            key={category}
            className="flex fd-c f-w mb20"
          >
            <BehaviorGoalCategoryLabelComponent
              category={behaviorGoals[0].category}
              customCategory={behaviorGoals[0].customCategory}
            />
            {
              map(behaviorGoals, (goal, idx) => (
                <div key={idx} className="mb12">
                  {goal.behaviorGoalValue}
                </div>
              ))
            }
          </div>
        ))
      }
    </div>
  );
};
