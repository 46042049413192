import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { EnrollmentActionEnum, usePatientUpdateEnrollment } from '../../../../uc-api-sdk';
import { SubmitValue } from '../../../consentForm/component/ConsentFormComponent/ConsentFormComponent';
import { ConsentFormContainer, ConsentFormContainerProps } from '../../../consentForm/container/ConsentFormContainer/ConsentFormContainer';

export interface EnrollmentConsentFormContainerProps extends ConsentFormContainerProps {}

export const EnrollmentConsentFormContainer = ({
  patientInfo,
  showSentModal,
  onSubmit,
}: EnrollmentConsentFormContainerProps) => {
  const { send: sendMixpanel } = useMixpanelContext();
  const programEnrollmentUpdate = usePatientUpdateEnrollment({});

  const handleOnSubmit = async (v?: SubmitValue) => {
    sendMixpanel({ event: MixpanelEvents.PatientConsentSubmit });
    await programEnrollmentUpdate.send({
      params: {
        memberId: patientInfo.id,
        request: {
          action: EnrollmentActionEnum.CONSENT_SUBMIT,
          consentRequest: {
            consentType: v?.signatureMethod,
          },
        },
      },
    });
    onSubmit?.();
  };

  return (
    <ConsentFormContainer
      patientInfo={patientInfo}
      onSubmit={handleOnSubmit}
      showSentModal={showSentModal}
    />
  );
};
