import { Form, FormProps } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { Dayjs } from 'dayjs';
import _ from 'lodash';
import { Moment } from 'moment';
import { FC, ReactNode } from 'react';
import { createRequiredRule } from '../../../../helpers/form';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { PriorityLevelEnum } from '../../../../uc-api-sdk';
import { ConfirmationModalComponent } from '../../../../uiComponent/ConfirmationModalComponent/ConfirmationModalComponent';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import { TicketCommentMessages, TicketPlaceHolders } from '../../constant/text';
import { EditTicketMode } from '../../constants';
import { useCreateEditTicketForm } from '../../hook/useCreateEditTicketForm';
import '../../style/style.scss';
import { TicketCommentFormItemComponent } from '../TicketCommentFormItemComponent/TicketCommentFormItemComponent';
import { TicketFormItemComponent } from '../TicketFormItemComponent/TicketFormItemComponent';

export type SubmitValueType = Record<string, string | Dayjs | Moment | null | undefined>;
export interface SubmitValue extends SubmitValueType{
  priorityLevel?: PriorityLevelEnum | null;
  assignedTo?: string | null;
  memberId?: string | null;
  description: string;
  dueDate: Dayjs | Moment;
  comment?: string;
}
export interface FormButtonsProps {
  onCancel?: () => void;
}
export interface CreateEditTicketFormComponentProps {
  initialValues?: FormProps['initialValues'];
  onFinish?: FormProps['onFinish'],
  mode?: EditTicketMode;
  onCancel?: () => void;
  children: ReactNode | ((props: FormButtonsProps) => ReactNode);
  title: string;
  isLoading: boolean;
  patientInputDisable?: boolean;
  clinicId?: string;
}
export const CreateEditTicketFormComponent: FC<CreateEditTicketFormComponentProps> = ({
  initialValues,
  onFinish,
  mode = 'Create',
  onCancel,
  children,
  title,
  isLoading,
  patientInputDisable,
  clinicId,
}) => {
  const hook = useCreateEditTicketForm();
  const { form } = hook;
  const { value: open, setTrue: showModal, setFalse: hideModal } = useBoolean(false);
  const isValuesChanged = (initValues: Store | undefined, newValues: SubmitValue) => {
    if (_.isEmpty(newValues)) {
      return false;
    }
    if (Object.keys(newValues).length >= 2) {
      return true;
    }

    if (newValues.dueDate && '_isAMomentObject' in newValues.dueDate
      && !newValues?.dueDate?.isSame(initValues?.dueDate)) { return true; }
    return false;
  };

  const discardChanges = () => {
    const fields = form.getFieldsValue(true, (meta) => meta.touched);
    if (!isValuesChanged(initialValues, fields)) { onCancel?.(); } else {
      showModal();
    }
  };

  const getButtons = () => {
    if (typeof children === 'function') {
      return children({
        onCancel: discardChanges,
      });
    }
    return children;
  };

  return (
    <Form
      className="createEditTicketForm h100 flex fd-c jc-sb ticket-ant-drawer-body"
      initialValues={initialValues}
      onFinish={hook.handleSubmitAndReset(onFinish)}
      scrollToFirstError
      form={form}
      preserve={false}
      disabled={isLoading}
      layout="vertical"
    >
      <FixedComponent>
        <FixedComponent.Child>
          <TicketFormItemComponent
            title={title}
            patientInputDisable={patientInputDisable ?? false}
            clinicId={clinicId}
          />
        </FixedComponent.Child>
        <FixedComponent.Child
          isFixed
        >
          <div className="border-t pt20">
            {(mode === 'Edit' || mode === 'ReOpen')
              && (
              <TicketCommentFormItemComponent
                mode={mode}
                commentFormItemProps={{
                  info: hook.getInfo('comment'),
                  rules: [createRequiredRule(TicketCommentMessages[mode])],
                }}
                placeholder={TicketPlaceHolders[mode]}
              />
              )}
            <div>
              {getButtons()}
            </div>
            <ConfirmationModalComponent
              open={open}
              onCancel={hideModal}
              onConfirm={onCancel}
              text="Discard unsaved changes?"
              cancelText="Cancel"
              confirmText="Discard"
            />
          </div>
        </FixedComponent.Child>
      </FixedComponent>
    </Form>
  );
};
