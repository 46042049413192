import { RcFile } from 'antd/lib/upload';
import { AxiosError } from 'axios';
import {
  createContext, MutableRefObject,
  ReactNode, useContext,
  useState
} from 'react';
import { useDeepCompareEffect } from '../../hooks/useDeepCompareEffect';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import { useSendMessage } from '../../hooks/useSendMessage/useSendMessage';
import {
  MessageHistory, MessageType
} from '../../services/CHSServices/types/data';
import {
  MessageACKPublisher,
  MessageChannel
} from '../../types/message';
import { Patient } from '../../uc-api-sdk';
import { useLoggedInUserFromContext } from '../loggedInUserContext';
import { useMessageServicesContext } from './MessageServicesContext';

export interface MessagePatientContextValue {
  draft?: string;
  file?: RcFile;
  setFile: (file?: RcFile) => void;
  setDraft: (value?: string) => void;
  patientId: string;
  patientChannel?: MessageChannel;
  patientInfo?: Patient | null;
  isLoadingPatientChannel: boolean;
  getPatientChannelHistoryFirstTime: () => void;
  handleGetMorePatientMessages: () => void;
  publishACKMessage: () => ReturnType<ReturnType<typeof useSendMessage>['sendMessage']> | null;
  markChannelAsUnread: () => ReturnType<ReturnType<typeof useSendMessage>['sendMessage']> | null;
  handleSendMessage: ReturnType<typeof useSendMessage>['sendMessage'];
  scrollToBottom: (ref: MutableRefObject<Element | null>) => void;
}

const MessagePatientContext = createContext<MessagePatientContextValue | undefined>(undefined);

export const useMessagePatientContext = () => {
  const context = useContext(MessagePatientContext);
  return (context || {}) as MessagePatientContextValue;
};

export interface MessagePatientContextProviderProps {
  children: ReactNode;
  patientId: string;
  patientInfo?: Patient | null;
}
export const MessagePatientContextProvider = ({
  children,
  patientId,
  patientInfo,
}: MessagePatientContextProviderProps) => {
  const {
    userInfo,
  } = useLoggedInUserFromContext();

  const {
    channelMap,
    userMap,
    handleSetChannel,
    handleSetUserMap,
    handleSetPatientMapData,
    handleGetChannelHistory,
    handleInitChannel,
  } = useMessageServicesContext();
  const [isLoadingPatientChannel, setIsLoadingPatientChannel] = useState(false);
  const [draft, setDraft] = useState<string | undefined>(undefined);
  const [file, setFile] = useState<RcFile | undefined>(undefined);

  const patientChannel: MessagePatientContextValue['patientChannel'] = channelMap[patientId];
  const {
    sendMessage,
  } = useSendMessage({ patientId, patientInfo });

  const setPatientChannel = (
    messageHistory: MessageHistory,
  ) => {
    handleSetChannel(patientId, messageHistory);
  };

  const getPatientChannelHistory = async (
    fromTimestamp?: string,
  ): Promise<void> => {
    setIsLoadingPatientChannel(true);
    // fromTimestamp is to support load more message in the past
    const res = await handleGetChannelHistory([patientId], fromTimestamp);
    const history = (res?.[0] || {}) as MessageHistory;
    setPatientChannel(history);
    setIsLoadingPatientChannel(false);
  };

  const getPatientChannelHistoryFirstTime = async () => {
    if (!patientChannel) {
      getPatientChannelHistory();
    }
    if (!userMap[patientId]) {
      handleSetUserMap(patientId, true);
    }
  };

  const handleGetMorePatientMessages: MessagePatientContextValue['handleGetMorePatientMessages'] = () => {
    const fromTimestamp = patientChannel.fromTimestamp || '0';
    getPatientChannelHistory(fromTimestamp);
  };

  const handleSendMessage: MessagePatientContextValue['handleSendMessage'] = async (
    payload,
  ) => {
    await handleInitChannel(patientId);

    let res;
    try {
      res = await sendMessage(payload);
      setDraft(undefined);
      return res;
    } catch (error) {
      const axiosError = (error as AxiosError).response?.data as string;
      return {
        success: false,
        error: axiosError || res?.error || 'Failed to send message',
      };
    }
  };

  const publishACKMessage: MessagePatientContextValue['publishACKMessage'] = () => (
    patientChannel?.isUnread
      ? (
        handleSendMessage({
          type: MessageType.ACK,
          publisher: `${MessageACKPublisher.READ}-${userInfo?.id}`,
        })
      )
      : null
  );

  const markChannelAsUnread: MessagePatientContextValue['markChannelAsUnread'] = () => (
    !patientChannel?.isUnread
      ? (
        handleSendMessage({
          type: MessageType.ACK,
          publisher: `${MessageACKPublisher.UNREAD}-${userInfo?.id}`,
        })
      )
      : null
  );

  const scrollToBottom: MessagePatientContextValue['scrollToBottom'] = (
    ref,
  ) => {
    if (ref?.current) {
      ref.current.scrollIntoView();
    }
  };

  useDeepCompareEffect(() => {
    if (patientInfo) {
      handleSetPatientMapData(patientInfo);
    }
  }, [patientInfo]);

  const contextValue = useGetContextValue<MessagePatientContextValue>({
    patientId,
    patientChannel,
    patientInfo,
    isLoadingPatientChannel,
    getPatientChannelHistoryFirstTime,
    handleGetMorePatientMessages,
    publishACKMessage,
    markChannelAsUnread,
    handleSendMessage,
    scrollToBottom,
    draft,
    setDraft,
    file,
    setFile,
  });

  return (
    <MessagePatientContext.Provider key={patientId} value={contextValue}>
      {children}
    </MessagePatientContext.Provider>
  );
};
