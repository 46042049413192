import { OrderItemEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface OrderItemEnumComponentProps {
  value: OrderItemEnum;
}

export const OrderItemEnumComponent = ({
  value,
}: OrderItemEnumComponentProps) => {
  const getFullName = () => {
    switch (value) {
      case OrderItemEnum.BLOOD_GLUCOSE_SUPPLY: return 'Blood Glucose Supply';
      case OrderItemEnum.BPM1_CUFF: return 'BPM1 Cuff';
      case OrderItemEnum.BPM1_HOTSPOT: return 'BPM1 Hotspot';
      case OrderItemEnum.BPM1_ROUTER: return 'BPM1 Router';
      case OrderItemEnum.EASE_CUFF: return 'iHealth Ease Cuff';
      case OrderItemEnum.IHEALTH_AIR: return 'iHealth Air';
      case OrderItemEnum.IHEALTH_CLEAR: return 'iHealth Clear';
      case OrderItemEnum.IHEALTH_EASE: return 'iHealth Ease';
      case OrderItemEnum.IHEALTH_GLUCO: return 'iHealth Gluco';
      case OrderItemEnum.IHEALTH_NEXUS: return 'iHealth Nexus';
      case OrderItemEnum.REWARD_ITEM: return 'Reward Item';
      case OrderItemEnum.LOAN_DEVICE: return 'Loan Device';
      case OrderItemEnum.CELLULAR_BIOLAND: return 'Cellular Bioland';
      case OrderItemEnum.CELLULAR_PYLO: return 'Cellular Pylo';
      case OrderItemEnum.A1C: return 'A1C';
      case OrderItemEnum.OTHER: return 'Other';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  return (<>{getFullName()}</>);
};
