import { LinkButton } from '../../../../../uiComponent/LinkButton/LinkButton';

export interface StartNowVisitButtonComponentProps {
  loading?: boolean;
  onClick?: () => void;
}

export const StartNowVisitButtonComponent = ({
  loading,
  onClick,
}: StartNowVisitButtonComponentProps) => (
  <LinkButton
    useBlueColor
    onClick={onClick}
    loading={loading}
  >
    Start now
  </LinkButton>
);
