import dayjs, { Dayjs } from 'dayjs';
import { isEmpty } from 'lodash';
import { REQUEST_DATE_FORMAT, DATE_STANDARD } from '../../../../constants/timeFormat';
import {
  BenefitOrderEnum, PriorAuthCaseStatusEnum
} from '../../../../uc-api-sdk';
import { InsuranceSubmitValue, PriorAuthSubmitValue, TempPriorAuthCase } from '../../../../hooks/formHook/useInsuranceForm';
import TimezoneService from '../../../../helpers/timezone/timezoneService';

export class InsuranceHelperService {
  static MAX_INSURANCE_COUNT = 3;

  static getLocalDate = (date?: Dayjs | string) => (
    date ? dayjs(date).format(DATE_STANDARD) : undefined
  );

  static getDateFormat = (date?: Dayjs | string) => {
    if (!date) return undefined;
    const d = dayjs(date);
    return d.startOf('day').utc().format(REQUEST_DATE_FORMAT);
  };

  static shouldPriorAuthCaseBeCancellable = <T extends TempPriorAuthCase>(
    priorAuthCase?: T
  ) => {
    if (isEmpty(priorAuthCase)) {
      return true;
    }
    return (
      priorAuthCase?.status !== PriorAuthCaseStatusEnum.APPROVED
      || (
        typeof priorAuthCase?.expirationDays === 'number'
        && priorAuthCase?.expirationDays < 0 // already expired
      )
    );
  };

  static getDayjsDate = (date?: string | null) => {
    if (!date) return undefined;
    return TimezoneService.calcDateTimeDayjs(date);
  };

  static parsePriorAuthCaseToFormValues = (priorAuthCase?: TempPriorAuthCase) => {
    if (!priorAuthCase) {
      return undefined;
    }
    return {
      priorAuthStatus: priorAuthCase?.status ?? undefined,
      priorAuthSubmissionDate: this.getDayjsDate(priorAuthCase?.submissionDate),
      priorAuthApprovalDate: this.getDayjsDate(priorAuthCase?.approvalDate),
      priorAuthAuthorizationNumber: priorAuthCase?.authorizationNumber ?? undefined,
      priorAuthCPTCodeUnits: priorAuthCase?.cptCodes ?? undefined,
      priorAuthAuthPeriod: (
        priorAuthCase
          ? (
            [
              this.getDayjsDate(priorAuthCase.localStartDate),
              this.getDayjsDate(priorAuthCase.localExpiredDate)
            ]
          ) : undefined
      ),
      priorAuthDeniedDate: this.getDayjsDate(priorAuthCase?.deniedDate),
      priorAuthDeniedReason: priorAuthCase?.deniedReason ?? undefined,
      priorAuthComments: priorAuthCase?.comment,
      priorAuthCPTCodeCategory: priorAuthCase?.cptCodeCategory,
    } as PriorAuthSubmitValue;
  };

  static validatePriorAuthCase = (values: InsuranceSubmitValue) => {
    const primaryInsurance = values.insuranceList?.find(
      (v) => v.benefitOrder === BenefitOrderEnum.PRIMARY
    );

    if (!primaryInsurance) {
      return true;
    }

    const {
      priorAuth,
      priorAuthActiveCase,
      priorAuthApprovalDate,
      priorAuthAuthPeriod,
      priorAuthAuthorizationNumber,
      priorAuthStatus,
    } = values || {};
    if (!priorAuth) {
      // priorAuth is turned off
      return true;
    }

    // make sure all required fields are satisfied
    switch (priorAuthStatus) {
      case PriorAuthCaseStatusEnum.APPROVED:
        if (
          priorAuthActiveCase?.status === PriorAuthCaseStatusEnum.APPROVED
        ) {
          // case already exists
          return true;
        }
        if (
          !priorAuthAuthorizationNumber
          || !priorAuthApprovalDate
          || !priorAuthAuthPeriod?.[0]
          || !priorAuthAuthPeriod?.[1]
        ) {
          return false;
        }
        break;
      default:
    }
    return true;
  };
}
