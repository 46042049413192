/* eslint-disable react/no-unstable-nested-components */
import { Collapse } from 'antd';
import { map } from 'lodash';
import { CaretRightOutlined } from '@ant-design/icons';
import { ReactNode, useMemo } from 'react';
import { RoleTypeEnum } from '../../../../uc-api-sdk';
import { MemberOptions } from '../../type';
import { OnCallCheckboxGroupComponent } from '../OnCallCheckboxGroupComponent/OnCallCheckboxGroupComponent';
import { MembersRolesTitleComponent } from '../../../../uiComponent/MembersRolesTitleComponent/MembersRolesTitleComponent';
import './OnCallCollapseComponent.scss';

const { Panel } = Collapse;

export interface OnCallPanelComponentProps {
  header: string;
  key: string;
  val: ReactNode | null | undefined;
}

export const OnCallPanelComponent = ({
  header,
  key,
  val,
}: OnCallPanelComponentProps) => (
  <Panel header={header} key={key}>
    {val}
  </Panel>
);

export interface OnCallCollapseComponentProps {
  expanded?: boolean;
  value?: string[];
  onChange?: (v: string[]) => void;
  userRolesList: RoleTypeEnum[];
  membersWithRolesMap: Map<RoleTypeEnum, object[]>;
  mode: string;
  showSelectAllCheckbox?: boolean;
}

export const OnCallCollapseComponent = ({
  expanded = true,
  value,
  onChange,
  userRolesList,
  membersWithRolesMap,
  mode,
  showSelectAllCheckbox,
}: OnCallCollapseComponentProps) => {
  const memberData = useMemo(() => {
    const res: MemberOptions[] = [];
    map(userRolesList, (role) => {
      map(membersWithRolesMap.get(role), (member) => (
        res.push(member as MemberOptions)
      ));
    });
    return res;
  }, [userRolesList, membersWithRolesMap]);

  return (
    <Collapse
      className="on-call-collapse-component mb10"
      defaultActiveKey={expanded ? ['1'] : []}
      onChange={undefined}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
    >
      <Panel
        header={
        mode === 'Patient Care'
          ? <MembersRolesTitleComponent membersRolesList={userRolesList} />
          : userRolesList.join(' + ')
        }
        key="1"
      >
        <OnCallCheckboxGroupComponent
          memberData={memberData}
          title={<MembersRolesTitleComponent membersRolesList={userRolesList} />}
          value={value}
          onChange={onChange}
          showSelectAllCheckbox={showSelectAllCheckbox}
        />
      </Panel>
    </Collapse>
  );
};
