import classNames from 'classnames';
import { Spin } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { CallCenterStatusName } from '../../../../contexts/CallCenterContext/types';
import { BackgroundType, TagComponent, TagType } from '../../../../uiComponent/TagComponent/TagComponent';

import './CallCenterStatusButtonComponent.scss';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';

export interface CallCenterStatusButtonComponentProps {
  isLoggedIn: boolean;
  status: CallCenterStatusName;
  background?: BackgroundType;
  isLoading?: boolean;
  showCaret?: boolean;
}

export const CallCenterStatusButtonComponent = ({
  isLoggedIn,
  status,
  background = 'oval',
  isLoading,
  showCaret,
}: CallCenterStatusButtonComponentProps) => {
  const getTagType = (): TagType => {
    if (!isLoggedIn || status === 'Offline') {
      return 'default';
    }
    switch (status) {
      case 'Online':
        return 'success';
      case 'Busy':
        return 'warning';
      case 'Error':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <TagComponent
      type={getTagType()}
      background={background}
      iconType="solidDot"
      className={classNames({
        'call-center-status-btn': true,
        'checking-login': isLoading,
      })}
    >
      <div className="flex ai-c">
        {
          isLoading
          && (
            <TooltipComponent
              type={null}
              title="checking login status"
            >
              <Spin size="small" className="mr5" />
            </TooltipComponent>
          )
        }
        <span>
          {isLoggedIn ? status : 'Offline'}
        </span>
        {showCaret && <CaretDownOutlined />}
      </div>
    </TagComponent>
  );
};
