import { includes } from 'lodash';
import { DeviceModelEnumComponent } from '../../../../enumComponent/DeviceModelEnumComponent/DeviceModelEnumComponent';
import { AssignDeviceInfo, DeviceModelEnum } from '../../../../uc-api-sdk';
import { SupportDeviceInfoComponent } from '../SupportDeviceInfoComponent/SupportDeviceInfoComponent';
import { DeviceImageComponent } from '../../DeviceImageComponent';

export interface DeviceLabelComponentProps {
  device?: AssignDeviceInfo;
  deviceModel?: AssignDeviceInfo['deviceModel'];
  showSupportDevice?: boolean;
  showImage?: boolean;
}

export const DeviceLabelComponent = ({
  device,
  deviceModel = device?.deviceModel,
  showSupportDevice = true,
  showImage = true,
}: DeviceLabelComponentProps) => {
  const renderSupportDeviceInfo = () => {
    const isBPM1 = includes(
      [DeviceModelEnum.BPM1, DeviceModelEnum.BPM1_HOTSPOT],
      deviceModel
    );
    if (deviceModel && isBPM1) {
      return (
        <>
          {' '}
          <span>+</span>
          {' '}
          <SupportDeviceInfoComponent
            deviceModel={deviceModel}
            showImage={showImage}
          />
        </>
      );
    }
    return null;
  };
  return (
    <>
      {
        showImage
        && (
          <DeviceImageComponent
            deviceModel={deviceModel ?? undefined}
          />
        )
      }
      <span>
        <DeviceModelEnumComponent value={deviceModel ?? undefined} />
      </span>
      {
        showSupportDevice
        && renderSupportDeviceInfo()
      }
    </>
  );
};
