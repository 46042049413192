import { BillableProcessStatusEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface BillableTimeReviewStatusEnumComponentProps {
  value?: BillableProcessStatusEnum;
}

export const BillableTimeReviewStatusEnumComponent = ({
  value,
}: BillableTimeReviewStatusEnumComponentProps) => {
  const getFullName = () => {
    switch (value) {
      case BillableProcessStatusEnum.REVIEW_REQUIRED: return 'Waiting Review';
      case BillableProcessStatusEnum.CONFIRMED: return 'Confirmed';
      case BillableProcessStatusEnum.REVIEW_EXPIRED: return 'Review Expired';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  return (<>{getFullName()}</>);
};
