import { Icons } from '../../../../icons/Icons';

export const DeletedPatientProfilePageComponent = () => (
  <div className="w100 h100 flex jc-c ai-c fd-c">
    <Icons.PatientDeletedIcon />
    <div>
      <h1>This patient has been delete.</h1>
      <p>Please contact the IT team if you think this is a mistake.</p>
    </div>
  </div>
);
