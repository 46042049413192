import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { every, map, reduce } from 'lodash';
import {
  OnCall,
  OnCallFor,
  PriorityCoverEnum,
  RoleTypeEnum,
  useOnCallGet,
  useOnCallSave,
} from '../../../../uc-api-sdk';
import { useApiRequestHelper } from '../../../../hooks/useApiRequestHelper/useApiRequestHelper';
import { Options, Response } from '../../../../helpers/ApiRequest';
import { AccountHelper } from '../../../../helpers/account';
import { useOnCallMainContext } from '../OnCallMainContext/OnCallMainContext';

export type OnCallType = 'patientCare' | 'task';

export interface OnCallContextProps {
  children: ReactNode;
  type: OnCallType;
}

export interface OnCallContextValue {
  isOnCall: boolean;
  onCallData: NonNullable<ReturnType<typeof useOnCallGet>['data']>['data'];
  onCallFor: OnCallFor[];
  onCallGet: ReturnType<typeof useOnCallGet> | undefined;
  onCallSave: ReturnType<typeof useOnCallSave> | undefined;
  onOnCallSubmit: (onCallFor: OnCallFor[], options: Options<OnCall>) => Promise<Response<OnCall>>;
  onOnCallOff: (options: Options<OnCall>) => Promise<Response<OnCall>>;
  isLoading: boolean;
  hasAllEmployees: boolean;
  AllEmployeesRoles: RoleTypeEnum[];
  employees: string[];
  employeeIds: string[];
  rolePriority: Partial<Record<RoleTypeEnum, PriorityCoverEnum>>;
  employeesNameWithRole: (roles: RoleTypeEnum[]) => string[];
}

const initialValue: OnCallContextValue = {
  isOnCall: false,
  onCallData: undefined,
  onCallFor: [],
  onCallGet: undefined,
  onCallSave: undefined,
  onOnCallSubmit: () => {
    console.error('onOnCallSubmit: OnCallContext has not been implemented yet!');
    return Promise.reject();
  },
  onOnCallOff: () => {
    console.error('onOnCallOff: OnCallContext has not been implemented yet!');
    return Promise.reject();
  },
  isLoading: false,
  hasAllEmployees: false,
  AllEmployeesRoles: [],
  employees: [],
  employeeIds: [],
  rolePriority: {},
  employeesNameWithRole: () => {
    console.error('onOnCallOff: OnCallContext has not been implemented yet!');
    return [];
  },
};

export const OnCallContext = createContext<OnCallContextValue>(initialValue);
export const OnCallTaskContext = createContext<OnCallContextValue>(initialValue);

export const OnCallContextProvider = ({
  children,
  type,
}: OnCallContextProps) => {
  const { onCallGet, onCallSave, isLoading } = useOnCallMainContext();
  const { tryCatch } = useApiRequestHelper();

  const Provider = useMemo(() => {
    switch (type) {
      case 'task': return OnCallTaskContext.Provider;
      case 'patientCare':
      default: return OnCallContext.Provider;
    }
  }, [type]);

  const onCallForKey = useMemo(() => {
    switch (type) {
      case 'task': return 'taskOnCallFor';
      case 'patientCare':
      default: return 'onCallFor';
    }
  }, [type]);

  const onCallActiveKey = useMemo(() => {
    switch (type) {
      case 'task': return 'taskOnCallActive';
      case 'patientCare':
      default: return 'active';
    }
  }, [type]);

  const onOnCallSubmit = useCallback<OnCallContextValue['onOnCallSubmit']>((onCallFor, options) => {
    const result = onCallSave.send({
      params: {
        onCall: {
          [onCallForKey]: onCallFor,
          [onCallActiveKey]: true,
        }
      }
    });
    return tryCatch(result, {
      ...options,
      onSuccess: (v) => {
        onCallGet.refetch();
        options.onSuccess?.(v);
      },
    });
  }, [
    onCallForKey,
    onCallActiveKey,
    tryCatch,
    onCallSave.send,
    onCallGet.refetch,
  ]);

  const onOnCallOff = useCallback<OnCallContextValue['onOnCallOff']>((options) => {
    const result = onCallSave.send({
      params: {
        onCall: {
          [onCallActiveKey]: false,
        }
      }
    });
    return tryCatch(result, {
      ...options,
      onSuccess: (v) => {
        onCallGet.refetch();
        options.onSuccess?.(v);
      },
    });
  }, [
    tryCatch,
    onCallActiveKey,
    onCallSave.send,
    onCallGet.refetch,
  ]);

  const onCallFor = useMemo(() => (
    onCallGet.data?.data?.[onCallForKey] || []
  ), [onCallGet.data, onCallForKey]);

  const hasAllEmployees = useMemo(() => (
    every(onCallGet.data?.data?.[onCallForKey] || [], (v: OnCallFor) => (
      v.allSelected
    ))
  ), [onCallGet.data, onCallForKey]);

  const AllEmployeesRoles = useMemo(() => (
    reduce(onCallGet.data?.data?.[onCallForKey] || [], (res, v: OnCallFor) => {
      if (v.allSelected && v.role) {
        res.push(v.role);
      }
      return res;
    }, [] as RoleTypeEnum[])
  ), [onCallGet.data, onCallForKey]);

  const rolePriority = useMemo(() => (
    reduce(onCallGet.data?.data?.[onCallForKey] || [], (res, v: OnCallFor) => {
      if (v.role && v.priority) {
        res[v.role] = v.priority;
      }
      return res;
    }, {} as Record<RoleTypeEnum, PriorityCoverEnum>)
  ), [onCallGet.data, onCallForKey]);

  const employees = useMemo(() => (
    reduce(onCallGet.data?.data?.[onCallForKey] || [], (res, curr) => (
      res.concat(map(curr.employees, (e) => AccountHelper.getFullName(e) || ''))
    ), [] as string[])
  ), [onCallGet.data, onCallForKey]);

  const employeesNameWithRole = useCallback((roles: RoleTypeEnum[]) => (
    reduce(onCallGet.data?.data?.[onCallForKey] || [], (res, curr) => {
      if (roles.includes(curr.role as RoleTypeEnum)) {
        return res.concat(map(curr.employees, (e) => AccountHelper.getFullName(e) || ''));
      }
      return res;
    }, [] as string[])
  ), [onCallGet.data, onCallForKey]);

  const employeeIds = useMemo(() => (
    reduce(onCallGet.data?.data?.[onCallForKey] || [], (res, curr) => (
      res.concat(map(curr.employeeIds, (id) => id || ''))
    ), [] as string[])
  ), [onCallGet.data, onCallForKey]);

  const value = useMemo<OnCallContextValue>(() => ({
    isOnCall: onCallGet.data?.data?.[onCallActiveKey] || false,
    onCallData: onCallGet.data?.data,
    onCallFor,
    onCallGet,
    onCallSave,
    onOnCallSubmit,
    onOnCallOff,
    isLoading,
    hasAllEmployees,
    AllEmployeesRoles,
    employees,
    employeeIds,
    rolePriority,
    employeesNameWithRole,
  }), [
    onCallFor,
    onCallGet.data,
    onCallGet.error,
    onCallGet.isLoading,
    onCallSave.data,
    onCallSave.error,
    onCallSave.isLoading,
    onOnCallSubmit,
    onOnCallOff,
    isLoading,
    hasAllEmployees,
    AllEmployeesRoles,
    employees,
    employeeIds,
    rolePriority,
    employeesNameWithRole,
  ]);

  return (
    <Provider value={value}>
      {children}
    </Provider>
  );
};

export interface UseOnCallContextArg {
  type: OnCallType;
}

export const useOnCallContext = ({
  type,
}: UseOnCallContextArg) => {
  const getContext = () => {
    switch (type) {
      case 'task': return useContext(OnCallTaskContext);
      case 'patientCare':
      default: return useContext(OnCallContext);
    }
  };

  const value = getContext();
  return value;
};
