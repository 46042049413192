import { TableCacheNameEnum } from '../../../../hooks/useTableChange/useTableChange';
import { PatientListTypeEnum } from '../../../../uc-api-sdk';
import { PatientsTableContainer } from '../../../patients/container/PatientsTableContainer/PatientsTableContainer';
import { ProviderViewEnrolledPatientsTableComponent } from '../../component/ProviderViewEnrolledPatientsTableComponent/ProviderViewEnrolledPatientsTableComponent';

export interface ProviderViewEnrolledPatientsTableContainerProps {
  doctorId?: string;
}

export const ProviderViewEnrolledPatientsTableContainer = ({
  doctorId
}: ProviderViewEnrolledPatientsTableContainerProps) => (
  <PatientsTableContainer
    type={PatientListTypeEnum.ENROLLED}
    name={TableCacheNameEnum.EnrolledPatients}
    doctorId={doctorId}
  >
    {({
      totalSize,
      pagination,
      dataSource,
      onChange,
      filters,
      sorters,
      isLoading,
      exportData,
    }) => (
      <ProviderViewEnrolledPatientsTableComponent
        totalSize={totalSize}
        pagination={pagination}
        dataSource={dataSource}
        onChange={onChange}
        filters={filters}
        sorters={sorters}
        loading={isLoading}
        exportData={exportData}
      />
    )}
  </PatientsTableContainer>
);
