import { Select, SelectProps } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { MedicalOrganization, useMedicalOrganizationSearch } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { IHEALTH_INQUIRING_PROVIDER_OPTION } from './constant';

export interface InquiringProviderContainerProps extends SelectProps {

}

export const InquiringProviderContainer = ({
  ...props
}: InquiringProviderContainerProps) => {
  const [inquiringProvider, setInquiringProvider] = useState<Record<string, string> | undefined>();
  const { info } = usePatientContext();
  const { patientInfoService } = info || {};
  const clinicId = patientInfoService?.getClinicId();
  const clinic = useMedicalOrganizationSearch({
    options: {
      sendOnMount: !!clinicId,
    },
    params: {
      filter: {
        id: clinicId,
      }
    }
  });

  const providerMap = useMemo(() => {
    const m: Record<string, string> = {
      [IHEALTH_INQUIRING_PROVIDER_OPTION.value]: IHEALTH_INQUIRING_PROVIDER_OPTION.label,
    };
    if (clinic.data?.data?.content) {
      m[clinic.data?.data?.content[0].billingEntity?.officeNpi as string] = clinic.data?.data?.content[0].profile?.businessName || '';
    }
    return m;
  }, [clinic.data?.data?.content]);

  useEffect(() => {
    if (clinic.data?.data?.content && props.value && !inquiringProvider) {
      setInquiringProvider({
        providerName: providerMap[props.value.providerNpi],
        providerNpi: props.value.providerNpi,
      });
    }
  }, [props.value]);

  const handleOnChange = (value: string) => {
    const providerObject = {
      providerName: providerMap[value],
      providerNpi: value,
    };
    setInquiringProvider(providerObject);
    props.onChange?.(providerObject, []);
  };

  return (
    <FetchComponent
      info={clinic}
    >
      {({ data }) => {
        const org = data?.rawValue?.content?.[0] as MedicalOrganization;
        const options = [IHEALTH_INQUIRING_PROVIDER_OPTION];
        if (org.billingEntity?.officeNpi) {
          options.push({
            label: org.profile?.businessName || '',
            value: org.billingEntity?.officeNpi,
          });
        }
        return (
          <Select
            showSearch
            optionFilterProp="children"
            options={options}
            filterOption={(input, option) => `${option?.label}`?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            onChange={handleOnChange}
            value={inquiringProvider?.providerNpi}
          />
        );
      }}
    </FetchComponent>
  );
};
