import { Icons } from '../../icons/Icons';
import { ErrorBoundaryCardComponent } from './ErrorBoundaryCardComponent';

export interface ErrorBoundaryFallbackComponentProps {

}

export const ErrorBoundaryFallbackComponent = () => (
  <ErrorBoundaryCardComponent
    icon={<Icons.ErrorIcon />}
    message={`Oops, the widget is currently unavailable due to
      an error. Please try to refresh widget with the button below or refresh the page if issue persists.`}
    showRefreshButton
  />
);
