import { ErrorInfo, ReactNode } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ErrorBoundary, ErrorBoundaryProps } from 'react-error-boundary';
import { useMixpanelContext } from '../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../contexts/MixpanelContext/MixpanelEvents';
import { useSessionStorage } from '../../hooks';
import { _StorageKeyEnum } from '../../hooks/useSessionStorage/storageKey';
import { ErrorBoundaryFallbackComponent } from './ErrorBoundaryFallbackComponent';
import { ErrorBoundaryIdentifier, ErrorBoundaryPage } from './errorBoundaryIdentifier';
import { ErrorSimulatorPlaceHolderComponent } from './simulator/ErrorSimulator';

export const ErrorBoundaryInfoKey = 'ErrorBoundaryInfo';
export interface ErrorType extends Error {
  request?: string;
}

export interface ErrorBoundaryComponentProps extends Pick<ErrorBoundaryProps, 'FallbackComponent'> {
  children: ReactNode;
  identifier?: keyof typeof ErrorBoundaryPage | keyof typeof ErrorBoundaryIdentifier;
}

export const ErrorBoundaryComponent = ({
  children,
  FallbackComponent,
  identifier,
}: ErrorBoundaryComponentProps) => {
  const { send } = useMixpanelContext() || {};
  const [retried, setRetried] = useSessionStorage<Record<string, string>>(
    _StorageKeyEnum.RELOAD_ON_LAZY_LOAD_ERROR,
    {}
  );

  const handleOnError = (error: ErrorType, info: ErrorInfo) => {
    if (error.name === 'ChunkLoadError') {
      const fileName = error.request || window.location.href;
      if (!retried?.[fileName]) {
        setRetried({ ...retried, [fileName]: 'true' });
        window.location.reload();
      }
    }
    send?.({
      event: MixpanelEvents.CrashEvent,
      properties: {
        identifier,
        error,
        info,
      },
    });
    sessionStorage.setItem(ErrorBoundaryInfoKey, JSON.stringify(info));
  };

  return (
    <ErrorBoundary
      FallbackComponent={FallbackComponent || ErrorBoundaryFallbackComponent}
      onError={handleOnError}
    >
      {children}
      <ErrorSimulatorPlaceHolderComponent spot={identifier} />
    </ErrorBoundary>
  );
};
