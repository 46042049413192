import { isNil } from 'lodash';
import { Language } from '../../types/user';
import { ConnectionInfo } from './services/types';
import {
  CallCenterConnectionStatusType,
  CallCenterPatientProfile,
  CallCenterStatusName,
  CallLogInfo,
  ConnectAgentStateNames,
  ConnectPatientAttribute,
  ConnectPatientAttributes,
} from './types';

export const getAttributeValue = (
  attributeProp?: string | ConnectPatientAttribute,
): (string | undefined) => (
  (!isNil(attributeProp) && typeof attributeProp !== 'string')
    ? (attributeProp as ConnectPatientAttribute)?.value
    : attributeProp
);

export const parseAttribute = (
  attribute: ConnectPatientAttributes,
): CallCenterPatientProfile => {
  const {
    patientId,
    name,
    language,
    enrolledProgramId,
    isEp,
    organizationId,
    newPortal,
  } = attribute;

  return {
    id: getAttributeValue(patientId),
    fullName: getAttributeValue(name),
    language: getAttributeValue(language) as Language['code'],
    enrolledProgram: {
      id: getAttributeValue(enrolledProgramId),
      isEnrolled: Boolean(getAttributeValue(isEp)) === true,
    },
    organization: {
      id: getAttributeValue(organizationId),
    },
    newPortal: Boolean(getAttributeValue(newPortal)) === true,
  };
};

export const getAvailabilityStateName = (
  availabilityState: ConnectAgentStateNames,
): CallCenterStatusName => {
  switch (availabilityState) {
    case 'Available':
      return 'Online';
    case 'Offline':
    case 'Init':
      return 'Offline';
    case 'Error':
    case 'RealtimeCommunicationError':
    case 'BadAddressAgent':
    case 'BadAddressCustomer':
    case 'MultipleCcpWindows':
      return 'Error';
    default:
      return 'Busy';
  }
};

export const validatePhoneNumber = (
  phoneNumber: string,
): boolean => {
  if (!phoneNumber) return false;

  // allow to call in the US only for now
  return /\+?1?[0-9]{10}/.test(phoneNumber);
};

export const getConnectionStatus = (
  connectionInfo: ConnectionInfo,
): CallCenterConnectionStatusType => {
  if (!connectionInfo) return undefined;
  const { isConnected, isInbound } = connectionInfo.contactInfo;
  if (isInbound) {
    return isConnected ? 'ANSWERING' : 'ANSWER_PENDING';
  }
  return isConnected ? 'CALLING' : 'CALL_PENDING';
};

export const isCallLogDone = (
  callLog?: CallLogInfo,
) => (
  !callLog || !!callLog.isSubmitted || !!callLog.isClosed
);
