import { Input, InputProps } from 'antd';
import { FC } from 'react';

export interface AddressAptInputComponentProps {
  value?: string;
  onChange?: InputProps['onChange'];
}

export const AddressAptInputComponent: FC<AddressAptInputComponentProps> = ({
  value,
  onChange,
}) => (
  <Input
    value={value}
    onChange={onChange}
  />
);
