import Card from 'antd/lib/card/Card';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { PatientHiatusStatus, PatientHiatusStatusEnum, PauseServiceInfo } from '../../../../uc-api-sdk';
import { PauseServiceTagComponent } from '../TagsComponent/PauseServiceTagComponent/PauseServiceTagComponent';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { HiatusReasonEnumListComponent } from '../../../../enumComponent/HiatusReasonEnumComponent/HiatusReasonEnumListComponent';
import './PauseServiceViewCardComponent.scss';
import { PauseServicNameEnum } from './PauseServiceNameEnum';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';

export interface PauseServiceViewCardComponentProps {
  hiatusDetail: PatientHiatusStatus;
  serviceName: PauseServicNameEnum;
}

export const PauseServiceViewCardComponent = ({
  hiatusDetail,
  serviceName,
}: PauseServiceViewCardComponentProps) => {
  const { info } = usePatientContext();
  const { enrolledProgramService } = info || {};

  const isServicePausedOrPending = (
    pauseServiceInfo: PauseServiceInfo | undefined | null
  ) => pauseServiceInfo && pauseServiceInfo?.status !== PatientHiatusStatusEnum.RESUMED;

  const renderStatusTagByService = (pauseServiceInfo: PauseServiceInfo | undefined | null) => {
    if (!isServicePausedOrPending(pauseServiceInfo)) {
      return null;
    }
    if (pauseServiceInfo?.status === PatientHiatusStatusEnum.PAUSED) {
      return <PauseServiceTagComponent.PausedTag />;
    }
    return <PauseServiceTagComponent.PendingTag />;
  };

  const renderStatusTag = (
    hiatusDetail: PatientHiatusStatus | null | undefined,
    serviceName: PauseServicNameEnum
  ) => {
    if (!enrolledProgramService?.isHiatusStatusPaused()) {
      return null;
    }
    switch (serviceName) {
      case PauseServicNameEnum.VITAL_MONITORING:
        return renderStatusTagByService(hiatusDetail?.vitalMonitoring);
      case PauseServicNameEnum.VISIT_SERVICE:
        return renderStatusTagByService(hiatusDetail?.visitService);
      case PauseServicNameEnum.BILLING:
        return renderStatusTagByService(hiatusDetail?.billingReport);
      default:
        return null;
    }
  };

  const renderTooltip = (serviceName: PauseServicNameEnum) => {
    switch (serviceName) {
      case PauseServicNameEnum.VITAL_MONITORING:
        return 'Pausing 7-day inactive alerts during a care team-defined period means the care team won\'t contact patients due to a lack of measurements.';
      case PauseServicNameEnum.VISIT_SERVICE:
        return 'Pausing visit due alerts during a care team-defined period prevents the care team from receiving compliant alerts to schedule regular visits with patients.';
      case PauseServicNameEnum.BILLING:
        return (
          <>
            <div>
              Pausing Billing means that during this period, no measurements
              or time will count toward billing for the current cycle. Time
              and measurement outside the pause will still be eligible
              for billing within the same cycle.
            </div>
            <div>
              If the patient prefers not to receive a bill,
              mark them as "self pay" in the patient profile
              {' > '}
              insurance widget or discharge them as self-mode patients.
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const renderTimerangeByService = (
    pauseServiceInfo: PauseServiceInfo | null | undefined
  ) => {
    if (!isServicePausedOrPending(pauseServiceInfo)) {
      return (
        <div>Not Paused</div>
      );
    }
    return (
      <div>
        {pauseServiceInfo?.status === PatientHiatusStatusEnum.PAUSED
          ? 'Paused from'
          : 'Scheduled to be paused from'}
        &nbsp;
        <DisplayDateComponent value={pauseServiceInfo?.startDate} format="USA_DATE" />
        {' to '}
        <DisplayDateComponent value={pauseServiceInfo?.endDate} format="USA_DATE" />
      </div>
    );
  };

  const renderTimerage = (
    hiatusDetail: PatientHiatusStatus | undefined | null,
    serviceName: PauseServicNameEnum
  ) => {
    if (!enrolledProgramService?.isHiatusStatusPaused()) {
      return (
        <div>Not Paused</div>
      );
    }
    switch (serviceName) {
      case PauseServicNameEnum.VITAL_MONITORING:
        return renderTimerangeByService(hiatusDetail?.vitalMonitoring);
      case PauseServicNameEnum.VISIT_SERVICE:
        return renderTimerangeByService(hiatusDetail?.visitService);
      case PauseServicNameEnum.BILLING:
        return renderTimerangeByService(hiatusDetail?.billingReport);
      default:
        return null;
    }
  };

  const renderReasonByService = (pauseServiceInfo: PauseServiceInfo | undefined | null) => {
    if (!isServicePausedOrPending(pauseServiceInfo)) {
      return null;
    }
    return (
      <div>
        <div className="text-gray-scale-2 fs12">Reason</div>
        <HiatusReasonEnumListComponent value={pauseServiceInfo?.reason || []} splitter=", " />
      </div>
    );
  };

  const renderReason = (
    hiatusDetail: PatientHiatusStatus | undefined | null,
    serviceName: PauseServicNameEnum
  ) => {
    if (!enrolledProgramService?.isHiatusStatusPaused()) {
      return null;
    }
    switch (serviceName) {
      case PauseServicNameEnum.VITAL_MONITORING:
        return renderReasonByService(hiatusDetail?.vitalMonitoring);
      case PauseServicNameEnum.VISIT_SERVICE:
        return renderReasonByService(hiatusDetail?.visitService);
      case PauseServicNameEnum.BILLING:
        return renderReasonByService(hiatusDetail?.billingReport);
      default:
        return null;
    }
  };

  return (
    <Card className="pauseServiceViewCardComponent">
      <div className="flex mb20 gap2">
        <span className="bold">{serviceName}</span>
        <Tooltip
          placement="top"
          title={renderTooltip(serviceName)}
        >
          <InfoCircleOutlined />
        </Tooltip>
        {renderStatusTag(hiatusDetail, serviceName)}
      </div>
      <p>{renderTimerage(hiatusDetail, serviceName)}</p>
      <p>{renderReason(hiatusDetail, serviceName)}</p>
    </Card>
  );
};
