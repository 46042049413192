import { useMemo } from 'react';
import { Tabs } from 'antd';
import { map } from 'lodash';
import { ArticleCategory } from '../../../../uc-api-sdk';
import { ArticleCategories } from '../../types';

export interface ArticleSearchOptionHeaderComponentProps {
  categories: ArticleCategories,
  onCategoryChange: (newCategory: NonNullable<ArticleCategory['id']>) => void,
  selectedCategory: NonNullable<ArticleCategory['id']>,
}
export const ArticleSearchOptionHeaderComponent = (
  props: ArticleSearchOptionHeaderComponentProps,
) => {
  const {
    categories,
    onCategoryChange,
    selectedCategory,
  } = props;

  const categoryTabItems = useMemo(() => (
    map(
      categories,
      (c) => ({
        key: c.id as string,
        label: c.name as string,
      }),
    )
  ), [categories]);

  return (
    <Tabs
      size="small"
      activeKey={selectedCategory}
      onChange={onCategoryChange}
      items={categoryTabItems}
      className="article-select-option-header"
    />
  );
};
