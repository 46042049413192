import { ReactNode } from 'react';
import { message } from 'antd';
import ModalButton, { ModalButtonProps } from '../ModalButton/ModalButton';
import { Title } from '../Title/Title';
import { ChangePasswordContainerProps, ChangePasswordContainer } from '../../uiContainer/ChangePasswordContainer/ChangePasswordContainer';
import { PasswordFormComponentProps } from '../PasswordFormComponent/PasswordFormComponent';

export interface ChangePasswordModalComponentProps
  extends Omit<PasswordFormComponentProps, 'children' | 'onSubmit' | 'onCancel'>,
  Omit<ChangePasswordContainerProps, 'children'> {
  userId: string;
  autoCloseOnSubmit?: boolean;
  successMessage?: string;
  modalTitle?: ReactNode;
  width?: number;
  buttonText?: string;
  buttonType?: ModalButtonProps['buttonType'];
  buttonClassName?: ModalButtonProps['buttonClassName'];
  buttonBlock?: boolean;
}

export const ChangePasswordModalComponent = ({
  userType,
  userId,
  autoCloseOnSubmit = true,
  successMessage = 'Password has been changed successfully.',
  onError,
  afterSubmit,
  buttonText = 'Change Password',
  modalTitle = buttonText,
  width = 573,
  buttonType = 'default',
  buttonBlock,
  buttonClassName,
  ...passwordFormProps
}: ChangePasswordModalComponentProps) => (
  <ModalButton
    button={buttonText}
    buttonType={buttonType}
    buttonClassName={buttonClassName}
    buttonBlock={buttonBlock}
    width={width}
    destroyOnClose
  >
    {
      ({ close }) => {
        const handleAfterSubmit: ChangePasswordContainerProps['afterSubmit'] = (
          token
        ) => {
          message.success(successMessage);
          afterSubmit?.(token);
          if (autoCloseOnSubmit) close();
        };

        return (
          <div className="change-password-modal-body p40">
            <Title>
              {modalTitle}
            </Title>
            <ChangePasswordContainer
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...passwordFormProps}
              userType={userType}
              userId={userId}
              afterSubmit={handleAfterSubmit}
              onError={onError}
            />
          </div>
        );
      }
    }
  </ModalButton>
);
