import classNames from 'classnames';
import { useMemo } from 'react';
import { Menu, MenuProps } from 'antd';
import { useRouter } from '../../../../hooks/useRouter/useRouter';
import { getMenuItem } from '../../../carePortalLayout/SideMenuComponent/SideMenuItem';
import { Icon } from '../../../../icons/Icon';
import { DUMP_REPORT_ICON } from '../../../../assets/commonIconInfo';
import useChangePage from '../../../../hooks/useChangePage/useChangePage';
import { Icons } from '../../../../icons/Icons';

enum BillerPortalSideMenuKeyEnum {
  Reports = 'reports',
  Utilities = 'utilities',

}

type BillerPortalSideMenuKey =
  BillerPortalSideMenuKeyEnum.Reports
  | BillerPortalSideMenuKeyEnum.Utilities

export const BillerPortalSideMenuComponent = () => {
  const page = useChangePage();
  const { location } = useRouter();

  const selectedKey = useMemo<BillerPortalSideMenuKey | undefined>(() => {
    const { pathname } = location;
    if (pathname.startsWith(page.createBillerPortalReportsUrl())) {
      return BillerPortalSideMenuKeyEnum.Reports;
    }
    if (pathname.startsWith(page.createBillerPortalUtilitiesUrl())) {
      return BillerPortalSideMenuKeyEnum.Utilities;
    }
    return undefined;
  }, [location, page]);

  const items: MenuProps['items'] = [
    getMenuItem({
      icon: <Icon info={DUMP_REPORT_ICON} />,
      label: 'Reports',
      key: BillerPortalSideMenuKeyEnum.Reports,
      onClick: page.goToBillerPortalReports,
    }),
    getMenuItem({
      icon: <Icons.UtilitiesMenu />,
      label: 'Utilities',
      key: BillerPortalSideMenuKeyEnum.Utilities,
      onClick: page.goToBillerPortalUtilities,
    }),
  ];

  return (
    <div className="sidebar-component">
      <Menu
        className={classNames({
          'sidebar-menu': true,
          collapsed: true,
          'bg-uc-blue': true,
        })}
        selectedKeys={selectedKey ? [selectedKey] : []}
        mode="inline"
        inlineCollapsed
        items={items}
      />
    </div>
  );
};
