import { Select, SelectProps } from 'antd';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';

export interface PotentialClinicSelectComponentProps extends SelectProps<string> { }

export const PotentialClinicSelectComponent = ({
  ...props
}: PotentialClinicSelectComponentProps) => {
  const { info } = usePatientContext();
  const { patientAssociatedClinics } = info?.patientInfoService || {};
  const options = patientAssociatedClinics?.map((clinic) => ({
    label: clinic?.clinicInfo?.alias,
    value: clinic?.clinicInfo?.id,
  }));

  return (
    <Select
      options={options}
      defaultActiveFirstOption
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};
