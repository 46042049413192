import { MultiProviderDropdownComponent, MultiProviderDropdownComponentProps } from '../../../uiComponent/MultiProviderDropdownComponent/MultiProviderDropdownComponent';
import { TableFilterType } from '../type/filter';
import { SearchIcon } from './SearchIcon';

export type CreateMultiProviderSearchFilterOptions = MultiProviderDropdownComponentProps['coWorkerDropdownProps'] & {
  containerId: string; // containerId must be provided when relative is true
  needToFilter?: boolean;
  clinicIdList?: string[];
}

export const createMultiProviderSearchFilter = (
  options: CreateMultiProviderSearchFilterOptions,
): TableFilterType => ({
  filterDropdown: ((props) => (
    <MultiProviderDropdownComponent
      coWorkerDropdownProps={options}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )),
  filterIcon: SearchIcon,
});
