import { ReactNode } from 'react';
import { CardTextComponent } from '../../../../uiComponent/CardTypographyComponent/CardTextComponent/CardTextComponent';
import { PatientCommonLabelComponent } from '../../../patient/component/PatientCommonLabelComponent/PatientCommonLabelComponent';
import { VitalEnumType } from '../../../../uc-api-sdk';
import { VitalsEnumTypeComponent } from '../../../../enumComponent/VitalsEnumTypeComponent/VitalsEnumTypeComponent';

export interface InterventionMonitoringLayoutComponentProps {
  vitalType: VitalEnumType;
  children: ReactNode
}

export const InterventionMonitoringLayoutComponent = ({
  vitalType,
  children,
}: InterventionMonitoringLayoutComponentProps) => (
  <div className="mb10">
    <PatientCommonLabelComponent>
      <VitalsEnumTypeComponent value={vitalType} />
    </PatientCommonLabelComponent>
    <CardTextComponent>
      {children}
    </CardTextComponent>
  </div>
);
