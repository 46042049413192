import { Badge } from 'antd';
import Avatar from '../../../../uiComponent/Avatar/Avatar';

export interface MessageUserAvatarComponentProps {
  avatarUrl?: string | null;
}

export const MessageUserAvatarComponent = ({
  avatarUrl,
}: MessageUserAvatarComponentProps) => (
  <Badge offset={[-4, 4]}>
    {
      avatarUrl
        ? <Avatar size={43} src={avatarUrl} />
        : <Avatar size={43} />
    }
  </Badge>
);
