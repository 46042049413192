import { DisplayDateComponent } from '../../../../../uiComponent/DisplayComponent/DisplayDateComponent';

export interface GoalFormTimeComponentProps {
  value: string;
}

export const GoalFormTimeComponent = ({
  value,
}: GoalFormTimeComponentProps) => (
  <DisplayDateComponent
    value={value}
    format="USA_DATE"
  />
);
