import {
  filter,
  find,
  keys,
  map,
  uniq
} from 'lodash';
import { useDeepCompareMemo } from '../../../hooks/useDeepCompareEffect';
import {
  AssignDeviceInfo,
  DeviceAssignment,
  DeviceModelEnum,
  DeviceTypeEnum,
  MonitorMethodEnum,
  Nullable,
  PatientDevice,
  VitalEnumType
} from '../../../uc-api-sdk';
import {
  DeviceMonitorMethodEnum
} from '../../../enumComponent/DeviceMonitorMethodEnumComponent/DeviceMonitorMethodEnumComponent';
import { ConnectedDeviceInfo } from '../type';
import { DeviceHelper } from '../helper';
import { orderByArray } from '../../../helpers/order/orderByArray';
import { usePatientContext } from '../../../contexts/PatientInfoContext/PatientInfoContext';

export interface GetAllMostRecentDevicesOptions {
  includeLoanDevice?: boolean;
  sortByVital?: boolean;
}

export interface GetFullDeviceListOptions {
  sortByVital?: boolean;
}

export const getDefaultDeviceByVitalType = (
  vitalType: VitalEnumType,
) => {
  const defaultDevice = DeviceHelper.getDefaultDeviceByVitalType(vitalType);
  if (defaultDevice?.deviceMethod) {
    return defaultDevice;
  }
  return {};
};

export const mapMethodToNonApp = (monitorMethod?: DeviceMonitorMethodEnum | null) => {
  if (monitorMethod && DeviceHelper.isNonAppMonitor(monitorMethod)) {
    return DeviceMonitorMethodEnum.NON_APP_MONITOR;
  }
  return monitorMethod;
};

export const useGetDeviceInfo = (
  _patientDevice?: Nullable<PatientDevice>,
) => {
  const { enrolledProgramService } = usePatientContext()?.info || {};
  const isVitalEnrolled = (vitalType: VitalEnumType) => (
    enrolledProgramService?.getVitals()?.includes(vitalType)
  );
  const deviceOrderByVitalType = DeviceHelper.deviceOrderByVitalType.filter(isVitalEnrolled);
  const patientDevice = _patientDevice ? {
    ..._patientDevice,
    deviceList: filter(
      _patientDevice?.deviceList,
      (d) => isVitalEnrolled(d.vitalType as VitalEnumType)
    ) as DeviceAssignment[],
  } : _patientDevice;

  const getMonitorMethods = (): Record<VitalEnumType | DeviceTypeEnum, DeviceMonitorMethodEnum> => (
    // keyBy vitalType
    Object.assign(
      {},
      ...map(patientDevice?.deviceList, (d) => ({
        [d.vitalType as VitalEnumType]: mapMethodToNonApp(d.monitorMethod),
      }))
    )
  );

  const getDeviceInfoByType = (
    type: VitalEnumType | DeviceTypeEnum,
  ) => {
    const deviceAssignment = find(
      patientDevice?.deviceList,
      { vitalType: type }
    ) as DeviceAssignment;
    if (!deviceAssignment) return {};
    deviceAssignment.monitorMethod = mapMethodToNonApp(deviceAssignment.monitorMethod);
    return deviceAssignment;
  };

  const getMonitorMethodByType = (
    type: VitalEnumType | DeviceTypeEnum,
  ): DeviceMonitorMethodEnum | undefined => {
    const monitorMethods = getMonitorMethods();
    return monitorMethods[type];
  };

  // in monitorMethod groups
  const getMostRecentDevicesByType = (
    type: VitalEnumType | DeviceTypeEnum,
  ): Record<DeviceMonitorMethodEnum, ConnectedDeviceInfo> => {
    const devicesByVitalType = getDeviceInfoByType(type)?.devices || [];
    const mostRecentDeviceGroupedByMethod = {} as Record<DeviceMonitorMethodEnum, AssignDeviceInfo>;
    devicesByVitalType.forEach((d) => {
      if (!d?.deviceModel) return;
      const deviceMethod = DeviceHelper.getMethodByModel(d.deviceModel);
      const cur = mostRecentDeviceGroupedByMethod[deviceMethod as DeviceMonitorMethodEnum];
      if (
        deviceMethod
        && (new Date(cur?.accessedAt || 0).getTime() <= new Date(d?.accessedAt || 0).getTime())
      ) {
        mostRecentDeviceGroupedByMethod[deviceMethod] = d;
      }
    });
    return mostRecentDeviceGroupedByMethod as Record<DeviceMonitorMethodEnum, ConnectedDeviceInfo>;
  };

  const getMostRecentDeviceByType = (
    type: VitalEnumType | DeviceTypeEnum,
  ): ConnectedDeviceInfo | undefined => {
    const mostRecentDevices = getMostRecentDevicesByType(type);
    const { monitorMethod } = getDeviceInfoByType(type) || {};

    let deviceInfo: ConnectedDeviceInfo | undefined = (
      mostRecentDevices[monitorMethod as MonitorMethodEnum]
    );
    if (monitorMethod === MonitorMethodEnum.APP_MONITOR && !deviceInfo) {
      deviceInfo = DeviceHelper.getDefaultDeviceByVitalType(type as VitalEnumType);
    }
    return deviceInfo;
  };

  const getCuffSizeByType = (
    type: VitalEnumType | DeviceTypeEnum,
  ) => {
    if (type !== VitalEnumType.BP) return undefined;
    const bpDeviceInfo = getDeviceInfoByType(type);
    const mostRecentBPDevice = getMostRecentDevicesByType(type);
    return (
      mostRecentBPDevice[bpDeviceInfo.monitorMethod as DeviceMonitorMethodEnum]?.cuffSize
      || bpDeviceInfo.cuffSize
      || undefined
    );
  };

  const getLoanerPhoneDevice = () => {
    const loanerPhone = patientDevice?.loanDevices?.[0];
    return loanerPhone;
  };

  const getAllMostRecentDevices = (options?: GetAllMostRecentDevicesOptions) => {
    const {
      includeLoanDevice = false,
      sortByVital = true,
    } = options || {};
    let listOfVitals = (
      (keys(VitalEnumType) as VitalEnumType[]).filter(isVitalEnrolled)
    );
    if (sortByVital) {
      listOfVitals = uniq([
        ...deviceOrderByVitalType,
        ...listOfVitals,
      ]);
    }
    const mostRecentDevices = map(listOfVitals, (v) => (
      getMostRecentDeviceByType(v)
    ));
    if (includeLoanDevice && getLoanerPhoneDevice()) {
      const loanDevice = getLoanerPhoneDevice();
      mostRecentDevices.push({
        ...loanDevice,
        deviceModel: DeviceModelEnum.LOAN_DEVICE
      } as ConnectedDeviceInfo);
    }
    return mostRecentDevices.filter((v) => !!v);
  };

  const getFullDeviceList = (options?: GetFullDeviceListOptions) => {
    const {
      sortByVital = true,
    } = options || {};
    const loanDevice = getLoanerPhoneDevice();
    let processedDeviceList = patientDevice?.deviceList || [];
    if (sortByVital) {
      processedDeviceList = orderByArray(
        processedDeviceList,
        deviceOrderByVitalType,
        'vitalType'
      );
    }
    return [
      ...processedDeviceList,
      ...(
        loanDevice
          ? [{ ...loanDevice, deviceModel: DeviceModelEnum.LOAN_DEVICE }]
          : []
      ),
    ];
  };

  const res = useDeepCompareMemo(() => ({
    getMonitorMethods,
    getMonitorMethodByType,
    getDeviceInfoByType,
    getMostRecentDeviceByType,
    getMostRecentDevicesByType,
    getAllMostRecentDevices,
    getCuffSizeByType,
    getLoanerPhoneDevice,
    getFullDeviceList,
    getDefaultDeviceByVitalType,
  }), [patientDevice]);

  return res;
};
