import { FormItem } from '../../FormItem/FormItem';
import { TextareaComponent } from '../../TextareaComponent/TextareaComponent';
import { ActivityTypeCheckboxGroupComponent } from '../ActivityTypeCheckboxGroupComponent/ActivityTypeCheckboxGroupComponent';
import { ActivityTypeEnum } from '../type';

export interface AddOfflineActivityFormItemComponentProps {

}

export const AddOfflineActivityFormItemComponent = () => (
  <>
    <FormItem
      label="Choose Activity"
      name="offlineActivities"
      required
    >
      <ActivityTypeCheckboxGroupComponent />
    </FormItem>
    <FormItem
      noStyle
      shouldUpdate={(prev, curr) => prev.offlineActivities !== curr.offlineActivities}
    >
      {({ getFieldValue }) => {
        const show = getFieldValue('offlineActivities')?.includes(ActivityTypeEnum.OTHER);
        return show ? (
          <FormItem name="otherActivity" required>
            <TextareaComponent placeholder="Enter your activity" />
          </FormItem>
        ) : null;
      } }
    </FormItem>
  </>
);
