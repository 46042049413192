import { FreqEnumComponent } from '../../../../enumComponent/FreqEnumComponent/FreqEnumComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface LifestyleSmokingOrDrinkingComponentProps {
  quitYear?: string;
  value?: boolean;
  type?: 'smoke' | 'drink';
  activityFreq?: string;
  activityValue?: string;
  drinkChoice?: string;
  drinkQuantity?: string;
}

export const LifestyleSmokingOrDrinkingComponent = ({
  quitYear,
  value = false,
  type = 'smoke',
  activityFreq,
  activityValue,
  drinkChoice,
  drinkQuantity,
}: LifestyleSmokingOrDrinkingComponentProps) => {
  const renderContent = () => (
    value
      ? (
        <div>
          Yes,
          {' '}
          {activityValue}
          {' times per '}
          {' '}
          <FreqEnumComponent value={activityFreq || ''} type="short" />
          {',  '}
          {
            type === 'drink' && (
              <>
                <DisplayOrEmptyComponent value={drinkQuantity} />
                {', '}
                <DisplayOrEmptyComponent value={drinkChoice} />
              </>
            )
          }
        </div>
      ) : (
        <div>
          No, Quit year:
          {' '}
          <DisplayOrEmptyComponent value={quitYear} />
        </div>
      )
  );

  return (
    renderContent()
  );
};
