import { MessageVitalSingleComponent, MessageVitalSingleComponentProps } from '../MessageVitalSingleComponent/MessageVitalSingleComponent';
import { TMMessagePayload, VitalMessagePayload } from '../../../../../services/CHSServices/types/data';
import { MeasurementResultTypeEnum, VitalEnumType } from '../../../../../uc-api-sdk';
import { MessageVitalSingleDisplayValueComponent } from '../MessageVitalSingleDisplayComponent/MessageVitalSingleDisplayValueComponent';
import { MessageVitalSingleDisplaySeverityComponent } from '../MessageVitalSingleDisplayComponent/MessageVitalSingleDisplaySeverityComponent';

export interface MessageTMComponentProps extends Omit<MessageVitalSingleComponentProps, 'vitalName' | 'children'> {
  temperature: TMMessagePayload['temperature'];
  unit: VitalMessagePayload['unit'];
  severity: VitalMessagePayload['severity'];
}

export const MessageTMComponent = ({
  temperature,
  measuredAt,
  text,
  unit,
  severity,
}: MessageTMComponentProps) => (
  <MessageVitalSingleComponent
    vitalName={MeasurementResultTypeEnum.TM}
    measuredAt={measuredAt}
    text={text}
  >
    <MessageVitalSingleDisplayValueComponent
      value={Number(temperature).toFixed(1)}
      unit={unit}
    />
    <MessageVitalSingleDisplaySeverityComponent
      vitalType={VitalEnumType.TM}
      severity={severity}
    />
  </MessageVitalSingleComponent>
);
