import { useMemo } from 'react';
import {
  GetOptions, MakeRequestHookConfig, UseRequestFactory, useRequestFactory,
} from '../../../uc-api-sdk/staticFiles/useReqHook';
import { chsServices } from '../CHSServices';
import { PublishMessageParams, PublishMessageResponseData } from '../types';

export const usePublishMessage = <RCN = undefined>(
  configs?: MakeRequestHookConfig<PublishMessageParams, GetOptions, RCN>,
) => {
  const baseConfigs: UseRequestFactory<
    PublishMessageParams,
    PublishMessageResponseData,
    typeof chsServices.publishMessage,
    GetOptions,
    PublishMessageResponseData
  > = useMemo(() => ({
    reqFunc: (...args: Parameters<typeof chsServices.publishMessage>) => (
      chsServices.publishMessage(...args)
    ),
    ResponseClassCreator: chsServices.getResponse,
    typeName: 'usePublishMessage',
  }), []);

  return useRequestFactory(baseConfigs, configs);
};
