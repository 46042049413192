import { Space } from 'antd';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { PatientHiatusStatusEnum, PauseServiceInfo } from '../../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { PauseServiceNamesComponent } from './PauseServiceNamesComponent';

export interface PauseServiceStatusComponentProps {}

export const PauseServiceStatusComponent = () => {
  const { info } = usePatientContext();
  const { enrolledProgramService } = info || {};
  const hiatusDetail = enrolledProgramService?.getHiatusDetail();

  const buildMessage = (pauseServiceInfo: PauseServiceInfo | null | undefined) => {
    if (!pauseServiceInfo || pauseServiceInfo.status === PatientHiatusStatusEnum.RESUMED) {
      return null;
    }

    return (
      <>
        {pauseServiceInfo.status === PatientHiatusStatusEnum.PAUSED
          ? ' is paused: '
          : ' is scheduled to pause: '}
        <DisplayDateComponent value={pauseServiceInfo?.startDate} format="USA_DATE" />
        {' - '}
        <DisplayDateComponent value={pauseServiceInfo?.endDate} format="USA_DATE" />
      </>
    );
  };

  return (
    <div className="inline-block">
      <Space split="|" wrap size={[5, 0]}>
        {enrolledProgramService?.isVitalMonitoringPaused() && (
          <span>
            <PauseServiceNamesComponent
              pauseVitalMonitoring
            />
            {buildMessage(hiatusDetail?.vitalMonitoring)}
          </span>
        )}
        {enrolledProgramService?.isVisitServicePaused() && (
          <span>
            <PauseServiceNamesComponent
              pauseVisitService
            />
            {buildMessage(hiatusDetail?.visitService)}
          </span>
        )}
        {enrolledProgramService?.isBillingPaused() && (
          <span>
            <PauseServiceNamesComponent
              pauseBilling
            />
            {buildMessage(hiatusDetail?.billingReport)}
          </span>
        )}
      </Space>
    </div>
  );
};
