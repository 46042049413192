import { InfoCircleFilled } from '@ant-design/icons';
import { ReactNode } from 'react';
import { MissingErrorComponent } from '../../uiComponent/MissingErrorComponent/MissingErrorComponent';

// eslint-disable-next-line import/prefer-default-export
export const createRequiredRule = (
  msg = 'Missing!',
  icon: ReactNode = <InfoCircleFilled className="danger-svg" />,
) => ({
  required: true,
  message: (
    <MissingErrorComponent icon={icon}>
      {msg}
    </MissingErrorComponent>
  ),
});
