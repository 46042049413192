import { map } from 'lodash';
import { DeviceMonitorMethodEnum, DeviceMonitorMethodEnumComponent } from '../../../../enumComponent/DeviceMonitorMethodEnumComponent/DeviceMonitorMethodEnumComponent';
import { VitalEnumType } from '../../../../uc-api-sdk';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { RadioGroupComponent } from '../../../../uiComponent/RadioGroupComponent/RadioGroupComponent';
import { useDeviceMonitorForm } from '../../hook/useDeviceMonitorForm';

export interface DeviceMonitorMethodFormItemComponentProps {
  vitalType: VitalEnumType;
  required?: boolean;
}

export const DeviceMonitorMethodFormItemComponent = ({
  vitalType,
  required,
}: DeviceMonitorMethodFormItemComponentProps) => {
  const deviceMonitorForm = useDeviceMonitorForm();

  const getOptions = (vitalType: VitalEnumType) => {
    const enumOptions: DeviceMonitorMethodEnum[] = [];

    switch (vitalType) {
      case VitalEnumType.BG:
        enumOptions.push(
          DeviceMonitorMethodEnum.APP_MONITOR,
          DeviceMonitorMethodEnum.NON_APP_MONITOR,
          DeviceMonitorMethodEnum.MANUALLY_INPUT
        );
        break;
      case VitalEnumType.BP:
        enumOptions.push(
          DeviceMonitorMethodEnum.APP_MONITOR,
          DeviceMonitorMethodEnum.NON_APP_MONITOR,
          DeviceMonitorMethodEnum.MANUALLY_INPUT
        );
        break;
      default:
        enumOptions.push(
          DeviceMonitorMethodEnum.APP_MONITOR,
          DeviceMonitorMethodEnum.MANUALLY_INPUT,
        );
    }

    return map(enumOptions, (v) => ({
      label: <DeviceMonitorMethodEnumComponent value={v} />,
      value: v,
    }));
  };

  return (
    <FormItem noStyle shouldUpdate>
      {
        ({ getFieldValue }) => {
          const monitorMethod = deviceMonitorForm.getValue('monitorMethod', getFieldValue);
          return (
            <>
              <div className="flex fd-c">
                <div>
                  {deviceMonitorForm.getLabel('monitorMethod')}
                </div>
                <FormItem
                  name={deviceMonitorForm.getName('monitorMethod')}
                  required={required}
                >
                  <RadioGroupComponent
                    options={getOptions(vitalType)}
                    spaceProps={{ className: 'f-w' }}
                  />
                </FormItem>
              </div>
              {
                monitorMethod === DeviceMonitorMethodEnum.MANUALLY_INPUT
                && (
                  <div className="pr80">
                    When Manual Input is enabled, patient will NOT be able to use the
                    {' '}
                    connected device in the App, will have to enter vital results manually.
                  </div>
                )
              }
            </>
          );
        }
      }
    </FormItem>
  );
};
