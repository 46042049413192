import { Button, Modal } from 'antd';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useLoggedInUserFromContext } from '../../contexts/loggedInUserContext';
import './UnicareCopilotComponent.scss';
import EnvConfig from '../../configs/envConfig/envConfig';
import { Icon } from '../../icons/Icon';
import { COPILOT_CLOSE } from '../../assets/commonIconInfo';

export const UnicareCopilotComponent = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { token } = useLoggedInUserFromContext();
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleSwitchChange = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getURL = () => {
    // when it's local or dev, use dev url; otherwise using test url.
    if (EnvConfig.unicareCopilotUrl) {
      return `${EnvConfig.unicareCopilotUrl}/unicare-copilot-dev/${token}`;
    }
    return undefined;
  };

  return (
    <div>
      <Button type="link" className="p0" onClick={handleSwitchChange}>
        <Icon
          info={COPILOT_CLOSE}
          alt="copilot"
          size={30}
          hexColor={isModalVisible ? '#228BE6' : ''}
        />
      </Button>
      <Modal
        title={null}
        onCancel={handleCancel}
        footer={null}
        mask={false}
        width={expanded ? 1000 : 390}
        maskClosable={false}
        open={isModalVisible}
        className="unicare-copilot-component"
        transitionName=""
        maskTransitionName=""
        zIndex={1001}
      >
        <Button onClick={handleToggleExpand} className="expand-button">
          {expanded ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
        </Button>
        <iframe
          id="myIframe"
          title="Embedded Content"
          src={getURL()}
          className="w100 h100 iframe-border"
        />
      </Modal>
    </div>
  );
};
