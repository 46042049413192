import { MonitoringVital } from '../../../../../uc-api-sdk';
import { VitalsMonitoringExerciseFrequencyComponent } from '../VitalsMonitoringExerciseFrequencyComponent/VitalsMonitoringExerciseFrequencyComponent';

export interface VitalsMonitoringExerciseComponentProps {
  value?: MonitoringVital;
}

export const VitalsMonitoringExerciseComponent = ({
  value: {
    target,
    repeat,
  } = {},
}: VitalsMonitoringExerciseComponentProps) => (
  (target || 0) > 0
    ? (
      <VitalsMonitoringExerciseFrequencyComponent
        target={target || undefined}
        repeat={repeat || undefined}
      />
    ) : (
      <div className="mb30">
        User hasn't activated this function.
      </div>
    )
);
