import { map } from 'lodash';
import { ROLE_OPTIONS } from '../../../../../constants/role';
import TimezoneService from '../../../../../helpers/timezone/timezoneService';
import LANGUAGES_MAP from '../../../../../selectComponents/LanguageSelectComponent/LanguagesMap';
import { LanguageType } from '../../../../../types/languages';
import { ROLE_ENUM, ROLE_GROUP_MAP, ROLE_NAME_MAP } from '../../../../../types/roles';
import { TimezoneType } from '../../../../../types/timezone';
import { getMembersStatusText } from '../../../helpers';
import { MEMBERS_STATUS_TEXT } from '../../constant';

export enum EmployeeStatus {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED'
}

export enum IHealthEmployeeRole {
  RD = 'RD',
  HC = 'HC',
  CA = 'CA',
  MANAGER = 'MANAGER',
  BILLER = 'BILLER',
  CALL_CENTER_REPRESENTATIVE = 'CALL_CENTER_REPRESENTATIVE',
  SUPER_ADMIN = 'SUPER_ADMIN'
}

export enum ClinicalStaffRole {
  PROVIDER = 'PROVIDER',
  NP = 'NP',
  MA = 'MA',
  QUALITY_TEAM_MEMBER = 'QUALITY_TEAM_MEMBER',
  CLINICAL_GROUP_BILLER = 'CLINICAL_GROUP_BILLER'
}

export const iHealthEmployeeRole = map(IHealthEmployeeRole, (v) => ({
  text: ROLE_NAME_MAP[v],
  value: v,
}));

export const clinicalStaffRole = map(ClinicalStaffRole, (v) => ({
  text: ROLE_NAME_MAP[v],
  value: v,
}));

export const status = map(EmployeeStatus, (v) => ({
  text: getMembersStatusText(v),
  value: v,
}));

const memberFilterStatus = map(EmployeeStatus, (v) => ({
  text: MEMBERS_STATUS_TEXT[v],
  value: v,
}));

export const MEMBERS_FILTERS = {
  memberFilterStatus,
  patientLang: [
    {
      text: LANGUAGES_MAP[LanguageType.English].abbr,
      value: LANGUAGES_MAP[LanguageType.English].value,
    },
    {
      text: LANGUAGES_MAP[LanguageType.Cantonese].abbr,
      value: LANGUAGES_MAP[LanguageType.Cantonese].value,
    },
    {
      text: LANGUAGES_MAP[LanguageType.Mandarin].abbr,
      value: LANGUAGES_MAP[LanguageType.Mandarin].value,
    },
    {
      text: LANGUAGES_MAP[LanguageType.Spanish].abbr,
      value: LANGUAGES_MAP[LanguageType.Spanish].value,
    },
    {
      text: LANGUAGES_MAP[LanguageType.Vietnamese].abbr,
      value: LANGUAGES_MAP[LanguageType.Vietnamese].value,
    },
    {
      text: LANGUAGES_MAP[LanguageType.Tagalog].abbr,
      value: LANGUAGES_MAP[LanguageType.Tagalog].value,
    },
  ],
  timezones: Object.keys(TimezoneType).map((t) => ({
    text: TimezoneService.getTimezoneText(TimezoneType[t as keyof typeof TimezoneType]),
    value: TimezoneType[t as keyof typeof TimezoneType],
  })) as { text: string, value: TimezoneType }[],
  languages: Object.values(LANGUAGES_MAP).map((l) => ({
    text: l.abbr,
    value: l.value,
  })) as { text: string, value: LanguageType }[],
  careDeptRoles: ROLE_OPTIONS.filter((r) => (
    [ROLE_GROUP_MAP.CARE_DEPT, ROLE_GROUP_MAP.UC_MANAGER].includes(r.group)
  )).map((r) => ({
    text: r.label,
    value: r.value,
  })) as { text: string, value: ROLE_ENUM }[],
};
