import { ColumnType } from 'antd/es/table';
import { filter } from 'lodash';
import React, { ReactNode } from 'react';
import { measurementTableDateColumnMaker } from '../MeasurementTable/MeasurementTableColumn';
import { BGTableCellComponent } from './BGTableCellComponent';
import { BGTableDataType } from '../../type/BGTypes';
import { BgMealTypeEnum } from '../../../../uc-api-sdk';

const createMealColumnWithoutBeforeMealType = (title: ReactNode, key: BgMealTypeEnum) => (
  (): ColumnType<BGTableDataType> => ({
    title,
    key,
    render: (__value, rec) => {
      const data = rec.data[key];
      return (data && data.length > 0 ? <BGTableCellComponent data={data} /> : null);
    },
    width: '11%',
  })
);

const createMealColumns = (
  title: string,
  key: BgMealTypeEnum,
) => (): ColumnType<BGTableDataType> => ({
  title,
  // This is because antd doesn't have the correct type
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  children: [
    {
      title: 'Pre',
      key: `${key}Before`,
      render: (__value, rec) => {
        const data = filter(rec.data[key], (v) => v.beforeMeal);
        return (data.length > 0 ? <BGTableCellComponent data={data} /> : null);
      },
      width: '11%',
    },
    {
      title: 'Post',
      key: `${key}After`,
      render: (__value, rec) => {
        const data = filter(rec.data[key], (v) => !v.beforeMeal);
        return (data.length > 0 ? <BGTableCellComponent data={data} /> : null);
      },
      width: '11%',
    },
  ] as ColumnType<BGTableDataType>[],
});

/* eslint-disable import/prefer-default-export */
export const BGTableColumns = {
  date: measurementTableDateColumnMaker('11%'),
  overnight: createMealColumnWithoutBeforeMealType(
    'Over-night',
    BgMealTypeEnum.OVERNIGHT,
  ),
  breakfast: createMealColumns('Breakfast', BgMealTypeEnum.BREAKFAST),
  lunch: createMealColumns('Lunch', BgMealTypeEnum.LUNCH),
  dinner: createMealColumns('Dinner', BgMealTypeEnum.DINNER),
  bedtime: createMealColumnWithoutBeforeMealType(
    'Bed-time',
    BgMealTypeEnum.BEDTIME,
  ),
};
