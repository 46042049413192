import { Divider, message, Tooltip } from 'antd';
import { ReactNode, useMemo } from 'react';
import { QuestionCircleFilled } from '@ant-design/icons';
import { EhrJobRecord, LoginRequest } from '../../../../uc-api-sdk';
import { GoogleLoginComponent } from '../GoogleLoginComponent/GoogleLoginComponent';
import { LoginFormComponent, LoginFormComponentProps } from '../LoginFormComponent/LoginFormComponent';
import { LoginHeaderComponent } from '../LoginHeaderComponent/LoginHeaderComponent';
import { SubTitle } from '../../../../uiComponent/Title/SubTitle';
import { StorageKeyEnum, useSessionStorage } from '../../../../hooks';

import './LoginComponent.scss';
import { EhrNameComponent } from '../../../ehr/component/EhrNameComponent/EhrNameComponent';

export interface LoginComponentProps {
  onLogin?: (inputValues: LoginRequest) => void;
  isLoading: boolean;
  onClickForgotPassword: LoginFormComponentProps['onClickForgotPassword'];
}

export const LoginComponent = ({
  onLogin,
  isLoading,
  onClickForgotPassword,
}: LoginComponentProps) => {
  const [ehrQuery] = useSessionStorage<EhrJobRecord>(StorageKeyEnum.EHR_QUERY);
  const handleOnLogin = (
    inputValues: LoginRequest,
  ) => {
    onLogin?.(inputValues);
  };

  const handleOnGoogleLogin = (
    credential?: string,
  ) => {
    handleOnLogin({ googleAuthToken: credential });
  };

  const handleOnGoogleLoginError = () => {
    message.error('Something is wrong with Google Login');
  };

  const formText = useMemo(() => {
    let text = {
      header: 'Sign in to Unified Care Portal',
      subTitle: 'For iHealth employees, continue with Google',
      divider: 'or sign in with your username',
      submitText: 'Sign In',
    } as {
      header: string | ReactNode;
      subTitle: string;
      divider: string;
      submitText: string;
    };
    if (ehrQuery) {
      text = {
        header: (
          <>
            <span>
              Connect your
              {' '}
              <EhrNameComponent />
              {' '}
              Account with Unified Care Portal
            </span>
            {' '}
            <Tooltip
              overlayClassName="ehr-login-tooltip"
              title={(
                <>
                  <div>
                    Connect your
                    {' '}
                    <EhrNameComponent />
                    {' '}
                    Account with Unified Care Portal to be auto logged in
                    to portal from the
                    {' '}
                    <EhrNameComponent />
                    {' '}
                    EHR in the future.
                  </div>
                  <div>
                    If you don't have an account yet,
                    please contact iHealth account manager to set up your account.
                  </div>
                </>
              )}
              showArrow={false}
            >
              <QuestionCircleFilled />
            </Tooltip>
          </>
        ),
        subTitle: 'For iHealth employees, continue with Google',
        divider: 'or sign in with your username and password to connect',
        submitText: 'Connect and Sign In',
      };
    }
    return text;
  }, [ehrQuery]);

  return (
    <div className="login-component">
      <LoginHeaderComponent
        header={formText.header}
      />

      <div className="flex fd-c jc-c ai-c gap2">
        <SubTitle className="mb5i">
          {formText.subTitle}
        </SubTitle>
        <GoogleLoginComponent
          onSuccess={handleOnGoogleLogin}
          onError={handleOnGoogleLoginError}
        />
      </div>

      <Divider>
        {formText.divider}
      </Divider>

      <LoginFormComponent
        onLogin={handleOnLogin}
        isLoading={isLoading}
        onClickForgotPassword={onClickForgotPassword}
        submitText={formText.submitText}
      />

    </div>
  );
};
