import { useCallback } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { REWRITE_WITH_AI_ICON } from '../../../../assets/commonIconInfo';
import { Icon } from '../../../../icons/Icon';
import { RewriteMessageWithAIContainer } from '../../container/RewriteWithAIContainer/RewriteMessageWithAIContainer';
import { RewriteNoteWithAIContainer } from '../../container/RewriteWithAIContainer/RewriteNoteWithAIContainer';
import './RewriteWithAIModalButtonComponent.scss';
import { ModalWidth880 } from '../../../../constants/modal';
import { DraggableModalComponent, DraggableModalComponentProps } from '../../../../uiComponent/DraggableModalComponent/DraggableModalComponent';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';

export interface RewriteWithAIModalButtonComponentProps {
  mode?: 'rewriteMessage' | 'rewriteNote';
  originalContent: string;
  onClick?: () => void;
}

export const RewriteWithAIModalButtonComponent = ({
  mode = 'rewriteMessage',
  originalContent,
  onClick,
}: RewriteWithAIModalButtonComponentProps) => {
  const { open, close } = useOpen(false);

  const renderContent = () => (
    <div className="rewrite-ai-modal">
      {
        mode === 'rewriteMessage'
          ? <RewriteMessageWithAIContainer originalMessage={originalContent || ''} />
          : <RewriteNoteWithAIContainer originalNote={originalContent || ''} />
      }
    </div>
  );

  const renderTrigger = useCallback<NonNullable<DraggableModalComponentProps['trigger']>>(({
    onOpen,
  }) => {
    const handleClick = () => {
      onOpen();
      onClick?.();
    };

    return (
      <Button
        onClick={handleClick}
        className={
          classNames({
            'rewrite-message-button': mode === 'rewriteMessage',
            'rewrite-note-button': mode === 'rewriteNote',
            'rewrite-ai-button flex-c pointer': true,
          })
        }
      >
        <Icon info={REWRITE_WITH_AI_ICON} />
      </Button>
    );
  }, [mode, onClick]);

  return (
    <DraggableModalComponent
      onOpen={open}
      onCancel={close}
      trigger={renderTrigger}
      title="AI rewrite assistant"
      width={ModalWidth880}
      wrapClassName="rewrite-modal"
      destroyOnClose
    >
      {renderContent()}
    </DraggableModalComponent>
  );
};
