import { useCallback } from 'react';
import { BenefitOrderEnum } from '../../uc-api-sdk';

export interface OrderOfBenefitEnumComponentProps {
  value?: BenefitOrderEnum;
}

export const OrderOfBenefitEnumComponent = ({
  value,
}: OrderOfBenefitEnumComponentProps) => {
  const getText = useCallback(() => {
    switch (value) {
      case BenefitOrderEnum.PRIMARY: return 'Primary';
      case BenefitOrderEnum.SECONDARY: return 'Secondary';
      case BenefitOrderEnum.TERTIARY: return 'Tertiary';
      default: return '';
    }
  }, [value]);

  return (<>{getText()}</>);
};
