import { map } from 'lodash';
import { useMemo } from 'react';
import { BaseMeasurement, MeasurementHelper } from '../../helper/MeasurementHelper';

export const useMeasurementResultToTablePrint = <
  T extends BaseMeasurement,
  K extends BaseMeasurement,
>(
    data: T[],
    mapper: (value: T) => K,
  ) => {
  const processedData = useMemo(() => {
    const unsortedObject = map(data, mapper);
    return MeasurementHelper.sortArrayByDate(unsortedObject);
  }, [data]);

  return processedData;
};
