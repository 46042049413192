import { Input } from 'antd';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { YesNoRadioComponent } from '../../../../uiComponent/YesNoRadioComponent/YesNoRadioComponent';
import { useDiseaseHistoryForm } from '../../hook/useDiseaseHistoryForm';

export interface DiseaseHistoryHasSeenSpecialistFormListItemComponentProps {
  fieldName: number;
}

export const DiseaseHistoryHasSeenSpecialistFormListItemComponent = ({
  fieldName,
}: DiseaseHistoryHasSeenSpecialistFormListItemComponentProps) => {
  const {
    makeListNamePath,
    getLabel,
    shouldUpdateFromList,
    shouldShowNameAndContact,
  } = useDiseaseHistoryForm();

  return (
    <>
      <FormItem
        name={makeListNamePath('hasSeenSpecialist', fieldName)}
        label={getLabel('hasSeenSpecialist') as string}
      >
        <YesNoRadioComponent />
      </FormItem>
      <FormItem
        noStyle
        shouldUpdate={shouldUpdateFromList(['hasSeenSpecialist'], fieldName)}
      >
        {
          ({ getFieldValue }) => (
            <FormItem
              name={makeListNamePath('nameAndContact', fieldName)}
              label={getLabel('nameAndContact') as string}
              hidden={!shouldShowNameAndContact(getFieldValue, fieldName)}
            >
              <Input.TextArea
                placeholder="Name & contact"
                autoSize={{ maxRows: 2, minRows: 2 }}
              />
            </FormItem>
          )
        }
      </FormItem>

    </>
  );
};
