import { BehaviorGoalCategoryEnum } from '../../uc-api-sdk';

export interface BehaviorGoalCategoryEnumComponentProps {
  value: BehaviorGoalCategoryEnum;
}

export const BehaviorGoalCategoryEnumComponent = ({
  value,
}: BehaviorGoalCategoryEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case BehaviorGoalCategoryEnum.ALCOHOL: return 'Alcohol';
      case BehaviorGoalCategoryEnum.CANCER: return 'Cancer';
      case BehaviorGoalCategoryEnum.CARBOHYDRATES: return 'Carbohydrates';
      case BehaviorGoalCategoryEnum.CHRONIC_KIDNEY_DISEASE: return 'Chronic Kidney Disease';
      case BehaviorGoalCategoryEnum.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE: return 'Chronic Obstructive Pulmonary Disease';
      case BehaviorGoalCategoryEnum.DIET: return 'Diet';
      case BehaviorGoalCategoryEnum.FAT: return 'Fat';
      case BehaviorGoalCategoryEnum.FLUID: return 'Fluid';
      case BehaviorGoalCategoryEnum.FLUIDS: return 'Fluids';
      case BehaviorGoalCategoryEnum.LIFESTYLE: return 'Lifestyle';
      case BehaviorGoalCategoryEnum.NON_STARCHY_VEGETABLES: return 'Non Starchy Vegetables';
      case BehaviorGoalCategoryEnum.PHYSICAL_ACTIVITY: return 'Physical Activity';
      case BehaviorGoalCategoryEnum.PROTEIN: return 'Protein';
      case BehaviorGoalCategoryEnum.SODIUM: return 'Sodium';
      case BehaviorGoalCategoryEnum.UNDERWEIGHT: return 'Underweight';
      case BehaviorGoalCategoryEnum.WELLNESS: return 'Wellness';
      case BehaviorGoalCategoryEnum.UNMAPPED:
      default: return null;
    }
  };

  return (<span>{getText()}</span>);
};
