import { Button } from 'antd';
import { ReactNode } from 'react';
import { Dayjs } from 'dayjs';
import { PatientCommonCardTitleComponent } from '../../../patient/component/PatientCommonCardTitleComponent/PatientCommonCardTitleComponent';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';

import './OutstandingItemsCardComponent.scss';

export interface OutstandingItemsCardComponentProps {
  title: ReactNode;
  dueDate?: Dayjs | null | string;
  onStartNow?: () => void;
  button?: ReactNode;
}

export const OutstandingItemsCardComponent = ({
  title,
  dueDate,
  onStartNow,
  button,
}: OutstandingItemsCardComponentProps) => (
  <div className="patient-common-card-component">
    <div className="flex jc-sb">
      <PatientCommonCardTitleComponent>
        {title}
      </PatientCommonCardTitleComponent>
      <div className="patient-common-card-last-time-update">
        {button || <Button type="link" onClick={onStartNow}>Start now</Button>}
      </div>
    </div>
    <div className="outstanding-card-due-date">
      {dueDate && (
        <span>
          Due
          {' '}
          <DisplayDateComponent value={dueDate} format="USA_DATE" />
        </span>
      )}
    </div>
  </div>
);
