import {
  ReactNode,
  createContext,
  useContext,
} from 'react';

const ErrorContext = createContext<unknown>(null);

export const useErrorContext = <T, >() => {
  const context = useContext(ErrorContext);
  if (!context) throw new Error('ErrorContext not found');
  return context as T;
};

export interface ErrorContextProviderProps {
  children: ReactNode;
  value: unknown;
}
export const ErrorContextProvider = ({
  children,
  value,
}: ErrorContextProviderProps) => (
  <ErrorContext.Provider value={value}>
    {children}
  </ErrorContext.Provider>
);
