import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';
import { ReactNode } from 'react';
import { VitalsEnumTypeComponent } from '../../../../enumComponent/VitalsEnumTypeComponent/VitalsEnumTypeComponent';
import { NumberService } from '../../../../helpers/number';
import { WeightService } from '../../../../helpers/weight';
import {
  MeasurementResultsStats,
  MeasurementResultTypeEnum,
  VitalEnumType,
  Number as NumberType,
} from '../../../../uc-api-sdk';
import { SummaryTwoIndexComponent } from '../SummaryColumnComponents/SummaryTwoIndexComponent';
import './VitalSummaryComponent.scss';

// SummaryItem
interface SummaryItemProps {
  title: ReactNode;
  value: ReactNode;
  hide?: boolean;
  span?: number;
}

export const SummaryItem = ({
  title,
  value,
  hide = false,
  span = 6
}: SummaryItemProps) => (
  !hide ? (
    <Col span={span} className="vital-summary-component__item">
      <>
        <div className="item-title">{title}</div>
        <div className="item-value">{value}</div>
      </>
    </Col>
  ) : null
);

// BaseVitalSummaryComponent
interface BaseVitalSummaryComponentProps {
  type: VitalEnumType;
  total: ReactNode;
  average: ReactNode;
  inNormal: ReactNode;
  pulse?: ReactNode;
  range?: ReactNode;
  bmi?: ReactNode;
  variability?: ReactNode;
}

const BaseVitalSummaryComponent = ({
  type,
  total,
  average,
  inNormal,
  pulse,
  range,
  bmi,
  variability,
}: BaseVitalSummaryComponentProps) => {
  const renderAverageTitle = () => (
    <div>
      Avg
      {' '}
      <VitalsEnumTypeComponent
        type="short"
        value={type}
      />
    </div>
  );

  const renderAverageBPTitle = () => (
    <div>
      Avg
      {' '}
      <VitalsEnumTypeComponent
        type="short"
        value={type}
      />
      , P.
    </div>
  );

  const renderAverageBP = () => (
    <div className="flex">
      {average}
      {pulse}
    </div>
  );

  const renderVariabilityTitle = () => (
    <div>
      Variability
      {' '}
      <Tooltip placement="topLeft" title="(Highest weight - Lowest weight) /2" overlayClassName="hs-variability-tooltip">
        <InfoCircleOutlined />
      </Tooltip>
    </div>
  );

  return (
    <Row className="vital-summary-component" justify="start">
      <SummaryItem title="Total Measure" value={total} span={type === VitalEnumType.HS ? 5 : undefined} />
      <SummaryItem
        span={type === VitalEnumType.HS ? 5 : undefined}
        title={type === VitalEnumType.BP ? renderAverageBPTitle() : renderAverageTitle()}
        value={type === VitalEnumType.BP ? renderAverageBP() : average}
      />
      <SummaryItem title="BMI" value={bmi} hide={!bmi} span={4} />
      <SummaryItem
        title={renderVariabilityTitle()}
        value={variability}
        hide={!variability && variability !== 0}
        span={5}
      />
      <SummaryItem
        title="Vitals in normal"
        value={`${inNormal}%`}
        hide={!inNormal}
        span={type === VitalEnumType.HS ? 5 : undefined}
      />
      <SummaryItem
        title={type === VitalEnumType.BP ? 'Range (SYS/DIA)' : 'Range'}
        value={`${range}`}
        hide={!range}
        span={type === VitalEnumType.HS ? 5 : undefined}
      />
    </Row>
  );
};

// VitalSummaryComponent
export interface VitalSummaryComponentProps {
  type: MeasurementResultTypeEnum;
  value?: MeasurementResultsStats;
}

export const VitalSummaryComponent = ({
  type,
  value,
}: VitalSummaryComponentProps) => {
  const getFixed = (v: NumberType | number | null | undefined) => (
    (+(v || 0).toString()).toFixed(0)
  );

  const getBGValues = () => {
    const {
      count, average, inRangePercentage, range
    } = value?.bg?.all || {};
    return {
      type: VitalEnumType.BG,
      total: count || 0,
      average: (average?.[0] || 0).toFixed(0),
      inNormal: ((inRangePercentage || 0) * 100).toFixed(0),
      range: `${getFixed(range?.[0])}-${getFixed(range?.[1])}`,
    };
  };

  const getBPValues = () => {
    const {
      count, average, inRangePercentage, range
    } = value?.bp?.all || {};
    return {
      type: VitalEnumType.BP,
      total: count || 0,
      average: (
        <SummaryTwoIndexComponent
          value={NumberService.numberArrayToFixed(average || [], 0)}
        />
      ),
      inNormal: ((inRangePercentage || 0) * 100).toFixed(0),
      pulse: ((average?.length || 0) >= 3) ? average?.[2]?.toFixed(0) : 'N/A',
      range: `${getFixed(range?.[0])}-${getFixed(range?.[1])}/${getFixed(range?.[2])}-${getFixed(range?.[3])}`,
    };
  };

  const getPOValues = () => {
    const { count, average } = value?.po?.all || {};
    return {
      type: VitalEnumType.PO,
      total: count || 0,
      average: (average || 0).toFixed(0),
      inNormal: undefined,
    };
  };

  const getHSValues = () => {
    const {
      total, average, variability, bmi, min, max
    } = value?.hs?.all || {};
    return {
      type: VitalEnumType.HS,
      total: total || 0,
      average: WeightService.kgToLb(average || 0),
      variability: WeightService.kgToLb(variability || 0) || 0,
      bmi: bmi?.toFixed(1) || 'N/A',
      range: `${WeightService.kgToLb(min || 0)} - ${WeightService.kgToLb(max || 0)}`,
      inNormal: undefined,
    };
  };

  const getValues = (): BaseVitalSummaryComponentProps | null => {
    switch (type) {
      case MeasurementResultTypeEnum.BG: return getBGValues();
      case MeasurementResultTypeEnum.BP: return getBPValues();
      case MeasurementResultTypeEnum.PO: return getPOValues();
      case MeasurementResultTypeEnum.HS: return getHSValues();
      default: return null;
    }
  };

  const v = getValues();
  if (!v) return null;

  return (
    <BaseVitalSummaryComponent
      type={v.type}
      total={v.total}
      average={v.average}
      inNormal={v.inNormal}
      pulse={v.pulse}
      range={v.range}
      bmi={v.bmi}
      variability={v.variability}
    />
  );
};
