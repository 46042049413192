import { WorklistStepEnum } from '../../features/worklist/component/WorklistDrawerComponent/WorklistDrawerComponent';
import { BasicEnumComponent } from '../BasicEnumComponent/BasicEnumComponent';

export interface WorklistStepEnumComponentProps {
  value: WorklistStepEnum;
}

export const WorklistStepEnumComponent = ({
  value,
}: WorklistStepEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case WorklistStepEnum.GOALS_EDUCATION_SETUP:
        return 'Goals & Education Setup';
      case WorklistStepEnum.FOLLOW_UP_SCHEDULE:
        return 'Schedule Follow-up Visit';
      default:
        return <BasicEnumComponent value={value} />;
    }
  };

  return (
    <span>{getText()}</span>
  );
};
