import EmployeeInfo from '../hooks/useUserInfo/employeeInfo';
import { PatientComplexity } from '../uc-api-sdk';

export interface PatientComplexityInfo {
  complexity?: PatientComplexity;
}

export class PatientComplexityService {
  private complexity?: PatientComplexity;

  constructor(info: PatientComplexityInfo) {
    this.complexity = info.complexity;
  }

  get patientComplexity() {
    return this.complexity;
  }

  get updatedByUserName() {
    const updatedByUser = this.complexity?.updatedByUser || undefined;
    return EmployeeInfo.getFullNameWithTitle(updatedByUser);
  }
}
