import { Divider } from 'antd';
import { map } from 'lodash';
import { PrintDiseaseHistoryTitleComponent } from './PrintDiseaseHistoryDisplayComponent/PrintDiseaseHistoryTitleComponent';
import { DiseaseHistoryItem } from '../../../../../../uc-api-sdk';
import { DiseaseHistory } from '../../../../../diseaseHistory/helper';
import { PrintDiseaseHistoryGeneralComponent } from './PrintDiseaseHistoryGeneralComponent';
import { PrintDiseaseHistoryByVitalComponent } from './PrintDiseaseHistoryByVitalComponent';
import { DiseaseHistoryConditionComponent } from '../../../../../diseaseHistory/component/DiseaseHistoryDisplayComponent/DiseaseHistoryConditionComponent';

export interface PrintDiseaseHistoryComponentProps {
  diseaseHistoryList: DiseaseHistoryItem[];
}

export const PrintDiseaseHistoryComponent = ({
  diseaseHistoryList,
}: PrintDiseaseHistoryComponentProps) => {
  const renderInfo = (diseaseHistory: DiseaseHistoryItem) => {
    const isGeneral = DiseaseHistory.isGeneralChronicDisease(diseaseHistory.disease);
    if (isGeneral) {
      return <PrintDiseaseHistoryGeneralComponent diseaseHistory={diseaseHistory} />;
    }
    return <PrintDiseaseHistoryByVitalComponent diseaseHistory={diseaseHistory} />;
  };
  return (
    <>
      {
        map(diseaseHistoryList, (diseaseHistory, i) => (
          <div className="flex fd-c mb12" key={i}>
            <PrintDiseaseHistoryTitleComponent
              title={(
                <DiseaseHistoryConditionComponent
                  disease={diseaseHistory.disease}
                  customDisease={diseaseHistory.customDisease}
                />
              )}
            />
            {renderInfo(diseaseHistory)}
          </div>
        ))
      }
      <Divider />
    </>
  );
};
