import { QuestionCircleOutlined } from '@ant-design/icons';
import { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { ModalConfigContextProvider } from '../../../../../contexts/ModalConfigContext/ModalConfigContext';
import { LLMTranscribeLabResultContainer } from '../../container/LLMTranscribeLabResultContainer/LLMTranscribeLabResultContainer';
import { DefaultExtendableDrawers } from '../../../../../uiComponent/ExtendableDrawerComponent/controller/DefaultExtendableDrawers';
import { LLMDrawerButtonComponent } from '../../../component/LLMDrawerButtonComponent/LLMDrawerButtonComponent';
import { LLMFeedbackModalContainer } from '../../../container/LLMFeedbackModalContainer/LLMFeedbackModalContainer';
import { AITranscribeOnboardingModalComponent } from '../../../../../uiComponent/AITranscribeOnboardingModalComponent/AITranscribeOnboardingModalComponent';
import { useOpen } from '../../../../../hooks/useBoolean/useOpen';
import { ClickableDiv } from '../../../../../uiComponent/ClickableDiv/ClickableDiv';
import { useDoNotShowUntil } from '../../../../../hooks/useDoNotShowAgainUntil/useDoNotShowAgainUntil';
import { StorageKeyEnum } from '../../../../../hooks';

import './LLMTranscribeLabResultButtonComponent.scss';

export interface LLMTranscribeLabResultButtonComponentProps {
  patientId: string;
  onClose?: () => void;
}

export const LLMTranscribeLabResultButtonComponent = ({
  patientId,
  onClose,
}: LLMTranscribeLabResultButtonComponentProps) => {
  const {
    getDoNotShowUntil: getDidShowFeedback,
    setDoNotShowUntil: setDidShowFeedback,
  } = useDoNotShowUntil(
    StorageKeyEnum.DO_NOT_SHOW_UNTIL_AI_LAB_RESULT_FEEDBACK,
    dayjs().endOf('week').valueOf(),
  );
  const [
    feedbackJobId,
    setFeedbackJobId,
  ] = useState<string | undefined>();
  const {
    isOpen: isOnboardingModalOpen,
    open: openOnboardingModal,
    close: closeOnboardingModal,
  } = useOpen();

  const handleOpenFeedbackModal = (jobId?: string) => {
    if (!getDidShowFeedback()) {
      setFeedbackJobId(jobId);
    }
  };

  const handleCloseFeedbackModal = () => {
    setFeedbackJobId(undefined);
    setDidShowFeedback(true);
  };

  const handleOnClose = () => {
    onClose?.();
  };

  const renderTitle = useCallback(() => (
    <>
      <ClickableDiv
        onClick={openOnboardingModal}
        className="flex ai-c"
      >
        Lab Results - AI Transcribe (Beta)
        <QuestionCircleOutlined />
      </ClickableDiv>
      <AITranscribeOnboardingModalComponent
        open={isOnboardingModalOpen}
        onClose={closeOnboardingModal}
      />
    </>
  ), [isOnboardingModalOpen]);

  return (
    <>
      <LLMDrawerButtonComponent
        extendableId={DefaultExtendableDrawers.LLMLabResult}
        title={renderTitle()}
        onClose={handleOnClose}
      >
        {
          (close) => (
            <div id="llm-drawer-labresult__body" className="h100">
              <ModalConfigContextProvider
                getContainer={() => (
                  document.getElementById('llm-drawer-labresult__body') as HTMLDivElement
                )}
              >
                <LLMTranscribeLabResultContainer
                  patientId={patientId}
                  onSubmit={(jobId) => {
                    handleOpenFeedbackModal(jobId);
                    close();
                  }}
                />
              </ModalConfigContextProvider>
            </div>
          )
        }
      </LLMDrawerButtonComponent>
      <LLMFeedbackModalContainer
        open={!!feedbackJobId}
        jobId={feedbackJobId}
        onSubmit={handleCloseFeedbackModal}
        onCancel={handleCloseFeedbackModal}
      />
    </>
  );
};
