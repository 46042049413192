import { ReactNode } from 'react';
import { Select, SelectProps } from 'antd';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import './SelectWithPrefixIconComponent.scss';

export interface SelectWithPrefixIconComponentProps extends SelectProps {
  prefixIcon: ReactNode,
  showPrefixIcon?: boolean;
  fullWidth?: boolean;
}

export const SelectWithPrefixIconComponent = ({
  suffixIcon,
  className = '',
  children,
  prefixIcon,
  showArrow = true,
  showPrefixIcon = true,
  fullWidth = true,
  loading,
  ...props
}: SelectWithPrefixIconComponentProps) => {
  const renderArrow = () => (
    showArrow
      ? (suffixIcon || <DownOutlined />)
      : null
  );

  return (
    <Select
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={classNames({
        'select-with-prefix-icon-component': true,
        'full-width': !!fullWidth,
        'show-prefix-icon': !!showPrefixIcon,
        'show-suffix-icon': !!showArrow,
        [className]: !!className,
      })}
      suffixIcon={(
        <>
          <div className="select-with-prefix-icon-component__prefix-icon">
            {
              showPrefixIcon
                ? prefixIcon
                : null
            }
          </div>
          <div className="select-with-prefix-icon-component__suffix-icon">
            {
              loading
                ? <LoadingOutlined />
                : renderArrow()
            }
          </div>
        </>
      )}
      showArrow
    >
      {children}
    </Select>
  );
};
