import { useState } from 'react';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { LabResultClass } from '../../../../uc-api-sdk';
import { AddLabResultDrawerComponent } from './AddLabResultDrawerComponent';
import { UpdatedByAt } from '../../../../user/component/UpdatedByAtComponent/UpdatedByAtComponent';
import { LabResultProvider } from '../../context/LabResultContext/LabResultContext';

export interface LabResultInCardComponentProps {
  patientId?: string;
  disabled?: boolean;
  onClickAddLabResult?: () => void;
}

export const LabResultInCardComponent = ({
  patientId,
  disabled,
  onClickAddLabResult,
}: LabResultInCardComponentProps) => {
  const [latestLab, setLatestLab] = useState<LabResultClass | undefined>();
  const updateInfo: UpdatedByAt = {
    updatedAt: latestLab?.updatedAt?.toString(),
    updatedByUser: latestLab?.updatedByUser,
  };

  return (
    <LabResultProvider>
      <PatientCommonCardComponent
        title="Lab Result"
        isEditing={false}
        showEdit={false}
        updateInfo={updateInfo}
        content={(
          <AddLabResultDrawerComponent
            patientId={patientId}
            placement="right"
            addButtonToRight
            setLatestLab={setLatestLab}
            disabled={disabled}
            onClickAddLabResult={onClickAddLabResult}
          />
    )}
      />
    </LabResultProvider>
  );
};
