/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Form } from 'antd';
import type { FormInstance } from 'antd/es/form';
import './LabResultDetailTableComponent.scss';

export interface LabResultDetailEditableRowComponentProps {
}
export const LabResultDetailEditableContext = React.createContext<
FormInstance | null>(null);

export const LabResultDetailEditableRowComponent = ({
  ...props
}: LabResultDetailEditableRowComponentProps) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <LabResultDetailEditableContext.Provider value={form}>
        <tr {...props} />
      </LabResultDetailEditableContext.Provider>
    </Form>
  );
};
