import { ReactNode, useEffect } from 'react';
import { FlowerSpin } from '../../../../uiComponent/FlowerSpin/FlowerSpin';
import { TranscribeJob, useTranscribingJobGetTranscriptionJob } from '../../../../uc-api-sdk';

export enum LLMTranscribeJobStatusEnum {
  PROCESSING = 'PROCESSING',
  POST_PROCESSING = 'POST_PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}

const finalJobStatus = [
  LLMTranscribeJobStatusEnum.COMPLETED,
  LLMTranscribeJobStatusEnum.FAILED
];

export interface LLMProcessContainerProps {
  jobId?: string;
  onFinish?: <T>(result: TranscribeJob<T>) => void;
  onFail?: <T>(result: TranscribeJob<T>) => void;
  refreshTime?: number; // ms
  loadingText?: ReactNode;
}

export const LLMProcessContainer = ({
  jobId = '',
  onFinish,
  onFail,
  refreshTime = 8000,
  loadingText = 'Processing and generating results, please wait…',
}: LLMProcessContainerProps) => {
  const jobCheckingInfo = useTranscribingJobGetTranscriptionJob({
    params: { jobId },
    options: { sendOnMount: !!jobId }
  });
  const { isLoading = false, data } = jobCheckingInfo;
  const {
    status,
    ...result
  } = data?.data || {};
  const jobStatus = status as unknown as LLMTranscribeJobStatusEnum;

  useEffect(() => {
    if (data?.code && data?.code !== 200) {
      onFail?.(result);
    }
  }, [data?.code]);

  useEffect(() => {
    switch (jobStatus) {
      case LLMTranscribeJobStatusEnum.COMPLETED:
        onFinish?.(result);
        break;
      case LLMTranscribeJobStatusEnum.FAILED:
        onFail?.(result);
        break;
      default:
      // processing, post-processing
    }
  }, [jobStatus]);

  useEffect(() => {
    if (
      isLoading === false
      && jobStatus
      && !finalJobStatus.includes(jobStatus as LLMTranscribeJobStatusEnum)
    ) {
      setTimeout(() => {
        jobCheckingInfo.refetch();
      }, refreshTime);
    }
  }, [jobStatus, isLoading, refreshTime]);

  return (
    <div className="flex fd-c ai-c jc-c w100 h100">
      <FlowerSpin />
      <div>
        {loadingText}
      </div>
    </div>
  );
};
