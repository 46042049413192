import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import './RequiredAsteriskComponent.scss';
import { Size } from '../../types/size';

export interface RequiredAsteriskComponentProps {
  text?: ReactNode;
  size?: Size | number;
  isBefore?: boolean;
}

export const RequiredAsteriskComponent: FC<RequiredAsteriskComponentProps> = ({
  text,
  size = 'medium',
  isBefore = false,
}) => {
  const sizeClass = typeof size === 'string' ? size : '';
  return (
    <div
      className={classNames({
        RequiredAsteriskComponent: true,
        [sizeClass]: true,
        showBefore: isBefore,
        showAfter: !isBefore,
      })}
      style={typeof size === 'number' ? { fontSize: size } : undefined}
    >
      {text}
    </div>
  );
};
