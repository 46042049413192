import { VitalEnumType } from '../../../uc-api-sdk';
import { getBasicVitalsOptions } from '../../enrollment/helper/VitalsOptions';
import { CheckboxFilterComponent } from '../component/CheckboxFilterComponent/CheckboxFilterComponent';
import { TableFilterType } from '../type/filter';
import { FilterIcon } from './FilterIcon';

export const createVitalsCheckboxFilter = (
  enums: Record<string, VitalEnumType> | VitalEnumType[],
): TableFilterType => {
  const options = getBasicVitalsOptions(enums);
  return ({
    filterDropdown: ((props) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <CheckboxFilterComponent {...props} filterOptions={options} />
    )),
    filterIcon: FilterIcon,
  });
};
