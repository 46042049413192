import { CalendarSourceEnum } from '../../../../uc-api-sdk';
import { CalendarColorService, Color } from '../CalendarColorService/CalendarColorService';
import { ColorName } from '../CalendarColorService/Colors';

export class UserColorService {
  private static DEFAULT_COLOR: ColorName = 'gray';

  private static CURRENT_USER_DEFAULT_COLOR: ColorName = 'gray';

  private static OTHER_USERS_DEFAULT_COLOR: ColorName = 'purple';

  private static otherUsersColors: ColorName[] = [
    'mint',
    'paleRed',
    'neonGreen',
    'pink',
    'oliveGreen',
    'brown',
    'yellow',
    'purple',
  ];

  private static unusedColors: ColorName[] = [...this.otherUsersColors];

  private static usersColorTable: Record<string, ColorName> = {};

  private static getCurrentUser(type: CalendarSourceEnum): ColorName {
    switch (type) {
      case CalendarSourceEnum.UC_VISIT: return 'blue';
      case CalendarSourceEnum.OTHER: return 'teal';
      case CalendarSourceEnum.GOOGLE_CALENDAR: return 'gray';
      default: return this.CURRENT_USER_DEFAULT_COLOR;
    }
  }

  private static getOtherUser(id: string): ColorName {
    if (this.usersColorTable[id]) {
      return this.usersColorTable[id];
    }
    const color: ColorName = this.unusedColors.length > 0
      ? this.unusedColors.pop() as ColorName
      : this.OTHER_USERS_DEFAULT_COLOR;
    this.usersColorTable[id] = color;
    return color;
  }

  public static getColor(id: string | 'current', source?: CalendarSourceEnum, isClinicView = false): Required<Color> {
    let colorName: ColorName = this.DEFAULT_COLOR;
    if (isClinicView && source === CalendarSourceEnum.OTHER) {
      colorName = 'yellow';
    } else if (id === 'current' && source) {
      colorName = this.getCurrentUser(source);
    } else if (id !== 'current') {
      colorName = this.getOtherUser(id);
    }
    return CalendarColorService.getColor(colorName);
  }

  public static removeUser(id: string | 'current') {
    if (id !== 'current' && this.usersColorTable[id]) {
      const color = this.usersColorTable[id];
      delete this.usersColorTable[id];
      this.unusedColors.push(color);
    }
  }
}
