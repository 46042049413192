import { ColumnType } from 'antd/es/table';
import { isNil } from 'lodash';
import { NumberService } from '../../../../helpers/number';
import { BPSummaryAverageColumnComponent } from './BPSummaryColumnComponents/BPSummaryAverageColumnComponent';
import { BPSummaryRangeColumnComponent } from './BPSummaryColumnComponents/BPSummaryRangeColumnComponent';
import { BPSummaryTableData } from './BPSummaryTableComponent';
import { BPSummaryTypeComponent } from './BPSummaryTypeComponent';
import { TotalMeasurementCountDisplayComponent } from '../TotalMeasurementCountDisplayComponent/TotalMeasurementCountDisplayComponent';

const EMPTY_VALUE = 'N/A';

/* eslint-disable import/prefer-default-export */
export const BPSummaryTableColumns = {
  type: (): ColumnType<BPSummaryTableData> => ({
    title: 'Type',
    key: 'type',
    dataIndex: 'type',
    width: '23%',
    render: (value) => <BPSummaryTypeComponent type={value} />,
  }),
  count: (totalCount?: number): ColumnType<BPSummaryTableData> => ({
    title: '#',
    key: 'count',
    dataIndex: 'count',
    render: (value) => (
      <TotalMeasurementCountDisplayComponent value={value} totalCount={totalCount} />
    ),
  }),
  average: (): ColumnType<BPSummaryTableData> => ({
    title: 'Avg (mmHg)',
    key: 'average',
    dataIndex: 'average',
    render: (value, rec) => (
      <BPSummaryAverageColumnComponent
        value={NumberService.numberArrayToFixed(value || [], 0)}
        type={rec.type}
      />
    ),
  }),
  range: (): ColumnType<BPSummaryTableData> => ({
    title: 'Range (mmHg)',
    key: 'range',
    dataIndex: 'range',
    render: (value, rec) => (
      <BPSummaryRangeColumnComponent value={value} type={rec.type} />
    ),
  }),
  inRangePercentage: (): ColumnType<BPSummaryTableData> => ({
    title: 'Vitals in Normal',
    key: 'rangePercentage',
    dataIndex: 'inRangePercentage',
    width: '17%',
    render: (value) => (isNil(value) ? EMPTY_VALUE : `${NumberService.getPercentageFromRatio(value)}%`),
  }),
};
