import { Dayjs } from 'dayjs';
import React from 'react';
import classNames from 'classnames';
import { WarningComponent } from '../../../../uiComponent/WarningComponent/WarningComponent';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';

export interface FollowUpdateVisitWindowComponentProps {
  selectedDate?: Dayjs;
  from?: Dayjs;
  to?: Dayjs;
}

export const FollowUpdateVisitWindowComponent = ({
  selectedDate,
  from,
  to,
}: FollowUpdateVisitWindowComponentProps) => {
  const showWarning = (
    from && to && (!selectedDate || selectedDate?.isBefore(from) || selectedDate?.isAfter(to))
  );

  return (
    <div className="fs12">
      {from && to && (
        <div
          className={classNames({
            mb10: !showWarning,
          })}
        >
          Follow-up visit window:
          &nbsp;
          <DisplayDateComponent value={from} format="USA_DATE" />
          {' - '}
          <DisplayDateComponent value={to} format="USA_DATE" />
        </div>
      )}
      {showWarning && (
        <WarningComponent
          className="mb5"
          showIcon={false}
          textColor="alert"
          text="The date selected is not in the follow-up visit window."
        />
      )}
    </div>
  );
};
