import React, { FC, useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { Button, Modal, Space } from 'antd';
import './ConfirmationModalComponent.scss';
import { useConfirmationModalSize } from '../../uiHooks/useConfirmationModalSize/useConfirmationModalSize';

export interface ConfirmationModalComponentProps {
  open: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  text: string;
  cancelText: string;
  confirmText: string;
  width?: number;
  isLoading?: boolean;
  danger?: boolean;
}

export const ConfirmationModalComponent: FC<ConfirmationModalComponentProps> = ({
  open,
  onConfirm,
  onCancel,
  text,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  width,
  isLoading = false,
  danger = false,
}) => {
  const ref = useRef(null);
  const handleClickOutside = () => {
    onCancel?.();
  };
  useOnClickOutside(ref, handleClickOutside);

  if (width) {
    useConfirmationModalSize(width);
  }
  return (
    <Modal
      wrapClassName="confirmation-modal"
      onCancel={onCancel}
      open={open}
      footer={null}
      title={null}
      width={300}
      closable={false}
      maskStyle={{
        width: width || 'var(--confirmation-modal-width, 100%)',
        right: 0,
        left: 'auto',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
      }}
    >
      <div className="confirmation-modal-text b5 mb10">
        {text}
      </div>
      <div className="confirmation-modal-buttons">
        <Space>
          <Button
            className="b5"
            type="link"
            onClick={onConfirm}
            loading={isLoading}
            disabled={isLoading}
            danger={danger}
            ref={ref}
          >
            {confirmText}
          </Button>
          <Button
            className="b5"
            type="link"
            onClick={onCancel}
            loading={isLoading}
            disabled={isLoading}
          >
            {cancelText}
          </Button>
        </Space>
      </div>
    </Modal>
  );
};
