/* eslint-disable no-nested-ternary */
import { Button, Space } from 'antd';
import { map } from 'lodash';
import { useState } from 'react';
import { MedicalAlert, MedicalAlertResult } from '../../../../uc-api-sdk';
import './MedicalAlertContentComponent.scss';
import { Icons } from '../../../../icons/Icons';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { PatientLocalTimeComponent } from '../../../visit/component/PatientLocalTimeComponent/PatientLocalTimeComponent';

export interface MedicalAlertContentComponentProps {
  medicalAlertResult: MedicalAlertResult;
}

export const MedicalAlertContentComponent = ({
  medicalAlertResult,
}: MedicalAlertContentComponentProps) => {
  const alertsList = medicalAlertResult.alerts as MedicalAlert[];
  const [showAllAlerts, setShowAllAlerts] = useState(false);
  const { info } = usePatientContext();

  const renderClickToSeeAllAlerts = () => (
    <Button
      type="text"
      block
      onClick={() => setShowAllAlerts(true)}
      className="expand-collapse"
    >
      <Space>
        <Icons.DownArrow />
        <span>
          See all records
        </span>
      </Space>
    </Button>
  );

  const renderCollapse = () => (
    <Button
      type="text"
      block
      onClick={() => setShowAllAlerts(false)}
      className="expand-collapse"
    >
      <Space>
        <Icons.UpArrow />
        <span>Collapse</span>
      </Space>
    </Button>
  );

  const getPatientLocalTimeTooltip = (createdAt: string) => (
    <PatientLocalTimeComponent
      time={createdAt}
      timezone={info?.patientInfo?.profile?.timezone ?? 'America/Toronto'}
      mode="medicalAlert"
    />
  );

  const getPatientMeasureTimeTooltip = (measureTime: string) => (
    <PatientLocalTimeComponent
      time={measureTime}
      timezone={info?.patientInfo?.profile?.timezone ?? 'America/Toronto'}
      mode="measureTime"
    />
  );

  const renderPatientAlertTimes = (alert: MedicalAlert) => {
    const isDelayed = alert.delayed;
    return isDelayed
      ? (
        <div className="flex ai-s color-red">
          <TooltipComponent
            type="info-icon"
            highlightOnHover={false}
            title={getPatientMeasureTimeTooltip(alert.measureTime as string)}
          />
          <div>(Delayed measurement)</div>
        </div>
      )
      : (
        <TooltipComponent
          type="info-icon"
          title={getPatientLocalTimeTooltip(alert.createdAt as string)}
        />
      );
  };

  const renderSingleAlertHidden = (alert: MedicalAlert, hidden: boolean) => (
    hidden ? (
      <div>
        <div className="medical-alert-content">
          <div className="medical-alert-content-item">
            <div className="medical-alert-generated-time flex gap0">
              <DisplayDateComponent format="MEDICAL_ALERT_DATE" value={alert.createdAt} />
              {renderPatientAlertTimes(alert)}
            </div>
            <div className="medical-alert-reason-hidden">
              {alert.reason}
            </div>
          </div>
        </div>
        {renderClickToSeeAllAlerts()}
      </div>
    ) : (
      <div>
        <div className="medical-alert-content">
          <div className="medical-alert-content-item">
            <div className="medical-alert-generated-time flex gap0">
              <DisplayDateComponent format="MEDICAL_ALERT_DATE" value={alert.createdAt} />
              {renderPatientAlertTimes(alert)}
            </div>
            <div>
              {alert.reason}
            </div>
          </div>
        </div>
      </div>
    )
  );

  const renderAllAlerts = () => (
    <div>
      <div className="medical-alert-content">
        {
        map(alertsList, (alert) => (
          <div className="medical-alert-content-item">
            <div className="medical-alert-generated-time flex gap0">
              <DisplayDateComponent format="MEDICAL_ALERT_DATE" value={alert.createdAt} />
              {renderPatientAlertTimes(alert)}
            </div>
            <div>
              {alert.reason}
            </div>
          </div>
        ))
        }
      </div>
      {renderCollapse()}
    </div>
  );

  return (
    alertsList?.length && alertsList.length > 1
      ? (
        showAllAlerts
          ? renderAllAlerts()
          : renderSingleAlertHidden(alertsList[0], true)
      ) : (
        renderSingleAlertHidden(alertsList[0], false)
      )
  );
};
