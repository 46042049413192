import {
  ReactNode,
  createContext,
  useContext,
} from 'react';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';

export interface DisableContextValue {
  [key: string]: boolean;
}

const DisableContext = createContext<
  DisableContextValue | undefined
>(undefined);

export const useDisableContext = <T extends DisableContextValue>() => {
  const context = useContext(DisableContext);
  if (!context) throw new Error('DisableContext not found');
  return context as T;
};

export interface DisableContextProviderProps<T> {
  value: T;
  children: ReactNode;
}
export const DisableContextProvider = <T extends DisableContextValue>({
  value,
  children,
}: DisableContextProviderProps<T>) => {
  const contextValue = useGetContextValue<DisableContextValue>(value);

  return (
    <DisableContext.Provider value={contextValue}>
      {children}
    </DisableContext.Provider>
  );
};
