import { ReactNode } from 'react';
import TooltipComponent from '../TooltipComponent/TooltipComponent';

export interface EligibleProgramsTooltipComponentProps {
  children: ReactNode;
}

export const EligibleProgramsTooltipComponent = ({
  children
}: EligibleProgramsTooltipComponentProps) => (
  <TooltipComponent
    title={(
      <>
        <div><b>Eligible Programs</b></div>
        <ul>
          <li>
            {'A patient needs at least one of HTN, DM, PreDM,'
              + ' or Obesity to be qualified to enroll in RPM.'}

          </li>
          <li>A patient needs at least two diagnoses to be qualified to enroll in CCM.</li>
          <li>
            {'A patient needs at least one of HTN, DM, PreDM,'
              + ' Obesity or CHF to be qualified to enroll in VBC.'}
          </li>
        </ul>
      </>
    )}
    type="question-icon"
    className="mt20"
    overlayClassName="mw100"
  >
    {children}
  </TooltipComponent>
);
