import { FC, useEffect } from 'react';
import { useGetFoodLog } from '../../hook/useGetFoodLog';
import { FoodLog } from '../../type';
import { FoodLogTabLabelComponent } from '../../component/FoodLogTabComponent/FoodLogTabLabelComponent/FoodLogTabLabelComponent';

export interface FoodLogTabLabelContainerProps {
  data: FoodLog;
  index: number;
  prevKey: string;
}

export const FoodLogTabLabelContainer: FC<FoodLogTabLabelContainerProps> = ({
  data,
  index,
  prevKey,
}) => {
  const getFoodLog = useGetFoodLog({
    params: { id: data.id },
    options: { sendOnMount: false },
  });

  useEffect(() => {
    if (prevKey && +prevKey === index) {
      getFoodLog.refetch();
    }
  }, [prevKey]);

  const newData = (getFoodLog.data?.data || data) as FoodLog;

  return (
    <FoodLogTabLabelComponent
      hasRating={!!newData.rating?.star}
      imageUrl={newData.images?.at(0)?.thumbnailLink || ''}
      alt={newData.createdAt ?? undefined}
    />
  );
};
