import React from 'react';
import { map } from 'lodash';
import { FoodAccessEnum } from '../../../../uc-api-sdk';
import { FoodAccessEnumComponent } from '../../../../enumComponent/FoodAccessEnumComponent/FoodAccessEnumComponent';
import { InputType } from '../../../Input/types';
import { EnumSelectComponent } from '../../../../uiComponent/EnumSelectComponent/EnumSelectComponent';

export interface FoodAccessSelectComponentProps extends
  InputType<FoodAccessEnum> {
}

export const FoodAccessSelectComponent = ({
  value,
  onChange,
  disabled,
}: FoodAccessSelectComponentProps) => (
  <EnumSelectComponent
    options={map(FoodAccessEnum)}
    optionRenderer={FoodAccessEnumComponent}
    value={value}
    onChange={onChange}
    disable={disabled}
  />
);
