import { Input, InputProps } from 'antd';
import React, { FC, ReactNode } from 'react';
import { InputType } from '../../features/Input/types';
import { CurrentUserAvatarComponent } from '../CurrentUserAvatarComponent/CurrentUserAvatarComponent';

export interface CommentInputWAvatarComponentProps extends InputType<string> {
  extra?: ReactNode;
  placeholder?: string;
}

export const CommentInputWAvatarComponent: FC<CommentInputWAvatarComponentProps> = ({
  value,
  onChange,
  placeholder,
  extra,
}) => {
  const handleChange: InputProps['onChange'] = (evt) => {
    onChange?.(evt.target.value || '');
  };

  return (
    <div className="CommentInputWAvatarComponent flex gap2">
      <CurrentUserAvatarComponent />
      <div className="RightWrapper w100">
        <Input
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
        />
        {extra && (
          <div>
            {extra}
          </div>
        )}
      </div>
    </div>
  );
};
