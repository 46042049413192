import { useCallback } from 'react';
import { Title } from '../../../../uiComponent/Title/Title';
import { Icon } from '../../../../icons/Icon';
import { NoDataWithActionComponent } from '../../../../uiComponent/NoDataWithActionComponent/NoDataWithActionComponent';
import { NO_DATA_NO_TEXT_ICON } from '../../../../assets/commonIconInfo';

export interface EmptyInsuranceComponentProps {
  onAddInsurance: () => void;
}

export const EmptyInsuranceComponent = ({
  onAddInsurance,
}: EmptyInsuranceComponentProps) => {
  const handleAddInsurancePlan = () => {
    onAddInsurance();
  };

  const description = useCallback(() => (
    <div className="flex fd-c ai-c">
      <Title noMargin className="fs16">
        No insurance plan added yet
      </Title>
      <div>Please add insurance plan and it will show up here</div>
    </div>
  ), []);

  return (
    <NoDataWithActionComponent
      icon={<Icon info={NO_DATA_NO_TEXT_ICON} />}
      description={description()}
      actionLinkText="Add insurance plan"
      actionLinkOnClick={handleAddInsurancePlan}
    />
  );
};
