import { Row, Col, Card } from 'antd';
import { ReactNode } from 'react';
import classNames from 'classnames';
import { CardTextComponent } from '../../../../uiComponent/CardTypographyComponent/CardTextComponent/CardTextComponent';
import { DeviceModelEnum } from '../../../../uc-api-sdk';
import { DeviceImageComponent } from '../../DeviceImageComponent';
import { DeviceLabelComponent } from '../DeviceLabelComponent/DeviceLabelComponent';

export interface DeviceInfoComponentProps {
  deviceModel: DeviceModelEnum;
  children: ReactNode;
  className?: string;
  extraHeader?: ReactNode;
  boxShadow?: boolean;
}

export const DeviceInfoComponent = ({
  deviceModel,
  children,
  className = '',
  extraHeader,
  boxShadow = true,
}: DeviceInfoComponentProps) => (
  <Card
    className={classNames({
      'box-shadow': !!boxShadow,
      'default-border-radius': true,
      mb10: true,
      [className]: !!className,
    })}
    bodyStyle={{ padding: 14 }}
  >
    <Row>
      <Col span={5} className="flex jc-c ai-s mt10">
        <DeviceImageComponent
          deviceModel={deviceModel}
        />
      </Col>
      <Col className="f1 ml10">
        <div className="flex jc-sb ai-c mb10">
          <CardTextComponent bold>
            <DeviceLabelComponent
              deviceModel={deviceModel}
              showImage={false}
            />
          </CardTextComponent>
          {extraHeader}
        </div>
        {children}
      </Col>
    </Row>
  </Card>
);
