import { Popover } from 'antd';
import { useDisableContext } from '../../../../contexts/DisableContext/DisableContext';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { useVisibility } from '../../../../contexts/VisibilityContext/VisibilityContext';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { AddPatientToWatchlistContainer } from '../../container/AddPatientToWatchlistContainer/AddPatientToWatchlistContainer';
import { UnwatchPatientContainer } from '../../container/UnwatchPatientContainer/UnwatchPatientContainer';
import { DotDropdownComponent, DotDropdownComponentProps } from '../DotDropdownComponent/DotDropdownComponent';
import { PatientInfoDisableValue } from '../PatientInfoCardComponent/PatientInfoCardComponentEnhanced';
import { MakeSchedulesModalComponent } from '../PatientVisitsListComponent/MakeSchedulesComponent/MakeSchedulesModalComponent';
import { SendDownloadAppMsgBtnContainer } from '../../../unifiedCareApp/container/SendDownloadAppMsgBtnContainer/SendDownloadAppMsgBtnContainer';

export interface ActionsComponentProps {
  onClickCreateTask?: () => void;
  onClickPrintVitalsData?: () => void;
  onClickCreateMTPR?: () => void;
  onClickCreateVisit?: () => void;
  onClickPrintCarePlan?: () => void;
}

export const ActionsComponent = ({
  onClickCreateTask,
  onClickPrintVitalsData,
  onClickCreateMTPR,
  onClickCreateVisit,
  onClickPrintCarePlan,
}: ActionsComponentProps) => {
  const { showMTPR } = useVisibility();
  const { disableCreateMTPR } = useDisableContext<PatientInfoDisableValue>();
  const { isOpen, open: openMakeSchedule, close } = useOpen();
  const { isOpen: isWatchlistOpen, open: openWatchlist, close: closeWatchlist } = useOpen();

  const { info } = usePatientContext();
  const { id, patientInfoService } = info || {};
  const { watchInfo } = patientInfoService || {};
  const refetchPatient = useUpdate('PATIENT_INFO_UPDATED');

  const handleVisitModalOpen = () => {
    openMakeSchedule();
    onClickCreateVisit?.();
  };

  const onOpenChange = (open: boolean) => {
    if (!open) {
      closeWatchlist();
    } else {
      openWatchlist();
    }
  };

  const getActionItems = () => {
    const actionItems = [
      {
        label: 'Create a Task',
        key: 'create-task',
        onClick: onClickCreateTask,
      }
    ] as DotDropdownComponentProps['items'];
    if (onClickPrintVitalsData) {
      actionItems.push({
        label: 'Print Vitals Data',
        key: 'print-vitals',
        onClick: onClickPrintVitalsData,
      });
    }

    if (handleVisitModalOpen) {
      actionItems.push({
        label: 'Create a Visit',
        key: 'create-visit',
        onClick: handleVisitModalOpen,
      });
    }

    if (showMTPR && onClickCreateMTPR) {
      actionItems.push({
        label: 'Create a MTPR',
        key: 'create-mtpr',
        onClick: onClickCreateMTPR,
        disabled: disableCreateMTPR,
      });
    }

    if (onClickPrintCarePlan) {
      actionItems.push({
        label: 'Print Care Plan',
        key: 'print-care-plan',
        onClick: onClickPrintCarePlan,
      });
    }
    if (watchInfo?.isWatched) {
      actionItems.push({
        label: (
          <UnwatchPatientContainer
            memberId={id || ''}
            btnText="Remove from watchlist"
            type="text"
            className="p0 autoH"
          />),
        key: 'remove-from-watchlist',
      });
    } else {
      actionItems.push({
        label: (
          <Popover
            className="watched-indicator-component"
            content={(
              <AddPatientToWatchlistContainer
                patientId={id}
                onSuccess={refetchPatient.updateValue}
                onClose={closeWatchlist}
              />
            )}
            overlayClassName="popover-overlay"
            placement="bottomLeft"
            trigger="click"
            open={isWatchlistOpen}
            onOpenChange={onOpenChange}
          >
            Add patient to watchlist
          </Popover>
        ),
        key: 'add-to-watchlist',
      });
    }

    actionItems.push({
      label: <SendDownloadAppMsgBtnContainer patientId={id as string} buttonText="Send App download SMS" />,
      key: 'send-app-download-sms',
    });

    return actionItems;
  };

  return (
    <div>
      <DotDropdownComponent items={getActionItems()} />
      <MakeSchedulesModalComponent isOpen={isOpen} onClose={close} />
    </div>
  );
};
