import React from 'react';
import { EatingRoutineEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface EatingRoutineEnumComponentProps {
  value?: EatingRoutineEnum;
}

export const EatingRoutineEnumComponent = ({ value }: EatingRoutineEnumComponentProps) => {
  const getFullName = () => {
    switch (value) {
      case EatingRoutineEnum.NONE: return 'None selected';
      case EatingRoutineEnum.NO_ROUTINE: return 'No routine for eating; regular routine for meals at least once per day';
      case EatingRoutineEnum.IRREGULAR_ROUTINE: return 'Irregular schedule for eating (time to eat ALL meals varies by at least 2 hours).';
      case EatingRoutineEnum.REGULAR_ROUTINE: return 'Regular eating schedule for each meal of the day.';
      case EatingRoutineEnum.SOMEWHERE_IRREGULAR_ROUTINE: return 'Somewhat irregular schedule for eating (time to eat SOME meals varies by at least 2 hours).';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  return (<>{getFullName()}</>);
};
