import { Dayjs } from 'dayjs';
import { useCallback, useMemo } from 'react';
import { CHSServices } from '../../../../services/CHSServices/CHSServices';
import { MessageChannelSectionHrReadComponent } from '../MessageChannelSectionHrReadComponent/MessageChannelSectionHrReadComponent';

export interface MessageChannelSectionHrPatientACKComponentProps {
  lastClientACK?: number,
  currDateTime?: Dayjs,
  nextDateTime?: number,
}

export const MessageChannelSectionHrPatientACKComponent = ({
  lastClientACK,
  currDateTime,
  nextDateTime,
}: MessageChannelSectionHrPatientACKComponentProps) => {
  const checkShouldDisplayHrPatientUnread = useCallback((
    lastClientACK?: number,
    currDateTime?: Dayjs,
    nextDateTime = Infinity,
  ) => {
    if (!currDateTime || !lastClientACK) return false;
    const currDateTimeNumber = currDateTime.valueOf();
    const lastClientACKNumber = CHSServices.convertTimestamp(String(lastClientACK));
    return lastClientACKNumber >= currDateTimeNumber
      && lastClientACKNumber < nextDateTime;
  }, []);

  const shouldDisplayPatientACK = useMemo(() => (
    checkShouldDisplayHrPatientUnread(
      lastClientACK,
      currDateTime,
      nextDateTime,
    )
  ), [
    lastClientACK,
    currDateTime,
    nextDateTime,
  ]);

  if (!shouldDisplayPatientACK) return null;

  return <MessageChannelSectionHrReadComponent />;
};
