// eslint-disable-next-line camelcase
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { Patient } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { InsuranceFormComponent, InsuranceFormComponentProps } from '../../component/InsuranceFormComponent/InsuranceFormComponent';
import { Insurance, usePatientInsuranceGetAll } from '../../hook/insuranceApis';
import { useInsuranceUpdateHandler } from '../../hook/insuranceUpdateHandler';

export interface InsuranceFormContainerProps {
  patientInfo: Patient;
  onSubmit?: () => void;
  onEdit?: InsuranceFormComponentProps['onEdit'];
}

export const InsuranceFormContainer = ({
  patientInfo,
  onSubmit,
  onEdit,
}: InsuranceFormContainerProps) => {
  const patientId = patientInfo?.id || '';
  const insuranceInfo = usePatientInsuranceGetAll({
    params: { id: patientId },
    options: { sendOnMount: !!patientId },
  });
  const {
    updateInsurance,
    updateInsuranceInfo,
    updateInsuranceNote,
  } = useInsuranceUpdateHandler(patientId);
  const debouncedRefetch = useDebounce(insuranceInfo.refetch, 500, [patientId]);
  useUpdateListener('INSURANCE_UPDATED', debouncedRefetch);
  useUpdateListener('INSURANCE_ELIGIBILITY_UPDATED', debouncedRefetch);

  const isLoading = insuranceInfo.isLoading || updateInsuranceInfo.isLoading;

  const handleSubmit: InsuranceFormComponentProps['onSave'] = (
    values,
    successMsg
  ) => {
    updateInsurance(
      values,
      {
        success: successMsg || 'Insurance updated successfully',
        error: 'Failed to update insurance',
        onSuccess: () => {
          onSubmit?.();
          insuranceInfo.refetch();
        },
      }
    );
  };

  const handleSaveNote = (note: string) => {
    updateInsuranceNote(
      note,
      {
        success: 'Note updated successfully',
        error: 'Failed to update note',
        onSuccess: () => {
          insuranceInfo.refetch();
        },
      },
    );
  };

  return (
    <FetchComponent
      info={insuranceInfo}
    >
      {
        (info) => {
          const insurance = info?.rawValue.data as Insurance;
          return (
            <InsuranceFormComponent
              patient={patientInfo}
              insurance={insurance}
              onSave={handleSubmit}
              onEdit={onEdit}
              disabled={isLoading}
              onSaveNote={handleSaveNote}
            />
          );
        }
      }
    </FetchComponent>
  );
};
