import { NoDataWithActionComponent } from '../../../../uiComponent/NoDataWithActionComponent/NoDataWithActionComponent';

export interface EmptyAlertComponentProps {
  type: 'medical' | 'compliance';
}
export const EmptyAlertComponent = ({
  type,
}: EmptyAlertComponentProps) => (
  type === 'medical'
    ? (
      <NoDataWithActionComponent
        description="No open medical alert."
      />
    ) : (
      <NoDataWithActionComponent
        description="No open compliance alert."
      />
    )
);
