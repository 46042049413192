import { ArticlesMainContainer } from '../../../articles/containers/ArticlesMainContainer';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';

export interface InterventionEducationComponentProps { }

export const InterventionEducationComponent = () => (
  <PatientCommonCardComponent
    title="Education"
    content={null}
    updateInfo={undefined}
    formContent={<ArticlesMainContainer title={null} />}
    isEditing
    showEdit={false}
  />
);
