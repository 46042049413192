import { Button } from 'antd';
import React from 'react';
import { useBoolean } from 'usehooks-ts';
import { VisitParticipant } from '../../../../uc-api-sdk';
import { CalendarParticipantComponent } from './CalendarParticipantComponent';

export interface CalendarParticipantListComponentProps {
  participants?: VisitParticipant[];
}

const DEFAULT_SHOWN_PARTICIPANTS = 8;

export const CalendarParticipantListComponent = ({
  participants = [],
}: CalendarParticipantListComponentProps) => {
  const { value: showAll, setTrue: setShowAllTrue } = useBoolean();
  const firstParticipants = participants.slice(0, DEFAULT_SHOWN_PARTICIPANTS);
  const otherParticipants = participants.slice(DEFAULT_SHOWN_PARTICIPANTS);

  const renderParticipants = (v: VisitParticipant, i: number) => (
    <div key={i}>
      <CalendarParticipantComponent value={v} />
    </div>
  );

  return (
    <div>
      {firstParticipants.map(renderParticipants)}
      {showAll && otherParticipants.map(renderParticipants)}
      {participants.length > DEFAULT_SHOWN_PARTICIPANTS && !showAll && (
        <Button type="link" className="p0" onClick={setShowAllTrue}>Show all</Button>
      )}
    </div>
  );
};
