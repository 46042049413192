import { map } from 'lodash';
import {
  Patient,
  ProgramCategoryEnum,
  useClinicGet,
  usePatientUpsertPatientEnrolledProgram,
} from '../../../../uc-api-sdk';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import {
  PatientProfileProgramEnrollmentFormComponent,
  PatientProfileProgramEnrollmentFormComponentProps,
} from '../../component/PatientProfileProgramEnrollmentFormComponent/PatientProfileProgramEnrollmentFormComponent';
import { ProgramEnrollmentSubmitValue } from '../../hook/useProfileProgramEnrollmentForm';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { EnrolledProgramService } from '../../../../services/EnrolledProgramService';
import { FormType } from '../../../Input/types';

export interface PatientProfileProgramEnrollmentFormContainerProps
  extends FormType<ProgramEnrollmentSubmitValue> {
  patient: Patient;
  enrolledProgramService: EnrolledProgramService;
  patientClinicProgramParticipation?: ProgramCategoryEnum[];
}

export const PatientProfileProgramEnrollmentFormContainer = ({
  patient,
  enrolledProgramService,
  patientClinicProgramParticipation,
  isLoading,
  onSubmit,
  onCancel,
  onError,
  onValuesChange,
  formButtons,
}: PatientProfileProgramEnrollmentFormContainerProps) => {
  const updateProgramsInfo = usePatientUpsertPatientEnrolledProgram({});

  const clinicInfo = useClinicGet({
    params: { id: patient.clinicId || '' },
    options: { sendOnMount: !patientClinicProgramParticipation && !!patient.clinicId },
  });

  const clinicProgramParticipation = (
    patientClinicProgramParticipation
    || map(clinicInfo.data?.data?.programParticipation, 'name') as ProgramCategoryEnum[]
  );

  const handleOnSubmit: PatientProfileProgramEnrollmentFormComponentProps['onSubmit'] = (
    values,
  ) => {
    const req = updateProgramsInfo.send({
      params: {
        memberId: patient.id as string,
        enrolledProgram: {
          programs: values.programs,
        },
      },
    });
    ApiRequestHelper.tryCatch(
      req,
      {
        success: 'Updated programs successfully.',
        error: 'Failed to update programs',
        onSuccess: () => {
          onSubmit?.(values);
        },
        onError,
      },
    );
  };

  const debouncedHandleSubmit = useDebounce(handleOnSubmit);

  return (
    <LoadingOverlayComponent
      isLoading={clinicInfo.isLoading}
      showSkeleton
    >
      <PatientProfileProgramEnrollmentFormComponent
        patientClinicProgramParticipation={clinicProgramParticipation}
        initialValues={{ programs: enrolledProgramService.getProgramEnrollments() }}
        isLoading={isLoading || updateProgramsInfo.isLoading}
        onSubmit={debouncedHandleSubmit}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        formButtons={formButtons}
      />
    </LoadingOverlayComponent>
  );
};
