import { TranscribeOutstandingTypeEnum } from '../../uc-api-sdk';
import EmptyComponent from '../../uiComponent/EmptyComponent/EmptyComponent';

export interface TranscribeOutstandingTypeEnumComponentProps {
  value: TranscribeOutstandingTypeEnum | null | undefined;
}

export const TranscribeOutstandingTypeEnumComponent = ({
  value,
}: TranscribeOutstandingTypeEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case TranscribeOutstandingTypeEnum.A1C:
        return 'A1C Transcribing Due';
      case TranscribeOutstandingTypeEnum.FOLLOW_UP:
        return 'Follow-up Visit Transcribing';
      case TranscribeOutstandingTypeEnum.INIT:
        return 'Initial Transcribing';
      default: return <EmptyComponent />;
    }
  };

  return (
    <>{getText()}</>
  );
};
