import { Dayjs } from 'dayjs';
import { Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { VisitMethodEnum, VisitTypeEnum } from '../../../../uc-api-sdk';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { Title } from '../../../../uiComponent/Title/Title';
import { StartEndDurationTimeComponent } from '../../../enrollment/component/StartEndDurationTimeComponent/StartEndDurationTimeComponent';
import { VisitTypeComponent } from '../../../visit/component/VisitTypeComponent/VisitTypeComponent';
import { VisitIconComponent } from '../../../visit/component/VisitIconComponent/VisitIconComponent';
import './WorklistVisitInfoComponent.scss';
import { RejoinZoomButtonComponent } from '../../../zoom/component/RejoinZoomButtonComponent/RejoinZoomButtonComponent';
import { useWorklistContext } from '../../../../contexts/WorklistContext/WorklistContext';

export interface WorklistVisitInfoComponentProps {
  visitType: VisitTypeEnum;
  visitMethod: VisitMethodEnum;
  startTime?: string | Dayjs;
  description?: string;
  endTime?: Dayjs | string;
  onClose?: () => void;
}

export const WorklistVisitInfoComponent = ({
  visitType,
  visitMethod,
  startTime,
  description,
  endTime,
  onClose,
}: WorklistVisitInfoComponentProps) => {
  const { clinicEvent } = useWorklistContext();

  return (
    <div className="flex fd-c w100 mb20">
      <div className="flex jc-sb ai-bl">
        <Title noMargin>
          <Space align="center">
            <Space>
              <VisitTypeComponent value={visitType} type="full" />
              Visit
            </Space>
            <div className="worklist-visit-info-component__icon">
              <VisitIconComponent value={visitMethod} />
            </div>
          </Space>
        </Title>
        <CloseOutlined onClick={onClose} />
      </div>
      <RejoinZoomButtonComponent
        visitMethod={visitMethod}
        isOnGoing={clinicEvent?.isOnGoing}
        zoomEntry={clinicEvent?.zoomEntry}
      />
      <StartEndDurationTimeComponent
        startTime={startTime}
        endTime={endTime}
      />
      <TextComponent color="gray2">
        Description:
        {' '}
        {description}
      </TextComponent>
    </div>
  );
};
