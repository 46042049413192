import { Rule } from 'antd/lib/form';

const REQUIRED_ERROR_MESSAGE = 'Email is required';
const INVALID_ERROR_MESSAGE = 'This email is not valid';
const INVALID_MULTIPLE_EMAILS_ERROR_MESSAGE = 'Please ensure that the contents you input are in email format, with each entry separated by a comma.';

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default class EmailService {
  static getEmailValidator(isRequired = false, isIhealthEmail = false) {
    return (_rule: Rule, value: string) => (
      new Promise((resolve, reject) => {
        if (!value && isRequired) {
          reject(REQUIRED_ERROR_MESSAGE);
        }
        if (value && !EmailService.validateEmail(value, isIhealthEmail)) {
          reject(INVALID_ERROR_MESSAGE);
        }
        resolve(true);
      })
    );
  }

  static getMultipleEmailsValidator(isIhealthEmail = false) {
    return (_rule: Rule, value: string) => (
      new Promise((resolve, reject) => {
        if (value && !EmailService.validateMultipleEmails(value, isIhealthEmail)) {
          reject(INVALID_MULTIPLE_EMAILS_ERROR_MESSAGE);
        }
        resolve(true);
      })
    );
  }

  static validateEmail(emailInput: string, isIhealthEmail = false) {
    if (isIhealthEmail && !emailInput.endsWith('@ihealthlabs.com')) {
      return false;
    }
    return emailInput.toLowerCase().match(emailRegex);
  }

  static validateMultipleEmails(emails: string, isIhealthEmail = false) {
    const emailList = emails.split(',');
    return emailList.every((email) => EmailService.validateEmail(email.trim(), isIhealthEmail));
  }
}
