// @ts-nocheck
import { map } from 'lodash';
import { useMemo } from 'react';
import { DayOfWeekEnum, VitalsMonitoringBGTableData, VitalsMonitoringBGTableDayData } from '../../component/VitalsMonitoringScheduleComponent/VitalsMonitoringBGComponent/VitalsMonitoringBGTableComponent/VitalsMonitoringBGTableComponent';
import { BGMonitoringScheduleHelper } from '../../helper/BGMonitoringScheduleHelper';
import { DailyMealSchedule, WeeklyMealSchedule } from '../../../../uc-api-sdk';

export const useVitalsMonitoringBGToTable = (data?: WeeklyMealSchedule) => {
  const dayToEnum = (day: keyof WeeklyMealSchedule) => {
    switch (day) {
      case 'sat': return DayOfWeekEnum.Saturday;
      case 'mon': return DayOfWeekEnum.Monday;
      case 'tues': return DayOfWeekEnum.Tuesday;
      case 'wed': return DayOfWeekEnum.Wednesday;
      case 'thurs': return DayOfWeekEnum.Thursday;
      case 'fri': return DayOfWeekEnum.Friday;
      case 'sun': return DayOfWeekEnum.Sunday;
      default: return DayOfWeekEnum.Saturday;
    }
  };

  const getData = (v?: DailyMealSchedule | null): VitalsMonitoringBGTableDayData => ({
    BREAKFAST: {
      beforeMeal: v?.beforeBreakfast || false,
      afterMeal: v?.afterBreakfast || false,
    },
    LUNCH: {
      beforeMeal: v?.beforeLunch || false,
      afterMeal: v?.afterLunch || false,
    },
    DINNER: {
      beforeMeal: v?.beforeDinner || false,
      afterMeal: v?.afterDinner || false,
    },
    BEDTIME: v?.bedtime || false,
    OVERNIGHT: v?.overnight || false,
  });

  const processedData = useMemo<VitalsMonitoringBGTableData[]>(() => map(
    BGMonitoringScheduleHelper.daysOrder,
    (v) => ({
      day: dayToEnum(v),
      dayName: v[0].toUpperCase() + v.substring(1, 3),
      data: getData(data?.[v]),
    }),
  ), [data]);

  return processedData;
};
