import { ReactNode } from 'react';
import { Button, ButtonProps } from 'antd';
import { useExtendableDrawerController } from '../controller/ExtendableDrawerController';

export interface ExtendableDrawerTriggerProps {
  disabled?: boolean;
  onClick?: () => void;
}

// trigger will override onClick if provided
export interface ExtendableDrawerButtonComponentProps {
  buttonText: ReactNode;
  buttonProps?: Omit<ButtonProps, 'onClick'>;
  trigger?: (props: ExtendableDrawerTriggerProps) => ReactNode;
  onClick?: () => void;
}

export const ExtendableDrawerButtonComponent = ({
  buttonText,
  buttonProps = {},
  trigger,
  onClick,
}: ExtendableDrawerButtonComponentProps) => {
  const {
    shouldDisableExtend,
    onExtendableDrawerButtonClick,
  } = useExtendableDrawerController();
  const isDisabled = shouldDisableExtend();

  const handleOpen = async () => {
    const res = await onExtendableDrawerButtonClick?.();
    if (!res) return;
    onClick?.();
  };

  if (trigger) {
    return (
      <>
        {
          trigger({
            disabled: isDisabled,
            onClick: handleOpen
          })
        }
      </>
    );
  }

  return (
    <Button
      disabled={isDisabled}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonProps}
      onClick={handleOpen}
    >
      {buttonText}
    </Button>
  );
};
