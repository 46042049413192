import classNames from 'classnames';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { AlertCardComponent } from '../../../../uiComponent/AlertCardComponent/AlertCardComponent';
import { PauseServiceDrawerComponent } from '../../../../uiComponent/PauseServiceDrawerComponent/PauseServiceDrawerComponent';
import './PauseServiceAlertComponent.scss';
import { PauseServiceStatusComponent } from './PauseServiceStatusComponent';

export interface PauseServiceAlertComponentProps {
  className?: string;
}

export const PauseServiceAlertComponent = ({
  className = '',
}: PauseServiceAlertComponentProps) => {
  const { info } = usePatientContext();
  const { enrolledProgramService } = info || {};

  return enrolledProgramService?.isHiatusStatusPaused() ? (
    <div className={
      classNames({
        [className]: !!className,
        PauseServiceAlertComponent: true,
      })
    }
    >
      <PauseServiceDrawerComponent
        btnTitle={(
          <AlertCardComponent
            type="error"
            message={<PauseServiceStatusComponent />}
          />
        )}
        type="default"
        className="bg-transparent no-hover no-active no-focus"
      />

    </div>
  ) : null;
};
