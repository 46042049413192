import { Select, SelectProps } from 'antd';
import React, { FC } from 'react';
import { TaskRepeatEnum } from '../../../../../uc-api-sdk';

export interface VitalsMonitoringOccurrenceSelectComponentProps {
  value?: TaskRepeatEnum;
  onSelect?: SelectProps<TaskRepeatEnum>['onSelect'];
}

export const VitalsMonitoringOccurrenceSelectComponent: FC<
  VitalsMonitoringOccurrenceSelectComponentProps
> = ({
  value,
  onSelect,
}) => (
  <Select<TaskRepeatEnum> onSelect={onSelect} value={value} className="mw120">
    <Select.Option value={TaskRepeatEnum.MONTHLY}>Monthly</Select.Option>
    <Select.Option value={TaskRepeatEnum.DAILY}>Daily</Select.Option>
    <Select.Option value={TaskRepeatEnum.WEEKLY}>Weekly</Select.Option>
  </Select>
);
