import { ReactNode } from 'react';
import { Dayjs } from 'dayjs';
import { CalendarEventBaseComponent } from '../CalendarEventBaseComponent/CalendarEventBaseComponent';

export interface CalendarGoogleEventComponentProps {
  startTime: Dayjs;
  endTime: Dayjs;
  title: ReactNode;
  isAllDay: boolean;
  className?: string;
}

export const CalendarGoogleEventComponent = ({
  startTime,
  endTime,
  title,
  isAllDay,
  className = '',
}: CalendarGoogleEventComponentProps) => (
  <CalendarEventBaseComponent
    startTime={startTime}
    endTime={endTime}
    title={title}
    isAllDay={isAllDay}
    className={className}
  />
);
