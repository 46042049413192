import React, { FC, ReactNode } from 'react';
import './TicketTitleValueItemComponent.scss';

export interface TicketTitleValueItemComponentProps {
  title: ReactNode;
  content?: ReactNode;
}

export const TicketTitleValueItemComponent: FC<TicketTitleValueItemComponentProps> = ({
  title,
  content,
}) => (
  <div className="flex ai-c">
    <div className="ticket-title-value-item-title">{title}</div>
    <div className="ticket-title-value-item-content">{content}</div>
  </div>
);
