import { NoDataWithActionComponent } from '../../../../uiComponent/NoDataWithActionComponent/NoDataWithActionComponent';
import { MakeSchedulesComponent } from '../PatientVisitsListComponent/MakeSchedulesComponent/MakeSchedulesComponent';

export const PatientEmptyVisitsComponent = () => (
  <NoDataWithActionComponent
    description="No visits at this time."
  >
    <div className="flex jc-e">
      <MakeSchedulesComponent />
    </div>
  </NoDataWithActionComponent>
);
