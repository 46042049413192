import { Checkbox, Form } from 'antd';
import { useMemo } from 'react';
import { FormType } from '../../../Input/types';
import { useAssignDeviceInfoForm } from '../../../enrollment/hook/useAssignDeviceInfoForm/useAssignDeviceInfoForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { DeviceModelEnum } from '../../../../uc-api-sdk';
import { DeviceMonitorMethodEnum } from '../../../../enumComponent/DeviceMonitorMethodEnumComponent/DeviceMonitorMethodEnumComponent';
import { DeviceSearchSelectContainer } from '../../container/DeviceSearchSelectContainer/DeviceSearchSelectContainer';
import ClearDeviceLanguageSelectComponent from '../../../../selectComponents/LanguageSelectComponent/ClearDeviceLanguageSelectComponent';
import { BPDeviceCuffSizeComponent } from '../../../enrollment/component/BPDeviceCuffSizeComponent/BPDeviceCuffSizeComponent';
import CancelSubmitButtonsComponent from '../../../../uiComponent/CancelSubmitButtonsComponent/CancelSubmitButtonsComponent';
import { DeviceHelper } from '../../helper';

export interface SubmitValue {
  deviceId?: string;
  deviceModel?: DeviceModelEnum;
  deviceMethod?: DeviceMonitorMethodEnum;
  deviceLanguage?: string;
  bpReadOut?: boolean;
}

export interface BloodPressureDeviceFormComponentProps extends FormType<SubmitValue> {
  deviceModel: DeviceModelEnum;
  submitText?: string;
}

export const BloodPressureDeviceFormComponent = ({
  deviceModel,
  initialValues,
  onSubmit,
  submitText = 'Save',
  onCancel,
}: BloodPressureDeviceFormComponentProps) => {
  const deviceInfoForm = useAssignDeviceInfoForm();

  const correctDeviceModel = useMemo(() => {
    if (deviceModel === DeviceModelEnum.BPM1_HOTSPOT) {
      return DeviceModelEnum.BPM1;
    }
    return deviceModel;
  }, [deviceModel]);

  const handleSubmit = (values: SubmitValue) => {
    onSubmit?.({
      ...values,
      deviceModel,
      deviceMethod: DeviceHelper.getMethodByModel(deviceModel),
    });
  };

  const processedInitialValues = useMemo(() => {
    if (deviceInfoForm.shouldShowLanguageAndBPReadOut(deviceModel)) {
      return {
        ...initialValues,
        [deviceInfoForm.getName('bpReadOut')]: !!initialValues?.bpReadOut, // make sure boolean
        deviceLanguage: initialValues?.deviceLanguage || DeviceHelper.BPM1DeviceLanguage.en.value,
      };
    }
    return { ...initialValues };
  }, [initialValues]);

  return (
    <Form
      form={deviceInfoForm.form}
      initialValues={processedInitialValues}
      onFinish={deviceInfoForm.handleSubmit(handleSubmit)}
      onValuesChange={deviceInfoForm.onBPM1ValuesChange}
      layout="vertical"
      className="my20"
      preserve={false}
    >
      <FormItem
        info={deviceInfoForm.getInfo('deviceId')}
        required
      >
        <DeviceSearchSelectContainer
          deviceModel={correctDeviceModel}
        />
      </FormItem>
      {
        deviceInfoForm.shouldShowLanguageAndBPReadOut(deviceModel)
        && (
          <>
            <FormItem
              info={deviceInfoForm.getInfo('deviceLanguage')}
            >
              <ClearDeviceLanguageSelectComponent />
            </FormItem>
            <FormItem
              noStyle
              shouldUpdate={deviceInfoForm.shouldUpdate(['deviceLanguage'])}
            >
              {
                ({ getFieldValue }) => (
                  <FormItem
                    name={deviceInfoForm.getName('bpReadOut')}
                    valuePropName="checked"
                  >
                    <Checkbox
                      disabled={deviceInfoForm.shouldDisableBpReadOut(getFieldValue)}
                    >
                      {deviceInfoForm.getLabel('bpReadOut')}
                    </Checkbox>
                  </FormItem>
                )
              }
            </FormItem>
          </>
        )
      }
      <FormItem
        name={deviceInfoForm.getName('cuffSize')}
        label={deviceInfoForm.getLabel('cuffSize')}
        className="mt20"
        required
      >
        <BPDeviceCuffSizeComponent
          noDefault
        />
      </FormItem>
      <CancelSubmitButtonsComponent
        className="mt40"
        submitText={submitText}
        onCancel={onCancel}
      />
    </Form>
  );
};
