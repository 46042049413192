import {
  Col,
  Form,
  Row,
} from 'antd';
import { useMemo } from 'react';
import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import { useWorklistContext } from '../../../../contexts/WorklistContext/WorklistContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { PatientService } from '../../../../services/PatientService';
import {
  ResultSourceEnum,
  Height,
  usePatientUpdate,
  Weight,
} from '../../../../uc-api-sdk';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { HeightInputComponent } from '../../../../uiComponent/Transcribing/component/HeightInputComponent/HeightInputComponent';
import { useDemographicsForm } from '../../../patient/hook/useDemographicsForm';
import { WeightInputFormItemComponent } from '../../../../uiComponent/Transcribing/component/WeightInputFormItemComponent/WeightInputFormItemComponent';

export interface WorklistBasicInformationSubmitValue {
  height?: string | number;
  heightUnit?: string;
  weight?: string | number;
  weightUnit?: string;
}

export interface WorklistBasicInformationFormContainerProps
  extends NestedFormComponentChildProps<WorklistBasicInformationSubmitValue> {
  patientId: string;
  patientInfoService: PatientService;
}

export const WorklistBasicInformationFormContainer = ({
  patientId,
  patientInfoService,
  onSubmit,
  onError,
  onValuesChange,
  isLoading,
}: WorklistBasicInformationFormContainerProps) => {
  const demographicsForm = useDemographicsForm();
  const updatePatient = usePatientUpdate({});
  const { clinicEvent } = useWorklistContext();

  const initialValues: WorklistBasicInformationSubmitValue = useMemo(() => {
    const height = patientInfoService.getHeight() ?? undefined;
    const weight = patientInfoService.getWeightInLb() ?? undefined;
    return {
      height,
      weight,
    };
  }, [patientInfoService]);

  const handleOnSubmit: WorklistBasicInformationFormContainerProps['onSubmit'] = (values) => {
    const height: Height | undefined = values?.height ? {
      value: Number(values?.height),
      unit: values?.heightUnit,
    } : undefined;

    const weight: Weight | undefined = values?.weight !== undefined ? {
      value: Number(values?.weight),
      unit: values?.weightUnit,
    } : undefined;
    const isWeightChanged = patientInfoService.isWeightChanged(
      weight?.value || 0,
      weight?.unit || undefined,
    );

    if (isWeightChanged && weight) {
      weight.source = ResultSourceEnum.VISIT;
    }

    if (height || weight) {
      ApiRequestHelper.tryCatch(
        updatePatient.send({
          params: {
            memberId: patientId,
            request: {
              editWeight: isWeightChanged,
              profile: { height, weight },
              visitId: clinicEvent?.id,
            },
          },
        }),
        {
          success: 'Updated patient profile successfully.',
          error: 'Failed to update height or weight.',
          onSuccess: () => onSubmit?.(values),
          onError,
        }
      );
    } else {
      onSubmit?.();
    }
  };

  return (
    <Form
      form={demographicsForm.form}
      onFinish={demographicsForm.handleSubmit(handleOnSubmit)}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
      disabled={demographicsForm.formDisabled || isLoading}
      layout="vertical"
    >
      <Row gutter={22} align="bottom" className="mb10">
        <Col span={6}>
          <FormItem
            info={demographicsForm.getInfo('height')}
            required
          >
            <HeightInputComponent />
          </FormItem>
          <FormItem
            name="heightUnit"
            initialValue="cm"
            hidden
          >
            <div />
          </FormItem>
        </Col>
        <Col span={6}>
          <WeightInputFormItemComponent
            info={demographicsForm.getInfo('weight')}
          />
          <FormItem
            name="weightUnit"
            initialValue="lb"
            hidden
          >
            <div />
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};
