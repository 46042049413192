export const GeneralConfigs = {
  pagination: {
    defaultPageSize: 20,
    // Cannot avoid using any here because global config for table
    // does not accept pageSizeOptions
    // this needs to be removed when antd updates the type and adds
    // configuration for pageSizeOptions in ConfigProviderProps
    defaultPageSizeOptions: ['10', '20', '50'],
  }
};
