import { ReactNode } from 'react';
import { CallCenterStatusButtonComponent } from './CallCenterStatusButtonComponent';

type CallCenterStatusDropdownItemKey = 'set-offline' | 'set-online' | 'open-ccp';
type Label = ReactNode;
export type CallCenterStatusDropdownItem = {
  key: CallCenterStatusDropdownItemKey,
  label: Label,
  type?: undefined;
  children?: CallCenterStatusDropdownItem[];
} | {
  key?: CallCenterStatusDropdownItemKey;
  label?: Label;
  type: 'divider',
  children?: CallCenterStatusDropdownItem[];
} | {
  key?: CallCenterStatusDropdownItemKey;
  label: Label;
  type: 'group',
  children: CallCenterStatusDropdownItem[];
};

export const callCenterStatusDropdownItems: CallCenterStatusDropdownItem[] = [
  {
    type: 'group',
    label: 'AWS status',
    children: [
      {
        key: 'set-offline',
        label: (
          <CallCenterStatusButtonComponent
            status="Offline"
            isLoggedIn
            background="none"
          />
        ),
      },
      {
        key: 'set-online',
        label: (
          <CallCenterStatusButtonComponent
            status="Online"
            isLoggedIn
            background="none"
          />
        ),
      },
    ],
  },
  {
    type: 'divider',
  },
  {
    key: 'open-ccp',
    label: 'Open AWS Panel',
  },
];
