import { Dayjs } from 'dayjs';
import { reduce } from 'lodash';
import { useMemo } from 'react';
import { USA_DATE } from '../../../../constants/timeFormat';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import { Measurement } from '../../../../uc-api-sdk';
import { HSMeasurementService } from '../../helper/HSMeasurementService';
import { MeasurementHelper } from '../../helper/MeasurementHelper';
import { HsTableDataType } from '../../type/HSTypes';
import { MeasurementType } from '../../type/MeasurementTypes';

export const useHSResultToTable = (
  data: Measurement[],
  fromDate: Dayjs,
  toDate: Dayjs,
) => {
  const processedData = useMemo(() => {
    // create a table with date as the key and array of measurements as the value
    const dateValueDict = MeasurementHelper.dateValueDict(
      data as MeasurementType[],
    );

    // uses the table above to a the date create a
    // new table mealType as the key and array of measurements
    // as the value
    const getData = (date: string) => {
      const valuesInDay = dateValueDict[date];
      if (!valuesInDay) {
        return {};
      }
      const unsortedObject = reduce(valuesInDay, (res, curr) => {
        const timeOfDay = TimezoneService.getTimeOfDay(curr.date, curr.timezone);
        if (res[timeOfDay] === undefined) {
          res[timeOfDay] = [];
        }
        res[timeOfDay]?.push(new HSMeasurementService(curr));
        return res;
      }, {} as HsTableDataType['data']);

      return MeasurementHelper.sortByDate(unsortedObject);
    };

    return MeasurementHelper.createArrayOfDateData(
      fromDate,
      toDate,
      (date: Dayjs) => getData(date.format(USA_DATE)),
    ).reverse();
  }, [data, fromDate, toDate]);

  return processedData;
};
