import { ReactNode } from 'react';
import { DisplayPercentageComponent } from '../../../../uiComponent/DisplayComponent/DisplayPercentageComponent';
import { MTPRMeasurementSummaryItemComponent } from '../MTPRMeasurementSummaryItemComponent/MTPRMeasurementSummaryItemComponent';

export interface MTPRMeasurementSummaryComponentProps {
  children?: ReactNode;
  measurementDays?: number | null;
  totalMeasurements?: number | null;
  normalPercentage?: number | null;
}

export const MTPRMeasurementSummaryComponent = ({
  children,
  measurementDays,
  totalMeasurements,
  normalPercentage,
}: MTPRMeasurementSummaryComponentProps) => (
  <div
    className="mtpr-measurement-summary flex w-mc f-w border secondary-br bg-gray5 py10 pl12 pr30"
  >
    <MTPRMeasurementSummaryItemComponent
      title="Days of Meas."
      value={measurementDays}
      emptyText="0"
    />
    <MTPRMeasurementSummaryItemComponent
      title="Total Meas."
      value={totalMeasurements}
      emptyText="0"
    />
    {children}
    <MTPRMeasurementSummaryItemComponent
      title="Meas. In Normal"
      value={(
        <DisplayPercentageComponent valueInPercent={((normalPercentage ?? 0) * 100)} />
      )}
    />
  </div>
);
