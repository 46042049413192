import { PriorityLevelEnum } from '../../../../uc-api-sdk';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import './MedicalAlertNameComponent.scss';

export interface MedicalAlertNameComponentProps {
  alertName?: string;
  priority?: PriorityLevelEnum;
  alertReason?: string;
}

export const MedicalAlertNameComponent = ({
  alertName = '',
  priority = PriorityLevelEnum.LOW,
  alertReason = 'Testing reading SBP/DBP = 182/102 at 10:22 AM, 03/15/2024.',
}: MedicalAlertNameComponentProps) => {
  const renderLowPriority = () => (
    <TooltipComponent
      title={alertReason}
      type={null}
      placement="top"
      trigger={['hover']}
      highlightOnHover={false}
    >
      <div className="bg-green alert-item flex-c ai-c gap2 pl10 pr10">
        <div className="alert-item-dot alert-item-dot-low" />
        <div className="b5 fs14">{alertName}</div>
      </div>
    </TooltipComponent>
  );

  const renderMediumPriority = () => (
    <TooltipComponent
      title={alertReason}
      type={null}
      placement="top"
      trigger={['hover']}
      highlightOnHover={false}
    >
      <div className="bg-yellow alert-item flex-c ai-c gap2 pl10 pr10">
        <div className="alert-item-dot alert-item-dot-medium" />
        <div className="b5 fs14">{alertName}</div>
      </div>
    </TooltipComponent>
  );

  const renderHighPriority = () => (
    <TooltipComponent
      title={alertReason}
      type={null}
      placement="top"
      trigger={['hover']}
      highlightOnHover={false}
    >
      <div className="alert-high-bg alert-item flex-c ai-c gap2 pl10 pr10">
        <div className="alert-item-dot alert-item-dot-high" />
        <div className="b5 fs14">{alertName}</div>
      </div>
    </TooltipComponent>
  );

  switch (priority) {
    case PriorityLevelEnum.LOW:
      return renderLowPriority();
    case PriorityLevelEnum.MEDIUM:
      return renderMediumPriority();
    case PriorityLevelEnum.HIGH:
      return renderHighPriority();
    default:
      return renderLowPriority();
  }
};
