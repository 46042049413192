import { Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { BillableTimeReviewTableDataSource } from '../BillableTimeReviewTableComponent/BillableTimeReviewTableComponent';
import { EnterActualBillableTimeDrawerComponent } from '../EnterActualBillableTimeDrawerComponent/EnterActualBillableTimeDrawerComponent';
import { BillableProcessStatusEnum } from '../../../../uc-api-sdk';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { MillisecondToMinuteComponent } from '../../../../uiComponent/MillisecondToMinuteComponent/MillisecondToMinuteComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import './BillableTimeReviewActionComponent.scss';

export interface SubmitValue {
  id: string;
  action?: BillableProcessStatusEnum;
  correctedBillableTime: string;
  comment: string;
}

export interface BillableTimeReviewActionComponentProps {
  confirmApproval?: (id: string) => void;
  record: BillableTimeReviewTableDataSource;
}

export const BillableTimeReviewActionComponent = ({
  confirmApproval,
  record,
}: BillableTimeReviewActionComponentProps) => {
  const renderConfirmed = () => (
    <div className="flex f-w">
      <div className="flex">
        <div className="success b7">
          <MillisecondToMinuteComponent value={record.correctedBillableTime as number} />
        </div>
        <div>min</div>
      </div>
      <TooltipComponent
        title={record.comment}
        placement="top"
        type={null}
        highlightOnHover={false}
        className="text-secondary-gray"
      >
        <div className="render-confirmed">{record.comment}</div>
      </TooltipComponent>
    </div>
  );

  const renderReviewExpired = () => (
    <TooltipComponent
      title="The review must be completed by the 2nd of the month. Failure to review will result in the billable time being discarded."
      placement="top"
      type="info-icon"
      highlightOnHover={false}
      className="text-secondary-gray"
    >
      Review Expired
    </TooltipComponent>
  );

  const renderAction = () => (
    <div className="flex gap2 f-w">
      <Button
        type="primary"
        onClick={() => { confirmApproval?.(record.id || ''); }}
      >
        <div className="flex gap1 b7">
          <CheckOutlined />
          <div>Confirm</div>
        </div>
      </Button>
      <EnterActualBillableTimeDrawerComponent
        id={record.id}
        startDate={record.startTime}
        totalTime={record.totalTime}
        totalBillableTime={record.originBillableTime}
      />
    </div>
  );

  if (confirmApproval) {
    switch (record.status) {
      case BillableProcessStatusEnum.CONFIRMED:
        return renderConfirmed();
      case BillableProcessStatusEnum.REVIEW_EXPIRED:
        return renderReviewExpired();
      case BillableProcessStatusEnum.PROCESSING:
        return <div>Processing</div>;
      case BillableProcessStatusEnum.REVIEW_REQUIRED:
      default:
        return renderAction();
    }
  } else {
    switch (record.status) {
      case BillableProcessStatusEnum.CONFIRMED:
        return renderConfirmed();
      case BillableProcessStatusEnum.REVIEW_EXPIRED:
        return renderReviewExpired();
      case BillableProcessStatusEnum.REVIEW_REQUIRED:
        return <div className="warning"> Waiting Review</div>;
      default:
        return <DisplayOrEmptyComponent />;
    }
  }
};
