import { ReactNode, useState } from 'react';
import {
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Button, Layout } from 'antd';
import classNames from 'classnames';
import { FixedComponent } from '../FixedComponent/FixedComponent';
import './SiderComponent.scss';

const { Sider } = Layout;

export interface SiderComponentProp {
  header?: ReactNode;
  content?: ReactNode;
  footer?: ReactNode;
  collapsible?: boolean;
  className?: string;
  type?: 'default' | 'primary';
}

export const SiderComponent = ({
  header,
  content,
  footer,
  collapsible = true,
  className = '',
  type = 'default',
}: SiderComponentProp) => {
  const [collapsed, setCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Sider
      className={classNames({
        'layout-sider': true,
        [`sider-component-${type}`]: type,
        [className]: !!className,
      })}
      collapsible={collapsible}
      collapsed={collapsed}
      onCollapse={handleToggleCollapse}
      trigger={(
        <Button
          className="layout-sider-collapse-btn"
          type="link"
          icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
          onClick={handleToggleCollapse}
        />
      )}
      collapsedWidth={20}
      width={330}
    >
      <FixedComponent>
        <FixedComponent.Child isFixed className="sider-header">
          {header}
        </FixedComponent.Child>
        <FixedComponent.Child className="sider-content">
          {content}
        </FixedComponent.Child>
        <FixedComponent.Child isFixed className="sider-footer">
          {footer}
        </FixedComponent.Child>
      </FixedComponent>
    </Sider>
  );
};
