import dayjs from 'dayjs';
import { useMemo } from 'react';
import { DATE_STANDARD } from '../../../../constants/timeFormat';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { HeightService } from '../../../../helpers/height';
import { WeightService } from '../../../../helpers/weight';
import {
  Address,
  BehaviorChangeEnum,
  GenderEnum,
  GenderIdentityEnum,
  Phone,
  PhoneTypeEnum,
  RaceEnum,
  ResultSourceEnum,
  usePatientAssigneesUpdate,
  usePatientUpdate
} from '../../../../uc-api-sdk';
import { ClinicNameDisplayComponent } from '../../../../uiComponent/DisplayComponent/ClinicDisplayComponent/ClinicNameDisplayComponent';
import { InterventionBaseProps } from '../../../intervention/type';
import { DemographicsFormComponent, DemographicsFormValue } from '../../component/DemographicsFormComponent/DemographicsFormComponent';
import { PatientCommonCardComponent } from '../../component/PatientCardComponent/PatientCommonCardComponent';
import { PatientProfileDemographicsComponent } from '../../component/PatientProfileDemographicsComponent/PatientProfileDemographicsComponent';
import { useConfirmEligibilityEffect } from '../../../Insurance/hook/useConfirmEligibilityEffect';

export interface DemoGraphicsFormContainerProps extends InterventionBaseProps {
}

export const PatientProfileDemographicsContainer = ({
  isEditing,
  onEdit,
  onSubmit,
  onCancel,
}: DemoGraphicsFormContainerProps) => {
  const updatePatient = usePatientUpdate({});
  const updateAssignee = usePatientAssigneesUpdate({});
  const { info } = usePatientContext();
  const {
    id: memberId,
    patientInfoService,
    assignees,
    assigneesService,
    refetch,
  } = info || {};
  const confirmEligibilityEffect = useConfirmEligibilityEffect({
    initialPatientInfo: info?.patientInfo,
    initialInsuranceInfo: info?.patientInsurance,
  });

  const handleSubmit = async (formValues: DemographicsFormValue) => {
    await confirmEligibilityEffect(formValues);
    const {
      height,
      weight,
      ...values
    } = formValues;
    const isWeightChanged = patientInfoService?.isWeightChanged(weight || 0);
    const isHeightChanged = patientInfoService?.isHeightChanged(height || 0);

    const p1 = {
      number: values.phone,
      isSmartPhone: values.smartPhone,
      canUseForLogin: values.canUseForLogin,
      type: PhoneTypeEnum.MOBILE,
      countryCode: '1',
    };

    const p2 = {
      number: values.phone2,
      type: PhoneTypeEnum.HOME,
      countryCode: '1',
    };

    const phones: Phone[] = [p1, p2];
    const address: Address[] = [
      {
        type: 'MAIN',
        streetName: values.address,
        unit: values.address2,
        city: values.city,
        state: values.state,
        postCode: values.zipcode,
      },
    ];

    const req = updatePatient.send({
      params: {
        memberId: memberId || '',
        request: {
          ...values,
          phone: phones,
          address,
          editWeight: isWeightChanged,
          editHeight: isHeightChanged,
          profile: {
            ...values,
            race: values.race as RaceEnum,
            height: {
              value: Number(height),
              unit: HeightService.defaultHeightUnit,
            },
            weight: {
              value: Number(weight),
              unit: WeightService.defaultWeightUnit,
              ...(isWeightChanged ? { source: ResultSourceEnum.PROFILE } : {})
            },
            birthday: values.birthday?.format(DATE_STANDARD),
            gender: values.gender as GenderEnum,
            genderIdentity: values.genderIdentity as GenderIdentityEnum,
            behaviorChangeInfo: {
              behaviorChangeLevel: formValues.behaviorChange as BehaviorChangeEnum,
              isEdit: true,
            }
          },
        },
      },
    });

    if (values.assignedToRD || values.assignedToCA) {
      const assigneeReq = updateAssignee.send({
        params: {
          id: memberId || '',
          document: {
            assignedToRD: values.assignedToRD,
            assignedToCA: values.assignedToCA,
          }
        }
      });

      await ApiRequestHelper.tryCatch(assigneeReq, {
        error: 'Failed to update patient assignees.',
        success: undefined,
      });
    }

    await ApiRequestHelper.tryCatch(req, {
      error: 'Failed to update patient profile.',
      success: 'Patient profile has been updated.',
      onSuccess: () => {
        onSubmit?.();
        refetch?.();
      }
    });
  };

  const {
    email,
    medicalRecordId,
    loginId,
    clinicId,
    isTestUser,
    profile,
  } = info?.patientInfo || {};

  const {
    gender = '',
    firstName,
    lastName,
    nickName = '',
    birthday,
    race = '',
    languages,
    height,
    weight,
    doctorId,
    communitySupport,
    contactInfo,
    otherProviders,
    genderIdentity,
    patientTechLevel,
    behaviorChangeInfo,
  } = profile || {};

  const address = patientInfoService?.getAddress();

  const addressString = `${address?.address || ''} ${address?.address2 || ''},`
    + ` ${address?.city || ''}, ${address?.state || ''} ${address?.zipcode || ''}`;

  const initialValues = useMemo(() => ({
    address: address?.address,
    address2: address?.address2,
    city: address?.city,
    state: address?.state,
    zipcode: address?.zipcode,
    genderIdentity,
    gender,
    firstName,
    lastName,
    behaviorChange: behaviorChangeInfo?.behaviorChangeLevel,
    patientTechLevel,
    isTestUser,
    nickName,
    birthday: birthday ? dayjs(birthday) : undefined,
    race,
    languages,
    height: height?.value || 0,
    weight: Number(patientInfoService?.getWeightInLb()),
    phone: patientInfoService?.getMobilePhoneNumber(),
    phone2: patientInfoService?.getHomePhoneNumber(),
    medicalRecordId,
    email,
    smartPhone: patientInfoService?.getIsSmartPhone(),
    loginId,
    clinicId,
    canUseForLogin: patientInfoService?.getMobilePhoneOTP(),
    doctorId,
    assignedToCA: assignees?.assignedToCA,
    assignedToRD: assignees?.assignedToRD,
    communitySupport,
    contactInfo,
    otherProviders,
  }), [
    patientInfoService,
    weight,
    height,
    assignees,
    address,
  ]);

  return (
    <PatientCommonCardComponent
      isEditing={isEditing}
      onEdit={onEdit}
      updateInfo={info?.patientInfo || {}}
      content={(
        <PatientProfileDemographicsComponent
          fullName={patientInfoService?.getFullName()}
          nickName={nickName || undefined}
          dob={dayjs(patientInfoService?.getBirthday())}
          gender={patientInfoService?.getGender()}
          height={patientInfoService?.getHeightInFt()}
          weight={String(patientInfoService?.getWeightInLb())}
          race={patientInfoService?.getRace()}
          languages={languages || []}
          genderIdentity={genderIdentity}
          behavior={behaviorChangeInfo?.behaviorChangeLevel}
          techLevel={patientTechLevel}
          username={patientInfoService?.getUserName()}
          clinicName={(
            <ClinicNameDisplayComponent clinic={patientInfoService?.getClinic()} />
          )}
          providerName={patientInfoService?.getProviderName()}
          medicalId={patientInfoService?.getMedicalId()}
          assignedToCA={assigneesService?.getAssignedCaName()}
          assignedToRD={assigneesService?.getAssignedRdName()}
          mobilePhone={patientInfoService?.getMobilePhoneInfo()}
          otp={patientInfoService?.getMobilePhoneOTP()}
          smartPhone={patientInfoService?.getIsSmartPhone()}
          homePhone={patientInfoService?.getHomePhoneInfo()}
          email={patientInfoService?.getEmail()}
          address={addressString === ' , ,  ' ? undefined : addressString}
          socialSupport={communitySupport}
          contactInfo={contactInfo}
          otherProviders={otherProviders}
          memberId={memberId || ''}
        />
      )}
      title="Demographics"
      formContent={(
        <DemographicsFormComponent
          onCancel={onCancel}
          onSubmit={handleSubmit}
          isLoading={updatePatient.isLoading || updateAssignee.isLoading}
          initialValues={initialValues}
        />
      )}
    />
  );
};
