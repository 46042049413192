import { useInsuranceProviderGetEligibilityHistory } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { InsuranceEligibilityInfoComponent } from '../../component/InsuranceEligibilityInfoComponent/InsuranceEligibilityInfoComponent';

export interface InsuranceEligibilityInfoContainerProps {
  patientId: string;
}

export const InsuranceEligibilityInfoContainer = ({
  patientId,
}: InsuranceEligibilityInfoContainerProps) => {
  const historyInfo = useInsuranceProviderGetEligibilityHistory({
    params: { memberId: patientId },
    options: { sendOnMount: !!patientId }
  });
  return (
    <FetchComponent
      info={historyInfo}
    >
      {
        (info) => {
          const history = info?.rawValue?.data;
          return (
            <InsuranceEligibilityInfoComponent
              eligibilityReports={history}
            />
          );
        }
      }
    </FetchComponent>
  );
};
