import {
  useRef,
  useState,
  ReactNode,
  RefObject
} from 'react';
import Draggable, { DraggableEventHandler, DraggableProps } from 'react-draggable';
import classNames from 'classnames';

export interface DraggablePosition {
  x: number;
  y: number;
}

export interface DraggableBounds {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export interface DraggableComponentProps extends Partial<DraggableProps> {
  innerRef?: RefObject<HTMLDivElement>;
  disabled?: boolean;
  children: ReactNode;
  className?: string;
  draggedClassName?: string;
  draggingClassName?: string;
}

export const DraggableComponent = ({
  innerRef,
  children,
  onStop,
  className = '',
  draggingClassName = '',
  draggedClassName = '',
  ...props
}: DraggableComponentProps) => {
  const draggableRef = useRef<HTMLDivElement>(null);
  const [bounds, setBounds] = useState<DraggableBounds>({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0
  });

  const mergedRef = innerRef || draggableRef;

  const onStart: DraggableEventHandler = (_event, uiData) => {
    const targetRect = mergedRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }

    const document = window.document.documentElement;
    const { clientWidth, clientHeight } = document;

    const bounds: DraggableBounds = {
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    };
    setBounds(bounds);
  };

  const handleOnDragStop: DraggableEventHandler = (_event, uiData) => {
    onStop?.(_event, uiData);
  };

  return (
    <Draggable
      defaultClassName={classNames({
        'react-draggable': true,
        [className]: !!className,
      })}
      defaultClassNameDragging={classNames({
        'react-draggable-dragging': true,
        [draggingClassName]: !!draggingClassName,
      })}
      defaultClassNameDragged={classNames({
        'react-draggable-dragged': true,
        [draggedClassName]: !!draggedClassName,
      })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      bounds={bounds}
      nodeRef={mergedRef}
      onStart={onStart}
      onStop={handleOnDragStop}
    >
      <div ref={mergedRef}>
        {children}
      </div>
    </Draggable>
  );
};
