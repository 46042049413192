import { useMemo } from 'react';
import { FormOptions, useFormHookFactory } from '../../../../hooks/useFormHookFactory/useFormHookFactory';

export const useConsentForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    signatureMethod: {
      name: 'signatureMethod',
      label: ' ',
      placeholder: '',
    },
    initials: {
      name: 'initials',
      label: 'I understand this is legal representation of my signature.',
      placeholder: 'Patient\'s Initials',
    },
    signDate: {
      name: 'signDate',
      label: 'Consent form signed date',
      placeholder: '',
    },
    consentContent: {
      name: 'consentContent',
      label: 'Upload the signed consent form',
      placeholder: '',
    }
  }, options);

  const response = useMemo(() => ({
    ...factory,
  }), [
    factory,
  ]);

  return response;
};
