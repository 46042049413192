import { Space } from 'antd';
import React, { FC } from 'react';
import { PHONE_ICON } from '../../assets/commonIconInfo';
import { formatMultiplePhoneNumber, formatPhoneNumber } from '../../helpers/phone';
import DisplayOrEmptyComponent from '../DisplayComponent/DisplayOrEmptyComponent';
import Icon from '../Icon/Icon';

export interface PhoneComponentProps {
  value?: number | string;
  showIcon?: boolean;
  countryCode?: string,
  divider?: string[],
  showCountryCode?: boolean;
}

export const PhoneComponent: FC<PhoneComponentProps> = ({
  value,
  showIcon,
  countryCode,
  divider = ['', '-', '-'],
  showCountryCode = false,
}) => (
  <span data-testid="phone-component">
    <Space align="center">
      {showIcon && <Icon img={PHONE_ICON} />}
      <DisplayOrEmptyComponent<string> value={
        countryCode ? formatMultiplePhoneNumber(value, countryCode, showCountryCode)
          : formatPhoneNumber(value, divider)
      }
      />
    </Space>
  </span>
);

export default PhoneComponent;
