import { Drawer, DrawerProps } from 'antd';
import { PatientCreateContainer } from '../../features/patient/container/PatientCreateContainer/PatientCreateContainer';
import { useMixpanelContext } from '../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../contexts/MixpanelContext/MixpanelEvents';

export interface PatientCreateDrawerComponentProps extends DrawerProps {
  onSubmit?: () => void;
  onClose?: () => void;
  showEnrollmentDrawer?: boolean;
}

export const PatientCreateDrawerComponent = ({
  open,
  onClose,
  title = 'Create a patient',
  onSubmit,
  showEnrollmentDrawer = false,
}: PatientCreateDrawerComponentProps) => {
  const { send: sendMixpanel } = useMixpanelContext();

  const handleSubmit = () => {
    sendMixpanel({ event: MixpanelEvents.PatientCreate });
    onSubmit?.();
  };

  return (
    <Drawer
      placement="right"
      open={open}
      onClose={onClose}
      title={title}
      width={450}
      maskClosable={false}
      keyboard={false}
      destroyOnClose
    >
      <div className="PatientCreateFormComponent">
        <PatientCreateContainer
          onCancel={onClose}
          onSubmit={handleSubmit}
          showEnrollmentDrawer={showEnrollmentDrawer}
        />
      </div>
    </Drawer>
  );
};
