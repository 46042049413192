import { Form, FormItemProps, Input } from 'antd';
import { Icons } from '../../../../icons/Icons';

export interface LoginUsernameFormItemComponentProps extends FormItemProps {
  disabled?: boolean;
}

export const LoginUsernameFormItemComponent = ({
  name,
  label,
  rules,
  disabled,
}: LoginUsernameFormItemComponentProps) => (
  <Form.Item
    className="username-form-item"
    name={name}
    label={label}
    rules={rules}
  >
    <Input
      prefix={<Icons.FilledUser />}
      disabled={disabled}
      placeholder="Enter your username"
      data-testid="username"
    />
  </Form.Item>
);
