import { useUCVersion } from '../../hooks/useUCVersion';
import { VersionDisplayComponent } from './VersionDisplayComponent';
import './PortalVersionComponent.scss';

export const PortalVersionComponent = () => {
  const {
    isLoading,
    feVersion,
    beVersion,
  } = useUCVersion();

  if (isLoading) return null;

  return (
    <div className="portal-version-component">
      <VersionDisplayComponent
        versionName="Version Number"
        versionNumber={feVersion}
      />
      <VersionDisplayComponent
        versionName="Server Version Number"
        versionNumber={beVersion}
      />
    </div>
  );
};
