import { Badge, Modal } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload';
import { MessagePDFPreviewComponent } from './MessagePDFPreviewComponent';
import { MessageImagePreviewComponent } from './MessageImagePreviewComponent';

import './MessageUploadPreviewComponent.scss';
import { ClickableDiv } from '../../../../uiComponent/ClickableDiv/ClickableDiv';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';

export interface MessageUploadPreviewComponentProps {
  file?: RcFile;
  onRemove?: () => void;
  disabled?: boolean;
}

export const MessageUploadPreviewComponent = ({
  file,
  onRemove,
  disabled,
}: MessageUploadPreviewComponentProps) => {
  const {
    value: isOpenPreviewModal,
    setTrue: openPreviewModal,
    setFalse: closePreviewModal,
  } = useBoolean();
  const fileType = file?.type;

  if (!file || !fileType) return null;

  const renderPreview = () => {
    if (fileType?.includes('pdf')) {
      return <MessagePDFPreviewComponent file={file} />;
    }

    if (fileType?.includes('image')) {
      return (
        <ClickableDiv
          onClick={openPreviewModal}
          disabled={disabled}
        >
          <MessageImagePreviewComponent file={file} />
        </ClickableDiv>
      );
    }

    return null;
  };

  return (
    <div className="message-upload-preview-component">
      <Badge
        count={(
          <CloseCircleOutlined
            onClick={onRemove}
          />
        )}
      >
        {renderPreview()}
      </Badge>
      <Modal
        open={isOpenPreviewModal}
        onCancel={closePreviewModal}
        width="80vw"
        className="message-upload-preview-modal"
        footer={null}
        centered
        destroyOnClose
      >
        {renderPreview()}
      </Modal>
    </div>
  );
};
