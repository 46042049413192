/* eslint-disable no-inline-styles/no-inline-styles */
import { useMemo } from 'react';
import {
  CheckCircleFilled,
  ExclamationCircleFilled
} from '@ant-design/icons';
import { EligibilityStatusEnum } from '../../uc-api-sdk';

export interface InsuranceEligibilityStatusEnumComponentProps {
  value?: EligibilityStatusEnum | null;
  showIcon?: boolean;
  showIconOnly?: boolean;
}

export const getText = (value?: EligibilityStatusEnum | null) => {
  switch (value) {
    case EligibilityStatusEnum.ACTIVE:
      return 'Active';
    case EligibilityStatusEnum.INACTIVE:
      return 'Inactive';
    default:
      return '';
  }
};

export const InsuranceEligibilityStatusEnumComponent = ({
  value,
  showIcon = true,
  showIconOnly,
}: InsuranceEligibilityStatusEnumComponentProps) => {
  const {
    text,
    icon,
  } = useMemo(() => {
    switch (value) {
      case EligibilityStatusEnum.ACTIVE:
        return {
          text: getText(value),
          icon: (
            <CheckCircleFilled
              style={{
                color: 'var(--secondary-green)',
                marginRight: 5,
              }}
            />
          ),
        };
      case EligibilityStatusEnum.INACTIVE:
        return {
          text: getText(value),
          icon: (
            <ExclamationCircleFilled
              style={{
                color: 'var(--secondary-red)',
                marginRight: 8,
              }}
            />
          ),
        };
      default:
        return {
          text: '',
        };
    }
  }, [value]);

  if (value === EligibilityStatusEnum.FAILED) {
    return <>Failed</>;
  }

  if (!showIcon || !icon) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{text}</>;
  }

  if (showIcon && showIconOnly && icon) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{icon}</>;
  }

  return (
    <>
      {icon}
      {text}
    </>
  );
};
