import { Space } from 'antd';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { VisitMethodEnum, VisitTypeEnum } from '../../../../uc-api-sdk';
import { PatientNameAnchorComponent } from '../../../../uiComponent/PatientNameAnchorComponent/PatientNameAnchorComponent';
import { VisitIconComponent } from '../../../visit/component/VisitIconComponent/VisitIconComponent';
import { VisitTypeComponent, VisitTypeComponentProps } from '../../../visit/component/VisitTypeComponent/VisitTypeComponent';

export interface CalendarVisitTitleComponentProps {
  visitMethod?: VisitMethodEnum;
  clinicNumber?: string;
  visitType?: VisitTypeEnum;
  visitTypeType?: VisitTypeComponentProps['type'];
  patientName?: string;
  patientId?: string;
}

export const CalendarVisitTitleComponent = ({
  visitMethod,
  clinicNumber,
  visitType,
  visitTypeType = 'abbr',
  patientName,
  patientId,
}: CalendarVisitTitleComponentProps) => {
  const { isProvider } = useLoggedInUserFromContext();
  const shouldShowClinicNumber = !isProvider && !!clinicNumber;
  return (
    <Space>
      {visitMethod && (
        <VisitIconComponent value={visitMethod} />
      )}
      {shouldShowClinicNumber && <div>{clinicNumber}</div>}
      {visitType && <VisitTypeComponent value={visitType} type={visitTypeType} />}
      <div>
        {patientId
          ? <PatientNameAnchorComponent name={patientName || ''} patientId={patientId} />
          : patientName}
      </div>
    </Space>
  );
};
