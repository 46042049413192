import { ReactNode } from 'react';
import classNames from 'classnames';

import './PatientCommonCardTitleComponent.scss';

export interface PatientCommonCardTitleComponentProps {
  children: ReactNode;
  className?: string;
}

export const PatientCommonCardTitleComponent = ({
  children,
  className = '',
}: PatientCommonCardTitleComponentProps) => (
  <div
    className={classNames({
      flex: true,
      'ai-c': true,
      'patient-common-card-title': true,
      [className]: !!className,
    })}
  >
    {children}
  </div>
);
