import { useCallback, useEffect } from 'react';
import { useBoolean, useUpdateEffect } from 'usehooks-ts';
import EnvConfig from '../../configs/envConfig/envConfig';

const THRESHOLD = EnvConfig.idleTime * 1000;

export const useUserActive = () => {
  let timeoutTimer: NodeJS.Timeout | null = null;
  const { value: isActive, setTrue, setFalse } = useBoolean(true);

  const clearThisTimeout = () => {
    if (timeoutTimer !== null) {
      clearTimeout(timeoutTimer);
    }
  };

  const createTimeout = () => {
    clearThisTimeout();
    timeoutTimer = setTimeout(() => { setFalse(); }, THRESHOLD);
  };

  useEffect(() => {
    createTimeout();
    return clearThisTimeout;
  }, []);

  const handleEvent = useCallback(() => {
    setTrue();
    createTimeout();
  }, [setTrue, setFalse]);

  useEffect(() => {
    window.addEventListener('keydown', handleEvent);
    window.addEventListener('mousedown', handleEvent);
    window.addEventListener('mousemove', handleEvent);
    window.addEventListener('scroll', handleEvent);

    return () => {
      window.removeEventListener('keydown', handleEvent);
      window.removeEventListener('mousedown', handleEvent);
      window.removeEventListener('mousemove', handleEvent);
      window.removeEventListener('scroll', handleEvent);
    };
  }, [handleEvent]);

  useUpdateEffect(() => {
    console.log(isActive ? 'user active' : 'user inactive');
  }, [isActive]);

  return isActive;
};
