import { useMemo } from 'react';
import { WeightService } from '../../../helpers/weight';
import { FormOptions, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';

export const useTranscribingForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    firstName: {
      name: 'firstName',
      label: 'First Name',
      required: true,
    },
    lastName: {
      name: 'lastName',
      label: 'Last Name',
      required: true,
    },
    nickName: {
      name: 'nickName',
      label: 'Nickname',
    },
    dob: {
      name: 'dob',
      label: 'DOB',
      required: true,
    },
    providerId: {
      name: 'providerId',
      label: 'Provider Name',
      required: true,
    },
    medicalId: {
      name: 'medicalId',
      label: 'Medical Record Number (MRN)',
    },
    spokenLanguage: {
      name: 'spokenLanguage',
      label: 'Preferred Spoken Language',
      required: true,
    },
    weight: {
      name: 'weight',
      label: `Weight (${WeightService.weightUnitDisplay})`,
    },
    noWeight: {
      name: 'noWeight',
      label: ' ',
    },
    height: {
      name: 'height',
      label: 'Height',
      required: true,
    },
    mobilePhone: {
      name: 'mobilePhone',
      label: 'Mobile Phone',
      required: true,
    },
    homePhone: {
      name: 'homePhone',
      label: 'Home Phone',
    },
    email: {
      name: 'email',
      label: 'E-mail',
    },
  }, options);

  const res = useMemo(() => ({
    ...factory,
  }), [factory]);

  return res;
};
