import { YesNoRadioComponent } from '../../../../uiComponent/YesNoRadioComponent/YesNoRadioComponent';
import { InputType } from '../../../Input/types';

export interface HaveSmartPhoneRadioComponentProps extends InputType<boolean> {

}

export const HaveSmartPhoneRadioComponent = ({
  value,
  onChange,
}: HaveSmartPhoneRadioComponentProps) => (
  <div>
    <p>
      Does patient have a smartphone/tablet that can install UC App?
    </p>
    <YesNoRadioComponent
      value={value}
      onChange={onChange}
    />
  </div>
);
