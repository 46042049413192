import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { USA_DATE } from '../../constants/timeFormat';
import EmptyComponent from '../EmptyComponent/EmptyComponent';

export interface BirthdayComponentProps {
  value?: Dayjs;
  showHowOld?: boolean;
}

export const BirthdayComponent = ({
  value,
  showHowOld = true,
}: BirthdayComponentProps) => (
  value
    ? (
      <>
        {dayjs(value).format(USA_DATE)}
        {showHowOld && (
          <>
            {' ('}
            {dayjs().diff(dayjs(value), 'years')}
            {' yrs'}
            )
          </>
        )}
      </>
    ) : <EmptyComponent />
);
