import { isEmpty } from 'lodash';
import { Form, Input, InputNumber } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { useLifestyleForm } from './useLifestyleForm';
import { PhysicalActivityLevelInputComponent } from './PhysicalActivityLevelInputComponent';
import './LifestyleFormComponent.scss';
import { InterventionFormComponentProps } from '../../type';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { InterventionSubmitCancelButtons } from '../InterventionSubmitCancelButtons/InterventionSubmitCancelButtons';
import { YesNoRadioComponent } from '../../../../uiComponent/YesNoRadioComponent/YesNoRadioComponent';
import { SleepingRoutineSelectComponent } from './SleepingRoutineSelectComponent';
import { EatingRoutineSelectComponent } from './EatingRoutineSelectComponent';
import { FoodAccessSelectComponent } from './FoodAccessSelectComponent';
import {
  ActivityLevelEnum,
  EatingRoutineEnum,
  FoodAccessEnum,
  FrequencyUnitEnum,
  Note,
  SleepingRoutineEnum,
} from '../../../../uc-api-sdk';
import { FreqInputComponent } from '../../../../uiComponent/FreqInputComponent/FreqInputComponent';
import { PhysicalActivityLevelSelectComponent } from './PhysicalActivityLevelSelectComponent';
import { HistoryDrawerComponent } from '../../../../uiComponent/HistoryComponent/HistoryDrawerComponent';

export interface SubmitValue {
  activityLevel?: ActivityLevelEnum;
  activityDuration?: string;
  howOften?: {
    frequency?: string,
    frequencyType?: FrequencyUnitEnum,
  },
  sleepingRoutine?: SleepingRoutineEnum;
  eatingRoutine?: EatingRoutineEnum;
  foodAccessibility?: FoodAccessEnum;
  smoke?: boolean;
  howOftenSmoke?: {
    frequency?: string,
    frequencyType?: FrequencyUnitEnum,
  },
  smokeQuitYear?: string;
  drink?: boolean;
  howOftenDrink?: {
    frequency?: string,
    frequencyType?: FrequencyUnitEnum,
  },
  drinkQuantity?: string;
  drinkChoice?: string;
  drinkQuitYear?: string;
  lifestylePattern?: string;
}

export interface LifestyleFormComponentProps extends
  InterventionFormComponentProps<SubmitValue> {
  isLoading: boolean;
  lifeStyleNotes: Note[];
}
export const LifestyleFormComponent = ({
  initialValues,
  onSubmit,
  onValuesChange,
  isLoading,
  onCancel,
  lifeStyleNotes,
  formButtons = <InterventionSubmitCancelButtons onCancel={onCancel} />
}: LifestyleFormComponentProps) => {
  const hook = useLifestyleForm();
  const { form } = hook;

  const isValuesChanged = (initValues: Store | undefined, newValues: SubmitValue) => {
    if (isEmpty(newValues)) {
      return false;
    }
    return true;
  };
  const onFinishPreCheck = (value: SubmitValue) => {
    const fields = form.getFieldsValue(true, (meta) => meta.touched);
    if (isValuesChanged(initialValues, fields)) {
      onSubmit?.(value);
    } else {
      onCancel?.();
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onFinish={hook.handleSubmitAndReset(onFinishPreCheck)}
      disabled={hook.formDisabled || isLoading}
      layout="vertical"
      form={form}
      onValuesChange={onValuesChange}
    >
      <FormItem
        info={hook.getInfo('activityLevel')}
      >
        <PhysicalActivityLevelSelectComponent />
      </FormItem>
      <div className="mt10 flex js-b gap5 mr50">
        <FormItem info={hook.getInfo('howOften')}>
          <FreqInputComponent />
        </FormItem>
        <FormItem
          info={hook.getInfo('activityDuration')}
        >
          <PhysicalActivityLevelInputComponent />
        </FormItem>
      </div>
      <FormItem
        info={hook.getInfo('sleepingRoutine')}
      >
        <SleepingRoutineSelectComponent />
      </FormItem>
      <FormItem
        info={hook.getInfo('eatingRoutine')}
      >
        <EatingRoutineSelectComponent />
      </FormItem>
      <FormItem
        info={hook.getInfo('foodAccessibility')}
      >
        <FoodAccessSelectComponent />
      </FormItem>
      <div className="flex js-b gap5">
        <FormItem
          info={hook.getInfo('smoke')}
        >
          <YesNoRadioComponent />
        </FormItem>
        <FormItem
          shouldUpdate={hook.shouldUpdate(['smoke'])}
        >
          {
            ({ getFieldValue }) => {
              const smoke = getFieldValue('smoke');
              return smoke ? (
                <div className="flex js-b gap5 mr50">
                  <FormItem
                    info={hook.getInfo('howOftenSmoke')}
                  >
                    <FreqInputComponent />
                  </FormItem>
                </div>
              ) : (
                <FormItem
                  info={hook.getInfo('smokeQuitYear')}
                >
                  <InputNumber />
                </FormItem>
              );
            }
          }
        </FormItem>
      </div>
      <div className="flex js-b gap5">
        <FormItem
          className="lifestyle-drink"
          info={hook.getInfo('drink')}
        >
          <YesNoRadioComponent />
        </FormItem>
        <FormItem
          shouldUpdate={hook.shouldUpdate(['drink'])}
        >
          {
            ({ getFieldValue }) => {
              const drink = getFieldValue('drink');
              return drink ? (
                <div className="flex js-b gap5">
                  <FormItem
                    info={hook.getInfo('howOftenDrink')}
                  >
                    <FreqInputComponent />
                  </FormItem>
                  <FormItem
                    info={hook.getInfo('drinkQuantity')}
                  >
                    <Input placeholder="eg: 4 drinks" />
                  </FormItem>
                  <FormItem
                    info={hook.getInfo('drinkChoice')}
                  >
                    <Input placeholder="Type of alcohol" />
                  </FormItem>
                </div>
              ) : (
                <FormItem
                  info={hook.getInfo('drinkQuitYear')}
                >
                  <InputNumber />
                </FormItem>
              );
            }
          }
        </FormItem>
      </div>
      <FormItem
        info={hook.getInfo('lifestylePattern')}
      >
        <Input.TextArea rows={4} />
      </FormItem>
      {
        lifeStyleNotes && lifeStyleNotes.length !== 0 && (
          <HistoryDrawerComponent history={lifeStyleNotes} isLifeStyle />
        )
      }
      {formButtons}
    </Form>
  );
};
