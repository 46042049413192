const objectIdRegex = /[a-z0-9]{24}/;

export const getId = (
  id: string,
) => {
  if (!objectIdRegex.test(id) && window.atob(id).split(':')[1]) {
    return window.atob(id).split(':')[1];
  }
  return id;
};
