import { RoleTypeEnum } from '../../../uc-api-sdk';
import { AssigneeSelectContainer } from '../../../uiContainer/AssigneeSelectContainer/AssigneeSelectContainer';
import { AssigneeSelectComponent } from '../../AssigneeSelectComponent/AssigneeSelectComponent';

export interface AssigneeProps {
  clinicIds: string[];
  roles: RoleTypeEnum[];
  value?: string;
  onChange?: (v: string) => void;
  patientId?: string;
}

export const PatientAssigneeSelectComponent = ({
  clinicIds,
  roles,
  value,
  onChange,
  patientId,
}: AssigneeProps) => (
  <AssigneeSelectContainer
    roles={roles}
    clinicIds={clinicIds || undefined}
    internalEmployeeForPatientId={patientId}
    needToFilter={false}
  >
    {
      ({
        assigneeDataList, isLoading: isLoadingAssigneeData,
      }) => (
        <AssigneeSelectComponent
          value={value}
          onChange={(v) => onChange?.(v as string)}
          assigneeDataList={assigneeDataList}
          loading={isLoadingAssigneeData}
          showSearch
        />
      )
    }
  </AssigneeSelectContainer>
);
