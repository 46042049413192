import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { InsuranceEligibilityReportComponent } from '../../InsuranceEligibilityReportComponent/InsuranceEligibilityReportComponent';
import { EligibilityReport, EligibilityStatusEnum } from '../../../../../uc-api-sdk';
import { ModalWidth880 } from '../../../../../constants/modal';
import { HTMLDisplayComponent } from '../../../../../uiComponent/HTMLDisplayComponent/HTMLDisplayComponent';

import './WaystarResultModalComponent.scss';

export interface WaystarResultModalComponentProps {
  isOpen?: boolean;
  eligibilityStatus?: EligibilityStatusEnum;
  inquiryInfo?: EligibilityReport;
  report?: string;
  onDone?: () => void;
}

export const WaystarResultModalComponent = ({
  isOpen = true,
  eligibilityStatus,
  inquiryInfo,
  report,
  onDone,
}: WaystarResultModalComponentProps) => (
  <Modal
    open={isOpen}
    width={ModalWidth880}
    className="waystar-result-modal"
    footer={null}
    closable={false}
    maskClosable={false}
    destroyOnClose
  >
    <div className="waystar-result-modal__content">
      <InsuranceEligibilityReportComponent
        status={eligibilityStatus}
        inquiryInfo={inquiryInfo}
        title={(
          eligibilityStatus === EligibilityStatusEnum.FAILED
            ? (
              <div className="flex ai-c">
                <ExclamationCircleFilled />
                Verification failed
              </div>
            )
            : null
        )}
      >
        <HTMLDisplayComponent
          htmlString={report}
        />
      </InsuranceEligibilityReportComponent>
    </div>
    <div className="waystar-result-modal__footer">
      <div>
        Eligibility status will be updated to patient's profile
      </div>
      <Button
        type="primary"
        onClick={onDone}
      >
        Done
      </Button>
    </div>
  </Modal>
);
