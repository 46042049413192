import React from 'react';
import { Drawer } from 'antd';
import { SmallDrawerWidth } from '../../../../constants/drawer';
import CreateTicketContainer from '../../container/CreateTicketContainer/CreateTicketContainer';

export interface CreateTicketDrawerComponentProps {
  patientId: string;
  clinicId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const CreateTicketDrawerComponent = ({
  patientId,
  clinicId,
  isOpen,
  onClose,
}: CreateTicketDrawerComponentProps) => (
  <Drawer width={SmallDrawerWidth} open={isOpen} closable onClose={onClose}>
    <CreateTicketContainer patientId={patientId} clinicId={clinicId} onSuccess={onClose} />
  </Drawer>
);
