import { WeightService } from '../../../helpers/weight';

// eslint-disable-next-line import/prefer-default-export
export class HSHelper {
  public static kgToLbs(value: number) {
    return +(value * 2.2046).toFixed(1);
  }

  public static getLbsUnit() {
    return WeightService.weightUnitDisplay;
  }
}
