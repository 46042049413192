import { Row, Col, Space } from 'antd';
import { ReactNode } from 'react';
import USStateSelectComponent from '../../selectComponents/USStatesSelectComponent/USStatesSelectComponent';
import { AddressAptInputComponent } from '../Address/AddressAptInputComponent/AddressAptInputComponent';
import { AddressCityInputComponent } from '../Address/AddressCityInputComponent/AddressCityInputComponent';
import { AddressInputComponent } from '../Address/AddressInputComponent/AddressInputComponent';
import ZipCodeInputComponent from '../ZipCodeInputComponent/ZipCodeInputComponent';
import FormItem from '../FormItem/FormItem';
import { CopyAddressButtonComponent } from '../CopyAddressButtonComponent/CopyAddressButtonComponent';
import { useAddressForm } from '../../hooks/formHook/useAddressForm';

type AddressFormInfo = {
  [x in 'address' | 'address2' | 'city' | 'state' | 'zipcode']: {
    name: string;
    label: ReactNode;
  };
}

export interface AddressFormValues {
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
}

export const defaultAddressFormInfo: AddressFormInfo = {
  address: {
    name: 'address',
    label: 'Address',
  },
  address2: {
    name: 'address2',
    label: 'Apt, Suite, etc.',
  },
  city: {
    name: 'city',
    label: 'City',
  },
  state: {
    name: 'state',
    label: 'State',
  },
  zipcode: {
    name: 'zipcode',
    label: 'Zip Code',
  },
};

export interface AddressFormItemComponentProps {
  formInfo?: AddressFormInfo;
  required?: boolean;
  showCopyButton?: boolean;
}

export const AddressFormItemComponent = ({
  formInfo,
  required,
  showCopyButton,
}: AddressFormItemComponentProps) => {
  const form = useAddressForm();
  return (
    <>
      <Row>
        <Col span={14}>
          <Space.Compact
            block
            className="compact"
          >
            <FormItem
              info={formInfo?.address || defaultAddressFormInfo.address}
              className="w80"
              colon={false}
              required={required}
            >
              <AddressInputComponent />
            </FormItem>
            <FormItem
              info={formInfo?.address2 || defaultAddressFormInfo.address2}
              className="w20"
              colon={false}
            >
              <AddressAptInputComponent />
            </FormItem>
          </Space.Compact>
        </Col>
      </Row>
      <Row>
        <Col span={7}>
          <FormItem
            info={formInfo?.city || defaultAddressFormInfo.city}
            required={required}
          >
            <AddressCityInputComponent />
          </FormItem>
        </Col>
        <Col offset={1} span={6}>
          <Space.Compact
            block
            className="compact"
          >
            <FormItem
              info={formInfo?.state || defaultAddressFormInfo.state}
              className="w100"
              colon={false}
              required={required}
            >
              <USStateSelectComponent />
            </FormItem>
            <FormItem
              info={formInfo?.zipcode || defaultAddressFormInfo.zipcode}
              colon={false}
              required={required}
            >
              <ZipCodeInputComponent />
            </FormItem>
          </Space.Compact>
        </Col>
        {
          showCopyButton
          && (
            <FormItem
              noStyle
              shouldUpdate={form.shouldUpdateCopyText()}
            >
              {
                ({ getFieldsValue }) => (
                  <Col offset={1}>
                    <FormItem label=" ">
                      <CopyAddressButtonComponent
                        value={getFieldsValue()}
                      />
                    </FormItem>
                  </Col>
                )
              }
            </FormItem>
          )
        }
      </Row>
    </>
  );
};
