import { join, map } from 'lodash';
import {
  Address, Nullable, Phone, RoleTypeEnum
} from '../../uc-api-sdk';
import { getFullRoleName } from '../../enumComponent/RoleTypeEnumComponent/RoleTypeEnumComponent';

export interface AccountNames {
  firstName?: string | null;
  nickName?: string | null;
  lastName?: string | null;
  title?: string | null;
  roles?: RoleTypeEnum[] | null;
}

export class AccountHelper {
  public static getFullName<T extends AccountNames>(data?: T | null) {
    if (data?.firstName || data?.lastName) {
      return `${data?.firstName} ${data?.lastName}`.trim();
    }
    return undefined;
  }

  public static getLastNameAndFirstName<T extends AccountNames>(data?: T | null) {
    if (data?.firstName || data?.lastName) {
      return `${data?.lastName}, ${data?.firstName}`.trim();
    }
    return undefined;
  }

  public static getFullNameNickname<T extends AccountNames>(data?: T | null) {
    const nickName = data?.nickName ? ` (${data?.nickName})` : '';
    if (data?.firstName || data?.lastName) {
      return `${data?.firstName}${nickName} ${data?.lastName}`.trim();
    }
    return undefined;
  }

  public static getFullNameWithTitle<T extends AccountNames>(
    data?: T | null,
    options?: {
      delimiter?: string;
      withNickname?: boolean;
    },
  ) {
    const {
      delimiter = ', ',
      withNickname = false,
    } = options || {};
    const fullName = withNickname ? this.getFullNameNickname(data) : this.getFullName(data);
    const title = data?.title || join(map(data?.roles, getFullRoleName), delimiter);
    if (fullName) {
      return `${fullName}${title ? `, ${title}` : ''}`;
    }
    return undefined;
  }

  public static getMedicalOrgFullName(
    alias?: string,
    orgNumber?: string | null
  ): string {
    if (orgNumber === null || orgNumber === undefined) {
      return alias ?? '';
    }
    return `${orgNumber} ${alias ?? ''}`.trim();
  }

  public static getPhoneNumbers(
    phones: Nullable<Phone[]> | undefined
  ): string[] {
    if (!phones || phones.length === 0) {
      return [];
    }

    return phones.map(phone => {
      const countryCode = phone.countryCode ?? '';
      const areaCode = phone.areaCode ?? '';
      const number = phone.number ?? '';
      return `${countryCode}${areaCode}${number}`.trim();
    });
  }

  public static getAddresses(
    addresses: Nullable<Address[]> | undefined
  ): string[] {
    if (!addresses || addresses.length === 0) {
      return [];
    }

    return addresses.map(address => {
      const parts: string[] = [];
      if (address.streetName) parts.push(address.streetName);
      if (address.city) parts.push(address.city);
      if (address.state) parts.push(address.state);
      if (address.postCode) parts.push(address.postCode);

      return parts.join(', ');
    });
  }
}
