import dayjs from 'dayjs';
import { useLocalStorage } from 'usehooks-ts';
import { StorageKeyEnum } from '..';

export interface DoNotShowUntil {
  value: boolean;
  exp?: number;
}

export const useDoNotShowUntil = <T extends StorageKeyEnum>(
  key: T,
  exp = dayjs().endOf('d').valueOf(),
) => {
  const [value, setValue] = useLocalStorage<DoNotShowUntil | undefined>(key, undefined);

  const getDoNotShowUntil = () => {
    const isExpired = (value?.exp === undefined) || (value.exp <= dayjs().valueOf());
    if (!value?.value || isExpired) {
      setValue({ value: false, exp: undefined });
      return false;
    }
    return value.value;
  };

  const setDoNotShowUntil = (value?: boolean) => {
    if (value === undefined) return;
    setValue({
      value,
      exp,
    });
  };

  return {
    getDoNotShowUntil,
    setDoNotShowUntil,
  };
};
