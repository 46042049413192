import { message } from 'antd';
import { ReactNode } from 'react';
import { ClickableDiv } from '../ClickableDiv/ClickableDiv';

export interface ClipboardButtonComponentProps {
  children: ReactNode;
  value?: string;
  copySuccessMessage?: string;
  onCopy?: () => void;
  disabled?: boolean;
}

export const ClipboardButtonComponent = ({
  children,
  value,
  copySuccessMessage = 'Copied to clipboard',
  onCopy,
  disabled = false,
}: ClipboardButtonComponentProps) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(value || '');
    if (copySuccessMessage) message.success(copySuccessMessage, 3);
    onCopy?.();
  };
  return (
    <ClickableDiv onClick={handleCopy} disabled={disabled}>
      {children}
    </ClickableDiv>
  );
};
