import classNames from 'classnames';
import React from 'react';
import './TitleContentTextComponent.scss';

export interface TitleContentTextComponentProps {
  title?: string;
  content?: React.ReactNode;
}

export const TitleContentTextComponent = ({
  title,
  content,
}: TitleContentTextComponentProps) => (
  <div className="mb20">
    <div
      className={classNames({
        'title-content-text-title': true,
      })}
    >
      {title}
    </div>
    <div
      className={classNames({
        'title-content-text-content': true,
      })}
    >
      {content}
    </div>
  </div>
);
