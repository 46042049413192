import { Space } from 'antd';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { usePatientGetPatientBaseline, usePatientUpdatePatientBaseline } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { UpdatedByAtComponent } from '../../../../user/component/UpdatedByAtComponent/UpdatedByAtComponent';
import { POBaselineFormComponent, POBaselineFormComponentProps, SubmitValue } from '../../component/POBaselineFormComponent/POBaselineFormComponent';

export interface POBaselineContainerProps {
  patientId: string;
}

export const POBaselineContainer = ({
  patientId,
}: POBaselineContainerProps) => {
  const baselineInfo = usePatientGetPatientBaseline({
    params: {
      memberId: patientId,
    },
  });

  const updateBaseline = usePatientUpdatePatientBaseline({});

  const handleSubmit = async (value: SubmitValue) => {
    const req = updateBaseline.send({
      params: {
        memberId: patientId,
        request: {
          bloodOxygen: value.baseline,
        },
      },
    });
    await ApiRequestHelper.tryCatch(req, {
      success: 'Updated baseline',
      error: 'Failed to submit baseline!',
    });
    baselineInfo.refetch();
  };

  const initialValues: POBaselineFormComponentProps['initialValues'] = {
    baseline: baselineInfo.data?.data?.bloodOxygen ?? undefined,
  };

  return (
    <FetchComponent info={baselineInfo}>
      <Space size="large" align="start">
        <POBaselineFormComponent
          onSubmit={handleSubmit}
          initialValues={initialValues}
          isLoading={updateBaseline.isLoading}
        />
        <div className="pt30">
          <UpdatedByAtComponent
            info={{ updatedAt: baselineInfo.data?.data?.updatedAt }}
            prefix="Last updated:"
          />
        </div>
      </Space>
    </FetchComponent>
  );
};
