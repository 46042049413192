import DisplayOrEmptyComponent from '../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

import './MessageVitalSingleDisplayValueComponent.scss';

export interface MessageVitalSingleDisplayValueComponentProps {
  value: string;
  unit: string;
}

export const MessageVitalSingleDisplayValueComponent = ({
  value,
  unit,
}: MessageVitalSingleDisplayValueComponentProps) => (
  <div className="message-vital-single-value">
    <div className="message-vital-single-value__value-and-unit flex jc-c ai-c">
      <span className="message-vital-single-value__value">
        <DisplayOrEmptyComponent value={value} />
      </span>
      <span className="message-vital-single-value__unit">
        <DisplayOrEmptyComponent value={unit} />
      </span>
    </div>
  </div>
);
