import parser from 'html-react-parser';
import { useMemo } from 'react';

export interface HTMLDisplayComponentProps {
  htmlString?: string | null;
  nowrap?: boolean;
}

export const HTMLDisplayComponent = ({
  htmlString,
  nowrap,
}: HTMLDisplayComponentProps) => {
  const htmlDisplay = useMemo(() => {
    if (!htmlString) {
      return '';
    }
    try {
      return parser(htmlString);
    } catch (e) {
      console.error('Parse error: ', e);
      return '';
    }
  }, [htmlString]);

  if (nowrap) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{htmlDisplay}</>; // not to break text flow
  }

  return (
    <div className="html-display">
      {htmlDisplay}
    </div>
  );
};
