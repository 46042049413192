/* eslint-disable import/prefer-default-export */
import { useCallback, useMemo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useNavigate } from 'react-router';
import {
  useParams,
  useLocation,
  useSearchParams,
  URLSearchParamsInit,
} from 'react-router-dom';
import queryString from 'query-string';
import useChangePage from '../useChangePage/useChangePage';

export function useRouter() {
  const params = useParams();
  const location = useLocation();
  const changePage = useChangePage();
  const navigate = useNavigate();
  const [, setSearchParamsBase] = useSearchParams();

  const setHash = useCallback((hash?: string) => {
    navigate({
      pathname: location.pathname,
      hash,
    });
  }, [navigate, location.pathname]);

  const setSearchParams = useCallback(<T>(params: T) => {
    setSearchParamsBase(params as URLSearchParamsInit);
  }, [setSearchParamsBase]);

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => ({
    pathname: location.pathname,
    // Merge params and parsed query string into single "query" object
    // so that they can be used interchangeably.
    // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
    query: {
      ...queryString.parse(location.search), // Convert string to object
      ...params,
    },
    location,
    changePage,
    setSearchParams,
    setHash,
    navigate,
  }), [params, location, changePage]);
}
