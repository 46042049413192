import { useState } from 'react';
import { trim } from 'lodash';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { OrderCheckboxComponent, OrderCheckboxComponentProps } from '../OrderCheckboxComponent/OrderCheckboxComponent';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import { EllipsisComponent } from '../../../../uiComponent/EllipsisComponent/EllipsisComponent';
import { OrderReasonModalComponent, OrderReasonModalComponentProps } from '../OrderReasonModalComponent/OrderReasonModalComponent';

import './DeviceReplacementCheckboxComponent.scss';
import { useModalConfigContext } from '../../../../contexts/ModalConfigContext/ModalConfigContext';

export interface DeviceReplacementValue {
  isChecked?: boolean;
  reason?: string;
}

export interface DeviceReplacementCheckboxComponentProps {
  value?: DeviceReplacementValue;
  onChange?: (value?: DeviceReplacementValue) => void;
  children: React.ReactNode;
  onClose?: () => void;
  extraContent?: React.ReactNode;
  showExtraContent?: boolean;
}

export const DeviceReplacementCheckboxComponent = ({
  value,
  onChange,
  children,
  onClose,
  extraContent = null,
  showExtraContent = true,
}: DeviceReplacementCheckboxComponentProps) => {
  const {
    isChecked,
    reason: initialReason,
  } = value || {};
  const {
    isOpen,
    open,
    close,
  } = useOpen();
  const {
    getContainer: getModalContainer,
  } = useModalConfigContext();
  const [tempReason, setTempReason] = useState(initialReason);

  const handleOnChange: OrderCheckboxComponentProps['onChange'] = (
    checked,
  ) => {
    const isChecked = !!checked;
    if (isChecked) {
      open();
    }
    onChange?.({ isChecked });
  };

  const handleOnClose = () => {
    close();
    onClose?.();
  };

  const handleOnCancelReason = () => {
    if (isChecked && !tempReason) {
      // only reset the value if it's initial change
      onChange?.({ isChecked: false });
    } else {
      onChange?.({ isChecked, reason: tempReason });
    }
    handleOnClose();
  };

  const handleOnSubmitReason: OrderReasonModalComponentProps['onSubmit'] = (
    value,
  ) => {
    const replacementReason = trim(value.replacementReason);
    if (!replacementReason) {
      onChange?.({ isChecked: false });
    } else {
      onChange?.({
        isChecked: true,
        reason: replacementReason
      });
    }
    handleOnClose();
    setTempReason(replacementReason);
  };

  return (
    <div className="device-replacement-checkbox">
      <OrderCheckboxComponent
        value={isChecked}
        onChange={handleOnChange}
      >
        <div className="flex ai-c w100">
          {children}
          {
            (isChecked && tempReason)
            && (
              <div
                className="no-pointer order-info-content device-replacement-reason"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                aria-hidden
              >
                <EllipsisComponent>
                  {tempReason}
                </EllipsisComponent>
                <LinkButton
                  onClick={open}
                  useBlueColor
                >
                  Edit
                </LinkButton>
              </div>
            )
          }
          {
            showExtraContent
            && (
              <div className="device-replacement-extra-content">
                {extraContent}
              </div>
            )
          }
        </div>
      </OrderCheckboxComponent>
      <OrderReasonModalComponent
        value={{ replacementReason: tempReason }}
        open={isOpen}
        onSubmit={handleOnSubmitReason}
        onCancel={handleOnCancelReason}
        getContainer={getModalContainer}
      />
    </div>
  );
};
