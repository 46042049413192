import dayjs from 'dayjs';
import React from 'react';
import { OBV_DAY } from '../../../../constants/timeFormat';
import './BigCalendarHeaderComponent.scss';

export interface BigCalendarHeaderComponentProps {
  date: Date;
}

export const BigCalendarHeaderComponent = ({
  date,
}: BigCalendarHeaderComponentProps) => {
  const d = dayjs(date);
  const day = d.get('date');
  const abbr = d.format(OBV_DAY);

  return (
    <div className="big-cal-header">
      <span className="abbr-date">{abbr}</span>
      <span className="day">{day}</span>
    </div>
  );
};
