import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { CuffSizeEnum, DeviceModelEnum } from '../../../../uc-api-sdk';
import { DeviceCuffSizeComponent } from '../../../device/component/DeviceCuffSizeComponent/DeviceCuffSizeComponent';
import { BPCuffSizeModalComponent } from '../../../enrollment/component/BPCuffSizeModalComponent/BPCuffSizeModalComponent';
import { BPDeviceCuffSizeComponentProps } from '../../../enrollment/component/BPDeviceCuffSizeComponent/BPDeviceCuffSizeComponent';
import { OrderType } from '../../type';
import { DeviceReplacementCheckboxComponent, DeviceReplacementCheckboxComponentProps, DeviceReplacementValue } from '../DeviceReplacementCheckboxComponent/DeviceReplacementCheckboxComponent';
import { OrderInfoComponent } from '../OrderInfoComponent/OrderInfoComponent';

interface BPEaseReplacementValue extends DeviceReplacementValue {
  cuffSize?: CuffSizeEnum;
}

export interface BPEaseReplacementComponentProps
  extends Omit<DeviceReplacementCheckboxComponentProps, 'children'> {
  value?: BPEaseReplacementValue;
  onChange?: (value?: BPEaseReplacementValue) => void;
}

export const BPEaseReplacementComponent = ({
  value,
  onChange,
}: BPEaseReplacementComponentProps) => {
  const {
    isChecked = false,
    reason,
    cuffSize,
  } = value || {};
  const {
    isOpen,
    open,
    close,
  } = useOpen();

  const handleOnChange: DeviceReplacementCheckboxComponentProps['onChange'] = (
    value
  ) => {
    const {
      isChecked = false,
      reason: newReason,
    } = value || {};
    if (isChecked && !reason && newReason) {
      // only automatically open the modal when it's initial change
      open();
    }
    if (!isChecked) {
      onChange?.({ isChecked });
      return;
    }
    onChange?.({
      isChecked,
      reason: newReason,
      cuffSize
    });
  };

  const handleOnCuffSizeSubmit: BPDeviceCuffSizeComponentProps['onChange'] = (
    value,
  ) => {
    onChange?.({ isChecked: true, reason, cuffSize: value });
    close();
  };

  return (
    <>
      <DeviceReplacementCheckboxComponent
        value={{
          isChecked,
          reason,
        }}
        onChange={handleOnChange}
        showExtraContent={!!reason}
        extraContent={(
          <DeviceCuffSizeComponent
            value={cuffSize}
            onChange={handleOnCuffSizeSubmit}
            closeable={false}
          />
        )}
      >
        <OrderInfoComponent
          type={OrderType.DEVICES}
          deviceModel={DeviceModelEnum.BP3L}
        />
      </DeviceReplacementCheckboxComponent>
      <BPCuffSizeModalComponent
        open={isOpen}
        value={cuffSize}
        onChange={handleOnCuffSizeSubmit}
        onCancel={close}
        closeable={false}
      />
    </>
  );
};
