import {
  Button, Col, Form, Row
} from 'antd';
import moment, { Moment } from 'moment';
import { FC, useEffect, useState } from 'react';
import { useMedicationForm } from '../../../../hooks/useMedicationForm/useMedicationForm';
import { MedicationTypeSelectComponent as FormItemMedicationTypeInput } from '../../../../selectComponents/MedicationTypeSelectComponent/MedicationTypeSelectComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { MedicationDeleteConfirmationModal } from '../../../../uiComponent/Medication/MedicationDeleteConfirmationModal';
import { MedicationDosageInputComponent } from '../../../../uiComponent/Medication/MedicationDosageInput';
import { MedicationEndDateInputComponent } from '../../../../uiComponent/Medication/MedicationEndDateInputComponent';
import { MedicationHintComponent } from '../../../../uiComponent/Medication/MedicationHintComponent';
import { MedicationInstructionInputComponent } from '../../../../uiComponent/Medication/MedicationInstructionInputComponent/MedicationInstructionInputComponent';
import { MedicationStartDateInputComponent } from '../../../../uiComponent/Medication/MedicationStartDateInputComponent';
import { MedicationStatusSelectComponent } from '../../../../uiComponent/Medication/MedicationStatusSelectComponent';
import { PrescribedCheckboxComponent } from '../../../../uiComponent/Medication/PrescribedCheckboxComponent';
import { MeidcationNameContainer } from '../../container/MedicationNameContainer';
import { MedicationType } from '../../type/type';
import './AddEditMedicationFormComponent.scss';

export interface AddEditMedicationFormComponentProps {
  initialValues?: MedicationType,
  onSubmit?: (v: MedicationType) => void,
  submitText?: string;
}

export const AddEditMedicationFormComponent:
  FC<AddEditMedicationFormComponentProps> = ({
    initialValues,
    onSubmit,
    submitText = 'Add',
  }) => {
    const form = Form.useForm()[0];
    const hook = useMedicationForm({ form });
    const [startDate, setStartDate] = useState<Moment |
      string | null>(initialValues?.startDate ? moment(initialValues?.startDate) : null);
    const [endDate, setEndDate] = useState<Moment | string | null>(
      initialValues?.endDate ? moment(initialValues?.endDate) : null,
    );

    const onStartDateChange = (value: Moment | null) => {
      form.setFieldsValue({ startDate: value });
      setStartDate(value);
    };

    const onEndDateChange = (value: Moment | null) => {
      form.setFieldsValue({ discontinueDate: value });
      setEndDate(value);
    };

    const handleSubmit = (v: MedicationType) => {
      onSubmit?.(v);
      form.resetFields();
    };

    useEffect(() => {
      // this fixes the issue when the ticket is updated
      // but the edit form still contains the old values
      if (initialValues) {
        form.setFieldsValue(initialValues);
      }
    }, [form, initialValues]);

    return (
      <Form
        id="medicationObjectForm"
        form={form}
        onFinish={hook.handleSubmitAndReset(handleSubmit)}
        initialValues={initialValues}
        layout="vertical"
      >
        <Row gutter={15}>
          <Col span={12}>
            <FormItem
              info={hook.getInfo('name')}
              className="required-field"
              required
            >
              <MeidcationNameContainer />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              info={hook.getInfo('type')}
              className="required-field"
              required
            >
              <FormItemMedicationTypeInput />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={12}>
            <FormItem
              info={hook.getInfo('status')}
            >
              <MedicationStatusSelectComponent />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              info={hook.getInfo('dosage')}
              required
              className="required-field"
            >
              <MedicationDosageInputComponent />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={12}>
            <FormItem
              info={hook.getInfo('startDate')}
            >
              <MedicationStartDateInputComponent
                onChange={onStartDateChange}
                disabledAfterDate={endDate}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              info={hook.getInfo('endDate')}
            >
              <MedicationEndDateInputComponent
                disabedBeforeDate={startDate}
                onChange={onEndDateChange}
              />
            </FormItem>
          </Col>
        </Row>
        <FormItem
          name={hook.getName('otherMD')}
        >
          <PrescribedCheckboxComponent />
        </FormItem>
        <FormItem
          info={hook.getInfo('instruction')}
          className="medicationInstructions"
        >
          <MedicationInstructionInputComponent />
        </FormItem>
        <FormItem>
          <MedicationHintComponent date={endDate} />
        </FormItem>

        <FormItem className="">
          <Button key="submit" htmlType="submit" type="primary">{submitText}</Button>
          {initialValues?.id && <MedicationDeleteConfirmationModal medId={initialValues?.id} />}
        </FormItem>
      </Form>
    );
  };
