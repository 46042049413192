/* eslint-disable no-shadow */
export interface RESTfulResponse<TData> {
  code: number;
  data: TData | null;
  msg: string | null;
}

export interface TimeRange {
  gte: string;
  lte: string;
}

export enum Direction {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface Sort {
  direction: Direction;
  property: string;
}

export interface In<T> {
  in: T[];
}

export interface Page<T> {
  totalPage: number;
  totalSize: number;
  content: T;
}

export interface PageReqBody {
  pageInfo?: {
    sort?: Sort[],
    page?: number;
    size?: number;
    pagination?: boolean;
  }
}

export interface ReqFilterByKeyValues {
  keyValues: Record<string, string>
}

export interface ReqFilterByKeysAndValue {
  keys: Array<string>,
  value: string,
}

export interface ReqFilterByKeyAndValues {
  key: string,
  values: string[],
}

// all fields of a type must exist
type FilterCombo<T> = (
  T | { [key in keyof T]?: never }
);

export interface ReqFilters extends Record<string, unknown> {
  '$or'?: (
    FilterCombo<ReqFilterByKeyValues>
    | FilterCombo<ReqFilterByKeysAndValue>
    | FilterCombo<ReqFilterByKeyAndValues>
  )
}
