import React from 'react';
import { Outlet } from 'react-router-dom';
import { ErrorBoundaryPageComponent } from '../../../../uiComponent/ErrorBoundaryComponent/ErrorBoundaryPageComponent';

export const InternalToolLayoutComponent = () => (
  <ErrorBoundaryPageComponent page="INTERNAL_TOOL_PAGE">
    <React.Suspense fallback="loading...">
      <Outlet />
    </React.Suspense>
  </ErrorBoundaryPageComponent>
);
