// eslint-disable-next-line import/prefer-default-export
export class TMHelper {
  public static celsiusToFahrenheit(value: number) {
    return +((value * 9) / 5 + 32).toFixed(1);
  }

  public static getFahrenheitUnit() {
    return 'F';
  }

  public static getValueUnitText(value: number, unit: string) {
    return `${value} ${unit}`;
  }
}
