import React from 'react';
import { PatientSourceEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface PatientSourceEnumComponentProps {
  value?: PatientSourceEnum;
}

export const PatientSourceEnumComponent = ({ value }: PatientSourceEnumComponentProps) => {
  const getFullName = () => {
    switch (value) {
      case PatientSourceEnum.BATCH_CREATED: return 'Batch-created';
      case PatientSourceEnum.MANUALLY_CREATED: return 'Manually-created';
      case PatientSourceEnum.MD_MA_REFERRED: return 'MD/MA Referred';
      case PatientSourceEnum.SCREENED_FROM_EHR_VISITS: return 'Screened from EHR Visits';
      case PatientSourceEnum.EHR_INTEGRATION_CREATED: return 'EHR Integration-created';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  return (<>{getFullName()}</>);
};
