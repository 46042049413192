import { InputTypeRequired } from '../../../Input/types';
import { RadioGroupComponent, RadioGroupComponentProps } from '../../../../uiComponent/RadioGroupComponent/RadioGroupComponent';
import { CuffSizeEnum } from '../../../../uc-api-sdk';
import { CuffSizeEnumComponent } from '../../../../enumComponent/CuffSizeEnumComponent/CuffSizeEnumComponent';

const CuffSizeOptions: RadioGroupComponentProps<CuffSizeEnum>['options'] = [
  {
    value: CuffSizeEnum.STANDARD,
    label: <CuffSizeEnumComponent value={CuffSizeEnum.STANDARD} />,
  },
  {
    value: CuffSizeEnum.MINI,
    label: <CuffSizeEnumComponent value={CuffSizeEnum.MINI} />,
  },
  {
    value: CuffSizeEnum.LARGE,
    label: <CuffSizeEnumComponent value={CuffSizeEnum.LARGE} />,
  },
  {
    value: CuffSizeEnum.EXTRA_LARGE,
    label: <CuffSizeEnumComponent value={CuffSizeEnum.EXTRA_LARGE} />,
  },
];

export interface BPDeviceCuffSizeComponentProps extends Partial<InputTypeRequired<CuffSizeEnum>> {
  noDefault?: boolean;
}

export const BPDeviceCuffSizeComponent = ({
  value,
  onChange,
  noDefault
}: BPDeviceCuffSizeComponentProps) => (
  <RadioGroupComponent
    options={CuffSizeOptions}
    defaultValue={noDefault ? undefined : CuffSizeEnum.STANDARD}
    value={value}
    onChange={onChange}
    direction="vertical"
  />
);
