import { useFormHookFactory } from '../../../../../hooks/useFormHookFactory/useFormHookFactory';

export const useNutritionHook = () => {
  const factory = useFormHookFactory({
    intake: {
      name: 'intake',
      label: '24-hour Recall or Typical intake',
    },
    fastFoodFreq: {
      name: 'fastFoodFreq',
      label: 'Fast Food Frequency',
    },
    sweetBeverageFreq: {
      name: 'sweetBeverageFreq',
      label: 'Sweet Beverage Frequency',
    },
    previousDiets: {
      name: 'previousDiets',
      label: 'Previous Calorie Management Regimens',
    },
    nutritionUnderstanding: {
      name: 'nutritionUnderstanding',
      label: 'Nutrition Understanding',
    },
    additionalComments: {
      name: 'additionalComments',
      label: 'Additional Comments',
    },
  });
  return factory;
};
