import { Checkbox, FormListFieldData } from 'antd';
import { FormItem } from '../../../../uiComponent/FormItem/FormItem';
import { VitalsEnumTypeComponent } from '../../../../enumComponent/VitalsEnumTypeComponent/VitalsEnumTypeComponent';
import { useAddRemoveVitalsMonitoringForm } from '../../hook/useAddRemoveVitalsMonitoringForm';
import { VitalEnumType } from '../../../../uc-api-sdk';

export interface PatientProfileVitalsMonitoringFormFieldData extends Omit<FormListFieldData, 'name'> {
  name: VitalEnumType | number;
}

export interface PatientProfileVitalsMonitoringFormItemComponentProps {
  vitalType: VitalEnumType;
  required?: boolean;
  formField?: PatientProfileVitalsMonitoringFormFieldData;
}

export const PatientProfileVitalsMonitoringFormItemComponent = ({
  vitalType,
  required,
  formField,
}: PatientProfileVitalsMonitoringFormItemComponentProps) => {
  const profileVitalsMonitoringForm = useAddRemoveVitalsMonitoringForm();
  return (
    <FormItem
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...formField}
      name={profileVitalsMonitoringForm.makeObjectNamePath('type', formField?.name)}
      required={required}
      valuePropName="checked"
      getValueFromEvent={(e) => {
        if (e.target.checked) return vitalType;
        return null;
      }}
    >
      <Checkbox>
        <VitalsEnumTypeComponent value={vitalType} />
      </Checkbox>
    </FormItem>
  );
};
