import { Button, Form, Space } from 'antd';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useFoodLogProviderForm } from '../../../../hooks/formHook/useFoodLogProviderForm';
import FoodLogRate from '../FoodLogRate/FoodLogRate';
import { Comment } from '../../../../uc-api-sdk';
import { FoodLogProviderCommentListComponent } from '../FoodLogProviderCommentComponent/FoodLogProviderCommentListComponent';
import { FoodLogTitleValueItemComponent } from '../FoodLogTitleValueItemComponent/FoodLogTitleValueItemComponent';
import { RequiredAsteriskComponent } from '../../../../uiComponent/RequiredAsterisk/RequiredAsteriskComponent';
import { Icons } from '../../../../icons/Icons';
import { FoodLogCommentComponentProps, TranslateInputValue, TranslationInputCommonComponent } from '../TranslationInputCommonComponent/TranslationInputCommonComponent';
import { useMultilingualTranslation } from '../../hook/useMultilingual';
import { FoodLogGlucoseResultsComponent } from '../FoodLogGlucoseResultsComponent/FoodLogGlucoseResultsComponent';
import { GlucoseLevels } from '../../container/FoodLogProviderFormContainer/FoodLogProviderFormContainer';

export interface SubmitValue {
  note: TranslateInputValue;
  rate: number;
}

export interface InitialValue {
  rate?: number;
  note?: TranslateInputValue;
}

export interface FoodLogProviderFormComponentProps {
  onSubmit?: (value: SubmitValue) => Promise<void> | void;
  onCancel?: () => Promise<void> | void;
  initialValues: InitialValue;
  providerComments: Comment[];
  isLoading?: boolean;
  showGlucoseResults?: boolean;
  glucoseLevels?: GlucoseLevels;
}

export const FoodLogProviderFormComponent = ({
  onSubmit,
  onCancel,
  providerComments,
  initialValues,
  showGlucoseResults,
  glucoseLevels,
  isLoading = false,
}: FoodLogProviderFormComponentProps) => {
  const foodLogProviderForm = useFoodLogProviderForm();
  const { multilingualTranslate } = useMultilingualTranslation();

  // get translated text
  const translateText = async () => {
    const noteName = foodLogProviderForm.getName('note');
    const note = foodLogProviderForm.form.getFieldValue(noteName) as TranslateInputValue;
    if (note?.originalText && note?.language) {
      const newTranslatedText = await multilingualTranslate(note.originalText, note.language);
      foodLogProviderForm.form.setFieldsValue({
        [noteName]: { ...note, translatedText: newTranslatedText, showTranslated: true },
      });
    }
  };

  const handleLanguageChange: FoodLogCommentComponentProps['onLanguageChange'] = () => {
    translateText();
  };

  const handleSubmit = (v: SubmitValue) => {
    const submit = foodLogProviderForm.handleSubmit(onSubmit);
    submit?.(v);
    // This is so after the form is submitted comment section gets
    // reset but not the rate
    const note = foodLogProviderForm.form.getFieldValue(foodLogProviderForm.getName('note'));
    foodLogProviderForm.form.setFieldsValue({
      [foodLogProviderForm.getName('note')]: { ...note, originalText: '', showTranslated: false },
    });
  };

  return (
    <Form
      form={foodLogProviderForm.form}
      initialValues={initialValues}
      onFinish={handleSubmit}
    >
      {showGlucoseResults && (
        <div className="my12">
          <FoodLogGlucoseResultsComponent data={glucoseLevels} />
        </div>
      )}
      <FormItem
        className="no-style-label"
        name={foodLogProviderForm.getName('rate')}
        label={(
          <FoodLogTitleValueItemComponent
            title={<RequiredAsteriskComponent text="Rating" size="large" />}
          />
        )}
        rules={foodLogProviderForm.getRequiredRules('rate')}
      >
        <FoodLogRate disabled={initialValues.rate !== undefined} />
      </FormItem>
      <div>
        <FoodLogTitleValueItemComponent title="Comments for patient:" />
        <div className="my20">
          <FoodLogProviderCommentListComponent comments={providerComments} />
        </div>
      </div>
      <FormItem
        info={foodLogProviderForm.getInfo('note')}
        rules={foodLogProviderForm.getRequiredRules('note')}
      >
        <TranslationInputCommonComponent
          onLanguageChange={handleLanguageChange}
          showAvatar
        />
      </FormItem>
      <div className="flex jc-e">
        <Space>
          <Icons.TranslateIcon onClick={translateText} />
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
            Submit
          </Button>
        </Space>
      </div>
    </Form>
  );
};
