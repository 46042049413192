import { Form, FormItemProps, Input } from 'antd';
import { Icons } from '../../../../icons/Icons';

export interface LoginPasswordFormItemComponentProps extends FormItemProps {
  disabled?: boolean;
}

export const LoginPasswordFormItemComponent = ({
  name,
  label,
  rules,
  disabled,
}: LoginPasswordFormItemComponentProps) => (
  <Form.Item
    className="password-form-item"
    name={name}
    label={label}
    rules={rules}
  >
    <Input.Password
      prefix={<Icons.Lock />}
      disabled={disabled}
      placeholder="Enter your password"
      data-testid="password"
    />
  </Form.Item>
);
