import { isBoolean } from 'lodash';
import { DiseaseHistoryItem } from '../../../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { YesNoDisplayComponent } from '../../../../../../uiComponent/YesNoDisplayComponent/YesNoDisplayComponent';

export interface PrintDiseaseHistorySpecialistComponentProps {
  diseaseHistory: DiseaseHistoryItem;
}

export const PrintDiseaseHistorySpecialistComponent = ({
  diseaseHistory
}: PrintDiseaseHistorySpecialistComponentProps) => (
  <>
    {
      isBoolean(diseaseHistory.hasSeenSpecialist)
      && (
        <YesNoDisplayComponent
          value={diseaseHistory.hasSeenSpecialist}
          yesDisplay="Patient sees a specialist for this condition"
          noDisplay="Patient doesn't see a specialist for this condition"
        />
      )
    }
    {
      diseaseHistory.hasSeenSpecialist === true
      && (
        <div className="flex">
          <span>Specialist name and contact:</span>
          <DisplayOrEmptyComponent value={diseaseHistory.nameAndContact || undefined} />
        </div>
      )
    }
  </>
);
