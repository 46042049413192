import { useCallback } from 'react';
import { OutstandingMessageComponent } from '../../component/OutstandingMessageComponent/OutstandingMessageComponent';
import { usePatientTaskResolveMessage } from '../../../../uc-api-sdk';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { useMessagePopupContext } from '../../../../contexts/MessageContext/MessagePopupContext';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';

export interface OutstandingMessageContainerProps {
  memberId: string;
}

export const OutstandingMessageContainer = ({
  memberId,
}: OutstandingMessageContainerProps) => {
  const {
    handleCloseChat,
  } = useMessagePopupContext() || {};
  const resolveMessage = usePatientTaskResolveMessage({});
  const updateOutstanding = useUpdate('OUTSTANDING_MANUAL_HANDLER');

  const handleResolveMessage = useCallback(async () => {
    const res = resolveMessage.send({
      params: {
        memberId
      }
    });
    ApiRequestHelper.tryCatch(res, {
      success: 'Successfully resolved message!',
      error: 'Failed to resolve message.',
      onSuccess: () => {
        updateOutstanding.updateValue();
        handleCloseChat();
      },
    });
  }, []);

  const handleResolveMessageDeb = useDebounce(handleResolveMessage, 200);

  return (
    <OutstandingMessageComponent
      onResolve={handleResolveMessageDeb}
      isSubmitting={resolveMessage.isLoading}
    />
  );
};
