import { NestedFormComponent } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';
import { PatientInfoRefetchObj } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { PatientService } from '../../../../services/PatientService';
import { MedicationCommonCardComponent } from '../../../medication/component/MedicationCommonCardComponent/MedicationCommonCardComponent';
import { ProblemsICDCodeContainer } from '../../../problems/container/ProblemsICDCodeContainer/ProblemsICDCodeContainer';
import { LabResultInCardComponent } from '../../../transcribing/component/LabResultTableComponent/LabResultInCardComponent';
import { WorklistBasicInformationComponent } from '../WorklistBasicInformationComponent/WorklistBasicInformationComponent';
import { WorklistContentComponent } from '../WorklistContentComponent/WorklistContentComponent';

export interface HealthRecordUpdateComponentProps {
  patientId: string;
  patientInfoService: PatientService;
  onSubmit?: (keys?: (keyof PatientInfoRefetchObj)[]) => void;
}

export const HealthRecordUpdateComponent = ({
  patientId,
  patientInfoService,
  onSubmit,
}: HealthRecordUpdateComponentProps) => {
  const debouncedOnSubmit = useDebounce(() => onSubmit?.());
  useUpdateListener('INSURANCE_RENEWED', () => {
    onSubmit?.(['patientRefetch', 'patientInsuranceRefetch']);
  });
  return (
    <div className="flex fd-c gap5">
      <WorklistBasicInformationComponent
        patientId={patientId}
        patientInfoService={patientInfoService}
        onSubmit={debouncedOnSubmit}
      />
      <NestedFormComponent>
        <WorklistContentComponent>
          <ProblemsICDCodeContainer
            patientId={patientId}
            title="ICD Code*"
            isEditing
            showEdit={false}
            formButtons={null}
            showHeaderOutside
          />
        </WorklistContentComponent>
      </NestedFormComponent>
      <NestedFormComponent>
        <WorklistContentComponent>
          <MedicationCommonCardComponent
            patientId={patientId}
            showEdit={false}
            isEditing
            formButtons={null}
            onSubmit={debouncedOnSubmit}
            onMedicationSubmit={debouncedOnSubmit}
          />
        </WorklistContentComponent>
      </NestedFormComponent>
      <NestedFormComponent>
        <LabResultInCardComponent
          patientId={patientId}
        />
      </NestedFormComponent>
    </div>
  );
};
