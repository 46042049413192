import { LanguageType } from '../../types/languages';
import { ArticleCategory, PatientArticles } from '../../uc-api-sdk';

export type ArticleCategories = ArticleCategory[];
export interface PatientArticleMap {
  [id: string]: PatientArticles
}
export enum WebflowLanguageEnum {
  en = 'en',
  zhTw = 'zh-tw',
  zhCn = 'zh-cn',
  es = 'es',
  vi = 'vi',
}
export type WebflowLanguageAbbreviationMap = {
  [key in WebflowLanguageEnum]: string
}
export type LanguageToWebflowLanguageMap = {
  [key in `${LanguageType}`]: WebflowLanguageEnum
}
