import { map } from 'lodash';
import { Dayjs } from 'dayjs';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { FC, useMemo } from 'react';
import {
  createMarker, createTMChartOptions, createTMChartSeries,
} from './TMChartHelper';
import { SeverityHelper } from '../../helper/severityHelper';
import { FromDateToDateDayjs, Severity } from '../../type';
import TimezoneService from '../../../../helpers/timezone/timezoneService';

export interface TMChartData {
  date: Dayjs;
  severity: Severity;
  patientNote: string;
  temperatureValue: number;
  temperatureUnit: string;
  type: 'vital' | 'visit';
}

export interface TMChartComponentProps extends FromDateToDateDayjs {
  data: TMChartData[];
}

export const TMChartComponent: FC<TMChartComponentProps> = ({
  data,
  fromDate,
  toDate,
}) => {
  const getData = (
    filterMeasurements: ((v: TMChartData[]) => TMChartData[]),
  ) => {
    const filteredData = filterMeasurements(data);
    return map(filteredData, (curr) => {
      const date = TimezoneService.calcDateTimeDayjs(curr.date).startOf('day').valueOf();
      return {
        x: date,
        y: curr.temperatureValue,
        severity: curr.severity,
        marker: createMarker(curr),
        notes: curr.patientNote,
      };
    });
  };

  const options = useMemo(() => {
    const series = createTMChartSeries({
      normal: getData(SeverityHelper.getNormal),
      risk: getData(SeverityHelper.getRisk),
      critical: getData(SeverityHelper.getCritical),
    });
    return createTMChartOptions(series, fromDate, toDate);
  }, [data, fromDate, toDate]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
};
