import { DatePicker, Input } from 'antd';
import { useInsuranceForm } from '../../../../hooks/formHook/useInsuranceForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { PriorAuthSubmissionDateFormItemComponent } from '../PriorAuthSubmissionDateFormItemComponent/PriorAuthSubmissionDateFormItemComponent';
import { MissingErrorComponent } from '../../../../uiComponent/MissingErrorComponent/MissingErrorComponent';

export const PriorAuthApprovalFormItemComponent = () => {
  const insuranceForm = useInsuranceForm();
  return (
    <>
      <PriorAuthSubmissionDateFormItemComponent />
      <FormItem
        info={insuranceForm.getInfo('priorAuthApprovalDate')}
        preserve={false}
        required
      >
        <DatePicker
          format="MM/DD/YYYY"
          placeholder="MM/DD/YYYY"
        />
      </FormItem>
      <FormItem
        info={insuranceForm.getInfo('priorAuthAuthorizationNumber')}
        preserve={false}
        required
      >
        <Input />
      </FormItem>
      <FormItem
        info={insuranceForm.getInfo('priorAuthAuthPeriod')}
        preserve={false}
        rules={[
          {
            required: true,
            message: <MissingErrorComponent />,
            validator: (_, value) => (
              new Promise((resolve, reject) => {
                if (!value || !value[0] || !value[1]) {
                  reject();
                }
                resolve(true);
              }))
          }
        ]}
      >
        <DatePicker.RangePicker
          format="MM/DD/YYYY"
          placeholder={['MM/DD/YYYY', 'MM/DD/YYYY']}
        />
      </FormItem>
    </>
  );
};
