import { useState } from 'react';

import './FollowUpScheduleFormComponent.scss';
import { NestedFormComponent } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';
import { SkipFollowUpScheduleFormContainer, SkipFollowUpScheduleFormContainerProps } from '../../container/SkipFollowUpScheduleFormContainer/SkipFollowUpScheduleFormContainer';
import { FollowUpScheduleButtonComponent } from '../FollowUpScheduleButtonComponent/FollowUpScheduleButtonComponent';

export interface FollowUpScheduleFormComponentProps {
  visitId?: string;
  canSkipSchedule?: boolean;
  followupVisitSkipped?: boolean;
}

export const FollowUpScheduleFormComponent = ({
  visitId,
  canSkipSchedule,
  followupVisitSkipped: _followupVisitSkipped = false,
}: FollowUpScheduleFormComponentProps) => {
  const [followupVisitSkipped, setSkipFollowUpSchedule] = useState(_followupVisitSkipped);

  const handleSkipScheduleChange: SkipFollowUpScheduleFormContainerProps['onValuesChange'] = (
    changedValues,
  ) => {
    const newIgnoreFollowUp = changedValues.followupVisitSkipped;
    if (newIgnoreFollowUp !== undefined) {
      setSkipFollowUpSchedule(newIgnoreFollowUp);
    }
  };

  return (
    <div className="follow-up-schedule">
      <div className="follow-up-schedule-button my20">
        <FollowUpScheduleButtonComponent
          disabled={followupVisitSkipped}
        />
      </div>
      {
        canSkipSchedule
        && (
          <NestedFormComponent>
            <SkipFollowUpScheduleFormContainer
              visitId={visitId}
              initialValues={{ followupVisitSkipped }}
              onValuesChange={handleSkipScheduleChange}
            />
          </NestedFormComponent>
        )
      }
    </div>
  );
};
