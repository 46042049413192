import { Button, Card, Input } from 'antd';
import { useState } from 'react';
import { trim } from 'lodash';
import useBoolean from '../../../../../hooks/useBoolean/useBoolean';
import { AddNewLinkButtonComponent } from '../../../../../uiComponent/AddNewLinkButtonComponent/AddNewLinkButtonComponent';

export interface BehaviorGoalAddNewComponentProps {
  onAdd: (category: string) => void;
}

export const BehaviorGoalAddNewComponent = ({
  onAdd,
}: BehaviorGoalAddNewComponentProps) => {
  const {
    value: isAdding,
    toggle: toggleIsAdding,
  } = useBoolean();
  const [
    category,
    setCategory,
  ] = useState('');
  const isValidCategory = trim(category).length > 0;

  const handleAddCategory = () => {
    onAdd(category);
    setCategory('');
    toggleIsAdding();
  };

  return (
    <div>
      <AddNewLinkButtonComponent
        buttonText="Add a New Category"
        onClick={toggleIsAdding}
        disabled={isAdding}
      />
      {
        isAdding
        && (
          <Card className="mb30 w100">
            <span>
              Name of the Category
            </span>
            <div className="flex pb20">
              <Input
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                className="input-med-2"
              />
              <Button
                type="primary"
                disabled={!isValidCategory}
                onClick={handleAddCategory}
                className="ml10 w100px"
              >
                Add
              </Button>
              <Button
                type="text"
                onClick={toggleIsAdding}
              >
                Cancel
              </Button>
            </div>
          </Card>
        )
      }
    </div>
  );
};
