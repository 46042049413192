import { message } from 'antd';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { useEmployeeForgotPasswordEmail } from '../../../../uc-api-sdk';
import { ForgotPasswordComponent, ForgotPasswordComponentProps } from '../../component/ForgotPasswordComponent/ForgotPasswordComponent';

export interface ForgotPasswordContainerProps {
  onBackToLogin: ForgotPasswordComponentProps['onBackToLogin'];
}

export const ForgotPasswordContainer = ({
  onBackToLogin,
}: ForgotPasswordContainerProps) => {
  const forgotPasswordInfo = useEmployeeForgotPasswordEmail({});

  const handleSendLink: ForgotPasswordComponentProps['onSendLink'] = useDebounce(async (
    email,
  ) => {
    const res = await forgotPasswordInfo.send({
      params: { resendEmailInput: { email } },
    });
    if (res?.code === 200) {
      message.success('Send reset link successfully');
      onBackToLogin?.();
      return;
    }
    message.error(res?.msg);
  }, 1000);

  return (
    <ForgotPasswordComponent
      onSendLink={handleSendLink}
      onBackToLogin={onBackToLogin}
    />
  );
};
