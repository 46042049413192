import { Modal, ModalProps } from 'antd';
import {
  compact,
  difference,
  forEach,
  isEmpty,
  map,
  omit
} from 'lodash';
import { useCallback, useMemo } from 'react';
import { InsuranceCardSubmitValue, InsuranceSubmitValue, TempPriorAuthCase } from '../../../hooks/formHook/useInsuranceForm';
import { useDeepCompareMemo } from '../../../hooks/useDeepCompareEffect';
import {
  BenefitOrderEnum, InsuranceSubmitForm, PriorAuthCase,
  PriorAuthCaseStatusEnum
} from '../../../uc-api-sdk';
import { InsuranceHelperService } from '../component/helper/InsuranceHelperService';
import { Insurance } from './insuranceApis';

export const useInsuranceHelper = (patientInsurance?: Insurance | null) => {
  const benefitOrderIndex = useMemo(() => ({
    [BenefitOrderEnum.PRIMARY]: 0,
    [BenefitOrderEnum.SECONDARY]: 1,
    [BenefitOrderEnum.TERTIARY]: 2,
  }), []);

  const getDayjsDate = useCallback(InsuranceHelperService.getDayjsDate, []);

  const getPriorAuthExpirationDate = (date?: string | null) => getDayjsDate(date);

  const getPriorAuthCase = (insurance = patientInsurance) => {
    const {
      needPriorAuth,
      priorAuthCases,
    } = insurance ?? {};
    if (isEmpty(priorAuthCases)) {
      return {};
    }
    let activeCase: PriorAuthCase | undefined;
    let historyCases = [...(priorAuthCases || [])];
    if (needPriorAuth) {
      [activeCase, ...historyCases] = historyCases;
    }
    return {
      activeCase,
      historyCases,
    };
  };

  const shouldPriorAuthCaseBeCancellable = useCallback(<T extends TempPriorAuthCase>(
    priorAuthCase?: T
  ) => {
    if (isEmpty(priorAuthCase)) {
      return true;
    }
    return (
      priorAuthCase?.status !== PriorAuthCaseStatusEnum.APPROVED
      || (
        typeof priorAuthCase?.expirationDays === 'number'
        && priorAuthCase?.expirationDays < 0 // already expired
      )
    );
  }, []);

  const parsePriorAuthCaseFormValues = useCallback((values?: InsuranceSubmitValue) => {
    const {
      priorAuth,
      priorAuthDeniedDate,
      priorAuthApprovalDate,
      priorAuthSubmissionDate,
      priorAuthAuthPeriod,
      priorAuthAuthorizationNumber,
      priorAuthDeniedReason,
      priorAuthCPTCodeCategory,
      priorAuthCPTCodeUnits,
      priorAuthStatus,
      priorAuthComments,
    } = values || {};

    if (!priorAuth) {
      return undefined;
    }

    return {
      submissionDate: InsuranceHelperService.getDateFormat(priorAuthSubmissionDate),
      approvalDate: InsuranceHelperService.getDateFormat(priorAuthApprovalDate),
      authStartDate: InsuranceHelperService.getDateFormat(priorAuthAuthPeriod?.[0]),
      authExpiredDate: InsuranceHelperService.getDateFormat(priorAuthAuthPeriod?.[1]),
      localStartDate: (
        priorAuthAuthPeriod?.[0]
          ? InsuranceHelperService.getLocalDate(priorAuthAuthPeriod?.[0]) : undefined
      ),
      localExpiredDate: (
        priorAuthAuthPeriod?.[1]
          ? InsuranceHelperService.getLocalDate(priorAuthAuthPeriod?.[1]) : undefined
      ),
      authorizationNumber: priorAuthAuthorizationNumber,
      deniedDate: InsuranceHelperService.getDateFormat(priorAuthDeniedDate),
      deniedReason: priorAuthDeniedReason,
      cptCodeCategory: priorAuthCPTCodeCategory,
      cptCodes: priorAuthCPTCodeUnits,
      status: priorAuthStatus,
      comment: priorAuthComments,
    } as PriorAuthCase;
  }, []);

  const parseInsuranceToFormValues = useCallback((insurance?: Insurance | null) => {
    if (!insurance) return {};
    const {
      activeCase,
      historyCases,
    } = getPriorAuthCase(insurance) || {};
    const parsedPriorAuthCaseValues = (
      InsuranceHelperService.parsePriorAuthCaseToFormValues(activeCase)
    );
    const orderedList = [
      undefined,
      undefined,
      undefined
    ] as (InsuranceCardSubmitValue | undefined)[];
    forEach(insurance?.insuranceInfoList, (info) => {
      const index = benefitOrderIndex[info.benefitOrder as BenefitOrderEnum];
      orderedList[index] = {
        ...omit(info, 'providerId'),
        insuranceProviderId: info?.providerId ?? undefined,
      };
    });

    return {
      insuranceList: compact(orderedList),
      priorAuth: insurance.needPriorAuth ?? undefined,
      ...parsedPriorAuthCaseValues,
      // readonly
      priorAuthActiveCase: activeCase,
      priorAuthHistoryCases: historyCases,
      nonCovered: insurance.nonCovered,
      note: insurance.note,
    } as InsuranceSubmitValue;
  }, []);

  const parseFormValues = useCallback((values: InsuranceSubmitValue) => {
    const {
      insuranceList,
      priorAuth,
      isNonCovered,
      reason,
      priorAuthActiveCase,
    } = values;
    // use conditional statements to avoid undefined value
    const updateValue = {} as InsuranceSubmitForm;

    if (typeof isNonCovered === 'boolean') {
      updateValue.nonCovered = {
        isNonCovered,
        reason: isNonCovered ? reason : '',
      };
    }
    let primaryInsurance = undefined as InsuranceCardSubmitValue | undefined;
    updateValue.insuranceInfoList = map(insuranceList, ({
      insuranceProviderId,
      ...insurance
    }) => {
      if (insurance.benefitOrder === BenefitOrderEnum.PRIMARY) {
        primaryInsurance = insurance;
      }
      return {
        ...insurance,
        providerId: insuranceProviderId,
      };
    });
    if (priorAuth !== undefined) {
      const isPriorAuthCaseCancellable = (
        shouldPriorAuthCaseBeCancellable(priorAuthActiveCase)
      );
      // no primary insurance
      if (primaryInsurance?.provider === undefined) {
        updateValue.priorAuthCase = (
          !isPriorAuthCaseCancellable
            ? ({
              needPriorAuth: true, // because case is still active
              ...priorAuthActiveCase
            }) : undefined // remove cancellable PA
        );
      } else {
        // has primary insurance
        updateValue.priorAuthCase = {
          needPriorAuth: !!priorAuth,
          ...parsePriorAuthCaseFormValues(values),
        };
      }
    }
    return updateValue;
  }, []);

  const getNextOrderOfBenefit = useCallback((insuranceList: InsuranceCardSubmitValue[]) => {
    if (isEmpty(insuranceList)) {
      return BenefitOrderEnum.PRIMARY;
    }
    const selectedOrders = insuranceList?.map((i) => i.benefitOrder) || [];
    const missingOrders = difference(
      [
        BenefitOrderEnum.PRIMARY,
        BenefitOrderEnum.SECONDARY,
        BenefitOrderEnum.TERTIARY,
      ],
      selectedOrders,
    );
    return missingOrders[0] ?? undefined;
  }, []);

  const shouldShowPA = useCallback((benefitOrder: BenefitOrderEnum) => (
    benefitOrder === BenefitOrderEnum.PRIMARY
  ), []);

  const promptDeleteConfirmation = useCallback((
    onOk?: () => void,
    options?: ModalProps,
  ) => {
    Modal.confirm({
      icon: null,
      content: 'Are you sure you want to delete this insurance?',
      centered: true,
      closable: true,
      onOk,
      okText: 'Yes, delete',
      ...options,
    });
  }, []);

  const initialValues = useDeepCompareMemo(() => (
    parseInsuranceToFormValues(patientInsurance)
  ), [patientInsurance]);

  return {
    initialValues,
    getPriorAuthExpirationDate,
    getPriorAuthCase,
    parseInsuranceToFormValues, // data to form
    parseFormValues, // form to data
    parsePriorAuthCaseFormValues,
    getNextOrderOfBenefit,
    shouldShowPA,
    benefitOrderIndex,
    promptDeleteConfirmation,
  };
};
