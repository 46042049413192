import { ClinicEventsService } from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { VisitTypeEnumComponent } from '../../../../enumComponent/VisitTypeEnumComponent/VisitTypeEnumComponent';
import { FutureFollowUpScheduleTimeComponent } from '../FutureFollowUpScheduleTimeComponent.tsx/FutureFollowUpScheduleTimeComponent';
import { WorklistCalendarDrawerEditButtonComponent } from '../WorklistCalendarDrawerEditButtonComponent/WorklistCalendarDrawerEditButtonComponent';

export interface FutureFollowUpClinicEventCardComponentProps {
  clinicEvent: ClinicEventsService;
}

export const FutureFollowUpClinicEventCardComponent = ({
  clinicEvent,
}: FutureFollowUpClinicEventCardComponentProps) => (
  <div className="flex f-w ai-c jc-sb pt12 px12 pb20 bordered bg-gray6 default-radius">
    <div className="flex fd-c gap2">
      <TextComponent bold>
        <VisitTypeEnumComponent
          value={clinicEvent.visitType}
        />
      </TextComponent>
      <div className="flex fd-c gap0">
        <FutureFollowUpScheduleTimeComponent
          clinicEvent={clinicEvent}
        />
      </div>
    </div>
    <div>
      <WorklistCalendarDrawerEditButtonComponent
        clinicEvent={clinicEvent}
      />
    </div>
  </div>
);
