import {
  Col,
  Input,
  Row,
} from 'antd';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useDiseaseHistoryForm } from '../../hook/useDiseaseHistoryForm';
import { YesNoRadioComponent } from '../../../../uiComponent/YesNoRadioComponent/YesNoRadioComponent';
import { DiseaseHistoryHasSeenSpecialistFormListItemComponent } from '../DiseaseHistoryHasSeenSpecialistFormListItemComponent/DiseaseHistoryHasSeenSpecialistFormListItemComponent';
import { DiseaseHistoryDiagnosisYearFormListItemComponent } from '../DiseaseHistoryDiagosisYearFormItemComponent/DiseaseHistoryDiagnosisYearFormItemComponent';

export interface DiseaseHistoryTemplateGeneralComponentProps {
  fieldName: number;
}

export const DiseaseHistoryTemplateGeneralComponent = ({
  fieldName,
}: DiseaseHistoryTemplateGeneralComponentProps) => {
  const {
    makeListNamePath,
    getLabel,
  } = useDiseaseHistoryForm();
  return (
    <Row
      gutter={70}
      className="disease-history-template-row"
    >
      <Col>
        <DiseaseHistoryDiagnosisYearFormListItemComponent fieldName={fieldName} />
        <FormItem
          name={makeListNamePath('otherTreatmentAndNotes', fieldName)}
          label={getLabel('otherTreatmentAndNotes') as string}
        >
          <Input />
        </FormItem>
      </Col>
      <Col>
        <FormItem
          name={makeListNamePath('isTakingMedication', fieldName)}
          label={getLabel('isTakingMedication') as string}
        >
          <YesNoRadioComponent />
        </FormItem>
        <DiseaseHistoryHasSeenSpecialistFormListItemComponent
          fieldName={fieldName}
        />
      </Col>
    </Row>
  );
};
