import { WarningFilled } from '@ant-design/icons';
import { TagComponent } from '../../../../uiComponent/TagComponent/TagComponent';

export const StartNowOverlapWarningComponent = () => (
  <TagComponent
    type="error"
    icon={null}
  >
    <div className="flex gap2 ai-s py5">
      <WarningFilled />
      <div className="breakword  text-gray-scale-1">
        Some participants may not be available in the next 30 mins.
      </div>
    </div>
  </TagComponent>
);
