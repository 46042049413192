import { FilterFilled } from '@ant-design/icons';
import classNames from 'classnames';
import './FilterIconComponent.scss';

interface FilterIconComponentProps {
  isFiltered: boolean;
}

export const FilterIconComponent = ({
  isFiltered,
}: FilterIconComponentProps) => (
  <FilterFilled
    className={classNames({
      'filter-icon-component': true,
      'is-active': isFiltered,
    })}
  />
);
