import {
  ReactNode,
  createRef,
  useCallback,
  useMemo,
} from 'react';
import { Dayjs } from 'dayjs';
import moment from 'moment';
import { filter, find } from 'lodash';
import BigCalendarComponent, { BigCalendarProps, CalendarEvent } from '../BigCalendarComponent/BigCalendarComponent';
import { CalendarEventComponent } from '../CalendarEventComponent/CalendarEventComponent';
import { CalendarPopoverContentComponent } from '../CalendarPopoverContentComponent/CalendarPopoverContentComponent';
import { ClinicEvent } from '../../../../uc-api-sdk';
import { CalendarEventModalComponent } from '../CalendarEventModalComponent/CalendarEventModalComponent';
import { NEW_EVENT_ID } from '../../constant';

export interface NewVisit {
  start: Dayjs;
  end: Dayjs;
}

export interface CalendarComponentProps {
  date: Dayjs;
  selectedEvent?: CalendarEvent<ClinicEvent>;
  events?: CalendarEvent<ClinicEvent>[];
  onNavigate: BigCalendarProps['onNavigate'];
  view: BigCalendarProps['view'];
  onView: BigCalendarProps['onView'];
  onSelectNew: BigCalendarProps['onSelectSlot'];
  onSelect: BigCalendarProps['onSelect'];
  onEventDrop?: BigCalendarProps['onEventDrop'];
  onPopoverClose?: BigCalendarProps['onPopoverClose'];
  isLoading?: boolean;
  extra?: ReactNode;
  defaultStartingHourView?: number | null;
}

export const CalendarComponent = ({
  date,
  selectedEvent,
  events = [],
  onPopoverClose,
  onSelectNew,
  onSelect,
  onEventDrop,
  onNavigate,
  view,
  onView,
  isLoading = false,
  extra,
  defaultStartingHourView,
}: CalendarComponentProps) => {
  const dateMoment = useMemo(() => moment(date?.toDate()), [date]);

  // not allowing dragging
  const handleSelectingSlot = useCallback<Exclude<BigCalendarProps['onSelecting'], undefined>>(() => false, []);

  const allEvents = useMemo(() => {
    if (selectedEvent) {
      const filteredEvents = filter(events, (v) => v.id !== selectedEvent.id) || [];
      return [...filteredEvents, { ...selectedEvent, ref: createRef<HTMLDivElement>() }];
    }
    return [...events];
  }, [events, selectedEvent]);

  const handleEventDrop: BigCalendarProps['onEventDrop'] = (v) => {
    onEventDrop?.(v);
  };

  return (isLoading && allEvents.length === 0) ? null : (
    <>
      <BigCalendarComponent
        newEventId={NEW_EVENT_ID}
        loading={isLoading}
        onPopoverClose={onPopoverClose}
        popoverContentRenderer={CalendarPopoverContentComponent}
        eventRenderer={CalendarEventComponent}
        date={dateMoment}
        extra={extra}
        events={allEvents}
        onSelecting={handleSelectingSlot}
        onSelectSlot={onSelectNew}
        onSelect={onSelect}
        onNavigate={onNavigate}
        onEventDrop={handleEventDrop}
        view={view}
        onView={onView}
        selectedEventId={selectedEvent?.id}
        defaultStartingHourView={defaultStartingHourView}
      />
      <CalendarEventModalComponent
        onClose={onPopoverClose}
        event={selectedEvent}
        // @ts-ignore
        reference={find(allEvents, (v) => selectedEvent?.id && v.id === selectedEvent.id)?.ref}
      />
    </>
  );
};
