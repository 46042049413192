import { useMemo, useState } from 'react';
import { AutoComplete, AutoCompleteProps } from 'antd';
import { includes, map } from 'lodash';
import { DefaultOptionType } from 'antd/lib/select';
import { ChronicDiseaseEnum } from '../../../../uc-api-sdk';
import { ChronicDiseaseEnumComponent, getText } from '../../../../enumComponent/ChronicDiseaseEnumComponent/ChronicDiseaseEnumComponent';

export interface DiseaseHistoryConditionAutoCompleteComponentProps
  extends Omit<AutoCompleteProps, 'onChange'> {
  disabledOptions?: string[];
  onChange?: (value?: string, associatedEnum?: ChronicDiseaseEnum) => void;
}

export const DiseaseHistoryConditionAutoCompleteComponent = ({
  value,
  onChange,
  disabledOptions,
}: DiseaseHistoryConditionAutoCompleteComponentProps) => {
  const [options] = useState([
    ChronicDiseaseEnum.DIABETES,
    ChronicDiseaseEnum.HYPERTENSION,
    ChronicDiseaseEnum.PRE_DIABETES,
    ChronicDiseaseEnum.HYPERLIPIDEMIA,
    ChronicDiseaseEnum.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE,
    ChronicDiseaseEnum.CONGESTIVE_HEART_FAILURE,
    ChronicDiseaseEnum.CHRONIC_KIDNEY_DISEASE,
  ]);

  const handleOnChange: AutoCompleteProps['onChange'] = (
    value,
    option
  ) => {
    const associatedEnum = (option as DefaultOptionType)?.associatedEnum;
    onChange?.(value, associatedEnum);
  };

  const processedOptions = useMemo(() => (
    map(options, (enumValue) => {
      const option = {
        value: getText(enumValue),
        label: <ChronicDiseaseEnumComponent value={enumValue} />,
        associatedEnum: enumValue,
      };
      if (includes(disabledOptions, enumValue)) {
        return {
          ...option,
          label: (
            <>
              <ChronicDiseaseEnumComponent value={enumValue} />
              <span>(Added)</span>
            </>
          ),
          disabled: true,
        };
      }
      return option;
    })
  ), [value]);

  return (
    <AutoComplete
      options={processedOptions}
      value={value}
      onChange={handleOnChange}
      className="w400"
    />
  );
};
