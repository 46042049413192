import { useMemo } from 'react';
import { map } from 'lodash';
import { FoodLogProviderCommentComponent } from './FoodLogProviderCommentComponent';
import { Comment } from '../../../../uc-api-sdk';
import { EmptyComponent } from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import { AccountHelper } from '../../../../helpers/account';
import { TranslatedContentComponent } from '../TranslatedContentComponent/TranslatedContentComponent';
import './FoodLogProviderCommentListComponent.scss';

export interface FoodLogProviderCommentListComponentProps {
  comments: Comment[];
}

export const FoodLogProviderCommentListComponent = ({
  comments,
}: FoodLogProviderCommentListComponentProps) => {
  const providerComments = useMemo(() => (
    map(comments, (v, i) => (
      <FoodLogProviderCommentComponent
        key={i}
        dateTime={v.commentedAt || ''}
        commentedBy={AccountHelper.getFullName(v.commentedByUser) || ''}
        comment={
          v.translatedText ? (
            <TranslatedContentComponent
              translatedText={v.translatedText || ''}
              originalText={v.originalText || ''}
            />
          ) : (
            v.text || ''
          )
        }
      />
    ))
  ), [comments]);

  if (providerComments.length === 0) {
    return (
      <EmptyComponent text="No comments yet" />
    );
  }

  return (
    <div className="FoodLogProviderCommentListComponent">
      {providerComments}
    </div>
  );
};
