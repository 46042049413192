import { useMemo } from 'react';
import { useAverageTrend } from './useAverageTrend';
import { AverageValue } from '../../../uc-api-sdk';

export const useBPAverageTrend = (
  averageValue: AverageValue[],
) => {
  const { getTrend, latestAvg } = useAverageTrend(averageValue);

  const systolicTrend = useMemo(() => getTrend((latest, prev) => {
    if (latest?.systolicBloodPressure?.value && prev?.systolicBloodPressure?.value) {
      return latest?.systolicBloodPressure?.value > prev?.systolicBloodPressure?.value;
    }
    return null;
  }), [getTrend]);

  const diastolicTrend = useMemo(() => getTrend((latest, prev) => {
    if (latest?.diastolicBloodPressure?.value && prev?.diastolicBloodPressure?.value) {
      return latest?.diastolicBloodPressure?.value > prev?.diastolicBloodPressure?.value;
    }
    return null;
  }), [getTrend]);

  return {
    latestAvg,
    systolicTrend,
    diastolicTrend,
  };
};
