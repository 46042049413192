import { Card } from 'antd';
import { useUpdateEffect } from 'usehooks-ts';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { PatientPageState } from '../../../../hooks/useChangePage/types';
import { useRouterState } from '../../../../hooks/useRouterState/useRouterState';
import { CalendarDrawerComponent } from '../../../calendar/component/CalendarDrawerComponent/CalendarDrawerComponent';
import {
  ClinicEventsService
} from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { OutstandingVisitCardHeaderComponent } from '../OutstandingVisitCardHeaderComponent/OutstandingVisitCardHeaderComponent';
import { OutstandingVisitCardItemsComponent } from '../OutstandingVisitCardItemsComponent/OutstandingVisitCardItemsComponent';
import './OutstandingVisitComponent.scss';

const makeVisitElementId = (visitId?: string) => `outstanding-visit_${visitId}`;

export interface OutstandingVisitComponentProps {
  visit: ClinicEventsService;
}

export const OutstandingVisitComponent = ({
  visit,
}: OutstandingVisitComponentProps) => {
  const { state, removeState } = useRouterState();
  const { isOpen, open, close } = useOpen();
  useUpdateListener('GO_TO_CHECK_IN', close);

  useUpdateEffect(() => {
    if (state && (state as PatientPageState)?.focusVisit === visit.id) {
      const visitCardEl = document.getElementById(makeVisitElementId(visit.id));
      visitCardEl?.scrollIntoView();
      removeState(['focusVisit']);
    }
  }, [
    state,
    document.getElementById(makeVisitElementId(visit.id)),
  ]);

  return (
    <Card
      id={makeVisitElementId(visit.id)}
      title={(
        <OutstandingVisitCardHeaderComponent
          visitType={visit.visitType}
          visitTime={visit.startTime?.toISOString()}
          onEdit={visit.isEditable ? open : undefined}
        />
      )}
      className="outstandingVisitComponent"
    >
      <OutstandingVisitCardItemsComponent
        visit={visit}
        onReschedule={open}
      />
      <CalendarDrawerComponent
        isOpen={isOpen}
        onClose={close}
        calendarInitialValue={{
          date: visit.startTime?.toISOString(),
          eventId: visit.calendarEventId,
          participants: visit.visitParticipantsId,
        }}
      />
    </Card>
  );
};
