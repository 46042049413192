import { useCallback, useMemo } from 'react';
import { FormOptions, useFormHookFactory } from '../useFormHookFactory/useFormHookFactory';
import { ShouldUpdate } from '../../types/form';

export const useAddressForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    address: {
      name: 'address',
      label: 'Address',
    },
    address2: {
      name: 'address2',
      label: 'Apt/Suite',
    },
    city: {
      name: 'city',
      label: 'City',
    },
    state: {
      name: 'state',
      label: 'State',
    },
    zipcode: {
      name: 'zipcode',
      label: 'Zip Code',
    },
  }, options);

  const shouldUpdateCopyText = useCallback((): ShouldUpdate => (
    (prev, next) => (
      prev[factory.getName('address')] !== next[factory.getName('address')]
      || prev[factory.getName('address2')] !== next[factory.getName('address2')]
      || prev[factory.getName('city')] !== next[factory.getName('city')]
      || prev[factory.getName('state')] !== next[factory.getName('state')]
      || prev[factory.getName('zipcode')] !== next[factory.getName('zipcode')]
    )
  ), []);

  const res = useMemo(() => ({
    ...factory,
    shouldUpdateCopyText
  }), [
    factory,
    shouldUpdateCopyText
  ]);

  return res;
};
