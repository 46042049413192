import { CuffSizeEnumComponent } from '../../../../enumComponent/CuffSizeEnumComponent/CuffSizeEnumComponent';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { CuffSizeEnum } from '../../../../uc-api-sdk';
import { CardInfoTextComponent } from '../../../../uiComponent/CardTypographyComponent/CardInfoTextComponent/CardInfoTextComponent';
import { CardTextComponent } from '../../../../uiComponent/CardTypographyComponent/CardTextComponent/CardTextComponent';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import { BPCuffSizeModalComponent } from '../../../enrollment/component/BPCuffSizeModalComponent/BPCuffSizeModalComponent';

export interface DeviceCuffSizeComponentProps {
  value?: CuffSizeEnum;
  onChange?: (cuffSize: CuffSizeEnum) => void;
  closeOnChange?: boolean;
  hideWhenEmpty?: boolean;
  closeable?: boolean;
}

export const DeviceCuffSizeComponent = ({
  value,
  onChange,
  closeOnChange = true,
  hideWhenEmpty,
  closeable,
}: DeviceCuffSizeComponentProps) => {
  const { isOpen, open, close } = useOpen();

  const handleOnChange: DeviceCuffSizeComponentProps['onChange'] = (
    value,
  ) => {
    onChange?.(value);
    if (closeOnChange) close();
  };

  return (
    <>
      {
        hideWhenEmpty && !value
          ? null
          : (
            <div className="flex f-w ai-bl">
              <CardInfoTextComponent size="small">
                Cuff size:
              </CardInfoTextComponent>
              <CardTextComponent size="small">
                <CuffSizeEnumComponent value={value as CuffSizeEnum} />
              </CardTextComponent>
              <LinkButton
                useBlueColor
                onClick={open}
                className="fs12"
              >
                Edit
              </LinkButton>
            </div>
          )
      }
      <BPCuffSizeModalComponent
        open={isOpen}
        onCancel={close}
        value={value}
        onChange={handleOnChange}
        closeable={closeable}
      />
    </>
  );
};
