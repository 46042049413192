import { message } from 'antd';
import { map } from 'lodash';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { MpTrackingHelperProps } from '../../../../hooks/useMpTrackingHelper/useMpTrackingHelper';
import {
  AlertSnoozeFormComponent,
  AlertSnoozeFormComponentProps,
  AlertSnoozeSubmitValue
} from '../../component/AlertComponent/AlertSnoozeFormComponent';
import { AlertText } from '../../constant/text';
import { muteAlerts, unMuteAlerts } from '../../hook/useAlertSearch';

export interface AlertSnoozeFormContainerProps extends AlertSnoozeFormComponentProps {
  hidePopOver: () => void;
  onComplianceAlertEnd?: (value?: MpTrackingHelperProps['properties']) => void;
}

export const AlertSnoozeFormContainer = ({
  data,
  hidePopOver,
  onComplianceAlertEnd
}: AlertSnoozeFormContainerProps) => {
  const muteRequest = muteAlerts();
  const unMuteRequest = unMuteAlerts();
  const { send: sendMixpanel } = useMixpanelContext();
  const updateHook = useUpdate('ALERT_SNOOZE_OR_UN_SNOOZE');

  const handleSubmit = async (value: AlertSnoozeSubmitValue) => {
    // mute alerts when snooze;
    const result = await muteRequest.send(data ?? [], value);
    let count = 0;
    map(result, (r) => {
      if (r && r.code === 200) {
        count += 1;
      }
    });
    if (count > 0) {
      message.success(AlertText.snoozedSuccess);
      onComplianceAlertEnd?.({
        alertIds: data?.map((alert) => alert.id),
      });
      // refresh compliance/snooze counts; outstanding items counts;
      updateHook.updateValue();
    } else {
      message.warning(AlertText.error);
    }

    hidePopOver();
  };

  const handleUnSnooze = async () => {
    sendMixpanel({ event: MixpanelEvents.ComplianceAlertSnooze });
    const result = await unMuteRequest.send(data ?? []);
    let count = 0;
    map(result, (r) => {
      if (r && r.code === 200) {
        count += 1;
      }
    });
    if (count > 0) {
      message.success(AlertText.unSnoozedSuccess);
      onComplianceAlertEnd?.({
        alertIds: data?.map((alert) => alert.id),
      });
      // refresh compliance/snooze counts; outstanding items counts;
      // update care note
      updateHook.updateValue();
    } else {
      message.warning(AlertText.error);
    }

    hidePopOver();
  };

  return (
    <AlertSnoozeFormComponent
      data={data}
      onSubmit={handleSubmit}
      onUnSnooze={handleUnSnooze}
      onCancel={hidePopOver}
    />
  );
};
