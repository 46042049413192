/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import './LogbookCellComponent.scss';
import { LogbookCellGroupComponent } from './LogbookCellGroupComponent';

export interface LogbookCellComponentProps {
  color: string;
  text: ReactNode;
  hasNote: boolean;
  topRightIcon?: ReactNode;
  bottomLeftIcon?: ReactNode;
  bottomRightIcon?: ReactNode;
  onClick?: () => void;
  reverseColor?: boolean;
  className?: string;
}

export interface LogbookCellComponentOthers {
  Group: typeof LogbookCellGroupComponent;
}

export const LogbookCellComponent: FC<LogbookCellComponentProps> & LogbookCellComponentOthers = ({
  color,
  text,
  hasNote,
  topRightIcon,
  bottomLeftIcon,
  bottomRightIcon,
  onClick,
  reverseColor = false,
  className = ''
}: LogbookCellComponentProps) => (
  <div
    className={classNames({
      logbookCell: true,
      hasNote,
      pointer: !!onClick,
      reverseColor,
      [className]: !!className,
    })}
    style={{
      backgroundColor: color,
    }}
    onClick={onClick}
  >
    {text}
    {topRightIcon && <div className="topRightIcon">{topRightIcon}</div>}
    {bottomLeftIcon && <div className="bottomLeftIcon">{bottomLeftIcon}</div>}
    {bottomRightIcon && <div className="bottomRightIcon">{bottomRightIcon}</div>}
    {hasNote && <div className="hasNote" />}
  </div>
);

LogbookCellComponent.Group = LogbookCellGroupComponent;
