import { useEffectOnce } from 'usehooks-ts';
import { ReactNode } from 'react';
import { PORTAL_USER_FILTER } from '../../hooks/useCoworker/useCoworker';
import { EmployeeStatus, useEmployeeCoworkers } from '../../uc-api-sdk';
import { Employee } from '../../types/user';
import { ApiRequestHelper } from '../../helpers/ApiRequest';
import { AssigneeSelectTypeValue } from '../../uiComponent/AssigneeSelectComponent/type';
import { SelectType } from '../../features/Input/types';
import useDebounce from '../../hooks/useDebounce/useDebounce';

export interface AssigneeSelectContainerChildrenProps {
  assigneeDataList: Employee[];
  isLoading: boolean;
  id?: string;
  value?: SelectType<AssigneeSelectTypeValue>['value'];
  onChange?: SelectType<AssigneeSelectTypeValue>['onChange'];
}

export interface AssigneeSelectContainerProps extends Omit<SelectType<AssigneeSelectTypeValue>, 'children'> {
  roles?: typeof PORTAL_USER_FILTER;
  ucOrgIds?: string[];
  clinicIds?: string[];
  statusList?: EmployeeStatus[];
  children: (props: AssigneeSelectContainerChildrenProps) => ReactNode;
  needToFilter?: boolean;
  internalEmployeeForPatientId?: string;
  externalEmployeeForPatientId?: string;
}

export const AssigneeSelectContainer = ({
  roles = PORTAL_USER_FILTER,
  ucOrgIds = [],
  clinicIds = [],
  statusList = [EmployeeStatus.ACTIVATED],
  children,
  id,
  value,
  onChange,
  needToFilter,
  internalEmployeeForPatientId,
  externalEmployeeForPatientId,
}: AssigneeSelectContainerProps) => {
  const coWorkerSearchInfo = useEmployeeCoworkers({});

  const getAssigneeDataList = useDebounce((async () => {
    const req = coWorkerSearchInfo.send({
      params: {
        searchRequest: {
          filter: {
            roleList: roles,
            ucOrgIdList: ucOrgIds,
            clinicIdList: clinicIds,
            statusList,
            needToFilter,
            internalEmployeeForPatientId,
            externalEmployeeForPatientId,
          },
        },
      },
    });
    ApiRequestHelper.tryCatch(req, 'Failed to load assignee');
  }), 500, [
    roles,
    ucOrgIds,
    clinicIds,
    statusList,
  ]);

  useEffectOnce(() => {
    getAssigneeDataList();
  });

  const assigneeDataList = (coWorkerSearchInfo.data?.data || []) as Employee[];

  return (
    <>
      {
        children({
          assigneeDataList,
          isLoading: coWorkerSearchInfo.isLoading,
          id,
          value,
          onChange,
        })
      }
    </>
  );
};
