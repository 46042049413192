import { SelectProps } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { ClinicSelectComponent } from '../../../../uiComponent/ClinicSelectComponent/ClinicSelectComponent';
import { TableSearchComponent } from '../TableSearchComponent/TableSearchComponent';

export interface ClinicFilterComponentProps extends FilterDropdownProps {
  mode?: SelectProps['mode'];
  containerId?: string;
}

export const ClinicFilterComponent = ({
  ...props
}: ClinicFilterComponentProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <TableSearchComponent {...props} relative>
    <ClinicSelectComponent
      value={props.selectedKeys?.[0] as string | undefined}
      onChange={(id) => {
        props.setSelectedKeys(id ? [id] : []);
      }}
      containerId={props.containerId}
      mode={props.mode}
      className="w400"
    />
  </TableSearchComponent>
);
