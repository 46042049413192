import {
  FC,
  ReactNode
} from 'react';
import { PrintButtonComponent } from '../../../../uiComponent/PrintButtonComponent/PrintButtonComponent';

export interface PrintVitalsButtonComponentProps {
  isLoading: boolean;
  children: ReactNode;
}

export const PrintVitalsButtonComponent: FC<PrintVitalsButtonComponentProps> = ({
  isLoading,
  children,
}) => (
  <div className="flex jc-e">
    <PrintButtonComponent
      disabled={isLoading}
      isLoading={isLoading}
      buttonType="primary"
    >
      {!isLoading && children}
    </PrintButtonComponent>
  </div>
);
