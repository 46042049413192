import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Space, Switch } from 'antd';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { InputTypeRequired } from '../../../Input/types';
import './OtpSwitchComponent.scss';

export interface OtpSwitchComponentProps extends Partial<InputTypeRequired<boolean>> {
  className?: string;
}

export const OtpSwitchComponent = ({
  value,
  onChange,
  ...props
}: OtpSwitchComponentProps) => {
  const handleChange: SwitchChangeEventHandler = (checked) => {
    onChange?.(checked);
  };

  return (
    <Space align="center" wrap>
      <Switch
        checked={value}
        defaultChecked
        className="otp-switch-component"
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        onChange={handleChange}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      <div className="flex ai-c gap0">
        App OTP Login
        <TooltipComponent
          type="question-icon"
          title="OTP stands for one-time password. When enabled, patient can request a OTP through SMS, and login with mobile number + the OTP. One mobile number can only be used to login one patient."
        />
      </div>
    </Space>
  );
};
