import { useMemo, useState } from 'react';
import {
  Button,
  Divider,
  Form,
  Popconfirm,
} from 'antd';
import { difference, filter, map } from 'lodash';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { OrderDeliveryMethodFormItemComponent } from '../OrderDeliveryMethodFormItemComponent/OrderDeliveryMethodFormItemComponent';
import { OrderShippingAddressFormItemComponent } from '../OrderShippingAddressFormItemComponent/OrderShippingAddressFormItemComponent';
import {
  OrderFormValues, useOrderFormHook
} from '../../hook/useOrderFormHook';
import { OrderReturnReminderComponent, UnassignDevice } from '../OrderReturnReminderComponent/OrderReturnReminderComponent';
import { OrderSuppliesFormItemComponent } from '../OrderSuppliesFormItemComponent/OrderSuppliesFormItemComponent';
import { OrderDevicesFormItemComponent } from '../OrderDevicesFormItemComponent/OrderDevicesFormItemComponent';
import { useOrderSuppliesContext } from '../../../../contexts/OrderSuppliesContext/OrderSuppliesContext';

import './OrderSuppliesReplacementFormComponent.scss';
import { useGetDevicesToReturn } from '../../hook/useGetDevicesToReturn';
import { DeviceModelEnum } from '../../../../uc-api-sdk';
import { FormType } from '../../../Input/types';

export interface OrderSuppliesReplacementFormComponentProps
  extends FormType<OrderFormValues> {
  onSubmit: (value: OrderFormValues, unassignDevices?: UnassignDevice[]) => void;
}

export const OrderSuppliesReplacementFormComponent = ({
  initialValues,
  onSubmit,
  onCancel,
  isLoading,
}: OrderSuppliesReplacementFormComponentProps) => {
  const {
    allMostRecentDevices: devicesNotIncludedLoanerPhone,
    loanerPhone,
  } = useOrderSuppliesContext();
  const orderForm = useOrderFormHook();
  const getDevicesToReturn = useGetDevicesToReturn();
  const [unassignedDevices, setUnassignedDevices] = useState<UnassignDevice[]>([]);
  const allMostRecentDevices = useMemo(() => {
    const devices = devicesNotIncludedLoanerPhone || [];
    if (loanerPhone) {
      return [
        ...devices,
        {
          deviceId: loanerPhone.deviceId as string,
          deviceModel: DeviceModelEnum.LOAN_DEVICE,
        },
      ];
    }
    return [...devices];
  }, [
    devicesNotIncludedLoanerPhone,
    loanerPhone
  ]);

  const renderCategoryTitle = (title: string, className?: string) => (
    <TextComponent size="large" color="gray2" className={`mb5 ${className}`}>
      {title}
    </TextComponent>
  );

  const handleOnUnassignDevice = (unassignDevice: UnassignDevice) => {
    setUnassignedDevices((prev) => [
      ...prev,
      unassignDevice
    ]);
  };

  const handleRemoveUnassignDevice = (newDeviceId: string) => {
    setUnassignedDevices((prev) => filter(prev, (device) => device.newDeviceId !== newDeviceId));
  };

  const handleOnSubmit = (values: OrderFormValues) => {
    onSubmit?.(values, unassignedDevices);
  };

  return (
    <Form
      initialValues={initialValues}
      onFinish={orderForm.handleSubmit(handleOnSubmit)}
      layout="vertical"
      colon={false}
      form={orderForm.form}
      disabled={orderForm.formDisabled || isLoading}
      className="order-device-supplies-form"
    >
      <div>
        <OrderSuppliesFormItemComponent
          title={renderCategoryTitle('Supplies')}
          onUncheck={handleRemoveUnassignDevice}
        />

        <OrderDevicesFormItemComponent
          title={renderCategoryTitle('Devices', 'mt20')}
          onUncheck={handleRemoveUnassignDevice}
        />

        <FormItem
          noStyle
          shouldUpdate
        >
          {
            ({ getFieldsValue }) => {
              const {
                suppliesList,
                devicesList
              } = getFieldsValue() as OrderFormValues;

              return (
                <OrderReturnReminderComponent
                  suppliesList={suppliesList}
                  devicesList={devicesList}
                  allMostRecentDevices={allMostRecentDevices}
                  unassignedDevices={unassignedDevices}
                  onUnassign={handleOnUnassignDevice}
                />
              );
            }
          }
        </FormItem>

        <div className="mt20 mb30">
          <OrderDeliveryMethodFormItemComponent />
        </div>

        <OrderShippingAddressFormItemComponent />
      </div>

      <div className="mt40 pb20">
        <Divider />
        <FormItem
          noStyle
          shouldUpdate
        >
          {
            ({ getFieldsValue }) => {
              const {
                deliveryMethod,
                suppliesList,
                devicesList,
              } = getFieldsValue() as OrderFormValues;
              const isAnyOrderItemChecked = orderForm.isAnyOrderItemChecked(getFieldsValue);
              const devicesToReturn = getDevicesToReturn(
                suppliesList,
                devicesList,
                allMostRecentDevices
              );
              const needActionDeviceIds = map(
                filter(devicesToReturn, (device) => device.needAction),
                'deviceId'
              );
              const hasDeviceNeedAction = difference(
                needActionDeviceIds,
                map(unassignedDevices, 'deviceId')
              ).length > 0;
              return (
                <div className="flex gap3 jc-e ai-c">
                  <Button onClick={onCancel}>
                    Cancel
                  </Button>
                  <Popconfirm
                    title={(
                      <>
                        <TextComponent
                          className="mb20"
                          size="large"
                          bold
                        >
                          You haven't unassign the problematic device from patient.
                        </TextComponent>
                        <div>
                          If the patient has already received the new device,
                          please remove the old device assignment from their record.
                        </div>
                      </>
                    )}
                    okText="Confirm"
                    icon={null}
                    overlayClassName="order-submit-popconfirm"
                    disabled={!hasDeviceNeedAction}
                    okButtonProps={{ size: 'middle' }}
                    cancelButtonProps={{ size: 'middle' }}
                    placement="topRight"
                  >
                    <Button
                      type="primary"
                      onClick={() => {
                        if (hasDeviceNeedAction) {
                          // prevent submit when there is old device assignment
                          return;
                        }
                        orderForm?.form?.submit();
                      }}
                      disabled={!isAnyOrderItemChecked || !deliveryMethod || isLoading}
                    >
                      Submit Order
                    </Button>
                  </Popconfirm>
                </div>
              );
            }
          }
        </FormItem>
      </div>
    </Form>
  );
};
