import { DropdownProps } from 'antd';
import { useMemo } from 'react';
import { useVisibility } from '../../../../../contexts/VisibilityContext/VisibilityContext';
import { platformAdminPortalMenuItem, platformBillerPortalMenuItem, platformCarePortalMenuItem } from './constant';

export const makePlatformMenuItem = () => {
  const visibility = useVisibility();
  const finalPlatformMenuItem = useMemo(() => {
    const item = {
      label: 'Platform',
      key: 'platform',
      type: 'group',
      children: [] as NonNullable<NonNullable<DropdownProps['menu']>['items']>,
    };
    if (visibility.showPlatformCarePortal) {
      item.children.push(platformCarePortalMenuItem);
    }
    if (visibility.showPlatformBillerPortal) {
      item.children.push(platformBillerPortalMenuItem);
    }

    if (visibility.showPlatformAdminPortal) {
      item.children.push(platformAdminPortalMenuItem);
    }
    return item;
  }, [
    visibility.showPlatformCarePortal,
    visibility.showPlatformAdminPortal,
    visibility.showPlatformBillerPortal,
  ]);

  if (finalPlatformMenuItem.children.length < 2) {
    // hide platform option when there is only one item
    return undefined;
  }

  return finalPlatformMenuItem;
};
