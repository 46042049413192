import React from 'react';
import { Space } from 'antd';
import { VisitIconComponent } from '../../../visit/component/VisitIconComponent/VisitIconComponent';
import { VisitMethodEnum } from '../../../../uc-api-sdk';

export interface VisitMethodComponentProps {
  value: VisitMethodEnum;
}

export const VisitMethodComponent = ({
  value,
}: VisitMethodComponentProps) => {
  const getText = () => {
    switch (value) {
      case VisitMethodEnum.IN_CLINIC: return 'In-clinic';
      case VisitMethodEnum.PHONE: return 'Phone';
      case VisitMethodEnum.VIRTUAL: return 'Video';
      default: return null;
    }
  };

  return (
    <Space>
      <VisitIconComponent value={value} />
      <span>{getText()}</span>
    </Space>
  );
};
