import classNames from 'classnames';
import { useState } from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import { Input, Space } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { InputType } from '../../../Input/types';
import './VisitSummaryInputComponent.scss';
import { RewriteWithAIModalButtonComponent } from '../../../rewriteWithAI/component/RewriteWithAIModalButtonComponent/RewriteWithAIModalButtonComponent';

export interface VisitSummaryInputComponentProps extends InputType<string> {
  showRequiredMark?: boolean;
}

export const VisitSummaryInputComponent = ({
  value,
  onChange,
  disabled,
  showRequiredMark,
}: VisitSummaryInputComponentProps) => {
  const [originalContent, setOriginalContent] = useState(''); // Manage original content separately

  const handleChange: TextAreaProps['onChange'] = (evt) => {
    onChange?.(evt.target.value);
  };

  const handleRewriteButtonClick = () => {
    setOriginalContent(value ?? '');
  };

  return (
    <div className="visit-summary-input-wrapper">
      <Input.TextArea
        className={classNames({
          'visit-summary-input-component': true,
          required: !!showRequiredMark,
        })}
        placeholder="Visit summary"
        value={value}
        onChange={handleChange}
        rows={10}
        disabled={disabled}
      />
      {value && (
      <RewriteWithAIModalButtonComponent
        mode="rewriteNote"
        originalContent={originalContent}
        onClick={handleRewriteButtonClick}
      />
      )}
      {
        showRequiredMark
        && (
          <Space>
            <InfoCircleFilled className="danger-svg" />
            <div>Missing!</div>
          </Space>
        )
      }
    </div>
  );
};
