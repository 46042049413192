import { MenuProps } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { compact } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { CurrentUserAvatarComponent } from '../../../../../uiComponent/CurrentUserAvatarComponent/CurrentUserAvatarComponent';
import DropdownComponent from '../../../../../uiComponent/DropdownComponent/DropdownComponent';
import {
  userInfoMenuItem,
} from './constant';

import './LoggedInUserDropdownComponent.scss';
import useBoolean from '../../../../../hooks/useBoolean/useBoolean';

export interface LoggedInUserDropdownComponentProps {
  items?: (ItemType | undefined)[];
  children?: ReactNode;
  includeUserInfo?: boolean;
}

export const LoggedInUserDropdownComponent = ({
  items = [],
  children,
  includeUserInfo = true,
}: LoggedInUserDropdownComponentProps) => {
  const {
    value: open,
    setFalse: setClose,
    setValue,
  } = useBoolean();

  const compactedItems = useMemo(() => compact(items), [items]);

  return (
    <DropdownComponent
      openState={open}
      setOpenState={setValue}
      menu={{
        items: [
          ...(
            includeUserInfo
              ? [
                userInfoMenuItem,
                { type: 'divider' },
              ]
              : []
          ),
          ...compactedItems,
        ] as MenuProps['items'],
        onClick: setClose,
      }}
      overlayClassName="logged-in-user-dropdown"
    >
      {
        children
        || (
          <CurrentUserAvatarComponent />
        )
      }
    </DropdownComponent>
  );
};
