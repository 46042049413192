import { useFetchComponentContext } from '../../../../contexts/FetchComponentContext/FetchComponentContext';
import { useUpdate, useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { CalendarSourceEnum, ClinicEvent } from '../../../../uc-api-sdk';
import { CreateVisitContainer } from '../../../visit/container/CreateVisitContainer/CreateVisitContainer';
import { NEW_EVENT_ID } from '../../constant';
import { EventViewContainer } from '../../container/EventViewContainer/EventViewContainer';
import { VisitViewContainer } from '../../container/VisitViewContainer/VisitViewContainer';
import { useCreateVisitContext } from '../../context/CreateVisitContext/CreateVisitContext';
import { CalendarEvent } from '../BigCalendarComponent/BigCalendarComponent';
import { CalendarGoogleFullEventComponent } from '../CalendarGoogleFullEventComponent/CalendarGoogleFullEventComponent';
import { CalendarNoAccessModalContentComponent } from '../CalendarNoAccessModalContentComponent/CalendarNoAccessModalContentComponent';

export interface CalendarEventModalContentComponentProps {
  event: CalendarEvent<ClinicEvent>;
  onClose: () => void;
}

export const CalendarEventModalContentComponent = ({
  event,
  onClose,
}: CalendarEventModalContentComponentProps) => {
  const calendarContainerContext = useFetchComponentContext();
  const calendarContext = useCreateVisitContext();
  const debouncedRefetch = useDebounce(() => {
    calendarContainerContext?.refetch();
  }, 500, [calendarContainerContext?.refetch]);

  useUpdateListener('TRANSCRIBE_FINISHED', debouncedRefetch);
  const updateVisitsAndEventsTable = useUpdate('UPDATE_VISITS_AND_EVENTS_TABLE');

  const handleSubmit = () => {
    onClose();
    updateVisitsAndEventsTable.updateValue();
    debouncedRefetch?.();
  };

  const handleOpenEditMode = () => {
    calendarContext?.setTempEventAsSelectedEvent(true);
  };

  const handleCloseEditMode = () => {
    calendarContext?.setTempEventAsSelectedEvent(false);
  };

  const handleCloseEdit = () => {
    handleCloseEditMode();
    handleSubmit();
  };

  const renderContent = () => {
    if (event?.info?.accessToView === false) {
      return (
        <CalendarNoAccessModalContentComponent
          onClose={onClose}
          start={event.start}
          end={event.end}
        />
      );
    }
    if (event?.id === NEW_EVENT_ID) {
      return (
        <CreateVisitContainer
          disabledFields={event.disabledFields}
          onClose={handleSubmit}
        />
      );
    }
    if (calendarContext?.selectedEvent?.isEdit) {
      return (
        <CreateVisitContainer
          visit={event.info}
          isEdit
          onClose={handleCloseEdit}
        />
      );
    }
    if (event?.info?.visitType) {
      return (
        <VisitViewContainer
          visit={event}
          onDelete={handleSubmit}
          onEdit={handleOpenEditMode}
          onClose={onClose}
        />
      );
    }
    if (event?.info?.calendarSource === CalendarSourceEnum.OTHER) {
      return (
        <EventViewContainer
          onClose={onClose}
          onDelete={handleSubmit}
          onEdit={handleOpenEditMode}
          visit={event}
        />
      );
    }
    return (
      <CalendarGoogleFullEventComponent
        onClose={onClose}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...event}
      />
    );
  };

  return (
    <div className="calendar-pop-over-content">
      {renderContent()}
    </div>
  );
};
