import { Button, Radio, Space } from 'antd';
import { RadioButtonProps } from 'antd/lib/radio/radioButton';
import dayjs from 'dayjs';
import React, { ReactNode } from 'react';
import { NavigateAction, View } from 'react-big-calendar';
import { Title } from '../../../../uiComponent/Title/Title';
import { CalendarTitleComponent } from '../CalendarTitleComponent/CalendarTitleComponent';
import './BigCalendarToolbarComponent.scss';

export interface BigCalendarToolbarComponentProps {
  date: Date;
  view: View;
  onView: (view: View) => void;
  onNavigate: (navigate: NavigateAction, date?: Date | undefined) => void;
  extra: ReactNode;
}

export const BigCalendarToolbarComponent = ({
  date,
  onView,
  view,
  onNavigate,
  extra,
}: BigCalendarToolbarComponentProps) => {
  const d = dayjs(date);

  const handleChange: RadioButtonProps['onChange'] = (evt) => {
    onView(evt.target.value);
  };

  const selectToday = () => {
    onNavigate('TODAY');
  };

  const goToPrevMonth = () => {
    onNavigate('PREV');
  };

  const goToNextMonth = () => {
    onNavigate('NEXT');
  };

  return (
    <div className="big-cal-toolbar">
      <div>
        <Title noMargin>
          <Space size="large">
            <Space>
              <CalendarTitleComponent
                view={view}
                date={d}
                onGoPrev={goToPrevMonth}
                onGoNext={goToNextMonth}
              />
            </Space>
            <Button onClick={selectToday}>Today</Button>
          </Space>
        </Title>
      </div>
      <div>
        <Radio.Group value={view || 'week'} onChange={handleChange}>
          <Radio.Button value="day">Day</Radio.Button>
          <Radio.Button value="week">Week</Radio.Button>
          <Radio.Button value="month">Month</Radio.Button>
        </Radio.Group>
      </div>
      <div>
        {extra}
      </div>
    </div>
  );
};
