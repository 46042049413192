import { Table } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { BGTableColumns } from './BGTableColumns';
import { BGTableDataType } from '../../type/BGTypes';
import './BGTableComponent.scss';
import '../vitalsTable.scss';

export interface BGTableComponentProps {
  data: BGTableDataType[];
}

export const BGTableComponent: FC<BGTableComponentProps> = ({
  data,
}) => {
  const columns = useTableColumns(BGTableColumns, ['date', 'breakfast', 'lunch', 'dinner', 'bedtime', 'overnight']);

  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={false}
      className={classNames({
        'table-header-cell-center': true,
        'bg-logbook-table': true,
        'log-book-table': true,
        'table-cell-no-padding': true,
        'vitals-bordered-table': true,
        'vitals-bordered-table-bg': true,
      })}
      bordered
      // each cell is 44px tall
      // we need to show 7 days at a time
      scroll={{ y: 44 * 7 }}
      rowKey={(v) => v.date}
    />
  );
};
