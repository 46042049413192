import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { FC } from 'react';

const { TextArea } = Input;
export interface MedicationInstructionInputComponentProps {
  value?: TextAreaProps['value'];
  onChange?: TextAreaProps['onChange']
}

export const MedicationInstructionInputComponent: FC<MedicationInstructionInputComponentProps> = ({
  value,
  onChange,
}) => (
  <TextArea
    rows={3}
    value={value}
    onChange={onChange}
    maxLength={250}
    placeholder="Instructions"
    allowClear
    onKeyDown={(e) => {
      e.stopPropagation();
    }}
  />
);
