import { InfoCircleFilled, InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Popover, Space, Tooltip } from 'antd';
import { TooltipPropsWithOverlay } from 'antd/es/tooltip';
import classNames from 'classnames';
import { FC, useMemo } from 'react';
import './TooltipComponent.scss';
import { Icons } from '../../icons/Icons';

export type TooltipStyle = null
  | 'dashed'
  | 'underlined'
  | 'info-icon'
  | 'info-icon-filled'
  | 'question-icon'
  | 'wand';
export interface x {
  h: string;
  k: number;
}

export interface TooltipComponentProps extends TooltipPropsWithOverlay {
  type: TooltipStyle;
  showIcon?: boolean;
  showIconBefore?: boolean;
  isPopover?: boolean;
  showPointer?: boolean;
  highlightOnHover?: boolean;
  triggerOnHoverIcon?: boolean;
}

export const TooltipComponent: FC<TooltipComponentProps> = ({
  children,
  type = 'info-icon',
  showIcon = true,
  className = '',
  showIconBefore = false,
  isPopover = false,
  overlay,
  showPointer = true,
  highlightOnHover = true,
  triggerOnHoverIcon = false,
  ...props
}) => {
  const icon = useMemo(() => {
    switch (type) {
      case 'info-icon': return <InfoCircleOutlined />;
      case 'info-icon-filled': return <InfoCircleFilled />;
      case 'question-icon': return <QuestionCircleOutlined />;
      case 'wand': return <Icons.Wand className="wand-icon" asSVG />;
      default: return null;
    }
  }, [type]);

  const getTooltipClassName = () => classNames({
    'tooltip-component flex': true,
    'dashed-children': type === 'dashed',
    'underlined-children': type === 'underlined',
    'question-children': type === 'question-icon',
    highlight: highlightOnHover,
    pointer: showPointer,
    [className]: !!className,
  });

  const TooltipComp = isPopover ? Popover : Tooltip;

  return (
    <Space>
      {triggerOnHoverIcon && !showIconBefore && <div>{children}</div>}
      <TooltipComp
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(isPopover ? { content: overlay } : { overlay })}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        <Space
          data-testid="TooltipComponent"
          className={getTooltipClassName()}
        >
          {showIcon && showIconBefore && <div>{icon}</div>}
          {!triggerOnHoverIcon && <div>{children}</div>}
          {showIcon && !showIconBefore && <div>{icon}</div>}
        </Space>
      </TooltipComp>
      {triggerOnHoverIcon && showIconBefore && <div>{children}</div>}
    </Space>
  );
};

export default TooltipComponent;
