import {
  Card, CardProps,
} from 'antd';
import { ArticleMetaData } from '../../../../services/CHSServices/types/data';

import './MessageArticleItemComponent.scss';

export interface MessageArticleItemComponentProps {
  url: ArticleMetaData['url'];
  name: ArticleMetaData['name'];
  summary?: ArticleMetaData['summary'];
  thumbnailImageUrl?: ArticleMetaData['thumbnailImageUrl'];
}

export const MessageArticleItemComponent = ({
  url,
  name,
  summary,
  thumbnailImageUrl,
}: MessageArticleItemComponentProps) => {
  const handleOpenArticleUrl: CardProps['onClick'] = (
    e,
  ) => {
    if (!url) return;
    e.preventDefault();
    window.open(url);
  };

  return (
    <div className="message-article-item-component">
      <Card
        hoverable
        cover={(
          thumbnailImageUrl
            ? (
              <img
                alt="thumbnailImageUrl"
                src={thumbnailImageUrl}
              />
            ) : null
        )}
        onClick={handleOpenArticleUrl}
      >
        <Card.Meta
          title={name}
          description={summary}
        />
      </Card>
    </div>
  );
};
