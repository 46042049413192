import { Select } from 'antd';
import { map } from 'lodash';
import { FC, useCallback } from 'react';
import { SelectType } from '../../features/Input/types';
import { LanguageType } from '../../types/languages';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { DeviceHelper } from '../../features/device/helper';

export interface ClearDeviceLanguageSelectComponentProps extends SelectType<LanguageType> {
  className?: string;
}

export const ClearDeviceLanguageSelectComponent: FC<ClearDeviceLanguageSelectComponentProps> = ({
  id,
  onChange,
  value,
  className = '',
}) => {
  const renderOptions = useCallback(() => map(DeviceHelper.BPM1DeviceLanguage, (v, k) => (
    <Select.Option
      key={k}
      value={v.value}
    >
      <DisplayOrEmptyComponent<string> value={v.label} />
    </Select.Option>
  )), []);

  return (
    <Select
      id={id}
      showSearch
      value={value}
      className={className}
      defaultActiveFirstOption={false}
      showArrow
      onChange={onChange}
      notFoundContent={null}
    >
      {renderOptions()}
    </Select>
  );
};

export default ClearDeviceLanguageSelectComponent;
