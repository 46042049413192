import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { LoggedInUserProvider } from './contexts/loggedInUserContext/LoggedInUserContext';
import './index.css';
import { graphQLClient } from './lib/gqlClient/gqlClient';
import reportWebVitals from './reportWebVitals';

import { ApiProvider } from './contexts/ApiProvider/ApiProvider';
import { BillableTimeProvider } from './contexts/BillableTimeContext/BillableTimeContext';
import { MixpanelProvider } from './contexts/MixpanelContext/MixpanelContext';
import { MixpanelMainProvider } from './contexts/MixpanelContext/MixpanelMainContext';
import { SetupGuideContextProvider } from './contexts/SetupGuideContext/SetupGuideContext';
import { UpdateContextProvider } from './contexts/UpdateContext/UpdateContext';
import { VisibilityContextProvider } from './contexts/VisibilityContext/VisibilityContext';
import { HeartbeatEventContainer } from './features/billableTime/container/HeartbeatEventContainer/HeartbeatEventContainer';
import { RoutesComponent } from './Router';
import { ErrorBoundaryAppComponent } from './uiComponent/ErrorBoundaryComponent/ErrorBoundaryAppComponent';
import EnvConfig from './configs/envConfig/envConfig';
import { ErrorSimulatorComponent } from './uiComponent/ErrorBoundaryComponent/simulator/ErrorSimulator';
import { StorageKeyEnum, useSessionStorage } from './hooks/useSessionStorage';
import { AntdConfigProvider } from './configs/AntdConfigProvider/AntdConfigProvider';

window.React = React;
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const basename = process.env.REACT_APP_BASENAME || '';

const App = () => {
  const [
    open
  ] = useSessionStorage<boolean>(
    'ErrorSimulator' as StorageKeyEnum,
  );

  return (
    <>
      <ErrorBoundaryAppComponent>
        <ApiProvider>
          <AntdConfigProvider>
            <QueryClientProvider client={graphQLClient}>
              <LoggedInUserProvider>
                <VisibilityContextProvider>
                  <BillableTimeProvider>
                    <HeartbeatEventContainer>
                      <BrowserRouter basename={basename}>
                        <UpdateContextProvider>
                          <SetupGuideContextProvider>
                            <MixpanelMainProvider>
                              <MixpanelProvider>
                                <ErrorBoundaryAppComponent>
                                  <RoutesComponent />
                                </ErrorBoundaryAppComponent>
                              </MixpanelProvider>
                            </MixpanelMainProvider>
                          </SetupGuideContextProvider>
                        </UpdateContextProvider>
                      </BrowserRouter>
                    </HeartbeatEventContainer>
                  </BillableTimeProvider>
                </VisibilityContextProvider>
              </LoggedInUserProvider>
            </QueryClientProvider>
          </AntdConfigProvider>
        </ApiProvider>
      </ErrorBoundaryAppComponent>
      {open && <ErrorSimulatorComponent />}
    </>
  );
};

const RootComponent = () => (
  EnvConfig.isStrictModeOff
    ? (
      <>
        <div className="strict-mode-off-note">
          Strict Mode is disabled
        </div>
        <App />
      </>
    )
    : (
      <React.StrictMode>
        <App />
      </React.StrictMode>
    )
);

root.render(
  <RootComponent />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
