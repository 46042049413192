import { RoleTypeEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface RoleTypeEnumComponentProps {
  value?: RoleTypeEnum;
}

export const getFullRoleName = (value?: RoleTypeEnum) => {
  switch (value) {
    case RoleTypeEnum.HC: return 'HC';
    case RoleTypeEnum.CA: return 'CA';
    case RoleTypeEnum.RD: return 'RD';
    case RoleTypeEnum.NP: return 'NP';
    case RoleTypeEnum.ADMIN: return 'Admin';
    case RoleTypeEnum.BILLER: return 'Unified Care Biller';
    case RoleTypeEnum.CALL_CENTER_REPRESENTATIVE: return 'Call Center Rep.';
    case RoleTypeEnum.MANAGER: return 'Manager';
    case RoleTypeEnum.PROVIDER: return 'MD';
    case RoleTypeEnum.MA: return 'MA';
    case RoleTypeEnum.CLINICAL_GROUP_BILLER: return 'Clinic biller';
    case RoleTypeEnum.QUALITY_TEAM_MEMBER: return 'Quality team member';
    case RoleTypeEnum.SUPER_ADMIN: return 'Super Admin';
    case RoleTypeEnum.MEMBER: return 'Patient';
    case RoleTypeEnum.CLINICAL_MANAGER: return 'Clinic Manager';
    default: return '';
  }
};

export const RoleTypeEnumComponent = ({ value }: RoleTypeEnumComponentProps) => (
  <DisplayOrEmptyComponent value={getFullRoleName(value)} />
);
