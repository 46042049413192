import { HiatusStatusReasonEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface HiatusReasonEnumComponentProps {
  value?: HiatusStatusReasonEnum;
}
export const HiatusReasonEnumComponent = ({ value }: HiatusReasonEnumComponentProps) => {
  const getFullName = () => {
    switch (value) {
      case HiatusStatusReasonEnum.INACTIVITY: return 'Inactive';
      case HiatusStatusReasonEnum.EXTENDED_TRAVEL: return 'Extended Travel';
      case HiatusStatusReasonEnum.HOSPITALIZATION: return 'Hospitalization/physically unable';
      case HiatusStatusReasonEnum.INSURANCE_MATTERS: return 'Insurance Matters';
      case HiatusStatusReasonEnum.HEALTH_WELL_CONTROL: return 'Health well control';
      case HiatusStatusReasonEnum.PROGRAM_OVERWHELMING: return 'Program overwhelming';
      case HiatusStatusReasonEnum.FAMILY_ISSUES: return 'Family issue';
      case HiatusStatusReasonEnum.OTHERS: return 'Others';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  return (<>{getFullName()}</>);
};
