import {
  useEffect, useMemo
} from 'react';
import { map } from 'lodash';
import {
  Employee,
  EmployeeFilter,
  EmployeeStatus,
  RoleTypeEnum,
  useEmployeeCoworkers,
} from '../../../../uc-api-sdk';
import {
  EmployeeSelectComponent,
  EmployeeSelectComponentInfo,
  EmployeeSelectComponentProps,
} from '../../component/EmployeeSelectComponent/EmployeeSelectComponent';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';

export interface EmployeeMultipleSelectContainerProps extends
  Omit<EmployeeSelectComponentProps<Employee>, 'value' | 'onChange' | 'onSearch' | 'data' | 'disabled'> {
  value?: string[];
  onChange?: (value: string[]) => void;
  patientId?: string;
  roles?: RoleTypeEnum[];
  internalEmployeeForPatient?: boolean;
  internalEmployeeForMyMedicalOrg?: boolean;
  shouldFilterBasedOnRole?: boolean;
}

export const EmployeeMultipleSelectContainer = ({
  value = [],
  onChange,
  optionRenderer,
  tagRender,
  className,
  patientId,
  roles,
  internalEmployeeForPatient,
  shouldFilterBasedOnRole = true,
  internalEmployeeForMyMedicalOrg,
  ...props
}: EmployeeMultipleSelectContainerProps) => {
  const myCoWorkerInfo = useEmployeeCoworkers({});

  const request = (otherFilter: EmployeeFilter) => {
    myCoWorkerInfo.send({
      params: {
        searchRequest: {
          filter: {
            roleList: roles,
            statusList: [EmployeeStatus.ACTIVATED],
            needToFilter: shouldFilterBasedOnRole,
            internalEmployeeForMyMedicalOrg,
            internalEmployeeForPatientId: internalEmployeeForPatient ? patientId : undefined,
            externalEmployeeForPatientId: patientId,
            ...otherFilter,
          }
        }
      }
    });
  };

  const handleSearch: EmployeeSelectComponentProps<Employee>['onSearch'] = (value) => {
    if (value) {
      request({ nameNear: value });
    }
  };

  useEffect(() => {
    if (value?.length) {
      request({});
    }
  }, [
    value,
    shouldFilterBasedOnRole,
    patientId,
    internalEmployeeForMyMedicalOrg,
  ]);

  const debounceHandleSearch = useDebounce(handleSearch, 400, [
    value,
    patientId,
    shouldFilterBasedOnRole,
    internalEmployeeForPatient,
    internalEmployeeForMyMedicalOrg,
    roles,
  ]);

  const providerList = useMemo<EmployeeSelectComponentInfo<Employee>[]>(() => (
    map(myCoWorkerInfo.data?.data, (v) => ({
      id: v.id || '',
      fullName: `${v.employeeProfile?.firstName} ${v.employeeProfile?.lastName}`,
      info: v,
    }))
  ), [myCoWorkerInfo]);

  const handleChange: EmployeeSelectComponentProps<Employee>['onChange'] = (value) => {
    onChange?.(value);
  };

  return (
    <EmployeeSelectComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      showArrow={myCoWorkerInfo.isLoading}
      isLoading={myCoWorkerInfo.isLoading}
      className={className}
      data={providerList}
      onSearch={debounceHandleSearch}
      value={value}
      optionRenderer={optionRenderer}
      onChange={handleChange}
      placeholder="Search for a user"
      tagRender={tagRender}
      mode="multiple"
    />
  );
};
