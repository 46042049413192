import { RaceEnum } from '../../uc-api-sdk';
import EmptyComponent from '../../uiComponent/EmptyComponent/EmptyComponent';

export interface RaceEnumComponentProps {
  value: RaceEnum;
}

export const RaceEnumComponent = ({
  value
}: RaceEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case RaceEnum.WHITE:
        return 'White';
      case RaceEnum.BAA:
        return 'Black or African American';
      case RaceEnum.AIAN:
        return 'American Indian or Alaska Native';
      case RaceEnum.ASIAN:
        return 'Asian';
      case RaceEnum.NHOPI:
        return 'Native Hawaiian or Other Pacific Islander';
      case RaceEnum.HISPANIC:
        return 'Hispanic';
      case RaceEnum.MULTIRACIAL:
        return 'Multiracial';
      case RaceEnum.OTHER:
        return 'Other';
      case RaceEnum.NOT_TO_SAY:
        return 'Prefer not to say';
      default:
        return <EmptyComponent />;
    }
  };
  return <span className="race-enum">{getText()}</span>;
};
