import { useEffect } from 'react';
import { usePrevious } from '../usePrevious/usePrevious';

export const useEffectWithPrevValue = <T>(value: T, func: (prevValue?: T) => void) => {
  const previous = usePrevious(value);

  useEffect(() => {
    func(previous);
  }, [value]);
};
