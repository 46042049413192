import { omit } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

export const useRouterState = () => {
  const {
    state,
    pathname,
    search,
    ...restLocation
  } = useLocation();
  const navigate = useNavigate();

  const getPath = () => `${pathname}${search}`;

  const removeState = (keys?: string[]): void => {
    let newState = {};
    if (keys && keys.length > 0) {
      newState = omit(state, keys);
    }
    navigate(
      getPath(),
      { state: newState, replace: true, ...restLocation }
    );
  };

  const upsertState = <T extends Record<string, unknown>>(values: T): void => {
    const newState = {
      ...state,
      ...values,
    };
    navigate(
      getPath(),
      { state: newState, replace: true, ...restLocation }
    );
  };

  return {
    state,
    removeState,
    upsertState,
  };
};
