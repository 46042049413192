import { Col } from 'antd';
import { VitalsEnumTypeComponent } from '../../../../enumComponent/VitalsEnumTypeComponent/VitalsEnumTypeComponent';
import { VitalEnumType } from '../../../../uc-api-sdk';
import { PatientProfileDateDisplayComponent, PatientProfileDateDisplayComponentProps } from '../PatientProfileDateDisplayComponent/PatientProfileDateDisplayComponent';

export interface PatientProfileVitalsMonitoringItemComponentProps {
  type: VitalEnumType;
  createdAt?: PatientProfileDateDisplayComponentProps['value'];
}

export const PatientProfileVitalsMonitoringItemComponent = ({
  type,
  createdAt,
}: PatientProfileVitalsMonitoringItemComponentProps) => (
  <div className="flex f-w jc-sb mb20">
    <Col span={14}>
      <VitalsEnumTypeComponent value={type} />
    </Col>
    <Col span={9}>
      <PatientProfileDateDisplayComponent
        value={createdAt}
        label="Added on"
      />
    </Col>
  </div>
);
