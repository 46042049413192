import { CheckCircleTwoTone, CloseCircleOutlined } from '@ant-design/icons';
import { Row, Space, Typography } from 'antd';
import { map } from 'lodash';
import { FC } from 'react';
import { Phone } from '../../../../uc-api-sdk';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import PhoneComponent from '../../../../uiComponent/PhoneComponent/PhoneComponent';
import { InfoComponent, InfoComponentProps } from '../InfoComponent/InfoComponent';
import { OtpSwitchComponent } from '../OtpSwitchComponent/OtpSwitchComponent';
import './DemographicContactInfoComponent.scss';

const { Text } = Typography;
export interface DemographicContactInfoComponentProps {
  mobilePhone?: Phone,
  otp?: boolean,
  smartPhone?: boolean,
  homePhone?: Phone;
  email?: string;
  address?: string;
}
const { Title } = Typography;

export const DemographicContactInfoComponent: FC<DemographicContactInfoComponentProps> = ({
  mobilePhone,
  otp,
  smartPhone,
  homePhone,
  email,
  address,
}) => {
  const dataSet: InfoComponentProps[] = [
    {
      name: 'Mobile Phone*',
      value: mobilePhone?.number,
      span: 6,
      render: (
        <Space direction="vertical" size={0}>
          <Text type="secondary">Mobile Phone*</Text>
          <PhoneComponent
            value={mobilePhone?.number || ''}
            divider={['(', ')', '-']}
            countryCode={mobilePhone?.countryCode || '1'}
            showCountryCode
          />
        </Space>
      )
    },
    {
      name: 'OTP Login',
      span: 6,
      render: () => (
        <OtpSwitchComponent value={otp} onChange={undefined} disabled />
      ),
    },
    {
      name: 'Smartphone',
      span: 6,
      render: () => (
        <Space>
          {smartPhone ? <CheckCircleTwoTone className="fs2" /> : <CloseCircleOutlined className="fs2" />}
          Smartphone
        </Space>
      ),
    },
  ];

  const dataSet2: InfoComponentProps[] = [
    {
      name: 'Home Phone*',
      value: homePhone?.number,
      span: 6,
      render: (
        <Space direction="vertical" size={0}>
          <Text type="secondary">Home Phone*</Text>
          <PhoneComponent
            value={homePhone?.number || ''}
            divider={['(', ')', '-']}
            countryCode={homePhone?.countryCode || '1'}
            showCountryCode
          />
        </Space>
      )
    },
    {
      name: 'E-mail',
      value: email || <EmptyComponent />,
      span: 6,
    },
    {
      name: 'Address',
      value: address,
      span: 12,
      render: () => (
        <>
          <Text type="secondary">Address</Text>
          <div>
            <Space>
              {address ? (
                <Text copyable={{ icon: <Text underline>Copy Address</Text> }}>
                  {address}
                </Text>
              ) : <EmptyComponent />}
            </Space>
          </div>
        </>
      ),
    },
  ];

  const renderColumns = (data: InfoComponentProps[]) => map(data, (d, index) => (
    <InfoComponent
      key={index}
      name={d.name}
      value={d.value}
      span={d.span}
      onClick={d.onClick}
      type={d.type}
      render={d.render}
    />
  ));

  return (
    <div className="DemographicContactInfoComponent flex gap3 fd-c">
      <Title level={5}>Contact Information</Title>
      <Row align="bottom" gutter={7}>
        {renderColumns(dataSet)}
      </Row>
      <Row gutter={7}>
        {renderColumns(dataSet2)}
      </Row>
    </div>
  );
};
