import { useMemo } from 'react';
import { find, map } from 'lodash';
import dayjs from 'dayjs';
import { VersionListItem, usePatientGetVersionList } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { InputType } from '../../../Input/types';
import { useEffectFirstTimeWithValue } from '../../../../hooks/useEffectFirstTimeWithValue/useEffectFirstTimeWithValue';
import { VersionHistory, VersionHistorySelectComponent, VersionValue } from '../../component/VersionHistorySelectComponent/VersionHistorySelectComponent';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';

export interface VersionHistorySelectContainerProps extends InputType<VersionValue> {
  patientId: string;
}

export const VersionHistorySelectContainer = ({
  patientId,
  value,
  onChange,
}: VersionHistorySelectContainerProps) => {
  const versionList = usePatientGetVersionList({
    params: {
      memberId: patientId,
    },
  });

  const setLatestVersion = (v: VersionHistory[] = []) => {
    const latest = find(v, (d: VersionHistory) => d.isLatest);
    if (latest) {
      onChange?.({
        version: latest.version,
        isLatest: true,
      });
    }
  };

  const processData = (data: VersionListItem[] | undefined) => (
    data
      ? map(data, (v): VersionHistory => ({
        version: v.version as string,
        date: dayjs(v.version, 'YYYYMMDD'),
        isLatest: v.isLatest ?? false,
        updatedByUser: v.updatedByUser ?? {},
        entries: v.entities || [],
      }))
      : undefined
  );

  // if intervention updated then refetch, then change to
  // the latest version
  const refetchAndSetLatest = async () => {
    const res = await versionList.refetch();
    setLatestVersion(processData(res?.data ?? undefined));
  };
  useUpdateListener('INTERVENTION_UPDATED', refetchAndSetLatest);

  const processedData = useMemo<VersionHistory[] | undefined>(() => (
    processData(versionList?.data?.data ?? undefined)
  ), [versionList.data]);

  useEffectFirstTimeWithValue(processedData, setLatestVersion);

  if (!versionList.data?.data?.length) {
    return null;
  }

  return (
    <FetchComponent info={versionList} alwaysShowChildren>
      <VersionHistorySelectComponent
        data={processedData || []}
        value={value}
        onChange={onChange}
        onFilterChange={console.log}
        onClick={versionList.refetch}
      />
    </FetchComponent>
  );
};
