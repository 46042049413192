import { FilterDropdownProps } from 'antd/lib/table/interface';
import { Select } from 'antd';
import { useTagsSearch } from '../../helper/useTagSearch';

interface PatientTagSearchSelectContainerProps extends FilterDropdownProps {
  value?: string;
  onChange?: (value: string) => void;
}

export const PatientTagSearchSelectContainer = ({
  value,
  onChange,
}: PatientTagSearchSelectContainerProps) => {
  const { tags, setSearchTerm } = useTagsSearch('');

  const handleSelectChange = (value: string) => {
    onChange?.(value);
  };

  const renderOptions = () => tags.map(tag => (
    <Select.Option key={tag.value} value={tag.value}>
      {tag.label}
    </Select.Option>
  ));

  return (
    <Select
      showSearch
      allowClear
      filterOption={false}
      className="w-full"
      placeholder="Search to Select"
      onSearch={setSearchTerm}
      value={value}
      onChange={handleSelectChange}
    >
      {renderOptions()}
    </Select>
  );
};
