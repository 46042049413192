import { ReactNode } from 'react';
import { TranscribeStatusEnumComponent } from '../../../../enumComponent/TranscribeStatusEnumComponent/TranscribeStatusEnumComponent';
import { TranscribeStatusEnum } from '../../../../uc-api-sdk';
import { ClickableDiv } from '../../../../uiComponent/ClickableDiv/ClickableDiv';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import { TranscribeDrawerComponent } from '../TranscribeDrawerComponent/TranscribeDrawerComponent';
import { TranscribeDrawerBodyComponent } from '../TranscribeDrawerLayoutComponent/TranscribeDrawerBodyComponent';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';

export interface TranscribeStatusButtonComponentProps {
  transcribeId?: string;
  transcribeStatus?: TranscribeStatusEnum;
  button?: ReactNode;
  className?: string;
}

export const TranscribeStatusButtonComponent = ({
  transcribeId,
  transcribeStatus,
  button,
  className,
}: TranscribeStatusButtonComponentProps) => {
  if (!transcribeId) {
    return <EmptyComponent />;
  }

  return (
    <TranscribeDrawerComponent
      transcribeId={transcribeId}
      className={className}
    >
      {({ transcribeData }) => (
        <TranscribeDrawerBodyComponent
          transcribeData={transcribeData}
          showNextPatientButton={false}
        />
      )}
      {
        ({ handleOpenTranscribeDrawer }) => (
          button
            ? (
              <ClickableDiv onClick={() => handleOpenTranscribeDrawer('')}>
                {button}
              </ClickableDiv>
            ) : (
              <LinkButton
                onClick={() => {
                  if (transcribeStatus === TranscribeStatusEnum.COMPLETE) {
                    return;
                  }
                  handleOpenTranscribeDrawer('');
                }}
              >
                <TranscribeStatusEnumComponent value={transcribeStatus} />
              </LinkButton>
            )
        )
      }
    </TranscribeDrawerComponent>
  );
};
