import { DischargeAlertInfoComponent } from '../../../discharge/component/DischargeAlertInfoComponent/DischargeAlertInfoComponent';
import { PauseServiceAlertComponent } from '../PauseServiceComponent/PauseServiceAlertComponent';
import { UnableToEngageAlertComponent } from '../UnableToEngageAlertComponent/UnableToEngageAlertComponent';
import './AlertListComponent.scss';

export const AlertListComponent = () => (
  <div className="flex fd-c gap2 alert-list-component">
    <DischargeAlertInfoComponent />
    <UnableToEngageAlertComponent />
    <PauseServiceAlertComponent />
  </div>
);
