import { Button } from 'antd';
import classNames from 'classnames';
import { useMessagePatientContext } from '../../../../contexts/MessageContext/MessagePatientContext';
import { logWarn } from '../../../../lib/helpers/logging';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';

import './MessageUnreadButtonComponent.scss';

export interface MessageUnreadButtonComponentProps {
  className?: string;
  onUnread?: () => void;
  unreadText?: string;
  disabled?: boolean;
}

export const MessageUnreadButtonComponent = ({
  className = '',
  onUnread,
  unreadText = 'Mark as unread',
  disabled,
}: MessageUnreadButtonComponentProps) => {
  const {
    markChannelAsUnread,
  } = useMessagePatientContext();

  const handleOnClick = useDebounce(async (e) => {
    e.stopPropagation();
    const res = await markChannelAsUnread?.();
    if (res && res.success) {
      onUnread?.();
    }
  }, 250, [
    markChannelAsUnread,
    onUnread,
  ]);

  if (!markChannelAsUnread) {
    logWarn('message patient context not found');
    return null;
  }

  return (
    <Button
      type="link"
      onClick={handleOnClick}
      className={classNames({
        'message-unread-btn': true,
        [className]: !!className,
      })}
      data-testid="messageUnreadBtn"
      disabled={disabled}
    >
      {unreadText}
    </Button>
  );
};
