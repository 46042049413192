import { Table } from 'antd';
import { TableProps } from 'antd/es/table';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { BillableMonthlySummary } from '../../../../uc-api-sdk';
import { createColumns } from './BillingHistoryColumn';

export interface BillingHistoryTableComponentProps extends Omit<TableProps<BillableMonthlySummary>, 'column'> {
}

export const BillingHistoryTableComponent = ({
  ...props
}: BillingHistoryTableComponentProps) => {
  const columns = useTableColumns(
    createColumns(),
    ['monthOfYear', 'billableTime', 'measurementDays'],
  );

  return (
    <Table
      size="middle"
      rowKey={(v) => v.id}
      columns={columns}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};
