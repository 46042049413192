import { Timeline } from 'antd';
import { DisplayDateComponent } from '../DisplayComponent/DisplayDateComponent';

export interface HistoryInfo1 {
  note?: string | null | undefined;
  createdAt?: string | null | undefined;
}

export interface HistoryInfo2 {
  content?: string | null | undefined;
  date?: string | null | undefined;
}

export type HistoryInfo = HistoryInfo1 | HistoryInfo2;

export interface HistoryItemProps<T extends HistoryInfo> {
  note?: T | null | undefined;
  isLatest: boolean;
}

export const HistoryItemComponent = <T extends HistoryInfo>({
  note,
  isLatest,
}: HistoryItemProps<T>) => {
  const getNote = () => {
    const note1 = note as HistoryInfo1;
    const note2 = note as HistoryInfo2;
    return note1.note || note2.content;
  };

  const getDate = () => {
    const note1 = note as HistoryInfo1;
    const note2 = note as HistoryInfo2;
    return note1.createdAt || note2.date;
  };

  return (
    <Timeline.Item
      className={isLatest ? 'latest-note' : 'not-latest-note'}
    >
      {isLatest && (<div> Latest update </div>)}
      <span className={isLatest ? 'latest-update-time' : ''}>
        <DisplayDateComponent
          value={getDate()}
          format="MONTH_DATE_YEAR_TIME_AM_PM"
        />
      </span>
      <div className="history-item">{getNote()}</div>
    </Timeline.Item>
  );
};
