import { CheckboxFilterComponent } from '../component/CheckboxFilterComponent/CheckboxFilterComponent';
import { TableFilterType } from '../type/filter';
import { FilterIcon } from './FilterIcon';

export const createBillableCheckboxFilter = (): TableFilterType => {
  const options = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  return ({
    filterDropdown: ((props) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <CheckboxFilterComponent {...props} filterOptions={options} />
    )),
    filterIcon: FilterIcon,
  });
};
