import React from 'react';
import { ActivityLevelEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface ActivityLevelEnumComponentProps {
  value?: ActivityLevelEnum;
}

export const ActivityLevelEnumComponent = ({ value }: ActivityLevelEnumComponentProps) => {
  const getFullName = () => {
    switch (value) {
      case ActivityLevelEnum.NONE: return 'None selected';
      case ActivityLevelEnum.SEDENTARY: return 'Sedentary (sitting for at least 6 hours per day)';
      case ActivityLevelEnum.ACTIVE: return 'Active (at least 60 minutes per day, 4 days per week)';
      case ActivityLevelEnum.VERY_ACTIVE: return 'Very active (Agricultural or other manual labor; exercising 2+ hours daily)';
      case ActivityLevelEnum.EXTREMELY_INACTIVE: return 'Extremely inactive (sitting for at least 10 hours per day)';
      case ActivityLevelEnum.IRREGULARLY_EXERCISING: return 'Irregularly exercising (exercising, but some weeks are completely sedentary)';
      case ActivityLevelEnum.MODERATELY_ACTIVE: return 'Moderately active (exercising at least 30 minutes per day, 4 days per week)';
      case ActivityLevelEnum.EXTREMELY_ACTIVE: return 'Extremely active (Competitive sports; heavy weight lifting; exercise 3+ hours daily)';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  return (<>{getFullName()}</>);
};
