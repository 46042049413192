import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
} from 'react';
import { MedicationType } from '../../features/medication/type/type';
import { Nullable } from '../../uc-api-sdk';

export interface MedicationContextValue {
  prevMeds: number;
  curMeds: number;
  setPrevMeds: (a: number) => void;
  setCurMeds: (a: number) => void;
  onDelete?: (memberId: string) => void;
  onInsert?: (m: MedicationType) => void;
  onUpdate?: (m: MedicationType, id: Nullable<string> | undefined) => void;
}

const MedicationContext = createContext<MedicationContextValue | undefined>(undefined);

export interface MedicationContextProviderProps extends MedicationContextValue {
  children: ReactNode,
}

export const MedicationContextProvider = ({
  children,
  prevMeds,
  curMeds,
  onDelete,
  onInsert,
  onUpdate,
  setPrevMeds,
  setCurMeds,
}: MedicationContextProviderProps) => {
  const contextValue = useMemo(() => ({
    prevMeds,
    curMeds,
    onDelete,
    onInsert,
    onUpdate,
    setPrevMeds,
    setCurMeds,
  }), [prevMeds, curMeds, onDelete, onInsert, onUpdate, setPrevMeds, setCurMeds]);

  return (
    <MedicationContext.Provider
      value={contextValue}
    >
      {children}
    </MedicationContext.Provider>
  );
};

export const useMedicationContext = () => {
  const context = useContext(MedicationContext);
  return (context || {}) as MedicationContextValue;
};
