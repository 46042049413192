import { Button, Form } from 'antd';
import EmailInputFormItemComponent from '../../../../uiComponent/EmailInputComponent/EmailInputFormItemComponent';
import { LoginHeaderComponent } from '../LoginHeaderComponent/LoginHeaderComponent';

import './ForgotPasswordComponent.scss';

export interface ForgotPasswordFormValues {
  email: string;
}

export interface ForgotPasswordComponentProps {
  onSendLink?: (email: string) => void;
  onBackToLogin?: () => void;
}

export const ForgotPasswordComponent = ({
  onSendLink,
  onBackToLogin,
}: ForgotPasswordComponentProps) => {
  const handleSendLink = (
    inputValues: ForgotPasswordFormValues,
  ) => {
    const { email } = inputValues;
    onSendLink?.(email);
  };

  const handleBackToLogin = () => {
    onBackToLogin?.();
  };

  return (
    <div className="forgot-password-component">
      <LoginHeaderComponent
        header="Forgot Password?"
        subHeader="Enter the email address associated with your account and we'll send an email to reset your password."
      />
      <Form
        onFinish={handleSendLink}
        layout="vertical"
      >
        <EmailInputFormItemComponent
          isRequired
          size="large"
        />
        <Button
          type="primary"
          className="mt10"
          htmlType="submit"
          size="large"
          block
        >
          Send Reset Link
        </Button>
      </Form>
      <Button
        type="link"
        className="mt10"
        onClick={handleBackToLogin}
        block
      >
        Back to Login
      </Button>
    </div>
  );
};
