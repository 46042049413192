import { Radio, RadioGroupProps } from 'antd';
import { InputType } from '../../../Input/types';
import { VisitMethodEnum } from '../../../../uc-api-sdk';

export interface VisitMethodRadioGroupComponentProps extends InputType<VisitMethodEnum> {
  allowVideoCall?: boolean;
}

export const VisitMethodRadioGroupComponent = ({
  value,
  onChange,
  allowVideoCall = true,
}: VisitMethodRadioGroupComponentProps) => {
  const handleChange: RadioGroupProps['onChange'] = (v) => {
    onChange?.(v as unknown as VisitMethodEnum);
  };

  return (
    <Radio.Group onChange={handleChange} value={value}>
      <Radio value={VisitMethodEnum.IN_CLINIC}>In-clinic</Radio>
      <Radio value={VisitMethodEnum.PHONE}>Phone call</Radio>
      {
        allowVideoCall && (
          <Radio value={VisitMethodEnum.VIRTUAL}>Video Call</Radio>
        )
      }
    </Radio.Group>
  );
};
