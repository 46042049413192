import { ReactNode } from 'react';
import { Button, Card } from 'antd';
import { useErrorBoundary } from 'react-error-boundary';
import './ErrorBoundaryCardComponent.scss';
import classNames from 'classnames';
import { resetSimulatorErrorTriggered } from './simulator/ErrorSimulator';

export interface ErrorBoundaryCardComponentProps {
  icon: ReactNode;
  message: ReactNode;
  showRefreshButton?: boolean;
}

export const RESET_BOUNDARY_BUTTON_ID = 'error-boundary-reset-button';

export const resetErrorBoundaryManually = () => {
  const resetButton = document.getElementById(RESET_BOUNDARY_BUTTON_ID);
  if (resetButton) {
    resetButton.click();
  }
};

export const ErrorBoundaryCardComponent = ({
  icon,
  message,
  showRefreshButton,
}: ErrorBoundaryCardComponentProps) => {
  const { resetBoundary } = useErrorBoundary();

  const handleRefresh = () => {
    resetSimulatorErrorTriggered();
    resetBoundary();
  };

  const refreshButton = (
    <Button
      id={RESET_BOUNDARY_BUTTON_ID}
      onClick={handleRefresh}
      className={classNames({
        invisible: !showRefreshButton
      })}
      block
    >
      Refresh
    </Button>
  );

  return (
    <Card className="error-boundary-card-component">
      <div className="error-boundary-card-component-content">
        <div>{icon}</div>
        <div>{message}</div>
        {refreshButton}
      </div>
    </Card>
  );
};
