import { PatientArticles } from '../../../uc-api-sdk';
import { ArticleSentHistoryTableComponent } from '../components/ArticlesSentHistoryTableComponent/ArticlesSentHistoryTableComponent';

import './ArticlesSentHistoryTableContainer.scss';
import OverlayLoading from '../../../uiComponent/OverlayLoading/OverlayLoading';

export interface ArticlesSentHistoryTableContainerProps {
  patientArticles: PatientArticles[];
  totalSize: number;
  isLoading?: boolean;
}

export const ArticlesSentHistoryTableContainer = ({
  isLoading,
  patientArticles,
  totalSize,
}: ArticlesSentHistoryTableContainerProps) => (
  <div className="article-sent-history-table-container">
    <OverlayLoading loading={!!isLoading} />
    <ArticleSentHistoryTableComponent
      articles={patientArticles}
      totalSize={totalSize || 0}
      isLoading={isLoading}
    />
  </div>
);
