import React, { FC } from 'react';
import { MeasurementType } from '../../type/MeasurementTypes';
import { useMeasurementResultToTablePrint } from '../../hook/useMeasurementResultToTablePrint/useMeasurementResultToTablePrint';
import { BGMeasurementService } from '../../helper/BGMeasurementService';
import { BGTablePrintComponent } from './BGTablePrintComponent';

export interface BGTableDataProcessorComponentProps {
  data: MeasurementType[];
}

export const BGTablePrintDataProcessorComponent: FC<BGTableDataProcessorComponentProps> = ({
  data,
}) => {
  const processedData = useMeasurementResultToTablePrint(
    data,
    (curr) => new BGMeasurementService(curr),
  );

  return (
    <BGTablePrintComponent data={processedData} />
  );
};
