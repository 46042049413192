import { ReactNode } from 'react';

import './PrintCarePlanSimpleItemComponent.scss';

export interface PrintCarePlanSimpleItemComponentProps {
  label?: ReactNode;
  content?: ReactNode;
}

export const PrintCarePlanSimpleItemComponent = ({
  label,
  content,
}: PrintCarePlanSimpleItemComponentProps) => (
  <div className="print-care-plan-item">
    <div className="print-care-plan-item-label">
      {label}
    </div>
    <div className="print-care-plan-item-content">
      {content}
    </div>
  </div>
);
