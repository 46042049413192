import { PatientDataHistoryEntityEnum } from '../../uc-api-sdk';
import { BasicEnumComponent } from '../BasicEnumComponent/BasicEnumComponent';

export interface PatientDataHistoryEntityEnumComponentProps {
  value?: PatientDataHistoryEntityEnum;
}

export const PatientDataHistoryEntityEnumComponent = ({
  value,
}: PatientDataHistoryEntityEnumComponentProps) => (
  <BasicEnumComponent value={value} />
);
