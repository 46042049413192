import { usePutWithFormData } from '..';
import { RESTfulResponse } from '../../../types/restful';
import { BucketsTypeEnum, FileCategoryEnum } from '../../../uc-api-sdk';
import StrictFormData from '../formData/strictFormData';

const uploadPath = '/file-manager/upload/';

export type UploadFileFormData = StrictFormData<{
  file: Blob,
  bucket: BucketsTypeEnum;
  category?: FileCategoryEnum;
  id: string; // user id
}>

export interface UploadFileResponse {
  fileKey: string;
  url: string;
}

const useUploadFileRestful = usePutWithFormData<
  UploadFileFormData,
  RESTfulResponse<UploadFileResponse>
>(
  uploadPath,
  {
    logError: true,
    throwError: true,
  },
);

export const useUploadFile = useUploadFileRestful;

export default useUploadFile;
