import { ReactNode } from 'react';
import { SubTitle } from '../../../../uiComponent/Title/SubTitle';
import { Title } from '../../../../uiComponent/Title/Title';

import './LoginHeaderComponent.scss';

export interface LoginHeaderComponentProps {
  header?: string | ReactNode;
  subHeader?: string | ReactNode;
}

export const LoginHeaderComponent = ({
  header,
  subHeader,
}: LoginHeaderComponentProps) => (
  <div className="login-header-component flex fd-c ai-c">
    <Title>
      {header}
    </Title>
    <SubTitle>
      {subHeader}
    </SubTitle>
  </div>
);
