import { Drawer } from 'antd';
import { ProgramEnrollmentFormContainer } from '../../container/ProgramEnrollmentFormContainer/ProgramEnrollmentFormContainer';
import { AssignDevicesFormContainer } from '../../container/AssignDevicesFormContainer/AssignDevicesFormContainer';
import { OnboardingComponent } from '../OnboardingComponent/OnboardingComponent';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { LargeDrawerWidth } from '../../../../constants/drawer';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { EnrollmentSuccessComponent } from '../EnrollmentSuccessComponent/EnrollmentSuccessComponent';
import { Step, useEnrollmentStepContext } from '../../context/EnrollmentStepContext/EnrollmentStepContext';
import './EnrollmentComponent.scss';
import { EnrollmentConsentFormContainer } from '../../container/EnrollmentConsentFormContainer/EnrollmentConsentFormContainer';
import { usePatientMedicalOrganizationContext } from '../../../../contexts/PatientMedicalOrganizationContext/PatientMedicalOrganizationContext';
import { BlockUnmountContextProvider } from '../../../../contexts/BlockUnmountContext/BlockUnmountContext';

export interface EnrollmentComponentProps {
  patientInfo: PatientInfo;
  onClose?: () => void;
}

export const EnrollmentComponent = ({
  patientInfo,
  onClose,
}: EnrollmentComponentProps) => {
  const { currStep: step, goTo: setStep, onDone: onStepDone } = useEnrollmentStepContext();
  const {
    isOpen: isEnrollmentSuccOpen,
    open: openEnrollmentSucc,
    close: closeEnrollmentSucc,
  } = useOpen();
  const hasConsentForm = patientInfo.consentService.hasConsent();
  const { vitalTypes, programCategories } = patientInfo.enrollmentInfo?.programEnrollment || {};
  const { medicalOrganizationService } = usePatientMedicalOrganizationContext();

  const handleProgramEnrollmentSubmit = () => {
    patientInfo.refetch();
    onStepDone(Step.ProgramEnrollment);
    if (hasConsentForm) {
      if (medicalOrganizationService?.isRiverBend()) {
        onClose?.();
        return;
      }
      setStep(Step.AssignDevices);
    } else {
      setStep(Step.Consent);
    }
  };

  const handleOpenConsent = () => {
    setStep(Step.Consent);
  };

  const handleCloseConsent = () => {
    setStep(Step.ProgramEnrollment);
  };

  const handleAssignDevicesSubmit = () => {
    patientInfo.refetch();
    onStepDone(Step.AssignDevices);
    if (medicalOrganizationService?.isStThomas()) {
      onClose?.();
      return;
    }
    setStep(Step.OnboardingVisits);
  };

  // this is only shown after the consent form has been submitted
  if (isEnrollmentSuccOpen) {
    const handleOkay = () => {
      closeEnrollmentSucc();
      patientInfo.refetch();
      if (medicalOrganizationService?.isRiverBend()) {
        onClose?.();
        return;
      }
      setStep(Step.AssignDevices);
    };

    return (
      <EnrollmentSuccessComponent
        vitals={vitalTypes || []}
        enrolledPrograms={programCategories || []}
        onOkay={handleOkay}
      />
    );
  }

  return (
    <div className="enrollment-comp">
      {step === Step.ProgramEnrollment && (
        <BlockUnmountContextProvider>
          <ProgramEnrollmentFormContainer
            patientInfo={patientInfo}
            onSubmit={handleProgramEnrollmentSubmit}
            onClose={onClose}
            onReject={onClose}
            refetch={patientInfo.refetch}
            onOpenConsentForm={handleOpenConsent}
          />
        </BlockUnmountContextProvider>
      )}
      {step === Step.Consent && (
        <Drawer
          open
          width={LargeDrawerWidth}
          onClose={handleCloseConsent}
        >
          <EnrollmentConsentFormContainer
            patientInfo={patientInfo}
            showSentModal={false}
            onSubmit={openEnrollmentSucc}
          />
        </Drawer>
      )}
      {step === Step.AssignDevices && (
        <AssignDevicesFormContainer
          patientInfo={patientInfo}
          onSubmit={handleAssignDevicesSubmit}
          onClose={onClose}
        />
      )}
      {step === Step.OnboardingVisits && (
        <OnboardingComponent
          patientInfo={patientInfo}
          onClose={onClose}
        />
      )}
    </div>
  );
};
