import dayjs, { Dayjs } from 'dayjs';
import { Modal } from 'antd';
import { some } from 'lodash';
import { Patient } from '../../../uc-api-sdk';
import { USA_DATE } from '../../../constants/timeFormat';
import { useMixpanelContext } from '../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../contexts/MixpanelContext/MixpanelEvents';
import { Insurance } from './insuranceApis';
import { InsuranceCardSubmitValue } from '../../../hooks/formHook/useInsuranceForm';
import { useInsuranceHelper } from './useInsuranceHelper';

enum EffectKey {
  firstName = 'firstName',
  lastName = 'lastName',
  birthday = 'birthday',
}

interface PatientProfileFormValues {
  firstName?: string | null;
  lastName?: string | null;
  dob?: string | Dayjs | null | unknown;
  birthday?: string | Dayjs | null | unknown;
  profile?: {
    firstName?: string | null;
    lastName?: string | null;
    dob?: string | Dayjs | null | unknown;
    birthday?: string | Dayjs | null | unknown;
  }
  insuranceList?: InsuranceCardSubmitValue[];
}

interface UseConfirmEligibilityEffectProps {
  initialPatientInfo?: Patient | null;
  initialInsuranceInfo?: Insurance | null;
}

export const useConfirmEligibilityEffect = (
  props: UseConfirmEligibilityEffectProps
) => {
  const { initialPatientInfo, initialInsuranceInfo } = props;
  const {
    parseInsuranceToFormValues
  } = useInsuranceHelper();
  const { send } = useMixpanelContext();

  // undefined, no effect
  const confirmEligibilityEffect = <T extends PatientProfileFormValues>(
    newPatientInfo?: T,
    labels?: Record<Partial<keyof typeof EffectKey>, string>,
  ) => {
    if (!newPatientInfo || !initialInsuranceInfo) {
      return undefined;
    }
    const { insuranceList } = parseInsuranceToFormValues(initialInsuranceInfo);
    const hasEligibility = some(insuranceList, (insurance) => (
      !!(insurance as InsuranceCardSubmitValue).eligibilityStatus
      && !!(insurance as InsuranceCardSubmitValue).wayStarInquiryId
    ));
    if (!hasEligibility) {
      return undefined;
    }

    const newFirstName = newPatientInfo.profile?.firstName || newPatientInfo.firstName;
    const newLastName = newPatientInfo.profile?.lastName || newPatientInfo.lastName;
    const newBirthday = newPatientInfo.profile?.dob
      || newPatientInfo.dob
      || newPatientInfo.profile?.birthday
      || newPatientInfo.birthday;
    const changes = [];
    if (initialPatientInfo?.profile?.firstName !== newFirstName) {
      changes.push(labels?.firstName || 'First Name');
    }
    if (initialPatientInfo?.profile?.lastName !== newLastName) {
      changes.push(labels?.lastName || 'Last Name');
    }
    if (
      dayjs(initialPatientInfo?.profile?.birthday).format(USA_DATE)
      !== dayjs(newBirthday as string).format(USA_DATE)
    ) {
      changes.push(labels?.birthday || 'DOB');
    }
    if (changes.length) {
      const changesStr = changes.join(', ');
      return new Promise((resolve) => {
        const handleOnClose = () => {
          send({
            event: MixpanelEvents.InsuranceEligibilityEffectConfirm,
            properties: { patientId: initialPatientInfo?.id }
          });
          resolve(true);
        };
        Modal.info({
          width: 500,
          wrapClassName: 'insurance-eligibility-effect-confirm-modal',
          title: 'Attention',
          centered: true,
          content: (
            <div>
              Please be aware that updating
              {' '}
              {changesStr}
              {' '}
              will impact the patient's insurance eligibility status.
              Remember to update the patient eligibility status accordingly.
            </div>
          ),
          onOk: handleOnClose,
          onCancel: handleOnClose,
        });
      });
    }
    return undefined;
  };

  return confirmEligibilityEffect;
};
