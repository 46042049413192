import React, { ReactNode } from 'react';
import TooltipComponent from '../TooltipComponent/TooltipComponent';
import { TooltipTitleComponent } from '../TooltipTitleComponent/TooltipTitleComponent';

export interface TabTitleComponentProps {
  title: string | ReactNode;
  tooltip: string | ReactNode;
  count: number;
}
export const TabTitleComponent = (
  props: TabTitleComponentProps,
) => {
  const {
    tooltip,
    title,
    count,
  } = props;
  return (
    <TooltipComponent
      title={(
        <TooltipTitleComponent title={tooltip} />
      )}
      destroyTooltipOnHide
      type={null}
    >
      {title}
      {` (${count})`}
    </TooltipComponent>
  );
};
