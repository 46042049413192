import { Button } from 'antd';
import { DeviceModelEnum } from '../../../../uc-api-sdk';
import { ConnectedDeviceInfo } from '../../type';
import { BloodPressureAssignedDeviceInfoComponent, BloodPressureAssignedDeviceInfoComponentProps } from '../BloodPressureDeviceInfoComponent/BloodPressureAssignedDeviceInfoComponent';
import { BloodPressureDeviceInfoCardComponent } from '../BloodPressureDeviceInfoComponent/BloodPressureDeviceInfoCardComponent';
import { BloodPressureDeviceFormModalButtonComponent } from '../BloodPressureDeviceModalButtonComponent/BloodPressureDeviceFormModalButtonComponent';

export interface BloodPressureAssignUnassignComponentProps {
  deviceModel: DeviceModelEnum;
  assignedDevice?: ConnectedDeviceInfo;
  onAssign: (device: Partial<ConnectedDeviceInfo>) => void;
  onUnassign: (device: ConnectedDeviceInfo) => void;
  disabled?: boolean;
}

export const BloodPressureAssignUnassignComponent = ({
  deviceModel,
  assignedDevice,
  onAssign,
  onUnassign,
  disabled,
}: BloodPressureAssignUnassignComponentProps) => {
  const {
    deviceId: assignedDeviceId,
    deviceModel: assignedDeviceModel,
  } = assignedDevice || {};

  const hasAssignedDevice = (
    assignedDevice
    && assignedDeviceId
    && (assignedDeviceModel === deviceModel)
  );

  const handleOnUnassign: BloodPressureAssignedDeviceInfoComponentProps['onUnassign'] = (
    v
  ) => {
    onUnassign(v);
  };

  const handleOnAssign: BloodPressureAssignedDeviceInfoComponentProps['onUpdate'] = (
    v
  ) => {
    onAssign(v);
  };

  if (hasAssignedDevice) {
    return (
      <BloodPressureAssignedDeviceInfoComponent
        device={assignedDevice}
        onUnassign={handleOnUnassign}
        onUpdate={handleOnAssign}
      />
    );
  }

  return (
    <BloodPressureDeviceInfoCardComponent deviceModel={deviceModel}>
      <BloodPressureDeviceFormModalButtonComponent
        deviceModel={deviceModel}
        onSubmit={handleOnAssign}
        disabled={disabled}
      >
        <Button>
          Assign Device
        </Button>
      </BloodPressureDeviceFormModalButtonComponent>
    </BloodPressureDeviceInfoCardComponent>
  );
};
