import { isNil } from 'lodash';
import { ReactNode, useCallback } from 'react';
import { EmptyComponent, EmptyComponentProps } from '../EmptyComponent/EmptyComponent';

export interface DisplayOrEmptyComponentProps<T> {
  value?: T | null;
  emptyType?: EmptyComponentProps['type'];
  emptyText?: EmptyComponentProps['text'];
}

export const DisplayOrEmptyComponent = <V, >({
  value,
  emptyType = 'reactNode',
  emptyText,
}: DisplayOrEmptyComponentProps<V>) => {
  const render = useCallback(() => (
    !isNil(value) && value !== ''
      ? value as ReactNode
      : <EmptyComponent type={emptyType} text={emptyText} />
  ), [value]);

  return (
    <span className="display-or-empty breakword">
      {render()}
    </span>
  );
};

export default DisplayOrEmptyComponent;
