import { FC } from 'react';
import { Spin } from 'antd';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { PatientProfileTabsComponent } from '../PatientProfileTabsComponent/PatientProfileTabsComponent';
import { ErrorBoundaryComponent } from '../../../../uiComponent/ErrorBoundaryComponent/ErrorBoundaryComponent';

export interface PatientProfileComponentProps {

}

export const PatientProfileComponent: FC<PatientProfileComponentProps> = () => {
  const { info } = usePatientContext();
  const { id: patientId } = info || {};

  if (!patientId) {
    return (
      <div>Patient id does NOT exist!!</div>
    );
  }

  if (info?.isLoading && info === undefined) {
    return <Spin />;
  }

  return (
    <ErrorBoundaryComponent identifier="PatientProfileComponent">
      <PatientProfileTabsComponent key={patientId} />
    </ErrorBoundaryComponent>
  );
};
