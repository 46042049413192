import { Button, Form } from 'antd';
import { LoginRequest } from '../../../../uc-api-sdk';
import { LoginUsernameFormItemComponent } from '../LoginUsernameFormItemComponent/LoginUsernameFormItemComponent';
import { LoginPasswordFormItemComponent } from '../LoginPasswordFormItemComponent/LoginPasswordFormItemComponent';
import { useLoginForm } from '../../hooks/useLoginForm';

import './LoginFormComponent.scss';
import { ForgotPasswordButtonComponent, ForgotPasswordButtonComponentProps } from '../ForgotPasswordButtonComponent/ForgotPasswordButtonComponent';

export interface LoginFormComponentProps {
  onLogin?: (inputValues: LoginRequest) => void;
  isLoading?: boolean;
  onClickForgotPassword: ForgotPasswordButtonComponentProps['onClick'];
  submitText?: string;
}

export const LoginFormComponent = ({
  onLogin,
  isLoading,
  onClickForgotPassword,
  submitText = 'Sign In',
}: LoginFormComponentProps) => {
  const formHook = useLoginForm();

  return (
    <Form
      onFinish={onLogin}
      className="login-input-form-component"
    >
      <LoginUsernameFormItemComponent
        name={formHook.getName('usernameOrEmail')}
        rules={formHook.getRequiredRules('usernameOrEmail')}
        disabled={isLoading}
      />

      <LoginPasswordFormItemComponent
        name={formHook.getName('password')}
        rules={formHook.getRequiredRules('password')}
        disabled={isLoading}
      />

      <ForgotPasswordButtonComponent
        onClick={onClickForgotPassword}
      />

      <Button
        type="primary"
        size="large"
        htmlType="submit"
        data-testid="login-button"
        block
        disabled={isLoading}
        loading={isLoading}
      >
        {submitText}
      </Button>
    </Form>
  );
};
