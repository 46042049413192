import { FC } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { DiscontinuedMedicationsTableComponent } from '../../../tables/MedicationsTables/DiscontinuedMedicationsTableComponent/DiscontinuedMedicationsTableComponent';
import { PrescribedMedicationsTableComponent } from '../../../tables/MedicationsTables/PrescribedMedicationsTableComponent/PrescribedMedicationsTableComponent';
import { useMedicationSearch } from '../../../uc-api-sdk';
import { BaseSortDirection } from '../../../uc-api-sdk/staticFiles/useReqHook';
import { FetchComponent } from '../../../uiComponent/FetchComponent/FetchComponent';

export interface MedicationTableContainerProps {
  type?: 'CURR' | 'PREV';
  memberId: string;
  status: number;
}
export const MedicationTableContainer: FC<MedicationTableContainerProps> = ({
  type = 'CURR',
  memberId,
  status,
}) => {
  const medications = useMedicationSearch({
    options: {
      sendOnMount: true,
    },
    params: {
      filter: {
        memberId,
        active: true,
        isEnded: type === 'PREV',
      },
      pageInfo: {
        page: 0,
        size: 1000,
        sort: [{
          direction: BaseSortDirection.DESC,
          property: 'endDate',
        }],
        pagination: false,
      },
    },
  });

  useUpdateEffect(
    () => {
      medications.refetch();
    },
    [status],
  );

  return (
    <FetchComponent
      info={medications}
      alwaysShowChildren
      showEmptyOverlay={false}
    >
      {(value) => {
        const meds = value?.rawValue?.data?.content || [];
        return type === 'CURR' ? (<PrescribedMedicationsTableComponent medications={meds} />)
          : (<DiscontinuedMedicationsTableComponent medications={meds} />);
      }}
    </FetchComponent>
  );
};
