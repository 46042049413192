import { map } from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Dayjs } from 'dayjs';
import React, { FC, useMemo } from 'react';
import { createBOChartOptions, createBOChartSeries } from './BOChartHelper';
import { createMarker } from '../../helper/chartHelper';
import { FromDateToDateDayjs, PoSeverity } from '../../type';
import { SeverityHelper } from '../../helper/severityHelper';
import TimezoneService from '../../../../helpers/timezone/timezoneService';

export interface BOChartData {
  date: Dayjs;
  patientNote: string;
  bloodOxygen: number;
  isCopd: boolean,
  poSeverity: PoSeverity;
}

export interface BOChartComponentProps extends FromDateToDateDayjs {
  data: BOChartData[];
}

export const BOChartComponent: FC<BOChartComponentProps> = ({
  data,
  fromDate,
  toDate,
}) => {
  const getData = (
    filterMeasurements: ((v: BOChartData[]) => BOChartData[]),
  ) => {
    const filteredArr = filterMeasurements(data);
    return map(filteredArr, (curr) => {
      const date = TimezoneService.calcDateTimeDayjs(curr.date).startOf('day').valueOf();
      return {
        x: date,
        y: curr.bloodOxygen,
        severity: curr.poSeverity,
        notes: curr.patientNote || '--',
        marker: createMarker({ poSeverity: curr.poSeverity, isCopd: curr.isCopd }),
      };
    });
  };

  const options = useMemo(() => {
    const normal = getData(SeverityHelper.getBoChartNormal);
    const low = getData(SeverityHelper.getBoChartLow);
    const criticalLow = getData(SeverityHelper.getBoChartCriticalLow);
    const series = createBOChartSeries({
      normal,
      low,
      criticalLow,
    });
    return createBOChartOptions(series, fromDate, toDate);
  }, [data, fromDate, toDate]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  );
};
