import { DiseaseHistoryItem } from '../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

interface YearOfDiagnosisComponentProps {
  diseaseHistory: DiseaseHistoryItem;
}

const YearOfDiagnosisComponent = ({ diseaseHistory }: YearOfDiagnosisComponentProps) => (
  <div className="disease-history-item">
    <span>
      <span className="label">Year of diagosis: </span>
      <DisplayOrEmptyComponent value={diseaseHistory.diagnosedYear} />
      <span className="label">
        {' ('}
        <DisplayOrEmptyComponent value={diseaseHistory.yearsWithCondition} />
        {(diseaseHistory.yearsWithCondition ?? 0) < 2
          ? ' year with condition)'
          : ' years with condition)'}
      </span>
    </span>
  </div>
);

export default YearOfDiagnosisComponent;
