import { Button, ButtonProps } from 'antd';
import classNames from 'classnames';
import './DisabledButtonWithFeedbackComponent.scss';

export interface DisabledButtonWithFeedbackComponentProps extends ButtonProps {
  onClickFeedback?: ButtonProps['onClick']; // when button is disabled
}

export const DisabledButtonWithFeedbackComponent = ({
  disabled,
  className = '',
  onClick,
  onClickFeedback,
  ...props
}: DisabledButtonWithFeedbackComponentProps) => {
  const handleOnClick: ButtonProps['onClick'] = (e) => {
    if (disabled) {
      onClickFeedback?.(e);
      return;
    }
    onClick?.(e);
  };

  return (
    <Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={classNames({
        'disabled-button-with-feedback': true,
        disabled: !!disabled,
        [className]: !!className,
      })}
      onClick={handleOnClick}
      disabled={disabled && !onClickFeedback}
    />
  );
};
