import { DietEnum } from '../../uc-api-sdk';
import { ListComponent, ListComponentProps } from '../../uiComponent/ListComponent/ListComponent';
import { DietEnumComponent } from './DietEnumComponent';

export interface DietEnumListComponentProps {
  value: DietEnum[];
  splitter?: ListComponentProps<DietEnum>['splitter'];
}

export const DietEnumListComponent = ({
  value,
  splitter,
}: DietEnumListComponentProps) => (
  <ListComponent
    value={value}
    splitter={splitter}
    renderItem={(v) => (
      <DietEnumComponent
        value={v}
      />
    )}
  />
);
