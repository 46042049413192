import React from 'react';
import { ScreenStatusEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface ScreenStatusEnumComponentProps {
  value?: ScreenStatusEnum;
}

export const ScreenStatusEnumComponent = ({ value }: ScreenStatusEnumComponentProps) => {
  const getFullName = () => {
    switch (value) {
      case ScreenStatusEnum.UNSCREENED: return 'Unscreened';
      case ScreenStatusEnum.ELIGIBLE: return 'Eligible to Enroll';
      case ScreenStatusEnum.INELIGIBLE: return 'Ineligible to Enroll';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  return (<>{getFullName()}</>);
};
