import { useMemo } from 'react';
import classNames from 'classnames';
import { reduce } from 'lodash';
import { StepProps, Steps, StepsProps } from 'antd';
import { InputType } from '../../features/Input/types';
import './StepsComponent.scss';

export interface Step<T extends string> extends StepProps {
  value: T;
  status: StepProps['status'];
}

export interface StepsComponentProps<T extends string> extends InputType<T> {
  steps: Step<T>[];
  className?: string;
}

export const StepsComponent = <T extends string>({
  value,
  onChange,
  steps = [],
  className = '',
}: StepsComponentProps<T>) => {
  // maps the step index to the value
  const stepMap = useMemo(() => ({
    numToValue: reduce(steps, (res, v, i) => {
      res[i] = v.value;
      return res;
    }, {} as Record<number, T>),
    valueToNum: reduce(steps, (res, v, i) => {
      res[v.value] = i;
      return res;
    }, {} as Record<T, number>),
  }), [steps]);

  const handleChange: StepsProps['onChange'] = (v) => {
    onChange?.(stepMap.numToValue[v]);
  };

  return (
    <Steps
      className={classNames({
        'steps-component': true,
        [className]: !!className,
      })}
      size="small"
      current={value ? stepMap.valueToNum[value] : 0}
      onChange={handleChange}
      direction="vertical"
      items={steps}
    />
  );
};
