import { ReactNode } from 'react';
import { Button } from 'antd';
import './PriorAuthConfirmContentComponent.scss';

export interface PriorAuthConfirmContentComponentProps {
  children: ReactNode;
  onCancel?: () => void;
  onConfirm?: () => void;
  confirmText?: string;
  cancelText?: string;
}

export const PriorAuthConfirmContentComponent = ({
  children,
  onCancel,
  onConfirm,
  confirmText = 'Yes, submit',
  cancelText = 'Cancel',
}: PriorAuthConfirmContentComponentProps) => {
  const renderButtons = () => (
    (onCancel || onConfirm)
      ? (
        <div className="prior-auth-confirm-buttons flex jc-e">
          {
            onCancel
            && (
              <Button
                className="prior-auth-confirm-buttons__cancel"
                onClick={onCancel}
              >
                {cancelText}
              </Button>
            )
          }
          {
            onConfirm
            && (
              <Button
                type="primary"
                className="prior-auth-confirm-buttons__confirm"
                onClick={onConfirm}
              >
                {confirmText}
              </Button>
            )
          }
        </div>
      ) : null
  );

  return (
    <>
      <div className="prior-auth-confirm-content">
        {children}
      </div>
      {renderButtons()}
    </>
  );
};
