import { useFormHookFactory } from '../../../../hooks/useFormHookFactory/useFormHookFactory';

export const usePOBaselineForm = () => {
  const factory = useFormHookFactory({
    baseline: {
      name: 'baseline',
      label: 'Baseline',
    },
  });

  return factory;
};
