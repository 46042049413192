import {
  Checkbox, Col, Input, Row
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { WeightService } from '../../../../helpers/weight';
import { FormOptions } from '../../../../hooks/useFormHookFactory/useFormHookFactory';
import { PatientSpokenLanguageSelectComponent } from '../../../../selectComponents/PatientSpokenLanguageSelectComponent/PatientSpokenLanguageSelectComponent';
import EmailInputFormItemComponent from '../../../EmailInputComponent/EmailInputFormItemComponent';
import FormItem from '../../../FormItem/FormItem';
import { PatientDobInputComponent } from '../../../PatientBasicInfo/PatientDobInputComponent/PatientDobInputComponent';
import PhoneInput from '../../../PhoneInput/PhoneInput';
import { useTranscribingForm } from '../../hook/useTranscribingForm';
import { HeightInputComponent } from '../HeightInputComponent/HeightInputComponent';
import { ProviderSelectContainer } from '../ProviderSelectContainer/ProviderSelectContainer';
import { WeightInputFormItemComponent } from '../WeightInputFormItemComponent/WeightInputFormItemComponent';
import './TranscribeBasicInfoFormItemComponent.scss';

export interface TranscribeBasicInfoFormItemComponentProps extends FormOptions {
  patientInfo?: PatientInfo;
}

export const TranscribeBasicInfoFormItemComponent = ({
  form,
  patientInfo,
}: TranscribeBasicInfoFormItemComponentProps) => {
  const hook = useTranscribingForm({ form });
  const {
    id,
    clinicId,
  } = patientInfo?.patientInfo || {};

  const handleNoWeightChange = (e: CheckboxChangeEvent) => {
    hook.form.setFieldsValue({
      weight: e.target.checked ? WeightService.NO_RECORD_VALUE : null,
    });
  };

  return (
    <>
      <Row gutter={16} align="bottom">
        <Col span={6}>
          <FormItem
            info={hook.getInfo('firstName')}
            required
            className="required-field"
          >
            <Input />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            info={hook.getInfo('lastName')}
            required
            className="required-field"
          >
            <Input />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            info={hook.getInfo('nickName')}
          >
            <Input />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            info={hook.getInfo('dob')}
            required
            className="required-field"
          >
            <PatientDobInputComponent />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16} align="bottom">
        <Col span={6}>
          <FormItem
            info={hook.getInfo('providerId')}
            required
            className="required-field"
          >
            <ProviderSelectContainer
              clinicIds={clinicId ? [clinicId] : undefined}
              externalEmployeeForPatientId={id || ''}
            />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            info={hook.getInfo('medicalId')}
            required
            className="required-field"
          >
            <Input />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            info={hook.getInfo('spokenLanguage')}
            required
            className="required-field"
          >
            <PatientSpokenLanguageSelectComponent />
          </FormItem>
        </Col>
        <Col span={6} className="flex jc-sb">
          <FormItem
            shouldUpdate={hook.shouldUpdate(['noWeight'])}
            noStyle
          >
            {({ getFieldValue }) => {
              const required = getFieldValue('noWeight') !== true;
              return (
                <WeightInputFormItemComponent
                  info={hook.getInfo('weight')}
                  shouldUpdate={hook.shouldUpdate(['weight'])}
                  required={required}
                  disabled={!required}
                />
              );
            }}
          </FormItem>
          <FormItem
            info={hook.getInfo('noWeight')}
            valuePropName="checked"
          >
            <Checkbox
              className="no-weight-checkbox"
              onChange={handleNoWeightChange}
            >
              No record
            </Checkbox>
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16} align="bottom">
        <Col span={6}>
          <FormItem
            info={hook.getInfo('height')}
            className="required-field"
          >
            <HeightInputComponent />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            info={hook.getInfo('mobilePhone')}
            required
            className="required-field"
          >
            <PhoneInput />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            info={hook.getInfo('homePhone')}
          >
            <PhoneInput />
          </FormItem>
        </Col>
        <Col span={6}>
          <EmailInputFormItemComponent />
        </Col>
      </Row>
    </>
  );
};
