import React, { ReactNode, useState } from 'react';
import {
  Button,
  Popover,
  PopoverProps,
} from 'antd';
import './HeaderPopoverComponent.scss';
import { Dayjs } from 'dayjs';
import { usePatientPageControllerContext } from '../../features/patient/context/PatientPageControllerContext/PatientPageControllerContext';

export interface BaseProps {
  max?: number;
  current?: number;
  qualified?: boolean;
  patientId?: string;
  refetch?: ()=> void;
  processingMinute?: number;
}
export interface HeaderPopoverContentComponentProps extends BaseProps {
  onClose?: () => void;
}

export interface ProgressBodyProps {
  current: number;
  max: number;
  processingMinute: number,
  startTime?: Dayjs | string;
}

export type PopoverContentRenderer = (props: HeaderPopoverContentComponentProps) => ReactNode;
export type ProgressBodyRenderer = (props: ProgressBodyProps) => ReactNode;

export interface HeaderPopoverComponentProps extends PopoverProps, BaseProps {
  popoverContentRenderer?: PopoverContentRenderer;
  progressRenderer?: ProgressBodyRenderer;
  contentRenderer?: ReactNode;
  onClick?: () => void;
}

export const HeaderPopoverComponent = ({
  children,
  popoverContentRenderer,
  max,
  current,
  qualified,
  patientId,
  progressRenderer,
  refetch,
  contentRenderer,
  processingMinute,
  onClick,
}: HeaderPopoverComponentProps) => {
  const [clicked, setClicked] = useState(false);
  const { sessionStartTimestamp } = usePatientPageControllerContext();

  const onClose = () => {
    setClicked(false);
  };

  const handleClickChange = (open: boolean) => {
    setClicked(open);
    if (open) {
      onClick?.();
    }
  };

  return (
    <Popover
      content={popoverContentRenderer?.({
        onClose,
        max,
        current,
        qualified,
        patientId,
        refetch,
      })}
      overlayClassName="header-popover-component"
      placement="bottomLeft"
      trigger="click"
      open={clicked}
      onOpenChange={handleClickChange}
    >
      <Button type="link" className="p0">
        {children}
        {progressRenderer?.({
          max: max || 20,
          current: current || 0,
          processingMinute: processingMinute || 0,
          startTime: sessionStartTimestamp,
        })}
        {contentRenderer}
      </Button>
    </Popover>
  );
};
