import { CloseOutlined } from '@ant-design/icons';
import { Modal, ModalProps } from 'antd';
import classNames from 'classnames';
import React, { RefObject, useEffect, useState } from 'react';
import { Icons } from '../../icons/Icons';
import './ModalComponent.scss';

export interface ClientPos {
  clientX: number;
  clientY: number;
}

export interface ModalComponentProps extends ModalProps {
  open: boolean;
  reference?: RefObject<HTMLDivElement | undefined>;
  syntheticBaseEvent?: React.MouseEvent;
  clientPos?: ClientPos;
  middleX?: boolean;
  middleY?: boolean;
  leftOrRight?: 'left' | 'right';
}

export const ModalComponent = ({
  open,
  closeIcon,
  maskClosable = true,
  destroyOnClose = true,
  mask = false,
  children,
  title,
  reference,
  syntheticBaseEvent,
  clientPos,
  middleX,
  middleY,
  footer,
  onOk,
  onCancel,
  className = '',
  leftOrRight,
  ...modalProps
}: ModalComponentProps) => {
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });
  const [modalHeight, setModalHeight] = useState<number>(0);
  const [modalWidth, setModalWidth] = useState<number>(0);
  const [hasMoved, setHasMoved] = useState<boolean>(false);

  // Wait for modal element creation.
  const waitForElement = (selector: string) => (
    new Promise<HTMLDivElement>((resolve) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector) as HTMLDivElement);
        return;
      }
      const observer = new MutationObserver(() => {
        if (document.querySelector(selector)) {
          observer.disconnect();
          resolve(document.querySelector(selector) as HTMLDivElement);
        }
      });
      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
    })
  );

  const calculateX = (clientX: number, width: number) => {
    // Calculate X position
    const spaceNext = 20;
    const spaceOnRight = window.innerWidth - (clientX + width);
    let x = 0;
    if (!leftOrRight) {
      x = spaceOnRight >= window.innerHeight / 2
        ? clientX + width + spaceNext
        : clientX - modalWidth - spaceNext;
    }
    if (leftOrRight === 'left') {
      x = clientX - modalWidth - spaceNext;
    }
    if (leftOrRight === 'right') {
      x = clientX + width + spaceNext;
    }
    const maxX = window.innerWidth - modalWidth;
    const newX = Math.min(Math.max(0, x), maxX);
    return newX;
  };

  const calculateY = (clientY: number, height: number) => {
    // Calculate Y position
    const spaceOnBottom = window.innerHeight - (clientY + height);
    const y = spaceOnBottom >= window.innerHeight / 2
      ? clientY : window.innerHeight - modalHeight;
    const maxY = window.innerHeight - modalHeight;
    const newY = Math.min(Math.max(0, y), maxY);
    return newY;
  };

  const setFinalPosition = (
    middleX: boolean | undefined,
    middleY: boolean | undefined,
    newX: number,
    newY: number
  ) => {
    // Position: center
    if (hasMoved) return;
    if (middleX && middleY) {
      setPosition({
        x: window.innerWidth / 2 - modalWidth / 2,
        y: window.innerHeight / 2 - modalHeight / 2
      });
      return;
    }
    // Position: center of X axis of the element.
    if (middleX) {
      setPosition({ x: window.innerWidth / 2 - modalWidth / 2, y: newY });
      return;
    }
    // Position: center of Y axis of the element.
    if (middleY) {
      setPosition({ x: newX, y: window.innerHeight / 2 - modalHeight / 2 });
      return;
    }
    // Position: next to the element, left or right.
    setPosition({ x: newX, y: newY });
  };

  useEffect(() => {
    const func = async () => {
      const element = await waitForElement('.modal-component');
      setModalWidth(element.clientWidth);
      setModalHeight(element.clientHeight);
    };
    func();
  }, [reference?.current, syntheticBaseEvent, open, clientPos]);

  useEffect(() => {
    if (clientPos) {
      const newX = calculateX(clientPos.clientX, 0);
      const newY = calculateY(clientPos.clientY, 0);
      setFinalPosition(middleX, middleY, newX, newY);
    }
    if (syntheticBaseEvent) {
      const newX = calculateX(syntheticBaseEvent.clientX, 0);
      const newY = calculateY(syntheticBaseEvent.clientY, 0);
      setFinalPosition(middleX, middleY, newX, newY);
    }
    if (reference?.current) {
      const rect = reference.current.getBoundingClientRect();
      const newX = calculateX(rect.left, rect.width);
      const newY = calculateY(rect.top, rect.height);
      setFinalPosition(middleX, middleY, newX, newY);
    }
  }, [reference?.current, modalHeight, syntheticBaseEvent, clientPos]);

  const handleDragStart = (e: React.MouseEvent) => {
    const startX = e.clientX;
    const startY = e.clientY;

    const handleDragMove = (e: MouseEvent) => {
      const dragX = e.clientX - startX;
      const dragY = e.clientY - startY;
      const draggedX = position.x + dragX;
      const draggedY = position.y + dragY;

      const maxX = window.innerWidth - modalWidth;
      const maxY = window.innerHeight - modalHeight;

      const newX = Math.min(Math.max(0, draggedX), maxX);
      const newY = Math.min(Math.max(0, draggedY), maxY);

      setPosition({ x: newX, y: newY });
    };

    const handleDragEnd = () => {
      document.removeEventListener('mousemove', handleDragMove);
      document.removeEventListener('mouseup', handleDragEnd);
      setHasMoved(true);
    };

    document.addEventListener('mousemove', handleDragMove);
    document.addEventListener('mouseup', handleDragEnd);
  };

  const renderTitle = () => (title === null ? null : (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onMouseDown={handleDragStart}
      className="modal-component-header"
    >
      <div className="flex jc-sb">
        <div className="draggable-title">
          <Icons.Movable />
          {title}
        </div>
        <div className="title-close-button flex">
          {closeIcon !== undefined ? closeIcon : <CloseOutlined onClick={onCancel} />}
        </div>
      </div>
    </div>
  ));

  return (
    <Modal
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...modalProps}
      mask={mask}
      className={classNames({
        'modal-component': true,
        [className]: !!className,
      })}
      wrapClassName="wrap-modal-component"
      title={renderTitle()}
      maskClosable={maskClosable}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      destroyOnClose={destroyOnClose}
      style={{
        top: `${position.y}px`,
        left: `${position.x}px`,
      }}
      footer={footer}
      zIndex={1001}
    >
      {children}
    </Modal>
  );
};
