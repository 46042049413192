import { Space, Typography } from 'antd';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { AlertCardComponent } from '../../../../uiComponent/AlertCardComponent/AlertCardComponent';
import { EnrollmentPreviewAndResignConsentComponent } from '../EnrollmentPreviewConsentDrawerComponent/EnrollmentPreviewConsentDrawerComponent';

const { Text } = Typography;

export interface EnrollmentConsentFormAlertComponentProps {
  patientInfo: PatientInfo;
  onSignConsent?: () => void;
  onResign?: () => void;
}

export const EnrollmentConsentFormAlertComponent = ({
  patientInfo,
  onSignConsent,
  onResign,
}: EnrollmentConsentFormAlertComponentProps) => {
  if (!patientInfo.consentService.hasConsent()) return null;
  const isSigned = patientInfo.consentService.isSigned();

  const renderWithConsent = () => (
    <AlertCardComponent
      type="success"
      message={(
        <Space>
          Patient enrolled.
          &nbsp;
          <EnrollmentPreviewAndResignConsentComponent
            patientInfo={patientInfo}
            onResign={onResign}
          />
        </Space>
      )}
    />
  );

  const renderWithoutConsent = () => (
    <AlertCardComponent
      type="error"
      message={(
        <div>
          Patient enrolled but haven't sign consent form.
          &nbsp;
          <Text className="pointer" underline onClick={onSignConsent}>
            Click to sign
          </Text>
        </div>
      )}
    />
  );

  return (
    isSigned
      ? renderWithConsent()
      : renderWithoutConsent()
  );
};
