import { PatientComplexityEnum } from '../../uc-api-sdk';

export interface ComplexityEnumComponentProps {
  value?: PatientComplexityEnum | null;
}

export const ComplexityEnumComponent = ({
  value
}: ComplexityEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case PatientComplexityEnum.COMPLEX: return 'Complex';
      case PatientComplexityEnum.NON_COMPLEX: return 'Non-complex';
      case PatientComplexityEnum.NULL_CODES: return 'Undefined condition complexity level';
      default: return value;
    }
  };

  return <span>{getText()}</span>;
};
