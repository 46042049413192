import { BillableTimeReviewStatusEnumComponent } from '../../../enumComponent/BillableTimeReviewStatusEnumComponent/BillableTimeReviewStatusEnumComponent';
import { BillableProcessStatusEnum } from '../../../uc-api-sdk';
import { CheckboxFilterComponent } from '../component/CheckboxFilterComponent/CheckboxFilterComponent';
import { TableFilterType } from '../type/filter';
import { FilterIcon } from './FilterIcon';

export const createBillableTimeReviewStatusCheckboxFilter = (): TableFilterType => {
  const options = Object.keys(BillableProcessStatusEnum)
    .filter((v) => v !== BillableProcessStatusEnum.PROCESSING)
    .map((p) => ({
      label: (
        <BillableTimeReviewStatusEnumComponent
          value={p as BillableProcessStatusEnum}
        />
      ),
      value: p,
    }));
  return ({
    filterDropdown: ((props) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <CheckboxFilterComponent {...props} filterOptions={options} />
    )),
    filterIcon: FilterIcon,
  });
};
