import { Select } from 'antd';
import { useMemo } from 'react';
import { map } from 'lodash';
import { InputType } from '../../../../Input/types';
import { CPTCodeUnitCategoryEnumComponent } from '../../../../../enumComponent/CPTCodeUnitCategoryEnumComponent/CPTCodeUnitCategoryEnumComponent';
import { CptCodeUnitEnum } from '../../../../../uc-api-sdk';

export interface CPTCodeCategorySelectComponentProps extends InputType<CptCodeUnitEnum> {}

export const CPTCodeCategorySelectComponent = ({
  value,
  onChange,
  disabled,
}: CPTCodeCategorySelectComponentProps) => {
  const options = useMemo(() => (
    map(CptCodeUnitEnum, (category) => ({
      label: <CPTCodeUnitCategoryEnumComponent value={category} />,
      value: category,
    }))
  ), []);
  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};
