import React, { FC } from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';

const { TextArea } = Input;

export interface TicketDescriptionTextAreaComponentProps {
  value?: string;
  onChange?: TextAreaProps['onChange'];
}

export const TicketDescriptionTextAreaComponent: FC<TicketDescriptionTextAreaComponentProps> = ({
  value,
  onChange,
}) => (
  <TextArea
    rows={4}
    value={value}
    placeholder="Describe what this task is about"
    onChange={onChange}
  />
);
