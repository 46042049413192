import React from 'react';
import { TagComponent } from '../../../../../uiComponent/TagComponent/TagComponent';

export const InitialVisitTagComponent = {
  DoneTag: () => (
    <TagComponent type="success">
      Done
    </TagComponent>
  ),
  ScheduledTag: () => (
    <TagComponent type="default">
      Scheduled
    </TagComponent>
  ),
  NotScheduledTag: () => (
    <TagComponent type="default">
      Not Scheduled
    </TagComponent>
  ),
  NoShowTag: () => (
    <TagComponent iconType="solidDot">
      No Show
    </TagComponent>
  ),
  CheckedOutTag: () => (
    <TagComponent iconType="solidDot" background="none" type="done">
      Checked-Out
    </TagComponent>
  ),
  UpcomingTag: () => (
    <TagComponent iconType="solidDot" background="none" type="processing">
      Upcoming
    </TagComponent>
  ),
  OnGoingTag: () => (
    <TagComponent iconType="solidDot" background="none" type="processing">
      On Going
    </TagComponent>
  ),
  VisitNoShowTag: () => (
    <TagComponent iconType="solidDot" background="none" type="error">
      No Show
    </TagComponent>
  ),
  StartsSoon: () => (
    <TagComponent iconType="solidDot" background="none" type="warning">
      Starts in 15 Mins
    </TagComponent>
  ),
  Waiting: () => (
    <TagComponent iconType="solidDot" background="none" type="warning">
      Waiting
    </TagComponent>
  ),
};
