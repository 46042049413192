import { Button } from 'antd';
import { capitalize, toLower } from 'lodash';
import { Title } from '../Title/Title';
import ModalButton from '../ModalButton/ModalButton';
import { EnvironmentTagComponent } from '../EnvironmentTagComponent/EnvironmentTagComponent';
import EnvConfig from '../../configs/envConfig/envConfig';

import './PreviewEnvWarningModalComponent.scss';

export interface PreviewEnvWarningModalComponentProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export const PreviewEnvWarningModalComponent = ({
  isOpen,
  onClose,
}: PreviewEnvWarningModalComponentProps) => (
  <ModalButton
    open={isOpen}
    wrapClassName="preview-env-warning-modal"
    trigger={() => null}
    width={814}
    closable={false}
    maskClosable={false}
    footer={null}
  >
    {
      ({ close }) => (
        <>
          <EnvironmentTagComponent />
          <Title>
            You are using the
            {' '}
            {capitalize(EnvConfig.envName)}
            {' '}
            Environment of Care Portal
          </Title>
          <Title.SubTitle>
            The
            {' '}
            {toLower(EnvConfig.envName)}
            {' '}
            environment is used for trial purposes and is not intended
            for actual patient care or operation.
            Please be aware that the patient data here might not be up-to-date.
          </Title.SubTitle>
          <Button
            type="primary"
            onClick={() => {
              close();
              onClose?.();
            }}
          >
            Got it!
          </Button>
        </>
      )
    }
  </ModalButton>
);
