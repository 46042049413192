// @ts-nocheck
export const usePerformance = () => {
  const convertToMB = (bytes: number) => (
    Math.round(bytes / 1048576)
  );

  const getAppMemoryUsage = () => {
    const { performance } = window || {};
    if (!performance) {
      return 'Performance API not supported';
    }
    return {
      totalJSHeapSize: convertToMB(performance.memory.totalJSHeapSize),
      usedJSHeapSize: convertToMB(performance.memory.usedJSHeapSize),
      jsHeapSizeLimit: convertToMB(performance.memory.jsHeapSizeLimit),
    };
  };

  return {
    getAppMemoryUsage
  };
};
