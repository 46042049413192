import dayjs, { Dayjs } from 'dayjs';
import { Moment } from 'moment';
import { ReactNode } from 'react';
import utc from 'dayjs/plugin/utc';
import dayjsTimezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';

import * as AllTimeFormats from '../../constants/timeFormat';
import TimezoneService from '../../helpers/timezone/timezoneService';

dayjs.extend(utc);
dayjs.extend(dayjsTimezone);
dayjs.extend(advanced);

export type AllTimeFormatNames = keyof typeof AllTimeFormats;

export interface DisplayDateComponentProps {
  value?: number | string | Dayjs | Moment | null;
  timezone?: string;
  format: AllTimeFormatNames;
  useNowWhenEmpty?: boolean;
  fallbackText?: string | ReactNode;
  useTimezoneService?: boolean;
}
export const DisplayDateComponent = ({
  value,
  format,
  useNowWhenEmpty = false,
  fallbackText,
  timezone,
  useTimezoneService = true,
}: DisplayDateComponentProps) => {
  if (!useNowWhenEmpty && !value) {
    return fallbackText
      ? (
        <span>{fallbackText}</span>
      ) : null;
  }

  const v = (value as Moment | Dayjs).toISOString
    ? (value as Moment | Dayjs).toISOString()
    : value as Exclude<DisplayDateComponentProps['value'], Moment | Dayjs>;

  const getTimeByTimeService = () => (
    (useTimezoneService && v)
      ? (
        TimezoneService.calcDateTimeDayjs(v, timezone).format(AllTimeFormats[format])
      ) : null
  );

  return (
    <span>
      {
        getTimeByTimeService()
        || (
          timezone
            ? dayjs(v).tz(timezone).format(AllTimeFormats[format])
            : dayjs(v).format(AllTimeFormats[format])
        )
      }
      {/* {t */}
    </span>
  );
};
