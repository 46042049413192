import { ReactNode, useCallback } from 'react';
import { VitalEnumType } from '../../../../uc-api-sdk';
import { BloodGlucoseDeviceMonitorDrawerComponent } from '../BloodGlucoseDeviceMonitorDrawerComponent/BloodGlucoseDeviceMonitorDrawerComponent';
import { BloodPressureDeviceMonitorDrawerComponent } from '../BloodPressureDeviceMonitorDrawerComponent/BloodPressureDeviceMonitorDrawerComponent';
import { DeviceMonitorDrawerComponent } from '../DeviceMonitorDrawerComponent/DeviceMonitorDrawerComponent';
import { DeviceMonitorFormComponentProps } from '../DeviceMonitorFormComponent/DeviceMonitorFormComponent';

export type DeviceDrawerComponentProps = {
  open?: boolean;
  onClose?: () => void;
  vitalType: VitalEnumType;
  onSubmit?: DeviceMonitorFormComponentProps['onSubmit'];
  title?: ReactNode;
} & ({
  patientId: string;
  useDefaultTemplate?: false;
} | {
  patientId?: string;
  useDefaultTemplate: true;
})

export const DeviceDrawerComponent = ({
  open,
  onClose,
  patientId,
  vitalType,
  onSubmit,
  title,
  useDefaultTemplate,
}: DeviceDrawerComponentProps) => {
  // default template doesn't need patient id
  const pId = useDefaultTemplate ? '' : patientId;
  const close = () => onClose?.();

  const getDrawer = useCallback((
    isOpen: boolean,
  ) => {
    switch (vitalType) {
      case VitalEnumType.BG:
        return (
          <BloodGlucoseDeviceMonitorDrawerComponent
            isOpen={isOpen}
            onClose={close}
            patientId={pId}
            title={title}
            onSubmit={onSubmit}
            useDefaultTemplate={useDefaultTemplate}
          />
        );
      case VitalEnumType.BP:
        return (
          <BloodPressureDeviceMonitorDrawerComponent
            isOpen={isOpen}
            onClose={close}
            patientId={pId}
            title={title}
            onSubmit={onSubmit}
            useDefaultTemplate={useDefaultTemplate}
          />
        );
      case VitalEnumType.HS:
      case VitalEnumType.PO:
      case VitalEnumType.TM:
        return (
          <DeviceMonitorDrawerComponent
            open={isOpen}
            onClose={close}
            patientId={pId}
            title={title}
            vitalType={vitalType}
            onSubmit={onSubmit}
            useDefaultTemplate={useDefaultTemplate}
          />
        );
      default:
        return null;
    }
  }, [vitalType]);

  return getDrawer(!!open);
};
