import React, { FC } from 'react';
import { TicketActivityUserComponent } from './TicketActivityUserComponent';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { TicketHistory, TicketHistoryEnum } from '../../../../uc-api-sdk';
import './TicketActivityHistoryComponent.scss';
import { AccountHelper } from '../../../../helpers/account';

export interface TicketSingleActivityLogComponentProps {
  info: TicketHistory;
}

export const TicketSingleActivityLogComponent: FC<TicketSingleActivityLogComponentProps> = ({
  info,
}) => {
  const renderCreate = () => (
    <>
      created and assigned this task to
      <TicketActivityUserComponent user={AccountHelper.getFullName(info.assignedToUser)} />
    </>
  );
  const renderReOpen = () => (
    <>
      reopened this task and assigned this task to
      <TicketActivityUserComponent user={AccountHelper.getFullName(info.assignedToUser)} />
    </>
  );
  const renderResolve = () => (
    <>
      resolved the task
    </>
  );
  const renderDescription = () => (
    <>
      changed description from
      {` "${info.oldDescription}" to "${info.updatedDescription}"`}
    </>
  );
  const renderDue = () => (
    <>
      changed due date from
      {' "'}
      <DisplayDateComponent value={info.oldDueDate} format="USA_DATE" />
      {'" '}
      to
      {' "'}
      <DisplayDateComponent value={info.updatedDueDate} format="USA_DATE" />
      {'" '}
    </>
  );
  const renderPatient = () => (
    <>
      changed patient from
      {` "${info.oldPatientFullName}" to "${info.updatedPatientFullName}"`}
    </>
  );
  const renderPriority = () => (
    <>
      changed priority from
      {` "${info.oldPriorityLevel}" to "${info.updatedPriorityLevel}"`}
    </>
  );
  const renderAssign = () => (
    <>
      assigned this task to
      <TicketActivityUserComponent user={AccountHelper.getFullName(info.assignedToUser)} />
    </>
  );
  const renderAddComment = () => (
    <>
      added a comment:
      {` "${info.comment}"`}
    </>
  );
  const renderEditComment = () => (
    <>
      edited a comment:
      {` "${info.comment}"`}
    </>
  );
  const renderDeleteComment = () => (
    <>
      deleted a comment
    </>
  );

  const getDate = () => {
    switch (info.type) {
      case TicketHistoryEnum.ASSIGN: return info.assignedAt;
      default: return info.createdAt;
    }
  };

  const getSubject = () => {
    switch (info.type) {
      case TicketHistoryEnum.CREATE: return info.assignedByFullNameTitle;
      case TicketHistoryEnum.ASSIGN: return AccountHelper.getFullName(info.assignedByUser);
      default: return info.editByFullNameTitle;
    }
  };

  const renderLog = () => {
    switch (info.type) {
      case TicketHistoryEnum.CREATE: return renderCreate();
      case TicketHistoryEnum.REOPEN: return renderReOpen();
      case TicketHistoryEnum.RESOLVE: return renderResolve();
      case TicketHistoryEnum.DESCRIPTION: return renderDescription();
      case TicketHistoryEnum.DUE_DATE: return renderDue();
      case TicketHistoryEnum.PATIENT_NAME: return renderPatient();
      case TicketHistoryEnum.PRIORITY: return renderPriority();
      case TicketHistoryEnum.ASSIGN: return renderAssign();
      case TicketHistoryEnum.ADD_COMMENT: return renderAddComment();
      case TicketHistoryEnum.EDIT_COMMENT: return renderEditComment();
      case TicketHistoryEnum.DELETE_COMMENT: return renderDeleteComment();
      default: return 'did an unknown Action';
    }
  };

  return (
    <div className="flex my10">
      <div>
        <DisplayDateComponent value={getDate()} format="USA_DATE" />
        :
      </div>
      <div className="ticket-single-activity-log-component">
        <TicketActivityUserComponent addSpaceAfter user={getSubject()} />
        {renderLog()}
        .
      </div>
    </div>
  );
};
