import { FC } from 'react';
import { useBPResultToTable } from '../../hook/useBPResultToTable/useBPResultToTable';
import { TimeOfDayTableComponent } from '../TimeOfDayTableComponent/TimeOfDayTableComponent';
import { BPTableCellComponent } from './BPTableCellComponent';
import { MeasurementType } from '../../type/MeasurementTypes';
import { FromDateToDateDayjs } from '../../type';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { BPTooltipComponent } from '../TooltipComponent/BPTooltipComponent';
import { MeasurementTimestampTextComponent } from '../MeasurementTimestampTextComponent/MeasurementTimestampTextComponent';

export interface BPTableDataProcessorComponentProps extends FromDateToDateDayjs {
  data: MeasurementType[];
}

export const BPTableDataProcessorComponent: FC<BPTableDataProcessorComponentProps> = ({
  data,
  fromDate,
  toDate,
}) => {
  const processedData = useBPResultToTable(data, fromDate, toDate);

  return (
    <>
      <TooltipComponent
        className="mb10 w-mc"
        title={<BPTooltipComponent />}
        placement="topLeft"
        type="info-icon"
        color="white"
        overlayStyle={{ minWidth: '537px' }}
      >
        <span>Log book</span>
      </TooltipComponent>
      <TimeOfDayTableComponent
        data={processedData}
        render={(v) => <BPTableCellComponent data={v} />}
        className="mb0"
      />
      <MeasurementTimestampTextComponent className="mt10 mb30" />
    </>
  );
};
