import { MonitorMethodEnum } from '../../uc-api-sdk';
import { BasicEnumComponent } from '../BasicEnumComponent/BasicEnumComponent';

export { MonitorMethodEnum as DeviceMonitorMethodEnum } from '../../uc-api-sdk';

export interface DeviceMonitorMethodEnumComponentProps {
  value: MonitorMethodEnum;
}

export const DeviceMonitorMethodEnumComponent = ({
  value,
}: DeviceMonitorMethodEnumComponentProps) => (
  value === MonitorMethodEnum.NON_APP_MONITOR
    ? <span>Non-App Monitor</span>
    : <BasicEnumComponent value={value} />
);
