import { DiseaseComponent } from '../../../../enumComponent/DiseaseComponent/DiseaseComponent';
import { ChronicDiseaseEnum } from '../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface DiseaseHistoryConditionComponentProps {
  disease?: ChronicDiseaseEnum | null;
  customDisease?: string | null;
}

export const DiseaseHistoryConditionComponent = ({
  disease,
  customDisease,
}: DiseaseHistoryConditionComponentProps) => (
  <span className="b5">
    {
      (disease && disease !== ChronicDiseaseEnum.CUSTOM_DISEASE)
        ? <DiseaseComponent disease={disease} />
        : <DisplayOrEmptyComponent value={customDisease} />
    }
  </span>
);
