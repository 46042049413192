import { Upload, UploadProps } from 'antd';

export interface UploadComponentProps extends UploadProps { }

export const UploadComponent = ({
  action,
  children,
  beforeUpload,
  ...uploadProps
}: UploadComponentProps) => {
  const handleBeforeUpload: UploadProps['beforeUpload'] = async (file, fileList) => {
    if (typeof action === 'function') {
      action(file);
      return false;
    }
    if (beforeUpload) return beforeUpload(file, fileList);
    return file;
  };

  return (
    <Upload
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...uploadProps}
      beforeUpload={handleBeforeUpload}
      action={action}
    >
      {children}
    </Upload>
  );
};
