import { ClinicStatus, MedicalOrganizationStatus } from '../../uc-api-sdk';
import { MedicalOrgStatusEnumComponent } from '../MedicalOrgStatusEnumComponent/MedicalOrgStatusEnumComponent';

export interface ClinicStatusEnumComponentProps {
  value?: ClinicStatus | MedicalOrganizationStatus;
}

export const ClinicStatusEnumComponent = ({
  value,
}: ClinicStatusEnumComponentProps) => {
  if (value && value in MedicalOrganizationStatus) {
    return <MedicalOrgStatusEnumComponent value={value as MedicalOrganizationStatus} />;
  }

  const getText = () => {
    switch (value) {
      case ClinicStatus.ACTIVE: return 'Active';
      case ClinicStatus.DELETED: return 'Deleted';
      case ClinicStatus.HIATUS: return 'Hiatus';
      case ClinicStatus.PENDING: return 'Pending';
      case ClinicStatus.SERVICE_ENDED: return 'Service ended';
      case ClinicStatus.TERMINATED: return 'Terminated';
      case ClinicStatus.TERMINATION_INITIATED: return 'Termination initiated';
      default: return null;
    }
  };

  const getClassName = (value?: ClinicStatus) => {
    switch (value) {
      case ClinicStatus.ACTIVE: return 'text-success';
      case ClinicStatus.DELETED: return 'text-danger';
      case ClinicStatus.HIATUS: return 'text-warning';
      case ClinicStatus.PENDING: return 'text-info';
      case ClinicStatus.SERVICE_ENDED: return 'text-warning';
      case ClinicStatus.TERMINATED: return 'text-danger';
      case ClinicStatus.TERMINATION_INITIATED: return 'text-danger';
      default: return 'text-muted';
    }
  };

  return (
    <span className={getClassName(value as ClinicStatus)}>
      [
      {getText()}
      ]
    </span>
  );
};
