import { VitalEnumType } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface VitalsEnumTypeComponentProps {
  value: VitalEnumType;
  type?: 'short' | 'long';
}

export const getVitalEnumShortText = (value: VitalEnumType) => {
  switch (value) {
    case VitalEnumType.BG: return 'BG';
    case VitalEnumType.EXERCISE: return 'EX';
    case VitalEnumType.BP: return 'BP';
    case VitalEnumType.HS: return 'Weight';
    case VitalEnumType.PO: return 'SpO2';
    case VitalEnumType.TM: return 'TEMP';
    default: return <DisplayOrEmptyComponent />;
  }
};

export const VitalsEnumTypeComponent = ({
  value,
  type = 'long',
}: VitalsEnumTypeComponentProps) => {
  const getLongText = () => {
    switch (value) {
      case VitalEnumType.BG: return 'Blood Glucose';
      case VitalEnumType.EXERCISE: return 'Exercise';
      case VitalEnumType.BP: return 'Blood Pressure';
      case VitalEnumType.HS: return 'Weight';
      case VitalEnumType.PO: return 'Blood Oxygen';
      case VitalEnumType.TM: return 'Temperature';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  const getText = () => {
    switch (type) {
      case 'short': return getVitalEnumShortText(value);
      case 'long':
      default:
        return getLongText();
    }
  };

  return (
    <span>{getText()}</span>
  );
};
