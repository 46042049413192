import { useCallback, useState } from 'react';
import { LoginRequest } from '../../../../uc-api-sdk';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { LoginComponent } from '../LoginComponent/LoginComponent';
import { AuthCardComponent } from '../AuthCardComponent/AuthCardComponent';
import { ForgotPasswordContainer } from '../../container/ForgotPasswordContainer/ForgotPasswordContainer';

export interface LoginCardComponentProps {
  onLogin?: (inputValues: LoginRequest) => void;
  isLoading: boolean;
}

export const LoginCardComponent = ({
  onLogin,
  isLoading,
}: LoginCardComponentProps) => {
  const { loginInfo } = useLoggedInUserFromContext();
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const LoginCardBody = useCallback(() => {
    if (showForgotPassword) {
      return (
        <ForgotPasswordContainer
          onBackToLogin={() => setShowForgotPassword(false)}
        />
      );
    }

    if (!loginInfo) {
      return (
        <LoginComponent
          onLogin={onLogin}
          isLoading={isLoading}
          onClickForgotPassword={() => setShowForgotPassword(true)}
        />
      );
    }

    // return (
    //   <AreYouOnSiteContainer />
    // );
    return null;
  }, [
    JSON.stringify(loginInfo),
    showForgotPassword,
    isLoading,
    onLogin,
  ]);

  return (
    <AuthCardComponent>
      <LoginCardBody />
    </AuthCardComponent>
  );
};
