import { ColumnType } from 'antd/lib/table';
import { filter } from 'lodash';
import React from 'react';
import { FoodLogTableDateComponent } from './FoodLogTableDateComponent';
import { MealType } from '../../features/foodLog/type';
import { FoodLogTableData } from '../../features/foodLog/component/FoodLogTableComponent/FoodLogTableComponent';
import { FoodLogTableItemWModalComponent } from '../../features/foodLog/component/FoodLogTableComponent/FoodLogTableItemWModalComponent';

const createColumn = (
  title: string,
  mealType: MealType,
  onReview: () => void,
): ColumnType<FoodLogTableData> => ({
  title,
  key: mealType,
  width: '22%',
  render: (_value, rec) => {
    const data = filter(rec.data, (v) => v.mealType === mealType);
    return data?.length
      ? (
        <FoodLogTableItemWModalComponent
          data={data}
          onReview={onReview}
        />
      ) : null;
  },
});

export const createTaskColumns = (handleReview: () => void) => ({
  Date: (): ColumnType<FoodLogTableData> => ({
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (value) => <FoodLogTableDateComponent date={value} />,
    sorter: (a, b) => a.date.valueOf() - b.date.valueOf(),
    defaultSortOrder: 'descend',
  }),
  Breakfast: () => {
    const column = createColumn('Breakfast', MealType.BREAKFAST, handleReview);
    return column;
  },
  Lunch: () => {
    const column = createColumn('Lunch', MealType.LUNCH, handleReview);
    return column;
  },
  Dinner: () => {
    const column = createColumn('Dinner', MealType.DINNER, handleReview);
    return column;
  },
  Snack: () => {
    const column = createColumn('Snack', MealType.SNACK, handleReview);
    return column;
  },
});

export default createTaskColumns;
