import React from 'react';
import { Input, InputProps } from 'antd';
import { InputType } from '../../../../features/Input/types';

export interface ResultInputComponentProps extends InputType<string> {
  className?: string;
  disabled?: boolean;
}

export const ResultInputComponent = ({
  className,
  value,
  onChange,
  disabled,
}: ResultInputComponentProps) => {
  const handleChange: InputProps['onChange'] = (evt) => {
    const v = evt.target.value;
    onChange?.(v);
  };

  return (
    <Input
      className={className}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      suffix="%"
    />
  );
};
