import { FC } from 'react';
import { Modal } from 'antd';
import { PrintVitalFormComponent } from './PrintVitalFormComponent';

export interface PrintVitalModalComponentProps {
  memberId: string;
  open: boolean;
  onClose: () => void;
}

export const PrintVitalModalComponent: FC<PrintVitalModalComponentProps> = ({
  memberId,
  open,
  onClose,
}) => (
  <Modal
    title="Vitals Summary Printout"
    open={open}
    onCancel={onClose}
    footer={null}
    destroyOnClose
  >
    <PrintVitalFormComponent memberId={memberId} />
  </Modal>
);
