import { Button, Form } from 'antd';
import { ReactNode } from 'react';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { Title } from '../../../../uiComponent/Title/Title';
import { LoanerPhoneSearchSelectContainer } from '../../../device/container/LoanerPhoneSearchSelectContainer/LoanerPhoneSearchSelectContainer';
import { useAssignLoanerPhoneForm } from '../../hook/useAssignLoanerPhoneForm/useAssignLoanerPhoneForm';
import { LoanDevice } from '../../../../uc-api-sdk';

export interface LoanerPhoneAssignFormComponentProps {
  initialValues?: LoanDevice;
  onSubmit?: (value?: LoanDevice) => void;
  submitText?: ReactNode;
  onCancel?: () => void;
  disabled?: boolean;
  title?: ReactNode;
}

export const LoanerPhoneAssignFormComponent = ({
  initialValues,
  onSubmit,
  submitText = 'Assign Device',
  onCancel,
  disabled,
  title = (
    <>
      <Title noMargin>
        Assign Patient a Loaner Phone
      </Title>
      <Title.SubTitle>
        To assign device, please confirm the device ID.
      </Title.SubTitle>
    </>
  ),
}: LoanerPhoneAssignFormComponentProps) => {
  const loanerPhoneForm = useAssignLoanerPhoneForm();
  return (
    <Form
      initialValues={initialValues}
      onFinish={loanerPhoneForm.handleSubmit(onSubmit)}
      layout="vertical"
      className="my20"
      disabled={loanerPhoneForm.formDisabled}
    >
      {title}
      <FormItem
        info={loanerPhoneForm.getInfo('deviceId')}
        required
      >
        <LoanerPhoneSearchSelectContainer disabled={disabled} />
      </FormItem>
      <div className="loaner-phone-form-buttons">
        {
          onCancel
          && (
            <Button onClick={onCancel}>
              Cancel
            </Button>
          )
        }
        <Button
          type="primary"
          htmlType="submit"
        >
          {submitText}
        </Button>
      </div>
    </Form>
  );
};
