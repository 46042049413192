import { ReactNode } from 'react';

export interface OnboardingCardDescriptionComponentProps {
  description: string;
  children?: ReactNode;
}

export const OnboardingCardDescriptionComponent = ({
  description,
  children,
}: OnboardingCardDescriptionComponentProps) => (
  <div className="flex f-w ai-c jc-sb">
    <span>{description}</span>
    <div>
      {children}
    </div>
  </div>
);
