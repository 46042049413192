import { useCallback } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { message } from 'antd';
import dayjs from 'dayjs';
import { useLabResultInsert } from '../../../../uc-api-sdk';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { CreateEditLabResultFormComponent, SubmitValue } from '../../component/CreateEditLabResultFormComponent/CreateEditLabResultFormComponent';
import { TranscribeTexts } from '../../constants';
import { DATE_TIME_CALENDAR } from '../../../../constants/timeFormat';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { StorageKeyEnum } from '../../../../hooks';
import { useLabResultContext } from '../../context/LabResultContext/LabResultContext';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { useLabResultHelper } from '../../hook/useLabResultHelper';

export interface CreateLabResultContainerProps {
  patientId?: string;
  onSubmit?: (res?: unknown) => void;
}
export const CreateLabResultContainer = ({
  patientId,
  onSubmit,
}: CreateLabResultContainerProps) => {
  const updateHook = useUpdate('LAB_CREATED');
  const service = useLabResultInsert({});
  const { send: sendMixpanel } = useMixpanelContext();
  const { value: isLoading, setTrue, setFalse } = useBoolean(false);
  const [value, setValue] = useLocalStorage<string | undefined>(
    StorageKeyEnum.LAB_DATE_COLLECTED,
    undefined
  );
  const { setJustAddedLabResultId } = useLabResultContext();
  const { send } = useBillableTimeInterventionComp();
  const { parseCreateLabResultItem } = useLabResultHelper();

  const onCreate = useCallback(
    async (v: SubmitValue) => {
      setTrue();

      send({
        patientId,
        component: BillableTimeInterventionComponentEnum.Transcribing,
        detail: (
          // eslint-disable-next-line max-len
          BillableTimeInterventionDetailEnum.TranscribingVisitTranscribeLabResultAddLabResultComplete
        )
      });

      sendMixpanel({ event: MixpanelEvents.AddLabResult });

      const result = await service.send({
        params: {
          document: parseCreateLabResultItem(patientId || '', v),
        },
      });
      if (result?.code === 200) {
        message.success(TranscribeTexts.createSuccess);
        updateHook.updateValue();
        // save the time
        setValue(v.testDate.format(DATE_TIME_CALENDAR));
        setJustAddedLabResultId?.(result.data?.id ?? '');

        onSubmit?.();
      } else if (result?.code && result.code >= 400) {
        message.warning(TranscribeTexts.error);
      }
      setFalse();
    },
    [service],
  );

  const initialValues = {
    testDate: dayjs(value ?? dayjs())
  };

  return (
    <CreateEditLabResultFormComponent
      mode="Create"
      onFinish={onCreate}
      isLoading={isLoading}
      initialValues={initialValues}
    />
  );
};
