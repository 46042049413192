import {
  ReactNode, useCallback,
  useState
} from 'react';
import classNames from 'classnames';
import { Button, Select } from 'antd';
import { map } from 'lodash';
import { DraggableComponent } from '../../DraggableComponent/DraggableComponent';
import { Minimizable } from '../../../contexts/Minimizable/Minimizable';
import { MinimizableChildComponent } from '../../../contexts/Minimizable/MinimizableChildComponent';
import { MinimizableControlComponent } from '../../../contexts/Minimizable/MinimizableControlComponent';
import { StorageKeyEnum, useSessionStorage } from '../../../hooks/useSessionStorage';
import { resetErrorBoundaryManually } from '../ErrorBoundaryCardComponent';

import './ErrorSimulator.scss';
import { ErrorBoundaryIdentifier } from '../errorBoundaryIdentifier';
import { ErrorBoundaryInfoKey } from '../ErrorBoundaryComponent';
import DisplayOrEmptyComponent from '../../DisplayComponent/DisplayOrEmptyComponent';

enum ErrorSimulatorStorageKey {
  ErrorSimulator = 'ErrorSimulator',
  ErrorSimulatorTriggered = 'ErrorSimulatorTriggered',
}

enum ErrorSimulatorSpotKey {
  App = 'App',
  Page = 'Page',
}

interface ErrorSimulatorPlaceHolderComponentProps {
  spot?: string | keyof typeof ErrorSimulatorSpotKey | keyof typeof ErrorBoundaryIdentifier;
}

export const ErrorSimulatorPlaceHolderComponent = ({
  spot
}: ErrorSimulatorPlaceHolderComponentProps) => {
  const [
    errorTriggered,
  ] = useSessionStorage<ErrorSimulatorSpotKey>(
    ErrorSimulatorStorageKey.ErrorSimulatorTriggered as string as StorageKeyEnum
  );
  if (spot && errorTriggered === spot) {
    throw new Error('Test Error');
  }
  return null;
};

export const resetSimulatorErrorTriggered = () => {
  if (sessionStorage.getItem(ErrorSimulatorStorageKey.ErrorSimulatorTriggered)) {
    sessionStorage.removeItem(ErrorSimulatorStorageKey.ErrorSimulatorTriggered);
  }
};

export interface ErrorSimulatorComponentProps {
  children?: ReactNode;
}
export const ErrorSimulatorComponent = () => {
  const [
    ,
    setErrorTriggered,
  ] = useSessionStorage<ErrorSimulatorSpotKey>(
    ErrorSimulatorStorageKey.ErrorSimulatorTriggered as string as StorageKeyEnum,
  );
  const [
    errorBoundaryInfo,
    setErrorBoundaryInfo,
  ] = useSessionStorage<string>(ErrorBoundaryInfoKey as StorageKeyEnum);
  const [
    spot,
    setSpot,
  ] = useState<ErrorSimulatorSpotKey | undefined>();

  const handleClearInfo = () => {
    setErrorBoundaryInfo('');
  };

  const triggerError = useCallback(() => {
    if (!spot) return;
    setTimeout(() => {
      setErrorTriggered(spot);
    }, 1000);
  }, [spot]);

  const renderControl = useCallback(() => {
    const getOptions = (enumList: string[]) => (
      map(enumList, (value) => ({ label: value, value }))
    );

    return (
      <DraggableComponent className="error-simulator">
        <Minimizable>
          {
            ({ isMinimized }) => (
              <div
                className={classNames({
                  minimized: isMinimized,
                })}
              >
                <MinimizableControlComponent>
                  <div>Error Simulator v1</div>
                </MinimizableControlComponent>
                <MinimizableChildComponent>
                  <div className="flex">
                    <Button
                      type="primary"
                      onClick={triggerError}
                    >
                      Trigger Error
                    </Button>
                    <Button
                      onClick={resetErrorBoundaryManually}
                    >
                      Reset
                    </Button>
                  </div>
                  <Select
                    allowClear
                    options={[
                      ...getOptions(Object.keys(ErrorSimulatorSpotKey)),
                      ...getOptions(Object.keys(ErrorBoundaryIdentifier)),
                    ]}
                    value={spot}
                    onChange={setSpot}
                    className="w100 pt20"
                  />
                  <div className="pt20">
                    <div className="flex ai-c">
                      Error boundary info
                      <Button onClick={handleClearInfo}>Clear</Button>
                    </div>
                    <div className="error-boundary-info-text">
                      <DisplayOrEmptyComponent
                        value={JSON.stringify(errorBoundaryInfo)}
                      />
                    </div>
                  </div>
                </MinimizableChildComponent>
              </div>
            )
          }
        </Minimizable>
      </DraggableComponent>
    );
  }, [triggerError, errorBoundaryInfo]);

  return (
    <>
      {renderControl()}
    </>
  );
};
