import React from 'react';
import { BillableEventStatusEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { BillableEventStatusTagComponent } from '../../features/patient/component/TagsComponent/BillableEventStatusTagComponent/BillableEventStatusTagComponent';

export interface BillableEventStatusEnumComponentProps {
  value?: BillableEventStatusEnum;
  showIcon?: boolean;
}

export const BillableEventStatusEnumComponent: React.FC<BillableEventStatusEnumComponentProps> = ({
  value,
  showIcon = false,
}: BillableEventStatusEnumComponentProps) => {
  const getFullName = () => {
    switch (value) {
      case BillableEventStatusEnum.PROCESSING:
        return 'Processing....';
      case BillableEventStatusEnum.REVIEW_REQUIRED:
        return 'Review Required';
      case BillableEventStatusEnum.CONFIRMED:
        return 'Confirmed';
      case BillableEventStatusEnum.MERGED_DROP:
        return 'Merged Drop';
      case BillableEventStatusEnum.SYSTEM_DROP:
        return 'System Drop';
      case BillableEventStatusEnum.REVIEW_EXPIRED:
        return 'Review Expired';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  const getIcon = () => {
    switch (value) {
      case BillableEventStatusEnum.PROCESSING:
        return <BillableEventStatusTagComponent.ProcessingTag />;
      case BillableEventStatusEnum.REVIEW_REQUIRED:
        return <BillableEventStatusTagComponent.ReviewedRequiredTag />;
      case BillableEventStatusEnum.CONFIRMED:
        return <BillableEventStatusTagComponent.ConfirmedTag />;
      case BillableEventStatusEnum.SYSTEM_DROP:
        return <BillableEventStatusTagComponent.SystemDropTag />;
      case BillableEventStatusEnum.REVIEW_EXPIRED:
        return <BillableEventStatusTagComponent.ReviewExpiredTag />;
      default: return <DisplayOrEmptyComponent />;
    }
  };

  if (showIcon) {
    return (
      <div className="flex">
        {getIcon()}
        {getFullName()}
      </div>
    );
  }
  return (<>{getFullName()}</>);
};
