import { Card, Checkbox } from 'antd';
import { ReactNode } from 'react';
import { InputType } from '../../../Input/types';
import { LLMViewSourceComponent, LLMViewSourceComponentProps } from '../LLMViewSourceComponent/LLMViewSourceComponent';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';

export interface LLMResultCardComponentProps extends InputType<boolean> {
  children: ReactNode;
  title?: ReactNode;
  getSourceData?: LLMViewSourceComponentProps['getSourceData'];
  getSourceNavigator?: LLMViewSourceComponentProps['renderNavigator'];
}

export const LLMResultCardComponent = ({
  children,
  title,
  value,
  onChange,
  getSourceData,
  getSourceNavigator = () => null,
  disabled,
}: LLMResultCardComponentProps) => (
  <Card>
    <div className="flex ai-c jc-sb">
      <Checkbox
        checked={value}
        onChange={(e) => onChange?.(e.target.checked)}
        disabled={disabled}
      >
        <TextComponent bold>
          {title}
        </TextComponent>
      </Checkbox>
      <LLMViewSourceComponent
        getSourceData={getSourceData}
        renderNavigator={getSourceNavigator}
        disabled={disabled}
      />
    </div>
    <div className="mt20">
      {children}
    </div>
  </Card>
);
