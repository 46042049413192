import React from 'react';
import { SmartAlertTaskStatus } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface SmartAlertTaskStatusEnumComponentProps {
  value?: SmartAlertTaskStatus;
}

export const SmartAlertTaskStatusEnumComponent = ({
  value,
}: SmartAlertTaskStatusEnumComponentProps) => {
  const getFullName = () => {
    switch (value) {
      case SmartAlertTaskStatus.TODO: return 'Open';
      case SmartAlertTaskStatus.DONE: return 'Resolved';
      case SmartAlertTaskStatus.MUTE: return 'Snoozed';
      default: return <DisplayOrEmptyComponent />;
    }
  };
  return (<>{getFullName()}</>);
};
