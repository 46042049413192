import { TranscribeStatusTagComponent } from '../../features/patient/component/TagsComponent/TranscribeStatusTagComponent/TranscribeStatusTagComponent';
import { TranscribeStatusEnum } from '../../uc-api-sdk';
import EmptyComponent from '../../uiComponent/EmptyComponent/EmptyComponent';

export interface TranscribeStatusEnumComponentProps {
  value: TranscribeStatusEnum | null | undefined;
  type?: 'tag' | 'text';
}

export const TranscribeStatusEnumComponent = ({
  value,
  type = 'tag',
}: TranscribeStatusEnumComponentProps) => {
  const getTag = (): React.ReactNode => {
    switch (value) {
      case TranscribeStatusEnum.COMPLETE:
        return <TranscribeStatusTagComponent.CompleteTag />;
      case TranscribeStatusEnum.TODO:
        return <TranscribeStatusTagComponent.IncompleteTag />;
      default:
        return <EmptyComponent />;
    }
  };

  const getFullName = () => {
    switch (value) {
      case TranscribeStatusEnum.TODO: return 'To do';
      case TranscribeStatusEnum.NO_RECORD: return 'No record';
      case TranscribeStatusEnum.COMPLETE: return 'Completed';
      default: return null;
    }
  };

  switch (type) {
    case 'tag':
      return <span>{getTag()}</span>;
    default:
      return <>{getFullName()}</>;
  }
};
