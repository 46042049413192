import React, { FC } from 'react';
import { Measurement } from '../../../../uc-api-sdk';
import { useMeasurementResultToTablePrint } from '../../hook/useMeasurementResultToTablePrint/useMeasurementResultToTablePrint';
import { NonNullUndefined } from '../../../../types/common';
import { HSResultHelper } from '../../helper/HSResultHelper';
import { HSTablePrintComponent } from './HSTablePrintComponent';
import { HSChartData } from '../HSChartComponent/HSChartComponent';

export interface HSTablePrintDataProcessorComponentProps {
  data: Measurement[];
}

export const HSTablePrintDataProcessorComponent: FC<HSTablePrintDataProcessorComponentProps> = ({
  data,
}) => {
  const processedData = useMeasurementResultToTablePrint(
    data as NonNullUndefined<Measurement>[],
    (curr) => (HSResultHelper.toDataTable(curr) as HSChartData),
  );

  return (
    <HSTablePrintComponent data={processedData} />
  );
};
