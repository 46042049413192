import {
  compact,
  isEmpty, map, trim
} from 'lodash';
import { Address } from '../../uc-api-sdk';
import { AddressFormValues } from '../../uiComponent/AddressFormItemComponent/AddressFormItemComponent';

export type AddressParams = Address | AddressFormValues;
export const makeAddressString = <T extends AddressParams>(
  address?: T,
  showEmpty?: boolean, // if true, there will be something like ', , ' in the address string
) => {
  if (isEmpty(address)) return '';
  const streetName = (address as Address).streetName || (address as AddressFormValues).address || '';
  const streetNumber = (address as Address).streetNumber || (address as AddressFormValues).address2 || '';
  const city = (address as Address).city || '';
  const state = (address as Address).state || '';
  const postCode = (address as Address).postCode || (address as AddressFormValues).zipcode || '';
  const addressTokens = [`${streetName} ${streetNumber}`, city, `${state} ${postCode}`];
  let addressString = '';
  if (!showEmpty) {
    addressString = compact(map(addressTokens, trim)).join(', ');
  } else {
    addressString = addressTokens.join(', ');
  }
  return trim(addressString);
};

export interface EhrAddressParams extends Address {
  name?: string;
  businessName?: string;
}

export const makeEhrOrgAddressString = (address: EhrAddressParams) => {
  const addressString = makeAddressString(address);
  return `${address.name || address.businessName || ''} ${addressString}`.trim();
};
