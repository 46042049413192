import { Col, Row, Select } from 'antd';
import { AccountHelper } from '../../../../helpers/account';
import { PatientSearchSelectItemComponentProps } from '../../../visit/component/PatientSearchSelectItemComponent/PatientSearchSelectItemComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { BirthdayComponent } from '../../../../uiComponent/BirthdayComponent/BirthdayComponent';
import { PatientSearchSelectComponentInfo } from './PatientSearchSelectComponent';
import { EnrolledProgramStatusEnum, PatientResult } from '../../../../uc-api-sdk';
import { PhoneService } from '../../../../services/PhoneService';
import { EnrolledProgramService } from '../../../../services/EnrolledProgramService';
import { ProgramCategoryEnumListComponent } from '../../../../enumComponent/ProgramCategoriesComponent/ProgramCategoryEnumListComponent';
import { GenderEnumComponent } from '../../../../enumComponent/GenderEnumComponent/GenderEnumComponent';
import { ClinicNameDisplayComponent } from '../../../../uiComponent/DisplayComponent/ClinicDisplayComponent/ClinicNameDisplayComponent';
import { EnrolledProgramStatusEnumComponent } from '../../../../enumComponent/EnrolledProgramStatusEnumComponent/EnrolledProgramStatusEnumComponent';
import PhoneComponent from '../../../../uiComponent/PhoneComponent/PhoneComponent';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import { PotentialClinicTooltipComponent } from '../PotentialClinicTooltipComponent/PotentialClinicTooltipComponent';

interface PatientSelectOptionInHeaderComponentProps {
  data: PatientSearchSelectComponentInfo<PatientResult>;
}

const PatientSelectOptionInHeaderComponent = ({
  data,
}: PatientSelectOptionInHeaderComponentProps) => {
  const pService = new PhoneService(data.info.phone);
  const pro = new EnrolledProgramService(data.info.enrolledProgram ?? undefined);

  const renderEnrollStatus = () => {
    switch (pro.getStatus()) {
      case EnrolledProgramStatusEnum.ENROLLED:
        return (
          <ProgramCategoryEnumListComponent
            value={pro.getPrograms()}
            type="short"
            splitter=", "
          />
        );
      default:
        return (
          <EnrolledProgramStatusEnumComponent value={pro.getStatus()} />
        );
    }
  };

  return (
    <Row gutter={24}>
      <Col span={5}>
        <div className="breakword of-hidden link-color">
          <DisplayOrEmptyComponent value={data.fullName} />
        </div>
        <div className="breakword of-hidden fs-small text-secondary-gray">
          <GenderEnumComponent value={data.info.profile?.gender ?? undefined} />
          ,
          <BirthdayComponent value={data.dob} showHowOld={false} />
        </div>
      </Col>
      <Col className="breakword of-hidden" span={5}>
        <ClinicNameDisplayComponent
          clinic={data.info.clinic || undefined}
        />
        <PotentialClinicTooltipComponent
          clinics={data.info.associatedList || []}
        />
      </Col>
      <Col className="breakword of-hidden" span={5}>
        <DisplayOrEmptyComponent
          value={AccountHelper.getFullName(data.info.profile?.doctorUser)}
        />
      </Col>
      <Col className="breakword of-hidden" span={3}>
        <DisplayOrEmptyComponent value={data.info.medicalRecordId} />
      </Col>
      <Col span={3}>
        {pService.getMobilePhoneNumber()
          ? (
            <PhoneComponent
              value={pService.getMobilePhoneNumber() as string}
              divider={['', '-', '']}
            />
          ) : <EmptyComponent />}
      </Col>
      <Col span={3}>
        {renderEnrollStatus()}
      </Col>
    </Row>
  );
};

export const patientSearchOptionInHeaderRenderer = (d: PatientSearchSelectItemComponentProps['data']) => (
  <Select.Option key={d.id} label={d.fullName}>
    <PatientSelectOptionInHeaderComponent data={d} />
  </Select.Option>
);
