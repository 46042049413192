import { FC, useCallback } from 'react';
import { DATE_STANDARD } from '../../../../constants/timeFormat';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import useChangePage from '../../../../hooks/useChangePage/useChangePage';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { PhoneTypeEnum, RaceEnum, usePatientInsert } from '../../../../uc-api-sdk';
import { PatientCreateFormComponent, PatientFormValues } from '../../../../uiComponent/PatientCreateFormComponent/PatientCreateFormComponent';
import { useInsuranceUpdateHandler } from '../../../Insurance/hook/insuranceUpdateHandler';

export interface PatientCreateContainerProps {
  onSubmit?: () => void;
  onCancel?: () => void;
  showEnrollmentDrawer: boolean;
}
export const PatientCreateContainer: FC<PatientCreateContainerProps> = ({
  onSubmit,
  onCancel,
  showEnrollmentDrawer,
}) => {
  const createPatient = usePatientInsert({});
  const { updateInsurance } = useInsuranceUpdateHandler();

  const { goToCarePortalPatient } = useChangePage();
  const create = useCallback(async (values: PatientFormValues) => {
    const phones = [];
    if (values.phone1) {
      phones.push({
        number: values.phone1,
        type: PhoneTypeEnum.MOBILE,
        countryCode: '1',
        canUseForLogin: values.canUseForLogin,
      });
    }
    if (values.phone2) {
      phones.push({
        number: values.phone2,
        type: PhoneTypeEnum.HOME,
        countryCode: '1',
      });
    }
    const result = createPatient.send({
      params: {
        document: {
          ...values,
          inquiryIdList: values.inquiryIdList,
          phone: phones,
          profile: {
            ...values,
            race: values.race as RaceEnum,
            languages: values.spokenLanguage,
            birthday: values.birthday.format(DATE_STANDARD),
          },
        },
      },
    });
    await ApiRequestHelper.tryCatch(result, {
      success: 'Patient created!',
      error: 'An error occurred. Please try again later.',
      onSuccess: (v) => {
        const patientId = v?.data?.id;
        if (patientId) {
          updateInsurance(values, {
            success: '',
            error: 'Failed to save insurance.',
          }, patientId);
          onSubmit?.();
          goToCarePortalPatient({ patientId, showEnrollmentDrawer });
        }
      }
    });
  }, [createPatient]);

  const debouncedCreate = useDebounce(create, 300, [create]);

  return (
    <PatientCreateFormComponent
      onSubmit={debouncedCreate}
      onCancel={onCancel}
      isLoading={createPatient.isLoading}
    />
  );
};
