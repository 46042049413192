import React from 'react';
import {
  InputNumber, InputNumberProps,
} from 'antd';

export interface PhysicalActivityLevelInputComponentProps extends
InputNumberProps<number> {
}
export const PhysicalActivityLevelInputComponent = ({
  value,
  onChange,
}: PhysicalActivityLevelInputComponentProps) => (
  <InputNumber
    precision={0}
    value={value}
    onChange={onChange}
    min={0}
    addonAfter={<span className="text-secondary-gray">min</span>}
  />
);
