/* eslint-disable class-methods-use-this */
import { CurrentMonthlyTime, Nullable } from '../uc-api-sdk';

export class PatientBillableTimeService {
  private billableMonthlyTime?: Nullable<CurrentMonthlyTime>;

  constructor(billableMonthlyTime?: Nullable<CurrentMonthlyTime>) {
    this.billableMonthlyTime = billableMonthlyTime;
  }

  public getBillableTime() {
    return this.billableMonthlyTime?.confirmedBillableMinutes;
  }

  public getBillableTimeTarget() {
    return this.billableMonthlyTime?.billableMinutesTarget;
  }

  public isQualified() {
    return this.billableMonthlyTime?.timeEligible;
  }

  public getMeasurementDays() {
    const days = this.billableMonthlyTime?.measurementDays;
    return days;
  }

  public getMeasurementDaysTarget() {
    return this.billableMonthlyTime?.measurementDaysTarget;
  }

  public isDaysQualified() {
    return this.billableMonthlyTime?.measurementEligible;
  }

  public getProcessingMinute() {
    return this.billableMonthlyTime?.processingBillableMinutes;
  }
}
