import { map } from 'lodash';
import { VisitTypeEnumComponent } from '../../../../enumComponent/VisitTypeEnumComponent/VisitTypeEnumComponent';
import { PatientCommonCardComponent } from '../PatientCardComponent/PatientCommonCardComponent';
import { ClinicEventsService } from './PatientVisitsItemComponent/ClinicEventsService';
import { PatientVisitsItemComponent } from './PatientVisitsItemComponent/PatientVisitsItemComponent';
import './PatientVisitsListComponent.scss';

interface PatientPageVisitListComponentProps{
  data: ClinicEventsService[];
  onEdit?: (value: ClinicEventsService) => void;
}

export const PatientVisitListComponent = ({
  data,
  onEdit,
}: PatientPageVisitListComponentProps) => (
  <div className="patient-page-visit-list-component">
    {map(data, (value, i) => (
      <PatientCommonCardComponent
        key={i}
        title={value.isOtherEvent ? 'Other Event' : (
          <VisitTypeEnumComponent
            value={value.visitType}
          />
        )}
        showHeaderOutside={false}
        showEdit={value.isEditable}
        onEdit={() => onEdit?.(value)}
        content={(
          <PatientVisitsItemComponent
            value={value}
          />
        )}
      />
    ))}
  </div>
);
