export const useOriginTrial = (originTrial?: string) => {
  const ifOriginTrialExists = (originTrial?: string) => {
    if (!originTrial) {
      return false;
    }
    const otMeta = document.querySelector(`meta[http-equiv="origin-trial"][content="${originTrial}"]`);
    return !!otMeta;
  };

  const mount = (_originTrial = originTrial) => {
    if (!_originTrial || ifOriginTrialExists(_originTrial)) return;
    const otMeta = document.createElement('meta');
    otMeta.httpEquiv = 'origin-trial';
    otMeta.content = _originTrial;
    document.head.append(otMeta);
  };

  const unmount = (_originTrial = originTrial) => {
    if (!ifOriginTrialExists(_originTrial)) return;
    const otMeta = document.querySelector(`meta[http-equiv="origin-trial"][content="${_originTrial}"]`);
    if (otMeta) {
      otMeta.remove();
    }
  };

  return {
    mount,
    unmount
  };
};
