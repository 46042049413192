import { FC } from 'react';
import { VitalMonitoringRemoveButtonComponent } from '../../component/VitalsMonitoringScheduleFormComponent/VitalMonitoringRemoveButtonComponent/VitalMonitoringRemoveButtonComponent';
import { VitalType } from '../../helper/VitalHelper';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useNotImplemented } from '../../../../hooks/useNotImplemented/useNotImplemented';

export interface VitalMonitoringRemoveButtonContainerProps {
  vitalType: VitalType;
  canRemove: () => boolean;
  onRemove: () => void;
}

export const VitalMonitoringRemoveButtonContainer: FC<
  VitalMonitoringRemoveButtonContainerProps
> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  vitalType,
  canRemove,
  onRemove,
}) => {
  const { info } = usePatientContext();
  const update = useNotImplemented('VitalMonitoringRemoveButtonContainer');

  const handleRemove = () => {
    if (info?.enrolledProgram?.id) {
      update.onChange();
      onRemove();
    }
  };

  return (
    <VitalMonitoringRemoveButtonComponent
      canRemove={canRemove}
      onClick={handleRemove}
    />
  );
};
