import { Dayjs } from 'dayjs';
import { ColumnType } from 'antd/es/table';
import { ReactNode } from 'react';
import { TimeOfDay } from '../../../../helpers/timezone/timezoneService';
import { measurementTableDateColumnMaker } from '../MeasurementTable/MeasurementTableColumn';

export interface TimeOfDayTableData {
  date: Dayjs;
  timeOfDay: TimeOfDay;
}

export type TimeOfDayTableColumnRender<T extends TimeOfDayTableData> = ((v: T) => ReactNode);

const createTimeOfDayPrintColumn = <T extends TimeOfDayTableData>(
  title: ReactNode,
  key: TimeOfDay,
  render: TimeOfDayTableColumnRender<T>,
) => (
    (): ColumnType<T> => ({
      title,
      key,
      render: (_value, data) => (
        data.timeOfDay === key ? render(data) : null
      ),
      width: '20%',
    })
  );

export const TimeOfDayTablePrintColumns = <T extends TimeOfDayTableData>(
  renderer: TimeOfDayTableColumnRender<T>,
) => ({
    date: measurementTableDateColumnMaker('20%'),
    overnight: createTimeOfDayPrintColumn('Overnight (24:00 - 4:00)', 'OVERNIGHT', renderer),
    morning: createTimeOfDayPrintColumn('Morning (4:00 - 12:00)', 'MORNING', renderer),
    afternoon: createTimeOfDayPrintColumn('Afternoon (12:00 - 18:00)', 'AFTERNOON', renderer),
    evening: createTimeOfDayPrintColumn('Evening (18:00 - 24:00)', 'EVENING', renderer),
  });
