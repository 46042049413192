import { Input, InputProps } from 'antd';
import { isNaN } from 'lodash';
import { useState } from 'react';
import { InputType } from '../../../Input/types';

export interface POBaselineInputComponentProps extends InputType<number> {

}

export const POBaselineInputComponent = ({
  value,
  onChange,
}: POBaselineInputComponentProps) => {
  const [inputValue, setInputValue] = useState(value);
  const handleChange: InputProps['onChange'] = (evt) => {
    const v = evt.target.value;
    const digits = v.replace(/\D/g, '');
    if (!isNaN(+digits) || undefined) {
      onChange?.(digits ? +digits : 0);
      setInputValue(+digits);
    }
  };

  return (
    <Input
      inputMode="decimal"
      className="input-small"
      suffix="%"
      value={inputValue}
      onChange={handleChange}
    />
  );
};
