import { useMemo } from 'react';
import { map } from 'lodash';
import { usePatientPatientList, Sort$Direction, PatientListTypeEnum } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { PinnedPatientsComponent, PatientInfo } from '../../component/PinnedPatientsComponent/PinnedPatientsComponent';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';

export interface PinnedPatientsContainerProps {

}

export const PinnedPatientsContainer = () => {
  const { info } = usePatientContext();
  const patientId = info?.id;

  const pinnedPatientsInfo = usePatientPatientList({
    options: { sendOnMount: true },
    params: {
      searchRequest: {
        filter: {
          listType: PatientListTypeEnum.ENROLLED,
          patient: {
            filterPinnedPatient: true,
          },
        },
        pageInfo: {
          pagination: false,
          sort: [{
            direction: Sort$Direction.ASC,
            property: 'pinInfo.pinnedDate',
          }]
        }
      },
    },
  });

  useUpdateListener('PIN_PATIENT', pinnedPatientsInfo.refetch);
  useUpdateListener('PATIENT_DISCHARGED', pinnedPatientsInfo.refetch);

  const pinnedPatients = useMemo<PatientInfo[]>(() => (
    map(pinnedPatientsInfo.data?.data?.content, (v): PatientInfo => ({
      id: v.id || '',
      firstName: v.profile?.firstName,
      lastName: v.profile?.lastName,
    }))
  ), [pinnedPatientsInfo.data]);

  return (
    <FetchComponent info={pinnedPatientsInfo} showOnRefetch>
      <PinnedPatientsComponent data={pinnedPatients} currentPatientId={patientId} />
    </FetchComponent>
  );
};
