import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useAssignDeviceForm } from '../../../patient/hook/useAssignDeviceForm';
import { TechLevelRadioComponent, TechLevelRadioComponentProps } from '../TechLevelRadioComponent/TechLevelRadioComponent';

export interface EnrollmentTechLevelFormItemComponentProps {
  onChange?: TechLevelRadioComponentProps['onChange'];
}

export const EnrollmentTechLevelFormItemComponent = ({
  onChange,
}: EnrollmentTechLevelFormItemComponentProps) => {
  const hook = useAssignDeviceForm();
  return (
    <FormItem
      info={hook.getInfo('techLevel')}
      className="mb50"
    >
      <TechLevelRadioComponent onChange={onChange} />
    </FormItem>
  );
};
