import { FC } from 'react';
import { Col, Row, Select } from 'antd';
import { PatientSearchSelectComponentInfo } from '../../../patient/component/PatientSearchSelectComponent/PatientSearchSelectComponent';
import { Patient } from '../../../../uc-api-sdk';
import PhoneComponent from '../../../../uiComponent/PhoneComponent/PhoneComponent';
import { PatientSearchSelectItemNameGenderDobComponent } from '../../../../uiComponent/PatientBasicInfo/PatientSearchSelectItemNameGenderDobComponent/PatientSearchSelectItemNameGenderDobComponent';
import { ClinicNameDisplayComponent } from '../../../../uiComponent/DisplayComponent/ClinicDisplayComponent/ClinicNameDisplayComponent';

export interface PatientSearchSelectItemComponentProps {
  data: PatientSearchSelectComponentInfo<Patient>;
}

export const PatientSearchSelectItemComponent: FC<PatientSearchSelectItemComponentProps> = ({
  data,
}) => (
  <Row align="middle">
    <Col span={6}>
      <PatientSearchSelectItemNameGenderDobComponent
        name={data.fullName}
        gender={data.info.profile?.gender}
        dob={data.dob}
      />
    </Col>
    <Col span={10}>
      <ClinicNameDisplayComponent clinic={data.info.clinic || undefined} />
    </Col>
    <Col span={4}>
      {data.info.medicalRecordId || 'N/A'}
    </Col>
    <Col span={4}>
      <PhoneComponent
        value={data.info.phone?.at(0)?.number || ''}
        divider={['', '-', '']}
      />
    </Col>
  </Row>
);

export const patientSearchSelectOptionRenderer = (d: PatientSearchSelectItemComponentProps['data']) => (
  <Select.Option key={d.id} label={d.fullName}>
    <PatientSearchSelectItemComponent data={d} />
  </Select.Option>
);
