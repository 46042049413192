import React, { FC } from 'react';
import { TextAreaProps } from 'antd/lib/input';
import TextArea from 'antd/lib/input/TextArea';

export type MedicationNotesTextAreaComponentProps = TextAreaProps;

export const MedicationNotesTextAreaComponent:
  FC<MedicationNotesTextAreaComponentProps> = ({
    value,
    onChange,
  }) => (
    <TextArea
      rows={3}
      value={value}
      onChange={onChange}
      allowClear
      placeholder="Enter any additional information here (eg., family history, treament history, etc..."
    />
  );
