import { MessagePayload, VitalDurationMessagePayload } from '../../../../services/CHSServices/types/data';
import { MessageVitalDurationComponent } from '../MessageVitalComponent/MessageVitalDurationComponent/MessageVitalDurationComponent';

export interface MessageMeasurementsItemComponentProps {
  messagePayload: MessagePayload;
}

export const MessageMeasurementsItemComponent = ({
  messagePayload,
}: MessageMeasurementsItemComponentProps) => {
  const {
    vitalName,
    dateFrom,
    dateTo,
    text,
    duration,
  } = messagePayload as VitalDurationMessagePayload;
  return (
    <MessageVitalDurationComponent
      vitalName={vitalName}
      dateFrom={dateFrom}
      dateTo={dateTo}
      text={text}
      duration={duration}
    />
  );
};
