import classNames from 'classnames';
import { useEffect } from 'react';
import { AnchorProvider } from './AnchorContext';
import { AnchorChildComponent, AnchorChildComponentProps } from './AnchorChildComponent';
import { useRouter } from '../../hooks/useRouter/useRouter';

export interface AnchorComponentProps extends AnchorChildComponentProps {
  scrollOnMount?: boolean;
  hideAnchorLinks?: boolean;
}

export const AnchorComponent = ({
  children,
  offsetTop,
  targetOffset,
  scrollOnMount = true,
  hideAnchorLinks,
}: AnchorComponentProps) => {
  const { location } = useRouter();

  const waitForElm = (selector: string) => (
    new Promise<HTMLElement>((resolve) => {
      if (document.getElementById(selector)) {
        resolve(document.getElementById(selector) as HTMLElement);
        return;
      }

      const observer = new MutationObserver(() => {
        if (document.getElementById(selector)) {
          observer.disconnect();
          resolve(document.getElementById(selector) as HTMLElement);
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
    })
  );

  useEffect(() => {
    const func = async () => {
      if (location.hash) {
        const doc = await waitForElm(location.hash.replace('#', ''));
        if (scrollOnMount) {
          // currently just waits for 1 second to make sure everything is loaded
          // but we have to change this in the future so its not dependent on a set timer
          setTimeout(() => doc?.scrollIntoView({ behavior: 'smooth', block: 'start' }), 300);
        }
      }
    };
    func();
  }, [location.hash]);

  return (
    <div
      className={classNames({
        'anchor-component-main': true,
        'anchor-component-main__hide-anchors': !!hideAnchorLinks,
      })}
    >
      <AnchorProvider>
        <AnchorChildComponent
          offsetTop={offsetTop}
          targetOffset={targetOffset}
        >
          {children}
        </AnchorChildComponent>
      </AnchorProvider>
    </div>
  );
};
