import { useLoggedInUserFromContext } from '../../../contexts/loggedInUserContext';
import { useZoomContext } from '../../../contexts/ZoomContext/ZoomContext';
import { ApiRequestHelper } from '../../../helpers/ApiRequest';
import { useOpen } from '../../../hooks/useBoolean/useOpen';
import { useClinicEventCheckInVisit, VisitMethodEnum } from '../../../uc-api-sdk';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../billableTime/contants/contants';
import { useBillableTimeInterventionComp } from '../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { ClinicEventsService } from '../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';

export interface UseCheckInVisitOptions {
  disabled?: boolean;
  onCheckedIn?: () => void;
}

export const useCheckInVisit = () => {
  const {
    isOpen: isReassignParticipantsOpen,
    setValue: setIsReassignParticipantsOpen
  } = useOpen(false);
  const checkInHook = useClinicEventCheckInVisit({});
  const { closeNotification } = useZoomContext();
  const { send } = useBillableTimeInterventionComp();
  const { userId } = useLoggedInUserFromContext();

  const handleCheckIn = (
    visit: ClinicEventsService,
    options: UseCheckInVisitOptions,
  ) => {
    const {
      disabled,
      onCheckedIn,
    } = options;
    const patientId = visit.patientId || '';
    const visitId = visit.id || '';
    const isVideoVisit = visit.visitMethod === VisitMethodEnum.VIRTUAL;
    if (isVideoVisit) {
      send({
        patientId,
        component: BillableTimeInterventionComponentEnum.Charting,
        detail: BillableTimeInterventionDetailEnum.WorkVisitVideoCallCheckIn,
      });
    } else {
      send({
        patientId,
        component: BillableTimeInterventionComponentEnum.Charting,
        detail: BillableTimeInterventionDetailEnum.WorkVisitsCheckIn,
      });
    }
    if (disabled) return;
    closeNotification(visitId);
    if (visit.isCheckedIn) {
      onCheckedIn?.();
      return;
    }
    const isUserParticipant = (
      ClinicEventsService.ifUserParticipant(visit.visitParticipantsId, userId)
    );
    if (!isUserParticipant) {
      setIsReassignParticipantsOpen(true);
      return;
    }
    // start Check in visit
    ApiRequestHelper.tryCatch(
      checkInHook.send({
        params: { id: visitId }
      }),
      {
        success: 'Check in success!',
        error: 'Failed to check in!',
        onSuccess: () => {
          onCheckedIn?.();
        },
      },
    );
  };

  return {
    isReassignParticipantsOpen,
    setIsReassignParticipantsOpen,
    handleCheckIn
  };
};
