import { useLoggedInUserFromContext } from '../../../../../contexts/loggedInUserContext';
import { MiniUserComponent } from '../../MiniUserComponent/MiniUserComponent';

export const LoggedInMiniUserComponent = () => {
  const { avatarInfo, userInfo } = useLoggedInUserFromContext();
  return (
    <MiniUserComponent
      avatar={
        avatarInfo?.url || undefined
      }
      name={userInfo?.fullName}
      email={userInfo?.email}
    />
  );
};
