import React from 'react';
import { Dayjs } from 'dayjs';
import { DisplayDateComponent } from '../../DisplayComponent/DisplayDateComponent';
import './PatientSearchSelectItemNameGenderDobComponent.scss';

export interface PatientSearchSelectItemNameGenderDobComponentProps {
 name?: string;
 gender?: string | undefined | null;
 dob?: Dayjs | undefined;
}

export const PatientSearchSelectItemNameGenderDobComponent = ({
  name,
  gender,
  dob,
}: PatientSearchSelectItemNameGenderDobComponentProps) => (
  <div className="flex fd-c gap0">
    <div className="patientSearchSelectItemName">{name}</div>
    <div className="text-gray patientSearchSelectItemGenderDob">
      {gender}
      {', '}
      <DisplayDateComponent value={dob} format="BIRTH_DATE" />
    </div>
  </div>
);
