/*
ref: Yiwen Lu
IBW Formula (as Hamwi Formula):
Men:Ideal Body Weight [lbs] = 106 + (6 × (height [in] – 60))
Women:Ideal Body Weight [lbs] = 100 + (5 × (height [in] – 60))

ABW Formula:
Adjusted BW = IBW + 0.4 × (ActualBW - IBW).
*/
import { ReactNode } from 'react';
import { NumberService } from '../../helpers/number';
import { useDeepCompareMemo } from '../../hooks/useDeepCompareEffect';
import { GenderEnum, Height, Weight } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../DisplayComponent/DisplayOrEmptyComponent';
import EmptyComponent from '../EmptyComponent/EmptyComponent';
import { WeightService } from '../../helpers/weight';

export interface ABWComponentProps {
  label?: ReactNode;
  gender?: GenderEnum;
  weight?: Weight;
  height?: Height;
  showLabelWhenEmpty?: boolean;
  toFixed?: number;
  showUnit?: boolean;
  adjFactor?: number;
  isBmiNormal?: boolean;
}

export const ABWComponent = ({
  label = 'ABW:',
  gender,
  weight,
  height,
  isBmiNormal,
  showLabelWhenEmpty = true,
  toFixed = 2,
  showUnit = true,
  adjFactor = 0.4,
}: ABWComponentProps) => {
  const [
    weightInLb,
    heightInInch
  ] = useDeepCompareMemo(() => (
    NumberService.getStandardWeightAndHeight(weight, height)
  ), [weight, height]);

  if (!gender || !weightInLb || !heightInInch) {
    return (
      <>
        {showLabelWhenEmpty && <span>{label}</span>}
        <EmptyComponent />
      </>
    );
  }

  if (isBmiNormal) {
    return (
      <div className="flex">
        <span>{label}</span>
        <DisplayOrEmptyComponent emptyType="mini" />
      </div>
    );
  }

  const getIBWForMen = () => {
    let factor = heightInInch - 60;
    if (factor < -1) factor = 0;
    return 106 + (6 * factor);
  };

  const getIBWForWomen = () => {
    let factor = heightInInch - 60;
    if (factor < -1) factor = 0;
    return 100 + (5 * factor);
  };

  const getIBWValue = () => {
    switch (gender) {
      case GenderEnum.M: return getIBWForMen();
      case GenderEnum.F: return getIBWForWomen();
      default: return 0;
    }
  };

  const getValue = () => {
    const ibwValue = getIBWValue();
    return (ibwValue + (adjFactor * (weightInLb - ibwValue)));
  };

  const getValueText = () => getValue().toFixed(toFixed);

  return (
    <div className="flex">
      <span>
        {/* ie ABW: */}
        {label}
      </span>
      <DisplayOrEmptyComponent value={getValueText()} />
      {' '}
      {showUnit ? WeightService.weightUnitDisplay : null}
    </div>
  );
};
