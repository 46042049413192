import { map } from 'lodash';
import './FlowerSpin.scss';

export const FlowerSpin = () => (
  <div className="flower-spinner">
    {
      map(Array(12), (_, idx) => (
        <div key={idx} />
      ))
    }
  </div>
);
