import { Checkbox, CheckboxProps, Collapse } from 'antd';
import './IsTestPatientInputComponent.scss';

export interface IsTestPatientInputComponentProps {
  value?: CheckboxProps['value'];
  onChange?: CheckboxProps['onChange'];
}

const { Panel } = Collapse;
export const IsTestPatientInputComponent = ({
  value,
  onChange,
}: IsTestPatientInputComponentProps) => (
  <Collapse ghost expandIconPosition="end" className="is-test-patient-input-component">
    <Panel
      header={<span className="text-gray-scale-2 underline">Create a test patient?</span>}
      key="1"
    >
      <Checkbox value={value} onChange={onChange}>
        The patient is a test patient.
      </Checkbox>
      <p className="my10">
        {
          'Note: A test patient will not behave as a real patient in this clinic.'
          + 'Any data gathered for this patient will not be counted for '
          + 'analysis or billing purposes. '
        }
      </p>
      <span>
        <b>
          Please make sure to use "Test" as the last name for all test patients.
        </b>
      </span>
    </Panel>
  </Collapse>
);
