import { FormListFieldData } from 'antd';
import { DiseaseHistoryConditionFormItemComponent } from '../DiseaseHistoryConditionFormItemComponent/DiseaseHistoryConditionFormItemComponent';
import { DiseaseHistoryItemLayoutComponent } from '../DiseaseHistoryItemLayoutComponent/DiseaseHistoryItemLayoutComponent';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';

import './DiseaseHistoryListFieldItemComponent.scss';
import { DiseaseHistoryTemplateGeneralComponent } from '../DiseaseHistoryTemplateGeneralComponent/DiseaseHistoryTemplateGeneralComponent';
import { DiseaseHistoryTemplateByVitalComponent } from '../DiseaseHistoryTemplateByVitalComponent/DiseaseHistoryTemplateByVitalComponent';
import { ChronicDiseaseEnum } from '../../../../uc-api-sdk';
import { DiseaseHistory } from '../../helper';

const { Condition, Info } = DiseaseHistoryItemLayoutComponent;

export interface DiseaseHistoryListFieldItemComponentProps {
  disease: ChronicDiseaseEnum;
  field: FormListFieldData;
  isRemovable?: boolean;
  onRemove?: () => void;
}

export const DiseaseHistoryListFieldItemComponent = ({
  disease,
  field,
  isRemovable,
  onRemove,
}: DiseaseHistoryListFieldItemComponentProps) => {
  const {
    name: fieldName,
    key,
  } = field;

  const handleOnRemove = () => {
    onRemove?.();
  };

  const renderDiseaseHistoryTemplate = () => {
    const associatedVital = DiseaseHistory.getVitalFromChronicDisease(disease);
    const isGeneral = associatedVital === undefined;
    if (isGeneral) {
      return (
        <DiseaseHistoryTemplateGeneralComponent
          fieldName={fieldName}
        />
      );
    }
    return (
      <DiseaseHistoryTemplateByVitalComponent
        fieldName={fieldName}
      />
    );
  };

  return (
    <DiseaseHistoryItemLayoutComponent key={key}>
      <Condition>
        <div className="flex fd-c">
          <DiseaseHistoryConditionFormItemComponent
            listFieldName={fieldName}
          />
          {
            isRemovable
            && (
              <LinkButton
                onClick={handleOnRemove}
                color="var(--text-gray)"
                showUnderline
              >
                Remove
              </LinkButton>
            )
          }
        </div>
      </Condition>
      <Info>
        {renderDiseaseHistoryTemplate()}
      </Info>
    </DiseaseHistoryItemLayoutComponent>
  );
};
