import { ReactNode } from 'react';
import { Dayjs } from 'dayjs';
import { some } from 'lodash';
import { VisitOverlapTimeComponent } from '../../component/VisitOverlapTimeComponent/VisitOverlapTimeComponent';
import { OverlapEventResponse, useClinicEventCheckOverlapCalendarEvent } from '../../../../uc-api-sdk';

export interface VisitOverlapTimeContainerChildProps {
  hasOverlap: boolean;
}

export interface VisitOverlapTimeContainerProps {
  startTime?: Dayjs;
  endTime?: Dayjs;
  participants?: string[];
  visitIds?: string[];
  children?: (props: VisitOverlapTimeContainerChildProps) => ReactNode;
}

export const VisitOverlapTimeContainer = ({
  startTime,
  endTime,
  participants = [],
  visitIds,
  children = ({ hasOverlap }) => (
    <VisitOverlapTimeComponent hasOverlap={hasOverlap} />
  ),
}: VisitOverlapTimeContainerProps) => {
  const overLapReq = useClinicEventCheckOverlapCalendarEvent({
    options: { sendOnMount: participants.length > 0 && !!startTime && !!endTime },
    params: {
      overlapEventRequest: {
        startTime: startTime?.toISOString().replace('Z', ''),
        endTime: endTime?.toISOString().replace('Z', ''),
        memberIds: participants,
        excludedVisitIds: visitIds,
      },
    },
  });

  const hasOverlap = some(
    overLapReq.data?.data || [],
    (v: OverlapEventResponse) => v.overlapEvents?.length,
  );

  return (
    <>
      {children({ hasOverlap })}
    </>
  );
};
