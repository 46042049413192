import React, { useState } from 'react';
import { Popover, Tooltip } from 'antd';
import { Icons } from '../../../../icons/Icons';
import { TagComponent } from '../../../../uiComponent/TagComponent/TagComponent';

import './MessagePopoverComponent.scss';

export interface MessagePopoverComponentProps {
  handleTranslate: () => void;
  children?: React.ReactNode;
  containerId: string;
  showTranslation?: boolean;
}

export const MessagePopoverComponent = ({
  showTranslation,
  handleTranslate,
  children,
  containerId
}: MessagePopoverComponentProps) => {
  const [isTranslate, setIsTranslate] = useState(false);

  const getTooltipTitle = (showTranslation?: boolean) => {
    if (!showTranslation) {
      return (
        <div>
          <TagComponent type="info" iconType="halfSolid" background="none" iconColor="blue">
            Original content is English
          </TagComponent>
        </div>
      );
    }
    if (isTranslate) {
      return '';
    }
    return 'Translate';
  };

  const handleMouseEnter = () => {
    document.getElementById('translate-icon')?.click();
  };

  const handleTranslateIconClick = async () => {
    await handleTranslate();
    setIsTranslate(true);
  };

  const tooltipContent = (
    <Tooltip
      overlayClassName="chat-message-tooltip"
      trigger="hover"
      color="white"
      title={getTooltipTitle(showTranslation)}
    >
      <div onMouseEnter={handleMouseEnter}>
        <Icons.TranslateIcon
          onClick={handleTranslateIconClick}
          className={isTranslate ? 'disabled' : ''}
        />
      </div>
    </Tooltip>
  );

  return (
    <Popover
      overlayClassName="chat-message-popover"
      trigger="hover"
      content={tooltipContent}
      placement="topRight"
      getPopupContainer={(node: HTMLElement) => document.querySelector(`#${containerId}`)
      || node as HTMLElement}
    >
      {children}
    </Popover>
  );
};
