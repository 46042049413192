import { useCallback } from 'react';
import { FileUploadMessagePayload } from '../../../../services/CHSServices/types/data';
import { MessageFileUploadItemComponent } from '../MessageFileUploadItemComponent/MessageFileUploadItemComponent';

import { BucketsTypeEnum, useFileManagerDownloadUrl } from '../../../../uc-api-sdk';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import './MessageImageComponent.scss';

export interface MessageImageComponentProps extends Pick<FileUploadMessagePayload, 'text' | 'fileKey'> {
  fileKeyName?: string;
}

export const MessageImageComponent = ({
  text,
  fileKey,
  fileKeyName,
}: MessageImageComponentProps) => {
  const fileDownloadInfo = useFileManagerDownloadUrl({
    params: {
      fileDownloadUrlInput: {
        bucket: BucketsTypeEnum.FILESUPLOAD,
        fileKey,
      },
    },
    options: {
      sendOnMount: true,
      retry: 1,
      cache: true,
    },
  });

  const Image = useCallback(() => (
    <LoadingOverlayComponent
      isLoading={fileDownloadInfo.isLoading}
      skeletonType="avatar"
      showSkeleton
    >
      <img
        src={fileDownloadInfo.data?.data?.url as string}
        alt={fileKeyName}
        className="message-file-upload__image"
      />
    </LoadingOverlayComponent>
  ), [
    fileDownloadInfo.isLoading,
    fileDownloadInfo.data?.data?.url,
    fileKeyName,
  ]);

  return (
    <div className="message-item__image-content">
      <MessageFileUploadItemComponent
        text={text}
        button={<Image />}
        className="message-image-component"
      >
        <Image />
      </MessageFileUploadItemComponent>
    </div>
  );
};
