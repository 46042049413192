import { FormInstance } from 'antd';
import { Rule } from 'antd/lib/form';
import { every, upperFirst } from 'lodash';
import {
  containLowerCaseRegex, containNumberRegex, containSpecialCharacterRegex, containUpperCaseRegex
} from '../constants/regex/password';

type FormInstanceType = Omit<FormInstance, 'scrollToField' | 'getFieldInstance'>;

export interface PasswordRulesType {
  characterCount?: number;
  containNumber?: boolean;
  containLowerCase?: boolean;
  containUpperCase?: boolean;
  containSpecialCharacter?: boolean;
}

class PasswordFormService {
  static verifyConfirmPassword(
    confirmationInput: string,
    currentInput?: string,
  ) {
    return currentInput && currentInput === confirmationInput;
  }

  static getPasswordValidator(
    form: FormInstance,
    name = 'Password',
    passwordRules: PasswordRulesType = {},
  ) {
    const {
      characterCount,
      containNumber,
      containLowerCase,
      containUpperCase,
      containSpecialCharacter,
    } = passwordRules;

    return (_rule: Rule, value: string) => (
      new Promise((resolve, reject) => {
        if (!value) {
          reject(new Error(`${upperFirst(name)} is required`));
        }
        const requirementFulfilled = {
          characterCount: (
            (characterCount && characterCount > 0)
            && (value && value.length >= characterCount)
          ),
          containNumber: (
            containNumber && !!value.match(containNumberRegex)
          ),
          containLowerCase: (
            containLowerCase && !!value.match(containLowerCaseRegex)
          ),
          containUpperCase: (
            containUpperCase && !!value.match(containUpperCaseRegex)
          ),
          containSpecialCharacter: (
            containSpecialCharacter && !!value.match(containSpecialCharacterRegex)
          ),
        };
        form.setFieldsValue(requirementFulfilled);
        const isAllValid = every(Object.values(requirementFulfilled), (v) => v === true);
        if (!isAllValid) {
          reject();
        }
        resolve(true);
      })
    );
  }

  static getConfirmPasswordValidator(
    form: FormInstance,
    passwordFieldName = 'password',
  ) {
    return (_rule: Rule, value: string) => (
      new Promise((resolve, reject) => {
        const currentInput = form.getFieldValue(passwordFieldName);
        if (!PasswordFormService.verifyConfirmPassword(value, currentInput)) {
          reject(new Error('Must match previous entry'));
        }
        resolve(true);
      })
    );
  }

  static isRequirementFulFilled(
    form: FormInstanceType,
    key: string,
  ) {
    return !!form.getFieldValue(key); // false => no error
  }
}

export default PasswordFormService;
