import classNames from 'classnames';
import { ReactNode } from 'react';
import './TooltipTitleComponent.scss';

export interface TooltipTitleComponentProps {
  title: string | ReactNode,
  className?: string,
}
// tooltip as string and with simple format (ie new line)
// if don't want to show empty tooltip, put condition before calling this
// ie. title && <TooltipTitleComponent />
export const TooltipTitleComponent = ({
  title,
  className = '',
}: TooltipTitleComponentProps) => (
  <pre
    className={classNames({
      'tooltip-title-component': true,
      [className]: !!className,
    })}
  >
    {title}
  </pre>
);
