import { Typography } from 'antd';
import { EllipsisConfig } from 'antd/lib/typography/Base';
import { ReactNode } from 'react';

const { Text } = Typography;
export interface AntdEllipsesComponentProps extends EllipsisConfig {
  children?: ReactNode;
  width?: string | number;
  className?: string;
}

export const AntdEllipsisComponent = ({
  children,
  width,
  className,
  ...props
}: AntdEllipsesComponentProps) => (
  <Text
    style={{ width }}
    ellipsis={{ ...props, tooltip: children }}
    className={className}
  >
    {children}
  </Text>
);
