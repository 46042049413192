import classNames from 'classnames';
import { getRange, getTooltip } from '../../constants';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import './A1cTemplateFlagComponent.scss';
import { ReferenceLevelEnumComponent } from '../ReferenceLevelEnumComponent';
import { LabResultFlagComponentProps } from './LabResultFlagComponent';
import { ReferenceLevel } from '../../../../uc-api-sdk';

export interface A1cTemplateFlagComponentProps extends LabResultFlagComponentProps {
}

export const A1cTemplateFlagComponent = ({
  value,
  referenceLevels,
}: A1cTemplateFlagComponentProps) => {
  const r: ReferenceLevel | undefined | 0 = getRange(value, referenceLevels) ?? undefined;
  const referenceTag = r?.description;

  return (
    <TooltipComponent
      title={getTooltip('A1C', r ?? undefined)}
      placement="top"
      type={null}
      className={classNames({
        'a1c-status-component': true,
        [`${referenceTag}`]: true,
      })}
    >
      <div
        className={classNames({
          'a1c-status-component': true,
          [`${referenceTag}`]: true,
        })}
      >
        <ReferenceLevelEnumComponent value={referenceTag ?? undefined} />
      </div>
    </TooltipComponent>
  );
};
