import { CheckboxGroupProps } from 'antd/lib/checkbox';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { CheckboxGroupComponent } from '../../../../uiComponent/CheckboxGroupComponent/CheckboxGroupComponent';
import { TableFilterComponent } from '../TableFilterComponent/TableFilterComponent';

export interface CheckboxFilterComponentProps extends FilterDropdownProps {
  filterOptions: CheckboxGroupProps['options'],
  careUnitId?: string;
}
export const CheckboxFilterComponent = ({
  filterOptions,
  careUnitId,
  ...props
}: CheckboxFilterComponentProps) => (
  <TableFilterComponent
  // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    careUnitId={careUnitId}
    clearFilters={() => props.setSelectedKeys([])}
  >
    <div className="p5">
      <CheckboxGroupComponent
        isVertical
        options={filterOptions}
        value={props.selectedKeys as string[]}
        onChange={(v) => props.setSelectedKeys(v as React.Key[])}
      />
    </div>
  </TableFilterComponent>
);
