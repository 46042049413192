import { FC } from 'react';
import { Space } from 'antd';
import { map, max } from 'lodash';
import { MeasurementResultTypeEnum, Measurement } from '../../../../uc-api-sdk';
import { VitalChartTableSummaryViewComponent, VitalChartTableSummaryViewComponentProps } from '../VitalChartTableSummaryViewComponent/VitalChartTableSummaryViewComponent';
import { VitalFetchProps } from '../../type';
import { BOChartDataProcessorComponent } from '../BOChartComponent/BOChartDataProcessorComponent';
import { BOTableDataProcessorComponent } from '../BOTableComponent/BOTableDataProcessorComponent';
import { BOSummaryTableDataProcessorComponent } from '../BOSummaryTableComponent/BOSummaryTableDataProcessorComponent';
import { MeasurementType } from '../../type/MeasurementTypes';
import { POBaselineContainer } from '../../container/POBaselineContainer/POBaselineContainer';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { MeasurementTimestampTextComponent } from '../MeasurementTimestampTextComponent/MeasurementTimestampTextComponent';

import './BOVitalComponent.scss';

export interface BOVitalComponentProps extends VitalFetchProps {

}

export const BOVitalComponent: FC<BOVitalComponentProps> = ({
  memberId,
  fromDate,
  toDate,
}) => {
  const chartView: VitalChartTableSummaryViewComponentProps['chartView'] = ({ data, fromDate, toDate }) => (
    <BOChartDataProcessorComponent
      data={(data?.results?.content || []) as MeasurementType[]}
      fromDate={fromDate}
      toDate={toDate}
    />
  );
  const tableView: VitalChartTableSummaryViewComponentProps['tableView'] = ({ data, fromDate, toDate }) => {
    const highest = max(map(
      data?.results?.content,
      (v: Measurement) => v.blood_oxygen?.value,
    ));

    return (
      <Space direction="vertical" className="bo-vital w100">
        <BOTableDataProcessorComponent
          data={(data?.results?.content || []) as MeasurementType[]}
          fromDate={fromDate}
          toDate={toDate}
        />
        <MeasurementTimestampTextComponent />
        <Space size="large" align="start" className="mt20">
          <POBaselineContainer patientId={memberId} />
          <TextComponent className="pt30 inline-block" color="gray3">
            Highest:
            {' '}
            <DisplayOrEmptyComponent value={highest ? `${highest}%` : undefined} />
          </TextComponent>
        </Space>
      </Space>
    );
  };

  const summaryView: VitalChartTableSummaryViewComponentProps['summaryTableView'] = ({ data }) => (
    <BOSummaryTableDataProcessorComponent
      data={data?.stats?.po || {}}
    />
  );

  return (
    <VitalChartTableSummaryViewComponent
      type={MeasurementResultTypeEnum.PO}
      memberId={memberId}
      title="Blood Oxygen"
      unit="%"
      chartView={chartView}
      tableView={tableView}
      summaryTableView={summaryView}
      fromDate={fromDate}
      toDate={toDate}
    />
  );
};
