import React, { FC, ReactNode } from 'react';
import { InputType } from '../../../../Input/types';
import { VitalsMonitoringOccurrenceSelectComponent, VitalsMonitoringOccurrenceSelectComponentProps } from '../VitalsMonitoringOccurrenceSelectComponent/VitalsMonitoringOccurrenceSelectComponent';
import { VitalsMonitoringOccurrenceInputComponent, VitalsMonitoringOccurrenceInputComponentProps } from '../VitalsMonitoringOccurrenceInputComponent/VitalsMonitoringOccurrenceInputComponent';
import { MonitoringVital } from '../../../../../uc-api-sdk';

export interface VitalsScheduleValue extends MonitoringVital {}

export interface VitalsScheduleComponentProps extends InputType<VitalsScheduleValue> {
  label: ReactNode;
}

export const VitalsScheduleComponent: FC<VitalsScheduleComponentProps> = ({
  label,
  value,
  onChange,
}) => {
  const handleChange: VitalsMonitoringOccurrenceInputComponentProps['onChange'] = (target) => {
    onChange?.({
      ...value,
      target,
      repeat: value?.repeat,
    });
  };

  const handleSelect: VitalsMonitoringOccurrenceSelectComponentProps['onSelect'] = (repeat) => {
    onChange?.({
      ...value,
      target: value?.target,
      repeat,
    });
  };

  return (
    <div className="flex fd-c gap0">
      <div>{label}</div>
      <div className="flex gap3">
        <VitalsMonitoringOccurrenceInputComponent
          value={value?.target || undefined}
          onChange={handleChange}
        />
        <VitalsMonitoringOccurrenceSelectComponent
          value={value?.repeat || undefined}
          onSelect={handleSelect}
        />
      </div>
    </div>
  );
};
