import React, { FC } from 'react';
import { USA_DATE } from '../../../../constants/timeFormat';
import { FromDateToDateDayjs } from '../../type';
import { PrintVitalTitleComponent } from './component/PrintTitleComponent/PrintTitleComponent';

export interface PrintVitalTableTitleComponentProps extends FromDateToDateDayjs {
  name: string;
}

export const PrintVitalTableTitleComponent: FC<PrintVitalTableTitleComponentProps> = ({
  name,
  fromDate,
  toDate,
}) => (
  <>
    <PrintVitalTitleComponent
      name={(
        <span>
          {name}
        </span>
    )}
    />
    <div className="fs12 b4 mb10">
      {fromDate.format(USA_DATE)}
      {' '}
      -
      {' '}
      {toDate.format(USA_DATE)}
    </div>
  </>
);
