import { useMemo } from 'react';
import {
  GetOptions, MakeRequestHookConfig, UseRequestFactory, useRequestFactory,
} from '../../../uc-api-sdk/staticFiles/useReqHook';
import { chsServices } from '../CHSServices';
import { TagMessageParams, TagMessageResponseData } from '../types';

export const useTagMessage = <RCN = undefined>(
  configs?: MakeRequestHookConfig<TagMessageParams, GetOptions, RCN>,
) => {
  const baseConfigs: UseRequestFactory<
    TagMessageParams,
    TagMessageResponseData,
    typeof chsServices.tagMessage,
    GetOptions,
    TagMessageResponseData
  > = useMemo(() => ({
    reqFunc: (...args: Parameters<typeof chsServices.tagMessage>) => (
      chsServices.tagMessage(...args)
    ),
    ResponseClassCreator: chsServices.getResponse,
    typeName: 'useTagMessage',
  }), []);

  return useRequestFactory(baseConfigs, configs);
};
