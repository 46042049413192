import { Modal } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { VisitParticipantReassignFormContainer, VisitParticipantReassignFormContainerProps } from '../../container/VisitParticipantReassignFormContainer/VisitParticipantReassignFormContainer';

import './VisitParticipantReassignModalComponent.scss';

export interface VisitParticipantReassignModalComponentProps {
  visit: VisitParticipantReassignFormContainerProps['visit'];
  isOpen?: boolean;
  close?: () => void;
  onSubmit?: () => void;
}

export const VisitParticipantReassignModalComponent = ({
  visit,
  isOpen,
  close,
  onSubmit = close,
}: VisitParticipantReassignModalComponentProps) => (
  <Modal
    open={!!isOpen}
    onCancel={close}
    width={433}
    footer={null}
    closable={false}
    wrapClassName="visit-participant-reassign-modal"
    destroyOnClose
  >
    <div className="visit-participant-modal-content">
      <div className="modal-info">
        <div className="modal-info-icon">
          <InfoCircleOutlined />
        </div>
        <div className="modal-info-text">
          You are not the participant of this visit,
          do you want to assign yourself as a new participant and check in?
        </div>
      </div>
      <VisitParticipantReassignFormContainer
        visit={visit}
        onSubmit={onSubmit}
        onCancel={close}
      />
    </div>
  </Modal>
);
