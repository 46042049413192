import {
  ReactNode,
  createContext,
  useContext
} from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import { MedicalOrganization, useMedicalOrganizationGet } from '../../uc-api-sdk';
import { MedicalOrganizationService } from '../../services/MedicalOrganizationService';

export interface PatientMedicalOrganizationContextValue {
  medicalOrganization?: MedicalOrganization | null;
  medicalOrganizationService?: MedicalOrganizationService;
  fetchMedicalOrganization: (id?: string) => void;
  isLoading: boolean;
  refetch: () => void;
}

const PatientMedicalOrganizationContext = createContext<
  PatientMedicalOrganizationContextValue | undefined
>(undefined);

export const usePatientMedicalOrganizationContext = () => {
  const context = useContext(PatientMedicalOrganizationContext);
  if (!context) throw new Error('PatientMedicalOrganizationContext not found');
  return context as PatientMedicalOrganizationContextValue;
};

export interface PatientMedicalOrganizationContextProviderProps {
  children: ReactNode;
  clinicId?: string;
  sendOnMount?: boolean;
}
export const PatientMedicalOrganizationContextProvider = ({
  children,
  clinicId,
  sendOnMount = !!clinicId,
}: PatientMedicalOrganizationContextProviderProps) => {
  const medicalOrgInfo = useMedicalOrganizationGet({
    params: { id: clinicId ?? '' },
    options: { sendOnMount },
  });

  const {
    isLoading: requestLoading,
    data,
    refetch,
    send,
  } = medicalOrgInfo ?? {};

  const isLoading = (
    (clinicId && medicalOrgInfo.data?.data === undefined)
    || requestLoading
  );
  const medicalOrganization = data?.data;

  const fetchMedicalOrganization = (id = (clinicId || '')) => {
    if (clinicId) {
      send({ params: { id } });
    }
  };

  useUpdateEffect(() => {
    fetchMedicalOrganization(clinicId);
  }, [clinicId]);

  const contextValue = useGetContextValue<PatientMedicalOrganizationContextValue>({
    isLoading,
    medicalOrganization,
    medicalOrganizationService: (
      medicalOrganization
        ? new MedicalOrganizationService(medicalOrganization)
        : undefined
    ),
    fetchMedicalOrganization,
    refetch
  });

  return (
    <PatientMedicalOrganizationContext.Provider value={contextValue}>
      {children}
    </PatientMedicalOrganizationContext.Provider>
  );
};
