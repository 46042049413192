import { Dayjs } from 'dayjs';
import { DATE_STANDARD, REQUEST_DATE_FORMAT } from '../../../constants/timeFormat';
import { usePatientContext } from '../../../contexts/PatientInfoContext/PatientInfoContext';
import { SubmitValue } from '../component/CreateEditLabResultFormComponent/CreateEditLabResultFormComponent';

export const useLabResultHelper = () => {
  const {
    info: { id } = {},
  } = usePatientContext();

  const getCollectedDate = (testDate: Dayjs) => {
    const date = testDate.startOf('day');
    const localDateCollected = date.format(DATE_STANDARD);
    return {
      dateCollected: date.format(REQUEST_DATE_FORMAT),
      localDateCollected
    };
  };

  const parseCreateLabResultItem = (
    patientId: string,
    formItemValue?: SubmitValue
  ) => {
    if (!patientId || !formItemValue) {
      return {};
    }
    return {
      userId: id,
      ...getCollectedDate(formItemValue.testDate),
      results: formItemValue.item,
      templateId: formItemValue.lab?.templateId,
      templateName: formItemValue.lab?.templateName,
    };
  };

  const parseEditLabResultItem = (
    formItemValue?: SubmitValue
  ) => {
    if (!formItemValue) {
      return {};
    }
    return {
      ...getCollectedDate(formItemValue.testDate),
      results: formItemValue.item,
    };
  };

  return {
    parseCreateLabResultItem,
    parseEditLabResultItem,
    getCollectedDate,
  };
};
