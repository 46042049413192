import { map } from 'lodash';
import { Select } from 'antd';
import { useMemo } from 'react';
import { SelectType } from '../../../Input/types';
import { PatientComplexityEnum } from '../../../../uc-api-sdk';
import './ComplexitySelectComponent.scss';
import { ComplexityEnumComponent } from '../../../../enumComponent/ComplexityEnumComponent/ComplexityEnumComponent';

export interface ComplexitySelectComponentProps extends SelectType<PatientComplexityEnum> { }

export const ComplexitySelectComponent = ({
  id,
  value,
  onChange,
}: ComplexitySelectComponentProps) => {
  const options = useMemo(() => (
    map(PatientComplexityEnum, (value) => ({
      label: <ComplexityEnumComponent value={value} />,
      value,
    }))
  ), []);
  return (
    <Select
      id={id}
      className="complexity-select"
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};
