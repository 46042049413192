import { useEffect } from 'react';
import { Input, InputProps } from 'antd';
import { InputType } from '../../../Input/types';

export interface EventTitleComponentProps extends InputType<string> {
  title?: string;
}

export const EventTitleComponent = ({
  title,
  value,
  onChange,
}: EventTitleComponentProps) => {
  useEffect(() => {
    if (title !== undefined && title !== value) {
      onChange?.(title);
    }
  }, [title]);

  const handleChange: InputProps['onChange'] = (evt) => {
    onChange?.(evt.target.value);
  };

  return (
    <Input value={value} onChange={handleChange} />
  );
};
