import { ReactNode, useState } from 'react';
import { LLMUploadProcessContainer, LLMUploadProcessContainerProps } from '../../container/LLMUploadProcessContainer/LLMUploadProcessContainer';
import { LLMUploadFileInfo } from '../../container/LLMUploadContainer/LLMUploadContainer';
import { LLMTranscribeJobInfo } from '../../type';

interface LLMContainerChildProps<T> {
  transcribeInfo: LLMTranscribeJobInfo<T>;
  cleanTranscribeResult: () => void;
  goBackToUpload?: () => void;
  reprocessJob?: () => void;
}

export interface LLMComponentWrapperProps<T> extends LLMUploadProcessContainerProps {
  children: (props: LLMContainerChildProps<T>) => ReactNode;
}

export const LLMComponentWrapper = <T, >({
  children,
  ...props
}: LLMComponentWrapperProps<T>) => {
  const [
    transcribeInfo,
    setTranscribeInfo,
  ] = useState<LLMTranscribeJobInfo<T> | undefined>();
  const [
    reprocessUploadFileInfoList,
    setReprocessUploadFileInfoList,
  ] = useState<LLMUploadFileInfo[] | undefined>();
  const uploadedFileInfoList = transcribeInfo?.uploadFileInfoList || reprocessUploadFileInfoList;

  const handleOnJobCompleted = (
    jobInfo: LLMTranscribeJobInfo<unknown>,
  ) => {
    setReprocessUploadFileInfoList(undefined);
    setTranscribeInfo(jobInfo as LLMTranscribeJobInfo<T>);
  };

  const handleCleanTranscribeResult = () => {
    setReprocessUploadFileInfoList(undefined);
    setTranscribeInfo(undefined);
  };

  const goBackToUpload = () => {
    setTranscribeInfo({
      uploadFileInfoList: transcribeInfo?.uploadFileInfoList,
    });
  };

  const handleReprocessJob = () => {
    setReprocessUploadFileInfoList(transcribeInfo?.uploadFileInfoList);
    setTranscribeInfo(undefined);
  };

  if (transcribeInfo?.jobId) {
    return (
      <>
        {
          children({
            transcribeInfo,
            cleanTranscribeResult: handleCleanTranscribeResult,
            goBackToUpload,
            reprocessJob: handleReprocessJob,
          })
        }
      </>
    );
  }

  return (
    <div className="h100">
      <LLMUploadProcessContainer
        onJobCompleted={handleOnJobCompleted}
        initialUploadedFiles={uploadedFileInfoList}
        autoStart={!!reprocessUploadFileInfoList?.length}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </div>
  );
};
