import { ReactNode } from 'react';
import classNames from 'classnames';
import { PageTitleComponent, PageTitleComponentProps } from '../PageTitleComponent/PageTitleComponent';
import './PageComponent.scss';

export interface PageComponentProps extends Partial<PageTitleComponentProps> {
  header?: ReactNode;
  children: ReactNode;
  className?: string;
}

export const PageComponent = ({
  header,
  title,
  tooltip,
  extra,
  children,
  className = '',
}: PageComponentProps) => (
  <div
    className={classNames({
      'page-component': true,
      [className]: !!className,
    })}
  >
    <div className="page-header-component">
      {header || (
        <PageTitleComponent
          title={title}
          tooltip={tooltip}
          extra={extra}
        />
      )}
    </div>
    {children}
  </div>
);
