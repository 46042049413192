import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';

export interface MedNameProps {
  name: string;
  otherMD: boolean;
}

export const MedicationNameRendererComponent = ({ name, otherMD }: MedNameProps) => (
  <Space size={7}>
    <span className="breakword">{name}</span>
    {otherMD && (
      <Tooltip title="This medication is not prescribed by this patient's primary provider.">
        <ExclamationCircleOutlined />
      </Tooltip>
    )}
  </Space>
);
