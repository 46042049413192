import { MTPRSeeNoteHistoryContainer } from '../../containter/MTPRSeeNoteHistoryContainer/MTPRSeeNoteHistoryContainer';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import './MTPRSeeNoteHistoryButtonComponent.scss';
import { Icons } from '../../../../icons/Icons';
import ModalButton from '../../../../uiComponent/ModalButton/ModalButton';

export interface MTPRSeeNoteHistoryButtonComponentProps {
  patientId: string;
  onClickSeeNoteHistory?: () => void;
  disabled?: boolean;
}

export const MTPRSeeNoteHistoryButtonComponent = ({
  patientId,
  onClickSeeNoteHistory,
  disabled = false,
}: MTPRSeeNoteHistoryButtonComponentProps) => (
  <ModalButton
    button={(
      <Icons.History size={22} />
    )}
    buttonType="text"
    buttonClassName="no-style-btn"
    width={721}
    wrapClassName="mtpr-see-note-history"
    maskStyle={{ display: 'none' }}
    maskClosable={false}
    destroyOnClose
    onClickSendBillableTime={onClickSeeNoteHistory}
    disabled={disabled}
  >
    <div className="flex fd-c w100 h100 pb24 px12">
      <TextComponent size="large" bold>
        Note History
      </TextComponent>
      <div className="mt20 overflow-a">
        <MTPRSeeNoteHistoryContainer patientId={patientId} />
      </div>
    </div>
  </ModalButton>
);
