import { Checkbox, CheckboxProps } from 'antd';
import { FC } from 'react';

export interface PrescribedCheckboxComponentProps {
  onChange?: (v: boolean) => void;
  value?: boolean;
}

export const PrescribedCheckboxComponent: FC<PrescribedCheckboxComponentProps> = ({
  onChange,
  value,
}) => {
  const handleOnChange: CheckboxProps['onChange'] = (v) => {
    onChange?.(v.target.checked);
  };

  return (

    <Checkbox defaultChecked={value} onChange={handleOnChange}>
      This medication is not prescribed by this patient's primary provider
    </Checkbox>
  );
};
