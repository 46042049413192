import React from 'react';
import {
  Button,
  Drawer,
} from 'antd';
import { useOpen } from '../../../hooks/useBoolean/useOpen';
import './AddOfflineTimeDrawerComponent.scss';
import { ActivityTypeEnum } from '../type';
import { AddOfflineTimeContainer } from '../AddOfflineTimeContainer/AddOfflineTimeContainer';
import { useUpdate } from '../../../contexts/UpdateContext/UpdateContext';

export interface SubmitValue {
  offlineActivities: ActivityTypeEnum[];
  dateOfService: string;
  timeSpent: number;
}
export interface AddOfflineTimeDrawerComponentProps {
  patientId: string;
  refetch?: () => void;
  onClosePopover?: () => void;
}

export const AddOfflineTimeDrawerComponent = ({
  patientId,
  refetch,
  onClosePopover,
}:AddOfflineTimeDrawerComponentProps) => {
  const { isOpen, open, close } = useOpen();
  const addOfflineBillableTime = useUpdate('BILLABLE_TIME_UPDATED');
  const handleOnClose = () => {
    close();
    refetch?.();
    addOfflineBillableTime.updateValue();
  };

  return (
    <>
      <Button
        type="link"
        className="p0"
        onClick={() => {
          open();
          onClosePopover?.();
        }}
      >
        Add Offline Time
      </Button>
      <Drawer
        className="add-offline-time-drawer-component"
        open={isOpen}
        onClose={handleOnClose}
        width={520}
        title="Add Offline Time"
      >
        <AddOfflineTimeContainer
          patientId={patientId}
          onClose={handleOnClose}
        />
      </Drawer>
    </>
  );
};
