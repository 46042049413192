import { useMemo } from 'react';
import { useFormHookFactory } from '../useFormHookFactory/useFormHookFactory';

export const useFoodLogProviderForm = () => {
  const factory = useFormHookFactory({
    note: {
      name: 'note',
      label: '',
      emptyMessage: 'Missing comment!',
    },
    rate: {
      name: 'rate',
      label: '',
      emptyMessage: 'Missing rating!',
    },
  });
  const response = useMemo(() => ({
    ...factory,
  }), [factory]);

  return response;
};
export default useFoodLogProviderForm;
