import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Space } from 'antd';
import dayjs from 'dayjs';
import EmployeeInfo from '../../hooks/useUserInfo/employeeInfo';
import { Employee } from '../../types/user';
import { AllTimeFormatNames, DisplayDateComponent } from '../DisplayComponent/DisplayDateComponent';
import { TagComponent, TagType } from '../TagComponent/TagComponent';
import TimezoneService from '../../helpers/timezone/timezoneService';
import { LimitedUser } from '../../uc-api-sdk';

export interface LastUpdateComponentProps {
  updatedByUser?: Employee | LimitedUser | null;
  updatedAt?: string | null;
  attentionDayThreshold?: [number, number];
  timezone?: string;
  className?: string;
  delimiter?: string;
  updatedAtFormat?: AllTimeFormatNames;
}

export const LastUpdateComponent = ({
  updatedByUser,
  updatedAt,
  timezone,
  className = '',
  attentionDayThreshold,
  delimiter = ' ',
  updatedAtFormat = 'WORKING_DATE_WITH_HOUR',
}: LastUpdateComponentProps) => {
  const attentionType = useMemo((): TagType => {
    let inThreshold = false;
    if (updatedAt) {
      const today = dayjs().endOf('day');
      const diff = TimezoneService.calcDateTimeDayjs(updatedAt).diff(today, 'days');
      const [thresholdMin, thresholdMax] = attentionDayThreshold || [-Infinity, Infinity];
      inThreshold = diff >= thresholdMin && diff <= thresholdMax;
    }
    return inThreshold ? 'warning' : 'default';
  }, [attentionDayThreshold, updatedAt]);

  const getLastUpdateInfo = useCallback(() => {
    const LastUpdate = [];
    if (updatedByUser) {
      const profile = (updatedByUser as Employee).employeeProfile || updatedByUser;
      LastUpdate.push(
        <span>{EmployeeInfo.getFullNameWithTitle(profile)}</span>,
      );
    }
    if (updatedAt) {
      LastUpdate.push(
        <DisplayDateComponent
          // fix Warning: Each child in a list should have a unique "key" prop.
          // in TranscribeVisitCardComponent > LastUpdateComponent > DisplayDateComponent
          key={updatedAt}
          useTimezoneService
          value={updatedAt}
          format={updatedAtFormat}
          timezone={timezone}
        />,
      );
    }
    if (LastUpdate.length) {
      return (
        <>
          <span>
            Last update:
          </span>
          {
            LastUpdate.map((v, index) => (
              (index === 0 || index === LastUpdate.length - 1)
                ? v
                : (
                  <>
                    {v}
                    {`${delimiter}`}
                  </>
                )
            ))
          }
        </>
      );
    }
    return null;
  }, [updatedByUser?.id, updatedAt]);

  return (
    <Space
      className={classNames({
        'last-update-component': true,
        [className]: !!className,
        [`last-update__${attentionType}`]: true,
      })}
    >
      <TagComponent
        type={attentionType}
        iconType="none"
        background="none"
      >
        {getLastUpdateInfo()}
      </TagComponent>
    </Space>
  );
};
