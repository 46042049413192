import { FC } from 'react';
import {
  Badge, Button,
} from 'antd';
import classNames from 'classnames';
import { Icons } from '../../../../icons/Icons';

const NOTIFICATION_FOR_NEW_MESSAGE = false;

export interface ChatButtonComponentProps {
  hasNewMessage?: boolean;
  onClickChat?: () => void;
}
export const ChatButtonComponent: FC<ChatButtonComponentProps> = ({
  hasNewMessage = false,
  onClickChat,
}) => (
  <Badge dot={NOTIFICATION_FOR_NEW_MESSAGE && hasNewMessage}>
    <Button
      type="primary"
      className={classNames({
        flex: true,
        b5: true,
        'chat-unread': NOTIFICATION_FOR_NEW_MESSAGE && hasNewMessage,
      })}
      icon={(
        <Icons.Chat className="flex ai-c" />
        )}
      onClick={onClickChat}
    >
      Chat
    </Button>
  </Badge>
);
