import classNames from 'classnames';
import { FC } from 'react';
import './HSTextComponent.scss';

export interface HSTextComponentProps {
  value: number;
  inClinic?: boolean;
}

export const HSTextComponent: FC<HSTextComponentProps> = ({
  value,
  inClinic
}) => (
  <div className={classNames({
    'HSTextComponent b6': true,
    'in-clinic': inClinic
  })}
  >
    {value}
  </div>
);
