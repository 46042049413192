import dayjs, { Dayjs } from 'dayjs';
import { Fragment, useEffect, useRef } from 'react';
import { useMessagePatientContext } from '../../../../contexts/MessageContext/MessagePatientContext';
import { useMessageServicesContext } from '../../../../contexts/MessageContext/MessageServicesContext';
import { MessageHistoryItemWrapperComponent } from '../MessageHistoryItemWrapperComponent/MessageHistoryItemWrapperComponent';
import { MessageChannelSectionHrDateComponent } from '../MessageChannelSectionHrDateComponent/MessageChannelSectionHrDateComponent';
import { MessageChannelSectionHrPatientACKComponent } from '../MessageChannelSectionHrPatientACKComponent/MessageChannelSectionHrPatientACKComponent';
import { MessageChannelSectionHrMoreMessageComponent } from '../MessageChannelSectionHrMoreMessageComponent/MessageChannelSectionHrMoreMessageComponent';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { MessageHistoryEmptyComponent } from '../MessageHistoryEmptyComponent/MessageHistoryEmptyComponent';
import { USA_DATE } from '../../../../constants/timeFormat';

import './MessageHistoryComponent.scss';
import { MESSAGE_NOTIFICATION } from '../../../../services/CHSServices/constants';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';

export const MessageHistoryComponent = () => {
  const bottomRef = useRef(null);
  const {
    userMap,
  } = useMessageServicesContext();
  const {
    patientChannel,
    handleGetMorePatientMessages,
    scrollToBottom,
    publishACKMessage,
  } = useMessagePatientContext();
  const billableTime = useBillableTimeInterventionComp();

  const handleLoadMoreMessages = useDebounce(() => {
    handleGetMorePatientMessages();
  }, 250, [handleGetMorePatientMessages]);

  const handleClickLoadMoreMessage = () => {
    billableTime.send({
      patientId: patientChannel?.patientId,
      component: BillableTimeInterventionComponentEnum.Messaging,
      detail: BillableTimeInterventionDetailEnum.MessageThread,
    });
    handleLoadMoreMessages();
  };

  const checkShouldDisplayHrDate = (
    currDateTime: Dayjs,
    prevDateTime?: number,
  ) => (
    !prevDateTime || currDateTime.format(USA_DATE) !== dayjs(prevDateTime).format(USA_DATE)
  );

  const handlePublishACK = useDebounce(() => {
    publishACKMessage();
  }, 500, [publishACKMessage]);

  useEffect(() => {
    if (
      patientChannel?.lastMsg?.dateTime
      || patientChannel?.lastMsg?.text === MESSAGE_NOTIFICATION
    ) {
      // has new message
      scrollToBottom(bottomRef);
      handlePublishACK();
    }
  }, [
    patientChannel?.lastMsg?.dateTime,
  ]);

  useEffect(() => {
    if (patientChannel?.isUnread) {
      // unread is marked when channel is open
      handlePublishACK();
    }
  }, [
    patientChannel?.isUnread,
  ]);

  if (!patientChannel || !patientChannel.messages?.length) {
    return (
      <MessageHistoryEmptyComponent />
    );
  }

  return (
    <div className="message-history-component">
      {
        patientChannel.hasMoreMessage
          ? (
            <MessageChannelSectionHrMoreMessageComponent
              onClick={handleClickLoadMoreMessage}
            />
          )
          : null
      }
      {
        patientChannel.messages.map((m, index) => {
          const { messageId, payload, dateTime } = m;
          const publisherInfo = userMap[payload.publisher as string];

          const prevDateTime = patientChannel.messages[index - 1]?.dateTime;
          const nextDateTime = patientChannel.messages[index + 1]?.dateTime;

          const messageDateTime = dayjs(dateTime);
          const shouldDisplayDate = checkShouldDisplayHrDate(messageDateTime, prevDateTime);

          return (
            <Fragment key={messageId}>
              {
                shouldDisplayDate
                  ? (
                    <MessageChannelSectionHrDateComponent
                      date={dayjs(messageDateTime)}
                    />
                  )
                  : null
              }
              <MessageHistoryItemWrapperComponent
                dateTime={dayjs(messageDateTime)}
                messagePayload={payload}
                messageId={messageId}
                publisher={publisherInfo}
              />
              <MessageChannelSectionHrPatientACKComponent
                lastClientACK={patientChannel.lastClientACK}
                currDateTime={messageDateTime}
                nextDateTime={nextDateTime}
              />
            </Fragment>
          );
        })
      }
      <div ref={bottomRef} className="message-history-bottom" />
    </div>
  );
};
