import { Button, DrawerProps } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { MediumDrawerWidth } from '../../../../constants/drawer';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { JsonTimeLocalDate, LabResultClass } from '../../../../uc-api-sdk';
import { DefaultExtendableDrawers } from '../../../../uiComponent/ExtendableDrawerComponent/controller/DefaultExtendableDrawers';
import { ExtendableDrawerComponent } from '../../../../uiComponent/ExtendableDrawerComponent/ExtendableDrawerComponent';
import { TagComponent } from '../../../../uiComponent/TagComponent/TagComponent';
import { CreateLabResultContainer } from '../../container/CreateLabResultContainer/CreateLabResultContainer';
import { LabResultA1CTableContainer } from '../../container/LabResultTableContainer/LabResultA1CTableContainer';
import { OtherLabTableContainer } from '../../container/LabResultTableContainer/OtherLabTableContainer';
import { TranscribeDueDateComponent } from '../TranscribeDisplayComponent/TranscribeDueDateComponent';
import './AddLabResultDrawerComponent.scss';
import { LLMTranscribeLabResultButtonComponent } from '../../../LLM/LLMLabResult/component/LLMTranscribeLabResultButtonComponent/LLMTranscribeLabResultButtonComponent';
import { ExtendableDrawerButtonComponent } from '../../../../uiComponent/ExtendableDrawerComponent/component/ExtendableDrawerButtonComponent';

const DEFAULT_DRAWER_ID = DefaultExtendableDrawers.addLabResult;
export interface AddLabResultDrawerComponentProps {
  patientId?: string;
  data?: JsonTimeLocalDate;
  placement: DrawerProps['placement'];
  addButtonToRight: boolean;
  setLatestLab?: (l?: LabResultClass) => void;
  disabled?: boolean;
  onClickAddLabResult?: () => void;
}

export const AddLabResultDrawerComponent = ({
  patientId,
  data,
  placement,
  addButtonToRight = false,
  setLatestLab,
  disabled,
  onClickAddLabResult,
}: AddLabResultDrawerComponentProps) => {
  const {
    value: open,
    setTrue: setOpen,
    setFalse: setClose,
  } = useBoolean(false);
  const { send } = useMixpanelContext();

  const [latestA1CCreatedAt, setLatestA1CCreatedAt] = useState<LabResultClass | undefined>();
  const [latestOtherCreatedAt, setLatestOtherCreatedAt] = useState<LabResultClass | undefined>();
  // compare 'latest A1C CreatedAt' & 'latest Other CreatedAt'
  useEffect(() => {
    if (latestA1CCreatedAt && !latestOtherCreatedAt) {
      setLatestLab?.(latestA1CCreatedAt);
    } else if (!latestA1CCreatedAt && latestOtherCreatedAt) {
      setLatestLab?.(latestOtherCreatedAt);
    } else if (latestA1CCreatedAt
      && latestOtherCreatedAt
      && dayjs(latestA1CCreatedAt.createdAt).isAfter(dayjs(latestOtherCreatedAt.createdAt))) {
      setLatestLab?.(latestA1CCreatedAt);
    } else {
      setLatestLab?.(latestOtherCreatedAt);
    }
  }, [latestA1CCreatedAt, latestOtherCreatedAt]);

  const title = (type: string) => (
    type === 'A1c' ? 'Hemoglobin A1c' : 'Other Labs'
  );

  const handleOnClickAddLabResult = () => {
    onClickAddLabResult?.();
    send({ event: MixpanelEvents.AddMedicationDrawerOpen });
    setOpen();
  };

  return (
    <>
      {!addButtonToRight ? (
        <div className="flex gap1">
          <ExtendableDrawerButtonComponent
            buttonText="Add Lab Result"
            buttonProps={{
              type: 'primary',
              className: 'mb30',
              ghost: true,
              disabled,
            }}
            onClick={handleOnClickAddLabResult}
          />
          {
            patientId
            && (
              <LLMTranscribeLabResultButtonComponent
                patientId={patientId}
              />
            )
          }
        </div>
      ) : null}
      <ExtendableDrawerComponent
        id={DEFAULT_DRAWER_ID}
        open={open}
        onClose={setClose}
        footer={null}
        placement={placement}
        width={MediumDrawerWidth}
        title="Add Lab Result"
        maskClosable={false}
      >
        <CreateLabResultContainer
          patientId={patientId}
          onSubmit={setClose}
        />
      </ExtendableDrawerComponent>
      <div className="mb10 flex gap5 ai-c">
        {title('A1c')}
        {data?.date ? (
          <TagComponent type="warning" background="none" iconColor="a1c-due">
            <TranscribeDueDateComponent dueDate={data?.date} />
          </TagComponent>
        ) : null}
        {addButtonToRight ? (
          <Button
            type="primary"
            className="medical-history"
            onClick={handleOnClickAddLabResult}
            ghost
            disabled={disabled}
          >
            Add Lab Result
          </Button>
        ) : null}
      </div>
      <LabResultA1CTableContainer
        patientId={patientId}
        setLatestCreatedAt={setLatestA1CCreatedAt}
      />
      <div className="mb10">{title('other')}</div>
      <OtherLabTableContainer
        patientId={patientId}
        setLatestCreatedAt={setLatestOtherCreatedAt}
      />
    </>
  );
};
