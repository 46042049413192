import { ClinicGoalEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export const getText = (value?: ClinicGoalEnum) => {
  switch (value) {
    case ClinicGoalEnum.GLUCOSE_MANAGEMENT_1:
      return 'Maintain or improve Hgb A1C by 0.5% over the next 3 months without symptoms of hypoglycemia';
    case ClinicGoalEnum.GLUCOSE_MANAGEMENT_2:
      return 'Maintain or improve Hgb A1C to less than 7% over the next 3 months without symptoms of hypoglycemia';
    case ClinicGoalEnum.GLUCOSE_MANAGEMENT_3:
      return 'Maintain a stable A1C between 6.5-7% over the next 3 months without symptoms of hypoglycemia';
    case ClinicGoalEnum.GLUCOSE_MANAGEMENT_4:
      return 'Maintain or improve A1C between 7-7.5% over the next 3 months, due to the patient\'s significant advanced risks due to other complicating conditions, without symptoms of hypoglycemia';
    case ClinicGoalEnum.GLUCOSE_MANAGEMENT_5:
      return 'Pre-diabetes A1C will not worsen over the next 6 months without symptoms of hypoglycemia';
    case ClinicGoalEnum.GLUCOSE_MANAGEMENT_6:
      return 'Pre-diabetes A1C will be maintained or improved to below 5.7% in the next 6 months without symptoms of hypoglycemia';

    case ClinicGoalEnum.BLOOD_PRESSURE_MANAGEMENT_1:
      return 'Reduce and maintain blood pressure to <130/80 mmHg over the next 6 months without symptoms associated with hypotension';
    case ClinicGoalEnum.BLOOD_PRESSURE_MANAGEMENT_2:
      return 'Reduce and maintain blood pressure to <140/90 mmHg over the next 6 months without symptoms associated with hypotension';
    case ClinicGoalEnum.BLOOD_PRESSURE_MANAGEMENT_3:
      return 'Reduce and maintain blood pressure to between 130/80-140/90 mmHg over the next 6 months without symptoms associated with hypotension, given advanced age and/or associated risks';
    case ClinicGoalEnum.BLOOD_PRESSURE_MANAGEMENT_4:
      return 'Maintain blood pressure at or below 160/90 mmHg due to age and other associated risks without symptoms associated with hypotension over the next 6 months';
    case ClinicGoalEnum.BLOOD_PRESSURE_MANAGEMENT_5:
      return 'Minimize hypotensive risk by maintaining blood pressure above 90/60 mmHg without symptoms associated with hypotension over the next 6 months';

    case ClinicGoalEnum.WEIGHT_REDUCTION_1:
      return 'Achieve and maintain a healthful weight at a BMI below 30 over the next 6 months';
    case ClinicGoalEnum.WEIGHT_REDUCTION_2:
      return 'Prevent weight gain over 6 months with nutrition interventions to reduce risk of negative outcomes from other chronic diseases';
    case ClinicGoalEnum.WEIGHT_REDUCTION_3:
      return 'Aim to lose up to avg 0.5lb/wk with gradual lifestyle changes over the next 6 months with nutrition interventions to reduce risk of negative outcomes from other chronic diseases';
    case ClinicGoalEnum.WEIGHT_REDUCTION_4:
      return 'Aim to lose up to avg 1lb/wk for the next 3 months with nutrition interventions to reduce risk of negative outcomes from other chronic diseases';
    case ClinicGoalEnum.WEIGHT_REDUCTION_5:
      return 'Maintain a steady weight over the course of the next 3 months with no more gain or loss exceeding 2lb/week';

    case ClinicGoalEnum.CANCER_1:
      return 'Maintain current weight in the next 3 months';
    case ClinicGoalEnum.CANCER_2:
      return 'Gain at least 0.5 lbs weekly in the next 3 months';
    case ClinicGoalEnum.CANCER_3:
      return 'No further muscle wasting in the next 3 months';

    case ClinicGoalEnum.UNDERWEIGHT_1:
      return 'Maintain current weight in the next 3 months';
    case ClinicGoalEnum.UNDERWEIGHT_2:
      return 'Gain at least 0.5 lbs weekly in the next 3 months';
    case ClinicGoalEnum.UNDERWEIGHT_3:
      return 'No further muscle wasting in the next 3 months';

    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_1:
      return 'Maintain a steady weight over the course of the next 3 months with no more gain or loss exceeding 2lb/week, and without symptoms of exacerbated CHF';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_2:
      return 'Maintain or improve total cholesterol to <200 mg/dl over the next 3-6 months';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_3:
      return 'Maintain or improve LDL to <100 mg/dL over the next 3-6 months';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_4:
      return 'Maintain or improve HDL to >40 mg/dL over the next 3-6 months (men)';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_5:
      return 'Maintain or improve HDL to >50 mg/dL over the next 3-6 months (women)';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_6:
      return 'Maintain or improve triglycerides to <150 mg/dL over the next 3-6 months';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_7:
      return 'Lipid profile will not worsen in the next 12 months';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_8:
      return 'LDL will be reduced to normal range in the next 12 months with nutrition interventions and medication management as needed';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_9:
      return 'HDL will meet AHA standards (>40 for men, >50 for women) in the next 12 months with nutrition interventions and medication management as needed';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_10:
      return 'Maintain or improve lipid profile to normal levels within the next 12 months';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_11:
      return 'Reduce and maintain blood pressure to <130/80 mmHg over the next 6 months without symptoms associated with hypotension';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_12:
      return 'Reduce and maintain blood pressure to <140/90 mmHg over the next 6 months without symptoms associated with hypotension';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_13:
      return 'Reduce and maintain blood pressure to between 130/80-140/90 mmHg over the next 6 months without symptoms associated with hypotension, given advanced age and/or associated risks';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_14:
      return 'Maintain blood pressure at or below 160/90 mmHg due to age and other associated risks without symptoms associated with hypotension over the next 6 months';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_15:
      return 'Minimize hypotensive risk by maintaining blood pressure above 90/60 mmHg without symptoms associated with hypotension over the next 6 months';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_16:
      return 'Achieve and maintain a healthful weight at a BMI below 30 over the next 6 months';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_17:
      return 'Prevent weight gain over 6 months with nutrition interventions to reduce risk of negative outcomes from other chronic diseases';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_18:
      return 'Aim to lose up to 0.5lb/wk with gradual lifestyle changes over the next 6 months with nutrition interventions to reduce risk of negative outcomes from other chronic diseases';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_19:
      return 'Aim to lose up to 1lb/wk for the next 3 months with nutrition interventions to reduce risk of negative outcomes from other chronic diseases';
    case ClinicGoalEnum.CONGESTIVE_HEART_FAILURE_20:
      return 'Maintain a steady weight over the course of the next 3 months with no more gain or loss exceeding 2lb/week';

    case ClinicGoalEnum.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_1:
      return 'Maintain oxygen saturation level within or above 88-92% without any symptoms of COPD exacerbation in the next 6 months';
    case ClinicGoalEnum.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_2:
      return 'Maintain current weight over the next 3-6 months with no significant weight change';
    case ClinicGoalEnum.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_3:
      return 'Aim for weight gain of 1-3 lbs over the next 3-6 months';
    case ClinicGoalEnum.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_4:
      return 'Aim for weight loss of 1-3 lbs over the next 3-6 months';
    case ClinicGoalEnum.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE_5:
      return 'No further muscle wasting over the next 3-6 months';

    case ClinicGoalEnum.CHRONIC_KIDNEY_DISEASE_1:
      return 'Maintain eGFR at current level for the next 3-6 months';
    case ClinicGoalEnum.CHRONIC_KIDNEY_DISEASE_2:
      return 'Maintain or improve renal panel with in normal range in the next 3-6 months';
    case ClinicGoalEnum.CHRONIC_KIDNEY_DISEASE_3:
      return 'Maintain or improve serum albumin level to 3.4-5.4 g/dL in the next 3-6 months';
    case ClinicGoalEnum.CHRONIC_KIDNEY_DISEASE_4:
      return 'Maintain or improve serum potassium level to 3.5-5.0 mmol/L in the next 3-6 months';
    case ClinicGoalEnum.CHRONIC_KIDNEY_DISEASE_5:
      return 'Maintain or improve serum phosphorus level to 2.5-4.5 mg/dL in the next 3-6 months';
    case ClinicGoalEnum.CHRONIC_KIDNEY_DISEASE_6:
      return 'Maintain current dry weight in the next 3-6 months';

    case ClinicGoalEnum.LIPID_MANAGEMENT_1:
      return 'Maintain or improve total cholesterol to <200 mg/dl over the next 3-6 months';
    case ClinicGoalEnum.LIPID_MANAGEMENT_2:
      return 'Maintain or improve LDL to <100 mg/dL over the next 3-6 months';
    case ClinicGoalEnum.LIPID_MANAGEMENT_3:
      return 'Maintain or improve HDL to >40 mg/dL over the next 3-6 months (men)';
    case ClinicGoalEnum.LIPID_MANAGEMENT_4:
      return 'Maintain or improve HDL to >50 mg/dL over the next 3-6 months (women)';
    case ClinicGoalEnum.LIPID_MANAGEMENT_5:
      return 'Maintain or improve triglycerides to <150 mg/dL over the next 3-6 months';
    case ClinicGoalEnum.LIPID_MANAGEMENT_6:
      return 'Lipid profile will not worsen in the next 12 months';
    case ClinicGoalEnum.LIPID_MANAGEMENT_7:
      return 'LDL will be reduced to normal range in the next 12 months with nutrition interventions and medication management as needed';
    case ClinicGoalEnum.LIPID_MANAGEMENT_8:
      return 'HDL will meet AHA standards (>40 for men, >50 for women) in the next 12 months with nutrition interventions and medication management as needed';
    case ClinicGoalEnum.LIPID_MANAGEMENT_9:
      return 'Maintain or improve lipid profile to normal levels within the next 12 months';

    case ClinicGoalEnum.COVID_1:
      return 'The temperature and/or blood oxygen for the duration of the acute COVID illness will be maintained at or near normal levels, and the patient will notify the provider if symptoms worsen.';

    default:
      return null;
  }
};

export interface ClinicalGoalEnumComponentComponentProps {
  value: ClinicGoalEnum;
}

export const ClinicalGoalEnumComponent = ({
  value,
}: ClinicalGoalEnumComponentComponentProps) => (
  <DisplayOrEmptyComponent value={getText(value)} />
);
