/* eslint-disable no-shadow */
export { RoleType } from '../../generates';
export { RoleTypeEnum as ROLE_ENUM } from '../../uc-api-sdk';

export enum ROLE_NAME_MAP {
  NP = 'NP',
  QUALITY_TEAM_MEMBER = 'Quality Team Member',
  RD = 'RD',
  CA = 'CA',
  HC = 'HC',
  PROVIDER = 'MD',
  MA = 'MA',
  BILLER = 'Unified Care Biller',
  CALL_CENTER_REPRESENTATIVE = 'Call Center Representative',
  MANAGER = 'Manager',
  CLINICAL_GROUP_BILLER = 'Clinical org biller',
  CLINICAL_MANAGER = 'Clinical Manager',
  SUPER_ADMIN = 'Super Admin',
  ADMIN = 'Admin',
  MEMBER = 'Patient',
  order = 'order',
  label = 'label',
}

export enum ROLE_GROUP_MAP {
  CARE_DEPT = 'Care Dept',
  CLINICAL_STAFF = 'Clinical org staff',
  UC_MANAGER = 'UC Manager role',
  CALL_CENTER_DEPT = 'Call Center Dept',
  BILLING_DEPT = 'Billing Dept',
  ADMIN = 'Admin',
}
