import { SorterResult } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { sorterHelper } from '../../../../hooks/useTableChange/sorterHelper';
import { Filter, TableCacheNameEnum, useTableChange } from '../../../../hooks/useTableChange/useTableChange';
import { LanguageType } from '../../../../types/languages';
import {
  BillableTimeSegmentEnum,
  EnrolledProgramStatusEnum,
  EnrollmentDateSegmentEnum,
  EnrollmentRequest,
  GenderEnum,
  Patient,
  PatientAssignees,
  PatientComplexityEnum,
  PatientDiagnosesEnum,
  PatientEnrolledProgram,
  PatientFilter,
  PatientResult,
  PatientSourceEnum,
  ProgramCategoryEnum,
  ProgramCategorySearchFilterEnum,
  RiskLevelEnum,
  ScreenStatusEnum,
  Sort$Direction,
  SortInfo,
  VitalEnumType
} from '../../../../uc-api-sdk';
import { createPatientColumns, DataIndexBillableTime, DataIndexRPMDays } from '../../../patient/component/PatientsTableComponent/columns';
import { DataIndexBGDeviceLastUsed, DataIndexBPDeviceLastUsed } from '../../../providerView/component/ProviderViewTablesComponent/columns';
import { getOrder } from '../../../../helpers/table';

export const usePatientsTableOnChange = (sort: SortInfo[], name?: TableCacheNameEnum | string) => {
  type FilerType = keyof typeof createPatientColumns | 'id';
  const tableChange = useTableChange<PatientResult, PatientFilter>({
    name,
    sort,
    sortProcessor: (sorter) => {
      const newSorter = sorterHelper(sorter);
      const { field, order } = (sorter || {}) as SorterResult<PatientResult>;
      // field is the dataIndex; order is undefine when cancel ordering
      // combine sorter
      if (order && field === DataIndexRPMDays) {
        newSorter.unshift({
          property: 'monthlyBillableStatistic.monthOfYear',
          direction: Sort$Direction.DESC,
        });
      }
      if (order && field === DataIndexBillableTime) {
        newSorter.unshift({
          property: 'monthlyBillableStatistic.monthOfYear',
          direction: Sort$Direction.DESC,
        });
      }
      if (order && field === DataIndexBPDeviceLastUsed) {
        newSorter.unshift({
          property: DataIndexBPDeviceLastUsed,
          direction: getOrder({ order }) as unknown as Sort$Direction,
        });
      }
      if (order && field === DataIndexBGDeviceLastUsed) {
        newSorter.unshift({
          property: DataIndexBGDeviceLastUsed,
          direction: getOrder({ order }) as unknown as Sort$Direction,
        });
      }
      return newSorter;
    },
    filterProcessor: (filters: Filter<FilerType>) => {
      const patient: Patient = {};
      const enrolledProgram: PatientEnrolledProgram = {};
      const assignee: PatientAssignees = {};
      const enrollmentRequest: EnrollmentRequest = {};

      if (filters.id) {
        patient.idIn = {
          in: filters.id as string[],
        };
      }
      if (filters.gender) {
        patient.genderIn = {
          in: filters.gender as GenderEnum[],
        };
      }
      if (filters.source) {
        patient.patientSourceIn = {
          in: filters.source as PatientSourceEnum[],
        };
      }
      if (filters.eligibility) {
        patient.screenStatusIn = {
          in: filters.eligibility as ScreenStatusEnum[],
        };
      }
      if (filters.program) {
        if (filters.program.includes(ProgramCategorySearchFilterEnum.CCM_ONLY)) {
          enrolledProgram.programIn = {
            in: [ProgramCategoryEnum.CCM],
            nin: [ProgramCategoryEnum.RPM],
          };
        } else if (filters.program.includes(ProgramCategorySearchFilterEnum.RPM_ONLY)) {
          enrolledProgram.programIn = {
            in: [ProgramCategoryEnum.RPM],
            nin: [ProgramCategoryEnum.CCM],
          };
        } else {
          patient.programCategoryFilter = filters.program[0] as ProgramCategorySearchFilterEnum;
        }
      }
      if (filters.vitals) {
        enrolledProgram.vitalIn = {
          in: filters.vitals as VitalEnumType[],
        };
      }
      if (filters.status) {
        patient.enrolledStatusIn = {
          in: filters.status as EnrolledProgramStatusEnum[],
        };
      }
      if (filters.billable && Array.isArray(filters.billable) && filters.billable.length === 1) {
        patient.monthlyBillableStatistic = { eligible: filters.billable[0] as boolean };
      }

      if (filters.clinic) {
        patient.clinicIdsIn = {
          in: filters.clinic[0] as unknown as string[],
        };
      }
      if (filters.provider?.length) {
        patient.profileDoctorIdIn = {
          in: Array.isArray(filters.provider[0])
            ? filters.provider[0] : filters.provider as unknown as string[],
        };
      }
      if (filters.assignedRdHC) {
        assignee.assignedToRDIn = {
          // @ts-ignore
          in: filters.assignedRdHC.includes('unassigned') ? [null] : filters.assignedRdHC as string[],
        };
      }
      if (filters.assignedCA) {
        assignee.assignedToCAIn = {
          // @ts-ignore
          in: filters.assignedCA.includes('unassigned') ? [null] : filters.assignedCA as string[],
        };
      }
      if (filters.dx) {
        enrollmentRequest.diagnosesIn = {
          in: filters.dx as PatientDiagnosesEnum[],
        };
      }
      if (filters.primaryIns) {
        enrollmentRequest.providerIn = {
          in: filters.primaryIns as string[],
        };
      }
      if (filters.secondaryIns) {
        enrollmentRequest.provider2In = {
          in: filters.secondaryIns as string[],
        };
      }
      if (filters.riskLevel) {
        patient.riskLevelIn = {
          in: filters.riskLevel as RiskLevelEnum[],
        };
      }
      if (filters.language) {
        patient.profileLanguageIn = {
          in: filters.language as LanguageType[],
        };
      }
      if (filters.appLanguage) {
        patient.profileAppLanguageIn = {
          in: filters.appLanguage as LanguageType[],
        };
      }
      if (filters.tag) {
        patient.tagIn = {
          in: filters.tag as string[],
        };
      }
      if (filters.complexity) {
        patient.complexityIn = {
          in: filters.complexity as PatientComplexityEnum[],
        };
      }

      const monthOfYear = Number(dayjs().format('YYYYMM'));

      const billableTimeSegmentRequest = filters.billableTime?.length
        ? {
          billableTimeSegment: filters.billableTime?.length
            ? filters.billableTime[0] as BillableTimeSegmentEnum : undefined,
          monthOfYear,
        } : undefined;

      const newFilters: PatientFilter = {
        patient,
        enrolledProgram,
        assignee,
        enrollmentRequest,
        enrollmentDateSegment: filters.enrollmentDate?.length
          ? filters.enrollmentDate[0] as EnrollmentDateSegmentEnum : undefined,
        billableTimeSegmentRequest
      };

      return newFilters;
    },
  });

  return tableChange;
};
