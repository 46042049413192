import { pick } from 'lodash';
import { Tabs, TabsProps } from 'antd';
import { useEffect } from 'react';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { DeviceComponent, DeviceTabAnchors } from '../../../device/component/DeviceComponent/DeviceComponent';
import { InterventionComponent, InterventionTabAnchors } from '../../../intervention/component/InterventionComponent/InterventionComponent';
import { CareNotesTabContainer } from '../../container/CareNotesTabContainer/CareNotesTabContainer';
import { PatientVisitsContainer } from '../../container/PatientVisitsContainer/PatientVisitsContainer';
import { PatientProfileTabEnum, usePatientPageControllerContext } from '../../context/PatientPageControllerContext/PatientPageControllerContext';
import { MedicalHistoryComponent, MedicalTabAnchors } from '../MedicalHistoryComponent/MedicalHistoryComponent';
import { PatientProfileProfileTabComponent, PatientProfileTabAnchors } from '../PatientProfileProfileTabComponent/PatientProfileProfileComponent';
import { PatientProfileTabsExtraComponent } from './PatientProfileTabsExtraComponent';
import { ErrorBoundaryComponent } from '../../../../uiComponent/ErrorBoundaryComponent/ErrorBoundaryComponent';
import { PatientProfileTabLabelComponent } from './PatientProfileTabLabelComponent';

import './PatientProfileTabsComponent.scss';

export interface PatientProfileTabsComponentProps {

}

export const PatientProfileTabsComponent = () => {
  const { selectedTab, onTabChange } = usePatientPageControllerContext();
  const { info } = usePatientContext();
  const { enrolledProgramService } = info || {};
  const { send } = useMixpanelContext();

  const sendMixpanelOnTabChange = (tab: PatientProfileTabEnum) => {
    const getMixpanelEvent = () => {
      switch (tab) {
        case PatientProfileTabEnum.CareNotes:
          return MixpanelEvents.CareNoteTabSelect;
        case PatientProfileTabEnum.Intervention:
          return MixpanelEvents.InterventionTabSelect;
        case PatientProfileTabEnum.MedicalHistory:
          return MixpanelEvents.MedicalHistoryTabSelect;
        case PatientProfileTabEnum.Visit:
          return MixpanelEvents.VisitTabSelect;
        case PatientProfileTabEnum.Profile:
          return MixpanelEvents.ProfileTabSelect;
        case PatientProfileTabEnum.Device:
          return MixpanelEvents.DeviceTabSelect;
        default:
          return MixpanelEvents.Unknown;
      }
    };

    send({
      event: getMixpanelEvent(),
      patientId: info?.id,
    });
  };

  const handleChange: TabsProps['onChange'] = (v) => {
    const tabEnum = v as PatientProfileTabEnum;
    onTabChange(tabEnum);
  };

  useEffect(() => {
    if (!selectedTab) return;
    sendMixpanelOnTabChange(selectedTab);
  }, [selectedTab]);

  const unenrolledItems: TabsProps['items'] = [
    {
      key: PatientProfileTabEnum.MedicalHistory,
      label: (
        <PatientProfileTabLabelComponent
          tabEnum={PatientProfileTabEnum.MedicalHistory}
          tabAnchors={MedicalTabAnchors}
        >
          Medical History
        </PatientProfileTabLabelComponent>
      ),
      children: (
        <ErrorBoundaryComponent identifier="PatientProfileMedicalHistoryTab">
          <MedicalHistoryComponent />
        </ErrorBoundaryComponent>
      ),
    },
    {
      key: PatientProfileTabEnum.CareNotes,
      label: 'Care Note',
      children: (
        <ErrorBoundaryComponent identifier="PatientProfileCareNoteTab">
          <CareNotesTabContainer />
        </ErrorBoundaryComponent>
      ),
    },
    {
      key: PatientProfileTabEnum.Profile,
      label: (
        <PatientProfileTabLabelComponent
          tabEnum={PatientProfileTabEnum.Profile}
          tabAnchors={pick(PatientProfileTabAnchors, ['demographics', 'insurance'])}
        >
          Profile
        </PatientProfileTabLabelComponent>
      ),
      children: (
        <ErrorBoundaryComponent identifier="PatientProfileProfileTab">
          <PatientProfileProfileTabComponent />
        </ErrorBoundaryComponent>
      ),
    },
    {
      key: PatientProfileTabEnum.Visit,
      label: 'Visit',
      children: (
        <ErrorBoundaryComponent identifier="PatientProfileVisitTab">
          <PatientVisitsContainer />
        </ErrorBoundaryComponent>
      ),
    },
  ];

  const items: TabsProps['items'] = [
    {
      key: PatientProfileTabEnum.MedicalHistory,
      label: (
        <PatientProfileTabLabelComponent
          tabEnum={PatientProfileTabEnum.MedicalHistory}
          tabAnchors={MedicalTabAnchors}
        >
          Medical History
        </PatientProfileTabLabelComponent>
      ),
      children: (
        <ErrorBoundaryComponent identifier="PatientProfileMedicalHistoryTab">
          <MedicalHistoryComponent />
        </ErrorBoundaryComponent>
      ),
    },
    {
      key: PatientProfileTabEnum.CareNotes,
      label: 'Care Note',
      children: (
        <ErrorBoundaryComponent identifier="PatientProfileCareNoteTab">
          <CareNotesTabContainer />
        </ErrorBoundaryComponent>
      )
    },
    {
      key: PatientProfileTabEnum.Intervention,
      label: (
        <PatientProfileTabLabelComponent
          tabEnum={PatientProfileTabEnum.Intervention}
          tabAnchors={InterventionTabAnchors}
        >
          Intervention
        </PatientProfileTabLabelComponent>
      ),
      children: (
        <ErrorBoundaryComponent identifier="PatientProfileInterventionTab">
          <InterventionComponent />
        </ErrorBoundaryComponent>
      ),
    },
    {
      key: PatientProfileTabEnum.Visit,
      label: 'Visit',
      children: (
        <ErrorBoundaryComponent identifier="PatientProfileVisitTab">
          <PatientVisitsContainer />
        </ErrorBoundaryComponent>
      ),
    },
    {
      key: PatientProfileTabEnum.Profile,
      label: (
        <PatientProfileTabLabelComponent
          tabEnum={PatientProfileTabEnum.Profile}
          tabAnchors={PatientProfileTabAnchors}
        >
          Profile
        </PatientProfileTabLabelComponent>
      ),
      children: (
        <ErrorBoundaryComponent identifier="PatientProfileProfileTab">
          <PatientProfileProfileTabComponent />
        </ErrorBoundaryComponent>
      ),
    },
    {
      key: PatientProfileTabEnum.Device,
      label: (
        <PatientProfileTabLabelComponent
          tabEnum={PatientProfileTabEnum.Device}
          tabAnchors={DeviceTabAnchors}
        >
          Device
        </PatientProfileTabLabelComponent>
      ),
      children: (
        <ErrorBoundaryComponent identifier="PatientProfileDeviceTab">
          <DeviceComponent />
        </ErrorBoundaryComponent>
      ),
    },
  ];

  return (
    <Tabs
      className="patient-profile-tabs tab-fixed-header"
      items={
        (enrolledProgramService?.isEnrolled() && enrolledProgramService.id)
          ? items : unenrolledItems
      }
      activeKey={selectedTab}
      onChange={handleChange}
      tabBarExtraContent={<PatientProfileTabsExtraComponent />}
      size="small"
      destroyInactiveTabPane
    />
  );
};
