import { Modal, Button } from 'antd';
import { useEffect, useState } from 'react';
import { InputTypeRequired } from '../../../Input/types';
import { BPDeviceCuffSizeComponent } from '../BPDeviceCuffSizeComponent/BPDeviceCuffSizeComponent';
import { CuffSizeEnum } from '../../../../uc-api-sdk';
import { useModalConfigContext } from '../../../../contexts/ModalConfigContext/ModalConfigContext';

interface BPCuffSizeModalComponentProps extends
  Partial<InputTypeRequired<CuffSizeEnum>> {
  open: boolean;
  onCancel: () => void;
  closeable?: boolean;
}

export const BPCuffSizeModalComponent = ({
  open,
  onCancel,
  value,
  onChange,
  closeable = true,
}: BPCuffSizeModalComponentProps) => {
  const [cuffSize, setCuffSize] = useState<CuffSizeEnum | undefined>();
  const {
    getContainer: getModalContainer,
  } = useModalConfigContext();

  useEffect(() => setCuffSize(value), [value]);

  const handleSave = () => {
    onChange?.(cuffSize || CuffSizeEnum.STANDARD);
  };

  const handleCancel = () => {
    setCuffSize(value);
    onCancel();
  };

  return (
    <Modal
      open={open}
      title="Choose the cuff size for patient"
      onCancel={handleCancel}
      footer={null}
      getContainer={getModalContainer}
      destroyOnClose
      closable={closeable}
    >
      <>
        <BPDeviceCuffSizeComponent value={cuffSize} onChange={setCuffSize} />
        <div className="mt30">
          <Button key="save" type="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      </>
    </Modal>
  );
};
