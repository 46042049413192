import { MarkInsuranceNonCoveredModalComponent, MarkInsuranceNonCoveredModalComponentProps } from '../MarkInsuranceNonCoveredModalComponent/MarkInsuranceNonCoveredModalComponent';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';

import './MarkInsuranceNonCoveredComponent.scss';
import { NonCovered } from '../../../../uc-api-sdk';

export interface MarkInsuranceNonCoveredComponentProps {
  nonCovered?: NonCovered;
  onSave?: MarkInsuranceNonCoveredModalComponentProps['onSave'];
  disabled?: boolean;
}

export const MarkInsuranceNonCoveredComponent = ({
  nonCovered,
  onSave,
  disabled,
}: MarkInsuranceNonCoveredComponentProps) => {
  const { isNonCovered } = nonCovered || {};

  const infoText = (
    !isNonCovered
      ? 'Mark as "Insurance Non-Covered" patient?'
      : 'This is "Insurance Non-Covered" patient'
  );

  return (
    <div className="mark-as-non-covered">
      {infoText}
      <TooltipComponent
        type="info-icon"
        title="Select this option if patients are paying for medical services out-of-pocket or if specific business cases with providers apply. By selecting this item, patients will not be eligible for insurance billing."
      />
      <MarkInsuranceNonCoveredModalComponent
        nonCovered={nonCovered}
        onSave={onSave}
        disabled={disabled}
      />
    </div>
  );
};
