import { Table } from 'antd';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { TimeOfDayTableColumnRender, TimeOfDayTableColumns } from './TimeOfDayTableColumns';
import './TimeOfDayTableComponent.scss';
import { TimeOfDay } from '../../../../helpers/timezone/timezoneService';
import '../vitalsTable.scss';

export interface TimeOfDayTableDataType<T> {
  date: Dayjs;
  data: {
    [key in TimeOfDay]?: T[] | undefined
  };
}

export interface TimeOfDayTableComponentProps<T> {
  data: TimeOfDayTableDataType<T>[];
  render: TimeOfDayTableColumnRender<T>;
  className?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TimeOfDayTableComponent = <T extends Record<string, any>>({
  data,
  render,
  className = '',
}: TimeOfDayTableComponentProps<T>) => {
  const columns = useTableColumns(
    TimeOfDayTableColumns(render),
    ['date', 'morning', 'afternoon', 'evening', 'overnight'],
  );

  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={false}
      className={classNames({
        'table-header-cell-center': true,
        'table-cell-no-padding': true,
        'log-book-table': true,
        'time-of-day-logbook-table': true,
        'vitals-bordered-table': true,
        [className]: !!className,
      })}
      bordered
      // each cell is 50px tall
      // we need to show 7 days at a time
      scroll={{ y: 50 * 7 }}
      rowKey={(v) => v.date}
    />
  );
};
