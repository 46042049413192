import { VitalEnumType } from '../../uc-api-sdk';
import { VitalsEnumTypeComponent, VitalsEnumTypeComponentProps } from './VitalsEnumTypeComponent';
import { ListComponent, ListComponentProps } from '../../uiComponent/ListComponent/ListComponent';

export interface VitalEnumTypeListComponentProps {
  value: VitalEnumType[] | null | undefined;
  type: VitalsEnumTypeComponentProps['type'];
  splitter?: ListComponentProps<VitalEnumType>['splitter'];
}

export const VitalEnumTypeListComponent = ({
  value,
  type,
  splitter,
}: VitalEnumTypeListComponentProps) => (
  <ListComponent
    value={value || []}
    splitter={splitter}
    renderItem={(v) => (
      <VitalsEnumTypeComponent
        key={v}
        value={v}
        type={type}
      />
    )}
  />
);
