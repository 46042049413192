import { Table } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { BOSummaryTableColumns } from './BOSummaryTableColumns';
import { BOSummaryKey } from '../../type';

export interface BOSummaryTableData {
  type: BOSummaryKey;
  count: number;
  average: number;
  range: number[];
  perfusionIndexAverage: number;
  pulseRateAverage: number;
}

export interface BOSummaryTableComponentProps {
  data: Partial<BOSummaryTableData>[];
}

export const BOSummaryTableComponent: FC<BOSummaryTableComponentProps> = ({
  data,
}) => {
  const columns = useTableColumns(BOSummaryTableColumns, ['type', 'count', 'average', 'range', 'perfusionIndex', 'averagePulseRate']);

  return (
    <Table
      className={classNames({
        'summary-table': true,
        'vitals-un-bordered-table': true,
      })}
      dataSource={data}
      columns={columns}
      pagination={false}
      rowKey={(v) => v.type}
    />
  );
};
