import React from 'react';
import { GenderEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { EmptyString } from '../../uiComponent/EmptyComponent/EmptyComponent';

export interface GenderEnumComponentProps {
  value?: GenderEnum;
  type?: 'short' | 'long';
}

export const getGenderLongDescription = (value?: GenderEnum) => {
  switch (value) {
    case GenderEnum.F: return 'Female';
    case GenderEnum.M: return 'Male';
    default: return EmptyString;
  }
};

export const GenderEnumComponent = ({
  value,
  type = 'long',
}: GenderEnumComponentProps) => {
  const getShort = () => {
    switch (value) {
      case GenderEnum.F: return 'F';
      case GenderEnum.M: return 'M';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  const getText = () => {
    switch (type) {
      case 'short': return getShort();
      case 'long':
      default:
        return getGenderLongDescription(value);
    }
  };

  return (<>{getText()}</>);
};
