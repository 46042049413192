import { Space, SpaceProps } from 'antd';
import classNames from 'classnames';
import { ReactNode } from 'react';

export interface LabelValueComponentProps {
  label?: ReactNode;
  value?: ReactNode;
  className?: string;
  labelClassName?: string;
  valueClassName?: string;
  direction?: SpaceProps['direction'];
  size?: SpaceProps['size'];
}

export const LabelValueComponent = ({
  label,
  value,
  className = '',
  labelClassName = '',
  valueClassName = '',
  direction,
  size,
}: LabelValueComponentProps) => (
  <Space
    direction={direction}
    size={size}
    wrap
    className={classNames({
      'label-value-component': true,
      [className]: !!className,
    })}
  >
    <div
      className={classNames({
        'label-value-component__label': true,
        [labelClassName]: !!labelClassName,
      })}
    >
      {label}
    </div>
    <div
      className={classNames({
        'label-value-component__value': true,
        [valueClassName]: !!valueClassName,
      })}
    >
      {value}
    </div>
  </Space>
);
