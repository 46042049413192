import { TreatmentComponent } from '../../../../enumComponent/TreatmentComponent/TreatmentComponent';
import { DiseaseHistoryItem } from '../../../../uc-api-sdk';

export interface DiseaseHistoryCurrentTreatmentComponentProps {
  diseaseHistory?: DiseaseHistoryItem;
}

export const DiseaseHistoryCurrentTreatmentComponent = ({
  diseaseHistory,
}: DiseaseHistoryCurrentTreatmentComponentProps) => (
  <div className="flex f-w">
    <span className="label">Current treatment:</span>
    {
      diseaseHistory?.treatmentTypes?.length
        ? (
          diseaseHistory?.treatmentTypes.map((t, idx, arr) => (
            <div key={(idx + 1).toString()}>
              <TreatmentComponent treatment={t} />
              <span>{idx < arr.length - 1 && ', '}</span>
            </div>
          ))
        ) : 'N/A'
    }
  </div>
);
