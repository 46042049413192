/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
import { map } from 'lodash';
import { useMemo } from 'react';
import { TableColumnCreator } from '../../../types/table';

export * from '../../../types/table';

export interface SelectedColumnWithArgs<T extends TableColumnCreator<any>, K extends keyof T> {
  name: K,
  args: Parameters<T[K]>,
}

const createWithDefaultOptions = <T extends string, K>(
  key: T,
  value: K,
) => ({
    key,
    dataIndex: key,
    ...value,
  });

export const useTableColumns = <T extends TableColumnCreator<any>, K extends keyof T>(
  creator: T,
  selectedColumns: (K | SelectedColumnWithArgs<T, K>)[],
  dependencies?: unknown[],
) => {
  const response = useMemo(() => map(selectedColumns, (v) => {
    if (typeof v === 'string') {
      return createWithDefaultOptions(v, creator[v]());
    }
    const { name, args } = v as SelectedColumnWithArgs<T, K>;
    return createWithDefaultOptions(name as string, creator[name](...args));
  }), dependencies || []);

  return response;
};
