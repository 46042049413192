/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable max-len */
// This file should be removed once the code gen starts generating baseController
import { useMemo } from 'react';
import {
  APIResponse, APIResponseClass, FoodLog, FoodLogClass, foodLogService,
} from '../../../uc-api-sdk';
import {
  MakeRequestHookConfig, UseRequestFactory, useRequestFactory,
  BaseAPIResponse, GetOptions, BaseGetParams,
} from '../../../uc-api-sdk/staticFiles/useReqHook';

export const createGetFoodLogObject = (value: APIResponse<FoodLog>) => new APIResponseClass<FoodLogClass>(value);

export const useGetFoodLog = <RCN = undefined>(configs: MakeRequestHookConfig<BaseGetParams<string>, GetOptions, RCN>) => {
  const baseConfigs: UseRequestFactory<
    BaseGetParams<string>,
    BaseAPIResponse<FoodLog>,
    // @ts-ignore
    typeof foodLogService.get,
    GetOptions,
    APIResponseClass<FoodLogClass>
  > = useMemo(() => ({
    reqFunc: (...args: Parameters<typeof foodLogService.get>) => (
      foodLogService.get(...args)
    ),
    ResponseClassCreator: createGetFoodLogObject,
    typeName: 'useGetFoodLog',
  }), []);

  // @ts-ignore
  return useRequestFactory(baseConfigs, configs);
};
