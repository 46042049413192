import { Select, SelectProps } from 'antd';
import { FC } from 'react';
import { MED_TYPES } from './constants';

export interface MedicationTypeSelectComponentProps {
  value?: string,
  onChange?: SelectProps['onChange'],
}

export const MedicationTypeSelectComponent: FC<MedicationTypeSelectComponentProps> = ({
  onChange,
  value,
}) => (
  <Select
    data-testid="medTypeInput"
    placeholder="Select"
    options={MED_TYPES}
    onChange={onChange}
    value={value}
  />
);
