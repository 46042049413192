import { makeUnsavedNotesKey, useUnsavedNotes } from '../../../hooks/useUnsavedNotes/useUnsavedNotes';

// either mptr id, clinic event id or manual mptr
type MTPR_ID = string | 'MANUAL';

const makeMTPRUnsavedNoteKey = (id: MTPR_ID) => makeUnsavedNotesKey('mtpr', id);

export const useMTPRUnsavedNotes = (
  id: MTPR_ID,
) => (
  useUnsavedNotes(makeMTPRUnsavedNoteKey(id))
);
