import { forEach, intersection } from 'lodash';
import { message } from 'antd';
import { RoleTypeEnum } from '../../uc-api-sdk';
import { AssigneeSelectOption, SelectionLimit, SelectionLimitCheckOptions } from './type';
import { Employee } from '../../types/user';

export const checkLimitAndGetOptionData = (
  selectedOptions: AssigneeSelectOption[],
  selectionLimit?: SelectionLimit,
  options = {} as SelectionLimitCheckOptions,
) => {
  const newOptionData = [] as Employee[];
  const limitMap = {} as Record<keyof SelectionLimit, number>;
  const limitEntries = Object.entries(selectionLimit || {});
  forEach(selectedOptions, (opt) => {
    let shouldAddOption = true;
    // eslint-disable-next-line no-restricted-syntax
    for (const [ruleName, rule] of limitEntries) {
      const { roles, maxSelection, maxMessage } = rule;
      const limitMapCount = limitMap[ruleName] || 0;
      const allUserRoles = opt?.data?.allRoleTypes;
      const isRoleIncluded = (
        (Array.isArray(roles) && !!intersection(allUserRoles, roles).length)
        || !!allUserRoles?.includes(roles as RoleTypeEnum)
      );
      const nextLimitMapCount = (limitMapCount || 0) + 1;
      if (isRoleIncluded) {
        shouldAddOption = nextLimitMapCount <= maxSelection;
        if (shouldAddOption) {
          limitMap[ruleName] = nextLimitMapCount;
          break;
        }
        if (!shouldAddOption && options.showMessageOnLimit && maxMessage) {
          message.info(maxMessage);
        }
      }
    }
    if (opt?.data?.employeeData && shouldAddOption) newOptionData.push(opt.data.employeeData);
  });
  return newOptionData;
};
