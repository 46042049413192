import { CheckboxOptionType } from 'antd';
import { filter, map } from 'lodash';
import { VitalsEnumTypeComponent } from '../../../enumComponent/VitalsEnumTypeComponent/VitalsEnumTypeComponent';
import { VitalEnumType } from '../../../uc-api-sdk';

export const getBasicVitalsOptions = (
  vitals: VitalEnumType[] | Record<string, VitalEnumType>,
) => (
  map(filter(vitals, (vital) => vital !== VitalEnumType.value), (v: VitalEnumType) => ({
    value: v,
    label: <VitalsEnumTypeComponent value={v} />,
  })) as unknown as CheckboxOptionType[]
);
