import { Button } from 'antd';
import { ProgramCategoryEnum, VitalEnumType } from '../../../../uc-api-sdk';
import { VitalEnumTypeListComponent } from '../../../../enumComponent/VitalsEnumTypeComponent/VitalEnumTypeListComponent';
import { ProgramCategoryEnumListComponent } from '../../../../enumComponent/ProgramCategoriesComponent/ProgramCategoryEnumListComponent';
import { SuccessLottie } from '../../../../assets/lottie/SuccessLottie/SuccessLottie';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';

export interface EnrollmentSuccessComponentProps {
  vitals: VitalEnumType[];
  enrolledPrograms: ProgramCategoryEnum[];
  onOkay: () => void;
}

export const EnrollmentSuccessComponent = ({
  vitals,
  enrolledPrograms,
  onOkay,
}: EnrollmentSuccessComponentProps) => {
  const { send: sendMixpanel } = useMixpanelContext();

  const handleOkay = () => {
    sendMixpanel({ event: MixpanelEvents.PatientEnrollmentDone });
    onOkay();
  };

  return (
    <div className="flex fd-c jc-c ai-c w100 h100">
      <SuccessLottie />
      <h3>Good Job</h3>
      <p className="mb5">You've successfully enrolled this patient!</p>
      <div className="flex ai-c">
        <div className="b6">Programs:</div>
        <div>
          <ProgramCategoryEnumListComponent
            value={enrolledPrograms}
            type="short"
            splitter="/"
          />
        </div>
      </div>
      <div className="flex ai-c">
        <div className="b6">Vitals to Monitor:</div>
        <div>
          <VitalEnumTypeListComponent
            value={vitals}
            splitter=", "
            type="long"
          />
        </div>
      </div>
      <Button
        type="primary"
        onClick={handleOkay}
        className="mt40 w100px"
      >
        Got it
      </Button>
    </div>
  );
};
