import { SortOrder } from 'antd/lib/table/interface';
import { compact, map } from 'lodash';
import { SorterResult } from '../../types/table';
import { Sort$Direction, SortInfo } from '../../uc-api-sdk';

const getOrder = (order?: SortOrder, reverseDirection?: boolean) => {
  let d = order === 'ascend' ? 1 : -1;
  if (reverseDirection) {
    d *= -1;
  }
  return d === 1;
};

const getSortObject = <T>(sort: SorterResult<T>): SortInfo | undefined => {
  const { field, order, column } = sort;
  if (!field || !column) return undefined;
  return {
    property: field as string,
    direction: getOrder(order, column.reverseDirection)
      ? Sort$Direction.ASC
      : Sort$Direction.DESC,
  };
};

/**
   *
   * @remarks for antd table sorter
   * @param sorter SorterResult<T> of antd table
   * export interface SorterResult<T> extends Omit<AntdSorterResult<T>, 'column'>
   * @return  interface SortInfo {
                direction?: Nullable<Sort$Direction>;
                property?: Nullable<string>;
              }
 */
export const sorterHelper = <T>(
  sorter: SorterResult<T> | SorterResult<T>[],
): SortInfo[] => {
  if (Array.isArray(sorter)) {
    const newSorter: (SortInfo | undefined)[] = map(sorter, (s) => getSortObject(s));
    return compact(newSorter);
  }
  return getSortObject(sorter) ? [getSortObject(sorter) as SortInfo] : [];
};
