import { PriorAuthCaseStatusEnum } from '../../../../uc-api-sdk';

export interface PriorAuthSubmissionNoteComponentProps {
  status?: PriorAuthCaseStatusEnum;
  alwaysShow?: boolean;
}

export const PriorAuthSubmissionNoteComponent = ({
  status,
  alwaysShow,
}: PriorAuthSubmissionNoteComponentProps) => (
  (alwaysShow || status === PriorAuthCaseStatusEnum.NEED_SUBMISSION)
    ? (
      <div className="prior-auth-note">
        Please submit patient's information on the insurance website ASAP.
        You can keep track of the Prior-auth progress in “Utilities” as well.
      </div>
    ) : null
);
