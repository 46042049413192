import { InitialVisitTagComponent } from '../../features/patient/component/TagsComponent/InitialVisitTagComponent/InitialVisitTagComponent';
import EmptyComponent from '../../uiComponent/EmptyComponent/EmptyComponent';
import { VisitStatusEnum } from '../../uc-api-sdk';

export interface VisitsStatusEnumComponentProps {
  value: VisitStatusEnum | null | undefined;
}

export const VisitsStatusEnumComponent = ({
  value,
}: VisitsStatusEnumComponentProps) => {
  const getTag = (): React.ReactNode => {
    switch (value) {
      case VisitStatusEnum.CHECKED_OUT:
        return <InitialVisitTagComponent.CheckedOutTag />;
      case VisitStatusEnum.NO_SHOW:
        return <InitialVisitTagComponent.VisitNoShowTag />;
      case VisitStatusEnum.ONGOING:
        return <InitialVisitTagComponent.OnGoingTag />;
      case VisitStatusEnum.UPCOMING:
        return <InitialVisitTagComponent.UpcomingTag />;
      case VisitStatusEnum.START_SOON:
        return <InitialVisitTagComponent.StartsSoon />;
      case VisitStatusEnum.WAITING:
        return <InitialVisitTagComponent.Waiting />;
      default:
        return <EmptyComponent />;
    }
  };

  return (
    <span>{getTag()}</span>
  );
};
