import { LIVEX_CAHTBOX_ROOT, LVIEX_TAG_ID } from './constants';

export function waitForElm(selector: string) {
// eslint-disable-next-line
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      // eslint-disable-next-line
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        observer.disconnect();
        resolve(document.querySelector(selector));
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
}

export const useLiveX = () => {
  const insertLiveX = async () => {
    let scriptTag = document.getElementById(LVIEX_TAG_ID) as HTMLScriptElement;

    if (!scriptTag) {
      scriptTag = document.createElement('script');
      scriptTag.async = true;
      scriptTag.defer = true;
      scriptTag.id = LVIEX_TAG_ID;
      scriptTag.type = 'text/javascript';
      scriptTag.src = 'https://chatbox.copilot.livex.ai/livex.min.js';
      scriptTag.dataset.botId = '5ebec53e-5bb7-4adf-8498-e9ef1aa1df8c';
      document.head.appendChild(scriptTag);
    }
    await waitForElm(`#${LIVEX_CAHTBOX_ROOT}`);
    return scriptTag;
  };

  const removeLiveX = () => {
    const element = document.getElementById(LIVEX_CAHTBOX_ROOT);
    element?.remove();
    document.getElementById(LVIEX_TAG_ID)?.remove();
  };

  const clearConversations = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cc = (window as any).livexChatbot?.clearConversations;
    if (cc) {
      cc();
    }
    removeLiveX();
  };

  return {
    insertLiveX,
    removeLiveX,
    clearConversations
  };
};
