import { ColumnType } from 'antd/es/table';
import { isNil } from 'lodash';
import { WeightService } from '../../../../helpers/weight';
import { HSSummaryTableData } from './HSSummaryTableComponent';

const EMPTY_VALUE = 'N/A';

/* eslint-disable import/prefer-default-export */
export const HSSummaryTableColumns = {
  type: (): ColumnType<HSSummaryTableData> => ({
    title: 'Type',
    key: 'type',
    dataIndex: 'type',
    width: '23%',
  }),
  average: (): ColumnType<HSSummaryTableData> => ({
    title: 'Avg (Lbs)',
    key: 'average',
    dataIndex: 'average',
    render: (value) => (isNil(value) ? EMPTY_VALUE : WeightService.kgToLb(Number(value))),
  }),
  bmi: (): ColumnType<HSSummaryTableData> => ({
    title: 'BMI',
    key: 'bmi',
    dataIndex: 'bmi',
    render: (value) => (isNil(value) ? EMPTY_VALUE : Number(value).toFixed(1)),
  }),
  range: (): ColumnType<HSSummaryTableData> => ({
    title: 'Range (Lbs)',
    key: 'range',
    dataIndex: 'range',
    width: '17%',
    render: (_, record) => (isNil(record.min) ? EMPTY_VALUE : `${WeightService.kgToLb(Number(record.min))} - ${WeightService.kgToLb(Number(record.max))}`),
  }),
  variability: (): ColumnType<HSSummaryTableData> => ({
    title: 'Variability',
    key: 'variability',
    dataIndex: 'variability',
    render: (value) => (isNil(value) ? EMPTY_VALUE : WeightService.kgToLb(Number(value))),
  }),
};
