import { Button, Drawer } from 'antd';
import { FC } from 'react';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { Icons } from '../../../../icons/Icons';
import { CareNote } from '../../../../uc-api-sdk';
import { DefaultExtendableDrawers } from '../../../../uiComponent/ExtendableDrawerComponent/controller/DefaultExtendableDrawers';
import { EditCareNoteFormContainer } from '../../container/EditCareNoteFormContainer/EditCareNoteFormContainer';

export interface CareNoteEditDrawerProps {
  note: CareNote;
}

export const CareNoteEditDrawer: FC<CareNoteEditDrawerProps> = ({
  note,
}) => {
  const {
    value: open,
    setTrue: setOpen,
    setFalse: setClose,
  } = useBoolean(false);
  return (
    <div>
      <Button type="text" onClick={setOpen} className="p0">
        <Icons.WriteIcon />
      </Button>
      <Drawer
        open={open}
        onClose={setClose}
        title="Edit Care Note"
        id={DefaultExtendableDrawers.editCareNote}
        className="header-reversed"
        destroyOnClose
      >
        <EditCareNoteFormContainer
          note={note}
          onSubmit={setClose}
          onCancel={setClose}
        />
      </Drawer>
    </div>
  );
};
