import { DeviceMonitorMethodEnum } from '../../../enumComponent/DeviceMonitorMethodEnumComponent/DeviceMonitorMethodEnumComponent';
import { FormOptions, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { VitalEnumType } from '../../../uc-api-sdk';

export interface DeviceMonitorSubmitValue {
  vitalType: VitalEnumType;
  monitorMethod: DeviceMonitorMethodEnum;
}

export const useDeviceMonitorForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    vitalType: {
      name: 'vitalType',
      label: '',
    },
    monitorMethod: {
      name: 'monitorMethod',
      label: 'Choose Monitor Method',
    },
  }, options);

  return factory;
};
