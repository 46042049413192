import { groupBy, reduce } from 'lodash';
import moment, { Moment } from 'moment';
import { USA_DATE } from '../../constants/timeFormat';

type GetDate<T> = (obj: T) => string | number | Moment | Date;

export class DateHelper {
  // create a table with date as the key and array of measurements as the value
  public static dateValueDict<T>(data: T[], getDate: GetDate<T>) {
    return reduce(data, (res, v) => {
      const key = moment(getDate(v)).format(USA_DATE);
      if (!res[key]) {
        res[key] = [];
      }
      res[key].push(v);
      return res;
    }, {} as Record<string, T[]>);
  }

  // groups by date
  public static groupByDate<T>(data: T[], getDate: GetDate<T>) {
    return groupBy(data, (v) => moment(getDate(v)).format(USA_DATE));
  }
}
