/* eslint-disable max-len */
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  FormProps,
  Input,
  Row
} from 'antd';
import dayjs from 'dayjs';
import { Moment } from 'moment';
import { FC } from 'react';
import { USA_DATE } from '../../../../constants/timeFormat';
import {
  CalendarSourceEnum, RoleTypeEnum, VisitMethodEnum, VisitTypeEnum
} from '../../../../uc-api-sdk';
import { DiscardUnsavedChangesModalComponent } from '../../../../uiComponent/DiscardUnsavedChangesModalComponent/DiscardUnsavedChangesModalComponent';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { EmployeeCalendarSelectComponent } from '../../../calendar/component/EmployeeCalendarSelectComponent/EmployeeCalendarSelectComponent';
import { PatientVisitCardComponent } from '../../../calendar/component/PatientVisitCardComponent/PatientVisitCardComponent';
import { useCreateVisitFormContext } from '../../../calendar/context/CreateVisitFormContext/CreateVisitFormContext';
import { FormType } from '../../../Input/types';
import { PatientProfileContainer } from '../../../patient/container/PatientProfileContainer/PatientProfileContainer';
import { PatientSearchSelectContainer } from '../../../patient/container/PatientSearchSelectContainer/PatientSearchSelectContainer';
import { VisitOverlapTimeContainer } from '../../container/VisitOverlapTimeContainer/VisitOverlapTimeContainer';
import { ParticipantsEnum } from '../../type/participants';
import { EventTitleComponent } from '../EventTitleComponent/EventTitleComponent';
import { EventTypeRadioGroupComponent } from '../EventTypeRadioGroupComponent/EventTypeRadioGroupComponent';
import { FollowUpdateVisitWindowComponent } from '../FollowUpVisitWindowComponent/FollowUpVisitWindowComponent';
import { ParticipantsCheckboxGroupComponent } from '../ParticipantsCheckboxGroupComponent/ParticipantsCheckboxGroupComponent';
import { PatientLocalTimeComponent } from '../PatientLocalTimeComponent/PatientLocalTimeComponent';
import { patientSearchSelectOptionRenderer } from '../PatientSearchSelectItemComponent/PatientSearchSelectItemComponent';
import { VisitMethodRadioGroupComponent } from '../VisitMethodRadioGroupComponent/VisitMethodRadioGroupComponent';
import { VisitTypeSelectCalendarComponent, VisitTypeSelectCalendarComponentProps } from '../VisitTypeSelectComponent/VisitTypeSelectCalendarComponent';
import './CreateVisitFormComponent.scss';
import { ParticipantsSelectFormItemComponent } from './ParticipantsSelectComponent';
import { TimeSelectComponent } from './TimeSelectComponent';

export interface SubmitValue {
  eventType: CalendarSourceEnum;
  eventTitle: string;
  otherEventParticipants: string[];
  patient: string;
  visitType: VisitTypeEnum;
  visitMethod: VisitMethodEnum;
  participants: ParticipantsEnum[];
  rdhc: string;
  ca: string;
  ma: string;
  md: string;
  visitDate: Moment;
  startTime: number;
  endTime: number;
  description: string;
}

interface RawSubmitValue extends Omit<SubmitValue, 'visitType'> {
  visitType: VisitTypeSelectCalendarComponentProps['value'];
}

export interface CreateVisitFormComponentProps extends FormType<SubmitValue> {
  isEdit?: boolean;
  isSubmitting?: boolean;
  disabledFields?: string[];
  showSearch?: boolean;
  disabled?: boolean;
  visitId?: string | null;
}

export const CreateVisitFormComponent: FC<CreateVisitFormComponentProps> = ({
  onSubmit,
  onCancel,
  isEdit = false,
  isSubmitting,
  initialValues,
  onValuesChange,
  disabledFields = [],
  disabled,
  formButtons,
  showSearch = true,
  visitId,
}) => {
  const formHook = useCreateVisitFormContext();

  if (!formHook) {
    return null;
  }

  const handleOnValuesChange: FormProps['onValuesChange'] = (changedValues, values) => {
    formHook.onValuesChange(changedValues, values);
    onValuesChange?.(changedValues, values);
  };

  const renderTitle = () => (
    <div className="create-visit-form-event-title">
      <div className="px20 pb10">
        <h3 className="m0">{isEdit ? 'Edit The Event' : 'Create an Event'}</h3>
      </div>
      <Divider className="mt0" />
    </div>
  );

  const handleFinish = (values: RawSubmitValue) => {
    formHook.handleSubmitAndReset(onSubmit)?.({
      ...values,
      visitType: typeof values.visitType === 'string'
        ? values.visitType : values.visitType?.value,
    });
  };

  return (
    <Form
      form={formHook.form}
      layout="vertical"
      onFinish={handleFinish}
      onFieldsChange={formHook.onFieldsChange}
      onValuesChange={handleOnValuesChange}
      initialValues={initialValues}
      className="create-visit-form h100"
      disabled={disabled || formHook.formDisabled}
    >
      <FixedComponent>
        <FixedComponent.Child className="create-visit-form__form-section">
          {renderTitle()}
          <div className="create-visit-form__section">
            <FormItem info={formHook.getInfo('eventType')} required>
              <EventTypeRadioGroupComponent disabled={isEdit} onChange={formHook.onEventTypeChange} />
            </FormItem>
          </div>
          <Divider />
          <FormItem
            noStyle
            shouldUpdate={formHook.shouldUpdate(['patient', 'eventType'])}
          >
            {({ getFieldValue: getFieldV }) => (
              <PatientProfileContainer
                patientId={formHook.getValue('patient', getFieldV)}
                showLoadingOverlay={false}
                getEnrolledProgram
                getAssignees
                getComplexity
                getFollowUpVisitRange
                getLastMeasurementDate
              >
                {(info, loading) => (
                  <>
                    <div className="create-visit-form__section">
                      <FormItem
                        info={formHook.getInfo('patient')}
                        required
                        hidden={!showSearch}
                      >
                        {
                          showSearch
                            ? (
                              <PatientSearchSelectContainer
                                isCreateOtherEventsInCalender={formHook.getValue('eventType', getFieldV) === CalendarSourceEnum.OTHER}
                                disabled={isEdit || disabledFields.includes('patient')}
                                popupClassName="create-visit-form__patient-search-select"
                                optionRenderer={patientSearchSelectOptionRenderer}
                              />
                            ) : <div />
                        }
                      </FormItem>
                      <FormItem
                        noStyle
                        shouldUpdate={formHook.shouldUpdate(['patient'])}
                        hidden={!showSearch}
                      >
                        {({ getFieldValue }) => (
                          formHook.shouldShowPatientInfoCard(getFieldValue) ? (
                            <PatientVisitCardComponent
                              isLoading={loading}
                              patientInfo={info}
                            />
                          ) : null
                        )}
                      </FormItem>
                    </div>
                    {showSearch && <Divider />}
                    <FormItem
                      noStyle
                      shouldUpdate={formHook.shouldUpdate(['eventType', 'patient'])}
                    >
                      {({ getFieldValue }) => {
                        if (formHook.isOtherEvent(getFieldValue)) {
                          return (
                            <>
                              <div className="create-visit-form__section">
                                <FormItem
                                  info={formHook.getInfo('eventTitle')}
                                  required
                                >
                                  <EventTitleComponent
                                    title={formHook.getEventTitle(info)}
                                  />
                                </FormItem>
                              </div>
                              <Divider />
                              <div className="create-visit-form__section">
                                <FormItem info={formHook.getInfo('otherEventParticipants')} required>
                                  <EmployeeCalendarSelectComponent
                                    source={formHook.getValue('eventType', getFieldValue)}
                                    patientId={info?.id}
                                    roles={[
                                      RoleTypeEnum.RD,
                                      RoleTypeEnum.HC,
                                      RoleTypeEnum.CA,
                                      RoleTypeEnum.PROVIDER,
                                      RoleTypeEnum.MA,
                                    ]}
                                  />
                                </FormItem>
                              </div>
                              <Divider />
                            </>
                          );
                        }
                        return (
                          <>
                            <div className="create-visit-form__section">
                              <FormItem
                                noStyle
                                shouldUpdate={formHook.shouldUpdate(['patient'])}
                              >
                                {({ getFieldValue }) => (
                                  <FormItem
                                    info={formHook.getInfo('visitType')}
                                    rules={formHook.getRequiredRules('visitType')}
                                  >
                                    <VisitTypeSelectCalendarComponent
                                      disabled={!formHook.hasValue('patient', getFieldValue) || disabledFields.includes('visitType')}
                                      onChange={formHook.onVisitTypeChange(info?.followUpVisitWindowService)}
                                      startDate={info?.followUpVisitWindowService?.from}
                                      endDate={info?.followUpVisitWindowService?.to}
                                    />
                                  </FormItem>
                                )}
                              </FormItem>
                              <FormItem
                                info={formHook.getInfo('visitMethod')}
                                rules={formHook.getRequiredRules('visitMethod')}
                              >
                                <VisitMethodRadioGroupComponent />
                              </FormItem>
                            </div>
                            <Divider />
                            <div className="create-visit-form__section">
                              <FormItem
                                noStyle
                                shouldUpdate={formHook.shouldUpdate(['patient', 'visitType'])}
                              >
                                {({ getFieldValue }) => (
                                  <>
                                    <FormItem
                                      info={formHook.getInfo('participants')}
                                      rules={formHook.getRequiredRules('participants')}
                                    >
                                      <ParticipantsCheckboxGroupComponent
                                        disabled={formHook.isParticipantsDisabled(getFieldValue)}
                                      />
                                    </FormItem>
                                    <ParticipantsSelectFormItemComponent
                                      source={formHook.getValue('eventType', getFieldValue)}
                                    />
                                  </>
                                )}
                              </FormItem>
                            </div>
                            <Divider />
                          </>
                        );
                      }}
                    </FormItem>
                    <div className="create-visit-form__section">
                      <FormItem
                        info={formHook.getInfo('visitDate')}
                        rules={formHook.getRequiredRules('visitDate')}
                      >
                        <DatePicker className="w100" format={USA_DATE} />
                      </FormItem>
                      <FormItem
                        noStyle
                        shouldUpdate={formHook.shouldUpdate(['patient', 'visitType'])}
                      >
                        {({ getFieldValue }) => {
                          const visitDate = formHook.getValue('visitDate', getFieldValue);
                          const show = formHook.shouldShowFollowUpVisitWindow(getFieldValue);
                          return show ? (
                            <FollowUpdateVisitWindowComponent
                              from={info?.followUpVisitWindowService?.from}
                              to={info?.followUpVisitWindowService?.to}
                              selectedDate={dayjs(visitDate)}
                            />
                          ) : null;
                        }}
                      </FormItem>
                      <Row gutter={5}>
                        <Col span={12}>
                          <FormItem
                            className="mb0"
                            info={formHook.getInfo('startTime')}
                            rules={formHook.getRequiredRules('startTime')}
                          >
                            <TimeSelectComponent onChange={formHook.onStartTimeChange} />
                          </FormItem>
                        </Col>
                        <Col span={12}>
                          <FormItem
                            noStyle
                            shouldUpdate={formHook.shouldUpdate(['startTime', 'patient', 'visitType', 'eventType'])}
                          >
                            {
                              ({ getFieldValue }) => (
                                <FormItem
                                  className="mb0"
                                  info={formHook.getInfo('endTime')}
                                >
                                  <TimeSelectComponent
                                    minValue={formHook.getValue('startTime', getFieldValue)}
                                    disabled={formHook.shouldBeDisabled(getFieldValue)}
                                    isInit={getFieldValue('visitType')?.value === VisitTypeEnum.INIT}
                                    onChange={formHook.onEndTimeChange}
                                    source={getFieldValue('eventType')}
                                  />
                                </FormItem>
                              )
                            }
                          </FormItem>
                        </Col>
                      </Row>
                      <FormItem
                        noStyle
                        shouldUpdate={formHook.shouldUpdate(['patient', 'visitDate', 'startTime'])}
                      >
                        {({ getFieldValue }) => {
                          const startTime = formHook.getStartTime(getFieldValue);
                          const shouldShowPatient = formHook.hasValue('patient', getFieldValue);
                          return shouldShowPatient ? (
                            <PatientLocalTimeComponent
                              time={startTime}
                              timezone={info?.patientInfo?.clinic?.timezone ?? undefined}
                            />
                          ) : null;
                        }}
                      </FormItem>
                      <FormItem
                        noStyle
                        shouldUpdate={formHook.shouldUpdate([
                          'visitDate',
                          'startTime',
                          'endTime',
                          'participants',
                          'rdhc',
                          'ca',
                          'md',
                        ])}
                      >
                        {({ getFieldValue }) => {
                          const startTime = formHook.getStartTime(getFieldValue);
                          const endTime = formHook.getEndTime(getFieldValue);
                          const participants = formHook.getParticipants(getFieldValue);
                          return (
                            <VisitOverlapTimeContainer
                              startTime={startTime}
                              endTime={endTime}
                              participants={participants}
                              visitIds={isEdit && visitId ? [visitId] : undefined}
                            />
                          );
                        }}
                      </FormItem>
                    </div>
                    <Divider />
                    <div className="create-visit-form__section">
                      <FormItem
                        info={formHook.getInfo('description')}
                      >
                        <Input.TextArea />
                      </FormItem>
                    </div>
                  </>
                )}
              </PatientProfileContainer>
            )}
          </FormItem>
        </FixedComponent.Child>
        <FixedComponent.Child isFixed>
          {
            formButtons === undefined
              ? (
                <>
                  <Divider className="mt10 mb12" />
                  <div className="flex jc-sb px20">
                    <DiscardUnsavedChangesModalComponent onConfirm={onCancel}>
                      <Button type="text">Cancel</Button>
                    </DiscardUnsavedChangesModalComponent>
                    <Button
                      htmlType="submit"
                      type="primary"
                      loading={isSubmitting}
                    >
                      {isEdit ? 'Save' : 'Create'}
                    </Button>
                  </div>
                </>
              ) : formButtons
          }
        </FixedComponent.Child>
      </FixedComponent>
    </Form>
  );
};
