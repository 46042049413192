import dayjs, { Dayjs, OpUnitType } from 'dayjs';
import { View } from 'react-big-calendar';

export type { View } from 'react-big-calendar';

export class BigCalendarHelper {
  public static viewToDayjsUnit(view: View): OpUnitType {
    switch (view) {
      case 'work_week': return 'week';
      case 'agenda': return 'day';
      default: return view;
    }
  }

  public static checkIsAllDayEvent = (
    startTime?: Dayjs,
    endTime?: Dayjs
  ) => {
    if (!startTime || !endTime) return false;
    const endOfStartTime = startTime.endOf('day');
    const endTimeMinusOne = endTime.subtract(1, 'minute');
    return (
      endOfStartTime.format('MM-DD-YY HH:mm') === endTimeMinusOne.format('MM-DD-YY HH:mm')
    );
  };

  public static checkIsMultiDayEvent = (
    startTime?: string | Dayjs,
    endTime?: string | Dayjs,
  ) => (
    // include All-day event
    (startTime && endTime)
    && (
      dayjs(endTime).diff(dayjs(startTime), 'hour') >= 24
    )
  );
}
