import { isNumber } from 'lodash';
import { ReactNode } from 'react';
import { WeightService } from '../../helpers/weight';
import { Weight } from '../../uc-api-sdk';
import EmptyComponent from '../EmptyComponent/EmptyComponent';

export interface WeightComponentProps {
  label?: string;
  weight?: Weight;
  showLabelWhenEmpty?: boolean;
  unitText?: ReactNode;
  showUnit?: boolean;
  showAsKg?: boolean;
}

export const WeightComponent = ({
  label = 'Weight:',
  weight,
  showLabelWhenEmpty = true,
  showUnit = true,
  showAsKg,
  unitText,
}: WeightComponentProps) => {
  const {
    value: weightValue,
  } = weight || {};

  if (!isNumber(weightValue) || weightValue === WeightService.NO_RECORD_VALUE) {
    return (
      <>
        {showLabelWhenEmpty && <span>{label}</span>}
        <EmptyComponent />
      </>
    );
  }

  const renderKg = () => {
    const weightInKg = WeightService.getWeightInKg(weight);
    return (
      <>
        <span>{weightInKg}</span>
        {showUnit ? <span>{(unitText || WeightService.defaultKgUnit)}</span> : null}
      </>
    );
  };

  const renderLb = () => {
    const weightInLb = WeightService.getWeightInLb(weight);
    return (
      <>
        <span>{weightInLb}</span>
        {showUnit ? <span>{(unitText || 'lbs')}</span> : null}
      </>
    );
  };

  const getText = () => {
    if (showAsKg) return renderKg();
    return renderLb();
  };

  return (
    <div>
      <span>
        {/* ie Weight: */}
        {label}
      </span>
      <div className="flex">
        {getText()}
      </div>
    </div>
  );
};
