import { intersection, map } from 'lodash';
import { EnrolledProgramService } from '../../../../services/EnrolledProgramService';
import { ProgramCategoryEnum } from '../../../../uc-api-sdk';
import { PatientProfileProgramEnrollmentItemComponent } from './PatientProfileProgramEnrollmentItemComponent';

export interface PatientProfileProgramEnrollmentComponentProps {
  enrolledProgramService: EnrolledProgramService;
  patientClinicProgramParticipation: ProgramCategoryEnum[];
}

export const PatientProfileProgramEnrollmentComponent = ({
  enrolledProgramService,
  patientClinicProgramParticipation,
}: PatientProfileProgramEnrollmentComponentProps) => {
  const programEnrollments = enrolledProgramService.getProgramEnrollments();
  const validPrograms = intersection(patientClinicProgramParticipation, map(programEnrollments, 'category'));
  if (!validPrograms.length) {
    return <div>No program enrolled</div>;
  }

  return (
    <div>
      {
        enrolledProgramService.getProgramEnrollments().map((p) => (
          validPrograms.includes(p.category as ProgramCategoryEnum)
            ? (
              <PatientProfileProgramEnrollmentItemComponent
                key={p.category}
                programEnrollment={p}
              />
            ) : null
        ))
      }
    </div>
  );
};
