import { DatePicker } from 'antd';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useDiseaseHistoryForm } from '../../hook/useDiseaseHistoryForm';

export interface DiseaseHistoryDiagnosisYearFormListItemComponentProps {
  fieldName: number;
}

export const DiseaseHistoryDiagnosisYearFormListItemComponent = ({
  fieldName,
}: DiseaseHistoryDiagnosisYearFormListItemComponentProps) => {
  const {
    makeListNamePath,
    getLabel,
  } = useDiseaseHistoryForm();

  return (
    <FormItem
      name={makeListNamePath('diagnosedYear', fieldName)}
      label={getLabel('diagnosedYear') as string}
    >
      <DatePicker picker="year" disabledDate={(date) => date.isAfter(new Date())} />
    </FormItem>
  );
};
