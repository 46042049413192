import { ConsentStatusEnum, PatientConsent } from '../uc-api-sdk';

export interface ConsentServiceArg {
  consent?: PatientConsent;
}

export class ConsentService {
  private consent: PatientConsent | undefined;

  constructor(consent?: PatientConsent) {
    this.consent = consent;
  }

  public hasConsent() {
    return !!this.consent?.id
      && this.consent?.status === ConsentStatusEnum.SIGNED
      // @ts-ignore
      && this.consent?.isDischarged !== true;
  }

  public isSigned() {
    return this.consent?.status === ConsentStatusEnum.SIGNED
    // @ts-ignore
      && this.consent?.isDischarged !== true;
  }

  public get signatureMethod() {
    return this.consent?.consentType ?? undefined;
  }

  public get consentHTML() {
    return this.consent?.content ?? undefined;
  }

  public get fileUrl() {
    return this.consent?.fileInfo?.url ?? undefined;
  }
}
