import { PatientService } from '../../../../services/PatientService';
import { CardTitleComponent } from '../../../../uiComponent/CardTypographyComponent/CardTitleComponent/CardTitleComponent';
import { InsuranceFormContainer } from '../../../Insurance/container/InsuranceFormContainer/InsuranceFormContainer';

export interface WorklistInsuranceFormContainerProps {
  patientInfoService: PatientService;
}

export const WorklistInsuranceFormContainer = ({
  patientInfoService,
}: WorklistInsuranceFormContainerProps) => (
  <>
    <CardTitleComponent size="default" className="mb10">
      <span>Insurance</span>
    </CardTitleComponent>
    <InsuranceFormContainer
      patientInfo={patientInfoService.patient}
    />
  </>
);
