import {
  Row, Col, Space
} from 'antd';
import USStateSelectComponent from '../../../../selectComponents/USStatesSelectComponent/USStatesSelectComponent';
import { AddressAptInputComponent } from '../../../../uiComponent/Address/AddressAptInputComponent/AddressAptInputComponent';
import { AddressCityInputComponent } from '../../../../uiComponent/Address/AddressCityInputComponent/AddressCityInputComponent';
import { AddressInputComponent } from '../../../../uiComponent/Address/AddressInputComponent/AddressInputComponent';
import ZipCodeInputComponent from '../../../../uiComponent/ZipCodeInputComponent/ZipCodeInputComponent';
import { FormItem } from '../../../../uiComponent/FormItem/FormItem';
import { Title } from '../../../../uiComponent/Title/Title';
import { SubmitValue, useAssignDeviceForm } from '../../../patient/hook/useAssignDeviceForm';
import { CopyAddressButtonComponent } from '../../../../uiComponent/CopyAddressButtonComponent/CopyAddressButtonComponent';

export const EnrollmentAddressFormItemComponent = () => {
  const hook = useAssignDeviceForm();
  return (
    <FormItem
      shouldUpdate={hook.shouldUpdate([
        'deliveryMethod',
        'deviceList',
        'address',
        'address2',
        'city',
        'state',
        'zipcode'
      ])}
      className="mb50"
    >
      {({ getFieldValue, getFieldsValue }) => (
        hook.shouldShowAddressInput(getFieldValue) ? (
          <div className="mt40 mb20 shipping-address">
            <Title>
              Confirm patient's shipping address
            </Title>
            <Row>
              <Col span={14}>
                <Space.Compact
                  block
                  className="compact"
                >
                  <FormItem
                    info={hook.getInfo('address')}
                    className="w80"
                  >
                    <AddressInputComponent />
                  </FormItem>
                  <FormItem
                    className="w20"
                    info={hook.getInfo('address2')}
                  >
                    <AddressAptInputComponent />
                  </FormItem>
                </Space.Compact>
              </Col>
            </Row>
            <Row>
              <Col span={7}>
                <FormItem
                  info={hook.getInfo('city')}
                >
                  <AddressCityInputComponent />
                </FormItem>
              </Col>
              <Col offset={1} span={6}>
                <Space.Compact
                  block
                  className="compact"
                >
                  <FormItem
                    info={hook.getInfo('state')}
                    className="w100"
                  >
                    <USStateSelectComponent />
                  </FormItem>
                  <FormItem
                    info={hook.getInfo('zipcode')}
                  >
                    <ZipCodeInputComponent />
                  </FormItem>
                </Space.Compact>
              </Col>
              <Col offset={1}>
                <FormItem label=" ">
                  <CopyAddressButtonComponent
                    value={(getFieldsValue() as SubmitValue).shippingAddress || {}}
                  />
                </FormItem>
              </Col>
            </Row>
          </div>
        ) : null
      )}
    </FormItem>
  );
};
