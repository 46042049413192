import { FC } from 'react';
import { useMedicationContext } from '../../../contexts/MedicationContext/MedicationContext';
import { AddEditMedicationFormComponent } from '../component/AddEditMedicationFormComponent/AddEditMedicationFormComponent';
import { MedicationType } from '../type/type';
import { useBillableTimeInterventionComp } from '../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../billableTime/contants/contants';
import { useMixpanelContext } from '../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../contexts/MixpanelContext/MixpanelEvents';

export interface EditMedicationContainerProps {
  initialValues: MedicationType;
  onSubmit: (v: MedicationType) => void;
  patientId?: string;
}

export const EditMedicationContainer:
  FC<EditMedicationContainerProps> = ({
    initialValues,
    onSubmit,
    patientId,
  }) => {
    const { onUpdate } = useMedicationContext();
    const { send: sendMixpanel } = useMixpanelContext();
    const { send } = useBillableTimeInterventionComp();
    const handleSubmit = (v: MedicationType) => {
      sendMixpanel({ event: MixpanelEvents.EditMedication });
      send({
        patientId,
        component: BillableTimeInterventionComponentEnum.Transcribing,
        detail:
        BillableTimeInterventionDetailEnum.TranscribingVisitTranscribeMedicationListEditMedication,
      });
      if (initialValues.id) {
        onUpdate?.(v, initialValues.id);
      }
      onSubmit?.(v);
    };

    return (
      <AddEditMedicationFormComponent
        onSubmit={handleSubmit}
        initialValues={initialValues}
        submitText="Save"
      />
    );
  };
