import { Table, TableProps } from 'antd';
import { FC } from 'react';
import { PatientResult } from '../../../../uc-api-sdk';
import './PatientsTableComponent.scss';
import { ModalExportDataComponent } from '../../../../uiComponent/ModalExportDataComponent/ModalExportDataComponent';
import { ExportDataFunction } from '../../../patients/container/PatientsTableContainer/PatientsTableContainer';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';

export interface PatientsTableComponentProps extends TableProps<PatientResult> {
  totalSize?: number;
  showTotalSize?: boolean;
  exportData?: ExportDataFunction;
}

export const PatientsTableComponent: FC<PatientsTableComponentProps> = ({
  totalSize,
  showTotalSize = true,
  exportData,
  columns,
  // eslint-disable-next-line react/jsx-props-no-spreading
  ...props
}) => {
  const { isProvider } = useLoggedInUserFromContext();
  return (
    <div>
      <div className="flex jc-sb">
        <div>
          {showTotalSize && (
          <div className="text-gray-scale-2 fs16 b5 mb30 patientCount">
            {' '}
            {totalSize}
            {' '}
            patients
          </div>
          )}
        </div>
        <div>
          <ModalExportDataComponent
            exportData={exportData}
            columns={columns}
            totalSize={totalSize}
            isProvider={isProvider}
          />
        </div>
      </div>
      <Table
        className="patientTable default-border-radius"
        size="middle"
        tableLayout="auto"
        rowKey={(record) => (record.id as string)}
        scroll={{
          scrollToFirstRowOnChange: true,
          x: 'max-content',
        }}
        columns={columns}
      // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </div>
  );
};
