import { Row, Typography } from 'antd';
import { map } from 'lodash';
import { FC } from 'react';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import { InfoComponent, InfoComponentProps } from '../InfoComponent/InfoComponent';

const { Title } = Typography;
export interface DemographicSocialSupportComponentProps {
  socialSupport?: string;
  contactInfo?: string;
  otherProviders?: string;
}

export const DemographicSocialSupportComponent: FC<DemographicSocialSupportComponentProps> = ({
  socialSupport,
  contactInfo,
  otherProviders,
}) => {
  const dataSet: InfoComponentProps[] = [
    {
      name: 'Community/Social Support',
      value: socialSupport ?? <EmptyComponent />,
      span: 6,
    },
    {
      name: 'Contact Information',
      value: contactInfo ?? <EmptyComponent />,
      span: 6,
    },
    {
      name: 'Other Providers',
      value: otherProviders ?? <EmptyComponent />,
      span: 6,
    },
  ];

  const renderColumns = (data: InfoComponentProps[]) => map(data, (d, index) => (
    <InfoComponent
      key={index}
      name={d.name}
      value={d.value}
      span={d.span}
      onClick={d.onClick}
      type={d.type}
      render={d.render}
    />
  ));
  return (
    <div className="DemographicContactInfoComponent flex gap3 fd-c">
      <Title level={5}>Social Support</Title>
      <Row align="bottom" gutter={7}>
        {renderColumns(dataSet)}
      </Row>
    </div>
  );
};
