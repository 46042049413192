import { FilterDropdownProps } from 'antd/lib/table/interface';
import { RoleTypeEnum } from '../../../../uc-api-sdk';
import { CoWorkerDropdownComponent } from '../../../../uiComponent/CoWorkerDropdownComponent/CoWorkerDropdownComponent';
import { TableSearchComponent } from '../TableSearchComponent/TableSearchComponent';

export interface EmployeeSearchFilterComponentProps extends FilterDropdownProps {
  filterRoles: RoleTypeEnum[];
  className?: string;
  needToFilter?: boolean;
  selectedOrgId?: string;
  reassignAssigneesClinicIdList?: string;
  includeUnassigned?: boolean;
  mode?: 'multiple' | 'tags',
}

export const EmployeeSearchFilterComponent = ({
  filterRoles,
  selectedOrgId,
  needToFilter,
  includeUnassigned,
  reassignAssigneesClinicIdList,
  mode,
  ...props
}: EmployeeSearchFilterComponentProps) => (
  <TableSearchComponent
    filterRoles={filterRoles}
    selectedClinic={reassignAssigneesClinicIdList}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <CoWorkerDropdownComponent
      mode={mode}
      needToFilter={needToFilter}
      value={props.selectedKeys?.[0] as string | undefined}
      onChange={(id) => {
        props.setSelectedKeys(id ? [id] : []);
      }}
      filterRoles={filterRoles}
      filterOrganizationIds={selectedOrgId ? [selectedOrgId] : undefined}
      clinicIdList={selectedOrgId ? [selectedOrgId] : undefined}
      reassignAssigneesClinicIdList={
        reassignAssigneesClinicIdList ? [reassignAssigneesClinicIdList] : undefined
      }
      includeUnassigned={includeUnassigned}
    />
  </TableSearchComponent>
);
