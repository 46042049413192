import React, { useState } from 'react';
import { Button } from 'antd';
import { TicketInfoComponent } from '../TicketInfoComponent/TicketInfoComponent';
import { TicketResolveAndCommentFormComponent, SubmitValue } from '../TicketResolveAndCommentFormComponent/TicketResolveAndCommentFormComponent';
import { EditTicketContainer } from '../../container/EditTicketContainer/EditTicketContainer';
import { Ticket, TicketCommentClass, TicketStatusEnum } from '../../../../uc-api-sdk';
import { EditTicketMode } from '../../constants';
import { TicketTitleValueItemComponent } from '../TicketTitleValueItemComponent/TicketTitleValueItemComponent';
import { TicketActivityHistoryComponent } from '../TicketActivityHistoryComponent/TicketActivityHistoryComponent';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import { AccountHelper } from '../../../../helpers/account';
import { TicketCommentListComponent } from '../TicketCommentComponent/TicketCommentListComponent';
import './TicketViewComponent.scss';

export interface TicketViewData {
  ticketInfo: Ticket;
  comments: TicketCommentClass[];
}

export interface TicketCommentHandleProps {
  handleDeleteComment?: (id: string) => void;
  handleEditComment?: (id: string, value: string) => void;
}

export interface ViewTicketComponentProps extends TicketCommentHandleProps {
  data: TicketViewData;
  onSubmit?: (value: SubmitValue) => void;
  refetch?: () => void;
  patientInputDisable: boolean;
  onResolve?: () => void;
}

type Mode = EditTicketMode;

export const TicketViewComponent = ({
  data,
  onSubmit,
  refetch,
  handleDeleteComment,
  handleEditComment,
  patientInputDisable,
  onResolve,
}: ViewTicketComponentProps) => {
  const { ticketInfo, comments } = data;
  const [mode, setMode] = useState<Mode>('View');

  const handleCancelMode = () => {
    setMode('View');
  };

  const setToOpenTicketMode = () => {
    setMode('ReOpen');
  };

  const setToEditTicketMode = () => {
    setMode('Edit');
  };

  if (mode !== 'View') {
    return (
      <EditTicketContainer
        ticket={ticketInfo}
        onCancel={handleCancelMode}
        refetch={refetch}
        title={mode === 'Edit' ? 'Edit Task' : 'Reopen Task'}
        mode={mode}
        patientInputDisable={patientInputDisable}
      />
    );
  }

  return (
    <FixedComponent>
      <FixedComponent.Child>
        <div className="ticket-ant-drawer-body">
          <TicketInfoComponent
            priorityLevel={ticketInfo.priorityLevel ?? undefined}
            ticketNumberDisplay={ticketInfo.ticketNumberDisplay ?? undefined}
            patient={AccountHelper.getFullName(ticketInfo.patientUser)}
            dueDate={ticketInfo.dueDate ?? undefined}
            creator={AccountHelper.getFullName(ticketInfo.createdByUser)}
            assignee={AccountHelper.getFullName(ticketInfo.assignedToUser)}
            description={ticketInfo.description ?? undefined}
            memberId={ticketInfo.memberId ?? undefined}
          />
          <TicketTitleValueItemComponent title="Comments" />
          <TicketCommentListComponent
            comments={comments || []}
            handleDeleteComment={handleDeleteComment}
            handleEditComment={handleEditComment}
          />
        </div>
        <div className="mb70">
          <TicketActivityHistoryComponent history={ticketInfo.histories || []} />
        </div>
      </FixedComponent.Child>
      <FixedComponent.Child isFixed>
        { ticketInfo.status === TicketStatusEnum.OPEN
          ? (
            <TicketResolveAndCommentFormComponent
              onEditClick={setToEditTicketMode}
              onSubmit={onSubmit}
              onResolve={onResolve}
            />
          ) : (
            <div className="flex jc-sb ai-c p20">
              <div>This task has been resolved.</div>
              <Button onClick={setToOpenTicketMode} type="primary" ghost>Reopen this task</Button>
            </div>
          )}
      </FixedComponent.Child>
    </FixedComponent>
  );
};
