import { useRef } from 'react';
import { useOnClickOutside as tsHook } from 'usehooks-ts';

export const useOnClickOutside = (
  onClickOutside: () => void,
) => {
  const ref = useRef(null);

  tsHook(ref, onClickOutside);

  return { ref };
};
