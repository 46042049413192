import {
  Button,
  Dropdown,
  DropdownProps,
  MenuProps,
  Tooltip,
} from 'antd';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { map } from 'lodash';
import classNames from 'classnames';
import { Icons } from '../../../../icons/Icons';
import { useRecentPatientsContext } from '../../context/RecentPatientsContext/RecentPatientsContext';
import useChangePage from '../../../../hooks/useChangePage/useChangePage';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';

export interface Value {
  recentPatients: string[];
  addRecentPatient: (patientId: string) => void;
}

export interface RecentPatientsComponentProps {
 className?: string;
}

export const RecentPatientsComponent = ({
  className = '',
}: RecentPatientsComponentProps) => {
  const { isOpen: isDropdownOpen, setValue: dropdownOpenChange } = useOpen();
  const { isOpen: isTooltipOpen, setValue: tooltipOpenChange } = useOpen();
  const { createCarePortalPatientCareUrl } = useChangePage();
  const { info } = usePatientContext();
  const { recentPatients, addRecentPatient } = useRecentPatientsContext();

  useEffect(() => {
    if (info?.id && info?.patientInfoService?.getFullNameWithNickname()) {
      addRecentPatient({
        id: info?.id,
        name: info?.patientInfoService?.getFullNameWithNickname() || '',
      });
    }
  }, [info?.patientInfo]);

  const items: MenuProps['items'] = map(recentPatients, (rp): ItemType => ({
    key: rp.id,
    label: (
      <Link to={createCarePortalPatientCareUrl({ patientId: rp.id })}>
        {rp.name}
      </Link>
    ),
  }));

  const handleOpenDropdown: DropdownProps['onOpenChange'] = (v) => {
    dropdownOpenChange(v);
    if (v) {
      tooltipOpenChange(false);
    }
  };

  return (
    <Dropdown
      menu={{ items }}
      className={classNames({
        'recent-patients-component': true,
        [className]: !!className,
      })}
      trigger={['click']}
      onOpenChange={handleOpenDropdown}
      open={isDropdownOpen}
    >
      <Tooltip
        title="Recent Patients"
        placement="bottom"
        onOpenChange={tooltipOpenChange}
        open={isTooltipOpen}
      >
        <Button type="text">
          <Icons.RecentIcon />
        </Button>
      </Tooltip>
    </Dropdown>
  );
};
