import { ProgramCategorySearchFilterEnumComponent } from '../../../enumComponent/ProgramCategorySearchFilterEnumComponent/ProgramCategorySearchFilterEnumComponent';
import { ProgramCategorySearchFilterEnum } from '../../../uc-api-sdk';
import { RadioFilterComponent } from '../component/RadioFilterComponent/RadioFilterComponent';
import { TableFilterType } from '../type/filter';
import { FilterIcon } from './FilterIcon';

export const createEnrollProgramsCheckboxFilter = (): TableFilterType => {
  const options = Object.keys(ProgramCategorySearchFilterEnum).map((p) => ({
    label: (
      <ProgramCategorySearchFilterEnumComponent
        value={p as ProgramCategorySearchFilterEnum}
        type="short"
      />
    ),
    value: p,
  }));

  return ({
    filterDropdown: ((props) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <RadioFilterComponent {...props} filterOptions={options} />
    )),
    filterIcon: FilterIcon,
  });
};
