import { ReactNode } from 'react';
import classNames from 'classnames';
import { FileUploadMessagePayload } from '../../../../services/CHSServices/types/data';
import { ModalButton, ModalButtonProps } from '../../../../uiComponent/ModalButton/ModalButton';

import './MessageFileUploadItemComponent.scss';

export interface MessageFileUploadItemComponentProps extends Pick<FileUploadMessagePayload, 'text'> {
  children: ReactNode;
  button: ModalButtonProps['button'];
  className?: string;
}

export const MessageFileUploadItemComponent = ({
  children,
  text,
  button,
  className = '',
}: MessageFileUploadItemComponentProps) => (
  <div className="message-file-upload message-item__message-content message-item__message-highlight">
    <div>{text}</div>
    <ModalButton
      className={classNames({
        'message-file-upload__modal': true,
        [className]: !!className,
      })}
      buttonType="link"
      button={button}
      footer={null}
      width={800}
      destroyOnClose
    >
      {children}
    </ModalButton>
  </div>
);
