import { useUpdateEffect } from 'usehooks-ts';
import { useBillableTimeContext, Value } from '../../contexts/BillableTimeContext/BillableTimeContext';

export type OnChange = (isActive: boolean, contextValue: Value) => void;

export const useOnActiveStatusChange = (onChange: OnChange) => {
  const value = useBillableTimeContext();

  useUpdateEffect(() => {
    onChange(value.isActive, value);
  }, [
    value.isActive,
  ]);

  return value;
};
