import { ReactNode } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { ClickableDiv } from '../../../../../uiComponent/ClickableDiv/ClickableDiv';
import { PortalPlatform } from '../../../../../contexts/loggedInUserContext/types';
import { useLoggedInUserFromContext } from '../../../../../contexts/loggedInUserContext';
import useChangePage from '../../../../../hooks/useChangePage/useChangePage';

export interface LoggedInPlatformComponentProps {
  children: ReactNode;
  redirectTo: PortalPlatform;
}

export const LoggedInPlatformComponent = ({
  children,
  redirectTo,
}: LoggedInPlatformComponentProps) => {
  const { currentPlatform, setCurrentPlatform } = useLoggedInUserFromContext();
  const changePage = useChangePage();
  const isPlatformActive = currentPlatform === redirectTo;

  const handleOnClick = () => {
    if (isPlatformActive) return;
    setCurrentPlatform(redirectTo);
    changePage.goToPlatform(redirectTo);
  };

  return (
    <ClickableDiv
      className={classNames({
        'logged-in-platform': true,
        'flex ai-c jc-sb': isPlatformActive,
      })}
      onClick={handleOnClick}
    >
      {children}
      {
        isPlatformActive
        && (<CheckOutlined />)
      }
    </ClickableDiv>
  );
};
