import { useMemo } from 'react';
import {
  GetOptions, MakeRequestHookConfig, UseRequestFactory, useRequestFactory,
} from '../../../uc-api-sdk/staticFiles/useReqHook';
import { chsServices } from '../CHSServices';
import { InitChannelParams, InitChannelResponseData } from '../types';

// to initiate subscription for channel whose patient is not assigned to user
export const useInitChannel = <RCN = undefined>(
  configs?: MakeRequestHookConfig<InitChannelParams, GetOptions, RCN>,
) => {
  const baseConfigs: UseRequestFactory<
    InitChannelParams,
    InitChannelResponseData,
    typeof chsServices.initChannel,
    GetOptions,
    InitChannelResponseData
  > = useMemo(() => ({
    reqFunc: (...args: Parameters<typeof chsServices.initChannel>) => (
      chsServices.initChannel(...args)
    ),
    ResponseClassCreator: chsServices.getResponse,
    typeName: 'useInitChannel',
  }), []);

  return useRequestFactory(baseConfigs, configs);
};
