import { useEnrollmentStepContext } from '../../context/EnrollmentStepContext/EnrollmentStepContext';
import { EnrollmentStepsComponent } from './EnrollmentStepsComponent';

export interface EnrollmentStepsWithContextProps {

}

export const EnrollmentStepsWithContext = () => {
  const {
    currStep,
    goTo,
    completedSteps,
  } = useEnrollmentStepContext();

  return (
    <EnrollmentStepsComponent
      value={currStep}
      onChange={goTo}
      completedSteps={completedSteps}
    />
  );
};
