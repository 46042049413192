import Input, { TextAreaProps } from 'antd/lib/input';
import { FC } from 'react';

export interface CareNoteContentComponentProps {
  value?: string;
  onChange?: TextAreaProps['onChange'];
}
const { TextArea } = Input;

export const CareNoteContentComponent: FC<CareNoteContentComponentProps> = ({
  value,
  onChange,
}) => (
  <TextArea
    value={value}
    onChange={onChange}
    autoSize={{ minRows: 15 }}
  />
);
