import { Point } from '../../type';

interface BGLineChart {
  averageBeforeMeal: Point[];
  averageAfterMeal: Point[];
  averageBeforeBreakfast: Point[];
  averageOvernightBedtime: Point[];
  onAverageBeforeMealClick: () => void;
  onAverageAfterMealClick: () => void;
  onAverageBeforeBreakfastClick: () => void;
  onAverageOvernightBedtimeClick: () => void;
}

export const createBGChartSeries = (info: BGLineChart) => ([
  {
    data: info.averageBeforeMeal,
    name: 'Average Before Meal',
    type: 'line',
    color: '#e0974e',
    marker: {
      enabled: false,
    },
    events: {
      legendItemClick() {
        info.onAverageBeforeMealClick();
        return true;
      },
    },
  },
  {
    data: info.averageAfterMeal,
    name: 'Average After Meal',
    type: 'line',
    color: '#1fb5ac',
    marker: {
      enabled: false,
    },
    events: {
      legendItemClick() {
        info.onAverageAfterMealClick();
        return true;
      },
    },
  },
  {
    data: info.averageBeforeBreakfast,
    name: 'Average Before Breakfast',
    type: 'line',
    color: '#e8c441',
    marker: {
      enabled: false,
    },
    events: {
      legendItemClick() {
        info.onAverageBeforeBreakfastClick();
        return true;
      },
    },
  },
  {
    data: info.averageOvernightBedtime,
    name: 'Average Overnight/Bedtime',
    type: 'line',
    color: '#186dd3',
    marker: {
      enabled: false,
    },
    events: {
      legendItemClick() {
        info.onAverageOvernightBedtimeClick();
        return true;
      },
    },
  },
]);
