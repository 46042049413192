import { first, isEmpty } from 'lodash';
import { EligibilityReport } from '../../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import { InsuranceEligibilityHistoryModalComponent } from '../InsuranceEligibilityHistoryModalComponent/InsuranceEligibilityHistoryModalComponent';

export interface InsuranceEligibilityInfoComponentProps {
  eligibilityReports?: EligibilityReport[];
}

export const InsuranceEligibilityInfoComponent = ({
  eligibilityReports,
}: InsuranceEligibilityInfoComponentProps) => {
  const lastCheckedTime = first(eligibilityReports)?.updatedAt;

  if (isEmpty(eligibilityReports)) {
    return null;
  }
  return (
    <div className="flex ai-c gap1">
      <div>
        Eligibility last checked:
        {' '}
        <DisplayDateComponent
          value={lastCheckedTime}
          format="USA_DATE"
        />
      </div>
      <InsuranceEligibilityHistoryModalComponent
        eligibilityReports={eligibilityReports}
      />
    </div>
  );
};
