import { EyeFilled } from '@ant-design/icons';
import './EmptyWatchedListComponent.scss';

export interface EmptyWatchedListComponentProps {

}

export const EmptyWatchedListComponent = () => (
  <div className="flex h100 jc-c ai-c fd-c emptyWatchList">
    <EyeFilled />
    <p className="mt10">
      On patient page, use the "add patient to
      watchlist" button to add patients to here.
    </p>
  </div>
);
