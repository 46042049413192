import { UploadOutlined } from '@ant-design/icons';
import { message, Upload, UploadProps } from 'antd';
import {
  compact,
  join,
  map,
  split,
} from 'lodash';
import { useCallback, useMemo } from 'react';

import './DragDropUploadComponent.scss';
import { RcFile } from 'antd/lib/upload';

export interface DragDropUploadComponentProps {
  accept: string; // file type
  fileList?: UploadProps['fileList'];
  onChange?: (fileList: RcFile[]) => void;
  children?: React.ReactNode; // upload description
  fileMaxSize?: number; // file size, MB
  multiple?: UploadProps['multiple'];
  itemRender?: UploadProps['itemRender'];
  action?: UploadProps['action'];
  beforeUpload?: UploadProps['beforeUpload'];
}

export const DragDropUploadComponent = ({
  accept,
  fileList,
  onChange,
  children,
  fileMaxSize = 5,
  multiple = true,
  itemRender,
  action,
  beforeUpload,
}: DragDropUploadComponentProps) => {
  const validateSize = useCallback((file: File) => {
    if (!fileMaxSize) return true;
    return file.size / 1024 / 1024 < fileMaxSize;
  }, [fileMaxSize]);

  const acceptText = useMemo(() => {
    const acceptList = split(accept, ',');
    const textList = map(acceptList, (accept) => {
      switch (accept.trim()) {
        case 'image/jpeg':
          return 'JPG';
        case 'image/png':
          return 'PNG';
        case 'application/pdf':
          return 'PDF';
        default:
          return 'UNKNOWN';
      }
    });
    const textListString = join(textList, ', ');
    return textListString;
  }, [accept]);

  const renderDescription = useCallback(() => {
    if (children) return children;
    return (
      <>
        <div className="drag-drop-upload__icon">
          <UploadOutlined />
        </div>
        <div className="drag-drop-upload__text">
          Click or drag file to this area to upload
        </div>
        <div className="drag-drop-upload__hint">
          Files supported:
          {' '}
          {acceptText}
          {' '}
          (
          max
          {' '}
          {fileMaxSize}
          MB each
          )
        </div>
      </>
    );
  }, [children, acceptText]);

  const handleBeforeUpload = useCallback((
    file: RcFile,
    fileList: RcFile[]
  ) => {
    const validFileList = [] as RcFile[];
    fileList.forEach((file) => {
      if (validateSize(file)) {
        validFileList.push(file);
      } else {
        message.error(`File size exceeds ${fileMaxSize}MB`);
      }
    });
    if (validFileList.length === 0) {
      return false;
    }
    if (typeof action === 'function') {
      action(file);
      return false;
    }
    if (beforeUpload) {
      return beforeUpload(file, validFileList);
    }
    return false;
  }, []);

  const handleOnChange: UploadProps['onChange'] = (
    info
  ) => {
    const { fileList } = info;
    const rcFileList = map(fileList, (file) => (
      file.originFileObj as RcFile || file
    ));
    onChange?.(compact(rcFileList));
  };

  return (
    <Upload.Dragger
      className="drag-drop-upload"
      fileList={fileList}
      accept={accept}
      action={action}
      beforeUpload={handleBeforeUpload}
      itemRender={itemRender}
      multiple={multiple}
      onChange={handleOnChange}
    >
      {renderDescription()}
    </Upload.Dragger>
  );
};
