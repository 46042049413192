import { isEmpty, map } from 'lodash';
import { Divider } from 'antd';
import { PriorAuthCase } from '../../../../uc-api-sdk';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { PriorAuthViewCardComponent } from '../PriorAuthLayout/PriorAuthViewCardComponent';

import './PriorAuthHistoryComponent.scss';

export interface PriorAuthHistoryComponentProps {
  priorAuthCases?: PriorAuthCase[];
  showHeader?: boolean;
}

export const PriorAuthHistoryComponent = ({
  priorAuthCases,
  showHeader = true,
}: PriorAuthHistoryComponentProps) => {
  if (isEmpty(priorAuthCases)) {
    return null;
  }
  return (
    <div className="prior-auth-history">
      {
        showHeader
        && (
          <div>
            <Divider />
            <TextComponent>
              History
            </TextComponent>
          </div>
        )
      }
      <div className="prior-auth-history-scroll">
        {
          map(priorAuthCases, (priorAuthCase, idx) => (
            <div
              key={idx}
              className="mt10"
            >
              <PriorAuthViewCardComponent
                priorAuth={priorAuthCase}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
};
