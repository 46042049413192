import { Select } from 'antd';
import { FC } from 'react';
import { SelectType } from '../../../../features/Input/types';
import EmployeeInfo from '../../../../hooks/useUserInfo/employeeInfo';

export interface ProviderSelectComponentProps extends SelectType<string> {
  providers: EmployeeInfo[];
  placeholder?: string;
  allowClear?: boolean;
  disabled?: boolean;
  isSelectedPatient?: boolean;
  unassignedOnly?: boolean;
}

export const ProviderSelectComponent: FC<ProviderSelectComponentProps> = ({
  loading,
  providers,
  value,
  placeholder,
  allowClear,
  disabled,
  isSelectedPatient,
  unassignedOnly,
  onChange,
  showSearch,
}) => {
  const option = [
    ...(isSelectedPatient && unassignedOnly) ? [{
      label: 'Unassign',
      value: 'unassign',
    }] : [],
    ...providers.map((p) => ({
      label: `${p.fullNameWithAllRoles}`,
      value: p.id,
    }))
  ];

  return (
    <Select
      loading={loading}
      options={option}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      allowClear={allowClear}
      disabled={disabled}
      showSearch={showSearch}
      optionFilterProp="label"
    />
  );
};
