import { Anchor, AnchorProps } from 'antd';
import { last, map } from 'lodash';
import { ReactNode, useRef, useState } from 'react';
import { useAnchor } from './AnchorContext';
import './AnchorComponent.scss';
import { useResizeObserver, Size } from '../../uiHooks/useResizeObserver/useResizeObserver';
import useDebounce from '../../hooks/useDebounce/useDebounce';

export interface AnchorChildComponentProps {
  children: ReactNode;
  offsetTop?: AnchorProps['offsetTop'];
  targetOffset?: AnchorProps['targetOffset'];
}

export const AnchorChildComponent = ({
  children,
  offsetTop,
  targetOffset,
}: AnchorChildComponentProps) => {
  const { anchors, anchorOrder } = useAnchor();
  const ref = useRef<HTMLDivElement>(null);
  const [{ height: anchorHeight = 0 }, setSize] = useState<Size>({
    width: undefined,
    height: 0,
  });

  const onResize = useDebounce(setSize, 200);

  useResizeObserver({
    ref,
    onResize,
  });

  const anchorChildrenClassName = 'anchor-component';
  const lastChild = last(document.getElementsByClassName('anchor-link-component'));
  const lastChildHeight = lastChild?.clientHeight || anchorHeight || 0;
  const emptyChildHeight = lastChildHeight < anchorHeight ? anchorHeight - lastChildHeight : 0;

  return (
    <div className={anchorChildrenClassName}>
      <div className="anchor-component__anchor" ref={ref}>
        <Anchor
          offsetTop={offsetTop}
          targetOffset={targetOffset}
          getContainer={() => (
            document.querySelector(`.${anchorChildrenClassName}`) as HTMLElement
          )}
        >
          {map(anchorOrder, (v) => (
            <Anchor.Link
              key={anchors[v].key.toLowerCase()}
              title={anchors[v].title}
              href={anchors[v].href.toLowerCase()}
            />
          ))}
        </Anchor>
      </div>
      <div className="anchor-component__children">
        {children}
        <div style={{ height: emptyChildHeight }} />
      </div>
    </div>
  );
};
