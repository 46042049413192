import { Col, Form } from 'antd';
import { keyBy, map } from 'lodash';
import { FormType } from '../../../Input/types';
import { ProgramEnrollmentSubmitValue, useProfileProgramEnrollmentForm } from '../../hook/useProfileProgramEnrollmentForm';
import { PatientProfileProgramEnrollmentFormItemComponent } from '../PatientProfileProgramEnrollmentFormItemComponent/PatientProfileProgramEnrollmentFormItemComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { ProgramCategoryEnum } from '../../../../uc-api-sdk';
import CancelSubmitButtonsComponent from '../../../../uiComponent/CancelSubmitButtonsComponent/CancelSubmitButtonsComponent';
import { PatientProfileDateDisplayComponent } from '../PatientProfileDateDisplayComponent/PatientProfileDateDisplayComponent';

export interface PatientProfileProgramEnrollmentFormComponentProps
  extends FormType<ProgramEnrollmentSubmitValue> {
  patientClinicProgramParticipation: ProgramCategoryEnum[];
}

export const PatientProfileProgramEnrollmentFormComponent = ({
  onSubmit,
  initialValues,
  isLoading,
  onCancel,
  onValuesChange,
  formButtons = <CancelSubmitButtonsComponent onCancel={onCancel} />,
  patientClinicProgramParticipation,
}: PatientProfileProgramEnrollmentFormComponentProps) => {
  const profileProgramEnrollmentForm = useProfileProgramEnrollmentForm();

  if (!patientClinicProgramParticipation.length) {
    return (
      <div>
        Patient's clinic doesn't have any program participation
      </div>
    );
  }

  return (
    <Form
      form={profileProgramEnrollmentForm.form}
      name={profileProgramEnrollmentForm.formName}
      onFinish={profileProgramEnrollmentForm.handleSubmit(onSubmit)}
      disabled={isLoading}
      initialValues={{ programs: keyBy(initialValues?.programs, 'category') }}
      onValuesChange={profileProgramEnrollmentForm.onValuesChange(onValuesChange)}
      className="ant-form-item-no-margin"
      preserve
    >
      <FormItem
        name={profileProgramEnrollmentForm.getMainFormItemName()}
        noStyle
        shouldUpdate
      >
        {
          map(ProgramCategoryEnum, (p: ProgramCategoryEnum, idx: number) => {
            const formField = {
              name: p,
              key: idx,
              isListField: true,
            };

            if (!patientClinicProgramParticipation.includes(p)) return null;

            return (
              <div className="flex ai-bl jc-sb">
                <Col span={16}>
                  <PatientProfileProgramEnrollmentFormItemComponent
                    category={p}
                    formField={formField}
                  />
                </Col>
                <Col span={8}>
                  <FormItem noStyle shouldUpdate>
                    {
                      ({ getFieldValue }) => {
                        const hasCategory = profileProgramEnrollmentForm.getObjectValue('category', getFieldValue, formField.name);
                        const createdAt = profileProgramEnrollmentForm.getObjectValue('createdAt', getFieldValue, formField.name);
                        return (
                          <FormItem
                            name={profileProgramEnrollmentForm.makeObjectNamePath('createdAt', formField.name)}
                            hidden={!hasCategory || !createdAt}
                          >
                            <PatientProfileDateDisplayComponent
                              value={createdAt}
                              label="Enrolled on"
                            />
                          </FormItem>
                        );
                      }
                    }
                  </FormItem>
                </Col>
              </div>
            );
          })
        }
      </FormItem>
      {formButtons}
    </Form>
  );
};
