import { CalendarSourceEnum, VisitTypeEnum } from '../../uc-api-sdk';
import EmptyComponent from '../../uiComponent/EmptyComponent/EmptyComponent';

export interface VisitTypeEnumComponentProps {
  value: VisitTypeEnum | undefined | null | CalendarSourceEnum;
  type?: 'short' | 'long';
}

export const VisitTypeEnumComponent = ({
  value,
  type = 'long',
}: VisitTypeEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case VisitTypeEnum.ADDITIONAL: return 'Additional Visit';
      case VisitTypeEnum.ANNUAL: return 'Annual';
      case VisitTypeEnum.CLINIC_FOLLOW_UP: return 'Clinic Follow Up';
      case VisitTypeEnum.FOLLOW_UP: return 'Follow-Up Visit';
      case VisitTypeEnum.MNT: return 'MNT Visit';
      case VisitTypeEnum.TECH: return 'Tech Support';
      case VisitTypeEnum.INIT: return 'Initial Visit';
      case VisitTypeEnum.TECH_ONBOARDING: return 'Tech-onboarding';
      case CalendarSourceEnum.OTHER: return 'Other Event';
      default: return <EmptyComponent />;
    }
  };

  const getShortText = () => {
    switch (value) {
      case VisitTypeEnum.ADDITIONAL: return 'Additional';
      case VisitTypeEnum.ANNUAL: return 'Annual';
      case VisitTypeEnum.CLINIC_FOLLOW_UP: return 'Clinic Follow-up';
      case VisitTypeEnum.FOLLOW_UP: return 'Follow-up';
      case VisitTypeEnum.MNT: return 'MNT';
      case VisitTypeEnum.TECH: return 'Tech Support';
      case VisitTypeEnum.INIT: return 'Initial Assessment';
      case VisitTypeEnum.TECH_ONBOARDING: return 'Tech Onboarding';
      case CalendarSourceEnum.OTHER: return 'Other Event';
      default: return <EmptyComponent />;
    }
  };

  if (type === 'short') {
    return (
      <span>{getShortText()}</span>
    );
  }

  return (
    <span>{getText()}</span>
  );
};
