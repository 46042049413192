import { ColumnType } from 'antd/es/table';
import { ReactNode } from 'react';
import { TimeOfDay } from '../../../../helpers/timezone/timezoneService';
import { measurementTableDateColumnMaker } from '../MeasurementTable/MeasurementTableColumn';
import { TimeOfDayTableDataType } from './TimeOfDayTableComponent';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';

export type TimeOfDayTableColumnRender<T> = ((v: T[]) => ReactNode);

const createTimeOfDayColumn = <C, T extends TimeOfDayTableDataType<C>>(
  title: ReactNode,
  key: TimeOfDay,
  render: TimeOfDayTableColumnRender<C>,
) => (): ColumnType<T> => ({
    title,
    key,
    render: (__value, rec) => {
      const data = rec.data[key];
      return (data && data.length > 0 ? render(data) : null);
    },
    width: '20%',
  });

const createTimeOfDayTitle = (
  title: ReactNode,
  timeframe: [number, number]
) => (
  <TooltipComponent
    type="dashed"
    title={`${timeframe[0]}:00 - ${timeframe[1]}:00`}
  >
    {title}
  </TooltipComponent>
);

export const TimeOfDayTableColumns = <T extends object>(
  renderer: TimeOfDayTableColumnRender<T>
) => ({
    date: measurementTableDateColumnMaker('11%'),
    morning: createTimeOfDayColumn(createTimeOfDayTitle('Morning', [4, 12]), 'MORNING', renderer),
    afternoon: createTimeOfDayColumn(createTimeOfDayTitle('Afternoon', [12, 18]), 'AFTERNOON', renderer),
    evening: createTimeOfDayColumn(createTimeOfDayTitle('Evening', [18, 24]), 'EVENING', renderer),
    overnight: createTimeOfDayColumn(createTimeOfDayTitle('Overnight', [24, 4]), 'OVERNIGHT', renderer),
  });
