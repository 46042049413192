import Title from 'antd/lib/typography/Title';
import dayjs from 'dayjs';
import { FC } from 'react';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import TimezoneService from '../../../../helpers/timezone/timezoneService';
import { CareNote } from '../../../../uc-api-sdk';
import { AnchorLinkComponent } from '../../../../uiComponent/AnchorComponent/AnchorLinkComponent';
import { useCareNoteAnchorIdHelper } from '../../hook/anchors/useCareNoteAnchors';
import { CareNoteMapType } from '../../types/types';
import { CareNoteCardComponent } from '../CareNoteCardComponent/CareNoteCardComponent';
import './CareNoteListWithDateComponent.scss';

export interface CareNoteListWithDateProps {
  notes: CareNote[];
  year: number;
}

export const CareNoteListWithDateComponent: FC<CareNoteListWithDateProps> = ({
  notes,
  year,
}) => {
  const currentYear = dayjs().year();
  const { info } = usePatientContext();
  const timezone = info?.patientInfoService?.getTimezone();

  const monthMap = notes.reduce((current: CareNoteMapType, note) => {
    const month = TimezoneService.calcDateTimeDayjs(note.createdAt || '', timezone).format('MMM');
    if (month in current) {
      current[month].push(note);
    } else {
      return { ...current, [month]: [note] };
    }
    return current;
  }, {});

  const renderNotes = (notes: CareNote[]) => notes.map((note) => (
    <CareNoteCardComponent
      note={note}
      key={note.id}
    />
  ));

  const renderMonthlyNotes = () => Object.keys(monthMap).map((month) => {
    const itemId = useCareNoteAnchorIdHelper(month, currentYear);
    return (
      <div key={itemId}>
        <AnchorLinkComponent
          title={itemId}
          id={itemId}
          href={`#${itemId}`}
        >
          <Title
            level={5}
            className="month-title"
          >
            {month}
          </Title>
          <div className="flex gap2 fd-c">
            {
              renderNotes(monthMap[month])
            }
          </div>
        </AnchorLinkComponent>
      </div>
    );
  });

  const renderYearlyNotes = () => Object.keys(monthMap).map((month) => (
    <div className="flex gap2 fd-c" key={month}>
      {
            renderNotes(monthMap[month])
          }
    </div>
  ));

  if (Number(year) === currentYear) {
    return (
      <div className="monthly flex fd-c">
        {renderMonthlyNotes()}
      </div>
    );
  }

  const yearId = `${year}-section`;
  return (
    <div className="my20">
      <AnchorLinkComponent
        title={String(year)}
        id={yearId}
        href={`#${yearId}`}
      >
        <Title
          level={4}
          className="year-title"
        >
          {year}
        </Title>
        <div className="flex gap2 fd-c">
          {renderYearlyNotes()}
        </div>
      </AnchorLinkComponent>
    </div>
  );
};
