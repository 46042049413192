import { forEach } from 'lodash';
import { useCallback, useState } from 'react';
import { PopupArg } from './types';

export interface PopupValue<T extends PopupArg> {
  isOpen?: boolean;
  args?: T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Popup<K extends string> = Record<K, PopupValue<any>>;

export const usePopups = <K extends string>(v: K[] | Record<string, K>) => {
  const [popups, setPopups] = useState<Popup<K>>(() => {
    const initialPopups = {} as Popup<K>;
    forEach(v, (key) => {
      initialPopups[key as K] = {
        isOpen: false,
        args: undefined,
      };
    });
    return initialPopups;
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const open = useCallback((type: K, args?: any) => {
    setPopups((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        isOpen: true,
        args,
      },
    }));
  }, [setPopups]);

  const close = useCallback((type: K) => {
    setPopups((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        isOpen: false,
      },
    }));
  }, [setPopups]);

  return {
    popups,
    open,
    close,
  };
};
