import { createElement } from 'react';
import './TextWithLinkComponent.scss';

const lessThanPlaceholder = '[lt]';
const greaterThanPlaceholder = '[gt]';

export interface TextWithLinkComponentProps {
  value: string;
}

export const TextWithLinkComponent = ({
  value,
}: TextWithLinkComponentProps) => {
  const replaceUnwantedCharacters = (value: string) => {
    // correct known character that creates element
    // url doesn't contain < and >, so it's safe to replace them
    let newValue = value;
    if (value) {
      newValue = value?.replaceAll('<', lessThanPlaceholder);
      newValue = newValue.replaceAll('>', greaterThanPlaceholder);
    }

    return newValue;
  };

  const isHTML = () => {
    try {
      const newValue = replaceUnwantedCharacters(value);
      const doc = new DOMParser().parseFromString(newValue, 'text/html');
      return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const parseClickableUrl = () => {
    if (!value) return null;
    try {
      const newValue = replaceUnwantedCharacters(value);
      const urlRegEx = /http[s]?:\/\/\S+/gi;
      const htmlString = newValue.replace(urlRegEx, (
        url,
      ) => (
        `<a href='${url}' target='_blank'>${url}</a>`
      ));

      const htmlDocument = new DOMParser().parseFromString(htmlString, 'text/html');
      const { childNodes } = htmlDocument.body;
      const nodes = Array.from(childNodes).map((childNode, index) => {
        if ((childNode as HTMLAnchorElement).href) {
          return createElement(
            childNode.nodeName?.toLowerCase(),
            {
              key: (index + 1).toString(),
              href: (childNode as HTMLAnchorElement).href,
              target: (childNode as HTMLAnchorElement).target,
              className: 'text-with-link',
              'data-testid': 'textWithLink'
            },
            (childNode as HTMLElement).innerHTML,
          );
        }
        let text = (childNode as Text).data || '?';
        text = text.replaceAll(lessThanPlaceholder, '<');
        text = text.replaceAll(greaterThanPlaceholder, '>');
        return text;
      });
      return nodes;
    } catch (error) {
      console.error(error);
    }
    // fallback if anything goes wrong
    return value;
  };

  if (isHTML()) {
    return (
      <div
        className="text-with-link"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: value }}
      />
    );
  }

  return (
    <>
      {parseClickableUrl()}
    </>
  );
};
