import { Divider } from 'antd';
import { isEmpty } from 'lodash';
import DisplayOrEmptyComponent from '../../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';
import { PrintCarePlanSimpleItemComponent } from '../PrintCarePlanSimpleItemComponent/PrintCarePlanSimpleItemComponent';
import { Patient } from '../../../../../uc-api-sdk';

export interface PrintCarePlanSocialSupportComponentProps {
  profile?: Patient['profile'];
}

export const PrintCarePlanSocialSupportComponent = ({
  profile,
}: PrintCarePlanSocialSupportComponentProps) => {
  if (isEmpty(profile)) {
    return null;
  }
  return (
    <>
      <div className="page-break" />
      <TextComponent className="pop-text">
        Social Support
      </TextComponent>
      <PrintCarePlanSimpleItemComponent
        label="Community/ Social Support"
        content={<DisplayOrEmptyComponent value={profile?.communitySupport} />}
      />
      <PrintCarePlanSimpleItemComponent
        label="Contact information"
        content={<DisplayOrEmptyComponent value={profile?.contactInfo} />}
      />
      <PrintCarePlanSimpleItemComponent
        label="Other Providers"
        content={<DisplayOrEmptyComponent value={profile?.otherProviders} />}
      />
      <Divider />
    </>
  );
};
