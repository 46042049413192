export interface PauseServiceNamesComponentProps {
  pauseVitalMonitoring?: boolean;
  pauseVisitService?: boolean;
  pauseBilling?: boolean;
}

export const PauseServiceNamesComponent = ({
  pauseVitalMonitoring,
  pauseVisitService,
  pauseBilling,
}: PauseServiceNamesComponentProps) => {
  const services = () => {
    const services: string[] = [];
    if (pauseVitalMonitoring) {
      services.push('Vitals Monitoring');
    }
    if (pauseVisitService) {
      services.push('Visit Service');
    }
    if (pauseBilling) {
      services.push('Billing Report');
    }
    return services;
  };

  return (
    <>
      {services().join(', ')}
    </>
  );
};
