import { RangePickerProps } from 'antd/lib/date-picker';
import moment, { Moment } from 'moment';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useLoggedInUserFromContext } from '../../../contexts/loggedInUserContext';
import { useTableChange } from '../../../hooks/useTableChange/useTableChange';
import {
  CalendarSourceEnum,
  ClinicEvent,
  Sort$Direction,
  VisitMethodEnum,
  VisitTypeEnum
} from '../../../uc-api-sdk';
import { BaseSearchParams } from '../../../uc-api-sdk/staticFiles/useReqHook';
import { RangeValue } from '../../patient/component/PauseServiceComponent/PauseServiceRangePickerComponent';

const defaultSort = () => [
  {
    direction: Sort$Direction.ASC,
    property: 'startTime',
  }
];

export interface VisitsAndEventsContextProviderProps {
  children: React.ReactNode;
}
export interface VisitsAndEventsContextValue {
  clinic: string | undefined;
  setClinic: (clinicId: string) => void;
  dateRange: [Moment, Moment];
  setDateRange: (dateRange: [Moment, Moment]) => void;
  handleRangeChange: RangePickerProps['onChange'];
  tableChange: ReturnType<typeof useTableChange<ClinicEvent, ClinicEvent>>;
}

export const VisitsAndEventsContext = createContext<VisitsAndEventsContextValue>({
  clinic: undefined,
  setClinic: () => Promise.reject(new Error('Not implemented yet!')),
  dateRange: [moment().startOf('D'), moment().endOf('D')],
  setDateRange: () => Promise.reject(new Error('Not implemented yet!')),
  handleRangeChange: () => Promise.reject(new Error('Not implemented yet!')),
  tableChange: {} as ReturnType<typeof useTableChange<ClinicEvent, ClinicEvent>>,
});

export const VisitsAndEventsContextProvider = ({
  children
}: VisitsAndEventsContextProviderProps) => {
  const { userInfo } = useLoggedInUserFromContext();
  const { defaultClinicId } = userInfo || {};
  const [clinic, setClinic] = useState<string | undefined>(defaultClinicId);
  const [
    dateRange,
    setDateRange
  ] = useState<[Moment, Moment]>([moment().startOf('D'), moment().endOf('D')]);

  const handleRangeChange: RangePickerProps['onChange'] = useCallback((dates: RangeValue) => {
    setDateRange([dates?.[0] || moment(), dates?.[1]?.endOf('D') || moment()]);
  }, []);

  const tableChange = useTableChange<ClinicEvent, ClinicEvent>({
    name: 'VisitsAndEventsTable',
    sort: defaultSort(),
    sortProcessor: defaultSort, // table doesn't have sorters
    filterProcessor: (filters) => {
      const visitSource: CalendarSourceEnum[] = [];
      const fil: BaseSearchParams<ClinicEvent>['filter'] = {};
      if (filters.patient && filters.patient?.length > 0) {
        fil.patientId = filters.patient?.[0] as string; // backend not ready for filters
      }
      if (filters.doctorId) {
        fil.providerId = filters.doctorId?.[0] as string;
      }
      if (filters.visit && filters.visit?.length > 0) {
        if (filters.visit.includes(CalendarSourceEnum.OTHER)) {
          visitSource.push(CalendarSourceEnum.OTHER);
        }
        const visitTypes = filters.visit?.filter(
          (v) => v !== CalendarSourceEnum.OTHER
            && !Object.keys(VisitMethodEnum).includes(v as string)
        );

        if (visitTypes.length > 0) {
          fil.visitTypeIn = {
            in: visitTypes as VisitTypeEnum[]
          };
        }

        const visitMethods = filters.visit?.filter(
          (v) => Object.keys(VisitMethodEnum).includes(v as string)
        );
        if (visitMethods.length > 0) {
          // @ts-ignore
          fil.visitMethodIn = {
            in: visitMethods as VisitMethodEnum[]
          };
        }
        if (visitTypes.length > 0 || visitMethods.length > 0) {
          visitSource.push(CalendarSourceEnum.UC_VISIT);
        }

        if (visitSource.length > 0) {
          fil.calendarSourceIn = {
            in: visitSource
          };
          fil.myCalendarSourceIn = {
            in: visitSource
          };
        }
      }

      if (filters.visitParticipants && filters.visitParticipants?.length > 0) {
        fil.participantsIn = {
          in: filters.visitParticipants as string[]
        };
      }

      return fil;
    }
  });

  useEffect(() => {
    if (clinic) {
      tableChange.reset();
    }
  }, [clinic]);

  const value = useMemo(() => ({
    clinic,
    setClinic,
    dateRange,
    setDateRange,
    handleRangeChange,
    tableChange,
  }), [clinic, dateRange, tableChange]);

  return (
    <VisitsAndEventsContext.Provider value={value}>
      {children}
    </VisitsAndEventsContext.Provider>
  );
};

export const useVisitsAndEventsContext = () => {
  const context = useContext(VisitsAndEventsContext);
  return context;
};
