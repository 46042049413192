import { HiatusStatusReasonEnum } from '../../uc-api-sdk';
import { ListComponent, ListComponentProps } from '../../uiComponent/ListComponent/ListComponent';
import { HiatusReasonEnumComponent } from './HiatusReasonEnumComponent';

export interface HiatusReasonEnumListComponentProps {
  value: HiatusStatusReasonEnum[];
  splitter?: ListComponentProps<HiatusStatusReasonEnum>['splitter'];
}

export const HiatusReasonEnumListComponent = ({
  value,
  splitter,
}: HiatusReasonEnumListComponentProps) => (
  <ListComponent
    value={value}
    splitter={splitter}
    renderItem={(v) => (
      <HiatusReasonEnumComponent
        value={v}
      />
    )}
  />
);
