import {
  sortBy, toLower, reverse,
} from 'lodash';
import { LimitedUser, Sort$Direction } from '../../uc-api-sdk';

export class CoWorkerHelpers {
  static sortCoWorkerByName = (
    coworkerList: LimitedUser[],
    order: `${Sort$Direction}` = 'ASC',
  ) => {
    let sorted = sortBy(
      coworkerList,
      (cw) => toLower(cw.lastName || '--'),
    );

    if (order === 'DESC') {
      sorted = reverse(sorted);
    }

    return sorted;
  };
}
