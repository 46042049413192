import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { AccountHelper } from '../../../../helpers/account';
import {
  MeasurementResultTypeEnum,
  Medication,
  useMeasurementResultList,
  useMedicationSearch,
  usePatientGetDiseaseHistory,
  usePatientGetHealthCondition,
} from '../../../../uc-api-sdk';
import { PrintMedicationComponent } from '../../component/PrintMedicationComponent/PrintMedicationComponent';
import { PrintHealthConditionsComponent } from '../../component/PrintVitalComponent/PrintHealthConditionsComponent';
import { PrintVitalComponent } from '../../component/PrintVitalComponent/PrintVitalComponent';
import { PrintVitalsButtonComponent } from '../../component/PrintVitalComponent/PrintVitalsButtonComponent';
import { PrintDiseaseHistoryComponent } from '../../component/PrintVitalComponent/component/PrintDiseaseHistoryComponent/PrintDiseaseHistoryComponent';
import { PrintVitalTitleComponent } from '../../component/PrintVitalComponent/component/PrintTitleComponent/PrintTitleComponent';
import { FromDateToDateDayjs } from '../../type';

export interface PrintVitalsContainerProps extends FromDateToDateDayjs {
  memberId: string;
}

export const PrintVitalsContainer = ({
  memberId,
  fromDate,
  toDate,
}: PrintVitalsContainerProps) => {
  const { info } = usePatientContext();

  if (!info || info?.isLoading) return null;
  const patientId = info.id;

  const measurementResult = useMeasurementResultList({
    options: { sendOnMount: true },
    params: {
      searchRequest: {
        filter: {
          memberId,
          dateRange: {
            gte: fromDate.toISOString(),
            lte: toDate.toISOString(),
          },
          needStats: true,
          needDataInVisits: true,
          typeList: [
            MeasurementResultTypeEnum.BP,
            MeasurementResultTypeEnum.BG,
            MeasurementResultTypeEnum.PO,
            MeasurementResultTypeEnum.HS,
          ],
        },
        pageInfo: {
          pagination: false,
        }
      },
    },
  });

  const healthConditionInfo = usePatientGetHealthCondition({
    params: { memberId: patientId },
  });

  const diseaseHistoryInfo = usePatientGetDiseaseHistory({
    params: { memberId: patientId },
  });

  const medications = useMedicationSearch({
    options: { sendOnMount: true },
    params: {
      filter: {
        memberId,
        endDateRange: {
          gte: fromDate.toISOString(),
          lte: toDate.toISOString(),
        },
      },
    },
  });

  const isLoading = (
    (measurementResult.data?.data === undefined || measurementResult.isLoading)
    || (medications.data?.data === undefined || medications.isLoading)
    || (healthConditionInfo.data?.data === undefined || healthConditionInfo.isLoading)
    || (diseaseHistoryInfo.data?.data === undefined || diseaseHistoryInfo.isLoading)
  );
  return (
    <PrintVitalsButtonComponent
      isLoading={isLoading}
    >
      {
        measurementResult.data?.data
        && (
          <PrintVitalComponent
            title={AccountHelper.getFullName(info.patientInfo?.profile) ?? ''}
            vitals={info.enrolledProgramService.getVitals()}
            data={measurementResult.data.data}
            fromDate={fromDate}
            toDate={toDate}
          />
        )
      }
      {
        medications.data?.data?.content
        && (
          <div>
            <PrintVitalTitleComponent name="Medications" />
            <PrintMedicationComponent
              data={medications.data?.data?.content as Medication[]}
            />
          </div>
        )
      }
      {
        healthConditionInfo.data?.data?.healthConditions
        && (
          <div>
            <PrintVitalTitleComponent name="Health Conditions" />
            <PrintHealthConditionsComponent
              healthConditions={healthConditionInfo.data?.data?.healthConditions || []}
            />
          </div>
        )
      }
      {
        diseaseHistoryInfo.data?.data?.diseaseHistory
        && (
          <div>
            <PrintVitalTitleComponent name="Disease History" />
            <PrintDiseaseHistoryComponent
              diseaseHistoryList={diseaseHistoryInfo.data.data.diseaseHistory || []}
            />
          </div>
        )
      }
    </PrintVitalsButtonComponent>
  );
};
