import { map } from 'lodash';
import React, { FC, useMemo } from 'react';
import { FromDateToDateDayjs } from '../../type';
import { ExerciseChartComponent, ExerciseChartData } from './ExerciseChartComponent';
import { ExerciseStat, Measurement } from '../../../../uc-api-sdk';
import { VitalHelper } from '../../helper/VitalHelper';
import TimezoneService from '../../../../helpers/timezone/timezoneService';

export interface ExerciseChartDataProcessorComponentProps extends FromDateToDateDayjs {
  data: Measurement[];
  stats: ExerciseStat;
}

export const ExerciseChartDataProcessorComponent: FC<ExerciseChartDataProcessorComponentProps> = ({
  data,
  stats,
  fromDate,
  toDate,
}) => {
  const processedData = useMemo(() => (
    map(data, (v): ExerciseChartData => ({
      date: TimezoneService.calcDateTimeDayjs(v.date as string),
      type: VitalHelper.getResultSource(v.resultSource),
      steps: v.steps?.value || 0,
    }))
  ), [data]);

  const getPercentage = (value: number) => +((value * 100).toFixed(2));

  return (
    <ExerciseChartComponent
      goalInfo={{
        goal: stats.target || 0,
        goalUnit: 'Steps/Day',
        total: stats.total || 0,
        abovePercentage: getPercentage(stats.aboveRatio || 0),
        bellowPercentage: getPercentage(stats.belowRatio || 0),
        aboveTarget: stats.aboveTarget || 0,
        belowTarget: stats.belowTarget || 0,
      }}
      data={processedData}
      fromDate={fromDate}
      toDate={toDate}
    />
  );
};
