import { AutoComplete, SelectProps } from 'antd';
import { range } from 'lodash';
import { DefaultOptionType, FilterFunc } from 'rc-select/lib/Select';
import { useEffect, useMemo, useState } from 'react';
import { InputType } from '../../features/Input/types';
import { TimeService } from '../../helpers/time/timeService';

export interface TimeStringSelectComponentProps extends InputType<string> {
  minValue?: string;
  placeholder?: string;
}

export const TimeStringSelectComponent = ({
  minValue,
  placeholder,
  onChange,
  value
}: TimeStringSelectComponentProps) => {
  const [
    selectedValue,
    setValue
  ] = useState<string | undefined>(value);

  useEffect(() => {
    // reset selected value if form value is greater than selected value
    if (value
      && selectedValue
      && TimeService.getMinutesFromTimeLabel(value)
      > TimeService.getMinutesFromTimeLabel(selectedValue)) {
      setValue(undefined);
    } else if (!value) {
      setValue(undefined); // reset selected value if form value is undefined
    }
  }, [value]);

  const filterOption: FilterFunc<DefaultOptionType> = (input, option) => {
    let trimmedInput = input;
    if (input.startsWith('0') && input.length === 1) {
      return String(option?.label).length === 7;
    }
    if (input.startsWith('0')) {
      trimmedInput = input.replace(/^0+/, '');
    }

    return String(option?.label)?.toLowerCase()
      .startsWith(trimmedInput.toLowerCase());
  };

  const handleChange = (value: string) => {
    const minutes = TimeService.getMinutesFromTimeLabel(value);
    if (Number.isInteger(minutes)) {
      setValue(value);
      onChange?.(value);
    }
  };

  const options = useMemo<SelectProps['options']>(() => {
    const startTime = minValue ? TimeService.getMinutesFromTimeLabel(minValue) / 60 : 5;
    const endTime = 21;
    // startTime * 15 * 4 for number of 15 minutes in a day
    // endTime * 15 * 4 for number of 15 minutes in a day
    // 15 for every 15 minutes
    const defaultRes: SelectProps['options'] = [];
    range(startTime * 4 * 15, endTime * 15 * 4 + 15, 15).forEach((v) => {
      const s = TimeService.getTimeLabel(v);
      defaultRes.push(
        {
          value: s,
          label: s,
          disabled: undefined,
        }
      );
    });
    return defaultRes;
  }, [selectedValue, minValue]);

  return (
    <AutoComplete
      value={selectedValue}
      onChange={handleChange}
      options={options}
      maxTagCount={1}
      allowClear
      placeholder={placeholder}
      optionFilterProp="label"
      filterOption={filterOption}
    />
  );
};
