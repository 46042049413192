import { DeprecatedOrganizationTypeEnum } from '../../features/adminPortal2/types';
import { ROLE_ENUM, ROLE_GROUP_MAP, ROLE_NAME_MAP } from '../../types/roles';

export const ROLE_MAP: { [key: string]: string } = {
  Nurse: 'RD',
  Doctor: 'MD',
  'Clinic Medical Assistant': 'MA',
  'Medical Assistant': 'CA',
  'PROVIDER:Doctor': 'MD',
  'PROVIDER:Clinic Medical Assistant': 'MA',
  'PROVIDER:Medical Assistant': 'CA',
  'PROVIDER:Nurse': 'RD',
  Biller: 'BILLER',
  'PROVIDER:Biller': 'BILLER',
  'PROVIDER:Partner Biller': 'Partner Biller',
  'Partner Biller': 'PROVIDER:Partner Biller',
  'Health Coach': 'HC',
  'PROVIDER:Health Coach': 'HC',
};

export const ROLE_OPTIONS = [
  {
    label: ROLE_NAME_MAP.RD,
    value: ROLE_ENUM.RD,
    group: ROLE_GROUP_MAP.CARE_DEPT,
  },
  {
    label: ROLE_NAME_MAP.CA,
    value: ROLE_ENUM.CA,
    group: ROLE_GROUP_MAP.CARE_DEPT,
  },
  {
    label: ROLE_NAME_MAP.HC,
    value: ROLE_ENUM.HC,
    group: ROLE_GROUP_MAP.CARE_DEPT,
  },
  {
    label: ROLE_NAME_MAP.PROVIDER,
    value: ROLE_ENUM.PROVIDER,
    group: ROLE_GROUP_MAP.CLINICAL_STAFF,
  },
  {
    label: ROLE_NAME_MAP.MA,
    value: ROLE_ENUM.MA,
    group: ROLE_GROUP_MAP.CLINICAL_STAFF,
  },
  {
    label: ROLE_NAME_MAP.CLINICAL_GROUP_BILLER,
    value: ROLE_ENUM.CLINICAL_GROUP_BILLER,
    group: ROLE_GROUP_MAP.CLINICAL_STAFF,
  },
  {
    label: ROLE_NAME_MAP.MANAGER,
    value: ROLE_ENUM.MANAGER,
    group: ROLE_GROUP_MAP.UC_MANAGER,
  },
  {
    label: ROLE_NAME_MAP.CALL_CENTER_REPRESENTATIVE,
    value: ROLE_ENUM.CALL_CENTER_REPRESENTATIVE,
    group: ROLE_GROUP_MAP.CALL_CENTER_DEPT,
  },
  {
    label: ROLE_NAME_MAP.BILLER,
    value: ROLE_ENUM.BILLER,
    group: ROLE_GROUP_MAP.BILLING_DEPT,
  },
];

export const ROLES_WITH_CAPACITY = [
  ROLE_ENUM.RD, ROLE_ENUM.CA, ROLE_ENUM.HC,
];

export const ROLES_WITH_ENTITIES = [
  ROLE_ENUM.RD, ROLE_ENUM.CA, ROLE_ENUM.HC, ROLE_ENUM.MANAGER,
  ROLE_ENUM.CALL_CENTER_REPRESENTATIVE, ROLE_ENUM.BILLER,
];

export const ROLES_WITH_CLINICAL_ORGS = [
  ROLE_ENUM.PROVIDER, ROLE_ENUM.MA, ROLE_ENUM.CLINICAL_GROUP_BILLER,
];

export const ROLES_WITH_DATA_ANALYTICS = [
  ROLE_ENUM.RD, ROLE_ENUM.HC, ROLE_ENUM.MANAGER,
  ROLE_ENUM.PROVIDER, ROLE_ENUM.MA, ROLE_ENUM.CA,
];

export const ROLE_DEFAULT_CAPACITY = {
  [ROLE_ENUM.RD]: 500,
  [ROLE_ENUM.CA]: 700,
  [ROLE_ENUM.HC]: 600,
} as { [key in ROLE_ENUM]: number };

export const UC_ROLE_ORG_TYPE_MAP = {
  [ROLE_ENUM.RD]: [
    DeprecatedOrganizationTypeEnum.CARE_CENTER,
    DeprecatedOrganizationTypeEnum.CARE_GROUP,
  ],
  [ROLE_ENUM.HC]: [
    DeprecatedOrganizationTypeEnum.CARE_CENTER,
    DeprecatedOrganizationTypeEnum.CARE_GROUP,
  ],
  [ROLE_ENUM.CA]: [
    DeprecatedOrganizationTypeEnum.CARE_CENTER,
    DeprecatedOrganizationTypeEnum.CARE_GROUP,
  ],
  [ROLE_ENUM.MANAGER]: [
    DeprecatedOrganizationTypeEnum.CARE_DEPARTMENT,
    DeprecatedOrganizationTypeEnum.CARE_CENTER,
    DeprecatedOrganizationTypeEnum.CARE_GROUP,
    DeprecatedOrganizationTypeEnum.CALL_DEPARTMENT,
    DeprecatedOrganizationTypeEnum.BILLING_DEPARTMENT,
  ],
  [ROLE_ENUM.CALL_CENTER_REPRESENTATIVE]: [DeprecatedOrganizationTypeEnum.CALL_DEPARTMENT],
  [ROLE_ENUM.BILLER]: [DeprecatedOrganizationTypeEnum.BILLING_DEPARTMENT],
} as { [key in ROLE_ENUM]: DeprecatedOrganizationTypeEnum[] };
