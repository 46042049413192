import React from 'react';
import { ConfigProvider } from 'antd';
import { ConfigProviderProps } from 'antd/lib/config-provider';

export interface AntdConfigProviderProps {
  children: React.ReactNode;
}

const pagination: ConfigProviderProps['pagination'] = {
  showSizeChanger: true,
};

export const AntdConfigProvider = ({
  children,
}: AntdConfigProviderProps) => (
  <ConfigProvider pagination={pagination}>
    {children}
  </ConfigProvider>
);
