import { isEmpty } from 'lodash';
import React, { FC } from 'react';
import { SummaryEmptyComponent } from './SummaryEmptyComponent';

export interface SummaryTwoIndexComponentProps {
  value: number[];
  divider?: string;
}

export const SummaryTwoIndexComponent: FC<SummaryTwoIndexComponentProps> = ({
  value,
  divider = '/',
}) => (
  isEmpty(value)
    ? <SummaryEmptyComponent />
    : (
      <div>
        {value[0]}
        {divider}
        {value[1]}
      </div>
    )
);
