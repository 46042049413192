import { getFullName } from '../../../../enumComponent/DeviceModelEnumComponent/DeviceModelEnumComponent';
import { DeviceMonitorMethodEnum } from '../../../../enumComponent/DeviceMonitorMethodEnumComponent/DeviceMonitorMethodEnumComponent';
import { ConnectedDeviceInfo } from '../../type';

export interface DeviceUnassignPromptTitleComponentProps {
  devicesNeedToUnassign?: ConnectedDeviceInfo[];
}

export const DeviceUnassignPromptTitleComponent = ({
  devicesNeedToUnassign = []
}: DeviceUnassignPromptTitleComponentProps) => (
  <span>
    {/* Please unassign BG Cellular Bioland device to proceed the action */}
    Please unassign
    {' '}
    {
      devicesNeedToUnassign.map((d, idx) => (
        d.deviceMethod !== DeviceMonitorMethodEnum.APP_MONITOR
        && (
          <>
            {getFullName(d.deviceModel)}
            {idx < devicesNeedToUnassign.length - 1 ? ', ' : ''}
          </>
        )
      ))
    }
    {' '}
    device to proceed the action.
  </span>
);
