import { PriorityCoverEnum } from '../../uc-api-sdk';
import { DisplayOrEmptyComponent } from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface PriorityCoverEnumComponentProps {
  value?: PriorityCoverEnum;
}

export const PriorityCoverEnumComponent = ({
  value,
}: PriorityCoverEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case PriorityCoverEnum.HIGH:
        return 'patients with high priority items';
      case PriorityCoverEnum.ALL:
        return 'all patients';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  return (
    <>{getText()}</>
  );
};
