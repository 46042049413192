import React, { FC, ReactNode } from 'react';
import './ScreenSizeComponent.scss';
import classNames from 'classnames';
import { ScreenSizeChildComponent } from './ScreenSizeChildComponent';

export interface ScreenSizeComponentProps {
  children: ReactNode;
  className?: string;
  topOffset?: string;
}

interface SubComponents {
  Child: typeof ScreenSizeChildComponent;
}

export const ScreenSizeComponent: FC<ScreenSizeComponentProps> & SubComponents = ({
  children,
  className = '',
  topOffset,
}: ScreenSizeComponentProps) => (
  <div
    className={classNames({
      'screen-size-component': true,
      [className]: !!className,
    })}
    style={{ height: `calc(100vh - 65px - ${topOffset})` }}
  >
    {children}
  </div>
);

ScreenSizeComponent.Child = ScreenSizeChildComponent;
