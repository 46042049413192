import { Button, Drawer } from 'antd';
import { ButtonProps } from 'antd/es/button';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { MediumDrawerWidth } from '../../constants/drawer';
import { usePatientContext } from '../../contexts/PatientInfoContext/PatientInfoContext';
import useBoolean from '../../hooks/useBoolean/useBoolean';
import { PauseServiceViewContainer } from '../../features/patient/container/PauseServiceContainer/PauseServiceContainer';

export interface PauseServiceDrawerComponentProps extends ButtonProps {
  memberId?: string;
  btnTitle?: ReactNode;
  className?: string;
  onOpenDrawer?: () => void;
}

export const PauseServiceDrawerComponent = ({
  memberId,
  btnTitle = 'Pause Service',
  type = 'link',
  className = '',
  onOpenDrawer,
}: PauseServiceDrawerComponentProps) => {
  const {
    value: open,
    setTrue: setOpen,
    setFalse: setClose,
  } = useBoolean(false);

  const { info } = usePatientContext();
  const { id = '' } = info || {};

  const onClick = () => {
    onOpenDrawer?.();
    setOpen();
  };

  return (
    <>
      <Button
        className={classNames({
          [className]: !!className,
        })}
        type={type}
        onClick={onClick}
      >
        {btnTitle}
      </Button>
      <Drawer
        open={open}
        onClose={setClose}
        className="drawerComponent"
        footer={null}
        placement="right"
        width={MediumDrawerWidth}
        title={<h3>Pause Services</h3>}
        destroyOnClose
      >
        <div className="h100 flex">
          <div className="f1 flex fd-c">
            <PauseServiceViewContainer
              memberId={memberId || id}
              onCancel={setClose}
            />
          </div>
        </div>
      </Drawer>
    </>
  );
};
