import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';

export type DownloadFileType = 'pdf';

export interface UseDownloadFileHook {
  fileType: DownloadFileType;
  downloadUrl?: string | null;
  onError?: (res: unknown) => void;
}

export const useDownloadFile = ({
  downloadUrl,
  fileType,
  onError,
}: UseDownloadFileHook) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadedFileUrl, setDownloadedFileUrl] = useState<string>();

  const blobFileType = useMemo(() => {
    switch (fileType) {
      case 'pdf':
        return 'application/pdf';
      default:
        return '';
    }
  }, [fileType]);

  useEffect(() => {
    if (downloadUrl) {
      setIsDownloading(true);
      (async () => {
        const res = await axios.get(downloadUrl as string, {
          responseType: 'arraybuffer',
        });
        setIsDownloading(false);
        if (res.status !== 200) {
          onError?.(res);
          return;
        }
        const blob = new Blob([res.data], { type: blobFileType });
        const url = URL.createObjectURL(blob);
        setDownloadedFileUrl(url);
      })();
    }
  }, [downloadUrl]);

  return {
    isDownloading,
    downloadedFileUrl,
  };
};
