import { Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { map } from 'lodash';
import { InputType } from '../../../Input/types';
import { InsurancePriorAuthStatusEnumComponent } from '../../../../enumComponent/InsurancePriorAuthStatusEnumComponent/InsurancePriorAuthStatusEnumComponent';
import { PriorAuthCaseStatusEnum } from '../../../../uc-api-sdk';

export const selectablePriorAuthCaseStatus = [
  PriorAuthCaseStatusEnum.NEED_SUBMISSION,
  PriorAuthCaseStatusEnum.PENDING_RESULT,
  PriorAuthCaseStatusEnum.APPROVED,
  PriorAuthCaseStatusEnum.DENIED,
];

export interface PriorAuthStatusSelectComponentProps extends InputType<PriorAuthCaseStatusEnum> {
  disabled?: boolean;
}

export const PriorAuthStatusSelectComponent = ({
  value,
  onChange,
  disabled,
}: PriorAuthStatusSelectComponentProps) => {
  const [initialValue] = useState(value);
  const options = useMemo(() => (
    map(selectablePriorAuthCaseStatus, (status) => ({
      label: <InsurancePriorAuthStatusEnumComponent value={status} />,
      value: status,
    }))
  ), []);

  useEffect(() => {
    if (initialValue) return;
    // set default value
    onChange?.(PriorAuthCaseStatusEnum.NEED_SUBMISSION);
  }, []);

  return (
    <Select
      value={value}
      onChange={onChange}
      options={options}
      disabled={disabled || !initialValue}
    />
  );
};
