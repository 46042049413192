import { Input, InputProps } from 'antd';
import { FC } from 'react';
import { InputType } from '../../features/Input/types';

export interface OtherInputComponentProps extends InputType<string> {
  className?: string;
  placeholder?: string;
  required?: boolean;
}

export const OtherInputComponent:
  FC<OtherInputComponentProps> = ({
    className,
    value,
    onChange,
    placeholder = 'Type new...',
    required,
  }) => {
    const handleChange: InputProps['onChange'] = (evt) => {
      const v = evt.target.value;
      onChange?.(v);
    };

    return (
      <Input
        required={required}
        className={className}
        defaultValue={value}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
      />
    );
  };
