import { AppInfoContainer } from '../../../device/container/AppInfoContainer/AppInfoContainer';
import { DeviceTableContainer } from '../../../device/container/DeviceTableContainer/DeviceTableContainer';
import { OrderTableContainer } from '../../../device/container/OrderTableContainer/OrderTableContainer';
import { OrderSuppliesReplacementDrawerButtonComponent } from '../../../order/component/OrderSuppliesReplacementDrawerButtonComponent/OrderSuppliesReplacementDrawerButtonComponent';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { PatientCommonCardTitleComponent } from '../../../patient/component/PatientCommonCardTitleComponent/PatientCommonCardTitleComponent';

export interface WorklistDevicesComponentProps {
  patientId: string;
  isTech?: boolean;
}

export const WorklistDevicesComponent = ({
  patientId,
  isTech,
}: WorklistDevicesComponentProps) => (
  <>
    {!isTech && (
      <div className="flex jc-e px10">
        <OrderSuppliesReplacementDrawerButtonComponent patientId={patientId} />
      </div>
    )}
    <div className="mb20">
      <PatientCommonCardTitleComponent>
        Devices
      </PatientCommonCardTitleComponent>
      <DeviceTableContainer
        patientId={patientId}
      />
    </div>
    <div className="mb20">
      <PatientCommonCardComponent
        title="App Info"
        showEdit={false}
        content={(
          <AppInfoContainer
            patientId={patientId}
          />
        )}
      />
    </div>
    <div className="mb20">
      <PatientCommonCardTitleComponent className="mb20 mt30">
        Orders
      </PatientCommonCardTitleComponent>
      <OrderTableContainer
        patientId={patientId}
      />
    </div>
  </>
);
