import { ReactNode } from 'react';
import TooltipComponent from '../TooltipComponent/TooltipComponent';

export interface ComplexLevelTooltipComponentProps {
  children: ReactNode;
}

export const ComplexLevelTooltipComponent = () => (
  <TooltipComponent
    title={(
      <div>
        <strong>What is Condition Complexity Level?</strong>
        <p>
          Patient's Condition Complexity Level is defined based on patient's
          conditions and can be changed manually as well. For enrolled patients,
          patients with different Condition Complexity Levels will be managed by different
          roles in the Care care team.
        </p>
        <p>
          <strong>The Non-complex patient </strong>
          will be managed by Health Coach (HC)
          Or Registered Dietitian (RD) + Clinical Assistant (CA).
        </p>
        <p>
          <strong>The Complex patients </strong>
          will be managed by Registered Dietitian (RD) + Clinical Assistant (CA).
        </p>
      </div>
    )}
    type="question-icon"
  />
);
