import {
  map, keyBy, pickBy, isNil
} from 'lodash';
import { MonitoringVital, VitalEnumType, usePatientUpsertPatientEnrolledProgram } from '../../../../uc-api-sdk';
import { SubmitValue, VitalsMonitoringScheduleFormComponent } from '../../component/VitalsMonitoringScheduleFormComponent/VitalsMonitoringScheduleFormComponent';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { FormType } from '../../../Input/types';

export interface VitalsMonitoringScheduleFormContainerProps extends FormType<SubmitValue> {
  patientId: string;
  visitId?: string;
  vitals?: MonitoringVital[];
  allowRemoveVital?: boolean;
  onSubmit?: () => void;
}

export const VitalsMonitoringScheduleFormContainer = ({
  patientId,
  visitId,
  vitals = [],
  onSubmit,
  onError,
  onCancel,
  onValuesChange,
  formButtons,
  allowRemoveVital,
}: VitalsMonitoringScheduleFormContainerProps) => {
  const enrolledProgramUpdateInfo = usePatientUpsertPatientEnrolledProgram({});

  const handleOnSubmit = useDebounce((values: SubmitValue) => {
    const { isEditVitals, ...vitalSchedules } = values;
    const vitals = Object.values(vitalSchedules).map((vital) => (
      pickBy(vital, (v) => !isNil(v))
    ));
    const req = enrolledProgramUpdateInfo.send({
      params: {
        memberId: patientId,
        enrolledProgram: {
          vitals,
          isEditVitals,
          visitId,
        },
      },
    });

    ApiRequestHelper.tryCatch(req, {
      success: 'Updated enrolled program successfully.',
      error: 'Failed to update enrolled program.',
      onSuccess: onSubmit,
      onError,
    });
  });

  return (
    <VitalsMonitoringScheduleFormComponent
      currentVitals={map(vitals, 'type') as VitalEnumType[]}
      initialValues={keyBy(vitals, 'type')}
      onSubmit={handleOnSubmit}
      onCancel={onCancel}
      allowRemoveVital={allowRemoveVital}
      isLoading={enrolledProgramUpdateInfo.isLoading}
      onValuesChange={onValuesChange}
      formButtons={formButtons}
    />
  );
};
