import { message } from 'antd';
import { reduce } from 'lodash';
import { Moment } from 'moment';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import {
  CalendarSourceEnum,
  ClinicEvent, useClinicEventInsert,
  useClinicEventUpdate, VisitParticipant,
  VisitParticipantRoleEnum
} from '../../../../uc-api-sdk';
import {
  CreateVisitFormComponent,
  CreateVisitFormComponentProps,
  SubmitValue
} from '../../component/CreateVisitFormComponent/CreateVisitFormComponent';
import { ParticipantsEnum } from '../../type/participants';

export interface CreateVisitContainerProps extends NestedFormComponentChildProps<SubmitValue> {
  disabledFields?: CreateVisitFormComponentProps['disabledFields'];
  showSearch?: CreateVisitFormComponentProps['showSearch'];
  onClose?: () => void;
  isEdit?: boolean;
  visit?: ClinicEvent;
  initialValues?: Partial<SubmitValue>;
  shouldUpdateValue?: boolean;
  createSuccessMessage?: string;
  editSuccessMessage?: string;
}

export const CreateVisitContainer = ({
  disabledFields,
  disabled,
  onClose,
  isEdit = false,
  visit,
  initialValues,
  onSubmit,
  onError,
  onValuesChange,
  formButtons,
  showSearch,
  shouldUpdateValue = true, // prevent refetching clinic event list that will close popup
  createSuccessMessage,
  editSuccessMessage,
}: CreateVisitContainerProps) => {
  const createVisit = useClinicEventInsert({});
  const editVisit = useClinicEventUpdate({});
  const createVisitEvent = useUpdate('VISIT_CREATED');
  const editVisitEvent = useUpdate('VISIT_UPDATED');
  const updateVisitsAndEventsTable = useUpdate('UPDATE_VISITS_AND_EVENTS_TABLE');
  const { send: sendMixpanel } = useMixpanelContext();

  const calcTime = (date: Moment, minutes: number) => (
    date.clone().startOf('day').add(minutes, 'minutes')
  );

  const getParticipants = (value: SubmitValue) => (
    reduce(value.participants, (res, curr) => {
      switch (curr) {
        case ParticipantsEnum.RdHc:
          if (value.rdhc) {
            res.push({
              participantId: value.rdhc,
              role: VisitParticipantRoleEnum.RD_HC,
            });
          }
          break;
        case ParticipantsEnum.CA:
          if (value.ca) {
            res.push({
              participantId: value.ca,
              role: VisitParticipantRoleEnum.CA,
            });
          }
          break;
        case ParticipantsEnum.MA:
          res.push({
            participantId: value.ma,
            role: VisitParticipantRoleEnum.MA,
          });
          break;
        case ParticipantsEnum.MD:
          if (value.md) {
            res.push({
              participantId: value.md,
              role: VisitParticipantRoleEnum.MD,
            });
          }
          break;
        default: break;
      }
      return res;
    }, [] as VisitParticipant[])
  );

  const getOtherEventParticipants = (value: SubmitValue) => (
    reduce(value.otherEventParticipants, (res, curr) => {
      res.push({
        participantId: curr,
      });
      return res;
    }, [] as VisitParticipant[])
  );

  const getStartTimeEndTime = (submitValue: SubmitValue) => {
    const startTime = calcTime(submitValue.visitDate, submitValue.startTime).toISOString().replace('Z', '');
    const endTime = calcTime(submitValue.visitDate, submitValue.endTime).toISOString().replace('Z', '');
    return { startTime, endTime };
  };

  const getEventTypeForMessage = (type: CalendarSourceEnum) => {
    switch (type) {
      case CalendarSourceEnum.UC_VISIT: return 'Visit';
      default: return 'Event';
    }
  };

  const handleCreateVisit: CreateVisitFormComponentProps['onSubmit'] = async (submitValue) => {
    const isOtherEvent = submitValue.eventType === CalendarSourceEnum.OTHER;
    const isVisit = submitValue.eventType === CalendarSourceEnum.UC_VISIT;
    const messageText = getEventTypeForMessage(submitValue.eventType);

    const { startTime, endTime } = getStartTimeEndTime(submitValue);

    try {
      sendMixpanel({ event: MixpanelEvents.CreateVisit });
      const res = await createVisit.send({
        params: {
          document: {
            patientId: submitValue.patient,
            calendarSource: submitValue.eventType,
            eventTitle: isOtherEvent ? submitValue.eventTitle : undefined,
            visitType: isVisit ? submitValue.visitType : undefined,
            visitMethod: isVisit ? submitValue.visitMethod : undefined,
            visitParticipants: isVisit
              ? getParticipants(submitValue)
              : getOtherEventParticipants(submitValue),
            startTime,
            endTime,
            description: submitValue.description,
          },
        },
      });
      if (!res?.code || res.code > 299) {
        throw new Error(`failed to create ${messageText}!`);
      }
      if (shouldUpdateValue) {
        createVisitEvent.updateValue();
      }
      updateVisitsAndEventsTable.updateValue();
      message.success(createSuccessMessage || `${messageText} created successfully!`);
      onSubmit?.();
      onClose?.();
    } catch (err) {
      message.error(`failed to create ${messageText}!`);
      onError?.();
    }
  };

  const handleUpdateVisit: CreateVisitFormComponentProps['onSubmit'] = async (submitValue) => {
    if (!visit?.id) return;
    const isOtherEvent = submitValue.eventType === CalendarSourceEnum.OTHER;
    const isVisit = submitValue.eventType === CalendarSourceEnum.UC_VISIT;
    const messageText = getEventTypeForMessage(submitValue.eventType);

    const { startTime, endTime } = getStartTimeEndTime(submitValue);
    try {
      sendMixpanel({ event: MixpanelEvents.EditVisit });
      const res = await editVisit.send({
        params: {
          id: visit.id,
          document: {
            calendarEventId: visit.calendarEventId,
            patientId: submitValue.patient,
            calendarSource: submitValue.eventType,
            eventTitle: isOtherEvent ? submitValue.eventTitle : undefined,
            visitType: isVisit ? submitValue.visitType : undefined,
            visitMethod: isVisit ? submitValue.visitMethod : undefined,
            visitParticipants: isVisit
              ? getParticipants(submitValue)
              : getOtherEventParticipants(submitValue),
            startTime,
            endTime,
            description: submitValue.description,
          },
        },
      });
      if (!res?.code || res.code > 299) {
        throw new Error(`failed to update ${messageText}!`);
      }
      if (shouldUpdateValue) {
        editVisitEvent.updateValue();
      }
      updateVisitsAndEventsTable.updateValue();
      message.success(editSuccessMessage || `${messageText} updated successfully!`);
      onSubmit?.();
      onClose?.();
    } catch (err) {
      message.error(`failed to update ${messageText}!`);
      onError?.();
    }
  };

  const handleSubmitVisit: CreateVisitFormComponentProps['onSubmit'] = async (submitValue) => {
    (isEdit ? handleUpdateVisit : handleCreateVisit)(submitValue);
  };

  return (
    <CreateVisitFormComponent
      visitId={visit?.id}
      disabledFields={disabledFields}
      disabled={disabled}
      isEdit={isEdit}
      onSubmit={handleSubmitVisit}
      isSubmitting={createVisit.isLoading || editVisit.isLoading}
      onCancel={onClose}
      initialValues={initialValues}
      onValuesChange={onValuesChange}
      formButtons={formButtons}
      showSearch={showSearch}
    />
  );
};
