import dayjs from 'dayjs';
import { MonthlyBillableStatistic } from '../../../../uc-api-sdk';
import { BILLING_DATE } from '../../../../constants/timeFormat';
import { BillableTagComponent } from '../../../patient/component/TagsComponent/BillableTagComponent/BillableTagComponent';

export interface BillableStatisticComponentProps {
  statistic?: MonthlyBillableStatistic,
  isDays?: boolean,
  isMins?: boolean,
  billable?: boolean,
}

const currentMonth = (statistic?: MonthlyBillableStatistic) => {
  const currentMonth = statistic?.monthOfYear === Number(
    dayjs().format(BILLING_DATE)
  );
  return currentMonth;
};

export const billableStatisticDays = (statistic?: MonthlyBillableStatistic) => {
  const total = currentMonth(statistic) ? (statistic?.measurementDays || 0) : 0;
  return `${total} ${total > 1 ? 'days' : 'day'}`;
};

export const billableStatisticTime = (statistic?: MonthlyBillableStatistic) => {
  const total = currentMonth(statistic) ? (statistic?.totalTime || 0) : 0;
  return `${total} ${total > 1 ? 'mins' : 'min'}`;
};

export const billableStatisticBillable = (
  statistic?: MonthlyBillableStatistic
) => (currentMonth(statistic) && statistic?.eligible);

export const BillableStatisticComponent = ({
  statistic,
  isDays = false,
  isMins = false,
  billable = false,
}: BillableStatisticComponentProps) => {
  const renderBillable = () => {
    if (currentMonth(statistic) && statistic?.eligible) {
      return <BillableTagComponent.YesTag />;
    }
    return (
      <BillableTagComponent.NoTag />
    );
  };

  return (
    <div>
      {isDays && billableStatisticDays(statistic)}
      {isMins && billableStatisticTime(statistic)}
      {billable && renderBillable()}
    </div>
  );
};
