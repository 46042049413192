import dayjs from 'dayjs';
import { BILLING_DATE } from '../../../../constants/timeFormat';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { BillingHistoryTableComponent } from '../../component/BillingComponent/BillingHistoryTableComponent';
import { billingInfo } from '../../hook/billingInfo';

export interface BillingHistoryContainerProps {
  patientId?: string;
}

export const BillingHistoryContainer = ({
  patientId,
}: BillingHistoryContainerProps) => {
  const date = dayjs().subtract(2, 'year');
  const formattedDateString = date.format(BILLING_DATE);
  const { info, tableChange } = billingInfo(
    patientId,
    { gte: +formattedDateString, lte: +dayjs().format(BILLING_DATE) },
  );

  return (
    <FetchComponent
      info={info}
      alwaysShowChildren
      showErrorOverlay={false}
      showLoadingOverlay={false}
      emptyOverlay={<h2>No Data!!!</h2>}
    >
      {(value, info) => {
        const {
          content = [],
        } = (value?.rawValue?.data || {});
        return (
          <BillingHistoryTableComponent
            dataSource={content}
            onChange={tableChange.handleTableChange}
            pagination={info.pagination}
          />
        );
      }}
    </FetchComponent>
  );
};
