import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { useTableColumnWithFiltersAndSorters } from '../../../../hooks/useTableColumnWithFiltersAndSorters/useTableColumnWithFiltersAndSorters';
import { PatientsTableComponent } from '../../../patient/component/PatientsTableComponent/PatientsTableComponent';
import { PatientTableProps } from '../../../patients/type';
import { createProviderViewPatientColumns } from '../ProviderViewTablesComponent/columns';

export interface ProviderViewMyClinicPatientsTableComponentProps extends PatientTableProps {
  selectedOrgId?: string;
}

export const ProviderViewMyClinicPatientsTableComponent = ({
  totalSize,
  filters,
  sorters,
  selectedOrgId,
  ...props
}: ProviderViewMyClinicPatientsTableComponentProps) => {
  const columns = useTableColumns(
    createProviderViewPatientColumns,
    [{ name: 'name', args: [selectedOrgId] }, 'age', 'gender',
      { name: 'provider', args: [selectedOrgId] },
      'mrn', 'status', 'program', 'enrollmentDate',
      { name: 'bpDeviceMacId', args: [selectedOrgId] },
      { name: 'bpPairDate', args: [selectedOrgId] },
      'bpDeviceLastUsed', 'bgDeviceMacId',
      'bgPairDate', 'bgDeviceLastUsed'],
    [selectedOrgId]
  );

  const columnsWithFilters = useTableColumnWithFiltersAndSorters({
    columns,
    filters,
    sorters: sorters || { columnKey: 'enrollmentDate', order: 'descend' },
  });

  return (
    <PatientsTableComponent
      totalSize={totalSize}
      columns={columnsWithFilters}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};
