import {
  Select,
  SelectProps
} from 'antd';
import classNames from 'classnames';
import { map } from 'lodash';
import { FC, useCallback } from 'react';
import { SelectType } from '../../features/Input/types';
import { LanguageType } from '../../types/languages';
import CheckboxSelectComponent from '../../uiComponent/CheckboxSelect/CheckboxSelectComponent';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import LANGUAGES_MAP from './LanguagesMap';

export interface LanguageSelectComponentProps extends SelectType<LanguageType | LanguageType[]> {
  className?: string;
  mode?: SelectProps['mode'];
  disabledLanguages?: LanguageType[];
}

export const LanguageSelectComponent: FC<LanguageSelectComponentProps> = ({
  onChange,
  value,
  className = '',
  mode,
  disabledLanguages,
  ...props
}) => {
  const renderOptions = useCallback(() => map(LANGUAGES_MAP, (v, k) => (
    <Select.Option
      key={k}
      value={v.value}
      disabled={disabledLanguages?.includes(v.value as LanguageType)}
    >
      <DisplayOrEmptyComponent<string> value={`${v.abbr} (${v.name})`} />
    </Select.Option>
  )), [disabledLanguages]);

  const SelectComponent = mode === 'multiple' ? CheckboxSelectComponent : Select;

  return (
    <SelectComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      mode={mode}
      showSearch
      value={value}
      className={
        classNames({
          'Languages-select': true,
          [className]: !!className,
        })
      }
      placeholder=""
      defaultActiveFirstOption={false}
      showArrow
      onChange={onChange}
      notFoundContent={null}
      // for form item if applicable
      id={props.id}
      aria-required={props['aria-required']}
    >
      {renderOptions()}
    </SelectComponent>
  );
};

export default LanguageSelectComponent;
