import { useEffect } from 'react';
import { first, map } from 'lodash';
import {
  Button, Form, Input, Radio, Space, Typography,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAlertSnoozeForm } from '../../hook/useAlertSnoozeForm';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { getSnoozeIntervalDate } from '../../constant/constants';
import { AlertPlaceHolders } from '../../constant/text';
import { ComplianceAlert, SmartAlertTaskStatus, SnoozeIntervalEnum } from '../../../../uc-api-sdk';
import { SnoozeIntervalEnumComponent } from '../../../../enumComponent/SnoozeIntervalEnumComponent/SnoozeIntervalEnumComponent';

const { Text } = Typography;

export interface AlertSnoozeSubmitValue {
  snoozeInterval: SnoozeIntervalEnum;
  snoozeReason?: string;
}

export interface AlertSnoozeFormComponentProps {
  data?: ComplianceAlert[];
  onSubmit?: (value: AlertSnoozeSubmitValue) => void;
  onCancel?: () => void;
  onUnSnooze?: (value: AlertSnoozeSubmitValue) => void;
}

export const AlertSnoozeFormComponent = ({
  data,
  onSubmit,
  onCancel,
  onUnSnooze,
}: AlertSnoozeFormComponentProps) => {
  const [form] = useForm();
  const hook = useAlertSnoozeForm({ form });
  const { value: isLoading, setTrue, setFalse } = useBoolean();

  const handleSubmit = async (v: AlertSnoozeSubmitValue) => {
    try {
      const submit = hook.handleSubmitAndReset(onSubmit);
      setTrue();
      await submit?.(v);
      setFalse();
    } catch (e) {
      setFalse();
      console.error(e);
    }
  };

  const handleUnSnooze = async () => {
    const value: AlertSnoozeSubmitValue = form.getFieldsValue();
    try {
      const submit = hook.handleSubmitAndReset(onUnSnooze);
      setTrue();
      await submit?.(value);
      setFalse();
    } catch (e) {
      setFalse();
      console.error(e);
    }
  };

  useEffect(() => {
    const initialValues = {
      snoozeInterval: first(data)?.snoozeInterval ?? SnoozeIntervalEnum.ONE_DAY,
      snoozeReason: first(data)?.snoozeReason,
    };
    form.setFieldsValue(initialValues);
  }, [data]);

  return (
    <>
      <div className="mb10">
        <Text type="secondary">
          After snooze, the selected alert type(s) won't be triggered
          until the snooze time ends.
        </Text>
      </div>
      <Form
        form={hook.form}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <FormItem
          noStyle
          shouldUpdate={hook.shouldUpdate(['snoozeInterval'])}
        >
          {({ getFieldValue }) => {
            const v = getFieldValue('snoozeInterval');
            return (
              <Text type="secondary" className="line-height-2">
                Snooze time
                {' '}
                (start date:
                {' '}
                <DisplayDateComponent format="USA_DATE" value={getSnoozeIntervalDate(v)} />
                )
              </Text>
            );
          }}
        </FormItem>
        <FormItem className="mb20" info={hook.getInfo('snoozeInterval')}>
          <Radio.Group>
            <Space direction="vertical">
              {map([
                SnoozeIntervalEnum.ONE_DAY,
                SnoozeIntervalEnum.THREE_DAYS,
                SnoozeIntervalEnum.ONE_WEEK,
                SnoozeIntervalEnum.TWO_WEEKS,
                SnoozeIntervalEnum.ONE_MONTH,
              ], (v, k) => (
                <Radio key={k} value={v}>
                  <SnoozeIntervalEnumComponent value={v} />
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </FormItem>
        <FormItem className="mb20" info={hook.getInfo('snoozeReason')}>
          <Input placeholder={AlertPlaceHolders.Snooze} />
        </FormItem>
        <div className="flex jc-sb">
          <div className="flex">
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={isLoading}
              className="mr20"
            >
              Confirm
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </div>
          {first(data)?.status === SmartAlertTaskStatus.MUTE
          && (
          <div>
            <Button
              type="default"
              loading={isLoading}
              disabled={isLoading}
              onClick={handleUnSnooze}
              className="mr20"
            >
              Unsnooze
            </Button>
          </div>
          )}
        </div>
      </Form>
    </>
  );
};
