import { Button, ButtonProps } from 'antd';
import classNames from 'classnames';

import './LinkButton.scss';

export interface LinkButtonProps extends Omit<ButtonProps, 'type'> {
  useBlueColor?: boolean;
  showUnderline?: boolean;
  underlineOnHover?: boolean;
  loading?: boolean;
}

export const LinkButton = ({
  children,
  className = '',
  useBlueColor,
  color = (useBlueColor ? 'var(--uc-blue)' : 'var(--text-gray)'),
  showUnderline,
  underlineOnHover,
  loading,
  disabled = !!loading,
  ...props
}: LinkButtonProps) => (
  <Button
    data-testid="linkButton"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    className={classNames({
      'link-button': true,
      'use-blue-color': !!useBlueColor,
      underlined: showUnderline,
      'underline-on-hover': underlineOnHover,
      [className]: !!className,
    })}
    type="link"
    style={!disabled ? { color } : {}}
    // to get support from antd form
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...(disabled ? { disabled: true } : {})}
  >
    {children}
  </Button>
);
