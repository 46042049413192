import { Drawer, Space } from 'antd';
import { useBoolean } from 'usehooks-ts';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { ClickableDiv } from '../../../../uiComponent/ClickableDiv/ClickableDiv';
import { Icons } from '../../../../icons/Icons';
import useChangePage from '../../../../hooks/useChangePage/useChangePage';
import { DayCalendarContainer } from '../../container/DayCalendarContainer/DayCalendarContainer';
import { PullUpDrawerWrapperComponent } from '../../../../uiComponent/PullUpDrawerComponent/PullUpDrawerWrapperComponent';
import './SlideInCalendarComponent.scss';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';

export interface SlideInCalendarComponentProps {
  className?: string;
  button?: ReactNode;
}

export const SlideInCalendarComponent = ({
  className = '',
  button = 'Open Slide in Calendar',
}: SlideInCalendarComponentProps) => {
  const changePage = useChangePage();
  const { send } = useMixpanelContext();
  const { value: isOpen, setTrue: setOpenTrue, setFalse: setOpenFalse } = useBoolean();

  const handleGoToCalendar = () => {
    setOpenFalse();
    send({ event: MixpanelEvents.BigCalendar });
    window.open(changePage.createCalendarUrl(), '_blank');
  };

  const handleOpenDayCalendar = () => {
    send({ event: MixpanelEvents.HeaderCalendar });
    setOpenTrue();
  };

  const renderButtons = () => (
    <Space size="large">
      <Icons.Popout onClick={handleGoToCalendar} />
      <Icons.CloseIcon onClick={setOpenFalse} />
    </Space>
  );

  return (
    <PullUpDrawerWrapperComponent>
      <ClickableDiv onClick={handleOpenDayCalendar}>
        {button}
      </ClickableDiv>
      <Drawer
        destroyOnClose
        push={false}
        title="Calendar"
        className={classNames({
          'slide-in-calendar-component': true,
          [className]: !!className,
        })}
        open={isOpen}
        width={407}
        onClose={setOpenFalse}
        closable={false}
        extra={renderButtons()}
      >
        <DayCalendarContainer onClose={setOpenFalse} />
      </Drawer>
    </PullUpDrawerWrapperComponent>
  );
};
