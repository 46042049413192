import { useDeepCompareMemo } from '../useDeepCompareEffect';

export interface GetContextValueHook<
  ValueType,
  OtherDepts,
> {
  values: { [x in keyof ValueType]: ValueType[x] },
  otherDependencies: OtherDepts[],
}
export const useGetContextValue = <ValueType, OtherDepts = unknown>(
  values: GetContextValueHook<ValueType, OtherDepts>['values'],
  otherDependencies = [] as GetContextValueHook<ValueType, OtherDepts>['otherDependencies'],
): ValueType => {
  const contextValue = useDeepCompareMemo(
    () => values,
    [
      ...Object.values(values),
      ...otherDependencies,
    ],
  );

  return contextValue;
};
