import { upperFirst } from 'lodash';
import { FC, useMemo } from 'react';
import { BGSummaryKey } from '../../type';

export interface BGSummaryTypeComponentProps {
  type: BGSummaryKey;
}

export const BGSummaryTypeComponent: FC<BGSummaryTypeComponentProps> = ({
  type,
}) => {
  const value = useMemo(() => {
    switch (type) {
      case 'beforeMeal': return 'Before Meal';
      case 'afterMeal': return 'After Meal';
      case 'bedtimeOvernightHypo': return 'B,O Hypos';
      case 'criticalHigh': return 'Critical High';
      case 'criticalLow': return 'Critical Low';
      default: return upperFirst(type);
    }
  }, [type]);

  return (
    <div>
      {value}
    </div>
  );
};
