import { useUpdateListener } from '../../../contexts/UpdateContext/UpdateContext';
import { useTableCacheFilters } from '../../../hooks/useTableCache/useTableCacheFilters';
import { TableCacheNameEnum } from '../../../hooks/useTableChange/useTableChange';
import { usePatientCareGetMyPatientCareResponse } from '../../../uc-api-sdk';
import { useOnCallEffect } from '../../oncall/hook/useOnCallEffect';
import { useOnCallContext } from '../../patientCare/context/OnCallContext/OnCallContext';
import { PatientCareFilter } from '../../patientCare/hook/usePatientCareTableOnChange';

export const useGetPatientCareCount = () => {
  const { isOnCall } = useOnCallContext({ type: 'patientCare' });
  const { processedFilters = {} } = useTableCacheFilters(TableCacheNameEnum.PatientCare) || {};

  const patientCareCountInfo = usePatientCareGetMyPatientCareResponse({});
  const { isLoading, data } = patientCareCountInfo;

  const fetchPatientCareCount = () => {
    patientCareCountInfo.send({
      params: {
        request: {
          patientCareRequest: {
            filter: processedFilters as PatientCareFilter,
            pageInfo: {
              sort: [],
              countOnly: true,
            },
          },
          onCall: isOnCall,
          includeTestPatient: (
            (processedFilters as PatientCareFilter)?.includeTestPatient || false
          ) as boolean,
        }
      },
    });
  };

  useOnCallEffect(
    'patientCare',
    () => fetchPatientCareCount(),
    [processedFilters]
  );

  useUpdateListener('OUTSTANDING_COUNT_UPDATED', fetchPatientCareCount);

  return {
    isLoading,
    count: data?.data?.totalSize || 0,
  };
};
