import { useMemo } from 'react';
import { StepProps, Steps, StepsProps } from 'antd';
import { InputTypeRequired } from '../../../Input/types';
import { Step } from '../../context/EnrollmentStepContext/EnrollmentStepContext';
import './EnrollmentStepsComponent.scss';
import { usePatientMedicalOrganizationContext } from '../../../../contexts/PatientMedicalOrganizationContext/PatientMedicalOrganizationContext';

export interface EnrollmentStepsComponentProps extends Partial<InputTypeRequired<Step>> {
  completedSteps?: Step[];
}

export const EnrollmentStepsComponent = ({
  value,
  onChange,
  completedSteps = [],
}: EnrollmentStepsComponentProps) => {
  const {
    medicalOrganizationService,
  } = usePatientMedicalOrganizationContext();

  const handleChange: StepsProps['onChange'] = (v) => {
    onChange?.(v as Step);
  };

  const getStepStatus = (step: Step): StepProps['status'] => {
    if (value === step) return 'process';
    if (completedSteps.includes(step)) {
      return 'finish';
    }
    return 'wait';
  };

  const items = useMemo(() => {
    if (!medicalOrganizationService) {
      return [];
    }

    const disabledAssignDevices = (
      medicalOrganizationService?.isRiverBend()
    );
    const disabledOnboardingVisits = (
      medicalOrganizationService?.isRiverBend()
      || medicalOrganizationService?.isStThomas()
    );

    const steps = [
      {
        status: getStepStatus(Step.ProgramEnrollment),
        title: 'Program Enrollment',
      },
      {
        status: getStepStatus(Step.AssignDevices),
        title: 'Assign Devices',
        disabled: disabledAssignDevices
      },
      {
        status: getStepStatus(Step.OnboardingVisits),
        title: 'Onboarding visits',
        disabled: disabledOnboardingVisits
      }
    ];

    return steps;
  }, [
    medicalOrganizationService?.isRiverBend(),
    medicalOrganizationService?.isStThomas(),
    getStepStatus,
  ]);

  return (
    <Steps
      type="navigation"
      current={value}
      onChange={handleChange}
      size="small"
      className="site-navigation-steps"
      items={items}
    />
  );
};
