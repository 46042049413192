import { ReactNode } from 'react';
import { ErrorBoundaryAppFallbackComponent } from './ErrorBoundaryAppFallbackComponent';
import { ErrorBoundaryComponent } from './ErrorBoundaryComponent';
import { ErrorSimulatorPlaceHolderComponent } from './simulator/ErrorSimulator';

export interface ErrorBoundaryAppComponentProps {
  children: ReactNode;
}

export const ErrorBoundaryAppComponent = ({
  children,
}: ErrorBoundaryAppComponentProps) => (
  <ErrorBoundaryComponent FallbackComponent={ErrorBoundaryAppFallbackComponent}>
    {children}
    <ErrorSimulatorPlaceHolderComponent spot="App" />
  </ErrorBoundaryComponent>
);
