import { FormOptions } from '../../../../hooks/useFormHookFactory/useFormHookFactory';
import { useIterableFormHook } from '../../../../hooks/useIterableFormListHook/useIterableFormHook';
import { useDeepCompareMemo } from '../../../../hooks/useDeepCompareEffect';

export const LLM_LAB_RESULT_FORM_NAME = 'llmLabResult';

export const useLLMLabResultForm = (options?: FormOptions) => {
  const factory = useIterableFormHook(options?.name || LLM_LAB_RESULT_FORM_NAME, {
    isChecked: {
      name: 'isChecked',
      label: '',
    },
    dateCollected: {
      name: 'dateCollected',
      label: 'Test Date',
    },
    name: {
      name: 'name',
      label: 'Test',
    },
    isInHouse: {
      name: 'isInHouse',
      label: 'In-house',
    },
    value: {
      name: 'value',
      label: 'Result',
    },
  }, options);

  return useDeepCompareMemo(() => factory, [factory]);
};
