import dayjs from 'dayjs';
import _, { map } from 'lodash';
import {
  useEffect,
  useMemo,
  useState
} from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { Patient } from '../../../../uc-api-sdk';
import { ALL_CLINIC_SELECT_OPTION } from '../../../../uiComponent/ClinicSelectComponent/ClinicSelectComponent';
import {
  PatientSearchSelectComponent,
  PatientSearchSelectComponentInfo,
  PatientSearchSelectComponentProps
} from '../../component/PatientSearchSelectComponent/PatientSearchSelectComponent';
import { usePatientSearchPage } from '../../hook/usePatientSearchPage';

export interface PatientSearchSelectContainerProps extends
  Omit<PatientSearchSelectComponentProps<Patient>, 'data' | 'onSearch' | 'onChange'> {
  value?: string | null;
  onChange?: (value: string) => void;
  getByPatientIdOnSelect?: (value?: Patient) => void;
  showClinicInFilter?: boolean;
  getAllClinics?: boolean;
  isCreateOtherEventsInCalender?: boolean;
  selectedOrgId?: string;
}

export const PatientSearchSelectContainer = ({
  value,
  onChange,
  getByPatientIdOnSelect,
  optionRenderer,
  dropdownRender,
  optionLabel,
  placeholder,
  showClinicInFilter,
  isCreateOtherEventsInCalender,
  selectedOrgId,
  ...props
}: PatientSearchSelectContainerProps) => {
  const [selectedClinicId, setSelectedClinicId] = useState<string | undefined>(selectedOrgId);
  const { handleSearch, patients } = usePatientSearchPage(value);

  const handleChange: PatientSearchSelectComponentProps<Patient>['onChange'] = (value) => {
    onChange?.(value);
    getByPatientIdOnSelect?.(_.first(patients.data?.data?.content?.filter(v => v.id === value)));
  };

  const debounceHandleSearch = useDebounce(
    handleSearch,
    500,
    [selectedClinicId, isCreateOtherEventsInCalender]
  );

  useEffect(() => {
    if (selectedOrgId !== selectedClinicId) {
      setSelectedClinicId(selectedOrgId);
    }
  }, [selectedOrgId]);

  useUpdateEffect(() => {
    debounceHandleSearch(
      value ?? undefined,
      selectedClinicId ?? undefined,
      isCreateOtherEventsInCalender
    );
  }, [selectedClinicId, isCreateOtherEventsInCalender]);

  const patientList = useMemo<PatientSearchSelectComponentInfo<Patient>[]>(() => (
    map(patients.data?.data?.content, (v) => ({
      id: v.id || '',
      fullName: `${v.profile?.firstName} ${v.profile?.lastName}`,
      dob: v.profile?.birthday ? dayjs(v.profile.birthday) : undefined,
      info: v,
    }))
  ), [patients.data?.data?.content]);

  return (
    <PatientSearchSelectComponent
      isLoading={patients.isLoading}
      data={patientList}
      onSearch={(v) => {
        debounceHandleSearch(
          v ?? undefined,
          selectedClinicId ?? undefined,
          isCreateOtherEventsInCalender
        );
      }}
      value={value}
      optionRenderer={optionRenderer}
      dropdownRender={dropdownRender}
      onChange={handleChange}
      optionLabel={optionLabel}
      placeholder={placeholder}
      showClinicInFilter={showClinicInFilter}
      clinicInFilterValue={selectedClinicId || ALL_CLINIC_SELECT_OPTION.value}
      onChangeClinicInFilter={setSelectedClinicId}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};
