import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { VisitTypeEnum, useClinicEventSearch } from '../../../../uc-api-sdk';
import { BaseSortDirection } from '../../../../uc-api-sdk/staticFiles/useReqHook';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';

export interface LastFollowupVisitContainerProps {
  patientId: string | null | undefined;
}

export const LastFollowupVisitContainer = ({ patientId }: LastFollowupVisitContainerProps) => {
  const [currentDate] = useState(dayjs());

  const clinicEventSearch = useClinicEventSearch({
    options: { sendOnMount: true },
    params: {
      filter: {
        patientId,
        visitType: VisitTypeEnum.FOLLOW_UP,
        startTimeRange: {
          lte: currentDate?.clone().startOf('day').toISOString(),
        },
      },
      pageInfo: {
        page: 0,
        size: 1,
        sort: [
          {
            direction: BaseSortDirection.DESC,
            property: 'startTime',
          },
        ],
        pagination: true,
      },
    },
  });

  const lastFollowupVisit = useMemo(() => (
    clinicEventSearch.data?.data?.content?.at?.(0)?.startTime
  ), [clinicEventSearch.data]);

  return (
    <DisplayDateComponent value={lastFollowupVisit} format="MESSAGE_DATE_FORMAT" useNowWhenEmpty={false} />
  );
};
