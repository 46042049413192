import { includes, intersection } from 'lodash';
import {
  AlternativeDeviveType,
  AssignDeviceInfo,
  PatientTechLevelEnum,
  VitalEnumType
} from '../../../../uc-api-sdk';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useAssignDeviceForm } from '../../../patient/hook/useAssignDeviceForm';
import { DevicesCheckboxGroupComponent } from '../DevicesCheckboxGroupComponent/DevicesCheckboxGroupComponent';
import { LoanerPhoneDeviceCheckboxComponent } from '../LoanerPhoneDeviceCheckboxComponent/LoanerPhoneDeviceCheckboxComponent';
import { NonAppDeviceCheckboxComponent } from '../NonAppDeviceCheckboxComponent/NonAppDeviceCheckboxComponent';

export interface EnrollmentDeviceSelectionFormItemComponentProps {
  vitals: VitalEnumType[];
  onChange?: (value?: AssignDeviceInfo[]) => void;
  hasSmartDevice?: boolean;
  nonSmartPhoneAlternatives?: AlternativeDeviveType[] | null;
}

export const EnrollmentDeviceSelectionFormItemComponent = ({
  vitals,
  onChange,
  hasSmartDevice,
  nonSmartPhoneAlternatives,
}: EnrollmentDeviceSelectionFormItemComponentProps) => {
  const hook = useAssignDeviceForm();
  const isNonAppSupport = (
    !!intersection(
      [AlternativeDeviveType.BP_CLEAR, AlternativeDeviveType.BP_CELLULAR_PYLO],
      nonSmartPhoneAlternatives
    ).length
  );
  const isLoanerPhoneSupport = (
    !!nonSmartPhoneAlternatives?.includes(AlternativeDeviveType.LOANER_PHONE)
  );
  return (
    <FormItem
      noStyle
      shouldUpdate={hook.shouldUpdate(['techLevel'])}
    >
      {({ getFieldValue }) => {
        const hasBP = includes(vitals, VitalEnumType.BP);
        const techLevel = getFieldValue(hook.getName('techLevel'));
        const showNonAppDevices = (
          isNonAppSupport && (
            (!hasSmartDevice && !isLoanerPhoneSupport && hasBP)
            || techLevel === PatientTechLevelEnum.LOW
          )
        );
        const showLoanerPhone = (
          !showNonAppDevices && !hasSmartDevice
        );

        return (
          <>
            <FormItem
              info={hook.getInfo('loanDevice')}
              className="mb0"
              required={showLoanerPhone}
              hidden={!showLoanerPhone}
              preserve={false}
            >
              <LoanerPhoneDeviceCheckboxComponent
                onChange={() => onChange?.()}
              />
            </FormItem>
            <FormItem
              info={hook.getInfo('deviceList')}
            >
              {
                showNonAppDevices
                  ? (
                    <NonAppDeviceCheckboxComponent
                      vitals={vitals}
                      onChange={onChange}
                      nonSmartPhoneAlternatives={nonSmartPhoneAlternatives || []}
                    />
                  ) : (
                    <DevicesCheckboxGroupComponent
                      vitals={vitals}
                      onChange={onChange}
                    />
                  )
              }
            </FormItem>
          </>
        );
      }}
    </FormItem>
  );
};
