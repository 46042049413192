import { Button } from 'antd';
import React, { FC, ReactNode, useState } from 'react';
import { FoodLogTitleValueItemComponent } from '../FoodLogTitleValueItemComponent/FoodLogTitleValueItemComponent';
import { FoodLogPatientCommentComponent } from '../FoodLogPatientCommentComponent/FoodLogPatientCommentComponent';
import { MealType } from '../../type';
import { VitalType } from '../../../vitals/helper/VitalHelper';
import { useTranslation } from '../../hook/useTranslation';
import './FoodLogMealInfoComponent.scss';

export interface FoodLogMealInfoComponentProps {
  imgUrl: string;
  postedAt?: ReactNode;
  uploadedAt?: ReactNode;
  mealType: MealType;
  vitals: VitalType[];
  note: string | undefined;
}

export const FoodLogMealInfoComponent: FC<FoodLogMealInfoComponentProps> = ({
  imgUrl,
  postedAt,
  uploadedAt,
  mealType,
  vitals,
  note,
}) => {
  const { translate, translatedNote } = useTranslation();
  const [isTranslated, setIsTranslated] = useState(false);
  const showTranslation = note !== '' || undefined || null;

  const handleTranslation = () => {
    if (!isTranslated && note) {
      translate(note);
    }
    setIsTranslated(!isTranslated);
  };

  return (
    <>
      <FoodLogTitleValueItemComponent title="Posted:" content={postedAt} />
      <FoodLogTitleValueItemComponent title="Uploaded:" content={uploadedAt} />
      <FoodLogTitleValueItemComponent title="Meal Type:" content={mealType} />
      <FoodLogTitleValueItemComponent title="Vitals:" content={vitals.join(', ')} />
      <div>
        <img className="mealImg" src={imgUrl} alt="meal-img" />
      </div>
      <div className="my10">
        <div className="mb10">
          <FoodLogTitleValueItemComponent title="Patient's Comment" />
        </div>
        {note && (
        <FoodLogPatientCommentComponent
          comment={isTranslated ? translatedNote || note : note}
        />
        )}
      </div>
      {showTranslation && (
        <div className="translation-link">
          <Button type="link" onClick={handleTranslation}>
            { isTranslated ? 'See Original' : 'See Translation' }
          </Button>
        </div>
      )}
    </>
  );
};
