import { FC } from 'react';
import { Height } from '../../../../uc-api-sdk';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { useHSResultToTable } from '../../hook/useHSResultToTable/useHSResultToTable';
import { FromDateToDateDayjs } from '../../type';
import { MeasurementType } from '../../type/MeasurementTypes';
import { MeasurementTimestampTextComponent } from '../MeasurementTimestampTextComponent/MeasurementTimestampTextComponent';
import { TimeOfDayTableComponent } from '../TimeOfDayTableComponent/TimeOfDayTableComponent';
import { HSTooltipComponent } from '../TooltipComponent/HSTooltipComponent';
import { HSTableCellComponent } from './HSTableCellComponent';

export interface HSTableDataProcessorComponentProps extends FromDateToDateDayjs {
  data: MeasurementType[];
  height?: Height;
}

export const HSTableDataProcessorComponent: FC<HSTableDataProcessorComponentProps> = ({
  data,
  fromDate,
  toDate,
  height
}) => {
  const processedData = useHSResultToTable(data, fromDate, toDate);

  return (
    <>
      <TooltipComponent
        className="mb10 w-mc"
        title={<HSTooltipComponent />}
        placement="topLeft"
        type="info-icon"
        color="white"
        overlayStyle={{ minWidth: '530px' }}
      >
        <span>Log book</span>
      </TooltipComponent>
      <TimeOfDayTableComponent
        data={processedData}
        render={(v) => <HSTableCellComponent data={v} height={height} />}
        className="mb0"
      />
      <MeasurementTimestampTextComponent className="mt10 mb30" />
    </>
  );
};
