import React, { FC } from 'react';
import { Rate, RateProps } from 'antd';

export const FoodLogRate: FC<RateProps> = (
  props,
) => (
  <Rate
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

export default FoodLogRate;
