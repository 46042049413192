import React from 'react';
import dayjs from 'dayjs';
import { PatientInfoComponent } from '../../../doctorVisit/component/PatientInfoComponent/PatientInfoComponent';
import { AccountHelper } from '../../../../helpers/account';
import { PatientInfo } from '../../../../contexts/PatientInfoContext/PatientInfoContext';

export interface PatientVisitCardComponentProps {
  patientInfo?: PatientInfo;
  isLoading?: boolean;
}

export const PatientVisitCardComponent = ({
  patientInfo: pInfo,
  isLoading,
}: PatientVisitCardComponentProps) => (
  <PatientInfoComponent
    isLoading={isLoading}
    id={pInfo?.id || ''}
    fullName={AccountHelper.getFullName(pInfo?.patientInfo?.profile)}
    gender={pInfo?.patientInfo?.profile?.gender ?? undefined}
    dob={dayjs(pInfo?.patientInfo?.profile?.birthday)}
    vitals={pInfo?.enrolledProgramService?.getVitals()}
    programCategories={pInfo?.enrolledProgramService?.getPrograms()}
    complexity={pInfo?.patientComplexity?.level ?? undefined}
    provider={AccountHelper.getFullName(pInfo?.patientInfo?.profile?.doctorUser)}
    lastMeasurementDate={pInfo?.lastMeasurementDate ?? []}
  />
);
