/* eslint-disable react/jsx-props-no-spreading */
import { map } from 'lodash';
import { useMemo } from 'react';
import classNames from 'classnames';
import { TableFormComponent, TableFormComponentProps } from '../../../../../uiComponent/TableFormComponent/TableFormComponent';
import { LLM_LAB_RESULT_FORM_NAME } from '../../hook/useLLMLabResultForm';

import './LLMLabResultTableComponent.scss';
import { LLMLabResultTableItem } from '../../type';
import { TableFormData } from '../../../../../uiComponent/TableFormComponent/type';

export interface LLMLabResultTableComponentProps
  extends Omit<TableFormComponentProps<TableFormData>, 'formName' | 'dataSource'> {
  dataSource?: LLMLabResultTableItem[];
}

export const LLMLabResultTableComponent = ({
  className = '',
  columns,
  dataSource,
  ...props
}: LLMLabResultTableComponentProps) => {
  const dataSourceTableData = useMemo(() => (
    map(dataSource, (item, idx) => ({
      ...item,
      tableDataIndex: idx,
    }))
  ), []);
  const commonTableProps = useMemo(() => ({
    formName: LLM_LAB_RESULT_FORM_NAME,
    columns,
    pagination: false,
  }) as TableFormComponentProps<TableFormData>, [columns]);

  const renderEmpty = () => (<div className="ta-l p10">No result</div>);

  return (
    <TableFormComponent
      {...props}
      {...commonTableProps}
      key="llm-lab-result-table"
      columns={columns}
      dataSource={dataSourceTableData}
      className={
        classNames({
          'llm-lab-result-table': true,
          [className]: !!className,
        })
      }
      locale={{ emptyText: renderEmpty }}
    />
  );
};
