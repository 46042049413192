import {
  Button,
  DatePicker,
  Form,
  Input,
  UploadFile,
} from 'antd';
import { useState } from 'react';
import classNames from 'classnames';
import { UploadComponent, UploadComponentProps } from '../../../../uiComponent/UploadComponent/UploadComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useConsentForm } from '../../../enrollment/hook/useConsentForm/useConsentForm';
import { LinkButton, LinkButtonProps } from '../../../../uiComponent/LinkButton/LinkButton';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { RequiredAsteriskComponent } from '../../../../uiComponent/RequiredAsterisk/RequiredAsteriskComponent';
import { getBase64, pdfStringHeaders } from '../../../../helpers/file';
import { FormOptions } from '../../../../hooks/useFormHookFactory/useFormHookFactory';
import { PDFViewerComponent } from '../../../../uiComponent/PDFViewerComponent/PDFViewerComponent';

import './ConsentFormPaperFormItemComponent.scss';

export interface ConsentFormPaperFormItemComponentProps {
  form?: FormOptions['form'];
}

export const ConsentFormPaperFormItemComponent = ({
  form: _form
}: ConsentFormPaperFormItemComponentProps) => {
  const form = Form.useFormInstance() || _form;
  const consentForm = useConsentForm({ form });
  const [file, setFile] = useState<UploadFile>();
  const isValidFile = (typeof file?.size === 'number' && file?.size > 0);

  const handleUpload: UploadComponentProps['action'] = (file) => {
    getBase64(file, (base64String) => {
      consentForm.form.setFieldValue(
        [consentForm.getName('consentContent')],
        base64String?.replace(pdfStringHeaders, '')
      );
      setFile(file);
    });
    return file.name;
  };

  const handleClearFile: LinkButtonProps['onClick'] = () => {
    setFile(undefined);
    consentForm.form.setFieldsValue({
      [consentForm.getName('consentContent')]: undefined
    });
  };

  const renderUploadButton = () => (
    <>
      <Button
        type="primary"
      >
        Choose file
      </Button>
      <div
        onClick={(e) => e.stopPropagation()}
        aria-hidden
      >
        <TextComponent size="small">
          Please upload the PDF version of the signed consent form.
        </TextComponent>
      </div>
    </>
  );

  const renderUploadedFile = () => (
    <>
      <Input
        value={file?.name}
      />
      <LinkButton
        onClick={handleClearFile}
        useBlueColor
      >
        Clear file
      </LinkButton>
    </>
  );

  return (
    <div className="consent-form-paper-form-item">
      <FormItem
        info={consentForm.getInfo('signDate')}
        preserve={false}
        required
      >
        <DatePicker />
      </FormItem>
      <div className="flex fd-c gap1 ai-s">
        <label
          htmlFor={consentForm.getName('consentContent')}
          className="flex text-gray-scale-2"
        >
          {consentForm.getLabel('consentContent')}
          <RequiredAsteriskComponent />
        </label>
        <UploadComponent
          action={handleUpload}
          accept=".pdf"
          maxCount={1}
          showUploadList={false}
          disabled={isValidFile}
        >
          <div className="flex ai-c">
            {
              !isValidFile
                ? (
                  renderUploadButton()
                ) : (
                  renderUploadedFile()
                )
            }
          </div>
        </UploadComponent>
        <div
          className={classNames({
            'consent-form-paper-form-item__upload-placeholder': true,
            'has-valid-file': isValidFile, // manually control required mark
          })}
        >
          <FormItem
            name={consentForm.getName('consentContent')}
            preserve={false}
            required={!isValidFile}
          >
            <div className="display-none">
              { /* placeholder */}
            </div>
          </FormItem>
        </div>
      </div>
      <div className="consent-form-paper-form-item__pdf-preview">
        <FormItem
          noStyle
          shouldUpdate={consentForm.shouldUpdate(['consentContent'])}
        >
          {
            ({ getFieldValue }) => {
              const consentContent = consentForm.getValue('consentContent', getFieldValue);
              if (!consentContent) {
                return null;
              }
              const fileUrl = `${pdfStringHeaders}${consentContent}`;
              return (
                <PDFViewerComponent
                  title={file?.name}
                  fileUrl={fileUrl}
                  height="100%"
                />
              );
            }
          }
        </FormItem>
      </div>
    </div>
  );
};
