/* eslint-disable react/jsx-props-no-spreading */
import { Checkbox, Input } from 'antd';
import { FC } from 'react';
import { CurrentUserAvatarComponent } from '../../../../uiComponent/CurrentUserAvatarComponent/CurrentUserAvatarComponent';
import { FormItem, FormItemProps } from '../../../../uiComponent/FormItem/FormItem';
import { EditTicketMode } from '../../constants';

export interface TicketCommentFormItemComponentProps {
  placeholder: string;
  showAddCommentOnly?: boolean;
  commentFormItemProps?: FormItemProps;
  generateCareNoteFormItemProps?: FormItemProps;
  commentOnlyFormItemProps?: FormItemProps;
  mode?: EditTicketMode;
}

export const TicketCommentFormItemComponent: FC<TicketCommentFormItemComponentProps> = ({
  placeholder,
  showAddCommentOnly = false,
  commentFormItemProps,
  commentOnlyFormItemProps,
  generateCareNoteFormItemProps,
  mode,
}) => (
  <div className="CommentInputWAvatarComponent flex gap2 mb10">
    <CurrentUserAvatarComponent />
    <div className="RightWrapper w100">
      <FormItem {...commentFormItemProps}>
        <Input placeholder={placeholder} />
      </FormItem>
      <FormItem dependencies={['isCommentOnly']} noStyle>
        {({ getFieldValue }) => {
          const isCommentOnly = getFieldValue('isCommentOnly');
          return !isCommentOnly && mode !== 'Edit' && mode !== 'ReOpen' && (
            <FormItem
              {...generateCareNoteFormItemProps}
              valuePropName="checked"
            >
              <Checkbox>
                Generate a care note after resolving
              </Checkbox>
            </FormItem>
          );
        }}
      </FormItem>
      {showAddCommentOnly && (
        <FormItem {...commentOnlyFormItemProps} valuePropName="checked">
          <Checkbox>
            Add a comment only
          </Checkbox>
        </FormItem>
      )}
    </div>
  </div>
);
