import { PriorityLevelEnum } from '../../../uc-api-sdk';

export const TICKET_PRIORITIES = [
  {
    value: PriorityLevelEnum.HIGH,
    label: 'High',
    type: 'High',
  },
  {
    value: PriorityLevelEnum.MEDIUM,
    label: 'Medium',
    type: 'Medium',
  },
  {
    value: PriorityLevelEnum.LOW,
    label: 'Low',
    type: 'Low',
  },
];
