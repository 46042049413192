import { Divider } from 'antd';
import {
  forEach, groupBy, isEmpty, map, uniqBy
} from 'lodash';
import { TextComponent } from '../../../../../uiComponent/TextComponent/TextComponent';
import { BehaviorGoalItem } from '../../../../../uc-api-sdk';
import { useDeepCompareMemo } from '../../../../../hooks/useDeepCompareEffect';
import { BehaviorGoalCategoryLabelComponent } from '../../../../intervention/component/BehaviorGoalComponent/BehaviorGoalCategoryLabelComponent/BehaviorGoalCategoryLabelComponent';

export interface PrintCarePlanBehavioralGoalComponentProps {
  behavioralGoals?: BehaviorGoalItem[] | null;
}

export const PrintCarePlanBehavioralGoalComponent = ({
  behavioralGoals,
}: PrintCarePlanBehavioralGoalComponentProps) => {
  const processedBehavioralGoals = useDeepCompareMemo(() => {
    const groupedByCategory = groupBy(behavioralGoals, (g) => g.category || g.customCategory);
    // dedup behavioral goal value in each category
    const dedupedBehavioralGoals = {} as Record<string, BehaviorGoalItem[]>;
    forEach(groupedByCategory, (behavioralGoals, category) => {
      const dedupedBehavioralGoalValue = uniqBy(behavioralGoals, (g) => g.behaviorGoalValue);
      dedupedBehavioralGoals[category] = dedupedBehavioralGoalValue;
    });
    return dedupedBehavioralGoals;
  }, [behavioralGoals]);

  if (isEmpty(processedBehavioralGoals)) {
    return null;
  }

  return (
    <>
      <div className="page-break" />
      <TextComponent className="pop-text">
        Behavioral Goal
      </TextComponent>

      {
        map(processedBehavioralGoals, (behavioralGoals, category) => (
          <div key={category} className="mb10">
            <TextComponent size="medium" bold>
              <BehaviorGoalCategoryLabelComponent
                category={behavioralGoals[0].category}
                customCategory={behavioralGoals[0].customCategory}
              />
            </TextComponent>
            {
              map(behavioralGoals, (behavioralGoal, idx) => (
                <div key={idx} className="mb5">
                  {behavioralGoal.behaviorGoalValue}
                </div>
              ))
            }
          </div>
        ))
      }

      <Divider />
    </>
  );
};
