import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import {
  ClinicEventsService
} from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { useFutureClinicEventContext } from '../../../../contexts/FutureClinicEventContext/FutureClinicEventContext';
import OverlayLoading from '../../../../uiComponent/OverlayLoading/OverlayLoading';
import { FollowUpScheduleWithFutureClinicEventsComponent } from '../FollowUpScheduleWithFutureClinicEventsComponent/FollowUpScheduleWithFutureClinicEventsComponent';
import { FollowUpScheduleFormComponent } from '../FollowUpScheduleFormComponent/FollowUpScheduleFormComponent';

export interface FollowUpScheduleComponentProps {
  clinicEvent?: ClinicEventsService | null;
  followupVisitSkipped?: boolean;
}

export const FollowUpScheduleComponent = ({
  clinicEvent,
  followupVisitSkipped = clinicEvent?.followupVisitSkipped,
}: FollowUpScheduleComponentProps) => {
  const {
    futureClinicEventServices,
    hasFutureClinicEvent,
    isLoading,
  } = useFutureClinicEventContext();
  const initialLoading = futureClinicEventServices === undefined;

  if (initialLoading) {
    return <LoadingOverlayComponent isLoading><span /></LoadingOverlayComponent>;
  }

  return (
    <div className="flex fd-c">
      <OverlayLoading loading={isLoading} />
      {
        hasFutureClinicEvent
          ? (
            <FollowUpScheduleWithFutureClinicEventsComponent
              visitId={clinicEvent?.id ?? undefined}
              futureClinicEventServices={futureClinicEventServices}
            />
          ) : (
            <FollowUpScheduleFormComponent
              visitId={clinicEvent?.id ?? undefined}
              canSkipSchedule
              followupVisitSkipped={followupVisitSkipped}
            />
          )
      }
    </div>
  );
};
