import { ModalProps } from 'antd';
import {
  ReactNode,
  createContext,
  useContext,
} from 'react';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';

export interface ModalConfigContextValue extends ModalProps {}

const ModalConfigContext = createContext<
    ModalConfigContextValue | undefined
  >(undefined);

export const useModalConfigContext = () => {
  const context = useContext(ModalConfigContext) || {};
  return context as ModalConfigContextValue;
};

export interface ModalConfigContextProviderProps extends ModalProps {
  children: ReactNode;
}
export const ModalConfigContextProvider = ({
  children,
  ...props
}: ModalConfigContextProviderProps) => {
  const contextValue = useGetContextValue<ModalConfigContextValue>({
    ...props,
    getContainer: props.getContainer || (() => document.body as HTMLDivElement),
  }, [
    props.getContainer,
  ]);

  return (
    <ModalConfigContext.Provider value={contextValue}>
      {children}
    </ModalConfigContext.Provider>
  );
};
