import { TaskService } from './TaskService';
import { AlertThreshold, MeasureTypeEnum } from '../../uc-api-sdk';

export class BGTaskService extends TaskService {
  defaultBeforeMealThreshold: AlertThreshold = {
    measure: MeasureTypeEnum.BEFORE_MEAL,
    unit: 'mmol/L',
    baseline: null,
    baselineUpdatedAt: null,
    range: [
      3.3336,
      4.4448,
      7.2228,
      11.112
    ],
    exerciseGoal: null
  };

  defaultAfterMealThreshold: AlertThreshold = {
    measure: MeasureTypeEnum.AFTER_MEAL,
    unit: 'mmol/L',
    baseline: null,
    baselineUpdatedAt: null,
    range: [
      3.8892,
      5.556,
      10.0008,
      13.89
    ],
    exerciseGoal: null
  };

  public getBeforeMealThreshold() {
    return (
      this.findThresholdByMeasureType(MeasureTypeEnum.BEFORE_MEAL)
      || this.defaultBeforeMealThreshold
    );
  }

  public getAfterMealThreshold() {
    return (
      this.findThresholdByMeasureType(MeasureTypeEnum.AFTER_MEAL)
      || this.defaultAfterMealThreshold
    );
  }
}
