import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { useInterval } from 'usehooks-ts';
import {
  VisitsStatusEnumComponent
} from '../../../../enumComponent/VisitsStatusEnumComponent/VisitsStatusEnumComponent';
import { Nullable, VisitStatusEnum } from '../../../../uc-api-sdk';
import { TimeService } from '../../../../helpers/time/timeService';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { TagComponent } from '../../../../uiComponent/TagComponent/TagComponent';

export interface VisitsStatusComponentProps {
  visitStatus?: Nullable<VisitStatusEnum>;
  startTime?: Dayjs;
}

export const VisitsStatusComponent = ({
  visitStatus,
  startTime,
}: VisitsStatusComponentProps) => {
  const [diffMin, setDiffMin] = useState(-1);
  const reloadVisit = useUpdate('VISIT_UPDATED');

  const shouldRunTimer = (
    startTime
    && visitStatus === VisitStatusEnum.START_SOON
    && TimeService.getDifferenceInSeconds(dayjs(), dayjs(startTime)) >= 0
  );

  useInterval(() => {
    const currentTime = dayjs();
    const diffSec = TimeService.getDifferenceInSeconds(currentTime, dayjs(startTime));
    const newDiffMin = Math.ceil(diffSec / 60);
    setDiffMin(newDiffMin);
    if (diffSec < 1) reloadVisit.updateValue();
  }, shouldRunTimer ? 1000 : null);

  if (shouldRunTimer && diffMin !== -1) {
    return (
      <TagComponent iconType="solidDot" background="none" type="warning">
        {`Start in ${diffMin} mins`}
      </TagComponent>
    );
  }

  return (
    <VisitsStatusEnumComponent value={visitStatus} />
  );
};
