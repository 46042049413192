import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { filter } from 'lodash';
import { useBoolean } from 'usehooks-ts';
import useChangePage from '../../../../hooks/useChangePage/useChangePage';
import { useRouter } from '../../../../hooks/useRouter/useRouter';
import { InFilter, WorkTypeEnum, usePatientCareGetMyPatientCareResponse } from '../../../../uc-api-sdk';
import { usePatientCareTableSearch } from '../../hook/usePatientCareTableSearch';
import { useOnCallContext } from '../OnCallContext/OnCallContext';
import { usePatientCareTableContext } from '../PatientCareTableContext/PatientCareTableContext';
import { useGetPatientCareCount } from '../../../todo/hook/useGetPatientCareCount';
import { PatientCareMoreSettingsValue } from '../../component/PatientCareMoreSettingsComponent/PatientCareMoreSettingsComponent';

export interface PatientCareExtraFilters {
  clinicIds?: string[];
  workTypes?: WorkTypeEnum[];
}

interface Value {
  patientId?: string;
  isLoading: boolean;
  totalPatientCases: number;
  isDone: boolean;
  getNextPatient: () => void;
  goToPatient: (goToPatient: string) => void;
  isWorkMode: boolean;
  startWorkMode: () => void;
  exitWorkMode: () => void;
  resetFiltersAndSorters: () => void;
  filterCount: number;
  patientCareList?: ReturnType<typeof usePatientCareTableSearch>['patients'];
  handleTableChange: ReturnType<typeof usePatientCareTableSearch>['handleTableChange'];
  clinicIds : string[] | undefined;
  handleClinicChange: (ids: string[] | undefined) => void;
  workTypes: WorkTypeEnum[] | undefined;
  handleWorkTypesChange: (items: WorkTypeEnum[] | undefined) => void;
  moreSettings?: PatientCareMoreSettingsValue;
  handleMoreSettingsChange: (value: PatientCareMoreSettingsValue | undefined) => void;
  onCall: ReturnType<typeof useOnCallContext> | undefined;
  workItems?: WorkTypeEnum[];
}

export const PatientCareContext = React.createContext<Value>({
  patientId: undefined,
  isLoading: true,
  totalPatientCases: 0,
  isDone: false,
  getNextPatient: () => { console.error('getNextPatient has not been implemented yet!'); },
  goToPatient: () => { console.error('goToPatient has not been implemented yet!'); },
  isWorkMode: false,
  startWorkMode: () => { console.error('startWorkMode has not been implemented yet!'); },
  exitWorkMode: () => { console.error('exitWorkMode has not been implemented yet!'); },
  resetFiltersAndSorters: () => { console.error('resetFiltersAndSorters has not been implemented yet!'); },
  patientCareList: undefined,
  handleTableChange: () => { console.error('handleTableChange has not been implemented yet!'); },
  filterCount: 0,
  clinicIds: undefined,
  handleClinicChange: () => { console.error('handleClinicChange has not been implemented yet!'); },
  workTypes: undefined,
  handleWorkTypesChange: () => { console.error('handleWorkTypesChange has not been implemented yet!'); },
  moreSettings: undefined,
  handleMoreSettingsChange: () => { console.error('handleMoreSettingsChange has not been implemented yet!'); },
  onCall: undefined,
  workItems: undefined,
});

export interface PatientCareProviderProps {
  children: ReactNode | ((v: Value) => ReactNode);
}

export const PatientCareProvider = ({
  children,
}: PatientCareProviderProps) => {
  const { query } = useRouter();
  const { goToCarePortalPatientCare } = useChangePage();
  const patientId = query.patientId as string | undefined;
  const {
    value: isWorkMode,
    setTrue: startWorkMode,
    setFalse: exitWorkMode,
  } = useBoolean(!!patientId);
  const [excludedPatients, setExcludedPatients] = useState<string[]>([]);
  const { navigate, changePage } = useRouter();
  const patientCareTableContext = usePatientCareTableContext();
  const { value: isDone, setValue: setIsDone } = useBoolean();
  const onCall = useOnCallContext({ type: 'patientCare' });
  const patientSearch = usePatientCareGetMyPatientCareResponse({});

  const patientCareTableSearch = usePatientCareTableSearch();

  const getIdsIn = (excludedIds: string[]) => {
    const { in: idIn } = patientCareTableContext.processedFilter?.idIn as InFilter<string> || {};
    const filteredIds = filter(idIn || [], (v) => !excludedIds.includes(v));
    return filteredIds.length > 0 ? filteredIds : undefined;
  };

  const getFilters = (excludedIds: string[]) => ({
    ...(patientCareTableContext.processedFilter || {}),
    idIn: {
      in: getIdsIn(excludedIds),
      nin: excludedIds,
    },
  });

  const totalPatientCases = useGetPatientCareCount();

  const getPatientSearch = (excludedIds: string[]) => (
    patientSearch.send({
      params: {
        request: {
          patientCareRequest: {
            filter: getFilters(excludedIds),
            pageInfo: {
              page: 1,
              size: 1,
              sort: patientCareTableContext.processedSort || [],
            },
          },
          onCall: onCall.isOnCall,
          includeTestPatient: (
            patientCareTableContext.filter?.includeTestPatient || false
          ) as boolean,
        }
      },
    })
  );

  const handleExitWorkMode = useCallback(() => {
    setExcludedPatients([]);
    exitWorkMode();
    setIsDone(false);
    goToCarePortalPatientCare();
  }, [
    setExcludedPatients,
    exitWorkMode,
    setIsDone,
  ]);

  const handleStartWorkMode = async () => {
    startWorkMode();
    getPatientSearch([]);
  };

  useEffect(() => {
    if (!patientId) {
      handleExitWorkMode();
    }
  }, [patientId]);

  const goToPatient = useCallback((id?: string) => {
    if (id) {
      navigate({
        pathname: changePage.createCarePortalPatientCareUrl({ patientId: id })
      });
    } else {
      patientCareTableContext.reset();
      setIsDone(true);
    }
  }, [
    navigate,
    setIsDone,
    patientCareTableContext.reset,
  ]);

  useEffect(() => {
    if (!patientSearch.isLoading && !!patientSearch.data) {
      const pId = patientSearch.data?.data?.content?.at(0)?.id ?? undefined;
      goToPatient(pId);
    }
  }, [patientSearch.isLoading, patientSearch.data]);

  const getNextPatient = useCallback(() => {
    let excludedIds = [...excludedPatients];
    if (patientId) {
      excludedIds.push(patientId);
    }
    if (patientSearch.data?.data?.totalSize === 1) {
      excludedIds = [];
    }
    setExcludedPatients(excludedIds);
    startWorkMode();
    getPatientSearch(excludedIds);
  }, [patientId, excludedPatients, setExcludedPatients, onCall.isOnCall]);

  const handleClinicChange = useCallback((clinicIds: string[] | undefined) => {
    patientCareTableContext.setFilter((v) => ({ ...v, clinicId: clinicIds ?? null }));
  }, [patientCareTableContext.setFilter]);

  const handleWorkTypesChange = useCallback((workTypes: WorkTypeEnum[] | undefined) => {
    patientCareTableContext.setFilter((v) => ({ ...v, workTypes: workTypes || null }));
  }, [patientCareTableContext]);

  const handleMoreSettingsChange = useCallback((
    moreSetting: PatientCareMoreSettingsValue | undefined
  ) => {
    // @ts-ignore
    // TODO: fix this issue
    patientCareTableContext.setFilter((v) => ({
      ...v,
      includeTestPatient: moreSetting?.includeTestPatient || false,
    }));
  }, [patientCareTableContext]);

  const contextValue: Value = useMemo(() => ({
    patientId,
    totalPatientCases: totalPatientCases.count,
    isLoading: patientSearch.isLoading,
    isDone,
    getNextPatient,
    goToPatient,
    isWorkMode,
    startWorkMode: handleStartWorkMode,
    exitWorkMode: handleExitWorkMode,
    filterCount: patientCareTableContext.filterCount,
    resetFiltersAndSorters: patientCareTableContext.reset,
    handleTableChange: patientCareTableSearch.handleTableChange,
    patientCareList: patientCareTableSearch.patients,
    handleClinicChange,
    clinicIds: (patientCareTableContext.filter?.clinicId ?? undefined) as (string[] | undefined),
    workTypes: (
      patientCareTableContext.filter?.workTypes ?? undefined
    ) as (WorkTypeEnum[] | undefined),
    handleWorkTypesChange,
    moreSettings: ({
      includeTestPatient: (patientCareTableContext.filter?.includeTestPatient || false),
    }) as PatientCareMoreSettingsValue,
    handleMoreSettingsChange,
    onCall,
  }), [
    totalPatientCases,
    patientId,
    patientSearch.isLoading,
    patientSearch.data,
    getNextPatient,
    goToPatient,
    isDone,
    isWorkMode,
    startWorkMode,
    handleStartWorkMode,
    handleExitWorkMode,
    patientCareTableContext.reset,
    patientCareTableContext.filterCount,
    patientCareTableContext.filter,
    patientCareTableSearch.handleTableChange,
    patientCareTableSearch.patients.data,
    patientCareTableSearch.patients.isLoading,
    handleClinicChange,
    handleWorkTypesChange,
    handleMoreSettingsChange,
    onCall,
    onCall.isOnCall,
  ]);

  const renderContent = () => {
    if (typeof children === 'function') {
      return children(contextValue);
    }
    return children;
  };

  return (
    <PatientCareContext.Provider value={contextValue}>
      {renderContent()}
    </PatientCareContext.Provider>
  );
};

export const usePatientCareContext = () => {
  const patientCareContextValue = useContext(PatientCareContext);
  return patientCareContextValue;
};
