import { useCallback, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox } from 'antd';
import { LinkButton } from '../LinkButton/LinkButton';
import { TextComponent } from '../TextComponent/TextComponent';
import UploadFiles from '../../assets/images/AI/Upload-files.png';
import AIProcessing from '../../assets/images/AI/AI-processing.png';
import ReviewImport from '../../assets/images/AI/Review-Import.png';
import MaximizeAccuracy from '../../assets/images/AI/Maximize-accuracy.png';
import HIPAACompliant from '../../assets/images/AI/HIPAA-compliance.png';

import './AITranscribeOnboardingComponent.scss';
import { useDoNotShowAgain } from '../../features/patient/hook/useDoNotShowAlertAgain';
import { useLoggedInUserFromContext } from '../../contexts/loggedInUserContext';

const MAX_STEP = 2;

export interface AITranscribeOnboardingComponentProps {
  onDone?: () => void;
}

export const AITranscribeOnboardingComponent = ({
  onDone,
}: AITranscribeOnboardingComponentProps) => {
  const { userId } = useLoggedInUserFromContext();
  const [
    IsAITranscribeOnboard,
    setIsAITranscribeOnboard,
  ] = useDoNotShowAgain('IsAITranscribeOnboard', userId);
  const [
    step,
    setStep,
  ] = useState(0);
  const [
    isUnderstandChecked,
    setIsUnderstandChecked,
  ] = useState(!!IsAITranscribeOnboard);

  const handleOnDone = () => {
    setIsAITranscribeOnboard(true);
    onDone?.();
  };

  const renderStep = useCallback((step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            <div className="flex gap4 ai-c jc-c">
              <img
                src={UploadFiles}
                alt="1. Upload files"
                className="f1"
              />
              <img
                src={AIProcessing}
                alt="2. AI processing"
                className="f1"
              />
              <img
                src={ReviewImport}
                alt="3. Review then Import"
                className="f1"
              />
            </div>
            <TextComponent color="gray2">
              <div className="flex ai-c jc-c mt70">
                <ExclamationCircleOutlined />
                AI Transcribe isn't 100% accurate and can make mistakes.
              </div>
            </TextComponent>
          </>
        );
      case 1:
        return (
          <>
            <div className="flex gap4 ai-c jc-c">
              <img
                src={MaximizeAccuracy}
                alt="Maximize Accuracy"
                className="f1"
              />
              <img
                src={HIPAACompliant}
                alt="Be HIPAA-Compliant"
                className="f1"
              />
            </div>
            <div className="flex ai-c jc-sb mt70">
              <Checkbox
                defaultChecked={!!IsAITranscribeOnboard}
                onChange={(e) => setIsUnderstandChecked(e.target.checked)}
                disabled={!!IsAITranscribeOnboard}
              >
                I understand all guidelines and will review the AI-processed results.
              </Checkbox>
              <Button
                type="primary"
                onClick={handleOnDone}
                disabled={!isUnderstandChecked}
              >
                Done
              </Button>
            </div>
          </>
        );
      default:
        return null;
    }
  }, [isUnderstandChecked]);

  return (
    <div className="ai-transcribing-onboarding">
      <div className="flex ai-c jc-sb">
        <div className="w140">
          {
            step > 0 && (
              <LinkButton
                useBlueColor
                underlineOnHover
                onClick={() => setStep(step - 1)}
              >
                Back
              </LinkButton>
            )
          }
        </div>
        <TextComponent size="x-large" bold>
          AI Transcribe Guidelines
          (
          {step + 1}
          /
          {MAX_STEP}
          )
        </TextComponent>
        <div className="w140 ta-r">
          {
            (step < MAX_STEP - 1) && (
              <LinkButton
                useBlueColor
                underlineOnHover
                onClick={() => setStep(step + 1)}
              >
                Next
              </LinkButton>
            )
          }
        </div>
      </div>
      <div className="mt50">
        {renderStep(step)}
      </div>
    </div>
  );
};
