import {
  Col, Form, Input
} from 'antd';
import { FormType } from '../../../Input/types';
import { useSocialSupportForm, SubmitValue } from '../../hook/useSocialSupportForm';
import FormItem from '../../../../uiComponent/FormItem/FormItem';

export interface SocialSupportFormComponentProps extends FormType<SubmitValue> {}

export const SocialSupportFormComponent = ({
  isLoading,
  initialValues,
  onSubmit,
  onValuesChange
}: SocialSupportFormComponentProps) => {
  const form = useSocialSupportForm();
  return (
    <Form
      form={form.form}
      onFinish={form.handleSubmit(onSubmit)}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
      disabled={form.formDisabled || isLoading}
      layout="vertical"
      colon={false}
    >
      <div className="flex gap3">
        <Col span={7}>
          <FormItem info={form.getInfo('communitySupport')}>
            <Input />
          </FormItem>
        </Col>
        <Col span={7}>
          <FormItem info={form.getInfo('contactInfo')}>
            <Input />
          </FormItem>
        </Col>
        <Col span={7}>
          <FormItem info={form.getInfo('otherProviders')}>
            <Input />
          </FormItem>
        </Col>
      </div>
    </Form>
  );
};
