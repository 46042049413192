import { NestedFormComponent } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import {
  WorklistBasicInformationFormContainer,
  WorklistBasicInformationFormContainerProps,
} from '../../container/WorklistBasicInformationFormContainer/WorklistBasicInformationFormContainer';
import { WorklistInsuranceFormContainer } from '../../container/WorklistBasicInformationFormContainer/WorklistInsuranceFormContainer';

export interface WorklistBasicInformationComponentProps
  extends WorklistBasicInformationFormContainerProps { }

export const WorklistBasicInformationComponent = ({
  patientId,
  patientInfoService,
  onSubmit,
}: WorklistBasicInformationComponentProps) => (
  <PatientCommonCardComponent
    title="Basic Information"
    updateInfo={patientInfoService.updateInfo}
    content={(<div />)}
    formContent={(
      <>
        <NestedFormComponent>
          <WorklistBasicInformationFormContainer
            patientId={patientId}
            patientInfoService={patientInfoService}
            onSubmit={onSubmit}
          />
        </NestedFormComponent>
        <WorklistInsuranceFormContainer
          patientInfoService={patientInfoService}
        />
      </>
    )}
    showEdit={false}
    isEditing
  />
);
