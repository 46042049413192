export interface MillisecondToMinuteComponentProps {
  value: number;
}

export const MillisecondToMinuteComponent = ({ value }: MillisecondToMinuteComponentProps) => {
  const minutes = Math.ceil(value / 60000);

  return (
    <span>{minutes}</span>
  );
};
