import React, { useCallback, useContext, useMemo } from 'react';
import { map } from 'lodash';
import { useGetContextValue } from '../../hooks/useGetContextValue/useGetContextValue';
import { usePopups } from './usePopups';
import { AFKBillableTimeModalComponent, AFKBillableTimeModalComponentProps } from '../../features/billableTime/component/AFKBillableTimeModalComponent/AFKBillableTimeModalComponent';

export enum PopupTypes {
  AFKModal = 'AFKModal',
}

export interface CarePortalPopupValue {
  open: ReturnType<typeof usePopups<PopupTypes>>['open'];
  close: ReturnType<typeof usePopups<PopupTypes>>['close'];
  isOpen: (popup: PopupTypes) => boolean;
}

export const CarePortalPopupContext = React.createContext<
  CarePortalPopupValue
  | undefined>(undefined);

export interface CarePortalPopupContextProviderProps {
  children: React.ReactNode;
}

export const CarePortalPopupContextProvider = ({
  children,
}: CarePortalPopupContextProviderProps) => {
  const { popups, open, close } = usePopups(PopupTypes);

  const renderPopup = (type: PopupTypes) => {
    switch (type) {
      case PopupTypes.AFKModal:
        return (
          <AFKBillableTimeModalComponent
            key={type}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(popups.AFKModal.args || {} as AFKBillableTimeModalComponentProps)}
            isOpen={popups.AFKModal.isOpen}
            onCancel={() => {
              popups.AFKModal.args.onCancel?.();
              close(PopupTypes.AFKModal);
            }}
          />
        );
      default:
        return null;
    }
  };

  const allPopups = useMemo(() => map(PopupTypes, renderPopup), [popups]);

  const isOpen = useCallback((popup: PopupTypes) => (
    !!popups[popup].isOpen
  ), [popups]);

  const value = useGetContextValue<CarePortalPopupValue>({
    open,
    close,
    isOpen,
  });

  return (
    <CarePortalPopupContext.Provider value={value}>
      {children}
      {allPopups}
    </CarePortalPopupContext.Provider>
  );
};

export const useCarePortalPopupContext = () => {
  const context = useContext(CarePortalPopupContext);
  return (context || {}) as CarePortalPopupValue;
};
