import React, { FC } from 'react';
import { SelectProps } from 'antd';
import { OPTIONS } from './constants';
import { SelectDropdownComponent } from '../../selectComponents/SelectDropdownComponent/SelectDropdownComponent';

export interface MedRncDropdownComponentProps extends SelectProps { }

export const MedRncDropdownComponent: FC<MedRncDropdownComponentProps> = ({
  value,
  onChange,
}) => (
  <SelectDropdownComponent
    options={OPTIONS}
    value={value}
    onChange={onChange}
  />
);
