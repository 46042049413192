import { Button } from 'antd';
import { useState } from 'react';
import { VitalsMonitoringBGTableProcessorComponent, VitalsMonitoringBGTableProcessorComponentProps } from '../VitalsMonitoringBGTableComponent/VitalsMonitoringBGTableProcessor';
import { VitalMonitoringBGScheduleSelectContainer, VitalMonitoringBGScheduleSelectContainerProps } from '../../../../container/VitalMonitoringBGScheduleSelectContainer/VitalMonitoringBGScheduleSelectContainer';
import { InputType } from '../../../../../Input/types';
import useBoolean from '../../../../../../hooks/useBoolean/useBoolean';
import { VitalMonitoringBGScheduleValue } from '../VitalsMonitoringBGTableComponent/VitalMonitoringBGScheduleSelectComponent/VitalMonitoringBGScheduleSelectComponent';
import { MonitoringVital } from '../../../../../../uc-api-sdk';

export interface VitalMonitoringBGInputValue extends MonitoringVital { }

export interface VitalMonitoringBGInputComponentProps
  extends InputType<VitalMonitoringBGInputValue> {
  autoSetDefault?: boolean;
}

export const VitalMonitoringBGInputComponent = ({
  value,
  onChange,
  autoSetDefault,
}: VitalMonitoringBGInputComponentProps) => {
  const [
    bgSchedule,
    setBgSchedule,
  ] = useState<VitalMonitoringBGScheduleValue | undefined>({
    name: value?.templateDetails?.name || '',
    schedule: value?.templateDetails?.schedule || {},
    description: value?.templateDetails?.description || '',
  });
  const { value: isCustomized, setTrue, setFalse } = useBoolean(bgSchedule?.name.includes('(Customized)'));

  const handleOnChange = (newValue: Partial<VitalMonitoringBGInputValue>) => {
    onChange?.({
      ...value,
      ...newValue,
    });
  };

  const handleSelectChange: VitalMonitoringBGScheduleSelectContainerProps['onChange'] = (v) => {
    if (v) {
      setFalse();
      setBgSchedule(v);
      handleOnChange?.({
        templateDetails: {
          name: v.name,
          description: v.description,
          schedule: v.schedule,
        },
      });
    }
  };

  const handleClear = () => {
    setFalse();
    setBgSchedule({
      name: 'Blank Template',
      description: '',
      schedule: {},
    });
    handleOnChange?.({
      templateDetails: {
        name: 'Blank Template',
        description: '',
        schedule: {},
      },
    });
  };

  const handleTableChange: VitalsMonitoringBGTableProcessorComponentProps['onChange'] = (v) => {
    if (isCustomized) {
      if (bgSchedule && v) {
        setBgSchedule((prev) => ({
          name: prev?.name || '',
          description: prev?.description || '',
          schedule: v,
        }));
        handleOnChange?.({
          templateDetails: {
            name: bgSchedule.name,
            description: bgSchedule.description,
            schedule: v,
          },
        });
      }
      return;
    }
    setTrue();
    if (bgSchedule && v) {
      setBgSchedule((prev) => ({
        name: `${prev?.name} (Customized)` || '',
        description: prev?.description || '',
        schedule: v,
      }));
      handleOnChange?.({
        templateDetails: {
          name: `${bgSchedule.name} (Customized)`,
          description: bgSchedule.description,
          schedule: v,
        },
      });
    }
  };

  return (
    <div>
      <VitalMonitoringBGScheduleSelectContainer
        value={bgSchedule}
        onChange={handleSelectChange}
        isCustomized={isCustomized}
        autoSetDefault={autoSetDefault}
      />
      <VitalsMonitoringBGTableProcessorComponent
        value={bgSchedule?.schedule}
        onChange={handleTableChange}
      />
      <div className="flex jc-e">
        <Button type="text" className="no-style-btn" onClick={handleClear}>
          <span className="underline">
            Clear All Selected Times
          </span>
        </Button>
      </div>
    </div>
  );
};
