import { useCallback } from 'react';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import classNames from 'classnames';
import { TreatmentComponent } from '../../enumComponent/TreatmentComponent/TreatmentComponent';
import { TreatmentTypeEnum } from '../../uc-api-sdk';
import { InputType } from '../../features/Input/types';

import './TreatmentCheckboxGroup.scss';
import { CheckboxGroupEnumComponent, CheckboxGroupEnumComponentProps } from '../CheckboxGroupEnumComponent/CheckboxGroupEnumComponent';

export interface TreatmentCheckboxGroupProps extends InputType<TreatmentTypeEnum[]> {
  options?: CheckboxGroupEnumComponentProps<TreatmentTypeEnum>['options'];
  className?: string;
}

export const TreatmentCheckboxGroup = ({
  options = TreatmentTypeEnum,
  value,
  onChange,
  className = '',
}: TreatmentCheckboxGroupProps) => {
  const optionRenderer: CheckboxGroupEnumComponentProps<TreatmentTypeEnum>['optionRenderer'] = useCallback((v) => (
    <TreatmentComponent treatment={v.value} />
  ), []);

  const handleOnChange: CheckboxGroupProps['onChange'] = (values) => {
    onChange?.(values as TreatmentTypeEnum[]);
  };

  return (
    <CheckboxGroupEnumComponent
      options={options}
      optionRenderer={optionRenderer}
      value={value}
      onChange={handleOnChange}
      className={classNames({
        'current-treatment-checkbox-group': true,
        [className]: !!className,
      })}
      spaceProps={{ className: 'flex f-w ai-s current-treatment-checkbox-group__item' }}
    />
  );
};
