import { reduce } from 'lodash';
import { useCallback } from 'react';
import { DayOfWeekEnum, VitalsMonitoringBGTableData, VitalsMonitoringBGTableDayData } from '../../component/VitalsMonitoringScheduleComponent/VitalsMonitoringBGComponent/VitalsMonitoringBGTableComponent/VitalsMonitoringBGTableComponent';
import { BGMonitoringScheduleHelper, ValidWeeklyMealScheduleDay } from '../../helper/BGMonitoringScheduleHelper';
import { DailyMealSchedule } from '../../../../uc-api-sdk';

export const useVitalsMonitoringBGTableToSchedule = () => {
  const dayEnumToDay = (day: DayOfWeekEnum): ValidWeeklyMealScheduleDay => {
    switch (day) {
      case DayOfWeekEnum.Saturday: return 'sat';
      case DayOfWeekEnum.Monday: return 'mon';
      case DayOfWeekEnum.Tuesday: return 'tues';
      case DayOfWeekEnum.Wednesday: return 'wed';
      case DayOfWeekEnum.Thursday: return 'thurs';
      case DayOfWeekEnum.Friday: return 'fri';
      case DayOfWeekEnum.Sunday: return 'sun';
      default: return 'sat';
    }
  };

  const convertBGTableDataToDict = (
    value: VitalsMonitoringBGTableData[],
  ): Record<ValidWeeklyMealScheduleDay, VitalsMonitoringBGTableDayData> => {
    const result = reduce(value, (res, v) => {
      res[dayEnumToDay(v.day)] = v.data;
      return res;
    }, {} as Record<ValidWeeklyMealScheduleDay, VitalsMonitoringBGTableDayData>);
    return result;
  };

  const getData = (v?: VitalsMonitoringBGTableDayData): DailyMealSchedule => ({
    beforeBreakfast: v?.BREAKFAST.beforeMeal || false,
    afterBreakfast: v?.BREAKFAST.afterMeal || false,
    beforeLunch: v?.LUNCH.beforeMeal || false,
    afterLunch: v?.LUNCH.afterMeal || false,
    beforeDinner: v?.DINNER.beforeMeal || false,
    afterDinner: v?.DINNER.afterMeal || false,
    bedtime: v?.BEDTIME || false,
    overnight: v?.OVERNIGHT || false,
  });

  const transform = useCallback((value: VitalsMonitoringBGTableData[]) => {
    const bgDayHash = convertBGTableDataToDict(value);
    const result = reduce(BGMonitoringScheduleHelper.daysOrder, (res, v) => {
      res[v] = getData(bgDayHash[v]);
      return res;
    }, {} as Record<ValidWeeklyMealScheduleDay, DailyMealSchedule>);
    return result;
  }, []);

  return { transform };
};
