import {
  filter, lowerCase, startCase, upperFirst
} from 'lodash';
import { Severity, PoSeverity } from '../type';

interface HasSeverity {
  severity: Severity;
}

interface HasBoChartSeverity {
  poSeverity: PoSeverity;
}

export class SeverityHelper {
  public static getColor(severity: Severity) {
    switch (severity) {
      case 'NORMAL': return 'green';
      case 'RISK': return 'yellow';
      case 'CRITICAL': return 'red';
      case 'MISSED': return 'white';
      default: return 'green';
    }
  }

  public static getPoColor(poSeverity: PoSeverity) {
    switch (poSeverity) {
      case 'CRITICAL_LOW': return '#f0666b';
      case 'LOW': return '#fdb543';
      case 'NORMAL': return '#37cc8d';
      default: return '#37cc8d';
    }
  }

  public static getHexColor(severity: Severity) {
    switch (severity) {
      case 'NORMAL': return '#37cc8d';
      case 'RISK': return '#fdb544';
      case 'CRITICAL': return '#f0656b';
      case 'MISSED': return '#000';
      default: return '#37cc8d';
    }
  }

  public static getMarkerColor(severity: Severity) {
    switch (severity) {
      case 'CRITICAL': return '#EE6161';
      case 'RISK': return '#FDBE43';
      default: return '';
    }
  }

  public static getText(severity: Severity) {
    return upperFirst(severity.toLocaleLowerCase());
  }

  public static getPoSeverityText(poSeverity: PoSeverity) {
    return startCase(lowerCase(poSeverity));
  }

  static getNormal<T extends HasSeverity>(measurements: T[]) {
    return filter(measurements, (v) => v.severity === 'NORMAL');
  }

  static getRisk<T extends HasSeverity>(measurements: T[]) {
    return filter(measurements, (v) => v.severity === 'RISK');
  }

  static getCritical<T extends HasSeverity>(measurements: T[]) {
    return filter(measurements, (v) => v.severity === 'CRITICAL');
  }

  static getBoChartNormal<T extends HasBoChartSeverity>(measurements: T[]) {
    return filter(measurements, (v) => v.poSeverity === 'NORMAL');
  }

  static getBoChartLow<T extends HasBoChartSeverity>(measurements: T[]) {
    return filter(measurements, (v) => v.poSeverity === 'LOW');
  }

  static getBoChartCriticalLow<T extends HasBoChartSeverity>(measurements: T[]) {
    return filter(measurements, (v) => v.poSeverity === 'CRITICAL_LOW');
  }
}
