import { Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';

export interface TranscribeDrawerNextPatientButtonComponentProps {
  isLoading?: boolean;
  disabled?: boolean;
  onClick: () => void;
  buttonText?: ReactNode;
  icon?: ReactNode;
}

export const TranscribeDrawerNextPatientButtonComponent = ({
  isLoading,
  disabled,
  onClick,
  buttonText = 'Next Patient',
  icon = <RightOutlined />,
}: TranscribeDrawerNextPatientButtonComponentProps) => (
  <Button
    onClick={onClick}
    data-testid="transcribeNextPatientBtn"
    loading={isLoading}
    disabled={disabled}
  >
    {buttonText}
    {icon}
  </Button>
);
