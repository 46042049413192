import { groupBy } from 'lodash';
import { BehaviorGoalItem } from '../../../uc-api-sdk';

export const useBehaviorGoalHelpers = (behaviorGoals?: BehaviorGoalItem[]) => {
  const groupBehaviorGoalsByType = (_behaviorGoals = behaviorGoals) => (
    groupBy(_behaviorGoals, 'behaviorGoalType')
  );

  const groupBehaviorGoalsByCategory = (_behaviorGoals = behaviorGoals) => (
    groupBy(_behaviorGoals, (goal) => goal.category || goal.customCategory)
  );

  return {
    groupBehaviorGoalsByType,
    groupBehaviorGoalsByCategory,
  };
};
