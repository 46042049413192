import { ReactNode } from 'react';
import classNames from 'classnames';

import './PatientCommonLabelComponent.scss';

export interface PatientCommonLabelComponentProps {
  children: ReactNode;
  className?: string;
}

export const PatientCommonLabelComponent = ({
  children,
  className = '',
}: PatientCommonLabelComponentProps) => (
  <div
    className={classNames({
      'patient-common-label': true,
      [className]: !!className,
    })}
  >
    {children}
  </div>
);
