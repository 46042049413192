import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { AddressFormItemComponent } from '../../../../uiComponent/AddressFormItemComponent/AddressFormItemComponent';
import { useOrderFormHook } from '../../hook/useOrderFormHook';
import { DeviceDeliveryMethodEnum } from '../../../../uc-api-sdk';

export interface OrderShippingAddressFormItemComponentProps { }

export const OrderShippingAddressFormItemComponent = () => {
  const orderForm = useOrderFormHook();
  return (
    <FormItem
      noStyle
      shouldUpdate={orderForm.shouldUpdate(['deliveryMethod'])}
    >
      {
        ({ getFieldsValue }) => {
          const isAnyOrderItemChecked = orderForm.isAnyOrderItemChecked(getFieldsValue);
          const { deliveryMethod } = getFieldsValue();
          if (!isAnyOrderItemChecked) {
            return null;
          }
          if (deliveryMethod === DeviceDeliveryMethodEnum.SHIP) {
            return (
              <AddressFormItemComponent
                showCopyButton
                required
              />
            );
          }
          return null;
        }
      }
    </FormItem>
  );
};
