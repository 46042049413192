import { useReactToPrint } from 'react-to-print';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button';
import {
  ReactNode,
  useCallback,
  useRef
} from 'react';

import './PrintButtonComponent.scss';

export interface PrintButtonComponentProps {
  children?: ReactNode;
  buttonText?: ReactNode;
  buttonType?: ButtonProps['type'];
  documentTitle?: string;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: (handlePrint: (delayMs?: number) => void) => void;
}

export const PrintButtonComponent = ({
  children,
  buttonText = 'Print',
  buttonType,
  isLoading,
  disabled,
  documentTitle,
  className,
  onClick,
}: PrintButtonComponentProps) => {
  const componentRef = useRef(null);

  const reactToPrintContent = useCallback(() => componentRef.current, [
    componentRef.current,
    isLoading,
  ]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle,
    removeAfterPrint: true,
  });

  const handlePrintCallback = (delayMs = 250) => {
    setTimeout(() => {
      handlePrint();
    }, delayMs); // delay to let children
  };

  const handleOnClick = () => {
    if (children || !onClick) {
      handlePrint();
      return;
    }
    onClick(handlePrintCallback);
  };

  return (
    <>
      <Button
        onClick={handleOnClick}
        disabled={disabled || isLoading}
        loading={isLoading}
        type={buttonType}
        className={className}
      >
        {buttonText}
      </Button>
      <div className="display-none">
        <div className="p20" ref={componentRef}>
          {!isLoading && children}
        </div>
      </div>
    </>
  );
};
