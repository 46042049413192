import { CalendarSourceEnum } from '../../../../uc-api-sdk';
import { RadioGroupComponent } from '../../../../uiComponent/RadioGroupComponent/RadioGroupComponent';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { ADMIN_PORTAL_TEXTS } from '../../../adminPortal2/constants/texts';
import { InputType } from '../../../Input/types';
import './EventTypeRadioGroupComponent.scss';

export interface EventTypeRadioGroupComponentProps extends InputType<CalendarSourceEnum> {

}

export const EventTypeRadioGroupComponent = ({
  value,
  onChange,
  disabled,
}: EventTypeRadioGroupComponentProps) => (
  <RadioGroupComponent<CalendarSourceEnum>
    onChange={onChange}
    disabled={disabled}
    value={value}
    options={[
      {
        label: 'Visit',
        value: CalendarSourceEnum.UC_VISIT,
      },
      {
        label: (
          <TooltipComponent
            className="other-event-tooltip"
            type="question-icon"
            overlay={ADMIN_PORTAL_TEXTS.tooltips.otherEventsTooltip}
            highlightOnHover={false}
            overlayClassName="other-event-tooltip-overlay"
          >
            Other Event
          </TooltipComponent>
        ),
        value: CalendarSourceEnum.OTHER,
      },
    ]}
  />
);
