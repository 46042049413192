import classNames from 'classnames';
import { MessageSubType } from '../../../../services/CHSServices/types/data';
import { TextWithLinkComponent } from '../../../../uiComponent/TextWithLinkComponent/TextWithLinkComponent';
import { MessagePopoverComponent } from './MessagePopoverComponent';
import { useTranslation } from '../../../foodLog/hook/useTranslation';
import { TranslatedContentComponent } from '../../../foodLog/component/TranslatedContentComponent/TranslatedContentComponent';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import './MessageTextComponent.scss';

export interface MessageTextItem {
  isPatient?: boolean;
  text?: string;
  subType?: MessageSubType;
  translation?: string;
  originalText?: string;
  publisher?: string;
}
export interface MessageTextComponentProps {
  message?: MessageTextItem;
  messageId: string;
}

export const MessageTextComponent = ({
  message,
  messageId,
}: MessageTextComponentProps) => {
  const {
    isPatient, text, subType, publisher, originalText
  } = message || {};

  // check if the message is a translation then show the translation ui
  const isTranslation = message?.translation;

  // unique id for each message
  const mid = `mid-${messageId}`;

  const { userId } = useLoggedInUserFromContext();
  const isLoginUser = userId === publisher;

  // translation api hook
  const { translate, storedTranslation, showTranslation } = useTranslation(messageId);

  const handleTranslate = async () => {
    await translate(text || '');
  };

  const renderText = () => {
    const isSystemMessage = subType === MessageSubType.SYSTEM;
    if (isSystemMessage || isPatient === false) {
      return <TextWithLinkComponent value={text || ''} />;
    }
    return <div>{text}</div>;
  };

  // renders the content based on translation settings
  const renderContent = () => {
    if (showTranslation && storedTranslation) {
      return (
        <TranslatedContentComponent
          isPatient={isPatient}
          messageHistoryArea
          translatedText={storedTranslation || ''}
          originalText={text || ''}
        />
      );
    } if (isTranslation) {
      return (
        <TranslatedContentComponent
          translatedText={message.translation || ''}
          originalText={originalText || ''}
        />
      );
    }
    return renderText();
  };

  const content = (
    <p
      className={classNames({
        'message-text': true,
        'message-item__message-content': true,
        'message-item__message-primary': isPatient,
      })}
    >
      {renderContent()}
    </p>
  );

  return (
    <div
      id={mid}
      className="relative"
    >
      {isLoginUser ? (
        content
      ) : (
        <MessagePopoverComponent
          containerId={mid}
          showTranslation={showTranslation}
          handleTranslate={handleTranslate}
        >
          {content}
        </MessagePopoverComponent>
      )}
    </div>
  );
};
