import { ReactNode } from 'react';
import { map } from 'lodash';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import { AverageValue } from '../../../../uc-api-sdk';

import './MeasurementAverageTooltipComponent.scss';

export interface MeasurementAverageTooltipComponentProps<T extends AverageValue> {
  tooltipTitle: ReactNode;
  valueRenderer: (value: T) => ReactNode; // return null if need empty render
  averageValue: T[];
  children?: ReactNode;
  emptyValueText?: ReactNode;
}

export const MeasurementAverageTooltipComponent = <T extends AverageValue>({
  tooltipTitle,
  valueRenderer,
  children,
  averageValue,
  emptyValueText = 'N/A',
}: MeasurementAverageTooltipComponentProps<T>) => {
  const renderTitle = () => {
    if (!averageValue || !averageValue.length) {
      return null;
    }
    return (
      <div className="flex fd-c gap2">
        <TextComponent bold>
          {tooltipTitle}
        </TextComponent>
        <div className="flex fd-c gap2 ai-c jc-s">
          {
            map(averageValue, (value, idx) => (
              <div
                key={idx}
                className="flex ai-c w100"
              >
                <TextComponent bold className="value-data">
                  {
                    valueRenderer(value)
                    || <EmptyComponent type="reactNode" text={emptyValueText} />
                  }
                </TextComponent>
                <div className="flex ai-c ml10 value-info">
                  <DisplayDateComponent value={value.startTime} format="USA_DATE" />
                  <span>-</span>
                  <DisplayDateComponent value={value.endTime} format="USA_DATE" />
                  <span>
                    {/* ie (1 meas.) */}
                    (
                    {value?.totalMeasInRange ?? 0}
                    {' '}
                    meas.
                    )
                  </span>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    );
  };

  return (
    <TooltipComponent
      type={null}
      title={renderTitle()}
      overlayClassName="measurement-average-tooltip"
    >
      {children}
    </TooltipComponent>
  );
};
