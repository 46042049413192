import classNames from 'classnames';
import { isNil } from 'lodash';
import { useLoggedInUserFromContext } from '../../../contexts/loggedInUserContext';
import { ClinicStatusEnumComponent } from '../../../enumComponent/ClinicStatusEnumComponent/ClinicStatusEnumComponent';
import { ClinicStatus, LimitedClinic, MedicalOrganizationStatus } from '../../../uc-api-sdk';

export interface ClinicNameDisplayComponentProps {
  clinic?: LimitedClinic;
  emptyText?: string;
  name?: string | null;
  city?: string | null;
  state?: string | null;
  nickname?: string | null;
  showSequenceNum?: boolean;
  showBusinessName?: boolean;
  showNickname?: boolean;
  showCityState?: boolean;
  showStatus?: boolean;
}

export const useGetClinicName = (props?: ClinicNameDisplayComponentProps) => {
  const {
    clinic = {},
    name = clinic?.businessName,
    nickname = clinic?.nickName || clinic?.alias,
    emptyText = '--',
    showSequenceNum = true,
    showBusinessName: shouldShowBusinessName,
    showNickname: shouldShowNickname = true,
    showCityState,
  } = props || {} as ClinicNameDisplayComponentProps;

  const { isProvider } = useLoggedInUserFromContext();
  const showBusinessName = isProvider || shouldShowBusinessName;
  const showNickname = isProvider ? false : shouldShowNickname;

  const getText = (value?: string | null) => (
    (value || emptyText)
  );
  const getSequenceNumber = (
    sequenceNum?: string | null,
  ) => {
    if (!sequenceNum) {
      return '';
    }
    let sequenceNumString = '';
    if (typeof sequenceNum === 'number') {
      sequenceNumString = `${sequenceNum}`;
    } else {
      sequenceNumString = sequenceNum;
    }
    return `${getText(sequenceNumString)} `;
  };
  const getCityState = (
    city?: string | null,
    state?: string | null,
  ) => {
    if (city || state) {
      return `(${getText(city || state)})`;
    }
    if (city && state) {
      return `(${getText(city)}/${getText(state)})`;
    }
    return null;
  };

  const getClinicName = (givenClinic = clinic) => {
    const {
      city,
      state,
    } = givenClinic;
    const gBusinessName = (
      givenClinic.businessName || name
    );
    const gNickname = (
      givenClinic.nickName || givenClinic.alias || nickname
    );

    let clinicName = '';
    if (!isProvider && showSequenceNum) {
      clinicName += getSequenceNumber(givenClinic.orgNumber || givenClinic.sequenceNumber);
    }

    if (showBusinessName) {
      clinicName += getText(gBusinessName);
    }

    if (showNickname && !isNil(gNickname)) {
      if (showBusinessName) {
        clinicName += '/';
      }

      clinicName += getText(gNickname);
    }

    if (showCityState) {
      clinicName += getCityState(city, state);
    }

    return clinicName;
  };

  return {
    clinicName: getClinicName(),
    getClinicName,
  };
};

// By default
// Provider: show only Business name
// Non-provider: show org number + nickName
// IMPORTANT: when update this component, please update ClinicSelectContainerHelper.makeSearchable
// TODO: [t][3789] add test
export const ClinicNameDisplayComponent = ({
  ...props
}: ClinicNameDisplayComponentProps) => {
  const { getClinicName } = useGetClinicName({ ...props });
  const errorEnums = [
    MedicalOrganizationStatus.TERMINATED,
    MedicalOrganizationStatus.TERMINATION_INITIATED,
    ClinicStatus.TERMINATED,
    ClinicStatus.TERMINATION_INITIATED
  ];
  return (
    <>
      {props.showStatus
        && (
        <div className={classNames({
          danger: !!errorEnums.includes(props.clinic?.medicalOrgStatus as MedicalOrganizationStatus)
            || errorEnums.includes(props.clinic?.clinicStatus as ClinicStatus),
        })}
        >
          &#91;
          <ClinicStatusEnumComponent
            value={props.clinic?.medicalOrgStatus || props.clinic?.clinicStatus || undefined}
          />
          &#93;
        </div>
        )}
      {getClinicName()}
    </>
  );
};
