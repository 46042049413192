import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import './Title.scss';

export interface PageTitleProps {
  children: ReactNode;
  className?: string;
  showMarginTop?: boolean;
  showMarginBot?: boolean;
}

export const PageTitle: FC<PageTitleProps> = ({
  children,
  className,
  showMarginTop = true,
  showMarginBot = true,
}) => (
  <div
    className={classNames({
      'page-title': true,
      mt32: showMarginTop,
      mb32: showMarginBot,
      [className || '']: true
    })}
  >
    {children}
  </div>
);
