import { TablePaginationConfig } from 'antd';
import { useEffect } from 'react';
import { useBoolean, useUpdateEffect } from 'usehooks-ts';
import { isEmpty, isNil } from 'lodash';
import { StorageKeyEnum, useSessionStorage } from '../useSessionStorage';
import {
  Filter,
  SetFilters,
  SetSorters,
  Sorter,
} from '../useTableChange/useTableChange';

export interface UseTableCacheArg<T, F, S> {
  tableName?: string;
  pagination: TablePaginationConfig;
  filters: Filter;
  sorters: Sorter<T>;
  processedFilters: F;
  processedSorters: S;
  setPagination: (pagination: TablePaginationConfig) => void;
  setFilters: SetFilters;
  setSorters: SetSorters<T>;
  cachePagination?: boolean;
}

export const useTableCache = <T, F, S>({
  tableName,
  pagination,
  filters,
  sorters,
  processedFilters,
  processedSorters,
  setPagination,
  setFilters,
  setSorters,
  cachePagination = true,
}: UseTableCacheArg<T, F, S>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tablesInfo, setTablesInfo] = useSessionStorage<Record<string, any>>(
    StorageKeyEnum.TABLES_FILTERS_SORTERS
  );
  const { value: hasUpdatedFromCache, setTrue } = useBoolean();

  useEffect(() => {
    if (!tableName) return;
    const tableInfo = tablesInfo?.[tableName];
    if (!hasUpdatedFromCache && tableInfo) {
      if (!isEmpty(tableInfo.filters)) {
        setFilters(tableInfo.filters);
      }
      if (!isEmpty(tableInfo.sorters)) {
        setSorters(tableInfo.sorters);
      }
      if (cachePagination && !isNil(tableInfo.pagination)) {
        setPagination(tableInfo.pagination);
      }
    }
    setTrue();
  }, []);

  useUpdateEffect(() => {
    if (!tableName) return;
    const newInfo = { ...(tablesInfo || {}) };
    newInfo[tableName] = {
      ...(tablesInfo?.[tableName] || {}),
      filters,
      sorters,
      processedFilters,
      processedSorters,
      pagination: cachePagination ? pagination : undefined,
    };
    setTablesInfo(newInfo);
  }, [
    filters,
    sorters,
    processedFilters,
    processedSorters,
  ]);
};
