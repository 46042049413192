import dayjs, { Dayjs } from 'dayjs';
import { uniq } from 'lodash';
import {
  useCallback, useMemo, useState
} from 'react';
import { View } from 'react-big-calendar';
import { URL_DATE } from '../../../constants/timeFormat';
import { useLoggedInUserFromContext } from '../../../contexts/loggedInUserContext';
import { CalendarPageParams } from '../../../hooks/useChangePage/types';
import { Assignee } from '../../../services/PatientAssigneesService';
import { ClinicEvent, VisitTypeEnum } from '../../../uc-api-sdk';
import { allVisitTypes } from '../../visit/constants';
import { DEFAULT_VISIT_DURATION_MINUTES, NewVisit, useCreateVisitHelper } from '../../visit/hook/useCreateVisitHelper';
import { FromDateToDateDayjs } from '../../vitals/type';
import { CalendarEvent } from '../component/BigCalendarComponent/BigCalendarComponent';
import { CalendarClinicViewFilters } from '../component/CalendarClinicEventViewComponent/CalendarClinicEventViewComponent';
import { CalendarPageSideComponentProps } from '../component/CalendarPageSideComponent/CalendarPageSideComponent';
import { CalendarType } from '../component/CalendarTypeComponent/CalendarTypeComponent';
import { NEW_EVENT_ID } from '../constant';
import { CalendarContainerProps } from '../container/CalendarContainer/CalendarContainer';
import { useCalendarContext } from '../context/CalendarContext/CalendarContext';
import { BigCalendarHelper } from '../helper/BigCalendarHelper/BigCalendarHelper';

export interface VisitInitialValue {
  dateTime: Dayjs;
  patientId: string;
  visitType?: VisitTypeEnum;
  assignees: Assignee[];
  showFormOnMount?: boolean;
}

export interface CalendarClinicViewFilterValue extends CalendarClinicViewFilters {
  isActive?: boolean;
}

export interface UseCalendarHelperArg {
  visitInitialValue?: VisitInitialValue;
  includeCurrentUser?: boolean;
}

const initialClinicViewFilters = {
  isActive: false,
  clinicId: '',
  visitType: allVisitTypes,
  otherEvents: true,
};

export const useCalendarHelper = (arg: UseCalendarHelperArg = {}) => {
  const { setParams, date: contextDate, participants } = useCalendarContext();
  const { userId: currentUserId } = useLoggedInUserFromContext();
  const [date, setDate] = useState<Dayjs>(dayjs(contextDate, URL_DATE));
  const [view, setView] = useState<View>('week');
  const [clinicId, setClinicId] = useState<string>();
  const [calendarType, setCalendarType] = useState<CalendarType>({
    showGoogleCalendar: true,
    visitType: allVisitTypes,
    showMyOtherEvents: true,
  });
  const [clinicViewFilters, setClinicViewFilters] = useState<CalendarClinicViewFilterValue>({
    ...initialClinicViewFilters
  });
  const [employeeIds, setEmployeeIdsBase] = useState<string[]>(participants || []);

  const handleClinicViewFiltersChange = useCallback((
    value: CalendarClinicViewFilterValue = {
      // reset
      ...initialClinicViewFilters
    }
  ) => {
    setClinicViewFilters((prev) => {
      const newValue = {
        ...prev,
        ...value,
      };
      newValue.isActive = !!newValue.clinicId;
      return newValue;
    });
  }, []);

  const {
    selectedEvent,
    deselectEvent,
    visitInfo,
    handleSelectNew,
    handleSelectExisting,
    handleSelectNewWithInitialValues,
    removeNewEvent,
    visitForm,
  } = useCreateVisitHelper({
    onEmployeeIdChange: setEmployeeIdsBase,
    onDateChange: setDate,
  });

  const getTimeWith15MinuteInterval = (date: Dayjs, time: Dayjs) => {
    const hours = time.get('hours');
    const minutes = (Math.round(time.get('minutes') / 15) + 1) * 15;
    return date.clone().startOf('day').add(hours, 'hours').add(minutes, 'minutes');
  };

  const createVisitWithInitialValues = () => {
    if (arg.visitInitialValue) {
      handleSelectNewWithInitialValues({
        ...arg.visitInitialValue,
        dateTime: getTimeWith15MinuteInterval(
          arg.visitInitialValue.dateTime,
          arg.visitInitialValue.dateTime,
        ),
      });
    }
  };

  const fromDateToDate = useMemo<FromDateToDateDayjs>(() => {
    const unit = BigCalendarHelper.viewToDayjsUnit(view);
    return {
      fromDate: date.clone().startOf(unit),
      toDate: date.clone().endOf(unit),
    };
  }, [
    view,
    date,
  ]);

  const clinicIds = useMemo<CalendarContainerProps['clinicIds']>(() => {
    if (clinicId) {
      return [clinicId];
    }
    if (calendarType.screenedPatientsClinic) {
      return [calendarType.screenedPatientsClinic];
    }
    return undefined;
  }, [clinicId, calendarType.screenedPatientsClinic]);

  const employeeCalendar = useMemo(() => {
    if (clinicId) return [];
    let employeeArr: string[] = [];
    if (employeeIds && employeeIds.length > 0) {
      employeeArr = employeeArr.concat(employeeIds);
    }
    if (
      currentUserId
      // only show current user calendar if any visit type is selected
      // or google calendar events is selected (my calendar checkboxes)
      && (
        calendarType.showGoogleCalendar
        || calendarType.showMyOtherEvents
        || (calendarType.visitType?.length || 0) > 0
      )
    ) {
      employeeArr.push(currentUserId);
    }
    return uniq(employeeArr);
  }, [currentUserId, employeeIds, calendarType, clinicId]);

  const moveNewVisitDate = (newD: Dayjs) => {
    const setDay = (oldD: Dayjs) => (
      oldD.clone()
        .set('date', newD.get('date'))
        .set('month', newD.get('month'))
        .set('year', newD.get('year'))
    );
    if (selectedEvent && selectedEvent.isEdit) {
      handleSelectNew({
        start: setDay(dayjs(selectedEvent.start)).toDate(),
        end: setDay(dayjs(selectedEvent.end)).toDate(),
      });
    } else {
      setParams({});
      deselectEvent();
    }
  };

  const handleFromToDate = (date: Dayjs, v: View) => {
    setView(v);
    setDate(date);
    moveNewVisitDate(date);
  };

  const handleMiniCalendarDateChange: CalendarPageSideComponentProps['onDateChange'] = (d) => {
    const newD = dayjs(d);
    setDate(newD);
    handleFromToDate(newD, view);
  };

  const handleNavigate: CalendarContainerProps['onNavigate'] = (d, v) => {
    const newD = dayjs(d);
    setDate(newD);
    setView(v);
    handleFromToDate(newD, v);
  };

  const handleView: CalendarContainerProps['onView'] = (v) => {
    setView(v);
    handleFromToDate(date, v);
  };

  // only need to use filter if the user is not in the "clinic visit view"
  const getFilter = <T, >(filter: T) => (clinicId ? undefined : filter);

  const handleCreateButtonClick = useCallback(() => {
    const now = dayjs();
    const start = getTimeWith15MinuteInterval(date, now);
    const end = start.clone().add(DEFAULT_VISIT_DURATION_MINUTES, 'minutes');

    setParams({});
    if (arg.visitInitialValue) {
      handleSelectNewWithInitialValues({
        ...arg.visitInitialValue,
        dateTime: start,
      });
    } else {
      handleSelectNew({
        start: start.toDate(),
        end: end.toDate(),
      });
    }
  }, [date]);

  const handleSelect = useCallback<Exclude<CalendarContainerProps['onSelect'], undefined>>((v) => {
    if (v.id === NEW_EVENT_ID) {
      setParams({});
    } else {
      const params: CalendarPageParams | undefined = v.id ? {
        eventId: v.id,
        date: dayjs(v.start).format(URL_DATE),
      } : undefined;
      setParams(params);
    }
    handleSelectExisting(v as CalendarEvent<ClinicEvent>);
  }, []);

  const handleSelectNewSlot = useCallback<Exclude<CalendarContainerProps['onSelect'], undefined>>((v) => {
    setParams({});
    // there is an event selected and is in view mode
    // then deselect it if an empty slot was clicked
    // but don't create a new popup
    if (selectedEvent && !selectedEvent.isEdit) {
      deselectEvent();
      return;
    }
    // disable selecting new slot when the calendar is
    // in clinic view
    if (clinicId) {
      return;
    }
    if (arg.visitInitialValue && !selectedEvent) {
      handleSelectNewWithInitialValues({
        ...arg.visitInitialValue,
        dateTime: dayjs(v.start),
      });
    } else {
      handleSelectNew(v as NewVisit);
    }
  }, [selectedEvent, deselectEvent, setParams, clinicId]);

  const handleEventDrop = useCallback<Exclude<CalendarContainerProps['onEventDrop'], undefined>>((v) => {
    handleSelectNew(v as NewVisit);
  }, []);

  const selectedDate = useMemo(() => (
    selectedEvent?.start ? dayjs(selectedEvent.start) : date
  ), [date, selectedEvent]);

  const onPopoverClose = useCallback(() => {
    setParams({});
    removeNewEvent();
  }, [removeNewEvent, setParams]);

  return {
    selectedEvent,
    selectedDate,
    visitInfo,
    visitForm,
    fromDateToDate,
    clinicId,
    setClinicId,
    clinicIds,
    calendarType,
    setCalendarType,
    employeeIdsWithoutCurrent: employeeIds.filter((id) => id !== currentUserId),
    employeeIds: getFilter(arg.includeCurrentUser ? employeeCalendar : employeeIds),
    setEmployeeIds: setEmployeeIdsBase,
    showMyGoogleEvents: calendarType.showGoogleCalendar,
    visitTypes: getFilter(calendarType.visitType),
    showMyOtherEvents: calendarType.showMyOtherEvents,
    handleMiniCalendarDateChange,
    handleNavigate,
    view,
    handleView,
    handleCreateButtonClick,
    handleSelect,
    handleSelectNewSlot,
    handleEventDrop,
    onPopoverClose,
    createVisitWithInitialValues,
    deselectEvent,
    clinicViewFilters,
    handleClinicViewFiltersChange,
  };
};
