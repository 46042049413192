import { Checkbox, CheckboxProps } from 'antd';
import classNames from 'classnames';

import './CheckboxCardComponent.scss';

export interface CheckboxCardComponentProps extends CheckboxProps {
  checkboxOnly?: boolean;
}

export const CheckboxCardComponent = ({
  className = '',
  children,
  checked,
  value = checked,
  checkboxOnly = false, // if true; prevent check action when click outside of checkbox
  ...props
}: CheckboxCardComponentProps) => (
  <Checkbox
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    checked={value}
    className={classNames({
      'checkbox-card': true,
      'checkbox-only': checkboxOnly,
      [className]: !!className,
    })}
  >
    {
      checkboxOnly
        ? (
          <div
            className="checkbox-children"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            aria-hidden
          >
            {children}
          </div>
        ) : children
    }
  </Checkbox>
);
