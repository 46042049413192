import React from 'react';

interface TotalMeasurementCountDisplayProps {
  value?: number;
  totalCount?: number;
}

export const TotalMeasurementCountDisplayComponent = ({
  value,
  totalCount
}: TotalMeasurementCountDisplayProps) => {
  if (value === undefined || totalCount === undefined || !value || !totalCount) {
    return <span>0</span>;
  }

  const percentage = (
    <span className="text-secondary-gray">
      {`(${((value / totalCount) * 100).toFixed(0)}%)`}
    </span>
  );
  return (
    <span>
      {value}
      {percentage}
    </span>
  );
};
