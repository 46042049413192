import { useMemo } from 'react';
import { StorageKeyEnum, useSessionStorage } from '../useSessionStorage';
import { Filter, Sorter, TableCacheNameEnum } from '../useTableChange/useTableChange';

export interface TableFiltersSorters<T, F, S> {
  sorters: Sorter<T>;
  filters: Filter;
  processedFilters: F;
  processedSorters: S;
}

export const useTableCacheFilters = <T, F, S>(name: TableCacheNameEnum) => {
  const [tablesInfo] = useSessionStorage<Record<string, unknown>>(
    StorageKeyEnum.TABLES_FILTERS_SORTERS
  );

  const response = useMemo(() => {
    const info = tablesInfo?.[name];
    return info;
  }, [tablesInfo, name]);

  return response as TableFiltersSorters<T, F, S>;
};
