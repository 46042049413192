import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { USA_DATE } from '../../../../constants/timeFormat';
import { GenderEnum, Nullable } from '../../../../uc-api-sdk';
import { GenderEnumComponent } from '../../../../enumComponent/GenderEnumComponent/GenderEnumComponent';

export interface PatientDobGenderComponentProps {
  dob?: Dayjs| undefined;
  gender?: Nullable<GenderEnum>;
}

export const PatientDobGenderComponent = ({
  dob,
  gender,
}: PatientDobGenderComponentProps) => (
  <span>
    {`${dob?.format(USA_DATE)}, ${dayjs().diff(dob, 'y')} yrs`}
    {gender && (
    <>
      {', '}
      <GenderEnumComponent value={gender} />
    </>
    )}
  </span>
);
