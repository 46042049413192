import { useMemo } from 'react';
import { FormOptions, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { AlertPlaceHolders } from '../constant/text';

export const useAlertSnoozeForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    snoozeReason: {
      name: 'snoozeReason',
      label: 'Snooze reason:',
      emptyMessage: AlertPlaceHolders.Snooze,
    },
    snoozeInterval: {
      name: 'snoozeInterval',
      label: '',
    },
  }, options);

  const response = useMemo(() => ({
    ...factory,
  }), [factory]);

  return response;
};
