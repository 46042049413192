import classNames from 'classnames';
import { FormListFieldData } from 'antd';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { InsuranceCardFormItemComponent, InsuranceCardFormItemComponentProps } from '../InsuranceCardFormComponent/InsuranceCardFormItemComponent';
import { InsuranceCardContentComponent } from './InsuranceCardContentComponent';
import { InsuranceCardSubmitValue } from '../../../../hooks/formHook/useInsuranceForm';

import './InsuranceCardComponent.scss';
import { BenefitOrderEnum } from '../../../../uc-api-sdk';

export interface InsuranceCardComponentProps {
  field: FormListFieldData;
  patientId: string;
  insurance: InsuranceCardSubmitValue;
  isEdit?: boolean;
  onEdit?: (isEdit?: boolean) => void;
  onCancelEdit?: () => void;
  onDelete?: () => void;
  disableEditDelete?: boolean;
  onSave?: InsuranceCardFormItemComponentProps['onSubmit'];
}

export const InsuranceCardComponent = ({
  field,
  patientId,
  insurance,
  isEdit,
  onEdit,
  onDelete,
  onCancelEdit,
  disableEditDelete,
  onSave,
}: InsuranceCardComponentProps) => {
  const handleOnEdit = () => {
    onEdit?.(true);
  };

  const handleOnCancelEdit = () => {
    onCancelEdit?.();
  };

  const handleOnDelete = () => {
    onDelete?.();
  };

  return (
    <div
      className={classNames({
        'insurance-card': true,
        'insurance-card__edit': !!isEdit,
      })}
    >
      <PatientCommonCardComponent
        title=""
        showEdit={false}
        isEditing={isEdit}
        showHeaderOutside={false}
        formContent={(
          <InsuranceCardFormItemComponent
            field={field}
            patientId={patientId}
            onSubmit={onSave}
            onCancel={handleOnCancelEdit}
          />
        )}
        content={(
          <InsuranceCardContentComponent
            provider={insurance.provider || ''}
            insuranceProviderId={insurance.insuranceProviderId || ''}
            benefitOrder={insurance.benefitOrder as BenefitOrderEnum}
            policyNumber={insurance.policyNumber ?? undefined}
            patientRelationship={insurance.patientRelationship ?? undefined}
            eligibilityStatus={insurance.eligibilityStatus ?? undefined}
            wayStarInquiryId={insurance.wayStarInquiryId ?? undefined}
            priorAuth={insurance.priorAuth}
            priorAuthActiveCase={insurance.priorAuthActiveCase}
            priorAuthHistoryCases={insurance.priorAuthHistoryCases}
            isEditable={!disableEditDelete}
            isDeletable={!disableEditDelete}
            onEdit={handleOnEdit}
            onDelete={handleOnDelete}
          />
        )}
      />
    </div>
  );
};
