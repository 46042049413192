import { map, partial } from 'lodash';
import { ReactNode, useState } from 'react';
import { LLMResultCardComponent, LLMResultCardComponentProps } from '../LLMResultCardComponent/LLMResultCardComponent';

interface LLMResultCardListChildProps<T> {
  item: T;
  isChecked: boolean;
  setIsChecked: (value?: boolean) => void;
  isDisabled: boolean;
  setIsDisabled: (value?: boolean) => void;
  index: number;
}
type GetSourceData = LLMResultCardComponentProps['getSourceData'];
type GetSourceNavigator = LLMResultCardComponentProps['getSourceNavigator'];

export interface LLMResultCardListComponentProps<T> {
  data: T[];
  renderTitle: (item: T) => ReactNode;
  children: (props: LLMResultCardListChildProps<T>) => ReactNode;
  getSourceData?: GetSourceData
  getSourceNavigator?: GetSourceNavigator;
  defaultCheckList?: boolean[];
  onCheckListChange?: (checkList: boolean[]) => void;
  defaultDisabledList?: boolean[];
}

export const LLMResultCardListComponent = <T, >({
  data,
  getSourceData,
  getSourceNavigator,
  renderTitle,
  defaultCheckList = map(data, () => true),
  onCheckListChange,
  defaultDisabledList = map(data, () => false),
  children,
}: LLMResultCardListComponentProps<T>) => {
  const [checkList, setCheckList] = useState<boolean[]>(defaultCheckList);
  const [disabledList, setDisabledList] = useState<boolean[]>(defaultDisabledList);

  const handleOnCheckListChange = (
    idx: number,
    value?: boolean,
  ) => {
    const newCheckList = [...checkList];
    newCheckList[idx] = !!value;
    setCheckList(newCheckList);
    onCheckListChange?.(newCheckList);
  };

  const handleOnChangeDisabledList = (
    idx: number,
    value?: boolean,
  ) => {
    const newDisabledList = [...disabledList];
    newDisabledList[idx] = !!value;
    setDisabledList(newDisabledList);
  };

  return (
    <>
      {
        map(data, (item, idx) => (
          <div key={idx} className="mt20">
            <LLMResultCardComponent
              title={renderTitle(item)}
              // TODO: [t][4743] make this fn to take item
              getSourceData={getSourceData}
              getSourceNavigator={getSourceNavigator}
              value={checkList[idx]}
              onChange={partial(handleOnCheckListChange, idx)}
              disabled={disabledList[idx]}
            >
              {
                children({
                  item,
                  isChecked: !!checkList[idx],
                  setIsChecked: partial(handleOnCheckListChange, idx),
                  isDisabled: !!disabledList[idx],
                  setIsDisabled: partial(handleOnChangeDisabledList, idx),
                  index: idx,
                })
              }
            </LLMResultCardComponent>
          </div>
        ))
      }
    </>
  );
};
