import { ReactNode, useEffect, useState } from 'react';
import { Checkbox, Col, Row } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import { filter, map } from 'lodash';
import { MemberOptions } from '../../type';
import { NoDataWithActionComponent } from '../../../../uiComponent/NoDataWithActionComponent/NoDataWithActionComponent';

export interface OnCallCheckboxGroupComponentProps {
  value?: string[];
  onChange?: (v: string[]) => void;
  memberData: MemberOptions[];
  title: ReactNode;
  showSelectAllCheckbox?: boolean;
}

export const OnCallCheckboxGroupComponent = ({
  memberData,
  value,
  onChange,
  title,
  showSelectAllCheckbox = false,
}: OnCallCheckboxGroupComponentProps) => {
  const getValue = (m: MemberOptions) => `${m.id} ${m.role}`;
  const allCheckedOptions = map(memberData, (m) => `${m.id} ${m.role}`);
  const [checkedValues, setCheckedValues] = useState(value);

  const handleOnChange: CheckboxGroupProps['onChange'] = (checkedValue) => {
    if (checkedValue.includes('all') && !checkedValues?.includes('all') && showSelectAllCheckbox) {
      onChange?.(allCheckedOptions);
      setCheckedValues(['all', ...allCheckedOptions]);
    } else if (checkedValues?.includes('all') && !checkedValue.includes('all') && showSelectAllCheckbox) {
      onChange?.([]);
      setCheckedValues([]);
    } else {
      onChange?.(filter(checkedValue as string[], (v) => v !== 'all'));
      setCheckedValues(filter(checkedValues as string[], (v) => v !== 'all'));
    }
  };

  useEffect(() => {
    if (value?.length === memberData.length) {
      setCheckedValues(['all', ...value]);
    } else {
      setCheckedValues(value);
    }
  }, [value, memberData]);

  if (memberData.length === 0) {
    return <NoDataWithActionComponent description="No data" iconSize={90} />;
  }

  return (
    <Checkbox.Group
      className="w100"
      onChange={handleOnChange}
      value={checkedValues}
    >
      <Row>
        {
          showSelectAllCheckbox && (
            <Col span={12}>
              <Checkbox key="all" value="all">
                <strong>
                  All
                  {' '}
                  {title}
                </strong>
              </Checkbox>
            </Col>
          )
        }
        {map(memberData, (member) => (
          <Col span={12}>
            <Checkbox key={getValue(member)} value={getValue(member)}>
              {member.name}
              {', '}
              {member.role as string}
            </Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  );
};
