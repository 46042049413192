import { useEffect } from 'react';
import { useBoolean } from 'usehooks-ts';
import { Button, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { StickyNoteComponent, StickyNoteComponentProps } from '../../../../uiComponent/StickyNoteComponent/StickyNoteComponent';
import './MemoComponent.scss';

export interface MemoComponentProps extends Omit<StickyNoteComponentProps, 'onCollapse'> {
}

export const MemoComponent = ({
  value,
  onAdd,
  onEdit,
  onDelete,
}: MemoComponentProps) => {
  const {
    value: showSticky,
    setTrue: setShowStickyTrue,
    setFalse: setShowStickyFalse,
  } = useBoolean(!!value?.length);

  useEffect(() => {
    if (!showSticky && value?.length) {
      setShowStickyTrue();
    }
  }, [value?.length]);

  const handleCollapse = () => {
    if (!value?.length) {
      setShowStickyFalse();
    }
  };

  if (!showSticky) {
    return (
      <Button
        className="add-memo-button"
        onClick={setShowStickyTrue}
      >
        <Space>
          <PlusOutlined />
          Add Memo
        </Space>
      </Button>
    );
  }

  return (
    <div className="memo-component">
      <StickyNoteComponent
        value={value}
        onAdd={onAdd}
        onEdit={onEdit}
        onDelete={onDelete}
        onCollapse={handleCollapse}
      />
    </div>
  );
};
