import { Collapse } from 'antd';
import { FC } from 'react';
import { CareNoteHistory } from '../../../../uc-api-sdk';
import { HistoryListComponent } from '../../../../uiComponent/HistoryComponent/HistoryListComponent';
import './CareNoteCollapsibleHistoryComponent.scss';

const { Panel } = Collapse;
export interface HistoryComponentProps {
  histories: CareNoteHistory[];
}

export const CareNoteCollapsibleHistoryComponent: FC<HistoryComponentProps> = ({ histories }) => (
  <Collapse ghost className="CareNoteCollapsibleHistoryComponent mt20">
    <Panel header="History" key="1">
      <HistoryListComponent notes={histories} />
    </Panel>
  </Collapse>
);
