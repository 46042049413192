import { SupplyTypeEnum } from '../../type';

export interface SupplyEnumComponentProps {
  value: SupplyTypeEnum;
}

export const SupplyEnumComponent = ({
  value,
}: SupplyEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case SupplyTypeEnum.BG_STRIP:
        return 'iHealth Test Strips (50ct) x 2';
      case SupplyTypeEnum.LANCETS:
        return 'Lancets (50ct) x 2';
      case SupplyTypeEnum.BPM1_CUFF:
        return 'iHealth BPM1 Cuff';
      case SupplyTypeEnum.EASE_CUFF:
        return 'iHealth Ease Cuff';
      case SupplyTypeEnum.WIFI_ROUTER:
        return 'iHealth BPM1 Wi-Fi Router';
      case SupplyTypeEnum.HOTSPOT:
        return 'iHealth BPM1 Hotspot';
      default:
        return '';
    }
  };
  return (<>{getText()}</>);
};
