import React, { useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { useDocumentTitle } from 'usehooks-ts';
import LoginContainer from './features/auth/container/LoginContainer/LoginContainer';
import { useLoggedInUserFromContext } from './contexts/loggedInUserContext/LoggedInUserContext';
import { PAGE_INFO_MAP } from './constants/pageInfo';
import { PortalPlatform } from './contexts/loggedInUserContext/types';
import useChangePage from './hooks/useChangePage/useChangePage';
import './App.scss';

const App: React.FC = () => {
  const { isUserLoggedIn, currentPlatform } = useLoggedInUserFromContext();
  const changePage = useChangePage();
  useDocumentTitle(PAGE_INFO_MAP.CARE_PORTAL.title);

  const renderNavigation = useCallback(() => {
    if (!currentPlatform) return null;
    const platformUrl = changePage.getPlatformUrl(currentPlatform as PortalPlatform);
    return <Navigate to={platformUrl} />;
  }, [currentPlatform]);

  return (
    <div className="App" data-testid="mainApp">
      {
        (!isUserLoggedIn)
          ? <LoginContainer />
          : renderNavigation()
      }
    </div>
  );
};
export default App;
