import { Modal } from 'antd';
import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { CuffSizeEnum, DeviceModelEnum } from '../../../../uc-api-sdk';
import { OrderType } from '../../type';
import { DeviceReplacementCheckboxComponent, DeviceReplacementCheckboxComponentProps, DeviceReplacementValue } from '../DeviceReplacementCheckboxComponent/DeviceReplacementCheckboxComponent';
import { OrderInfoComponent } from '../OrderInfoComponent/OrderInfoComponent';
import { useModalConfigContext } from '../../../../contexts/ModalConfigContext/ModalConfigContext';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import { EllipsisComponent } from '../../../../uiComponent/EllipsisComponent/EllipsisComponent';
import { DeviceMonitorMethodEnum } from '../../../../enumComponent/DeviceMonitorMethodEnumComponent/DeviceMonitorMethodEnumComponent';
import { BloodPressureDeviceFormComponent, BloodPressureDeviceFormComponentProps } from '../../../device/component/BloodPressureDeviceFormComponent/BloodPressureDeviceFormComponent';
import { Title } from '../../../../uiComponent/Title/Title';

interface BPClearReplacementValue
  extends DeviceReplacementValue {
  cuffSize?: CuffSizeEnum;
  deviceModel?: DeviceModelEnum;
  deviceMethod?: DeviceMonitorMethodEnum;
  deviceId?: string;
  deviceLanguage?: string;
  bpReadOut?: boolean;
}

export interface BPClearReplacementComponentProps
  extends Omit<DeviceReplacementCheckboxComponentProps, 'children'> {
  deviceModel: DeviceModelEnum;
  value?: BPClearReplacementValue;
  onChange?: (value?: BPClearReplacementValue) => void;
  onUncheck?: (deviceId: string) => void;
}

export const BPClearReplacementComponent = ({
  deviceModel,
  value,
  onChange,
  onUncheck,
}: BPClearReplacementComponentProps) => {
  const {
    isChecked = false,
    reason,
    deviceId,
    deviceLanguage,
    bpReadOut,
    cuffSize,
  } = value || {};
  const deviceSetupValue = {
    deviceId,
    deviceLanguage,
    bpReadOut,
    cuffSize,
    deviceModel,
  };
  const {
    isOpen,
    open,
    close,
  } = useOpen();
  const {
    getContainer: getModalContainer,
  } = useModalConfigContext();

  const handleOnChange: DeviceReplacementCheckboxComponentProps['onChange'] = (
    newValue
  ) => {
    const {
      isChecked = false,
      reason: newReason,
    } = newValue || {};
    if (isChecked && newReason && !deviceId) {
      // only automatically open the modal when it's initial change
      open();
    }
    if (!isChecked) {
      onChange?.({ isChecked, reason: newReason });
      onUncheck?.(deviceId as string);
      return;
    }
    onChange?.({
      ...value,
      isChecked,
      reason: newReason,
    });
  };

  const handleOnDeviceSubmit: BloodPressureDeviceFormComponentProps['onSubmit'] = (
    deviceSetupValue,
  ) => {
    onChange?.({
      ...value,
      ...deviceSetupValue,
      deviceMethod: DeviceMonitorMethodEnum.NON_APP_MONITOR,
      deviceModel,
    });
    close();
  };

  const handleOnCancelDeviceAssignment = () => {
    if (!deviceId) {
      onChange?.({ isChecked: false, reason });
    }
    close();
  };

  return (
    <>
      <DeviceReplacementCheckboxComponent
        value={{
          isChecked,
          reason,
        }}
        onChange={handleOnChange}
        showExtraContent={isChecked && !!reason && !!deviceId}
        extraContent={(
          <div className="flex f-w ai-c">
            <EllipsisComponent>
              Device ID:
              {' '}
              {deviceId || 'Not assigned'}
            </EllipsisComponent>
            <LinkButton
              useBlueColor
              onClick={open}
              className="fs12"
            >
              Edit
            </LinkButton>
          </div>
        )}
      >
        <OrderInfoComponent
          type={OrderType.DEVICES}
          deviceModel={DeviceModelEnum.BPM1}
        />
      </DeviceReplacementCheckboxComponent>
      <Modal
        open={isOpen}
        onCancel={handleOnCancelDeviceAssignment}
        footer={null}
        getContainer={getModalContainer}
        closable={!!deviceSetupValue.deviceId}
        destroyOnClose
      >
        <div className="my20">
          <Title className="mb20">
            Device Setup
          </Title>
          <BloodPressureDeviceFormComponent
            deviceModel={deviceModel}
            initialValues={deviceSetupValue}
            onCancel={handleOnCancelDeviceAssignment}
            onSubmit={handleOnDeviceSubmit}
          />
        </div>
      </Modal>
    </>
  );
};
