import { TagComponent } from '../../../../../uiComponent/TagComponent/TagComponent';

export const PauseServiceTagComponent = {
  PausedTag: () => (
    <TagComponent
      type="error"
      iconType="none"
      background="oval"
    >
      Paused
    </TagComponent>
  ),
  PendingTag: () => (
    <TagComponent
      type="warning"
      iconType="none"
      background="oval"
    >
      Upcoming Pause
    </TagComponent>
  )
};
