import {
  BillableMonthlySummary,
  RangeFilter,
  SearchRequest,
  Sort$Direction,
  useBillableMonthlySummarySearchBillableHistory,
} from '../../../uc-api-sdk';
import { useTableChange } from '../../../hooks/useTableChange/useTableChange';
import { sorterHelper } from '../../../hooks/useTableChange/sorterHelper';

export const billingInfo = (
  patientId?: string,
  monthOfYearRange?: RangeFilter<number>,
) => {
  const tableChange = useTableChange<
  BillableMonthlySummary,
    SearchRequest<BillableMonthlySummary>['filter']
    >({
      sort: [{
        direction: Sort$Direction.DESC,
        property: 'monthOfYear',
      }],
      sortProcessor: (sorter) => sorterHelper(sorter),
    });

  const info = useBillableMonthlySummarySearchBillableHistory({
    options: {
      sendOnMount: true,
    },
    params: {
      filter: {
        filter: {
          memberId: patientId,
          monthOfYearRange,
        },
        pageInfo: tableChange.pageInfo,
      },
    },
  });

  return {
    info,
    tableChange,
  };
};
