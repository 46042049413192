import { Button, Form } from 'antd';
import Checkbox from 'antd/lib/checkbox';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect } from 'react';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { MedicalAlertResult } from '../../../../uc-api-sdk';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useAlertForm } from '../../hook/useAlertForm';
import { EmptyAlertComponent } from '../EmptyAlertComponent/EmptyAlertComponent';
import { MedicalAlertCheckboxGroupComponent } from '../MedicalAlertCheckboxGroupComponent/MedicalAlertCheckboxGroupComponent';

export interface MedicalAlertFormSubmitValue {
  note: string;
  noteOnly: true;
  alerts: MedicalAlertResult[];
}

export interface MedicalAlertFormComponentProps {
  data?: MedicalAlertResult[];
  onSubmit?: (value: MedicalAlertFormSubmitValue) => void;
  onMedicalAlertStart?: () => void;
}

export const MedicalAlertFormComponent = ({
  data,
  onSubmit,
  onMedicalAlertStart
}: MedicalAlertFormComponentProps) => {
  const hook = useAlertForm();
  const { value: isLoading, setTrue, setFalse } = useBoolean();

  useEffect(() => {
    onMedicalAlertStart?.();
  }, []);

  const handleSubmit = async (v: MedicalAlertFormSubmitValue) => {
    try {
      const submit = hook.handleSubmitAndReset(onSubmit);
      setTrue();
      await submit?.(v);
      setFalse();
    } catch (e) {
      setFalse();
      console.error(e);
    }
  };

  return (
    <Form
      form={hook.form}
      onFinish={handleSubmit}
      className="h100"
    >
      <FixedComponent>
        <FixedComponent.Child>
          <FormItem info={hook.getInfo('alerts')}>
            {
              data && data.length !== 0
                ? (
                  <MedicalAlertCheckboxGroupComponent
                    value={data as MedicalAlertResult[]}
                    medicalData={data}
                  />
                )
                : (<EmptyAlertComponent type="medical" />)
            }
          </FormItem>
        </FixedComponent.Child>
        <FixedComponent.Child isFixed>
          <div className="border-t">
            <div className="mt10 pl20 pr20 p10">
              <FormItem
                noStyle
                shouldUpdate={hook.shouldUpdate(['alerts'])}
              >
                {({ getFieldValue }) => {
                  const v = getFieldValue('alerts');
                  return v && v.length > 0 && (
                    <>
                      <FormItem
                        shouldUpdate={hook.shouldUpdate(['noteOnly'])}
                      >
                        <FormItem
                          info={hook.getInfo('note')}
                          rules={hook.getRequiredRules('note')}
                          required
                        >
                          <TextArea placeholder="add notes" rows={3} />
                        </FormItem>
                      </FormItem>
                      <div className="flex jc-sb mt20">
                        <FormItem valuePropName="checked" info={hook.getInfo('noteOnly')}>
                          <Checkbox>
                            Add notes only
                          </Checkbox>
                        </FormItem>
                        <div className="flex jc-sb">
                          <FormItem
                            noStyle
                            shouldUpdate={hook.shouldUpdate(['noteOnly'])}
                          >
                            {({ getFieldValue }) => (
                              <Button
                                type="primary"
                                htmlType="submit"
                                loading={isLoading}
                                disabled={isLoading}
                                className="b5"
                              >
                                {hook.getMedicalAlertSubmitButtonText(getFieldValue)}
                              </Button>
                            ) }
                          </FormItem>
                        </div>
                      </div>
                    </>
                  );
                } }
              </FormItem>
            </div>
          </div>
        </FixedComponent.Child>
      </FixedComponent>
    </Form>
  );
};
