import { useOpen } from '../../../../hooks/useBoolean/useOpen';
import { CuffSizeEnum } from '../../../../uc-api-sdk';
import { DeviceCuffSizeComponent } from '../../../device/component/DeviceCuffSizeComponent/DeviceCuffSizeComponent';
import { BPCuffSizeModalComponent } from '../../../enrollment/component/BPCuffSizeModalComponent/BPCuffSizeModalComponent';
import { BPDeviceCuffSizeComponentProps } from '../../../enrollment/component/BPDeviceCuffSizeComponent/BPDeviceCuffSizeComponent';
import { OrderType, SupplyTypeEnum } from '../../type';
import { OrderCheckboxComponent } from '../OrderCheckboxComponent/OrderCheckboxComponent';
import { OrderInfoComponent } from '../OrderInfoComponent/OrderInfoComponent';

interface CuffSuppliesValue {
  isChecked: boolean;
  cuffSize?: CuffSizeEnum;
}

export interface CuffSuppliesComponentProps {
  supplyType: SupplyTypeEnum;
  value?: CuffSuppliesValue;
  onChange?: (value?: CuffSuppliesValue) => void;
}

export const CuffSuppliesComponent = ({
  supplyType,
  value,
  onChange
}: CuffSuppliesComponentProps) => {
  const {
    isChecked,
    cuffSize,
  } = value || {};
  const {
    isOpen,
    open,
    close,
  } = useOpen();

  const handleOnCheckboxChange = (checked?: boolean) => {
    if (checked) open();
    onChange?.({ isChecked: !!checked });
  };

  const handleOnCuffSizeSubmit: BPDeviceCuffSizeComponentProps['onChange'] = (
    cuffSize,
  ) => {
    onChange?.({ isChecked: true, cuffSize });
    close();
  };

  const handleOnCuffSizeCancel = () => {
    if (!cuffSize) onChange?.({ isChecked: false });
    close();
  };

  return (
    <>
      <OrderCheckboxComponent
        value={isChecked}
        onChange={handleOnCheckboxChange}
      >
        <OrderInfoComponent
          type={OrderType.SUPPLIES}
          supplyType={supplyType}
        />
        <div className="order-info-content">
          <DeviceCuffSizeComponent
            value={cuffSize}
            onChange={handleOnCuffSizeSubmit}
            hideWhenEmpty
          />
        </div>
      </OrderCheckboxComponent>
      <BPCuffSizeModalComponent
        open={isOpen}
        value={cuffSize}
        onChange={handleOnCuffSizeSubmit}
        onCancel={handleOnCuffSizeCancel}
        closeable={false}
      />
    </>

  );
};
