/* eslint-disable import/prefer-default-export */
import { BpStat } from '../../../../uc-api-sdk';
import { BPSummaryKey } from '../../type';
import { useResultToSummaryTable } from '../useResultToSummaryTable/useResultToSummaryTable';

export const useBPResultToSummaryTable = (data: BpStat) => {
  const order: BPSummaryKey[] = [
    'overnight',
    'morning',
    'afternoon',
    'evening',
    'low',
    'normal',
    'elevated',
    'hypertension1',
    'hypertension2',
    'criticalHigh',
    'arrhythmia',
    'pulsePressure',
  ];

  const response = useResultToSummaryTable(data as Required<BpStat>, order);

  return response;
};
