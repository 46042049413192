import { reduce } from 'lodash';
import { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { USA_DATE } from '../../../../constants/timeFormat';
import { BGTableDataType } from '../../type/BGTypes';
import { MeasurementHelper } from '../../helper/MeasurementHelper';
import { NonNullUndefined } from '../../../../types/common';
import { Measurement } from '../../../../uc-api-sdk';
import { BGMeasurementService } from '../../helper/BGMeasurementService';

export const useBGResultToTable = (
  data: NonNullUndefined<Measurement>[],
  fromDate: Dayjs,
  toDate: Dayjs,
) => {
  const processedData = useMemo(() => {
    // create a table with date as the key and array of measurements as the value
    const dateValueDict = MeasurementHelper.dateValueDict(data);

    // uses the table above to a the date create a
    // new table mealType as the key and array of measurements
    // as the value
    const getData = (date: string) => {
      const valuesInDay = dateValueDict[date];
      if (!valuesInDay) {
        return {};
      }
      const unsortedObject = reduce(valuesInDay, (res, curr) => {
        const { mealType } = curr;
        if (res[mealType] === undefined) {
          res[mealType] = [];
        }
        res[mealType]?.push(new BGMeasurementService(curr));
        return res;
      }, {} as BGTableDataType['data']);

      return MeasurementHelper.sortByDate(unsortedObject);
    };

    return MeasurementHelper.createArrayOfDateData(
      fromDate,
      toDate,
      (date: Dayjs) => getData(date.format(USA_DATE)),
    ).reverse();
  }, [data, fromDate, toDate]);

  return processedData;
};
