import { BillableTimeSegmentEnumComponent } from '../../../enumComponent/BillableTimeSegmentEnumComponent/BillableTimeSegmentEnumComponent';
import { BillableTimeSegmentEnum } from '../../../uc-api-sdk';
import { RadioFilterComponent } from '../component/RadioFilterComponent/RadioFilterComponent';
import { TableFilterType } from '../type/filter';
import { FilterIcon } from './FilterIcon';

export const createBillableTimeRangeRadioFilter = (): TableFilterType => {
  const options = Object.keys(BillableTimeSegmentEnum).map(
    (key) => (
      {
        label: <BillableTimeSegmentEnumComponent value={key as BillableTimeSegmentEnum} />,
        value: key
      }
    )
  );
  return ({
    filterDropdown: ((props) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <RadioFilterComponent {...props} filterOptions={options} />
    )),
    filterIcon: FilterIcon,
  });
};
