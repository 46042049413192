import { Drawer } from 'antd';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { VitalsEnumTypeComponent } from '../../../../enumComponent/VitalsEnumTypeComponent/VitalsEnumTypeComponent';
import { VitalEnumType } from '../../../../uc-api-sdk';
import { DeviceMonitorFormContainer, DeviceMonitorFormContainerProps } from '../../container/DeviceMonitorFormContainer/DeviceMonitorFormContainer';
import { Title } from '../../../../uiComponent/Title/Title';
import { MediumDrawerWidth } from '../../../../constants/drawer';
import { DeviceMonitorFormComponentProps } from '../DeviceMonitorFormComponent/DeviceMonitorFormComponent';
import { DeviceMonitorFormWithDefaultComponent } from '../DeviceMonitorFormWithDefaultComponent/DeviceMonitorFormWithDefaultComponent';
import { ModalConfigContextProvider } from '../../../../contexts/ModalConfigContext/ModalConfigContext';

import './DeviceMonitorDrawerComponent.scss';

const getModalContainer = () => (
  document.querySelector('.device-monitor-drawer .ant-drawer-content-wrapper') || document.body
) as HTMLElement;

export interface DeviceMonitorDrawerComponentProps {
  open: boolean;
  onClose: () => void;
  patientId: string;
  vitalType: VitalEnumType;
  onSubmit?: DeviceMonitorFormComponentProps['onSubmit'];
  title?: ReactNode;
  children?: DeviceMonitorFormContainerProps['children'];
  className?: string;
  useDefaultTemplate?: boolean;
  closeOnSubmit?: boolean;
}

export const DeviceMonitorDrawerComponent = ({
  open,
  onClose,
  patientId,
  vitalType,
  className = '',
  children,
  onSubmit,
  title = (
    <>
      {/* ie Blood Glucose Monitor */}
      <VitalsEnumTypeComponent value={vitalType} />
      {' '}
      Monitor Method
    </>
  ),
  useDefaultTemplate,
  closeOnSubmit = true,
}: DeviceMonitorDrawerComponentProps) => {
  const updateHook = useUpdate('DEVICE_MONITOR_UPDATED');
  const handleOnClose = () => {
    onClose();
  };

  const handleOnSubmit: DeviceMonitorDrawerComponentProps['onSubmit'] = (
    values,
  ) => {
    updateHook.updateValue();
    onSubmit?.(values);
    if (closeOnSubmit) {
      handleOnClose();
    }
  };

  const renderForm = () => {
    if (useDefaultTemplate) {
      return (
        <DeviceMonitorFormWithDefaultComponent
          vitalType={vitalType}
          onSubmit={handleOnSubmit}
          onCancel={handleOnClose}
        >
          {(props) => children?.(props) || null}
        </DeviceMonitorFormWithDefaultComponent>
      );
    }
    return (
      <DeviceMonitorFormContainer
        patientId={patientId}
        vitalType={vitalType}
        onSubmit={handleOnSubmit}
        onCancel={handleOnClose}
      >
        {(props) => children?.(props) || null}
      </DeviceMonitorFormContainer>
    );
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      className={classNames({
        'device-monitor-drawer': true,
        [className]: !!className,
      })}
      title={(
        <Title noMargin>
          {title}
        </Title>
      )}
      width={MediumDrawerWidth}
      headerStyle={{ borderBottom: 'none' }}
      bodyStyle={{ paddingTop: 0 }}
      destroyOnClose
    >
      <ModalConfigContextProvider
        getContainer={getModalContainer}
      >
        {renderForm()}
      </ModalConfigContextProvider>
    </Drawer>
  );
};
