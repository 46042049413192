import { FormOptions, ShouldShow, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { useDeepCompareMemo } from '../../../hooks/useDeepCompareEffect';

export interface SkipFollowUpScheduleSubmitValue {
  followupVisitSkipped: boolean;
  note: string;
}

export const useSkipFollowUpScheduleForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    followupVisitSkipped: {
      name: 'followupVisitSkipped',
      label: "Can't schedule follow-up now, will check with patient later.",
    },
  }, options);

  const shouldShowNote: ShouldShow = (getFieldValue) => (
    getFieldValue(factory.getName('followupVisitSkipped')) === true
  );

  return useDeepCompareMemo(() => ({
    ...factory,
    shouldShowNote
  }), [
    factory,
    shouldShowNote,
  ]);
};
