import {
  ArticleSearchComponent,
  ArticleSearchComponentProps,
} from '../components/ArticleSearchComponent/ArticleSearchComponent';
import { useSearchArticles } from '../hooks/useSearchArticles';
import {
  WebflowLanguageEnum,
} from '../types';

export interface ArticlesSearchContainerProps {
  preferredLanguages?: WebflowLanguageEnum[];
  disabled?: boolean;
  articleCategories: ArticleSearchComponentProps['articleCategories'];
  patientArticles: ArticleSearchComponentProps['patientArticles'];
  isLoading: boolean;
  containerClassName?: string;
  onSubmit?: ArticleSearchComponentProps['onSubmit'];
  submitText?: string;
}
export const ArticlesSearchContainer = (
  {
    preferredLanguages,
    disabled,
    articleCategories,
    patientArticles,
    isLoading: _isLoading,
    onSubmit,
    submitText,
    containerClassName,
  }: ArticlesSearchContainerProps,
) => {
  const {
    searchValue,
    category,
    page,
    onChange,
    articlesDataSource,
    isLoadingArticlesDataSource,
  } = useSearchArticles({
    sendOnMount: !disabled,
    initialLanguages: preferredLanguages,
  });

  return (
    <ArticleSearchComponent
      searchValue={searchValue}
      category={category}
      page={page}
      onChange={onChange}
      articlesDataSource={articlesDataSource}
      isLoading={!!_isLoading || isLoadingArticlesDataSource}
      onSubmit={onSubmit}
      submitText={submitText}
      containerClassName={containerClassName}
      disabled={disabled}
      patientArticles={patientArticles}
      articleCategories={articleCategories}
    />
  );
};
