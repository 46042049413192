import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { map, orderBy } from 'lodash';
import React, { FC, useMemo, useState } from 'react';
import { TicketSingleActivityLogComponent } from './TicketSingleActivityLogComponent';
import { TicketHistory } from '../../../../uc-api-sdk';
import './TicketActivityHistoryComponent.scss';

const { Panel } = Collapse;

export interface TicketActivityHistoryComponentProps {
  history?: TicketHistory[];
}

export const TicketActivityHistoryComponent: FC<TicketActivityHistoryComponentProps> = ({
  history = [],
}) => {
  const [openPanels, setOpenPanels] = useState<string | string[]>([]);

  const isOpen = (panelKey: string) => openPanels.includes(panelKey);

  const sortedHistory = useMemo(() => orderBy(
    history,
    (a) => (
      dayjs(a.createdAt || 0).valueOf()
    ),
    'desc',
  ), [history]);

  return (
    <div
      className={classNames({
        'ticket-activity-history-component': true,
        active: openPanels.length > 0,
      })}
    >
      <Collapse ghost onChange={setOpenPanels}>
        <Panel
          header="Activity History"
          key="activityHistory"
          showArrow={false}
          extra={<CaretRightOutlined rotate={isOpen('activityHistory') ? 90 : 0} />}
        >
          {sortedHistory.length > 0
            ? map(sortedHistory, (v, i) => (
              <TicketSingleActivityLogComponent
                key={i}
                info={v}
              />
            ))
            : (
              <div>Activity history not available!</div>
            )}
        </Panel>
      </Collapse>
    </div>
  );
};
