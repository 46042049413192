import { Select } from 'antd';
import { ClinicalGoalEnumComponent } from '../../../../../enumComponent/ClinicalGoalEnumComponent/ClinicalGoalEnumComponent';
import { ClinicGoalEnum } from '../../../../../uc-api-sdk';

import './GoalDescriptionSelectComponent.scss';

export interface GoalDescriptionSelectComponentProps {
  value?: ClinicGoalEnum;
  onChange?: (value: ClinicGoalEnum) => void;
  options: ClinicGoalEnum[];
}

export const GoalDescriptionSelectComponent = ({
  options,
  value,
  onChange,
}: GoalDescriptionSelectComponentProps) => (
  <Select
    className="goal-description-selector"
    value={value}
    onChange={onChange}
  >
    {
        options?.map((opt) => (
          <Select.Option value={opt} key={opt}>
            <ClinicalGoalEnumComponent value={opt} />
          </Select.Option>
        ))
      }
  </Select>
);
