import { useState } from 'react';
import { forEach, omit } from 'lodash';
import { useUpdateEffect } from 'usehooks-ts';
import { useMessageServicesContext } from '../../../contexts/MessageContext/MessageServicesContext';
import { chsServices } from '../CHSServices';
import { SubscriptionMessagePayload, MessageEventType, SubscriptionMessageVisitPayload } from '../types';
import { MessageHistoryMessages } from '../types/data';
import { useZoomContext } from '../../../contexts/ZoomContext/ZoomContext';
import { useCalendarNotificationContext } from '../../../contexts/CalendarNotificationContext/CalendarNotificationContext';

interface UserToMap {
  [userId: string]: boolean; // boolean => isPatient
}

export const useMessageSubscription = () => {
  const [userToMap, setUserToMap] = useState<UserToMap>({});
  const {
    userMap,
    handleSetUserMap,
    handleGetChannelHistory,
    handleSetChannel,
  } = useMessageServicesContext();
  const {
    openNotification: openZoomNotification,
  } = useZoomContext();
  const {
    openNotification: openReminderNotification,
  } = useCalendarNotificationContext();

  const handleSetUserToMap = (
    userId: string,
  ) => {
    setUserToMap((prevMap) => ({
      ...prevMap,
      [userId]: true,
    }));
  };

  const handleVideoPayload = ({
    clinicEventId
  }: SubscriptionMessageVisitPayload) => {
    if (!clinicEventId) return;
    openZoomNotification(clinicEventId);
  };

  const handleVisitReminderPayload = ({
    clinicEventId
  }: SubscriptionMessageVisitPayload) => {
    if (!clinicEventId) return;
    openReminderNotification(clinicEventId);
  };

  const handleMessagePayload = ({
    type,
    publisher,
    patient: patientId,
  }: SubscriptionMessagePayload) => {
    const isACKNotification = type === MessageEventType.ACK_NOTIFICATION;
    if (!isACKNotification && !chsServices.isACKPublisher(publisher)) {
      handleSetUserToMap(patientId);
      // don't need to set user map for user other than patient here
    }

    setTimeout(async () => {
      const res = await handleGetChannelHistory([patientId]);

      if (!res) return;
      const data = res[0] || {};
      const {
        messages,
      } = data;
      if (messages.length) {
        const lastMessageInHistory = messages?.[0] || {} as MessageHistoryMessages;
        if (!isACKNotification && chsServices.shouldExcludeMessage(lastMessageInHistory.payload)) {
          return;
        }
        handleSetChannel(patientId, data, true);
      }
    }, 350);
  };

  const processMessagePayload = (
    payload: SubscriptionMessagePayload,
  ) => {
    const { type } = payload;
    switch (type) {
      case MessageEventType.VIDEO_NOTIFICATION:
        handleVideoPayload(payload);
        return;
      case MessageEventType.VISIT_REMINDER:
        handleVisitReminderPayload(payload);
        return;
      case MessageEventType.CONSENT_NOTIFICATION:
        return;
      default:
        // TODO: [t] need to improve this by adding type for WS messagePayload
        // to determine if it is for Chat message specifically
        handleMessagePayload(payload);
    }
  };

  const handleMessageEvent = (
    message: string[],
  ) => {
    forEach(message, (msg) => {
      try {
        // stringified<payload: <stringified object>>
        if (!msg) return;
        const messageObj = JSON.parse(msg) || {};
        let parsedPayload: SubscriptionMessagePayload;
        if (messageObj.payload) {
          // chat
          parsedPayload = JSON.parse(messageObj.payload);
        } else {
          // video notification
          parsedPayload = messageObj;
        }
        processMessagePayload(parsedPayload);
      } catch (error) {
        console.error('Failed to parse message error: ', error);
      }
    });
  };

  useUpdateEffect(() => {
    // dispatch internal map to save all user info to userMap
    const userIds = Object.keys(userToMap);
    if (userIds.length) {
      userIds.forEach((userId) => {
        if (userMap[userId]) return;
        handleSetUserMap(userId, true);
        const newUserToMap = omit(userToMap, userId);
        setUserToMap(newUserToMap);
      });
    }
  }, [userToMap, userMap]);

  return {
    handleMessageEvent,
  };
};
