import React, { FC } from 'react';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { FromDateToDate } from '../../../vitals/type';
import { useFoodLogSearch } from '../../hook/useFoodLogSearch';
import { NO_PAGINATION } from '../../../../constants/pagination';
import { FoodLogProcessorComponent } from '../../component/FoodLogTableComponent/FoodLogProcessorComponent';
import { useBillableTimeInterventionComp } from '../../../billableTime/hook/useBillableEventTracker/useBillableTimeInterventionComp';
import { BillableTimeInterventionComponentEnum, BillableTimeInterventionDetailEnum } from '../../../billableTime/contants/contants';

export interface FoodLogTableContainerProps extends FromDateToDate {
  patientId: string;
}

export const FoodLogTableContainer: FC<FoodLogTableContainerProps> = ({
  patientId,
  fromDate,
  toDate,
}) => {
  const articles = useFoodLogSearch({
    options: { sendOnMount: true },
    params: {
      filter: {
        memberId: patientId,
        createdAtRange: {
          gte: fromDate.toISOString(),
          lte: toDate.toISOString(),
        },
      },
      pageInfo: NO_PAGINATION,
    },
  });

  const { send } = useBillableTimeInterventionComp();

  const handleOnReview = () => {
    send({
      patientId,
      component: BillableTimeInterventionComponentEnum.ReviewFoodLog,
      detail: BillableTimeInterventionDetailEnum.InterventionFoodLogReview,
    });
  };

  return (
    <FetchComponent
      info={articles}
      showEmptyOverlay={false}
      alwaysShowChildren
    >
      {(value) => (
        <FoodLogProcessorComponent
          data={value?.rawValue?.data?.content || []}
          onReview={handleOnReview}
        />
      )}
    </FetchComponent>
  );
};
