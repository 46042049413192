import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import './CardWHorizontalSectionComponent.scss';
import CardWHorizontalSectionItemComponent from './CardWHorizontalSectionItemComponent';

export interface CardWHorizontalSectionComponentProps {
  children: ReactNode;
  fullDividerLine?: boolean;
  className?: string;
}

interface SubComponents {
  Item: typeof CardWHorizontalSectionItemComponent;
}

export const CardWHorizontalSectionComponent: FC<
  CardWHorizontalSectionComponentProps
> & SubComponents = ({
  children,
  fullDividerLine,
  className,
}: CardWHorizontalSectionComponentProps) => (
  <div
    className={classNames({
      [className || '']: !!className,
      'card-w-horizontal-section': true,
      'full-divider-line': fullDividerLine,
    })}
  >
    {children}
  </div>
);

CardWHorizontalSectionComponent.Item = CardWHorizontalSectionItemComponent;

export default CardWHorizontalSectionComponent;
