import React from 'react';
import { VisitParticipantRoleEnum } from '../../../../uc-api-sdk';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';

export interface CalendarParticipantRoleComponentProps {
  value: VisitParticipantRoleEnum | undefined | null;
}

export const CalendarParticipantRoleComponent = ({
  value,
}: CalendarParticipantRoleComponentProps) => {
  const getName = () => {
    switch (value) {
      case VisitParticipantRoleEnum.RD_HC: return 'RD/HC';
      case null:
      case undefined:
        return <EmptyComponent />;
      default: return value;
    }
  };

  return (
    <>{getName()}</>
  );
};
