import { Button } from 'antd';
import { ReactNode } from 'react';
import { DragDropUploadComponent } from '../../../../uiComponent/DragDropUploadComponent/DragDropUploadComponent';
import { LLMUploadContainer, LLMUploadContainerProps } from '../../container/LLMUploadContainer/LLMUploadContainer';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import { FixedChildComponent } from '../../../../uiComponent/FixedComponent/FixedChildComponent';

import './LLMDragDropUploadComponent.scss';

export interface LLMDragDropUploadComponentProps {
  patientId: string;
  defaultFileList?: LLMUploadContainerProps['defaultFileList'];
  onFileListChange?: LLMUploadContainerProps['onFileListChange'];
  guide?: ReactNode;
  onUpload?: LLMUploadContainerProps['onUploadFinish'];
}

export const LLMDragDropUploadComponent = ({
  patientId,
  defaultFileList,
  onFileListChange,
  guide,
  onUpload,
}: LLMDragDropUploadComponentProps) => (
  <LLMUploadContainer
    patientId={patientId}
    defaultFileList={defaultFileList}
    onFileListChange={onFileListChange}
    onUploadFinish={onUpload}
  >
    {
      ({
        upload,
        isUploading,
        fileList,
        handleFileListChange
      }) => (
        <FixedComponent className="flex fd-c jc-sb">
          <FixedChildComponent className="mb20 llm-dragdrop-upload">
            <div className="llm-dragdrop-upload--guide">
              {guide}
            </div>
            <div className="mt20">
              <DragDropUploadComponent
                fileList={fileList}
                accept="image/jpeg, image/png, application/pdf"
                onChange={handleFileListChange}
                fileMaxSize={100}
              />
            </div>
          </FixedChildComponent>
          <FixedChildComponent isFixed>
            <div className="flex jc-e border-t pt30 pb24 px24">
              <Button
                type="primary"
                onClick={() => upload()}
                loading={isUploading}
                disabled={!fileList.length}
              >
                {
                  isUploading
                    ? 'Uploading'
                    : 'Process'
                }
              </Button>
            </div>
          </FixedChildComponent>
        </FixedComponent>
      )
    }
  </LLMUploadContainer>
);
