import React, { FC } from 'react';
import { DisplayOrEmptyComponent } from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import './BPTextComponent.scss';

export interface BPTextComponentProps {
  systolic: number;
  diastolic: number;
  heartRate: number | undefined | null;
}

export const BPTextComponent: FC<BPTextComponentProps> = ({
  systolic,
  diastolic,
  heartRate,
}) => (
  <div className="BPTextComponent">
    <div className="first-row">
      {systolic}
      /
      {diastolic}
    </div>
    {' '}
    <DisplayOrEmptyComponent value={heartRate} emptyType="mini" />
    {' '}
    <span className="display-or-empty">
      bpm
    </span>
  </div>
);
