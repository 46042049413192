/* eslint-disable import/prefer-default-export */

const getDividers = (dividers: string [] = []) => {
  const [beforeFirst = '(', afterFirst = ')', afterSecond = '-'] = dividers;
  return {
    beforeFirst,
    afterFirst,
    afterSecond,
  };
};

export const formatPhoneNumber = (phoneNumber?: string | number, dividers?: string []) => {
  const phoneNumberString = phoneNumber?.toString();
  if (!phoneNumberString) return undefined;
  const cleaned = phoneNumberString.replace(/\D/g, '');
  let countryCode = '';
  // Only supporting USA for now
  if (phoneNumber?.toString().startsWith('1') && phoneNumber?.toString().length > 10) {
    countryCode = '+1';
  }
  const countryCodeLen = countryCode.length;
  const first = cleaned.substring(countryCodeLen + 0, countryCodeLen + 3);
  const second = cleaned.substring(countryCodeLen + 3, countryCodeLen + 6);
  const third = cleaned.substring(countryCodeLen + 6, countryCodeLen + 10);
  const d = getDividers(dividers);

  if (cleaned.length > 6) {
    return `${countryCode ? `${countryCode} ` : ''}${d.beforeFirst}${first}${d.afterFirst}${second}${d.afterSecond}${third}`;
  }
  if (cleaned.length > 3) {
    return `${d.beforeFirst}${first}${d.afterFirst}${second}`;
  }
  if (cleaned.length > 0) {
    return `${first}`;
  }
  return undefined;
};

export const formatMultiplePhoneNumber = (
  inputNumber:string | number | undefined,
  selectedCode:string | undefined,
  showCountryCode?: boolean,
) => {
  if (!inputNumber) {
    return '';
  }
  // Remove all non-digit characters
  const formattedNumber = `${inputNumber}`.replace(/\D/g, '');
  // Format number based on selected country code
  // const countryCode = `+${selectedCode}`;
  let newFormattedNumber;
  const selectedCodeNum = selectedCode ? parseInt(selectedCode, 10) : '';
  switch (selectedCodeNum) {
    case 1:
      newFormattedNumber = formattedNumber.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
      break;
    case 86:
      newFormattedNumber = formattedNumber.replace(/^(\d{3})(\d{4})(\d{4})$/, '$1-$2-$3');
      break;
    case 63:
      newFormattedNumber = formattedNumber.replace(/^(\d{2})(\d{3})(\d{4})$/, '($1) $2-$3');
      break;
    case 91:
      newFormattedNumber = formattedNumber.replace(/^(\d{5})(\d{5})$/, '$1-$2');
      break;
    default:
      newFormattedNumber = formattedNumber;
  }
  if (showCountryCode) return `+${selectedCode} ${newFormattedNumber}`;
  return newFormattedNumber;
};
