import {
  Component,
  FC, useMemo,
  useState
} from 'react';
import classNames from 'classnames';
import './Icon.scss';
import { useUpdateEffect } from 'usehooks-ts';
import { useLoadSvgAsComponent } from './hooks/useLoadSvgAsComponent';
import { ClickableDiv, ClickableDivProps } from '../uiComponent/ClickableDiv/ClickableDiv';

export interface IconProps {
  onClick?: ClickableDivProps['onClick'];
  className?: string;
  size?: 64 | 128 | 256 | number | {
    width?: number,
    height?: number,
  };
  hexColor?: string;
  disabled?: boolean;
  asSVG?: boolean; // it will try to convert to svg
}

export interface IconAllProps extends IconProps {
  src?: string;
  alt?: string;
  info?: {
    src: string;
    alt?: string;
    size?: IconProps['size'];
  };
}

export interface ResolvedIconInfo {
  SVGComponent?: typeof Component;
  src?: string;
  alt?: string;
}

export const Icon: FC<IconAllProps> = ({
  src,
  alt,
  info,
  onClick,
  className = '',
  size,
  hexColor,
  disabled = false,
  asSVG
}) => {
  const [resolvedInfo, setResolvedInfo] = useState<ResolvedIconInfo>();
  const iconSrc = info?.src || src;
  const iconAlt = info?.alt || alt;
  const iconSize = info?.size || size;
  // prevent converting all to svg when it's not necessary
  const shouldConvertSVG = (
    asSVG
    || hexColor !== undefined
  );
  const {
    SVGComponent,
    isLoading,
  } = useLoadSvgAsComponent(shouldConvertSVG ? iconSrc : undefined);

  const [width, height] = useMemo(() => {
    if (typeof iconSize === 'object') {
      return [iconSize.width, iconSize.height];
    }
    return [iconSize, iconSize];
  }, [iconSize]);

  useUpdateEffect(() => {
    if (isLoading) return;
    if (SVGComponent) {
      setResolvedInfo({ SVGComponent });
      return;
    }
    (async () => {
      try {
        // try to resolve media src from src/assets
        const assetSrc = await import(`../assets${iconSrc}`);
        setResolvedInfo({ src: assetSrc.default, alt: iconAlt });
      } catch (error) {
        // iconSrc might not in correct format
        setResolvedInfo({ src: iconSrc, alt: iconAlt });
      }
    })();
  }, [SVGComponent, isLoading]);

  if (!resolvedInfo) return null;

  const color = hexColor || (disabled ? '#808080' : undefined);

  const content = resolvedInfo.SVGComponent
    ? (
      <div
        className={classNames({
          'base-svg-icon-container': true,
          'base-icon-container': true,
          [className]: !!className,
        })}
        style={{ width, height }}
      >
        <resolvedInfo.SVGComponent
          className={classNames({
            'base-svg-icon': true,
            'fill-color': !!color,
          })}
          style={{ color }}
        />
      </div>
    ) : (
      <div
        className={classNames({
          'base-icon-container': true,
          [className]: !!className,
        })}
      >
        <img
          src={resolvedInfo.src}
          alt={resolvedInfo.alt}
          width={width}
          height={height}
          draggable={false}
        />
      </div>
    );

  if (onClick) {
    return (
      <ClickableDiv onClick={onClick} className={`base-icon-button ${className}`}>
        {content}
      </ClickableDiv>
    );
  }
  return content;
};
