import { Dayjs } from 'dayjs';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';

export interface MTPRDataDateComponentProps {
  startTime?: Dayjs;
  endTime?: Dayjs;
}

export const MTPRDataDateComponent = ({
  startTime,
  endTime
}: MTPRDataDateComponentProps) => (
  <>
    <span>Data from</span>
    {' '}
    <DisplayDateComponent value={startTime} format="USA_DATE" fallbackText="N/A" />
    <span>-</span>
    <DisplayDateComponent value={endTime} format="USA_DATE" fallbackText="N/A" />
  </>
);
