import classNames from 'classnames';
import { Space } from 'antd';
import { useWorklistMTPRContext } from '../../../../contexts/WorklistMTPRContext/WorklistMTPRContext';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';
import { FormType } from '../../../Input/types';
import { ClinicalGoalContainer } from '../../../intervention/container/ClinicalGoalContainer/ClinicalGoalContainer';
import { SubmitValue } from '../../../mtpr/component/MTPRFormComponent/MTPRFormComponent';
import { MTPRFormContainer } from '../../../mtpr/container/MTPRFormContainer/MTPRFormContainer';
import { MonthlyMeasurementContextProvider } from '../../../../contexts/MonthlyMeasurementContext/MonthlyMeasurementContext';
import { MonthlyReviewStatusEnum, ProviderNoteCategoryEnum } from '../../../../uc-api-sdk';
import { MTPRCompleteComponent } from '../../../mtpr/component/MTPRCompleteComponent/MTPRCompleteComponent';
import { MTPRDataDateComponent } from '../../../mtpr/component/MTPRDataDateComponent/MTPRDataDateComponent';
import { MTPRTitleComponent } from '../../../mtpr/component/MTPRTitleComponent/MTPRTitleComponent';
import { MTPRTipComponent } from '../../../mtpr/component/MTPRTipComponent/MTPRTipComponent';
import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import './WorklistMTPRComponent.scss';
import { useErrorContext } from '../../../../contexts/ErrorContext/ErrorContext';
import { NonTechWorklistRequiredField } from '../NonTechWorklistComponent/NonTechWorklistComponent';
import { MissingErrorComponent } from '../../../../uiComponent/MissingErrorComponent/MissingErrorComponent';
import { useWorklistContext } from '../../../../contexts/WorklistContext/WorklistContext';

export interface WorklistMTPRComponentProps extends NestedFormComponentChildProps {
  patientId: string;
}

export const WorklistMTPRComponent = ({
  patientId,
  onSubmit,
  onValuesChange,
  ...nestFormChildProps
}: WorklistMTPRComponentProps) => {
  const {
    monthlyReviewData,
    shouldShowMTPR,
    setMTPRNote,
    refetch,
    isMTPRDisabled,
    setIsMTPRDisabled,
    setUnsavedNotes,
    finishUnsavedNotes,
  } = useWorklistMTPRContext();
  const {
    clinicEvent,
  } = useWorklistContext();
  const {
    MTPR_NOTE: missingMTPRNote,
  } = useErrorContext<Record<NonTechWorklistRequiredField, boolean>>();

  const debouncedSetNote = useDebounce((value: string) => {
    setUnsavedNotes(value);
    setMTPRNote(value);
  });

  const handleOnValuesChange: FormType<SubmitValue>['onValuesChange'] = (
    changedValue,
    allValues
  ) => {
    if (changedValue?.note !== undefined && !isMTPRDisabled) {
      debouncedSetNote(changedValue.note);
    }
    onValuesChange?.(changedValue, allValues);
  };

  const handleSubmit = () => {
    finishUnsavedNotes();
    refetch?.();
    onSubmit?.();
  };

  const renderClinicGoalContainer = () => (
    <ClinicalGoalContainer
      patientId={patientId}
      visitId={clinicEvent?.id}
      isEditing
      showEdit={false}
      onSubmit={onSubmit}
      onValuesChange={onValuesChange}
      formButtons={null}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...nestFormChildProps}
    />
  );

  if (!shouldShowMTPR) {
    return renderClinicGoalContainer();
  }

  if (monthlyReviewData?.status === MonthlyReviewStatusEnum.DONE) {
    return (
      <Space size="middle" direction="vertical" className="w100">
        <MTPRCompleteComponent
          info={monthlyReviewData}
          lastNote={monthlyReviewData.note}
        />
        <div>
          {renderClinicGoalContainer()}
        </div>
      </Space>
    );
  }
  return (
    <MonthlyMeasurementContextProvider patientId={patientId}>
      {({ startTime, endTime }) => (
        <div className="mtpr-wrapper">
          <div className="flex jc-e text-gray">
            <MTPRDataDateComponent
              startTime={startTime}
              endTime={endTime}
            />
          </div>
          <MTPRTitleComponent />
          <MTPRTipComponent />
          <div
            className={classNames({
              mt20: true,
              'missing-warning': !!missingMTPRNote,
            })}
          >
            <MTPRFormContainer
              patientId={patientId}
              finalStatus={MonthlyReviewStatusEnum.DRAFT}
              type={ProviderNoteCategoryEnum.MTPR_FOLLOW_UP}
              initialValues={{ note: monthlyReviewData?.note || '' }}
              onSubmit={handleSubmit}
              onValuesChange={handleOnValuesChange}
              isMtprDisabled={isMTPRDisabled}
              setIsMTPRDisabled={(isDisabled) => setIsMTPRDisabled(isDisabled)}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...nestFormChildProps}
            />
            {
              missingMTPRNote
              && !isMTPRDisabled
              && (
                <div className="missing-error-wrapper">
                  <MissingErrorComponent>
                    Review Note is Missing
                  </MissingErrorComponent>
                </div>
              )
            }
          </div>
        </div>
      )}
    </MonthlyMeasurementContextProvider>
  );
};
