import { NamePath } from 'antd/lib/form/interface';
import { useCallback } from 'react';
import { FormInput, FormOptions, useFormHookFactory } from '../useFormHookFactory/useFormHookFactory';
import { GetFieldValue } from '../../types/form';

export const useIterableFormHook = <T extends FormInput, K extends keyof T>(
  mainFormItemName: string,
  formInput: T,
  options: FormOptions = {},
) => {
  const factory = useFormHookFactory(formInput, options);

  const getMainFormItemName = useCallback(() => mainFormItemName, [mainFormItemName]);

  const makeObjectNamePath = useCallback((
    inputKey: K,
    fieldName?: string | number,
  ): NamePath => (
    fieldName !== undefined
      ? [getMainFormItemName(), fieldName, factory.getName(inputKey)]
      : factory.getName(inputKey)
  ), [getMainFormItemName, factory.getName]);

  const makeListNamePath = useCallback((
    inputKey: K,
    fieldName?: number,
  ): NamePath => (
    fieldName !== undefined
      ? [fieldName, factory.getName(inputKey)]
      : factory.getName(inputKey)
  ), [getMainFormItemName, factory.getName]);

  const getListValue = useCallback((
    inputKey: K,
    getFieldValue: GetFieldValue,
    fieldName?: number,
  ) => (
    fieldName !== undefined
      ? getFieldValue(makeListNamePath(inputKey, fieldName))
      : getFieldValue(factory.getName(inputKey))
  ), [makeListNamePath, factory.getName]);

  const getObjectValue = useCallback((
    inputKey: K,
    getFieldValue: GetFieldValue,
    fieldName?: string | number,
  ) => (
    fieldName !== undefined
      ? getFieldValue(makeObjectNamePath(inputKey, fieldName))
      : getFieldValue(factory.getName(inputKey))
  ), [makeObjectNamePath, factory.getName]);

  return {
    ...factory,
    getMainFormItemName,
    makeObjectNamePath,
    makeListNamePath,
    getListValue,
    getObjectValue,
  };
};
