import {
  Button,
  Input,
  Space
} from 'antd';
import './AddPatientToWatchlistComponent.scss';

export interface AddPatientToWatchlistComponentProps {
  onCancel?: () => void;
  onSubmit?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  note?: string;
}

export const AddPatientToWatchlistComponent = ({
  onSubmit,
  onCancel,
  onChange,
  note,
}: AddPatientToWatchlistComponentProps) => (
  <div className="add-patient-to-watch-list">
    <div className="fd-c ai-s">
      <div className="b6">
        Add patient to watchlist
      </div>
      <div className="mt10">
        <Input.TextArea
          placeholder="Note (optional)"
          maxLength={256}
          autoSize={{ minRows: 1, maxRows: 2 }}
          onChange={onChange}
          value={note}
        />
      </div>
      <div className="mt20">
        <Space className="flex">
          <Button onClick={onSubmit} type="primary" size="middle">
            Add
          </Button>
          <Button onClick={onCancel} type="link" size="middle">
            Cancel
          </Button>
        </Space>
      </div>
    </div>
  </div>
);
