import { map } from 'lodash';
import { Height, Weight } from '../../uc-api-sdk';
import { HeightService } from '../height';
import { WeightService } from '../weight';

export class NumberService {
  public static getPercentageFromRatio(value: number) {
    return +(value * 100).toFixed(0);
  }

  public static numberArrayToFixed(
    value: number[],
    fractionDigits?: number | undefined,
  ) {
    if (value.every((v) => v === null) || value.every((v) => v === undefined)) {
      return [];
    }
    return map(value, (v) => +(v || 0).toFixed(fractionDigits));
  }

  /**
   * @return {[number, number]} weight in lbs, height in inches
  */
  public static getStandardWeightAndHeight(
    weight?: Weight,
    height?: Height,
  ): [number | undefined, number | undefined] {
    const {
      value: weightValue,
    } = weight || {};
    const {
      value: heightInCm,
    } = height || {};

    let weightInLb: number | undefined;
    let heightInInch: number | undefined;
    if (!WeightService.hasNoRecord(weightValue)) {
      weightInLb = Number(WeightService.getWeightInLb(weight));
    }
    if (heightInCm) {
      const [ft, inch] = HeightService.cmToFeet(heightInCm);
      heightInInch = Number(((ft * 12) + inch).toFixed(2));
    }

    return [weightInLb, heightInInch];
  }
}
