/* eslint-disable react/no-unused-prop-types */
import { MessageVitalSingleComponent, MessageVitalSingleComponentProps } from '../MessageVitalSingleComponent/MessageVitalSingleComponent';
import { HSMessagePayload, VitalMessagePayload } from '../../../../../services/CHSServices/types/data';
import { MeasurementResultTypeEnum } from '../../../../../uc-api-sdk';
import { MessageVitalSingleDisplayValueComponent } from '../MessageVitalSingleDisplayComponent/MessageVitalSingleDisplayValueComponent';
import { MessageVitalDisplayWeightChangeComponent } from '../MessageVitalSingleDisplayComponent/MessageVitalDisplayWeightChangeComponent';

export interface MessageHSComponentProps extends Omit<MessageVitalSingleComponentProps, 'vitalName' | 'children'> {
  weight: HSMessagePayload['weight'];
  weightChange: HSMessagePayload['weight_change'];
  unit: VitalMessagePayload['unit'];
}

export const MessageHSComponent = ({
  weight,
  weightChange,
  measuredAt,
  unit,
  text,
}: MessageHSComponentProps) => (
  <MessageVitalSingleComponent
    vitalName={MeasurementResultTypeEnum.HS}
    measuredAt={measuredAt}
    text={text}
  >
    <MessageVitalSingleDisplayValueComponent
      value={Number(weight).toFixed(1)}
      unit={unit}
    />
    <MessageVitalDisplayWeightChangeComponent
      weightChange={weightChange}
      unit={unit}
    />
  </MessageVitalSingleComponent>
);
