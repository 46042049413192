import { MonthlyReview } from '../../../../uc-api-sdk';
import { DisplayDateComponent } from '../../../../uiComponent/DisplayComponent/DisplayDateComponent';

export interface CompleteMTPREligibilityComponentProps {
  completeMTPR: MonthlyReview;
}

export const CompleteMTPREligibilityComponent = ({
  completeMTPR,
}: CompleteMTPREligibilityComponentProps) => (
  <div>
    This patient has a completed MTPR this month on
    {' '}
    <DisplayDateComponent value={completeMTPR.updatedAt} format="USA_DATE" />
    {', '}
    do you still want to create this MTPR?
  </div>
);
