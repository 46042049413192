import { VisitTypeEnum } from '../../../generates';
import { Colors } from '../../../hooks/useColors/colors';

export interface MemberWithColor {
  id: string;
  colors: Colors;
}

export const VISIBLE_VISIT_TYPES = [
  VisitTypeEnum.ClinicFollowUp,
  VisitTypeEnum.Init,
  VisitTypeEnum.FollowUp,
  VisitTypeEnum.Additional,
  VisitTypeEnum.Tech,
  VisitTypeEnum.TechOnboarding,
];

export const NEW_EVENT_ID = 'NEW_EVENT_ID';
