import { Tooltip } from 'antd';
import classNames from 'classnames';
import { map } from 'lodash';
import { AccountHelper } from '../../../../helpers/account';
import Avatar from '../../../../uiComponent/Avatar/Avatar';
import { usePatientCareContext } from '../../context/PatientCareContext/PatientCareContext';
import './PinnedPatientComponent.scss';

export interface PatientInfo {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
}

export interface PinnedPatientsComponentProps {
  data: PatientInfo[];
  currentPatientId?: string;
}

export const PinnedPatientsComponent = ({
  data,
  currentPatientId,
}: PinnedPatientsComponentProps) => {
  const { goToPatient } = usePatientCareContext();

  const handleClick = (id: string) => () => {
    goToPatient(id);
  };

  const renderPatient = (p: PatientInfo) => {
    const fullName = AccountHelper.getFullName(p);
    return (
      <Tooltip title={fullName} placement="bottom">
        <Avatar
          onClick={handleClick(p.id)}
          key={p.id}
          name={fullName}
          className={classNames({
            selected: currentPatientId === p.id,
          })}
        />
      </Tooltip>
    );
  };

  return (
    <div className="pinned-patients-component">
      {map(data, renderPatient)}
    </div>
  );
};
