import { first } from 'lodash';
import {
  RangeFilter,
  SearchRequest,
  Sort$Direction,
  BillableTimeSegment,
  useBillableTimeSegmentSearch,
  BillableEventStatusEnum,
  InFilter,
  PageInfo,
} from '../../../uc-api-sdk';
import { useTableChange } from '../../../hooks/useTableChange/useTableChange';
import { sorterHelper } from '../../../hooks/useTableChange/sorterHelper';
import { DEFAULT_PAGINATION } from '../../../constants/pagination';

export const claimedTime = (
  patientId?: string,
  monthOfYearRange?: RangeFilter<number>,
) => {
  const defaultSorter = [{
    direction: Sort$Direction.DESC,
    property: 'createdAt',
  }];
  const tableChange = useTableChange<
    BillableTimeSegment,
    SearchRequest<BillableTimeSegment>['filter']
  >({
    sort: defaultSorter,
    sortProcessor: (sorter) => sorterHelper(sorter),
    filterProcessor: (filters) => {
      const newFilters: SearchRequest<BillableTimeSegment>['filter'] = { memberId: patientId ?? '' };
      if (filters.provider) {
        newFilters.viewerId = first(filters.provider) as string;
      }
      if (filters.status) {
        const filterIn: InFilter<BillableEventStatusEnum> = {
          in: filters.status as BillableEventStatusEnum[],
        };
        newFilters.statusIn = filterIn;
      }
      return newFilters;
    },
  });

  const page: PageInfo = {
    page: tableChange.pageInfo.page ?? 1,
    size: tableChange.pageInfo.size ?? DEFAULT_PAGINATION.pageSize,
    sort: (tableChange.processedSort && tableChange.processedSort?.length > 0)
      ? tableChange.processedSort : defaultSorter,
  };

  const info = useBillableTimeSegmentSearch({
    options: {
      sendOnMount: true,
    },
    params: {
      searchRequest: {
        filter: {
          ...tableChange.processedFilter,
          memberId: patientId ?? '',
          monthOfYearRange,
        },
        pageInfo: page,
      },
    },
  });

  return {
    info,
    tableChange,
  };
};
