import { BehaviorGoalCategoryComponent, BehaviorGoalCategoryComponentProps } from '../BehaviorGoalCategoryComponent/BehaviorGoalCategoryComponent';

interface BehaviorGoalCategoryLabelComponentProps extends BehaviorGoalCategoryComponentProps {}

export const BehaviorGoalCategoryLabelComponent = ({
  category,
  customCategory
}: BehaviorGoalCategoryLabelComponentProps) => (
  <div className="fs14 b5 secondary-gray">
    <BehaviorGoalCategoryComponent
      category={category}
      customCategory={customCategory}
    />
  </div>
);
