import { DiseaseHistoryItem } from '../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';
import YesNoComponent from '../../../../uiComponent/YesNoComponent/YesNoComponent';

export interface DiseaseHistorySpecialistDisplayComponentProps {
  itemClassName?: string;
  diseaseHistory: DiseaseHistoryItem;
}

export const DiseaseHistorySpecialistDisplayComponent = ({
  itemClassName = 'disease-history-item',
  diseaseHistory,
}: DiseaseHistorySpecialistDisplayComponentProps) => (
  <>
    <div className={itemClassName}>
      <span className="label">See specialist for this condition?</span>
      <YesNoComponent
        yes={diseaseHistory.hasSeenSpecialist}
        showIcon={false}
      />
    </div>
    {
      diseaseHistory.hasSeenSpecialist
      && (
        <div className={itemClassName}>
          <span className="label">Specialist name and contact:</span>
          <DisplayOrEmptyComponent value={diseaseHistory.nameAndContact || undefined} />
        </div>
      )
    }
  </>
);
