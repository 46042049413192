import React from 'react';
import { PopoverButton } from '../../../../uiComponent/PopoverButton/PopoverButton';
import './IntroduceUnifiedCareBtnComponent.scss';

export interface IntroduceUnifiedCareBtnComponentProps {

}

export const IntroduceUnifiedCareBtnComponent = () => {
  const content = (
    <div className="IntroduceUnifiedCareBtnComponent__content">
      <h3>
        Ask patient following questions and introduce Unified Care Program.
      </h3>
      <p>
        Hello, how are you today? My name is __ and I am a __.
        <b className="display-block">
          What has the doctor told you about our program so far?
        </b>
        <span className="b5">
          (Cover more information based on patient's answer)
        </span>
        &nbsp;
        We are a team of dietitians that work alongside your doctor to help manage your
        blood sugar, blood pressure, and weight. Our main goal is to help you
        lower your numbers and make sure you have healthy outcomes. Do you
        currently check your bp/bg/hs at home?
      </p>
      <p>
        We will be giving you devices that will allow you to measure your vitals
        at home. Also, we will download an app on your phone that syncs with your
        devices. This way, after you take your measurements, your data will be uploaded
        to us and we can give you some feedback. Does this seem like something
        you are interested in?
      </p>
      <p>
        <b className="display-block">
          How comfortable are you with using your phone?
        </b>
        <span className="b5">
          (Cover more information based on patient's answer)
        </span>
        &nbsp;
        Okay great! One of the good things about our program is that you can
        ask us questions directly through the app. So if you need help using
        the devices or if you have questions regarding your vitals, you can
        send us a chat. You can also send us pictures of your meals if you want
        to know if it is good/healthy or not.
      </p>
    </div>
  );

  return (
    <PopoverButton
      content={content}
      destroyPopoverOnHide
      buttonProps={{ type: 'link' }}
    >
      Program Guide
    </PopoverButton>
  );
};
