import { Input, InputProps } from 'antd';
import { ChangeEvent, useCallback } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { usePatientContext } from '../../contexts/PatientInfoContext/PatientInfoContext';
import { ApiRequestHelper } from '../../helpers/ApiRequest';
import useDebounce from '../../hooks/useDebounce/useDebounce';
import { FormOptions } from '../../hooks/useFormHookFactory/useFormHookFactory';
import { usePatientSearch } from '../../uc-api-sdk';

export interface PatientUsernameInputContainer {
  value?: string;
  onChange?: InputProps['onChange'];
  form: FormOptions['form'];
  setValid?: () => void;
  setInvalid?: () => void;
  shouldValidate?: number;
  fieldName?: string;
}

export const PatientUsernameInputContainer = ({
  value,
  onChange,
  form,
  setValid,
  setInvalid,
  shouldValidate = 0,
  fieldName = 'loginId',
}: PatientUsernameInputContainer) => {
  const patient = usePatientSearch({});
  const { info: { patientInfo = {} } = {} } = usePatientContext();
  const { loginId = '' } = patientInfo || {};

  const searchId = useDebounce((v: string) => {
    const req = patient.send({
      params: {
        filter: {
          loginId: v
        },
        pageInfo: {
          // @ts-ignore
          countOnly: true,
        }
      }
    });
    ApiRequestHelper.tryCatch(
      req,
      {
        success: undefined,
        error: 'An error has occurred, please try again later.',
        onSuccess(res) {
          if (!res?.data?.totalSize || loginId === v) {
            setValid?.();
            form?.setFields([
              {
                name: fieldName,
                errors: [],
              },
            ]);
          } else {
            form?.setFields([
              {
                name: fieldName,
                errors: ['User Id is taken.'],
              },
            ]);
            setInvalid?.();
          }
        },
        onError: setInvalid
      }
    );
  }, 500);

  useUpdateEffect(() => {
    searchId(value || '');
  }, [shouldValidate]);

  const handleOnchange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);
    searchId(e.target.value);
  }, []);

  return (
    <Input
      value={value}
      onChange={handleOnchange}
    />
  );
};
