import { TagComponent } from '../../../../../uiComponent/TagComponent/TagComponent';

export const BillableEventStatusTagComponent = {
  ConfirmedTag: () => (
    <TagComponent type="success" iconType="solidDot" background="none" />
  ),
  ProcessingTag: () => (
    <TagComponent type="warning" iconType="solidDot" background="none" />
  ),
  ReviewExpiredTag: () => (
    <TagComponent type="done" iconType="solidDot" background="none" />
  ),
  ReviewedRequiredTag: () => (
    <TagComponent type="processing" iconType="solidDot" background="none" />
  ),
  SystemDropTag: () => (
    <TagComponent type="done" iconType="solidDot" background="none" />
  ),
};

export default BillableEventStatusTagComponent;
