import { Col, Row } from 'antd';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useDiseaseHistoryForm } from '../../hook/useDiseaseHistoryForm';
import { YesNoRadioComponent } from '../../../../uiComponent/YesNoRadioComponent/YesNoRadioComponent';
import { DiseaseHistoryMeasureFrequencyFormItemComponent } from '../DiseaseHistoryMeasureFrequencyFormItemComponent/DiseaseHistoryMeasureFrequencyFormItemComponent';
import { DiseaseHistoryTestedBeforeFormItemComponent } from '../DiseaseHistoryTestedBeforeFormItemComponent/DiseaseHistoryTestedBeforeFormItemComponent';
import { DiseaseHistoryTreatmentCheckboxGroupComponent } from '../DiseaseHistoryTreatmentCheckboxGroupComponent/DiseaseHistoryTreatmentCheckboxGroupComponent';
import { DiseaseHistoryHasSeenSpecialistFormListItemComponent } from '../DiseaseHistoryHasSeenSpecialistFormListItemComponent/DiseaseHistoryHasSeenSpecialistFormListItemComponent';
import { DiseaseHistoryDiagnosisYearFormListItemComponent } from '../DiseaseHistoryDiagosisYearFormItemComponent/DiseaseHistoryDiagnosisYearFormItemComponent';

export interface DiseaseHistoryTemplateByVitalComponentProps {
  fieldName: number;
}

export const DiseaseHistoryTemplateByVitalComponent = ({
  fieldName,
}: DiseaseHistoryTemplateByVitalComponentProps) => {
  const {
    makeListNamePath,
    getLabel,
    getListValue,
  } = useDiseaseHistoryForm();
  return (
    <Row
      gutter={70}
      className="disease-history-template-row"
    >
      <Col>
        <DiseaseHistoryDiagnosisYearFormListItemComponent
          fieldName={fieldName}
        />
        <FormItem
          name={makeListNamePath('hasSeenDoctor', fieldName)}
          label={getLabel('hasSeenDoctor') as string}
        >
          <YesNoRadioComponent />
        </FormItem>
        <DiseaseHistoryHasSeenSpecialistFormListItemComponent
          fieldName={fieldName}
        />
      </Col>
      <Col>
        <FormItem noStyle shouldUpdate>
          {
            ({ getFieldValue }) => {
              const disease = getListValue('disease', getFieldValue, fieldName);
              return (
                <DiseaseHistoryTestedBeforeFormItemComponent
                  disease={disease}
                  name={makeListNamePath('hasTestedBefore', fieldName)}
                />
              );
            }
          }
        </FormItem>
        <DiseaseHistoryMeasureFrequencyFormItemComponent
          name={makeListNamePath('measureFreqTimes', fieldName)}
          label={getLabel('measureFreqTimes') as string}
          measureFrequencyUnitName={makeListNamePath('measureFreqUnit', fieldName)}
        />
        <FormItem
          noStyle
          shouldUpdate
        >
          {
            ({ getFieldValue }) => {
              const disease = getListValue('disease', getFieldValue, fieldName);
              return (
                <FormItem
                  name={makeListNamePath('treatmentTypes', fieldName)}
                  label={getLabel('treatmentTypes') as string}
                >
                  <DiseaseHistoryTreatmentCheckboxGroupComponent
                    disease={disease}
                  />
                </FormItem>
              );
            }
          }
        </FormItem>
      </Col>
    </Row>
  );
};
