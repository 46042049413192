import { FC } from 'react';
import { Col, Row } from 'antd';
import { PatientSearchSelectComponentInfo } from '../../../patient/component/PatientSearchSelectComponent/PatientSearchSelectComponent';
import { Patient } from '../../../../uc-api-sdk';
import './PatientSearchSelectItemComponent.scss';
import { PatientSearchSelectItemNameGenderDobComponent } from '../../../../uiComponent/PatientBasicInfo/PatientSearchSelectItemNameGenderDobComponent/PatientSearchSelectItemNameGenderDobComponent';
import { ClinicNameDisplayComponent } from '../../../../uiComponent/DisplayComponent/ClinicDisplayComponent/ClinicNameDisplayComponent';

export interface PatientSearchSelectItemComponentProps {
  data: PatientSearchSelectComponentInfo<Patient>;
}

export const PatientSearchSelectItemComponent: FC<PatientSearchSelectItemComponentProps> = ({
  data,
}) => (
  <Row align="middle">
    <Col span={12}>
      <PatientSearchSelectItemNameGenderDobComponent
        name={data.fullName}
        gender={data.info.profile?.gender}
        dob={data.dob}
      />
    </Col>
    <Col span={12} className="ticketPatientNameOrgColumn">
      <ClinicNameDisplayComponent clinic={data.info.clinic || undefined} />
    </Col>
  </Row>
);
