export const UNIFIEDCARE_LOGO = {
  src: '/logos/Logomark_RGB.svg',
  alt: 'UnifiedCare Logo',
};

export const UNIFIEDCARE_W_TEXT_LOGO = {
  src: '/logos/unifiedcare-logo.svg',
  alt: 'UnifiedCare Logo',
};

export const UNIFIEDCARE_ALPHA_LOGO = {
  src: '/logos/unifiedcare-alpha.png',
  alt: 'UnifiedCare Logo',
};

export const UNIFIEDCARE_W_TEXT_LOGO_2 = {
  src: '/logos/unifiedcare-logo-2.svg',
  alt: 'UnifiedCare Logo',
};

export const UNIFIEDCARE_PILOT_LOGO = {
  src: '/logos/unifiedcare-logo.svg',
  alt: 'UnifiedCare Logo',
};

export const UNIFIEDCARE_ADMIN_LOGO = {
  src: '/logos/unifiedcare-admin-logo-new.svg',
  alt: 'UnifiedCare Admin Logo',
};

export const WAYSTAR_LOGO = {
  src: '/logos/Waystar-logo.svg',
  alt: 'Waystar Logo',
};

export const APPLE_LOGO = {
  src: '/logos/Apple-logo.svg',
  alt: 'Apple Logo',
};

export const WINDOWS_LOGO = {
  src: '/logos/Windows-logo.svg',
  alt: 'Windows Logo',
};
