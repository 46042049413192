import { DiseaseHistory } from '../../features/diseaseHistory/helper';
import { ChronicDiseaseEnum, VitalEnumType } from '../../uc-api-sdk';

export interface DiseaseHasTestedComponentProps {
  disease?: ChronicDiseaseEnum | null;
  capitalize?: boolean;
}

export const DiseaseHasTestedComponent = ({
  disease,
  capitalize = true,
}: DiseaseHasTestedComponentProps) => {
  const getText = () => {
    const vital = DiseaseHistory.getVitalFromChronicDisease(disease);
    const t = capitalize ? 'T' : 't';
    switch (vital) {
      case VitalEnumType.BP:
        return `${t}ested home BP`;
      case VitalEnumType.BG:
        return `${t}ested home BG`;
      case VitalEnumType.PO:
        return `${t}ested home Blood Oxygen`;
      default: return 'tested';
    }
  };

  return (
    <span>{getText()}</span>
  );
};
