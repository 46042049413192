import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { usePatientUpdateLoanDevice } from '../../../../uc-api-sdk';
import { LoanerPhoneAssignFormComponent, LoanerPhoneAssignFormComponentProps } from '../../../enrollment/component/LoanerPhoneAssignFormComponent/LoanerPhoneAssignFormComponent';

export interface LoanerPhoneUnassignFormContainerProps
  extends LoanerPhoneAssignFormComponentProps {
  patientId: string;
}

export const LoanerPhoneUnassignFormContainer = ({
  patientId,
  onSubmit,
  ...props
}: LoanerPhoneUnassignFormContainerProps) => {
  const unassignLoanDeviceInfo = usePatientUpdateLoanDevice({});
  const deviceUpdateHook = useUpdate('DEVICE_MONITOR_UPDATED');

  const handleOnSubmit: LoanerPhoneAssignFormComponentProps['onSubmit'] = () => {
    ApiRequestHelper.tryCatch(
      unassignLoanDeviceInfo.send({
        params: {
          memberId: patientId,
          updateRequest: [],
        },
      }),
      {
        success: 'Unassign device successfully.',
        error: 'Failed to unassign device.',
        onSuccess: () => {
          deviceUpdateHook.updateValue();
          onSubmit?.();
        },
      }
    );
  };

  return (
    <LoanerPhoneAssignFormComponent
      onSubmit={handleOnSubmit}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};
