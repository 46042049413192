import React, { ReactNode } from 'react';
import { Space } from 'antd';
import { Title } from '../Title/Title';
import TooltipComponent from '../TooltipComponent/TooltipComponent';
import './PageTitleComponent.scss';

export interface PageTitleComponentProps {
  title: ReactNode;
  tooltip?: ReactNode;
  extra?: ReactNode;
}

export const PageTitleComponent = ({
  title,
  tooltip,
  extra,
}: PageTitleComponentProps) => (
  <div className="pageTitle flex jc-sb ai-c">
    <Space align="center">
      {typeof title === 'string'
        ? <Title.PageTitle>{title}</Title.PageTitle>
        : title}
      {tooltip && (
        <TooltipComponent
          title={tooltip}
          className="page-title-tooltip"
          overlayClassName="page-title-tooltip-overlay"
          destroyTooltipOnHide
          type="question-icon"
          placement="topLeft"
        />
      )}
    </Space>
    {extra}
  </div>
);
