import { SelectProps } from 'antd';
import { ClinicFilterComponent } from '../component/ClinicFilterComponent/ClinicFilterComponent';
import { TableFilterType } from '../type/filter';
import { FilterIcon } from './FilterIcon';

export const createClinicFilter = (
  mode?: SelectProps['mode'],
  containerId?: string,
): TableFilterType => ({
  filterDropdown: ((props) => (
    <ClinicFilterComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      mode={mode}
      containerId={containerId}
    />
  )),
  filterIcon: FilterIcon,
});
