import React from 'react';
import { Progress } from 'antd';
import { TagComponent } from '../../../../../uiComponent/TagComponent/TagComponent';
import './PatientInfoProgressTagComponent.scss';

export interface PatientMeasureProgressTagProps {
  max: number;
  current: number;
}

export const PatientMeasureProgressTagComponent = ({
  max,
  current,
}: PatientMeasureProgressTagProps) => {
  const progress = Math.floor((current / max) * 100);
  const strokeColor = current < 16 ? '#EF973B' : '#1890FF';

  return (
    <TagComponent iconType="none" background="oval">
      <div className="progress-tag">
        <span>16-day measure</span>
        <Progress
          className="progress-bar"
          percent={progress}
          showInfo={false}
          strokeColor={strokeColor}
          trailColor="#D9D9D9"
          strokeWidth={3.5}
        />
        <span>
          <span className="current-number">
            {current}
          </span>
          {`/${max}`}
        </span>
      </div>
    </TagComponent>
  );
};
