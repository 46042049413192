import { useMemo } from 'react';
import { WorklistTabsComponent, WorklistTabsComponentProps } from '../WorklistTabsComponent/WorklistTabsComponent';
import { VitalsComponent } from '../../../vitals/component/VitalsComponent/VitalsComponent';
import { WorklistTabProps } from '../WorklistTabsComponent/type';
import { WorklistDevicesComponent } from '../WorklistDevicesComponent/WorklistDevicesComponent';

export enum VitalsDataReviewTab {
  Vitals = 'Vitals',
  Device = 'Device',
}

export interface VitalsDataReviewComponentProps
  extends WorklistTabProps<VitalsDataReviewTab> {
  patientId: string;
}

export const VitalsDataReviewComponent = ({
  patientId,
  defaultActiveKey,
}: VitalsDataReviewComponentProps) => {
  const items: WorklistTabsComponentProps['items'] = useMemo(() => ([
    {
      key: VitalsDataReviewTab.Vitals,
      label: 'Vitals',
      children: <VitalsComponent />
    },
    {
      key: VitalsDataReviewTab.Device,
      label: 'Device',
      children: (
        <WorklistDevicesComponent patientId={patientId} />
      )
    }
  ]), [patientId]);

  return (
    <WorklistTabsComponent
      defaultActiveKey={defaultActiveKey}
      items={items}
    />
  );
};
