import classNames from 'classnames';
import { Button } from 'antd';
import useChangePage from '../../../../hooks/useChangePage/useChangePage';
import { Nullable } from '../../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface PatientNameComponentProps {
  firstName?: Nullable<string>;
  lastName?: Nullable<string>;
  id: string;
  className?: string;
  showEnrollmentDrawer?: boolean;
}
export const PatientNameComponent = ({
  firstName,
  lastName,
  id,
  className = '',
  showEnrollmentDrawer = false,
}: PatientNameComponentProps) => {
  const {
    goToCarePortalPatient,
  } = useChangePage();

  return (
    <Button
      type="link"
      onClick={() => goToCarePortalPatient({ patientId: id as string, showEnrollmentDrawer })}
      className={classNames({
        'breakword ta-l': true,
        [className]: !!className
      })}
    >
      {(firstName || lastName) ? `${firstName} ${lastName}`.trim() : <DisplayOrEmptyComponent />}
    </Button>
  );
};
