import React from 'react';
import {
  FormProps,
  Table,
} from 'antd';
import '../IntakeTableComponent/IntakeTableComponent.scss';
import { useTableColumns } from '../../../../../hooks/table/useTableColumns/useTableColumns';
import { intakeFormTableColumns } from './useIntakeFormTableColumns';

export interface IntakeFormTableComponentProps extends FormProps{
}

export const IntakeFormTableComponent = ({
  form,
}: IntakeFormTableComponentProps) => {
  const data = form?.getFieldValue('intake');
  const columns = useTableColumns(
    intakeFormTableColumns,
    ['meal', 'timeRange', 'foodTypeAmount', 'mealFreq', 'skipFreq'],
  );
  return (
    <div className="intake-table-component">
      <div className="text-title">
        24-hour Recall or Typical intake
      </div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </div>
  );
};
