import { Button } from 'antd';
import { map } from 'lodash';
import { ReactNode } from 'react';
import { useZoomContext } from '../../../../contexts/ZoomContext/ZoomContext';
import { ChartingStatusEnum } from '../../../../enumComponent/ChartingStatusEnumComponent/ChartingStatusEnum';
import useChangePage from '../../../../hooks/useChangePage/useChangePage';
import { ClickableDiv } from '../../../../uiComponent/ClickableDiv/ClickableDiv';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { OutstandingEnum } from '../../../outstanding/constant/outstandingOrder';
import {
  ClinicEventsService
} from '../../../patient/component/PatientVisitsListComponent/PatientVisitsItemComponent/ClinicEventsService';
import { useVisitStatusHelper } from '../../hook/useVisitStatusHelper';
import { VisitRescheduleComponent } from '../VisitRescheduleComponent/VisitRescheduleComponent';

export interface CTAButtonComponentProps {
  patientId: string;
  children?: ReactNode;
  onClick?: () => void;
  visitInfo: ClinicEventsService;
  onReschedule?: () => void;
  className?: string;
  defaultEmpty?: boolean;
}

export const CTAButtonComponent = ({
  patientId,
  children,
  onClick,
  visitInfo,
  onReschedule,
  className,
  defaultEmpty = false,
}: CTAButtonComponentProps) => {
  const changePage = useChangePage();
  const {
    getCheckInText,
    showCTA,
    showReschedule,
    disabled,
    disabledReason,
  } = useVisitStatusHelper({
    startTime: visitInfo?.startTime,
    isCheckedIn: !!visitInfo?.checkInTime,
    visitParticipantsId: map(visitInfo?.visitParticipants, (v) => v.participantId as string),
    visitMethod: visitInfo?.visitMethod,
    visitStatus: visitInfo?.visitsStatus,
    zoomStatus: visitInfo?.zoomStatus,
    isCompleted: visitInfo?.chartingStatus === ChartingStatusEnum.CHARTING
  });
  const {
    closeNotification,
  } = useZoomContext();

  const handleClick = () => {
    onClick?.();
    changePage.goToCarePortalPatient({
      patientId,
      outstanding: OutstandingEnum.VISITS,
    }, {
      focusVisit: visitInfo.id,
    });
    closeNotification(visitInfo.id);
  };

  if (!showCTA && defaultEmpty) {
    return <EmptyComponent />;
  }

  if (!showCTA) {
    return <div />;
  }
  return (
    showReschedule
      ? <VisitRescheduleComponent onClick={onReschedule} />
      : (
        <TooltipComponent
          type={null}
          title={disabledReason}
        >
          {
            (children || className)
              ? (
                <ClickableDiv
                  onClick={handleClick}
                  disabled={disabled}
                >
                  {
                    children || (
                      <div className={className}>
                        {getCheckInText(true)}
                      </div>
                    )
                  }
                </ClickableDiv>
              ) : (
                <Button
                  type="primary"
                  onClick={handleClick}
                  disabled={disabled}
                >
                  {getCheckInText(true)}
                </Button>
              )
          }
        </TooltipComponent>
      )
  );
};
