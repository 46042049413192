import {
  Form,
  Input,
  Modal,
  ModalProps,
} from 'antd';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import CancelSubmitButtonsComponent from '../../../../uiComponent/CancelSubmitButtonsComponent/CancelSubmitButtonsComponent';
import { TextComponent } from '../../../../uiComponent/TextComponent/TextComponent';

interface ReasonFormValue {
  replacementReason?: string;
}

export interface OrderReasonModalComponentProps extends ModalProps {
  value?: ReasonFormValue;
  onSubmit: (value: ReasonFormValue) => void;
  onCancel?: () => void;
}

export const OrderReasonModalComponent = ({
  value,
  onSubmit,
  open,
  onCancel,
  ...modalProps
}: OrderReasonModalComponentProps) => (
  <Modal
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...modalProps}
    open={open}
    closable={false}
    footer={null}
    destroyOnClose
  >
    <Form
      onFinish={onSubmit}
      initialValues={value}
      className="p15"
    >
      <div>
        <TextComponent
          className="pr100 mb12"
          size="large"
          bold
        >
          To proceed, please provide the reason for ordering this device.
        </TextComponent>
        <FormItem
          name="replacementReason"
          rules={[{
            required: true,
            message: 'Missing!',
            whitespace: true
          }]}
        >
          <Input.TextArea
            placeholder="Order reason"
            autoSize={{ minRows: 4, maxRows: 4 }}
          />
        </FormItem>
      </div>
      <FormItem
        noStyle
        shouldUpdate
      >
        {
          ({ getFieldsValue }) => (
            <CancelSubmitButtonsComponent
              onCancel={onCancel}
              shouldBeDisabled={() => !getFieldsValue().replacementReason}
            />
          )
        }
      </FormItem>
    </Form>
  </Modal>
);
