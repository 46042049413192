import { keys } from 'lodash';
import { Color } from './CalendarColorService';

const gray: Color = {
  backgroundColor: '#F4F6F8',
  leftBorderColor: '#C9C9C9',
  borderColor: '#E7EAED',
};
const blue: Color = {
  backgroundColor: '#DDECFE',
  leftBorderColor: '#3177C9',
  borderColor: '#CBE0F6',
};
const teal: Color = {
  backgroundColor: '#DFFEFC',
  leftBorderColor: '#00C8BC',
  borderColor: '#C6F6F3',
};
const purple: Color = {
  backgroundColor: '#F2E4FF',
  leftBorderColor: '#B180E1',
  borderColor: '#E7CEFF',
};
const yellow: Color = {
  backgroundColor: '#FFF3C0',
  leftBorderColor: '#F2C81A',
  borderColor: '#FFE67D',
};
const brown: Color = {
  backgroundColor: '#FFE2D1',
  leftBorderColor: '#C39579',
  borderColor: '#DCB095',
};
const pink: Color = {
  backgroundColor: '#FFCBD7',
  leftBorderColor: '#FE7C9B',
  borderColor: '#FFA0B7',
};
const oliveGreen: Color = {
  backgroundColor: '#E3F0D6',
  leftBorderColor: '#A4AD9A',
  borderColor: '#B3C5A0',
};
const paleRed: Color = {
  backgroundColor: '#FFE5F9',
  leftBorderColor: '#EBAEDD',
  borderColor: '#FFC8F2',
};
const neonGreen: Color = {
  backgroundColor: '#DFFFC6',
  leftBorderColor: '#A6EB71',
  borderColor: '#C9FF9E',
};
const mint: Color = {
  backgroundColor: '#EAFFF2',
  leftBorderColor: '#71C693',
  borderColor: '#B1FFD0',
};

export const COLORS = {
  gray,
  blue,
  teal,
  purple,
  yellow,
  brown,
  pink,
  oliveGreen,
  paleRed,
  neonGreen,
  mint,
};

export type ColorName = (keyof typeof COLORS);

export const COLOR_NAMES = keys(COLORS) as ColorName[];
