/* eslint-disable class-methods-use-this */
import { partial } from 'lodash';
import {
  ConnectAgent,
  ConnectContact,
  ConnectPatientAttributes,
  NextCallback,
  SubscriptionCallback,
  ConnectAgentStatus,
  ConnectSoftphoneErrorType,
  ConnectErrorContext,
} from '../types';
import {
  ContactInfo,
} from './types';
import {
  UNKNOWN_PHONE_NUMBER,
} from '../constants';

export class CallCenterSubscriptions {
  // AGENT
  agentOnRefresh = (
    _agent: ConnectAgent,
    cb: SubscriptionCallback<ConnectAgentStatus>,
  ) => {
    _agent.onRefresh((updatedAgent) => {
      const currentAgentStatus = updatedAgent.getStatus();
      const currentAgentAvailabilityState = updatedAgent.getAvailabilityState();
      // should have only 1 voice contact at a time
      const currentAgentContacts = updatedAgent.getContacts();

      cb({
        name: currentAgentStatus.name as ConnectAgentStatus['name'],
        type: currentAgentStatus.type as ConnectAgentStatus['type'],
        availabilityState: currentAgentAvailabilityState.state as ConnectAgentStatus['availabilityState'],
        startTimestamp: currentAgentStatus.startTimestamp,
        contacts: currentAgentContacts,
      });
    });
  };

  agentOnError = (
    _agent: ConnectAgent,
    cb?: SubscriptionCallback<ConnectErrorContext>,
  ) => {
    _agent.onError(() => {
      cb?.({ message: 'Agent error, unknown cause given by connect-streams' });
    });
    _agent.onSoftphoneError((softphoneError) => {
      cb?.({
        errorType: softphoneError.getErrorType() as ConnectSoftphoneErrorType,
        message: softphoneError.errorMessage,
      });
    });
    _agent.onWebSocketConnectionLost(() => {
      cb?.({ message: 'Websocket lost connection' });
    });
  };

  // CONTACT
  contactMiddleware = (
    next: NextCallback<ContactInfo>,
    contact: ConnectContact,
    // contactEventInfo: string,
    // eventBus: EventBus,
  ) => {
    const { contactId } = contact;
    const isInbound = contact.isInbound();
    const attributes = contact.getAttributes() as ConnectPatientAttributes;
    const isConnected = !!contact.isConnected();
    const phoneNumber = contact.getActiveInitialConnection()?.getEndpoint().phoneNumber
      || UNKNOWN_PHONE_NUMBER;

    const contactInfo: ContactInfo = {
      contactId,
      isInbound,
      isConnected,
      attributes,
      phoneNumber,
    };
    next(contactInfo);
  };

  contactOnConnecting = (
    contact: ConnectContact,
    cb?: SubscriptionCallback<ContactInfo>,
  ) => {
    contact.onConnecting(partial(this.contactMiddleware, (contactInfo) => {
      cb?.(contactInfo);
    }));
  };

  contactOnConnected = (
    contact: ConnectContact,
    cb?: SubscriptionCallback<ContactInfo>,
  ) => {
    contact.onConnected(partial(this.contactMiddleware, (contactInfo) => {
      cb?.(contactInfo);
    }));
  };

  contactOnEnded = (
    contact: ConnectContact,
    cb?: SubscriptionCallback<ContactInfo>,
  ) => {
    contact.onEnded(partial(this.contactMiddleware, (contactInfo) => {
      cb?.(contactInfo);
    }));
  };

  contactOnACW = (
    contact: ConnectContact,
    cb?: SubscriptionCallback<ContactInfo>,
  ) => {
    contact.onACW(partial(this.contactMiddleware, (contactInfo) => {
      cb?.(contactInfo);
    }));
  };

  contactOnDestroy = (
    contact: ConnectContact,
    cb?: SubscriptionCallback<ContactInfo>,
  ) => {
    contact.onDestroy(() => {
      cb?.({} as ContactInfo);
    });
  };
}
