import React, { FC } from 'react';
import { DatePickerProps } from 'antd';
import { Moment } from 'moment';
import './style.scss';
import { USA_DATE } from '../../../constants/timeFormat';
import { DatePicker } from '../../../uiComponent/DatePicker/DatePicker';

export interface TicketDueDateSelectComponentProps {
  value?: Moment,
  onChange?: DatePickerProps['onChange'];
}

export const TicketDueDateSelectComponent: FC<TicketDueDateSelectComponentProps> = ({
  value,
  onChange,
}) => (
  <DatePicker
    className="ticketDatePicker"
    defaultValue={value}
    disabledPast
    includeToday={false}
    format={USA_DATE}
    value={value}
    placeholder="Select a date"
    onChange={onChange}
  />
);
