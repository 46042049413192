import {
  find,
  map,
  compact
} from 'lodash';
import { LLMUploadFileInfo } from '../container/LLMUploadContainer/LLMUploadContainer';
import { LLMViewSourceData } from '../component/LLMViewSourceComponent/LLMViewSourceComponent';

export const useLLMSourceHelper = <T, D extends LLMViewSourceData>(
  results: T[],
  uploadFileInfoList?: LLMUploadFileInfo[],
) => {
  const getFileForSource = (s3FileKey?: string | null) => (
    find(uploadFileInfoList, (info) => {
      const fileName = info?.file?.name;
      return fileName && s3FileKey && s3FileKey?.includes(fileName);
    }) as LLMUploadFileInfo
  );

  const processSourceData = (
    processSourceDataItem: (d: T) => D,
    rawResults = results,
  ): D[] => {
    const sourceDataMap = map(rawResults, processSourceDataItem);
    const nonEmptySourceDataMap = compact(sourceDataMap);
    return nonEmptySourceDataMap;
  };

  return {
    getFileForSource,
    processSourceData,
  };
};
