import { NestedFormComponentChildProps } from '../../../../contexts/NestedFormControlContext/type';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import { usePatientSearchUcMedicationManagement } from '../../../../uc-api-sdk';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { InterventionContainerProps } from '../../../intervention/type';
import { PatientCommonCardComponent } from '../../../patient/component/PatientCardComponent/PatientCommonCardComponent';
import { MedicalHistoryMedicationContainer } from '../../container/MedicalHistoryMedicationContainer';

export interface MedicationCommonCardComponentProps
  extends Omit<InterventionContainerProps, 'onSubmit' | 'onError'>,
  NestedFormComponentChildProps {
  onMedicationSubmit?: () => void;
}

export const MedicationCommonCardComponent = ({
  patientId,
  visitId,
  isEditing,
  onEdit,
  showEdit,
  onSubmit,
  onMedicationSubmit,
  onCancel,
  onError,
  onValuesChange,
  formButtons,
}: MedicationCommonCardComponentProps) => {
  const medInfo = usePatientSearchUcMedicationManagement({
    options: {
      sendOnMount: true,
    },
    params: {
      searchRequest: {
        filter: {
          prescribedMedication: {
            memberId: patientId,
            active: true,
            isEnded: false,
          },
          discontinuedMedication: {
            memberId: patientId,
            active: true,
            isEnded: true,
          },
        },
      },
    },
  });

  useUpdateListener('PATIENT_INFO_UPDATED', medInfo.refetch);
  useUpdateListener('WORKLIST_UPDATED', medInfo.refetch);

  const handleSubmit = () => {
    medInfo.refetch();
    onSubmit?.();
  };

  const handleMedicationSubmit = () => {
    medInfo.refetch();
    onMedicationSubmit?.();
  };

  if (medInfo.data === undefined) {
    return null;
  }

  return (
    <FetchComponent
      info={medInfo}
      showErrorOverlay={false}
      alwaysShowChildren
    >
      {(data) => {
        const {
          medicationManagement,
          discontinuedMedications,
          prescribedMedications,
          lastUpdatedAt,
          updatedByUser,
        } = data?.rawValue?.data || {};
        return (
          <PatientCommonCardComponent
            isEditing={isEditing}
            showEdit={showEdit}
            updateInfo={{ updatedAt: lastUpdatedAt, updatedByUser }}
            onEdit={onEdit}
            title="Medication List"
            content={(
              <MedicalHistoryMedicationContainer
                memberId={patientId}
                visitId={visitId}
                mode="View"
                onCancel={onCancel}
                onMedicationSubmit={handleMedicationSubmit}
                medicationManagement={medicationManagement}
                prescribedMedications={prescribedMedications}
                discontinuedMedications={discontinuedMedications}
              />
            )}
            formContent={(
              <MedicalHistoryMedicationContainer
                memberId={patientId}
                visitId={visitId}
                mode="Edit"
                onCancel={onCancel}
                onSubmit={handleSubmit}
                onMedicationSubmit={handleMedicationSubmit}
                onValuesChange={onValuesChange}
                onError={onError}
                formButtons={formButtons}
                medicationManagement={medicationManagement}
                prescribedMedications={prescribedMedications}
                discontinuedMedications={discontinuedMedications}
              />
            )}
          />
        );
      }}
    </FetchComponent>

  );
};
