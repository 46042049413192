import dayjs from 'dayjs';
import React from 'react';
import { Icons } from '../../../../icons/Icons';
import { ClinicEvent } from '../../../../uc-api-sdk';
import { PopoverContentRendererProps } from '../BigCalendarEventComponent/BigCalendarEventComponent';
import { CalendarEventTimeComponent } from '../CalendarEventTimeComponent/CalendarEventTimeComponent';

export interface CalendarNoAccessModalContentComponentProps
  extends PopoverContentRendererProps<ClinicEvent> {
}

export const CalendarNoAccessModalContentComponent = ({
  onClose,
  start,
  end,
}: CalendarNoAccessModalContentComponentProps) => (
  <div className="py10 px20">
    <div className="flex jc-e">
      <Icons.CloseIcon onClick={onClose} />
    </div>
    <div className="mb20">
      <h2 className="m0">Busy</h2>
      <CalendarEventTimeComponent
        startTime={dayjs(start)}
        endTime={dayjs(end)}
        showDay
      />
    </div>
  </div>
);
