import { useMemo } from 'react';
import { Badge } from 'antd';
import { PriorAuthCaseStatusEnum } from '../../uc-api-sdk';

export const getText = (value?: PriorAuthCaseStatusEnum | null) => {
  switch (value) {
    case PriorAuthCaseStatusEnum.NEED_SUBMISSION:
      return 'Need submissions';
    case PriorAuthCaseStatusEnum.PENDING_RESULT:
      return 'Pending results';
    case PriorAuthCaseStatusEnum.APPROVED:
      return 'Approved';
    case PriorAuthCaseStatusEnum.DENIED:
      return 'Denied';
    default:
      return '';
  }
};

export interface InsurancePriorAuthStatusEnumComponentProps {
  value?: PriorAuthCaseStatusEnum | null;
  showBadge?: boolean;
}

export const InsurancePriorAuthStatusEnumComponent = ({
  value,
  showBadge = true,
}: InsurancePriorAuthStatusEnumComponentProps) => {
  const {
    text,
    color,
  } = useMemo(() => {
    switch (value) {
      case PriorAuthCaseStatusEnum.NEED_SUBMISSION:
        return {
          text: getText(value),
          color: '#6B7178',
        };
      case PriorAuthCaseStatusEnum.PENDING_RESULT:
        return {
          text: getText(value),
          color: '#F2C81A',
        };
      case PriorAuthCaseStatusEnum.APPROVED:
        return {
          text: getText(value),
          color: '#3BA767',
        };
      case PriorAuthCaseStatusEnum.DENIED:
        return {
          text: getText(value),
          color: '#DA6453',
        };
      default:
        return {};
    }
  }, [value]);

  if (!showBadge) {
    return (<span>{text}</span>);
  }

  return (<Badge color={color} text={text} />);
};
