import { EnrolledProgramService } from '../../../../services/EnrolledProgramService';
import { VitalEnumType } from '../../../../uc-api-sdk';
import { PatientProfileVitalsMonitoringItemComponent } from './PatientProfileVitalsMonitoringItemComponent';

export interface PatientProfileVitalsMonitoringComponentProps {
  enrolledProgramService: EnrolledProgramService;
}

export const PatientProfileVitalsMonitoringComponent = ({
  enrolledProgramService,
}: PatientProfileVitalsMonitoringComponentProps) => (
  <div>
    {
      enrolledProgramService.getMonitoringVitals().map((v) => (
        <PatientProfileVitalsMonitoringItemComponent
          key={v.type}
          type={v.type as VitalEnumType}
          createdAt={v.createdAt}
        />
      ))
    }
  </div>
);
