import { Input, InputProps } from 'antd';
import React, { FC } from 'react';
import { InputType } from '../../features/Input/types';

export interface ZipCodeInputComponentProps extends
  Omit<InputProps, 'value' | 'onChange'>, InputType<number> {

}

export const ZipCodeInputComponent: FC<ZipCodeInputComponentProps> = ({
  value,
  onChange,
  ...props
}) => {
  const handleChange: InputProps['onChange'] = (evt) => {
    const { value: newValue } = evt.target;
    const zipCode = newValue.replace(/\D/g, '').substring(0, 5);
    onChange?.(zipCode === '' ? undefined : +zipCode);
  };

  return (
    <Input
      aria-label="zipcode"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      value={value === undefined ? '' : value}
      onChange={handleChange}
    />
  );
};

export default ZipCodeInputComponent;
