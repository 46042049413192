import { find, map } from 'lodash';
import { useCallback, useMemo } from 'react';
import { LLMUploadFileInfo } from '../../container/LLMUploadContainer/LLMUploadContainer';
import { UploadFileListComponent, UploadFileListComponentProps } from '../../../../uiComponent/UploadFileListComponent/UploadFileListComponent';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';

export interface LLMUploadFileInfoComponentProps {
  uploadFileInfoList?: LLMUploadFileInfo[];
}

export const LLMUploadFileInfoComponent = ({
  uploadFileInfoList,
}: LLMUploadFileInfoComponentProps) => {
  const fileList = useMemo(() => map(uploadFileInfoList, 'file'), [uploadFileInfoList]);
  const renderFileName = useCallback<NonNullable<UploadFileListComponentProps['renderFileName']>>((
    file,
    openPreview,
  ) => {
    const fileName = file.name;
    const uploadFileInfo = find(uploadFileInfoList, (info) => info.file?.name === fileName);
    if (uploadFileInfo && uploadFileInfo.uploadStatus === 'fulfilled') {
      return (
        <LinkButton
          useBlueColor
          underlineOnHover
          onClick={() => openPreview(file)}
        >
          {file.name}
        </LinkButton>
      );
    }
    return <LinkButton>{`${fileName} (Failed to upload)`}</LinkButton>;
  }, [uploadFileInfoList]);

  return (
    <UploadFileListComponent
      fileList={fileList}
      renderFileName={renderFileName}
    />
  );
};
