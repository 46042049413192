import { useCallback } from 'react';
import { FormProps } from 'antd';
import { map } from 'lodash';
import { FormOptions } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { useIterableFormHook } from '../../../hooks/useIterableFormListHook/useIterableFormHook';
import { Program, ProgramCategoryEnum } from '../../../uc-api-sdk';

export interface ProgramEnrollmentSubmitValue {
  programs: Program[];
}

export interface ProgramEnrollmentFormValues {
  programs: {
    [key in ProgramCategoryEnum]?: {
      category: ProgramCategoryEnum | null;
      createdAt?: Program['createdAt'];
    }
  }
}

export const useProfileProgramEnrollmentForm = (options?: FormOptions) => {
  const factory = useIterableFormHook('programs', {
    category: {
      name: 'category',
    },
    createdAt: {
      name: 'createdAt',
    },
  }, {
    name: 'profileProgramEnrollmentForm',
    ...options,
  });

  // const getEnrolledTime = () => dayjs().format(REQUEST_DATE_FORMAT);

  const getPrograms = (values: ProgramEnrollmentFormValues) => {
    const { programs } = values;
    const submitPrograms = map(programs, (v) => {
      if (!v?.category) return null;
      return v;
    }).filter((v) => !!v) as ProgramEnrollmentSubmitValue['programs'];
    const newValues: ProgramEnrollmentSubmitValue = {
      ...values,
      programs: submitPrograms,
    };
    return newValues;
  };

  const handleSubmit: (onSubmit?: (v: ProgramEnrollmentSubmitValue) => Promise<void> | void) => FormProps['onFinish'] = (
    useCallback((onSubmit) => (values: ProgramEnrollmentFormValues) => {
      onSubmit?.(getPrograms(values));
    }, [])
  );

  const onValuesChange: (onValuesChange: FormProps['onValuesChange']) => FormProps['onValuesChange'] = (
    useCallback((onValuesChange) => (
      changedValues,
      values,
    ) => {
      onValuesChange?.(changedValues, getPrograms(values));
    }, [])
  );

  return {
    ...factory,
    handleSubmit,
    getPrograms,
    onValuesChange,
  };
};
