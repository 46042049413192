import { message } from 'antd';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import {
  usePatientDeviceGetToBeRemovedDevice,
  usePatientDischargeEnrolledProgram,
  VitalEnumType
} from '../../../../uc-api-sdk';
import { DischargeFormComponent, DischargeFormComponentProps } from '../../component/DischargeFormComponent/DischargeFormComponent';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';

export interface DischargeFormContainerProps {
  patientId: string;
  enrolledVitals: VitalEnumType[];
  onSubmit?: () => void;
  onCancel?: () => void;
}

export const DischargeFormContainer = ({
  patientId,
  enrolledVitals,
  onSubmit,
  onCancel,
}: DischargeFormContainerProps) => {
  const dischargeInfo = usePatientDischargeEnrolledProgram({});
  const toBeRemovedDeviceInfo = usePatientDeviceGetToBeRemovedDevice({
    params: { memberId: patientId, removedVitals: enrolledVitals },
    options: { sendOnMount: true },
  });
  const dischargeUpdateHook = useUpdate('PATIENT_DISCHARGED');
  const isLoading = dischargeInfo.isLoading || toBeRemovedDeviceInfo.isLoading;
  const toBeRemovedDevices = toBeRemovedDeviceInfo.data?.data || [];

  const handleOnSubmit: DischargeFormComponentProps['onSubmit'] = (
    values,
  ) => {
    const {
      dischargeReason,
      note,
    } = values;
    ApiRequestHelper.tryCatch(
      dischargeInfo.send({
        params: {
          memberId: patientId,
          dischargedDetail: {
            reason: dischargeReason,
            note,
          }
        },
      }),
      {
        success: '',
        error: 'Failed to discharge patient.',
        onSuccess: () => {
          message.success('Patient has been discharged.', 3);
          dischargeUpdateHook.updateValue();
          onSubmit?.();
        }
      }
    );
  };

  return (
    <DischargeFormComponent
      isLoading={isLoading}
      disabled={isLoading}
      onSubmit={handleOnSubmit}
      onCancel={onCancel}
      toBeRemovedDevices={toBeRemovedDevices}
    />
  );
};
