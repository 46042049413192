import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { MedicalOrganizationStatus, useMedicalOrganizationSearchAllAccessMedicalOrg } from '../../../../uc-api-sdk';

export const useProviderViewAccessibleClinics = () => {
  const { userInfo } = useLoggedInUserFromContext();
  const { id = '' } = userInfo || {};
  const response = useMedicalOrganizationSearchAllAccessMedicalOrg({
    options: {
      sendOnMount: true,
    },
    params: {
      searchRequest: {
        filter: {
          medicalOrganization: {
            statusIn: {
              in: [
                MedicalOrganizationStatus.ACTIVE,
                MedicalOrganizationStatus.TERMINATION_INITIATED,
              ],
            },
            staffIn: {
              in: [id]
            }
          }
        }
      },
    },
  });
  return {
    request: response
  };
};
