import { startCase } from 'lodash';
import EmptyComponent from '../../uiComponent/EmptyComponent/EmptyComponent';

export interface BasicEnumComponentProps<T extends string> {
  value?: T;
  type?: 'camelCase';
}

export const BasicEnumComponent = <T extends string>({
  value,
  type = 'camelCase',
}: BasicEnumComponentProps<T>) => {
  const getText = () => {
    switch (type) {
      case 'camelCase':
      default: return startCase(value?.toLowerCase());
    }
  };

  return value ? (
    <span>{getText()}</span>
  ) : <EmptyComponent />;
};
