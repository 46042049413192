import { NestedFormComponent } from '../../../../contexts/NestedFormControlContext/NestedFormComponent';
import { ProblemDiseaseHistoryContainer } from '../../../problems/container/ProblemDiseaseHistoryContainer/ProblemDiseaseHistoryContainer';
import { WorklistContentComponent } from '../WorklistContentComponent/WorklistContentComponent';

export interface WorklistDiseaseHistoryComponentProps {
  patientId: string;
}

export const WorklistDiseaseHistoryComponent = ({
  patientId,
}: WorklistDiseaseHistoryComponentProps) => (
  <NestedFormComponent>
    <WorklistContentComponent>
      <ProblemDiseaseHistoryContainer
        title=""
        patientId={patientId}
        isEditing
        showEdit={false}
      />
    </WorklistContentComponent>
  </NestedFormComponent>
);
