import { Radio, RadioGroupProps } from 'antd';
import { useEffect, useState } from 'react';
import { SelectHCsPatientsCheckBoxComponent } from '../SelectHCsPatientsCheckBoxComponent/SelectHCsPatientsCheckBoxComponent';
import { InputType } from '../../../Input/types';
import './SelectPatientsHCRadioComponent.scss';
import { PriorityCoverEnum } from '../../../../uc-api-sdk';

export interface SelectPatientsHCRadioComponentProps extends InputType<PriorityCoverEnum> {

}

export const SelectPatientsHCRadioComponent = ({
  value,
  onChange,
}: SelectPatientsHCRadioComponentProps) => {
  const [selectedCardHC, setSelectedCardHC] = useState<PriorityCoverEnum>();
  const isHighSelected = selectedCardHC === PriorityCoverEnum.HIGH;
  const isAllSelected = selectedCardHC === PriorityCoverEnum.ALL;

  const handleChange: RadioGroupProps['onChange'] = (v) => {
    onChange?.(v.target.value as PriorityCoverEnum);
  };

  useEffect(() => {
    setSelectedCardHC(value);
  }, []);

  const handleCardSelect = (value: PriorityCoverEnum) => {
    setSelectedCardHC(value);
    onChange?.(value);
  };

  return (
    <div className="mb30">
      <Radio.Group
        className="render-radio-group flex jc-sb"
        onChange={handleChange}
        value={value}
      >
        <SelectHCsPatientsCheckBoxComponent
          cardValue={PriorityCoverEnum.HIGH}
          onCardSelect={handleCardSelect}
          selected={isHighSelected}
        />
        <SelectHCsPatientsCheckBoxComponent
          cardValue={PriorityCoverEnum.ALL}
          onCardSelect={handleCardSelect}
          selected={isAllSelected}
        />
      </Radio.Group>
    </div>
  );
};
