import { Button, Checkbox, Form } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { Store } from 'antd/lib/form/interface';
import TextArea from 'antd/lib/input/TextArea';
import { isEmpty, isEqual } from 'lodash';
import { Moment } from 'moment';
import { usePauseServiceForm } from '../../../../hooks/formHook/usePauseServiceForm';
import {
  HiatusStatusReasonEnum,
  PatientEnrolledProgram,
  PatientHiatusStatus,
  PatientHiatusStatusEnum,
  PauseServiceInfo,
} from '../../../../uc-api-sdk';
import CancelSubmitButtonsComponent from '../../../../uiComponent/CancelSubmitButtonsComponent/CancelSubmitButtonsComponent';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { PauseServiceRangePickerComponent, RangeValue } from '../PauseServiceComponent/PauseServiceRangePickerComponent';
import { PauseServiceReasonSelectComponent } from '../PauseServiceComponent/PauseServiceReasonSelectComponent';
import './PauseServiceFormComponent.scss';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { RequiredAsteriskComponent } from '../../../../uiComponent/RequiredAsterisk/RequiredAsteriskComponent';

export interface PatientHiatusFormValue {
  vitalMonitoring?: boolean;
  vitalMonitoringReason?: HiatusStatusReasonEnum[] | null;
  vitalMonitoringTimeSpan?: RangeValue | null;
  visitService?: boolean;
  visitServiceReason?: HiatusStatusReasonEnum[] | null;
  visitServiceTimeSpan?: RangeValue | null;
  billing?: boolean;
  billingReason?: HiatusStatusReasonEnum[] | null;
  billingTimeSpan?: RangeValue | null;
  note?: string | null;
  status?: PatientHiatusStatusEnum;
  enrolledProgram?: PatientEnrolledProgram;
}

export interface PauseServiceFormComponentProps {
  onCancel?: () => void;
  onSubmit?: (v: PatientHiatusFormValue) => void;
  isLoading?: boolean;
  initialValues: PatientHiatusFormValue;
  onDateChange: RangePickerProps<Moment>['onChange'];
  needRescheduleVisit: boolean;
  onResumeAll?: () => void;
}

export const PauseServiceFormComponent = ({
  onCancel,
  onSubmit,
  isLoading,
  initialValues,
  onDateChange,
  needRescheduleVisit = true,
  onResumeAll,
}: PauseServiceFormComponentProps) => {
  const pauseServiceFormInfo = usePauseServiceForm();

  const isValuesChanged = (
    initValues: Store | undefined,
    fields: PatientHiatusStatus,
    newValues: PauseServiceInfo
  ) => {
    if (isEmpty(fields)) {
      return true;
    }
    if (isEqual(initValues, newValues)) {
      return true;
    }
    return false;
  };

  const onFormSubmit = (v: PatientHiatusFormValue) => {
    onSubmit?.({
      ...v,
    });
  };

  const renderMonitoringReason = () => (
    <FormItem
      info={pauseServiceFormInfo.getInfo('vitalMonitoringReason')}
      required
      className="w60"
    >
      <PauseServiceReasonSelectComponent />
    </FormItem>
  );

  const renderMonitoringTimeSpan = () => (
    <FormItem
      info={pauseServiceFormInfo.getInfo('vitalMonitoringTimeSpan')}
      required
      className="w60"
    >
      <PauseServiceRangePickerComponent
        disabled={[initialValues.status === PatientHiatusStatusEnum.PAUSED, false]}
        className="w100"
        onChange={onDateChange}
      />
    </FormItem>
  );

  const renderVisitServiceReason = () => (
    <FormItem
      info={pauseServiceFormInfo.getInfo('visitServiceReason')}
      required
      className="w60"
    >
      <PauseServiceReasonSelectComponent />
    </FormItem>
  );

  const renderVisitServiceTimespan = () => (
    <FormItem
      info={pauseServiceFormInfo.getInfo('visitServiceTimeSpan')}
      required
      className="w60"
    >
      <PauseServiceRangePickerComponent
        disabled={[initialValues.status === PatientHiatusStatusEnum.PAUSED, false]}
        className="w100"
        onChange={onDateChange}
      />
    </FormItem>
  );

  const renderBillingReason = () => (
    <FormItem
      info={pauseServiceFormInfo.getInfo('billingReason')}
      required
      className="w60"
    >
      <PauseServiceReasonSelectComponent />
    </FormItem>
  );

  const renderBillingTimespan = () => (
    <FormItem
      info={pauseServiceFormInfo.getInfo('billingTimeSpan')}
      required
      className="w60"
    >
      <PauseServiceRangePickerComponent
        disabled={[initialValues.status === PatientHiatusStatusEnum.PAUSED, false]}
        className="w100"
        onChange={onDateChange}
      />
    </FormItem>
  );

  const displayVitalMonitoring = () => (
    <FormItem noStyle shouldUpdate={pauseServiceFormInfo.shouldUpdate(['vitalMonitoring'])}>
      {({ getFieldValue }) => {
        const hasChecked = !!pauseServiceFormInfo.getValue('vitalMonitoring', getFieldValue);
        return hasChecked && (
          <div>
            {renderMonitoringTimeSpan()}
            {renderMonitoringReason()}
          </div>
        );
      }}
    </FormItem>
  );

  const displayVisitService = () => (
    <FormItem noStyle shouldUpdate={pauseServiceFormInfo.shouldUpdate(['visitService'])}>
      {({ getFieldValue }) => {
        const hasChecked = !!pauseServiceFormInfo.getValue('visitService', getFieldValue);
        return hasChecked && (
          <div>
            {renderVisitServiceTimespan()}
            {renderVisitServiceReason()}
          </div>
        );
      }}
    </FormItem>
  );

  const displayBillingReport = () => (
    <FormItem noStyle shouldUpdate={pauseServiceFormInfo.shouldUpdate(['billing'])}>
      {({ getFieldValue }) => {
        const hasChecked = !!pauseServiceFormInfo.getValue('billing', getFieldValue);
        return hasChecked && (
          <div>
            {renderBillingTimespan()}
            {renderBillingReason()}
          </div>
        );
      }}
    </FormItem>
  );

  return (
    <Form
      form={pauseServiceFormInfo.form}
      layout="vertical"
      className="h100 overflow-h pause-service-form-component"
      disabled={isLoading}
      onFinish={pauseServiceFormInfo.handleSubmit(onFormSubmit)}
      initialValues={initialValues}
    >
      <FixedComponent>
        <FixedComponent.Child>
          <p className="text-gray-scale-2">
            Which service(s) do you want to pause for this patient?
          </p>
          <div className="flex gap0">
            <FormItem
              name={pauseServiceFormInfo.getName('vitalMonitoring')}
              valuePropName="checked"
            >
              <Checkbox>{pauseServiceFormInfo.getLabel('vitalMonitoring')}</Checkbox>
            </FormItem>
            <div>
              <TooltipComponent
                placement="bottom"
                type={pauseServiceFormInfo.formInput.vitalMonitoring.tooltip.type}
                title={pauseServiceFormInfo.getTooltip('vitalMonitoring')?.tip}
              />
            </div>
          </div>
          {displayVitalMonitoring()}
          <div className="flex">
            <FormItem
              name={pauseServiceFormInfo.getName('visitService')}
              valuePropName="checked"
            >
              <Checkbox>{pauseServiceFormInfo.getLabel('visitService')}</Checkbox>
            </FormItem>
            <div className="pause-service-tool-tip">
              <TooltipComponent
                placement="bottom"
                type={pauseServiceFormInfo.formInput.visitService.tooltip.type}
                title={pauseServiceFormInfo.getTooltip('visitService')?.tip}
              />
            </div>
          </div>
          {displayVisitService()}
          <div className="flex">
            <FormItem
              name={pauseServiceFormInfo.getName('billing')}
              valuePropName="checked"
            >
              <Checkbox>{pauseServiceFormInfo.getLabel('billing')}</Checkbox>
            </FormItem>
            <div className="pause-service-tool-tip">
              <TooltipComponent
                placement="bottom"
                type={pauseServiceFormInfo.formInput.billing.tooltip.type}
                title={pauseServiceFormInfo.getTooltip('billing')?.tip}
              />
            </div>
          </div>
          {displayBillingReport()}
          {needRescheduleVisit && (
            <div className="need-reschedule-visit">
              <RequiredAsteriskComponent
                isBefore
                text="Patient has visit scheduled in the selected time span, please talk with patient and rescheduled the visit."
              />
            </div>
          )}
          <FormItem
            className="mt20"
            info={pauseServiceFormInfo.getInfo('note')}
          >
            <TextArea placeholder="Leave Notes" className="no-border bg-textarea" />
          </FormItem>
        </FixedComponent.Child>
        <FixedComponent.Child isFixed>
          <div className="border-t flex jc-sb ai-c pt20">
            <Button onClick={onResumeAll} type="link">Resume All Services</Button>
            <CancelSubmitButtonsComponent
              onCancel={onCancel}
              className="pause-service-form-component__cancel-btn"
              submitText={initialValues.status === 'PAUSED' ? 'Submit' : 'Save'}
              isLoading={isLoading}
              shouldBeDisabled={(values) => {
                // at least pause one service
                if (!values[pauseServiceFormInfo.getName('vitalMonitoring')]
                  && !values[pauseServiceFormInfo.getName('visitService')]
                  && !values[pauseServiceFormInfo.getName('billing')]) {
                  return true;
                }
                const fields = pauseServiceFormInfo.form.getFieldsValue(
                  true,
                  (meta) => meta.touched
                );
                return isValuesChanged(initialValues, fields, values);
              }}
            />
          </div>
        </FixedComponent.Child>
      </FixedComponent>
    </Form>
  );
};
