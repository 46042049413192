export interface MedicalOrgNameDisplayComponentProps {
  name: string | undefined;
  orgNumber?: string;
}

export const MedicalOrgNameDisplayComponent = ({
  name,
  orgNumber,
}: MedicalOrgNameDisplayComponentProps) => {
  const renderOrgNumber = (
    orgNumber: MedicalOrgNameDisplayComponentProps['orgNumber'],
  ) => {
    if (!orgNumber) return null;
    let orgNumberString = '';
    if (typeof orgNumber === 'number') {
      orgNumberString = `${orgNumber}`;
    } else {
      orgNumberString = orgNumber;
    }
    return (
      <>
        {orgNumberString}
        {' '}
      </>
    );
  };

  return (
    <>
      {renderOrgNumber(orgNumber)}
      {name}
    </>
  );
};
