import { BehaviorChangeEnum } from '../../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../DisplayComponent/DisplayOrEmptyComponent';

export interface PatientBehaviorChangeEnumComponentProps {
  value: BehaviorChangeEnum;
}
export const PatientBehaviorChangeEnumComponent = ({
  value,
}: PatientBehaviorChangeEnumComponentProps) => {
  const getText = () => {
    switch (value) {
      case BehaviorChangeEnum.NONE: return 'None';
      case BehaviorChangeEnum.ACTION: return 'Action';
      case BehaviorChangeEnum.CONTEMPLATION: return 'Contemplation';
      case BehaviorChangeEnum.MAINTENANCE: return 'Maintenance';
      case BehaviorChangeEnum.PRECONTEMPLATION: return 'Precontemplation';
      case BehaviorChangeEnum.PREPARATION: return 'Preparation';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  return (
    <>{getText()}</>
  );
};
