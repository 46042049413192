import {
  MutableRefObject,
  ReactNode, createContext,
  useContext,
  useRef,
  useState
} from 'react';
import { useGetContextValue } from '../../../hooks/useGetContextValue/useGetContextValue';
import { DefaultExtendableDrawers } from './DefaultExtendableDrawers';
import { ExtendDrawerStyleEnum } from '../ExtendableDrawerComponent';

type DrawerWidth = string | number | undefined;

type ExtendedPropertyMap<T> = {
  [key in DefaultExtendableDrawers]?: T
}

type DrawerOnClose = () => Promise<void> | void;

interface ExtendedOnClose {
  beforeOnClose?: DrawerOnClose;
  afterOnClose?: DrawerOnClose;
}

export interface ExtendableDrawerControllerContextValue {
  rootId: DefaultExtendableDrawers;
  rootWidth?: DrawerWidth;
  containerWidth?: number;
  containerWidthRef?: MutableRefObject<number | undefined>;
  setContainerWidth: (width?: number) => void;
  getExtendedWidth: (id?: DefaultExtendableDrawers) => DrawerWidth;
  getExtendedWrapperId: (id?: DefaultExtendableDrawers) => string;
  getExtendedOnClose: (
    onClose: DrawerOnClose,
    id?: DefaultExtendableDrawers,
  ) => () => Promise<boolean>;
  getExtendDrawerStyle: (id?: DefaultExtendableDrawers) => ExtendDrawerStyleEnum;
  shouldDisableExtend?: () => boolean;
  onExtendableDrawerButtonClick?: () => Promise<boolean> | boolean;
}

const ExtendableDrawerControllerContext = createContext<
  ExtendableDrawerControllerContextValue | undefined
>(undefined);

export const useExtendableDrawerController = (id?: DefaultExtendableDrawers) => {
  const context = (
    useContext(ExtendableDrawerControllerContext) || {} as ExtendableDrawerControllerContextValue
  );
  return ({
    ...context,
    getExtendedWidth: () => context.getExtendedWidth?.(id),
    getExtendedWrapperId: () => context.getExtendedWrapperId?.(id),
    getExtendedOnClose: (
      onClose: DrawerOnClose,
    ) => context.getExtendedOnClose?.(onClose, id) || onClose,
    getExtendDrawerStyle: () => context.getExtendDrawerStyle?.(id),
    shouldDisableExtend: context.shouldDisableExtend || (() => false),
    onExtendableDrawerButtonClick: context.onExtendableDrawerButtonClick || (() => true),
  });
};

export interface ExtendableDrawerControllerProps {
  rootId: ExtendableDrawerControllerContextValue['rootId'];
  rootWidth?: ExtendableDrawerControllerContextValue['rootWidth'];
  extendedWidthMap?: ExtendedPropertyMap<DrawerWidth> | number,
  extendedWrapperIdMap?: ExtendedPropertyMap<string> | string,
  extendedOnCloseMap?: ExtendedPropertyMap<ExtendedOnClose>;
  extendDrawerStyleMap?: ExtendedPropertyMap<ExtendDrawerStyleEnum> | ExtendDrawerStyleEnum;
  children: ReactNode;
  // to disable all available extendable drawer buttons within controller
  // true: disable button, false: no disabling button
  // usage only with ExtendableDrawerButtonComponent
  shouldDisableExtend?: () => boolean;
  // trigger async action when button is onclick
  // true: proceed action in button onclick, false: to stop action
  // usage only with ExtendableDrawerButtonComponent
  onExtendableDrawerButtonClick?: () => Promise<boolean> | boolean;
}
export const ExtendableDrawerController = ({
  rootId,
  rootWidth = 'max-content',
  extendedWidthMap,
  extendedWrapperIdMap,
  extendedOnCloseMap,
  extendDrawerStyleMap,
  children,
  shouldDisableExtend,
  onExtendableDrawerButtonClick
}: ExtendableDrawerControllerProps) => {
  const [containerWidth, _setContainerWidth] = useState<number | undefined>(0);
  const containerWidthRef = useRef<number | undefined>(containerWidth);

  const setContainerWidth = (width: number | undefined) => {
    containerWidthRef.current = width;
    _setContainerWidth(width);
  };

  const isRootDrawer = (id?: DefaultExtendableDrawers) => rootId === id;

  const getExtendedWrapperId: ExtendableDrawerControllerContextValue['getExtendedWrapperId'] = (
    id,
  ) => {
    if (isRootDrawer(id)) return '';
    if (!extendedWrapperIdMap) return rootId;
    if (typeof extendedWrapperIdMap === 'string') return extendedWrapperIdMap;
    return extendedWrapperIdMap[id as DefaultExtendableDrawers] || rootId;
  };

  const getExtendedWidth: ExtendableDrawerControllerContextValue['getExtendedWidth'] = (
    id,
  ) => {
    if (isRootDrawer(id)) return rootWidth;
    if (typeof extendedWidthMap === 'number') return extendedWidthMap;
    return extendedWidthMap?.[id as DefaultExtendableDrawers] || 'max-content';
  };

  const getExtendDrawerStyle: ExtendableDrawerControllerContextValue['getExtendDrawerStyle'] = (
    id,
  ) => {
    if (isRootDrawer(id)) return ExtendDrawerStyleEnum.Regular;
    const extendDrawerStyle = (
      (
        extendDrawerStyleMap as ExtendedPropertyMap<ExtendDrawerStyleEnum>
      )?.[id as DefaultExtendableDrawers]
    );
    if (extendDrawerStyleMap && !extendDrawerStyle) {
      return extendDrawerStyleMap as ExtendDrawerStyleEnum;
    }
    return extendDrawerStyle || ExtendDrawerStyleEnum.Regular;
  };

  const getExtendedOnClose: ExtendableDrawerControllerContextValue['getExtendedOnClose'] = (
    onClose,
    id,
  ) => async () => {
    try {
      await (
        extendedOnCloseMap?.[id as DefaultExtendableDrawers] as ExtendedOnClose
      )?.beforeOnClose?.();
      await onClose();
      await (
        extendedOnCloseMap?.[id as DefaultExtendableDrawers] as ExtendedOnClose
      )?.afterOnClose?.();
      return true;
    } catch (e) {
      // ignore
      return false;
    }
  };

  const contextValue = useGetContextValue<ExtendableDrawerControllerContextValue>({
    rootId,
    rootWidth,
    containerWidth,
    containerWidthRef,
    setContainerWidth,
    getExtendedWidth,
    getExtendedWrapperId,
    getExtendedOnClose,
    getExtendDrawerStyle,
    shouldDisableExtend,
    onExtendableDrawerButtonClick,
  });

  return (
    <ExtendableDrawerControllerContext.Provider value={contextValue}>
      {children}
    </ExtendableDrawerControllerContext.Provider>
  );
};
