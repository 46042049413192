import { ReactNode } from 'react';
import { AssociatedInfo } from '../../../../uc-api-sdk';
import { ClinicNameDisplayComponent } from '../../../../uiComponent/DisplayComponent/ClinicDisplayComponent/ClinicNameDisplayComponent';
import { ListComponent } from '../../../../uiComponent/ListComponent/ListComponent';

import './PotentialPatientInfoComponent.scss';
import { PotentialClinicTooltipComponent } from '../PotentialClinicTooltipComponent/PotentialClinicTooltipComponent';
import { WarningBoxComponent } from '../../../../uiComponent/WarningBoxComponent/WarningBoxComponent';

export interface PotentialPatientInfoComponentProps {
  associatedList?: AssociatedInfo[];
}

export const PotentialPatientInfoComponent = ({
  associatedList,
}: PotentialPatientInfoComponentProps) => {
  if (!associatedList || associatedList.length < 2) {
    return null;
  }

  return (
    <WarningBoxComponent className="potential-patient-info">
      This patient is a potential patient in:
      {' '}
      <ListComponent
        value={associatedList as ReactNode[]}
        renderItem={(associated) => (
          associated
            ? (
              <ClinicNameDisplayComponent
                key={(associated as AssociatedInfo)?.clinicId || ''}
                clinic={(associated as AssociatedInfo)?.clinicInfo || {}}
              />
            ) : null
        )}
        splitter=", "
      />
      <PotentialClinicTooltipComponent
        clinics={associatedList}
      />
    </WarningBoxComponent>
  );
};
