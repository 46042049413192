import { useMemo } from 'react';
import { map, filter } from 'lodash';
import { useArticleGetCategories } from '../../../uc-api-sdk';
import { ArticleCategories } from '../types';

export const ALL_CATEGORIES = 'ALL';

const sortedListByName = Object.assign(
  {},
  ...map([
    'All',
    'Diabetes',
    'Hypertension',
    'Weight Management',
    'Wellness',
    'Chronic Kidney Disease (CKD)',
    'Hyperlipidemia',
    'Chronic Obstructive Pulmonary Disease (COPD)',
    'Congestive Heart Failure (CHF)',
    'Holiday Treats',
  ], (v, index) => ({ [v]: index })),
);

export const useArticleCategories = () => {
  const articleCategoriesInfo = useArticleGetCategories({
    options: { sendOnMount: true },
    params: {},
  });
  const {
    isLoading,
    data,
  } = articleCategoriesInfo;

  const articleCategories = useMemo(() => {
    if (data?.data) {
      // eslint-disable-next-line no-underscore-dangle
      const _articleCategories = map(data.data, (d) => ({
        id: d.id,
        name: d.name,
        updatedAt: d.updatedAt,
        slug: d.slug,
        publishDate: d.publishDate,
        icd: d.icd,
      })) as ArticleCategories;

      _articleCategories.unshift({
        id: ALL_CATEGORIES,
        name: 'All',
      });

      const unsorted = [] as ArticleCategories;
      let sorted = Array(_articleCategories.length).fill(-1);
      map(_articleCategories, (a) => {
        const orderIndex = sortedListByName[a.name as string];
        if (orderIndex > -1) sorted.splice(orderIndex, 0, a);
        else unsorted.push(a);
      });
      sorted = filter(sorted, (s) => typeof s !== 'number');
      return [...sorted, ...unsorted];
    }
    return [];
  }, [isLoading, data]);

  return {
    ...articleCategoriesInfo,
    articleCategories,
    isLoadingArticleCategories: isLoading,
  };
};
