import { useCallback, useMemo } from 'react';
import { FormProps } from 'antd';
import { FormSubmitValue, ShouldShow, useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import {
  Address,
  AssignDeviceInfo,
  DeviceAssignment,
  DeviceDeliveryMethodEnum,
  LoanDevice,
  PatientTechLevelEnum,
  VitalEnumType,
} from '../../../uc-api-sdk';
import { DeviceHelper } from '../../device/helper';
import { useAddressForm } from '../../../hooks/formHook/useAddressForm';

export interface SubmitValue {
  techLevel: PatientTechLevelEnum;
  deliveryMethod: DeviceDeliveryMethodEnum;
  deviceList: DeviceAssignment[];
  shippingAddress?: Address;
  loanDevice?: LoanDevice;
}

export const useAssignDeviceForm = () => {
  const addressForm = useAddressForm();

  const addressFormInputs = useMemo(() => ({
    ...Object.assign(
      {},
      ...(
        (Object.keys(addressForm.formInput) as (keyof typeof addressForm.formInput)[]).map((
          k,
        ) => ({
          [k]: {
            ...addressForm.formInput[k],
            name: ['shippingAddress', k],
          }
        }))
      )
    ) as typeof addressForm.formInput,
  }), [addressForm.formInput]);

  const factory = useFormHookFactory({
    techLevel: {
      name: 'techLevel',
      label: 'Evaluate patient\'s tech-level',
    },
    deliveryMethod: {
      name: 'deliveryMethod',
      label: 'How will patient get device?',
    },
    deviceList: {
      name: 'deviceList',
      label: '',
    },
    loanDevice: {
      name: 'loanDevice',
      label: '',
    },
    ...addressFormInputs
  });

  const shouldShowDeliveryMethod: ShouldShow = useCallback((getFieldValue) => (
    getFieldValue(factory.getName('techLevel')) !== undefined
  ), []);

  const shouldShowDevice: ShouldShow = useCallback((getFieldValue) => (
    getFieldValue(factory.getName('deliveryMethod')) !== undefined
  ), []);

  const shouldShowConfirmButton: ShouldShow = useCallback((getFieldValue) => (
    getFieldValue(factory.getName('loanDevice'))
    || getFieldValue(factory.getName('deviceList'))?.length
  ), []);

  const shouldShowAddressInput: ShouldShow = useCallback((getFieldValue) => (
    getFieldValue(factory.getName('deviceList'))?.length
    && getFieldValue(factory.getName('deliveryMethod')) === DeviceDeliveryMethodEnum.SHIP
  ), []);

  const handleSubmit: (onSubmit?: (values: SubmitValue) => Promise<void> | void) => FormProps['onFinish'] = (
    useCallback((onSubmit) => (values) => {
      const { deviceList, shippingAddress, ...otherValues } = values;
      const parsedDeviceList = [] as DeviceAssignment[];
      deviceList.forEach((device: AssignDeviceInfo) => {
        const { deviceModel, deviceMethod, cuffSize } = device;
        if (!deviceModel) return;
        const vitalType = DeviceHelper.getVitalTypeByModel(deviceModel);
        const shouldSaveCuffSize = vitalType === VitalEnumType.BP && cuffSize;
        parsedDeviceList.push({
          vitalType,
          monitorMethod: deviceMethod,
          cuffSize: shouldSaveCuffSize ? cuffSize : undefined,
          devices: device?.deviceId ? [device] : undefined,
        }) as DeviceAssignment;
      });

      const {
        address,
        address2,
        city,
        state,
        zipcode,
      } = shippingAddress || {};
      let parsedShippingAddress: Address | undefined;
      if (address && city && state && zipcode) {
        parsedShippingAddress = {
          streetName: address,
          unit: address2,
          city,
          state,
          postCode: zipcode,
        };
      }

      onSubmit?.({
        ...otherValues as SubmitValue,
        deviceList: parsedDeviceList,
        shippingAddress: parsedShippingAddress,
      });
    }, [])
  );

  const response = useMemo(() => ({
    ...factory,
    shouldShowDeliveryMethod,
    shouldShowDevice,
    shouldShowConfirmButton,
    shouldShowAddressInput,
    handleSubmit,
  }), [
    factory,
    shouldShowDeliveryMethod,
    shouldShowDevice,
    shouldShowConfirmButton,
    shouldShowAddressInput,
    handleSubmit,
  ]);

  return response;
};

export type UseAssignDeviceFormSubmitValue = FormSubmitValue<typeof useAssignDeviceForm>;
