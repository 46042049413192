import { Table, TableProps } from 'antd';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { createColumns } from './column';

export interface ComplianceAlertTableComponentProps<T> extends Omit<TableProps<T>, 'column'> {
}

export const ComplianceAlertTableComponent = <T extends object> ({
  ...props
}: ComplianceAlertTableComponentProps<T>) => {
  const columns = useTableColumns(
    createColumns(),
    ['createdAt', 'priority', 'alertType', 'notes', 'status', 'closedBy', 'closedAt'],
  );

  return (
    <Table
      columns={columns}
      rowKey={(r) => r.id}
      size="middle"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};
