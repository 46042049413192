import { Select } from 'antd';
import { map } from 'lodash';
import { nutritionUnderstandings } from './options';

export interface NutritionUnderstandingSelectComponentProps {
  onChange?: (value: string) => void;
  defaultValue?: string;
}

export const NutritionUnderstandingSelectComponent = ({
  onChange,
  defaultValue,
}: NutritionUnderstandingSelectComponentProps) => (
  <Select
    onChange={onChange}
    defaultValue={defaultValue}
    listItemHeight={10}
    popupClassName="nutrition-component"
    options={map(nutritionUnderstandings, (value) => ({
      value,
      label: value,
    }))}
  />
);
