import { ReactNode } from 'react';
import { WAYSTAR_LOGO } from '../../../../../assets/logoIconInfo';
import { Icon } from '../../../../../icons/Icon';
import { Title } from '../../../../../uiComponent/Title/Title';
import { FlowerSpin } from '../../../../../uiComponent/FlowerSpin/FlowerSpin';

interface WaystarLoadingComponentProps {
  children?: ReactNode;
}

export const WaystarLoadingComponent = ({
  children,
}: WaystarLoadingComponentProps) => (
  <div className="ta-c">
    <Icon info={WAYSTAR_LOGO} />
    <Title>
      {children}
    </Title>
    <div className="my30">
      <FlowerSpin />
    </div>
  </div>
);
