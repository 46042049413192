/* eslint-disable class-methods-use-this */
import { find } from 'lodash';
import { AlertThreshold, MeasureTypeEnum, UCTask } from '../../uc-api-sdk';

export class TaskService {
  public readonly task: UCTask;

  constructor(task: UCTask) {
    this.task = task;
  }

  public findThresholdByMeasureType(measureType: MeasureTypeEnum) {
    return find(this.task.threshold || [], (v) => v.measure === measureType);
  }

  public getCriticallyLowRange(threshold: AlertThreshold) {
    const { range } = threshold;
    return [-Infinity, range?.[0]];
  }

  public getLowRange(threshold: AlertThreshold) {
    const { range } = threshold;
    return [range?.[0], range?.[1]];
  }

  public getNormalRange(threshold: AlertThreshold) {
    const { range } = threshold;
    return [range?.[1], range?.[2]];
  }

  public getHighRange(threshold: AlertThreshold) {
    const { range } = threshold;
    return [range?.[2], range?.[3]];
  }

  public getCriticallyHighRange(threshold: AlertThreshold) {
    const { range } = threshold;
    return [range?.[3], Infinity];
  }
}
