import { PatientSearchFilterComponent } from '../component/PatientSearchFilterComponent/PatientSearchFilterComponent';
import { TableFilterType } from '../type/filter';
import { SearchIcon } from './SearchIcon';

export interface PatientSearchFilterProps {
  selectedOrgId?: string;
}

export const createPatientSearchFilter = ({
  selectedOrgId
}: PatientSearchFilterProps): TableFilterType => ({
  filterDropdown: ((props) => (
    <PatientSearchFilterComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      selectedOrgId={selectedOrgId}
    />
  )),
  filterIcon: SearchIcon,
});
