import React, { FC, ReactNode } from 'react';
import { MeasurementResultTypeEnum } from '../../../../uc-api-sdk';
import { VitalFetchContainer } from '../../container/VitalFetchContainer/VitalFetchContainer';
import VitalHeaderComponent from '../VitalHeaderComponent/VitalHeaderComponent';
import { MeasurementRendererArgs, VitalFetchProps } from '../../type';

export interface VitalChartViewComponentProps extends VitalFetchProps {
  type: MeasurementResultTypeEnum;
  title: string;
  unit?: string;
  chartView: (v: MeasurementRendererArgs) => ReactNode;
  needStats: boolean;
}

export const VitalChartViewComponent: FC<VitalChartViewComponentProps> = ({
  memberId,
  type,
  title,
  unit,
  chartView,
  needStats,
  fromDate,
  toDate,
}) => (
  <div>
    <VitalHeaderComponent
      title={title}
      unit={unit}
    />
    <VitalFetchContainer
      fromDate={fromDate}
      toDate={toDate}
      memberId={memberId}
      type={type}
      needStats={needStats}
    >
      {/* for the chart view we need to set the
      empty data to {} to avoid the chart error */}
      {(data, info) => chartView({ data: info.isLoading ? {} : data, fromDate, toDate })}
    </VitalFetchContainer>
  </div>
);
