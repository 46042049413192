import { RoleTypeEnum } from '../../../uc-api-sdk';
import { EmployeeSearchFilterComponent } from '../component/EmployeeSearchFilterComponent/EmployeeSearchFilterComponent';
import { TableFilterType } from '../type/filter';
import { SearchIcon } from './SearchIcon';

export const createEmployeeSearchFilter = (
  filterRoles: RoleTypeEnum[],
  className?: string,
  needToFilter?: boolean,
  selectedOrgId?: string,
  reassignAssigneesClinicIdList?: string,
  includeUnassigned?: boolean,
  mode?: 'multiple' | 'tags',
): TableFilterType => ({
  filterDropdown: ((props) => (
    <EmployeeSearchFilterComponent
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      includeUnassigned={includeUnassigned}
      filterRoles={filterRoles}
      className={className}
      needToFilter={needToFilter}
      selectedOrgId={selectedOrgId}
      reassignAssigneesClinicIdList={reassignAssigneesClinicIdList}
      mode={mode}
    />
  )),
  filterIcon: SearchIcon,
});
