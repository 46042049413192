import { useMemo } from 'react';
import { FormOptions, useFormHookFactory } from '../../../../hooks/useFormHookFactory/useFormHookFactory';

export const useBasicInfoForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    clinicId: {
      name: 'clinicId',
      label: 'Clinic',
    },
    provider: {
      name: 'provider',
      label: 'Provider',
    },
    spokenLanguage: {
      name: 'spokenLanguage',
      label: 'Preferred Spoken Language',
    },
    username: {
      name: 'username',
      label: 'Username',
    },
    mobilePhoneNum: {
      name: 'mobilePhoneNum',
      label: 'Mobile Phone Number',
    },
    homePhoneNum: {
      name: 'homePhoneNum',
      label: 'Home Phone Number',
    },
    appOTPLogin: {
      name: 'appOTPLogin',
      label: ' ',
    },
  }, options);

  const response = useMemo(() => ({
    ...factory,
  }), [
    factory,
  ]);

  return response;
};
