import { Button } from 'antd';
import { Title } from '../../../../uiComponent/Title/Title';
import { PatientCreateDrawerButtonComponent } from '../../../../uiComponent/PatientCreateDrawerButtonComponent/PatientCreateDrawerButtonComponent';

export const PatientTablesSiderHeaderComponent = () => (
  <div className="flex flex-wrap jc-sb ai-c">
    <Title.PageTitle>
      Patients
    </Title.PageTitle>
    <PatientCreateDrawerButtonComponent>
      <Button type="primary">Create Patient</Button>
    </PatientCreateDrawerButtonComponent>
  </div>
);
