import { isEmpty } from 'lodash';
import { useZoomContext } from '../../../../contexts/ZoomContext/ZoomContext';
import { LinkButton } from '../../../../uiComponent/LinkButton/LinkButton';
import { ZoomEntry } from '../ZoomVideoComponent/ZoomVideoComponent';
import { VisitMethodEnum } from '../../../../uc-api-sdk';

export interface RejoinZoomButtonComponentProps {
  visitMethod?: VisitMethodEnum;
  zoomEntry?: ZoomEntry | null,
  isOnGoing?: boolean,
}

export const RejoinZoomButtonComponent = ({
  visitMethod,
  zoomEntry,
  isOnGoing,
}: RejoinZoomButtonComponentProps) => {
  const { openZoom } = useZoomContext();
  const isVideoVisit = visitMethod === VisitMethodEnum.VIRTUAL;

  if (
    !isVideoVisit
    || isEmpty(zoomEntry)
    || !zoomEntry.meetingId
    || !isOnGoing
  ) {
    return null;
  }

  const handleOpenZoom = () => {
    openZoom(zoomEntry);
  };

  return (
    <LinkButton onClick={handleOpenZoom} useBlueColor>
      Rejoin Zoom Meeting
    </LinkButton>
  );
};
