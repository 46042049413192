import { useMemo } from 'react';
import { FormOptions, useFormHookFactory } from '../../../../hooks/useFormHookFactory/useFormHookFactory';

export const useAssignLoanerPhoneForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    deviceId: {
      name: 'deviceId',
      label: 'Device ID',
    }
  }, options);

  return useMemo(() => factory, [factory]);
};
