import FormItem, { FormItemProps } from '../../../../uiComponent/FormItem/FormItem';
import { YesNoRadioComponent } from '../../../../uiComponent/YesNoRadioComponent/YesNoRadioComponent';
import { DiseaseTestedBeforeLabel } from '../DiseaseHistoryDisplayComponent/DiseaseTestedBeforeLabel';
import { ChronicDiseaseEnum } from '../../../../uc-api-sdk';

export interface DiseaseHistoryTestedBeforeFormItemComponentProps
  extends Omit<FormItemProps, 'children'> {
  disease?: ChronicDiseaseEnum;
}

export const DiseaseHistoryTestedBeforeFormItemComponent = ({
  disease,
  name,
  ...restFormItemProps
}: DiseaseHistoryTestedBeforeFormItemComponentProps) => (
  <FormItem
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...restFormItemProps}
    name={name}
    label={(
      <DiseaseTestedBeforeLabel
        disease={disease}
      />
    )}
  >
    <YesNoRadioComponent />
  </FormItem>
);
