import { Button, Drawer } from 'antd';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { ClickableDiv } from '../ClickableDiv/ClickableDiv';
import { useOpen } from '../../hooks/useBoolean/useOpen';
import { HistoryListComponent, HistoryListComponentProps } from './HistoryListComponent';
import { HistoryInfo } from './HistoryItemComponent';
import './HistoryDrawerComponent.scss';

export interface HistoryDrawerComponentProps<T extends HistoryInfo> {
  history?: HistoryListComponentProps<T>['notes'] | null | undefined;
  children?: ReactNode;
  isLifeStyle?: boolean;
}

export const HistoryDrawerComponent = <T extends HistoryInfo>({
  history,
  children,
  isLifeStyle = false,
}: HistoryDrawerComponentProps<T>) => {
  const { isOpen, open, close } = useOpen();

  const renderButton = () => (
    children || (
      <Button
        onClick={open}
        type={isLifeStyle ? 'link' : 'text'}
        className={classNames({
          'history-drawer-component-btn': true,
          'see-history-btn': !isLifeStyle,
        })}
      >
        {
          isLifeStyle
            ? 'See Lifestyle Pattern History'
            : 'See History'
        }
      </Button>
    )
  );

  return (
    <>
      <ClickableDiv className="w-mc" onClick={open}>
        {renderButton()}
      </ClickableDiv>
      <Drawer open={isOpen} onClose={close} title="History">
        <HistoryListComponent
          notes={history || []}
        />
      </Drawer>
    </>
  );
};
