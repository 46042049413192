/* eslint-disable camelcase */
import {
  BGMessagePayload,
  BPMessagePayload,
  HSMessagePayload,
  MessagePayload,
  POMessagePayload,
  TMMessagePayload,
  VitalMessagePayload,
} from '../../../../services/CHSServices/types/data';
import { MeasurementResultTypeEnum } from '../../../../uc-api-sdk';
import { MessageBGComponent } from '../MessageVitalComponent/MessageBGComponent/MessageBGComponent';
import { MessageBPComponent } from '../MessageVitalComponent/MessageBPComponent/MessageBPComponent';
import { MessageHSComponent } from '../MessageVitalComponent/MessageHSComponent/MessageHSComponent';
import { MessagePOComponent } from '../MessageVitalComponent/MessagePOComponent/MessagePOComponent';
import { MessageTMComponent } from '../MessageVitalComponent/MessageTMComponent/MessageTMComponent';

export interface MessageMeasurementItemComponentProps {
  messagePayload: MessagePayload;
}

export const MessageMeasurementItemComponent = ({
  messagePayload,
}: MessageMeasurementItemComponentProps) => {
  const {
    vitalName,
    measuredAt,
    unit,
    severity,
    text,
  } = messagePayload as VitalMessagePayload;

  if (vitalName === MeasurementResultTypeEnum.BP) {
    const {
      diastolic,
      systolic,
      severity: bpSeverity,
    } = messagePayload as BPMessagePayload;

    return (
      <MessageBPComponent
        measuredAt={measuredAt}
        unit={unit}
        severity={bpSeverity}
        diastolic={diastolic}
        systolic={systolic}
        text={text}
      />
    );
  }

  if (vitalName === MeasurementResultTypeEnum.BG) {
    const {
      value,
    } = messagePayload as BGMessagePayload;

    return (
      <MessageBGComponent
        measuredAt={measuredAt}
        unit={unit}
        severity={severity}
        value={value}
        text={text}
      />
    );
  }

  if (vitalName === MeasurementResultTypeEnum.TM) {
    const {
      temperature,
    } = messagePayload as TMMessagePayload;

    return (
      <MessageTMComponent
        measuredAt={measuredAt}
        unit={unit}
        severity={severity}
        temperature={temperature}
        text={text}
      />
    );
  }

  if (vitalName === MeasurementResultTypeEnum.HS) {
    const {
      weight,
      weight_change,
    } = messagePayload as HSMessagePayload;

    return (
      <MessageHSComponent
        weight={weight}
        weightChange={weight_change}
        unit={unit}
        measuredAt={measuredAt}
        text={text}
      />
    );
  }

  if (vitalName === MeasurementResultTypeEnum.PO) {
    const {
      oxygen_saturation,
    } = messagePayload as POMessagePayload;

    return (
      <MessagePOComponent
        measuredAt={measuredAt}
        unit={unit}
        severity={severity}
        oxygenSaturation={oxygen_saturation}
        text={text}
      />
    );
  }

  return null;
};
