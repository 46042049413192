import { useCallback, useMemo } from 'react';
import { useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { GetFieldValue } from '../../../types/form';

export const useSelectCoverRangeForm = () => {
  const factory = useFormHookFactory({
    membersRD: {
      name: 'membersRD',
      label: '',
    },
    membersHC: {
      name: 'membersHC',
      label: '',
    },
    membersCA: {
      name: 'membersCA',
      label: '',
    },
    priorityRD: {
      name: 'priorityRD',
      label: '',
    },
    priorityHC: {
      name: 'priorityHC',
      label: '',
    },
    priorityCA: {
      name: 'priorityCA',
      label: '',
    },
  });

  const getConfirmButtonDisabled = useCallback((getFieldValue: GetFieldValue) => {
    let disabled = false;

    if (
      !getFieldValue(factory.getName('membersRD'))?.length
      && !getFieldValue(factory.getName('membersHC'))?.length
      && !getFieldValue(factory.getName('membersCA'))?.length
    ) {
      return true;
    }
    if (
      getFieldValue(factory.getName('membersRD'))
      && (getFieldValue(factory.getName('membersRD'))?.length || 0) > 0
      && !getFieldValue(factory.getName('priorityRD'))?.length) {
      disabled = true;
    }
    if (
      getFieldValue(factory.getName('membersHC'))
      && (getFieldValue(factory.getName('membersHC'))?.length || 0) > 0
      && !getFieldValue(factory.getName('priorityHC'))?.length
    ) {
      disabled = true;
    }
    if (
      getFieldValue(factory.getName('membersCA'))
      && (getFieldValue(factory.getName('membersCA'))?.length || 0) > 0
      && !getFieldValue(factory.getName('priorityCA'))?.length) {
      disabled = true;
    }
    return disabled;
  }, []);

  const response = useMemo(() => ({
    getConfirmButtonDisabled,
    ...factory,
  }), [factory]);

  return response;
};
