import {
  Button, Col, Divider, Row
} from 'antd';
import React from 'react';
import { ClinicInFilterComponent } from '../ClinicInFilterComponent/ClinicInFilterComponent';
import { PatientSearchSelectPropsForClinicInFilter } from './PatientSearchSelectComponent';

export interface PatientSelectDropdownInHeaderComponentProps
  extends PatientSearchSelectPropsForClinicInFilter {
  menu: React.ReactElement;
  onPatientCreateClick?: () => void;
  getAllClinics?: boolean;
}

export const PatientSelectDropdownInHeaderComponent = ({
  clinicInFilterValue,
  onChangeClinicInFilter = () => 0,
  menu,
  onPatientCreateClick,
  getAllClinics,
}: PatientSelectDropdownInHeaderComponentProps) => {
  const renderOptions = () => (
    <div>
      <div className="ml20 mr20">
        <ClinicInFilterComponent
          value={clinicInFilterValue}
          onChange={onChangeClinicInFilter}
          getAllClinics={getAllClinics}
        />
      </div>
      <Divider className="mt5 mb5" />
      <div className="ml20 mr20">
        <div className="fs-normal text-secondary-gray mt10 mb10">Matched Results</div>
        <div className="fs12 text-secondary-gray mt10 mb10">
          <Row gutter={24}>
            <Col span={5}>
              Patient
            </Col>
            <Col span={5}>
              Clinic
            </Col>
            <Col span={5}>
              Provider
            </Col>
            <Col span={3}>
              MRN
            </Col>
            <Col span={3}>
              Phone
            </Col>
            <Col span={3}>
              Enroll Status
            </Col>
          </Row>
        </div>
      </div>
      <div className="ml10 mr10 mt5 mb5">
        {menu}
      </div>
      <Divider className="mt5 mb5" />
      <div>
        <Button
          className="ml5 mt5 mb5"
          type="link"
          onClick={onPatientCreateClick}
        >
          Create a new patient
        </Button>
      </div>
    </div>
  );
  return renderOptions();
};
