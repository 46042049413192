import { Modal } from 'antd';
import { PopupArg } from '../../../../contexts/CarePortalPopupContext/types';
import { AFKBillableTimeFormContainer } from '../../../patient/container/AFKBillableTimeFormContainer/AFKBillableTimeFormContainer';

export interface AFKBillableTimeModalComponentProps extends PopupArg {
  totalTime: number;
  totalAfkTime: number;
  patientId: string;
}

export const AFKBillableTimeModalComponent = ({
  totalTime,
  totalAfkTime,
  isOpen,
  onCancel,
  patientId,
}: AFKBillableTimeModalComponentProps) => (
  <Modal
    open={isOpen}
    onCancel={onCancel}
    destroyOnClose
    maskClosable={false}
    footer={null}
    width={416}
    closable={false}
  >
    <AFKBillableTimeFormContainer
      totalAfkTime={totalAfkTime}
      totalTime={totalTime}
      onSubmit={onCancel}
      onIgnore={onCancel}
      patientId={patientId}
    />
  </Modal>
);
