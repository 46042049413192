import { Table } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { BGMeasurementService } from '../../helper/BGMeasurementService';
import { BGTablePrintColumns } from './BGTablePrintColumns';

export interface BGTablePrintComponentProps {
  data: BGMeasurementService[];
}

export const BGTablePrintComponent: FC<BGTablePrintComponentProps> = ({
  data,
}) => {
  const columns = useTableColumns(BGTablePrintColumns, ['date', 'overnight', 'breakfast', 'lunch', 'dinner', 'bedtime']);

  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={false}
      className={classNames({
        'table-header-cell-center': true,
        'bg-logbook-table': true,
        'table-cell-no-padding': true,
      })}
      bordered
      rowKey="id"
    />
  );
};
