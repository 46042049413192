import { useMemo } from 'react';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { VitalEnumType } from '../../../../uc-api-sdk';
import { BGHelper } from '../../helper/BGHelper';
import { BGTaskService } from '../../../../services/TaskService/BGTask';

export const useBGThresholdPlotBand = () => {
  const { value: showBeforeMeal, toggle: toggleBeforeMeal } = useBoolean(true);
  const { value: showAfterMeal, toggle: toggleAfterMeal } = useBoolean(false);
  const { value: showOverNightMeal, toggle: toggleOverNightMeal } = useBoolean(false);
  const { value: showBreakfastMeal, toggle: toggleBreakfastMeal } = useBoolean(true);
  const { info } = usePatientContext();

  const normalRanges = useMemo(() => {
    const bgTask = info?.enrolledProgramService.getVitalTask(VitalEnumType.BG) as BGTaskService;
    const beforeMThreshold = bgTask?.getBeforeMealThreshold();
    const afterMThreshold = bgTask?.getAfterMealThreshold();
    const beforeMealNormalRange = beforeMThreshold && bgTask?.getNormalRange(beforeMThreshold);
    const afterMealNormalRange = afterMThreshold && bgTask?.getNormalRange(afterMThreshold);
    return {
      beforeMealNormalRange: beforeMealNormalRange && {
        from: BGHelper.mmollToMgdl(beforeMealNormalRange[0] || 0),
        to: BGHelper.mmollToMgdl(beforeMealNormalRange[1] || 0),
      },
      afterMealNormalRange: afterMealNormalRange && {
        from: BGHelper.mmollToMgdl(afterMealNormalRange[0] || 0),
        to: BGHelper.mmollToMgdl(afterMealNormalRange[1] || 0),
      },
    };
  }, [info]);

  return {
    showBeforeMeal,
    showBreakfastMeal,
    showOverNightMeal,
    showAfterMeal,
    toggleBeforeMeal,
    toggleAfterMeal,
    toggleOverNightMeal,
    toggleBreakfastMeal,
    normalRanges,
  };
};
