import { ReactNode } from 'react';
import classNames from 'classnames';
import CardWHorizontalSectionComponent from '../../../../uiComponent/CardWHorizontalSectionComponent/CardWHorizontalSectionComponent';
import { PortalVersionComponent } from '../PortalVersionComponent/PortalVersionComponent';

import './AuthCardComponent.scss';
import { Icon } from '../../../../icons/Icon';
import { UNIFIEDCARE_PILOT_LOGO } from '../../../../assets/logoIconInfo';

export interface AuthCardComponentProps {
  children: ReactNode;
}

export const AuthCardComponent = ({
  children,
}: AuthCardComponentProps) => (
  <div className="auth-card-wrapper">
    <div
      className={classNames({
        'auth-card-component': true,
        'flex fd-c jc-c ai-c': true,
      })}
    >
      <Icon className="unified-care-logo" info={UNIFIEDCARE_PILOT_LOGO} />

      <CardWHorizontalSectionComponent
        className="auth-card-component__body flex fd-c ai-c"
      >
        {children}
      </CardWHorizontalSectionComponent>

      <PortalVersionComponent />
    </div>
  </div>
);
