import { Icons } from '../../icons/Icons';
import { ErrorBoundaryCardComponent } from './ErrorBoundaryCardComponent';
import './ErrorBoundaryFallbackComponent.scss';

export interface ErrorBoundaryAppFallbackComponentProps {

}

export const ErrorBoundaryAppFallbackComponent = () => (
  <div className="error-boundary-app-fallback-component">
    <ErrorBoundaryCardComponent
      icon={<Icons.AppCrashIcon />}
      message={`Oops, the care portal crashed due to an error. Kindly close this browser
        tab and reopen the care portal. If the issue persists, please reach out to IT
        support for assistance.`}
    />
  </div>
);
