import { ReactNode } from 'react';
import { CheckboxProps } from 'antd';
import { CheckboxCardComponent } from '../../../../uiComponent/CheckboxCardComponent/CheckboxCardComponent';
import { InputType } from '../../../Input/types';

import './OrderCheckboxComponent.scss';

export interface OrderCheckboxComponentProps extends InputType<boolean> {
  children: ReactNode;
}

export const OrderCheckboxComponent = ({
  value,
  onChange,
  children,
}: OrderCheckboxComponentProps) => {
  const handleOnChange: CheckboxProps['onChange'] = (e) => {
    const isChecked = e.target.checked;
    onChange?.(isChecked);
  };

  return (
    <CheckboxCardComponent
      className="order-checkbox"
      value={value}
      onChange={handleOnChange}
      checkboxOnly
    >
      <div className="flex f-w ai-c">
        {children}
      </div>
    </CheckboxCardComponent>
  );
};
