import { FormProps } from 'antd';
import { useCallback } from 'react';
import { difference, map } from 'lodash';
import { FormOptions } from '../../../hooks/useFormHookFactory/useFormHookFactory';
import { useIterableFormHook } from '../../../hooks/useIterableFormListHook/useIterableFormHook';
import { MonitoringVital, VitalEnumType } from '../../../uc-api-sdk';
import { SubmitValue as DeviceMonitorSubmitValue } from '../../device/component/DeviceMonitorFormComponent/DeviceMonitorFormComponent';
import { SubmitValue as VitalScheduleSubmitValue } from '../../vitals/component/VitalsMonitoringScheduleFormComponent/VitalsMonitoringScheduleFormComponent';

export type SupportedVitalScheduleEnumType = (
  Exclude<VitalEnumType, VitalEnumType.EXERCISE | VitalEnumType.value>
);

interface VitalsDeviceScheduleValue
  extends MonitoringVital {
  deviceMonitor?: DeviceMonitorSubmitValue;
  schedule?: VitalScheduleSubmitValue;
  removeDevicesFor?: SupportedVitalScheduleEnumType[];
}

export interface AddRemoveVitalsMonitoringSubmitValue
  extends VitalsDeviceScheduleValue {
  vitals?: VitalsDeviceScheduleValue[];
  removedVitalList?: VitalEnumType[];
}

export interface AddRemoveVitalsMonitoringFormValues {
  vitals: {
    [key in VitalEnumType]?: VitalsDeviceScheduleValue;
  }
}

export const useAddRemoveVitalsMonitoringForm = (options?: FormOptions) => {
  const factory = useIterableFormHook('vitals', {
    type: {
      name: 'type',
    },
    createdAt: {
      name: 'createdAt',
    },
  }, {
    name: 'profileVitalsMonitoringForm',
    ...options,
  });

  const getMonitoringVitals = useCallback((
    values: AddRemoveVitalsMonitoringFormValues,
    initialValues?: AddRemoveVitalsMonitoringSubmitValue,
  ) => {
    const { vitals } = values;
    const submitVitals = map(vitals, (v) => {
      if (!v?.type) return null;
      const vitalSchedule = v.schedule?.[v.type as SupportedVitalScheduleEnumType];
      return {
        type: v.type,
        deviceMonitor: v.deviceMonitor,
        schedule: vitalSchedule,
      };
    }).filter((v) => !!v) as AddRemoveVitalsMonitoringSubmitValue['vitals'];
    const currentVitalTypeList = map(initialValues?.vitals, 'type');
    const newVitalTypeList = map(submitVitals, 'type');
    const removedVitalList = difference(currentVitalTypeList, newVitalTypeList) as VitalEnumType[];

    const newValues: AddRemoveVitalsMonitoringSubmitValue = {
      ...values,
      removedVitalList: removedVitalList.length ? removedVitalList : undefined,
      vitals: submitVitals,
    };
    return newValues;
  }, []);

  const handleSubmit: (
    onSubmit?: (v: AddRemoveVitalsMonitoringSubmitValue) => (Promise<void> | void),
    initialValues?: AddRemoveVitalsMonitoringSubmitValue,
  ) => FormProps['onFinish'] = useCallback((
    onSubmit,
    initialValues,
  ) => (values: AddRemoveVitalsMonitoringFormValues) => {
    onSubmit?.(getMonitoringVitals(values, initialValues));
  }, []);

  const onValuesChange: (
    onValuesChange: FormProps['onValuesChange'],
    initialValues?: AddRemoveVitalsMonitoringSubmitValue,
  ) => FormProps['onValuesChange'] = useCallback((onValuesChange, initialValues) => (
    changedValues,
    values,
  ) => {
    onValuesChange?.(changedValues, getMonitoringVitals(values, initialValues));
  }, []);

  return {
    ...factory,
    handleSubmit,
    getMonitoringVitals,
    onValuesChange,
  };
};
