import classNames from 'classnames';
import './WarningBoxComponent.scss';

export interface WarningBoxComponentProps {
  children?: React.ReactNode;
  className?: string;
}

export const WarningBoxComponent = ({
  children,
  className = ''
}: WarningBoxComponentProps) => (
  <div
    className={classNames({
      'warning-box': true,
      [className]: className,
    })}
  >
    {children}
  </div>
);
