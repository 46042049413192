import React from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { LabResultEditButtonContainer } from '../../container/LabResultTableContainer/LabResultEditButtonContainer';
import { LabResultDeleteButtonContainer } from '../../container/LabResultTableContainer/LabResultDeleteButtonContainer';
import { OtherLabResultTableColumnActionComponentProp } from './OtherLabResultTableColumnActionComponent';

export interface LabResultTableColumnActionComponentProp
extends OtherLabResultTableColumnActionComponentProp {
}
export const LabResultTableColumnActionComponent = ({
  labResult,
}: LabResultTableColumnActionComponentProp) => (
  <Dropdown
    overlayClassName="button-in-dropdown"
    menu={{
      items: [
        {
          key: 'Edit',
          label: (
            <LabResultEditButtonContainer
              labResult={labResult}
            />
          ),
        },
        {
          key: 'Delete',
          label: (
            <LabResultDeleteButtonContainer id={labResult?.id ?? undefined} />
          ),
        },
      ],
    }}
  >
    <MoreOutlined />
  </Dropdown>
);
