import { Table } from 'antd';
import { TableProps } from 'antd/es/table';
import {
  FC, useEffect, useMemo, useState
} from 'react';
import { uniqueId } from 'lodash';
import { useTableColumns } from '../../../../hooks/table/useTableColumns/useTableColumns';
import { LabResultClass } from '../../../../uc-api-sdk';
import { otherLabResultColumns } from './OtherLabResultTableColumn';
import { useLabResultTimer } from '../../hook/useLabResultTimer';
import { LabResultModalComponent } from '../LabResultModalComponent/LabResultModalComponent';
import { ClientPos } from '../../../../uiComponent/ModalComponent/ModalComponent';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';

export interface OtherLabTableComponentProps extends Omit<TableProps<LabResultClass>, 'column'> {
  allOtherLabResult: LabResultClass[];
}

export const OtherLabTableComponent: FC<OtherLabTableComponentProps> = ({
  dataSource,
  allOtherLabResult,
  ...props
}) => {
  const justAddedLabResultId = useLabResultTimer();
  const [selectedLabResult, setSelectedLabResult] = useState<LabResultClass | undefined>();
  const currentLabResultIndex = useMemo(() => allOtherLabResult?.findIndex(
    (labResult) => labResult.id === selectedLabResult?.id
  ) || 0, [selectedLabResult?.id, allOtherLabResult]);

  const columns = useTableColumns(
    otherLabResultColumns(setSelectedLabResult),
    ['dateCollected', 'templateName', 'action'],
  );

  const [position, setPosition] = useState<ClientPos>();

  const tableInMedicalHistory = document.querySelector('.OtherLabTableComponent') as HTMLElement;
  const tableInWorklist = document.querySelector('.worklist-section') as HTMLElement;

  useEffect(() => {
    if (tableInMedicalHistory) {
      const rect = tableInMedicalHistory.getBoundingClientRect();
      setPosition({
        clientX: rect.left * 2, clientY: window.innerHeight / 2 - rect.top
      });
    }
    if (tableInWorklist) {
      const rect = tableInWorklist.getBoundingClientRect();
      setPosition({ clientX: rect.left / 13, clientY: window.innerHeight / 13 });
    }
  }, [tableInMedicalHistory, tableInWorklist]);

  const handleOnClose = () => {
    setSelectedLabResult(undefined);
  };

  useUpdateListener('LAB_DELETE', handleOnClose);
  useUpdateListener('LAB_EDITED', handleOnClose);

  const handleGoToPrevModal = () => {
    setSelectedLabResult(allOtherLabResult?.at(currentLabResultIndex - 1));
  };

  const handleGoToNextModal = () => {
    setSelectedLabResult(allOtherLabResult?.at(currentLabResultIndex + 1));
  };

  return (
    <div className="OtherLabTableComponent">
      <Table
        dataSource={dataSource}
        size="middle"
        onRow={(row) => ({
          style: (justAddedLabResultId && row.id === justAddedLabResultId) ? { background: '#e6f4ff' } : {},
        })}
        className="mb0"
        columns={columns}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        rowKey={(r) => r.id || uniqueId()}
      />
      <LabResultModalComponent
        clientPos={position}
        onClose={handleOnClose}
        labResult={selectedLabResult}
        disablePre={currentLabResultIndex === 0}
        disableNext={currentLabResultIndex === allOtherLabResult.length - 1}
        goToNextLabResult={handleGoToNextModal}
        goToPreLabResult={handleGoToPrevModal}
      />
    </div>
  );
};
