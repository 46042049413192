import { ReactNode } from 'react';
import { Button } from 'antd';

export interface ArticleSendButtonComponentProps {
  onSubmit?: () => void,
  disabled: boolean,
  children: ReactNode,
}
export const ArticleSendButtonComponent = (
  props: ArticleSendButtonComponentProps,
) => {
  const {
    onSubmit,
    disabled,
    children,
  } = props;

  const handleSubmit = () => {
    onSubmit?.();
  };

  return (
    <Button
      id="submit-article-button"
      type="primary"
      className="submit-button"
      onClick={(e) => {
        e.stopPropagation();
        handleSubmit();
      }}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};
