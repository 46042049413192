import { useMemo } from 'react';
import {
  GetOptions, MakeRequestHookConfig, UseRequestFactory, useRequestFactory,
} from '../../../uc-api-sdk/staticFiles/useReqHook';
import { chsServices } from '../CHSServices';
import { PaginatedChannelsParams, PaginatedChannelsResponseData } from '../types';

export const useGetPaginatedChannels = <RCN = undefined>(
  configs?: MakeRequestHookConfig<PaginatedChannelsParams, GetOptions, RCN>,
) => {
  const baseConfigs: UseRequestFactory<
    PaginatedChannelsParams,
    PaginatedChannelsResponseData,
    typeof chsServices.getPaginatedChannels,
    GetOptions,
    PaginatedChannelsResponseData
  > = useMemo(() => ({
    reqFunc: (...args: Parameters<typeof chsServices.getPaginatedChannels>) => (
      chsServices.getPaginatedChannels(...args)
    ),
    ResponseClassCreator: chsServices.getResponse,
    typeName: 'useGetPaginatedChannels',
  }), []);

  return useRequestFactory(baseConfigs, configs);
};
