import classNames from 'classnames';
import { map } from 'lodash';
import {
  Collapse
} from 'antd';
import { ComplianceAlert } from '../../../../uc-api-sdk';
import './AlertSnoozeListComponent.scss';
import { AlertItemComponent } from './AlertItemComponent';

const { Panel } = Collapse;

export interface AlertSnoozeListComponentProps {
  data: ComplianceAlert[];
}

export const AlertSnoozeListComponent = ({
  data,
}: AlertSnoozeListComponentProps) => (
  <div
    className={classNames({
      'snooze-collapse': true,
    })}
  >
    <Collapse ghost>
      <Panel
        header={`Snoozed alerts (${data.length})`}
        key="snooze"
      >
        {data.length > 0
          ? map(data, (data, index) => (
            <AlertItemComponent
              key={index}
              data={data}
              type="snooze"
              showCheckbox={false}
            />
          ))
          : null}
      </Panel>
    </Collapse>
  </div>
);
