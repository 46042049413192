import React from 'react';
import { Button } from 'antd';
import {
  HeaderPopoverContentComponentProps,
} from '../../../../uiComponent/HeaderPopoverComponent/HeaderPopoverComponent';
import { QualifiedComponent } from '../../../../uiComponent/QualifiedComponent/QualifiedComponent';
import { NotQualifiedComponent } from '../../../../uiComponent/NotQualifiedComponent/NotQualifiedComponent';
import './MeasurementProgressPopoverContentComponent.scss';
import {
  PatientProfileTabEnum,
  usePatientPageControllerContext,
} from '../../context/PatientPageControllerContext/PatientPageControllerContext';

export interface MeasurementProgressPopoverContentComponentProps
  extends HeaderPopoverContentComponentProps {
}

export const MeasurementProgressPopoverContentComponent = ({
  onClose,
  max,
  current,
  qualified,
}: MeasurementProgressPopoverContentComponentProps) => {
  const { onTabChange } = usePatientPageControllerContext();

  const handleHistory = () => {
    onClose?.();
    onTabChange(PatientProfileTabEnum.Profile, 'billinghistory');
  };

  const cur = current ?? 0;
  const ma = max ?? 0;

  return (
    <div className="measurement-progress-popover">
      <div className="fd-c ai-s">
        {qualified ? <QualifiedComponent /> : <NotQualifiedComponent />}
        <div className="my12">
          {cur < ma ? `Patients need to take ${ma - cur} ${ma - cur > 1 ? 'days' : 'day'} or more to be eligible for billing.`
            : 'The patient have reached the minimum days threshold to bill'}
        </div>
      </div>
      <Button type="link" className="p0" onClick={handleHistory}>
        Billing Eligibility History
      </Button>
    </div>
  );
};
