// @ts-nocheck
import { upperFirst } from 'lodash';
import { Point } from '../../type';

interface BGPointChart<T extends Point> {
  beforeBreakfast: T[];
  beforeMeal: T[];
  afterMeal: T[];
  overnightBedtime: T[];
  risk: T[];
  critical: T[];
  onBeforeBreakfastClick: () => void;
  onBeforeMealClick: () => void;
  showAfterMeal: boolean;
  onAfterMealClick: () => void;
  showOverNightMeal: boolean;
  onOvernightClick: () => void;
}

const createTooltip = () => ({
  pointFormatter() {
    const { y, severity } = this;
    return `
      <br />
      Severity:<b>${upperFirst(severity.toLowerCase())}</b>
      <br />
      BG: <b>${y}</b>
      <br />
    `;
  },
});

export const createBGPointChartSeries = <T extends Point>(info: BGPointChart<T>) => ([
  {
    data: info.beforeBreakfast,
    name: 'Before Breakfast',
    findNearestPointBy: 'xy',
    lineWidth: 0,
    fillColor: null,
    color: '#e8c441',
    symbol: 'triangle',
    states: {
      hover: {
        lineWidthPlus: 0,
      },
    },
    marker: {
      enabled: true,
      symbol: 'triangle',
      fillColor: '#e8c441',
      radius: 6,
      lineWidth: 1,
      lineColor: null,
    },
    events: {
      legendItemClick() {
        info.onBeforeBreakfastClick();
        return true;
      },
    },
  },
  {
    data: info.beforeMeal,
    name: 'Before Meal',
    findNearestPointBy: 'xy',
    lineWidth: 0,
    color: '#e0974e',
    fillColor: '#e0974e',
    symbol: 'circle',
    states: {
      hover: {
        lineWidthPlus: 0,
      },
    },
    marker: {
      enabled: true,
      symbol: 'circle',
      fillColor: '#e0974e',
      radius: 4,
      lineWidth: 1,
      lineColor: null,
    },
    events: {
      legendItemClick() {
        info.onBeforeMealClick();
        return true;
      },
    },
  },
  {
    data: info.afterMeal,
    name: 'After Meal',
    findNearestPointBy: 'xy',
    lineWidth: 0,
    color: '#1fb5ac',
    symbol: 'circle',
    states: {
      hover: {
        lineWidthPlus: 0,
      },
    },
    visible: info.showAfterMeal,
    marker: {
      enabled: true,
      radius: 4,
      symbol: 'circle',
    },
    events: {
      legendItemClick() {
        info.onAfterMealClick();
        return true;
      },
    },
  },
  {
    data: info.overnightBedtime,
    name: 'Overnight/Bedtime',
    findNearestPointBy: 'xy',
    lineWidth: 0,
    lineColor: '#186DD3',
    color: '#186DD3',
    symbol: 'square',
    states: {
      hover: {
        lineWidthPlus: 0,
      },
    },
    visible: info.showOverNightMeal,
    marker: {
      enabled: true,
      radius: 4,
      symbol: 'square',
    },
    events: {
      legendItemClick() {
        info.onOvernightClick();
        return true;
      },
    },
  },
  {
    data: [],
    name: 'Critical',
    findNearestPointBy: 'xy',
    lineWidth: 0,
    lineColor: '#EE6161',
    color: '#EE6161',
    states: {
      hover: {
        lineWidthPlus: 0,
      },
    },
    marker: {
      enabled: true,
      radius: 4,
      symbol: 'square',
    },
    events: {
      legendItemClick() {
        return false;
      },
    },
    tooltip: createTooltip(),
  },
  {
    data: [],
    name: 'Risk',
    findNearestPointBy: 'xy',
    lineWidth: 0,
    lineColor: '#FFBD03',
    color: '#FFBD03',
    states: {
      hover: {
        lineWidthPlus: 0,
      },
    },
    marker: {
      enabled: true,
      radius: 4,
      symbol: 'square',
    },
    events: {
      legendItemClick() {
        return false;
      },
    },
    tooltip: createTooltip(),
  },
]);
