import { useMemo } from 'react';
import { useFormHookFactory } from '../../../hooks/useFormHookFactory/useFormHookFactory';

export const useLoginForm = () => {
  const factory = useFormHookFactory({
    usernameOrEmail: {
      name: 'usernameOrEmail',
      emptyMessage: 'Username is required!',
      label: '',
      placeholder: 'Enter your username',
    },
    password: {
      name: 'password',
      emptyMessage: 'Password is required!',
      label: '',
      placeholder: 'Enter your password',
    },
  });

  const response = useMemo(() => ({
    ...factory,
  }), [factory]);

  return response;
};
