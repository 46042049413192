// @ts-nocheck
import { Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { map } from 'lodash';
import React, { FC, useCallback, useMemo } from 'react';
import { PeriodUnitEnum, PeriodValue } from '../../../../types/time';
import { InputType } from '../../../Input/types';

export interface PrintPeriodSelectComponentProps extends InputType<PeriodValue> {

}

export const PrintPeriods = {
  OneWeek: {
    text: 'Recent 1 week',
    value: {
      key: 'OneWeek',
      value: 1,
      unit: PeriodUnitEnum.Week,
    },
  },
  TwoWeeks: {
    text: 'Recent 2 week',
    value: {
      key: 'TwoWeeks',
      value: 2,
      unit: PeriodUnitEnum.Week,
    },
  },
  OneMonth: {
    text: 'Recent 1 month',
    value: {
      key: 'OneMonth',
      value: 1,
      unit: PeriodUnitEnum.Month,
    },
  },
  ThreeMonths: {
    text: 'Recent 3 months',
    value: {
      key: 'ThreeMonths',
      value: 3,
      unit: PeriodUnitEnum.Month,
    },
  },
  SixMonths: {
    text: 'Recent 6 months',
    value: {
      key: 'SixMonths',
      value: 6,
      unit: PeriodUnitEnum.Month,
    },
  },
};

export const PrintPeriodSelectComponent: FC<PrintPeriodSelectComponentProps> = ({
  value,
  onChange,
}) => {
  const options = useMemo<SelectProps['options']>(() => (
    map(PrintPeriods, (v, k) => {
      const res: DefaultOptionType = {
        label: v.text,
        value: k,
      };
      return res;
    })
  ), []);

  const handleChange = useCallback<NonNullable<SelectProps['onChange']>>((v) => {
    onChange?.(PrintPeriods[v].value);
  }, [onChange]);

  return (
    <Select
      className="w100"
      options={options}
      value={value?.key}
      onChange={handleChange}
    />
  );
};
