import { SignatureCollectionMethodEnumComponent } from '../../../../enumComponent/SignatureCollectionMethodEnumComponent/SignatureCollectionMethodEnumComponent';
import { SignatureCollectionMethodEnum } from '../../../../uc-api-sdk';
import { PDFViewerComponent } from '../../../../uiComponent/PDFViewerComponent/PDFViewerComponent';
import { Title } from '../../../../uiComponent/Title/Title';

export interface PreviewConsentComponentProps {
  signatureMethod: SignatureCollectionMethodEnum;
  fileUrl: string;
}

export const PreviewConsentComponent = ({
  signatureMethod,
  fileUrl,
}: PreviewConsentComponentProps) => (
  <div className="flex gap0 fd-c h100">
    <Title>Sign Consent Form</Title>
    <h3>Signature Collection Method</h3>
    <p>
      <SignatureCollectionMethodEnumComponent
        value={signatureMethod}
      />
    </p>
    <div className="f1">
      <PDFViewerComponent fileUrl={fileUrl} height="100%" />
    </div>
  </div>
);
