import { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { usePatientTagList } from '../../../uc-api-sdk';

interface SelectOption {
  value: string;
  label: string;
}

export const useTagsSearch = (initialSearchTerm: string) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [tags, setTags] = useState<SelectOption[]>([]);
  const patientTagList = usePatientTagList({});

  const debouncedSetSearchTerm = useCallback(debounce(setSearchTerm, 500), []);

  useEffect(() => {
    const fetchTagsAsync = async () => {
      if (!searchTerm.trim()) {
        setTags([]);
        return;
      }

      try {
        const response = await patientTagList.send({
          params: {
            request: {
              name: searchTerm,
            },
          },
        });
        if (response && Array.isArray(response.data)) {
          const mappedTags: SelectOption[] = response.data.map(tag => ({
            value: tag.name ?? '',
            label: tag.name,
          }));
          setTags(mappedTags);
        } else {
          setTags([]);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setTags([]);
      }
    };

    fetchTagsAsync();
  }, [searchTerm]);

  return { tags, setSearchTerm: debouncedSetSearchTerm };
};
