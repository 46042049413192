import { Image } from 'antd';
import { LoadingOverlayComponent } from '../../../../uiComponent/LoadingOverlayComponent/LoadingOverlayComponent';
import { FoodLogMessagePayload, MessagePayload } from '../../../../services/CHSServices/types/data';
import { NonNullUndefined } from '../../../../types/common';
import { FoodLogModalComponent } from '../../../foodLog/component/FoodLogModalComponent/FoodLogModalComponent';
import { FoodLog } from '../../../foodLog/type';
import { ClickableDiv } from '../../../../uiComponent/ClickableDiv/ClickableDiv';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { useFoodLogGet } from '../../../../uc-api-sdk';
import { getId } from '../../../../lib/helpers/getId';
import useDebounce from '../../../../hooks/useDebounce/useDebounce';

import './MessageFoodLogReviewComponent.scss';

export interface MessageFoodLogReviewComponentProps {
  messagePayload: MessagePayload;
}

export const MessageFoodLogReviewComponent = ({
  messagePayload,
}: MessageFoodLogReviewComponentProps) => {
  const {
    value: openFoodLog,
    setValue: setOpenFoodLog,
  } = useBoolean();
  const {
    foodLogId,
    text,
  } = messagePayload as FoodLogMessagePayload;
  const foodLogInfo = useFoodLogGet({
    params: { id: getId(foodLogId) },
    options: { sendOnMount: !!getId(foodLogId) },
  });
  const foodLog = foodLogInfo?.data?.data as NonNullUndefined<FoodLog>;
  const isLoading = foodLogInfo.isLoading || foodLog === undefined;

  const handleOpenFoodLog = () => {
    if (foodLog) {
      setOpenFoodLog(true);
    }
  };

  const handleOnCloseFoodLog = useDebounce(() => {
    foodLogInfo?.refetch();
    setOpenFoodLog(false);
  }, 500);

  return (
    <>
      <LoadingOverlayComponent isLoading={isLoading}>
        <ClickableDiv
          className="message-food-log-review message-item__message-content message-item__message-highlight"
          onClick={handleOpenFoodLog}
        >
          <Image
            loading="lazy"
            preview={false}
            src={`${foodLog?.images[0]?.thumbnailLink}`}
          />
          <div className="message-food-log-review__text">
            {text}
          </div>
        </ClickableDiv>
      </LoadingOverlayComponent>
      {
        foodLog
        && (
          <FoodLogModalComponent
            data={[foodLog]}
            open={openFoodLog}
            onClose={handleOnCloseFoodLog}
          />
        )
      }
    </>
  );
};
