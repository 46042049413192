import { Input, InputProps } from 'antd';
import { isNaN } from 'lodash';

import React, { FC } from 'react';
import { onlyDigits } from '../../helpers/inputValidation';
import { formatPhoneNumber } from '../../helpers/phone';

export interface PhoneInputProps {
  value?: number | string;
  onChange?: (value?: number) => void;
  prefix?: string
}

export const PhoneInput: FC<PhoneInputProps> = ({
  value,
  onChange,
  prefix = '',
}) => {
  const handleChange: InputProps['onChange'] = (evt) => {
    const { value: newValue } = evt.target;
    const phoneNumberString = newValue.replace(/\D/g, '').substring(0, 10);
    if (phoneNumberString && !onlyDigits(phoneNumberString)) {
      return;
    }
    if (phoneNumberString === '') {
      onChange?.(undefined);
      return;
    }
    if (!isNaN(+phoneNumberString)) {
      onChange?.(+phoneNumberString);
    }
  };

  return (
    <Input
      data-testid="phone-input"
      className="phone-input"
      type="text"
      prefix={prefix}
      value={formatPhoneNumber(value)}
      onChange={handleChange}
    />
  );
};

export default PhoneInput;
