import { SearchOutlined } from '@ant-design/icons';
import { Select, SelectProps } from 'antd';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import React from 'react';
import { BIRTH_DATE } from '../../../../constants/timeFormat';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { useDeepCompareCallback } from '../../../../hooks/useDeepCompareEffect';
import { SelectWithPrefixIconComponent } from '../../../../uiComponent/SelectWithPrefixIconComponent/SelectWithPrefixIconComponent';
import { SearchPatientPlaceholder } from '../../constants';
import { ClinicInFilterComponentProps, DEFAULT_CLINIC_IN_FILTER_ID } from '../ClinicInFilterComponent/ClinicInFilterComponent';
import './PatientSearchSelectComponent.scss';
import { PatientSelectDropdownInHeaderComponent } from './PatientSelectDropdownInHeaderComponent';
import OverlayLoading from '../../../../uiComponent/OverlayLoading/OverlayLoading';
import { PatientCreateDrawerComponent } from '../../../../uiComponent/PatientCreateDrawerButtonComponent/PatientCreateDrawerComponent';
import { useMixpanelContext } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEvents } from '../../../../contexts/MixpanelContext/MixpanelEvents';

export interface PatientSearchSelectPropsForClinicInFilter {
  showClinicInFilter?: boolean;
  clinicInFilterValue?: ClinicInFilterComponentProps['value'];
  onChangeClinicInFilter?: ClinicInFilterComponentProps['onChange'];
  getAllClinics?: boolean;
}

export interface PatientSearchSelectComponentInfo<T> {
  id: string;
  fullName: string;
  dob?: Dayjs | undefined;
  info: T;
}

export interface PatientSearchSelectComponentProps<T>
  extends SelectProps,
  PatientSearchSelectPropsForClinicInFilter {
  isLoading?: boolean;
  data: PatientSearchSelectComponentInfo<T>[];
  optionLabel?: string | null;
  optionRenderer?: (info: PatientSearchSelectComponentInfo<T>) => JSX.Element;
  dropdownRender?: (menu: React.ReactElement) => React.ReactElement;
  onSearch: SelectProps['onSearch'];
  onChange: SelectProps['onChange'];
  value?: string | string[] | null;
  placeholder?: React.ReactNode;
  getAllClinics?: boolean;
}

export const PatientSearchSelectComponent = <T, >({
  isLoading,
  allowClear,
  placeholder,
  className = '',
  popupClassName = '',
  data,
  optionRenderer,
  dropdownRender,
  onSearch,
  onChange,
  value,
  optionLabel,
  showClinicInFilter,
  clinicInFilterValue,
  onChangeClinicInFilter = () => 0,
  tagRender,
  mode,
  disabled,
  placement,
  showArrow,
  getAllClinics,
}: PatientSearchSelectComponentProps<T>) => {
  const {
    value: open,
    setTrue: openPopup,
    setFalse: closePopup,
  } = useBoolean();
  const {
    value: isCreatePatientOpen,
    setTrue: openCreatePatient,
    setFalse: closeCreatePatient,
  } = useBoolean();
  const { send: sendMixpanel } = useMixpanelContext();

  const checkIfClinicInFilterFocused = () => {
    const clinicInFilterEl = document.querySelector(`[data-id="${DEFAULT_CLINIC_IN_FILTER_ID}"]`);
    const activeClassName = document.activeElement?.className;
    return (
      clinicInFilterEl
      && activeClassName
      && !!clinicInFilterEl?.querySelector(`.${activeClassName}`)
    );
  };

  const handleOnOpenChange: SelectProps['onDropdownVisibleChange'] = (
    open,
  ) => {
    if (open) {
      openPopup();
      return;
    }
    if (!checkIfClinicInFilterFocused()) {
      closePopup();
    }
  };

  const renderOptions = (d: PatientSearchSelectComponentInfo<T>) => (
    <Select.Option key={d.id}>
      <div className="flex jc-sb w100">
        <div>
          {d.fullName}
        </div>
        <div>
          {d.dob?.format(BIRTH_DATE) || 'N/A'}
        </div>
      </div>
    </Select.Option>
  );

  const handleOnCreatePatientClick = () => {
    openCreatePatient();
    sendMixpanel({ event: MixpanelEvents.PatientCreateDrawerOpen });
    closePopup();
  };

  const renderDropdown = (menu: React.ReactElement) => {
    if (showClinicInFilter) {
      return (
        <PatientSelectDropdownInHeaderComponent
          menu={menu}
          clinicInFilterValue={clinicInFilterValue}
          onChangeClinicInFilter={onChangeClinicInFilter}
          onPatientCreateClick={handleOnCreatePatientClick}
          getAllClinics={getAllClinics}
        />
      );
    }
    if (dropdownRender) return dropdownRender?.(menu);
    return menu;
  };

  const renderSelectOptions = useDeepCompareCallback(() => data.map(
    optionRenderer || renderOptions,
  ), [
    data,
  ]);

  const onSelectChange = () => {
    closePopup();
  };

  return (
    <>
      <SelectWithPrefixIconComponent
        prefixIcon={<SearchOutlined />}
        showSearch
        allowClear={allowClear}
        loading={isLoading}
        value={value}
        className={classNames({
          'patient-search-select-component': true,
          [className]: !!className,
        })}
        placeholder={placeholder || SearchPatientPlaceholder}
        defaultActiveFirstOption={false}
        showArrow={showArrow}
        filterOption={false}
        optionLabelProp={optionLabel === null ? undefined : 'label'}
        onSearch={onSearch}
        onChange={onChange}
        onSelect={onSelectChange}
        // notFoundContent={null}
        dropdownRender={renderDropdown}
        open={open}
        onDropdownVisibleChange={handleOnOpenChange}
        popupClassName={classNames({
          'patient-select-popup': true,
          [popupClassName]: !!popupClassName,
        })}
        tagRender={tagRender}
        mode={mode}
        disabled={disabled}
        placement={placement}
      >
        {
          isLoading
            ? (
              <Select.Option key="loading" disabled>
                <div className="patient-select-loading">
                  <OverlayLoading loading />
                </div>
              </Select.Option>
            ) : null
        }
        {renderSelectOptions()}
      </SelectWithPrefixIconComponent>
      <PatientCreateDrawerComponent
        open={isCreatePatientOpen}
        onClose={closeCreatePatient}
        onSubmit={closeCreatePatient}
        showEnrollmentDrawer={false}
      />
    </>
  );
};

export default PatientSearchSelectComponent;
