import React from 'react';
import { PatientCarePriorityTagComponent } from '../../features/patientCare/component/PatientCarePriorityTagComponent/PatientCarePriorityTagComponent';
import { PriorityLevelEnum, RiskLevelEnum } from '../../uc-api-sdk';
import EmptyComponent from '../../uiComponent/EmptyComponent/EmptyComponent';
import './PriorityEnumComponent.scss';

export interface PriorityEnumComponentProps {
  value: PriorityLevelEnum | null | undefined | RiskLevelEnum;
  type?: 'tag' | 'long' | 'filter';
  className?: string;
}

export const PriorityEnumComponent = ({
  value,
  type = 'tag',
  className,
}: PriorityEnumComponentProps) => {
  const getTag = (): React.ReactNode => {
    switch (value) {
      case PriorityLevelEnum.HIGH:
      case RiskLevelEnum.HIGH_RISK:
        return <PatientCarePriorityTagComponent.HIGH />;
      case PriorityLevelEnum.MEDIUM:
      case RiskLevelEnum.MEDIUM_RISK:
        return <PatientCarePriorityTagComponent.MED />;
      case PriorityLevelEnum.LOW:
      case RiskLevelEnum.LOW_RISK:
        return (
          <div className="low-priority-tag">
            <PatientCarePriorityTagComponent.LOW />
          </div>
        );
      default:
        return <EmptyComponent />;
    }
  };

  const getFilter = (): React.ReactNode => {
    switch (value) {
      case PriorityLevelEnum.HIGH:
      case RiskLevelEnum.HIGH_RISK:
        return 'HIGH';
      case PriorityLevelEnum.MEDIUM:
      case RiskLevelEnum.MEDIUM_RISK:
        return 'MED';
      case PriorityLevelEnum.LOW:
      case RiskLevelEnum.LOW_RISK:
        return 'LOW';
      default:
        return <EmptyComponent />;
    }
  };

  const getText = (): React.ReactNode => {
    switch (value) {
      case PriorityLevelEnum.HIGH:
        return <PatientCarePriorityTagComponent.HIGH text="High Priority" className={className} />;
      case PriorityLevelEnum.MEDIUM:
        return <PatientCarePriorityTagComponent.MED text="Med Priority" className={className} />;
      case PriorityLevelEnum.LOW:
        return <PatientCarePriorityTagComponent.LOW text="Low Priority" className={className} />;
      default:
        return <EmptyComponent />;
    }
  };

  switch (type) {
    case 'tag':
      return <>{getTag()}</>;
    case 'long':
      return <>{getText()}</>;
    case 'filter':
      return <>{getFilter()}</>;
    default:
      return <>{getTag()}</>;
  }
};
