import { EnrolledProgramStatusEnum } from '../../../uc-api-sdk';
import { EnrolledProgramStatusEnumComponent } from '../../../enumComponent/EnrolledProgramStatusEnumComponent/EnrolledProgramStatusEnumComponent';
import { CheckboxFilterComponent } from '../component/CheckboxFilterComponent/CheckboxFilterComponent';
import { FilterIcon } from './FilterIcon';
import { TableFilterType } from '../type/filter';
import { BINARY_STATUS } from '../../patient/component/EnrolledProgramStatus/EnrolledProgramStatus';

export const createEnrolledStatusCheckboxFilter = (): TableFilterType => {
  const options = Object.keys(BINARY_STATUS).map((v) => ({
    label: (
      <EnrolledProgramStatusEnumComponent value={v as EnrolledProgramStatusEnum} />
    ),
    value: v,
  }));

  return ({
    filterDropdown: ((props) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <CheckboxFilterComponent {...props} filterOptions={options} />
    )),
    filterIcon: FilterIcon,
  });
};
