// @ts-nocheck
import axios, { AxiosRequestHeaders } from 'axios';
import { useState } from 'react';
import { unacceptableRequestType } from '../../../errors/errors';
import { StorageKeyEnum, useSessionStorage } from '../../useSessionStorage';
import {
  createPath,
  createUrl,
  getStatus,
  PathType,
} from '../helper';
import { MutationOptions } from '../types';

// eslint-disable-next-line no-shadow
export enum ReqType {
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

const getFunc = (type: ReqType) => {
  switch (type) {
    case ReqType.POST: return axios.post;
    case ReqType.PUT: return axios.put;
    case ReqType.DELETE: return axios.delete;
    default: throw unacceptableRequestType();
  }
};

export const usePostPutDelete = (type: ReqType) => <
  Variables extends object,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Data extends object = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PathParams extends object = any
>(
    path: PathType,
    options?: MutationOptions,
  ) => {
  const log = options?.log;
  const logError = options?.logError;
  const maxRetryCount = options?.retry || 3;
  const retryDelay = options?.retryDelay || 2000;
  const throwError = options?.throwError === undefined || options?.throwError === true;
  const customHeaders = options?.headers || {};

  return (
    () => {
      const [token] = useSessionStorage(StorageKeyEnum.TOKEN, null);
      let retryCount = 0;
      const func = getFunc(type);
      const [isLoading, setIsLoading] = useState<boolean>(false);
      const [error, setError] = useState<Error>();
      const [status, setStatus] = useState<number>();
      const [data, setData] = useState<Data | null>();
      const headers = {
        'x-session-token': `${token}`,
        ...customHeaders,
      } as AxiosRequestHeaders;

      const mutateAsync = async (variables: Variables, pathParams: PathParams):
        Promise<Data | undefined> => {
        setIsLoading(true);
        let response: Data | null | undefined;
        const p = createPath(path, pathParams);
        try {
          let res;
          if (type === ReqType.DELETE) {
            res = await axios.delete(createUrl(p), { headers });
          } else {
            res = await func(createUrl(p), variables, { headers });
          }
          if (log) console.log(res);
          setStatus(getStatus<Data>(res));
          setData(res.data);
          response = res.data as Data || null;
          retryCount = 0;
        } catch (err) {
          if (logError) console.error(err);
          setError(err as Error);

          // Retry
          if (retryCount < maxRetryCount) {
            retryCount += 1;
            return new Promise((resolve, reject) => {
              setTimeout(async () => {
                try {
                  const v = await mutateAsync(variables, pathParams);
                  return resolve(v);
                } catch (e) {
                  return reject(e);
                }
              }, retryDelay);
            });
          }

          if (throwError) throw err;
          response = undefined;
        } finally {
          setIsLoading(false);
        }
        return response;
      };

      return {
        mutateAsync,
        isLoading,
        error,
        status,
        data,
      };
    }
  );
};

export default usePostPutDelete;
