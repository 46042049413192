/* eslint-disable no-shadow */
// eslint-disable-next-line import/prefer-default-export
export enum TimezoneType {
  ET = 'US/Eastern',
  CT = 'US/Central',
  MT = 'US/Mountain',
  AT = 'US/Arizona',
  PT = 'US/Pacific',
  AK = 'US/Alaska',
  HT = 'US/Hawaii',
  GT = 'Pacific/Guam',
  PhilippineT = 'Asia/Manila',
}

export enum TimezoneOffset {
  ET = -4,
  CT = -5,
  MT = -6,
  AT = -6,
  PT = -7,
  AK = -8,
  HT = -10,
  GT = 10,
  PhilippineT = 8,
}
