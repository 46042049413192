import { uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import { message } from 'antd';
import { useRequestFactory } from '../../uc-api-sdk/staticFiles/useReqHook';
import useDebounce from '../useDebounce/useDebounce';
import { APIResponse, PageResponse } from '../../uc-api-sdk';
import { useDeepCompareEffect } from '../useDeepCompareEffect';

export type ScrollHistory = {
  [key in string]: {
    currentPage: number;
    totalPage: number;
  }
};

export interface UseSelectScrollPaginationHook<
  I,
> {
  requestInfo: I;
  fetchParams: (page: number) => NonNullable<Parameters<(ReturnType<typeof useRequestFactory> & I)['send']>['0']>['params'];
  dataIdentifier?: string;
  disabled?: boolean;
  scrollOffset?: number; // in pixel
  showDebugScroll?: boolean;
  notifyOnNoMoreFetch?: boolean;
  resetDataTriggers?: unknown[]; // only reset when one of the triggers is changed
}

export const useScrollPagination = <
  I
>({
    requestInfo,
    fetchParams,
    dataIdentifier = 'id',
    scrollOffset = 1,
    disabled,
    showDebugScroll,
    notifyOnNoMoreFetch,
    resetDataTriggers,
  }: UseSelectScrollPaginationHook<I>) => {
  const {
    isLoading,
    send,
    data,
  } = requestInfo as (ReturnType<typeof useRequestFactory> & I);
  const {
    content: newData = [],
    page,
    totalPage,
  } = (data as NonNullable<APIResponse<PageResponse<unknown>>>)?.data || {};
  const [allData, setAllData] = useState<NonNullable<typeof newData>>([]);
  const [scrollHistory, setScrollHistory] = useState<ScrollHistory>({});

  const printDebugScroll = (...args: unknown[]) => {
    // DEBUG ONLY
    if (showDebugScroll) console.log('useScrollPagination:', ...args);
  };

  // set page to -1 to make params more static
  const getFetchParamsAsKey = () => fetchParams(-1);

  const getNextPage = () => {
    const lastScrollParams = getFetchParamsAsKey();
    const {
      currentPage,
      totalPage,
    } = scrollHistory[JSON.stringify(lastScrollParams)] || {};
    if (!currentPage || currentPage >= totalPage) {
      return -1; // no more page
    }
    // default to always start at 2
    return currentPage ? currentPage + 1 : 2;
  };

  const appendScrollHistory = (page: number, totalPage: number) => {
    const newScrollHistoryKey = JSON.stringify(getFetchParamsAsKey());
    if (scrollHistory[newScrollHistoryKey]?.currentPage > page) {
      // not a new history
      return;
    }
    setScrollHistory((prevScrollHistory) => ({
      ...prevScrollHistory,
      [newScrollHistoryKey]: { currentPage: page, totalPage },
    }));
  };

  const handleOnScroll = useDebounce((e) => {
    if (disabled) return;
    const target = e.target as HTMLElement;
    if (isLoading || !target) return;
    printDebugScroll('target', target);
    const reachEnd = (
      Math.ceil(target.scrollHeight - target.scrollTop) - target.clientHeight <= scrollOffset
    );
    printDebugScroll('scrollHeight', target.scrollHeight, 'scrollTop', target.scrollTop, 'clientHeight', target.clientHeight);
    printDebugScroll('Math.ceil(target.scrollHeight - target.scrollTop) - target.clientHeight  <= scrollOffset', reachEnd);
    if (reachEnd) {
      const nextPage = getNextPage();
      printDebugScroll('nextPage, fetchParams', nextPage, fetchParams(nextPage));
      if (nextPage > -1) {
        send({ params: fetchParams(nextPage) });
        return;
      }
      if (notifyOnNoMoreFetch) {
        message.info('No more result');
      }
    }
  }, 1000, [
    disabled,
    isLoading,
    page,
    totalPage,
    fetchParams,
    getNextPage,
  ]);

  useDeepCompareEffect(() => {
    if (resetDataTriggers) {
      setAllData([]);
    }
  }, resetDataTriggers || []);

  useEffect(() => {
    if (newData?.length) {
      setAllData((prevAllData) => {
        // dedup
        const uniqueData = uniqBy([
          ...prevAllData,
          ...newData,
        ], dataIdentifier);

        return uniqueData;
      });
    }
  }, [newData]);

  useEffect(() => {
    if (page && totalPage) {
      appendScrollHistory(page, totalPage);
    }
  }, [page, totalPage]);

  return {
    allData,
    handleOnScroll,
  };
};
