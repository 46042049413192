import { message } from 'antd';
import { FC, useCallback } from 'react';
import { useCareNoteContext } from '../../../../contexts/CareNoteContext/CareNoteContext';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { CareNote, useCareNoteUpdate } from '../../../../uc-api-sdk';
import { CareNoteFormComponent } from '../../component/CareNoteFormComponent/CareNoteFormComponent/CareNoteFormComponent';
import { CareNoteTexts } from '../CreateCareNoteFormContainer/constant';

export interface EditCareNoteFormContainerProps {
  onSubmit?: () => void;
  note: CareNote;
  onCancel: () => void;
}

export const EditCareNoteFormContainer: FC<EditCareNoteFormContainerProps> = ({
  onSubmit,
  note,
  onCancel,
}) => {
  const { info } = usePatientContext();
  const { id = '' } = info?.patientInfo || {};
  const useUpdate = useCareNoteUpdate({});
  const { refresh } = useCareNoteContext();
  const { value: isLoading, setTrue, setFalse } = useBoolean(false);
  const updateCareNote = useCallback(async (values: CareNote) => {
    setTrue();
    const response = await useUpdate.send({
      params: {
        id: note.id || '',
        document: { ...values, memberId: id },
      },
    });

    if (response?.code === 200) {
      message.success(CareNoteTexts.editSuccess);
    } else if (response?.code && response.code >= 400) {
      message.warning(CareNoteTexts.error);
    }
    setFalse();
    onSubmit?.();
    refresh?.();
  }, []);

  return (
    <CareNoteFormComponent
      onSubmit={updateCareNote}
      mode="EDIT"
      initialValues={note}
      onCancel={onCancel}
      isLoading={isLoading}
    />
  );
};
