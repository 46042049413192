import { useLocalStorage } from 'usehooks-ts';

export type DoNotShowAgainType =
  'RemovePatient' | 'IsWelcomed' | 'IsAITranscribeOnboard';

export const useDoNotShowAgain = (type: DoNotShowAgainType, userId?: string) => {
  if (type === 'RemovePatient') {
    return useLocalStorage(`doNotShowAgain-${type}`, false);
  }
  return useLocalStorage(`doNotShowAgain-${type}-${userId}`, false);
};
