import React from 'react';
import { OrderStaging } from '../../../../uc-api-sdk';
import { CuffSizeEnumComponent } from '../../../../enumComponent/CuffSizeEnumComponent/CuffSizeEnumComponent';
import DisplayOrEmptyComponent from '../../../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface OrderNoteComponentProps {
  order: OrderStaging;
}

export const OrderNoteComponent = ({
  order,
}: OrderNoteComponentProps) => {
  const renderNote = () => {
    if (order.orderItems?.reason) {
      return <DisplayOrEmptyComponent value={order.orderItems?.reason} />;
    }

    if (order.orderItems?.cuffSize) {
      return <CuffSizeEnumComponent value={order.orderItems?.cuffSize} shortText />;
    }

    return <DisplayOrEmptyComponent />;
  };

  return order.orderPlanType ? (<div>{order.orderPlanType}</div>) : renderNote();
};
