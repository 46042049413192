import React from 'react';
import { DatePicker } from 'antd';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import dayjs from 'dayjs';
import FormItem from '../../../../uiComponent/FormItem/FormItem';
import { useCreateEditLabResultForm } from '../../hook/useCreateEditLabResultForm';
import { USA_DATE } from '../../../../constants/timeFormat';
import { LabTemplateSelectComponentInfo } from '../../../patient/component/LabTemplateSelectComponent/LabTemplateSelectComponent';
import { LabResultTemplateItem } from '../../../../uc-api-sdk';
import { EditLabResultMode } from '../../constants';
import { LabTemplateContainer } from '../../../patient/component/LabTemplateSelectComponent/LabTemplateSelectContainer';

dayjs.extend(weekday);
dayjs.extend(localeData);

export type CreateLabType = LabTemplateSelectComponentInfo<LabResultTemplateItem[]>;
export interface TranscribeLabResultFormItemComponentProps {
  onChange?: (labResultItem: LabResultTemplateItem[] | undefined, v?: CreateLabType) => void;
  mode?: EditLabResultMode;
}

export const TranscribeLabResultFormItemComponent = ({
  onChange,
  mode,
}: TranscribeLabResultFormItemComponentProps) => {
  const hook = useCreateEditLabResultForm();

  const onTemplateChange = (value?: CreateLabType) => {
    onChange?.(value?.info, value);
  };

  return (
    <div>
      <FormItem
        info={hook.getInfo('testDate')}
        className="required-field"
        required
      >
        <DatePicker
          disabled={mode === 'View'}
          format={USA_DATE}
        />
      </FormItem>
      <FormItem
        info={hook.getInfo('templateName')}
        className="required-field"
        required={mode !== 'Edit'}
      >
        <LabTemplateContainer
          onSelect={onTemplateChange}
          // don't change lab result template when edit or view;
          // only enable on create
          disabled={mode === 'Edit' || mode === 'View'}
        />
      </FormItem>
    </div>
  );
};
