import {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { PopupTypes, useCarePortalPopupContext } from '../../../../contexts/CarePortalPopupContext/CarePortalPopupContext';
import { useBillableTimeContext } from '../../../../contexts/BillableTimeContext/BillableTimeContext';
import EnvConfig from '../../../../configs/envConfig/envConfig';
import { usePatientPageControllerContext } from '../../context/PatientPageControllerContext/PatientPageControllerContext';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';

export interface PatientBillableTimeComponentProps {
  children?: ReactNode;
}

export const PatientBillableTimeComponent = ({
  children,
}: PatientBillableTimeComponentProps) => {
  const { open, isOpen } = useCarePortalPopupContext();
  const { sessionStartTimestamp } = usePatientPageControllerContext();
  const { lastIdle, resetIdleTime } = useBillableTimeContext();
  const totalAfkTime = useRef<number>(0);
  const { info } = usePatientContext();
  const patientId = info?.id || '';

  const calcMinutes = useCallback((timeInMilliseconds: number) => (
    Math.ceil(timeInMilliseconds / (60 * 1000))
  ), []);

  useUpdateEffect(() => {
    totalAfkTime.current += lastIdle.timeLength;

    const resetTimers = () => {
      totalAfkTime.current = 0;
      resetIdleTime();
    };

    if (
      totalAfkTime.current > EnvConfig.autoAfkTimerThreshold * 1000
      && !isOpen(PopupTypes.AFKModal)
    ) {
      open(
        PopupTypes.AFKModal,
        {
          totalTime: 0,
          totalAfkTime: calcMinutes(totalAfkTime.current),
          onCancel: resetTimers,
          onSuccess: resetTimers,
          patientId,
        }
      );
    }
  }, [lastIdle]);

  useEffect(() => (
    () => {
      if (totalAfkTime.current > EnvConfig.afkTimerThreshold * 1000) {
        open(
          PopupTypes.AFKModal,
          {
            totalTime: calcMinutes(Date.now() - sessionStartTimestamp.toDate().getTime()),
            totalAfkTime: calcMinutes(totalAfkTime.current),
            patientId,
          }
        );
      }
    }
  ), [calcMinutes]);

  return (
    <div className="patient-billable-time-component">
      {children}
    </div>
  );
};
