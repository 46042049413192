import dayjs, { Dayjs } from 'dayjs';
import querystring from 'query-string';
import React, {
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { useUpdateEffect } from 'usehooks-ts';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { useUpdate, useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';
import useBoolean from '../../../../hooks/useBoolean/useBoolean';
import { useDeepCompareEffect } from '../../../../hooks/useDeepCompareEffect';
import { useRouter } from '../../../../hooks/useRouter/useRouter';
import { OutstandingEnum } from '../../../outstanding/constant/outstandingOrder';

export enum PatientProfileTabEnum {
  CareNotes = 'careNotes',
  MedicalHistory = 'medicalHistory',
  Intervention = 'intervention',
  Profile = 'profile',
  Device = 'device',
  Visit = 'visit',
}

interface Value {
  selectedTab?: PatientProfileTabEnum;
  onTabChange: (value: PatientProfileTabEnum, hash?: string) => void;
  selectedAnchor?: string;
  onAnchorChange: (value: string) => void;
  selectedOutstanding?: OutstandingEnum;
  onOutstandingChange: (value?: OutstandingEnum) => void;
  showOutstandingButton?: boolean;
  showChatButton?: boolean;
  showMessageInOutstandingTabs?: boolean;
  isManualMTPROpen: boolean;
  openManualMTPR: () => void;
  closeManualMTPR: () => void;
  isManualMTPREligibilityConfirmed: boolean;
  confirmManualMTPREligibility: () => void;
  resetManualMTPREligibility: () => void;
  sessionStartTimestamp: Dayjs;
}

export const PatientPageControllerContext = React.createContext<Value>({
  selectedTab: undefined,
  onTabChange: () => console.error('onTabChange has not been initialized yet!'),
  selectedAnchor: undefined,
  onAnchorChange: () => console.error('onAnchorChange has not been initialized yet!'),
  selectedOutstanding: undefined,
  onOutstandingChange: () => console.error('onOutstandingChange has not been initialized yet!'),
  isManualMTPROpen: false,
  openManualMTPR: () => console.error('openManualMTPR has not been initialized yet!'),
  closeManualMTPR: () => console.error('closeManualMTPR has not been initialized yet!'),
  isManualMTPREligibilityConfirmed: false,
  confirmManualMTPREligibility: () => console.error('confirmManualMTPREligibility has not been initialized yet!'),
  resetManualMTPREligibility: () => console.error('resetManualMTPREligibility has not been initialized yet!'),
  sessionStartTimestamp: dayjs(),
});

export interface PatientProviderProps {
  children: ReactNode;
  isPatientCare?: boolean;
  shouldRedirect?: boolean;
  showOutstandingButton?: boolean;
  showChatButton?: boolean;
  showMessageInOutstandingTabs?: boolean;
}

interface ChangeArg {
  tab?: PatientProfileTabEnum;
  anchor?: string;
  outstanding?: OutstandingEnum;
}

export const PatientProfilePageControllerProvider = ({
  children,
  isPatientCare = false,
  shouldRedirect = true,
  showOutstandingButton = false,
  showChatButton = true,
  showMessageInOutstandingTabs = true,
}: PatientProviderProps) => {
  const {
    setHash,
    navigate,
    query,
    changePage,
    location,
  } = useRouter();
  const { state } = useLocation();
  const { info } = usePatientContext();
  const [sessionStartTimestamp] = useState(dayjs());
  const [selectedTab, setSelectedTab] = useState<PatientProfileTabEnum>();
  const [selectedAnchor, setSelectedAnchor] = useState<string>();
  const [selectedOutstanding, setSelectedOutstanding] = useState<OutstandingEnum>();
  const [isManualMTPROpen, setIsManualMTPROpen] = useState(false);
  const {
    value: isManualMTPREligibilityConfirmed,
    setTrue: confirmManualMTPREligibility,
    setFalse: resetManualMTPREligibility,
  } = useBoolean();

  const createManualMTPRHook = useUpdate('MANUAL_MTPR_CREATED');

  useUpdateListener('WORKLIST_UPDATED', () => info?.refetch());

  const createSearch = ({ tab, outstanding }: ChangeArg) => (
    querystring.stringify({ tab, outstanding })
  );

  const onChange = useCallback((options: ChangeArg) => {
    let newPathname: string | undefined;
    if (!shouldRedirect) {
      newPathname = location.pathname;
    } else if (isPatientCare) {
      // go to patient care page
      newPathname = changePage.createCarePortalPatientCareUrl({ patientId: info?.id || '' });
    } else {
      // go to patient page
      newPathname = changePage.createCarePortalPatientUrl({ patientId: info?.id || '' });
    }
    navigate({
      pathname: newPathname,
      search: `?${createSearch(options)}`,
      hash: options.anchor,
    }, { state, replace: true });
  }, [info?.id, state]);

  const onTabChange = useCallback((newTab: PatientProfileTabEnum, hash?: string) => {
    setSelectedTab(newTab);
    setSelectedAnchor(hash);
    onChange({ tab: newTab, anchor: hash, outstanding: selectedOutstanding });
  }, [setSelectedTab, setSelectedAnchor, selectedOutstanding, onChange]);

  const onAnchorChange = useCallback((newAnchor: string) => {
    const lowerCareAnchor = newAnchor.toLocaleLowerCase();
    setSelectedAnchor(lowerCareAnchor);
    setHash(lowerCareAnchor);
  }, [setSelectedAnchor, setHash]);

  const onOutstandingChange = useCallback((newOutstanding?: OutstandingEnum) => {
    setSelectedOutstanding(newOutstanding);
    onChange({ outstanding: newOutstanding });
  }, [setSelectedOutstanding, onChange]);

  const handleOpenManualMTPR = () => {
    onOutstandingChange(OutstandingEnum.MANUAL_MONTHLY_REVIEW);
    setIsManualMTPROpen(true);
    createManualMTPRHook.updateValue();
  };

  const handleCloseManualMTPR = () => {
    resetManualMTPREligibility();
    setIsManualMTPROpen(false);
    onOutstandingChange(undefined);
  };

  const updatePatient = () => {
    const newOutstanding = query.outstanding as OutstandingEnum | undefined;
    let newTab = query.tab as PatientProfileTabEnum | undefined;
    if (newOutstanding) {
      setSelectedOutstanding(newOutstanding);
    }
    if (newTab) {
      setSelectedTab(newTab);
    } else {
      newTab = PatientProfileTabEnum.MedicalHistory;
      setSelectedTab(PatientProfileTabEnum.MedicalHistory);
    }
    onChange({ tab: newTab, anchor: state?.anchor, outstanding: newOutstanding });
  };

  useUpdateEffect(() => {
    if (query.tab !== selectedTab && query.tab === PatientProfileTabEnum.Profile) {
      onTabChange(PatientProfileTabEnum.Profile);
    }
    if (query.outstanding && query.outstanding !== selectedOutstanding) {
      onOutstandingChange(query.outstanding as OutstandingEnum);
    }
  }, [query.tab, query.outstanding, selectedTab, selectedOutstanding]);

  useDeepCompareEffect(() => {
    if (info?.id && info?.enrolledProgram !== undefined) {
      updatePatient();
      if (state?.anchor && !selectedAnchor) {
        setTimeout(() => {
          setSelectedAnchor(state.anchor);
        }, 200);
      }
    }
  }, [info?.id, info?.enrolledProgram]);

  const contextValue: Value = useMemo(() => ({
    selectedTab,
    onTabChange,
    selectedAnchor,
    onAnchorChange,
    selectedOutstanding,
    onOutstandingChange,
    showOutstandingButton,
    showChatButton,
    showMessageInOutstandingTabs,
    isManualMTPROpen,
    openManualMTPR: handleOpenManualMTPR,
    closeManualMTPR: handleCloseManualMTPR,
    isManualMTPREligibilityConfirmed,
    confirmManualMTPREligibility,
    resetManualMTPREligibility,
    sessionStartTimestamp,
  }), [
    selectedTab,
    onTabChange,
    selectedAnchor,
    onAnchorChange,
    selectedOutstanding,
    onOutstandingChange,
    showOutstandingButton,
    showChatButton,
    showMessageInOutstandingTabs,
    isManualMTPROpen,
    handleOpenManualMTPR,
    isManualMTPREligibilityConfirmed,
    sessionStartTimestamp,
  ]);

  return (
    <PatientPageControllerContext.Provider value={contextValue}>
      {children}
    </PatientPageControllerContext.Provider>
  );
};

export const usePatientPageControllerContext = () => {
  const patientPageControllerContextValue = useContext(PatientPageControllerContext);
  return patientPageControllerContextValue;
};
