import { Row, Space, Typography } from 'antd';
import { Dayjs } from 'dayjs';
import { map } from 'lodash';
import { FC } from 'react';
import { USA_DATE } from '../../../../constants/timeFormat';
import { GenderIdentityEnumComponent } from '../../../../enumComponent/GenderIdentityEnumComponent/GenderIdentityEnumComponent';
import { PatientTechLevelEnumComponent } from '../../../../enumComponent/PatientTechLevelEnumComponent/PatientTechLevelEnumComponent';
import { RaceEnumComponent } from '../../../../enumComponent/RaceEnumComponent/RaceEnumComponent';
import { WeightService } from '../../../../helpers/weight';
import {
  BehaviorChangeEnum, GenderEnum, GenderIdentityEnum, Nullable, PatientTechLevelEnum, RaceEnum
} from '../../../../uc-api-sdk';
import { ChangePasswordModalComponent } from '../../../../uiComponent/ChangePasswordModalComponent/ChangePasswordModalComponent';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import LanguagesComponent from '../../../../uiComponent/LanguagesComponent/LanguagesComponent';
import { PatientBehaviorChangeEnumComponent } from '../../../../uiComponent/PatientBasicInfo/PatientBehaviorChangeEnumComponent/PatientBehaviorChangeEnumComponent';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { GenderTexts } from '../../constants';
import { InfoComponent, InfoComponentProps } from '../InfoComponent/InfoComponent';

const { Title, Text } = Typography;

export interface DemographicBasicInfoComponentProps {
  fullName?: string;
  nickName?: string;
  dob?: Dayjs;
  gender?: Nullable<GenderEnum>;
  genderIdentity?: Nullable<GenderIdentityEnum>;
  height?: number[];
  weight?: string;
  race?: string;
  languages?: string[];
  behavior?: Nullable<BehaviorChangeEnum>;
  techLevel?: Nullable<PatientTechLevelEnum>;
  userName?: string;
  memberId: string;
}

export const DemographicBasicInfoComponent: FC<DemographicBasicInfoComponentProps> = ({
  fullName,
  nickName,
  dob,
  gender,
  genderIdentity,
  height,
  weight,
  race,
  languages,
  behavior,
  techLevel,
  userName,
  memberId,
}) => {
  const dataSet: InfoComponentProps[] = [
    {
      name: 'Full Name*',
      value: fullName || <EmptyComponent />,
      span: 6,
    },
    {
      name: 'Nickname',
      value: nickName,
      span: 6,
    },
    {
      name: 'DOB*',
      value: dob?.format(USA_DATE) || <EmptyComponent />,
      span: 6,
    },
    {
      name: <TooltipComponent type="info-icon" title={GenderTexts.genderTooltip}>Sex at birth*</TooltipComponent>,
      value: gender as string,
      span: 3,
    },
    {
      name: <TooltipComponent type="info-icon" title={GenderTexts.genderIdentityTooltip}>Gender Identity</TooltipComponent>,
      value: <GenderIdentityEnumComponent value={genderIdentity ?? undefined} />,
      span: 3,
    },
  ];

  const dataSet2: InfoComponentProps[] = [
    {
      name: 'Height',
      value: height,
      span: 6,
      render: () => (height ? (
        <>
          <Text type="secondary">Height</Text>
          <div>
            <Space size={5}>
              <span>
                {' '}
                {height?.[0]}
              </span>
              <sub>ft.</sub>
              <span>{height?.[1]}</span>
              <sub>in.</sub>
            </Space>
          </div>
        </>
      ) : <EmptyComponent />),
    },
    {
      name: `Weight (${WeightService.weightUnitDisplay})*`,
      value: weight === '-1.00' ? <EmptyComponent /> : weight,
      span: 6,
    },
    {
      name: 'Race',
      value: <RaceEnumComponent value={race as RaceEnum} />,
      span: 6,
    },
    {
      name: 'Preferred Spoken Language*',
      value: <LanguagesComponent value={languages} type="fullName" />,
      span: 6,
    },
  ];
  const dataSet3: InfoComponentProps[] = [
    {
      name: 'Stage of Behavior Change',
      span: 6,
      value: (
        <PatientBehaviorChangeEnumComponent
          value={behavior as BehaviorChangeEnum}
        />
      )
    },
    {
      name: 'Tech Level',
      span: 6,
      render: (
        <>
          <Text type="secondary">Tech Level</Text>
          <div><PatientTechLevelEnumComponent value={techLevel as PatientTechLevelEnum} /></div>
        </>
      ),
    },
    {
      name: 'Username',
      value: userName,
      span: 6,
    },
    {
      name: 'Change Password',
      span: 6,
      render: (
        <ChangePasswordModalComponent
          userId={memberId}
          userType="PATIENT"
          buttonText="Change Password"
          buttonBlock
        />
      ),
    },
  ];

  const renderColumns = (data: InfoComponentProps[]) => map(data, (d, index) => (
    <InfoComponent
      key={index}
      name={d.name}
      value={d.value}
      span={d.span}
      onClick={d.onClick}
      type={d.type}
      render={d.render}
    />
  ));

  return (
    <div className="flex gap3 fd-c">
      <Title level={5}>Basic Information</Title>
      <Row gutter={7}>
        {renderColumns(dataSet)}
      </Row>
      <Row gutter={7}>
        {renderColumns(dataSet2)}
      </Row>
      <Row align="bottom" gutter={7}>
        {renderColumns(dataSet3)}
      </Row>
    </div>
  );
};
