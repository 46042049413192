import { Checkbox, Space } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import {
  OfflineActivityTypeEnumComponent
} from '../../../enumComponent/OfflineActivityTypeEnumComponent/OfflineActivityTypeEnumComponent';
import { InputTypeArray } from '../../../features/Input/types';
import { OfflineCallSettingsComponent } from '../../OfflineCallSettingsComponent/OfflineCallSettingsComponent';
import { ActivityTypeEnum } from '../type';

export interface ActivityTypeCheckboxGroupComponentProps
  extends InputTypeArray<ActivityTypeEnum> {

}

export const ActivityTypeCheckboxGroupComponent = ({
  onChange,
  ...props
}: ActivityTypeCheckboxGroupComponentProps) => {
  const handleChange: CheckboxGroupProps['onChange'] = (checkedValue) => {
    onChange?.(checkedValue as ActivityTypeEnum[]);
  };

  return (
    <Checkbox.Group
      onChange={handleChange}
      className="mt12 w100"
    >
      <Space
        direction="vertical"
        size={16}
      >
        <Checkbox value={ActivityTypeEnum.CALL}>
          <OfflineActivityTypeEnumComponent value={ActivityTypeEnum.CALL} />
        </Checkbox>
        {props.value?.includes(ActivityTypeEnum.CALL)
          && (
            <OfflineCallSettingsComponent />
          )}
        <Checkbox value={ActivityTypeEnum.CHECK_EMR}>
          <OfflineActivityTypeEnumComponent value={ActivityTypeEnum.CHECK_EMR} />
        </Checkbox>
        <Checkbox value={ActivityTypeEnum.EDUCATION}>
          <OfflineActivityTypeEnumComponent value={ActivityTypeEnum.EDUCATION} />
        </Checkbox>
        <Checkbox value={ActivityTypeEnum.REVIEW_VITALS}>
          <OfflineActivityTypeEnumComponent value={ActivityTypeEnum.REVIEW_VITALS} />
        </Checkbox>
        <Checkbox value={ActivityTypeEnum.COORDINATE_CARE}>
          <OfflineActivityTypeEnumComponent value={ActivityTypeEnum.COORDINATE_CARE} />
        </Checkbox>
        <Checkbox value={ActivityTypeEnum.RESEARCHED_CONDITION}>
          <OfflineActivityTypeEnumComponent value={ActivityTypeEnum.RESEARCHED_CONDITION} />
        </Checkbox>
        <Checkbox value={ActivityTypeEnum.OTHER}>
          <OfflineActivityTypeEnumComponent value={ActivityTypeEnum.OTHER} />
        </Checkbox>
      </Space>
    </Checkbox.Group>
  );
};
