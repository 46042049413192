import { includes, intersection } from 'lodash';
import { AlternativeDeviveType, PatientTechLevelEnum, VitalEnumType } from '../../../../uc-api-sdk';

interface UseIsEnrollmentDeviceEligible {
  vitals: VitalEnumType[];
  hasSmartDevice: boolean;
  techLevel?: PatientTechLevelEnum;
  nonSmartPhoneAlternatives?: AlternativeDeviveType[] | null;
}

/**
 * @return boolean / undefined
 * true = eligible for enrollment
 * undefined = unknown result, hook user should handle this case
 */
export const useIsEnrollmentDeviceEligible = ({
  vitals,
  hasSmartDevice,
  techLevel,
  nonSmartPhoneAlternatives
}: UseIsEnrollmentDeviceEligible) => {
  const isLowTechLevel = techLevel === PatientTechLevelEnum.LOW;
  const isClearSupport = (
    includes(nonSmartPhoneAlternatives, AlternativeDeviveType.BP_CLEAR)
    && ( // has Clear device but doesn't have neither wifi nor hotspot selected
      !!intersection(
        [AlternativeDeviveType.WIFI_ROUTER, AlternativeDeviveType.HOTSPOT],
        nonSmartPhoneAlternatives
      ).length
    )
  );
  const isBPCellularSupport = (
    includes(nonSmartPhoneAlternatives, AlternativeDeviveType.BP_CELLULAR_PYLO)
  );
  const isLoanerPhoneSupport = (
    includes(nonSmartPhoneAlternatives, AlternativeDeviveType.LOANER_PHONE)
  );

  // nonSmartPhoneAlternatives is not provided
  if (
    !hasSmartDevice
    && (
      !intersection(
        [
          AlternativeDeviveType.BP_CLEAR,
          AlternativeDeviveType.BP_CELLULAR_PYLO,
          AlternativeDeviveType.LOANER_PHONE
        ],
        nonSmartPhoneAlternatives
      ).length
    )
  ) {
    return false;
  }

  if (!techLevel) return undefined;

  if (isLowTechLevel) {
    // Tech level is LOW,
    // only Clear device is available, checking:
    // 1. Clear device is only eligible with BP vital
    if (!includes(vitals, VitalEnumType.BP)) {
      return false;
    }
    // 2. Clear device will only be eligible
    // when clinic configuration has Clear and with wifi and/or hotspot
    // or when clinic configuration has BP Cellular
    if (!isClearSupport && !isBPCellularSupport) {
      return false;
    }
    return true;
  }

  // Tech level is not LOW
  // And doesn't have smart device
  if (!hasSmartDevice && !isLoanerPhoneSupport) {
    if (
      (isClearSupport || isBPCellularSupport)
      && includes(vitals, VitalEnumType.BP)
    ) {
      // has BP vital and has Clear/BP Cellular eligible
      return true;
    }

    // doesn't have Loaner phone as non smart phone alternatives
    return false;
  }

  return true;
};
