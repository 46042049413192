import moment, { Moment } from 'moment';
import { Select, SelectProps } from 'antd';
import { range } from 'lodash';
import { InputType } from '../../../Input/types';
import './BillableTimeReviewDatePickerComponent.scss';
import { BILLABLE_TIME_MONTH_MAP } from '../../contants/contants';

export interface BillableTimeReviewDatePickerComponentProps extends InputType<Moment> {
  isBillerPortal?: boolean;
}

export const BillableTimeReviewDatePickerComponent = ({
  value,
  onChange,
  isBillerPortal = false,
}: BillableTimeReviewDatePickerComponentProps) => {
  const curr = value || moment();
  const thisYear = moment().year();
  const month = curr.month();
  const year = curr.year();
  const yearOptions = range(year - 10, year + 10).map((v) => ({
    label: v,
    value: v,
  }));

  const billerPortalYearOptions = range(thisYear - 2, thisYear + 1).map((v) => ({
    label: v,
    value: v,
  }));

  const isAfterNow = (value: Moment) => (
    value.isAfter(moment().endOf('month'))
  );

  const handelChangeMonth: SelectProps['onChange'] = (val) => {
    const newValue = curr.clone().set('month', val);
    onChange?.(newValue);
  };

  const handelChangeYear: SelectProps['onChange'] = (val) => {
    let newValue = val ? curr.clone().set('year', val) : undefined;
    if (newValue && isAfterNow(newValue)) {
      newValue = moment().startOf('month');
    }
    onChange?.(newValue);
  };

  return (
    <div className="flex gap2">
      <Select
        className="year-picker"
        options={BILLABLE_TIME_MONTH_MAP}
        value={value?.month() || month}
        onChange={handelChangeMonth}
      />
      <Select
        className="year-picker"
        options={isBillerPortal ? billerPortalYearOptions : yearOptions}
        onChange={handelChangeYear}
        value={value?.year() || year}
      />
    </div>
  );
};
