import React from 'react';
import { FoodAccessEnum } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export interface FoodAccessEnumComponentProps {
  value?: FoodAccessEnum;
}

export const FoodAccessEnumComponent = ({ value }: FoodAccessEnumComponentProps) => {
  const getFullName = () => {
    switch (value) {
      case FoodAccessEnum.NONE: return 'None selected';
      case FoodAccessEnum.LACK_RESOURCES: return 'Lack of available resources to shop for food (rural living)';
      case FoodAccessEnum.SHOP_NO_CARE: return 'Limited ability to shop independently, and no caregiver to help';
      case FoodAccessEnum.NO_AID: return 'Limited income to buy food, not receiving aid (WIC, CalFresh, Food Bank, etc)';
      case FoodAccessEnum.AID: return 'Limited income to buy food, but getting aid (WIC, CalFresh, Food Bank, etc)';
      case FoodAccessEnum.KITCHEN: return 'Limited access to kitchen features';
      case FoodAccessEnum.COOK_NO_CARE: return 'Limited ability to cook for oneself, and no caregiver';
      case FoodAccessEnum.COOK_CARE: return 'Limited ability to cook for oneself, but has a caregiver';
      case FoodAccessEnum.SHOP_CARE: return 'Limited ability to shop independently, but has a caregiver';
      default: return <DisplayOrEmptyComponent />;
    }
  };

  return (<>{getFullName()}</>);
};
