import { Divider, Space } from 'antd';
import { FC, ReactNode } from 'react';
import { EXERCISED_ICON, HAD_INSULIN_ICON, HAD_MEDS_ICON } from '../../assets/commonIconInfo';
import { Icon } from '../../icons/Icon';
import EmptyComponent from '../EmptyComponent/EmptyComponent';

export interface MeasurementTagsComponentProps {
  hadActivity?: boolean;
  hadInsulin?: boolean;
  hadMedicine?: boolean;
}

export const MeasurementTagsComponent: FC<MeasurementTagsComponentProps> = ({
  hadActivity,
  hadInsulin,
  hadMedicine,
}) => {
  const tags: ReactNode[] = [];
  if (hadMedicine) {
    tags.push((
      <Space key="medicine">
        <Icon src={HAD_MEDS_ICON.src} alt={HAD_MEDS_ICON.alt} />
        Took Medication
      </Space>
    ));
  }
  if (hadInsulin) {
    tags.push((
      <Space key="insulin">
        <Icon src={HAD_INSULIN_ICON.src} alt={HAD_INSULIN_ICON.alt} />
        Took Insulin
      </Space>
    ));
  }
  if (hadActivity) {
    tags.push((
      <Space key="activity">
        <Icon src={EXERCISED_ICON.src} alt={EXERCISED_ICON.alt} />
        Exercised
      </Space>
    ));
  }

  return tags.length === 0
    ? <EmptyComponent />
    : <Space split={<Divider type="vertical" />}>{tags}</Space>;
};
