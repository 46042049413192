import { CareNote, CareNoteTagEnum, useCareNoteSearch } from '../../../../uc-api-sdk';
import { BaseSortDirection } from '../../../../uc-api-sdk/staticFiles/useReqHook';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { MTPRNoteHistoryListComponent } from '../../component/MTPRNoteHistoryListComponent/MTPRNoteHistoryListComponent';

export interface MTPRSeeNoteHistoryContainerProps {
  patientId: string;
}

export const MTPRSeeNoteHistoryContainer = ({
  patientId,
}: MTPRSeeNoteHistoryContainerProps) => {
  const careNoteSearchInfo = useCareNoteSearch({
    params: {
      filter: {
        memberId: patientId,
        tagsIn: {
          in: [
            CareNoteTagEnum.MONTHLY_REVIEW_RD_HC,
            CareNoteTagEnum.MONTHLY_REVIEW_CA,
            CareNoteTagEnum.MONTHLY_REVIEW,
          ]
        }
      },
      pageInfo: {
        page: 1,
        size: 50,
        sort: [{
          property: 'createdAt',
          direction: BaseSortDirection.DESC,
        }]
      }
    },
    options: {
      sendOnMount: true,
    }
  });

  return (
    <FetchComponent
      info={careNoteSearchInfo}
    >
      {
        (value) => {
          const careNotes = (value?.data?.rawValue?.content || []) as CareNote[];
          return (
            careNotes !== undefined
              ? <MTPRNoteHistoryListComponent careNotes={careNotes} />
              : null
          );
        }
      }
    </FetchComponent>
  );
};
