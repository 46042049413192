import { DeviceModelEnumComponent } from '../../../../enumComponent/DeviceModelEnumComponent/DeviceModelEnumComponent';
import { DeviceModelEnum } from '../../../../uc-api-sdk';
import { DeviceImageComponent } from '../../../device/DeviceImageComponent';
import { OrderType, SupplyTypeEnum } from '../../type';
import { SupplyEnumComponent } from '../SupplyEnumComponent/SupplyEnumComponent';
import { SupplyImageComponent } from '../SupplyImageComponent/SupplyImageComponent';

import './OrderInfoComponent.scss';
import { OrderDeviceWithIdComponent } from '../OrderDeviceWithIdComponent/OrderDeviceWithIdComponent';

export type OrderInfoComponentProps = {
  showImage?: boolean;
} & ({
  type: OrderType.SUPPLIES;
  supplyType: SupplyTypeEnum;
  deviceModel?: DeviceModelEnum;
  deviceId?: string; // for BPM1 use only
} | {
  type: OrderType.DEVICES;
  deviceModel: DeviceModelEnum;
  supplyType?: SupplyTypeEnum;
  deviceId?: string; // for BPM1 use only
})

export const OrderInfoComponent = ({
  type,
  deviceModel,
  supplyType,
  deviceId,
  showImage = true,
}: OrderInfoComponentProps) => {
  const renderImage = () => {
    if (!showImage) return null;
    switch (type) {
      case OrderType.DEVICES:
        return <DeviceImageComponent deviceModel={deviceModel} />;
      case OrderType.SUPPLIES:
        return <SupplyImageComponent value={supplyType} />;
      default:
        return null;
    }
  };

  const renderTitle = () => {
    switch (type) {
      case OrderType.DEVICES:
        if (deviceId) {
          return (
            <OrderDeviceWithIdComponent
              deviceModel={deviceModel}
              deviceId={deviceId}
            />
          );
        }
        return <DeviceModelEnumComponent value={deviceModel} />;
      case OrderType.SUPPLIES:
        return <SupplyEnumComponent value={supplyType} />;
      default:
        return null;
    }
  };

  return (
    <div className="order-info-content">
      <div className="order-info__image">
        {renderImage()}
      </div>
      <div className="order-info__title">
        {renderTitle()}
      </div>
    </div>
  );
};
