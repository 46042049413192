export const AlertPlaceHolders = {
  Resolve: 'Add a comment to resolve the alert',
  Snooze: 'Add a comment to snooze the alert',
  CommentOnly: 'Add a comment only',
  Comment: 'Add a comment',
  Note: 'Add notes',
};

export const AlertText = {
  Compliance: 'Compliance Alerts',
  Medical: 'Medical Alerts',
  snoozedSuccess: 'The selected alert type has been snoozed.',
  unSnoozedSuccess: 'The selected alert type has been un-snoozed.',
  commentSuccess: 'Comment added',
  resolveSuccess: 'Alert resolved',
  error: 'An error occurred. Please try again.',
  noteSuccess: 'Note added',
};
