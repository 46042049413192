import { Spin } from 'antd';
import { EnrollmentComponent } from '../../component/EnrollmentComponent/EnrollmentComponent';
import { PatientProfileContainer } from '../../../patient/container/PatientProfileContainer/PatientProfileContainer';
import { EnrollmentProgressEnhancedProvider } from '../../context/EnrollmentProgressContext/EnrollmentProgressEnhancedProvider';
import { EnrollmentStepsEnhancedProvider } from '../../context/EnrollmentStepContext/EnrollmentStepsEnhancedProvider';
import { Step } from '../../context/EnrollmentStepContext/EnrollmentStepContext';
import { MixpanelProvider } from '../../../../contexts/MixpanelContext/MixpanelContext';
import { MixpanelEventsParents } from '../../../../contexts/MixpanelContext/MixpanelEvents';
import { PatientMedicalOrganizationContextProvider } from '../../../../contexts/PatientMedicalOrganizationContext/PatientMedicalOrganizationContext';

export interface EnrollmentContainerProps {
  patientId: string;
  initialStep?: Step;
  onClose?: () => void;
}

export const EnrollmentContainer = ({
  patientId,
  initialStep,
  onClose,
}: EnrollmentContainerProps) => (
  <PatientProfileContainer
    patientId={patientId}
    getEnrollment
    getConsent
    getInsurance
    getAssignees
    getEnrolledProgram
  >
    {(patientInfo, loading) => {
      if (
        (
          loading
          && (
            patientInfo?.enrollmentInfo === undefined
            || patientInfo?.patientInfo === undefined
          )
        ) || (
          // initial loading
          patientInfo?.patientInfoService?.patientInsurance === undefined
        )
      ) {
        return <Spin />;
      }
      // this should not happen unless there was an error
      if (!patientInfo?.patientInfo) {
        return (
          <div>Missing patient information!</div>
        );
      }
      const patientClinicId = patientInfo.patientInfo?.clinicId;
      return (
        <MixpanelProvider context={MixpanelEventsParents.PatientEnrollment}>
          <EnrollmentProgressEnhancedProvider
            enrollment={patientInfo.enrollmentInfo ?? null}
          >
            <PatientMedicalOrganizationContextProvider
              clinicId={patientClinicId || ''}
            >
              <EnrollmentStepsEnhancedProvider
                consentService={patientInfo.consentService}
                enrollmentService={patientInfo.enrollmentService}
                initialStep={initialStep}
              >
                <EnrollmentComponent patientInfo={patientInfo} onClose={onClose} />
              </EnrollmentStepsEnhancedProvider>
            </PatientMedicalOrganizationContextProvider>
          </EnrollmentProgressEnhancedProvider>
        </MixpanelProvider>
      );
    }}
  </PatientProfileContainer>
);
