import { Spin } from 'antd';
import { FC, ReactNode } from 'react';

import './OverlayLoading.scss';

const DEFAULT_LOADING_INDICATOR = <Spin />;

export interface OverlayLoadingProps {
  loading: boolean,
  LoadingIndicator?: ReactNode,
}

export const OverlayLoading: FC<OverlayLoadingProps> = ({
  loading,
  LoadingIndicator = DEFAULT_LOADING_INDICATOR,
}) => (
  loading
    ? (
      <div data-testid="overlay-loading" className="overlay-loading">
        { LoadingIndicator }
      </div>
    ) : null
);

export default OverlayLoading;
