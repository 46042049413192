import React, {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import moment, { Moment } from 'moment';
import dayjs, { Dayjs } from 'dayjs';
import { Calendar, CalendarProps } from 'antd';
import { CalendarTitleComponent } from '../CalendarTitleComponent/CalendarTitleComponent';
import './MiniCalendarComponent.scss';
import { InputTypeRequired } from '../../../Input/types';

export interface MiniCalendarComponentProps extends InputTypeRequired<Dayjs> {
  selectDateOnMonthChange?: boolean;
}

export const MiniCalendarComponent = ({
  selectDateOnMonthChange = true,
  value,
  onChange,
}: MiniCalendarComponentProps) => {
  const [tempDate, setTempDate] = useState<Dayjs>(value);
  const date = useMemo(() => (
    moment((selectDateOnMonthChange ? value : tempDate)?.toDate())
  ), [
    value,
    selectDateOnMonthChange,
    tempDate,
  ]);

  useEffect(() => {
    setTempDate(value);
  }, [value]);

  const headerRenderer = useCallback<Exclude<CalendarProps<Moment>['headerRender'], undefined>>(({ value: v }) => {
    const d = dayjs(v.toDate());

    return (
      <CalendarTitleComponent
        view="month"
        showMonthOnly
        date={d}
        onGoPrev={() => {
          setTempDate((v) => v.clone().add(-1, 'month'));
          if (selectDateOnMonthChange) onChange?.(d.clone().add(-1, 'month'));
        }}
        onGoNext={() => {
          setTempDate((v) => v.clone().add(1, 'month'));
          if (selectDateOnMonthChange) onChange?.(d.clone().add(1, 'month'));
        }}
      />
    );
  }, [onChange, setTempDate]);

  const handleChange: CalendarProps<Moment>['onChange'] = (v) => {
    onChange?.(dayjs(v.toDate()));
  };

  return (
    <Calendar
      className="mini-calendar-component"
      value={date}
      fullscreen={false}
      headerRender={headerRenderer}
      onChange={handleChange}
    />
  );
};
