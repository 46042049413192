import { ChronicDiseaseEnum } from '../../uc-api-sdk';

export const getText = (value?: ChronicDiseaseEnum | null) => {
  switch (value) {
    case ChronicDiseaseEnum.CHRONIC_KIDNEY_DISEASE:
      return 'Chronic Kidney Disease (CKD)';
    case ChronicDiseaseEnum.CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE:
      return 'Chronic Obstructive Pulmonary Disease (COPD)';
    case ChronicDiseaseEnum.CONGESTIVE_HEART_FAILURE:
      return 'Congestive Heart Failure';
    case ChronicDiseaseEnum.DIABETES:
      return 'Diabetes';
    case ChronicDiseaseEnum.HYPERLIPIDEMIA:
      return 'Hyperlipidemia';
    case ChronicDiseaseEnum.HYPERTENSION:
      return 'Hypertension';
    case ChronicDiseaseEnum.OBESITY:
      return 'Obesity';
    case ChronicDiseaseEnum.PRE_DIABETES:
      return 'Pre-diabetes';
    case ChronicDiseaseEnum.CUSTOM_DISEASE:
    case ChronicDiseaseEnum.NONE:
    case ChronicDiseaseEnum.name:
    default:
      return null;
  }
};

export interface ChronicDiseaseEnumComponentProps {
  value?: ChronicDiseaseEnum | null;
}

export const ChronicDiseaseEnumComponent = ({
  value,
}: ChronicDiseaseEnumComponentProps) => (
  <span>{getText(value)}</span>
);
