import { PatientCommonLabelComponent } from '../../../../patient/component/PatientCommonLabelComponent/PatientCommonLabelComponent';

interface ClinicalGoalFreeTextVitalsLabelComponentProps {
  customCategory: string ;
}

export const ClinicalGoalFreeTextVitalsLabelComponent = ({
  customCategory,
} : ClinicalGoalFreeTextVitalsLabelComponentProps) => (
  <PatientCommonLabelComponent>
    <span>{customCategory}</span>
  </PatientCommonLabelComponent>
);
