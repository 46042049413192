import { ReactNode } from 'react';
import { Icon } from '../../../../icons/Icon';
import { RESTRICTED_ACCESS_ICON } from '../../../../assets/commonIconInfo';
import { Title } from '../../../../uiComponent/Title/Title';

export interface RestrictedPatientPageComponentProps {
  actionMessage?: ReactNode;
}

export const RestrictedPatientViewComponent = ({
  actionMessage,
}: RestrictedPatientPageComponentProps) => (
  <div className="flex fd-c jc-c ai-c w100 h100">
    <div className="mb30">
      <Icon info={RESTRICTED_ACCESS_ICON} size={80} />
    </div>
    <Title noMargin>
      Looks like you don't have access to this patient's information.
    </Title>
    <Title.SubTitle>
      {actionMessage}
    </Title.SubTitle>
  </div>
);

export const ProviderRestrictedPatientViewComponent = () => (
  <RestrictedPatientViewComponent
    // eslint-disable-next-line react/jsx-props-no-spreading
    actionMessage="Please contact your care team member if you think this is a mistake."
  />
);
