import React, { FC, ReactNode } from 'react';
import EmptyComponent from '../../../../uiComponent/EmptyComponent/EmptyComponent';
import { Title } from '../../../../uiComponent/Title/Title';

export interface LogbookSingleInfoComponentProps {
  title: ReactNode;
  content: ReactNode;
  emptyMessage?: string;
}

export const LogbookSingleInfoComponent: FC<LogbookSingleInfoComponentProps> = ({
  title,
  content,
  emptyMessage,
}) => (
  <div>
    <Title className="text-gray" type="secondary">{title}</Title>
    <div className="mb20">
      {content
        ? <Title.SubTitle>{content}</Title.SubTitle>
        : <EmptyComponent text={emptyMessage} />}
    </div>
  </div>
);
