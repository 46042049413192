import { SupportDeviceEnumComponent } from '../../../../enumComponent/SupportDeviceEnumComponent/SupportDeviceEnumComponent';
import { DeviceModelEnum } from '../../../../uc-api-sdk';
import { DeviceImageComponent } from '../../DeviceImageComponent';
import { SupportDeviceEnum } from '../../type';

export interface SupportDeviceInfoComponentProps {
  deviceModel: DeviceModelEnum;
  showImage?: boolean;
}

export const SupportDeviceInfoComponent = ({
  deviceModel,
  showImage = true,
}: SupportDeviceInfoComponentProps) => {
  const getSupportDeviceInfo = () => {
    switch (deviceModel) {
      case DeviceModelEnum.BPM1: return SupportDeviceEnum.WIFI_ROUTER;
      case DeviceModelEnum.BPM1_HOTSPOT: return SupportDeviceEnum.HOTSPOT;
      default: return undefined;
    }
  };

  const renderSupportDevice = () => {
    const supportDevice = getSupportDeviceInfo();
    if (!supportDevice) return null;
    return (
      <>
        {
          showImage
          && (
            <DeviceImageComponent
              deviceModel={supportDevice}
              className="mr5"
            />
          )
        }
        <SupportDeviceEnumComponent value={supportDevice} />
      </>
    );
  };

  return (<>{renderSupportDevice()}</>);
};
