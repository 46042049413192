import { Button, Space } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { PinnedPatientsContainer } from '../../container/PinnedPatientsContainer/PinnedPatientsContainer';
import { usePatientCareContext } from '../../context/PatientCareContext/PatientCareContext';
import { ClickableDiv } from '../../../../uiComponent/ClickableDiv/ClickableDiv';
import { PinPatientButtonContainer } from '../../../outstanding/container/PinPatientButtonContainer/PinPatientButtonContainer';
import { RecentPatientsComponent } from '../RecentPatientsComponent/RecentPatientsComponent';
import { OnCallCoverageModeTagComponent } from '../OnCallCoverageModeTag/OnCallCoverageModeTag';
import { useOnCallContext } from '../../context/OnCallContext/OnCallContext';
import './PinnedPatientsBarComponent.scss';

export interface PinnedPatientsBarComponentProps {

}

export const PinnedPatientsBarComponent = () => {
  const {
    getNextPatient,
    totalPatientCases,
    exitWorkMode,
  } = usePatientCareContext();
  const { isOnCall } = useOnCallContext({ type: 'patientCare' });

  return (
    <div className="pinned-patients-bar-component">
      <Space size="small" className="pinned-patients-bar-component__section-1">
        <ClickableDiv
          onClick={exitWorkMode}
          className="pinned-patients-bar-component__patient-care-btn text-gray-scale-2"
        >
          Patient Care
        </ClickableDiv>
        /
        <div>
          Patient Cases
          {' ('}
          {totalPatientCases}
          )
        </div>
        {isOnCall && <OnCallCoverageModeTagComponent />}
      </Space>
      <div className="pinned-patient-avatars">
        <PinnedPatientsContainer />
      </div>
      <div className="flex ai-c gap0">
        <PinPatientButtonContainer className="pin-patient-button" />
        <RecentPatientsComponent className="recent-patient-button" />
        <Button onClick={getNextPatient} type="text" className="next-patient-button">
          <Space align="center">
            <div>Next Patient</div>
            <RightOutlined />
          </Space>
        </Button>
      </div>
    </div>
  );
};
