import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { CalendarSourceEnum, useClinicEventSearch } from '../../../../uc-api-sdk';
import { useLoggedInUserFromContext } from '../../../../contexts/loggedInUserContext';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { DayCalendarComponent } from '../../component/DayCalendarComponent/DayCalendarComponent';
import { useCalendarEventProcessor } from '../../hook/useCalendarEventProcessor';
import { useUpdateListener } from '../../../../contexts/UpdateContext/UpdateContext';

export interface DayCalendarContainerProps {
  clinicIds?: string[];
  onClose?: () => void;
}

export const DayCalendarContainer = ({
  clinicIds,
  onClose,
}: DayCalendarContainerProps) => {
  const { userId } = useLoggedInUserFromContext();
  const [date, setDate] = useState<Dayjs>(dayjs());

  const calendarInfo = useClinicEventSearch({
    options: { sendOnMount: true },
    params: {
      filter: {
        participantsIn: userId ? {
          in: [userId],
        } : undefined,
        myCalendarSourceIn: {
          in: [
            CalendarSourceEnum.UC_VISIT,
            CalendarSourceEnum.OTHER,
            CalendarSourceEnum.GOOGLE_CALENDAR,
          ],
        },
        clinicIdIn: clinicIds ? {
          in: clinicIds,
        } : undefined,
        startTimeRange: {
          gte: date?.clone().startOf('day').toISOString(),
          lte: date?.clone().endOf('day').toISOString(),
        },
      },
    },
  });

  useUpdateListener('TRANSCRIBE_FINISHED', calendarInfo.refetch);

  const { processedEvents } = useCalendarEventProcessor(calendarInfo.data?.data?.content);

  return (
    <FetchComponent
      info={calendarInfo}
      showEmptyOverlay={false}
      showErrorOverlay={false}
      showLoadingOverlay={false}
      alwaysShowChildren
    >
      <DayCalendarComponent
        events={processedEvents}
        isLoading={calendarInfo.isLoading}
        date={date}
        onChange={setDate}
        refetch={calendarInfo.refetch}
        onClose={onClose}
      />
    </FetchComponent>
  );
};
