import React, {
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { debounce } from 'lodash';
import { functionNotImplYet } from '../helper';
import { DurationSendArgs, MainSendArgs, useMixpanelMainContext } from './MixpanelMainContext';
import { MixpanelEventsParents } from './MixpanelEvents';

export interface SendArgs extends Omit<MainSendArgs, 'parent'> {
  parent?: MixpanelEventsParents;
}

export interface Value {
  send: (args: SendArgs) => void;
  sendDuration: (args: DurationSendArgs) => void;
}

const contextNotImplementedYet = functionNotImplYet('MixpanelContext');

export const MixpanelContext = React.createContext<Value>({
  send: contextNotImplementedYet('send'),
  sendDuration: contextNotImplementedYet('sendDuration'),
});

export interface MixpanelProviderProps {
  children: React.ReactNode;
  context?: MixpanelEventsParents;
}

export const MixpanelProvider = ({
  children,
  context,
}: MixpanelProviderProps) => {
  const {
    send: sendMain,
    sendDuration: sendDurationMain,
  } = useMixpanelMainContext();

  const send = useCallback((args: SendArgs) => {
    sendMain({
      properties: args.properties,
      event: args.event,
      patientId: args.patientId,
      parent: args.parent || context,
    });
  }, [sendMain, context]);

  const sendDuration = useCallback((args: DurationSendArgs) => {
    sendDurationMain(args);
  }, [sendMain, context]);

  const value = useMemo(() => ({
    send: debounce(send, 500),
    sendDuration: debounce(sendDuration, 500),
  }), [
    send,
    sendDuration,
  ]);

  return (
    <MixpanelContext.Provider value={value}>
      {children}
    </MixpanelContext.Provider>
  );
};

export const useMixpanelContext = () => {
  const context = useContext<Value>(MixpanelContext);
  return context;
};
