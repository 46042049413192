import { Divider } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { ToolbarProps } from 'react-big-calendar';
import { MiniCalendarComponentProps } from '../MiniCalendarComponent/MiniCalendarComponent';

import './DayCalendarToolbarComponent.scss';
import { CalendarDropdownComponent } from '../CalendarDropdownComponent/CalendarDropdownComponent';

export interface DayCalendarToolbarComponentProps extends ToolbarProps {

}

export const DayCalendarToolbarComponent = ({
  date,
  view,
  onNavigate,
}: DayCalendarToolbarComponentProps) => {
  const d = dayjs(date);

  const selectToday = () => {
    onNavigate('TODAY');
  };

  const goToPrevDay = () => {
    onNavigate('PREV');
  };

  const goToNextDay = () => {
    onNavigate('NEXT');
  };

  const handleDateChange: MiniCalendarComponentProps['onChange'] = (v) => {
    onNavigate('DATE', v.toDate());
  };

  return (
    <div className="day-cal-toolbar">
      <div className="day-cal-toolbar-content">
        <CalendarDropdownComponent
          view={view}
          date={d}
          onChange={handleDateChange}
          onSelectToday={selectToday}
          onSelectPrevDay={goToPrevDay}
          onSelectNextDay={goToNextDay}
        />
      </div>
      <Divider />
    </div>
  );
};
