import {
  AlternativeDeviveType,
  AssignDeviceInfo,
  DeviceModelEnum,
  DeviceTypeEnum,
  VitalEnumType,
} from '../../../../uc-api-sdk';
import { InputTypeArray } from '../../../Input/types';
import { useDeviceCheckboxGroupHelper } from '../../hook/useDeviceCheckboxGroupHelper/useDeviceCheckboxGroupHelper';
import { CellularDeviceCheckboxComponent } from '../CellularDeviceCheckboxComponent/CellularDeviceCheckboxComponent';
import { ClearDevicesCheckboxGroupComponent } from '../ClearDevicesCheckboxGroupComponent/ClearDevicesCheckboxGroupComponent';

export interface NonAppDeviceCheckboxComponentProps extends
  Partial<InputTypeArray<AssignDeviceInfo>> {
  vitals: VitalEnumType[];
  nonSmartPhoneAlternatives?: AlternativeDeviveType[];
}

export const NonAppDeviceCheckboxComponent = ({
  value,
  onChange,
  vitals,
  nonSmartPhoneAlternatives,
}: NonAppDeviceCheckboxComponentProps) => {
  const {
    vitalsMap,
    getDevice,
    doesSameDeviceTypeExist,
    handleChange,
  } = useDeviceCheckboxGroupHelper(vitals, value, onChange);
  const isDeviceSupported = (deviceModel: AlternativeDeviveType) => (
    !!nonSmartPhoneAlternatives?.includes(deviceModel)
  );
  const isBPCellularSupport = isDeviceSupported(AlternativeDeviveType.BP_CELLULAR_PYLO);
  const isWifiSupport = isDeviceSupported(AlternativeDeviveType.WIFI_ROUTER);
  const isHotspotSupport = isDeviceSupported(AlternativeDeviveType.HOTSPOT);

  return (
    vitalsMap.BP
      ? (
        <>
          {
            isBPCellularSupport
            && (
              <CellularDeviceCheckboxComponent
                type={DeviceTypeEnum.BP}
                model={DeviceModelEnum.PY_802_LTE}
                value={getDevice(DeviceModelEnum.PY_802_LTE)}
                onChange={handleChange(DeviceModelEnum.PY_802_LTE)}
                disabled={doesSameDeviceTypeExist(DeviceTypeEnum.BP, DeviceModelEnum.PY_802_LTE)}
              />
            )
          }
          <ClearDevicesCheckboxGroupComponent
            vitals={vitals}
            value={value}
            onChange={onChange}
            supportHotspot={isHotspotSupport}
            supportWifi={isWifiSupport}
          />
        </>
      ) : null
  );
};
