import React, { FC } from 'react';
import './VitalsMonitoringBGTableCellComponent.scss';

export interface VitalsMonitoringBGTableCellComponentProps {
  isSelected: boolean;
}

export const VitalsMonitoringBGTableCellComponent: FC<
  VitalsMonitoringBGTableCellComponentProps
> = ({
  isSelected,
}) => {
  if (isSelected) {
    return (
      <div className="vital-monitoring-bg-table-cell selected-cell" />
    );
  }
  return null;
};
