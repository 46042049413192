// eslint-disable-next-line import/prefer-default-export
export const DEFAULT_PAGINATION = { page: 1, pageSize: 30 };

export const PAGINATION_TEN = { page: 1, pageSize: 10 };

export const NO_PAGINATION = {
  page: 1,
  pagination: false,
  size: 0,
  sort: [],
};
