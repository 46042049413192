import { useMemo } from 'react';
import { InputTypeRequired } from '../../../Input/types';
import { RadioGroupComponent, RadioGroupComponentProps } from '../../../../uiComponent/RadioGroupComponent/RadioGroupComponent';
import { DeviceDeliveryMethodComponent } from '../DeviceDeliveryMethodComponent/DeviceDeliveryMethodComponent';
import { DeviceDeliveryMethodEnum } from '../../../../uc-api-sdk';
import TooltipComponent from '../../../../uiComponent/TooltipComponent/TooltipComponent';
import { ShippingNoticeComponent } from '../../../device/component/ShippingNoticeComponent/ShippingNoticeComponent';

export interface DeviceDeliveryMethodRadioComponentProps extends
  Partial<InputTypeRequired<DeviceDeliveryMethodEnum>> {
  disablePickup?: boolean;
  direction?: RadioGroupComponentProps<string>['direction'];
}

export const DeviceDeliveryMethodRadioComponent = ({
  value,
  onChange,
  disablePickup,
  direction,
  disabled,
}: DeviceDeliveryMethodRadioComponentProps) => {
  const processedOptions = useMemo(() => ([
    {
      value: DeviceDeliveryMethodEnum.PICK_UP,
      label: (
        <div className="flex ai-bl">
          <DeviceDeliveryMethodComponent value={DeviceDeliveryMethodEnum.PICK_UP} />
          {
            disablePickup
            && (
              <div className="flex gap0 ai-c">
                (Option not available)
                <TooltipComponent
                  type="info-icon"
                  title="Devices are not inventoried in this clinic"
                  highlightOnHover={false}
                />
              </div>
            )
          }
        </div>
      ),
      disabled: disablePickup,
    },
    {
      value: DeviceDeliveryMethodEnum.SHIP,
      label: <DeviceDeliveryMethodComponent value={DeviceDeliveryMethodEnum.SHIP} />,
    },
  ]), []);

  return (
    <>
      <ShippingNoticeComponent
        hide={!value || value !== DeviceDeliveryMethodEnum.SHIP}
      />
      <RadioGroupComponent
        options={processedOptions}
        value={value}
        onChange={onChange}
        direction={direction}
        disabled={disabled || undefined}
      />
    </>
  );
};
