import { Button, Tooltip } from 'antd';
import { useEmployeePinPatient, useEmployeeUnpinPatient, usePatientPatientList } from '../../../../uc-api-sdk';
import { usePatientContext } from '../../../../contexts/PatientInfoContext/PatientInfoContext';
import { ApiRequestHelper } from '../../../../helpers/ApiRequest';
import { FetchComponent } from '../../../../uiComponent/FetchComponent/FetchComponent';
import { useUpdate } from '../../../../contexts/UpdateContext/UpdateContext';
import { Icons } from '../../../../icons/Icons';

export interface PinPatientButtonContainerProps {
  className?: string;
}

export const PinPatientButtonContainer = ({
  className = '',
}: PinPatientButtonContainerProps) => {
  const { info } = usePatientContext();
  const patientId = info?.id || '';
  const update = useUpdate('PIN_PATIENT');

  const pinPatientStatus = usePatientPatientList({
    options: { sendOnMount: true },
    params: {
      searchRequest: {
        filter: {
          patient: {
            id: patientId,
            filterPinnedPatient: true,
          },
        },
      },
    },
  });
  const pinPatient = useEmployeePinPatient({});
  const unpinPatient = useEmployeeUnpinPatient({});
  const isPinned = pinPatientStatus.data?.data?.totalSize === 1;

  const handleSuccess = () => {
    update.updateValue();
    pinPatientStatus.refetch();
  };

  const handlePinPatient = () => {
    const req = pinPatient.send({
      params: {
        pinRequest: {
          patientId,
        },
      },
    });
    ApiRequestHelper.tryCatch(req, {
      success: 'Pinned patient successfully!',
      error: 'Failed to pin the patient!',
      onSuccess: handleSuccess,
    });
  };

  const handleUnpinPatient = () => {
    const req = unpinPatient.send({
      params: {
        unpinRequest: {
          patientId,
        },
      },
    });
    ApiRequestHelper.tryCatch(req, {
      success: 'Unpinned patient successfully!',
      error: 'Failed to unpin the patient!',
      onSuccess: handleSuccess,
    });
  };

  const getTooltipTitle = () => (
    `${isPinned ? 'Unpin' : 'Pin'} this patient`
  );

  const renderButton = () => {
    if (pinPatientStatus.data?.data?.totalSize === 1) {
      return (
        <Button onClick={handleUnpinPatient} type="text" className={className}>
          <Icons.PinFillIcon />
        </Button>
      );
    }

    return (
      <Button onClick={handlePinPatient} type="text" className={className}>
        <Icons.PinOutlineIcon />
      </Button>
    );
  };

  return (
    <FetchComponent info={pinPatientStatus} showOnRefetch>
      <Tooltip
        title={getTooltipTitle()}
        placement="bottom"
      >
        {renderButton()}
      </Tooltip>
    </FetchComponent>
  );
};
