import { map } from 'lodash';
import React, { FC, useMemo } from 'react';
import { BPChartComponent, BPChartData } from './BPChartComponent';
import { MeasurementType } from '../../type/MeasurementTypes';
import { FromDateToDateDayjs } from '../../type';
import { BPMeasurementService } from '../../helper/BPMeasurementService';

export interface BPChartDataProcessorComponentProps extends FromDateToDateDayjs {
  data: MeasurementType[];
}

export const BPChartDataProcessorComponent: FC<BPChartDataProcessorComponentProps> = ({
  data,
  fromDate,
  toDate,
}) => {
  const processedData = useMemo<BPChartData[]>(() => (
    map(data, (v) => {
      const m = new BPMeasurementService(v);
      return {
        date: m.date.toDate(),
        systolic: m.systolic.value,
        diastolic: m.diastolic.value,
        heartRate: m.heartRate.value,
        severity: m.chartSeverity,
      };
    })
  ), [data]);

  return (
    <BPChartComponent
      data={processedData}
      fromDate={fromDate}
      toDate={toDate}
    />
  );
};
