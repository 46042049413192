import { TagComponent } from '../../../../../uiComponent/TagComponent/TagComponent';
import { ComplexityEnumComponent } from '../../../../../enumComponent/ComplexityEnumComponent/ComplexityEnumComponent';
import { PatientComplexityEnum } from '../../../../../uc-api-sdk';

export const PatientStatusTagComponent = {
  IneligibleTag: () => (
    <TagComponent type="error">
      Ineligible to enroll
    </TagComponent>
  ),
  UnenrolledPotentialTag: () => (
    <TagComponent type="warning">
      Unenrolled Potential
    </TagComponent>
  ),
  InsCheckPendingTag: () => (
    <TagComponent type="warning">
      Ins. Check Pending
    </TagComponent>
  ),
  ManualInsCheckPendingTag: () => (
    <TagComponent type="warning">
      Manual Ins. Check Pending
    </TagComponent>
  ),
  MissingInfoTag: () => (
    <TagComponent type="warning">
      Missing Info
    </TagComponent>
  ),
  NoAttentionNeededTag: () => (
    <TagComponent type="default">
      No attention needed
    </TagComponent>
  ),
  InactiveTag: () => (
    <TagComponent type="error" iconType="none" background="oval">
      Inactive
    </TagComponent>
  ),
  ComplexTag: () => (
    <TagComponent type="warning" iconType="none" background="oval">
      <ComplexityEnumComponent value={PatientComplexityEnum.COMPLEX} />
    </TagComponent>
  ),
  NonComplexTag: () => (
    <TagComponent type="default" iconType="none" background="oval">
      <ComplexityEnumComponent value={PatientComplexityEnum.NON_COMPLEX} />
    </TagComponent>
  ),
};
