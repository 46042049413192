// ref: https://www.cdc.gov/nccdphp/dnpao/growthcharts/training/bmiage/page5_2.html#:~:text=Formula%3A%20weight%20(lb)%20%2F,in)%5D2%20x%20703&text=When%20using%20a%20handheld%20calculator,round%20to%20one%20decimal%20place.
// Formula: weight (lb) / [height (in)]2 x 703

// ref: https://www.cdc.gov/obesity/basics/adult-defining.html#:~:text=If%20your%20BMI%20is%20less,falls%20within%20the%20obesity%20range.
// Indication:
/*
If your BMI is less than 18.5, it falls within the underweight range.
If your BMI is 18.5 to <25, it falls within the healthy weight range.
If your BMI is 25.0 to <30, it falls within the overweight range.
If your BMI is 30.0 or higher, it falls within the obesity range.
*/
import { ReactNode, useEffect } from 'react';
import { NumberService } from '../../helpers/number';
import { useDeepCompareMemo } from '../../hooks/useDeepCompareEffect';
import { Height, Weight } from '../../uc-api-sdk';
import DisplayOrEmptyComponent from '../DisplayComponent/DisplayOrEmptyComponent';
import EmptyComponent from '../EmptyComponent/EmptyComponent';

export enum BMIIndicationEnum {
  UNDERWEIGHT = 'UNDERWEIGHT',
  NORMAL = 'NORMAL',
  OVERWEIGHT = 'OVERWEIGHT',
  OBESITY = 'OBESITY',
}

export interface BMIComponentProps {
  label?: ReactNode;
  weight?: Weight;
  height?: Height;
  showLabelWhenEmpty?: boolean;
  toFixed?: number;
  showIndication?: boolean;
  onIsNormalChange?: (isNormal: boolean) => void;
}

export const BMIComponent = ({
  label = 'BMI:',
  weight,
  height,
  onIsNormalChange,
  showLabelWhenEmpty = true,
  toFixed = 1,
  showIndication = true,
}: BMIComponentProps) => {
  const [
    weightInLb,
    heightInInch
  ] = useDeepCompareMemo(() => (
    NumberService.getStandardWeightAndHeight(weight, height)
  ), [weight, height]);

  if (!weightInLb || !heightInInch) {
    return (
      <>
        {showLabelWhenEmpty && <span>{label}</span>}
        <EmptyComponent />
      </>
    );
  }

  const getValue = () => ((weightInLb / heightInInch ** 2) * 703);

  const getIndication = () => {
    const bmiValue = getValue();
    if (bmiValue < 18.5) return BMIIndicationEnum.UNDERWEIGHT;
    if (bmiValue < 25) return BMIIndicationEnum.NORMAL;
    if (bmiValue < 30) return BMIIndicationEnum.OVERWEIGHT;
    return BMIIndicationEnum.OBESITY;
  };

  const getValueText = () => getValue().toFixed(toFixed);
  const isNormal = getIndication() === BMIIndicationEnum.NORMAL;

  useEffect(() => {
    onIsNormalChange?.(isNormal);
  }, [isNormal, onIsNormalChange]);

  const getIndicationText = () => {
    switch (getIndication()) {
      case BMIIndicationEnum.UNDERWEIGHT: return 'Underweight';
      case BMIIndicationEnum.OVERWEIGHT: return 'Overweight';
      case BMIIndicationEnum.OBESITY: return 'Obesity';
      case BMIIndicationEnum.NORMAL: return 'Normal';
      default: return '';
    }
  };

  return (
    <div className="flex">
      <span>
        {/* ie BMI: */}
        {label}
      </span>
      <DisplayOrEmptyComponent value={getValueText()} />
      {
        showIndication
        && (
          <span>
            {' '}
            (
            {getIndicationText()}
            )
          </span>
        )
      }
    </div>
  );
};
