import Avatar from '../../../../uiComponent/Avatar/Avatar';
import TextWithSubAndLinkComponent from '../../../../uiComponent/TextWithSubAndLinkComponent/TextWithSubAndLinkComponent';
import './MiniUserComponent.scss';

export interface MiniUserComponentProps {
  avatar?: string;
  name?: string;
  role?: string;
  email?: string;
  id?: string;
  isObserver?: boolean;
}

export const MiniUserComponent = ({
  avatar,
  name,
  role,
  email,
  id,
  isObserver = false,
}: MiniUserComponentProps) => (
  <div className="mini-user-component">
    <div>
      <Avatar src={avatar} />
    </div>
    <div>
      <div className="flex f-w breakword">
        {
          id ? (
            <TextWithSubAndLinkComponent
              text={name}
              link={`/admin-portal/users/${id}`}
            />
          ) : name
        }
        {role && (<div>{`, ${role}`}</div>)}
        {isObserver && (<div className="text-secondary-gray">(Observer user)</div>)}
      </div>
      <div className="mini-user-component-email">
        {email}
      </div>
    </div>
  </div>
);
