import { FormOptions, useFormHookFactory } from '../useFormHookFactory/useFormHookFactory';

export const useMedicationForm = (options?: FormOptions) => {
  const factory = useFormHookFactory({
    type: {
      name: 'type',
      label: 'Type',
      required: true,
    },
    name: {
      name: 'name',
      label: 'Medication name',
      required: true,
    },
    status: {
      name: 'status',
      label: 'Status',
    },
    otherMD: {
      name: 'otherMD',
      label: "Not prescribed by this patient's primary provider",
    },
    dosage: {
      name: 'dosage',
      label: 'Dosage',
      required: true,
    },
    startDate: {
      name: 'startDate',
      label: 'Start date',
    },
    endDate: {
      name: 'endDate',
      label: 'Discontinue date',
    },
    instruction: {
      name: 'instruction',
      label: 'Instruction',
    },
  }, options);

  return factory;
};
