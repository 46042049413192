import { Button } from 'antd';
import { PatientEnrolledProgram, PatientHiatusStatus } from '../../../../uc-api-sdk';
import './PauseServiceViewComponent.scss';
import { PauseServiceViewCardComponent } from '../PauseServiceViewCardComponent/PauseServiceViewCardComponent';
import { PauseServicNameEnum } from '../PauseServiceViewCardComponent/PauseServiceNameEnum';
import { FixedComponent } from '../../../../uiComponent/FixedComponent/FixedComponent';

export interface PauseServiceViewData {
  hiatusDetails: PatientHiatusStatus;
}

export interface PauseServiceViewComponentProps {
  enrolledProgram: PatientEnrolledProgram;
  onResumeAll: () => void;
  onEdit: () => void;
}

export const PauseServiceViewComponent = ({
  enrolledProgram,
  onResumeAll,
  onEdit,
}: PauseServiceViewComponentProps) => {
  const hiatusDetails = enrolledProgram?.hiatusDetail;

  const renderCardTitle = (
    hiatusDetail: PatientHiatusStatus | null | undefined,
    serviceName: PauseServicNameEnum,
  ) => {
    if (hiatusDetail) {
      return (
        <PauseServiceViewCardComponent
          hiatusDetail={hiatusDetail}
          serviceName={serviceName}
        />
      );
    }
    return null;
  };

  const note = (hiatusDetails: PatientHiatusStatus) => hiatusDetails.note;

  return (
    <FixedComponent>
      <FixedComponent.Child>
        <div className="pauseServiceViewComponent">
          <div>
            {renderCardTitle(hiatusDetails, PauseServicNameEnum.VITAL_MONITORING)}
            {renderCardTitle(hiatusDetails, PauseServicNameEnum.VISIT_SERVICE)}
            {renderCardTitle(hiatusDetails, PauseServicNameEnum.BILLING)}
          </div>
          <div>
            <div className="text-gray-scale-2 fs12">Notes</div>
            <p>
              {hiatusDetails && note(hiatusDetails)}
            </p>
          </div>
        </div>
      </FixedComponent.Child>
      <FixedComponent.Child isFixed>
        <div className="border-t flex jc-sb ai-c pt20">
          <Button onClick={onResumeAll} type="link">Resume All Services</Button>
          <Button onClick={onEdit}>Edit</Button>
        </div>
      </FixedComponent.Child>
    </FixedComponent>
  );
};
