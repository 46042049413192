import { Button } from 'antd';
import useChangePage from '../../hooks/useChangePage/useChangePage';

export interface PatientNameAnchorComponentProps {
  name: string;
  patientId: string;
}

export const PatientNameAnchorComponent = ({
  name,
  patientId,
}: PatientNameAnchorComponentProps) => {
  const { goToCarePortalPatient } = useChangePage();
  const handleClick = () => goToCarePortalPatient({ patientId });

  return (
    <Button
      className="no-style-btn underline anchor-hover"
      type="text"
      onClick={handleClick}
    >
      {name}
    </Button>
  );
};
