import dayjs from 'dayjs';
import { ColumnType } from 'antd/lib/table';
import { Article, PatientArticles } from '../../uc-api-sdk';
import { DisplayDateComponent } from '../../uiComponent/DisplayComponent/DisplayDateComponent';
import { ClickableDiv } from '../../uiComponent/ClickableDiv/ClickableDiv';
import DisplayOrEmptyComponent from '../../uiComponent/DisplayComponent/DisplayOrEmptyComponent';

export type TableArticle = Article & {
  sentDate?: string | null;
}

export const articleTableColumns = ({
  name: () => ({
    key: 'article',
    title: 'Article',
    render: (value: unknown, patientArticle: PatientArticles) => {
      const { article } = patientArticle || {};
      const { name, url } = article || {};
      return (
        url
          ? (
            <ClickableDiv
              onClick={() => {
                if (url) window.open(url, '_blank')?.focus();
              }}
              className="article-select-option-info__link"
            >
              <DisplayOrEmptyComponent value={name} />
            </ClickableDiv>
          ) : (
            <DisplayOrEmptyComponent value={name} />
          )
      );
    },
  }),
  sentDate: (): ColumnType<Article> => ({
    key: 'sentDate',
    title: 'Sent date',
    dataIndex: 'updatedAt',
    width: 100,
    render: (updatedAt: string) => (
      <DisplayDateComponent
        value={updatedAt}
        format="USA_DATE"
        fallbackText={<DisplayOrEmptyComponent />}
      />
    ),
    sorter: (
      a: TableArticle,
      b: TableArticle,
    ) => dayjs(a.updatedAt || undefined).valueOf() - dayjs(b.updatedAt || undefined).valueOf(),
    defaultSortOrder: 'descend',
  }),
});
